import React, { useState, useEffect } from "react";
import styles from "../Components/css/rigdetails.module.css";
import axios from "axios";
import UploadDocument from "./Uploaddocument";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const Editcomponent = ({
  s_no,
  rig_name,
  short_name,
  customer_name,
  details,
  design,
  location,
  hull_no,
  design_2,
  new_group,
  setEnableEdit,
  enableEdit,
  refresh,

  setRefresh,
  details_document_name,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [checked, setChecked] = useState(false);
  const [openmodal2, setopenmodal2] = useState(false);

  // remove scroll when popup is open
  useEffect(() => {
    const body = document.querySelector("body");
    if (checked) {
      body.classList.add(styles.noScroll);
    } else {
      body.classList.remove(styles.noScroll);
    }

    return () => {
      body.classList.remove(styles.noScroll);
    };
  }, [checked]);

  const [formData, setFormData] = useState({
    rig_name,
    short_name: short_name,
    customer_name: customer_name,
    design: design,
    location: location,
    hull_no: hull_no,
    design_2: design_2,
    new_group: new_group,
  });

  const [selectedFile, setSelectedFile] = useState({
    details: details,
  });

  const [selectedFileName, setSelectedFileName] = useState({
    details: details_document_name,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStopClose = (e) => {
    e.stopPropagation();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      rig_name,
      short_name,
      customer_name,
      design,
      location,
      hull_no,
      design_2,
      new_group,
    } = formData;
    const payload = {
      rig_name:rig_name,
      short_name: short_name,
      customer_name: customer_name,
      design: design,
      location: location,
      hull_no: hull_no,
      design_2: design_2,
      new_group: new_group,
      details_document_name: selectedFileName.details,
      details: selectedFile.details,
    };
    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updaterigdetails/${s_no}`,
        payload
      );
      const data = res.data; // Access the response data using `data` property
      console.log(data);
      if (res.status === 200) {
        setChecked(!checked);
      }
    } catch (error) {
      console.log("Error occurred during request.", error);
      // Handle any request errors
    }
    setSelectedOption("");
    setRefresh(!refresh);
    setEnableEdit(false);
    setopenmodal2(true);
  };

  // _______________________________to uncheck radio button
  const closePopup = () => {
    setChecked(!checked);
    handleUncheckRadio();
    setEnableEdit(!enableEdit);
  };
  const handleRadioOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleUncheckRadio = () => {
    setSelectedOption("");
  };
  // ______________________________________________________

  const handleFileUpload = async (file, fieldName) => {
    const formData = new FormData();

    formData.append("file", file);
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/uploadfile",
        formData
      );

      // console.log(res.data);
      const pathOfFile = res.data.fileUrl;
      const fileNameApi = res.data.filename;

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: fileNameApi,
      });

      setSelectedFile({
        ...selectedFile,
        [fieldName]: pathOfFile,
      });
    } catch (error) {
      console.error("Error uploading details doc:", error);
    }
  };

  const removeFile = async (fieldName, e, index) => {
    e.preventDefault();

    setSelectedFile((prevSelectedFile) => ({
      ...prevSelectedFile,
      [fieldName]: null,
    }));
  };

  const deleteFile = async (fieldName) => {
    const fileApiName = selectedFileName[fieldName];
    console.log(fileApiName);
    try {
      const res = await axios.delete(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/deletefile/${fileApiName}`
      );

      await deleteURLName(fieldName);

      console.log("deleted File    ", res);

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: "",
      });
      setSelectedFile({
        ...selectedFile,
        [fieldName]: "",
      });
    } catch (err) {
      console.log(err);
    }
    setRefresh(!refresh);
  };

  const deleteURLName = async (fieldName) => {
    const { details } = selectedFile;

    const payload = {
      details: fieldName === "details" ? "" : details,

      details_document_name:
        fieldName === "details" ? "" : selectedFileName.details,
    };

    const res = await axios.patch(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/rig_details/fileupdate/${s_no}`,
      payload
    );

    console.log(res);
  };

  return (
    <div>
      <div className={styles.editRadioButtonRig}>
        <input
          type="radio"
          name="rig"
          value="select"
          checked={selectedOption === "select"}
          onChange={handleRadioOptionChange}
          onClick={() => setChecked(!checked)}
        />
      </div>
      {checked && (
        <>
          <div className={styles.popupContainer} onClick={closePopup}>
            <div className={styles.popUp} onClick={handleStopClose}>
              <form>
                <div className={styles.popupItemss}>
                  Edit
                  <div style={{ cursor: "pointer" }}>
                    <svg
                      onClick={closePopup}
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.4998 2.41663C7.84192 2.41663 2.4165 7.84204 2.4165 14.5C2.4165 21.1579 7.84192 26.5833 14.4998 26.5833C21.1578 26.5833 26.5832 21.1579 26.5832 14.5C26.5832 7.84204 21.1578 2.41663 14.4998 2.41663ZM18.5598 17.2791C18.9103 17.6295 18.9103 18.2095 18.5598 18.56C18.3786 18.7412 18.149 18.8258 17.9194 18.8258C17.6898 18.8258 17.4603 18.7412 17.279 18.56L14.4998 15.7808L11.7207 18.56C11.5394 18.7412 11.3098 18.8258 11.0803 18.8258C10.8507 18.8258 10.6211 18.7412 10.4398 18.56C10.0894 18.2095 10.0894 17.6295 10.4398 17.2791L13.219 14.5L10.4398 11.7208C10.0894 11.3704 10.0894 10.7904 10.4398 10.44C10.7903 10.0895 11.3703 10.0895 11.7207 10.44L14.4998 13.2191L17.279 10.44C17.6294 10.0895 18.2094 10.0895 18.5598 10.44C18.9103 10.7904 18.9103 11.3704 18.5598 11.7208L15.7807 14.5L18.5598 17.2791Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                {/* <label>
              S.NO
              <input
                type="text"
                name="s_no"
                value={formData.rig_name}
                onChange={handleInputChange}
              />
            </label> */}
                <div className={styles.popupItemss}>
                  <label>Rig Name:</label>
                  <input
                    type="text"
                    name="rig_name"
                    value={formData.rig_name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItemss}>
                  <label>Short Name:</label>
                  <input
                    type="text"
                    name="short_name"
                    value={formData.short_name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.popupItemss}>
                  <label>Customer Name:</label>
                  <input
                    type="text"
                    name="customer_name"
                    value={formData.customer_name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItemss}>
                  <label>
                    Details:
                    <br />
                    (Upload Document)
                  </label>
                  <div>
                    {selectedFile.details ? (
                      <div className={styles.uploadedFileContainer}>
                        <NavLink
                          className={styles.uploadedFileName}
                          to={selectedFile.details}
                          target="_blank"
                          rel="noopener noreferrer">
                          View File
                        </NavLink>
                        <svg
                          onClick={() => deleteFile("details")}
                          style={{ cursor: "pointer" }}
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    ) : (
                      <UploadDocument
                        fieldName={"details"}
                        selectedFile={selectedFile.details}
                        selectedFileName={selectedFileName.details}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    )}
                  </div>
                </div>

                <div className={styles.popupItemss}>
                  <label>Design</label>
                  <input
                    type="text"
                    name="design"
                    value={formData.design}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItemss}>
                  <label>Location:</label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItemss}>
                  <label>Hull Number:</label>
                  <input
                    type="text"
                    name="hull_no"
                    value={formData.hull_no}
                    onChange={handleInputChange}
                  />
                </div>

                {/* <div className={styles.popupItemss}>
                  <label>Design 2:</label>
                  <input
                    type="text"
                    name="design_2"
                    value={formData.design_2}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItemss}>
                  <label>New Group:</label>
                  <input
                    type="text"
                    name="new_group"
                    value={formData.new_group}
                    onChange={handleInputChange}
                  />
                </div> */}

                <br />

                <div className={styles.popupButtons}>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    className={styles.SubmitButton}
                    size="large"
                    variant="contained">
                    Submit
                  </Button>
                  <Button
                    className={styles.CancelButton}
                    size="large"
                    onClick={closePopup}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Editcomponent;
