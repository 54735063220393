import React from "react";
import { Link } from "react-router-dom";
import styles from "./css/CustomerPropertyLogs.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";


const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function CustomerPropertyLogs() {
  const [searchQuery, setSearchQuery] = useState("");
  const { id } = useParams();
  const { part_number } = useParams();
  const navigate = useNavigate();
  const [itemsReceived, setItemsReceived] = useState([]);
  const [itemsProduced, setItemsProduced] = useState([]);
  const [itemdata, setitemdata] = useState([]); 
  const [consumedSum, setConsumedSum] = useState([]);
  const [producedSum, setProducedSum] = useState([]);

  const [partNumberToItemIdMap, setPartNumberToItemIdMap] = useState({});
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  useEffect(() => {
    async function fetchDetails() {
      try {
        const [res1, res2, resi] = await Promise.all([
          axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get2`),
          axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/csp_produced_items_get`),
          axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster`),
        ]);

        const itemsReceivedData = res1.data.data;
        const itemsProducedData = res2.data.data;
        const itemData = resi.data;

        // Create a mapping between (Item_Name + Part_Number) and item IDs
        const partNumberToItemIdMap = {};
        itemData.forEach((item) => {
          const key = `${item.Item_Name}_/${item.Part_Number}`;
          partNumberToItemIdMap[key] = item.Item_Id;
        });

        // Set the state for items received and the part number to item ID map
        setItemsReceived(itemsReceivedData);
        setItemsProduced(itemsProducedData);
        setPartNumberToItemIdMap(partNumberToItemIdMap);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, []);
  console.log("setPartNumberToItemIdMap", partNumberToItemIdMap);


  const groupedItems = itemsReceived.reduce((acc, item) => {
    // Convert quantity to number, consider it as 0 if it's null or an empty string
    const quantityReceived = item.Quantity_Received ? parseInt(item.Quantity_Received) : 0;
    const key = `${item.Item_Name}-${item.Part_Number}`; // Create composite key
  
    if (!acc[key]) {
      acc[key] = { ...item, Quantity_Total: quantityReceived };
    } else {
      acc[key].Quantity_Total += quantityReceived;
    }
    
    return acc;
  }, {});

  // Add items from itemsProduced that are not in groupedItems
  itemsProduced.forEach(item => {
    const key = `${item.Item_Name}-${item.Part_Number}`; // Create composite key

    if (!groupedItems[key]) {
      groupedItems[key] = { ...item, Quantity_Total: 0 };
    }
  });

  // Convert the grouped items object back to an array if needed
  const groupedItemsArray = Object.values(groupedItems);

  console.log("groupedItemsArray",groupedItemsArray);

  // Filtering logic
  const filteredItems = Object.values(groupedItems).filter((item) => {
    const queryParts = searchQuery.trim().toLowerCase().split(' ');
    const itemId = partNumberToItemIdMap[`${item.Item_Name}_/${item.Part_Number}`];
    return queryParts.every(part =>
      item.Item_Name?.toLowerCase().includes(part) ||
      item.Part_Number?.toLowerCase().includes(part) ||
      (itemId && itemId.toString().toLowerCase().includes(part))
    );
  });

  console.log("filtered_items", filteredItems);
  useEffect(() => {
    const finalsum = async () => {
      try {
        const res1 = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/Material_Consumed_Sum');

        // Transform the data to create the composite key using forEach
        const transformedData = {};
        res1.data.data.forEach(item => {
          const compositeKey = `${item.Item_Name}_/${item.Part_Number}`;
          transformedData[compositeKey] = item.Qty_Consumed;
        });

        setConsumedSum(transformedData);
      } catch (error) {
        console.error(error);
      }
    }

    finalsum();
  }, []);

  console.log("setConsumedSum", consumedSum);

  useEffect(() => {
    const finalsum = async () => {
      try {
        const res1 = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/Material_Produced_Sum');

        // Transform the data to create the composite key using forEach
        const transformedData = {};
        res1.data.data.forEach(item => {
          const compositeKey = `${item.Item_Name}_/${item.Part_Number}`;
          transformedData[compositeKey] = item.Qty_Produced;
        });

        setProducedSum(transformedData);
      } catch (error) {
        console.error(error);
      }
    }

    finalsum();
  }, []);

  console.log("ProducedSum",producedSum)
  return (
    <div className={styles.topdiv}>
      <div style={{ justifyContent: "left" }}>
        <div className={styles.headingdiv}>
          <h1 className={styles.heading}> Customer Supplied Property Logs </h1>
        </div>
      </div>
      <div className={styles.sections}>
        <div>
          <div className={styles.searchContainer}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Search Item Name, Part Number, Item ID..."
              value={searchQuery}
              onChange={handleSearchChange}
            />

            <div className={styles.buttonContainer}>
              <button className={styles.searchButton} disabled>
                <svg
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  style={{ paddingBottom: "2px" }}
                  viewBox="0 0 22 22"
                  id="search"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M15.5 14h-.79l-.28-.27c1.2-1.4 1.82-3.31 1.48-5.34-.47-2.78-2.79-5-5.59-5.34-4.23-.52-7.79 3.04-7.27 7.27.34 2.8 2.56 5.12 5.34 5.59 2.03.34 3.94-.28 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* ////table//// */}

        <div className={styles.itemstablecontainer}>
          <table className={styles.itemstable}>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Item Name</th>
                <th>Part Number</th>
                <th>Available Quantity</th>
                <th>UOM</th>
                <th>Item Id</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.length === 0 && (
                <tr>
                  <td colSpan="6">
                    <div className={styles.noResults}>No Results Found</div>
                  </td>
                </tr>
              )}
              {filteredItems.map((item, index) => {
                const key = `${item.Item_Name}_/${item.Part_Number}`;
                const itemId = partNumberToItemIdMap[key];
                console.log('Rendering:', item.Item_Name, item.Part_Number, key, itemId);
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Link
                        to={{
                          pathname: `/customerlogsdetails/${encodeURIComponent(item.Item_Name)}/${encodeURIComponent(itemId)}/${item.Part_Number ? encodeURIComponent(item.Part_Number) : null
                            }`,
                        }}
                      >
                        {item.Item_Name}
                      </Link>
                    </td>
                    <td>{item.Part_Number}</td>
                    <td>{item.Quantity_Total - (consumedSum[key] ? consumedSum[key] : 0) + (producedSum[key] ? producedSum[key] : 0)}</td>
                    <td>{item.UOM ? item.UOM : item.UoM ? item.UoM : "-"}</td>
                    <td>{itemId}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}


export default CustomerPropertyLogs;
