import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './css/tabs_bar_dailytracker.module.css';

const TabsBarDailyTracker = ({ activeTab, setActiveStatus, activeStatus, setSearchTerm }) => {
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState("");



    const handleNav = (tab, path) => {
        navigate(path);
    }

    const handleSearch = (event) => {
        setSearchInput(event.target.value);
        setSearchTerm(event.target.value); // Update search term in parent component
    }

    const statusOptions = {
        "Spares Trading": ["Ongoing", "Delivered", "Closed"],
        "Inquiry": ["Open Quote", "Open PO", "Closed"],
        "Purchases": ["Ongoing", "Closed"],
    };

    // Ensure the first option in the select dropdown is set as the default when navigating to a new tab
    useEffect(() => {
        if (statusOptions[activeTab] && setActiveStatus) {
            setActiveStatus(statusOptions[activeTab][0]); // Set the first option as the default
        }
    }, [activeTab, setActiveStatus]); // Run this whenever activeTab changes


    return (
        <div className={styles.mainDiv1}>

            <div className={styles.buttonDiv}>
                <button style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
                    className={activeTab === 'Spares Trading' ? styles.DivTabs1 : styles.DivTabs2}
                    onClick={() => handleNav('Spares Trading', '/spares-orders/spares-trading')}>Spares Trading</button>

                <button className={activeTab === 'Inquiry' ? styles.DivTabs1 : styles.DivTabs2} onClick={() => handleNav('Inquiry', '/spares-orders/inquiry')}>Inquiry</button>

                <button
                    style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
                    className={activeTab === 'Purchases' ? styles.DivTabs1 : styles.DivTabs2}
                    onClick={() => handleNav('Purchases', '/spares-orders/purchases-tracker')}
                >Purchases
                </button>
            </div>

            <div className={styles.selectDiv}>
                <label>Select Status: </label>
                <div style={{ maxWidth: "240px" }}>
                    <select value={activeStatus} onChange={(e) => setActiveStatus && setActiveStatus(e.target.value)}>
                        {statusOptions[activeTab]?.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        style={{
                            marginLeft: "-30px",
                            cursor: "pointer"
                        }}
                    // onClick={() => {
                    //     document.getElementById("svgdwn").click();
                    // }}

                    >
                        <path
                            d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>

            <div className={styles.searchDiv}>
                <div className={styles.Searchbar}>
                    <input
                        type='text'
                        placeholder={
                            activeTab === "Spares Trading" ? "Search by PO No, So No" :
                            activeTab === "Inquiry" ? "Search by ID No" :
                            activeTab === "Purchases" ? "Search by PO No, Linked SO" :
                            "Search"
                        }
                        value={searchInput}
                        onChange={handleSearch}
                    />
                    <svg
                        className={styles.searchIconFilter}
                        style={{ marginLeft: "5%" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                            fill="#0000008F"
                        />
                        <path
                            d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
                            fill="#0000008F"
                        />
                    </svg>
                </div>
            </div>

            {/* <div className={styles.downloadDiv}>
                <svg width="40" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                // onClick={handleexceldwn} className={styles.btnfilter3} 
                >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 5.28595 -1.19209e-07 2.92893 1.46447 1.46447C2.92893 -1.19209e-07 5.28595 0 10 0C14.714 0 17.0711 -1.19209e-07 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C-1.19209e-07 17.0711 0 14.714 0 10ZM10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V10.1893L12.4697 8.4697C12.7626 8.1768 13.2374 8.1768 13.5303 8.4697C13.8232 8.7626 13.8232 9.2374 13.5303 9.5303L10.5303 12.5303C10.3897 12.671 10.1989 12.75 10 12.75C9.8011 12.75 9.6103 12.671 9.4697 12.5303L6.46967 9.5303C6.17678 9.2374 6.17678 8.7626 6.46967 8.4697C6.76256 8.1768 7.23744 8.1768 7.53033 8.4697L9.25 10.1893V5C9.25 4.58579 9.5858 4.25 10 4.25ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75H14C14.4142 15.75 14.75 15.4142 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H6Z" fill="#5B6BE1" />
                </svg>
            </div> */}


        </div>
    )
}

export default TabsBarDailyTracker