import React from "react";
import Etlogo from "../../src/Components/Etlogo.png";
import signout from "../../src/Components/signout-icon.png";
import "../App.css";
import styles from ".././Components/css/nav.module.css";
import RigDetails from "../Components/Rigdetails";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import NotificationBell from "./NotificationBell";
import { useState, useEffect } from "react";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Navbar({ isLoggedIn, setIsLoggedIn }) {
  const [name, setname] = useState("")
  const [featureadminper, setfeatureadminper] = useState([]);
  const [featuredailytracker, setfeaturedailytracker] = useState([]);
  const [featurerefrencedetails, setfeaturerefrencedetails] = useState([]);
  const [featuredashboard, setfeaturedashboard] = useState([]);
  const [featuresurveyschedule, setfeaturesurveyschedule] = useState([]);
  const [featureprojects, setfeatureprojects] = useState([]);
  const [featuremobilization, setfeaturemobilization] = useState([]);
  const [featurereports, setfeaturereports] = useState([]);
  const [featureprofdetails, setfeatureprofdetails] = useState([]);
  const [featurerigsdet, setfeaturerigsdet] = useState([]);
  const [featuretoolsdet, setfeaturetoolsdet] = useState([]);
  const [featurelegaldet, setfeaturelegaldet] = useState([]);
  const [featureqmstrack, setfeatureqmstrack] = useState([]);
  const [featuretempdet, setfeaturetempdet] = useState([]);
  const [featuretracker, setfeaturetracker] = useState([]);
  const [featureworkshop, setfeatureworkshop] = useState([]);
  const [featureworkshopproject, setfeatureworkshopproject] = useState([]);
  const [featureworkshopadmin, setfeatureworkshopadmin] = useState([]);
  const [featureworkshopinventory, setfeatureworkshopinventory] = useState([]);
  const [featureworkshopbills, setfeatureworkshopbills] = useState([]);
  const email = localStorage.getItem("UserEmail")
  const [subDropdownOpen, setSubDropdownOpen] = useState(false);
  const [isSubDropdownVisible, setSubDropdownVisible] = useState(false); //To control Inventory sub-dropdown
  const [isProjectsSubDropdownVisible, setProjectsSubDropdownVisible] = useState(false);//To control Workshop Projects sub-dropdown

  const handleMouseEnter = () => {
    setSubDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setSubDropdownVisible(false);
  };

  const handleMouseEnterWorkshopProjects = () => {
    setProjectsSubDropdownVisible(true);
  };

  const handleMouseLeaveWorkshopProjects = () => {
    setProjectsSubDropdownVisible(false);
  };

  const getDropdownPosition = () => {
    const dropdownElement = document.getElementById('dropdown-trigger');
    const dropdownWidth = 140; // Adjust this based on the width of your dropdown

    if (dropdownElement) {
      const windowWidth = window.innerWidth;
      const dropdownRightSpace = windowWidth - dropdownElement.getBoundingClientRect().right;

      return dropdownRightSpace <= dropdownWidth ? 'right' : 'left'; // right here is used for right: "100%, positioning it to the left of the container element, and just the reverse for left: "100%"
    }

    return 'right'; // Default to right: '100%' if unable to determine position
  };

  const dropdownPosition = getDropdownPosition();

  const getDropdownPositionWorkshopProjects = () => {
    const dropdownElement = document.getElementById('dropdown-trigger-workshop-projects');
    const dropdownWidth = 140; // Adjust this based on the width of your dropdown

    if (dropdownElement) {
      const windowWidth = window.innerWidth;
      const dropdownRightSpace = windowWidth - dropdownElement.getBoundingClientRect().right;

      return dropdownRightSpace <= dropdownWidth ? 'right' : 'left'; // right here is used for right: "100%, positioning it to the left of the container element, and just the reverse for left: "100%"
    }

    return 'right'; // Default to right: '100%' if unable to determine position
  };

  const dropdownPositionWorkshopProjects = getDropdownPositionWorkshopProjects();



  function setCookie(name, value) {
    var cookieString = encodeURIComponent(name) + "=" + encodeURIComponent(value) + "; path=/";
    document.cookie = cookieString;
  }
  const handleSignOut = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("token");
    setCookie("isLoggedIn3", false);
    setIsLoggedIn(!isLoggedIn);
  };

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        console.log(response.data);
        console.log(localStorage.getItem("Group"));

        const filgroupfeatures = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Daily Tracker" && el.Access === "Can View"
          );
        });
        const filfeaturetracker = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Tracker" && el.Access === "Can View"
          );
        });

        const filfeatureworkshop = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Workshop" && el.Access === "Can View"
          );
        });

        const filgroupfeatures2 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Dashboard" && el.Access === "Can View"
          );
        });

        const filgroupfeatures3 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Reports" && el.Access === "Can View"
          );
        });

        const filgroupfeatures4 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Survey Schedule" && el.Access === "Can View"
          );


        });
        const filgroupfeatures5 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Reference Details" && el.Access === "Can View"
          );


        });
        const filgroupfeatures6 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Mobilizations" && el.Access === "Can View"
          );



        });
        const filgroupfeatures7 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Projects" && el.Access === "Can View"
          );



        });
        const filgroupfeatures8 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Professional Details" && el.Access === "Can View"
          );



        });
        const filgroupfeatures9 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Rig Details" && el.Access === "Can View"
          );



        });
        const filgroupfeatures10 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Tools Register" && el.Access === "Can View"
          );



        });
        const filgroupfeatures11 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Legal Details" && el.Access === "Can View"
          );



        });
        const filgroupfeatures12 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Templates" && el.Access === "Can View"
          );



        });
        const filgroupfeatures13 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "QMS Roadmap" && el.Access === "Can View"
          );



        });

        const filworksproj = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Workshop Projects" && el.Access === "Can View"
          );
        });

        const filworksadmin = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Workshop Admin Dashboard" && el.Access === "Can View"
          );
        });

        const filworksinve = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Workshop Inventory" && el.Access === "Can View"
          );
        });

        const filworksbills = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Workshop Bills" && el.Access === "Can View"
          );
        });

        const filgroupfeaturesadmin = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Nav Bar" && el.Feature_Name === "Admin Permissions" && el.Access === "Can View"
          );
        });

        setfeatureadminper(filgroupfeaturesadmin);
        setfeaturedailytracker(filgroupfeatures);
        setfeaturedashboard(filgroupfeatures2);
        setfeaturesurveyschedule(filgroupfeatures4);
        setfeaturerefrencedetails(filgroupfeatures5);
        setfeaturereports(filgroupfeatures3);
        setfeaturemobilization(filgroupfeatures6);
        setfeatureprojects(filgroupfeatures7);
        setfeatureprofdetails(filgroupfeatures8);
        setfeaturerigsdet(filgroupfeatures9);
        setfeaturetoolsdet(filgroupfeatures10);
        setfeaturelegaldet(filgroupfeatures11);
        setfeaturetempdet(filgroupfeatures12);
        setfeatureqmstrack(filgroupfeatures13);
        setfeaturetracker(filfeaturetracker);
        setfeatureworkshop(filfeatureworkshop);
        setfeatureworkshopproject(filworksproj);
        setfeatureworkshopadmin(filworksadmin);
        setfeatureworkshopinventory(filworksinve);
        setfeatureworkshopbills(filworksbills);

        console.log(filgroupfeatures);
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  // const handleSignOut = () => {
  //   localStorage.removeItem("isLoggedIn");
  //   setIsLoggedIn(!isLoggedIn);
  // };

  return (
    <>
      <div className={styles.navbarContainer}>
        <div className={styles.logoAndText}>
          <img src={Etlogo} className={styles.ergonlogo} />
          <div className={styles.jupitertext}>Jupiter</div>
        </div>

        <div className={styles.headingsContent}>
          <nav>
            <ul className={styles.horizontalList}>
              {featuredashboard.length > 0 && (<li>
                <NavLink
                  to="/dashboard"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 700 : "",
                    paddingBottom: isActive ? "7px" : "",
                    borderBottom: isActive ? "3px solid white" : "",
                  })}
                  className={`${styles.navLinks} ${styles.items}`}>
                  Dashboard
                </NavLink>
              </li>)}

              {featuretracker.length > 0 && (<div>
                <button
                  className="btn btn-light dropdown-toggle"
                  type="button"
                  id={styles.dropdownMenuButton1}
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Tracker
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1">
                  {featuredailytracker.length > 0 && (
                    <>
                      <li>
                        <NavLink
                          to="/dailytracker"
                          style={({ isActive }) => ({
                            fontWeight: isActive ? 700 : "",
                            paddingBottom: isActive ? "7px" : "",
                            borderBottom: isActive ? "3px solid white" : "",
                          })}
                          className="dropdown-item">
                          Service jobs
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="/spares-orders/spares-trading"
                          style={({ isActive }) => ({
                            fontWeight: isActive ? 700 : "",
                            paddingBottom: isActive ? "7px" : "",
                            borderBottom: isActive ? "3px solid white" : "",
                          })}
                          className="dropdown-item">
                          Spares Orders
                        </NavLink>
                      </li>
                    </>
                  )}

                  <li
                    id="dropdown-trigger"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ cursor: "pointer" }}>
                    <a
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      className="dropdown-item">
                      Techs
                      <svg
                        style={{ marginLeft: "42px" }}
                        width="20"
                        height="20"
                        viewBox="-5 0 30 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.17969 6.08025V12.3103V17.9203C8.17969 18.8803 9.33969 19.3603 10.0197 18.6803L15.1997
                            13.5003C16.0297 12.6703 16.0297 11.3203 15.1997 10.4903L13.2297 8.52025L10.0197 5.31025C9.33969 4.64025 8.17969 5.12025 8.17969 6.08025Z"
                          fill="black"
                        />
                      </svg>
                    </a>
                    {isSubDropdownVisible && (
                      <ul
                        className="dropdown-menu"
                        style={{
                          display: isSubDropdownVisible ? "block" : "none",
                          background: "white",
                          position: "absolute",
                          top: "43.5%",
                          [dropdownPosition]: "100%",
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <li>
                          <NavLink
                            to="/availability"
                            className="dropdown-item">
                            Availability
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/attendance"
                            className="dropdown-item">
                            Attendance
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/residentvisas"
                            style={({ isActive }) => ({
                              fontWeight: isActive ? 700 : "",
                              paddingBottom: isActive ? "7px" : "",
                              borderBottom: isActive ? "3px solid white" : "",
                            })}
                            className="dropdown-item">
                            Resident Visas
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/mobilizationdocuments"
                            style={({ isActive }) => ({
                              fontWeight: isActive ? 700 : "",
                              paddingBottom: isActive ? "7px" : "",
                              borderBottom: isActive ? "3px solid white" : "",
                            })}
                            className="dropdown-item">
                            Documents
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/utilization" className="dropdown-item">
                            % Utilization
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/monthmandays" className="dropdown-item">
                            Man Days
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>

                  {featurereports.length > 0 && (<li>
                    <NavLink
                      to="/documents"
                      style={({ isActive }) => ({
                        fontWeight: isActive ? 700 : "",
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      className="dropdown-item">
                      Reports
                    </NavLink>
                  </li>)}

                  {featuremobilization.length > 0 && (<li>
                    {/* <NavLink
                  to="/mobilizations"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 700 : "",
                    paddingBottom: isActive ? "7px" : "",
                    borderBottom: isActive ? "3px solid white" : "",
                  })}
                  className="dropdown-item">
                  Mobilizations
                </NavLink> */}
                  </li>)}
                </ul>
              </div>)}

              {featuresurveyschedule.length > 0 && (<li>
                <NavLink
                  to="/surveyschedule"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 700 : "",
                    paddingBottom: isActive ? "7px" : "",
                    borderBottom: isActive ? "3px solid white" : "",
                  })}
                  className={`${styles.navLinks} ${styles.items}`}>
                  Survey Schedule
                </NavLink>
              </li>)}

              <div>
                {featurerefrencedetails.length > 0 && (<button
                  className="btn btn-light dropdown-toggle"
                  type="button"
                  id={styles.dropdownMenuButton1}
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Reference details
                </button>)}
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1">
                  {featurerigsdet.length > 0 && (<li>
                    <NavLink
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      to="/rigdetails"
                      className="dropdown-item">
                      Rig Details
                    </NavLink>
                  </li>)}
                  {featuretoolsdet.length > 0 && (<li>
                    <NavLink
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      to="/toolsregister"
                      className="dropdown-item">
                      Tools Register
                    </NavLink>
                  </li>)}
                  {featurelegaldet.length > 0 && (<li>
                    <NavLink
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      to="/legaldetails"
                      className="dropdown-item">
                      Legal Details
                    </NavLink>
                  </li>)}

                  {featureqmstrack.length > 0 && (
                    <li>
                      <NavLink
                        to="QMStracker"
                        style={({ isActive }) => ({
                          paddingBottom: isActive ? "7px" : "",
                          borderBottom: isActive ? "3px solid white" : "",
                        })}
                        className="dropdown-item">
                        QMS Roadmap
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      to="/responsibilitymatrix"
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      className="dropdown-item">
                      Responsibility Matrix
                    </NavLink>
                  </li>
                  <li
                    id="dropdown-trigger-workshop-projects"
                    onMouseEnter={handleMouseEnterWorkshopProjects}
                    onMouseLeave={handleMouseLeaveWorkshopProjects}
                    style={{ cursor: 'pointer' }}
                  >
                    <a
                      onMouseEnter={handleMouseEnterWorkshopProjects}
                      onMouseLeave={handleMouseLeaveWorkshopProjects}
                      className="dropdown-item"
                    >
                      Supplier
                      <svg style={{ marginLeft: "52px" }} width="20" height="20" viewBox="-5 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.17969 6.08025V12.3103V17.9203C8.17969 18.8803 9.33969 19.3603 10.0197 18.6803L15.1997 
                                13.5003C16.0297 12.6703 16.0297 11.3203 15.1997 10.4903L13.2297 8.52025L10.0197 5.31025C9.33969 4.64025 8.17969 5.12025 8.17969 6.08025Z" fill="black" />
                      </svg>
                    </a>
                    {isProjectsSubDropdownVisible && (
                      <ul
                        className="dropdown-menu"
                        style={{
                          display: isProjectsSubDropdownVisible ? 'block' : 'none',
                          background: 'white',
                          position: 'absolute',
                          bottom: '-15%',
                          [dropdownPositionWorkshopProjects]: '100%',
                        }}
                        onMouseEnter={handleMouseEnterWorkshopProjects}
                        onMouseLeave={handleMouseLeaveWorkshopProjects}
                      >
                        <li>
                          <NavLink
                            to="/supplierdetails"
                            className="dropdown-item"
                          >
                            Details
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/supplierItemMaster"
                            className="dropdown-item"
                          >
                            Items Master
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>

              {featureprofdetails.length > 0 && (<li>
                <NavLink
                  to="/professionaldetails"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 700 : "",
                    paddingBottom: isActive ? "7px" : "",
                    borderBottom: isActive ? "3px solid white" : "",
                  })}
                  className={`${styles.navLinks} ${styles.items}`}>
                  Professionals Details
                </NavLink>
              </li>)}

              {featureprojects.length > 0 && (<li>
                <NavLink
                  to="/projects"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 700 : "",
                    paddingBottom: isActive ? "7px" : "",
                    borderBottom: isActive ? "3px solid white" : "",
                  })}
                  className={`${styles.navLinks} ${styles.items}`}>
                  Offshore Projects
                </NavLink>
              </li>)}

              <div>
                {featureworkshop.length > 0 && (<button
                  className="btn btn-light dropdown-toggle"
                  type="button"
                  id={styles.dropdownMenuButton1}
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Workshop
                </button>)}
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1">
                  {featureworkshopproject.length > 0 && (
                    // <li>
                    //   <NavLink
                    //   exact
                    //     style={({ isActive }) => ({
                    //       paddingBottom: isActive ? "7px" : "",
                    //       borderBottom: isActive ? "3px solid white" : "",
                    //     })}
                    //     to="/workshopprojects"
                    //     className="dropdown-item">
                    //     Projects
                    //   </NavLink>
                    // </li>

                    <>
                      <li>
                        <NavLink
                          style={({ isActive }) => ({
                            paddingBottom: isActive ? "7px" : "",
                            borderBottom: isActive ? "3px solid white" : "",
                          })}
                          to="/workshopprojects"
                          className="dropdown-item">
                          Services
                        </NavLink>
                      </li>

                      <li
                        id="dropdown-trigger-workshop-projects"
                        onMouseEnter={handleMouseEnterWorkshopProjects}
                        onMouseLeave={handleMouseLeaveWorkshopProjects}
                        style={{ cursor: 'pointer' }}
                      >
                        <a
                          onMouseEnter={handleMouseEnterWorkshopProjects}
                          onMouseLeave={handleMouseLeaveWorkshopProjects}
                          className="dropdown-item"
                        >
                          Spares Trading
                          <svg style={{ marginLeft: "5px" }} width="20" height="20" viewBox="-5 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.17969 6.08025V12.3103V17.9203C8.17969 18.8803 9.33969 19.3603 10.0197 18.6803L15.1997 
                                13.5003C16.0297 12.6703 16.0297 11.3203 15.1997 10.4903L13.2297 8.52025L10.0197 5.31025C9.33969 4.64025 8.17969 5.12025 8.17969 6.08025Z" fill="black" />
                          </svg>
                        </a>
                        {isProjectsSubDropdownVisible && (
                          <ul
                            className="dropdown-menu"
                            style={{
                              display: isProjectsSubDropdownVisible ? 'block' : 'none',
                              background: 'white',
                              position: 'absolute',
                              top: '15%',
                              [dropdownPositionWorkshopProjects]: '100%',
                            }}
                            onMouseEnter={handleMouseEnterWorkshopProjects}
                            onMouseLeave={handleMouseLeaveWorkshopProjects}
                          >
                            <li>
                              <NavLink
                                to="/workshop-spares-trading"
                                className="dropdown-item"
                              >
                                Orders
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/workshopspares-inquiry-listing"
                                className="dropdown-item"
                              >
                                Inquiry
                              </NavLink>
                            </li>
                          </ul>
                        )}
                      </li>
                    </>
                  )}

                  {featureworkshopadmin.length > 0 && (<li>
                    <NavLink
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      to="/admintaskbar"
                      className="dropdown-item">
                      Admin Dashboard
                    </NavLink>
                  </li>)}
                  {/* {featureworkshopinventory.length > 0 && (<li>
                    <NavLink
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      to="/inventory"
                      className="dropdown-item">
                      Inventory
                    </NavLink>
                  </li>)} */}
                  <li
                    id="dropdown-trigger"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ cursor: 'pointer' }}
                  >
                    <a
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      className="dropdown-item"
                    >
                      Inventory
                      <svg style={{ marginLeft: "42px" }} width="20" height="20" viewBox="-5 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.17969 6.08025V12.3103V17.9203C8.17969 18.8803 9.33969 19.3603 10.0197 18.6803L15.1997 
                            13.5003C16.0297 12.6703 16.0297 11.3203 15.1997 10.4903L13.2297 8.52025L10.0197 5.31025C9.33969 4.64025 8.17969 5.12025 8.17969 6.08025Z" fill="black" />
                      </svg>

                    </a>
                    {isSubDropdownVisible && (
                      <ul
                        className="dropdown-menu"
                        style={{
                          display: isSubDropdownVisible ? 'block' : 'none',
                          background: 'white',
                          position: 'absolute',
                          top: '41%',
                          [dropdownPosition]: '100%',
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <li>
                          <NavLink
                            to="/ergoninventorylogs"
                            className="dropdown-item"
                          >
                            Ergon Inventory
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/customerpropertylogs"
                            className="dropdown-item">
                            CX Property Log
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/items-master"
                            className="dropdown-item">
                            Items Master List
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* {featureworkshopbills.length > 0 && (<li>
                    <NavLink
                      to="/bills"
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      className="dropdown-item">
                      Bills
                    </NavLink>
                  </li>)} */}

                  <li>
                    <NavLink
                      to="/Purchases"
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      className="dropdown-item">
                      Purchases
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/universal_deliveries"
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      className="dropdown-item">
                      Deliveries
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="universal_inspections"
                      style={({ isActive }) => ({
                        paddingBottom: isActive ? "7px" : "",
                        borderBottom: isActive ? "3px solid white" : "",
                      })}
                      className="dropdown-item">
                      Inspections
                    </NavLink>
                  </li>
                </ul>
              </div>

            </ul>
          </nav>
        </div>

        <div style={{ display: "flex", flexDirection: "row", gap: "25px" }}>
          <div style={{ marginTop: "3px" }}>         <NotificationBell /></div>
          <div>
            <button
              className="btn btn-light dropdown-toggle"
              type="button"
              id={styles.dropdownMenuButton2}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="currentColor"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                />
              </svg>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    border: "1px solid black",
                    margin: "10px",
                    padding: "10px 15px",
                    background: "#D1D9FF",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                  </div>
                  <div>
                    <h6>{localStorage.getItem("Name")}</h6>
                    <span>{localStorage.getItem("useremail")}</span>
                  </div>
                </div>
              </li>
              {featureadminper.length > 0 && (<li>
                <NavLink to="/adminpermission" className={styles.navLinks}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      border: "1px solid black",
                      margin: "10px",
                      padding: "14px 5px",
                    }}
                  >
                    <div className={styles.SignOut}>Admin Permission</div>
                    <div style={{ marginRight: "5px" }}>

                    </div>
                  </div>
                </NavLink>
              </li>)}

              <li>
                <NavLink to="/signout" className={styles.navLinks}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      border: "1px solid black",
                      margin: "10px",
                      padding: "14px 5px",
                    }}
                  >
                    <div className={styles.SignOut} variant="outlined">
                      Logout
                    </div>

                    <div style={{ marginRight: "5px" }}>
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 13 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.1 1.16699H7.69167C5.95833 1.16699 4.875 2.33366 4.875 4.20033V6.56283H7.28L6.15875 5.35533C6.0775 5.26783 6.03958 5.15699 6.03958 5.04616C6.03958 4.93533 6.0775 4.82449 6.15875 4.73699C6.31583 4.56783 6.57583 4.56783 6.73292 4.73699L8.5475 6.69116C8.70458 6.86033 8.70458 7.14033 8.5475 7.30949L6.73292 9.26366C6.57583 9.43283 6.31583 9.43283 6.15875 9.26366C6.00167 9.09449 6.00167 8.81449 6.15875 8.64533L7.28 7.43783H4.875V9.80032C4.875 11.667 5.95833 12.8337 7.69167 12.8337H9.09458C10.8279 12.8337 11.9113 11.667 11.9113 9.80032V4.20033C11.9167 2.33366 10.8333 1.16699 9.1 1.16699Z"
                          fill="black"
                        />
                        <path
                          d="M1.48828 6.5625C1.2662 6.5625 1.08203 6.76083 1.08203 7C1.08203 7.23917 1.2662 7.4375 1.48828 7.4375H4.8737V6.5625H1.48828Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;