import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_inquiry_item_details.module.css";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Select from "react-select";
import TabsBarSparesInquiry from './TabsBarSparesInquiry';
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparesTradingInquiryItemDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks

  const CustomModal = styled(Modal)`
    .MuiBackdrop-root {
      background-color: rgba(255, 255, 255, 0.1);
    }`;

  const [fileContent, setFileContent] = useState([]);   //to store excel/csv file content

  const [rows, setRows] = useState([]);   //to track the rows in the table

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);    // to set the Add Items Manually disabled state

  const [smResults, setsmResults] = useState([]);   // stores the main item details data to be displayed in a table
  const [showInputRow, setShowInputRow] = useState(false);    //an empty input row is added when "Add Items Manually" button is clicked which sets showInputRow to true
  const [showeditRow, setShoweditRow] = useState(false);    //on clicking "Edit" button showeditRow is set true, an additional column containing "Remove" button is displayed
  const [editModesm, seteditModesm] = useState(false);    //on clicking "Edit" button showeditRow is set true, all the rows in the table go into editable state with inputs
  const [refresh, setRefresh] = useState(false);    //refresh state is used to control the useEffect where all the get requests are, explicitly used in Edit, Save, Cancel and other buttons to refresh the data
  const [openRemoveConfirmationModal, setOpenRemoveConfirmationModal] = useState(false);
  const [SNoToDelete, setSNoToDelete] = useState([]);   //to store the S_Nos to delete
  const [confirmationData, setConfirmationData] = useState({
    extractedSNo: null,
    ItemName: "",
    PartNumber: "",
  });   //to store the data of the entry being removed
  const [featureitemdetails, setfeatureitemdetails] = useState([]);
  const [featureitemdetailsedit, setfeatureitemdetailsedit] = useState([]);
  const [linkedSO, setLinkedSO] = useState([]);    //used for storing the Linked Project SO's to display in the select dropdown
  const [itemsMaster, setItemsMaster] = useState([]);   //used for storing the Items Master List data to display in the Item_Name dropdown when anything is typed
  const [suppliersList, setSupplierList] = useState([]);    //to store a list of all suppliers, their Supplier_ID and Supplier_Name
  const [formDatasm, setFormDatasm] = useState({    //used for storing the table data in the editable state, editModesm
    data: [],
  });

  const [formDataAddRow, setFormDataAddRow] = useState({    //used for storing the data for the input row on clicking the "Add Items Manually" button
    data: [],
  });

  // ***
  const handleSubmitsm = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const {
      Item_Name,
      Part_Number,
      UoM,
      Inquired_Qty,
      Selected_Suppliers,
      Remarks,
    } = formDataAddRow;

    const payloadsm = {
      Spares_Inquiry_ID: id,
      Item_Name: Item_Name,
      Part_Number: Part_Number,
      UoM: UoM,
      Inquired_Qty: Inquired_Qty,
      Selected_Suppliers: Selected_Suppliers,
      Remarks: Remarks,
    };

    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_itemdetails_post", // create api in the backend
        payloadsm
      );
      console.log("payloadsm:", payloadsm);

      setFormDataAddRow({
        Item_Name: "",
        Part_Number: "",
        UoM: "",
        Inquired_Qty: "",
        Selected_Suppliers: "",
        Remarks: "",
      });

      if (res.status === 200) {
        const resVendorQuotes = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_itemdetails_vendor_quotes/${id}`);
        const resVendorQuotesDocs = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_vendor_quotes_docs_create/${id}`);
        const resRFQ = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/store_rfq_suppliers/${id}`);
        if (resRFQ.status === 200 && resVendorQuotes.status === 200 && resVendorQuotesDocs.status === 200) {
          console.log("RFQ updated successfully");
        } else {
          console.error("Error updating RFQ");
        }

        alert("Item Added Successfully");
        setIsLoading(false);
        setRefresh(!refresh);
        setHeightRefresh(!heightRefresh);
      }
      console.log("post POST smResults", smResults);
    } catch (error) {
      console.error("Error submitting data:", error);
      setIsLoading(false);
    }
    setIsButtonDisabled(false);
    setShowInputRow(false);
    setIsLoading(false);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setFormDatasm((prevFormDatasm) => {
      const updatedData = [...prevFormDatasm.data];
      if (!updatedData[index]) {
        updatedData[index] = {};
      }
      updatedData[index] = {
        ...updatedData[index],
        [name]: value,
      };
      return {
        ...prevFormDatasm,
        data: updatedData,
      };
    });
  };

  const handleDropdownSelection = (selectedItem, index) => {
    const { Item_Name, Part_Number } = selectedItem;
    const newData = [...formDatasm.data];
    newData[index] = { ...newData[index], Item_Name, Part_Number: Part_Number };
    setFormDatasm({ data: newData });
    setDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: false }));
    setPartDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: false }));
    setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [index]: true }));
  };

  const handleSupplierChange = (selectedOptions, index) => {
    // Get the Supplier_IDs of the selected suppliers and join them as a comma-separated string
    const selectedSupplierIDs = selectedOptions.map(option => option.Supplier_ID).join(',');

    // Update formDatasm with the new Selected_Suppliers for the specific row (index)
    const updatedFormDatasm = { ...formDatasm };
    updatedFormDatasm.data[index].Selected_Suppliers = selectedSupplierIDs;
    setFormDatasm(updatedFormDatasm);
  };

  console.log("formDatasm input change:", formDatasm);

  const handleInputChangevis = (e) => {
    const { name, value } = e.target;
    setFormDataAddRow((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log("formDataAddRow input change:", formDataAddRow);
  };

  const handleDropdownSelectionAddRow = (selectedItem) => {
    const { Item_Name, Part_Number } = selectedItem;
    setFormDataAddRow((prev) => ({
      ...prev,
      Item_Name: Item_Name,
      Part_Number: Part_Number,
    }));
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setIsOEMDisabledAddRow(true);
  };

  const handleSupplierChangeAddRow = (selectedOptions) => {
    // Get the Supplier_IDs of the selected suppliers and join them as a comma-separated string
    const selectedSupplierIDs = selectedOptions.map(option => option.Supplier_ID).join(',');

    // Update formDataAddRow with the new Selected_Suppliers
    setFormDataAddRow(prevState => ({
      ...prevState,
      Selected_Suppliers: selectedSupplierIDs
    }));
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    setShowInputRow(false);
    setIsButtonDisabled(false);
    setShoweditRow(false);
    seteditModesm(false);
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setDropdownVisible(false);
    setPartDropdownVisible(false);
    for (let i = 0; i < smResults.length; i++) {
      setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [i]: true }));
    }
    setIsOEMDisabledAddRow(true);
    setFormDataAddRow({
      Item_Name: "",
      Part_Number: "",
      UoM: "",
      Inquired_Qty: "",
      Selected_Suppliers: "",
      Remarks: "",
    });
    setRefresh(!refresh);
  };

  const handleManualInputClick = () => {
    setIsButtonDisabled(true);
    setShowInputRow(true);
    setHeightRefresh(!refresh);
    setFormDataAddRow((prev) => ({
      ...prev,
      UoM: "pcs",
    }));
  };

  console.log("formDataAddRow", formDataAddRow);

  const handleeditsm = () => {
    setShoweditRow(true);
    seteditModesm(true);
    setIsButtonDisabled(true);
  };

  const handlecancelsm = () => {
    setShoweditRow(false);
    seteditModesm(false);
    setShowInputRow(false);
    setIsButtonDisabled(false);
    setSNoToDelete([]);
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setDropdownVisible(false);
    setPartDropdownVisible(false);
    for (let i = 0; i < smResults.length; i++) {
      setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [i]: true }));
    }
    setIsOEMDisabledAddRow(true);
    setFormDataAddRow({
      Item_Name: "",
      Part_Number: "",
      UoM: "",
      Inquired_Qty: "",
      Selected_Suppliers: "",
      Remarks: "",
    });
    setRefresh(!refresh);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setDropdownVisible(false);
    setPartDropdownVisible(false);
    try {
      await Promise.all(SNoToDelete.map(async (extractedSNo) => {
        await axios.delete(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_itemdetails_delete?S_No=${extractedSNo}`
        );
      }));

      // Clear the SNoToDelete array after successful deletions
      setSNoToDelete([]);

      const updatePromises = formDatasm.data.map(async (el) => {
        const updatePayload = {
          S_No: el.S_No,
          Spares_Inquiry_ID: id,
          Item_Name: el.Item_Name,
          Part_Number: el.Part_Number,
          UoM: el.UoM,
          Inquired_Qty: el.Inquired_Qty,
          Selected_Suppliers: el.Selected_Suppliers,
          Remarks: el.Remarks,
        };
        console.log("updatePayload", updatePayload);

        const updatePayload1 = {
          Item_Name: el.Item_Name,
          Part_Number: el.Part_Number,
          Manufacturer_Part_Number: el.Manufacturer_Part_Number,
          UoM: el.UoM,
        }

        return axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_itemdetails_put?S_No=${el.S_No}`,
          updatePayload
        );
      });

      // Wait for all promises to resolve before proceeding
      await Promise.all(updatePromises);

      const resVendorQuotes = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_itemdetails_vendor_quotes/${id}`);
      const resVendorQuotesDocs = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_vendor_quotes_docs_create/${id}`);
      const resRFQ = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/store_rfq_suppliers/${id}`)

      // Clear the formDatasm after successful updates
      setFormDatasm({
        data: [],
      });

    } catch (error) {
      console.error("Error submitting data:", error);
    }

    setShoweditRow(false);
    seteditModesm(false);
    setShowInputRow(false);
    setIsButtonDisabled(false);
    setRefresh(!refresh);
    setHeightRefresh(!heightRefresh);
    setIsLoading(false);
  }

  const handleOpenRemoveConfirmationModal = async (e, SNo, ItemName, PartNumber) => {
    e.preventDefault();
    setConfirmationData({
      extractedSNo: SNo,
      ItemName: ItemName,
      PartNumber: PartNumber,
    });
    setOpenRemoveConfirmationModal(true);
  }

  const handleCloseRemoveConfirmationModal = async (e) => {
    e.preventDefault();
    setConfirmationData({
      extractedSNo: null,
      ItemName: "",
      PartNumber: "",
    });

    setOpenRemoveConfirmationModal(false);
  }

  const handleRemove = (e, extractedSNo) => {
    try {
      if (extractedSNo !== null) {
        console.log("idToDelete:" + extractedSNo);

        // Store the extractedSNo in the SNoToDelete array
        setSNoToDelete(prevState => [...prevState, extractedSNo]);

        // Update formDatasm and smResults on the frontend
        const updatedFormData = {
          ...formDatasm,
          data: formDatasm.data.filter((el) => el.S_No !== extractedSNo)
        };
        setFormDatasm(updatedFormData);

        const updatedsmResults = smResults.filter((sm) => sm.S_No !== extractedSNo);
        setsmResults(updatedsmResults);

        console.log("updatedFormDatasm, updatesmResults", updatedFormData, updatedsmResults);

        setOpenRemoveConfirmationModal(false);
      } else {
        console.log("No ID to delete");
      }
    } catch (error) {
      console.error("Error removing data:", error);
    }
  };


  useEffect(() => {
    async function fetchsmDetails() { //first loading of the page data and states, also used to refresh the page when save, cancel, cross etc. are clicked
      setIsLoading(true);
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_itemdetails_get"
        );

        const resItemsMaster = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
        );

        const resSuppliersList = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_itemdetails_suppliers_list"
        );

        setSupplierList(resSuppliersList.data.data);

        const responsefilter = response.data.data.filter((el) => el.Spares_Inquiry_ID == id); //get all item details where Spares_Inquiry_ID is equal to the useParams id

        setItemsMaster(resItemsMaster.data);

        // Create a lookup for Supplier_ID to Supplier_Name
        const supplierNameLookup = resSuppliersList.data.data.reduce((acc, supplier) => {
          acc[supplier.Supplier_ID] = supplier.Supplier_Name;
          return acc;
        }, {});

        // Fetch supplier catalogue data
        const supplierCatalogueResponse = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_supplier_catalogue_getall");
        const supplierCatalogueData = supplierCatalogueResponse.data.data;

        // Fetch suggested supplier data
        const suggestedSupplierResponse = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_suggested_supplier_dataget");
        const suggestedSuppliersData = suggestedSupplierResponse.data.data;

        // Process each item to determine top Suggested_Suppliers based on a point system and Quoted_On date
        const filter = responsefilter.map((item) => {
          const trimmedPartNumber = item.Part_Number?.trim();
          const trimmedItemName = item.Item_Name?.trim();

          // Track suppliers and points and their latest Quoted_On date
          const supplierInfo = {};

          // Function to add points to supplier and track latest Quoted_On date
          const addSupplierInfo = (Supplier_ID, points, date) => {
            if (!supplierInfo[Supplier_ID]) {
              supplierInfo[Supplier_ID] = { points: 0, latestQuotedOn: null };
            }
            supplierInfo[Supplier_ID].points += points;

            // Update latestQuotedOn if the new date is more recent
            if (date && (!supplierInfo[Supplier_ID].latestQuotedOn || date > supplierInfo[Supplier_ID].latestQuotedOn)) {
              supplierInfo[Supplier_ID].latestQuotedOn = date;
            }
          };

          // Check matches in supplierCatalogueData based on Part_Number and Item_Name
          supplierCatalogueData.forEach((supplier) => {
            const date = supplier.Quoted_On ? new Date(supplier.Quoted_On) : null;
            if (supplier.OEM_Part_No?.trim() === trimmedPartNumber) {
              addSupplierInfo(supplier.Supplier_ID, 3, date); // Higher points for Part_Number match
            } else if (supplier.Item_Name?.trim() === trimmedItemName) {
              addSupplierInfo(supplier.Supplier_ID, 1, date); // Lower points for Item_Name match
            }
          });

          // Check matches in suggestedSuppliersData and update points and Quoted_On date
          suggestedSuppliersData.forEach((supplier) => {
            const date = supplier.Quoted_On ? new Date(supplier.Quoted_On) : null;
            let points = 0;

            if (supplier.Part_Number?.trim() === trimmedPartNumber) {
              points = supplierInfo[supplier.Supplier_ID] ? 3 : 2; // Extra points if in both datasets
            } else if (supplier.Item_Name?.trim() === trimmedItemName) {
              points = 1; // Lower points for Item_Name match
            }

            if (points > 0) {
              addSupplierInfo(supplier.Supplier_ID, points, date);
            }
          });

          console.log("supplierInfo", supplierInfo);

          // Sort suppliers first by points, then by latest Quoted_On date
          const topSuppliers = Object.keys(supplierInfo)
            .sort((a, b) => {
              if (supplierInfo[b].points !== supplierInfo[a].points) {
                return supplierInfo[b].points - supplierInfo[a].points; // Descending by points
              }
              return supplierInfo[b].latestQuotedOn - supplierInfo[a].latestQuotedOn; // Descending by date
            })
            .slice(0, 3)
            .map((Supplier_ID) => supplierNameLookup[Supplier_ID])
            .join(", ");

          // Return the item with top suppliers
          return {
            ...item,
            Suggested_Suppliers: topSuppliers
          };
        });


        setsmResults(filter);
        console.log("smResults:", filter);
        console.log("Items Master:", resItemsMaster.data);
        setFormDatasm({
          data: filter,
        });
        setSNoToDelete([]);
        //Setting the Part_Number input to disabled 
        for (let i = 0; i < filter.length; i++) {
          setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [i]: true }));
        }
        setIsOEMDisabledAddRow(true);

        /*       
        //Adding items to Items Master List if they don't exist already based on Part Number
        const itemsToPost = [];

        filter.forEach((filterItem) => {
          // Normalize Manufacturer_Part_Number to null if it's an empty string
          const normalizedManufacturerPartNumber = filterItem.Manufacturer_Part_Number ? (filterItem.Manufacturer_Part_Number === "" ? null : filterItem.Manufacturer_Part_Number) : null;

          const matchingItem = resItemsMaster.data.find(
            (item) =>
              item.Part_Number === filterItem.Part_Number &&
              item.Item_Name === filterItem.Item_Name &&
              item.Manufacturer_Part_Number === normalizedManufacturerPartNumber
          );

          console.log("items master item", filterItem, matchingItem);

          if (!matchingItem) {
            // If matchingItem is not found, add it to the itemsToPost array
            itemsToPost.push({
              Item_Name: filterItem.Item_Name,
              Part_Number: filterItem.Part_Number,
              Manufacturer_Part_Number: normalizedManufacturerPartNumber,
            });
          }
        });

        // Perform POST requests for itemsToPost
        for (const itemToPost of itemsToPost) {
          try {
            const res = await axios.post(
              "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemmaster",
              itemToPost
            );
            console.log("Item added to itemsMaster:", itemToPost);
          } catch (error) {
            console.error("Error occurred while making POST request:", error);
          }
        }

        if (itemsToPost.length > 0) {
          const updatedItemsMaster = await axios.get(
            "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
          );
          setItemsMaster(updatedItemsMaster.data);
          console.log("Updated Items Master:", updatedItemsMaster.data)
        }
        */

      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    fetchsmDetails();
  }, [refresh]);


  const [loading, setLoading] = useState(false); //loading while excel/csv file uploads

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Set loading state to true
      setLoading(true);

      const readFilePromise = new Promise((resolve) => {
        const reader = new FileReader();

        reader.onloadstart = () => {
          console.log("File reading started");
        };

        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const firstSheet = workbook.SheetNames[0];
          const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet], { header: 1 });
          resolve(sheetData);
        };

        reader.onloadend = () => {
          console.log("File reading completed");
          // Set loading state to false when reading is complete
          setLoading(false);
        };

        reader.onerror = (error) => {
          console.error("File reading error:", error);
          // Set loading state to false in case of an error
          setLoading(false);
        };

        if (
          selectedFile.type === "application/vnd.ms-excel" ||
          selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          reader.readAsBinaryString(selectedFile);
        } else if (selectedFile.type === "text/csv") {
          reader.readAsText(selectedFile);
        } else {
          console.log("Invalid file type.");
        }
      });

      try {
        const fileContent = await readFilePromise;
        setFileContent(fileContent);
        setFilteredTable(fileContent.slice(1)); //remove header row from fileContent and store the data in filteredTable
        console.log("Filtered Table ", filteredTable);
        console.log("fileContent", fileContent);
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }
  };

  const handleDownloadExcelTemplate = (e) => {
    e.preventDefault();
    // Define header row with bold font
    const header = [
      { v: 'S. No', s: { bold: true } },
      { v: 'Item Name', s: { bold: true } },
      { v: 'Part Number', s: { bold: true } },
      { v: 'UoM', s: { bold: true } },
      { v: 'Inquired Qty', s: { bold: true } },
      // { v: 'Selected Suppliers', s: { bold: true } },
      { v: 'Remarks', s: { bold: true } },
    ];

    // Create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([header]);

    // Set column widths
    const columnWidths = [
      { wch: 5 }, // S. No
      { wch: 20 }, // Item Name
      { wch: 20 }, // Part Number
      { wch: 5 }, // UoM
      { wch: 15 }, // Inquired Qty
      // { wch: 30 }, // Selected Suppliers
      { wch: 35 }, // Remarks
    ];
    worksheet['!cols'] = columnWidths;


    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Spares Trading Item Details');

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'SparesTradingItemDetails.xlsx');
  };


  useEffect(() => {
    const body = document.querySelector("body");
    if (fileContent.length !== 0) {
      body.classList.add(styles.noScroll);
    } else {
      body.classList.remove(styles.noScroll);
    }

    return () => {
      body.classList.remove(styles.noScroll);
    };
  }, [fileContent]);

  const handleCancel = () => {
    setFileContent([]);
  };

  const [filteredTable, setFilteredTable] = useState([]); //used for storing the uploaded excel file data, with 1 row removed from the top for header

  let payl;
  const store = filteredTable.map((el, index) => {
    payl = {
      Spares_Inquiry_ID: id,
      Item_Name: el[1],
      Part_Number: el[2],
      UoM: el[3],
      Inquired_Qty: el[4],
      // Selected_Suppliers: el[5],
      Remarks: el[5],
    };
  });
  console.log(payl);

  const handlesubmittt = async () => {
    try {
      const responses = [];

      for (const el of filteredTable) {
        const payload = {
          Spares_Inquiry_ID: id,
          Item_Name: el[1],
          Part_Number: el[2],
          UoM: el[3],
          Inquired_Qty: el[4],
          // Selected_Suppliers: el[5],
          Remarks: el[5],
        };

        const res = await axios.post(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_itemdetails_post",
          payload
        );
        responses.push(res);
      }

      const allSuccessful = responses.every((res) => res.status === 200);

      if (allSuccessful) {
        alert("Added all data from file successfully");
        setRefresh(!refresh);
        setHeightRefresh(!heightRefresh);
      }

      setFormDataAddRow({
        Item_Name: "",
        Part_Number: "",
        UoM: "",
        Inquired_Qty: "",
        // Selected_Suppliers: "",
        Remarks: "",
      });

    } catch (error) {
      console.error("Error submitting data:", error);
    }

    setRefresh(!refresh);
    setHeightRefresh(!heightRefresh);
    setFileContent([]);
    setOpen(false);
  };

  const maxColumns = 5;    // excel/csv file upload number of columns to be displayed in modal

  const [open, setOpen] = useState(false);    // to open or close the Modal on clicking "Import using Excel"
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isDropdownVisible, setDropdownVisible] = useState(false);    //used to control dropdown visibility under Item Name input in editable state, editModesm
  const [isPartDropdownVisible, setPartDropdownVisible] = useState(false);    //used to control dropdown visibility under Part Number input in editable state, editModesm
  const [isOEMDisabled, setIsOEMDisabled] = useState([]);   //used to control the disabled state of the OEM Part Number input in editable state, editModesm
  const [isSelectedSuppliersDropdownVisible, setSelectedSuppliersDropdownVisible] = useState(false);    //used to control dropdown visibility for Suppliers in editable state, editModesm

  const [isDropdownVisibleAddRow, setDropdownVisibleAddRow] = useState(false);    //used to control dropdown visibility under Item Name input when "Add Items Manually" is clicked, showInputRow state true
  const [isPartDropdownVisibleAddRow, setPartDropdownVisibleAddRow] = useState(false);    //used to control dropdown visibility under Part Number input when "Add Items Manually" is clicked, showInputRow state true
  const [isOEMDisabledAddRow, setIsOEMDisabledAddRow] = useState(true);    //used to control the disabled state of the OEM Part Number input when "Add Items Manually" is clicked, showInputRow state true
  const [isSelectedSuppliersDropdownAddRowVisible, setSelectedSuppliersDropdownAddRowVisible] = useState(false);    //used to control dropdown visibility for Suppliers when "Add Items Manually" is clicked, showInputRow state true

  console.log("isOEMDisabled", isOEMDisabled);

  const [initialHeight, setInitialHeight] = useState();   // State variable to track the initial height of the container
  const [heightRefresh, setHeightRefresh] = useState(false);    //to refresh the height of smtablecontainer div which contains the table and dropdowns
  const [containerStyle, setContainerStyle] = useState({});   // State variable to store container style

  const [dropdownSelectHeightRefresh, setDropdownSelectHeightRefresh] = useState(false);    //to explicitly refresh height after dropdown selection which wasn't working normally
  const [dropdownSelectHeightRefreshTrigger, setDropdownSelectHeightRefreshTrigger] = useState(false);    //to trigger height refresh after dropdown selection is done

  useEffect(() => {
    setDropdownSelectHeightRefresh(!dropdownSelectHeightRefresh);
  }, [dropdownSelectHeightRefreshTrigger]);

  // useEffect(() => {
  //   // Delay execution slightly to allow for DOM rendering
  //   const timerId = setTimeout(() => {
  //     // Get the smtablecontainer element
  //     const container = document.querySelector(`.${styles.smtablecontainer}`);
  //     if (container) {
  //       // Set the initial height if the container exists
  //       var initialHeight = container.offsetHeight;
  //       setInitialHeight(initialHeight);
  //             }
  //   }, 800); // Delay in milliseconds

  //   return () => clearTimeout(timerId); // Cleanup function to clear the timer
  // }, [heightRefresh]);

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const observer = new ResizeObserver((entries) => {
      // Since there might be multiple entries, we take the first one
      const newHeight = entries[0].contentRect.height;
      setInitialHeight(newHeight);
    });

    observer.observe(container);

    return () => observer.disconnect();
  }, [heightRefresh]);

  console.log("initialHeight", initialHeight);

  useEffect(() => {
    // Calculate the new height based on the initial height and whether the dropdowns are visible
    const newHeight = (
      (isDropdownVisible ||
        isDropdownVisibleAddRow ||
        isPartDropdownVisible ||
        isPartDropdownVisibleAddRow ||
        isSelectedSuppliersDropdownVisible ||
        isSelectedSuppliersDropdownAddRowVisible
      )
        ? (initialHeight + 270) : (initialHeight + 20)
    );
    // const newHeightInt = parseInt(newHeight);

    console.log("newHeight", newHeight);
    setContainerStyle({ height: newHeight });
  }, [isDropdownVisible, isDropdownVisibleAddRow, isPartDropdownVisible, isPartDropdownVisibleAddRow, isSelectedSuppliersDropdownVisible, isSelectedSuppliersDropdownAddRowVisible, initialHeight, showInputRow, editModesm]);

  console.log("isDropdownVisible, isDropdownVisibleAddRow", isDropdownVisible, isDropdownVisibleAddRow);
  console.log("isPartDropdownVisible, isPartDropdownVisibleAddRow", isPartDropdownVisible, isPartDropdownVisibleAddRow);
  console.log("ppppppppp", containerStyle);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.getElementById('myDropdown');

      // Check if the clicked element is inside the dropdown
      if (dropdown && !dropdown.contains(event.target)) {
        // If the clicked element is outside the dropdown, hide it
        setDropdownVisible(false);
        setDropdownVisibleAddRow(false);
        setPartDropdownVisible(false);
        setPartDropdownVisibleAddRow(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleAddToItemsMaster = (index) => {
    // const newData = [...formDatasm.data];
    // newData[index] = { ...newData[index], Part_Number: "" };
    // setFormDatasm({ data: newData });
    setDropdownVisible(false);
    setPartDropdownVisible(false);
    setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [index]: false }));
  };

  const handleAddToItemsMasterAddRow = () => {
    setFormDataAddRow((prev) => ({
      ...prev,
      Item_Name: formDataAddRow.Item_Name,
      Part_Number: formDataAddRow.Part_Number || "",
    }));
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setIsOEMDisabledAddRow(false);
  };


  return (
    <div>
      <LoadingScreen isLoading={isLoading} />

      <TabsBarSparesInquiry activeTab="Item Details" />

      <div className={styles.sections2}>
        <div className={styles.buttonsdiv}
          style={{
            border: "2px solid #E7EBFF",
            borderTop: "none",
            borderBottom: "none",
          }}>
          {showeditRow ? (
            <>

              <button
                variant="contained"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                Save Changes
              </button>
              <button
                variant="contained"
                className={styles.cancel}
                onClick={handlecancelsm}>
                Cancel
              </button>
            </>
          ) : (
            <>

              <button
                variant="contained"
                onClick={handleeditsm}>
                Edit
              </button>
              <button
                variant="contained"
                onClick={setOpen}
              >
                Import using excel
                <svg viewBox="0 0 50 50" width="20px" height="20px" style={{ marginLeft: "8px" }}>
                  <path d="M 28.8125 0.03125 L 0.8125 5.34375 C 0.339844 5.433594 0 5.863281 0 6.34375
                           L 0 43.65625 C 0 44.136719 0.339844 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 28.875
                           49.980469 28.9375 50 29 50 C 29.230469 50 29.445313 49.929688 29.625 49.78125 C 29.855469 49.589844 30 49.296875
                           30 49 L 30 1 C 30 0.703125 29.855469 0.410156 29.625 0.21875 C 29.394531 0.0273438 29.105469 -0.0234375 28.8125 0.03125 Z M 32 6 L 32 13 L 34 13 L 34 15 L 32 15 L 32 20 L 34 20 L 34 22 L 32 22 L 32 27 L 34 27 L 34 29 L 32 29 L 32 35 L 34 35 L 34 37 L 32 37 L 32 44 L 47 44 C 48.101563 44 49 43.101563 49 42 L 49 8 C 49 6.898438 48.101563 6 47 6 Z M 36 13 L 44 13 L 44 15 L 36 15 Z M 6.6875 15.6875 L 11.8125 15.6875 L 14.5 21.28125 C 14.710938 21.722656 14.898438 22.265625 15.0625 22.875 L 15.09375 22.875 C 15.199219 22.511719 15.402344 21.941406 15.6875 21.21875 L 18.65625 15.6875 L 23.34375 15.6875 L 17.75 24.9375 L 23.5 34.375 L 18.53125 34.375 L 15.28125 28.28125 C 15.160156 28.054688 15.035156 27.636719 14.90625 27.03125 L 14.875 27.03125 C 14.8125 27.316406 14.664063 27.761719 14.4375 28.34375 L 11.1875 34.375 L 6.1875 34.375 L 12.15625 25.03125 Z M 36 20 L 44 20 L 44 22 L 36 22 Z M 36 27 L 44 27 L 44 29 L 36 29 Z M 36 35 L 44 35 L 44 37 L 36 37 Z"
                    fill="white" />
                </svg>
              </button> 
            </>
          )}
        </div>

        {fileContent.length === 0 && (
          <CustomModal
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
            }}>
            <Fade in={open}>
              <Box className={styles.ModalBox}>
                <div>
                  <svg
                    onClick={handleClose}
                    className={styles.crossButton}
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.9499 22.3641L9.63615 11.0504C9.2496 10.6638 9.2496 10.0227 9.63615 9.63618C10.0227 9.24963 10.6638 9.24963 11.0504 9.63618L22.3641 20.9499C22.7506 21.3364 22.7506 21.9776 22.3641 22.3641C21.9775 22.7507 21.3364 22.7507 20.9499 22.3641Z"
                      fill="black"
                    />
                    <path
                      d="M9.63592 22.3641C9.24937 21.9776 9.24937 21.3364 9.63592 20.9499L20.9496 9.63618C21.3362 9.24963 21.9773 9.24963 22.3638 9.63618C22.7504 10.0227 22.7504 10.6638 22.3638 11.0504L11.0501 22.3641C10.6636 22.7507 10.0225 22.7507 9.63592 22.3641Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className={styles.iconAndText}>
                  <div className={styles.downloadText}>
                    Download Excel Template
                  </div>

                  <div className={styles.fileInputContainer} onClick={(e) => handleDownloadExcelTemplate(e)}>
                    <div style={{ padding: "5px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="30px"
                        height="30px">
                        <path
                          fill="#169154"
                          d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"
                        />
                        <path
                          fill="#18482a"
                          d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"
                        />
                        <path
                          fill="#0c8045"
                          d="M14 15.003H29V24.005000000000003H14z"
                        />
                        <path fill="#17472a" d="M14 24.005H29V33.055H14z" />
                        <g>
                          <path
                            fill="#29c27f"
                            d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"
                          />
                          <path
                            fill="#27663f"
                            d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"
                          />
                          <path
                            fill="#19ac65"
                            d="M29 15.003H44V24.005000000000003H29z"
                          />
                          <path fill="#129652" d="M29 24.005H44V33.055H29z" />
                        </g>
                        <path
                          fill="#0c7238"
                          d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
                        />
                        <path
                          fill="#fff"
                          d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"
                        />
                      </svg>
                    </div>
                    <div className={styles.downloadIcon}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className={styles.orText}>or</div>

                  <div
                    onClick={() => {
                      document.getElementById("fileInput").click();
                    }}
                    style={{ marginBottom: "30px" }}
                    className={styles.fileInputContainer}>
                    <input
                      id="fileInput"
                      type="file"
                      accept=".xls, .xlsx, .csv"
                      onChange={handleFileChange}
                      className={styles.hiddenInput}
                    />
                    <div className={styles.selectFileText}>Select From Device</div>
                    <div className={styles.downloadIcon}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.19 22H7.81C4.17 22 2 19.83 2 16.19V7.82C2 4.17 4.17 2 7.81 2H16.18C19.82 2 21.99 4.17 21.99 7.81V16.19C22 19.83 19.83 22 16.19 22ZM8.47 13.02C8.76 13.31 9.24 13.31 9.53 13.02L11.25 11.3V17.49C11.25 17.9 11.59 18.24 12 18.24C12.41 18.24 12.75 17.9 12.75 17.49V11.3L14.47 13.02C14.76 13.31 15.24 13.31 15.53 13.02C15.82 12.73 15.82 12.25 15.53 11.96L12.53 8.96C12.46 8.89 12.38 8.84 12.29 8.8C12.2 8.76 12.1 8.74 12 8.74C11.9 8.74 11.81 8.76 11.71 8.8C11.62 8.84 11.54 8.89 11.47 8.96L8.47 11.96C8.18 12.25 8.18 12.72 8.47 13.02ZM18.24 6.78C16.23 6.11 14.12 5.77 12 5.77C9.88 5.77 7.77 6.11 5.76 6.78C5.37 6.91 5.16 7.34 5.29 7.73C5.42 8.12 5.84 8.34 6.24 8.2C9.96 6.96 14.05 6.96 17.77 8.2C18.16 8.33 18.59 8.12 18.72 7.73C18.84 7.33 18.63 6.91 18.24 6.78Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </Box>
            </Fade>
          </CustomModal>
        )}

        {fileContent.length > 0 && (
          <div className={styles.uploadedFile}>
            <div className={styles.containerFile}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", color: "#5B6BE1" }}>
                <h5 style={{ fontWeight: "700" }}>
                  Uploaded Data
                </h5>
              </div>

              <div className={styles.buttonsdiv}>
                <button
                  variant="contained"
                  className={styles.cancel}
                  onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  variant="contained"
                  onClick={handlesubmittt}>
                  Submit
                </button>
              </div>

              <div className={styles.uploadedFileContainer}>
                <table>
                  <thead>
                    <tr>
                      <th className={styles.fileUploadheading}>S.No</th>
                      <th className={styles.fileUploadheading}>Item Name</th>
                      <th className={styles.fileUploadheading}>Part Number</th>
                      <th className={styles.fileUploadheading}>UoM</th>
                      <th className={styles.fileUploadheading}>Inquired Qty</th>
                      <th className={styles.fileUploadheading}>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTable.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {Array.from({ length: maxColumns }).map(
                          (cell, idx) => (
                            <td key={idx}>
                              {idx < el.length ? ( el[idx+1] ? el[idx+1] : "-") : "-"}
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        <div>
          <div className={styles.smtablecontainer} style={containerStyle}>
            <table ref={containerRef} className={styles.smtable}>
              <thead>
                <tr className={styles.headrowsm}>
                  {showeditRow && <th></th>}
                  {showInputRow && !editModesm && <th></th>}
                  <th className={styles.fileUploadheading}>S.No</th>
                  <th className={styles.fileUploadheading}>Item Name</th>
                  <th className={styles.fileUploadheading}>Part Number</th>
                  <th className={styles.fileUploadheading}>UoM</th>
                  <th className={styles.fileUploadheading}>Inquired Qty</th>
                  <th className={styles.fileUploadheading}>Suggested Suppliers</th>
                  <th className={styles.fileUploadheading}>Selected Suppliers</th>
                  <th className={styles.fileUploadheading}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {/* {rows.map((row) => row)} */}
                {smResults.map((sm, index) => {

                  // Parse the already selected Supplier_IDs for this row
                  const selectedSupplierIDs = formDatasm.data[index]?.Selected_Suppliers ? formDatasm.data[index].Selected_Suppliers.split(',') : [];

                  // Filter out suppliers that are already selected for this row
                  const availableSuppliers = suppliersList.filter(
                    supplier => !selectedSupplierIDs.includes(supplier.Supplier_ID)
                  );

                  // Create options for the select dropdown with Supplier_Name visible, but Supplier_ID as the value
                  const supplierOptions = availableSuppliers.map(supplier => ({
                    value: supplier.Supplier_ID,
                    label: supplier.Supplier_Name,
                    Supplier_ID: supplier.Supplier_ID // Adding Supplier_ID for easy reference
                  }));

                  // Create the array of selected suppliers for the current row to show in the multi-select dropdown
                  const selectedSuppliers = suppliersList
                    .filter(supplier => selectedSupplierIDs.includes(supplier.Supplier_ID))
                    .map(supplier => ({
                      value: supplier.Supplier_ID,
                      label: supplier.Supplier_Name,
                      Supplier_ID: supplier.Supplier_ID
                    }));

                  return (
                    <tr key={sm.S_No}>
                      {showeditRow && (
                        <td>
                          <div
                            className={styles.removeTxtBtn}
                            onClick={(e) => handleOpenRemoveConfirmationModal(e, sm.S_No, sm.Item_Name, sm.Part_Number)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none">
                              <path
                                d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                fill="#567191"
                              />
                            </svg>
                            Remove
                          </div>
                        </td>
                      )}
                      {showInputRow && !editModesm && <td></td>}
                      <td>{index + 1}</td>
                      <td>
                        {editModesm ? (
                          <>
                            <div style={{ position: "relative" }}>
                              <input
                                type="text"
                                className={styles.inputFieldsm}
                                name="Item_Name"
                                id="inputItemName"
                                value={formDatasm.data[index].Item_Name}
                                onChange={(e) => {
                                  handleInputChange(e, index);
                                  // setDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                                }}
                              />
                              {/* {isDropdownVisible[index] && formDatasm.data[index].Item_Name && (
                                <div id="myDropdown" className={styles.dropdown}>
                                  {itemsMaster
                                    .filter((item) =>
                                      item.Item_Name && item.Item_Name.toLowerCase().includes((formDatasm.data[index].Item_Name || '').toLowerCase())
                                    )
                                    .map((item) => (
                                      <div
                                        key={item.Item_Id}
                                        onClick={() => handleDropdownSelection(item, index)}
                                        className={styles.dropdownItem}>
                                        {item.Item_Name}
                                      </div>
                                    ))}
                                  <div
                                    className={styles.dropdownItem}
                                    style={{ color: "#567191", fontWeight: "700" }}
                                    onClick={() => handleAddToItemsMaster(index)}>
                                    {`+ Add "${formDatasm.data[index].Item_Name}" to Items Master List`}
                                  </div>
                                </div>
                              )} */}
                            </div>
                          </>
                        ) : sm.Item_Name ? (
                          sm.Item_Name
                        ) : (
                          "-"
                        )}
                      </td>

                      <td>
                        {editModesm ? (
                          <>
                            <div style={{ position: "relative" }}>
                              <input
                                type="text"
                                className={styles.inputFieldsm}
                                name="Part_Number"
                                value={formDatasm.data[index].Part_Number}
                                onChange={(e) => {
                                  handleInputChange(e, index);
                                  // setPartDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                                }}
                              />
                              {/* {isPartDropdownVisible[index] && formDatasm.data[index].Part_Number && (
                                <div id="myDropdown" className={styles.dropdown}>
                                  {itemsMaster
                                    .filter((item) =>
                                      item.Part_Number && item.Part_Number.toLowerCase().includes((formDatasm.data[index].Part_Number || '').toLowerCase())
                                    )
                                    .map((item) => (
                                      <div
                                        key={item.Item_Id}
                                        onClick={() => handleDropdownSelection(item, index)}
                                        className={styles.dropdownItem}>
                                        {item.Part_Number}
                                      </div>
                                    ))}
                                  <div
                                    className={styles.dropdownItem}
                                    style={{ color: "#567191", fontWeight: "700" }}
                                    onClick={() => handleAddToItemsMaster(index)}>
                                    {`+ Add "${formDatasm.data[index].Part_Number}" to Items Master List`}
                                  </div>
                                </div>
                              )} */}
                            </div>
                          </>
                        ) : sm.Part_Number ? (
                          sm.Part_Number
                        ) : (
                          "-"
                        )}
                      </td>

                      {/* <td>
                      {editModesm ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="Part_Number"
                          value={formDatasm.data[index].Part_Number}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled={isOEMDisabled[index]}
                        />
                      ) : sm.Part_Number ? (
                        sm.Part_Number
                      ) : (
                        "-"
                      )}
                    </td> */}
                      <td>
                        {editModesm ? (
                          <>
                            <select
                              className={styles.inputFieldsm}
                              style={{ paddingRight: "30px" }}
                              name="UoM"
                              value={formDatasm.data[index].UoM}
                              onChange={(e) => handleInputChange(e, index)}>
                              <option value="">Select Unit</option>
                              <option value="pcs">pcs</option>
                              <option value="kg">kg</option>
                              <option value="mm">mm</option>
                              <option value="inch">inch</option>
                              <option value="m">m</option>
                            </select>
                            <svg
                              style={{ marginLeft: "-25px", marginTop: "-1%", pointerEvents: "none" }}
                              width="24"
                              height="24"
                              viewBox="0 0 28 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                fill="black"
                              />
                            </svg>
                          </>
                        ) : sm.UoM ? (
                          sm.UoM
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {editModesm ? (
                          <input
                            type="text"
                            className={styles.inputFieldsm}
                            style={{ width: "80px" }}
                            name="Inquired_Qty"
                            value={formDatasm.data[index].Inquired_Qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        ) : sm.Inquired_Qty ? (
                          sm.Inquired_Qty
                        ) : (
                          "-"
                        )}
                      </td>

                      <td>
                        {editModesm ? (
                          <textarea
                            type="text"
                            className={styles.inputFieldsm}
                            style={{ width: "200px", height: "auto", marginTop: "5px", resize: "none", whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
                            name="Suggested_Suppliers"
                            value={formDatasm.data[index].Suggested_Suppliers}
                            onChange={(e) => handleInputChange(e, index)}
                            disabled
                          />
                        ) : sm.Suggested_Suppliers ? (
                          sm.Suggested_Suppliers
                        ) : (
                          "-"
                        )}
                      </td>

                      {/* <td>
                        {editModesm ? (
                          <input
                            type="text"
                            className={styles.inputFieldsm}
                            name="Selected_Suppliers"
                            value={formDatasm.data[index].Selected_Suppliers}
                            onChange={(e) => handleInputChange(e, index)}
                            disabled
                          />
                        ) : sm.Selected_Suppliers ? (
                          sm.Selected_Suppliers
                        ) : "-"
                        }
                      </td> */}

                      <td>
                        {editModesm ? (
                          <Select
                            isMulti
                            value={selectedSuppliers}
                            options={supplierOptions}
                            onChange={(selectedOptions) => handleSupplierChange(selectedOptions, index)}
                            placeholder="Select Suppliers"
                            onMenuOpen={() => setSelectedSuppliersDropdownVisible(true)}
                            onMenuClose={() => setSelectedSuppliersDropdownVisible(false)}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid #E7EBFF",
                                boxShadow: "none",
                                cursor: "pointer",
                                overflowY: "hidden",
                              }),
                              clearIndicator: (provided) => ({
                                ...provided,
                                display: "none", // Hide the clear indicator
                              }),
                              menuList: (provided) => ({
                                ...provided,
                                maxHeight: "200px",
                                overflowY: "scroll", // Enable vertical scrolling
                                scrollbarWidth: "thin",
                                scrollbarColor: "#c5c5c5 #f5f5f5",
                                zIndex: "100"
                              }),
                            }}
                          />
                        ) :
                          sm.Selected_Suppliers ? (
                            sm.Selected_Suppliers.split(',').map(supplierID => {
                              const supplier = suppliersList.find(s => s.Supplier_ID == supplierID);
                              return supplier ? supplier.Supplier_Name : supplierID;
                            }).join(', ')
                          ) : (
                            "-"
                          )}
                      </td>

                      <td>
                        {editModesm ? (
                          <input
                            type="text"
                            className={styles.inputFieldsm}
                            name="Remarks"
                            value={formDatasm.data[index].Remarks}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        ) : sm.Remarks ? (
                          sm.Remarks
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
                {showInputRow && !editModesm && (
                  <tr key={rows.length} className={styles.addinputspares}>
                    <td className={styles.tickcross}>
                      <svg
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={handleSubmitsm}
                        disabled={isLoading}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                          fill="#66D171"
                        />
                      </svg>
                      <svg
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={handleDeleteRow}>
                        <path
                          d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                          fill="#F97575"
                        />
                        <path
                          d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                          fill="#F97575"
                        />
                      </svg>
                    </td>

                    <td></td>

                    <td>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="Item_Name"
                          value={formDataAddRow.Item_Name}
                          onChange={(e) => {
                            handleInputChangevis(e);
                            // setDropdownVisibleAddRow(true); // Show the dropdown when typing
                          }}
                        />
                        {/* {isDropdownVisibleAddRow && formDataAddRow.Item_Name && (
                          <div id="myDropdown" className={styles.dropdown}>
                            {itemsMaster
                              .filter((item) =>
                                item.Item_Name && item.Item_Name.toLowerCase().includes((formDataAddRow.Item_Name || '').toLowerCase())
                              )
                              .map((item) => (
                                <div
                                  key={item.Item_Id}
                                  onClick={() => handleDropdownSelectionAddRow(item)}
                                  className={styles.dropdownItem}>
                                  {item.Item_Name}
                                </div>
                              ))}
                            <div
                              className={styles.dropdownItem}
                              style={{ color: "#567191", fontWeight: "700" }}
                              onClick={handleAddToItemsMasterAddRow}>
                              {`+ Add "${formDataAddRow.Item_Name}" to Items Master List`}
                            </div>
                          </div>
                        )} */}
                      </div>
                    </td>

                    <td>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="Part_Number"
                          value={formDataAddRow.Part_Number}
                          onChange={(e) => {
                            handleInputChangevis(e);
                            // setPartDropdownVisibleAddRow(true); // Show the dropdown when typing
                          }}
                        />
                        {/* {isPartDropdownVisibleAddRow && formDataAddRow.Part_Number && (
                          <div id="myDropdown" className={styles.dropdown}>
                            {itemsMaster
                              .filter((item) =>
                                item.Part_Number && item.Part_Number.toLowerCase().includes((formDataAddRow.Part_Number || '').toLowerCase())
                              )
                              .map((item) => (
                                <div
                                  key={item.Item_Id}
                                  onClick={() => handleDropdownSelectionAddRow(item)}
                                  className={styles.dropdownItem}>
                                  {item.Part_Number}
                                </div>
                              ))}
                            <div
                              className={styles.dropdownItem}
                              style={{ color: "#567191", fontWeight: "700" }}
                              onClick={handleAddToItemsMasterAddRow}>
                              {`+ Add "${formDataAddRow.Part_Number}" to Items Master List`}
                            </div>
                          </div>
                        )} */}
                      </div>
                    </td>

                    <td>
                      <select
                        className={styles.inputFieldsm}
                        style={{ paddingRight: "30px" }}
                        name="UoM"
                        value={formDataAddRow.UoM}
                        onChange={handleInputChangevis}>
                        <option value="">Select Unit</option>
                        <option value="pcs">pcs</option>
                        <option value="kg">kg</option>
                        <option value="mm">mm</option>
                        <option value="inch">inch</option>
                        <option value="m">m</option>
                      </select>
                      <svg
                        style={{ marginLeft: "-25px", marginTop: "-1%", pointerEvents: "none" }}
                        width="24"
                        height="24"
                        viewBox="0 0 28 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        style={{ width: "80px" }}
                        name="Inquired_Qty"
                        value={formDataAddRow.Inquired_Qty}
                        onChange={handleInputChangevis}
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        style={{ width: "80px" }}
                        name="Suggested_Suppliers"
                        value={formDataAddRow.Suggested_Suppliers}
                        onChange={handleInputChangevis}
                        disabled
                      />
                    </td>

                    <td>
                      {(() => {
                        // Parse the already selected Supplier_IDs for the add row
                        const selectedSupplierIDs = formDataAddRow.Selected_Suppliers
                          ? formDataAddRow.Selected_Suppliers.split(',')
                          : [];

                        // Filter out suppliers that are already selected for the add row
                        const availableSuppliers = suppliersList.filter(
                          supplier => !selectedSupplierIDs.includes(supplier.Supplier_ID)
                        );

                        // Create options for the select dropdown with Supplier_Name visible, but Supplier_ID as the value
                        const supplierOptions = availableSuppliers.map(supplier => ({
                          value: supplier.Supplier_ID,
                          label: supplier.Supplier_Name,
                          Supplier_ID: supplier.Supplier_ID
                        }));

                        // Create the array of selected suppliers for the current add row to show in the multi-select dropdown
                        const selectedSuppliers = suppliersList
                          .filter(supplier => selectedSupplierIDs.includes(supplier.Supplier_ID))
                          .map(supplier => ({
                            value: supplier.Supplier_ID,
                            label: supplier.Supplier_Name,
                            Supplier_ID: supplier.Supplier_ID
                          }));

                        return (
                          <Select
                            isMulti
                            value={selectedSuppliers} // The selected suppliers for the add row
                            options={supplierOptions} // The available supplier options
                            onChange={handleSupplierChangeAddRow} // Handle changes to the selection
                            placeholder="Select Suppliers"
                            className={styles.textbox2z} // Your custom styling
                            onMenuOpen={() => setSelectedSuppliersDropdownAddRowVisible(true)}
                            onMenuClose={() => setSelectedSuppliersDropdownAddRowVisible(false)}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid #E7EBFF",
                                boxShadow: "none",
                                cursor: "pointer",
                                overflowY: "hidden",
                              }),
                              clearIndicator: (provided) => ({
                                ...provided,
                                display: "none", // Hide the clear indicator
                              }),
                              menuList: (provided) => ({
                                ...provided,
                                maxHeight: "200px",
                                overflowY: "scroll", // Enable vertical scrolling
                                scrollbarWidth: "thin",
                                scrollbarColor: "#c5c5c5 #f5f5f5",
                              }),
                            }}
                          />
                        );
                      })()}
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        name="Remarks"
                        value={formDataAddRow.Remarks}
                        onChange={handleInputChangevis}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {!isButtonDisabled &&
            <button
              className={styles.manualbuttonsm}
              onClick={handleManualInputClick}
              disabled={isButtonDisabled}>
              Add Items Manually
              <svg
                style={{ marginLeft: "10px" }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.493 1.66669H6.50964C3.4763 1.66669 1.66797 3.47502 1.66797 6.50835V13.4834C1.66797 16.525 3.4763 18.3334 6.50964 18.3334H13.4846C16.518 18.3334 18.3263 16.525 18.3263 13.4917V6.50835C18.3346 3.47502 16.5263 1.66669 13.493 1.66669ZM15.0013 10.625H10.6263V15C10.6263 15.3417 10.343 15.625 10.0013 15.625C9.65964 15.625 9.3763 15.3417 9.3763 15V10.625H5.0013C4.65964 10.625 4.3763 10.3417 4.3763 10C4.3763 9.65835 4.65964 9.37502 5.0013 9.37502H9.3763V5.00002C9.3763 4.65835 9.65964 4.37502 10.0013 4.37502C10.343 4.37502 10.6263 4.65835 10.6263 5.00002V9.37502H15.0013C15.343 9.37502 15.6263 9.65835 15.6263 10C15.6263 10.3417 15.343 10.625 15.0013 10.625Z"
                  fill={isButtonDisabled ? '#5B6BE1' : 'white'}
                />
              </svg>
            </button>
          }
        </div>

        <div style={{ position: "", zIndex: "2" }}>
          <Modal
            open={openRemoveConfirmationModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              backdropFilter: "blur(4px)",
            }}
          >
            <Fade in={openRemoveConfirmationModal}>
              <Box
                sx={{
                  width: 440,
                  height: "auto",
                  p: 3,
                  backgroundColor: "#fff",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginLeft: "50px"  // Change justifyContent to space-between
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <svg
                      width="25"
                      height="25"
                      style={{ display: "flex" }}
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 47.5 47.5"
                      viewBox="0 0 47.5 47.5"
                      id="warning"
                    >
                      <defs>
                        <clipPath id="a">
                          <path d="M0 38h38V0H0v38Z"></path>
                        </clipPath>
                      </defs>
                      <g clip-path="url(#a)" transform="matrix(1.25 0 0 -1.25 0 47.5)">
                        <path
                          fill="#ffcc4d"
                          d="M0 0c-1.842 0-2.654 1.338-1.806 2.973l15.609 30.055c.848 1.635 2.238 1.635 3.087 0L32.499 2.973C33.349 1.338 32.536 0 30.693 0H0Z"
                          transform="translate(3.653 2)"
                        ></path>
                        <path
                          fill="#231f20"
                          d="M0 0c0 1.302.961 2.108 2.232 2.108 1.241 0 2.233-.837 2.233-2.108v-11.938c0-1.271-.992-2.108-2.233-2.108-1.271 0-2.232.807-2.232 2.108V0Zm-.187-18.293a2.422 2.422 0 0 0 2.419 2.418 2.422 2.422 0 0 0 2.419-2.418 2.422 2.422 0 0 0-2.419-2.419 2.422 2.422 0 0 0-2.419 2.419"
                          transform="translate(16.769 26.34)"
                        ></path>
                      </g>
                    </svg>
                    <h3
                      style={{
                        textAlign: "center",
                        font: "default",
                        fontWeight: "600",
                        fontSize: "25px",
                        marginLeft: "30px",
                      }}
                    >
                      CONFIRMATION
                    </h3>
                  </div>
                  {/* <button
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      marginLeft: "0px",
                      marginTop: "2px",
                      font: "default",
                      fontWeight: "600",
                      fontSize: "25px",
                    }}
                    onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                  >
                    x
                  </button> */}
                  <svg
                    width="20"
                    onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                    style={{
                      marginLeft: "0px",
                      cursor: "pointer",
                    }}
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="black"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>Are you sure you want to remove this item?</h2>
                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>
                  Item Name: &nbsp;
                  <label style={{ fontSize: "17px", fontWeight: "700" }}>
                    {confirmationData.ItemName}
                  </label>
                </h2>
                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "15px" }}>
                  Part Number:  &nbsp;
                  <label style={{ fontSize: "17px", fontWeight: "700" }}>
                    {confirmationData.PartNumber}
                  </label>
                </h2>


                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#5b6be1",
                      border: "1px solid #5b6be1",
                      borderRadius: "6px",
                      justifyContent: "center",
                      minWidth: "74px",
                      fontWeight: 600,
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                    onClick={(e) => handleRemove(e, confirmationData.extractedSNo)}
                  >
                    Yes
                  </button>
                  <button
                    style={{
                      backgroundColor: "white",
                      color: "#5b6be1",
                      border: "1px solid #5b6be1",
                      borderRadius: "6px",
                      justifyContent: "center",
                      minWidth: "74px",
                      fontWeight: 600,
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                    onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                  >
                    No
                  </button>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default SparesTradingInquiryItemDetails;