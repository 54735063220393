import { useState } from "react";
import { ProfessionalExp } from "./professionalExp";
import ProfessionalSummary from "./ProfessionalSummary";
import { ProfessionalDetails_EditDetails } from "./ProfessionalDetails_EditDetails"
import { ProfessionalDetails_EditDocs } from "./ProfessionalDetails_EditDocs"


export const MainComponent = () => {
    const [activeTab, setActiveTab] = useState("Details");

    const renderContent = () => {
        switch (activeTab) {
            case "Experience":
                return <ProfessionalExp activeTab={activeTab} setActiveTab={setActiveTab} />;
            case "Docs":
                return <ProfessionalDetails_EditDocs activeTab={activeTab} setActiveTab={setActiveTab} />;
            case "Details":
                return <ProfessionalDetails_EditDetails activeTab={activeTab} setActiveTab={setActiveTab} />;
            case "Projects":
                return <ProfessionalSummary activeTab={activeTab} setActiveTab={setActiveTab} />;
            default:
                return null;
        }
    };

    return (
        <>
            {renderContent()}
        </>
    );
};
