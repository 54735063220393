import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./css/workshopprojects.module.css";
import { Button, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { NavLink } from "react-router-dom";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Workshopprojects() {
  const [searchQuery, setSearchQuery] = useState("");
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [filterContainer, setFilterContainer] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});
  const [locationFilter, setLocationFilter] = useState([]);
  const [teamFilter, setTeamFilter] = useState([]);
  const [ponoFilter, setPonoFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const { id } = useParams;
  const [storeFilter, setStoreFilter] = useState([]);
  const [scopeFilter, setScopeFilter] = useState([]);
  const [costingIdFilter, setCostingIdFilter] = useState([]);
  const [poNoFilter, setPoNoFilter] = useState([]);
  const [projectCheckList, setProjectCheckList] = useState([]);
  const [newArrScope, setNewArrScope] = useState([]);
  const [newArrCostingId, setNewArrCostingId] = useState([]);
  const [newArrPoNo, setNewArrPoNo] = useState([]);
  const [newArrStatus, setNewArrStatus] = useState([]);

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
  });

  const [filterItems, setFilterItems] = useState({
    scope: false,
    pono: false,
    costingId: false,
  });
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const saveButtonClose = (e) => {
    e.preventDefault();
    const updatedFilterItems = {};
    for (const key in filterItems) {
      updatedFilterItems[key] = false;
    }
    setFilterItems(updatedFilterItems);
    e.stopPropagation();
  };

  const [countryName, setCountryName] = useState([]);

  const handleFilterItemsClick = (e) => {
    const name = e.currentTarget.getAttribute("name");

    const updatedFilterItems = {};
    for (const key in filterItems) {
      updatedFilterItems[key] = key === name;
    }

    setFilterItems(updatedFilterItems);
  };

  const handleChange1 = (e) => {
    const { name, checked, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: checked ? value : "",
    });

    setScopeFilter({
      ...scopeFilter,
      [name]: checked ? value : "",
    });
  };

  const handleChange2 = (e) => {
    const { name, checked, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: checked ? value : "",
    });

    setLocationFilter({
      ...locationFilter,
      [name]: checked ? value : "",
    });
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: value,
    });

    setCostingIdFilter({
      ...teamFilter,
      [name]: value,
    });
  };

  const handleChange4 = (e) => {
    const { name, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: value,
    });

    setPoNoFilter({
      ...ponoFilter,
      [name]: value,
    });
  };

  const handleChange5 = (e) => {
    const { name, checked, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: checked ? value : "",
    });

    setStatusFilter({
      ...statusFilter,
      [name]: checked ? value : "",
    });
  };

  const applyFilter = () => {
    let valuesScope = Object.values(scopeFilter);
    let filterScope = valuesScope.filter((el) => el);
    console.log("Scope filter", filterScope);
    console.log(scopeFilter);

    let valuesCostingId = Object.values(costingIdFilter);
    let filterCostingId = valuesCostingId.filter((el) => el);
    console.log("filterCostingId:" + filterCostingId);

    let valuesPoNo = Object.values(poNoFilter);
    let filterPoNo = valuesPoNo.filter((el) => el);
    console.log("filterPoNo:" + filterPoNo);

    let valuesStatus =  Object.values(statusFilter);
    let  filterStatus = valuesStatus.filter((el)=>el);
    console.log( "filterStatus: ",filterStatus ) ;
    console.log("values status",statusFilter);

    setNewArrScope(filterScope);
    setNewArrCostingId(filterCostingId);
    setNewArrPoNo(filterPoNo);
    setNewArrStatus(filterStatus);

    setStoreFilter([...filterScope, ...filterCostingId, ...filterPoNo, ...filterStatus]);
    setFilterContainer(false);
  };

  const scopeFunctionFilter = () => {
    const filter = projects.filter((el) => {
      if (newArrScope.length === 0) {
        return el;
      }
      return newArrScope.some((it) => {
        const scope = el.Scope;

        switch (it) {
          case it:
            return !it || (scope && scope === it);
        }
      });
    });
    costingIdFunctionFilter(filter);
  };

  const costingIdFunctionFilter = (data) => {
    const filter = data.filter((el) => {
      if (newArrCostingId.length === 0) {
        return el;
      }
      return newArrCostingId.some((it) => {
        const costID = el.Costing_ID;

        switch (it) {
          case "costingId = 0":
            return !it || costID === "0" || costID === "" || costID === null;
          case "costingId > 0":
            return !it || (costID && costID > "0");
        }
      });
    });
    poNoFunctionFilter(filter);
  };

  const poNoFunctionFilter = (data) => {
    const filter = data.filter((el) => {
      if (newArrPoNo.length === 0) {
        return el;
      }
      return newArrPoNo.some((it) => {
        const pono = el.PO_No;

        switch (it) {
          case "PO No = 0":
            return !it || pono === "0" || pono === "" || pono === null;
          case "PO No > 0":
            return !it || (pono && pono > "0");
        }
      });
    });
    // setFilteredProjects(filter);
    statusFunctionFilter(filter)
  };

  const statusFunctionFilter = (data) => {
    const filter = data.filter((el) => {
      if (newArrStatus.length === 0) {
        return el;
      }
      return newArrStatus.some((it) => {
       

        let dayCompletion;
        let monthCompletion;
        let yearCompletion;
        const [day, month, year] =
        el.Estimated_Date_Of_Commencement.split("/").map(Number);
        const estimatedDate = new Date(year, month - 1, day);
        let estimatedDate2 = estimatedDate;
 
        let presentDate3 = new Date();
        let completionDate = new Date(
          yearCompletion,
          monthCompletion,
          dayCompletion
        );

        let count = 0;

        for (let i = 1; i <= 44; i++) {
          const propertyName = "Item" + i;
          if (el[propertyName] === "Completed") {
            count++;
          }
        }

      
        const upcomingStatus =
    
       
        estimatedDate2 > presentDate3;

        const ongoingStatus =
      
        estimatedDate2 <= presentDate3 && isScopeCompleted(el);

        const completedStatus =
        estimatedDate2 <= presentDate3 && !isScopeCompleted(el) && !isPaymentCompleted(el)
        const readyfordispatchStatus =
        estimatedDate2 <= presentDate3 && !isScopeCompleted(el) && isPaymentCompleted(el)

        switch (it) {
        
          case "Upcoming":
            return !it || upcomingStatus;
          case "Ongoing":
            return !it || ongoingStatus;
          case "Ready for Dispatch":
            return !it || readyfordispatchStatus;
          case "Completed":
            return !it || completedStatus;
       
        }
      });
    });
    setFilteredProjects(filter);
  };

  const removeFilter = async (e, index, name) => {
    e.preventDefault();
    const updatedStoreFilter = [...storeFilter];

    updatedStoreFilter?.splice(index, 1);

    setScopeFilter((prev) => {
      const update = { ...prev };
      delete update[name];
      return update;
    });
    setCostingIdFilter((prev) => {
      const update = { ...prev };
      delete update[name];
      return update;
    });
    setPoNoFilter((prev) => {
      const update = { ...prev };
      delete update[name];
      return update;
    });
    setStatusFilter((prev) => {
      const update = { ...prev };
      delete update[name];
      return update;
    });

    let valuesScope = Object.values(scopeFilter);
    let valuesCostingId = Object.values(costingIdFilter);
    let valuesPoNo = Object.values(poNoFilter);
    let valuesStatus = Object.values(statusFilter);

    let filterScope = valuesScope.filter((elm) => elm !== name);
    let filterCostingId = valuesCostingId.filter((elm) => elm !== name);
    let filterPoNo = valuesPoNo.filter((elm) => elm !== name);
    let filterStatus = valuesStatus.filter((elm) => elm !== name);

    setStoreFilter([...filterScope, ...filterCostingId, ...filterPoNo, ...filterStatus]);
    setNewArrScope(filterScope);
    setNewArrCostingId(filterCostingId);
    setNewArrPoNo(filterPoNo);
    setNewArrStatus(filterStatus);

    let radioName;

    switch (name) {
      case name:
        radioName = name;
        break;
    }

    setAdvancedFilter((prev) => {
      const updateRadio = { ...prev };
      delete updateRadio[radioName];
      return updateRadio;
    });
  };

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PO_Date: filter[0]?.PODate,
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
      //setFilteredProjects(filter[0]);
    };
    fetchProject();
  }, [id]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
        );
        setProjects(response.data.data);
        setFilteredProjects(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    scopeFunctionFilter();
  }, [newArrScope, newArrCostingId, newArrPoNo,newArrStatus]);

  useEffect(() => {
    const fetchProjectCheckLists = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshopproject-checklistgetdetails"
        );
        setProjectCheckList(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjectCheckLists();
  }, []);

  console.log(setSearchQuery);
  console.log(projects);

  



  const handleSearchInputChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    // Filter projects based on the search query
    const filtered = projects.filter((project) => {
      const Sales_Order_No = project?.Sales_Order_No
        ? project.Sales_Order_No.toString()
        : "";
      const PO_No = project?.PO_No ? project.PO_No.toString() : "";
      const Project_Name = project?.Project_Name
        ? project.Project_Name.toString()
        : "";

      const matchSearchQuery =
        Sales_Order_No.toLowerCase().includes(value.toLowerCase()) ||
        PO_No.toLowerCase().includes(value.toLowerCase()) ||
        Project_Name.toLowerCase().includes(value.toLowerCase());

      return matchSearchQuery;
    });

    console.log("Filtered: ", filtered);
    setFilteredProjects(filtered);
  };

  const openFilterContainer = (e) => {
    e.preventDefault();
    setFilterContainer(!filterContainer);
    setFilterItems({
      scope: false,
      location: false,
      costingId: false,
      pono: false,
      status: false,
    });
  };

  
  function isScopeCompleted(p1) {
    const filter1 = projectCheckList.find((el) => el.ID_No === p1.ID_No);
    return filter1?.Item30A !== "Completed";
  }

  
  function isPaymentCompleted(p1){
    const filter1 = projectCheckList.find((el) => el.ID_No === p1.ID_No);
    return filter1?.Item42A  !== "Completed" ;
  }
  
  function areAllChecklistItemsCompleted(p1) {
    const filter1 = projectCheckList.find((el) => el.ID_No === p1.ID_No);
    if (!filter1) return false;
  
    for (let i = 1; i <= 44; i++) {
      const propertyName = "Item" + i;
      if (filter1[propertyName] !== "Completed") {
        return false;
      }
    }
    return true;
  }

  return (
    <div>
      <div className={styles.searchbar}>
        <input
          className={styles.inputtext}
          type="text"
          placeholder=" Search by SO No,PO No ,Project Name"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
      </div>
      <div className={styles.filterContainerworkshop}>
        <Button
          onClick={openFilterContainer}
          variant="contained"
          className={styles["btn-filter"]}>
          Filter
          <svg
            style={{ marginLeft: "10px" }}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none">
            <path
              d="M13.491 1.66699H6.50768C3.47435 1.66699 1.66602 3.47533 1.66602 6.50866V13.4837C1.66602 16.5253 3.47435 18.3337 6.50768 18.3337H13.4827C16.516 18.3337 18.3243 16.5253 18.3243 13.492V6.50866C18.3327 3.47533 16.5243 1.66699 13.491 1.66699ZM11.1077 14.167H8.88268C8.54102 14.167 8.25768 13.8837 8.25768 13.542C8.25768 13.2003 8.54102 12.917 8.88268 12.917H11.1077C11.4493 12.917 11.7327 13.2003 11.7327 13.542C11.7327 13.8837 11.4577 14.167 11.1077 14.167ZM13.3327 10.6253H6.66602C6.32435 10.6253 6.04102 10.342 6.04102 10.0003C6.04102 9.65866 6.32435 9.37533 6.66602 9.37533H13.3327C13.6743 9.37533 13.9577 9.65866 13.9577 10.0003C13.9577 10.342 13.6743 10.6253 13.3327 10.6253ZM14.9993 7.08366H4.99935C4.65768 7.08366 4.37435 6.80033 4.37435 6.45866C4.37435 6.11699 4.65768 5.83366 4.99935 5.83366H14.9993C15.341 5.83366 15.6243 6.11699 15.6243 6.45866C15.6243 6.80033 15.341 7.08366 14.9993 7.08366Z"
              fill="#292D32"
            />
          </svg>
        </Button>

        {filterContainer && (
          <div className={styles.optionContainerworkshop}>
            <div
              name="scope"
              onClick={handleFilterItemsClick}
              className={
                filterItems.scope
                  ? `${styles.optionsList} ${styles.activeOption}`
                  : styles.optionsList
              }>
              <h5>Scope</h5>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.75 13.5L11.25 9L6.75 4.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {filterItems.scope && (
                <div className={styles.itemsContainer}>
                  <div className={styles.optionsList}>
                    <h5>Assembly</h5>
                    <input
                      type="checkbox"
                      name="Assembly"
                      value="Assembly"
                      checked={advancedFilter[`Assembly`] === "Assembly"}
                      onChange={handleChange1}
                    />
                  </div>
                  <div className={styles.optionsList}>
                    <h5>D&I</h5>
                    <input
                      type="checkbox"
                      name="D&I"
                      value="D&I"
                      checked={advancedFilter[`D&I`] === "D&I"}
                      onChange={handleChange1}
                    />
                  </div>

                  <div className={styles.optionsList}>
                    <h5>Overhaul</h5>
                    <input
                      type="checkbox"
                      name="Overhaul"
                      value="Overhaul"
                      checked={advancedFilter[`Overhaul`] === "Overhaul"}
                      onChange={handleChange1}
                    />
                  </div>
                  <div className={styles.optionsList}>
                    <h5>Replenishment</h5>
                    <input
                      type="checkbox"
                      name="Replenishment"
                      value="Replenishment"
                      checked={
                        advancedFilter[`Replenishment`] === "Replenishment"
                      }
                      onChange={handleChange1}
                    />
                  </div>
                  <div className={styles.optionsList}>
                    <h5>Inspection</h5>
                    <input
                      type="checkbox"
                      name="Inspection"
                      value="Inspection"
                      checked={advancedFilter[`Inspection`] === "Inspection"}
                      onChange={handleChange1}
                    />
                  </div>

                  <div className={styles.applyContainer}>
                    <Button
                      onClick={saveButtonClose}
                      className={styles.applyBtn}
                      variant="contained">
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </div>

            {/* <div
                name="location"
                onClick={handleFilterItemsClick}
                className={
                  filterItems.location
                    ? `${styles.optionsList} ${styles.activeOption}`
                    : styles.optionsList
                }>
                <h5>Location</h5>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {filterItems.location && (
                  <div className={styles.itemsContainer}>
                    {countryName?.map((el, index) => (
                      <div className={styles.optionsList}>
                        <h5>{el}</h5>
                        <input
                          type="checkbox"
                          name={el}
                          value={el}
                          checked={advancedFilter[`${el}`] === el}
                          onChange={handleChange2}
                        />
                      </div>
                    ))}

                    <div className={styles.applyContainer}>
                      <Button
                        onClick={saveButtonClose}
                        className={styles.applyBtn}
                        variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div> */}

            <div
              name="costingId"
              onClick={handleFilterItemsClick}
              className={
                filterItems.costingId
                  ? `${styles.optionsList} ${styles.activeOption}`
                  : styles.optionsList
              }>
              <h5>Costing ID</h5>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.75 13.5L11.25 9L6.75 4.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {filterItems.costingId && (
                <div className={styles.itemsContainer}>
                  <div className={styles.teamOption2}>
                    <h5>No CostingID Selected</h5>
                    <input
                      type="radio"
                      name="costingId"
                      value="costingId = 0"
                      checked={
                        advancedFilter["costingId"] === "costingId = 0"
                      }
                      onChange={handleChange3}
                    />
                  </div>
                  <div className={styles.teamOption}>
                    <h5 style={{whiteSpace:"nowrap"}}>costingId Selected</h5>
                    <input
                      type="radio"
                      name="costingId"
                      value="costingId > 0"
                      checked={
                        advancedFilter["costingId"] === "costingId > 0"
                      }
                      onChange={handleChange3}
                    />
                  </div>

                  <div className={styles.applyContainer}>
                    <Button
                      onClick={saveButtonClose}
                      className={styles.applyBtn}
                      variant="contained">
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div
              name="pono"
              onClick={handleFilterItemsClick}
              className={
                filterItems.pono
                  ? `${styles.optionsList} ${styles.activeOption}`
                  : styles.optionsList
              }>
              <h5>PO</h5>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.75 13.5L11.25 9L6.75 4.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {filterItems.pono && (
                <div className={styles.itemsContainer}>
                  <div className={styles.teamOption3}>
                    <h5>No PO Exists</h5>
                    <input
                      type="radio"
                      name="PO No"
                      value="PO No = 0"
                      checked={advancedFilter["PO No"] === "PO No = 0"}
                      onChange={handleChange4}
                    />
                  </div>
                  <div className={styles.teamOption4}>
                    <h5>PO Exists</h5>
                    <input
                      type="radio"
                      name="PO No"
                      value="PO No > 0"
                      checked={advancedFilter["PO No"] === "PO No > 0"}
                      onChange={handleChange4}
                    />
                  </div>

                  <div className={styles.applyContainer}>
                    <Button
                      onClick={saveButtonClose}
                      className={styles.applyBtn}
                      variant="contained">
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div
            name="status"
            onClick={handleFilterItemsClick}
            className={
              filterItems.status
              ? `${styles.optionsList} ${styles.activeOption}`
              : styles.optionsList
            }>
              <h5>Status</h5>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.75 13.5L11.25 9L6.75 4.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {filterItems.status && (
                <div className={styles.itemsContainer}>
                  <div className={styles.optionsList}>
                    <h5>Ongoing</h5>
                    <input
                      type="checkbox"
                      name="Ongoing"
                      value="Ongoing"
                      checked={
                        advancedFilter[`Ongoing`] === "Ongoing"
                      }
                      onChange={handleChange5}
                    />
                  </div>
                  <div className={styles.optionsList}>
                    <h5 style={{whiteSpace:"nowrap"}}>Upcoming</h5>
                    <input
                      type="checkbox"
                      name="Upcoming"
                      value="Upcoming"
                      checked={
                        advancedFilter[`Upcoming`] === "Upcoming"
                      }
                      onChange={handleChange5}
                    />
                  </div>
                  <div className={styles.optionsList}>
                    <h5>Ready for Dispatch</h5>
                    <input
                      type="checkbox"
                      name="Ready for Dispatch"
                      value="Ready for Dispatch"
                      checked={advancedFilter[`Ready for Dispatch`] === "Ready for Dispatch"}
                      onChange={handleChange5}
                    />
                  </div>
                  <div className={styles.optionsList}>
                    <h5>Completed</h5>
                    <input
                      type="checkbox"
                      name="Completed"
                      value="Completed"
                      checked={
                        advancedFilter[`Completed`] === "Completed"
                      }
                      onChange={handleChange5}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* <div
                name="status"
                onClick={handleFilterItemsClick}
                className={
                  filterItems.status
                    ? `${styles.optionsList} ${styles.activeOption}`
                    : styles.optionsList
                }>
                <h5>Status</h5>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {filterItems.status && (
                  <div className={styles.itemsContainer}>
                    <div className={styles.optionsList}>
                      <h5>Upcoming</h5>
                      <input
                        type="checkbox"
                        name="Upcoming"
                        value="Upcoming"
                        checked={advancedFilter["Upcoming"] === "Upcoming"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Ongoing</h5>
                      <input
                        type="checkbox"
                        name="Ongoing"
                        value="Ongoing"
                        checked={advancedFilter["Ongoing"] === "Ongoing"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Demobbed</h5>
                      <input
                        type="checkbox"
                        name="Demobbed"
                        value="Demobbed"
                        checked={advancedFilter["Demobbed"] === "Demobbed"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Draft</h5>
                      <input
                        type="checkbox"
                        name="Draft"
                        value="Draft"
                        checked={advancedFilter["Draft"] === "Draft"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Paused</h5>
                      <input
                        type="checkbox"
                        name="Paused"
                        value="Paused"
                        checked={advancedFilter["Paused"] === "Paused"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Completed</h5>
                      <input
                        type="checkbox"
                        name="Completed"
                        value="Completed"
                        checked={advancedFilter["Completed"] === "Completed"}
                        onChange={handleChange5}
                      />
                    </div>

                    <div className={styles.applyContainer}>
                      <Button
                        onClick={saveButtonClose}
                        className={styles.applyBtn}
                        variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div> */}
            <div className={styles.applyContainer}>
              <Button
                onClick={applyFilter}
                variant="contained"
                className={styles.applyBtn}>
                Apply
              </Button>
            </div>
          </div>
        )}

        <NavLink to="/workshopcreatenew">
          <Button variant="contained" className={styles["btn-Createnew"]}>
            Create New
            <svg
              style={{ marginLeft: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-plus-square-fill"
              viewBox="0 0 16 16">
              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
            </svg>
          </Button>
        </NavLink>
      </div>

      {storeFilter.length !== 0 && (
        <div className={styles.projectList} style={{ padding: "10px 0" }}>
          <div className={styles.filterContainer}>
            <div className={styles.svgContainer}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM13.33 17H10.66C10.25 17 9.91 16.66 9.91 16.25C9.91 15.84 10.25 15.5 10.66 15.5H13.33C13.74 15.5 14.08 15.84 14.08 16.25C14.08 16.66 13.75 17 13.33 17ZM16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75ZM18 8.5H6C5.59 8.5 5.25 8.16 5.25 7.75C5.25 7.34 5.59 7 6 7H18C18.41 7 18.75 7.34 18.75 7.75C18.75 8.16 18.41 8.5 18 8.5Z"
                  fill="#292D32"
                />
              </svg>
            </div>
            {storeFilter.map((el, index) => (
              <div key={index} className={styles.fieldsContainer}>
                <h5>{el}</h5>
                <IconButton
                  onClick={(e) => removeFilter(e, index, el)}
                  className={styles.iconBtn}>
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}

{filteredProjects.map((el) => {
        const [day, month, year] =
          el.Estimated_Date_Of_Commencement.split("/").map(Number);
        const estimatedDate = new Date(year, month - 1, day);
        const presentDate = new Date();
        // const [day1, month1, year1] =
        //   el.Estimated_Date_Of_Commencement.split("/").map(Number);
        // const completionDate = new Date(el.Estimated_Project_Completion_Month);
        // const presentDate = new Date();
        // const inDays = Math.floor(
        //   (presentDate - estimatedDate) / (1000 * 60 * 60 * 24)
        // );
        // console.log(filteredProjects);
        let estimatedDate2 = estimatedDate;
        let presentDate3 = new Date();
        let daysArrivedSince = 0;
        if (el.First_Arrival) {
          const [day, month, year] = el.First_Arrival.split("/");
          const estimatedDate2 = new Date(`${month}/${day}/${year}`);
          estimatedDate2.setHours(0, 0, 0, 0); // set time to midnight
          const presentDate2 = new Date();
          presentDate2.setHours(0, 0, 0, 0); // set time to midnight
          daysArrivedSince = Math.floor(
            (presentDate2 - estimatedDate2) / (1000 * 60 * 60 * 24)
          );
        }

        if (daysArrivedSince < 0) {
          daysArrivedSince = "NA";
        }


       

        return (
          <div className={styles.projectList} key={el.ID_No}>

{el.Estimated_Date_Of_Commencement && (
  <>
    {estimatedDate2 > presentDate3 ? (
      <div className={styles.statusButtonWorkshop}>
        <Button
          className={styles.upcomingButtonworkshop}
          variant="contained">
          Upcoming
        </Button>
      </div>
    ) : (
      <>
        {estimatedDate2 <= presentDate3 &&  !isScopeCompleted(el) && !isPaymentCompleted(el) ? (
          <div className={styles.statusButtonWorkshop}>
            <Button
              className={styles.completedButton}
              variant="contained">
              Completed
            </Button>
          </div>
        ) : (
          <>
            {estimatedDate2 <= presentDate3 && isScopeCompleted(el) ? (
              <div className={styles.statusButtonWorkshop}>
                <Button
                  className={styles.ongoingButton}
                  variant="contained">
                  Ongoing
                </Button>
              </div>
            ) : null}
            {estimatedDate2 <= presentDate3 && !isScopeCompleted(el) && isPaymentCompleted(el)? (
              <div className={styles.statusButtonWorkshop}>
                <Button
                  className={styles.readyForDispatchButton}
                  variant="contained">
                  Ready for Dispatch
                </Button>
              </div>
            ) : null}
          </>
        )}
      </>
    )}
  </>
)}

            <NavLink
              to={"/project-details-workshop/" + el.ID_No}
              className={styles.link}>
              <div className={styles.top}>
                <h6>
                  SO <span>{el.Sales_Order_No}</span>
                </h6>
                <h6>|</h6>
                <h6>
                  PO <span>{el.PO_No}</span>
                </h6>
                <h6>|</h6>
                <h6>
                  Quote No <span>{el.Quote_No}</span>
                </h6>
                <h6>|</h6>
                <h6>
                  Costing ID <span>{el.Costing_ID}</span>
                </h6>
              </div>

              <div className={styles.content}>
        <div style={{ width: "100px", whiteSpace: "nowrap"}}>
        {el.Design + ' ' + el.Project_Name}
        <BootstrapTooltip 
          
            title={el.Project_Description ? el.Project_Description : "-"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M8.0899 4.88889H8.09778M8.0899 7.22222V11.1111M15.1798 8C15.1798 11.866 12.0056 15 8.0899 15C4.17426 15 1 11.866 1 8C1 4.13401 4.17426 1 8.0899 1C12.0056 1 15.1798 4.13401 15.1798 8Z"
                stroke="#5B6BE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BootstrapTooltip>
          </div>
          <div style={{ width: "100px", marginLeft: "1.4%", whiteSpace: "nowrap"}}>
            {/* {el.Project_Name} */}
          
          
          </div>
          <div
            style={{
              width: "100px",
              whiteSpace: "nowrap",
              marginLeft: "170px",
            }}
          >
            {el.Scope}
          </div>
          <div style={{ width: "300px" }}>{el.Service_Component}</div>

          <div style={{ width: "78px", display: "flex",marginLeft:"-210px" }}>
            <span style={{ color: "#606060", whiteSpace: "nowrap" }}>
              Days Since Arrival:
            </span>{" "}
            &nbsp;{daysArrivedSince}
          </div>
          <div style={{ width: "78px", display: "flex" }}>
            <span
              style={{
                color: "#606060",
                whiteSpace: "nowrap",
                marginLeft: "210px",
              }}
            >
              Quantity:
            </span>{" "}
            &nbsp;{el.Quantity}
          </div>
          {/* {((estimatedDate < presentDate &&
            presentDate < completionDate) ||
            presentDate > completionDate) && (
            <div style={{ width: "130px" }}>
              <span style={{ color: "#606060" }}>Days:</span> {inDays}/
              {el.Po_Days}
            </div>
          )}
          {estimatedDate < presentDate &&
            presentDate < completionDate && (
              <div>
                <span style={{ color: "#606060" }}>
                  Date of Completion:
                </span>
                {el.Estimated_Project_Completion_Month}
              </div>
            )}
          {estimatedDate > presentDate && (
            <div>
              <span style={{ color: "#606060" }}>
                Date of commencement:
              </span>
              {el.Estimated_Date_Of_Commencement}
            </div>
          )} */}
        </div>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
}
export default Workshopprojects;
