import React, { useState, useRef, useEffect } from "react";
import styles from "../Components/css/fielddocs.module.css";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
// import { LocalizationProvider, DatePicker } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import MobileDatePicker from "@mui/lab/MobileDatePicker";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Customerfeedback from "../Components/Customerfeedback"
import FieldDocsFinalReport from "./FieldDocsFinalReport";
import FieldDocsStatusSheet from "./FieldDocsStatusSheet";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
//import ProjectReport from './ProjectReport';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const Fielddocs = () => {
  const { id } = useParams();
  const [reports, setReports] = useState(1);
  const reportsTab = (tabNumber) => {
    setReports(tabNumber);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [textValue, setTextValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValue1, setSelectedValue1] = useState("");
  const [selectedDate, setSelectedDate] = useState(getDefaultDate());
  const [selectedFileName, setSelectedFileName] = useState("");
  const [open, setOpen] = useState(false);
  const [names, setNames] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedScope, setSelectedScope] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(null);
  const [comments, setComments] = useState("");
  const [rigName, setRigName] = useState("");
  const [rigName1, setRigName1] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const [dailyReportUploaded, setDailyReportUploaded] = useState(null);
  const [selectedDailyReport, setSelectedDailyReport] = useState(null);
  const [finalReportUploaded, setFinalReportUploaded] = useState(null);
  const [selectedFinalReport, setSelectedFinalReport] = useState(null);
  const [revisedReportUploaded, setRevisedReportUploaded] = useState(null);
  const [selectedRevisedReport, setSelectedRevisedReport] = useState(null);
  const [revisedReportSent, setRevisedReportSent] = useState(null);
  const [recipient, setRecipient] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [reportSent, setReportSent] = useState(false);
  const [isCommentDialogOpen, setCommentDialogOpen] = useState(false);
  const [selectedComments, setSelectedComments] = useState([]);
  const [messageErgonOffice, setMessageErgonOffice] = useState("");
  const today = formatDate();
  const [Day, setDay] = useState(""); // Initialize with appropriate default values
  const [Month, setMonth] = useState("");
  const [Year, setYear] = useState("");
  const [Daily_Reports, setDailyReports] = useState("");
  const [S_No, setS_No] = useState("");
  const [showOldContent, setShowOldContent] = useState(true);
  const [selectedRig, setSelectedRig] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [reportsData, setReportsData] = useState({
    selectedDate: today,
    document: "",
    stage: "",
    actions: "",
  });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsModalOpen(false);
  };
  const handleTextChange = (event) => {
    setTextValue(event.target.value); // Step 2: Update the text value
  };
  const handleViewReport = () => {
    const reportModal = document.getElementById("reportModal");
    const closeReportModal = document.getElementById("closeReportModal");

    reportModal.style.display = "block";

    // Close the modal when the close button is clicked
    closeReportModal.onclick = () => {
      reportModal.style.display = "none";
    };

    // Close the modal if the user clicks outside of it
    window.onclick = (event) => {
      if (event.target === reportModal) {
        reportModal.style.display = "none";
      }
    };
  };
  const recipientsForSelectedRig = names
    .filter((name) => name.short_name === rigName)
    .map((name) => (
      <MenuItem key={name.recipients} value={name.recipients}>
        <Checkbox checked={rigName1.indexOf(name.recipients) > -1} />
        <ListItemText primary={name.recipients} />
      </MenuItem>
    ));
  const recipientsDropdown = recipientsForSelectedRig.map((name) => (
    <MenuItem key={name.recipients} value={name.recipients}>
      <Checkbox checked={rigName1.indexOf(name.recipients) > -1} />
      <ListItemText primary={name.recipients} />
    </MenuItem>
  ));
  //const ary = recipientsForSelectedRig.split(",");

  const handleMultiSelectChange = (e) => {
    const { value } = e.target;
    setRigName1(typeof value === "string" ? value.split(",") : value);
    console.log(value);
    setRigName1(value);
    setTextAreaValue(value.join(", "));
  };

  const handleMultiSelectChange1 = (event) => {
    const selectedValues = event.target.value;
    setRigName1(selectedValues);
  };
  const handleViewComments = () => {
    const commentsModal = document.getElementById("commentsModal");
    const closeCommentsModal = document.getElementById("closeCommentsModal");

    commentsModal.style.display = "block";

    // Close the modal when the close button is clicked
    closeCommentsModal.onclick = () => {
      commentsModal.style.display = "none";
    };

    // Close the modal if the user clicks outside of it
    window.onclick = (event) => {
      if (event.target === commentsModal) {
        commentsModal.style.display = "none";
      }
    };
  };

  document.addEventListener("DOMContentLoaded", () => {
    const viewReportButton = document.getElementById("viewReportButton");
    const viewCommentsButton = document.getElementById("viewCommentsButton");

    if (viewReportButton) {
      viewReportButton.addEventListener("click", handleViewReport);
    }

    if (viewCommentsButton) {
      viewCommentsButton.addEventListener("click", handleViewComments);
    }
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  function SimpleDialog(props) {
    return <div></div>;
  }
  //const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDownload = () => {
    console.log("Download button clicked");
    const downloadLink = document.createElement("a");
    downloadLink.href = "/path/to/download/report.pdf";
    downloadLink.download = "report.pdf";
    downloadLink.click();
  };

  const openCommentDialog = (comments) => {
    setSelectedComments(comments);
    setCommentDialogOpen(true);
  };
  const closeCommentDialog = () => {
    setCommentDialogOpen(false);
  };
  const handleFileInputChange = (event) => {
    const fileName = event.target.value.split("\\").pop(); // Get the file name from the input
    setUploadFileName(fileName);
  };
  const calculateMargin = () => {
    const fileNameLength = uploadFileName.length;
    // Set a fixed base margin and adjust it as needed
    const baseMargin = 900; // Adjust this value as needed
    return `${baseMargin + fileNameLength * 10}px`;
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zeros for single-digit months and days
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    // Format as YYYY-MM-DD
    return `${day}-${month}-${year}`;
  }

  const renderTable = () => {
    if (reportSent) {
      return (
        <table class="table">
          <thead
            style={{
              height: "61px",
              background: "#99A1E4",
              color: "white",
            }}
          >
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Date</th>
              <th scope="col">Daily Reports</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>{getDefaultDate}</td>
              <td>{selectedDailyReport}</td>
              <td>Sent to Ergon Office</td>
              <td>
                <button onClick={handleDownload}>Download</button>
                <button href={filePreview}>View Report</button>
                <button> View comments</button>
              </td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
              <td>Action1</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Larry</td>
              <td>the Bird</td>
              <td>@twitter</td>
              <td>Action1</td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      return null; // Return null if reportSent is false
    }
  };

  const renderTable1 = () => {
    if (revisedReportSent) {
      return (
        <table
          class="table"
          style={{
            width: "1639px",
            marginLeft: "-100px",
          }}
        >
          <thead
            style={{
              background: "#99A1E4",
            }}
          >
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Final Report</th>
              <th scope="col">Stage</th>
              <th scope="col">Actions </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{selectedDate}</th>
              <td>{selectedFinalReport}</td>
              <td>First Draft by Ops Engineer</td>
              <td>
                <button onClick={handleDownload}>Download</button>
                <button onClick={handleViewReport}>View Report</button>
                <button onClick={handleViewComments}>View Comments</button>
              </td>
            </tr>
            <tr>
              <th scope="row">{selectedDate}</th>
              <td>{selectedRevisedReport}</td>
              <td>Revised by Supervisor</td>
              <td>
                <button onClick={handleDownload}>Download</button>
                <button onClick={handleViewReport}>View Report</button>
                <button onClick={handleViewComments}>View Comments</button>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  };
  const handleDayChange = (e) => {
    setDay(e.target.value);
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleDailyReportsChange = (e) => {
    setDailyReports(e.target.value);
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/daily_reports/${S_No}`
      );
      const data = response.data;

      // Assign data to your state variables
      setS_No(data.S_No);
      setDay(data.Day);
      setMonth(data.Month);
      setYear(data.Year);
      setDailyReports(data.Daily_Reports);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSendReportClick = () => {
    console.log("Button clicked");
    const dataToSend = {
      Day,
      Month,
      Year,
      Daily_Reports,
    };

    axios
      .post(
        `https://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/daily_reports/${S_No}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Data received:", response.data);
        alert("Data posted successfully!");
        setReportSent(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getrig_details?rigName=${rigName}"
        );
        if (response.status === 200) {
          setNames(response.data);
          console.log(response.data);
        } else {
          console.error("Request failed with status:", response.status);
          console.log(names);
        }
      } catch (error) {
        console.error("Axios error:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRig) {
      fetchData();
    }
  }, [selectedRig]);

  useEffect(() => {
    // Make an Axios GET request to fetch the rig name
    axios
      .get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/rigName")
      .then((response) => {
        if (response.data.success) {
          const rigName = response.data.rigName;
          setRigName(rigName); // Store the rig name in component state
        } else {
          console.error("Failed to fetch rig name:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching rig name:", error);
      });
  }, []);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleRadioChange1 = (event) => {
    setSelectedValue1(event.target.value);
  };
  const handleRecipientChange = (event) => {
    setRecipient(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleMessageChangeErgon = (event) => {
    setMessage(event.target.value);
  };

  function formatDate() {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) {
      return null;
    }
  };

  const handleUpload = () => {
    fileInputRef.current.click();
  };

  const handleScopeChange = (event) => {
    setSelectedScope(event.target.value);
  };
  function sendReport() {
    // Get the selected recipients from the textarea
    const textarea = document.getElementById("message");
    const selectedRecipients = textarea.value;

    alert(`Sending report to: ${selectedRecipients}`);
    setReportSent(true);
    console.log(reportSent);
  }

  const handleSubmit = async () => {
    const formattedDate = formatDate();
    const completedate = formattedDate.split("/");
    const day = completedate[0];
    const month = completedate[1];
    const year = completedate[2];
    const { document, stage } = reportsData;
    setOpen(false);
    setIsModalOpen(false);
    const payload = {
      Day: day,
      Month: month,
      Year: year,
      Document: document,
      Stage: stage,
    };
    console.log(payload);
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/reports",
        payload
      );
      if (res.status === 200) {
        alert("data added successfully");
        renderTable();
      } else {
        alert("Error adding data");
        renderTable();
      }
      setIsSubmitting(true);
    } catch (error) {
      console.error("An error occurred:", error);
      alert("Error adding data");
      console.log(payload);
    }
    setShowOldContent(false);
  };

  const handleSend = async (selectedRevisedReport) => {
    try {
      const formData = new FormData();
      formData.append("revisedReport", selectedRevisedReport);

      const response = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/reports",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Revised report sent successfully");
        renderTable1();
      } else {
        // Handle error
        alert("Error sending revised report");
        renderTable1();
      }
    } catch (error) {
      // Handle any network or server error
      console.error("An error occurred:", error);
      alert("Error sending revised report");
    }
  };

  function handleFileUpload(event) {
    const fileInput = event.target;
    const selectedFile = fileInput.files[0];

    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFilePreview(fileURL);
      setSelectedFileName(selectedFile.name);
      setOpen(true);
    } else {
      console.log("No file selected");
    }
  }
  function getDefaultDate(event) {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  }

  function handleDailyReportUpload(event) {
    const fileInput = event.target;
    const selectedDailyReport = fileInput.files[0];

    if (selectedDailyReport) {
      const fileURL = URL.createObjectURL(selectedDailyReport);
      setFilePreview(fileURL);
      setSelectedDailyReport(selectedDailyReport.name);
      setOpen(true);
      setDailyReportUploaded(true);
    } else {
      console.log("No file selected");
    }
  }

  function handleRevisedReportUpload(event) {
    const fileInput = event.target;
    const selectedRevisedReport = fileInput.files[0];

    if (selectedRevisedReport) {
      const fileURL = URL.createObjectURL(selectedRevisedReport);
      setFilePreview(fileURL);
      setSelectedRevisedReport(selectedRevisedReport.name);
      setOpen(true);
      setRevisedReportUploaded(true); //
    } else {
      console.log("No file selected");
    }
  }

  function handleFinalReportUpload(event) {
    const fileInput = event.target;
    const selectedFinalReport = fileInput.files[0];

    if (selectedFinalReport) {
      const fileURL = URL.createObjectURL(selectedFinalReport);
      setFilePreview(fileURL);
      setSelectedFinalReport(selectedFinalReport.name);
      setOpen(true);
      setFinalReportUploaded(true);
    } else {
      console.log("No ");
    }
  }

  const [height, setHeight] = useState(0);
  const divRef = useRef(null);

  const calculateHeight = () => {
    if (divRef.current) {
      const divPosition = divRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const calculatedHeight = windowHeight - divPosition - (8 * window.innerHeight / 100);
      setHeight(calculatedHeight);
    }
  };

  useEffect(() => {
    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);

  return (
    <div>
      <div className={styles.allTabs}>
        <div
          className={
            reports === 1 ? styles.activeReportsTab : styles.reportsTab
          }
          onClick={() => reportsTab(1)}
          style={{ marginLeft: "50px" }}
        >
          Daily Reports
        </div>
        <div
          className={
            reports === 2 ? styles.activeReportsTab : styles.reportsTab
          }
          onClick={() => reportsTab(2)}
        >
          Final Report
        </div>
        <div
          className={
            reports === 3 ? styles.activeReportsTab : styles.reportsTab
          }
          onClick={() => reportsTab(3)}
        >
          Status sheet
        </div>
        <div
          className={
            reports === 4 ? styles.activeReportsTab : styles.reportsTab
          }
          onClick={() => reportsTab(4)}
        >
          Customer Feedback
        </div>
      </div>
      {reports === 1 && (
        <div
          ref={divRef}
          style={{
            minHeight: `${height}px`,
          }}
        >
          {/* <label htmlFor="myDateInput" className={styles.datepicker}>
            Select Date:
          </label>
          <input
            type="date"
            id="myDateInput"
            className={styles.myInput}
            value={selectedDate || getCurrentDate()}
            onChange={handleDateChange}
          />

          <label for="uploadButton" className={styles.uploadButton}>
            Upload Report:
          </label> */}

          {/* {!dailyReportUploaded && (
            <button
              onClick={() => {
                document.getElementById("fileInput").click();
              }}
              className={styles.uploadDailyReportButton}
            >
              Upload Daily Report
            </button>
          )}
          {!dailyReportUploaded && (
            <div>
              <p className={styles.subtext}>
                Accepted formats: .doc, .docx, .pdf
              </p>
            </div>
          )}
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={(event) => {
              handleDailyReportUpload(event);
              setDailyReportUploaded(true);
            }}
          />
          {dailyReportUploaded && (
            <div>
              <div>
                <p className={styles.filename}>{selectedDailyReport}</p>
                <Button
                  href={filePreview}
                  target="_blank"
                  className={styles.viewButton}
                  // style={{
                  //   color: "white",
                  //   height: "50px",
                  //   padding: "15px 12px 15px 17px",
                  //   justifyContent: "flex-end",
                  //   gap: "8px",
                  //   alignItems: "center",
                  //   borderRadius: "8px",
                  //   background: "#5B6BE1",
                  //   boxShadow: "1px 8px 24px 0px rgba(125, 142, 231, 0.40)",
                  //   textDecoration: "none",
                  //   fontSize: "14px",
                  //   marginLeft: "850px",
                  //   marginTop: "-93px",
                  //   fontWeight: "700",
                  // }}
                >
                  View
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.333"
                    height="18.333"
                    viewBox="0 0 17 15"
                    fill="none"
                  >
                    <path
                      d="M16 10H14.5V13H2.5V2.5H7V1H1V14.5H16V10Z"
                      fill="white"
                      stroke="white"
                    />
                    <path
                      d="M9.25191 8.8105L14.5019 3.5605V7.75H16.0019V1H9.25191V2.5H13.4414L8.19141 7.75L9.25191 8.8105Z"
                      fill="white"
                      stroke="white"
                    />
                  </svg>
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    document.getElementById("fileInput").click();
                  }}
                  className={styles.uploadAgain}
                  // style={{
                  //   color: "white",
                  //   textAlign: "center",
                  //   textDecoration: "none",
                  //   fontSize: "14px",
                  //   height: "50px",
                  //   fontStyle: "normal",
                  //   fontWeight: "400",
                  //   marginTop: "-96px",
                  //   marginLeft: "28px",
                  //   borderRadius: "8px",
                  //   border: "1px solid #5B6BE1",
                  //   background: "#5B6BE1",
                  //   fontWeight: "700",
                  // }}
                >
                  Upload Again
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.333"
                    height="18.333"
                    viewBox="0 0 19 19"
                    fill="none"
                    style={{ marginLeft: "10px" }}
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.335938 9.16667C0.335938 4.84545 0.335937 2.68485 1.67837 1.34243C3.02079 -1.09275e-07 5.18139 0 9.5026 0C13.8238 0 15.9844 -1.09275e-07 17.3268 1.34243C18.6693 2.68485 18.6693 4.84545 18.6693 9.16667C18.6693 13.4878 18.6693 15.6485 17.3268 16.9909C15.9844 18.3333 13.8238 18.3333 9.5026 18.3333C5.18139 18.3333 3.02079 18.3333 1.67837 16.9909C0.335937 15.6485 0.335938 13.4878 0.335938 9.16667ZM9.5026 14.4375C9.88229 14.4375 10.1901 14.1297 10.1901 13.75V8.99314L11.7665 10.5694C12.035 10.8379 12.4702 10.8379 12.7387 10.5694C13.0072 10.301 13.0072 9.86572 12.7387 9.59723L9.98871 6.8472C9.85983 6.71827 9.68493 6.64583 9.5026 6.64583C9.32028 6.64583 9.14538 6.71827 9.0165 6.8472L6.26647 9.59723C5.99799 9.86572 5.99799 10.301 6.26647 10.5694C6.53495 10.8379 6.97026 10.8379 7.23874 10.5694L8.8151 8.99314V13.75C8.8151 14.1297 9.12292 14.4375 9.5026 14.4375ZM5.83594 5.27083C5.45624 5.27083 5.14844 4.96303 5.14844 4.58333C5.14844 4.20364 5.45624 3.89583 5.83594 3.89583H13.1693C13.549 3.89583 13.8568 4.20364 13.8568 4.58333C13.8568 4.96303 13.549 5.27083 13.1693 5.27083H5.83594Z"
                      fill="white"
                    />
                  </svg>
                </Button>
                <div>
                  <FormControl>
                    <div className={styles.action1}>
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        className={styles.action2}
                      >
                        Action:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedValue}
                        onChange={handleRadioChange}
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="Send to Client"
                          control={<Radio />}
                          label="Send to Client"
                        />
                        <FormControlLabel
                          value="Send to Ergon Office"
                          control={<Radio />}
                          label="Send to Ergon Office"
                        />
                      </RadioGroup>
                    </div>
                  </FormControl>
                  {selectedValue === "Send to Ergon Office" && (
                    <>
                      <label
                        htmlFor="message"
                        className={styles.sendToErgonOffice}
                        // style={{ marginLeft: "-407px", marginTop: "95px" }}
                      >
                        Add Comments(if any):
                      </label>
                      <textarea
                        id="message"
                        rows="8"
                        cols="50"
                        className={styles.handleMessageChangeErgon}
                        //value={messageErgonOffice}
                        onChange={handleMessageChangeErgon}
                      />
                      <button
                        onClick={sendReport}
                        style={{
                          display: "inline-flex",
                          padding: "15px 37px 15px 39px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          background: "#5B6BE1",
                          boxShadow:
                            "1px 8px 24px 0px rgba(125, 142, 231, 0.4)",
                          marginTop: "206px",
                          marginLeft: "1506px",
                        }}
                      >
                        Send Report
                      </button>
                      {renderTable()}
                    </>
                  )}

                  {selectedValue === "Send to Client" && (
                    <div className={styles.chooseRecipientsLabel}>
                      <div>
                        <label
                          htmlFor="recipient"
                          className={styles.recipientbox}
                        >
                          Choose Recipients:
                        </label>

                        <Select
                          multiple
                          className={styles.chooseRecipientsDropDown}
                          value={rigName1}
                          onChange={handleMultiSelectChange1}
                          renderValue={(selected) =>
                            Array.isArray(selected) ? selected.join(",") : ""
                          }
                          MenuProps={MenuProps}
                          IconComponent={() => null}
                        >
                          {recipientsForSelectedRig}
                        </Select>
                      </div>
                      <br />

                      <label htmlFor="subject" className={styles.subjectlabel}>
                        Subject:
                      </label>
                      <input
                        type="text"
                        id="subject"
                        value={`Daily Report | ${rigName} | ${selectedDate} | ET`}
                        className={styles.subjecttext}
                        readOnly
                        disabled
                      />
                      <br />
                      <label
                        htmlFor="message"
                        class="message-label1"
                        className={styles.addMessageLabel}
                      >
                        Add Message:
                      </label>
                      <div className={styles.addMessageLabelDailyReports1}>
                        <textarea
                          className={styles.addMessageLabelDailyReports2}
                          rows="4"
                          cols="50"
                          placeholder="Enter your text here..."
                          value={textAreaValue}
                          readOnly
                        ></textarea>
                      </div>
                      <textarea
                        id="message"
                        rows="8"
                        cols="50"
                        className={styles.addMessageTextBox}
                        value={`Dear All,
Please find the daily report for ${selectedDate} attached with this email for your kind perusal.
Please feel free to write back in case of any questions.
Regards
[User Account Name]`}
                        disabled
                      ></textarea>

                      <div>
                        <button
                          onClick={handleSendReportClick}
                          className={styles.sendDailyReportButton}
                        >
                          Send Report
                        </button>
                        {renderTable()}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )} */}
        </div>
      )}
      {reports === 2 &&
        <div
          ref={divRef}
          style={{
            minHeight: `${height}px`,
          }}
        >
          <FieldDocsFinalReport />
        </div>
      }
      {reports === 3 &&
        <div
          ref={divRef}
          style={{
            minHeight: `${height}px`,
          }}
        >
          <FieldDocsStatusSheet />
        </div>
      }
      {reports === 4 && (
        <div
          ref={divRef}
          style={{
            minHeight: `${height}px`,
          }}
        >
          <Customerfeedback />
        </div>
      )}
    </div>
  );
};

export default Fielddocs;
