import React from 'react'
import { Stepper, Step, StepLabel, Box } from '@mui/material';
// import styles from "./css/Production_Create_New.module.css";    //css for the Material Produced screen, step 1 of the flow
// import styles2 from "../Components/css/Production_Create_New_2.module.css";   //css for the Material Consumed screen, step 2 of the flow
import style from "./css/modal.module.css";
import styles from "./css/dispatchcreatenew.module.css";
import styles2 from "./css/dispatchcreatenew2.module.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparestradingDispatchCreatenew() {
    const { id } = useParams();   //Project ID taken from url
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks

    const [formDataProduced, setFormDataProduced] = useState([]);   //to store the "Material Produced" table data
    const [itemsMaster, setItemsMaster] = useState([]);   //used for storing the Items Master List data to display in the Item_Name dropdown when anything is typed
    const [prodType, setProdType] = useState("WIP");    //state to store the Production_Type option selected using radio buttons
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);    // to set the Add More Items button disabled state
    const [addItemMode, setAddItemMode] = useState(false);    //when "Add More Items" button is clicked, this state is activated
    const [editItemMode, setEditItemMode] = useState(null);   //when the edit pencil icon is clicked, this state is activated
    const [initialEditData, setInitialEditData] = useState(null);   //state to store the initial data stored in formDataProduced whenever the edit "pencil" icon is clicked, this will be used to restore the data in case the edit is cancelled
    const [refresh, setRefresh] = useState(false);    //refresh state is used to control the useEffect where all the get requests are, explicitly used in Edit, Save, Cancel and other buttons to refresh the data
    const [filteredItems, setFilteredItems] = useState([]);
    const [filterWip, setFilterWip] = useState([]);
    const [filterFg, setFilterFg] = useState([]);
    const [isDropdownVisible, setDropdownVisible] = useState(false);    //used to control dropdown visibility under Item Name input in editable state, editItemMode, addItemMode
    const [isPartNoDisabled, setIsPartNoDisabled] = useState([]);   //used to control the disabled state of the Part Number input in editable state, editItemMode
    const [stockQuantities, setStockQuantities] = useState({}); // Tracks stock quantities by item ID or Part Number

    const [isPartNoDisabledAddRow, setIsPartNoDisabledAddRow] = useState(true);    //used to control the disabled state of the Part Number input when "Add More Items " is clicked, addItemMode state true
    const containerRef = useRef(null);
    const [initialHeight, setInitialHeight] = useState();   // State variable to track the initial height of the container
    const [heightRefresh, setHeightRefresh] = useState(false);    //to refresh the height of smtablecontainer div which contains the table and dropdowns
    const [containerStyle, setContainerStyle] = useState({});   // State variable to store container style
    const [fileName3, setFileName3] = useState("");
    const [fileName4, setFileName4] = useState("");
    const [documentToUpload3, setDocumentToUpload3] = useState("");
    const [documentToUpload4, setDocumentToUpload4] = useState("");
    const [selectedPurpose, setSelectedPurpose] = useState("");
    const [incomingQuantitiesWip, setIncomingQuantitiesWip] = useState([]);
    const [incomingQuantitiesFG, setIncomingQuantitiesFg] = useState([]);
    const [pendingQty, setPendingQty] = useState([]);
    const [differencesArray, setDifferencesArray] = useState([]);
    const [mergedArray, setMergedArray] = useState([]);
    const [isfillsame, setIsfillsame] = useState(false);
    const [isfillsameWip, setIsFillSameWip] = useState(false);
    const [isfillsameFg, setIsFillSameFg] = useState(false);
    const [isCsp, setIsCsp] = useState(true);
    const [projectList, setProjectList] = useState({});
    // const [errors, setErrors] = useState(Array(filteredItems.length).fill(false));
    const [errors, setErrors] = useState({});
    const [errors2, setErrors2] = useState({});
    const [dispatchIdState, setDispatchIdState] = useState(null);
    const [incomingData, setIncomingData] = useState([]);
    const [isPODocumentMarkedForRemoval, setIsPODocumentMarkedForRemoval] =
        useState(false);
    const [isQuoteDocumentMarkedForRemoval, setIsQuoteDocumentMarkedForRemoval] =
        useState(false);
        const [formData, setFormData] = useState({
            Sales_Order_No: "",
            PO_No: "",
            PO_Date: null,
            Quote_No: "",
            Quote_Date: null,
            Costing_ID: "",
            Customer_Name: "",
            Scope: "",
            Scope_Description: "",
            Estimated_Date_Of_Dispatch: null,
            Ops_Engineer: "",
            Current_Status: "",
          });

    const [formdata1, setFormData1] = useState({
        salesOrderNo: "",

        PONO: "",
        PODays: "",
        PODate: "",
        QuoteNO: "",
        QuoteDate: "",
        CostingID: "",
        ProjectName: "",
        Scope: "",
        EstimatedDateCommencement: "",
        CustomerName: "",
        FirstArrival: "",
        TargetDaysForCompletion: "",
        TargetManhours: "",
        opsEngineer: "",
        quantity: "",
        projectDescription: "",
        currentStatus: "",
        Design: "",
        scopeDescription: "",
        DmccFze: "",
        FzeDmccPo: "",
    });

    const [dispatchdata, setDispatchdata] = useState({
        Purpose: "",
        ReceivingParty: "",
        Deliverydate: "",
        Ponumber: "",
        Quotenumber: "",
        Dispatchqty: "",
    })

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
            );
    
            if (response.data.success) {
              const data = response.data.data[0];
              setFormData({
                Sales_Order_No: data.Sales_Order_No,
                PO_No: data.PO_No,
                PO_Date: data.PO_Date,
                Quote_No: data.Quote_No,
                Quote_Date: data.Quote_Date,
                Costing_ID: data.Costing_ID,
                Customer_Name: data.Customer_Name,
                Scope: data.Scope,
                Scope_Description: data.Scope_Description,
                Estimated_Date_Of_Dispatch: data.Estimated_Date_Of_Dispatch,
                Ops_Engineer: data.Ops_Engineer,
                Current_Status: data.Current_Status,
              });
            //   setOriginalData(data);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error here
          }
        };
    
        fetchData();
      }, [id]);

    useEffect(() => {
        // Merge filteredItems and incomingQuantities arrays
        const mergedArray = filteredItems.map((item, index) => ({
            ...item,
            Dispatchqty: incomingQuantitiesWip[index] || "", // Adding incomingQuantity to each item
        }));
        setMergedArray(mergedArray);
    }, [incomingQuantitiesWip]);

    const isSaveDisabled = () => {
        if (isCsp) {
            return Object.values(inputValues).length === 0 || Object.values(inputValues).some(value => value === "" || value === 0);
        } else {
            return Object.values(inputValues2).length === 0 || Object.values(inputValues2).some(value => value === "" || value === 0);
        }
    };

 


    useEffect(() => {
        // Calculate differencesArray whenever filteredItems or pendingQty change
        const newDifferencesArray = filteredItems.map((item) => {
            const pendingItem = pendingQty.find(
                (pItem) => pItem.OEM_Part_Number === item.OEM_Part_Number
            );
            return pendingItem
                ? item.Qty_ordered - pendingItem.Scheduled_Quantity
                : item.Qty_ordered;
        });
        setDifferencesArray(newDifferencesArray);
    }, [filteredItems, pendingQty]);

    const [sumState, setSumState] = useState([]);
    useEffect(() => {
        // Ensure both incomingQuantities and differencesArray have the same length
        if (incomingQuantitiesWip.length === differencesArray.length) {
            // Calculate the sum of corresponding elements from incomingQuantities and differencesArray
            const sumArray = incomingQuantitiesWip.map(
                (val, index) => val + differencesArray[index]
            );
            setSumState(sumArray);
        }
    }, []);
    useEffect(() => {
        if (incomingQuantitiesWip.length > 0) {
            // Ensure both incomingQuantities and differencesArray have the same length
            {
                // Calculate the sum of corresponding elements from incomingQuantities and differencesArray
                const sumArray = incomingQuantitiesWip.map(
                    (val, index) => val + differencesArray[index]
                );
                setSumState(sumArray);
            }
        }
    }, [differencesArray]);
    console.log("setSumState", sumState);

    //useEffect to get the Items Master List Data on first loading of the page data and states
    useEffect(() => {
        async function fetchItemsMaster() {
            try {
                const resItemsMaster = await axios.get(
                    "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
                );

                setItemsMaster(resItemsMaster.data);
                console.log("Items Master:", resItemsMaster.data);








            } catch (error) {
                console.error(error);
            }
        }
        fetchItemsMaster();
    }, [refresh]);

    // Use effect to initialize and update isPartNoDisabled
    useEffect(() => {
        setIsPartNoDisabled(formDataProduced.map(() => true));
    }, [formDataProduced.length]);


    const handleProdBackButton = () => {
        navigate(`/workshop-spares-trading-dispatch/${id}`);
    };

    console.log(id);

    //Stepper states and functions

    const steps = [
        { label: 'Dispatch details' },
        { label: 'Dispatch items' }
    ];

    const [activeStep, setActiveStep] = useState(0);    //to store the activeStep for the stepper and to render the tables, 0 for "Material Produced", 1 for "Material Consumed"

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };













    useEffect(() => {
        const container = containerRef.current;

        if (!container) return;

        const observer = new ResizeObserver((entries) => {
            // Since there might be multiple entries, we take the first one
            const newHeight = entries[0].contentRect.height;
            setInitialHeight(newHeight);
        });

        observer.observe(container);

        return () => observer.disconnect();
    }, [heightRefresh, activeStep]);

    console.log("initialHeight", initialHeight);

    useEffect(() => {
        // Calculate the new height based on the initial height and whether the dropdowns are visible
        const newHeight = ((isDropdownVisible) ? (initialHeight + 270) : (initialHeight + 20));
        // const newHeightInt = parseInt(newHeight);

        console.log("newHeight", newHeight);
        setContainerStyle({ height: newHeight });
    }, [isDropdownVisible, initialHeight, addItemMode, isButtonDisabled, editItemMode, formDataProduced, activeStep]);

    console.log("isDropdownVisible saSasASasAS", isDropdownVisible)
    console.log("containerStyle ppppppppp", containerStyle);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdown = document.getElementById('myDropdown');

            // Check if the clicked element is inside the dropdown
            if (dropdown && !dropdown.contains(event.target)) {
                // If the clicked element is outside the dropdown, hide it
                setDropdownVisible(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!isCsp) {
            async function fetchFgItems() {
                try {
                    const res = await axios.get(
                        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsproduced_get2id?Project_ID=${id}`
                    );
                    setFilterFg(res.data.data);
                } catch (error) {
                    console.error(error);
                }
            }
            fetchFgItems();
        }
    }, [isCsp, id]);
    { console.log(filterFg) }

    useEffect(() => {
        if (isCsp) {
            async function fetchWipItems() {
                try {
                    const res = await axios.get(
                        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsproduced_get?Project_ID=${id}`
                    );
                    setFilterWip(res.data.data);
                } catch (error) {
                    console.error(error);
                }
            }
            fetchWipItems();
        }
    }, [isCsp, id]);
    { console.log(filterWip) }





    const handlecross = (name) => {
        switch (name) {
            case "PO_document":
                setFileName3("");
                setDocumentToUpload3(null);
                setIsPODocumentMarkedForRemoval(true)
                break;
            case "Quote_document":
                setFileName4("");
                setDocumentToUpload4(null);
                setIsQuoteDocumentMarkedForRemoval(true)
                break;
            default:
                break;
        }
    };

    const handlefileupload3 = (name, event) => {
        event.preventDefault();
        const temp3 = event.target.files[0];
        setDocumentToUpload3(temp3);

        const inputElement3 = document.getElementsByName(name)[0];
        const selectedFile3 = inputElement3.files[0];

        if (selectedFile3) {
            const fileName3 = selectedFile3.name;
            setFileName3(fileName3 ? fileName3 : "");
        } else {
            setFileName3("");
        }
        inputElement3.value = "";
        temp3.value = "";
    };
    const handlefileupload4 = (name, event) => {
        event.preventDefault();
        const temp4 = event.target.files[0];
        setDocumentToUpload4(temp4);

        const inputElement4 = document.getElementsByName(name)[0];
        const selectedFile4 = inputElement4.files[0];

        if (selectedFile4) {
            const fileName4 = selectedFile4.name;
            setFileName4(fileName4 ? fileName4 : "");
        } else {
            setFileName4("");
        }
        inputElement4.value = "";
        temp4.value = "";
    };
    //states and functions for the "Material Consumed" screen, step 2 on the frontend

    const [saveDisabled, setSaveDisabled] = useState(true);

    const [consumedItems, setConsumedItems] = useState([]);
    const [inventoryUtilisedItems, setInventoryUtilisedItems] = useState([]);
    const [inputDisabled, setInputDisabled] = useState(true);


    const [billEntries, setBillEntries] = useState({});
    const Navigate = useNavigate();
    const [mappedValues, setMappedValues] = useState({});
    const [changes, setChanges] = useState([]);
    const [hasErrors, setHasErrors] = useState(false);
    const [hasErrors2, setHasErrors2] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isDisabled2, setIsDisabled2] = useState(true);

    const [inputValues, setInputValues] = useState({});
    const [inputValues2, setInputValues2] = useState({});
    const [Project_SO, setProjectSO] = useState({}); //Project_SO taken from formdata1
    console.log("Project_SO", Project_SO);



    const handleOnChange2 = (e, item, index) => {
        const { value } = e.target;
        const matchedItem = filteredItems.find(el => el.Item_Name === item.Item_Name && el.Part_Number === item.Part_Number);
    
        // Use Stock_Qty directly for comparison
        const stockQty = matchedItem ? matchedItem.Stock_Qty : 0;
    
        if (Number(value) > stockQty) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [index]: 'Dispatch quantity cannot exceed stock quantity'
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [index]: ''
            }));
        }
    
        setInputValues(prevValues => ({
            ...prevValues,
            [`${item?.Item_Name}_${item?.Part_Number}`]: parseInt(value)
        }));
    };






    console.log("setInventoryUtilisedItems", inventoryUtilisedItems);
    useEffect(() => {
        async function fetchConsumptions() {
            try {
                const res1 = await axios.get(
                    `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_consumed_get?Project_SO=${Project_SO}`
                );

                const itemsReceivedData = res1.data.data;

                // Set the state for items consumed
                setConsumedItems(itemsReceivedData);

                const newMappedValues = {};
                itemsReceivedData.forEach((item) => {
                    const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
                    newMappedValues[key] = item.Total_Qty_Used;
                });
                setMappedValues(newMappedValues);
            } catch (error) {
                console.error(error);
            }
        }
        fetchConsumptions();
    }, [Project_SO]);

    console.log("setConsumedItems", consumedItems);



    // const handleCheckboxChangeWip = (checked) => {
    //     setIsFillSameWip(checked);
    //     const newValues = {};
    
    //     filterWip.forEach((wip, index) => {
    //         if (checked) {
    //             const matchedItem = filteredItems.find(item => item.Item_Name === wip.Item_Name && item.Part_Number === wip.Part_Number);
    //             const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
    //             const availableQty = matchedItem ? matchedItem.Quantity_Received - totalQtyUsed : 0;
    //             newValues[index] = availableQty;  // Set availableQty regardless of its value (including zero)
    //         } else {
    //             newValues[index] = inputValues[index] || "";  // Retain the current input values if unchecked
    //         }
    //     });
    
    //     setInputValues(newValues);
    // };
    
    const handleCheckboxChangeWip = (checked) => {
        setIsFillSameWip(checked);
        if (checked) {
            // Fill inputValues with Stock_Qty from filteredItems
            const updatedInputValues = {};
            filteredItems.forEach(item => {
                updatedInputValues[`${item.Item_Name}_${item.Part_Number}`] = item.Stock_Qty;
            });
            setInputValues(updatedInputValues);
        } else {
            // Clear inputValues when checkbox is unchecked
            setInputValues({});
        }
    };
    
    



    const handleOnChange = (event) => {
        const { name, value } = event.target;

        setDispatchdata({
            ...dispatchdata,
            [name]: value
        })
    }

    const handlepurposechange = (e) => {
        setSelectedPurpose(e.target.value);
        setDispatchdata({
            ...dispatchdata,
            Purpose: e.target.value,
        });

    }

    useEffect(() => {
        const areAllInputsEmpty = () => {
            return Object.values(inputValues).every(
                (value) => value === "" || value === undefined || value === 0 || value === "0"
            );
        };

        setIsDisabled(areAllInputsEmpty());
    }, [inputValues, isCsp]);

    useEffect(() => {
        const areAllInputsEmpty = () => {
            return Object.values(inputValues2).every(
                (value) => value === "" || value === undefined || value === 0 || value === "0"
            );
        };

        setIsDisabled2(areAllInputsEmpty());
    }, [inputValues2, isCsp]);

    useEffect(() => {
        const fetchDeliveries = async () => {
            try {
                const response = await axios.get(
                    "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries2"
                );
                if (response.data.success) {
                    const hashArray = response.data.data.reduce((acc, current) => {
                        acc[current.S_No] = current.Entry_Bill_No;
                        return acc;
                    }, {});
                    setBillEntries(hashArray);
                } else {
                    console.error("Failed to fetch data:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDeliveries();
    }, []); // Empty dependency array ensures this runs only once on mount

    // useEffect(() => {
    //     async function fetchDetails() {
    //         try {
    //             // Fetch item details
    //             const res1 = await axios.get(
    //                 `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_get`
    //             );

    //             const itemsReceivedData = res1.data.data;
    //             console.log(itemsReceivedData);

    //             // Fetch Sales Order No data if needed
    //             const resSalesOrderNo = await axios.get(
    //                 `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
    //             );

    //             const data = resSalesOrderNo.data.data[0]; // Ensure data is correctly assigned

    //             const getdispatchdetails = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getsparesdispatchdetails`)

                

    //             // Fetch inventory utilization data
    //             const resInventoryUtil = await axios.get(
    //                 `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_projectSO_get?Project_SO=${data?.Sales_Order_No}`
    //             );

    //             // Create a map to store totalQtyUtilized for each item
    //             const inventoryUtilMap = resInventoryUtil.data.data.reduce((acc, inventoryItem) => {
    //                 const key = `${inventoryItem.Item_Name}_${inventoryItem.Part_Number}`;
    //                 acc[key] = (acc[key] || 0) + (parseFloat(inventoryItem.Qty_Utilized) || 0);
    //                 return acc;
    //             }, {});

    //             // Filter and update items with Stock_Qty and other details
    //             const filteredItems = itemsReceivedData.map((item) => {
    //                 const key = `${item.Item_Name}_${item.Part_Number}`;
    //                 const totalQtyUsed = mappedValues[`${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`] || 0;
    //                 const stockQty = inventoryUtilMap[key] || 0;

    //                 return {
    //                     ...item,
    //                     Stock_Qty: stockQty,
    //                     availableQty: item.Quantity_Received - totalQtyUsed,
    //                 };
    //             }).filter(item => item.availableQty !== 0);

    //             // Set the state for items received
    //             setFilteredItems(filteredItems);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }
    //     fetchDetails();
    // }, [mappedValues]);


    useEffect(() => {
  async function fetchDetails() {
    try {
      // Fetch item details
      const res1 = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_get`
      );
      const itemsReceivedData = res1.data.data.filter(
        (el) => el.Spares_Trading_Project_ID == id
      );
      console.log("itemsReceivedData", itemsReceivedData);

      // Fetch Sales Order No data
      const resSalesOrderNo = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
      );

      const data = resSalesOrderNo.data.data[0]; // Ensure data is correctly assigned

      setProjectSO(data?.Sales_Order_No);
      // Fetch inventory utilization data
      const resInventoryUtil = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_projectSO_get?Project_SO=${data?.Sales_Order_No}`
      );

      // Create a map to store totalQtyUtilized for each item
      const inventoryUtilMap = resInventoryUtil.data.data.reduce((acc, inventoryItem) => {
        const key = `${inventoryItem.Item_Name}_${inventoryItem.Part_Number}`;
        acc[key] = (acc[key] || 0) + (parseFloat(inventoryItem.Qty_Utilized) || 0);
        return acc;
      }, {});

      // Fetch the current dispatch details
      const getDispatchDetails = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getsparesdispatchdetails`, {
        params: {
          Project_ID: data.ID_No
        }
      });

      //   const dispatchDetails = getDispatchDetails.data.results;

      // Raw data from API
      const allDispatchDetails = getDispatchDetails.data.results;

      // Filter the data to ensure Project_ID matches `id` from useParams
      const dispatchDetails = allDispatchDetails.filter((item) => {
        return String(item.Project_ID) === String(id); // Convert both to strings for comparison
      });
    

      // Calculate total dispatched quantities for each item
      const dispatchQtyMap = dispatchDetails.reduce((acc, item) => {
        const key = `${item.Item_Name}_${item.Part_Number}`;
        acc[key] = (acc[key] || 0) + (parseFloat(item.Dispatch_qty) || 0);
        return acc;
      }, {});

      // Filter and update items with Stock_Qty and other details
      const filteredItems = itemsReceivedData.map((item) => {
        const key = `${item.Item_Name}_${item.Part_Number}`;
        const totalQtyUsed = inventoryUtilMap[key] || 0;
        const totalDispatchQty = dispatchQtyMap[key] || 0;
        const stockQty = totalQtyUsed - totalDispatchQty;

        return {
          ...item,
          Stock_Qty: stockQty,
          availableQty: totalQtyUsed - totalDispatchQty,
        };
      }).filter(item => item.availableQty > 0);

      // Set the state for items received
      setFilteredItems(filteredItems);
      console.log("filteredItems", filteredItems);
    } catch (error) {
      console.error(error);
    }
  }
  fetchDetails();
}, [mappedValues]);




    console.log("setFilteredItems", filteredItems);
    //data from inventory utilised
    useEffect(() => {
        async function fetchDetails() {
            try {
                const res1 = await axios.get(
                    `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_inventory_utilised?Project_SO=${Project_SO}`
                );

                const itemsReceivedData2 = res1.data.data;
                const filteredItems = itemsReceivedData2.filter((item) => {
                    const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
                    const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
                    return item.Qty_Utilized - totalQtyUsed !== 0;
                });

                // Set the state for items received
                setInventoryUtilisedItems(filteredItems);
            } catch (error) {
                console.error(error);
            }
        }
        fetchDetails();
    }, [Project_SO, mappedValues]);



    const handleInventoryClick = () => {
        setIsCsp(false);
    };

    const handleCspClick = () => {
        setIsCsp(true);
    };

    const handleCancel = () => {
        Navigate(-1);
    };



    useEffect(() => {
        const fetchInitialData = async () => {
            const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsproduced_get?Project_ID=${id}`);
            if (response.data && Array.isArray(response.data)) {
                const items = response.data.map(item => ({
                    ...item,
                    Stock_Qty: item.Stock_Qty || 0  // Ensure Stock_Qty is a number
                }));
                setFilteredItems(items);
                const initialValues = {};
                items.forEach(item => {
                    initialValues[`${item.Item_Name}_${item.Part_Number}`] = 0; // Initialize dispatch quantities as 0
                });
                setInputValues(initialValues);
            }
        };
        fetchInitialData();
    }, [id]);

    
    

    // const handleInputChange = (itemName, partNumber, value) => {
    //     if (isNaN(value) || value < 0) {
    //         setErrors({ ...errors, [partNumber]: "Invalid quantity" });
    //         return;
    //     }
    //     setInputValues(prev => ({
    //         ...prev,
    //         [partNumber]: value
    //     }));
    //     setErrors({ ...errors, [partNumber]: null }); // Clear error when valid
    // };

    const handleInputChange = (itemName, partNumber, stockQty, value) => {
        // If the input is empty or not a valid number
        if (value === "" || isNaN(value)) {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[`${itemName}_${partNumber}`]; // Clear error when input is empty or NaN
                return updatedErrors;
            });
    
            setInputValues(prev => {
                const updated = { ...prev };
                delete updated[`${itemName}_${partNumber}`]; // Clear the input value
                return updated;
            });
            return; // Exit since the input is invalid
        }
    
        // If the value is less than or equal to 0
        if (value < 0) {
            setInputValues(prev => ({
                ...prev,
                [`${itemName}_${partNumber}`]: value // Store the invalid value in inputValues
            }));
    
            setErrors(prevErrors => ({
                ...prevErrors,
                [`${itemName}_${partNumber}`]: "Invalid quantity" // Store error for invalid quantity
            }));
            return; // Exit since we handled the invalid value
        }
    
        // If the input is valid (greater than 0)
        setInputValues(prev => ({
            ...prev,
            [`${itemName}_${partNumber}`]: value // Update inputValues with valid number
        }));
    
        setErrors(prevErrors => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[`${itemName}_${partNumber}`]; // Clear error when input is valid
            return updatedErrors;
        });

        // If the value is more than the available stockQty
        if (value > stockQty) {
            setInputValues(prev => ({
                ...prev,
                [`${itemName}_${partNumber}`]: value // Store the invalid value in inputValues
            }));
    
            setErrors(prevErrors => ({
                ...prevErrors,
                [`${itemName}_${partNumber}`]: `Dispatch Qty can't exceed ${stockQty}` // Store error for invalid quantity
            }));
            return; // Exit since we handled the invalid value
        }
        
};
    
    
    console.log("errors", errors);
    console.log("isDisabled", isDisabled);
    console.log("dispatchdata", dispatchdata);
    console.log("inputValues", inputValues);

    // date changes
    const handleDates = (name, value) => {
        if (value !== null && value !== undefined) {
            const selectedDate = value.format("YYYY-MM-DD");
            setDispatchdata(prevState => ({
                ...prevState,
                [name]: selectedDate // Update the state with formatted date
            }));
            console.log(name, selectedDate);
        } else {
            console.log("Invalid Date");
        }
    };
    // mui styling for date picker
    const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
        "& .MuiPickersBasePicker-pickerView": {
            backgroundColor: "#DADCE0",
        },
        "& .MuiOutlinedInput-root": {
            width: "230px",
            "&:hover": {
                "& fieldset": {
                    borderColor: "1px solid #DADCE0",
                },
            },
            "&:not(.Mui-focused)": {
                "& fieldset": {
                    border: "1px solid #DADCE0",
                    borderRadius: "8px",
                },
            },
            "&.Mui-focused": {
                "& fieldset": {
                    border: "1px solid #DADCE0",
                    borderRadius: "8px",
                },
            },
        },
    }));

    // const handlecreate = async () => {
    //     try {
    //         let dispatchId = dispatchIdState; // Retrieve the dispatchId from state
    
    //         // Create a new FormData object for uploading
    //         const uploadFormData = new FormData();
    //         uploadFormData.append('Purpose', "Final Delivery"); // Hardcoded as per provided UI
    //         uploadFormData.append('Receiving_Party', formData.Customer_Name); // Based on the UI
    //         uploadFormData.append('Delivery_date', dispatchdata.Deliverydate); // Assuming date is in the desired format
    //         uploadFormData.append("Project_Id", id); // Assuming `id` is defined elsewhere in your code
    
    //         if (documentToUpload3) uploadFormData.append('PO_document', documentToUpload3);
    //         if (documentToUpload4) uploadFormData.append('Quote_document', documentToUpload4);
    
    //         if (!dispatchId) {
    //             // Send the first request to create a new dispatch if dispatchId is not already present
    //             const res = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/dispatch', uploadFormData, {
    //                 headers: {
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             });
    
    //             dispatchId = res.data.dispatch_id; // Assuming the API returns the dispatch_id
    //             setDispatchIdState(dispatchId); // Save the dispatchId to state
    //         }
    
    //         if (activeStep === 1) {
    //             const finalDispatchData = [];
    
    //             // Prepare data for final_dispatch based on active tab
    //             filteredItems.forEach((wip, index) => {
    //                 const qty = inputValues[wip?.Part_Number];
    //                 if (qty) {
    //                     finalDispatchData.push({
    //                         Project_ID: id,
    //                         dispatch_id: dispatchId,
    //                         Item_Name: wip.Item_Name,
    //                         Part_Number: wip.Part_Number,
    //                         UoM: wip.UoM,
    //                         Stock_Qty: wip.Stock_Qty-inputValues[wip?.Part_Number],
    //                         Dispatch_qty: qty,
    //                     });
    //                 }
    //             });
    
    //             // Send the data for final_dispatch
    //             await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_dispatch', finalDispatchData, {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //             });
    
    //             console.log('Data inserted successfully');
    //         }
    
    //         // Navigate after successful creation/update
    //         navigate(`/workshop-spares-trading-dispatch/${id}`);
    //     } catch (error) {
    //         console.error("Failed to create/update dispatch:", error);
    //     }
    // };
    
    const handlecreate= async () => {
        setIsLoading(true);
        // Validate the quantities before proceeding
        const valid = Object.keys(inputValues).every(key => 
            inputValues[key] <= filteredItems.find(item => `${item.Item_Name}_${item.Part_Number}` === key).Stock_Qty);
    
        if (!valid) {
            alert('Dispatch quantity exceeds stock quantity for one or more items.');
            return;
        }
    
        try {
            // Prepare FormData for any potential file uploads and additional data
            const uploadFormData = new FormData();
            uploadFormData.append('Purpose', "Final Delivery");
            uploadFormData.append('Receiving_Party', formData.Customer_Name);
            uploadFormData.append('Delivery_date', dispatchdata.Deliverydate);
            uploadFormData.append("Project_Id", id);
    
            if (documentToUpload3) uploadFormData.append('PO_document', documentToUpload3);
            if (documentToUpload4) uploadFormData.append('Quote_document', documentToUpload4);
    
            let dispatchId = dispatchIdState;
    
            if (!dispatchId) {
                const res = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/dispatch', uploadFormData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                dispatchId = res.data.dispatch_id; // Save the new dispatch ID from the response
                setDispatchIdState(dispatchId);
            }
    
            // After ensuring the dispatch ID is set, send the dispatch quantities
            const finalDispatchData = filteredItems.map(item => ({
                Project_ID: id,
                dispatch_id: dispatchId,
                Item_Name: item.Item_Name,
                Part_Number: item.Part_Number,
                UoM: item.UoM,
                Dispatch_qty: inputValues[`${item.Item_Name}_${item.Part_Number}`] || 0,
                Stock_Qty: item.Stock_Qty - (inputValues[`${item.Item_Name}_${item.Part_Number}`] || 0) // Adjusting stock quantities based on dispatch
            }));
    
            await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_dispatch', finalDispatchData, {
                headers: { "Content-Type": "application/json" },
            });

            const resInspection = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_outgoing_inspections_post', finalDispatchData);
    
// Construct the payload for deliveries
            const Inspection_ID = parseInt(dispatchId) - 47000;
            const Delivery_ID = parseInt(dispatchId) - 67000;
            const Delivery_Date = dispatchdata.Deliverydate;

            const deliveryPayload = {
                Project_ID: id,
                Dispatch_ID: dispatchId,
                Inspection_ID: Inspection_ID,
                Delivery_ID: Delivery_ID,
                Delivery_Date: Delivery_Date
            };

            // Post the delivery payload to the deliveries API
            await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_dispatch_outgoing_deliveries_post', deliveryPayload, {
                headers: { "Content-Type": "application/json" },
            });
    
            alert('Dispatch created successfully!');
            navigate(`/workshop-spares-trading-dispatch/${id}`);
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to create/update dispatch:", error);
        }
        setIsLoading(false);
    };
    


    // Render Buttons
    {
        activeStep === 1 &&
            <>
                <div
                    style={{
                        marginTop: "0px",
                        display: "flex",
                        flexDirection: "row",
                        flex: "1",
                    }}
                >

                </div>

                <button
                    className={`${styles["buttonsDivButton"]} ${styles["save"]}`}
                    onClick={handlecreate}
                    disabled={isDisabled || Object.keys(errors).length > 0}
                    style={{ opacity: isDisabled || Object.keys(errors).length > 0 ? 0.8 : 1 }} // Reduce opacity when disabled
                >
                    Create
                </button>
                <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]}`}
                    onClick={handleBackStep}>
                    Back
                </button>
                <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]}`}
                    onClick={handleProdBackButton}
                >
                    Cancel
                </button>
            </>
    }

    return (
        <div>
            <LoadingScreen isLoading={isLoading} />
            <div className={styles.topButtonContainer}>
                <button
                    className={styles.topBackButton}
                    onClick={handleProdBackButton}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
                            fill="#5B6BE1"
                        />
                    </svg>
                    {' '}Dispatch
                </button>

                <Box sx={{ width: '50%', mt: "8px", ml: "278px" }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#5B6BE1',
                                        '& .MuiStepLabel-label': {
                                            mt: '6px !important',
                                            fontWeight: 'bold !important',
                                            color: '#5B6BE1 !important',
                                        },
                                        '& .MuiStepLabel-label.Mui-active': {
                                            fontWeight: 'bold !important',
                                            color: '#5B6BE1 !important',
                                        },
                                        '& .MuiStepLabel-label.Mui-completed': {
                                            fontWeight: 'bold !important',
                                            color: '#5B6BE1 !important',
                                        },
                                    }}
                                >
                                    {step.label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

            </div>



            <div className={styles.mainDiv}>
                <div className={styles.buttonsdiv} style={{ minHeight: "63px", marginTop: "15px" }}>
                    {activeStep === 0 &&
                        <>
                            <div style={{ display: "flex", flexDirection: "column", flex: "1", justifyContent: "center", color: "#5B6BE1", marginLeft: "2%" }}>
                                <h4 style={{ borderBottom: "2px solid #5B6BE1", width: "fit-content", fontWeight: "bold" }}>
                                    Dispatch Details
                                </h4>
                            </div>
                            <button
                                className={styles.buttonsDivButton}
                                onClick={handleNextStep}
                            >
                                Next
                            </button>
                            <button
                                variant="contained"
                                className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                                    }`}
                                onClick={handleProdBackButton}>
                                Cancel
                            </button>
                        </>
                    }
                    {activeStep === 1 &&
                        <>

                            <>
                                <div
                                    style={{
                                        marginTop: "0px",
                                        display: "flex",
                                        flexDirection: "row",
                                        // justifyContent: "space-between",
                                        flex: "1",
                                    }}
                                >

                                </div>

                                <button
                                    className={`${styles["buttonsDivButton"]} ${styles["save"]
                                        }`}
                                    onClick={handlecreate}
                                    disabled={isDisabled || Object.keys(errors).length > 0 || isLoading}
                                    style={{ opacity: isDisabled || Object.keys(errors).length > 0 ? 0.8 : 1 }} // Reduce opacity when disabled
                                >
                                    Create
                                </button>
                                <button
                                    variant="contained"
                                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                                        }`}
                                    onClick={handleBackStep}>
                                    Back
                                </button>
                                <button
                                    variant="contained"
                                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                                        }`}
                                    onClick={handleProdBackButton}>
                                    Cancel
                                </button>
                            </>


                        </>
                    }
                </div>

                {activeStep === 0 && (
                    <>
                        <div className={styles.formElement}>
                            <label>Purpose:</label>
                            <input type="text"
                                        name="Final Delivery"
                                        value={"Final Delivery"}
                                        onChange={handleOnChange}
                                        disabled
                                    />
                         
                        </div>
                     
                      
                            <>
                                <div className={styles.formElement}>
                                    <label>Customer Name:</label>
                                    <input type="text"
                                        name="Customer_Name"
                                        value={formData.Customer_Name}
                                        onChange={handleOnChange}
                                        disabled
                                    />
                                </div>
                                <div className={`${styles.formElement} ${styles.datepickerInput}`}>
                                    <label>Delivery Date:</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <CustomDatePicker name="Deliverydate" value={dispatchdata.Deliverydate ? dayjs(dispatchdata.Deliverydate, "YYYY-MM-DD") : null} onChange={(value) => handleDates("Deliverydate", value)} slotProps={{ textField: { size: "small" } }} format="DD/MM/YYYY" sx={{ backgroundColor: "white", borderRadius: "8px" }} />
                                    </LocalizationProvider>
                                </div>
                            </>
                 
                    </>
                )}
                {activeStep === 1 && (
                    <div className={styles2.itemstablecontainer}>
                        <table className={styles2.itemstable}>
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Item Name</th>
                                    <th>Part Number</th>
                                    <th>UoM</th>
                                    <th>Available Qty for dispatch</th>
                                    <th style={{width: "min-content"}}>
                                        <div>
                                        Dispatch Qty
                                        </div>
                                        <input
                                            type="checkbox"
                                            checked={isfillsameWip}
                                            style={{
                                                width: "12px",
                                                height: "12px",
                                            }}
                                            onChange={(e) => handleCheckboxChangeWip(e.target.checked)}
                                        />
                                        <label
                                            style={{
                                                marginLeft: "5px",
                                                color: "#567191",
                                                fontWeight: "400",
                                                fontSize: "15px",
                                            }}
                                        >
                                            Fill Same
                                        </label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredItems.length === 0 ? (
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: 'center' }}>No results found</td>
                                    </tr>
                                ) : (
                                    filteredItems.map((item, index) => {
                                        const availableQty = item.Stock_Qty;

                                        const uniqueKey = `${item.Project_ID}_${item.Production_Type}_${item.Item_Name}_${item.Part_Number}_${index}`;

                                        return (
                                            <tr key={uniqueKey}>
                                                <td>{index + 1}</td>
                                                <td>{item.Item_Name}</td>
                                                <td>{item.Part_Number}</td>
                                                <td>{item.UoM}</td>
                                                <td>{item.Stock_Qty|| 0}</td>
                            <td>
                                <input 
                                    style={{ width: "60px" }}
                                    type="number" 
                                    value={inputValues[`${item.Item_Name}_${item.Part_Number}`] ? inputValues[`${item.Item_Name}_${item.Part_Number}`] : (inputValues[`${item.Item_Name}_${item.Part_Number}`] == 0 ? 0 : '') }
                                    onChange={e => handleInputChange(item.Item_Name, item.Part_Number, item.Stock_Qty, parseInt(e.target.value))}
                                    max={item.Stock_Qty}
                                />
                                {errors[`${item.Item_Name}_${item.Part_Number}`] && <p style={{ color: 'red' }}>{errors[`${item.Item_Name}_${item.Part_Number}`]}</p>}
                            </td>

                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>


                        </table>
                    </div>
                )}


            </div>
        </div>
    );
}

export default SparestradingDispatchCreatenew
