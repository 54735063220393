import { React, useState, useEffect,useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./css/inventory_utilization.module.css";
import { Box, Button, Fade, Modal, Backdrop, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import axios from "axios";
import Select from "react-select";
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Inventory_Utilization() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("New Entry"); //to set the purple background and white color style for active tab
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks

  const [salesOrderNos, setSalesOrderNos] = useState([]);
  const [data, setData] = useState([]);
  const [modaldata, setModaldata] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [itemsReceived2, setItemsReceived2] = useState([]);
  const [itemsReceived3, setItemsReceived3] = useState([]);
  const [data2, setData2] = useState([]); // State to store fetched data
  const [openModal2, setOpenModal2] = useState(false);
  const [partNumbers, setPartNumbers] = useState([]);
  const [totalsMap, setTotalsMap] = useState({}); // Hashmap for processed totals
  const [payload, setPayload] = useState([]);
  const [formdata, setFormData] = useState({
    Date: "",
    ItemName: null,
    AvailableQty: "",
    UoM: "",
    QtyUtilized: "",
    PartNumber: null,
    ProjectSO: "",
    ManufacturerPartNumber: "",
  });

  const [incomingDeliveryIds, setIncomingDeliveryIds] = useState([]);
  const [dispatchingParties, setDispatchingParties] = useState({});
  const [qtyDelta, setQtyDelta] = useState([]);
  const [qtyRejected, setQtyRejected] = useState([]);
  const [traceabilityNumber, setTraceabilityNumber] = useState([]);

  useEffect(() => {
    async function fetchDetails() {
      try {
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incoming_inspection_get`
        );

        if (Array.isArray(res1.data.data)) {
          let filteredItems = res1.data.data.filter((item) =>
            incomingDeliveryIds.includes(item.Incoming_Delivery_ID)
          );

          if (formdata.ItemName) {
            filteredItems = filteredItems.filter(
                (item) =>
                    item.Item_Name === formdata.ItemName &&
                    item.OEM_Part_Number === formdata.PartNumber
            ).sort((a, b) => b.Incoming_Delivery_ID - a.Incoming_Delivery_ID);
        }
        

          const qtyDelta = filteredItems.map((item) =>
            item.Qty_Delta !== null && item.Qty_Delta !== "" ? item.Qty_Delta : 0
          );
          const qtyRejected = filteredItems.map((item) =>
            item.Qty_Rejected !== null && item.Qty_Rejected !== "" ? item.Qty_Rejected : 0
          );

          const traceabilityNumber = filteredItems.reduce((acc, item) => {
            const key = `${item.Item_Name}_/${item.OEM_Part_Number}_/${item.Incoming_Delivery_ID}_/${item.Manufacturer_Part_Number}`;
            acc[key] = item.Traceability_Number;
            return acc;
          }, {});

          setQtyDelta(qtyDelta);
          setQtyRejected(qtyRejected);
          console.log("Filtered Items:", filteredItems);
          setTraceabilityNumber(traceabilityNumber);
        } else {
          console.error("Data received from API is not an array:", res1.data.data);
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    }

    fetchDetails();
  }, [incomingDeliveryIds, formdata.ItemName, formdata.PartNumber]); // Updated dependency array




  console.log("zzzzzzzz1", qtyDelta);
  console.log("zzzzzzzz2", qtyRejected);
  console.log("setTraceabilityNumber", traceabilityNumber);

  const totalUtilized = itemsReceived2.reduce((total, item) => {
    const utilized =
      item.Qty_Utilized !== null && item.Qty_Utilized !== ""
        ? parseInt(item.Qty_Utilized)
        : 0;
    return total + utilized;
  }, 0);

  useEffect(() => {
    async function fetchIncomingDeliveryIds() {
      try {
        // Fetch data from the API
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get9`
        );
        console.log("Items received:", res.data.data);

        // Extract Incoming_Delivery_Id and corresponding S_No (which is Incoming_Delivery_Id)
        const incomingDeliveryIds = res.data.data.map(
          (item) => item.Incoming_Delivery_ID
        );

        // Set the items received data in state
        setItemsReceived2(res.data.data);
        // Store Incoming_Delivery_Id in state
        setIncomingDeliveryIds(incomingDeliveryIds);

        // Filter the data based on formdata.ItemName or formdata.PartNumber
        filterData(res.data.data);
      } catch (error) {
        console.error(error);
      }
    }

    function filterData(data) {
      const filteredData = data
        .filter(
          (item) =>
            item.Item_Name === formdata.ItemName &&
            item.OEM_Part_Number === formdata.PartNumber 
        )
        .sort((a, b) => b.Incoming_Delivery_ID - a.Incoming_Delivery_ID);

      setItemsReceived3(filteredData);
    }

    fetchIncomingDeliveryIds();
  }, [formdata.ItemName, formdata.PartNumber]);


  console.log("received22", itemsReceived2); 

  const [billEntries, setBillEntries] = useState({});

  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries2"
        );
        if (response.data.success) {
          const hashArray = response.data.data.reduce((acc, current) => {
            acc[current.S_No] = current.Entry_Bill_No;
            return acc;
          }, {});
          setBillEntries(hashArray);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDeliveries();
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    // Filter out items where Scheduled_Sum is 0
    const filteredData = data.filter((item) => item.Scheduled_Sum > 0);

    // Extract Item_Name and Part_Number into separate arrays
    const itemNamesArray = filteredData.map((item) => item.Item_Name);
    const partNumbersArray = filteredData.map((item) => item.OEM_Part_Number);

    // Remove duplicate values from the arrays if needed
    const uniqueItemNames = [...new Set(itemNamesArray)];
    const uniquePartNumbers = [...new Set(partNumbersArray)];

    // Set the state with unique item names and part numbers
    setItemNames(uniqueItemNames);
    setPartNumbers(uniquePartNumbers);
  }, [data]);


  console.log("itemname", itemNames); 
  console.log("itemnam545e", data);

  useEffect(() => {
    const fetchDatain = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_summary2"
        ); // Assuming this is the endpoint you've set up
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDatain(); // Call the function to fetch data when the component mounts
  }, []); // Empty depe

  useEffect(() => {
    const fetchSalesOrderNos = async () => {
      try {
        // Fetch data from the first API
        const res1 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details_Sales_Order_No"
        );
        const salesOrderNosArray1 = res1.data.data.map((row) => ({
          label: row.Sales_Order_No,
          value: row.Sales_Order_No,
        }));
  
        // Fetch data from the second API
        const res2 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_trading_Sales_Order_No"
        );
        const salesOrderNosArray2 = res2.data.data.map((row) => ({
          label: row.Sales_Order_No,
          value: row.Sales_Order_No,
        }));
  
        // Combine both arrays
        const combinedSalesOrderNos = [...salesOrderNosArray1, ...salesOrderNosArray2];
  
        // Set the combined sales order numbers
        setSalesOrderNos(combinedSalesOrderNos);
      } catch (error) {
        console.error("Error fetching Sales Order Numbers:", error);
      }
    };

    fetchSalesOrderNos();
  }, []);
  const handleDivClick = (tab, route) => {
    setActiveTab(tab);
    navigate(`${route}`);
  };

  const handleBackButtonClick = () => {
    navigate(`/admintaskbar`);
  };

  const handleCancel = () => {
    navigate(`/admintaskbar`);
  };

  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#b2b3b5",
    },
    "& .MuiOutlinedInput-root": {
      width: "20.5vw",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #b2b3b5",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #b2b3b5",
          borderRadius: "6px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #394759",
          borderRadius: "6px",
        },
      },
    },
  }));

  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("YYYY-MM-DD");
      setFormData({ ...formdata, [name]: selectedDate });
    } else {
      console.log("Invalid Date");
    }
  };
  const handleCloseModal2 = () => setOpenModal2(false);
  const handleOpenModal2 = () => setOpenModal2(true);
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      // Extract label from the selected option
      const selectedValue = selectedOption.label;

      // Find all items in the data array that match the selected Item_Name
      const matchingItems = data.filter((item) => item.Item_Name === selectedValue);

      // Create a map to track unique OEM_Part_Number
      const uniqueItemsMap = new Map();

      // Filter to only include one item for each unique OEM_Part_Number
      const uniqueMatchingItems = matchingItems.filter((item) => {
        if (!uniqueItemsMap.has(item.OEM_Part_Number)) {
          uniqueItemsMap.set(item.OEM_Part_Number, true);
          return true;
        }
        return false;
      });

      // Check if there is only one unique matching item
      if (uniqueMatchingItems.length === 1) {
        // If there's only one matching item, set the formData including PartNumber
        const selectedItem = uniqueMatchingItems[0];
        setFormData((prevState) => ({
          ...prevState,
          ItemName: selectedItem.Item_Name,
          PartNumber: selectedItem.OEM_Part_Number,
          UoM: selectedItem.UoM,
          AvailableQty: selectedItem.Final_Total,
          ManufacturerPartNumber: selectedItem ? selectedItem.Manufacturer_Part_Number : "",
        }));
      } else {
        // If there are multiple unique matching items, don't set PartNumber
        setModaldata(uniqueMatchingItems);
        handleOpenModal2();
      }
    } else {
      // Handle the case where no option is selected
      // You might want to clear or reset formData here
      console.error("No option selected.");
    }
  };


  console.log("modaldata", modaldata);
  console.log("dataaaaaaaaa", data)
  const handleSelectChange2 = (selectedOption) => {
    if (selectedOption) {
      // Extract label from the selected option
      const selectedValue = selectedOption.label;

      // Find the item in the data array that matches the selected Item_Name
      const selectedItem = data.find(
        (item) => item.OEM_Part_Number === selectedValue
      );
      // Update formData with the corresponding values
      setFormData((prevState) => ({
        ...prevState,
        ItemName: selectedItem ? selectedItem.Item_Name : "",
        PartNumber: selectedItem ? selectedItem.OEM_Part_Number : "",
        UoM: selectedItem ? selectedItem.UoM : "",
        AvailableQty: selectedItem ? selectedItem.Final_Total : "",
        ManufacturerPartNumber: selectedItem ? selectedItem.Manufacturer_Part_Number : "",
      }));

      console.log("options", formdata);
    } else {
      // Handle the case where no option is selected
      // You might want to clear or reset formData here
      console.error("No option selected.");
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formdata, [name]: value });
  };

  const handleProjectSOChange = (selectedOption) => {
    if (selectedOption) {
      // Extract label from the selected option
      const selectedValue = selectedOption.label;

      // Update formData with the selected ProjectSO value
      setFormData((prevState) => ({
        ...prevState,
        ProjectSO: selectedValue,
      }));
    } else {
      // Handle the case where no option is selected
      // You might want to clear or reset formData here
      console.error("No option selected for ProjectSO.");
    }
  };

  const handleselect = (e, item) => {
    setFormData((prevState) => ({
      ...prevState,
      ItemName: item.Item_Name,
      UoM: item.UoM, // Just taking the first item's UoM for example
      AvailableQty: item.Final_Total, // Just taking the first item's AvailableQty for example
      PartNumber: item.OEM_Part_Number,
      ManufacturerPartNumber: item.Manufacturer_Part_Number
    }));
    setOpenModal2(false);
  };

  const handleSave = async (e) => {
    setIsLoading(true);
    const payload = compilePayload(); // Generate the payload

    const allHaveDateOfUtilization = payload.every(
      (item) => item.Date_of_Utilization
    );
    if (!allHaveDateOfUtilization) {
      alert("Date of Utilization is required.");
      setIsLoading(false);
      return; // Stop execution if any entry is missing the Date of Utilization
    }
    // Check if all entries have a ProjectSO
    const allHaveProjectSO = payload.every(
      (item) => item.ProjectSO && item.ProjectSO.value
    );

    if (!allHaveProjectSO) {
      alert("Filled enteries must have a Project SO selected.");
      setIsLoading(false);
      return; // Stop execution if any entry is missing the ProjectSO
    }

    try {
      const response = await fetch(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/post_inventory_utilizations",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send data: " + response.statusText); // Throwing an error if response is not OK
      }

      const responseData = await response.json(); // Assuming the server responds with JSON
      console.log("Success:", responseData); // Log or handle the successful response here
      alert("Inventory utilization details added successfully"); // Inform the user of success, adjust according to UI requirements
      setIsLoading(false);
      if (response.status == 200) window.location.reload();
    } catch (error) {
      console.error("Error during data submission:", error); // Error handling
      alert("Failed to send data. Please try again."); // Inform the user of failure, adjust according to UI requirements
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const [changes, setChanges] = useState([]);
  const [errors, setErrors] = useState({}); // State to track errors
  const [hasErrors, setHasErrors] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleOnChange2 = (e, item, index) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    const newValue = parseInt(trimmedValue, 10);
    const availableQty =
      item.Scheduled_Quantity +
      parseInt(qtyDelta[index], 10) -
      parseInt(qtyRejected[index], 10) -
      (data2.find(
        (d) =>
          d.Incoming_Delivery_ID.toString() ===
          item.Incoming_Delivery_ID.toString() &&
          d.Item_Name.toString() === item.Item_Name.toString() && d.Part_Number === item.OEM_Part_Number && d.Manufacturer_Part_Number===item.Manufacturer_Part_Number
      )?.Total || 0);

    // Update the state first (whether error or not)
    const newItem = { ...item, [name]: trimmedValue ? trimmedValue : null };
    updateChangesState(item.S_No, newItem, name);

    // Check if this is the QtyUtilized field and if the new value exceeds available quantity
    if (name === "QtyUtilized") {
      if (!isNaN(newValue) && newValue > availableQty) {
        // Set error if QtyUtilized is greater than available quantity
        setErrors((prev) => ({
          ...prev,
          [item.S_No]: `Quantity utilized cannot exceed ${availableQty}.`,
        }));
        setHasErrors(true);
      } else {
        // Remove any existing error for this item if the condition is no longer met
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[item.S_No];
          if (Object.keys(newErrors).length === 0) {
            setHasErrors(false);
          }
          return newErrors;
        });
      }
    }
  };

  const handleProjectSOChange2 = (selectedOption, item) => {
    const newItem = { ...item, ProjectSO: selectedOption };
    updateChangesState(item.S_No, newItem, "ProjectSO");
  };

  const updateChangesState = (sNo, newItem, changeKey) => {
    // Add Date_of_Utilization from formdata.Date to every new item
    const itemWithDate = {
      ...newItem,
      Date_of_Utilization: formdata.Date, // Ensure this date is added to each item
    };

    setChanges((prev) => {
      const index = prev.findIndex((c) => c.S_No === sNo);
      if (index >= 0) {
        // If the item exists, update it with the new information including the date
        const existingItem = prev[index];
        const updatedItem = { ...existingItem, ...itemWithDate };
        return [...prev.slice(0, index), updatedItem, ...prev.slice(index + 1)];
      } else {
        // If the item does not exist, add the new item with the date to the list
        return [...prev, itemWithDate];
      }
    });
  };

  const compilePayload = useCallback(() => {
    return itemsReceived3
      .map((item, index) => {
        const change = changes.find((ch) => ch.S_No === item.S_No);
        const finalItem = change ? { ...item, ...change } : item;

        // Calculate the AvailableQty for each item
        finalItem.AvailableQty =
          item.Scheduled_Quantity +
          parseInt(qtyDelta[index], 10) -
          parseInt(qtyRejected[index], 10) -
          (data2.find(
            (d) =>
              d.Incoming_Delivery_ID.toString() === item.Incoming_Delivery_ID.toString() &&
              d.Item_Name.toString() === item.Item_Name.toString() &&
              d.Part_Number === item.OEM_Part_Number && d.Manufacturer_Part_Number === item.Manufacturer_Part_Number
          )?.Total || 0);

        // Include the corresponding billEntry from the billEntries using the Incoming_Delivery_ID as key
        finalItem.BillEntry = billEntries[item.Incoming_Delivery_ID];

        // Add the Date of Utilization to each item
        finalItem.Date_of_Utilization = formdata.Date;

        // Add the traceability number based on the key format
        const key = `${item.Item_Name}_/${item.OEM_Part_Number}_/${item.Incoming_Delivery_ID}_/${item.Manufacturer_Part_Number}`;
        finalItem.TraceabilityNumber = traceabilityNumber[key] || "";

        // Update formdata state with the new traceability number and other relevant info
        setFormData((prevState) => ({
          ...prevState,
         
          traceabilityNumbers: {
            ...prevState.traceabilityNumbers,
            [key]: traceabilityNumber[key] || ""
          }
        }));

        // Filter out items where QtyUtilized is null or undefined
        if (finalItem.QtyUtilized != null && finalItem.QtyUtilized !== "") {
          return finalItem;
        }
        return null; // Return null for items that do not meet the condition
      })
      .filter((item) => item !== null); // Filter out null entries from the array
  }, [itemsReceived3, changes, qtyDelta, qtyRejected, billEntries, traceabilityNumber, data2, formdata.Date, setFormData]);

  useEffect(() => {
    const newPayload = compilePayload();
    setPayload(newPayload);
    setIsDisabled(newPayload.length === 0); // Disable the button if payload is empty
  }, [compilePayload]);

  useEffect(() => {
    console.log("Payload:", payload);
    console.log("Updated Form Data with Traceability Numbers:", formdata);
  }, [payload, formdata]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_inventory_utilizations"
        );
        const jsonData = await response.json();
        if (jsonData.success) {
          setData2(jsonData.data); // Set the fetched data
        } else {
          alert(jsonData.message); // Alert if no data found or other message from server
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Failed to fetch data.");
      }
    };

    fetchData();
  }, []);

  console.log("data2", data2);
  console.log("IR3", itemsReceived3);

  function findAndLogMatches() {
    itemsReceived3.forEach((item) => {
      console.log(
        `Checking matches for item S_No: ${item.S_No}, Item Name: ${item.Item_Name}`
      );

      // Find a match in data2. Convert Incoming_Delivery_ID to string for matching.
      const match = data2.find(
        (d) =>
          d.Incoming_Delivery_ID === item.Incoming_Delivery_ID.toString() &&
          (d.Item_Name === item.Item_Name || d.Part_Number === item.OEM_Part_Number) &&
          (d.Manufacturer_Part_Number===item.Manufacturer_Part_Number)
      );

      // Log the result
      if (match) {
        console.log(`Match found for S_No: ${item.S_No}:`, match);
      } else {
        console.log(`No match found for S_No: ${item.S_No}`);
      }
    });
  }

  // Execute the function
  findAndLogMatches();

  return (
    <div className={styles.topdiv}>
      <LoadingScreen isLoading={isLoading} />
      <div className={styles.topRowDiv}>
        <svg
          width="40"
          height="26"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <path
            d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
            fill="white"
          />
        </svg>
        <h3 style={{ marginLeft: "1.25%", paddingBottom: "0.2%" }}>
          Details of Inventory Utilization
        </h3>
      </div>

      <div className={styles.sections1}>
        {" "}
        {/*Top_Bar_Div*/}
        <div
          className={
            activeTab === "New Entry" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() => handleDivClick("New Entry", "/Inventory_Utilization")}
        >
          New Entry
        </div>
        <div
          className={
            activeTab === "Transaction Logs" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick("Transaction Logs", "/InventoryUtilizationLogs")
          }
        >
          Transaction Logs
        </div>
      </div>

      <div className={styles.sections2}>
        <div>
          <div className={styles.editAndSaveButtons}>
            <>
              <button
                variant="contained"
                onClick={handleSave}
                className={
                  hasErrors || isDisabled
                    ? styles.saveButton2
                    : styles.saveButton
                }
                disabled={hasErrors || isDisabled || isLoading}
              >
                Save
              </button>

              <button
                variant="outlined"
                onClick={handleCancel}
                className={styles.cancelBtn}
              >
                Exit
              </button>
            </>
          </div>
        </div>

        <div style={{ marginLeft: "35px", marginTop: "15px" }}>
          {/*  Date */}
          <div className={`${styles.formElement} ${styles.datepickerInput}`}>
            <label
              style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
            >
              Date of Inventory Utilization*:
            </label>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="Date"
                  value={
                    formdata.Date ? dayjs(formdata.Date, "YYYY-MM-DD") : null
                  }
                  onChange={(value) => handleDates("Date", value)}
                  slotProps={{ textField: { size: "small" } }}
                  format="DD-MM-YYYY"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "6px",
                    boxShadow: "none", // Corrected property name
                    marginLeft: "25px",
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          {/*  Date Ends */}
          <div style={{ display: "flex" }}>
            <div
              className={styles.formElement}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ width: "270px" }}>
                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginRight: "auto", // Pushes the label to the left end
                  }}
                >
                  Item Name:
                </label>
              </div>
              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  // marginLeft: "10.5vw", // Pushes the Select component to the right end
                }}
              >
                <Select
                  // isMulti
                  value={{
                    label: formdata.ItemName || "Type to search...", // Set the placeholder text if ItemName is empty
                    value: formdata.ItemName,
                  }}
                  options={itemNames.map((itemName) => ({
                    value: itemName,
                    label: itemName,
                  }))}
                  onChange={handleSelectChange}
                  className={styles.textbox1z}
                  name="ItemName"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      overflowY: "hidden",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the clear indicator
                    }),
                    menu: (provided) => ({
                      ...provided,
                      right: "0px", // Move the menu 10px to the left
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                  }}
                />
              </div>
            </div>
            <Modal
              open={openModal2}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
              sx={{
                backdropFilter: "blur(4px)",
              }}
            >
              <Fade in={openModal2}>
                <Box
                  sx={{
                    width: "50vw",
                    height: "63vh",
                    p: 3,
                    backgroundColor: "#fff",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "2vh",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: "18vw"
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        style={{
                          textAlign: "center",
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: "600",
                          fontSize: "1.5rem",
                          color: "#FFC107",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          fill="#FFC107"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          viewBox="0 0 160 150"
                        >
                          <g>
                            <path
                              d="M9.821,118.048h104.4c7.3,0,12-7.7,8.7-14.2l-52.2-92.5c-3.601-7.199-13.9-7.199-17.5,0l-52.2,92.5
                  C-2.179,110.348,2.521,118.048,9.821,118.048z M70.222,96.548c0,4.8-3.5,8.5-8.5,8.5s-8.5-3.7-8.5-8.5v-0.2c0-4.8,3.5-8.5,8.5-8.5
                  s8.5,3.7,8.5,8.5V96.548z M57.121,34.048h9.801c2.699,0,4.3,2.3,4,5.2l-4.301,37.6c-0.3,2.7-2.1,4.4-4.6,4.4s-4.3-1.7-4.6-4.4
                  l-4.301-37.6C52.821,36.348,54.422,34.048,57.121,34.048z"
                            />
                          </g>
                        </svg>
                        Alert
                      </Typography>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={handleCloseModal2}
                      viewBox="0 0 25 25"
                      style={{
                        width: "23px",
                        height: "23px",
                      }}
                      id="x-circle"
                    >
                      <circle
                        cx="12.5"
                        cy="12.5"
                        r="10"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      ></circle>
                      <line
                        x1="8"
                        x2="17"
                        y1="8"
                        y2="17"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      ></line>
                      <line
                        x1="8"
                        x2="17"
                        y1="17"
                        y2="8"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      ></line>
                    </svg>
                  </div>
                  <div style={{ paddingLeft: "1.5%", marginBottom: "3vh", color: "#5B6BE1" }}>
                    <h2 style={{ fontSize: "1.3rem" }}>
                      Item <u><b>{modaldata[0]?.Item_Name}</b></u> exists with the following part numbers in Ergon Inventory. Please select one to proceed:
                    </h2>
                  </div>
                  <div style={{ height: "35vh", overflowY: "scroll", scrollbarColor: "#888 #f1f1f1", scrollbarWidth: "thin" }}>
                    <table className={styles.itemstable2}>
                      <thead style={{ position: "sticky", top: 0 }}>
                        <tr>
                          <th>S.no.</th>
                          <th>Item Name</th>
                          <th>Part Number</th>
                          <th>Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modaldata.length === 0 ? (
                          <tr>
                            <td colSpan="4">No result found</td>
                          </tr>
                        ) : (
                          modaldata.map((item, index) => (
                            <tr key={item.S_No}>
                              <td>{index + 1}</td>
                              <td>{item.Item_Name}</td>
                              <td>{item.OEM_Part_Number}</td>
                              <td>
                                <button
                                  variant="contained"
                                  onClick={(e) => handleselect(e, item)}
                                  className={styles.saveButton}
                                >
                                  Select
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </Box>
              </Fade>
            </Modal>

            <div className={styles.formElement}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",

                  marginLeft: "10vw",

                  flexWrap: "wrap",
                }}
              >
                Part Number:
              </label>

              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Select
                  value={{
                    label: formdata.PartNumber || "Type to search...",
                    value: formdata.PartNumber,
                  }}
                  options={partNumbers.map((partNumber) => ({
                    value: partNumber,
                    label: partNumber,
                  }))}
                  onChange={handleSelectChange2} // You need to provide the onChange handler
                  placeholder="Type to search..."
                  className={styles.textbox1z}
                  name="PartNumber"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      overflowY: "hidden",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the clear indicator
                    }),
                    menu: (provided) => ({
                      ...provided,
                      right: "0px", // Move the menu 10px to the left
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>BOE</th>
                  <th>Traceability Number</th>
                  <th>Manufacturer Part No.</th>
                  <th>Location</th>
                  <th>UoM</th>
                  <th>Available Qty</th>
                  <th>Qty Utilized</th>
                  <th>Project SO*</th>
                </tr>
              </thead>
             <tbody>
  {itemsReceived3.length === 0 ? (
    <tr>
      <td colSpan="11"></td>
    </tr>
  ) : (
    itemsReceived3.map((item, index) => {
      const key = `${item.Item_Name}_/${item.OEM_Part_Number}_/${item.Incoming_Delivery_ID}_/${item.Manufacturer_Part_Number}`;
      const traceability = traceabilityNumber[key] || "";

      const computedValue =
        item.Scheduled_Quantity +
        parseInt(qtyDelta[index], 10) -
        parseInt(qtyRejected[index], 10) -
        (data2.find(
          (d) =>
            d.Incoming_Delivery_ID.toString() === item.Incoming_Delivery_ID.toString() &&
            d.Item_Name.toString() === item.Item_Name.toString() &&
            d.Part_Number === item.OEM_Part_Number && d.Manufacturer_Part_Number===item.Manufacturer_Part_Number
        )?.Total || 0);

      if (computedValue === 0) {
        return null;
      }

      return (
        <tr key={item.S_No}>
          <td>{index + 1}</td>
          <td>{item.Item_Name}</td>
          <td>{item.OEM_Part_Number}</td>
          <td>{billEntries[item.Incoming_Delivery_ID]}</td>
          <td>{traceability}</td>
          <td>{item.Manufacturer_Part_Number}</td>
          <td>{item.Location}</td>
          <td>{item.UoM}</td>
          <td>{computedValue}</td>
          <td>
            <input
              type="Number"
              defaultValue={item.QtyUtilized}
              name="QtyUtilized"
              onChange={(e) => handleOnChange2(e, item, index)}
              style={{ width: "5vw" }}
            />
            {errors[item.S_No] && (
              <div
                style={{
                  color: "red",
                  fontSize: "0.8rem",
                  fontWeight: "normal",
                }}
              >
                {errors[item.S_No]}
              </div>
            )}
          </td>
          <td>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Select
                options={salesOrderNos}
                onChange={(selectedOption) => handleProjectSOChange2(selectedOption, item)}
                className={styles.textbox1z2}
                name="ProjectSO"
                placeholder=""
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    boxShadow: "none",
                    cursor: "pointer",
                    overflowY: "hidden",
                  }),
                  clearIndicator: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    right: 0,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px",
                    overflowY: "scroll",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#c5c5c5 #f5f5f5",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    fontWeight: "normal",
                  }),
                }}
              />
            </div>
          </td>
        </tr>
      );
    })
  )}
</tbody>


            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Inventory_Utilization;