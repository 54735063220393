import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_trading_update.module.css";
import modalStyles from "./css/workshop_spares_trading_update_modal.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/system";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import dayjs from "dayjs";
import TabsBarSparesTrading from './TabsBarSparesTrading';
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiPickersBasePicker-pickerView": {
    backgroundColor: "#DADCE0",
  },
  "& .MuiOutlinedInput-root": {
    width: "230px",
    "&:hover": {
      "& fieldset": {
        borderColor: "1px solid #DADCE0",
      },
    },
    "&:not(.Mui-focused)": {
      "& fieldset": {
        border: "1px solid #DADCE0",
        borderRadius: "8px",
      },
    },
    "&.Mui-focused": {
      "& fieldset": {
        border: "1px solid #DADCE0",
        borderRadius: "8px",
      },
    },
  },
  "& input.Mui-disabled": {
    opacity: 1,
    "-webkit-text-fill-color": "black", // For WebKit-based browsers
    color: "black", // Fallback for other browsers
  },
}));

const SparesTradingDetailsUpdate = () => {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [refresh, setRefresh] = useState(false);
  const [formData, setFormData] = useState({
    Sales_Order_No: "",
    PO_No: "",
    PO_Date: null,
    Quote_No: "",
    Quote_Date: null,
    Costing_ID: "",
    Customer_Name: "",
    Scope: "",
    Rig_Name: "",
    Scope_Description: "",
    Estimated_Date_Of_Dispatch: null,
    Ops_Engineer: "",
    Current_Status: "",
  });
  const [originalData, setOriginalData] = useState({});

  const userEmail = localStorage.getItem('useremail');
  if (userEmail) {
    console.log('Found value:', userEmail);
  } else {
    console.log('No value found');
  }

  const [dispatchDateLogData, setDispatchDateLogData] = useState([]);
  const [openModal1, setOpenModal1] = useState(false);    //to open the Dispatch Date Edit modal
  const [openModal2, setOpenModal2] = useState(false);    //to open the Dispatch Date changes log modal


  const [dispatchDateFormData, setDispatchDateFormData] = useState({
    Order_ID: id,
    Previous_Dispatch_Date: "",
    New_Dispatch_Date: "",
    Done_By: "",
    Reason_For_Change: "",
  });

  console.log("Dispatch Date Form Data", dispatchDateFormData);
  console.log("formData", formData);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );

        const dispatchDateLogRes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/spares_trading_dispatch_date_change_log_get_all?Order_ID=${id}`
        );

        const userRes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_user_details_project_details?Email=${userEmail}`
        );

        if (response.data.success) {
          const data = response.data.data[0];
          setFormData({
            Sales_Order_No: data.Sales_Order_No,
            PO_No: data.PO_No,
            PO_Date: data.PO_Date,
            Quote_No: data.Quote_No,
            Quote_Date: data.Quote_Date,
            Costing_ID: data.Costing_ID,
            Customer_Name: data.Customer_Name,
            Scope: data.Scope,
            Rig_Name: data.Rig_Name,
            Scope_Description: data.Scope_Description,
            Estimated_Date_Of_Dispatch: data.Estimated_Date_Of_Dispatch,
            Ops_Engineer: data.Ops_Engineer,
            Current_Status: data.Current_Status,
          });
          setOriginalData(data);

          setDispatchDateLogData(dispatchDateLogRes.data.data);

          setDispatchDateFormData(prevState => ({
            ...prevState,
            Previous_Dispatch_Date: data?.Estimated_Date_Of_Dispatch,
            Done_By: userRes.data.data[0].Name,
            Reason_For_Change: data?.Estimated_Date_Of_Dispatch ? prevState.Reason_For_Change : "Order Details Added"
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
      setIsLoading(false);
    };

    fetchData();
  }, [id, refresh]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value ? value.format("YYYY-MM-DD") : null,
    });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Extract relevant fields from formData
    const {
      Sales_Order_No,
      PO_No,
      PO_Date,
      Quote_No,
      Quote_Date,
      Costing_ID,
      Customer_Name,
      Scope,
      Rig_Name,
      Scope_Description,
      // Estimated_Date_Of_Dispatch,
      Ops_Engineer,
      Current_Status,
    } = formData;

    // Check if Estimated_Date_Of_Dispatch and Current_Status are missing or empty
    // if (!Estimated_Date_Of_Dispatch) {
    //   alert('Please enter "Estimated Date of Dispatch"');
    //   return; // Stop execution if the field is empty
    // }

    if (!Current_Status) {
      alert('Please enter "Current Status"');
      return; // Stop execution if the field is empty
    }


    // Construct the payload for the API request
    const payload = {
      Sales_Order_No,
      PO_No,
      PO_Date,
      Quote_No,
      Quote_Date,
      Costing_ID,
      Customer_Name,
      Scope,
      Rig_Name,
      Scope_Description,
      // Estimated_Date_Of_Dispatch,
      Ops_Engineer,
      Current_Status,
    };

    try {
      // Make the API call to update the data
      const response = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_put?ID_No=${id}`, payload);

      if (response.data.success) {
        // If the update is successful, update the originalData and reset the form to non-editable mode
        setOriginalData(formData);
        setEditMode(false);
      }

      // Optionally refresh the page or component data
      // window.location.reload(); // Uncomment if you want to refresh the entire page

    } catch (error) {
      console.error('Error saving data:', error);
      // Handle error here
    }
  };

  const handleCancel = () => {
    setFormData(originalData);
    setEditMode(false);
  };


  const handleOpenModal1 = () => {
    setOpenModal1(true);
    console.log(openModal1);
  }
  const handleOpenModal2 = () => {
    setOpenModal2(true);
    console.log(openModal2);
  }

  const handleCloseModal1 = () => {
    setOpenModal1(false);
    setDispatchDateFormData(prevState => ({
      ...prevState,
      Previous_Dispatch_Date: "",
      New_Dispatch_Date: "",
      Done_By: "",
      Reason_For_Change: "",
    }));
    setRefresh(!refresh);
  }

  const handleCloseModal2 = () => {
    setOpenModal2(false)
  };

  function handleDateChangeReason(event) {
    const { name, value } = event.target;

    setDispatchDateFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleDispatchDate = (name, value) => {
    const selectedDate = value ? value.format("YYYY-MM-DD") : null;

    console.log("selectedDate", selectedDate);
    console.log("formData.Estimated_Date_Of_Dispatch", formData.Estimated_Date_Of_Dispatch);
    console.log("dispatchDateFormData.New_Dispatch_Date", dispatchDateFormData.New_Dispatch_Date);

    if (selectedDate && selectedDate === dispatchDateFormData.Previous_Dispatch_Date) {
      setDispatchDateFormData({ ...dispatchDateFormData, New_Dispatch_Date: "" });
      alert("No Date Change Detected");
      return;
    }
    else if (selectedDate && selectedDate !== dispatchDateFormData.Previous_Dispatch_Date) {
      setDispatchDateFormData({ ...dispatchDateFormData, New_Dispatch_Date: selectedDate })
      setFormData({ ...formData, [name]: selectedDate });
    }

    console.log("formData.Estimated_Date_Of_Dispatch", formData.Estimated_Date_Of_Dispatch);
    console.log("dispatchDateFormData.New_Dispatch_Date", dispatchDateFormData.New_Dispatch_Date);
  };


  const handleSaveDispatchDate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Extracting values from the state
    let { Order_ID, Previous_Dispatch_Date, Done_By, Reason_For_Change } = dispatchDateFormData;
    const New_Dispatch_Date = formData.Estimated_Date_Of_Dispatch;
    const Timestamp = new Date();  // Current date and time in ISO format

    // Convert empty string to null for Previous_Dispatch_Date to prevent empty string post into the database
    if (!Previous_Dispatch_Date) {
      Previous_Dispatch_Date = null;
    }

    // Validations
    if (!formData.Estimated_Date_Of_Dispatch || !dispatchDateFormData.New_Dispatch_Date) {
      alert("Please enter a Valid Date");
      return;
    }
    if (New_Dispatch_Date === Previous_Dispatch_Date) {
      alert("No Date Change Detected");
      return;
    }
    if (!Reason_For_Change) {
      alert("Please enter a Reason for Date Change");
      return;
    }

    // Construct the payload
    const payload = {
      Order_ID,
      Previous_Dispatch_Date,
      New_Dispatch_Date,
      Done_By,
      Reason_For_Change,
      Timestamp
    };

    const projPayload = {
      Estimated_Date_Of_Dispatch: formData.Estimated_Date_Of_Dispatch,
    }

    // sendNotificationToAll(payload);

    // Set modal states
    setOpenModal1(false);
    setOpenModal2(false);

    setDispatchDateFormData(prevState => ({
      ...prevState,
      New_Dispatch_Date: "",
      Reason_For_Change: "",
    }));

    // API call
    try {
      const response = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/spares_trading_dispatch_date_change_log', payload);
      
      const projRes = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_put?ID_No=${id}`, projPayload);
      
      console.log("response, projRes", response.data, projRes.data);
            
      // Set modal states
      setOpenModal1(false);
      setOpenModal2(false);
      
      setDispatchDateFormData(prevState => ({
        ...prevState,
        New_Dispatch_Date: "",
        Reason_For_Change: "",
      }));
      if (response.status === 200 && projRes.status === 200) {
        setRefresh(!refresh);
      }
    } catch (error) {
      console.error("Failed to save data:", error);
    }
    setIsLoading(false);
  };


  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }

    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  return (
    <div>
      <LoadingScreen isLoading={isLoading} />

      <div className={styles.topdiv}>
        <label className={styles.aryl1}>
          SO: {formData.Sales_Order_No}
        </label>
        <label className={styles.aryl}>
          PO: {formData.PO_No}
        </label>
        <label className={styles.aryl}>
          Customer Name: {formData.Customer_Name}
        </label>
      </div>

      <TabsBarSparesTrading activeTab="Project Details" />

      <div className={styles.sections}>
        <div className={styles.buttonsdiv}>
          {editMode ? (
            <div style={{ display: "flex" }}>
              <button
                variant="contained"
                onClick={handleSave}
              >
                Save Changes
              </button>
              <button
                variant="outlined"
                onClick={handleCancel}
                className={styles.cancel}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              variant="contained"
              onClick={() => handleEdit()}
            >
              Edit
            </button>
          )}
        </div>

        <form style={{ display: "flex", justifyContent: "space-around" }}>
          <div className={styles.splitHalfSection}>
            <div className={styles.formElement}>
              <label>Sales Order No</label>
              <input
                type="text"
                name="Sales_Order_No"
                value={formData.Sales_Order_No}
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div className={styles.formElement}>
              <label>PO Number</label>
              <input
                type="text"
                name="PO_No"
                value={formData.PO_No}
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div
              className={`${styles.formElement} ${styles.datepickerInput}`}
            >
              <label>PO Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="PO_Date"
                  value={
                    formData.PO_Date ? dayjs(formData.PO_Date, "YYYY-MM-DD") : null
                  }
                  onChange={(value) => handleDateChange("PO_Date", value)}
                  slotProps={{ textField: { size: "small" } }}
                  format="DD/MM/YYYY"
                  disabled={!editMode}
                  className={editMode ? null : styles.textbox2}
                />
              </LocalizationProvider>
            </div>

            <div className={styles.formElement}>
              <label>Quote No</label>
              <input
                type="text"
                name="Quote_No"
                value={formData.Quote_No}
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div
              className={`${styles.formElement} ${styles.datepickerInput}`}
            >
              <label>Quote Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="Quote_Date"
                  value={
                    formData.Quote_Date
                      ? dayjs(formData.Quote_Date, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(value) =>
                    handleDateChange("Quote_Date", value)
                  }
                  slotProps={{ textField: { size: "small" } }}
                  format="DD/MM/YYYY"
                  disabled={!editMode}
                  className={editMode ? null : styles.textbox2}
                />
              </LocalizationProvider>
            </div>

            <div className={styles.formElement}>
              <label>Costing ID</label>
              <input
                type="text"
                name="Costing_ID"
                value={formData.Costing_ID}
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div className={styles.formElement}>
              <label>Customer Name</label>
              <select
                value={formData.Customer_Name}
                name="Customer_Name"
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              >
                <option value="">Select value</option>
                <option value="Shelf Drilling">Shelf Drilling</option>
                <option value="Valaris">Valaris</option>
                <option value="ARO Drilling">ARO Drilling</option>
                <option value="ADES">ADES</option>
                <option value="COC">COC</option>
                <option value="Northern Offshore">Northern Offshore</option>
                <option value="Melius">Melius</option>
                <option value="Goltens Co Ltd. Dubai Branch">Goltens Co Ltd. Dubai Branch</option>
                <option value="Foresight Offshore Drilling Limited S.A.">Foresight Offshore Drilling Limited S.A.</option>
              </select>
              <svg
                style={{ position: "absolute", right: "15" }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                  fill="black"
                />
              </svg>
            </div>


          </div>

          <div className={styles.splitHalfSection}>
            <div className={styles.formElement}>
              <label>Scope</label>
              <input
                type="text"
                name="Scope"
                value={formData.Scope}
                className={styles.textbox1}
                disabled={true}
                readOnly={true}
              />
            </div>

            <div className={styles.formElement}>
              <label>Scope Description</label>
              <textarea
                name="Scope_Description"
                value={formData.Scope_Description}
                onChange={handleInputChange}
                className={editMode ? styles.textbox1b : styles.textbox1a}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div
              className={`${styles.formElement} ${styles.datepickerInput}`}
            >
              <label>Estimated Date Of Dispatch *</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="Estimated_Date_Of_Dispatch"
                  value={
                    formData.Estimated_Date_Of_Dispatch
                      ? dayjs(formData.Estimated_Date_Of_Dispatch, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(value) =>
                    handleDateChange("Estimated_Date_Of_Dispatch", value)
                  }
                  slotProps={{ textField: { size: "small" } }}
                  format="DD/MM/YYYY"
                  disabled={true}
                  className={styles.textbox2}
                />
              </LocalizationProvider>

              {/* Pencil Icon to edit Estimated date of dispatch*/}
              {!editMode && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  onClick={() => !editMode && handleOpenModal1()}
                  style={{ marginLeft: "10px", cursor: editMode ? "default" : "pointer" }}
                >
                  <path
                    d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z"
                    fill="black"
                  />
                </svg>
              )}

              {/* Popup Icon to view Date of Dispatch change logs*/}
              {!editMode && (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  onClick={() => !editMode && handleOpenModal2()}
                  style={{ marginLeft: "10px", pointerEvents: "all", cursor: editMode ? "default" : "pointer" }}
                >
                  <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
                </svg>
              )}

            </div>

            <div className={styles.formElement}>
              <label>Rig Name</label>
              <input
                type="text"
                name="Rig_Name"
                value={formData.Rig_Name}
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>
            
          </div>

          <div className={styles.splitHalfSection}>
            <div className={styles.formElement}>
              <label>Ops Engineer</label>
              <select
                value={formData.Ops_Engineer}
                name="Ops_Engineer"
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              >
                <option value=""> Select the Member</option>
                <option value="Jancy">Jancy</option>
                <option value="Charulatha">Charulatha</option>
              </select>
              <svg
                style={{ position: "absolute", right: "15" }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                  fill="black"
                />
              </svg>
            </div>


            <div className={styles.formElement}>
              <label>Current Status *</label>
              <textarea
                name="Current_Status"
                value={formData.Current_Status}
                onChange={handleInputChange}
                className={editMode ? styles.textbox1b : styles.textbox1a}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>
          </div>
        </form>

        {/* Estimated Date of Dispatch Edit Modal */}
        <Modal
          open={openModal1}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            backdropFilter: "blur(4px)",
          }}
        >
          <Fade in={openModal1}>
            <Box
              sx={{
                width: "45vw",
                minHeight: "45vh",
                maxHeight: "85vh",
                p: 3,
                backgroundColor: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between", // Changed to space-between to push the last item to the right
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "19vw",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{
                      textAlign: "center",
                      fontFamily: "'Open Sans', sans-serif", // Ensure the font is available or imported
                      fontWeight: "bold",
                      fontSize: "25px",
                      color: "#5B6BE1",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      fill="#5B6BE1"
                      width="28"
                      height="28"
                      style={{ marginRight: "10px", marginBottom: "6px" }}
                      viewBox="0 0 22 22"
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 24 24"
                    >
                      <path d="M12,2C12,2,12,2,12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,17c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,17,12,17z M13,12c0,0.6-0.4,1-1,1s-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1V12z" />
                    </svg>
                    Edit
                  </Typography>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleCloseModal1}
                  viewBox="0 0 25 25"
                  style={{
                    width: "23px", // Smaller size
                    height: "23px", // Smaller size
                    cursor: "pointer",
                  }}
                  id="x-circle"
                >
                  <circle
                    cx="12.5"
                    cy="12.5"
                    r="10"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></circle>
                  <line
                    x1="8"
                    x2="17"
                    y1="8"
                    y2="17"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    x1="8"
                    x2="17"
                    y1="17"
                    y2="8"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                </svg>
              </div>

              <div style={{ display: "flex" }}>
                <h3 style={{ fontSize: "18px", width: "22vw" }}>
                  Current Dispatch date:
                </h3>
                <h3 style={{ fontSize: "18px" }}>
                  {dispatchDateFormData.Previous_Dispatch_Date ? formatDate(dispatchDateFormData.Previous_Dispatch_Date) : ""}
                </h3>
              </div>

              <div style={{ display: "flex", marginTop: "4.5vh", alignItems: "center" }}>
                <h3 style={{ fontSize: "18px", width: "22vw" }}>
                  New Dispatch date:{" "}
                </h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="Estimated_Date_Of_Dispatch"
                      value={dispatchDateFormData.New_Dispatch_Date ? dayjs(dispatchDateFormData.New_Dispatch_Date, "YYYY-MM-DD") : null}
                      onChange={(value) =>
                        handleDispatchDate(
                          "Estimated_Date_Of_Dispatch",
                          value
                        )
                      }
                      slotProps={{ textField: { size: "small" } }}
                      format="DD/MM/YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "6px",
                        width: "20vw", // Assuming you meant to set the width as 20% of the viewport width
                        marginleft: "5px"
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div style={{ display: "flex", marginTop: " 4vh" }}>
                <h3 style={{ fontSize: "18px", width: "22vw" }}>
                  Reason for change:{" "}
                </h3>
                <textarea
                  value={dispatchDateFormData.Reason_For_Change}
                  name="Reason_For_Change"
                  onChange={handleDateChangeReason}
                  className={modalStyles.textbox1}
                  disabled={!dispatchDateFormData.Previous_Dispatch_Date}
                  style={{
                    minHeight: "70px",
                    marginTop: "0px",
                    marginBottom: "30px",
                    // Ensure it takes full width available
                    resize: "none", // Optional: prevent resizing
                    padding: "5px", // Adjust padding as needed
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Keeps children centered horizontally
                  alignItems: "center", // Also keeps children centered horizontally, redundant but ensures centering if contents are wrapped or modified
                  marginBottom: "4vh"// Needed for absolute positioning of the child
                  // Ensures the div occupies the full viewport height or adjust accordingly to your layout
                }}
              >
                <div className={modalStyles.editAndSaveButtons}>
                  <button
                    variant="contained"
                    onClick={e => handleSaveDispatchDate(e)}
                    className={modalStyles.saveButton}
                  >
                    Save
                  </button>
                  <button
                    variant="outlined"
                    onClick={handleCloseModal1}
                    className={modalStyles.cancelBtn}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>

        {/* Estimated Date of Dispatch Change Logs Modal */}
        <Modal
          open={openModal2}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            backdropFilter: "blur(4px)",
          }}
        >
          <Fade in={openModal2}>
            <Box
              sx={{
                width: "45vw",
                height: "55vh",
                p: 3,
                backgroundColor: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  marginBottom: "3vh",
                  display: "flex",
                  justifyContent: "space-between", // Changed to space-between to push the last item to the right
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{
                      textAlign: "center",
                      fontFamily: "'Open Sans', sans-serif", // Ensure the font is available or imported
                      fontWeight: "400",
                      fontSize: "1.5rem",
                      color: "#5B6BE1",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      fill="#5B6BE1"
                      width="28"
                      height="28"
                      style={{ marginRight: "10px", marginBottom: "6px" }}
                      viewBox="0 0 22 22"
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 24 24"
                    >
                      <path d="M12,2C12,2,12,2,12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,17c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,17,12,17z M13,12c0,0.6-0.4,1-1,1s-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1V12z" />
                    </svg>
                    Changes in Dispatch date
                  </Typography>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleCloseModal2}
                  viewBox="0 0 25 25"
                  style={{
                    width: "23px", // Smaller size
                    height: "23px", // Smaller size
                    cursor: "pointer",
                  }}
                  id="x-circle"
                >
                  <circle
                    cx="12.5"
                    cy="12.5"
                    r="10"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></circle>
                  <line
                    x1="8"
                    x2="17"
                    y1="8"
                    y2="17"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    x1="8"
                    x2="17"
                    y1="17"
                    y2="8"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                </svg>
              </div>

              <div className={modalStyles.itemstablecontainer}>
                <table className={modalStyles.itemstable}>
                  <thead>
                    <tr>
                      <th>Change Order</th>
                      <th>New Dispatch Date</th>
                      <th>Done by</th>
                      <th>Reason for change</th>

                    </tr>
                  </thead>
                  <tbody>
                    {dispatchDateLogData.length === 0 ? (
                      <tr>
                        <td colSpan="4">No result found</td>
                      </tr>
                    ) : (
                      dispatchDateLogData.map((item, index) => (
                        <tr key={item.S_No}>
                          <td>{index}</td>
                          <td>{item.new_value ? formatDate(item.new_value) : "-"}</td>
                          <td>{item.done_by}</td>
                          <td>{item.reason_for_change}</td>


                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>


            </Box>
          </Fade>
        </Modal>

      </div>
    </div>
  );
};

export default SparesTradingDetailsUpdate;
