import { useEffect, useState } from "react";
import axios from "axios";
import styles from "../Components/css/projectreport.module.css";
import style from "./css/modal.module.css";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import excel from "./css/image/excel.png";
// stepper mui
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useParams } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

axios.defaults.headers.common["Content-Type"] = "application/json";

const CustomModal = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
const getSteps = () => {
  return ["Upload Issues", "Upload Success"];
};
function Sparesmobilization() {
  const [fileContent, setFileContent] = useState([]);
  const [rows, setRows] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [smResults, setsmResults] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [smDocuments, setsmDocuments] = useState([]);
  const [showInputRowItems, setShowInputRowItems] = useState(false);
  const [showInputRowDocuments, setShowInputRowDocuments] = useState(false);
  const [editModeItems, setEditModeItems] = useState(false);
  const [editModeDocuments, setEditModeDocuments] = useState(false);
  const [addNewModeDocuments, setAddNewModeDocuments] = useState(false);
  const [addNewModeItems, setAddNewModeItems] = useState(false);
  const [ciplSuggestions, setCiplSuggestions] = useState([]);
  const [newRowData, setNewRowData] = useState([]);
  const [featureman, setfeatureman] = useState([]);
  const [featureexcel, setfeatureexcel] = useState([]);
  const [refresh, setRefresh] = useState(false);
  // const [removeId, setRemoveId] = useState([]);
  const [removeItemId, setRemoveItemId] = useState([]);
  const [removeDocumentId, setRemoveDocumentId] = useState([]);
  const [filteData, setFilterData] = useState();
  const [featureedit, setfeatureedit] = useState();
  const [originalSmResults, setOriginalSmResults] = useState([...smResults]);
  const [originalSmDocuments, setOriginalSmDocuments] = useState([...smDocuments]);
  const [showEditButton, setShowEditButton] = useState(true);
  const [showCiplButton, setShowCiplButton] = useState(true);
  const [showInputButton, setShowInputButton] = useState(true);
  const [inputCIPLActive, setInputCIPLActive] = useState(false);
  const [inputSparesActive, setInputSparesActive] = useState(false);
  const [tab, setTab] = useState("documents"); // Added for tab management
  const [selectedCiplNo, setSelectedCiplNo] = useState('');


  const { id } = useParams();

  const [formDatasm, setFormDatasm] = useState({
    data: [],
  });

  const [
    formDatasmdocuments, setFormDatasmdocuments] = useState({
      data: [],
    });

  const handleSubmitsm = async (e) => {
    e.preventDefault();

    const {
      Location,

      Part_Number,

      Item_Name,

      CIPL_No_spares,

      Units,

      Quantity_Required,

      Available_Quantity_On_Rig,

      Qty_intransit,

      DocumentsStatus,

      Comments,

      Last_updated_on,

      Date_of_arrival_on_rig,
    } = formDatasm;

    const payloadsm = {
      ID_No: id,

      Location: Location,

      Part_Number: Part_Number,

      Item_Name: Item_Name,

      CIPL_No_spares: CIPL_No_spares,

      Qty_intransit: Qty_intransit,

      Last_updated_on: Last_updated_on,

      Date_of_arrival_on_rig: Date_of_arrival_on_rig,

      Comments: Comments,

      Units: Units,

      Quantity_Required: Quantity_Required,

      Available_Quantity_On_Rig: Available_Quantity_On_Rig,

      DocumentsStatus: DocumentsStatus,
    };

    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesmobilization_data",

        payloadsm
      );

      setFormDatasm({
        ID_No: "",

        Location: "",

        Part_Number: "",

        Item_Name: "",

        CIPL_No_spares: "",

        Qty_intransit: "",

        Last_updated_on: "",

        Date_of_arrival_on_rig: "",

        Comments: "",

        Units: "",

        Quantity_Required: "",

        Available_Quantity_On_Rig: "",

        DocumentsStatus: "",
      });

      if (res.status === 200) {
        alert("Spares Details Added Successfully");

        setRefresh(!refresh);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }

    setIsButtonDisabled(false);

    setShowInputRowItems(false);
    setShowEditButton(true)
    setShowInputButton(true);
  };

  const handleSubmitsmdocuments = async (e) => {
    e.preventDefault();

    const {
      Spares_requirement_shared_on,

      CIPL_No_spares,

      CIPL_shared_on,

      EC_obtained,

      Vessel_pre_alert,

      ETD,

      ETA_for_port,

      DocumentsStatus,

      Last_updated_on,

      Comments,

      Date_of_arrival_on_rig,
    } = formDatasmdocuments;

    const payloadsmdocuments = {
      ID_No: id,

      Spares_requirement_shared_on: Spares_requirement_shared_on,

      CIPL_No_spares: CIPL_No_spares,

      CIPL_shared_on: CIPL_shared_on,

      EC_obtained: EC_obtained,

      Vessel_pre_alert: Vessel_pre_alert,

      ETD: ETD,

      ETA_for_port: ETA_for_port,

      DocumentsStatus: DocumentsStatus,

      Last_updated_on: Last_updated_on,

      Comments: Comments,

      Date_of_arrival_on_rig: Date_of_arrival_on_rig,
    };

    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesdocpost",

        payloadsmdocuments
      );

      setFormDatasmdocuments({
        ID_No: "",

        Spares_requirement_shared_on: "",

        CIPL_No_spares: "",

        CIPL_shared_on: "",

        EC_obtained: "",

        Vessel_pre_alert: "",

        ETD: "",

        ETA_for_port: "",

        DocumentsStatus: "",

        Last_updated_on: "",

        Comments: "",

        Date_of_arrival_on_rig: "",
      });

      if (res.status === 200) {
        alert("Spares Details Added Successfully");

        setRefresh(!refresh);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }

    setIsButtonDisabled(false);

    setShowInputRowDocuments(false);
    setShowEditButton(true)
    setShowCiplButton(true)
  };

  const updateItemsOnDocumentChange = (ciplNo, Comments, newValue) => {
    const updatedItemsData = formDatasm.data.map(item => {
      if (item.CIPL_No_spares === ciplNo) {
        return {
          ...item,
          Comments: newValue, // Ensure that Comments are updated
        };
      }
      return item;
    });

    // Update the formDatasm state
    setFormDatasm((prevState) => ({
      ...prevState,
      data: updatedItemsData,
    }));

    // Also update smResults state to reflect the changes in UI
    const updatedSmResults = smResults.map(sm => {
      if (sm.CIPL_No_spares === ciplNo) {
        return {
          ...sm,
          Comments: newValue, // Ensure that Comments are updated
        };
      }
      return sm;
    });

    setsmResults(updatedSmResults);
  };


  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    const updatedFormDatasm = formDatasm.data.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value,
        };
      }
      return item;
    });

    // Update the formDatasm state
    setFormDatasm((prevState) => ({
      ...prevState,
      data: updatedFormDatasm,
    }));

    // Update the smResults state accordingly
    const updatedSmResults = smResults.map((sm, idx) => {
      if (idx === index) {
        return {
          ...sm,
          [name]: value,
        };
      }
      return sm;
    });

    setsmResults(updatedSmResults);

    // If the updated field is related to 'Comments' in the 'Documents' section
    if (name === 'Comments') {
      updateItemsOnDocumentChange(updatedFormDatasm[index].CIPL_No_spares, name, value);
    }
  };



  const handleInputChangesdocs = (e, index) => {
    console.log(e);
    let { name, value } = e.target;
    console.log(value);

    // Step 1: Update the document section state
    const updatedData = [...formDatasmdocuments.data];
    updatedData[index][name] = value;

    setFormDatasmdocuments({
      ...formDatasmdocuments,
      data: updatedData,
    });

    // Step 2: Now update the related items' comments if the changed field is "Comments"
    if (name === 'Comments') {
      const ciplNo = formDatasm.data[index].CIPL_No_spares;
      updateItemsOnDocumentChange(ciplNo, name, value);
    }
  };

  const handleInputChangevis = (e) => {
    let { name, value } = e.target;

    console.log(name, value);

    setFormDatasm((prev) => ({
      ...prev,

      [name]: value,
    }));
  };



  const handleInputChangevisdocs = (e) => {
    let { name, value } = e.target;

    console.log(name, value);

    setFormDatasmdocuments((prev) => ({
      ...prev,

      [name]: value,
    }));
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);

    setRows(updatedRows);

    setShowInputRowItems(false);
    setShowEditButton(true)
    setIsButtonDisabled(false);
  };

  const handleDeleteRowdocs = (index) => {
    const updatedRows = formDatasmdocuments.data.filter((_, i) => i !== index);

    setFormDatasmdocuments({
      ...formDatasmdocuments,

      data: updatedRows,
    });

    setShowInputRowDocuments(false);
    setShowEditButton(true)
    setIsButtonDisabled(false);
  };

  const handleManualInputClick = (type) => {
    setNewRowData();
    setIsButtonDisabled(true);
    setShowEditButton(false);
    if (type === "items") {
      setShowInputRowItems(true);
      setInputSparesActive(true); // Mark spares input as active
      setInputCIPLActive(false);
    } else {
      setShowInputRowDocuments(true);
      setInputCIPLActive(true); // Mark CIPL input as active
      setInputSparesActive(false);
    }
  };

  const handleEdit = (type) => {
    console.log(`Edit mode activated for: ${type}`);
    setShowEditButton(true);
    if (type === "items") {

      setEditModeItems(true);
      setEditModeDocuments(false);
      setShowInputButton(false);
    } else {
      setEditModeItems(false);
      setEditModeDocuments(true);
      setShowCiplButton(false);

    }
  };


  const handlecancelsm = (type) => {
    if (type === "items") {
      setRemoveItemId([]); // Reset only items remove ID
      setShowInputRowItems(false);
      setsmResults([...originalSmResults]); // Restore from original items data
      setEditModeItems(false);
      setShowInputButton(true);
    } else {
      setRemoveDocumentId([]); // Reset only documents remove ID
      setShowInputRowDocuments(false);
      // Assuming you have something like `originalSmDocuments` for documents
      setFilterData([...originalSmDocuments]); // Restore from original documents data
      setEditModeDocuments(false);
      setShowCiplButton(true)
    }
  };

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        const filgroupfeatures = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Spares" &&
            el.Feature_Name === "Import Using excel" &&
            el.Access === "Can View"
          );
        });

        const filgroupfeatures2 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Spares" &&
            el.Feature_Name === "Edit" &&
            el.Access === "Can View"
          );
        });

        const filgroupfeatures3 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Spares" &&
            el.Feature_Name === "Input Spares Manually" &&
            el.Access === "Can View"
          );
        });

        setfeatureedit(filgroupfeatures2);

        setfeatureman(filgroupfeatures3);

        setfeatureexcel(filgroupfeatures);
      } catch (error) {
        console.error(error);
      }
    }

    fetchRigDetails();
  }, []);

  const handlesavesm = async (e) => {
    e.preventDefault();

    try {
      for (const id of removeItemId) {
        await axios.delete(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesmobilization_data_remove/${id}`
        );
      }


      for (const sm of smResults) {
        if (!removeItemId.includes(sm.S_No)) {
          const index = smResults.findIndex((item) => item.S_No === sm.S_No);
          const {
            Location,
            Part_Number,
            Item_Name,
            CIPL_No_spares,
            Units,
            Quantity_Required,
            Available_Quantity_On_Rig,
            DocumentsStatus,
            Qty_intransit,
            Last_updated_on,
            Date_of_arrival_on_rig,
            Comments,
          } = formDatasm.data[index];

          const update = {
            S_No: sm.S_No,
            ID_No: id,
            Location,
            Part_Number,
            Item_Name,
            CIPL_No_spares,
            Qty_intransit,
            Last_updated_on,
            Date_of_arrival_on_rig,
            Comments,
            Units,
            Quantity_Required,
            Available_Quantity_On_Rig,
            DocumentsStatus,
          };

          const res = await axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesmobilization_data_update/${sm.S_No}`,
            [update]
          );

          if (res.status !== 200) {

            console.error('Failed to update:', sm.S_No);
          }
        }
      }

      setRefresh((prev) => !prev);
      alert("changes saved successfully")
      setEditModeItems(false);
      setEditModeDocuments(false);
    } catch (error) {
      console.error("Error submitting data:", error);
    }

    // Reset edit mode regardless of the result to close edit form
    setEditModeItems(false);
    setEditModeDocuments(false);
    setShowInputButton(true);
  };

  // const handlesavesmdocuments = async (e) => {
  //   e.preventDefault();

  //   try {
  //     for (const sm of smDocuments) {
  //       const index = smDocuments.findIndex((item) => item.S_No === sm.S_No);

  //       const {
  //         Spares_requirement_shared_on,

  //         CIPL_No_spares,

  //         CIPL_shared_on,

  //         EC_obtained,

  //         Vessel_pre_alert,

  //         ETD,

  //         ETA_for_port,

  //         DocumentsStatus,

  //         Last_updated_on,

  //         Comments,

  //         Date_of_arrival_on_rig,
  //       } = formDatasmdocuments.data[index];

  //       const update = {
  //         S_No: sm.S_No,

  //         ID_No: id,

  //         Spares_requirement_shared_on,

  //         CIPL_No_spares,

  //         CIPL_shared_on,

  //         EC_obtained,

  //         Vessel_pre_alert,

  //         ETD,

  //         ETA_for_port,

  //         DocumentsStatus,

  //         Last_updated_on,

  //         Comments,

  //         Date_of_arrival_on_rig,
  //       };
  //       console.log(update);
  //       const res = await axios.put(
  //         `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesdocsupdate/${sm.S_No}`,

  //         [update]
  //       );

  //       if (res.status === 200) {
  //         setRefresh(!refresh);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error submitting data:", error);
  //   }

  //   setEditModeItems(false);

  //   setEditModeDocuments(false);
  // };

  const handlesavesmdocuments = async (e) => {
    e.preventDefault();
    try {
      // Handle deletions first
      for (const id of removeDocumentId) {
        await axios.delete(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesdocs_remove/${id}`
        );
      }

      // Proceed with updates
      for (const sm of smDocuments) {
        if (!removeDocumentId.includes(sm.S_No)) {
          console.log(sm.S_No);
          // Ensure we do not update items that are marked for removal
          const index = smDocuments.findIndex((item) => item.S_No === sm.S_No);
          const {
            ID_No,
            Spares_requirement_shared_on,
            CIPL_No_spares,
            CIPL_shared_on,
            EC_obtained,
            Vessel_pre_alert,
            ETD,
            ETA_for_port,
            DocumentsStatus,
            Last_updated_on,
            Comments,
            Date_of_arrival_on_rig,
          } = formDatasmdocuments.data[index];

          const update = {
            ID_No,
            Spares_requirement_shared_on,
            CIPL_No_spares,
            CIPL_shared_on,
            EC_obtained,
            Vessel_pre_alert,
            ETD,
            ETA_for_port,
            DocumentsStatus,
            Last_updated_on,
            Comments,
            Date_of_arrival_on_rig,
          };

          const res = await axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesdocsupdate/${sm.S_No}`,
            [update]
          );
          if (res.status === 200) {
            console.log("Update successful for S_No:", sm.S_No);
          }
        }
      }

      // Clear removed documents and refresh state

      setRefresh((prev) => !prev);
      alert("Changes saved successfully!");

      // Reset edit modes
      setEditModeItems(false);
      setEditModeDocuments(false);
    } catch (error) {
      console.error("Error submitting data:", error);
      setEditModeDocuments(false);
    }
    setEditModeDocuments(false);
    setShowCiplButton(true)
  };

  // const handleRemove = (id) => {
  //   const updatedsmResults = smResults.filter((sm) => sm.ID_No !== id);

  //   setsmResults(updatedsmResults);

  //   console.log("Updated smResults:", updatedsmResults);
  // };
  const handleRemove = async (sNo) => {
    alert(`Removing item with S_No: ${sNo}`);
    setRemoveItemId((prevRemoveId) => [...prevRemoveId, sNo]);
  };
  console.log(removeItemId)

  useEffect(() => {
    if (removeItemId?.length > 0) {
      const newItems = smResults.filter((item) => !removeItemId.includes(item.S_No));
      setsmResults([...newItems]);
      setFormDatasm({

        data: newItems,
      });

    }
  }, [removeItemId]);

  // const handleRemovedocs = (id) => {
  //   const updatedsmDocuments = smDocuments.filter((sm) => sm.ID_No !== id);

  //   setsmDocuments(updatedsmDocuments);

  //   console.log("Updated smResults:", updatedsmDocuments);
  // };
  const handleRemovedocs = async (id) => {
    setRemoveDocumentId((prevRemoveId) => [...prevRemoveId, id]);
  };
  useEffect(() => {
    if (removeDocumentId && Array.isArray(removeDocumentId) && removeDocumentId.length > 0) {
      const filteredDocs = smDocuments.filter((doc) => !removeDocumentId.includes(doc.S_No));
      setFilterData(filteredDocs);
      setFormDatasmdocuments({
        data: filteredDocs,
      })
    }
  }, [removeDocumentId]);


  useEffect(() => {
    async function fetchsmDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesmobilization_data"
        );

        const filter = response.data.filter((el) => el.ID_No == id);

        setsmResults(filter);
        setOriginalSmResults(filter);
        setFormDatasm({
          data: filter,
        });
      } catch (error) {
        console.error(error);
      }
    }

    fetchsmDetails();
  }, [refresh]);

  useEffect(() => {
    async function fetchsmDocsdetails() {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesdocs/${id}`
        );
        console.log(response?.data);

        // const filter = response.data.filter((el) => el.ID_No == id);

        setsmDocuments(response?.data);

        setFormDatasmdocuments({
          data: response?.data,
        });
      } catch (error) {
        console.error(error);
      }
    }

    fetchsmDocsdetails();
  }, [refresh]);

  // ***

  // Handle CIPL No suggestion selection for existing document entries
  const processData = () => {
    console.log(smDocuments);
    return removeDocumentId?.length > 0 ? (filteData ? filteData : null) : smDocuments;
  };

  const filterbyCipl = (cipiValue, index) => {
    const data = smDocuments?.find(
      (item) => item?.CIPL_No_spares === cipiValue
    );
    console.log(data);
    formDatasm.data[index]["DocumentsStatus"] = data?.DocumentsStatus || "";
    formDatasm.data[index]["Comments"] = data?.Comments || "";
    formDatasm.data[index]['CIPL_No_spares'] = cipiValue;
    formDatasm.data[index]['Date_of_arrival_on_rig'] = data?.Date_of_arrival_on_rig || "";
    formDatasm.data[index]['Last_updated_on'] = data?.Last_updated_on || "";
  };
  const handleCiplSuggestionSelect = (ciplValue, index, id, type) => {
    let newValue = ciplValue;
    if (ciplValue?.includes('Add')) {
      const newv = ciplValue?.split(':')
      newValue = newv && newv[1]?.trim()
      console.log(newValue);
    }
    if (type === 'documents') {
      const forupdate = { ...formDatasmdocuments }
      console.log(forupdate);
      forupdate.data[index]['CIPL_No_spares'] = newValue;
      console.log(forupdate);
      setFormDatasmdocuments(forupdate)
    }
    if (type === 'items') {
      filterbyCipl(newValue, index)
    }


  };

  // Handle CIPL No suggestion selection for new document entries

  // const handleCiplSuggestionSelectNew = (ciplValue) => {

  //   document.getElementById("newCiplInput").value = ciplValue;

  //   setNewRowData({ ...newRowData, ["CIPL_No_spares"]: ciplValue });

  //   setCiplSuggestions([]);

  //   const newSuggestionList = document.getElementById("newCiplSuggestionsList");

  //   if (newSuggestionList) {

  //     newSuggestionList.style.display = "none";

  //   } else {

  //     console.error('New suggestions list element not found');

  //   }

  // };
  const fetchAndSetDates = (newValue, index) => {
    const data = smDocuments?.find(
      item => item?.CIPL_No_spares === newValue
    );

    if (data) {
      if (index !== undefined) {
        // Editing existing row
        formDatasm = {
          ...formDatasm,
          'DocumentsStatus': data?.DocumentsStatus,
          'Comments': data?.Comments,
          'Date_of_arrival_on_rig': data?.Date_of_arrival_on_rig,
          'Last_updated_on': data?.Last_updated_on
        };
      } else {
        // New row addition
        setFormDatasm(prevState => ({
          ...prevState,
          'DocumentsStatus': data?.DocumentsStatus,
          'Comments': data?.Comments,
          'Date_of_arrival_on_rig': data?.Date_of_arrival_on_rig,
          'Last_updated_on': data?.Last_updated_on
        }));
      }
    }
  };

  console.log("formDatasm", formDatasm);

  const handleCiplSuggestionSelectNew = (ciplValue, id = "new") => {
    let newValue = ciplValue;
    if (ciplValue?.includes('Add')) {
      const newv = ciplValue?.split(':')
      newValue = newv && newv[1]?.trim()
      console.log(newValue);
    }
    const inputId = `newCiplInput${id}`;
    const suggestionListId = `newCiplSuggestionsList${id}`;

    const inputElement = document.getElementById(inputId);

    const suggestionElement = document.getElementById(suggestionListId);

    if (inputElement) {
      inputElement.value = newValue; // Update the input field with the selected value
    }

    setFormDatasm((prevState) => ({
      ...prevState,

      ["CIPL_No_spares"]: newValue, // Update the state with the new CIPL number
    }));
    fetchAndSetDates(newValue);


    setCiplSuggestions([]); // Clear the suggestions list

    if (suggestionElement) {
      suggestionElement.style.display = "none"; // Hide the suggestions list
    } else {
      console.error("Suggestions list element not found:", suggestionListId);
    }
  };


  console.log("newRowData", newRowData);
  // const fetchCiplNoFromServer = async (inputValue) => {

  //   if(!inputValue){

  //     return

  //   }

  //   try {

  //     const response = await axios.get(

  //       `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/cipl_suggestions`,

  //       {

  //         params: { input: inputValue }, // Pass the partial CIPL number as a query parameter

  //       }

  //     );

  //     // Check if the response.data is an array

  //     if (Array.isArray(response.data)) {

  //       return response.data;

  //     } else {

  //       console.error("Data fetched is not an array:", response.data);

  //       return []; // Return an empty array if data is not as expected

  //     }

  //   } catch (error) {

  //     console.error("Failed to fetch CIPL suggestions:", error);

  //     return []; // Return an empty array in case of an error

  //   }

  // };

  const fetchCiplNoFromServer = async (inputValue) => {


    try {
      const response = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/cipl_suggestions/${id}`,

      );

      if (Array.isArray(response.data)) {
        setCiplSuggestions(response.data); // Update the suggestions in the state

        return response.data;
      } else {
        console.error("Data fetched is not an array:", response.data);

        // Clear suggestions if fetch is unsuccessful

        return [];
      }
    } catch (error) {
      console.error("Failed to fetch CIPL suggestions:", error);

      // Clear suggestions on error

      return [];
    }
  };

  // Display suggestions based on partial input

  const fetchCiplSuggestions = async (inputValue, id = "new") => {
    const suggestions = await fetchCiplNoFromServer(inputValue);

    if (suggestions?.length === 0) {
      return;
    }

    setCiplSuggestions(suggestions);


  };
  useEffect(() => {
    console.log(ciplSuggestions);
  }, [ciplSuggestions])

  useEffect(() => {
    if (formDatasmdocuments?.data?.length > 0) {
      const updatedFormDatasm = formDatasm?.data?.map((item) => {
        const matchingDocument = formDatasmdocuments.data.find((doc) => doc.CIPL_No_spares === item.CIPL_No_spares);
        if (matchingDocument) {
          return {
            ...item,
            Comments: matchingDocument.Comments,
            DocumentsStatus: matchingDocument.DocumentsStatus,
            Last_updated_on: matchingDocument.Last_updated_on,
            Date_of_arrival_on_rig: matchingDocument.Date_of_arrival_on_rig,
          };
        }
        return item;
      });
      setFormDatasm((prevState) => ({ ...prevState, data: updatedFormDatasm }));
    }
  }, [formDatasmdocuments.data]);


  // You need to implement `fetchCiplNoFromServer` to fetch data based on user input

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (
        selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = e.target.result;

          const workbook = XLSX.read(data, { type: "binary" });

          const firstSheet = workbook.SheetNames[0];

          const sheetData = XLSX.utils.sheet_to_json(
            workbook.Sheets[firstSheet],

            { header: 1 }
          );

          setFileContent(sheetData);
        };

        reader.readAsBinaryString(selectedFile);
      } else if (selectedFile.type === "text/csv") {
        const reader = new FileReader();

        reader.onload = (e) => {
          const csvData = e.target.result;

          const csvRows = csvData.split("\n");

          setFileContent(csvRows.map((row) => row.split(",")));
        };

        reader.readAsText(selectedFile);
      } else {
        console.log("Invalid file type.");
      }
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");

    if (fileContent.length !== 0) {
      body.classList.add(styles.noScroll);
    } else {
      body.classList.remove(styles.noScroll);
    }

    return () => {
      body.classList.remove(styles.noScroll);
    };
  }, [fileContent]);

  const handleCancel = () => {
    setFileContent([]);
  };

  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  const [filteredTable, setFilteredTable] = useState([]);

  const [falseFilteredTable, setFalseFilteredTable] = useState([]);

  let payl;

  const store = filteredTable.map((el, index) => {
    payl = {
      ID_No: id,

      Location: el[0],

      Part_Number: el[2],

      Item_Name: el[1],

      CIPL_No_spares: el[3],

      Qty_intransit: el[7],

      DocumentsStatus: el[8],

      Last_updated_on: el[10],

      Date_of_arrival_on_rig: el[11],

      Comments: el[9],

      Units: el[4],

      Quantity_Required: el[5],

      Available_Quantity_On_Rig: el[6],
    };
  });

  console.log(store);

  const handlesubmittt = () => {
    try {
      filteredTable.map((el, index) => {
        const payload = {
          ID_No: id,

          Location: el[0],

          Part_Number: el[2],

          Item_Name: el[1],

          CIPL_No_spares: el[3],

          Qty_intransit: el[7],

          DocumentsStatus: el[8],

          Last_updated_on: el[10],

          Date_of_arrival_on_rig: el[11],

          Comments: el[9],

          Units: el[4],

          Quantity_Required: el[5],

          Available_Quantity_On_Rig: el[6],
        };

        const res = axios.post(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesfile_post",

          [payload]
        );

        if (res.status === 200) {
          alert("added data from file");
        }
      });
    } catch (error) {
      console.log(error);
    }

    setRefresh(!refresh);

    setFileContent([]);

    setOpen(false);
  };

  const filterData = () => {
    let tableIndexCorrect = validate?.reduce((indices, el, idx) => {
      if (el === true) {
        indices.push(idx);
      }

      return indices;
    }, []);

    let tableIndexFalse = validate?.reduce((indices, el, idx) => {
      if (el === false) {
        indices.push(idx);
      }

      return indices;
    }, []);

    let correctFilter = fileContent.filter((el, idx) =>
      tableIndexCorrect.includes(idx)
    );

    let falseFilter = fileContent.filter((el, idx) =>
      tableIndexFalse.includes(idx)
    );

    setFilteredTable(correctFilter);

    setFalseFilteredTable(falseFilter);
  };

  console.log("---------: ", filteredTable);

  const handleNext = () => {
    setActiveStep(1);
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  const [validate, setValidate] = useState([]);

  const validationFunction = () => {
    const updatedValidate = [];

    fileContent.forEach((elm, index) => {
      if (typeof elm[4] === "number") {
        updatedValidate[index] = true;
      } else {
        updatedValidate[index] = false;
      }
    });

    setValidate(updatedValidate);
  };

  useEffect(() => {
    validationFunction();
  }, [fileContent]);

  useEffect(() => {
    filterData();
  }, [validate]);

  const maxColumns = 6;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const renderItemsTable = () => (
    <div>
      <div className={styles.tableitems}>
        <table className={styles.smtable}>
          <thead>
            <tr className={styles.headrowsm}>
              {editModeItems && <th></th>}

              {showInputRowItems && <th></th>}

              <th>S.no</th>

              <th>Location</th>

              <th>Item name</th>

              <th>Part Number</th>

              <th>CIPL No</th>

              <th style={{ whiteSpace: "nowrap" }}>Units</th>

              <th>Quantity required for this phase</th>

              <th>Available Quantity on rig</th>

              <th>Qty in-transit</th>

              <th>Current Status</th>

              <th>Comments</th>

              <th>Last follow-up date</th>

              <th>Date of arrival on rig</th>
            </tr>
          </thead>

          <tbody>
            {rows.map((row) => row)}

            {smResults.map((sm, index) => (
              <tr key={sm.id}>
                {editModeItems && (
                  <td>
                    <div
                      className={styles.removeTxtBtn}
                      style={{ pointerEvents: "all", cursor: "pointer" }}
                      onClick={() => handleRemove(sm.S_No)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                          fill="#567191"
                        />
                      </svg>
                      Remove
                    </div>
                  </td>
                )}

                {showInputRowItems && <td></td>}
                {console.log("Resultssssssssss changes", smResults)}
                {console.log("formdatasssss changes", formDatasm)}
                <td>{index + 1}</td>

                <td>
                  {editModeItems ? (
                    <input
                      className={styles.inputFieldsm}
                      name="Location"
                      value={formDatasm?.data[index].Location || ''}
                      onChange={(e) => handleInputChange(e, index)}
                    />


                  ) : sm.Location ? (
                    sm.Location
                  ) : (
                    "-"
                  )}
                </td>

                <td style={{ minWidth: "200px" }}>
                  {editModeItems ? (
                    <input
                      type="text"
                      className={styles.inputFieldsm}
                      name="Item_Name"
                      value={formDatasm.data[index].Item_Name}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : sm.Item_Name ? (
                    sm.Item_Name
                  ) : (
                    "-"
                  )}
                </td>

                <td style={{ minWidth: "200px" }}>
                  {editModeItems ? (
                    <input
                      type="text"
                      className={styles.inputFieldsm}
                      name="Part_Number"
                      value={formDatasm.data[index].Part_Number}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : sm.Part_Number ? (
                    sm.Part_Number
                  ) : (
                    "-"
                  )}
                </td>

                {/* <td className="sparesInput">
                {editModeItems ? (
                  <>
                    <input
                      type="text"
                      autocomplete="off"
                      id="ciplInput"
                      name="CIPL_No_spares"
                      className={styles.inputFieldsm}
                      value={formDatasm?.data[index]?.CIPL_No_spares}
                      onChange={(e) => handleInputChange(e, index)}
                      onInput={(e) =>
                        fetchCiplSuggestions(e.target.value, sm.S_No)
                      }
                    />

                    <ul
                      id={`ciplSuggestionsList${sm.S_No}`}
                      style={{ display: "none", cursor: "pointer" }}
                    >
                      {Array.isArray(ciplSuggestions) &&
                        ciplSuggestions.map(
                          (
                            suggestion,
                            sIndex // using sIndex to avoid shadowing outer index
                          ) => (
                            <li
                              key={sIndex}
                              onClick={() =>handleCiplSuggestionSelect(suggestion.CIPL_No_spares,index,sm.S_No,'items' )}
                            >
                              {suggestion.CIPL_No_spares}
                            </li>
                          )
                        )}
                    </ul>
                  </>
                ) : (
                  <input
                    type="text"
                    className={`${styles.inputFieldsm} ${styles.disabledInputSpares}`}
                    value={sm.CIPL_No_spares}
                    disabled
                  />
                )}
              </td> */}

                <td className="sparesInput" style={{ minWidth: "200px" }}>
                  {editModeItems ? (
                    <>
                      <div style={{ position: "relative" }}>
                        <select id="ciplInput"  // Make sure ID is unique or dynamically generated if necessary
                          name="CIPL_No_spares"
                          className={styles.inputFieldsm}
                          value={formDatasm?.data[index]?.CIPL_No_spares}
                          // onChange={(e) => handleInputChange(e, index)}
                          onChange={(e) => {
                            const selectedCiplNo = e.target.value;
                            console.log('Selected CIPL No:', selectedCiplNo);
                            if (selectedCiplNo !== 'Select Cipl No') {
                              handleCiplSuggestionSelect(selectedCiplNo, index, sm.S_No, "items");
                            }
                          }}
                          onClick={(e) => fetchCiplSuggestions(e.target.value, sm.S_No)}>
                          <option value={formDatasm?.data[index]?.CIPL_No_spares}>{formDatasm?.data[index]?.CIPL_No_spares}</option>
                          {/* {ciplSuggestions?.map((suggestion) => (
                            <option
                              // onClick={() =>handleCiplSuggestionSelect(suggestion.CIPL_No_spares,index,sm.S_No,'items' )}  
                              value={suggestion.CIPL_No_spares}>{suggestion.CIPL_No_spares}
                            </option>
                          ))} */}
                          {ciplSuggestions?.filter(suggestion => suggestion.CIPL_No_spares !== sm.CIPL_No_spares).map((suggestion, i) => (
                            <option key={i} value={suggestion.CIPL_No_spares}>{suggestion.CIPL_No_spares}</option>
                          ))}
                        </select>
                        <svg
                          style={{
                            position: 'absolute',  // Make sure to position it absolutely within the div
                            right: "11px",
                            pointerEvents: 'none'  // Disable pointer events if it shouldn't be clickable
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    </>
                  ) : (
                    <input
                      type="text"
                      className={`${styles.inputFieldsm} ${styles.disabledInputSpares}`}
                      value={sm.CIPL_No_spares}
                      disabled
                    />
                  )}
                </td>

                <td style={{ minWidth: "200px" }}>
                  {editModeItems ? (
                    <div style={{ position: "relative" }}>
                      <select
                        className={styles.inputFieldsm}
                        name="Units"
                        value={formDatasm.data[index].Units}
                        onChange={(e) => handleInputChange(e, index)}

                      >
                        <option value="">Select Quantity</option>

                        <option value="pcs">pcs</option>

                        <option value="kg">kg</option>
                      </select>
                      <svg
                        style={{
                          position: 'absolute',  // Make sure to position it absolutely within the div
                          right: "11px",
                          pointerEvents: 'auto'  // Disable pointer events if it shouldn't be clickable
                        }}
                        className={styles.svgSelect}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                          fill="rgba(57, 71, 89, 0.5)"
                        />
                      </svg>
                    </div>
                  ) : sm.Units ? (
                    sm.Units
                  ) : (
                    "-"
                  )}
                </td>

                <td style={{ minWidth: "200px" }}>
                  {editModeItems ? (
                    <input
                      type="number"
                      className={styles.inputFieldsm}
                      name="Quantity_Required"
                      value={formDatasm.data[index].Quantity_Required}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : sm.Quantity_Required ? (
                    sm.Quantity_Required
                  ) : (
                    "-"
                  )}
                </td>

                <td style={{ minWidth: "200px" }}>
                  {editModeItems ? (
                    <input
                      type="text"
                      className={styles.inputFieldsm}
                      name="Available_Quantity_On_Rig"
                      value={formDatasm.data[index].Available_Quantity_On_Rig}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : sm.Available_Quantity_On_Rig ? (
                    sm.Available_Quantity_On_Rig
                  ) : (
                    "-"
                  )}
                </td>

                <td style={{ minWidth: "200px" }}>
                  {editModeItems ? (
                    <input
                      type="number"
                      className={styles.inputFieldsm}
                      name="Qty_intransit"
                      value={formDatasm.data[index].Qty_intransit}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : sm.Qty_intransit ? (
                    sm.Qty_intransit
                  ) : (
                    "-"
                  )}
                </td>

                <td style={{ minWidth: "200px" }}>
                  {editModeItems ? (
                    <input
                      type="text"
                      className={styles.inputFieldsm}
                      name="DocumentsStatus"
                      value={formDatasm?.data[index]?.DocumentsStatus}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled
                    />
                  ) : (

                    formDatasm?.data?.[index]?.DocumentsStatus ||
                    "-"
                  )}
                </td>

                <td style={{ paddingLeft: "10px", paddingRight: "10px", minWidth: "200px" }}>
                  {editModeItems ? (
                    <textarea
                      type="text"
                      className={styles.inputFieldsmComments}
                      name="Comments"
                      value={formDatasm?.data[index]?.Comments}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled
                    />
                  ) : (
                    formDatasm?.data?.[index]?.Comments || "-"
                  )}
                </td>

                <td style={{ paddingLeft: "10px", paddingRight: "10px", minWidth: "200px" }}>
                  {editModeItems ? (
                    <input type="date" className={styles.inputFieldsm} name="Last_updated_on"
                     value={formDatasm?.data?.[index].Last_updated_on} 
                     onChange={(e) => handleInputChange(e, index)}
                     style={{ color: formDatasm?.data?.[index].Last_updated_on ? 'initial' : 'transparent' }} // This line hides the placeholder
                      disabled />
                  ) : (
                    formDatasm?.data?.[index]?.Last_updated_on ? (
                      new Date(formDatasm?.data?.[index].Last_updated_on).toLocaleDateString('en-GB')
                    ) : (
                      "-"
                    )
                  )}
                </td>

                {/* <td>
                {editModeItems ? (
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="Date_of_arrival_on_rig"
                    value={
                      formDatasm?.data[index]?.Date_of_arrival_on_rig
                    }
                    onChange={(e) => handleInputChange(e, index)}
                  />
                ) : formDatasm?.data[index]?.Date_of_arrival_on_rig ? (
                  formDatasm?.data[index]?.Date_of_arrival_on_rig
                ) : (
                  "-"
                )}
              </td> */}

                {formDatasm && formDatasm.data && (
                  <td style={{ paddingLeft: "10px", paddingRight: "10px", minWidth: "200px" }}>
                    {editModeItems ? (
                      <input type="date" className={styles.inputFieldsm} name="Date_of_arrival_on_rig"
                       value={formDatasm.data[index]?.Date_of_arrival_on_rig || ''}
                        onChange={(e) => handleInputChange(e, index)}
                        style={{ color: formDatasm?.data?.[index].Date_of_arrival_on_rig ? 'initial' : 'transparent' }} // This line hides the placeholder
                         disabled />
                    ) : (
                      formDatasm?.data?.[index]?.Date_of_arrival_on_rig ? (
                        new Date(formDatasm?.data?.[index].Date_of_arrival_on_rig).toLocaleDateString('en-GB')
                      ) : (
                        '-'
                      )
                    )}
                  </td>
                )}


              </tr>
            ))}

            {showInputRowItems && (
              <tr key={rows.length} className={styles.addinputspares}>
                <td className={styles.tickcross}>
                  <svg
                    style={{ cursor: "pointer" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    onClick={handleSubmitsm}
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                      fill="#66D171"
                    />
                  </svg>

                  <svg
                    style={{ cursor: "pointer" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    onClick={handleDeleteRow}
                  >
                    <path
                      d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                      fill="#F97575"
                    />

                    <path
                      d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                      fill="#F97575"
                    />
                  </svg>
                </td>

                <td></td>

                <td>
                  <input
                    className={styles.inputFieldsm}
                    name="Location"
                    value={formDatasm?.Location}
                    onChange={handleInputChangevis}
                  />

                </td>

                <td>
                  <input
                    type="text"
                    className={styles.inputFieldsm}
                    name="Item_Name"
                    value={formDatasm.Item_Name}
                    onChange={handleInputChangevis}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    className={styles.inputFieldsm}
                    name="Part_Number"
                    value={formDatasm.Part_Number}
                    onChange={handleInputChangevis}
                  />
                </td>


                <td>
                  <div style={{ position: "relative" }}>
                    <select
                      className={styles.inputFieldsm}
                      id="newCiplInput"
                      name="CIPL_No_spares"
                      value={formDatasm?.CIPL_No_spares}
                      // onChange={(e) => handleInputChangevis(e)}
                      onChange={(e) => {
                        const selectedCiplNo = e.target.value;
                        console.log('Selected CIPL No:', selectedCiplNo);
                        if (selectedCiplNo !== 'Select Cipl No') {
                          handleCiplSuggestionSelectNew(selectedCiplNo, "new");
                        }
                      }}
                      onClick={(e) => fetchCiplSuggestions(e.target.value, "new")}
                    >
                      <option>Select Cipl No</option>
                      {ciplSuggestions.map((suggestion, index) => (
                        <option
                          //  onClick={() =>
                          //   handleCiplSuggestionSelectNew(
                          //     suggestion.CIPL_No_spares,
                          //     "new"
                          //   )} 


                          value={suggestion.CIPL_No_spares}>  {suggestion.CIPL_No_spares}</option>
                      ))}
                    </select>
                    <svg
                      style={{
                        position: 'absolute',  // Make sure to position it absolutely within the div
                        right: "11px",
                        pointerEvents: 'none'  // Disable pointer events if it shouldn't be clickable
                      }}
                      className={styles.svgSelect}
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                        fill="rgba(57, 71, 89, 0.5)"
                      />
                    </svg>
                  </div>
                </td>

                <td>
                  <div style={{ position: "relative" }}>
                    <select
                      className={styles.inputFieldsm}
                      name="Units"
                      value={formDatasm.Units}
                      onChange={handleInputChangevis}
                    >
                      <option value="">Select Quantity</option>

                      <option value="pcs">pcs</option>

                      <option value="kg">kg</option>
                    </select>
                    <svg
                      style={{
                        position: 'absolute',  // Make sure to position it absolutely within the div
                        right: "11px",
                        pointerEvents: 'none'  // Disable pointer events if it shouldn't be clickable
                      }}
                      className={styles.svgSelect}
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                        fill="rgba(57, 71, 89, 0.5)"
                      />
                    </svg>
                  </div>
                </td>

                <td>
                  <input
                    type="text"
                    className={styles.inputFieldsm}
                    name="Quantity_Required"
                    value={formDatasm.Quantity_Required}
                    onChange={handleInputChangevis}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    className={styles.inputFieldsm}
                    name="Available_Quantity_On_Rig"
                    value={formDatasm.Available_Quantity_On_Rig}
                    onChange={handleInputChangevis}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    className={styles.inputFieldsm}
                    name="Qty_intransit"
                    value={formDatasm.Qty_intransit}
                    onChange={handleInputChangevis}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    className={styles.inputFieldsm}
                    name="DocumentsStatus"
                    value={formDatasm?.DocumentsStatus}
                    onChange={handleInputChangevis}
                    disabled
                  />

                </td>

                <td style={{ paddingLeft: "10px", paddingRight: "10px", minWidth: "200px" }}>
                  <textarea
                    type="text"
                    className={styles.inputFieldsmComments}
                    name="Comments"
                    value={formDatasm?.Comments}
                    onChange={handleInputChangevis}
                    disabled
                  />
                </td>

                <td >
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="Last_updated_on"
                    value={formDatasm?.Last_updated_on || ''}
                    onChange={handleInputChangevis}
                    disabled
                  />
                </td>



                <td>
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="Date_of_arrival_on_rig"
                    value={formDatasm.Date_of_arrival_on_rig}
                    onChange={handleInputChangevis}
                    disabled
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {featureman.length > 0 && showInputButton && (
        <button
          className={styles.manualbuttonsm}
          onClick={() => handleManualInputClick("items")}
          disabled={isButtonDisabled}
        >
          Input Spares Manually
          <svg
            style={{ marginLeft: "5px" }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.493 1.66669H6.50964C3.4763 1.66669 1.66797 3.47502 1.66797 6.50835V13.4834C1.66797 16.525 3.4763 18.3334 6.50964 18.3334H13.4846C16.518 18.3334 18.3263 16.525 18.3263 13.4917V6.50835C18.3346 3.47502 16.5263 1.66669 13.493 1.66669ZM15.0013 10.625H10.6263V15C10.6263 15.3417 10.343 15.625 10.0013 15.625C9.65964 15.625 9.3763 15.3417 9.3763 15V10.625H5.0013C4.65964 10.625 4.3763 10.3417 4.3763 10C4.3763 9.65835 4.65964 9.37502 5.0013 9.37502H9.3763V5.00002C9.3763 4.65835 9.65964 4.37502 10.0013 4.37502C10.343 4.37502 10.6263 4.65835 10.6263 5.00002V9.37502H15.0013C15.343 9.37502 15.6263 9.65835 15.6263 10C15.6263 10.3417 15.343 10.625 15.0013 10.625Z"
              fill="white"
            />
          </svg>
        </button>
      )}
    </div>
  );

  const renderDocumentsTable = () => (
    <div className={styles.tableContainer}>
      <table className={styles.smtable}>
        <thead>
          <tr className={styles.headrowsm}>
            {editModeDocuments && <th></th>}

            {showInputRowDocuments && <th></th>}

            <th>S.no</th>

            <th>Spares Requirement shared on</th>

            <th>CIPL No</th>

            <th>CIPL shared by Customer on</th>

            <th>EC obtained</th>

            <th>Vessel pre-alert received on</th>

            <th>ETD</th>

            <th>ETA for nearby port</th>

            <th style={{ whiteSpace: "nowrap" }}>Current Status</th>

            <th >Last updated on</th>

            <th>Comments</th>

            <th >Date of arrival on rig</th>
          </tr>
        </thead>

        <tbody>
          {rows?.map((row) => row)}

          {processData()?.map((sm, index) => (
            <tr key={sm.id}>
              {editModeDocuments && (
                <td>
                  <div
                    className={styles.removeTxtBtn}
                    style={{ pointerEvents: "all", cursor: "pointer" }}
                    onClick={() => handleRemovedocs(sm.S_No)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                        fill="#567191"
                      />
                    </svg>
                    Remove
                  </div>
                </td>
              )}

              {showInputRowDocuments && <td></td>}

              <td>{index + 1}</td>

              <td>
                {editModeDocuments ? (
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="Spares_requirement_shared_on"
                    value={
                      formDatasmdocuments?.data[index]
                        ?.Spares_requirement_shared_on
                    }
                    onChange={(e) => handleInputChangesdocs(e, index)}
                  />
                ) : sm.Spares_requirement_shared_on ? (
                  new Date(sm.Spares_requirement_shared_on).toLocaleDateString('en-GB')
                ) : (
                  "-"
                )}
              </td>

              {/* <td  className="sparesInput">
                {editModeDocuments ? (
                  <>
                    <input
                      type="text"
                      autocomplete="off"
                      id="ciplInput"
                      name="CIPL_No_spares"
                      className={styles.inputFieldsm}
                      value={formDatasmdocuments.data[index]?.CIPL_No_spares}
                      onChange={(e) => handleInputChangesdocs(e, index)}
                      onInput={(e) =>
                        fetchCiplSuggestions(e.target.value, sm.S_No)
                      }
                    />

                    <ul
                      id={`ciplSuggestionsList${sm.S_No}`}
                      style={{ display: "none", cursor: "pointer" }}
                    >
                      {Array.isArray(ciplSuggestions) &&
                        ciplSuggestions.map(
                          (
                            suggestion,
                            sIndex // using sIndex to avoid shadowing outer index
                          ) => (
                            <li
                              key={sIndex}
                              onClick={() =>
                                handleCiplSuggestionSelect(suggestion.CIPL_No_spares,index,sm.S_No,'documents'
                                )
                              }
                            >
                              {suggestion.CIPL_No_spares}
                            </li>
                          )
                        )}
                    </ul>
                  </>
                ) : (
                  <input
                    type="text"
                    className={`${styles.inputFieldsm} ${styles.disabledInputSpares}`}
                    value={sm.CIPL_No_spares}
                    disabled
                  />
                )}
              </td> */}
              {/* <td className="sparesInput">
                {editModeDocuments ? (
                  <>
                    <input
                      type="text"
                      autoComplete="off"
                      id="ciplInput"
                      name="CIPL_No_spares"
                      className={styles.inputFieldsm}
                      value={formDatasmdocuments.data[index]?.CIPL_No_spares}
                      onChange={(e) => handleInputChangesdocs(e, index)}
                      onInput={(e) => fetchCiplSuggestions(e.target.value, sm.S_No)}
                    />

                    <ul
                      id={`ciplSuggestionsList${sm.S_No}`}
                      style={{
                        display: "none",
                        cursor: "pointer",
                        width: '5%', // Adjusts width to match the input
                        position: 'absolute', // Adjust positioning if needed
                        zIndex: 1000,
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        boxSizing: 'border-box', // Includes padding and border in the element's total width
                      }}
                    >
                      {Array.isArray(ciplSuggestions) &&
                        ciplSuggestions.map((suggestion, sIndex) => (
                          <li
                            key={sIndex}
                            className={styles.dropdownItem}
                            onClick={() => handleCiplSuggestionSelect(suggestion.CIPL_No_spares, index, sm.S_No, 'documents')}
                          >
                            {suggestion.CIPL_No_spares}
                          </li>
                        ))
                      }
                    </ul>
                  </>
                ) : (
                  <input
                    type="text"
                    className={`${styles.inputFieldsm} ${styles.disabledInputSpares}`}
                    value={sm.CIPL_No_spares}
                    disabled
                  />
                )}
              </td> */}
              <td >
                {editModeDocuments ? (
                  <input
                    type="text"
                    className={styles.inputFieldsm}
                    name="CIPL_No_spares"
                    value={formDatasmdocuments.data[index]?.CIPL_No_spares}
                    onChange={(e) => handleInputChangesdocs(e, index)}
                  />
                ) : sm.CIPL_No_spares ? (
                  sm.CIPL_No_spares
                ) : (
                  "-"
                )}
              </td>

              <td>
                {editModeDocuments ? (
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="CIPL_shared_on"
                    value={formDatasmdocuments?.data[index].CIPL_shared_on}
                    onChange={(e) => handleInputChangesdocs(e, index)}
                  />
                ) : sm.CIPL_shared_on ? (
                  new Date(sm.CIPL_shared_on).toLocaleDateString('en-GB')
                ) : (
                  "-"
                )}
              </td>

              <td style={{ minWidth: "200px" }}>
                {editModeDocuments ? (
                  <div style={{ position: 'relative' }}>  {/* Ensure container positioning if needed */}
                    <select
                      className={styles.inputFieldsm}
                      name="EC_obtained"
                      value={formDatasmdocuments.data[index].EC_obtained}
                      onChange={(e) => handleInputChangesdocs(e, index)}
                    >
                      <option value="">Select </option>
                      <option value="Yet to start">Yet to start</option>
                      <option value="In progress">In progress</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <svg
                      style={{
                        position: 'absolute',  // Make sure to position it absolutely within the div
                        right: "14px",
                        pointerEvents: 'none'  // Disable pointer events if it shouldn't be clickable
                      }}
                      className={styles.svgSelect}
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                        fill="rgba(57, 71, 89, 0.5)"
                      />
                    </svg>
                  </div>
                ) : sm.EC_obtained ? (
                  sm.EC_obtained
                ) : (
                  "-"
                )}
              </td>


              <td>
                {editModeDocuments ? (
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="Vessel_pre_alert"
                    value={formDatasmdocuments.data[index].Vessel_pre_alert}
                    onChange={(e) => handleInputChangesdocs(e, index)}
                  />
                ) : sm.Vessel_pre_alert ? (
                  new Date(sm.Vessel_pre_alert).toLocaleDateString('en-GB')
                ) : (
                  "-"
                )}
              </td>

              <td style={{ whiteSpace: "nowrap", paddingLeft: "17px", paddingRight: "17px" }}>
                {editModeDocuments ? (
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="ETD"
                    value={formDatasmdocuments.data[index].ETD}
                    onChange={(e) => handleInputChangesdocs(e, index)}
                  />
                ) : sm.ETD ? (
                  new Date(sm.ETD).toLocaleDateString('en-GB')
                ) : (
                  "-"
                )}
              </td>

              <td>
                {editModeDocuments ? (
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="ETA_for_port"
                    value={formDatasmdocuments.data[index].ETA_for_port}
                    onChange={(e) => handleInputChangesdocs(e, index)}
                  />
                ) : sm.ETA_for_port ? (
                  new Date(sm.ETA_for_port).toLocaleDateString('en-GB')
                ) : (
                  "-"
                )}
              </td>

              <td style={{ minWidth: "300px" }}>
                {editModeDocuments ? (
                  <div style={{ position: "relative" }}>
                    <select
                      className={styles.inputFieldsm}
                      name="DocumentsStatus"
                      value={formDatasmdocuments.data[index].DocumentsStatus}
                      onChange={(e) => handleInputChangesdocs(e, index)}
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <option value="">Select Status</option>

                      <option value="Under Procurement">Under Procurement</option>

                      <option value="In Transit to FS">In Transit to FS</option>

                      <option value="Extraction and Packaging">
                        Extraction and Packaging
                      </option>

                      <option value="Obtaining EC">Obtaining EC</option>

                      <option value="Waiting For Vessel">
                        Waiting For Vessel
                      </option>

                      <option value="In Transit from FS to nearby port">
                        In Transit from FS to nearby port
                      </option>

                      <option value="Under Customs Clearance">
                        Under Customs Clearance
                      </option>

                      <option value="Nearby Yard">Nearby Yard</option>

                      <option value="Portbase loading point to rig">
                        Portbase loading point to rig
                      </option>


                      <option value="In Transit to rig">
                        In Transit to rig
                      </option>
                      <option value="Onboard">
                        Onboard
                      </option>
                    </select>
                    <svg
                      style={{
                        position: 'absolute',  // Make sure to position it absolutely within the div
                        right: "14px",
                        pointerEvents: 'none'  // Disable pointer events if it shouldn't be clickable
                      }}
                      className={styles.svgSelect}
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                        fill="rgba(57, 71, 89, 0.5)"
                      />
                    </svg>
                  </div>

                ) : sm.DocumentsStatus ? (
                  sm.DocumentsStatus
                ) : (
                  "-"
                )}
              </td>

              <td>
                {editModeDocuments ? (
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="Last_updated_on"
                    value={formDatasmdocuments.data[index].Last_updated_on}
                    onChange={(e) => handleInputChangesdocs(e, index)}
                  />
                ) : sm.Last_updated_on ? (
                  new Date(sm.Last_updated_on).toLocaleDateString('en-GB')
                ) : (
                  "-"
                )}
              </td>

              <td style={{ paddingLeft: "10px", paddingRight: "10px", minWidth: "200px" }}>
                {editModeDocuments ? (
                  <textarea
                    type="text"
                    className={styles.inputFieldsmComments}
                    name="Comments"
                    value={formDatasmdocuments.data[index].Comments}
                    onChange={(e) => handleInputChangesdocs(e, index)}
                  />
                ) : sm.Comments ? (
                  sm.Comments
                ) : (
                  "-"
                )}
              </td>

              <td>
                {editModeDocuments ? (
                  <input
                    type="date"
                    className={styles.inputFieldsm}
                    name="Date_of_arrival_on_rig"
                    value={
                      formDatasmdocuments.data[index].Date_of_arrival_on_rig
                    }
                    onChange={(e) => handleInputChangesdocs(e, index)}
                  />
                ) : sm.Date_of_arrival_on_rig ? (
                  new Date(sm.Date_of_arrival_on_rig).toLocaleDateString('en-GB')
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}

          {showInputRowDocuments && (
            <tr key={rows.length} className={styles.addinputspares}>
              <td className={styles.tickcross}>
                <svg
                  style={{ cursor: "pointer" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={handleSubmitsmdocuments}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                    fill="#66D171"
                  />
                </svg>

                <svg
                  style={{ cursor: "pointer" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={handleDeleteRowdocs}
                >
                  <path
                    d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                    fill="#F97575"
                  />

                  <path
                    d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                    fill="#F97575"
                  />
                </svg>
              </td>

              <td></td>

              <td>
                <input
                  type="date"
                  className={styles.inputFieldsm}
                  name="Spares_requirement_shared_on"
                  value={formDatasmdocuments.Spares_requirement_shared_on}
                  onChange={handleInputChangevisdocs}
                />
              </td>

              <td>
                <input
                  type="text"
                  id="newCiplInput"
                  name="CIPL_No_spares"
                  className={styles.inputFieldsm}
                  value={newRowData?.CIPL_No_spares}
                  onChange={handleInputChangevisdocs}
                // onInput={(e) => fetchCiplSuggestions(e.target.value, "new")}
                />


              </td>
              {/* <td>
                <input
                  type="text"
                  id="newCiplInput"
                  name="CIPL_No_spares"
                  className={styles.inputFieldsm}
                  value={newRowData?.CIPL_No_spares}
                  onChange={handleInputChangevisdocs}
                  onInput={(e) => fetchCiplSuggestions(e.target.value, "new")}
                />

                <ul
                  id="newCiplSuggestionsList"
                  style={{ display: "none", cursor: "pointer" }}
                >
                  {Array.isArray(ciplSuggestions) &&
                    ciplSuggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleCiplSuggestionSelectNew(
                            suggestion.CIPL_No_spares
                          )
                        }
                      >
                        {suggestion.CIPL_No_spares}
                      </li>
                    ))}
                </ul>
              </td> */}

              <td>
                <input
                  type="date"
                  className={styles.inputFieldsm}
                  name="CIPL_shared_on"
                  value={formDatasmdocuments.CIPL_shared_on}
                  onChange={handleInputChangevisdocs}
                />
              </td>

              <td>
                <div style={{ position: "relative" }}>
                  <select
                    className={styles.inputFieldsm}
                    name="EC_obtained"
                    value={formDatasmdocuments.EC_obtained}
                    onChange={handleInputChangevisdocs}
                  >
                    <option value="">Select </option>

                    <option value="Yet to start">Yet to start</option>

                    <option value="In progress">In progress</option>

                    <option value="Completed">Completed</option>
                  </select>
                  <svg
                    style={{
                      position: 'absolute',  // Make sure to position it absolutely within the div
                      right: "14px",
                      pointerEvents: 'none'  // Disable pointer events if it shouldn't be clickable
                    }}
                    className={styles.svgSelect}
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                      fill="rgba(57, 71, 89, 0.5)"
                    />
                  </svg>
                </div>
              </td>

              <td>
                <input
                  type="date"
                  className={styles.inputFieldsm}
                  name="Vessel_pre_alert"
                  value={formDatasmdocuments.Vessel_pre_alert}
                  onChange={handleInputChangevisdocs}
                />
              </td>

              <td>
                <input
                  type="date"
                  className={styles.inputFieldsm}
                  name="ETD"
                  value={formDatasmdocuments.ETD}
                  onChange={handleInputChangevisdocs}
                />
              </td>

              <td>
                <input
                  type="date"
                  className={styles.inputFieldsm}
                  name="ETA_for_port"
                  value={formDatasmdocuments.ETA_for_port}
                  onChange={handleInputChangevisdocs}
                />
              </td>

              <td style={{ minWidth: "200px" }}>
                <div style={{ position: "relative" }}>
                  <select
                    className={styles.inputFieldsm}
                    name="DocumentsStatus"
                    value={formDatasmdocuments.DocumentsStatus}
                    onChange={handleInputChangevisdocs}
                  >
                    <option value="">Select Status</option>

                    <option value="Under Procurement">Under Procurement</option>

                    <option value="In Transit to FS">In Transit to FS</option>

                    <option value="Extraction and Packaging">
                      Extraction and Packaging
                    </option>

                    <option value="Obtaining EC">Obtaining EC</option>

                    <option value="Waiting For Vessel">Waiting For Vessel</option>

                    <option value="In Transit from FS to nearby port">
                      In Transit from FS to nearby port
                    </option>

                    <option value="Under Customs Clearance">
                      Under Customs Clearance
                    </option>

                    <option value="Nearby Yard">Nearby Yard</option>

                    <option value="Portbase loading point to rig">
                      Portbase loading point to rig
                    </option>

                    <option value="In Transit to rig">
                      In Transit to rig
                    </option>
                    <option value="Onboard">
                      Onboard
                    </option>
                  </select>
                  <svg
                    style={{
                      position: 'absolute',  // Make sure to position it absolutely within the div
                      right: "14px",
                      pointerEvents: 'none'  // Disable pointer events if it shouldn't be clickable
                    }}
                    className={styles.svgSelect}
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                      fill="rgba(57, 71, 89, 0.5)"
                    />
                  </svg>
                </div>
              </td>

              <td>
                <input
                  type="date"
                  className={styles.inputFieldsm}
                  name="Last_updated_on"
                  value={formDatasmdocuments.Last_updated_on}
                  onChange={handleInputChangevisdocs}
                />
              </td>

              <td style={{ paddingLeft: "10px", paddingRight: "10px", minWidth: "200px" }}>
                <textarea
                  type="text"
                  className={styles.inputFieldsmComments}
                  name="Comments"
                  value={formDatasmdocuments.Comments}
                  onChange={handleInputChangevisdocs}
                />
              </td>

              <td>
                <input
                  type="date"
                  className={styles.inputFieldsm}
                  name="Date_of_arrival_on_rig"
                  value={formDatasmdocuments.Date_of_arrival_on_rig}
                  onChange={handleInputChangevisdocs}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {featureman.length > 0 && showCiplButton && (
        <button
          className={styles.manualbuttonsm}
          onClick={() => handleManualInputClick("documents")}
          disabled={isButtonDisabled}
        >
          Add new CIPL
          <svg
            style={{ marginLeft: "5px" }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.493 1.66669H6.50964C3.4763 1.66669 1.66797 3.47502 1.66797 6.50835V13.4834C1.66797 16.525 3.4763 18.3334 6.50964 18.3334H13.4846C16.518 18.3334 18.3263 16.525 18.3263 13.4917V6.50835C18.3346 3.47502 16.5263 1.66669 13.493 1.66669ZM15.0013 10.625H10.6263V15C10.6263 15.3417 10.343 15.625 10.0013 15.625C9.65964 15.625 9.3763 15.3417 9.3763 15V10.625H5.0013C4.65964 10.625 4.3763 10.3417 4.3763 10C4.3763 9.65835 4.65964 9.37502 5.0013 9.37502H9.3763V5.00002C9.3763 4.65835 9.65964 4.37502 10.0013 4.37502C10.343 4.37502 10.6263 4.65835 10.6263 5.00002V9.37502H15.0013C15.343 9.37502 15.6263 9.65835 15.6263 10C15.6263 10.3417 15.343 10.625 15.0013 10.625Z"
              fill="white"
            />
          </svg>
        </button>
      )}
    </div>
  );


  console.log(smResults)

  return (
    <>
      <div className={styles.fileUploadedBtns}>
        {tab === "items" && !editModeItems && showEditButton && (
          <Button
            variant="contained"
            className={styles.saveButton}
            onClick={() => handleEdit("items")}

          >
            Edit
          </Button>
        )}

        {tab === "documents" && !editModeDocuments && showEditButton && (
          <Button
            variant="contained"
            className={styles.saveButton}
            onClick={() => handleEdit("documents")}

          >
            Edit
          </Button>
        )}

        {editModeItems && (
          <>
            <Button
              variant="contained"
              className={styles.saveButton}
              onClick={handlesavesm}
            >
              Save
            </Button>
            <Button
              variant="contained"
              className={styles.saveButton1}
              onClick={() => handlecancelsm("items")}
            >
              Cancel
            </Button>


          </>
        )}

        {editModeDocuments && (
          <>
            <Button
              variant="contained"
              className={styles.saveButton}
              onClick={handlesavesmdocuments}
            >
              Save
            </Button>
            <Button
              variant="contained"
              className={styles.saveButton1}
              onClick={() => handlecancelsm("documents")}
            >
              Cancel
            </Button>


          </>
        )}

        {/* {featureexcel.length > 0 && !editModeItems && !editModeDocuments && (
          <Button
            variant="contained"
            className={styles.saveButton}
            style={{ width: "200px" }}
            onClick={setOpen}
          >
            Import using excel
            <img style={{ marginLeft: "5px" }} src={excel} alt="excelIcon" />
          </Button>
        )} */}
      </div>

      <div className={styles.tabContainer}>
        <button
          className={`${styles["incomingButton"]} ${tab === "documents" ? styles["incoming"] : styles["not-incoming"]
            }`}
          onClick={() => setTab("documents")}
        >
          Documents
        </button>

        <button
          className={`${styles["outgoingButton"]} ${tab === "items" ? styles["outgoing"] : styles["not-outgoing"]
            }`}
          onClick={() => setTab("items")}
        >
          Items
        </button>
      </div>

      {tab === "items" ? renderItemsTable() : renderDocumentsTable()}

      <CustomModal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={style.modalbox}>
            <div className={style.mheader}>
              <p className={style.uploadImg}>Upload Excel</p>
            </div>

            <div className={style.fileupload}>
              <input
                type="file"
                accept=".xlsx,.xls,.csv"
                onChange={handleFileChange}
              />

              {fileContent.length > 0 && (
                <>
                  <div>
                    <p>File content:</p>

                    <table className={style.fileTable}>
                      <thead>
                        <tr>
                          {Array.from({ length: maxColumns }, (_, index) => (
                            <th key={index}>Column {index + 1}</th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {fileContent.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {Array.from(
                              { length: maxColumns },

                              (_, colIndex) => (
                                <td key={colIndex}>{row[colIndex] || ""}</td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className={style.validate}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>

                  {activeStep === 0 ? (
                    <div className={style.validate}>
                      <div className={style.validatelabel}>
                        <Button
                          variant="contained"
                          className={style.validatebutton}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className={style.validate}>
                      <div className={style.validatelabel}>
                        <Button
                          variant="contained"
                          className={style.validatebutton}
                          onClick={handlesubmittt}
                        >
                          Submit
                        </Button>

                        <Button
                          variant="contained"
                          className={style.validatebutton}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </Box>
        </Fade>
      </CustomModal>
    </>
  );
}

export default Sparesmobilization;
