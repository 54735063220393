import { useEffect, useState } from "react";
import axios from "axios";
import styles from "../Components/css/purchaseincominginspections.module.css";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Purchase_Incoming_Inspections() {
  const { Purchase_id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Incoming Inspections");
  const [indetails, setindetails] = useState([]);
  const [inspectionDetails, setInspectionDetails] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [featureincominginspection,setfeatureincominginspection] = useState([]);
 
  
  const handleDivClick = (tab, route) => {
    setActiveTab(tab);
    navigate(`${route}${Purchase_id}`);
  };

  useEffect(() => {
    async function fetchsmDetails() {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_details?ID_No=${Purchase_id}`
        );

        const res_inspection = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_inspections_get?Purchase_ID=${Purchase_id}`
        );

        setindetails(response.data.data);
        console.log("indetails", response.data.data);
        setInspectionDetails(res_inspection.data.data);
        console.log("inspectionDetails", res_inspection.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchsmDetails();
  }, [refresh]);

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }

    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const getBackgroundColor = (el) => {
    // Filter inspectionDetails for entries with the same Incoming_Delivery_ID
    const filteredEntries = inspectionDetails.filter(
      entry => entry.Incoming_Delivery_ID === el.S_No && entry.Incoming_Qty !== undefined && entry.Incoming_Qty !== null && entry.Incoming_Qty !== 0 && entry.Incoming_Qty !== "0"
    );
    console.log("filteredEntries",filteredEntries);
    // Check if any Actual_Received_Qty is null
    const anyNullReceivedQty = filteredEntries.some(entry => entry.Actual_Received_Qty === null || entry.Actual_Received_Qty === "");
    
    // Check the conditions
    if (filteredEntries.length === 0 || anyNullReceivedQty) {
      return '#900909'; // Pending
    } 
    else {
      return '#077D7D'; // Completed
    }
  };
  
  const getButtonText = (el) => {
    // Filter inspectionDetails for entries with the same Incoming_Delivery_ID
    const filteredEntries = inspectionDetails.filter(
      entry => entry.Incoming_Delivery_ID === el.S_No && entry.Incoming_Qty !== undefined && entry.Incoming_Qty !== null && entry.Incoming_Qty !== 0 && entry.Incoming_Qty !== "0"
    );
    
    // Check if any Actual_Received_Qty is null
    const anyNullReceivedQty = filteredEntries.some(entry => entry.Actual_Received_Qty === null || entry.Actual_Received_Qty === "");
    
    // Check the conditions
    if (filteredEntries.length === 0 || anyNullReceivedQty) {
      return 'PENDING';
    } 
    else {
      return 'COMPLETED';
    }
  };
  
  const handleInspectionDivClick = (S_No) => {
    navigate(`/purchase_incoming_inspections_update/${Purchase_id}/${S_No}`);
  }

  useEffect(()=>{
    async function fetchRigDetails(){
    try{
      const response = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
      );
    
    
 
      const workshoppurchasesincominginspection= response.data.filter((el)=>{
        return(
        el.Group_Name === localStorage.getItem("Group") &&
        el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Purchases Incoming Inspections" && (el.Access === "Can View" || el.Access === "Can Edit")
        );
      })
 
    
      setfeatureincominginspection(workshoppurchasesincominginspection);
 
    }catch(error){
      console.error(error);
    }
    }
    fetchRigDetails();
      },[]);
  return (
  
    <div className={styles.topdiv}>
      <div className={styles.sections1}>
        {" "}
        {/*Top_Bar_Div*/}
        <div
          className={
            activeTab === "Purchase Details" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick("Purchase Details", "/purchase_details/")
          }
        >
          Purchase Details
        </div>
        <div
          className={
            activeTab === "Item Details" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() => handleDivClick("Item Details", "/item_details/")}
        >
          Item Details
        </div>
        <div
          className={
            activeTab === "Incoming Deliveries"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Incoming Deliveries",
              "/purchase_incoming_deliveries/"
            )
          }
        >
          Incoming Deliveries
        </div>
        <div
          className={
            activeTab === "Incoming Inspections"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Incoming Inspections",
              "/purchase_incoming_inspections/"
            )
          }
        >
          Incoming Inspections
        </div>
        <div
          className={
            activeTab === "Items Received"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Items Received",
              "/purchase_items_received/"
            )
          }
        >
          Items Received
        </div>
      </div>
      { featureincominginspection.length>0 ?(
      <div className={styles.sections2}>
      {indetails
            .slice() // Create a shallow copy of the array
            .sort((a, b) => b.S_No - a.S_No) // Sort in descending order based on the S_No         
            .map((el, index) => (
              <div key={index} className={styles.projectList}
                onClick={() => handleInspectionDivClick(el.S_No)}
              >
                <div className={styles.textContainer}>
                  {`Arrival: ${formatDate(el.Expected_Arrival_Date) || '-'} \u00a0|\u00a0 
                    Dispatching Party: ${el.Dispatching_Party || '-'} \u00a0|\u00a0 
                    Bill of Entry: ${el.Entry_Bill_No || '-'} \u00a0|\u00a0 
                    Incoming Delivery ID: ${el.S_No || '-'} \u00a0|\u00a0 
                    Incoming Inspection ID: ${el.Incoming_Inspection_Id || '-'} \u00a0|\u00a0 
                  `}
                </div>
                <button
                  className={styles.deliveryStatusButton}
                  style={{ backgroundColor: getBackgroundColor(el), color: "white" }}>
                  {getButtonText(el)}
                </button>
              </div>
            ))}
      </div>
    
    ):(
      <div style={{ marginLeft: "30%", marginTop: "15%" }}>
      <h3>
        You do not have permissions to view this page.
      </h3>
    </div>
    )}
    </div>
  );
}

export default Purchase_Incoming_Inspections;