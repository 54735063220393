import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
function formatDate(dateString) {
  // Split the input date string (assuming format "YYYY-MM-DD" or similar)
  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // month index is 0-based
  const day = parseInt(parts[2], 10);

  // Create a date object using UTC to avoid timezone issues
  const date = new Date(Date.UTC(year, month, day));

  const formattedDay = date.getUTCDate().toString().padStart(2, '0');
  const monthIndex = date.getUTCMonth();
  const formattedYear = date.getUTCFullYear().toString().slice(2);

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const formattedMonth = monthNames[monthIndex];

  return `${formattedDay}-${formattedMonth}-${formattedYear}`;
}
export default function WorkshopTimeline({data}) {
  return (
    <div>
    <Timeline
      sx={{
        [`& .${timelineContentClasses.root}`]: {
          flex: 0.3,
        },
      }}
    >
      {data.map((item, index) => (
      <TimelineItem>
        <TimelineOppositeContent >
        <Typography sx={{
                  fontFamily: 'Open Sans',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19.07px',
                  color: '#000000' // Black color
                }}>
               {formatDate(item.Date)}
                </Typography>
        
        </TimelineOppositeContent>
        <TimelineSeparator>
        <TimelineDot sx={{ bgcolor: '#5B6BE1', width: 8, height: 8, padding: 0, border: 'none' }} />
        {index !== data.length-1 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
        <Typography variant="h6" component="h1" sx={{
                  fontFamily: 'Open Sans',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19.07px',
                  color: '#000000' // Black color
                }}>
                  {item.Task_description || 'No description'}
                </Typography>
        </TimelineContent>
      </TimelineItem>

      ))}
    </Timeline>
    </div>
  );
}