import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/supplierdetailsviewdetails.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/system";

function SupplierDetailsViewDetails() {
  const navigate = useNavigate();
  const { Supplier_ID } = useParams();
  const { Supplier_Name} = useParams()
  const [activeTab, setActiveTab] = useState("Details"); //Change to default tab

  const handleDivClick = (path) => {
    navigate(`/supplierdetails-${path}${Supplier_ID}/${Supplier_Name}`);
  };

  const [UploadFile, setUploadFile] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [formdata1, setFormData1] = useState({});
  const [originalData, setOriginalData] = useState({});

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formdata1, [name]: value });
  };

  const handleFileChange = (e) => {
    setUploadFile(e.target.files[0]); // Store selected file in state
  };

  const handleDates = (name, value) => {
    setFormData1({
        ...formdata1,
        [name]: value ? value.format("YYYY-MM-DD") : null,
    });
};

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    fetchData();
    setFormData1(originalData);
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supplier_details_view/${Supplier_ID}`
      );
  
      if (res.status === 200) {
        const details = res.data[0];
  
        // Add initial data variables with consistent date formatting
        const initialData = {
          SupplierName: details.Supplier_Name,
          RegDate: details.Reg_Date,
          Scope: details.Scope,
          DeliveryTime: details.Delivery_Time,
          Rating: details.Rating,
          Category: details.Category,
          TaxRegNo: details.Tax_Reg_No,
          Address: details.Address,
          POC: details.POC,
          Phone: details.Phone,
          Email: details.Email,
          Website: details.Website,
          Currency: details.Currency,
          PaymentTerms: details.Payment_Terms,
          AccountName: details.Account_Name,
          AccountNo: details.Account_No,
          BankCode: details.Bank_Code,
          BranchCode: details.Branch_Code,
          SwiftCode: details.Swift_Code,
          BankAddress: details.Bank_Address,
          DateOfEval: details.Date_Of_Eval,
          DateOfReEval: details.Date_Of_ReEval,
          ISOExpiry: details.ISO_Expiry,
          UploadFile: details.Upload_File,
        };
  
        setFormData1(initialData);
        setOriginalData(initialData);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  
  
  useEffect(() => {
    
    fetchData();
  }, [Supplier_ID]);
  console.log("initial data", formdata1);

  const handleSave = async (e) => {
    if (e) {
      e.preventDefault(); // Prevent the form from submitting the traditional way
    }
  

    const formData = new FormData();
    formData.append("UploadFile2",UploadFile);

    Object.keys(formdata1).forEach((key) => {
      formData.append(key, formdata1[key]); // Add all form data to FormData
    });

    console.log("formdata", formData);
    console.log("formdata1111", formdata1)

    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supplier_details_update/${Supplier_ID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type
          },
        } 
      );
      console.log("res",res)
      if (res.status === 200) {
        fetchData();
              // Manually update the formdata1 with the latest saved values
      // setFormData1((prevFormData) => ({
      //   ...prevFormData,
      //   RegDate: formdata1.RegDate ? dayjs(formdata1.RegDate).format("YYYY-MM-DD") : "",
      //   DateOfEval: formdata1.DateOfEval ? dayjs(formdata1.DateOfEval).format("YYYY-MM-DD") : "",
      //   DateOfReEval: formdata1.DateOfReEval ? dayjs(formdata1.DateOfReEval).format("YYYY-MM-DD") : "",
      //   ISOExpiry: formdata1.ISOExpiry ? dayjs(formdata1.ISOExpiry).format("YYYY-MM-DD") : "",
      // }));
        setIsEditing(false);
        
        alert("Supplier Edited successfully");
      }
    } catch (error) {
      console.error(error);
    }

  };


  const previewFile = (file) => {
    const fileURL = file;
    window.open(fileURL);
  };

  // Download the file
  const fileNameFromLink = (link) => {
    if (typeof link === "string" && link) {
      return link.split("/").pop().split("-").slice(1).join("-");
    }
    // Return an empty string if link is not valid
    return "";
  };

  const downloadFile = (fileUrl) => {
    console.log("file", fileUrl);

    const a = document.createElement("a");
    if (fileUrl !== null) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = fileNameFromLink(fileUrl); // You can set a filename here
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("There is no file to download");
    }
  };

  // mui styling for date picker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "280px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));


  return (
    <div className={styles.mainContainer}>
      <div className={styles.tabsBar}>
        <div>
          <h3
            className={styles.heading11}
          >{`Suppliers > ${Supplier_Name} | ID: ${Supplier_ID}`}</h3>
        </div>

        <div className={styles.tab}>
          <div
            className={
              activeTab === "Details" ? styles.DivTabs1 : styles.DivTabs2
            }
            onClick={() => handleDivClick("viewdetails/")}
            style={{ borderRadius: "8px 0 0 8px" }}
          >
            Details
          </div>
          <div
            className={
              activeTab === "Catalogue" ? styles.DivTabs1 : styles.DivTabs2
            }
            onClick={() => handleDivClick("viewcatalogue/")}
          >
            Catalogue
          </div>
          <div
            className={
              activeTab === "Orders" ? styles.DivTabs1 : styles.DivTabs2
            }
            onClick={() => handleDivClick("vieworders/")}
            style={{ borderRadius: "0 8px 8px 0" }}
          >
            Orders
          </div>
        </div>

        <div
          style={{
            width: "260px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {isEditing ? (
            <div>
              <button className={styles.editBtn} onClick={() => handleSave()}>
                Save
              </button>
              <button
                className={styles.cancelBtn}
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button className={styles.editBtn} onClick={() => handleEdit()}>
              Edit
            </button>
          )}
        </div>
      </div>

      <div className={styles.supplierContainer}>
        <form>
          <div className={styles.heading1}>
            <h5>1. Supplier Registration and Contact</h5>
          </div>

          <div className={styles.container1}>
            <div className={styles.leftContainer}>
              <div className={styles.formElement}>
                <label>Supplier Name*</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.SupplierName}
                    name="SupplierName"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Registration Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="RegDate"
                    value={
                      formdata1.RegDate
                        ? dayjs(formdata1.RegDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("RegDate", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.formElement}>
                <label>Approved Supplier Scope</label>
                <textarea
                  type="text"
                  value={formdata1.Scope}
                  name="Scope"
                  onChange={handleOnChange}
                  className={isEditing ? styles.textbox1b : styles.textbox1a}
                  disabled={!isEditing}
                />
              </div>

              <div className={styles.formElement}>
                <label>Standard Delivery Time</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.DeliveryTime}
                    name="DeliveryTime"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Rating</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.Rating}
                    name="Rating"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Category</label>
                <select
                  value={formdata1.Category}
                  name="Category"
                  onChange={handleOnChange}
                  className={isEditing ? null : styles.cursor}
                  disabled={!isEditing}
                  required
                >
                  <option value="">Select value</option>
                  <option value="Critical">Critical</option>
                  <option value="Non-Critical">Non-Critical</option>
                </select>
                <svg
                  style={{ position: "absolute", right: "15" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>

            <div className={styles.rightContainer}>
              <div className={styles.formElement}>
                <label>Tax Registration No</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.TaxRegNo}
                    name="TaxRegNo"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Address</label>
                <textarea
                  type="text"
                  value={formdata1.Address}
                  name="Address"
                  onChange={handleOnChange}
                  className={isEditing ? styles.textbox1b : styles.textbox1a}
                  disabled={!isEditing}
                />
              </div>

              <div className={styles.formElement}>
                <label>Supplier POC</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.POC}
                    name="POC"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Phone</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.Phone}
                    name="Phone"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Email</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.Email}
                    name="Email"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Website</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.Website}
                    name="Website"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

            </div>
          </div>

          <div className={styles.heading1}>
            <h5>2. Bank Account Details</h5>
          </div>

          <div className={styles.container1}>
            <div className={styles.leftContainer}>
              <div className={styles.formElement}>
                <label>Payment Currency</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.Currency}
                    name="Currency"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Payment Terms</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.PaymentTerms}
                    name="PaymentTerms"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Bank Account Name</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.AccountName}
                    name="AccountName"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Account Number</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.AccountNo}
                    name="AccountNo"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>
            </div>

            <div className={styles.rightContainer}>
              <div className={styles.formElement}>
                <label>Bank Code</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.BankCode}
                    name="BankCode"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Branch Code</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.BranchCode}
                    name="BranchCode"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Swift Code</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.SwiftCode}
                    name="SwiftCode"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Bank Address</label>
                <textarea
                  type="text"
                  value={formdata1.BankAddress}
                  name="BankAddress"
                  onChange={handleOnChange}
                  className={isEditing ? styles.textbox1b : styles.textbox1a}
                  disabled={!isEditing}
                />
              </div>
            </div>
          </div>

          <div className={styles.heading1}>
            <h5>3. Certification and Evaluation</h5>
          </div>

          <div className={styles.container1}>
            <div className={styles.leftContainer}>
              <div className={styles.formElement}>
                <label>Date of Evaluation</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="DateOfEval"
                    value={
                      formdata1.DateOfEval
                        ? dayjs(formdata1.DateOfEval, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("DateOfEval", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.formElement}>
                <label>Due Date of Re-Evaluation</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="DateOfReEval"
                    value={
                      formdata1.DateOfReEval
                        ? dayjs(formdata1.DateOfReEval, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("DateOfReEval", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className={styles.rightContainer}>
              <div className={styles.formElement}>
                <label>ISO Certificate Expiration Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="ISOExpiry"
                    value={
                      formdata1.ISOExpiry
                        ? dayjs(formdata1.ISOExpiry, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("ISOExpiry", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.formElement}>
                <label className={styles.uploadLabel}>Upload form (optional)</label>
                <div className={styles.uploadFieldContainer}>
                  {/* If not in edit mode, display the file name if uploaded, otherwise display 'No file chosen' */}
                  {!isEditing && (
                    <input
                      type="text"
                      value={
                        formdata1.UploadFile
                          ? fileNameFromLink(formdata1.UploadFile)
                          : "No file chosen"
                      }
                      readOnly
                      className={styles.fileNameInput}
                      disabled={!isEditing}
                    />
                  )}

                  {/* If in edit mode, display file upload input and additional functionality */}
                  {isEditing && (
                    <>
                      <input
                        type="file"
                        name="UploadFile"
                        style={{ display: "none", width: "0" }}
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            setFormData1({
                              ...formdata1,
                              UploadFile: e.target.files[0],
                            });
                          }
                        }}
                        id="UploadFile_Input"
                        disabled={!isEditing}
                      />
                      <div
                        className={styles["inquiry-input-upload"]}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          document.getElementById("UploadFile_Input").click()
                        }
                      >
                        <div>
                        <label
                          style={{
                            fontFamily: "Arial, sans-serif",
                            marginLeft: "4px",
                            marginTop: "5px",
                            color: "rgba(128, 128, 128, 0.8)",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "180px",
                          }}
                        >
                          {/* Show the uploaded file name or a placeholder */}
                          <span>
                            {formdata1.UploadFile?.name ||
                              fileNameFromLink(formdata1.UploadFile) ||
                              "Upload File"}
                          </span>
                        </label>

                        <svg
                          width="28"
                          height="25"
                          viewBox="0 0 28 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            position: "absolute",
                            left: "460px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                          }}
                        >
                          <path
                            d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                            fill="black"
                          />
                          <path
                            d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                            fill="black"
                          />
                        </svg>
                        </div>
                      </div>
                      
                      
                      {/* If a file is uploaded, show the remove (X) icon */}
                      {formdata1.UploadFile && (
                        <>
                          <div>
                            <img
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEJSURBVChTlZI9r0ZAEIXXbX1L0CoU/P9/IfGdUCgkSDSiolLMdeZlw3ur+yQbM2fmmLVLoRPxT36upyiKQkzTdGV/Qa0sy0+CSX3fYxrZtk1ZlkF6Ac2yLO4ZhoHYdBwHRVHEoq7rL2OapqyhFscx97IJzPNMQRBw0TAMyvOcF2JoYRhyD5AmMI6jNKqqygsxtNsAXiYAo+d53Izl+z5rT+Tp3ZxvFNu2XZkQ+76LZVmu7OIyM0mSkKZpPMF1XXIch2N8F2o30vRt6LqO2rblGBpqt5FN53h5rLirpmm4COq6Zu2euK7rx4STURSFt1NVFTc/gWaaJvdggNwe7uT7lJ6gdv5qZ0T0C/vJiM0XBD8WAAAAAElFTkSuQmCC"
                              alt="preview"
                              width="12.5"
                              height="12.5"
                              style={{ marginLeft: "9px", cursor: "pointer" }}
                              onClick={() =>
                                setFormData1({ ...formdata1, UploadFile: null })
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {/* If not in edit mode and a file exists, show preview and download options */}
                  {!isEditing && formdata1.UploadFile && (
                    <>
                      <div className={styles.uploadFieldContainer}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() => previewFile(formdata1.UploadFile)}
                        />
                      </div>

                      <div className={styles.uploadFieldContainer}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() => downloadFile(formdata1.UploadFile)}
                        />
                      </div>
                    </>
                  )}
                </div>
                
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SupplierDetailsViewDetails;
