import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import styles from "../Components/css/newtimesheet.module.css";
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const Newtimesheet = () => {
    const navigate = useNavigate();
    const [maindate, setmaindate] = useState('');
    const [maindate2, setmaindate2] = useState('');
    const [Fildata, setFildata] = useState([]);
    const [Techarray, setTecharray] = useState([]);
    const [Projectarray, setProjectarray] = useState([]);
    const [inputValues, setInputValues] = useState([]);
    const [conby, setconby] = useState('MOHAMMAD AJHAR HUSSAIN');
  
    useEffect(() => {
      async function fetchRigDetails() {
        try {
          const response = await axios.get(
            "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolbox_data"
          );
  
          const filteredData = response.data.data.filter(el => el.Date === maindate);
          setFildata(filteredData);

  
          const attendees = filteredData[0]?.Attendees || '';
          const supervisors = filteredData[0]?.Supervisors || '';
          const combinedString = (attendees !== '' ? attendees + ',' : '') + (supervisors !== '' ? supervisors : '');

          console.log(combinedString);
          setTecharray(combinedString.split(','));
  
          const projectArray = response.data.data
          .filter(el => el.Date === maindate)
            .map(item => item.Project_so)
            // .filter(project => project !== null)
            // .filter((value, index, self) => self.indexOf(value) === index);
            const uniqueArrayp = [...new Set(projectArray)]
          setProjectarray(uniqueArrayp);
          setInputValues(Array(combinedString.split(',').length).fill().map(() => Array(projectArray.length).fill('')));
        } catch (error) {
          console.error(error);
        }
      }
  
      if (maindate) {
        fetchRigDetails();
      }
    }, [maindate]);
  
    const handledate = (e) => {
      const dateParts = e.target.value.split("-");
      const [year, month, day] = dateParts;
      const formattedDate = `${day}/${month}/${year}`;
      setmaindate(formattedDate);
      setmaindate2(e.target.value);
    }


    const handleconby = (e) => {
      setconby(e.target.value);
    }



    const handlesubmit = async () => {
        Techarray.map(async (tech, techindex) => {
          await Projectarray.map(async (project, proindex) => {
            const payload = {
              Date: maindate,
              Conducted_by:conby,
              Tech: tech,
              Project: project,
              Manhours: inputValues[techindex][proindex],
            };
      
            try {
              const res = await axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshoptimesheet_insert", payload);
              console.log(res);
              navigate(`/Timesheet_edit/${maindate2}`); // Change '/success' to the desired path
            } catch (error) {
              console.error("Error:", error);
            }
          });
        });
      };
    

    const handleInputChange = (techIndex, projectIndex, value) => {
        const newInputValues = [...inputValues];
        newInputValues[techIndex][projectIndex] = value;
        setInputValues(newInputValues);
      }

    const inputElements = Techarray.map((tech, techIndex) => (
        <tr key={techIndex}>
          <td>{tech}</td>
          {Projectarray.map((project, projectIndex) => (
            <td key={projectIndex}>
              <input
                type="number"
                value={inputValues[techIndex]?.[projectIndex] || ""}
                onChange={(e) => handleInputChange(techIndex, projectIndex, e.target.value)}
              />
            </td>
          ))}
        </tr>
      ));
  
    return (
      <>
      <div className={styles.maindiv}>
        <h4 className={styles.mainheading1}><NavLink to="/admintaskbar"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M22.4598 10.4922H5.25583L12.772 3.09556C13.3727 2.50443 13.3727 1.53438 12.772 0.943258C12.6295 0.802747 12.4602 0.691271 12.2739 0.61521C12.0876 0.53915 11.8879 0.5 11.6861 0.5C11.4844 0.5 11.2847 0.53915 11.0984 0.61521C10.912 0.691271 10.7428 0.802747 10.6003 0.943258L0.450421 10.9318C0.30764 11.072 0.194362 11.2385 0.117073 11.4219C0.0397835 11.6053 0 11.8018 0 12.0003C0 12.1988 0.0397835 12.3954 0.117073 12.5788C0.194362 12.7621 0.30764 12.9287 0.450421 13.0689L10.6003 23.0574C10.7429 23.1977 10.9122 23.309 11.0985 23.385C11.2848 23.4609 11.4845 23.5 11.6861 23.5C11.8878 23.5 12.0875 23.4609 12.2738 23.385C12.4601 23.309 12.6294 23.1977 12.772 23.0574C12.9146 22.9171 13.0277 22.7505 13.1049 22.5671C13.182 22.3838 13.2217 22.1873 13.2217 21.9888C13.2217 21.7904 13.182 21.5939 13.1049 21.4105C13.0277 21.2272 12.9146 21.0606 12.772 20.9202L5.25583 13.5236H22.4598C23.3069 13.5236 24 12.8415 24 12.0079C24 11.1743 23.3069 10.4922 22.4598 10.4922Z" fill="white"/>
</svg></NavLink> Timesheet</h4>
<div className={styles.div2}>
    <h6>Date:</h6>
        <input type="date" onChange={handledate}></input>
        <h6 style={{marginLeft:"120px"}}>Prepared by:</h6>
        <input value={conby}  onChange={handleconby} style={{width:"300px"}} ></input>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{marginLeft:"-25px" , marginTop:"20px"}}>
  <path d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z" fill="#5B6BE1"/>
</svg> */}
        <button onClick={handlesubmit}>Save</button>
        </div>
        <table className={styles.maintable}>
          <thead>
            <tr>
              <th>Techs/Projects</th>
              {Projectarray.map((project, index) => (
  <th key={index}>{project}</th>
))}
            </tr>
          </thead>
          <tbody>
            {inputElements}
          </tbody>
        </table>
        </div>
      </>
    );
  }
  
  export default Newtimesheet;