import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styles from "../Components/css/Personal_mobilization.module.css";
import Box from "@mui/material/Box";
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Personal(props){
  const {id, SuperArray, TechArray, TrainArray, projectList} = props;
  const [personalMobilisation, setPersonalMobilisation] = useState(1);
  const [mobilisationTabs, setMobilisationTabs] = useState(true);
  const [getCustomerDocs, setGetCustomerDocs] = useState("");
  const [visamodal, setvisamodal] = useState(false);
  const [uploadticketfilename, setuploadticketfilename] = useState("");
  const [visafilename, setvisafilename] = useState("");
  const [uploadbookingfilename, setuploadbookingfilename] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [docedit, setdocedit] = useState(true);
  const [returnuploadticketfilename, setreturnuploadticketfilename] =
    useState("");
  const [returnuploadbookingfilename, setreturnuploadbookingfilename] =
    useState("");
    const [removed, setremoved] = useState({
      upticket:false,
      upbook : false,
      reupticket: false,
      reupbook: false,
    });
    const [uploadticketURL, setUploadticketURL] = useState(null);
    const [uploadticketname, setUploadticketname] = useState("");
    const [uploadvisaname, setUploadvisaname] = useState("");
    const [uploadbookingURL, setUploadbookingURL] = useState(null);
    const [uploadvisaURL, setUploadvisaURL] = useState(null);
    const [uploadbookingname, setUploadbookingname] = useState("");
    const [returnuploadticketURL, setreturnUploadticketURL] = useState(null);
    const [returnuploadticketname, setreturnUploadticketname] = useState("");
    const [returnuploadbookingURL, setreturnsetUploadbookingURL] = useState(null);
    const [returnuploadbookingname, setreturnsetUploadbookingname] = useState("");   
    const [visaname, setvisaname] = useState("");
    const [visaexpiry, setvisaexpiry] = useState("");
    const [visadoc, setvisadoc] = useState(null);

    const [featureedit, setfeatureedit] = useState([]);
  const [featureedit5, setfeatureedit5] = useState([]);
  const [featureedit6, setfeatureedit6] = useState([]);
  const [profdetails, setprofdetails] = useState([]);


  const [docs, setDocs] = useState([]);

  const [forwarddocs, setForwarddocs] = useState({
    boarding_Airport: "",
    boarding_Date: "",
    arrival_Airport: "",
    arrival_Date: "",
    upload_Tickets: "",
    hotel_Name: "",
    booking_Dates_checkin: "",
    booking_Dates_checkout: "",
    upload_Bookings: "",
    return_boarding_airport: "",
    return_boarding_date: "",
    return_arrival_airport: "",
    return_arrival_date: "",
    return_upload_ticket: "",
    return_hotel_name: "",
    return_booking_checkin: "",
    return_booking_checkout: "",
    return_upload_booking: "",
    temp_visa_doc:"",
    temp_visa_expiry:"",
  });
  const [editMode5, setEditMode5] = useState(false);
  const [editMode6, setEditMode6] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const personalMobilsationTab = (index) => {
    setPersonalMobilisation(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details");
        const filtered = res.data.filter((el) => el.Email === localStorage.getItem("useremail"));
        setprofdetails(filtered);
        console.log(filtered);
  
        if (localStorage.getItem("Group") === "Supervisor" || 
            localStorage.getItem("Group") === "Technician" || 
            localStorage.getItem("Group") === "Trainee") {
          setGetCustomerDocs(filtered[0]?.Customer_Name);
        } else {
          if (SuperArray && SuperArray.length > 0 && SuperArray[0] !== "") {
            setGetCustomerDocs(SuperArray[0]);
          } else if (TechArray && TechArray.length > 0 && TechArray[0] !== "") {
            setGetCustomerDocs(TechArray[0]);
          } else if (TrainArray && TrainArray.length > 0 && TrainArray[0] !== "") {
            setGetCustomerDocs(TrainArray[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );
  
        const group = localStorage.getItem("Group");
        const roles = JSON.parse(localStorage.getItem("Roles")) || []; // Retrieve roles from localStorage
  
        // Group feature filter for "Documents"
        const documentsGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Personnel Mobilization" &&
            el.Feature_Name === "Documents" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Role feature filter for "Documents"
        const documentsRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Personnel Mobilization" &&
            el.Feature === "Documents" &&
            el.Access === "Can View"
          );
        });
  
        // Combine group and role features for "Documents"
        const combinedDocuments = [...documentsGroup, ...documentsRole];
  
        // Group feature filter for "Forward Mobilization"
        const forwardMobilizationGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Personnel Mobilization" &&
            el.Feature_Name === "Forward Mobilization" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Role feature filter for "Forward Mobilization"
        const forwardMobilizationRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Personnel Mobilization" &&
            el.Feature === "Forward Mobilization" &&
            el.Access === "Can View"
          );
        });
  
        // Combine group and role features for "Forward Mobilization"
        const combinedForwardMobilization = [...forwardMobilizationGroup, ...forwardMobilizationRole];
  
        // Group feature filter for "Return Mobilization"
        const returnMobilizationGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Personnel Mobilization" &&
            el.Feature_Name === "Return Mobilization" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Role feature filter for "Return Mobilization"
        const returnMobilizationRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Personnel Mobilization" &&
            el.Feature === "Return Mobilization" &&
            el.Access === "Can View"
          );
        });
  
        // Combine group and role features for "Return Mobilization"
        const combinedReturnMobilization = [...returnMobilizationGroup, ...returnMobilizationRole];
  
        // Prioritize "Can View" over "Can Edit"
        const prioritizeAccess = (features) => {
          const featureMap = {};
          features.forEach((feature) => {
            const key = `${feature.Header_Name || feature.Header}-${feature.Feature_Name || feature.Feature}`;
            if (!featureMap[key] || feature.Access === "Can View") {
              featureMap[key] = feature;
            }
          });
          return Object.values(featureMap);
        };
  
        // Update states with prioritized features
        setfeatureedit(prioritizeAccess(combinedDocuments));
        setfeatureedit5(prioritizeAccess(combinedForwardMobilization));
        setfeatureedit6(prioritizeAccess(combinedReturnMobilization));
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  const handleforward = (e) => {
    const { name, value } = e.target;
    setForwarddocs({ ...forwarddocs, [name]: value });
  };
  const handleforwardfile = (e) => {
    const { name } = e.target;
    const selectedFile = e.target.files[0];
    setForwarddocs({ ...forwarddocs, [name]: selectedFile });
    if (name === "upload_Tickets"){
      setUploadticketURL(URL.createObjectURL(selectedFile));
      setUploadticketname(selectedFile.name);
    }
    if (name === "upload_Bookings"){
      setUploadbookingURL(URL.createObjectURL(selectedFile));
      setUploadbookingname(selectedFile.name)
    }
    if (name === "return_upload_ticket"){
      setreturnUploadticketURL(URL.createObjectURL(selectedFile));
      setreturnUploadticketname(selectedFile.name);
    }
    if(name === "return_upload_booking"){
      setreturnsetUploadbookingURL(URL.createObjectURL(selectedFile));
      setreturnsetUploadbookingname(selectedFile.name);
    }
  };

  const removevisafile = () => {
    setUploadvisaname("");
    setForwarddocs({...forwarddocs, temp_visa_doc:""});
    setvisamodal(true);
  }

  useEffect(() => {
    const CustomerDocs = () => {
      axios
        .get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/personalmobilization_details?Customer_Name=${getCustomerDocs}`
        )
        .then((res) => {
          setDocs(res.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    CustomerDocs();
    setEditMode(false);

  }, [getCustomerDocs, refresh]);

  const handlevisafile = (e) => {
    const selectedfile =  e.target.files[0];
    setUploadvisaURL(URL.createObjectURL(selectedfile));
    setUploadvisaname(selectedfile.name);
    setForwarddocs({...forwarddocs, temp_visa_doc : selectedfile});
  }

  useEffect(() => {
    if (docs.length > 0 && projectList?.Rig_Location !== "") {
      if (forwarddocs.temp_visa_doc){
        setvisadoc(forwarddocs.temp_visa_doc);
        // const convertedVisaExpiry = (() => {
        //   const parts = forwarddocs.temp_visa_expiry.split("-");
        //   if (parts.length === 3) {
        //     return `${parts[2]}/${parts[1]}/${parts[0]}`;
        //   } else {
        //     return forwarddocs.temp_visa_expiry; // Keep the original format if conversion fails
        //   }
        // });
        setvisaexpiry(forwarddocs.temp_visa_expiry);
        setvisaname("Temp_Visa");
      } else if ((docs[0]?.Active_Visas_One || '').replace(/\s/g, '').toLowerCase() === (projectList?.Rig_Location || '').replace(/\s/g, '').toLowerCase()) {
        setvisaname("Active_Visas_One");
        setvisadoc(docs[0]?.Active_Visas_One_Doc);
        setvisaexpiry(docs[0]?.Active_Visas_One_Expiry);
        setdocedit(false);
      } else if ((docs[0]?.Active_Visas_Two || '').replace(/\s/g, '').toLowerCase() === (projectList?.Rig_Location || '').replace(/\s/g, '').toLowerCase()) {
        setvisaname("Active_Visas_Two");
        setvisadoc(docs[0]?.Active_Visas_Two_Doc);
        setvisaexpiry(docs[0]?.Active_Visas_Two_Expiry);
        setdocedit(false);
      } else if ((docs[0]?.Active_Visas_Three || '').replace(/\s/g, '').toLowerCase() === (projectList?.Rig_Location || '').replace(/\s/g, '').toLowerCase()) {
        setvisaname("Active_Visas_Three");
        setvisadoc(docs[0]?.Active_Visas_Three_Doc);
        setvisaexpiry(docs[0]?.Active_Visas_Three_Expiry);
        setdocedit(false);
      } else if ((docs[0]?.Active_Visas_Four || '').replace(/\s/g, '').toLowerCase() === (projectList?.Rig_Location || '').replace(/\s/g, '').toLowerCase()) {
        setvisaname("Active_Visas_Four");
        setvisadoc(docs[0]?.Active_Visas_Four_Doc);
        setvisaexpiry(docs[0]?.Active_Visas_Four_Expiry);
        setdocedit(false);
      } else if ((docs[0]?.Active_Visas_Five || '').replace(/\s/g, '').toLowerCase() === (projectList?.Rig_Location || '').replace(/\s/g, '').toLowerCase()) {
        setvisaname("Active_Visas_Five");
        setvisadoc(docs[0]?.Active_Visas_Five_Doc);
        setvisaexpiry(docs[0]?.Active_Visas_Five_Expiry);
        setdocedit(false);
      } else if ((docs[0]?.Active_Visas_Six || '').replace(/\s/g, '').toLowerCase() === (projectList?.Rig_Location || '').replace(/\s/g, '').toLowerCase()) {
        setvisaname("Active_Visas_Six");
        setvisadoc(docs[0]?.Active_Visas_Six_Doc);
        setvisaexpiry(docs[0]?.Active_Visas_Six_Expiry);
        setdocedit(false);
      }
      else{
        setvisaname("");
        setvisadoc(null);
        setvisaexpiry("");
      }
    }
  },[docs, forwarddocs.temp_visa_doc]);

  const handleEdit = async () => {
    const formData = new FormData();
        formData.append('Temp_Visa_Doc', forwarddocs.temp_visa_doc);
        formData.append('Temp_Visa_Expiry', forwarddocs.temp_visa_expiry);

        const res = await axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/visafile_update?Customer_Name=${getCustomerDocs}&ID=${id}`,
          formData,
          {
            headers:{
              'Content-Type' : 'multipart/form-data'
            }
          }
        );

        if(visamodal && forwarddocs.temp_visa_doc === ""){
          const res4 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details/remove_visa?Customer_Name=${getCustomerDocs}&ID=${id}`);
          console.log(res4);
        }
        console.log(res.data.message);
        setEditMode(false);
        setrefresh(!refresh);
  }

  useEffect(() => {
    const ForwardDetails = () => {
      axios
        .get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization?Customer_Name=${getCustomerDocs}&ID=${id}`
        )
        .then((res) => {
          const {
            Boarding_Airport,
            Boarding_Date,
            Arrival_Airport,
            Arrival_Date,
            Upload_Tickets,
            Hotel_Name,
            Booking_Dates_checkin,
            Booking_Dates_Checkout,
            Upload_Bookings,
            return_Boarding_Airport,
            return_Boarding_Date,
            return_Arrival_Airport,
            return_Arrival_Date,
            return_Upload_Ticket,
            return_Hotel_Name,
            return_Booking_Checkin,
            return_Booking_Checkout,
            return_Upload_Booking,
            Temp_Visa_Doc,
            Temp_Visa_Expiry,
          } = res.data[0];
          setForwarddocs({
            boarding_Airport: Boarding_Airport,
            boarding_Date: Boarding_Date,
            arrival_Airport: Arrival_Airport,
            arrival_Date: Arrival_Date,
            upload_Tickets: Upload_Tickets,
            hotel_Name: Hotel_Name,
            booking_Dates_checkin: Booking_Dates_checkin,
            booking_Dates_checkout: Booking_Dates_Checkout,
            upload_Bookings: Upload_Bookings,
            return_boarding_airport: return_Boarding_Airport,
            return_boarding_date: return_Boarding_Date,
            return_arrival_airport: return_Arrival_Airport,
            return_arrival_date: return_Arrival_Date,
            return_upload_ticket: return_Upload_Ticket,
            return_hotel_name: return_Hotel_Name,
            return_booking_checkin: return_Booking_Checkin,
            return_booking_checkout: return_Booking_Checkout,
            return_upload_booking: return_Upload_Booking,
            temp_visa_doc: Temp_Visa_Doc,
            temp_visa_expiry: Temp_Visa_Expiry,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    setEditMode5(false);
    setEditMode6(false);
    setUploadticketURL(null);
    setUploadbookingURL(null);
    setreturnUploadticketURL(null);
    setreturnsetUploadbookingURL(null);
    setUploadticketname("");
    setUploadbookingname("");
    setreturnUploadticketname("");
    setreturnsetUploadbookingname("");
    ForwardDetails();
  }, [getCustomerDocs, refresh]);

  useEffect(() => {
    if (forwarddocs.upload_Tickets && forwarddocs.upload_Tickets !== " ") {
      const uploadticketstring = forwarddocs.upload_Tickets.toString();
      const parts1 = uploadticketstring
        .split("/")
        .pop()
        .split("-")
        .slice(1)
        .join("-");
      setuploadticketfilename(parts1);
    }

    if (forwarddocs.upload_Bookings && forwarddocs.upload_Bookings !== " ") {
      const uploadbookingstring = forwarddocs.upload_Bookings.toString();
      const parts2 = uploadbookingstring
        .split("/")
        .pop()
        .split("-")
        .slice(1)
        .join("-");
      setuploadbookingfilename(parts2);
    }

    if (forwarddocs.temp_visa_doc && forwarddocs.temp_visa_doc !== " ") {
      const uploadbookingstring = forwarddocs.temp_visa_doc.toString();
      const parts2 = uploadbookingstring
        .split("/")
        .pop()
        .split("-")
        .slice(1)
        .join("-");
      setvisafilename(parts2);
    }

    if (
      forwarddocs.return_upload_ticket &&
      forwarddocs.return_upload_ticket !== " "
    ) {
      const returnuploadticketstring =
        forwarddocs.return_upload_ticket.toString();
      const parts3 = returnuploadticketstring
        .split("/")
        .pop()
        .split("-")
        .slice(1)
        .join("-");
      setreturnuploadticketfilename(parts3);
    }

    if (
      forwarddocs.return_upload_booking &&
      forwarddocs.return_upload_booking !== " "
    ) {
      const returnuploadbookingstring =
        forwarddocs.return_upload_booking.toString();
      const parts4 = returnuploadbookingstring
        .split("/")
        .pop()
        .split("-")
        .slice(1)
        .join("-");
      setreturnuploadbookingfilename(parts4);
    }
  }, [
    forwarddocs.upload_Tickets,
    forwarddocs.upload_Bookings,
    forwarddocs.return_upload_ticket,
    forwarddocs.return_upload_booking,
    forwarddocs.temp_visa_doc,
  ]);

//   useEffect(() => {
//     const Customercheck = () => {
//       axios
//         .get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getforward_mobilization?ID=${id}`)
//         .then((res) => {
//           const Customer_Name = res.data;
//           setCustomerArray(Customer_Name);
//         })
//         .catch((err) => {
//           console.log("error", err);
//         });
//     };
//     Customercheck();
//   }, [getCustomerDocs]);

  const removefile = (i) => {
    if (i === "uploadtick"){
      setUploadticketname("");
      setForwarddocs({...forwarddocs, upload_Tickets : ""});
      setremoved({...removed, upticket: true});
    }
    if (i === "uploadbook"){
      setUploadbookingname("");
      setForwarddocs({...forwarddocs, upload_Bookings : ""});
      setremoved({...removed, upbook: true});
      
    }
    if (i === "reuploadtick"){
      setreturnUploadticketname("");
      setForwarddocs({...forwarddocs, return_upload_ticket : ""});
      setremoved({...removed, reupticket: true});
    }

    if (i === "reuploadbook"){
      setreturnsetUploadbookingname("");
      setForwarddocs({...forwarddocs, return_upload_booking : ""});
      setremoved({...removed, reupbook: true});
    }
  }

  const handleforwarddoc = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = ""; // Set desired filename
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };

  const handlezipdoc = async (i, j, k, l) => {
    const zip = new JSZip();
    const files = [];
  

    const fetchAndAddToZip = async (link, fileName) => {
      if (!link || link.trim() === "") {
        console.warn(`Skipping empty URL for ${fileName}`);
        return;
      }
  
      const response = await fetch(link);
  
      if (response.ok) {
        const blob = await response.blob();
        zip.file(fileName, blob);
      } else {
        console.error(`Failed to fetch ${fileName}`);
      }
    };
  

    const contentTypeI = await getContentType(i);
    if (contentTypeI) {
      files.push({ name: `Passport.${getFileExtension(contentTypeI)}`, link: i });
    }
  
    const contentTypeJ = await getContentType(j);
    if (contentTypeJ) {
      files.push({ name: `Insurance.${getFileExtension(contentTypeJ)}`, link: j });
    }
  
    const contentTypeK = await getContentType(k);
    if (contentTypeK) {
      files.push({ name: `Bosiet.${getFileExtension(contentTypeK)}`, link: k });
    }

    const contentTypeL = await getContentType(l);
    if (contentTypeL) {
      files.push({ name: `Seaman book.${getFileExtension(contentTypeL)}`, link: l });
    }
  

    const promises = files.map((file) => fetchAndAddToZip(file.link, file.name));
    await Promise.all(promises);
  
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "Files.zip");
  };
  
  const getContentType = async (url) => {
    try {
      if (!url || url.trim() === "") {
        return null;
      }
  
      const response = await fetch(url, { method: 'HEAD' });
  
      if (response.ok) {
        const contentType = response.headers.get('content-type');
        return contentType;
      } else {
        console.error(`Failed to fetch HEAD information for ${url}`);
        return null;
      }
    } catch (error) {
      console.error(`Error while fetching HEAD for ${url}:`, error);
      return null;
    }
  };
  

  const getFileExtension = (contentType) => {
    const parts = contentType.split('/');
    if (parts.length === 2) {
      return parts[1];
    }
    return '';
  };

  const handleCancel7 = () => {
    setEditMode5(false);
    axios
      .get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization?Customer_Name=${getCustomerDocs}&ID=${id}`
      )
      .then((res) => {
        const {
          Boarding_Airport,
          Boarding_Date,
          Arrival_Airport,
          Arrival_Date,
          Upload_Tickets,
          Hotel_Name,
          Booking_Dates_checkin,
          Booking_Dates_Checkout,
          Upload_Bookings,
          return_Boarding_Airport,
          return_Boarding_Date,
          return_Arrival_Airport,
          return_Arrival_Date,
          return_Upload_Ticket,
          return_Hotel_Name,
          return_Booking_Checkin,
          return_Booking_Checkout,
          return_Upload_Booking,
          Temp_Visa_Doc,
          Temp_Visa_Expiry,
        } = res.data[0];
        setForwarddocs({
          boarding_Airport: Boarding_Airport,
          boarding_Date: Boarding_Date,
          arrival_Airport: Arrival_Airport,
          arrival_Date: Arrival_Date,
          upload_Tickets: Upload_Tickets,
          hotel_Name: Hotel_Name,
          booking_Dates_checkin: Booking_Dates_checkin,
          booking_Dates_checkout: Booking_Dates_Checkout,
          upload_Bookings: Upload_Bookings,
          return_boarding_airport: return_Boarding_Airport,
          return_boarding_date: return_Boarding_Date,
          return_arrival_airport: return_Arrival_Airport,
          return_arrival_date: return_Arrival_Date,
          return_upload_ticket: return_Upload_Ticket,
          return_hotel_name: return_Hotel_Name,
          return_booking_checkin: return_Booking_Checkin,
          return_booking_checkout: return_Booking_Checkout,
          return_upload_booking: return_Upload_Booking,
          temp_visa_doc: Temp_Visa_Doc,
          temp_visa_expiry: Temp_Visa_Expiry,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });

      setUploadticketURL(null);
    setUploadbookingURL(null);
    setremoved({...removed, upticket : false});
    setremoved({...removed, upbook : false});
   
  };

  const  handleCancel = () => {
    setEditMode(false);
    setrefresh(!refresh);
  }

  const handleCancel6 = () => {
    setEditMode6(false);
    axios
      .get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization?Customer_Name=${getCustomerDocs}&ID=${id}`
      )
      .then((res) => {
        const {
          Boarding_Airport,
          Boarding_Date,
          Arrival_Airport,
          Arrival_Date,
          Upload_Tickets,
          Hotel_Name,
          Booking_Dates_checkin,
          Booking_Dates_Checkout,
          Upload_Bookings,
          return_Boarding_Airport,
          return_Boarding_Date,
          return_Arrival_Airport,
          return_Arrival_Date,
          return_Upload_Ticket,
          return_Hotel_Name,
          return_Booking_Checkin,
          return_Booking_Checkout,
          return_Upload_Booking,
          Temp_Visa_Doc,
          Temp_Visa_Expiry,
        } = res.data[0];
        setForwarddocs({
          boarding_Airport: Boarding_Airport,
          boarding_Date: Boarding_Date,
          arrival_Airport: Arrival_Airport,
          arrival_Date: Arrival_Date,
          upload_Tickets: Upload_Tickets,
          hotel_Name: Hotel_Name,
          booking_Dates_checkin: Booking_Dates_checkin,
          booking_Dates_checkout: Booking_Dates_Checkout,
          upload_Bookings: Upload_Bookings,
          return_boarding_airport: return_Boarding_Airport,
          return_boarding_date: return_Boarding_Date,
          return_arrival_airport: return_Arrival_Airport,
          return_arrival_date: return_Arrival_Date,
          return_upload_ticket: return_Upload_Ticket,
          return_hotel_name: return_Hotel_Name,
          return_booking_checkin: return_Booking_Checkin,
          return_booking_checkout: return_Booking_Checkout,
          return_upload_booking: return_Upload_Booking,
          temp_visa_doc: Temp_Visa_Doc,
          temp_visa_expiry: Temp_Visa_Expiry,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });

     
    setreturnUploadticketURL(null);
    setreturnsetUploadbookingURL(null);
    setremoved({...removed, reupticket : false});
    setremoved({...removed, reupbook : false});
  };

  const handleEdit5 = async (e) => {
    e.preventDefault();
    setEditMode5(false);
    const {
      boarding_Airport,
      boarding_Date,
      arrival_Airport,
      arrival_Date,
      upload_Tickets,
      hotel_Name,
      booking_Dates_checkin,
      booking_Dates_checkout,
      upload_Bookings,
      return_boarding_airport,
      return_boarding_date,
      return_arrival_airport,
      return_arrival_date,
      return_upload_ticket,
      return_hotel_name,
      return_booking_checkin,
      return_booking_checkout,
      return_upload_booking,
    } = forwarddocs;

    const payloadfm = {
      Boarding_Airport: boarding_Airport,
      Boarding_Date: boarding_Date,
      Arrival_Airport: arrival_Airport,
      Arrival_Date: arrival_Date,
      Upload_Tickets: upload_Tickets,
      Hotel_Name: hotel_Name,
      Booking_Dates_checkin: booking_Dates_checkin,
      Booking_Dates_Checkout: booking_Dates_checkout,
      Upload_Bookings: upload_Bookings,
      return_Boarding_Airport: return_boarding_airport,
      return_Boarding_Date: return_boarding_date,
      return_Arrival_Airport: return_arrival_airport,
      return_Arrival_Date: return_arrival_date,
      return_Upload_Ticket: return_upload_ticket,
      return_Hotel_Name: return_hotel_name,
      return_Booking_Checkin: return_booking_checkin,
      return_Booking_Checkout: return_booking_checkout,
      return_Upload_Booking: return_upload_booking,
    };

    const res2 = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization/update?Customer_Name=${getCustomerDocs}&ID=${id}`,
      payloadfm
    );
    console.log(res2.data);

    const res1 = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization_files/update?Customer_Name=${getCustomerDocs}&ID=${id}`,
      payloadfm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(res1.data.message);


    if (removed.upticket && forwarddocs.upload_Tickets === "") {
      try {
        const res4 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization_files_uptick/update?Customer_Name=${getCustomerDocs}&ID=${id}`);
        console.log(res4.data);
      } catch (error) {
        console.error("Error making the request:", error);
      }
    }
    if (removed.upbook && forwarddocs.upload_Bookings === "") {
      try {
        const res4 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization_files_upbook/update?Customer_Name=${getCustomerDocs}&ID=${id}`);
        console.log(res4.data);
      } catch (error) {
        console.error("Error making the request:", error);
      }
    }
    

    const res3 = await axios
      .get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization?Customer_Name=${getCustomerDocs}&ID=${id}`
      )
      .then((res) => {
        const {
          Boarding_Airport,
          Boarding_Date,
          Arrival_Airport,
          Arrival_Date,
          Upload_Tickets,
          Hotel_Name,
          Booking_Dates_checkin,
          Booking_Dates_Checkout,
          Upload_Bookings,
          return_Boarding_Airport,
          return_Boarding_Date,
          return_Arrival_Airport,
          return_Arrival_Date,
          return_Upload_Ticket,
          return_Hotel_Name,
          return_Booking_Checkin,
          return_Booking_Checkout,
          return_Upload_Booking,
          Temp_Visa_Doc,
          Temp_Visa_Expiry,
        } = res.data[0];
        setForwarddocs({
          boarding_Airport: Boarding_Airport,
          boarding_Date: Boarding_Date,
          arrival_Airport: Arrival_Airport,
          arrival_Date: Arrival_Date,
          upload_Tickets: Upload_Tickets,
          hotel_Name: Hotel_Name,
          booking_Dates_checkin: Booking_Dates_checkin,
          booking_Dates_checkout: Booking_Dates_Checkout,
          upload_Bookings: Upload_Bookings,
          return_boarding_airport: return_Boarding_Airport,
          return_boarding_date: return_Boarding_Date,
          return_arrival_airport: return_Arrival_Airport,
          return_arrival_date: return_Arrival_Date,
          return_upload_ticket: return_Upload_Ticket,
          return_hotel_name: return_Hotel_Name,
          return_booking_checkin: return_Booking_Checkin,
          return_booking_checkout: return_Booking_Checkout,
          return_upload_booking: return_Upload_Booking,
          temp_visa_doc: Temp_Visa_Doc,
          temp_visa_expiry: Temp_Visa_Expiry,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });

      setUploadticketURL(null);
    setUploadbookingURL(null);
    setremoved({...removed, upticket : false});
    setremoved({...removed, upbook : false});
   
  };
  const handleEdit6 = async (e) => {
    e.preventDefault();
    setEditMode6(false);
    const {
      boarding_Airport,
      boarding_Date,
      arrival_Airport,
      arrival_Date,
      upload_Tickets,
      hotel_Name,
      booking_Dates_checkin,
      booking_Dates_checkout,
      upload_Bookings,
      return_boarding_airport,
      return_boarding_date,
      return_arrival_airport,
      return_arrival_date,
      return_upload_ticket,
      return_hotel_name,
      return_booking_checkin,
      return_booking_checkout,
      return_upload_booking,
    } = forwarddocs;

    const payloadfm = {
      Boarding_Airport: boarding_Airport,
      Boarding_Date: boarding_Date,
      Arrival_Airport: arrival_Airport,
      Arrival_Date: arrival_Date,
      Upload_Tickets: upload_Tickets,
      Hotel_Name: hotel_Name,
      Booking_Dates_checkin: booking_Dates_checkin,
      Booking_Dates_Checkout: booking_Dates_checkout,
      Upload_Bookings: upload_Bookings,
      return_Boarding_Airport: return_boarding_airport,
      return_Boarding_Date: return_boarding_date,
      return_Arrival_Airport: return_arrival_airport,
      return_Arrival_Date: return_arrival_date,
      return_Upload_Ticket: return_upload_ticket,
      return_Hotel_Name: return_hotel_name,
      return_Booking_Checkin: return_booking_checkin,
      return_Booking_Checkout: return_booking_checkout,
      return_Upload_Booking: return_upload_booking,
    };

    const res2 = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization/update?Customer_Name=${getCustomerDocs}&ID=${id}`,
      payloadfm
    );
    console.log(res2.data);

    const res1 = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization_files/update?Customer_Name=${getCustomerDocs}&ID=${id}`,
      payloadfm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(res1.data.message);

    if (removed.reupticket&& forwarddocs.return_upload_ticket === "") {
      try {
        const res4 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization_files_retick/update?Customer_Name=${getCustomerDocs}&ID=${id}`);
        console.log(res4.data);
      } catch (error) {
        console.error("Error making the request:", error);
      }
    }

    if (removed.reupbook&& forwarddocs.return_upload_booking === "") {
      try {
        const res4 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization_files_rebook/update?Customer_Name=${getCustomerDocs}&ID=${id}`);
        console.log(res4.data);
      } catch (error) {
        console.error("Error making the request:", error);
      }
    }

    const res3 = await axios
      .get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization?Customer_Name=${getCustomerDocs}&ID=${id}`
      )
      .then((res) => {
        const {
          Boarding_Airport,
          Boarding_Date,
          Arrival_Airport,
          Arrival_Date,
          Upload_Tickets,
          Hotel_Name,
          Booking_Dates_checkin,
          Booking_Dates_Checkout,
          Upload_Bookings,
          return_Boarding_Airport,
          return_Boarding_Date,
          return_Arrival_Airport,
          return_Arrival_Date,
          return_Upload_Ticket,
          return_Hotel_Name,
          return_Booking_Checkin,
          return_Booking_Checkout,
          return_Upload_Booking,
          Temp_Visa_Doc,
          Temp_Visa_Expiry,
        } = res.data[0];
        setForwarddocs({
          boarding_Airport: Boarding_Airport,
          boarding_Date: Boarding_Date,
          arrival_Airport: Arrival_Airport,
          arrival_Date: Arrival_Date,
          upload_Tickets: Upload_Tickets,
          hotel_Name: Hotel_Name,
          booking_Dates_checkin: Booking_Dates_checkin,
          booking_Dates_checkout: Booking_Dates_Checkout,
          upload_Bookings: Upload_Bookings,
          return_boarding_airport: return_Boarding_Airport,
          return_boarding_date: return_Boarding_Date,
          return_arrival_airport: return_Arrival_Airport,
          return_arrival_date: return_Arrival_Date,
          return_upload_ticket: return_Upload_Ticket,
          return_hotel_name: return_Hotel_Name,
          return_booking_checkin: return_Booking_Checkin,
          return_booking_checkout: return_Booking_Checkout,
          return_upload_booking: return_Upload_Booking,
          temp_visa_doc: Temp_Visa_Doc,
          temp_visa_expiry: Temp_Visa_Expiry,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });

     
    setreturnUploadticketURL(null);
    setreturnsetUploadbookingURL(null);
    setremoved({...removed, reupticket : false});
    setremoved({...removed, reupbook : false});
  };

  return (
    <Box
          sx={{
            mt: "-30px",
            ml: "0px",
            mb:"10px",
            bgcolor: "#FFFFFF",
            minHeight: "550px",
            width: "100%",
            borderRight: "1px solid #E7EBFF",
            borderLeft: "1px solid #E7EBFF",
            borderBottom: "1px solid #E7EBFF",
           
          }}>
            {getCustomerDocs !== "" ? (<div>
          <div
            style={{
              padding: " 25px 10px",
              display: "flex",
              gap: "20px",
              marginTop: "20px",
            }}>
            <div style={{ mt: "30px" }}>
              <h6 style={{ fontWeight: "bold" }}>Select Team Member</h6>
            </div>
            
            {localStorage.getItem("Group") === "Supervisor" || localStorage.getItem("Group") === "Technician" || localStorage.getItem("Group") === "Trainee" ? (
              <>
                {Array.isArray(SuperArray)
              ? SuperArray.map((elm, index) =>
              elm === "" || (profdetails[0]?.Customer_Name !== elm) ? null : (
                    <div style={{ display: "flex", gap: "7px", mt: "20px" }}>
                      <div className={styles.personalradio}>
                        <input
                          key={index}
                          type="radio"
                          name="CustomerName"
                          value={elm}
                          onClick={() => setMobilisationTabs(true)}
                          checked={getCustomerDocs === elm}
                          onChange={() => {
                            if (getCustomerDocs !== elm) {
                              setGetCustomerDocs(elm); // Update state only if the value changes
                            }
                          }}
                        />
                      </div>
                      <label style={{ fontSize: "16px", fontWeight: 520 }}>
                        {elm}
                      </label>
                    </div>
                  )
                )
              : null}
            {Array.isArray(TechArray)
              ? TechArray.map((elm, index) =>
              elm === "" || (profdetails[0]?.Customer_Name !== elm) ? null : (
                    <div style={{ display: "flex", gap: "7px", mt: "20px" }}>
                      <div className={styles.personalradio}>
                        <input
                          key={index}
                          type="radio"
                          name="CustomerName"
                          value={elm}
                          onClick={() => setMobilisationTabs(true)}
                          checked={getCustomerDocs === elm}
                          onChange={() => {
                            if (getCustomerDocs !== elm) {
                              setGetCustomerDocs(elm); // Update state only if the value changes
                            }
                          }}
                        />
                      </div>
                      <label style={{ fontSize: "16px", fontWeight: 520 }}>
                        {elm}
                      </label>
                    </div>
                  )
                )
              : null}

              {Array.isArray(TrainArray)
              ? TrainArray.map((elm, index) =>
              elm === "" || (profdetails[0]?.Customer_Name !== elm) ? null : (
                    <div style={{ display: "flex", gap: "7px", mt: "20px" }}>
                      <div className={styles.personalradio}>
                        <input
                          key={index}
                          type="radio"
                          name="CustomerName"
                          value={elm}
                          onClick={() => setMobilisationTabs(true)}
                          checked={getCustomerDocs === elm}
                          onChange={() => {
                            if (getCustomerDocs !== elm) {
                              setGetCustomerDocs(elm);
                            }
                          }}
                        />
                      </div>
                      <label style={{ fontSize: "16px", fontWeight: 520 }}>
                        {elm}
                      </label>
                    </div>
                  )
                )
              : null}
              </>
            ):(
              <>
                {Array.isArray(SuperArray)
              ? SuperArray.map((elm, index) =>
                  elm === "" ? null : (
                    <div style={{ display: "flex", gap: "7px", mt: "20px" }}>
                      <div className={styles.personalradio}>
                        <input
                          key={index}
                          type="radio"
                          name="CustomerName"
                          value={elm}
                          onClick={() => setMobilisationTabs(true)}
                          checked={getCustomerDocs === elm}
                          onChange={() => {
                            if (getCustomerDocs !== elm) {
                              setGetCustomerDocs(elm); // Update state only if the value changes
                            }
                          }}
                        />
                      </div>
                      <label style={{ fontSize: "16px", fontWeight: 520 }}>
                        {elm}
                      </label>
                    </div>
                  )
                )
              : null}
            {Array.isArray(TechArray)
              ? TechArray.map((elm, index) =>
                  elm === "" ? null : (
                    <div style={{ display: "flex", gap: "7px", mt: "20px" }}>
                      <div className={styles.personalradio}>
                        <input
                          key={index}
                          type="radio"
                          name="CustomerName"
                          value={elm}
                          onClick={() => setMobilisationTabs(true)}
                          checked={getCustomerDocs === elm}
                          onChange={() => {
                            if (getCustomerDocs !== elm) {
                              setGetCustomerDocs(elm); // Update state only if the value changes
                            }
                          }}
                        />
                      </div>
                      <label style={{ fontSize: "16px", fontWeight: 520 }}>
                        {elm}
                      </label>
                    </div>
                  )
                )
              : null}

              {Array.isArray(TrainArray)
              ? TrainArray.map((elm, index) =>
                  elm === "" ? null : (
                    <div style={{ display: "flex", gap: "7px", mt: "20px" }}>
                      <div className={styles.personalradio}>
                        <input
                          key={index}
                          type="radio"
                          name="CustomerName"
                          value={elm}
                          onClick={() => setMobilisationTabs(true)}
                          checked={getCustomerDocs === elm}
                          onChange={() => {
                            if (getCustomerDocs !== elm) {
                              setGetCustomerDocs(elm);
                            }
                          }}
                        />
                      </div>
                      <label style={{ fontSize: "16px", fontWeight: 520 }}>
                        {elm}
                      </label>
                    </div>
                  )
                )
              : null}
              </>
            )}
          </div>

          {mobilisationTabs && (
            <>
              <div style={{ display: "flex", paddingTop: "10px" }}>
                <div
                  className={
                    personalMobilisation === 1
                      ? styles.activePersonalMobilisationTab
                      : styles.personalMobilisationTab
                  }
                  onClick={() => personalMobilsationTab(1)}>
                  Documents
                </div>
                <div
                  className={
                    personalMobilisation === 2
                      ? styles.activePersonalMobilisationTab
                      : styles.personalMobilisationTab
                  }
                  onClick={() => personalMobilsationTab(2)}>
                  Forward Mobilization
                </div>
                <div
                  className={
                    personalMobilisation === 3
                      ? styles.activePersonalMobilisationTab
                      : styles.personalMobilisationTab
                  }
                  onClick={() => personalMobilsationTab(3)}>
                  Return Mobilisation
                </div>
              </div>

              <div
                className={
                  personalMobilisation === 1
                    ? styles.activeTabPersonalMobilisation
                    : styles.TabPersonalMobilisation
                }
                style={{ marginTop: "40px", paddingLeft: "20px",  paddingBottom:"30px"}}>
                <div style={{ opacity: "0.6" }}>
                  Documents [{" "}
                  <a
                    href="#"
                    onClick={() => {
                      handlezipdoc(
                        docs[0]?.Passport_Doc,
                        docs[0]?.Insurance_Doc,
                        docs[0]?.Bosiet_Doc,
                        docs[0]?.Seaman_Doc
                      );
                    }}>
                    Download All
                  </a>{" "}
                  ]
                </div>
                <div
                  style={{ marginTop: "30px", display: "flex", gap: "130px",}}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
                          fill="#292D32"
                        />
                        <path
                          d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "2px",
                        width: "180px",
                      }}>
                      Passport
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "30px" }}>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "3px",
                        width: "150px",
                      }}>
                      Expiry:{" "}
                      <span style={{ fontWeight: "700" }}>
                      {docs[0]?.Passport_Expiry ? docs[0]?.Passport_Expiry : "NA"}
                      </span>
                    </div>
                    {docs[0]?.Passport_Doc && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}>
                      <a href={docs[0]?.Passport_Doc} rel="noreferrer" target="_blank">
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                          fill="black"
                        />
                        <path
                          d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                          fill="black"
                        />
                      </svg>
                      </a>
                    </div>)}

                    {docs[0]?.Passport_Doc && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }} 
                      onClick={()=> handleforwarddoc(docs[0]?.Passport_Doc)}
                      >
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>)}
                  </div>
                </div>

                <div
                  style={{ marginTop: "30px", display: "flex", gap: "130px" }}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
                          fill="#292D32"
                        />
                        <path
                          d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "2px",
                        width: "180px",
                      }}>
                      Insurance
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "30px" }}>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "3px",
                        width: "150px",
                      }}>
                      Expiry:{" "}
                      <span style={{ fontWeight: "700" }}>
                      {docs[0]?.Insurance_Expiry ? docs[0]?.Insurance_Expiry : "NA"}
                      </span>
                    </div>
                    {docs[0]?.Insurance_Doc && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}>
                      <a href={docs[0]?.Insurance_Doc} rel="noreferrer" target="_blank">
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                          fill="black"
                        />
                        <path
                          d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                          fill="black"
                        />
                      </svg>
                      </a>
                    </div>)}

                    {docs[0]?.Insurance_Doc && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }} 
                      onClick={()=> handleforwarddoc(docs[0]?.Insurance_Doc)}>
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>)}
                  </div>
                </div>

                <div
                  style={{ marginTop: "30px", display: "flex", gap: "130px" }}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
                          fill="#292D32"
                        />
                        <path
                          d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "2px",
                        width: "180px",
                      }}>
                      BOSIET
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "30px" }}>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "3px",
                        width: "150px",
                      }}>
                      Expiry:{" "}
                      <span style={{ fontWeight: "700" }}>
                      {docs[0]?.Bosiet_Expiry ? docs[0]?.Bosiet_Expiry : "NA"}
                      </span>
                    </div>
                    {docs[0]?.Bosiet_Doc && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}>
                      <a href={docs[0]?.Bosiet_Doc} rel="noreferrer" target="_blank">
                      <svg
                        style={{ marginTop: "3px", marginLeft: "3px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                          fill="black"
                        />
                        <path
                          d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                          fill="black"
                        />
                      </svg>
                      </a>
                    </div>)}

                    {docs[0]?.Bosiet_Doc && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }} onClick={() =>handleforwarddoc(docs[0]?.Bosiet_Doc)}>
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>)}
                  </div>
                </div>

                <div
                  style={{ marginTop: "30px", display: "flex", gap: "130px",}}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
                          fill="#292D32"
                        />
                        <path
                          d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "2px",
                        width: "180px",
                      }}>
                      Visa
                    </div>
                  </div>

                  {/* {visaname !== "" ? (
                    <div style={{ display: "flex", gap: "30px" }}>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "3px",
                        width: "150px",
                      }}>
                      Expiry:{" "}
                      <span style={{ fontWeight: "700" }}>
                        {visaexpiry}
                      </span>
                    </div>
                    <div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}>
                      <a href={visadoc} rel="noreferrer" target="_blank">
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                          fill="black"
                        />
                        <path
                          d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                          fill="black"
                        />
                      </svg>
                      </a>
                    </div>

                    <div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }} 
                      onClick={()=> handleforwarddoc(visadoc)}
                      >
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                  </div>
                  ):(
                    editMode ? (

                    ):(
                      null
                    )
                  )} */}
                  {editMode ? (
                    uploadvisaname !=="" ? (
                      <div style={{display:"flex"}}>
                          <div
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "200px",
                              borderRadius: "8px",
                              height: "35px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div
                                style={{
                                  maxWidth: "150px", // Adjusted width to accommodate the SVG
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                            {uploadvisaname}
                            </div>
                          </div>
                          <svg
                          width="20"
                          onClick={removevisafile}
                          style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg>
                        
                        <input
                            style={{
                              border: "1px solid #E7EBFF",
                              marginLeft:"20px",
                              // position:"absolute",
                              width: "120px",
                              borderRadius: "8px",
                              height: "35px",
                              cursor:"pointer"
                            }}
                            type="date"
                            name="temp_visa_expiry"
                            value={forwarddocs.temp_visa_expiry}
                            onChange={handleforward}
                          />
                        
                        </div>
                    ): forwarddocs.temp_visa_doc ? (
                      <div style={{ display: "flex" }}>
                            <div
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "170px", 
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {visafilename}
                              </div>
                            </div>
                          
                            <svg
                              width="20"
                              style={{ marginTop: "7px", cursor: "pointer" }}
                              onClick={removevisafile}
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                                fill="black"
                              />
                              <path
                                d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                                fill="black"
                              />
                            </svg>
                            <input
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "120px",
                              borderRadius: "8px",
                              height: "35px",
                              cursor:"pointer",
                              marginLeft:"20px"
                            }}
                            type="date"
                            name="temp_visa_expiry"
                            value={forwarddocs.temp_visa_expiry}
                            onChange={handleforward}
                          />
                          </div>
                    ): (
                         <>
                    <input
                      style={{
                        display: "none",
                      }}
                      type="file"
                      name="upload_Tickets"
                      accept=".pdf"
                      id="uploadvisa"
                      onChange={handlevisafile}
                    />
                    <div style={{display:"flex"}}>
                    <div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "200px",
                        borderRadius: "8px",
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        document.getElementById("uploadvisa").click();
                      }}
                    >
                      {"Upload Visa"}
                    </div>
                    <svg
                      onClick={() => {
                        document.getElementById("uploadvisa").click();
                      }}
                      style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                        fill="black"
                      />
                      <path
                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                        fill="black"
                      />
                    </svg>
                    <input
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "120px",
                              borderRadius: "8px",
                              height: "35px",
                              cursor:"pointer",
                              marginLeft:"20px"
                            }}
                            type="date"
                            name="temp_visa_expiry"
                            value={forwarddocs.temp_visa_expiry}
                            onChange={handleforward}
                          />
                    </div>
                  </>
                    )
                 
                  ) : (
                    // visaname !== "" ? (
                      <div style={{ display: "flex", gap: "30px" }}>
                      <div
                        style={{
                          fontFamily: "OpenSans",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          marginTop: "3px",
                          width: "150px",
                        }}>
                        Expiry:{" "}
                        <span style={{ fontWeight: "700" }}>
                        {visaexpiry ? visaexpiry : "NA"}
                        </span>
                      </div>
                      {visadoc && (<div
                        style={{
                          border: "1px solid #E7EBFF",
                          width: "30px",
                          height: "30px",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}>
                        <a href={visadoc} rel="noreferrer" target="_blank">
                        <svg
                          style={{ marginTop: "4px", marginLeft: "5px" }}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                            fill="black"
                          />
                          <path
                            d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                            fill="black"
                          />
                        </svg>
                        </a>
                      </div>)}
  
                      {visadoc && (<div
                        style={{
                          border: "1px solid #E7EBFF",
                          width: "30px",
                          height: "30px",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }} 
                        onClick={()=> handleforwarddoc(visadoc)}
                        >
                        <svg
                          style={{ marginTop: "4px", marginLeft: "5px" }}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                            fill="#292D32"
                          />
                        </svg>
                      </div>)}
                    </div>
                    // ):(
                    //   null
                    // )
                  )}
                </div>
                <div
                  style={{ marginTop: "30px", display: "flex", gap: "130px" }}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
                          fill="#292D32"
                        />
                        <path
                          d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "2px",
                        width: "180px",
                      }}>
                      Seaman Book
                    </div>

                  </div>

                  <div style={{ display: "flex", gap: "30px" }}>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "3px",
                        width: "150px",
                      }}>
                      Expiry:{" "}
                      <span style={{ fontWeight: "700" }}>
                      {docs[0]?.Seaman_Book_Expiry ? docs[0]?.Seaman_Book_Expiry : "NA"}
                      </span>
                    </div>
                    {docs[0]?.Seaman_Doc && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}>
                      <a href={docs[0]?.Seaman_Doc} rel="noreferrer" target="_blank">
                      <svg
                        style={{ marginTop: "3px", marginLeft: "3px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                          fill="black"
                        />
                        <path
                          d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                          fill="black"
                        />
                      </svg>
                      </a>
                    </div>)}

                    {docs[0]?.Seaman_Doc && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }} onClick={() =>handleforwarddoc(docs[0]?.Seaman_Doc)}>
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>)}
                  </div>
                </div>

                <div
                  style={{ marginTop: "30px", display: "flex", gap: "130px" }}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
                          fill="#292D32"
                        />
                        <path
                          d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "2px",
                        width: "180px",
                      }}>
                      Vaccination Certificate
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "30px" }}>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "3px",
                        width: "150px",
                      }}>
                      Expiry:{" "}
                      <span style={{ fontWeight: "700" }}>
                        NA
                      </span>
                    </div>
                    {docs[0]?.Vaccination_Certificate && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}>
                      <a href={docs[0]?.Vaccination_Certificate} rel="noreferrer" target="_blank">
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                          fill="black"
                        />
                        <path
                          d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                          fill="black"
                        />
                      </svg>
                      </a>
                    </div>)}

                    {docs[0]?.Vaccination_Certificate && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }} 
                      onClick={()=> handleforwarddoc(docs[0]?.Vaccination_Certificate)}>
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>)}
                  </div>
                </div>

                <div
                  style={{ marginTop: "30px", display: "flex", gap: "130px" }}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
                          fill="#292D32"
                        />
                        <path
                          d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "2px",
                        width: "180px",
                      }}>
                      PCC
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "30px" }}>
                    <div
                      style={{
                        fontFamily: "OpenSans",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: "3px",
                        width: "150px",
                      }}>
                      Expiry:{" "}
                      <span style={{ fontWeight: "700" }}>
                        NA
                      </span>
                    </div>
                    {docs[0]?.PCC && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}>
                      <a href={docs[0]?.PCC} rel="noreferrer" target="_blank">
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                          fill="black"
                        />
                        <path
                          d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                          fill="black"
                        />
                      </svg>
                      </a>
                    </div>)}

                    {docs[0]?.PCC && (<div
                      style={{
                        border: "1px solid #E7EBFF",
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }} 
                      onClick={()=> handleforwarddoc(docs[0]?.PCC)}>
                      <svg
                        style={{ marginTop: "4px", marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>)}
                  </div>
                </div>
                <div style={{display:"flex", justifyContent:"flex-end"}}>
                {editMode ? (
                    <>
                      <button
                        onClick={handleCancel}
                        style={{
                          background: "white",
                          color: "#5B6BE1",
                          width: "181px",
                          height: "50px",
                          border: "1px solid #5B6BE1",
                          borderRadius: "8px",
                          padding: "15px 59px 15px 58px",
                          fontWeight: "bold",
                        }}>
                        Cancel
                      </button>
                      <button
                        onClick={handleEdit}
                        style={{
                          background: "#5B6BE1",
                          width: "181px",
                          height: "50px",
                          border: "1px solid #5B6BE1",
                          borderRadius: "8px",
                          padding: "15px 59px 15px 58px",
                          fontWeight: "bold",
                        }}>
                        Save
                      </button>
                    </>
                  ) : (
                    featureedit.length > 0 && (<button
                      style={{
                        background: "#5B6BE1",
                        width: "181px",
                        height: "50px",
                        border: "1px solid #5B6BE1",
                        borderRadius: "8px",
                        padding: "15px 59px 15px 58px",
                        fontWeight: "bold",
                      }}
                      onClick={() => setEditMode(true)}>
                      Edit{" "}
                      <svg
                        style={{ marginLeft: "2px" }}
                        width="16"
                        height="16"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.75 16.5H2.25C1.9425 16.5 1.6875 16.245 1.6875 15.9375C1.6875 15.63 1.9425 15.375 2.25 15.375H15.75C16.0575 15.375 16.3125 15.63 16.3125 15.9375C16.3125 16.245 16.0575 16.5 15.75 16.5Z"
                          fill="white"
                        />
                        <path
                          d="M14.2649 2.61C12.8099 1.155 11.3849 1.1175 9.89243 2.61L8.98493 3.5175C8.90993 3.5925 8.87993 3.7125 8.90993 3.8175C9.47993 5.805 11.0699 7.395 13.0574 7.965C13.0874 7.9725 13.1174 7.98 13.1474 7.98C13.2299 7.98 13.3049 7.95 13.3649 7.89L14.2649 6.9825C15.0074 6.2475 15.3674 5.535 15.3674 4.815C15.3749 4.0725 15.0149 3.3525 14.2649 2.61Z"
                          fill="white"
                        />
                        <path
                          d="M11.7082 8.6475C11.4907 8.5425 11.2807 8.4375 11.0782 8.3175C10.9132 8.22 10.7557 8.115 10.5982 8.0025C10.4707 7.92 10.3207 7.8 10.1782 7.68C10.1632 7.6725 10.1107 7.6275 10.0507 7.5675C9.80323 7.3575 9.52573 7.0875 9.27823 6.7875C9.25573 6.7725 9.21823 6.72 9.16573 6.6525C9.09073 6.5625 8.96323 6.4125 8.85073 6.24C8.76073 6.1275 8.65573 5.9625 8.55823 5.7975C8.43823 5.595 8.33323 5.3925 8.22823 5.1825C8.09056 4.88749 7.70337 4.79986 7.47317 5.03005L3.25573 9.2475C3.15823 9.345 3.06823 9.5325 3.04573 9.66L2.64073 12.5325C2.56573 13.0425 2.70823 13.5225 3.02323 13.845C3.29323 14.1075 3.66823 14.25 4.07323 14.25C4.16323 14.25 4.25323 14.2425 4.34323 14.2275L7.22323 13.8225C7.35823 13.8 7.54573 13.71 7.63573 13.6125L11.8601 9.38809C12.0857 9.1625 12.001 8.77435 11.7082 8.6475Z"
                          fill="white"
                        />
                      </svg>
                    </button>)
                  )}
                </div>
              </div>

              <div
                className={
                  personalMobilisation === 2
                    ? styles.activeTabPersonalMobilisation
                    : styles.TabPersonalMobilisation
                }>
                <div
                  style={{
                    display: "flex",
                    gap: "100px",
                    marginTop: "40px",
                    marginLeft: "20px",
                  }}>
                  <div>
                    <h6 style={{ fontWeight: "bold" }}>Flight Bookings</h6>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px", marginTop: "2px" }}>
                        Boarding Airport
                      </div>
                      {editMode5 ? (
                        <>
                          <div>
                            <input
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "30px",
                              }}
                              name="boarding_Airport"
                              placeholder="Select the city"
                              type="text"
                              value={
                                forwarddocs.boarding_Airport
                                  ? forwarddocs.boarding_Airport
                                  : " "
                              }
                              onChange={handleforward}
                            />
                          </div>
                          <input
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "120px",
                              borderRadius: "8px",
                              height: "30px",
                              cursor:"pointer"
                            }}
                            type="date"
                            name="boarding_Date"
                            value={forwarddocs.boarding_Date}
                            onChange={handleforward}
                          />
                        </>
                      ) : (
                        <>
                          <div style={{}}>
                            <input
                              style={{
                                border: "none",
                                width: "200px",
                                height: "30px",
                                background: "#F0F0F0",
                                borderRadius: "8px",
                              }}
                              type="text"
                              value={
                                forwarddocs.boarding_Airport
                                  ? forwarddocs.boarding_Airport
                                  : " "
                              }
                              disabled
                            />
                          </div>
                          <input
                            style={{
                              border: "none",
                              width: "120px",

                              height: "30px",
                              background: "#F0F0F0",
                              borderRadius: "8px",
                              textAlign: "center",
                            }}
                            type="text"
                            value={
                              forwarddocs.boarding_Date
                                ? forwarddocs.boarding_Date
                                : " "
                            }
                            disabled
                          />
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px", marginTop: "2px" }}>
                        Arrival Airport
                      </div>
                      {editMode5 ? (
                        <>
                          {/* <div>
                            <select
                              style={{
                                width: "200px",
                                height: "30px",
                                border: "1px solid #E7EBFF",
                                borderRadius: "8px",
                                height: "30px",
                                cursor:"pointer"
                              }}
                              placeholder="Select the city"
                              name="arrival_Airport"
                              value={forwarddocs.arrival_Airport}
                              onChange={handleforward}>
                              <option>select</option>
                              <option>{projectList?.Rig_Location}</option>
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              style = {{
                                marginLeft: "-25px",
                                // cursor:"pointer"
                              }}
                            
                            >
                              <path
                                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                fill="#394759"
                              />
                            </svg>
                          </div> */}
                           <div>
                            <input
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "30px",
                              }}
                              name="arrival_Airport"
                              placeholder="Select the city"
                              type="text"
                              value={
                                forwarddocs.arrival_Airport
                                  ? forwarddocs.arrival_Airport
                                  : " "
                              }  
                              onChange={handleforward}
                            />
                          </div>
                          <input
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "120px",
                              borderRadius: "8px",
                              height: "30px",
                              cursor:"pointer"
                            }}
                            type="date"
                            name="arrival_Date"
                            value={forwarddocs.arrival_Date}
                            onChange={handleforward}
                          />
                        </>
                      ) : (
                        <>
                          <div style={{}}>
                            <input
                              style={{
                                border: "none",
                                width: "200px",
                                height: "30px",
                                background: "#F0F0F0",
                                borderRadius: "8px",
                              }}
                              type="text"
                              value={
                                forwarddocs.arrival_Airport === "select"
                                  ? " "
                                  : forwarddocs.arrival_Airport || " "
                              }
                              disabled
                            />
                          </div>
                          <input
                            style={{
                              border: "none",
                              width: "120px",
                              height: "30px",
                              background: "#F0F0F0",
                              borderRadius: "8px",
                              textAlign: "center",
                            }}
                            type="text"
                            value={
                              forwarddocs.arrival_Date
                                ? forwarddocs.arrival_Date
                                : " "
                            }
                            disabled
                          />
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px", marginTop: "2px" }}>
                        Upload Ticket
                      </div>
                      <div>
                      {editMode5 ? (
                        uploadticketname !== "" ? (
                        <div style={{display:"flex"}}>
                          <div
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "200px",
                              borderRadius: "8px",
                              height: "35px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div
                                style={{
                                  maxWidth: "150px", // Adjusted width to accommodate the SVG
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                            {uploadticketname}
                            </div>
                          </div>
                          <svg
                          width="20"
                          onClick={() => removefile("uploadtick")}
                          style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg></div>
                        ) : forwarddocs.upload_Tickets? (
                            <div style={{ display: "flex" }}>
                            <div
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "170px", // Adjusted width to accommodate the SVG
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {uploadticketfilename}
                              </div>
                            </div>
                          
                            <svg
                              width="20"
                              onClick={() => removefile("uploadtick")}
                              style={{ marginTop: "7px", cursor: "pointer" }}
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                                fill="black"
                              />
                              <path
                                d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                                fill="black"
                              />
                            </svg>
                          </div>
                          
                        ) : (
                          <>
                            <input
                              style={{
                                display: "none",
                              }}
                              type="file"
                              name="upload_Tickets"
                              accept=".pdf"
                              id="uploadticket"
                              onChange={handleforwardfile}
                            />
                            <div style={{display:"flex"}}>
                            <div
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                document.getElementById("uploadticket").click();
                              }}
                            >
                              {forwarddocs.upload_Tickets ? uploadticketfilename : "No File is Selected"}
                            </div>
                            <svg
                              onClick={() => {
                                document.getElementById("uploadticket").click();
                              }}
                              style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                fill="black"
                              />
                              <path
                                d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                fill="black"
                              />
                            </svg>
                            </div>
                          </>
                        )
                      ) : (
                        <input
                          style={{
                            border: "none",
                            height: "30px",
                            background: "#F0F0F0",
                            borderRadius: "8px",
                            width: "200px",
                          }}
                          type="text"
                          value={forwarddocs.upload_Tickets ? uploadticketfilename : " "}
                          disabled
                        />
                      )}


                      </div>
                      {editMode5?(<>
                  <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                  >
                  <a href={uploadticketURL ===null ? forwarddocs.upload_Tickets:uploadticketURL} rel="noreferrer" target="_blank">
                  <svg style={{marginTop:"3px", marginLeft:"3px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z" fill="black"/>
                    <path d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z" fill="black"/>
                  </svg>
                  </a>

                  

                </div>

                <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                   onClick={() =>handleforwarddoc(forwarddocs.upload_Tickets)}>
                  <svg style={{marginTop:"5px", marginLeft:"5px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32"/>
                  </svg>
                  

                </div>
                </>
                  ):(
                  <>
                    <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                  >
                  <a href={forwarddocs.upload_Tickets} rel="noreferrer" target="_blank">
                  <svg style={{marginTop:"3px", marginLeft:"3px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z" fill="black"/>
                    <path d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z" fill="black"/>
                  </svg>
                  </a>

                  

                </div>

                <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                   onClick={() =>handleforwarddoc(forwarddocs.upload_Tickets)}>
                  <svg style={{marginTop:"5px", marginLeft:"5px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32"/>
                  </svg>
                  

                </div>
                  </>)}
                    </div>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "bold" }}>Hotel Bookings</h6>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px" }}>Hotel Name</div>
                      <div>
                        {editMode5 ? (
                          <input
                            style={{
                              border: "1px solid #E7EBFF",
                              borderRadius: "8px",
                              height: "30px",
                              width: "280px",
                            }}
                            type="text"
                            name="hotel_Name"
                            value={forwarddocs.hotel_Name}
                            onChange={handleforward}
                          />
                        ) : (
                          <input
                            type="text"
                            style={{
                              border: "none",
                              height: "30px",
                              background: "#F0F0F0",
                              borderRadius: "8px",
                              width: "280px",
                            }}
                            value={
                              forwarddocs.hotel_Name
                                ? forwarddocs.hotel_Name
                                : " "
                            }
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px" }}>Booking Dates</div>
                      {editMode5 ? (
                        <>
                          <div>
                            <input
                              type="date"
                              style={{
                                width: "130px",
                                border: "1px solid #E7EBFF",
                                borderRadius: "8px",
                                height: "30px",
                                cursor:"pointer"
                              }}
                              name="booking_Dates_checkin"
                              value={forwarddocs.booking_Dates_checkin}
                              onChange={handleforward}
                            />
                          </div>

                          <div>
                            <input
                              type="date"
                              style={{
                                width: "130px",
                                border: "1px solid #E7EBFF",
                                borderRadius: "8px",
                                height: "30px",
                                cursor:"pointer"
                              }}
                              name="booking_Dates_checkout"
                              value={forwarddocs.booking_Dates_checkout}
                              onChange={handleforward}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <input
                              type="text"
                              value={
                                forwarddocs.booking_Dates_checkin
                                  ? forwarddocs.booking_Dates_checkin
                                  : " "
                              }
                              disabled
                              style={{
                                border: "none",
                                height: "30px",
                                background: "#F0F0F0",
                                borderRadius: "8px",
                                width: "130px",
                                textAlign: "center",
                              }}
                            />
                          </div>

                          <div>
                            <input
                              type="text"
                              value={
                                forwarddocs.booking_Dates_checkout
                                  ? forwarddocs.booking_Dates_checkout
                                  : " "
                              }
                              disabled
                              style={{
                                width: "100px",
                                border: "none",
                                height: "30px",
                                background: "#F0F0F0",
                                borderRadius: "8px",
                                width: "130px",
                                textAlign: "center",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px", marginTop: "2px" }}>
                        Upload Booking
                      </div>
                      <div>
                        {editMode5 ? (
                          uploadbookingname !== ""? (
                            <div style={{display:"flex"}}>
                          <div
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "200px",
                              borderRadius: "8px",
                              height: "35px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div
                                style={{
                                  maxWidth: "150px", // Adjusted width to accommodate the SVG
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                            {uploadbookingname}
                            </div>
                          </div>
                          <svg
                          width="20"
                          onClick={() => removefile("uploadbook")}
                          style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg></div>
                          ) : forwarddocs.upload_Bookings ? (
                            <div style={{display:"flex"}}>
                          <div
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "200px",
                              borderRadius: "8px",
                              height: "35px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div
                                style={{
                                  maxWidth: "170px", // Adjusted width to accommodate the SVG
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                            {uploadbookingfilename}
                            </div>
                          </div>
                          <svg
                          width="20"
                          onClick={() => removefile("uploadbook")}
                          style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg></div>
                          ):(
                            <>
                            <input
                              style={{
                                display: "none",
                              }}
                              type="file"
                              name="upload_Bookings"
                              accept=".pdf"
                              id="uploadbook"
                              onChange={handleforwardfile}
                            />
                            <div style={{display:"flex"}}>
                            <div
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                document.getElementById("uploadbook").click();
                              }}
                            >
                              {forwarddocs.upload_Bookings? uploadbookingfilename : "No File is Selected"}
                            </div>
                            <svg
                              onClick={() => {
                                document.getElementById("uploadbook").click();
                              }}
                              style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                fill="black"
                              />
                              <path
                                d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                fill="black"
                              />
                            </svg>
                            </div>
                          </>
                          )
                        ) : (
                          <input
                            style={{
                              border: "none",
                              width: "125px",
                              height: "30px",
                              background: "#F0F0F0",
                              borderRadius: "8px",
                              width: "200px",
                            }}
                            type="text"
                            value={
                              forwarddocs.upload_Bookings
                                ? uploadbookingfilename
                                : " "
                            }
                            disabled
                          />
                        )}
                      </div>
                      {editMode5?(
                  <>
                    <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}>
                  <a href={uploadbookingURL === null ? forwarddocs.upload_Bookings : uploadbookingURL} rel="noreferrer" target="_blank">
                    <svg style={{marginTop:"3px", marginLeft:"3px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z" fill="black"/>
                      <path d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z" fill="black"/>
                    </svg>
                  </a>

                </div>

                <div 
                  style={{border:"1px solid #E7EBFF", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                  onClick={() => {
                    handleforwarddoc(forwarddocs.upload_Bookings);
                  }}>
                
                  <svg style={{marginTop:"4px", marginLeft:"5px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32"/>
                  </svg>
                  

                </div>
                  </>
                ):(
                  <>
                    <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}>
                  <a href={forwarddocs.upload_Bookings} rel="noreferrer" target="_blank">
                    <svg style={{marginTop:"3px", marginLeft:"3px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z" fill="black"/>
                      <path d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z" fill="black"/>
                    </svg>
                  </a>

                </div>

                <div 
                  style={{border:"1px solid #E7EBFF", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                  onClick={() => {
                    handleforwarddoc(forwarddocs.upload_Bookings);
                  }}>
                
                  <svg style={{marginTop:"4px", marginLeft:"5px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32"/>
                  </svg>
                  

                </div>
                  </>
                )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "20px",
                    marginTop: "60px",
                    marginBottom: "20px",
                  }}>
                  {editMode5 ? (
                    <>
                      <button
                        onClick={handleCancel7}
                        style={{
                          background: "white",
                          color: "#5B6BE1",
                          width: "181px",
                          height: "50px",
                          border: "1px solid #5B6BE1",
                          borderRadius: "8px",
                          padding: "15px 59px 15px 58px",
                          fontWeight: "bold",
                        }}>
                        Cancel
                      </button>
                      <button
                        onClick={handleEdit5}
                        style={{
                          background: "#5B6BE1",
                          width: "181px",
                          height: "50px",
                          border: "1px solid #5B6BE1",
                          borderRadius: "8px",
                          padding: "15px 59px 15px 58px",
                          fontWeight: "bold",
                        }}>
                        Save
                      </button>
                    </>
                  ) : (
                    featureedit5.length > 0 && (<button
                      style={{
                        background: "#5B6BE1",
                        width: "181px",
                        height: "50px",
                        border: "1px solid #5B6BE1",
                        borderRadius: "8px",
                        padding: "15px 59px 15px 58px",
                        fontWeight: "bold",
                      }}
                      onClick={() => setEditMode5(true)}>
                      Edit{" "}
                      <svg
                        style={{ marginLeft: "2px" }}
                        width="16"
                        height="16"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.75 16.5H2.25C1.9425 16.5 1.6875 16.245 1.6875 15.9375C1.6875 15.63 1.9425 15.375 2.25 15.375H15.75C16.0575 15.375 16.3125 15.63 16.3125 15.9375C16.3125 16.245 16.0575 16.5 15.75 16.5Z"
                          fill="white"
                        />
                        <path
                          d="M14.2649 2.61C12.8099 1.155 11.3849 1.1175 9.89243 2.61L8.98493 3.5175C8.90993 3.5925 8.87993 3.7125 8.90993 3.8175C9.47993 5.805 11.0699 7.395 13.0574 7.965C13.0874 7.9725 13.1174 7.98 13.1474 7.98C13.2299 7.98 13.3049 7.95 13.3649 7.89L14.2649 6.9825C15.0074 6.2475 15.3674 5.535 15.3674 4.815C15.3749 4.0725 15.0149 3.3525 14.2649 2.61Z"
                          fill="white"
                        />
                        <path
                          d="M11.7082 8.6475C11.4907 8.5425 11.2807 8.4375 11.0782 8.3175C10.9132 8.22 10.7557 8.115 10.5982 8.0025C10.4707 7.92 10.3207 7.8 10.1782 7.68C10.1632 7.6725 10.1107 7.6275 10.0507 7.5675C9.80323 7.3575 9.52573 7.0875 9.27823 6.7875C9.25573 6.7725 9.21823 6.72 9.16573 6.6525C9.09073 6.5625 8.96323 6.4125 8.85073 6.24C8.76073 6.1275 8.65573 5.9625 8.55823 5.7975C8.43823 5.595 8.33323 5.3925 8.22823 5.1825C8.09056 4.88749 7.70337 4.79986 7.47317 5.03005L3.25573 9.2475C3.15823 9.345 3.06823 9.5325 3.04573 9.66L2.64073 12.5325C2.56573 13.0425 2.70823 13.5225 3.02323 13.845C3.29323 14.1075 3.66823 14.25 4.07323 14.25C4.16323 14.25 4.25323 14.2425 4.34323 14.2275L7.22323 13.8225C7.35823 13.8 7.54573 13.71 7.63573 13.6125L11.8601 9.38809C12.0857 9.1625 12.001 8.77435 11.7082 8.6475Z"
                          fill="white"
                        />
                      </svg>
                    </button>)
                  )}
                </div>
              </div>
              <div
                className={
                  personalMobilisation === 3
                    ? styles.activeTabPersonalMobilisation
                    : styles.TabPersonalMobilisation
                }>
                <div
                  style={{
                    display: "flex",
                    gap: "100px",
                    marginTop: "40px",
                    marginLeft: "20px",
                  }}>
                  <div>
                    <h6 style={{ fontWeight: "bold" }}>Flight Bookings</h6>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px", marginTop: "2px" }}>
                        Boarding Airport
                      </div>
                      {editMode6 ? (
                        <>
                          {/* <div>
                            <select
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "30px",
                                cursor:"pointer"
                              }}
                              placeholder="Select the city"
                              name="return_boarding_airport"
                              value={forwarddocs.return_boarding_airport}
                              onChange={handleforward}>
                              <option>select</option>
                              <option>{projectList?.Rig_Location}</option>
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              style = {{
                                marginLeft: "-25px",
                                // cursor:"pointer"
                              }}
                            
                            >
                              <path
                                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                fill="#394759"
                              />
                            </svg>
                          </div> */}
                            <div>
                            <input
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "30px",
                              }}
                              type="text"
                              // placeholder="Select the City"
                              name="return_boarding_airport"
                              value={forwarddocs.return_boarding_airport}
                              onChange={handleforward}
                            />
                          </div>
                          <input
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "120px",
                              borderRadius: "8px",
                              height: "30px",
                              cursor:"pointer"
                            }}
                            type="date"
                            name="return_boarding_date"
                            value={forwarddocs.return_boarding_date}
                            onChange={handleforward}
                          />
                        </>
                      ) : (
                        <>
                          <div style={{}}>
                            <input
                              style={{
                                border: "none",
                                width: "200px",
                                height: "30px",
                                background: "#F0F0F0",
                                borderRadius: "8px",
                              }}
                              type="text"
                              value={
                                forwarddocs.return_boarding_airport === "select"
                                  ? " "
                                  : forwarddocs.return_boarding_airport || " "
                              }
                              disabled
                            />
                          </div>
                          <input
                            style={{
                              border: "none",
                              width: "120px",
                              height: "30px",
                              background: "#F0F0F0",
                              borderRadius: "8px",
                              textAlign: "center",
                            }}
                            type="text"
                            value={
                              forwarddocs.return_boarding_date
                                ? forwarddocs.return_boarding_date
                                : " "
                            }
                            disabled
                          />
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px", marginTop: "2px" }}>
                        Arrival Airport
                      </div>
                      {editMode6 ? (
                        <>
                          <div>
                            <input
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "30px",
                              }}
                              type="text"
                              // placeholder="Select the City"
                              name="return_arrival_airport"
                              value={forwarddocs.return_arrival_airport}
                              onChange={handleforward}
                            />
                          </div>
                          <input
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "120px",
                              borderRadius: "8px",
                              height: "30px",
                              cursor:"pointer"
                            }}
                            type="date"
                            name="return_arrival_date"
                            value={forwarddocs.return_arrival_date}
                            onChange={handleforward}
                          />
                        </>
                      ) : (
                        <>
                          <div style={{}}>
                            <input
                              style={{
                                border: "none",
                                width: "200px",
                                height: "30px",
                                background: "#F0F0F0",
                                borderRadius: "8px",
                              }}
                              type="text"
                              value={
                                forwarddocs.return_arrival_airport
                                  ? forwarddocs.return_arrival_airport
                                  : " "
                              }
                              disabled
                            />
                          </div>
                          <input
                            style={{
                              border: "none",
                              width: "120px",
                              height: "30px",
                              background: "#F0F0F0",
                              borderRadius: "8px",
                            }}
                            type="text"
                            value={
                              forwarddocs.return_arrival_date
                                ? forwarddocs.return_arrival_date
                                : " "
                            }
                            disabled
                          />
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px", marginTop: "2px" }}>
                        Upload Ticket
                      </div>
                      <div>
                        {editMode6 ? (
                          returnuploadticketname !== "" ? (
                            <div style={{display:"flex"}}>
                          <div
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "200px",
                              borderRadius: "8px",
                              height: "35px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div
                                style={{
                                  maxWidth: "170px", // Adjusted width to accommodate the SVG
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                            {returnuploadticketname}
                            </div>
                          </div>
                          <svg
                          width="20"
                          onClick={() => removefile("reuploadtick")}
                          style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg></div>
                          ) : forwarddocs.return_upload_ticket ? (
                            <div style={{display:"flex"}}>
                          <div
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "200px",
                              borderRadius: "8px",
                              height: "35px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div
                                style={{
                                  maxWidth: "170px", // Adjusted width to accommodate the SVG
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                            {returnuploadticketfilename}
                            </div>
                          </div>
                          <svg
                          width="20"
                          onClick={() => removefile("reuploadtick")}
                          style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg></div>
                          ) : (
                            <>
                            <input
                              style={{
                                display: "none",
                              }}
                              type="file"
                              name="return_upload_ticket"
                              accept=".pdf"
                              id="reuploadticket"
                              onChange={handleforwardfile}
                            />
                            <div style={{display:"flex"}}>
                            <div
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                document.getElementById("reuploadticket").click();
                              }}
                            >
                              {forwarddocs.return_upload_ticket ? returnuploadticketfilename : "No File is Selected"}
                            </div>
                            <svg
                              onClick={() => {
                                document.getElementById("reuploadticket").click();
                              }}
                              style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                fill="black"
                              />
                              <path
                                d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                fill="black"
                              />
                            </svg>
                            </div>
                          </>
                          )
                        ) : (
                          <input
                            style={{
                              border: "none",
                              height: "30px",
                              background: "#F0F0F0",
                              borderRadius: "8px",
                              width: "200px",
                            }}
                            type="text"
                            value={
                              forwarddocs.return_upload_ticket
                                ? returnuploadticketfilename
                                : " "
                            }
                            disabled
                          />
                        )}
                      </div>
                      {editMode6? (
                  <>
                    <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}>
                  <a href={returnuploadticketURL === null ? forwarddocs.return_upload_ticket : returnuploadticketURL} rel="noreferrer" target="_blank">
                  <svg style={{marginTop:"3px", marginLeft:"3px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z" fill="black"/>
                    <path d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z" fill="black"/>
                  </svg>

                  </a>

                </div>

                <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                      onClick={() => {
                        handleforwarddoc(forwarddocs.return_upload_ticket);
                      }}>
                  
                  <svg style={{marginTop:"4px", marginLeft:"5px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32"/>
                  </svg>
                </div>
                  </>
                ):(
                  <>
                    <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}>
                  <a href={forwarddocs.return_upload_ticket} rel="noreferrer" target="_blank">
                  <svg style={{marginTop:"3px", marginLeft:"3px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z" fill="black"/>
                    <path d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z" fill="black"/>
                  </svg>

                  </a>

                </div>

                <div style={{border:"none", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                      onClick={() => {
                        handleforwarddoc(forwarddocs.return_upload_ticket);
                      }}>
                  
                  <svg style={{marginTop:"4px", marginLeft:"5px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32"/>
                  </svg>
                </div>
                  </>
                )}
                    </div>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "bold" }}>Hotel Bookings</h6>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px" }}>Hotel Name</div>
                      <div>
                        {editMode6 ? (
                          <input
                            type="text"
                            style={{
                              border: "1px solid #E7EBFF",
                              borderRadius: "8px",
                              height: "30px",
                              width: "280px",
                            }}
                            name="return_hotel_name"
                            value={forwarddocs.return_hotel_name}
                            onChange={handleforward}
                          />
                        ) : (
                          <input
                            type="text"
                            style={{
                              border: "none",
                              height: "30px",
                              background: "#F0F0F0",
                              borderRadius: "8px",
                              width: "280px",
                            }}
                            value={
                              forwarddocs.return_hotel_name
                                ? forwarddocs.return_hotel_name
                                : " "
                            }
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px" }}>Booking Dates</div>
                      {editMode6 ? (
                        <>
                          <div>
                            <input
                              type="date"
                              style={{
                                width: "130px",
                                border: "1px solid #E7EBFF",
                                borderRadius: "8px",
                                height: "30px",
                                cursor:"pointer"
                              }}
                              name="return_booking_checkin"
                              value={forwarddocs.return_booking_checkin}
                              onChange={handleforward}
                            />
                          </div>

                          <div>
                            <input
                              type="date"
                              style={{
                                width: "130px",
                                border: "1px solid #E7EBFF",
                                borderRadius: "8px",
                                height: "30px",
                                cursor:"pointer"
                              }}
                              name="return_booking_checkout"
                              value={forwarddocs.return_booking_checkout}
                              onChange={handleforward}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <input
                              type="text"
                              value={
                                forwarddocs.return_booking_checkin
                                  ? forwarddocs.return_booking_checkin
                                  : " "
                              }
                              disabled
                              style={{
                                border: "none",
                                height: "30px",
                                background: "#F0F0F0",
                                borderRadius: "8px",
                                width: "130px",
                                textAlign: "center",
                              }}
                            />
                          </div>

                          <div>
                            <input
                              type="text"
                              value={
                                forwarddocs.return_booking_checkout
                                  ? forwarddocs.return_booking_checkout
                                  : " "
                              }
                              disabled
                              style={{
                                width: "100px",
                                border: "none",
                                height: "30px",
                                background: "#F0F0F0",
                                borderRadius: "8px",
                                width: "130px",
                                textAlign: "center",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "40px",
                      }}>
                      <div style={{ width: "120px", marginTop: "2px" }}>
                        Upload Booking
                      </div>
                      <div>
                        {editMode6 ? (
                          returnuploadbookingname !=="" ? (
                            <div style={{display:"flex"}}>
                          <div
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "200px",
                              borderRadius: "8px",
                              height: "35px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div
                                style={{
                                  maxWidth: "170px", // Adjusted width to accommodate the SVG
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                            {returnuploadbookingname}
                            </div>
                          </div>
                          <svg
                          width="20"
                          onClick={() => removefile("reuploadbook")}
                          style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg></div>
                          ): forwarddocs.return_upload_booking ? (
                            <div style={{display:"flex"}}>
                          <div
                            style={{
                              border: "1px solid #E7EBFF",
                              width: "200px",
                              borderRadius: "8px",
                              height: "35px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <div
                                style={{
                                  maxWidth: "170px", // Adjusted width to accommodate the SVG
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                            {returnuploadbookingfilename}
                            </div>
                          </div>
                          <svg
                          width="20"
                          onClick={() => removefile("reuploadbook")}
                          style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg></div>
                          ):(
                            <>
                            <input
                              style={{
                                display: "none",
                              }}
                              type="file"
                              name="return_upload_booking"
                              accept=".pdf"
                              id="reuploadbooking"
                              onChange={handleforwardfile}
                            />
                            <div style={{display:"flex"}}>
                            <div
                              style={{
                                border: "1px solid #E7EBFF",
                                width: "200px",
                                borderRadius: "8px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                document.getElementById("reuploadbooking").click();
                              }}
                            >
                              {forwarddocs.return_upload_booking ? returnuploadbookingname : "No File is Selected"}
                            </div>
                            <svg
                              onClick={() => {
                                document.getElementById("reuploadbooking").click();
                              }}
                              style={{marginTop:"7px", marginLeft:"-25px", cursor:"pointer"}}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                fill="black"
                              />
                              <path
                                d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                fill="black"
                              />
                            </svg>
                            </div>
                          </>
                          )
                        ) : (
                          <input
                            style={{
                              border: "none",
                              width: "125px",
                              height: "30px",
                              background: "#F0F0F0",
                              borderRadius: "8px",
                              width: "200px",
                            }}
                            type="text"
                            value={
                              forwarddocs.return_upload_booking
                                ? returnuploadbookingfilename
                                : " "
                            }
                            disabled
                          />
                        )}
                      </div>
                      {editMode6 ? (
                  <>
                    <div style={{border:"1px solid #E7EBFF", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}>
                  <a href={returnuploadbookingURL === null ? forwarddocs.return_upload_booking : returnuploadbookingURL} rel="noreferrer" target="_blank">
                  <svg style={{marginTop:"3px", marginLeft:"3px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z" fill="black"/>
                    <path d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z" fill="black"/>
                  </svg>
                  </a>

                </div>

                <div style={{border:"1px solid #E7EBFF", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                      onClick={() => {
                        handleforwarddoc(forwarddocs.return_upload_booking);
                      }}>
                  
                  <svg style={{marginTop:"4px", marginLeft:"5px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32"/>
                  </svg>
                </div>
                  </>
                ):(
                  <>
                    <div style={{border:"1px solid #E7EBFF", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}>
                  <a href={forwarddocs.return_upload_booking} rel="noreferrer" target="_blank">
                  <svg style={{marginTop:"3px", marginLeft:"3px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z" fill="black"/>
                    <path d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z" fill="black"/>
                  </svg>
                  </a>

                </div>

                <div style={{border:"1px solid #E7EBFF", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#F0F0F0'}}
                      onClick={() => {
                        handleforwarddoc(forwarddocs.return_upload_booking);
                      }}>
                
                  <svg style={{marginTop:"4px", marginLeft:"5px"}} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32"/>
                  </svg>
                </div>
                  </>
                )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "20px",
                    marginTop: "60px",
                    marginBottom: "20px",
                  }}>
                  {editMode6 ? (
                    <>
                      <button
                        onClick={handleCancel6}
                        style={{
                          background: "white",
                          color: "#5B6BE1",
                          width: "181px",
                          height: "50px",
                          border: "1px solid #5B6BE1",
                          borderRadius: "8px",
                          padding: "15px 59px 15px 58px",
                          fontWeight: "bold",
                        }}>
                        Cancel
                      </button>
                      <button
                        onClick={handleEdit6}
                        style={{
                          background: "#5B6BE1",
                          width: "181px",
                          height: "50px",
                          border: "1px solid #5B6BE1",
                          borderRadius: "8px",
                          padding: "15px 59px 15px 58px",
                          fontWeight: "bold",
                        }}>
                        Save
                      </button>
                    </>
                  ) : (
                    featureedit6.length > 0 && (<button
                      style={{
                        background: "#5B6BE1",
                        width: "181px",
                        height: "50px",
                        border: "1px solid #5B6BE1",
                        borderRadius: "8px",
                        padding: "15px 59px 15px 58px",
                        fontWeight: "bold",
                      }}
                      onClick={() => setEditMode6(true)}>
                      Edit{" "}
                      <svg
                        style={{ marginLeft: "2px" }}
                        width="16"
                        height="16"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.75 16.5H2.25C1.9425 16.5 1.6875 16.245 1.6875 15.9375C1.6875 15.63 1.9425 15.375 2.25 15.375H15.75C16.0575 15.375 16.3125 15.63 16.3125 15.9375C16.3125 16.245 16.0575 16.5 15.75 16.5Z"
                          fill="white"
                        />
                        <path
                          d="M14.2649 2.61C12.8099 1.155 11.3849 1.1175 9.89243 2.61L8.98493 3.5175C8.90993 3.5925 8.87993 3.7125 8.90993 3.8175C9.47993 5.805 11.0699 7.395 13.0574 7.965C13.0874 7.9725 13.1174 7.98 13.1474 7.98C13.2299 7.98 13.3049 7.95 13.3649 7.89L14.2649 6.9825C15.0074 6.2475 15.3674 5.535 15.3674 4.815C15.3749 4.0725 15.0149 3.3525 14.2649 2.61Z"
                          fill="white"
                        />
                        <path
                          d="M11.7082 8.6475C11.4907 8.5425 11.2807 8.4375 11.0782 8.3175C10.9132 8.22 10.7557 8.115 10.5982 8.0025C10.4707 7.92 10.3207 7.8 10.1782 7.68C10.1632 7.6725 10.1107 7.6275 10.0507 7.5675C9.80323 7.3575 9.52573 7.0875 9.27823 6.7875C9.25573 6.7725 9.21823 6.72 9.16573 6.6525C9.09073 6.5625 8.96323 6.4125 8.85073 6.24C8.76073 6.1275 8.65573 5.9625 8.55823 5.7975C8.43823 5.595 8.33323 5.3925 8.22823 5.1825C8.09056 4.88749 7.70337 4.79986 7.47317 5.03005L3.25573 9.2475C3.15823 9.345 3.06823 9.5325 3.04573 9.66L2.64073 12.5325C2.56573 13.0425 2.70823 13.5225 3.02323 13.845C3.29323 14.1075 3.66823 14.25 4.07323 14.25C4.16323 14.25 4.25323 14.2425 4.34323 14.2275L7.22323 13.8225C7.35823 13.8 7.54573 13.71 7.63573 13.6125L11.8601 9.38809C12.0857 9.1625 12.001 8.77435 11.7082 8.6475Z"
                          fill="white"
                        />
                      </svg>
                    </button>)
                  )}
                </div>
              </div>
            </>
          )}
          </div>):(<div>
           <div style={{paddingTop:"250px", marginLeft:"35%"}}>
              <h1>No Team Members</h1>
           </div>
          </div>)}
        </Box>
  )

}

export default Personal;