import React from "react";
import styles from "./css/workshop_spares_trading_inventory_used.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import TabsBarSparesTrading from './TabsBarSparesTrading';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparesTradingWorkshopInventoryUsed() {
  const { id } = useParams();
  const [inventoryUtilData, setInventoryUtilData] = useState([]);
  const [formData, setFormData] = useState({
    Sales_Order_No: "",
    PO_No: "",
    PO_Date: null,
    Quote_No: "",
    Quote_Date: null,
    Costing_ID: "",
    Customer_Name: "",
    Scope: "",
    Scope_Description: "",
    Estimated_Date_Of_Dispatch: null,
    Ops_Engineer: "",
    Current_Status: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );

        if (response.data.success) {
          const data = response.data.data[0];
          setFormData({
            Sales_Order_No: data.Sales_Order_No,
            PO_No: data.PO_No,
            PO_Date: data.PO_Date,
            Quote_No: data.Quote_No,
            Quote_Date: data.Quote_Date,
            Costing_ID: data.Costing_ID,
            Customer_Name: data.Customer_Name,
            Scope: data.Scope,
            Scope_Description: data.Scope_Description,
            Estimated_Date_Of_Dispatch: data.Estimated_Date_Of_Dispatch,
            Ops_Engineer: data.Ops_Engineer,
            Current_Status: data.Current_Status,
          });

          const res1 = await axios.get(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_projectSO_get?Project_SO=${data?.Sales_Order_No}`
          );
          console.log("inventory used", res1.data.data);
          const inventoryUtilRes = res1.data.data;

          const updatedItemsReceived = await Promise.all(inventoryUtilRes.map(async (item) => {
            const ergonBOERes = await axios.get(
              `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_get_ErgonBOE?Incoming_Delivery_ID=${item.Incoming_Delivery_ID}`
            );
            console.log("ergonBOERes", ergonBOERes);
            return {
              ...item,
              Entry_Bill_No: ergonBOERes.data.data[0]?.Entry_Bill_No,
            };
          }));

          console.log("Updated itemsReceived", updatedItemsReceived);

          // Fetch consumption details
          const consumptionRes = await axios.get(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_used_consumption_details?Project_ID=${id}`
          );

          const consumptionDetails = consumptionRes.data.data;

          // Add Qty_Used to each item in updatedItemsReceived
          const updatedItemsWithConsumption = updatedItemsReceived.map(item => ({
            ...item,
            Qty_Used: 0, // Initialize Qty_Used with 0
          }));

          // Process each unique item and assign Qty_Used
          const itemConsumptionMap = {};

          consumptionDetails.forEach(consumptionItem => {
            const key = `${consumptionItem.Item_Name}_${consumptionItem.Part_Number}`;
            if (!itemConsumptionMap[key]) {
              itemConsumptionMap[key] = parseFloat(consumptionItem.Qty_Consumed) || 0;
            } else {
              itemConsumptionMap[key] += parseFloat(consumptionItem.Qty_Consumed) || 0;
            }
          });

          updatedItemsWithConsumption
            .sort((a, b) => new Date(b.Date_Of_Utilization) - new Date(a.Date_Of_Utilization))
            .forEach(updatedItem => {
              const key = `${updatedItem.Item_Name}_${updatedItem.Part_Number}`;
              if (itemConsumptionMap[key] && itemConsumptionMap[key] > 0) {
                const qtyUtilized = parseFloat(updatedItem.Qty_Utilized) || 0;
                const qtyToAssign = Math.min(qtyUtilized, itemConsumptionMap[key]);
                updatedItem.Qty_Used += qtyToAssign;
                itemConsumptionMap[key] -= qtyToAssign;
              }
            });

          setInventoryUtilData(updatedItemsWithConsumption);
          console.log("updatedItemsWithConsumption", updatedItemsWithConsumption);


          // setInventoryUtilData(updatedItemsReceived);

          console.log(data);
          console.log(id);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
    };
    fetchData();
  }, [id]);

  // Sort the inventoryUtilData array by Date_Of_Utilization in descending order
  const sortedData = inventoryUtilData.sort((a, b) => {
    return new Date(b.Date_Of_Utilization) - new Date(a.Date_Of_Utilization);
  });

  function formatDate(inputDate) {
    console.log(inputDate);
    if (inputDate == null || inputDate == "") {
      return "-";
    }

    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      return "-";
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }


  return (
    <div>
      <div className={styles.topdiv}>
        <label className={styles.aryl1}>
          SO: {formData.Sales_Order_No}
        </label>
        <label className={styles.aryl}>
          PO: {formData.PO_No}
        </label>
        <label className={styles.aryl}>
          Customer Name: {formData.Customer_Name}
        </label>
      </div>

      <TabsBarSparesTrading activeTab="Inventory Used" />

      <div className={styles.sections2} style={{ paddingTop: "10px" }}>
        <div className={styles.smtablecontainer}>
          {sortedData.length === 0 ? (
            <>
              <h4 style={{ textAlign: "center", fontWeight:"700", marginTop: "15px", color: "#567191" }}>Allocate items to this project.</h4>
              <h4 style={{ textAlign: "center", fontWeight:"700", marginTop: "15px", color: "#567191" }}>Use 'Inventory Utilization' option in 'Admin Dashboard'.</h4>
            </>
        ) : (
            <table className={styles.smtable}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>UoM</th>
                  <th>BOE</th>
                  <th>Utilization Date from Stock</th>
                  <th>Qty taken from Inventory</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item, index) => (
                  <tr key={item.S_No}>
                    <td>{index + 1}</td>
                    <td>{item.Item_Name || "-"}</td>
                    <td>{item.Part_Number || "-"}</td>
                    <td>{item.UoM || "-"}</td>
                    <td>{item.Entry_Bill_No || "-"}</td>
                    <td>{formatDate(item.Date_Of_Utilization) || "-"}</td>
                    <td>{item.Qty_Utilized || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default SparesTradingWorkshopInventoryUsed
