import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../Components/css/purchaseincomingcreatenew.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import axios from "axios";
import dayjs from "dayjs";
import { CoPresentOutlined } from "@mui/icons-material";
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";
const PurchaseIncomingUpdate = () => {
  const { Purchase_id, S_No } = useParams();
  const navigate = useNavigate();
  const [receivedDeliveryStatus, setReceivedDeliveryStatus] = useState(false);
  const [documentToUpload1, setDocumentToUpload1] = useState("");
  const [documentToUpload2, setDocumentToUpload2] = useState("");
  const [documentToUpload3, setDocumentToUpload3] = useState("");
  const [documentToUpload4, setDocumentToUpload4] = useState("");
  const [fileName1, setFileName1] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [fileName3, setFileName3] = useState("");
  const [fileName4, setFileName4] = useState("");
  const [removeCIPLdoc, setRemoveCIPLdoc] = useState(false);
  const [removeCustExitDoc, setRemoveCustExitDoc] = useState(false);
  const [removeErgonEntryDoc, setRemoveErgonEntryDoc] = useState(false);
  const [removeMopaAttestation, setRemoveMopaAttestation] = useState(false);

  const [isNext, setIsNext] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItems2, setFilteredItems2] = useState([]);
  const [incomingQuantities, setIncomingQuantities] = useState([]);
  const [mergedArray, setMergedArray] = useState([]);
  const [pendingQty, setPendingQty] = useState([]);
  const [isfillsame, setIsfillsame] = useState(false);
  const [isedit1, setIsedit1] = useState(false);
  const [isedit2, setIsedit2] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [isDocument, setIsDocument] = useState(true);
  const [featureincomingdeliverydocedit,setfeatureincomingdeliverydocedit] = useState([]);
  const [featureincomingdeliveryitemedit,setfeatureincomingdeliveryitemedit] = useState([]);
  const [formDataDelivery, setFormDataDelivery] = useState({
    S_No: "",
    CIPL_Doc_No: "",
    CIPL_Doc: "",
    Customer_Exit_Bill_No: "",
    Customer_Exit_Bill_Doc: "",
    Entry_Bill_Doc: "",
    Dispatching_Party: "",
    Expected_Arrival_Date: "",
    ID_No: "",
    Entry_Bill_No: "",
    Entry_Bill_Date: "",
    Customer_Exit_Bill_Date: "",
    CIPL_Date: "",
    Received_Delivery_Status: "",
    MOPA_Attestation: "",
  });

  const [formdata1, setFormData1] = useState({
    CiplNumber: "",
    CiplDoc: "",
    CustomerExitNumber: "",
    CustomerExitDoc: "",
    ErgonBillDoc: "",
    DispatchingParty: "",
    ExpectedArrivalDate: "",
    ErgonBillNumber: "",
    ErgonBillDate: "",
    CustomerExitDate: "",
    CiplDate: "",
  });
  async function fetchsmDetails() {
    try {
      const response = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_details?ID_No=${Purchase_id}`
      );
      console.log(response.data.data);

      const filterDeliveryData = response.data.data.filter(
        (el) => el.S_No == S_No
      );
      console.log("sdfdsfs", filterDeliveryData);
      // const [formdata1, setFormData1] = useState({
      //   CiplNumber: "",
      //   CiplDoc: "",
      //   CustomerExitNumber: "",
      //   CustomerExitDoc: "",
      //   ErgonBillDoc: "",
      //   DispatchingParty: "",
      //   ExpectedArrivalDate: "",
      //   ErgonBillNumber: "",
      //   ErgonBillDate: "",
      //   CustomerExitDate: "",
      //   CiplDate: "",
      // });

      setFormDataDelivery({
        ...formDataDelivery,
        S_No: filterDeliveryData[0].S_No,
        CiplNumber: filterDeliveryData[0].CIPL_Doc_No,
        CiplDoc: filterDeliveryData[0].CIPL_Doc,
        CustomerExitNumber: filterDeliveryData[0].Customer_Exit_Bill_No,
        CustomerExitDoc: filterDeliveryData[0].Customer_Exit_Bill_Doc,
        ErgonBillDoc: filterDeliveryData[0].Entry_Bill_Doc,
        DispatchingParty: filterDeliveryData[0].Dispatching_Party,
        ExpectedArrivalDate: filterDeliveryData[0].Expected_Arrival_Date,
        ID_No: filterDeliveryData[0].ID_No,
        ErgonBillNumber: filterDeliveryData[0].Entry_Bill_No,
        ErgonBillDate: filterDeliveryData[0].Entry_Bill_Date,
        CustomerExitDate: filterDeliveryData[0].Customer_Exit_Bill_Date,
        CiplDate: filterDeliveryData[0].CIPL_Date,
        Received_Delivery_Status:
          filterDeliveryData[0].Received_Delivery_Status,
        Incoming_Inspection_ID: filterDeliveryData[0].Incoming_Inspection_Id,
        MopaAttestation: filterDeliveryData[0].MOPA_Attestation,
      });
      console.log("SDsdsds", formDataDelivery);
      setFormData1({
        ...formDataDelivery,
        CiplNumber: filterDeliveryData[0].CIPL_Doc_No,
        CiplDoc: filterDeliveryData[0].CIPL_Doc,
        CustomerExitNumber: filterDeliveryData[0].Customer_Exit_Bill_No,
        CustomerExitDoc: filterDeliveryData[0].Customer_Exit_Bill_Doc,
        ErgonBillDoc: filterDeliveryData[0].Entry_Bill_Doc,
        DispatchingParty: filterDeliveryData[0].Dispatching_Party,
        ExpectedArrivalDate: filterDeliveryData[0].Expected_Arrival_Date,
        ErgonBillNumber: filterDeliveryData[0].Entry_Bill_No,
        ErgonBillDate: filterDeliveryData[0].Entry_Bill_Date,
        CustomerExitDate: filterDeliveryData[0].Customer_Exit_Bill_Date,
        CiplDate: filterDeliveryData[0].CIPL_Date,
        MopaAttestation: filterDeliveryData[0].MOPA_Attestation,
        finalReceivedDeliveryStatus:
          filterDeliveryData[0].Received_Delivery_Status,
        // filterDeliveryData[0].Received_Delivery_Status,
      });
      console.log("dddd", formdata1);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchsmDetails();
  }, [refresh]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_item_details?Purchase_ID=${Purchase_id}`
        );
        console.log("res", res);
        const data = res.data.data;
        console.log("data", data);
        setFilteredItems(data);
        // Initialize incomingQuantities state with empty values for each item
        // setIncomingQuantities(new Array(data.length).fill(""));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchItems();
  }, [Purchase_id]);

  useEffect(() => {
    const fetchItemsmapped = async () => {
      try {
        // Making a GET request to the specified endpoint
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_items_mapped?Purchase_ID=${Purchase_id}&Incoming_Delivery_ID=${S_No}`
        );
        console.log("res", res);
        // Extracting data from the response
        const data = res.data.data;
        console.log("dataaaaaaaaaaaaaaaaaa", data);
        // Setting the fetched data to the state variable filteredItems2
        setFilteredItems2(data);
        // Initialize incomingQuantities state with empty values for each item
        // setIncomingQuantities(new Array(data.length).fill(""));
      } catch (error) {
        // Handling errors
        console.error("Error fetching data:", error);
      }
    };

    // Calling the fetchItemsmapped function when the component mounts or when Purchase_id changes
    fetchItemsmapped();
  }, [Purchase_id]);

  const [incomingData, setIncomingData] = useState([]);

  useEffect(() => {
    const fetchItemsqty = async () => {
      try {
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_item_qty?Purchase_ID=${Purchase_id}`
        );

        console.log("restt", res);
        const data2 = res.data.data;

        // Filter data based on Incoming_Delivery_ID=${S_No}
        const filteredData2 = res.data.data.filter(
          (el) => el.Incoming_Delivery_ID == S_No
        );

        console.log("ggggyyyyy", data2);

        // Extract quantities and OEM_Part_Numbers from filtered data
        const quantities = filteredData2.map((item) => item.Scheduled_Quantity);
        const partNumbers = filteredData2.map((item) => item.OEM_Part_Number);

        // Set incoming data and quantities in states
        setIncomingData(filteredData2);
        setIncomingQuantities(quantities);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchItemsqty();
  }, [Purchase_id, S_No]);

  const handleSave2 = async () => {
    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_incoming_quantities?Incoming_Delivery_ID=${S_No}`,
        incomingData
      );
      const res2 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_incoming_quantities_inspections?Incoming_Delivery_ID=${S_No}`,
        incomingData
      );

      // Handle success response if needed
      window.location.reload();
    } catch (error) {
      console.error("Error updating quantities:", error);
      // Handle error response if needed
    }
  };

  useEffect(() => {
    const fetchItems2 = async () => {
      try {
        const res9 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_items_get9?Purchase_ID=${Purchase_id}`
        );
        console.log("res", res9);
        const data9 = res9.data.data;
        console.log("data", data9);
        setPendingQty(data9);
        // Initialize incomingQuantities state with empty values for each item
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchItems2();
  }, [Purchase_id]);

  const [differencesArray, setDifferencesArray] = useState([]);

  useEffect(() => {
    setFileName1(extractFileName(formdata1.CiplDoc));
  }, [formdata1.CiplDoc]);
  useEffect(() => {
    setFileName2(extractFileName(formdata1.CustomerExitDoc));
  }, [formdata1.CustomerExitDoc]);

  useEffect(() => {
    setFileName3(extractFileName(formdata1.ErgonBillDoc));
  }, [formdata1.ErgonBillDoc]);

  useEffect(() => {
    setFileName4(extractFileName(formdata1.MopaAttestation));
  }, [formdata1.MopaAttestation]);

  const [errors, setErrors] = useState(Array(filteredItems.length).fill(false));

  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    // Check if incomingQuantities is not empty and contains any non-empty values
    const hasNonEmptyValues =
      incomingQuantities.length > 0 &&
      incomingQuantities.some(
        (val) => typeof val === "string" && val.trim() !== ""
      );

    if (hasNonEmptyValues) {
      // Check if any error exists, if yes, disable the save button
      const hasError = errors.some((error) => error);
      setSaveDisabled(hasError);
    } else if (isfillsame == true) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true); // Disable save button if incomingQuantities is empty or contains only empty strings
    }

    // Check if the sum of differencesArray is 0
    // Disable input if sum is 0
  }, [errors, differencesArray, incomingQuantities]);

  useEffect(() => {
    // Merge filteredItems and incomingQuantities arrays
    const mergedArray = filteredItems.map((item, index) => ({
      ...item,
      Scheduled_Quantity: incomingQuantities[index] || "", // Adding incomingQuantity to each item
    }));
    setMergedArray(mergedArray);
  }, [incomingQuantities]);

  const [formdata2, setFormData2] = useState({
    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    SupplierName: "",
    LinkedProject: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_details?Purchase_ID=${Purchase_id}`
      );
      const filter = res.data.data.filter(
        (el) => el.Purchase_ID == Purchase_id
      );

      setFormData2({
        ...formdata2,

        PONO: filter[0]?.PO_Number?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        LinkedProject: filter[0]?.Linked_Project,
        SupplierName: filter[0]?.Supplier_Name,
      });
    };
    fetchProject();
  }, [Purchase_id]);

  // const [newRows, setNewRows] = useState("");

  const handleClick = () => {
    navigate(`/universal_deliveries`);
  };
  // const handleNext = () => {
  //   if (!formdata1.ExpectedArrivalDate) {
  //     alert("Expected Arrival Date is required in Step 1.");
  //     return; // Exit the function without saving if the date is null
  //   } else setIsNext(true);
  // };
  // const handleBack = () => {
  //   setIsNext(false);
  // };
  const handleIncomingClick = () => {
    setIsDocument(true);
  };

  const handleOutgoingClick = () => {
    setIsDocument(false);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formdata1, [name]: value });
  };
  // date changes
  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("YYYY-MM-DD");
      setFormData1({ ...formdata1, [name]: selectedDate });
      // setFormData3Tool1({ ...formdata3Tool1, [name]: selectedDate });
      // setFormData3Tool2({ ...formdata3Tool2, [name]: selectedDate });
      console.log(name, selectedDate);
    } else {
      console.log("Invalid Date");
    }
  };
  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const handlecross = (name) => {
    switch (name) {
      case "CIPL_Doc":
        setFileName1("");
        setDocumentToUpload1("");
        setRemoveCIPLdoc(true);
        break;
      case "Customer_Exit_Bill_Doc":
        setFileName2("");
        setDocumentToUpload2("");
        setRemoveCustExitDoc(true);
        break;
      case "Entry_Bill_Doc":
        setFileName3("");
        setDocumentToUpload3("");
        setRemoveErgonEntryDoc(true);
        break;
      case "MOPA_Attestation":
        setFileName4("");
        setDocumentToUpload4("");
        setRemoveMopaAttestation(true);
        break;
    }
  };

  const handleSave1 = async (e, el) => {
    e.preventDefault();
    const {
      CiplNumber,
      CiplDoc,
      CustomerExitNumber,
      CustomerExitDoc,
      ErgonBillDoc,
      DispatchingParty,
      ExpectedArrivalDate,
      ErgonBillNumber,
      ErgonBillDate,
      CustomerExitDate,
      CiplDate,
    } = formdata1;

    const {
      PONO,
      PODays,
      PODate,
      QuoteNO,
      QuoteDate,
      SupplierName,
      LinkedProject,
    } = formdata2;

    const finalReceivedDeliveryStatus =
      ExpectedArrivalDate <= currentDate
        ? formdata1.finalReceivedDeliveryStatus
        : "0";
    const removeConditions = [
      { remove: removeCIPLdoc, temp: fileName1, type: "CIPL_Doc" },
      {
        remove: removeCustExitDoc,
        temp: fileName2,
        type: "Customer_Exit_Bill_Doc",
      },
      { remove: removeErgonEntryDoc, temp: fileName3, type: "Entry_Bill_Doc" },
      {
        remove: removeMopaAttestation,
        temp: fileName4,
        type: "MOPA_Attestation",
      },
    ];

    for (const condition of removeConditions) {
      if (condition.remove && condition.temp === "") {
        const payloadRemoveFiles = { type: condition.type };
        await axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_removefiles/${S_No}`,
          payloadRemoveFiles
        );
      }
    }
    const payload = {
      CIPL_Doc_No: CiplNumber,

      Customer_Exit_Bill_No: CustomerExitNumber,

      Dispatching_Party: SupplierName,
      Expected_Arrival_Date: ExpectedArrivalDate,
      ID_No: Purchase_id,
      Entry_Bill_No: ErgonBillNumber,
      Entry_Bill_Date: ErgonBillDate,
      Customer_Exit_Bill_Date: CustomerExitDate,
      CIPL_Date: CiplDate,
      Received_Delivery_Status: finalReceivedDeliveryStatus,
      Type: "Purchase",
    };
    const payloadfiles1 = new FormData();
    const payloadfiles2 = new FormData();
    const payloadfiles3 = new FormData();
    const payloadfiles4 = new FormData();

    payloadfiles3.append("CIPL_Doc", documentToUpload1);
    payloadfiles4.append("MOPA_Attestation", documentToUpload4);
    payloadfiles2.append("Customer_Exit_Bill_Doc", documentToUpload2);
    payloadfiles1.append("Entry_Bill_Doc", documentToUpload3);

    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_update?SNO=${S_No}`,
        payload
      );
      const proId = res.data.ID_No;
      console.log("saadasppppd", proId);

      const res0 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_received_delivery_status?Incoming_Delivery_ID=${S_No}`,
        { receivedDeliveryStatus: finalReceivedDeliveryStatus }
      );

      console.log("hhhhhhhhhhhhhhh");

      const res1 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseincomingdeliveries_put?proId=${S_No}`,
        payloadfiles1,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const res2 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseincomingdeliveries_put2?proId=${S_No}`,
        payloadfiles2,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const res3 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseincomingdeliveries_put3?proId=${S_No}`,
        payloadfiles3,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const res7 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseincomingdeliveries_put4?proId=${S_No}`,
        payloadfiles4,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // const modifiedMergedArray = mergedArray.map((item) => ({
      //   ...item,
      //   Incoming_Delivery_ID: proId,
      //   Incoming_Inspection_ID: incomingInspectionId,
      //   Received_Delivery_Status: finalReceivedDeliveryStatus,
      // }));

      // const res5 = await axios.post(
      //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_items_post`,
      //   modifiedMergedArray
      // );

      if (
        res.status === 200 &&
        res1.status === 200 &&
        res2.status === 200 &&
        res3.status === 200 &&
        res7.status == 200 &&
        res0.status == 200
        // &&
        // res5.status === 200
      ) {
        alert("Incoming delivery updated successfully");
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    window.location.reload();
  };

  const [documents, setDocuments] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDocuments([...documents, file]);
      setFileNames([...fileNames, ""]); // Add an empty string for the corresponding file
      event.target.value = ""; // Reset the file input
      console.log("docs", setDocuments);
      console.log("names", setFileNames);
    }
  };

  const handleCross2 = (index) => {
    const updatedDocuments = [...documents];
    const updatedFileNames = [...fileNames];
    updatedDocuments.splice(index, 1);
    updatedFileNames.splice(index, 1);
    setDocuments(updatedDocuments);
    setFileNames(updatedFileNames);
  };

  const handlefileupload1 = (name, event) => {
    event.preventDefault();
    const temp1 = event.target.files[0];
    setDocumentToUpload1(temp1);

    const inputElement1 = document.getElementsByName(name)[0];
    const selectedFile1 = inputElement1.files[0];

    if (selectedFile1) {
      const fileName1 = selectedFile1.name;
      setFileName1(fileName1 ? fileName1 : "");
    } else {
      setFileName1("");
    }
    inputElement1.value = "";
    temp1.value = "";
  };
  const handlefileupload2 = (name, event) => {
    event.preventDefault();
    const temp2 = event.target.files[0];
    setDocumentToUpload2(temp2);

    const inputElement2 = document.getElementsByName(name)[0];
    const selectedFile2 = inputElement2.files[0];

    if (selectedFile2) {
      const fileName2 = selectedFile2.name;
      setFileName2(fileName2 ? fileName2 : "");
    } else {
      setFileName2("");
    }
    inputElement2.value = "";
    temp2.value = "";
  };
  const handlefileupload3 = (name, event) => {
    event.preventDefault();
    const temp3 = event.target.files[0];
    setDocumentToUpload3(temp3);

    const inputElement3 = document.getElementsByName(name)[0];
    const selectedFile3 = inputElement3.files[0];

    if (selectedFile3) {
      const fileName3 = selectedFile3.name;
      setFileName3(fileName3 ? fileName3 : "");
    } else {
      setFileName3("");
    }
    inputElement3.value = "";
    temp3.value = "";
  };

  const handlefileupload4 = (name, event) => {
    event.preventDefault();
    const temp4 = event.target.files[0];
    setDocumentToUpload4(temp4);

    const inputElement4 = document.getElementsByName(name)[0];
    const selectedFile4 = inputElement4.files[0];

    if (selectedFile4) {
      const fileName4 = selectedFile4.name;
      setFileName4(fileName4 ? fileName4 : "");
    } else {
      setFileName4("");
    }
    inputElement4.value = "";
    temp4.value = "";
  };

  const extractFileNames = (filePaths) => {
    return filePaths.map((filePath) => {
      if (!filePath) return "";
      const fileName = filePath.split("/").pop().split("-").slice(1).join("-");
      return fileName.trim();
    });
  };

  useEffect(() => {
    if (formDataDelivery.CiplDoc) {
      const filePaths = formDataDelivery.CiplDoc.split(",");
      const trimmedNames = extractFileNames(filePaths);
      setDocuments(trimmedNames);
    }
  }, [formDataDelivery.CiplDoc]);

  const [inputValues, setInputValues] = useState({});
  const extractFileName = (filePath) => {
    if (!filePath) return "";
    const fileNameParts = filePath.toString().split("/").pop().split("-");
    fileNameParts.shift(); // Remove the first element (number)
    const finalname = fileNameParts.join("-");
    return finalname;
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "230px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));

  const getBackgroundColor = () => {
    return formDataDelivery.Received_Delivery_Status === "1"
      ? "#077D7D"
      : formDataDelivery.ExpectedArrivalDate < getCurrentDate()
      ? "#900909"
      : "#EFC221";
  };

  const getTextColor = () => {
    return formDataDelivery.Received_Delivery_Status === "1"
      ? "#FFFFFF"
      : formDataDelivery.ExpectedArrivalDate < getCurrentDate()
      ? "#FFFFFF"
      : "#000000";
  };

  const getButtonText = () => {
    return formDataDelivery.Received_Delivery_Status === "1"
      ? "RECEIVED"
      : formDataDelivery.ExpectedArrivalDate < getCurrentDate()
      ? "PENDING"
      : "UPCOMING";
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const currentDate = getCurrentDate();
  const handleEdit1 = () => {
    setIsedit1(true);
    // Add logic to handle edit click
  };
  const handleEdit2 = () => {
    setIsedit2(true);
    // Add logic to handle edit click
  };

  const handleDeliveryNavigate = () => {
    navigate(`/purchase_incoming_inspections_update/${Purchase_id}/${S_No}`);
  };

  const handleforwarddoc = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = ""; // Set desired filename
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };
  const adjustedS_No = parseInt(S_No) + 20000;

  const handleQuantityChange = (event, index) => {
    const { value } = event.target;
    const parsedValue = parseInt(value);
  
    const updatedQuantities = [...incomingQuantities];
    updatedQuantities[index] = parsedValue;
    setIncomingQuantities(updatedQuantities);
  
    const updatedData = [...incomingData];
    updatedData[index].Scheduled_Quantity = parsedValue;
    setIncomingData(updatedData);
  
    const newErrors = [...errors];
    if (parsedValue > sumState[index]) {
      newErrors[index] = true; // Set error state for this index
    } else {
      newErrors[index] = false; // Clear error state for this index
    }
    setErrors(newErrors);
  };
  
  
  useEffect(() => {
    // Calculate differencesArray whenever filteredItems or pendingQty change
    const newDifferencesArray = filteredItems.map((item) => {
      const pendingItem = pendingQty.find(
        (pItem) => pItem.OEM_Part_Number === item.OEM_Part_Number && pItem.Item_Name === item.Item_Name && pItem.Manufacturer_Part_Number === item.Manufacturer_Part_Number
      );
      return pendingItem
        ? item.Qty_ordered - pendingItem.Scheduled_Quantity
        : item.Qty_ordered;
    });
    setDifferencesArray(newDifferencesArray);
  }, [filteredItems, pendingQty]);

  const [sumState, setSumState] = useState([]);
  useEffect(() => {
    // Ensure both incomingQuantities and differencesArray have the same length
    if (incomingQuantities.length === differencesArray.length) {
      // Calculate the sum of corresponding elements from incomingQuantities and differencesArray
      const sumArray = incomingQuantities.map(
        (val, index) => val + differencesArray[index]
      );
      setSumState(sumArray);
    }
  }, []);
  useEffect(() => {
    if (incomingQuantities.length > 0) {
      // Ensure both incomingQuantities and differencesArray have the same length
       {
        // Calculate the sum of corresponding elements from incomingQuantities and differencesArray
        const sumArray = incomingQuantities.map(
          (val, index) => val + differencesArray[index]
        );
        setSumState(sumArray);
      }
    }
  }, [differencesArray]);
  console.log("setSumState", sumState);
  // const handleCheckboxChange = (isChecked) => {
  //   if (isChecked) {
  //     const updatedQuantities = differencesArray.map((difference) => difference || "");
  //     setIncomingQuantities(updatedQuantities);

  //     // After setting incomingQuantities, update the incomingData
  //     const updatedIncomingData = incomingData.map((data, index) => ({
  //       ...data,
  //       Scheduled_Quantity: updatedQuantities[index] // Assuming the index mapping is aligned
  //     }));
  //     setIncomingData(updatedIncomingData);
  //   } else {
  //     setIncomingQuantities(new Array(filteredItems.length).fill(""));

  //     // If checkbox is unchecked, you might need to reset incomingData Scheduled_Quantity to initial state
  //     const updatedIncomingData = incomingData.map((data) => ({
  //       ...data,
  //       Scheduled_Quantity: "" // Or whatever your initial state might be
  //     }));
  //     setIncomingData(updatedIncomingData);
  //   }
  //   setIsfillsame(isChecked);
  //   setErrors(Array(filteredItems.length).fill(false));
  // };

  // console.log("incomyyyy",incomingData)
  // console.log("incoming_qtyyyyyy",incomingQuantities)
  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );
  
        // Retrieve group and roles from localStorage
        const group = localStorage.getItem("Group");
        const roles = JSON.parse(localStorage.getItem("Roles")) || []; // Retrieve roles from localStorage
  
        // Filter for Group Features: Documents Edit
        const incomingDeliveriesDocEditGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Workshop Projects" &&
            el.Feature_Name === "Workshop Purchases Incoming Deliveries Documents Edit" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Filter for Role Features: Documents Edit
        const incomingDeliveriesDocEditRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Workshop Projects" &&
            el.Feature === "Workshop Purchases Incoming Deliveries Documents Edit" &&
            el.Access === "Can View"
          );
        });
  
        // Combine results for Documents Edit
        const incomingDeliveriesDocEditFeatures = [
          ...incomingDeliveriesDocEditGroup,
          ...incomingDeliveriesDocEditRole,
        ];
  
        // Filter for Group Features: Items Edit
        const incomingDeliveriesItemEditGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Workshop Projects" &&
            el.Feature_Name === "Workshop Purchases Incoming Deliveries Items Edit" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Filter for Role Features: Items Edit
        const incomingDeliveriesItemEditRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Workshop Projects" &&
            el.Feature === "Workshop Purchases Incoming Deliveries Items Edit" &&
            el.Access === "Can View"
          );
        });
  
        // Combine results for Items Edit
        const incomingDeliveriesItemEditFeatures = [
          ...incomingDeliveriesItemEditGroup,
          ...incomingDeliveriesItemEditRole,
        ];
  
        // Ensure "Can View" overrides "Can Edit"
        const prioritizeAccess = (features) => {
          const featureMap = {};
          features.forEach((feature) => {
            const key = `${feature.Header_Name || feature.Header}-${feature.Feature_Name || feature.Feature}`;
            if (!featureMap[key] || feature.Access === "Can View") {
              featureMap[key] = feature;
            }
          });
          return Object.values(featureMap);
        };
  
        // Set states with prioritized features
        console.log("I am here");
        console.log(incomingDeliveriesItemEditFeatures);
        setfeatureincomingdeliverydocedit(prioritizeAccess(incomingDeliveriesDocEditFeatures));
        setfeatureincomingdeliveryitemedit(prioritizeAccess(incomingDeliveriesItemEditFeatures));
      } catch (error) {
        console.error(error);
      }
    }
  
    fetchRigDetails();
  }, []);
  

  return (
    <div style={{ marginTop: "115px", marginLeft: "1%", marginRight: "1%" }}>
      <div>
        <button className={styles.mybutton} onClick={handleClick}>
          &lt; Incoming Deliveries
        </button>
      </div>
      <div>
        <div className={styles.box}>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              PO No: {formdata2.PONO}
            </label>
          </div>

          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Supplier Name: {formdata2.SupplierName}
            </label>
          </div>

          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Linked to SO: {formdata2.LinkedProject}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Delivery ID: {S_No}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Incoming Inspection ID: {adjustedS_No}
              <svg
                onClick={handleDeliveryNavigate}
                width="20"
                height="20"
                viewBox="0 0 24 24"
                style={{
                  marginLeft: "10px",
                  pointerEvents: "all",
                  cursor: "pointer",
                }}
              >
                <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
              </svg>
            </label>
          </div>
          <div>
            <label
              className={styles.deliveryStatusDiv}
              style={{
                backgroundColor: getBackgroundColor(),
                color: getTextColor(),
              }}
            >
              Delivery Status: {getButtonText()}
            </label>
          </div>
        </div>
      </div>
      <div>
        <button
          className={`${styles["incomingButton2"]} ${
            isDocument ? styles["incoming2"] : styles["not-incoming2"]
          }`}
          onClick={handleIncomingClick}
        >
          Documents
        </button>

        <button
          className={`${styles["outgoingButton2"]} ${
            !isDocument ? styles["outgoing2"] : styles["not-outgoing2"]
          }`}
          onClick={handleOutgoingClick}
        >
          Items
        </button>
      </div>
      {isDocument ? (
        !isedit1 ? (
          <div className={styles.sections}>
            <div className={styles.editAndSaveButtons}>
            {featureincomingdeliverydocedit.length !==0 && (
              <>
                <button
                  variant="contained"
                  onClick={handleEdit1}
                  className={styles.saveButton}
                >
                  Edit
                </button>
              </>
              )}
            </div>
            <form>
              <div style={{ marginLeft: "25px" }}>
                {/* custom cipl */}
                <div>
                  <div>
                    <div
                      className={`${styles.formElement} ${styles.datepickerInput}`}
                    >
                      <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                        Expected Arrival Date*:
                      </label>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDatePicker
                          name="ExpectedArrivalDate"
                          disabled
                          value={
                            formDataDelivery.ExpectedArrivalDate
                              ? dayjs(
                                  formDataDelivery.ExpectedArrivalDate,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          // onChange={(value) =>
                          //   handleDates("ExpectedArrivalDate", value)
                          // }
                          slotProps={{ textField: { size: "small" } }}
                          format="DD-MM-YYYY"
                          sx={{
                            backgroundColor: "#D8DADC",
                            borderRadius: "8px",
                            border: "1px solid #5B6BE1",
                            boxShadow: "none", // Corrected property name
                            marginLeft: "12px",
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <h2 className={styles.heading}>Supplier CIPL</h2>
                  {/* Document No */}
                  <div className={styles.formElement}>
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Number:
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        value={formDataDelivery.CiplNumber}
                        name="CiplNumber"
                        // onChange={handleOnChange}
                        className={styles.textboxedit1}
                        disabled
                      />
                    </div>
                  </div>
                  {/* Document No. Ends */}

                  {/* Document Date */}
                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}
                  >
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Date:
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="CiplDate"
                        value={
                          formDataDelivery.CiplDate
                            ? dayjs(formDataDelivery.CiplDate, "YYYY-MM-DD")
                            : null
                        }
                        disabled
                        // onChange={(value) => handleDates("CiplDate", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD-MM-YYYY"
                        sx={{
                          backgroundColor: "#D8DADC",
                          borderRadius: "8px",
                          border: "1px solid #5B6BE1",
                          boxShadow: "none", // Corrected property name
                          marginLeft: "52px",
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* Document Date Ends */}
                  {/* ----------------------------------------------------------------------------------------------- */}

                  <div
                    style={{
                      display: "inline-flex",
                      marginLeft: "0px",
                      padding: "10px 0px",
                      alignItems: "center",
                      border: "1px",
                    }}
                  >
                    <label
                      htmlFor="upload2"
                      style={{ color: "#5B6BE1", fontWeight: "600" }}
                    >
                      Upload Document:
                    </label>
                    <input
                      type="file"
                      name="upload1"
                      id="upload1"
                      // onChange={handleFileUpload2}
                      style={{ display: "none" }}
                      disabled
                    />

                    <div
                      className={styles.textboxedit2}
                      style={{ position: "relative" }}
                      // onClick={() => document.getElementById("upload2").click()}
                    >
                      <label
                        style={{
                          marginLeft: "15px",
                          marginTop: "5px",
                          color: "rgba(128, 128, 128, 0.8)",
                          overflow: "hidden", // Hide overflow text
                          textOverflow: "ellipsis", // Display ellipsis for overflow text
                          whiteSpace: "nowrap", // Prevent wrapping
                          maxWidth: "180px", // Set maximum width for the file name
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {formDataDelivery.CiplDoc
                            ? extractFileName(formDataDelivery.CiplDoc)
                            : ""}
                        </span>
                      </label>

                      <svg
                        width="25"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px", // Adjust as needed
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="grey"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="grey"
                        />
                      </svg>
                    </div>
                    {formDataDelivery.CiplDoc && (
                      <>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginLeft: "20px" }}
                          onClick={() =>
                            handleforwarddoc(formDataDelivery.CiplDoc)
                          }
                        >
                          <path
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                            fill="#292D32"
                          />
                        </svg>
                        <a href={formDataDelivery.CiplDoc} target=" _blank">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginLeft: "10px" }}
                          >
                            <path
                              d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                              fill="black"
                            />
                            <path
                              d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                              fill="black"
                            />
                          </svg>
                        </a>
                      </>
                    )}
                  </div>
                  {/* ----------------------------------------------------------------------------------------------- */}
                </div>
                {/* Customer Exit Bill/Delivery Note */}
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <h2 className={styles.heading}>
                    Supplier Exit Bill/Delivery Note
                  </h2>
                  {/* Document No */}
                  <div className={styles.formElement}>
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Number:
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        value={formDataDelivery.CustomerExitNumber}
                        name="CustomerExitNumber"
                        // onChange={handleOnChange}
                        className={styles.textboxedit1}
                        disabled
                      />
                    </div>
                  </div>
                  {/* Document No. Ends */}

                  {/* Document Date */}
                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}
                  >
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Date:
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="CustomerExitDate"
                        value={
                          formDataDelivery.CustomerExitDate
                            ? dayjs(
                                formDataDelivery.CustomerExitDate,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        disabled
                        // onChange={(value) =>
                        //   handleDates("CustomerExitDate", value)
                        // }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD-MM-YYYY"
                        sx={{
                          backgroundColor: "#D8DADC",
                          borderRadius: "8px",
                          border: "1px solid #5B6BE1",
                          boxShadow: "none", // Corrected property name
                          marginLeft: "52px",
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* Document Date Ends */}

                  <div
                    style={{
                      display: "inline-flex",
                      marginLeft: "0px",
                      padding: "10px 0px",
                      alignItems: "center",
                      border: "1px",
                    }}
                  >
                    <label
                      htmlFor="upload2"
                      style={{ color: "#5B6BE1", fontWeight: "600" }}
                    >
                      Upload Document:
                    </label>
                    <input
                      type="file"
                      name="upload2"
                      id="upload2"
                      // onChange={handleFileUpload2}
                      style={{ display: "none" }}
                      disabled
                    />

                    <div
                      className={styles.textboxedit2}
                      style={{ position: "relative" }}
                      onClick={() => document.getElementById("upload2").click()}
                    >
                      <label
                        style={{
                          marginLeft: "15px",
                          marginTop: "5px",
                          color: "rgba(128, 128, 128, 0.8)",
                          overflow: "hidden", // Hide overflow text
                          textOverflow: "ellipsis", // Display ellipsis for overflow text
                          whiteSpace: "nowrap", // Prevent wrapping
                          maxWidth: "180px", // Set maximum width for the file name
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {formDataDelivery.CustomerExitDoc
                            ? extractFileName(formDataDelivery.CustomerExitDoc)
                            : ""}
                        </span>
                      </label>

                      <svg
                        width="25"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px", // Adjust as needed
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="grey"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="grey"
                        />
                      </svg>
                    </div>
                    {formDataDelivery.CustomerExitDoc && (
                      <>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginLeft: "20px" }}
                          onClick={() =>
                            handleforwarddoc(formDataDelivery.CustomerExitDoc)
                          }
                        >
                          <path
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                            fill="#292D32"
                          />
                        </svg>
                        <a
                          href={formDataDelivery.CustomerExitDoc}
                          target=" _blank"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginLeft: "10px" }}
                          >
                            <path
                              d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                              fill="black"
                            />
                            <path
                              d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                              fill="black"
                            />
                          </svg>
                        </a>
                      </>
                    )}
                  </div>
                </div>
                {/* Ergon Entry Bill */}
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <h2 className={styles.heading}>Ergon Entry Bill</h2>
                  {/* Document No */}
                  <div className={styles.formElement}>
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Number:
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        value={formDataDelivery.ErgonBillNumber}
                        name="ErgonBillNumber"
                        // onChange={handleOnChange}
                        className={styles.textboxedit1}
                        disabled
                      />
                    </div>
                  </div>
                  {/* Document No. Ends */}

                  {/* Document Date */}
                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}
                  >
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Date:
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="ErgonBillDate"
                        value={
                          formDataDelivery.ErgonBillDate
                            ? dayjs(
                                formDataDelivery.ErgonBillDate,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        // onChange={(value) => handleDates("ErgonBillDate", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD-MM-YYYY"
                        disabled
                        sx={{
                          backgroundColor: "#D8DADC",
                          borderRadius: "8px",
                          border: "1px solid #5B6BE1",
                          boxShadow: "none", // Corrected property name
                          marginLeft: "52px",
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* Document Date Ends */}

                  <div
                    style={{
                      display: "inline-flex",
                      marginLeft: "0px",
                      padding: "10px 0px",
                      alignItems: "center",
                      border: "1px",
                    }}
                  >
                    <label
                      htmlFor="upload3"
                      style={{ color: "#5B6BE1", fontWeight: "600" }}
                    >
                      Upload Document:
                    </label>
                    <input
                      type="file"
                      name="upload3"
                      id="upload3"
                      // onInput={(e) => handlefileupload3("upload3", e)}
                      style={{
                        display: "none",
                      }}
                      disabled
                    />
                    <div
                      className={styles.textboxedit2}
                      style={{ position: "relative" }}
                      onClick={() => document.getElementById("upload3").click()}
                    >
                      <label
                        style={{
                          marginLeft: "15px",
                          marginTop: "5px",
                          color: "rgba(128, 128, 128, 0.8)",
                          overflow: "hidden", // Hide overflow text
                          textOverflow: "ellipsis", // Display ellipsis for overflow text
                          whiteSpace: "nowrap", // Prevent wrapping
                          maxWidth: "180px", // Set maximum width for the file name
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {formDataDelivery.ErgonBillDoc
                            ? extractFileName(formDataDelivery.ErgonBillDoc)
                            : ""}
                        </span>
                      </label>

                      <svg
                        width="25"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px", // Adjust as needed
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="grey"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="grey"
                        />
                      </svg>
                    </div>
                    {formDataDelivery.ErgonBillDoc && (
                      <>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginLeft: "20px" }}
                          onClick={() =>
                            handleforwarddoc(formDataDelivery.ErgonBillDoc)
                          }
                        >
                          <path
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                            fill="#292D32"
                          />
                        </svg>
                        <a
                          href={formDataDelivery.ErgonBillDoc}
                          target=" _blank"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginLeft: "10px" }}
                          >
                            <path
                              d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                              fill="black"
                            />
                            <path
                              d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                              fill="black"
                            />
                          </svg>
                        </a>
                      </>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "inline-flex",
                    marginLeft: "0px",
                    padding: "10px 0px",
                    alignItems: "center",
                    border: "1px",
                  }}
                >
                  <label
                    htmlFor="upload4"
                    style={{ color: "#5B6BE1", fontWeight: "600" }}
                  >
                    MOFA Attestation:
                  </label>
                  <input
                    type="file"
                    name="upload4"
                    id="upload4"
                    onInput={(e) => handlefileupload4("upload4", e)}
                    style={{
                      display: "none",
                    }}
                    disabled
                  />
                  <div
                    className={styles.textboxedit2}
                    style={{ position: "relative" }}
                    // onClick={() => document.getElementById("upload4").click()}
                  >
                    <label
                      style={{
                        marginLeft: "15px",
                        marginTop: "5px",
                        color: "rgba(128, 128, 128, 0.8)",
                        overflow: "hidden", // Hide overflow text
                        textOverflow: "ellipsis", // Display ellipsis for overflow text
                        whiteSpace: "nowrap", // Prevent wrapping
                        maxWidth: "180px", // Set maximum width for the file name
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {formDataDelivery.MopaAttestation
                          ? extractFileName(formDataDelivery.MopaAttestation)
                          : ""}
                      </span>
                    </label>

                    <svg
                      width="25"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px", // Adjust as needed
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                        fill="grey"
                      />
                      <path
                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                        fill="grey"
                      />
                    </svg>
                  </div>
                  {formDataDelivery.MopaAttestation && (
                    <>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: "20px" }}
                        onClick={() =>
                          handleforwarddoc(formDataDelivery.MopaAttestation)
                        }
                      >
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#292D32"
                        />
                      </svg>
                      <a
                        href={formDataDelivery.MopaAttestation}
                        target=" _blank"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginLeft: "10px" }}
                        >
                          <path
                            d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                            fill="black"
                          />
                          <path
                            d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                            fill="black"
                          />
                        </svg>
                      </a>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className={styles.sections}>
            <div className={styles.editAndSaveButtons}>
              <>
                <button
                  variant="contained"
                  onClick={handleSave1}
                  className={styles.saveButton}
                >
                  Save
                </button>

                <button
                  variant="outlined"
                  onClick={handleCancel}
                  className={styles.cancelBtn}
                >
                  Cancel
                </button>
              </>
            </div>
            <form>
              <div style={{ marginLeft: "25px" }}>
                {/* custom cipl */}
                <div>
                  <div>
                    <div
                      className={`${styles.formElement} ${styles.datepickerInput}`}
                    >
                      <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                        Expected Arrival Date*:
                      </label>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDatePicker
                          name="ExpectedArrivalDate"
                          value={
                            formdata1.ExpectedArrivalDate
                              ? dayjs(
                                  formdata1.ExpectedArrivalDate,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          onChange={(value) =>
                            handleDates("ExpectedArrivalDate", value)
                          }
                          slotProps={{ textField: { size: "small" } }}
                          format="DD-MM-YYYY"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                            border: "1px solid #5B6BE1",
                            boxShadow: "none", // Corrected property name
                            marginLeft: "12px",
                          }}
                        />
                      </LocalizationProvider>
                      {formdata1.ExpectedArrivalDate &&
                        formdata1.ExpectedArrivalDate !== "" &&
                        formdata1.ExpectedArrivalDate <= currentDate && (
                          <>
                            <input
                              type="checkbox"
                              checked={
                                formdata1.finalReceivedDeliveryStatus === "1"
                              }
                              style={{
                                marginLeft: "30px",
                                width: "12px",
                                height: "12px",
                              }}
                              onChange={(e) =>
                                setFormData1({
                                  ...formdata1,
                                  finalReceivedDeliveryStatus: e.target.checked
                                    ? "1"
                                    : "0",
                                })
                              }
                            />
                            <label
                              style={{
                                marginLeft: "10px",
                                color: "black",
                                fontWeight: 600,
                              }}
                            >
                              {" "}
                              Mark Received
                            </label>
                          </>
                        )}
                    </div>
                  </div>
                  <h2 className={styles.heading}>Supplier CIPL</h2>
                  {/* Document No */}
                  <div className={styles.formElement}>
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Number:
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        value={formdata1.CiplNumber}
                        name="CiplNumber"
                        onChange={handleOnChange}
                        className={styles.textbox1}
                      />
                    </div>
                  </div>
                  {/* Document No. Ends */}

                  {/* Document Date */}
                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}
                  >
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Date:
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="CiplDate"
                        value={
                          formdata1.CiplDate
                            ? dayjs(formdata1.CiplDate, "YYYY-MM-DD")
                            : null
                        }
                        onChange={(value) => handleDates("CiplDate", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD-MM-YYYY"
                        sx={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "8px",
                          border: "1px solid #5B6BE1",
                          boxShadow: "none", // Corrected property name
                          marginLeft: "52px",
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* Document Date Ends */}
                  {/* ----------------------------------------------------------------------------------------------- */}
                  {/* <div> */}
                  {/* <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    > */}
                  {/* <label
                        style={{
                          color: "#5B6BE1",
                          fontWeight: "600",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "20px",
                        }}
                      >
                        Upload Document:
                      </label> */}
                  {/* {documents.length === 0 && (
                        <div
                          className={styles.textbox2}
                          style={{
                            marginLeft: "15px",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                          onClick={() =>
                            document.getElementById("upload1").click()
                          }
                        >
                          <label
                            style={{
                              marginLeft: "15px",
                              marginRight: "5px",

                              color: "rgba(128, 128, 128, 0.8)",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "180px",
                              flex: "1",
                            }}
                          >
                            Upload File
                          </label>
                          <svg
                            width="25"
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            height="25"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                              fill="grey"
                            />
                            <path
                              d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                              fill="grey"
                            />
                          </svg>
                        </div>
                      )} */}
                  {/* {documents.map((file, index) => (
                        <div
                          key={index}
                          className={styles.textbox2}
                          style={{
                            marginLeft: "15px",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <label
                            style={{
                              marginLeft: "15px",
                              marginRight: "5px",

                              color: "rgba(128, 128, 128, 0.8)",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "180px",
                            }}
                          >
                            {file}
                          </label>
                          <svg
                            width="19"
                            style={{
                              marginLeft: "auto",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                            height="19"
                            viewBox="0 0 20 20"
                            fill="grey"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => handleCross2(index)}
                          >
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="grey"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="grey"
                            />
                          </svg>
                        </div>
                      ))} */}

                  {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 48 48"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "10px",
                        }}
                        onClick={() =>
                          document.getElementById("upload1").click()
                        }
                      >
                        <path fill="none" d="M0 0h48v48H0z"></path>
                        <path
                          fill="grey"
                          d="M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18h-8v8h-4v-8h-8v-4h8v-8h4v8h8v4z"
                        ></path>
                      </svg> */}
                  {/* </div> */}

                  {/* <input
                      type="file"
                      name="upload1"
                      id="upload1"
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    /> */}
                  {/* </div> */}
                  <div
                    style={{
                      display: "inline-flex",
                      marginLeft: "0px",
                      padding: "10px 0px",
                      alignItems: "center",
                      border: "1px",
                    }}
                  >
                    <label
                      htmlFor="upload1"
                      style={{ color: "#5B6BE1", fontWeight: "600" }}
                    >
                      Upload Document:
                    </label>
                    <input
                      type="file"
                      name="upload1"
                      id="upload1"
                      onInput={(e) => handlefileupload1("upload1", e)}
                      style={{
                        display: "none",
                      }}
                    />
                    <div
                      className={styles.textbox2}
                      style={{ position: "relative" }}
                      onClick={() => document.getElementById("upload1").click()}
                    >
                      <label
                        style={{
                          marginLeft: "15px",
                          marginTop: "5px",
                          color: "rgba(128, 128, 128, 0.8)",
                          overflow: "hidden", // Hide overflow text
                          textOverflow: "ellipsis", // Display ellipsis for overflow text
                          whiteSpace: "nowrap", // Prevent wrapping
                          maxWidth: "180px", // Set maximum width for the file name
                          alignItems: "center",
                        }}
                      >
                        {fileName1 ? fileName1 : "Upload File"}
                      </label>

                      <svg
                        width="25"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px", // Adjust as needed
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="grey"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="grey"
                        />
                      </svg>
                    </div>
                    {fileName1 && (
                      <svg
                        width="25"
                        onClick={() => handlecross("CIPL_Doc")}
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        height="25"
                        viewBox="0 0 20 20"
                        fill="grey"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                          fill="grey"
                        />
                        <path
                          d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                          fill="grey"
                        />
                      </svg>
                    )}
                  </div>

                  {/* ----------------------------------------------------------------------------------------------- */}
                </div>
                {/* Customer Exit Bill/Delivery Note */}
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <h2 className={styles.heading}>
                    Supplier Exit Bill/Delivery Note
                  </h2>
                  {/* Document No */}
                  <div className={styles.formElement}>
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Number:
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        value={formdata1.CustomerExitNumber}
                        name="CustomerExitNumber"
                        onChange={handleOnChange}
                        className={styles.textbox1}
                      />
                    </div>
                  </div>
                  {/* Document No. Ends */}

                  {/* Document Date */}
                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}
                  >
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Date:
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="CustomerExitDate"
                        value={
                          formdata1.CustomerExitDate
                            ? dayjs(formdata1.CustomerExitDate, "YYYY-MM-DD")
                            : null
                        }
                        onChange={(value) =>
                          handleDates("CustomerExitDate", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD-MM-YYYY"
                        sx={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "8px",
                          border: "1px solid #5B6BE1",
                          boxShadow: "none", // Corrected property name
                          marginLeft: "52px",
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* Document Date Ends */}

                  <div
                    style={{
                      display: "inline-flex",
                      marginLeft: "0px",
                      padding: "10px 0px",
                      alignItems: "center",
                      border: "1px",
                    }}
                  >
                    <label
                      htmlFor="upload2"
                      style={{ color: "#5B6BE1", fontWeight: "600" }}
                    >
                      Upload Document:
                    </label>
                    <input
                      type="file"
                      name="upload2"
                      id="upload2"
                      onInput={(e) => handlefileupload2("upload2", e)}
                      style={{
                        display: "none",
                      }}
                    />
                    <div
                      className={styles.textbox2}
                      style={{ position: "relative" }}
                      onClick={() => document.getElementById("upload2").click()}
                    >
                      <label
                        style={{
                          marginLeft: "15px",
                          marginTop: "5px",
                          color: "rgba(128, 128, 128, 0.8)",
                          overflow: "hidden", // Hide overflow text
                          textOverflow: "ellipsis", // Display ellipsis for overflow text
                          whiteSpace: "nowrap", // Prevent wrapping
                          maxWidth: "180px", // Set maximum width for the file name
                          alignItems: "center",
                        }}
                      >
                        {fileName2 ? fileName2 : "Upload File"}
                      </label>

                      <svg
                        width="25"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px", // Adjust as needed
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="grey"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="grey"
                        />
                      </svg>
                    </div>
                    {fileName2 && (
                      <svg
                        width="25"
                        onClick={() => handlecross("Customer_Exit_Bill_Doc")}
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        height="25"
                        viewBox="0 0 20 20"
                        fill="grey"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                          fill="grey"
                        />
                        <path
                          d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                          fill="grey"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                {/* Ergon Entry Bill */}
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <h2 className={styles.heading}>Ergon Entry Bill</h2>
                  {/* Document No */}
                  <div className={styles.formElement}>
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Number:
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        value={formdata1.ErgonBillNumber}
                        name="ErgonBillNumber"
                        onChange={handleOnChange}
                        className={styles.textbox1}
                      />
                    </div>
                  </div>
                  {/* Document No. Ends */}

                  {/* Document Date */}
                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}
                  >
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Document Date:
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="ErgonBillDate"
                        value={
                          formdata1.ErgonBillDate
                            ? dayjs(formdata1.ErgonBillDate, "YYYY-MM-DD")
                            : null
                        }
                        onChange={(value) =>
                          handleDates("ErgonBillDate", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD-MM-YYYY"
                        sx={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "8px",
                          border: "1px solid #5B6BE1",
                          boxShadow: "none", // Corrected property name
                          marginLeft: "52px",
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* Document Date Ends */}

                  <div
                    style={{
                      display: "inline-flex",
                      marginLeft: "0px",
                      padding: "10px 0px",
                      alignItems: "center",
                      border: "1px",
                    }}
                  >
                    <label
                      htmlFor="upload3"
                      style={{ color: "#5B6BE1", fontWeight: "600" }}
                    >
                      Upload Document:
                    </label>
                    <input
                      type="file"
                      name="upload3"
                      id="upload3"
                      onInput={(e) => handlefileupload3("upload3", e)}
                      style={{
                        display: "none",
                      }}
                    />
                    <div
                      className={styles.textbox2}
                      style={{ position: "relative" }}
                      onClick={() => document.getElementById("upload3").click()}
                    >
                      <label
                        style={{
                          marginLeft: "15px",
                          marginTop: "5px",
                          color: "rgba(128, 128, 128, 0.8)",
                          overflow: "hidden", // Hide overflow text
                          textOverflow: "ellipsis", // Display ellipsis for overflow text
                          whiteSpace: "nowrap", // Prevent wrapping
                          maxWidth: "180px", // Set maximum width for the file name
                          alignItems: "center",
                        }}
                      >
                        {fileName3 ? fileName3 : "Upload File"}
                      </label>

                      <svg
                        width="25"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px", // Adjust as needed
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="grey"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="grey"
                        />
                      </svg>
                    </div>{" "}
                    {fileName3 && (
                      <svg
                        width="25"
                        onClick={() => handlecross("Entry_Bill_Doc")}
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        height="25"
                        viewBox="0 0 20 20"
                        fill="grey"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                          fill="grey"
                        />
                        <path
                          d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                          fill="grey"
                        />
                      </svg>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "inline-flex",
                    marginLeft: "0px",
                    padding: "10px 0px",
                    alignItems: "center",
                    border: "1px",
                  }}
                >
                  <label
                    htmlFor="upload4"
                    style={{ color: "#5B6BE1", fontWeight: "600" }}
                  >
                    MOFA Attestation:
                  </label>
                  <input
                    type="file"
                    name="upload4"
                    id="upload4"
                    onInput={(e) => handlefileupload4("upload4", e)}
                    style={{
                      display: "none",
                    }}
                  />
                  <div
                    className={styles.textbox2}
                    style={{ position: "relative" }}
                    onClick={() => document.getElementById("upload4").click()}
                  >
                    <label
                      style={{
                        marginLeft: "15px",
                        marginTop: "5px",
                        color: "rgba(128, 128, 128, 0.8)",
                        overflow: "hidden", // Hide overflow text
                        textOverflow: "ellipsis", // Display ellipsis for overflow text
                        whiteSpace: "nowrap", // Prevent wrapping
                        maxWidth: "180px", // Set maximum width for the file name
                        alignItems: "center",
                      }}
                    >
                      <span>{fileName4 ? fileName4 : "Upload File"}</span>
                    </label>

                    <svg
                      width="25"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px", // Adjust as needed
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                        fill="grey"
                      />
                      <path
                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                        fill="grey"
                      />
                    </svg>
                  </div>{" "}
                  {fileName4 && (
                    <svg
                      width="25"
                      onClick={() => handlecross("MOPA_Attestation")}
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="grey"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                        fill="grey"
                      />
                      <path
                        d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                        fill="grey"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </form>
          </div>
        )
      ) : !isedit2 ? (
        <div className={styles.sections}>
          <div className={styles.editAndSaveButtons}>
          {featureincomingdeliveryitemedit.length !==0 && (
            <button
              variant="contained"
              onClick={handleEdit2}
              className={styles.saveButton}
            >
              Edit
            </button>
          )}
          </div>
          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>OEM Part Number</th>
                  <th>Manufacturer Part Number</th>
                  <th>UOM</th>
                  <th>Pending Qty</th>
                  <th>Incoming Qty</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems2.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.Item_Name}</td>
                    <td>{item.OEM_Part_Number}</td>
                    <td>{item.Manufacturer_Part_Number}</td>
                    <td>{item.UoM}</td>
                    <td>{differencesArray[index]}</td>
                    <td style={{ position: "relative" }}>
                      <input
                        type="number"
                        style={{
                          border: "1px solid #5B6BE1",
                          maxWidth: "55px",
                          outline: "none",
                          paddingLeft: "5px",
                          backgroundColor: " #D8DADC",
                        }}
                        disabled
                        value={incomingQuantities[index] || ""}
                        // onChange={(event) => handleQuantityChange(event, index)}
                      />
                      {errors[index] && (
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            fontSize: "13px",
                            position: "absolute",
                            left: 0,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          Incoming Qty can't exceed {sumState[index]}
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className={styles.sections}>
          <div className={styles.editAndSaveButtons}>
         
            <>
              <button
                variant="contained"
                onClick={handleSave2}
                className={`${styles.saveButton} ${
                  errors.some((error) => error) ? styles.disabledSaveButton : ""
                }`}
                disabled={errors.some((error) => error)} // Disable if any error is present
              >
                Save
              </button>

              <button
                variant="outlined"
                onClick={handleCancel}
                className={styles.cancelBtn}
              >
                Cancel
              </button>
            </>
       
          </div>
          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>OEM Part Number</th>
                  <th>Manufacturer Part Number</th>
                  <th>UOM</th>
                  <th>Pending Qty</th>
                  <th>Incoming Qty</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems2.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.Item_Name}</td>
                    <td>{item.OEM_Part_Number}</td>
                    <td>{item.Manufacturer_Part_Number}</td>
                    <td>{item.UoM}</td>
                    <td>{differencesArray[index]}</td>
                    <td style={{ position: "relative" }}>
                      <input
                        type="number"
                        style={{
                          border: "1px solid #5B6BE1",
                          maxWidth: "55px",
                          outline: "none",
                          paddingLeft: "5px",
                        }}
                        value={incomingQuantities[index] || ""}
                        onChange={(event) => handleQuantityChange(event, index)}
                        min="0" 
                      />
                      {errors[index] && (
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            fontSize: "13px",
                            position: "absolute",

                            left: 0,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                           Incoming Qty can't exceed {sumState[index]}
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseIncomingUpdate;
