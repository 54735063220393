import React, { useState, useEffect } from "react";
import styles from "../Components/css/toolbox_edit_table.module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Toolbox_edit_table() {
  const { selectedDate } = useParams();
  const decodedDate = decodeURIComponent(selectedDate);
  const [dbdetails, setdbdetails] = useState([]);
  const [showpreview, setshowpreview] = useState(false);
  const [previewedit, setpreviewedit] = useState(false);
  const [modal, setmodal] = useState(false);
  const [refresh, setrefresh] = useState(false);
  // const date = 11/10/2023;

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolbox_talk_get?seldate=${decodedDate}`
        );
        setdbdetails(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchRigDetails();
  }, [refresh]);

  const handlereview = (e, index) => {
    const {name, value} = e.target;
    let temp = [...dbdetails];
    temp[index][name] = value;
    setdbdetails(temp);
  }

  const handlemodal = () => {
    setshowpreview(true);
    setmodal(false);
    setpreviewedit(true);
  }

  const handlesubmit = async() => {
    if(dbdetails && dbdetails.length > 0){
      await Promise.all(
        dbdetails.map(async (el, index) => {
          const payload = {
            Date: el.Date,
            Time: el.Time,
            Conducted_by: el.Conducted_by,
            Supervisors: el.Supervisors,
            Attendees: el.Attendees,
            Task_description: el.Task_description,
            Project_so: el.Project_so,
            Team_assigned: el.Team_assigned,
            Additional_Tools: el.Additional_Tools,
            Target_units: el.Target_units,
            UOM: el.UOM,
            Achieved_Units: el.Achieved_Units,
            Reason: el.Reason,
            Reviewed: "Yes",
            Review_UOM: el.Review_UOM
          };

          try {
            const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolbox_talk_update?s_no=${el.S_no}`, payload);
              console.log(res);
          } catch (error) {
            console.log("Error:", error);
          }
        })
      )
      
    }
    setrefresh(!refresh);
    setmodal(false);
    setpreviewedit(false);
    
  }

  return (
    <div className={styles.maindiv}>
      {!showpreview && dbdetails[0]?.Reviewed !== "Yes" && (
        <div>
          <div className={styles.backsvg}>
          <NavLink  to="/admintaskbar"  style={{color:"white"}}><h5 style={{ color: "white", cursor: "pointer" ,color:"white"}}>Admin Dashboard</h5></NavLink>
            <svg
              width="15"
              height="15"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.705078 12.5254L10.7246 8.40918L0.705078 3.76562V0.25L15.251 7.51562V9.61035L0.705078 16.0264V12.5254Z"
                fill="black"
              />
            </svg>
            <NavLink to ="/Workshoplist" style={{color:"white"}} ><h5 style={{ color: "white", cursor: "pointer"  ,color:"white"}}>Toolbox Talk</h5></NavLink>
            <svg
              width="15"
              height="15"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.705078 12.5254L10.7246 8.40918L0.705078 3.76562V0.25L15.251 7.51562V9.61035L0.705078 16.0264V12.5254Z"
                fill="black"
              />
            </svg>
            <h5  style={{color:"white"}}>{dbdetails[0]?.Date}</h5>
          </div>
          <hr style={{borderTop: "2px solid #5B6BE1", height:"2px",backgroundColor:" black", marginTop:"0px"}}></hr>
          <div className={styles.datemaincont}>
           
           {/* <div className={styles.datemaincont3}>
             <label>Date : </label>
             <span style={{ marginLeft: "70px" }}>{dbdetails[0]?.Date}</span>
           </div> */}
           <div className={styles.datemaincont2}>
           <div className={styles.datemaincont3}>
             <label>Conducted by </label>
             <span style={{ marginLeft: "5px"}}>
             :{"  "}{dbdetails[0]?.Conducted_by}
             </span>
           </div>
         </div>
           <div className={styles.datemaincont3}>
             
             <label>Time</label>
             <span style={{ marginLeft: "5px" }}>: {"  "}{dbdetails[0]?.Time}</span>
           </div>
       </div>
       <div className={styles.supercont3}>
             <label>Supervisors</label>
             <span style={{ marginLeft: "32px" }}>
             :{"  "}{dbdetails[0]?.Supervisors}
             </span>
         </div>
       <div className={styles.attendcont3}>
         <label>Attendees </label>
         <span style={{ marginLeft: "45px" }}>
         :{"  "}{dbdetails[0]?.Attendees}
         </span>
       </div>
          <div className={styles.buttonsdiv}>
            <NavLink to={`/toolbox_edit/${encodeURIComponent(decodedDate)}`}>
              <button>
                Edit{" "}
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "-5px", marginLeft: "5px" }}
                >
                  <path
                    d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                    fill="white"
                  />
                </svg>
              </button>
            </NavLink>
            {/* <button>
              Download{" "}
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-3px", marginLeft: "5px" }}
              >
                <path
                  d="M13.493 1.66699H6.50964C3.4763 1.66699 1.66797 3.47533 1.66797 6.50866V13.4837C1.66797 16.5253 3.4763 18.3337 6.50964 18.3337H13.4846C16.518 18.3337 18.3263 16.5253 18.3263 13.492V6.50866C18.3346 3.47533 16.5263 1.66699 13.493 1.66699ZM7.05964 9.15033C7.3013 8.90866 7.7013 8.90866 7.94297 9.15033L9.3763 10.5837V5.42533C9.3763 5.08366 9.65964 4.80033 10.0013 4.80033C10.343 4.80033 10.6263 5.08366 10.6263 5.42533V10.5837L12.0596 9.15033C12.3013 8.90866 12.7013 8.90866 12.943 9.15033C13.1846 9.39199 13.1846 9.79199 12.943 10.0337L10.443 12.5337C10.3846 12.592 10.318 12.6337 10.243 12.667C10.168 12.7003 10.0846 12.717 10.0013 12.717C9.91797 12.717 9.84297 12.7003 9.75963 12.667C9.68464 12.6337 9.61797 12.592 9.55964 12.5337L7.05964 10.0337C6.81797 9.79199 6.81797 9.40033 7.05964 9.15033ZM15.2013 14.3503C13.5263 14.9087 11.768 15.192 10.0013 15.192C8.23464 15.192 6.4763 14.9087 4.8013 14.3503C4.4763 14.242 4.3013 13.8837 4.40964 13.5587C4.51797 13.2337 4.86797 13.0503 5.2013 13.167C8.3013 14.2003 11.7096 14.2003 14.8096 13.167C15.1346 13.0587 15.493 13.2337 15.6013 13.5587C15.7013 13.892 15.5263 14.242 15.2013 14.3503Z"
                  fill="white"
                />
              </svg>
            </button> */}
            <button onClick={() => setmodal(true)}>
              Proceed to Review{" "}
              <svg
                width="20"
                height="14"
                viewBox="0 0 22 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-1px", marginLeft: "5px"}}
              >
                <path
                  d="M11 13C16.523 13 21 7 21 7C21 7 16.523 1 11 1C5.477 1 1 7 1 7C1 7 5.477 13 11 13Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 9.5C11.663 9.5 12.2989 9.23661 12.7678 8.76777C13.2366 8.29893 13.5 7.66304 13.5 7C13.5 6.33696 13.2366 5.70107 12.7678 5.23223C12.2989 4.76339 11.663 4.5 11 4.5C10.337 4.5 9.70107 4.76339 9.23223 5.23223C8.76339 5.70107 8.5 6.33696 8.5 7C8.5 7.66304 8.76339 8.29893 9.23223 8.76777C9.70107 9.23661 10.337 9.5 11 9.5Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className={styles.rigtablecontainer}>
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Task Description</th>
                  <th>Project</th>
                  <th>Team Assigned</th>
                  <th>Target units</th>
                  <th>Additional tools requested</th>
                </tr>
              </thead>
              <tbody>
                {dbdetails &&
                  dbdetails.map((el, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{el.Task_description ? el.Task_description : "-"}</td>
                      <td>{el.Project_so ? el.Project_so : "-"}</td>
                      <td>{el.Team_assigned ? el.Team_assigned : "-"}</td>
                      <td>{el.Target_units ? el.Target_units : "-"}{" "}{el.UOM ? el.UOM : ""}</td>
                      <td>{el.Additional_Tools ? el.Additional_Tools : "-"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {(showpreview || dbdetails[0]?.Reviewed === "Yes") && (
        <div>
          <div className={styles.backsvg}>
          <NavLink  to="/admintaskbar"  style={{color:"white"}}><h5 style={{ color: "white", cursor: "pointer" }}>Admin Dashboard</h5></NavLink>
            <svg
              width="15"
              height="15"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.705078 12.5254L10.7246 8.40918L0.705078 3.76562V0.25L15.251 7.51562V9.61035L0.705078 16.0264V12.5254Z"
                fill="black"
              />
            </svg>
            <NavLink to ="/Workshoplist"  style={{color:"white"}}><h5 style={{ color: "white", cursor: "pointer" }}>Toolbox Talk</h5></NavLink>
            <svg
              width="15"
              height="15"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.705078 12.5254L10.7246 8.40918L0.705078 3.76562V0.25L15.251 7.51562V9.61035L0.705078 16.0264V12.5254Z"
                fill="black"
              />
            </svg>
            <h5  style={{color:"white"}}>{dbdetails[0]?.Date}</h5>
          </div>
          <hr style={{borderTop: "2px solid #5B6BE1", height:"2px",backgroundColor:" black", marginTop:"20px"}}></hr>
          <div className={styles.datemaincont}>
           
              {/* <div className={styles.datemaincont3}>
                <label>Date : </label>
                <span style={{ marginLeft: "70px" }}>{dbdetails[0]?.Date}</span>
              </div> */}
              
              <div className={styles.datemaincont2}>
              <div className={styles.datemaincont3}>
                <label>Time: </label>
                <span style={{ marginLeft: "75px" }}>{dbdetails[0]?.Time}</span>
              </div>
            </div>
              <div className={styles.datemaincont3}>
                <label>Conducted by : </label>
                <span style={{ marginLeft: "0px"}}>
                  {dbdetails[0]?.Conducted_by}
                </span>
              </div>
          </div>
          <div className={styles.supercont3}>
                <label>Supervisors: </label>
                <span style={{ marginLeft: "32px" }}>
                  {dbdetails[0]?.Supervisors}
                </span>
            </div>
          <div className={styles.attendcont3}>
            <label>Attendees: </label>
            <span style={{ marginLeft: "48px" }}>
              {dbdetails[0]?.Attendees}
            </span>
          </div>
          <div className={styles.buttonsdiv}>
            {!previewedit  &&(
              <button
                style={{ width: "130px" }}
                onClick={() => setpreviewedit(true)}
              >
                Edit{" "}
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "-5px", marginLeft: "8px" }}
                >
                  <path
                    d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                    fill="white"
                  />
                </svg>
              </button>
            )}
            {previewedit && (
              <>
                <button style={{ width: "130px" }} onClick={handlesubmit}> Save </button>
                <button
                  style={{ width: "130px", background: "white", color: "blue" }}
                  onClick={() => setpreviewedit(false)}
                >
                  {" "}
                  Cancel{" "}
                </button>
              </>
            )}
          </div>
          <div className={styles.rigtablecontainer}>
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Task Description</th>
                  <th>Project</th>
                  <th>Team Assigned</th>
                  <th>Target units</th>
                  <th>Achieved Units</th>
                  <th>Reason for Shortfall</th>
                  <th>Additional tools requested</th>
                </tr>
              </thead>
              <tbody>
                {dbdetails &&
                  dbdetails.map((el, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{el.Task_description ? el.Task_description : "-"}</td>
                      <td>{el.Project_so ? el.Project_so : "-"}</td>
                      <td>{el.Team_assigned ? el.Team_assigned : "-"}</td>
                      <td>{el.Target_units ? el.Target_units : "-"}{" "}{el.UOM ? el.UOM : ""}</td>
                      {previewedit ? (
                        <td>
                              <input
                                type='number'
                                className={styles.Achieved_Units}
                                name='Achieved_Units'
                                value={el.Achieved_Units}
                                onChange={(e) => handlereview(e, index)}
                              />
                            <select 
                                name='Review_UOM'
                                className={styles.Achieved_Units3}
                                value={el.Review_UOM}
                                onChange={(e) => handlereview(e, index)}
                            >
                                <option value="">Select</option>
                                <option value="Nos">Nos</option>
                                <option value="%">%</option>
                                <option value="Sets">Sets</option>
                                <option value="Boxes">Boxes</option>
                            </select>
                        </td>
                      ) : (
                        <td>{el.Achieved_Units ? el.Achieved_Units : "-"}{" "}{el.Review_UOM ? el.Review_UOM : ""}</td>
                      )}
                      {previewedit ? (
                        <td>
                            <input
                              type='text'
                              className={styles.Achieved_Units2}
                              name='Reason'
                              value={el.Reason}
                              onChange={(e) => handlereview(e, index)}
                            />
                        </td>
                      ) : (
                        <td>{el.Reason ? el.Reason : "-"}</td>
                      )}
                      <td>{el.Additional_Tools ? el.Additional_Tools : "-"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {modal && (
        <div  className={styles.overlay}>
          <div  className={styles.modal}>
            <div className={styles.modalhead}>
              <h6>Once saved, they cannot be modified. Are you sure you want to proceed?</h6>
            </div>
            <div className={styles.modalbutton}>
                <button style={{ width: "25%" }} onClick={handlemodal}> Ok </button>
                <button
                  style={{ width: "25%", background: "white", color: "blue" }}
                  onClick={() => setmodal(false)}
                >
                  {" "}
                  Cancel{" "}
                </button>
            </div>
          </div>
      </div>
      )}
    </div>
  );
}
export default Toolbox_edit_table;
