import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import styles from "../Components/css/workplan.module.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Workplan(props) {
  const [formData, setFormData] = useState({
    client: "",
    Rig_Name: "",
    Sales_Order_No: "",
    Rig_Location: "",
    ProjectName: "",
    scopeofwork: "",
    TeamMembers: "",
    MobilisationDetails: "",
    ProjectDuration: "",
    JobDetails: "",
    EntryPermit: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [projectList, setProjectList] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchProject = async () => {

      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      console.log("@@@@@@@@@@@@2", props.ID);
      console.log("#############", res.data.data);
      console.log(filter);

      setProjectList(filter);
    };

    fetchProject();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call your API to post the form data
    // Once the API call is successful, proceed to generate the PDF
    generatePDF(formData);
  };

  const generatePDF = (formData) => {
    const input = document.getElementById("workplan");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        pdf.save("workplan.pdf");
      }
    );
  };

  return (
    <div className={styles.workplancontainer}>
      <div className={styles.formContainer}>
        <form id="workplan">
          <table>
            <thead>
              <tr>
                <th colSpan="2">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <div>Work Plan</div>
                    <div>
                      <svg
                        onClick={props.closeOnClick}
                        style={{ cursor: "pointer" }}
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.9499 22.3641L9.63615 11.0504C9.2496 10.6638 9.2496 10.0227 9.63615 9.63618C10.0227 9.24963 10.6638 9.24963 11.0504 9.63618L22.3641 20.9499C22.7506 21.3364 22.7506 21.9776 22.3641 22.3641C21.9775 22.7507 21.3364 22.7507 20.9499 22.3641Z"
                          fill="black"
                        />
                        <path
                          d="M9.63592 22.3641C9.24937 21.9776 9.24937 21.3364 9.63592 20.9499L20.9496 9.63618C21.3362 9.24963 21.9773 9.24963 22.3638 9.63618C22.7504 10.0227 22.7504 10.6638 22.3638 11.0504L11.0501 22.3641C10.6636 22.7507 10.0225 22.7507 9.63592 22.3641Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={styles.darkHeading} scope="row">
                  Head
                </th>
                <td className={styles.darkHeading}>Description</td>
              </tr>
              <tr>
                <th scope="row">Client</th>
                <td>{projectList[0]?.Customer_Name}</td>
              </tr>
              <tr>
                <th scope="row">Rig Name</th>
                <td>{projectList[0]?.Rig_Name} </td>
              </tr>
              <tr>
                <th scope="row">Sales Order No</th>
                <td>{projectList[0]?.Sales_Order_No}</td>
              </tr>
              <tr>
                <th scope="row">Rig Location</th>
                <td> {projectList[0]?.Rig_Location}</td>
              </tr>
              <tr>
                <th scope="row">Project Name</th>
                <td>{projectList[0]?.Service_Component}</td>
              </tr>
              <tr>
                <th scope="row">Scope of Work</th>
                <td>{projectList[0]?.Service_Component}</td>
              </tr>
              <tr>
                <th scope="row">Team Members</th>
                <td>{projectList[0]?.Supervisor_Names}</td>
              </tr>
              <tr>
                <th scope="row">Mobilisation Details</th>
                <td>{projectList[0]?.Technician_Names}</td>
              </tr>
              <tr>
                <th scope="row">Project Duration</th>
                <td>Estimated {projectList[0]?.Po_Days}days</td>
              </tr>
              <tr>
                <th scope="row">Job Details</th>
                <td>The scope is,{projectList[0]?.Service_Component}</td>
              </tr>
              <tr>
                <th scope="row">Final Deliverable</th>
                <td>
                  1. Daily Report & Final Report
                  <br />
                  2. Status Sheet
                  <br />
                  3. Feedback form
                </td>
              </tr>
              <tr>
                <th scope="row">Tools & Consumables</th>
                <td>Available at rig</td>
              </tr>
              <tr>
                <th scope="row">Reporting</th>
                <td>
                  1. Daily Report to be sent to central team every evening
                  <br />
                  2. Final report to be prepared .<br />
                  3. Parts list to be made.
                </td>
              </tr>
              <tr>
                <th scope="row">Contact Point</th>
                <td>
                  1st Contact: Operation Team (projects@ergontec.com,
                  +971527256908) <br />
                  2nd Contact: Technical Team (operation@ergontec.com,
                  +971521988815)
                  <br /> 3rd Contact: Mathew (mbm@ergontec.com, +971585039099)
                </td>
              </tr>
              <tr>
                <th scope="row">Essential Documents Checklist</th>
                <td>
                  1. Passport
                  <br />
                  2. BOSIET
                  <br />
                  3. Medical (Health) Certificate
                  <br />
                  4. National identity Card (or Driving License)
                  <br />
                  5. Valid PCR (Covid) Test Report
                  <br />
                  6. Entry Permit for {projectList[0]?.Rig_Location}
                </td>
              </tr>
              <tr>
                <th scope="row">Points to Note</th>
                <td>
                  1. Please ensure that the Ergon Reporting formats are followed
                  for the task.
                  <br />
                  2. Please ensure that all mandatory HSE documents are filled
                  in correctly and returned after the completion of the job
                  <br />
                  3. Please ensure that daily progress reports are sent into the
                  central team on a regular basis
                  <br />
                  4. Please ensure that all DPR’s and timesheets are signed and
                  stamped by the client on completion of the task.
                  <br />
                  5. In case of any variation in the Scope of Work defined
                  above, please inform the central team.
                </td>
              </tr>
            </tbody>
          </table>

          <div style={{ backgroundColor: "white" }}>
            <h6>Prepared By</h6>
            <h6>Signed By</h6>

            <h6>Shubham Mehta</h6>

            <div>TSD-OPS-FOR-009-19, Rev.01</div>
            <div>30 April 2022</div>

            <div>6</div>
          </div>

          <div className={styles.btnContainerFixed}>
            <NavLink
              className={styles.opennewtabbtn}
              to={"/workplan/" + projectList[0]?.ID_No}
              target="_blank"
              rel="noopener noreferrer">
              Open in New Tab
              <OpenInNewIcon />
            </NavLink>

            <Button
              variant="contained"
              type="submit"
              className={styles.Downloadpdfbtn}
              onClick={handleSubmit}>
              Download Pdf
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Workplan;
