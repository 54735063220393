import React from 'react'
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import styles from "../Components/css/projectreport.module.css";
import { useParams, NavLink } from "react-router-dom";
import Workplan from "./Workplan";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Dosplan from "./Dosplan";
import { red } from "@mui/material/colors";
import excel from "./css/image/excel.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Yourcomponentbutton from "./Sparesmobilization.js";
// date picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { Switch } from "@mui/material";
import Sparesmobilization from "./Sparesmobilization";
import Personal from "./Personal_mobilization";
import Timesheet from "./Timesheet";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import { TextField, InputAdornment } from "@mui/material";
import Select from "react-select";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Apidocs  from "../Components/apidocs.js";
import Fielddocs from "../Components/Fielddocs"
//updated code

import Consumables from "./Consumables";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const getSteps = () => {
  return [
    "Pre-Project Kickoff",
    "Pre-Mobilization",
    "Field reporting",
    "Post-Demobilization",
  ];
};

function Attendancefile(props) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1;
    const {id, SuperArray, TechArray, TrainArray, projectList} = props;
    const [refresh, setRefresh] = useState(true);
    const [markedDates, setMarkedDates] = useState([]);
    const [pause, setPause] = useState(false);
    const [personalMobilisation, setPersonalMobilisation] = useState(1);
    const [mobilisationTabs, setMobilisationTabs] = useState(false);
    const [getCustomerDocs, setGetCustomerDocs] = useState([]);
    const [getTech, setGetTech] = useState([]);
    const [oldcommence, setoldcommence] = useState("");
    const [helpcommence, sethelpcommence] = useState("");
    const [timestamp, settimestamp] = useState("");
    const [validateMessage, setValidateMessage] = useState(""); 
    const presentdate = new Date();
    const [addSupervisor, setAddSupervisor] = useState([""]);
    const [addTechnician, setAddTechnician] = useState([""]);
    const [uploadticketfilename, setuploadticketfilename] = useState("");
    const [uploadbookingfilename, setuploadbookingfilename] = useState("");
    const [returnuploadticketfilename, setreturnuploadticketfilename] =
      useState("");
    const [returnuploadbookingfilename, setreturnuploadbookingfilename] =
      useState("");
      const [featurechecklist,setfeaturechecklist]= useState([]);
    const [featureprojectdetails,setfeatureprojectdetails]= useState([]);
    const [featureteamdetails,setfeatureteamdetails]= useState([]);
    const [featuretooldetails,setfeaturetooldetails]= useState([]);
    const [featureapidocs,setfeatureapidocs]= useState([]);
    const [featurefielddocs,setfeaturefielddocs]= useState([]);
    const [featuresuperchecklist,setfeaturesuperchecklist]= useState([]);
    const [featureattendence,setfeatureattendence]= useState([]);
    const [featurepersonal,setfeaturepersonal]= useState([]);
    const [featurechecklistedit,setfeaturechecklistedit]= useState([]);
    const [featureteamdetailsedit,setfeatureteamdetailsedit]= useState([]);
    const [featuresuperchecklistedit,setfeaturesuperchecklistedit]= useState([]);
    const [featuremark,setfeaturemark]= useState([]);
    const [featurerequest,setfeaturerequest]= useState([]);
    const [featureuploadts,setfeatureuploadts]= useState([]);
    const [featuredownts,setfeaturedownts]= useState([]);
    const [featureviewts,setfeatureviewts]= useState([]);
    const [featureuploadagain,setfeatureuploadagain]= useState([]);
    const [featuredaily,setfeaturedaily]= useState([]);
    const [featurets,setfeaturets]= useState([]);
    const[featuresp,setfeaturesp]= useState([]);
    const[featurecon,setfeaturecon]= useState([]);
    const[featurectd,setfeaturectd]= useState([]);
    const[featurectdedit,setfeaturectdedit]= useState([]);
    const [featureapidocsedit,setfeatureapidocsedit]= useState([]);
    const [featureprojectdetailsbtn,setfeatureprojectdetailsbtn]= useState([]);
    const [checkDate, setCheckDate] = useState(false);
  const [checkAttendance, setCheckAttendance] = useState([]);
      const [uploadticketURL, setUploadticketURL] = useState(null);
      const [uploadbookingURL, setUploadbookingURL] = useState(null);
      const [returnuploadticketURL, setreturnUploadticketURL] = useState(null);
      const [returnuploadbookingURL, setreturnsetUploadbookingURL] = useState(null);  
      const [traineeNamesList, setTraineeNamesList] = useState([]);
    const [Trainee, setTraineeData] = useState({});
    const [addTrainee, setAddTrainee] = useState([""]);
   
    // const [SuperArray, setSuperArray] = useState([]);
 const [openMarkAttendanceModal, setOpenMarkAttendanceModal] = useState(false);
 const [openEditRequestModal, setOpenEditRequestModal] = useState(false);

 const [markForAll, setMarkForAll] = useState(false);
 //  const [personnelNames, setPersonnelNames] = useState([]);

 const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString().padStart(2, '0'));
 const [selectedYear, setSelectedYear] = useState(currentYear);
 const selectedMonthInt = parseInt(selectedMonth);
 const [changeToOption, setChangeToOption] = useState("");
 const [checkAttendance1, setCheckAttendance1] = useState([]);
 const [tableData, setTableData] = useState([]);
 const [offshoretableData, setOffshoretableData] = useState([]);
 function formatDate() {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
 console.log("currentMonth",selectedMonth)
  const today = formatDate();
 
  const [attendanceData, setAttendanceData] = useState({
    selectedDate: "",
    attendance: "",
    previousAttendance: "",
    reqStatus: "",
    reason: "",
    remarks: "",
  });
  const personalMobilsationTab = (index) => {
    setPersonalMobilisation(index);
  };

  const handleMonthChange = (event) => {
    const selectedMonthValue = event.target.value;
    console.log("Selected Month Value:", selectedMonthValue);
 
    const selectedMonthInt = selectedMonthValue;
    console.log("Parsed Month Value:", selectedMonthInt);
 
    setSelectedMonth(selectedMonthValue);
    
  };
 
  const handleYearChange = (event) => {
   const selectYearValue = event.target.value;
   console.log("Selected Year Value:", selectYearValue);
 
   const selectedYearInt = selectYearValue;
   console.log("Parsed Year Value:", selectedYearInt);
 
   setSelectedYear(selectYearValue);
 };
 
  const CustomModal = styled(Modal)`
    .MuiBackdrop-root {
      background-color: rgba(255, 255, 255, 0.1);
    }
  `;
 
  const handleDateChange = async (event) => {
   const selectedDate = event.target.value;
   if(markedDates.includes(selectedDate)){
     alert("Attendance for this date already exists.");
     event.target.value = "";
   } else {
   setAttendanceData((prevData) => ({
     ...prevData,
     selectedDate: event.target.value,
   }));
   }
 };
 
 useEffect(() => {
   getAttendanceData();
 }, []); 
 
 
 const handleSubmit = async () => {
   //  const formattedDate = formatDate();
 //  const completedate = formattedDate.split("/");
 //  const day = completedate[0];
 //  const month = completedate[1];
 //  const year = completedate[2];
   const { attendance, reqStatus, remarks, selectedDate } = attendanceData;
   const [year, month, day] = selectedDate.split("-");
   const allTeamMembers = [
     ...projectList?.Supervisor_Names?.split(",") || [],
     ...projectList?.Technician_Names?.split(",") || [],
     ...projectList?.Trainee_Names?.split(",") || [],
   ].filter(removeNull=>removeNull);
   console.log("Selected Date:", selectedDate);
   if (!selectedDate) {
     alert("Please select a date before submitting.");
     return;
   }
   if (attendance === "Standby" && !remarks) {
     alert("Please provide a reason for Standby.");
     return;
   }
 
   if (!markForAll) {
     let designation;
     const check = projectList?.Technician_Names.split(",").includes(getTech);
     if (check) {
       designation = "";
     } else {
       designation = "Supervisor";
     }
 
     const payload = {
       ID_No: id,
       Tech: getTech,
       Designation: designation,
       Day: day,
       Month: month,
       Year: year,
       Status: attendance,
       Previous_Attendance: attendance,
       Remarks: remarks,
       Requested_Status: reqStatus,
     };
 
     try{
       const checkExistingData = await axios.post(
         "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/checkDailyAttendance",
         { ID_No: id, Tech: getTech, Day: day, Month: month, Year: year }
       );
   
       const existingData = checkExistingData.data.data;
   
       if (existingData.length > 0) {
         alert("Entered data already exists.");
         return;
       }
     } catch(err) {
       console.log("Error checking existing data:", err);
     }
 
     try {
       const res = await axios.post(
         "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/daily_attendancePost",
         payload
       );
       if (res.status === 200) {
         alert("data added successfully");
       }
     } catch (err) {
       console.log(err);
     }
   } else {
     console.log("Mark for all checked");
     try {
       await Promise.all(
         allTeamMembers.map(async (el) => {
           const checkMarkedForToday = await checkmarkedAttenance(el);
           const isSupervisor =
           projectList?.Supervisor_Names?.split(",").includes(el);
           const isTechnicianOrTrainee =
           projectList?.Technician_Names?.split(",").includes(el) ||
           projectList?.Trainee_Names?.split(",").includes(el);
 
           if (!checkMarkedForToday && (isSupervisor || isTechnicianOrTrainee)) {
             let designation;
             if (projectList?.Supervisor_Names?.split(",").includes(el)) {
               designation = "Supervisor";
             } else {
               designation = "Technician";
             }
     
             const payload = {
               ID_No: id,
               Tech: el,
               Designation: designation,
               Day: day,
               Month: month,
               Year: year,
               Status: attendance,
               Previous_Attendance: attendance,
               Remarks: remarks,
               Requested_Status: reqStatus,
             };
     
             console.log(payload);
     
             // Check if data already exists for this Technician or Trainee
             const checkExistingData = await axios.post(
               "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/checkDailyAttendance",
               {
                 ID_No: id,
                 Tech: el,
                 Day: day,
                 Month: month,
                 Year: year,
               }
             );
     
             const existingData = checkExistingData.data.data;
     
             if (existingData.length === 0) {
               await axios.post(
                 "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/daily_attendancePost",
                 payload
               );
             }
           }
         })
       );
 
       alert("multiple data added");
     } catch (err) {
       console.log(err);
     }
   }
 
   setRefresh(!refresh);
   handleAttendanceCancel();
 };
 
  const checkmarkedAttenance = async (personName) => {
   const { attendance, reqStatus, remarks, selectedDate } = attendanceData;
   const [year, month, day] = selectedDate.split("-");
   const allTeamMembers = [
     ...projectList?.Supervisor_Names?.split(",") || [],
     ...projectList?.Technician_Names?.split(",") || [],
     ...projectList?.Trainee_Names?.split(",") || [],
   ];
 
    try {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/daily_attendance"
      );
      const checkMarked = res.data.data.filter(
        (el) => el.ID_No == id && el.Tech === personName
      );
 
      const isMarkedForToday = checkMarked.some(
        (el) => day === el.Day && month === el.Month && year === el.Year
      );
 
      return isMarkedForToday;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
 
  const editAttendance = async () => {
    const { attendance, reqStatus, reason, selectedDate, remarks } =
      attendanceData;
 
    let designation;
    const check = projectList?.Technician_Names.split(",").includes(getTech);
    if (check) {
      designation = "";
    } else {
      designation = "Supervisor";
    }
 
    const formattedDate = selectedDate.split("-");
    const day = formattedDate[2];
    const month = formattedDate[1];
    const year = formattedDate[0];
 
    const prevAttend = checkAttendance[0]?.Status;
 
    const payload = {
      ID_No: id,
      Tech: getTech,
      Designation: designation,
      Day: day,
      Month: month,
      Year: year,
      Status: attendance,
      Previous_Attendance: prevAttend,
      Remarks: remarks,
      Requested_Status: "Request Sent",
      Reason: reason,
    };
    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/daily_attendanceUpdation/${checkAttendance[0].S_No}`,
        payload
      );
 
      console.log(res.config.data);
      if (res.status === 200) {
        alert("attendance uploaded successfully");
        setRefresh(!refresh);
      }
    } catch (err) {
      console.log(err);
    }
    handleAttendanceCancel();
  };
 
  const handleAttendanceCancel = () => {
    setOpenMarkAttendanceModal(false);
    setOpenEditRequestModal(false);
    setAttendanceData({
      selectedDate: "",
      attendance: "",
      reqStatus: "",
      reason: "",
    });
  };
 
  const handleOpenMarkAttendanceModal = () => {
    setOpenMarkAttendanceModal(true);
    setMarkForAll(false);
  };
 
  const handleOpenEditRequestModal = () => {
    setOpenEditRequestModal(true);
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details`);
        const { SuperArray, TechArray, TrainArray } = res.data;
        const firstPerson = SuperArray[0] || TechArray[0] || TrainArray[0];
        if (firstPerson) {
            setGetTech(firstPerson);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);
  useEffect(() => {
   // Use the useEffect hook to handle side effects when relevant state changes
   if (attendanceData.selectedDate && checkAttendance1.length > 0) {
     setEmessage(true);
     setValidateMessage(
       <>
         This date cannot be edited as timesheet 
         <br />
         for this month is already uploaded.
         <br />
         Please get in touch with the finance team
         <br />
         for changes required. 
       </>
     );
   } else {
     setEmessage(false);
     setValidateMessage("");
   }
 }, [attendanceData.selectedDate, checkAttendance1]);
 
  const handleAttendanceOptionChange = (e) => {
   let { name, value } = e.target;
 
   console.log(name, value);
   if (name === "attendance" && value !== "Standby") {
     setAttendanceData((prevData) => ({
       ...prevData,
       attendance: value,
       remarks: "",
     }));
   } else {
     setAttendanceData((prevData) => ({
       ...prevData,
       [name]: value,
     }));
   }
 };
 
  const handleCheckbox = (event) => {
    setMarkForAll(event.target.checked);
  };
  const compareDates = (a, b) => {
   const dateA = new Date(`${a.Year}-${a.Month}-${a.Day}`);
   const dateB = new Date(`${b.Year}-${b.Month}-${b.Day}`);
   return dateB - dateA;
 };
  const getAttendanceData = async () => {
    try {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/daily_attendance"
      );
 
      // const filter = res.data.data.filter(
      //   (el) =>
      //     el.ID_No == id && el.Tech === getTech && el.Month === selectedMonth && el.Year === selectedYear
      // );
      const filter = res.data.data.filter((el) => {
        return (
          el.ID_No.toString() === id.toString() &&
          el.Tech === getTech &&
          el.Month.toString() === selectedMonth.toString() &&
          el.Year.toString() === selectedYear.toString()
        );
      });
      
      // filter.map((el, index) => {
      //   setAttendanceData({
      //     selectedDate: `${filter[index].Day}/${filter[index].Month}/${filter[index].Year}`,
      //     attendance: filter[index].Status,
      //     remarks: filter[index].Remarks,
      //     reqStatus: filter[index].Requsted_Status,
      //     reason: filter[index].Reason,
      //   });
      // });
      console.log("filterrrrrrrrrrrrrrrr",res.data.data)
      console.log("filterrrrrrrrrrrrrrrr",filter)
      console.log("ajasjhfdgashdhd",selectedMonth)
      console.log("64894984884848",selectedYear)
      const sortedData = filter.sort(compareDates);
       setTableData(sortedData);
      //   const getNames = TechArray.concat(SuperArray);
      //  setPersonnelNames(getNames);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 
 
  const getOffshoreAttendanceData = async () => {
   try {
     const res = await axios.get(
       "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/attendance_offshore"
     );
 
     const filter = res.data.data.filter(
       (el) =>
         el.ID_No == id &&
         el.Month === selectedMonth &&
         el.Year === selectedYear
     );
     // filter.map((el, index) => {
     //   setAttendanceData({
     //     selectedDate: `${filter[index].Day}/${filter[index].Month}/${filter[index].Year}`,
     //     attendance: filter[index].Status,
     //     remarks: filter[index].Remarks,
     //     reqStatus: filter[index].Requsted_Status,
     //     reason: filter[index].Reason,
     //   });
     // });
 
   //  const sorted1Data = filter.sort(compareDates);
     setOffshoretableData(filter);
     //   const getNames = TechArray.concat(SuperArray);
     //  setPersonnelNames(getNames);
   } catch (error) {
     console.error("Error fetching data:", error);
   }
 };
 
  useEffect(() => {
   const datesWithAttendance = tableData.map(
     (el) => `${el.Year}-${el.Month}-${el.Day}`
   );
   setMarkedDates(datesWithAttendance);
 }, [tableData]);
 

 
  const dateChecker = () => {
    let date = [];
    tableData.map((el) => {
      date.push(`${el.Day}/${el.Month}/${el.Year}`);
    });
 
    const convertedToday = formatDate();
 
    const checkDates = date.includes(convertedToday);
    setCheckDate(checkDates);
  };
 
  const attendaceStatus = () => {
    const checkAtt = attendanceData.selectedDate.split("-");
    const convertAtt = `${checkAtt[2]}/${checkAtt[1]}/${checkAtt[0]}`;
 
    const filterAtt = tableData.filter(
      (el, index) => `${el.Day}/${el.Month}/${el.Year}` === convertAtt
    );
    console.log(filterAtt);
    setCheckAttendance(filterAtt);
  };
 
  const attendaceStatus1 = () => {
   const checkAtt = attendanceData.selectedDate.split("-");
   const month = checkAtt[1];
   const year = checkAtt[0];
 
   const filterAtt = offshoretableData.filter(
     (el, index) => el.Month === month && el.Year ===year
   ); 
   const filtertime = filterAtt.filter(
     (el,index) => el.Timesheet !== null && el.Timesheet !== undefined
   );
 
   console.log(filtertime);
   setCheckAttendance1(filtertime);
 };

 useEffect(() => {
  getAttendanceData();
  getOffshoreAttendanceData();
}, [selectedMonth, selectedYear, getTech]);

useEffect(() => {
  getAttendanceData();
  getOffshoreAttendanceData();
}, []);
 
  useEffect(() => {
    getAttendanceData();
  }, [getTech, refresh, selectedMonth,selectedYear,currentMonth,currentYear]);
 
  useEffect(() => {
   getOffshoreAttendanceData();
 }, [getTech, refresh, selectedMonth, selectedYear,currentMonth,currentYear]);
 
  useEffect(() => {
    dateChecker();
  }, [tableData]);
 
  useEffect(() => {
    attendaceStatus();
  }, [attendanceData.selectedDate, openEditRequestModal]);
 

  
  useEffect(() => {
   attendaceStatus1();
 }, [attendanceData.selectedDate, openEditRequestModal]);
 
    const [docs, setDocs] = useState({
      passport_Doc: "",
      passport_Expiry: "",
      insurance_Doc: "",
      insurance_Expiry: "",
      bosiet_Doc: "",
      bosiet_Expiry: "",
    });
  
    const [forwarddocs, setForwarddocs] = useState({
      boarding_Airport: "",
      boarding_Date: "",
      arrival_Airport: "",
      arrival_Date: "",
      upload_Tickets: "",
      hotel_Name: "",
      booking_Dates_checkin: "",
      booking_Dates_checkout: "",
      upload_Bookings: "",
      return_boarding_airport: "",
      return_boarding_date: "",
      return_arrival_airport: "",
      return_arrival_date: "",
      return_upload_ticket: "",
      return_hotel_name: "",
      return_booking_checkin: "",
      return_booking_checkout: "",
      return_upload_booking: "",
    });
  
    const [SONO, setSONO] = useState(1);
    const[Emessage, setEmessage] = useState(true);
    const [profdetails, setprofdetails] = useState([]);
    const [customerArray, setCustomerArray] = useState([]);
    const [postsuper, setpostsuper] = useState([]);
    const [posttech, setposttech] = useState([]);
    const [Supervisor, setSupervisorData] = useState({});
    const [workplan, setworkplan] = useState("");
    const [workplanfullpath, setworkplanfullpath] = useState("");
    const [workplanshortname, setworkplanshortname] = useState("");
    // const [addSupervisor, setAddSupervisor] = useState(["addSupervisor"]);
    const [Technician, setTechnicianData] = useState({});
    // const [addTechnician, setAddTechnician] = useState(["addTechnician"]);
    useEffect(() => {

        const fetchData = async () => {
          try {
            const res = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details");
            const filtered = res.data.filter((el) => el.Email === localStorage.getItem("useremail"));
            setprofdetails(filtered);
            console.log(filtered);
            console.log(profdetails);
      
            // if (localStorage.getItem("Group") === "Supervisor" || 
            //     localStorage.getItem("Group") === "Technician" || 
            //     localStorage.getItem("Group") === "Trainee") {
                    // setGetTech(filtered[0]?.Customer_Name);
            // } else {
              if (SuperArray && SuperArray.length > 0 && SuperArray[0] !== "") {
                setGetTech(SuperArray[0]);
              } else if (TechArray && TechArray.length > 0 && TechArray[0] !== "") {
                setGetTech(TechArray[0]);
              } else if (TrainArray && TrainArray.length > 0 && TrainArray[0] !== "") {
                setGetTech(TrainArray[0]);
              }
            // }
          
            
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
      
        fetchData();
      }, []);
      console.log(SuperArray)
      console.log(TechArray)
      console.log(TrainArray)
  return (
    <div>
     <Box
          sx={{
            padding: "20px",
            bgcolor: "#FFFFFF",
            height: "auto",
            borderRight: "1px solid #E7EBFF",
            borderLeft: "1px solid #E7EBFF",
            borderBottom: "1px solid #E7EBFF",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <div
              className={
                personalMobilisation === 1
                  ? styles.activePersonalMobilisationTab
                  : styles.personalMobilisationTab
              }
              onClick={() => personalMobilsationTab(1)}>
              Daily Attendance
            </div>
            <div
              className={
                personalMobilisation === 2
                  ? styles.activePersonalMobilisationTab
                  : styles.personalMobilisationTab
              }
              onClick={() => personalMobilsationTab(2)}>
              Timesheet
            </div>
          </div>
          <div
            className={
              personalMobilisation === 1
                ? styles.activeTabPersonalMobilisation
                : styles.TabPersonalMobilisation
            }>
            <div
              style={{
                padding: "25px 0",
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}>
              <div>
                <h6 style={{ fontWeight: "bold" }}>Choose Team Member</h6>
              </div>
              {SuperArray?.map((elm, index) => (
                elm !== "" && (<div className={styles.checboxInput}>
                  <input
                    key={index}
                    type="radio"
                    name="CustomerName"
                    value={elm}
                    checked={getTech === elm}
                    onChange={() => {
                      if (getTech !== elm) {
                        setGetTech(elm);
                      }
                    }}
                  />
                  <label style={{ fontSize: "16px", fontWeight: 500 }}>
                    {elm}
                    (S)
                  </label>
                </div>)
              ))}
              {TechArray?.map((elm, index) => (
                elm !== "" && (<div className={styles.checboxInput}>
                  <input
                    key={index}
                    type="radio"
                    name="CustomerName"
                    value={elm}
                    checked={getTech === elm}
                    onChange={() => {
                      if (getTech !== elm) {
                        setGetTech(elm);
                      }
                    }}
                  />
                  <label style={{ fontSize: "16px", fontWeight: 500 }}>
                    {elm}
                  </label>
                </div>)
              ))}

              {TrainArray?.map((elm, index) => (
                elm !== "" && (<div className={styles.checboxInput}>
                  <input
                    key={index}
                    type="radio"
                    name="CustomerName"
                    value={elm}
                    checked={getTech === elm}
                    onChange={() => {
                      if (getTech !== elm) {
                        setGetTech(elm);
                      }
                    }}
                  />
                  <label style={{ fontSize: "16px", fontWeight: 500 }}>
                    {elm}
                  </label>
                </div>)
              ))}
            </div>

            {getTech.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "20px 0",
                }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}>
                  <div>
                    <label
                      style={{
                        color: "var(--black, #394759)",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}>
                      Select Month:
                    </label>
                  </div>
                  <div className={styles.selectContainer}>
                    <select
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      className={styles.editOpen}
                      style={{backgroundColor: "#E7EBFF"}}>
                      <option value="">Select Month</option>
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <svg
                      className={styles.svgSelect}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div>
                   <div>
                    <label
                      style={{
                        color: "var(--black, #394759)",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}>
                      Select Year:
                    </label>
                   </div>
                  </div>
                  <div className={styles.selectContainer}>
                    <select
                      value={selectedYear}
                      onChange={handleYearChange}
                      className={styles.editOpen}
                       style={{backgroundColor: "#E7EBFF"}}>
                      <option value="">Select Year</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                     
                    </select>
                    <svg
                      className={styles.svgSelect}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}>
                
                    <Button
                      className={styles.saveButton}
                      style={{ width: "230px" }}
                      variant="contained"
                      onClick={handleOpenMarkAttendanceModal}>
                      Mark  Attendance
                    </Button>
                 

                  <Button
                    className={styles.saveButton}
                    variant="contained"
                    onClick={handleOpenEditRequestModal}>
                     Edit
                  </Button>
                </div>
              </div>
            )}

            <Modal
              open={openMarkAttendanceModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
              sx={{
                backdropFilter: "blur(4px)",
              }}>
              <Fade in={openMarkAttendanceModal}>
                <Box
                  sx={{
                    width: 500,
                    height: 500,
                    p: 3,
                    backgroundColor: "#fff",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                  }}>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ textAlign: "center", margin: "10px 0" }}>
                    Mark Attendance
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px",
                    }}>
                        <label
                      style={{
                        color: "#567191",
                        fontSize: "16px",
                        fontWeight: 400,
                        width: "120px",
                      }}>
                     Professional:
                    </label>
                    <input
                    type="text"
                    style={{ width: '300px', border: 'none' }}
                    value={getTech}
                    />
                 {tableData.id}
                  </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "20px",
                      }}>
                    <label
                      style={{
                        color: "#567191",
                        fontSize: "16px",
                        fontWeight: 400,
                        width: "120px",
                      }}>
                      Selected Date:
                    </label>
                    {/* <div>{formatDate()}</div> */}
                    <input 
                    type="date"
                    onChange={handleDateChange}
                    // value={attendanceData.selectedDate}
                    />

                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px",
                    }}>
                    <label
                      style={{
                        color: "#567191",
                        fontSize: "16px",
                        fontWeight: 400,
                        width: "120px",
                      }}>
                      Attendance:
                    </label>

                    <div className={styles.selectContainer}>
                      <select
                        style={{ border: "1px solid rgba(0,0,0,0.23)" }}
                        className={styles.editOpen}
                        name="attendance"
                        value={attendanceData.attendance}
                        onChange={handleAttendanceOptionChange}>
                        <option value="">Select Option</option>
                        <option value="Work">Work</option>
                        <option value="Travel">Travel</option>
                        <option value="Standby">Standby</option>
                      </select>
                      <svg
                        className={styles.svgSelect}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                  {attendanceData.attendance === "Standby" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "20px",
                      }}>
                      <label
                        style={{
                          color: "#567191",
                          fontSize: "16px",
                          fontWeight: 400,
                          width: "120px",
                        }}>
                        Reason for <br />
                        Standby:
                      </label>
                      <textarea
                        className={styles.editOpen}
                        style={{
                          padding: "10px",
                          height: "100px",
                          resize: "none",
                          border: "1px solid rgba(0,0,0,0.23)",
                        }}
                        name="remarks"
                        value={attendanceData.remarks}
                        onChange={handleAttendanceOptionChange}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "20px",
                    }}>
                    {projectList?.Supervisor_Names?.split(",")?.includes(
                      getTech
                    ) && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}>
                        <Checkbox
                          checked={markForAll}
                          onChange={handleCheckbox}
                          color="primary"
                        />
                        <label
                          style={{
                            color: "#567191",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}>
                          Mark same attendance for all team members
                        </label>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}>
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        className={styles.saveButton}
                        //disabled={!attendanceData.selectedDate}
                        >
                        Submit
                      </Button>
                      <Button
                        onClick={handleAttendanceCancel}
                        variant="contained"
                        className={styles.cancelattenButton}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Box>
              </Fade>
            </Modal>

            <Modal
              open={openEditRequestModal}
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
              sx={{
                backdropFilter: "blur(4px)",
              }}>
              <Box
                sx={{
                  width: 500,
                  height: 600,
                  p: 3,
                  backgroundColor: "#fff",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                }}>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{ textAlign: "center", margin: "10px" }}>
                  Edit Attendance Records
                </Typography>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "20px",
                  }}>
                       <label
                      style={{
                        color: "#567191",
                        fontSize: "16px",
                        fontWeight: 400,
                        width: "120px",
                      }}>
                     Professional:
                    </label>
                    <input
                    type="text"
                    style={{width: '300px', border: 'none'}}
                    value={getTech}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px",
                    }}>
                  <label
                    style={{
                      color: "#567191",
                      fontSize: "16px",
                      fontWeight: 400,
                      width: "120px",
                    }}>
                    Selected Date:
                  </label>
                  <TextField
                    style={{
                      width: "230px",
                    }}
                    // label="Selected Date"
                    type="date"
                    name="selectedDate"
                    value={attendanceData.selectedDate}
                    onChange={handleAttendanceOptionChange}
                  />
                      {  checkAttendance1.length>0  && attendanceData.selectedDate &&(
                      <div className={styles.validationMessage}>
                        {validateMessage}
                      </div>
                   )
    }
                </div>
                {checkAttendance.length > 0 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "20px",
                      }}>
                      <label
                        style={{
                          color: "#567191",
                          fontSize: "16px",
                          fontWeight: 400,
                          width: "120px",
                        }}>
                        Current Status:
                      </label>
                      {checkAttendance[0]?.Status}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "20px",
                      }}>
                      <label
                        style={{
                          color: "#567191",
                          fontSize: "16px",
                          fontWeight: 400,
                          width: "120px",
                        }}>
                        Change To:
                      </label>
                      <div className={styles.selectContainer}>
                        <select
                          style={{ border: "1px solid rgba(0,0,0,0.23)" }}
                          className={styles.editOpen}
                          name="attendance"
                          value={attendanceData.attendance}
                          onChange={handleAttendanceOptionChange}
                          disabled  = {checkAttendance1.length>0}
                          >
                          <option value="">Select Option</option>
                          {["Work", "Travel", "Standby"].map(
                            (el) =>
                              checkAttendance[0]?.Status !== el && (
                                <option value={el} key={el}>
                                  {el}
                                </option>
                              )
                          )}
                        </select>
                        <svg
                          className={styles.svgSelect}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "20px",
                      }}>
                      <label
                        style={{
                          color: "#567191",
                          fontSize: "16px",
                          fontWeight: 400,
                          width: "120px",
                        }}>
                        Reason for <br />
                        Change:
                      </label>
                      <textarea
                        className={styles.editOpen}
                        style={{
                          padding: "10px",
                          height: "100px",
                          resize: "none",
                          border: "1px solid rgba(0,0,0,0.23)",
                        }}
                        name="reason"
                        value={attendanceData.reason}
                        onChange={handleAttendanceOptionChange}
                        disabled  = {checkAttendance1.length>0}
                      />
                    </div>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}>
                    {checkAttendance.length > 0 && (
                      <Button
                        onClick={editAttendance}
                        variant="contained"
                        className={styles.saveButton}
                        disabled  = {checkAttendance1.length>0}
                        style={{ color: checkAttendance1.length > 0 ? '#fff' : '#fff' }}
                        >
                        Submit<span class="lock-icon" style={{ display: checkAttendance1.length > 0 ? 'inline' : 'none', marginLeft: '2px' }}>🔒</span>
                      </Button>
                    )}

                    <Button
                      onClick={handleAttendanceCancel}
                      variant="contained"
                      className={styles.cancelattenButton}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>

            {getTech.length > 0 && (
              <table className={styles.attendanceTable}>
                <thead style={{backgroundColor: "#E7EBFF"}}>
                  <tr>
                    <th>Date</th>
                    <th>Attendance</th>
                    <th>Remarks</th>
                    {/* <th>Amendment Request Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((el, index) => (
                    <tr>
                      <td
                        style={{
                          border: "1px solid #000",
                        }}>{`${el.Day}/${el.Month}/${el.Year}`}</td>
                      <td>
                        <div className={styles.spacingInStatus}>
                          {/* {el.Status === el.Previous_Attendance ||
                          el.Previous_Attendance === null ? (
                            el.Status
                          ) : (
                            <>
                              <div className={styles.previous}>
                                {el.Previous_Attendance}
                              </div>
                              <div className={styles.present}>{el.Status}</div>
                            </>
                          )} */}
                          {el.Status}
                        </div>
                      </td>
                      <td>{el.Reason ? el.Reason : (el.Remarks ? el.Remarks : "")}</td>
                      {/* <td style={{ color: "#2715F4" }}>
                        {el.Requested_Status}
                      </td> */}
                    </tr>
                  ))}
                  <tr>
                    <td
                      style={{
                        border: "1px solid #000",
                        padding: "50px",
                      }}></td>
                    <td
                      style={{
                        border: "1px solid #000",
                        padding: "50px",
                      }}></td>
                    <td
                      style={{
                        border: "1px solid #000",
                        padding: "50px",
                        //borderRight: "1px solid #000"
                      }}></td>
                    {/* <td
                      style={{
                        border: "1px solid #000",
                        padding: "50px",
                      }}></td> */}
                  </tr>
                </tbody>
              </table>
            )}
          </div>

          <div
            className={
              personalMobilisation === 2
                ? styles.activeTabPersonalMobilisation
                : styles.TabPersonalMobilisation
            }>
            <Timesheet
              ID={id}
              superarray={SuperArray}
              techarray={TechArray}
              trainarray={TrainArray}
            />
          </div>
        </Box>
      
        
  
    </div>
  )
}

export default Attendancefile;