import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import styles from "../Components/css/Workshoplist.module.css";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const Timesheetlist=() =>{

    const[data,setdata]=useState([]);
    const[fildata,setfildata]=useState([]);
    const[datearray,setdatearray]=useState([]);

    const[popupdate,setpopupdate]=useState();

    useEffect(() => {
        async function fetchRigDetails() {
          try {
            const response = await axios.get(
              "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheetwork_data"
            );
    
            setdatearray(response.data.data.map(item => item.Date));
            setdata(response.data.data);
          } catch (error) {
            console.error(error);
          }
        }
        fetchRigDetails();
      },[]);


      const handlepop=(date)=>{

setpopupdate(date);
setfildata( data.filter(el => el.Date === date));
console.log(date);
console.log(data.filter(el => el.Date === date));

      }


      const handlecrss=()=>{
        setpopupdate();
        setfildata();
      };


      datearray.sort((a, b) => {
        const dateA = new Date(b.split('/').reverse().join('/'));
        const dateB = new Date(a.split('/').reverse().join('/'));
        return dateA - dateB;
      });
      
      const uniqueDates = [...new Set(datearray)];
    
const convertdate=(date)=>{
    const dateParts = date?.split("/") || []
    const [year, month, day] = dateParts;
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
}

    return(
        <>
         <div className={styles.agv}>
        <NavLink  to="/admintaskbar"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none" style={{marginLeft:"50px" , marginTop:"10px"}}>
<path d="M0.939342 10.9393C0.353556 11.5251 0.353556 12.4749 0.939342 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939342 10.9393ZM26 10.5L2 10.5V13.5L26 13.5V10.5Z" fill="white"/>
</svg></NavLink>
        <h3 className={styles.head1}>Timesheet</h3>
        </div>
       <div className={styles.mainndiv}>
        {uniqueDates.map((date, index) => (
        <div key={index}  className={styles.div1}>
            <p className={styles.visss}>Date:{date}</p>
           {/* <p className={styles.visss3}  onClick={() => handlepop(date)}><NavLink>Quick View </NavLink>{"  |  "}</p> */}
           <p className={styles.visss2} ><NavLink to={`/Timesheet_edit/${convertdate(date)}`} style={{ marginLeft:"20px"}}>View Details</NavLink></p>
            </div>
      ))}

       </div>
       {popupdate && (
        <div className={styles.div2}> 
        <div className={styles.modalcontent}>
            <div style={{backgroundColor:"#D1D9FF" , marginTop:"0px" , height:"50px" , paddingTop:"10px"}}>
             <h4 style={{marginRight:"73%" }}>Toolbox Talk  {popupdate} </h4>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={handlecrss} style={{cursor:"pointer", marginLeft:"95%" , marginTop: "-50px"}}>
<path opacity="0.15" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="black"/>
<path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </div>
        <table className={styles.rigtable} >
            <thead>
            <tr>
                <th>S.No</th>
                <th>Task Description</th>
                <th>Project</th>
                <th>Team Assigned</th>
                <th>Target units</th>
                <th>Achieved Units</th>
                <th>Reason for Shortfall</th>
                <th>Additional tools requested</th>
            </tr>
            </thead>
           
            {fildata.map((item, index) => (
          <tr key={index}>
            <td>{index+1}</td>
            <td>{item.Task_description}</td>
            <td>{item.Project_so}</td>
            <td>{item.Team_assigned}</td>
            <td>{item.Target_units}</td>
            <td>{item.Achieved_Units}</td>
            <td>{item.Reason}</td>
            <td>{item.Additional_Tools}</td>       
          </tr>
        ))}


        </table>
        </div>
        </div>
       )
}
       </>
    )
}

export default Timesheetlist ;