import React, { useState, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import the plugin
import axios from "axios";
import { useEffect } from "react";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import styles from "../Components/css/projectreport.module.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
import Workplan from "./Workplan";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Dosplan from "./Dosplan";
import Erogonlogo from "../../src/Components/Erogonlogo.png";
import "../Components/css/Workplan3.css";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';


const Apidocs = (props) => {
  const { id } = useParams();
  const workplanref = useRef(null);
  const dosref = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const mobProjDetRef = useRef(null);
  const mobPlanTableRef = useRef(null);
  const mobMonthsRef = useRef(null);


  const [projectList, setProjectList] = useState({});
  const [isComplete, setIsComplete] = useState(false);
  const [imageData, setImageData] = useState("");
  const [imageData1, setImageData1] = useState("");
  const [imageData2, setImageData2] = useState("");
  const [imageData3, setImageData3] = useState("");
  const [imageData4, setImageData4] = useState("");
  const [imageData5, setImageData5] = useState("");
  const [imageData6, setImageData6] = useState("");
  const [editclicked, seteditclicked] = useState(false);
  const [date, setdate] = useState();
  const [teamselection, setteamselection] = useState("");
  const [sqp, setsqp] = useState("");
  const [riskassessment, setriskassessment] = useState("");
  const [contractreview, setcontractreview] = useState("");
  const [enquiry, setenquiry] = useState("");
  const [orderconfirmation, setorderconfirmation] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [formattedDate1,setFormattedDate1] = useState("");
  const [formattedDate2,setFormattedDate2] = useState("");

  const [formData, setFormData] = useState({
    Sales_Order_No: "",
    Rig_Name: "",
    Po_No: "",
    Tool1: "",
    Model1: "",
    Make1: "",
    Serial_Number1: "",
    Calibration_Date1: "",
    Calibration_Due_Date1: "",
    Estimated_Project_Completion_Month: "",
  });

  const [showPreview, setShowPreview] = useState(false);
  const [showDosplanPreview, setShowDosplanPreview] = useState(false);
  const handlePreview = () => {
    // setShowPreview(true);
    previewWorkplan();
  };

  const [projectData, setProjectData] = useState([]);
  const [error, setError] = useState(null);
  const [istorque, setIstorque] = useState(true);
  const [istorque2, setIstorque2] = useState(true);
  const table1Ref = useRef(null);
  const table2Ref = useRef(null);
  const table3Ref = useRef(null);
  const table4Ref = useRef(null);
  const table5Ref = useRef(null);
  const table6Ref = useRef(null);
  const table7Ref = useRef(null);
  const table8Ref = useRef(null);
  const table9Ref = useRef(null);
  const table10Ref = useRef(null);

  const formattedName2 =
  projectData && projectData.length > 0 && projectData[0].Supervisor_Names
    ? projectData[0].Supervisor_Names.split(",")[0] // Get the first name
        .split(" ") // Split by spaces
        .map(
          (word) =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize first character of each word
        .join(" ") // Join the words back together with spaces
    : "";
    const estimatedCompletionMonth = projectData && projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month;
    const formatWithLeadingZero = (number) => (number < 10 ? `0${number}` : `${number}`);
    let estimatedCompletionDate="";
    
    if (estimatedCompletionMonth) {
        const [day, month, year] = estimatedCompletionMonth.split('/').map(Number); // Swapped day and month here
        const originalDate = new Date(year, month - 1, day); // Months are 0-indexed in JavaScript Date constructor
        
        const newDate = new Date(originalDate);
        newDate.setDate(newDate.getDate() + 364);
    
        const newDay = formatWithLeadingZero(newDate.getDate());
        const newMonth = formatWithLeadingZero(newDate.getMonth() + 1); // Adding 1 because months are 0-indexed
        const newYear = newDate.getFullYear();
        
        // Format the new date
        estimatedCompletionDate = `${newDay}/${newMonth}/${newYear}`;
    } else {
        estimatedCompletionDate = "";
    }
    
    

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        if (!id) {
          throw new Error("Project ID is missing");
        }

        const response = await fetch(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details2?id=${id}}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        if (data.success) {
          setProjectData(data.data);
          // console.log("Data",data.data[0]?.Document_Date)

          const rawDate = data.data[0]?.Document_Date;
          const rawDate2 = data.data[0]?.Estimated_Project_Completion_Month;
          const [day, month, year] = rawDate.split("/").map(Number); 
          const jsDate = new Date(year, month - 1, day); 
          const [day2, month2, year2] = rawDate2.split("/").map(Number); 
          const jsDate22 = new Date(year2, month2 - 1, day2); 

          // Create new Date instances to avoid modifying the original jsDate
          const jsDate1 = new Date(jsDate);
          jsDate1.setDate(jsDate1.getDate() + 2);

          const jsDate2 = new Date(jsDate22);
          jsDate2.setDate(jsDate2.getDate() + 14);

          const formattedDate11 = `${String(jsDate1.getDate()).padStart(2, "0")}/${String(
            jsDate1.getMonth() + 1
          ).padStart(2, "0")}/${jsDate1.getFullYear()}`;

          const formattedDate22 = `${String(jsDate2.getDate()).padStart(2, "0")}/${String(
            jsDate2.getMonth() + 1
          ).padStart(2, "0")}/${jsDate2.getFullYear()}`;

          setFormattedDate1(formattedDate11);
          setFormattedDate2(formattedDate22);

          // Access data directly, not projectData
          if (data.data[0].Tool1 === "Dial Indicator") {
            setIstorque(false);
          }

          

        } else {
          throw new Error(data.message);
        }

      } catch (error) {
        setError(error.message);
      }
    };

    // Call fetchProjectData within useEffect
    fetchProjectData();
  }, []);

  // Moved console.log inside useEffect to ensure it's executed after state updates

  useEffect(() => {
    const fetchProjectData2 = async () => {
      try {


        const response2 = await fetch(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details3?id=${id}`
        );

        if (!response2.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response2.json();

        if (data.success) {
          setProjectData(data.data);
          // Access data directly, not projectData
          if (data.data[0].Tool2 === "Dial Indicator") {
            setIstorque2(false);
          }
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    // Call fetchProjectData within useEffect
    fetchProjectData2();
  }, []);


  const downloadPDF = () => {
    // Create new jsPDF instance with landscape orientation
    const doc = new jsPDF({
      orientation: "landscape",
    });

    // Set the desired row heights
    const rowHeights1 = [30, 50, 40]; // Row heights for the first table
    const rowHeights2 = [30, 30, 30]; // Row heights for the second table

    // Define column widths
    const columnWidths1 = [55, 85, 45, 75]; // Column widths for the first table
    const columnWidths2 = [85, 55, 75, 45]; // Column widths for the second table
    const styles = {
      lineColor: [0, 0, 0], // Border color (black)
      textColor: [0, 0, 0], // Text color (black)
    };
    // Add image to the PDF
    const imgWidth = 40; // Width of the image
    const imgHeight = 15; // Height of the image
    const x = 12; // Calculate center position horizontally
    const y = 5; // Calculate center position vertically
    doc.addImage(Erogonlogo, "PNG", x, y, imgWidth, imgHeight); // Position the image at the center

    // Add header for the first table
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.setTextColor(0); // Set font color to black
    doc.text("Measuring Equipment Validation Form", 150, 15, {
      align: "center",
    });

    // Add a grey line below the header
    doc.setLineWidth(0.5); // Set line width
    doc.setDrawColor(192, 192, 192); // Set line color to grey
    doc.line(10, 25, 283, 25); // Draw a line below the header

    // Add the first table after a timeout of 0.5 seconds
    setTimeout(() => {
      doc.autoTable({
        html: table1Ref.current,
        theme: "grid",
        startY: 35,
        margin: { top: 30 },
        rowHeights: rowHeights1,
        columnStyles: {
          0: { columnWidth: columnWidths1[0], halign: "center" },
          1: { columnWidth: columnWidths1[1], halign: "center" },
          2: { columnWidth: columnWidths1[2], halign: "center" },
          3: { columnWidth: columnWidths1[3], halign: "center" },
        },
        styles: styles,
        didParseCell: function (data) {
          if (data.column.index === 0 || data.column.index === 2) {
            data.cell.styles.fillColor = [43, 100, 166]; // Set the fill color for the first and third rows
            data.cell.styles.textColor = [255, 255, 255]; // Set the text color
            data.cell.styles.fontStyle = "bold"; // Set the font style
          }
        },
      });

      // Add the second table
      doc.autoTable({
        html: table2Ref.current,
        theme: "grid", // Use the grid theme
        startY: doc.autoTable.previous.finalY + 35, // Start position of the second table
        margin: { top: 30 }, // Margin for the second table
        rowHeights: rowHeights2, // Row heights for the second table
        columnStyles: {
          // Column widths for the second table
          0: { columnWidth: columnWidths2[0], halign: "center" },
          1: { columnWidth: columnWidths2[1], halign: "center" },
          2: { columnWidth: columnWidths2[2], halign: "center" },
          3: { columnWidth: columnWidths2[3], halign: "center" },
        },
        // headStyles: {
        //   fillColor: [43, 100, 166], // Custom color for the header
        //   textColor: [255, 255, 255], // Text color in the header
        //   fontStyle: "bold", // Font style for the header
        //   valign: "middle", // Vertical alignment of the content in the header
        //   halign: "center", // Horizontal alignment of the content in the header
        // },
        styles: styles,
        didParseCell: function (data) {
          if (data.row.index === 0) {
            data.cell.styles.fillColor = [43, 100, 166]; // Set the fill color for the first and third rows
            data.cell.styles.textColor = [255, 255, 255]; // Set the text color
            data.cell.styles.fontStyle = "bold"; // Set the font style
          }
        },
      });

      // Add a grey line above the footer
      const pageHeight = doc.internal.pageSize.getHeight();
      doc.setLineWidth(0.5); // Set line width
      doc.setDrawColor(192, 192, 192); // Set line color to grey
      doc.line(10, pageHeight - 25, 283, pageHeight - 25); // Draw a line above the footer

      // Additional content for the footer
      const marginBottomFooter = 10;
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);

        // Add content on the left
        doc.text(
          "TSD-OPS-FOR-007-19, Rev.01",
          10,
          pageHeight - marginBottomFooter - 3,
          { align: "left" }
        );

        // Add content in the middle (random data)
        const randomData = "30-April-2022"; // static date
        const dateTextOptions = { align: "center" };
        doc.text(
          randomData,
          140,
          pageHeight - marginBottomFooter - 3,
          dateTextOptions
        );

        // Add margin-bottom and additional text below the date
        const additionalText =
          "This document is digitally generated and does not require a signature";
        const additionalTextWidth = doc.getTextWidth(additionalText);
        const additionalTextX =
          (doc.internal.pageSize.getWidth() - additionalTextWidth) / 2 - 10;
        doc.text(
          additionalText,
          additionalTextX,
          pageHeight - marginBottomFooter + 5
        ); // Adjusted margin-bottom

        // Add content on the right (page number)
        const pageString = `Page ${i}`;
        const pageStringWidth =
          doc.getStringUnitWidth(pageString) * doc.internal.getFontSize();
        const marginRight = 20; // Adjust margin from the right edge
        doc.text(
          pageString,
          doc.internal.pageSize.getWidth() - pageStringWidth + marginRight,
          pageHeight - marginBottomFooter - 3,
          { align: "right" }
        ); // Adjusted the x-coordinate here
      }

      // Splitting the string by spaces and mapping each word to capitalize the first character
      const formattedName = projectData && projectData.length > 0 && projectData[0]?.Supervisor_Names
        ? projectData[0]?.Supervisor_Names.split(",")[0]  // Get the first name
          .split(" ")  // Split by spaces
          .map(word =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )  // Capitalize first character of each word
          .join(" ")  // Join the words back together with spaces
        : '';

      // Now, formattedName contains the first name with each word's first character capitalized

      doc.setFont("helvetica", "bold");
      doc.text("Prepared By: " + formattedName, 10, pageHeight - 65, {
        align: "left",
      });
      doc.setFont("helvetica", "normal");

      // // Add content on the right (Approved By)
      doc.setFont("helvetica", "bold");
      doc.text("Approved By: Nishad Majeed", 283, pageHeight - 65, {
        align: "right",
      });
      doc.setFont("helvetica", "normal");
      // Save the PDF
      doc.save("Equipment_Initial_Form_1.pdf");
    }, 500); // Timeout of 0.5 seconds
  };

  const downloadPDF2 = () => {
    // Create new jsPDF instance with landscape orientation
    const doc = new jsPDF({
      orientation: "landscape",
    });

    // Set the desired row heights
    const rowHeights1 = [30, 50, 40]; // Row heights for the first table
    const rowHeights2 = [30, 30, 30]; // Row heights for the second table

    // Define column widths
    const columnWidths1 = [55, 85, 45, 75]; // Column widths for the first table
    const columnWidths2 = [85, 55, 75, 45]; // Column widths for the second table
    const styles = {
      lineColor: [0, 0, 0], // Border color (black)
      textColor: [0, 0, 0], // Text color (black)
    };
    // Add image to the PDF
    const imgWidth = 40; // Width of the image
    const imgHeight = 15; // Height of the image
    const x = 12; // Calculate center position horizontally
    const y = 5; // Calculate center position vertically
    doc.addImage(Erogonlogo, "PNG", x, y, imgWidth, imgHeight); // Position the image at the center

    // Add header for the first table
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.setTextColor(0); // Set font color to black
    doc.text("Measuring Equipment Validation Form", 150, 15, {
      align: "center",
    });

    // Add a grey line below the header
    doc.setLineWidth(0.5); // Set line width
    doc.setDrawColor(192, 192, 192); // Set line color to grey
    doc.line(10, 25, 283, 25); // Draw a line below the header

    // Add the first table after a timeout of 0.5 seconds
    setTimeout(() => {
      doc.autoTable({
        html: table3Ref.current,
        theme: "grid",
        startY: 35,
        margin: { top: 30 },
        rowHeights: rowHeights1,
        columnStyles: {
          0: { columnWidth: columnWidths1[0], halign: "center" },
          1: { columnWidth: columnWidths1[1], halign: "center" },
          2: { columnWidth: columnWidths1[2], halign: "center" },
          3: { columnWidth: columnWidths1[3], halign: "center" },
        },
        styles: styles,
        didParseCell: function (data) {
          if (data.column.index === 0 || data.column.index === 2) {
            data.cell.styles.fillColor = [43, 100, 166]; // Set the fill color for the first and third rows
            data.cell.styles.textColor = [255, 255, 255]; // Set the text color
            data.cell.styles.fontStyle = "bold"; // Set the font style
          }
        },
      });

      // Add the second table
      doc.autoTable({
        html: table4Ref.current,
        theme: "grid", // Use the grid theme
        startY: doc.autoTable.previous.finalY + 35, // Start position of the second table
        margin: { top: 30 }, // Margin for the second table
        rowHeights: rowHeights2, // Row heights for the second table
        columnStyles: {
          // Column widths for the second table
          0: { columnWidth: columnWidths2[0], halign: "center" },
          1: { columnWidth: columnWidths2[1], halign: "center" },
          2: { columnWidth: columnWidths2[2], halign: "center" },
          3: { columnWidth: columnWidths2[3], halign: "center" },
        },
        // headStyles: {
        //   fillColor: [43, 100, 166], // Custom color for the header
        //   textColor: [255, 255, 255], // Text color in the header
        //   fontStyle: "bold", // Font style for the header
        //   valign: "middle", // Vertical alignment of the content in the header
        //   halign: "center", // Horizontal alignment of the content in the header
        // },
        styles: styles,
        didParseCell: function (data) {
          if (data.row.index === 0) {
            data.cell.styles.fillColor = [43, 100, 166]; // Set the fill color for the first and third rows
            data.cell.styles.textColor = [255, 255, 255]; // Set the text color
            data.cell.styles.fontStyle = "bold"; // Set the font style
          }
        },
      });

      // Add a grey line above the footer
      const pageHeight = doc.internal.pageSize.getHeight();
      doc.setLineWidth(0.5); // Set line width
      doc.setDrawColor(192, 192, 192); // Set line color to grey
      doc.line(10, pageHeight - 25, 283, pageHeight - 25); // Draw a line above the footer

      // Additional content for the footer
      const marginBottomFooter = 10;
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);

        // Add content on the left
        doc.text(
          "TSD-OPS-FOR-007-19, Rev.01",
          10,
          pageHeight - marginBottomFooter - 3,
          { align: "left" }
        );

        // Add content in the middle (random data)
        const randomData = "30-April-2022"; // static date
        const dateTextOptions = { align: "center" };
        doc.text(
          randomData,
          140,
          pageHeight - marginBottomFooter - 3,
          dateTextOptions
        );

        // Add margin-bottom and additional text below the date
        const additionalText =
          "This document is digitally generated and does not require a signature";
        const additionalTextWidth = doc.getTextWidth(additionalText);
        const additionalTextX =
          (doc.internal.pageSize.getWidth() - additionalTextWidth) / 2 - 10;
        doc.text(
          additionalText,
          additionalTextX,
          pageHeight - marginBottomFooter + 5
        ); // Adjusted margin-bottom

        // Add content on the right (page number)
        const pageString = `Page ${i}`;
        const pageStringWidth =
          doc.getStringUnitWidth(pageString) * doc.internal.getFontSize();
        const marginRight = 20; // Adjust margin from the right edge
        doc.text(
          pageString,
          doc.internal.pageSize.getWidth() - pageStringWidth + marginRight,
          pageHeight - marginBottomFooter - 3,
          { align: "right" }
        ); // Adjusted the x-coordinate here
      }

      // Splitting the string by spaces and mapping each word to capitalize the first character
      const formattedName = projectData && projectData.length > 0 && projectData[0].Supervisor_Names
        ? projectData[0].Supervisor_Names.split(",")[0]  // Get the first name
          .split(" ")  // Split by spaces
          .map(word =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )  // Capitalize first character of each word
          .join(" ")  // Join the words back together with spaces
        : '';


      // Now, formattedName contains the first name with each word's first character capitalized

      doc.setFont("helvetica", "bold");
      doc.text("Prepared By: " + formattedName, 10, pageHeight - 65, {
        align: "left",
      });
      doc.setFont("helvetica", "normal");

      doc.setFont("helvetica", "bold");
      doc.text("Approved By: Nishad Majeed", 283, pageHeight - 65, {
        align: "right",
      });
      doc.setFont("helvetica", "normal");
      // Save the PDF
      doc.save("Equipment_Initial_Form_2.pdf");
    }, 500); // Timeout of 0.5 seconds
  };

  const downloadPDF3 = () => {
    // Create new jsPDF instance with landscape orientation
    const doc = new jsPDF({
      orientation: "landscape",
    });

    // Set the desired row heights
    const rowHeights1 = [30, 50, 40]; // Row heights for the first table
    const rowHeights2 = [30, 30, 30]; // Row heights for the second table

    // Define column widths
    const columnWidths1 = [55, 85, 45, 75]; // Column widths for the first table
    const columnWidths2 = [85, 55, 75, 45]; // Column widths for the second table
    const styles = {
      lineColor: [0, 0, 0], // Border color (black)
      textColor: [0, 0, 0] // Text color (black)
    };
    // Add image to the PDF
    const imgWidth = 40; // Width of the image
    const imgHeight = 15; // Height of the image
    const x = 12; // Calculate center position horizontally
    const y = 5; // Calculate center position vertically
    doc.addImage(Erogonlogo, "PNG", x, y, imgWidth, imgHeight); // Position the image at the center

    // Add header for the first table
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.setTextColor(0); // Set font color to black
    doc.text("Measuring Equipment Validation Form", 150, 15, {
      align: "center",
    });

    // Add a grey line below the header
    doc.setLineWidth(0.5); // Set line width
    doc.setDrawColor(192, 192, 192); // Set line color to grey
    doc.line(10, 25, 283, 25); // Draw a line below the header

    // Add the first table after a timeout of 0.5 seconds
    setTimeout(() => {
      doc.autoTable({
        html: table5Ref.current,
        theme: "grid",
        startY: 35,
        margin: { top: 30 },
        rowHeights: rowHeights1,
        columnStyles: {
          0: { columnWidth: columnWidths1[0], halign: "center" },
          1: { columnWidth: columnWidths1[1], halign: "center" },
          2: { columnWidth: columnWidths1[2], halign: "center" },
          3: { columnWidth: columnWidths1[3], halign: "center" },
        },
        styles: styles,
        didParseCell: function (data) {
          if (data.column.index === 0 || data.column.index === 2) {
            data.cell.styles.fillColor = [43, 100, 166]; // Set the fill color for the first and third rows
            data.cell.styles.textColor = [255, 255, 255]; // Set the text color
            data.cell.styles.fontStyle = "bold"; // Set the font style
          }
        }

      });

      // Add the second table
      doc.autoTable({
        html: table2Ref.current,
        theme: "grid", // Use the grid theme
        startY: doc.autoTable.previous.finalY + 35, // Start position of the second table
        margin: { top: 30 }, // Margin for the second table
        rowHeights: rowHeights2, // Row heights for the second table
        columnStyles: {
          // Column widths for the second table
          0: { columnWidth: columnWidths2[0], halign: "center" },
          1: { columnWidth: columnWidths2[1], halign: "center" },
          2: { columnWidth: columnWidths2[2], halign: "center" },
          3: { columnWidth: columnWidths2[3], halign: "center" },
        },
        // headStyles: {
        //   fillColor: [43, 100, 166], // Custom color for the header
        //   textColor: [255, 255, 255], // Text color in the header
        //   fontStyle: "bold", // Font style for the header
        //   valign: "middle", // Vertical alignment of the content in the header
        //   halign: "center", // Horizontal alignment of the content in the header
        // },
        styles: styles,
        didParseCell: function (data) {
          if (data.row.index === 0) {
            data.cell.styles.fillColor = [43, 100, 166]; // Set the fill color for the first and third rows
            data.cell.styles.textColor = [255, 255, 255]; // Set the text color
            data.cell.styles.fontStyle = "bold"; // Set the font style
          }
        },
      });

      // Add a grey line above the footer
      const pageHeight = doc.internal.pageSize.getHeight();
      doc.setLineWidth(0.5); // Set line width
      doc.setDrawColor(192, 192, 192); // Set line color to grey
      doc.line(10, pageHeight - 25, 283, pageHeight - 25); // Draw a line above the footer

      // Additional content for the footer
      const marginBottomFooter = 10;
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);

        // Add content on the left
        doc.text(
          "TSD-OPS-FOR-007-19, Rev.01",
          10,
          pageHeight - marginBottomFooter - 3,
          { align: "left" }
        );

        // Add content in the middle (random data)
        const randomData = "30-April-2022"; // static date
        const dateTextOptions = { align: "center" };
        doc.text(
          randomData,
          140,
          pageHeight - marginBottomFooter - 3,
          dateTextOptions
        );

        // Add margin-bottom and additional text below the date
        const additionalText =
          "This document is digitally generated and does not require a signature";
        const additionalTextWidth = doc.getTextWidth(additionalText);
        const additionalTextX =
          (doc.internal.pageSize.getWidth() - additionalTextWidth) / 2 - 10;
        doc.text(
          additionalText,
          additionalTextX,
          pageHeight - marginBottomFooter + 5
        );  // Adjusted margin-bottom

        // Add content on the right (page number)
        const pageString = `Page ${i}`;
        const pageStringWidth =
          doc.getStringUnitWidth(pageString) * doc.internal.getFontSize();
        const marginRight = 20; // Adjust margin from the right edge
        doc.text(
          pageString,
          doc.internal.pageSize.getWidth() - pageStringWidth + marginRight,
          pageHeight - marginBottomFooter - 3,
          { align: "right" }
        ); // Adjusted the x-coordinate here
      }
      // // Add content on the left (Prepared By)
      const formattedName = projectData && projectData.length > 0 && projectData[0].Supervisor_Names
        ? projectData[0].Supervisor_Names.split(",")[0]  // Get the first name
          .split(" ")  // Split by spaces
          .map(word =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )  // Capitalize first character of each word
          .join(" ")  // Join the words back together with spaces
        : '';


      // Now, formattedName contains the first name with each word's first character capitalized

      doc.setFont("helvetica", "bold");
      doc.text("Prepared By: " + formattedName, 10, pageHeight - 65, {
        align: "left",
      });
      doc.setFont("helvetica", "normal");

      // // Add content on the right (Approved By)
      // doc.setFont('helvetica', 'bold');
      // doc.text("Approved By: Nishad Majeed", 283, pageHeight - 35, { align: "right" });
      // doc.setFont('helvetica', 'normal');
      // Save the PDF
      doc.save("Equipment_validation_Form_1.pdf");
    }, 500); // Timeout of 0.5 seconds
  };
  const downloadPDF4 = () => {
    // Create new jsPDF instance with landscape orientation
    const doc = new jsPDF({
      orientation: "landscape",
    });

    // Set the desired row heights
    const rowHeights1 = [30, 50, 40]; // Row heights for the first table
    const rowHeights2 = [30, 30, 30]; // Row heights for the second table

    // Define column widths
    const columnWidths1 = [55, 85, 45, 75]; // Column widths for the first table
    const columnWidths2 = [85, 55, 75, 45]; // Column widths for the second table
    const styles = {
      lineColor: [0, 0, 0], // Border color (black)
      textColor: [0, 0, 0] // Text color (black)
    };
    // Add image to the PDF
    const imgWidth = 40; // Width of the image
    const imgHeight = 15; // Height of the image
    const x = 12; // Calculate center position horizontally
    const y = 5; // Calculate center position vertically
    doc.addImage(Erogonlogo, "PNG", x, y, imgWidth, imgHeight); // Position the image at the center

    // Add header for the first table
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.setTextColor(0); // Set font color to black
    doc.text("Measuring Equipment Validation Form", 150, 15, {
      align: "center",
    });

    // Add a grey line below the header
    doc.setLineWidth(0.5); // Set line width
    doc.setDrawColor(192, 192, 192); // Set line color to grey
    doc.line(10, 25, 283, 25); // Draw a line below the header

    // Add the first table after a timeout of 0.5 seconds
    setTimeout(() => {
      doc.autoTable({
        html: table6Ref.current,
        theme: "grid",
        startY: 35,
        margin: { top: 30 },
        rowHeights: rowHeights1,
        columnStyles: {
          0: { columnWidth: columnWidths1[0], halign: "center" },
          1: { columnWidth: columnWidths1[1], halign: "center" },
          2: { columnWidth: columnWidths1[2], halign: "center" },
          3: { columnWidth: columnWidths1[3], halign: "center" },
        },
        styles: styles,
        didParseCell: function (data) {
          if (data.column.index === 0 || data.column.index === 2) {
            data.cell.styles.fillColor = [43, 100, 166]; // Set the fill color for the first and third rows
            data.cell.styles.textColor = [255, 255, 255]; // Set the text color
            data.cell.styles.fontStyle = "bold"; // Set the font style
          }
        }

      });
      doc.autoTable({
        html: table4Ref.current,
        theme: "grid", // Use the grid theme
        startY: doc.autoTable.previous.finalY + 35, // Start position of the second table
        margin: { top: 30 }, // Margin for the second table
        rowHeights: rowHeights2, // Row heights for the second table
        columnStyles: {
          // Column widths for the second table
          0: { columnWidth: columnWidths2[0], halign: "center" },
          1: { columnWidth: columnWidths2[1], halign: "center" },
          2: { columnWidth: columnWidths2[2], halign: "center" },
          3: { columnWidth: columnWidths2[3], halign: "center" },
        },
        // headStyles: {
        //   fillColor: [43, 100, 166], // Custom color for the header
        //   textColor: [255, 255, 255], // Text color in the header
        //   fontStyle: "bold", // Font style for the header
        //   valign: "middle", // Vertical alignment of the content in the header
        //   halign: "center", // Horizontal alignment of the content in the header
        // },
        styles: styles,
        didParseCell: function (data) {
          if (data.row.index === 0) {
            data.cell.styles.fillColor = [43, 100, 166]; // Set the fill color for the first and third rows
            data.cell.styles.textColor = [255, 255, 255]; // Set the text color
            data.cell.styles.fontStyle = "bold"; // Set the font style
          }
        },
      });

      // // Add the second table
      // doc.autoTable({
      //   html: table2Ref.current,
      //   theme: "grid", // Use the grid theme
      //   startY: doc.autoTable.previous.finalY + 35, // Start position of the second table
      //   margin: { top: 30 }, // Margin for the second table
      //   rowHeights: rowHeights2, // Row heights for the second table
      //   columnStyles: {
      //     // Column widths for the second table
      //     0: { columnWidth: columnWidths2[0], halign: "center" },
      //     1: { columnWidth: columnWidths2[1], halign: "center" },
      //     2: { columnWidth: columnWidths2[2], halign: "center" },
      //     3: { columnWidth: columnWidths2[3], halign: "center" },
      //   },
      //   headStyles: {
      //     fillColor: [43, 100, 166], // Custom color for the header
      //     textColor: [255, 255, 255], // Text color in the header
      //     fontStyle: "bold", // Font style for the header
      //     valign: "middle", // Vertical alignment of the content in the header
      //     halign: "center", // Horizontal alignment of the content in the header
      //   },
      //   styles: styles
      // });

      // Add a grey line above the footer
      const pageHeight = doc.internal.pageSize.getHeight();
      doc.setLineWidth(0.5); // Set line width
      doc.setDrawColor(192, 192, 192); // Set line color to grey
      doc.line(10, pageHeight - 25, 283, pageHeight - 25); // Draw a line above the footer

      // Additional content for the footer
      const marginBottomFooter = 10;
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);

        // Add content on the left
        doc.text(
          "TSD-OPS-FOR-007-19, Rev.01",
          10,
          pageHeight - marginBottomFooter - 3,
          { align: "left" }
        );

        // Add content in the middle (random data)
        const randomData = "30-April-2022"; // static date
        const dateTextOptions = { align: "center" };
        doc.text(
          randomData,
          140,
          pageHeight - marginBottomFooter - 3,
          dateTextOptions
        );

        // Add margin-bottom and additional text below the date
        const additionalText =
          "This document is digitally generated and does not require a signature";
        const additionalTextWidth = doc.getTextWidth(additionalText);
        const additionalTextX =
          (doc.internal.pageSize.getWidth() - additionalTextWidth) / 2 - 10;
        doc.text(
          additionalText,
          additionalTextX,
          pageHeight - marginBottomFooter + 5
        );         // Add content on the right (page number)
        const pageString = `Page ${i}`;
        const pageStringWidth =
          doc.getStringUnitWidth(pageString) * doc.internal.getFontSize();
        const marginRight = 20; // Adjust margin from the right edge
        doc.text(
          pageString,
          doc.internal.pageSize.getWidth() - pageStringWidth + marginRight,
          pageHeight - marginBottomFooter - 3,
          { align: "right" }
        ); // Adjusted the x-coordinate here
      }
      // // Add content on the left (Prepared By)
      const formattedName = projectData && projectData.length > 0 && projectData[0].Supervisor_Names
        ? projectData[0].Supervisor_Names.split(",")[0]  // Get the first name
          .split(" ")  // Split by spaces
          .map(word =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )  // Capitalize first character of each word
          .join(" ")  // Join the words back together with spaces
        : '';


      // Now, formattedName contains the first name with each word's first character capitalized

      doc.setFont("helvetica", "bold");
      doc.text("Prepared By: " + formattedName, 10, pageHeight - 65, {
        align: "left",
      });
      doc.setFont("helvetica", "normal");

      // // Add content on the right (Approved By)
      // doc.setFont('helvetica', 'bold');
      // doc.text("Approved By: Nishad Majeed", 283, pageHeight - 35, { align: "right" });
      // doc.setFont('helvetica', 'normal');
      // Save the PDF
      doc.save("Equipment_validation_Form_2.pdf");
    }, 500); // Timeout of 0.5 seconds
  };

  const downloadPDF5 = () => {
    // Create new jsPDF instance with landscape orientation
    const doc = new jsPDF({
      format:[217,290]
    });
  
    // Set the desired row heights
    const rowHeights1 = [15,10, 10, 10, 10, 10, 10,10, 10, 10, 10, 10, 10,10,10]; // Row heights for the first table
  
    // Define column widths
    const columnWidths1 = [30, 30, 30, 'auto', 'auto', 30]; // Column widths for the first table
  
    // Add image to the PDF
    const imgWidth = 40; // Width of the image
    const imgHeight = 15; // Height of the image
    const x = 12; // Calculate center position horizontally
    const y = 5; // Calculate center position vertically
    doc.addImage(Erogonlogo, "PNG", x, y, imgWidth, imgHeight); // Position the image at the center
  
    // Add header for the first table
    // doc.setFont("helvetica", "bold");
    // doc.setFontSize(18);
    // doc.setTextColor(0); // Set font color to black
    // doc.text("SRP Preservation Form", 150, 15, {
    //   align: "center",
    // });
  
    // Add a grey line below the header
    // doc.setLineWidth(0.5); // Set line width
    // doc.setDrawColor(192, 192, 192); // Set line color to grey
    // doc.line(10, 25, 283, 25); // Draw a line below the header
  
    // Add the first table after a timeout of 0.5 seconds
    setTimeout(() => {
      doc.autoTable({
        html: table7Ref.current,
        theme: "grid",
        startY: 30,
        margin: { top: 30 },
        rowHeights: rowHeights1,
        rowStyles: { valign: "middle" },
        columnStyles: {
            0: { cellWidth: columnWidths1[0],valign:"middle"},
            1: { cellWidth: columnWidths1[1],valign:"middle" },
            2: { cellWidth: columnWidths1[2], halign: "center",valign:"middle" },
            3: { cellWidth: columnWidths1[3], valign:"middle" },
            4: { cellWidth: columnWidths1[4], halign: "center",valign:"middle" },
            5: { cellWidth: columnWidths1[5], halign: "center",valign:"middle" },
        },
        styles: { overflow: 'linebreak', lineColor: [0, 0, 0], textColor: [0, 0, 0] }, // Allow content to break into multiple lines
        didParseCell: function (data) {
          if (data.row.index === 2) {
              data.cell.styles.fillColor = [218, 237, 202]; // Set the fill color for the third row
              data.cell.styles.fontStyle = "bold"; // Set the font style
          }
          if (data.column.index === 1) {
              data.cell.styles.fontStyle = "bold"; // Set bold font style for the second column
          }
          if (data.column.index === 0 && (data.row.index === 0 || data.row.index === 5)) {
              data.cell.styles.halign = "center"; // Horizontally align text in the first column for the first and last rows
              data.cell.styles.valign = "middle"; // Vertically center the text within the cells
          }
          if ([1, 2, 3, 12, 13,14].includes(data.row.index)&& data.column.index!==3) {
              data.cell.styles.halign = "center"; // Horizontally align text in the first column for specified rows
              data.cell.styles.valign = "middle"; // Vertically center the text within the cells
          }
          if (data.column.index === 3 && (data.row.index === 4 || data.row.index === 11)) {
              data.cell.styles.fontStyle = "bold"; // Set bold font style for specified cells in the fourth column
          }
          if (data.row.index === 0) {
              data.cell.styles.fillColor = [210, 210, 210]; // Set the fill color for the first row
              data.cell.styles.fontStyle = "bold"; // Set the font style
              data.cell.styles.fontSize = 16; // Set the font size for the first row
          }
      },
      
    });
    
      // Add a grey line above the footer
      const pageHeight = doc.internal.pageSize.getHeight();
      doc.setLineWidth(0.5); // Set line width
      doc.setDrawColor(192, 192, 192); // Set line color to grey
      doc.line(10, pageHeight - 25, 283, pageHeight - 25); // Draw a line above the footer
  
      // Additional content for the footer
      const marginBottomFooter = 10;
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
  
        // Add content on the left
        doc.text(
          "TSD-OPS-FOR-022-21, Rev.01",
          10,
          pageHeight - marginBottomFooter - 3,
          { align: "left" }
        );
  
        // Add content in the middle (random data)
        const randomData = "30-April-2022"; // static date
        const dateTextOptions = { align: "center" };
        doc.text(
          randomData,
          110,
          pageHeight - marginBottomFooter - 3,
          dateTextOptions
        );
  
        // Add margin-bottom and additional text below the date
        const additionalText =
          "This document is digitally generated and does not require a signature";
        const additionalTextWidth = doc.getTextWidth(additionalText);
        const additionalTextX =
          (doc.internal.pageSize.getWidth() - additionalTextWidth) / 2 ;
        doc.text(
          additionalText,
          additionalTextX,
          pageHeight - marginBottomFooter + 5
        ); // Adjusted margin-bottom
  
        // Add content on the right (page number)
        const pageString = `Page ${i}`;
        const pageStringWidth =
          doc.getStringUnitWidth(pageString) * doc.internal.getFontSize();
        const marginRight = 20; // Adjust margin from the right edge
        doc.text(
          pageString,
          doc.internal.pageSize.getWidth() - pageStringWidth + marginRight,
          pageHeight - marginBottomFooter - 3,
          { align: "right" }
        ); // Adjusted the x-coordinate here
      }
  
      // Save the PDF
      doc.save("Equipment_Preservation_Form_1.pdf");
    }, 500); // Timeout of 0.5 seconds
  };
  const downloadPDF6 = () => {
    // Create new jsPDF instance with landscape orientation
    const doc = new jsPDF({
      format:[217,290]
    });
  
    // Set the desired row heights
    const rowHeights1 = [15,10, 10, 10, 10, 10, 10,10, 10, 10, 10, 10, 10,10,10]; // Row heights for the first table
  
    // Define column widths
    const columnWidths1 = [30, 30, 30, 'auto', 'auto', 30]; // Column widths for the first table
  
    // Add image to the PDF
    const imgWidth = 40; // Width of the image
    const imgHeight = 15; // Height of the image
    const x = 12; // Calculate center position horizontally
    const y = 5; // Calculate center position vertically
    doc.addImage(Erogonlogo, "PNG", x, y, imgWidth, imgHeight); // Position the image at the center
  
    // Add header for the first table
    // doc.setFont("helvetica", "bold");
    // doc.setFontSize(18);
    // doc.setTextColor(0); // Set font color to black
    // doc.text("SRP Preservation Form", 150, 15, {
    //   align: "center",
    // });
  
    // Add a grey line below the header
    // doc.setLineWidth(0.5); // Set line width
    // doc.setDrawColor(192, 192, 192); // Set line color to grey
    // doc.line(10, 25, 283, 25); // Draw a line below the header
  
    // Add the first table after a timeout of 0.5 seconds
    setTimeout(() => {
      doc.autoTable({
        html: table8Ref.current,
        theme: "grid",
        startY: 30,
        margin: { top: 30 },
        rowHeights: rowHeights1,
        rowStyles: { valign: "middle" },
        columnStyles: {
            0: { cellWidth: columnWidths1[0],valign:"middle"},
            1: { cellWidth: columnWidths1[1],valign:"middle" },
            2: { cellWidth: columnWidths1[2], halign: "center",valign:"middle" },
            3: { cellWidth: columnWidths1[3], valign:"middle" },
            4: { cellWidth: columnWidths1[4], halign: "center",valign:"middle" },
            5: { cellWidth: columnWidths1[5], halign: "center",valign:"middle" },
        },
        styles: { overflow: 'linebreak', lineColor: [0, 0, 0], textColor: [0, 0, 0] }, // Allow content to break into multiple lines
        didParseCell: function (data) {
          if (data.row.index === 2) {
              data.cell.styles.fillColor = [218, 237, 202]; // Set the fill color for the third row
              data.cell.styles.fontStyle = "bold"; // Set the font style
          }
          if (data.column.index === 1) {
              data.cell.styles.fontStyle = "bold"; // Set bold font style for the second column
          }
          if (data.column.index === 0 && (data.row.index === 0 || data.row.index === 5)) {
              data.cell.styles.halign = "center"; // Horizontally align text in the first column for the first and last rows
              data.cell.styles.valign = "middle"; // Vertically center the text within the cells
          }
          if ([1, 2, 3, 12, 13,14].includes(data.row.index)&& data.column.index!==3) {
              data.cell.styles.halign = "center"; // Horizontally align text in the first column for specified rows
              data.cell.styles.valign = "middle"; // Vertically center the text within the cells
          }
          if (data.column.index === 3 && (data.row.index === 4 || data.row.index === 11)) {
              data.cell.styles.fontStyle = "bold"; // Set bold font style for specified cells in the fourth column
          }
          if (data.row.index === 0) {
              data.cell.styles.fillColor = [210, 210, 210]; // Set the fill color for the first row
              data.cell.styles.fontStyle = "bold"; // Set the font style
              data.cell.styles.fontSize = 16; // Set the font size for the first row
          }
      },
      
    });
    
      // Add a grey line above the footer
      const pageHeight = doc.internal.pageSize.getHeight();
      doc.setLineWidth(0.5); // Set line width
      doc.setDrawColor(192, 192, 192); // Set line color to grey
      doc.line(10, pageHeight - 25, 283, pageHeight - 25); // Draw a line above the footer
  
      // Additional content for the footer
      const marginBottomFooter = 10;
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
  
        // Add content on the left
        doc.text(
          "TSD-OPS-FOR-022-21, Rev.01",
          10,
          pageHeight - marginBottomFooter - 3,
          { align: "left" }
        );
  
        // Add content in the middle (random data)
        const randomData = "30-April-2022"; // static date
        const dateTextOptions = { align: "center" };
        doc.text(
          randomData,
          110,
          pageHeight - marginBottomFooter - 3,
          dateTextOptions
        );
  
        // Add margin-bottom and additional text below the date
        const additionalText =
          "This document is digitally generated and does not require a signature";
        const additionalTextWidth = doc.getTextWidth(additionalText);
        const additionalTextX =
          (doc.internal.pageSize.getWidth() - additionalTextWidth) / 2 ;
        doc.text(
          additionalText,
          additionalTextX,
          pageHeight - marginBottomFooter + 5
        ); // Adjusted margin-bottom
  
        // Add content on the right (page number)
        const pageString = `Page ${i}`;
        const pageStringWidth =
          doc.getStringUnitWidth(pageString) * doc.internal.getFontSize();
        const marginRight = 20; // Adjust margin from the right edge
        doc.text(
          pageString,
          doc.internal.pageSize.getWidth() - pageStringWidth + marginRight,
          pageHeight - marginBottomFooter - 3,
          { align: "right" }
        ); // Adjusted the x-coordinate here
      }
  
      // Save the PDF
      doc.save("Equipment_Preservation_Form_2.pdf");
    }, 500); // Timeout of 0.5 seconds
  };
  
  const handleDosplanPreview = () => {
    // setShowDosplanPreview(true);
    previewDosplan();
  };
  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleDosplanClosePreview = () => {
    setShowDosplanPreview(false);
  };
  const checkCompleteness = () => {
    if (
      projectList[0]?.Sales_Order_No &&
      projectList[0]?.Rig_Location &&
      projectList[0]?.Service_Component &&
      projectList[0]?.Customer_Name &&
      projectList[0]?.Rig_Name &&
      projectList[0]?.Supervisor_Names &&
      projectList[0]?.Technician_Names &&
      projectList[0]?.Po_Days
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  };

  const [isCompleteDos, setIsCompleteDos] = useState(false);
  const checkDosCompleteness = () => {
    if (
      projectList[0]?.Sales_Order_No &&
      projectList[0]?.Document_Date &&
      projectList[0]?.Customer_Name &&
      projectList[0]?.Rig_Type &&
      projectList[0]?.Service_Component &&
      projectList[0]?.Rig_Location &&
      projectList[0]?.Tool1 &&
      projectList[0]?.Make1 &&
      projectList[0]?.Model1 &&
      projectList[0]?.Serial_Number1 &&
      projectList[0]?.Acceptance_Criteria1 &&
      projectList[0]?.Tool2 &&
      projectList[0]?.Make2 &&
      projectList[0]?.Model2 &&
      projectList[0]?.Serial_Number2 &&
      projectList[0]?.Acceptance_Criteria2 &&
      projectList[0]?.Estimated_Date_Of_Commencement &&
      projectList[0]?.Estimated_Project_Completion_Month &&
      projectList[0]?.Po_Date !== "Invalid Date" &&
      projectList[0]?.Supervisor_Names &&
      projectList[0]?.Technician_Names
    ) {
      setIsCompleteDos(true);
    } else {
      setIsCompleteDos(false);
    }
  };

  const [isCompletemob, setIsCompletemob] = useState(false);
  const checkmobCompleteness = () => {
    if (
      projectList[0]?.Sales_Order_No &&
      projectList[0]?.Document_Date &&
      projectList[0]?.Rig_Name &&
      projectList[0]?.Service_Component &&
      projectList[0]?.Estimated_Date_Of_Commencement &&
      projectList[0]?.Estimated_Project_Completion_Month
    ) {
      setIsCompletemob(true);
    } else {
      setIsCompletemob(false);
    }
  };

  const [isCompleteevf1, setIsCompleteevf1] = useState(false);
  const checkevf1Completeness = () => {
    if (
      projectList[0]?.Tool1 &&
      projectList[0]?.Document_Date &&
      projectList[0]?.Model1 &&
      projectList[0]?.Serial_Number1
    ) {
      setIsCompleteevf1(true);
    } else {
      setIsCompleteevf1(false);
    }
  };

  const [isCompleteevf2, setIsCompleteevf2] = useState(false);
  const checkevf2Completeness = () => {
    if (
      projectList[0]?.Tool2 &&
      projectList[0]?.Document_Date &&
      projectList[0]?.Model2 &&
      projectList[0]?.Serial_Number2
    ) {
      setIsCompleteevf2(true);
    } else {
      setIsCompleteevf2(false);
    }
  };

  const [isCompleteevf3, setIsCompleteevf3] = useState(false);
  const checkevf3Completeness = () => {
    if (
      projectList[0]?.Tool1 &&
      projectList[0]?.Estimated_Date_Of_Commencement &&
      projectList[0]?.Model1 &&
      projectList[0]?.Serial_Number1
    ) {
      setIsCompleteevf3(true);
    } else {
      setIsCompleteevf3(false);
    }
  };

  const [isCompleteevf4, setIsCompleteevf4] = useState(false);
  const checkevf4Completeness = () => {
    if (
      projectList[0]?.Tool2 &&
      projectList[0]?.Estimated_Date_Of_Commencement &&
      projectList[0]?.Model2 &&
      projectList[0]?.Serial_Number2
    ) {
      setIsCompleteevf4(true);
    } else {
      setIsCompleteevf4(false);
    }
  };

  const [isCompletepf1, setIsCompletepf1] = useState(false);
  const checpf1Completeness = () => {
    if (
      projectList[0]?.Sales_Order_No &&
      projectList[0]?.Po_No &&
      projectList[0]?.Rig_Name &&
      projectList[0]?.Tool1 &&
      projectList[0]?.Make1 &&
      projectList[0]?.Model1 &&
      projectList[0]?.Serial_Number1 &&
      projectList[0]?.Calibration_Date1 &&
      projectList[0]?.Calibration_Due_Date1 &&
      projectList[0]?.Estimated_Project_Completion_Month
    ) {
      setIsCompletepf1(true);
    } else {
      setIsCompletepf1(false);
    }
  };

  const [isCompletepf2, setIsCompletepf2] = useState(false);
  const checpf2Completeness = () => {
    if (
      projectList[0]?.Sales_Order_No &&
      projectList[0]?.Po_No &&
      projectList[0]?.Rig_Name &&
      projectList[0]?.Tool2 &&
      projectList[0]?.Make2 &&
      projectList[0]?.Model2 &&
      projectList[0]?.Serial_Number2 &&
      projectList[0]?.Calibration_Date2 &&
      projectList[0]?.Calibration_Due_Date2 &&
      projectList[0]?.Estimated_Project_Completion_Month
    ) {
      setIsCompletepf2(true);
    } else {
      setIsCompletepf2(false);
    }
  };

  useEffect(() => {
    checkCompleteness();
    checkDosCompleteness();
    checkmobCompleteness();
    checkevf1Completeness();
    checkevf2Completeness();
    checkevf3Completeness();
    checkevf4Completeness();
    checpf1Completeness();
    checpf2Completeness();
  });

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const res = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
        );
        const filter = res.data.data.filter((el) => el.ID_No == id);
        setProjectList(filter);
        setFormData({
          ...formData,
          Sales_Order_No: filter[0]?.Sales_Order_No,
          Rig_Name: filter[0]?.Rig_Name,
          Po_No: filter[0]?.Po_No,
          Tool1: filter[0]?.Tool1,
          Model1: filter[0]?.Model1,
          Make1: filter[0]?.Make1,
          Serial_Number1: filter[0]?.Serial_Number1,
          Calibration_Date1: filter[0]?.Calibration_Date1,
          Calibration_Due_Date1: filter[0]?.Calibration_Due_Date1,
          Estimated_Project_Completion_Month:
            filter[0]?.Estimated_Project_Completion_Month,
        });

        if (projectList[0]?.Document_Date) {
          const dateParts = projectList[0].Document_Date.split("/");
          const originalDate = new Date(
            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
          );
          const newDate = new Date(
            originalDate.getTime() + 3 * 24 * 60 * 60 * 1000
          );

          // Format the date as 'DD/MM/YYYY'
          const formattedDay = newDate.getDate().toString().padStart(2, "0");
          const formattedMonth = (newDate.getMonth() + 1)
            .toString()
            .padStart(2, "0"); // Month is zero-based, so add 1
          const formattedYear = newDate.getFullYear();

          const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;
          setdate(formattedDate);
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    fetchProject();
  }, []);



  const getLegalRequirementsFileName = (rigLocation) => {
    const matchingCountry = searchResults.find(result => result.country === rigLocation);
    if (matchingCountry) {
      return matchingCountry.File_name_for_legal_requirements;
    } else {
      const filteredResults = searchResults.filter(result => result.country === rigLocation);
      if (filteredResults.length > 0) {
        return filteredResults[0].File_name_for_legal_requirements;
      } else {
        return "-";
      }
    }
  };

  useEffect(() => {
    async function fetchLegalDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/legal_details"
        );
        setSearchResults(response.data);
        console.log(searchResults[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchLegalDetails();
  }, []);

  // const generatePDF = () => {
  //   const input = document.getElementById('workplan');
  //   const inputHeight = input.clientHeight;
  //   const inputWidth = input.clientWidth;
  //   html2canvas(input, { height: inputHeight, width: inputWidth }).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('p', 'mm', 'a4'); // Set PDF document size to A4
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
  //     // Save or preview the PDF
  //     pdf.save('workplan.pdf');
  //   });
  //   };
  const [downLoadStart, setDownLoadStart] = useState(false);
  const [downLoadStartWorkPlan, setDownLoadStartWorkPlan] = useState(false);

  // const generatePDF1 = async () => {
  //   setDownLoadStartWorkPlan(true);
  //   // Create new jsPDF instance
  //   // Create new jsPDF instance with landscape orientation
  //   const doc = new jsPDF({
  //     orientation: 'portrait'
  //   });

  //   // Set the desired row heights
  //   const rowHeights = ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']; // Add more row heights as needed

  //   // Define column widths
  //   const cellWidths = [40, 140]; // Replace 'auto' with desired width for each column

  //   // Add image to the PDF
  //   const imgWidth = 40; // Width of the image
  //   const imgHeight = 12; // Height of the image
  //   const x = 10; // Calculate center position horizontally
  //   const y = 5; // Calculate center position vertically
  //   doc.addImage(Erogonlogo, 'PNG', x, y, imgWidth, imgHeight); // Position the image at the center

  //   // Add header
  //   doc.setFontSize(10);
  //   doc.setTextColor(0); // Set font color to black
  //   doc.setFont('helvetica', 'bold');
  //   doc.setFontSize(16);
  //   doc.text("Work Plan", 110, 15, { align: "center" });
  //   doc.setFont('helvetica', 'normal');
  //   doc.setFontSize(10);

  //   // Get the table using useRef after a delay of 10 seconds
  //   setTimeout(() => {
  //     const table = workplanref.current;

  //     // Ensure the table element is captured correctly
  //     console.log("table table table table table table", table);

  //     // Position the table below the header line with a margin
  //     doc.autoTable({
  //       html: table,
  //       theme: 'grid', // Use the grid theme
  //       startY: 21, // Start position of the table (below the grey line)
  //       margin: { top: 15 }, // Increase the margin below the grey line
  //       didParseCell: function (data) {
  //         console.log("table data data data data data data data data", data);
  //         if (data.row.index < rowHeights.length) {
  //           data.cell.styles.rowHeight = rowHeights[data.row.index];
  //           // Set overflow to linebreak
  //           data.cell.styles.overflow = 'linebreak';
  //           // Set border color to black
  //           data.cell.styles.lineWidth = 0.1;
  //           data.cell.styles.lineColor = [0, 0, 0];

  //           // Apply styles to header cells (assuming the second row as the header)
  //           if (data.row.index === 0 && data.row.section === 'head') {
  //             data.cell.styles.fillColor = [0, 0, 0]; // Black background color
  //             data.cell.styles.textColor = [255, 255, 255]; // White text color
  //             data.cell.styles.fontStyle = 'bold'; // Make the text bold
  //           }
  //         }
  //       },
  //       styles: {
  //         cellWidth: 'wrap',
  //         textColor: [0, 0, 0] // Set font color to black
  //       },
  //       columnStyles: {
  //         0: { cellWidth: cellWidths[0], halign: 'center' }, // Set horizontal alignment to center
  //         1: { cellWidth: cellWidths[1], halign: 'left' }, // Set horizontal alignment to center
  //         // Add more columns if needed
  //       }
  //     });

  //     // Add a grey line above the footer
  //     const pageHeight = doc.internal.pageSize.getHeight();
  //     doc.setLineWidth(0.5); // Set line width
  //     doc.setDrawColor(192, 192, 192); // Set line color to grey
  //     doc.line(10, pageHeight - 18, 200, pageHeight - 18); // Draw a line above the footer

  //     // Add content on the left (Prepared By)
  //     doc.setFont('helvetica', 'bold');
  //     doc.text("Prepared By: Shubham Mehta", 10, pageHeight - 22, { align: "left" });
  //     doc.setFont('helvetica', 'normal');

  //     // Add content on the right (Approved By)
  //     doc.setFont('helvetica', 'bold');
  //     doc.text("Approved By: Nishad Majeed", 200, pageHeight - 22, { align: "right" });
  //     doc.setFont('helvetica', 'normal');

  //     const marginBottomFooter = 10; // Set margin-bottom for the footer content
  //     const pageCount = doc.internal.getNumberOfPages();
  //     for (let i = 1; i <= pageCount; i++) {
  //       doc.setPage(i);
  //       doc.setFontSize(10);

  //       // Add content on the left
  //       doc.text("TSD-OPS-FOR-021-21, Rev 1", 10, pageHeight - marginBottomFooter - 3, { align: "left" });

  //       // Add content in the middle (random data)
  //       const randomData = "30-April-2022"; // static date
  //       const dateTextOptions = { align: "center" };
  //       doc.text(randomData, 110, pageHeight - marginBottomFooter - 3, dateTextOptions);

  //       // Add margin-bottom and additional text below the date
  //       const additionalText = "This document is digitally generated and does not require a signature";
  //       const additionalTextWidth = doc.getTextWidth(additionalText);
  //       const additionalTextX = (doc.internal.pageSize.getWidth() - additionalTextWidth) / 2;
  //       doc.text(additionalText, additionalTextX, pageHeight - marginBottomFooter + 5); // Adjusted margin-bottom

  //       // Add content on the right (page number)
  //       const pageString = `${i}`;
  //       const pageStringWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize();
  //       const marginRight = 20; // Adjust margin from the right edge
  //       // doc.text(pageString, doc.internal.pageSize.getWidth() - pageStringWidth + marginRight, pageHeight - marginBottomFooter - 3, { align: "right" }); // Adjusted the x-coordinate here
  //       doc.text(pageString, 195, pageHeight - marginBottomFooter - 3); // Adjusted the x-coordinate here
  //     }

  //     // Save the PDF
  //     doc.save('workplan.pdf');
  //     setDownLoadStartWorkPlan(false);
  //   }, 500); // Delay of 10 seconds
  // }

  const generatePDF1 = async () => {
    setDownLoadStartWorkPlan(true);
    const doc = new jsPDF({
      orientation: 'portrait'
    });
  
    const rowHeights = ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']; // Add more row heights as needed

    const imgWidth = 40;
    const imgHeight = 12;
    const x = 10;
    const y = 5;
    doc.addImage(Erogonlogo, 'PNG', x, y, imgWidth, imgHeight);
  
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(16);
    doc.text("Work Plan", 110, 15, { align: "center" });
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
  
    setTimeout(() => {
      const table = workplanref.current;
  
      const workplanPageHeight = doc.internal.pageSize.getHeight();
      const startY = 21;
      const marginBottom = 22; // Footer height reserved
  
      doc.autoTable({
        html: table,
        theme: 'grid',
        startY: startY,
        margin: { top: 15, bottom: marginBottom },
        pageBreak: 'auto', // Ensures automatic page breaking
        tableWidth: 'auto', // Adjust table width to fit within page boundaries
        styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          valign: 'middle'
        },
        columnStyles: {
          0: { cellWidth: 40, halign: 'center' },
          1: { cellWidth: 140, halign: 'left' }
        },
        headStyles: {
          fillColor: [24, 18, 69], // Custom color for the header
          textColor: [255, 255, 255], // Text color in the header
          fontStyle: "bold", // Font style for the header
          valign: "middle", // Vertical alignment of the content in the header
          halign: "center", // Horizontal alignment of the content in the header
        },
        didParseCell: function (data) {
          console.log("table data data data data data data data data", data);
          if (data.row.index < rowHeights.length) {
            data.cell.styles.rowHeight = rowHeights[data.row.index];
            // Set overflow to linebreak
            data.cell.styles.overflow = 'linebreak';
            // Set border color to black
            data.cell.styles.lineWidth = 0.1;
            data.cell.styles.lineColor = [0, 0, 0];
            data.cell.styles.textColor = [0, 0, 0]; // White text color

            // Apply styles to header cells (assuming the second row as the header)
            if (data.row.index === 0 && data.row.section === 'head') {
              data.cell.styles.fillColor = [23, 80, 126]; // Blue background color
              data.cell.styles.textColor = [255, 255, 255]; // White text color
              data.cell.styles.fontStyle = 'bold'; // Make the text bold
            }
          }
        },
        didDrawPage: function(data) {
          // Handle drawing the footer on each new page
          const footerPosY = workplanPageHeight - marginBottom + 2;
          doc.setFont('helvetica', 'bold');

          // Parse the date in dd/mm/yyyy format
        const rawDate = projectList[0]?.Document_Date;
        const [day, month, year] = rawDate.split('/').map(Number); // Split and parse as numbers
        const jsDate = new Date(year, month - 1, day); // Convert to JS Date object
        jsDate.setDate(jsDate.getDate() + 1); // Add one day
        const formattedDate = `${String(jsDate.getDate()).padStart(2, '0')}/${String(jsDate.getMonth() + 1).padStart(2, '0')}/${jsDate.getFullYear()}`;

          doc.text("Prepared By: Jancy K Mathew", 10, footerPosY-5, { align: "left" });
          doc.text("Approved By: Nishad Majeed", 200, footerPosY-5, { align: "right" });
          doc.text(`Date: ${formattedDate}`,10,footerPosY, {align: "left"})
          doc.text(`Date: ${formattedDate}`,200,footerPosY, {align: "right"})
          doc.setFont('helvetica', 'normal');
          doc.setDrawColor(192, 192, 192);
          doc.line(10, footerPosY + 4, 200, footerPosY + 4); // Draw a line in the footer
        }
      });
  
      // Add page numbers and additional footer details on each page
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        const pageFooterPosY = workplanPageHeight - 10; // Adjust position for page number
        doc.text("TSD-OPS-FOR-021-21, Rev 1", 10, pageFooterPosY);
        doc.text("30-April-2022", 110, pageFooterPosY, { align: "center" });
        doc.text("This document is digitally generated and does not require a signature.",
          110, pageFooterPosY + 6, { align: "center", maxWidth: 190 });
        doc.text(`${i}`, 195, pageFooterPosY);
      }
  
      doc.save('workplan.pdf');
      setDownLoadStartWorkPlan(false);
    }, 500);
  }

  const downloadPdfBtn = async (e) => {
    e.preventDefault();
    await generatePDF1();
  };

  const previewWorkplan = async () => {
    await setDownLoadStart(true);
    const parentDiv = document.getElementById("parentDivWorkplan");
    parentDiv.style.display = "block";
    const input = document.getElementById("workplan2");
    const inputHeight = await input.clientHeight;
    const inputWidth = await input.clientWidth;
    html2canvas(input, {
      height: inputHeight,
      width: inputWidth,
      scale: 7,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4"); // Set PDF document size to A4
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
      const pdfBlob = pdf.output("blob"); // Get PDF blob
      const pdfUrl = URL.createObjectURL(pdfBlob); // Create object URL for the PDF blob
      // Open the PDF in a new tab
      window.open(pdfUrl, "_blank");
      setDownLoadStart(false);
    });
    parentDiv.style.display = "none";
  };

  // const generatePDF3 = async () => {

  //   window.scrollTo(0, 0);
  //   await setDownLoadStart(true);
  //   const input = document.getElementById("dosplan");
  //   const input2 = document.getElementById("dosplan2");
  //   const input3 = document.getElementById("dosplan3");
  //   const input4 = document.getElementById("dosplan4");
  //   const input5 = document.getElementById("dosplan5");
  //   const input6 = document.getElementById("dosplan6");
  //   const inputHeight = await input.clientHeight;
  //   const inputWidth = await input.clientWidth;
  //   const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4

  //   await delay(2000);
  //   html2canvas(input, {
  //     height: inputHeight,
  //     width: inputWidth,
  //     imageTimeout: 15000,
  //     scale: 7,
  //     format: [2600, 1300],
  //   }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");

  //   await delay(2000);
  //     html2canvas(input2, {
  //       height: inputHeight,
  //       width: inputWidth,
  //       imageTimeout: 15000,
  //       scale: 7,
  //       format: [2600, 1300],
  //     }).then((canvas1) => {
  //       const imgData1 = canvas1.toDataURL("image/png");

  //   await delay(2000);
  //       html2canvas(input3, {
  //         height: inputHeight,
  //         width: inputWidth,
  //         imageTimeout: 15000,
  //         scale: 7,
  //         format: [2600, 1300],
  //       }).then((canvas2) => {
  //         const imgData2 = canvas2.toDataURL("image/png");

  //   await delay(2000);
  //         html2canvas(input4, {
  //           height: inputHeight,
  //           width: inputWidth,
  //           imageTimeout: 15000,
  //           scale: 7,
  //           format: [2600, 1300],
  //         }).then((canvas3) => {
  //           const imgData3 = canvas3.toDataURL("image/png");

  //   await delay(2000);
  //           html2canvas(input5, {
  //             height: inputHeight,
  //             width: inputWidth,
  //             imageTimeout: 15000,
  //             scale: 7,
  //             format: [2600, 1300],
  //           }).then((canvas4) => {
  //             const imgData4 = canvas4.toDataURL("image/png");

  //   await delay(2000);
  //             html2canvas(input6, {
  //               height: inputHeight,
  //               width: inputWidth,
  //               imageTimeout: 15000,
  //               scale: 7,
  //               format: [2600, 1300],
  //             }).then((canvas5) => {
  //               const imgData5 = canvas5.toDataURL("image/png");
  //               const pdfWidth = pdf.internal.pageSize.getWidth();
  //               const pdfHeight = pdf.internal.pageSize.getHeight();
  //               var position = 0;
  //               pdf.addImage(
  //                 imgData,
  //                 "PNG",
  //                 position,
  //                 0,
  //                 pdfWidth,
  //                 pdfHeight,
  //                 "",
  //                 "FAST"
  //               ); // Adjust image position and size to fit the PDF
  //               // Save or preview the PDF
  //               pdf.addPage();
  //               pdf.addImage(
  //                 imgData1,
  //                 "PNG",
  //                 position,
  //                 0,
  //                 pdfWidth,
  //                 pdfHeight,
  //                 "",
  //                 "FAST"
  //               );
  //               pdf.addPage();
  //               pdf.addImage(
  //                 imgData2,
  //                 "PNG",
  //                 position,
  //                 0,
  //                 pdfWidth,
  //                 pdfHeight,
  //                 "",
  //                 "FAST"
  //               );
  //               pdf.addPage();
  //               pdf.addImage(
  //                 imgData3,
  //                 "PNG",
  //                 position,
  //                 0,
  //                 pdfWidth,
  //                 pdfHeight,
  //                 "",
  //                 "FAST"
  //               );
  //               pdf.addPage();
  //               pdf.addImage(
  //                 imgData4,
  //                 "PNG",
  //                 position,
  //                 0,
  //                 pdfWidth,
  //                 pdfHeight,
  //                 "",
  //                 "FAST"
  //               );
  //               pdf.addPage();
  //               pdf.addImage(
  //                 imgData5,
  //                 "PNG",
  //                 position,
  //                 0,
  //                 pdfWidth,
  //                 pdfHeight,
  //                 "",
  //                 "FAST"
  //               );

  //               pdf.save("dosplan.pdf");
  //             });
  //           });
  //         });
  //       });
  //     });
  //   });
  //   setDownLoadStart(false);
  // };

  const generatePDF3 = async () => {
    setDownLoadStartWorkPlan(true);
    // Create new jsPDF instance
    // Create new jsPDF instance with landscape orientation
    const doc = new jsPDF({
      orientation: 'landscape'
    });

    // Set the desired row heights
    const rowHeights = ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']; // Add more row heights as needed

    // Define column widths
    const cellWidths = [10, 40, 60, 40, 25, 40, 50]; // Replace 'auto' with desired width for each column

    // Function to add page break after footer
    const addPageBreakAfterFooter = (doc) => {
      doc.addPage(); // Add page break
    };

    // Get the table using useRef after a delay of 10 seconds
    setTimeout(() => {

      const imgWidth = 40; // Width of the image
      const imgHeight = 12; // Height of the image
      const x = 20; // Calculate center position horizontally
      const y = 35; // Calculate center position vertically

      // Your table retrieval code here

      // Loop through each table
      for (let i = 1; i <= 7; i++) {
        const table = dosref[i].current;

        const headerColumnStyles = {
          0: { cellWidth: cellWidths[0], halign: 'center' },
          1: { cellWidth: cellWidths[1], halign: 'left' },
          2: { cellWidth: cellWidths[2], halign: 'left' },
          3: { cellWidth: cellWidths[3], halign: 'left' },
          4: { cellWidth: cellWidths[4], halign: 'left' },
          5: { cellWidth: cellWidths[5], halign: 'left' },
          6: { cellWidth: cellWidths[6], halign: 'left' },
          7: { cellWidth: cellWidths[7], halign: 'left' },
        };

        doc.autoTable({
          html: table,
          theme: 'grid', // Use the grid theme
          startY: 15, // Start position of the table (below the grey line)
          styles: {
            cellWidth: 'wrap',
            textColor: [0, 0, 0] // Set font color to black
          },
          columnStyles: {
            0: { cellWidth: cellWidths[0], halign: 'center' }, // Set horizontal alignment to center
            1: { cellWidth: cellWidths[1], halign: 'left' }, // Set horizontal alignment to center
            2: { cellWidth: cellWidths[2], halign: 'left' },
            3: { cellWidth: cellWidths[3], halign: 'left' },
            4: { cellWidth: cellWidths[4], halign: 'left' },
            5: { cellWidth: cellWidths[5], halign: 'left' },
            6: { cellWidth: cellWidths[6], halign: 'left' },
            7: { cellWidth: cellWidths[7], halign: 'left' },
            // Add more columns if needed
          },
          didParseCell: function (data) {
            if (i === 6) {
              console.log("table data data data data data data data data", data);
            }
            if (data.row.index < rowHeights.length) {
              data.cell.styles.rowHeight = rowHeights[data.row.index];
              // Set overflow to linebreak
              data.cell.styles.overflow = 'linebreak';
              // Set border color to black
              data.cell.styles.lineWidth = 0.1;
              data.cell.styles.lineColor = [0, 0, 0];

              // Apply styles to header cells (assuming the second row as the header)

              if (data.row.section === 'head') {
                data.cell.styles.fillColor = [255, 255, 255]; //background color
                data.cell.styles.textColor = [0, 0, 0]; //  text color
                data.cell.styles.fontStyle = 'bold'; // Make the text bold
                data.cell.styles.valign = 'middle'; // Vertical alignment: center
                data.cell.styles.align = 'middle'; // Vertical alignment: center

                const columnStyle = headerColumnStyles[data.column.index];
                if (columnStyle) {
                  for (const key in columnStyle) {
                    data.cell.styles[key] = columnStyle[key];
                  }
                }
              }

              data.cell.styles.fillColor = [255, 255, 255]; // background color
              data.cell.styles.textColor = [0, 0, 0]; //text color

              const boldValues = [
                "I. Design Input",
                "II. Design Output",
                "III. Verification",
                "IV. Final Review and Approval",
                "V. Design Changes"
              ];
              if (boldValues.includes(data.cell.text[0])) {
                data.cell.styles.fontStyle = 'bold';
              }

              if (data.cell.text[0] === "Ergon Technologies Design and Development Plan") {
                data.cell.styles.fontSize = 16;
                data.cell.styles.fontStyle = 'bold';
                data.cell.styles.valign = 'middle'; // Vertical alignment: center
                data.cell.styles.align = 'center'; // Horizontal alignment: center
              }
            }
          }
        });

        // Add image to the PDF (common for all tables)
        doc.addImage(Erogonlogo, 'PNG', x, y, imgWidth, imgHeight); // Position the image at the center

        // Add a grey line above the footer and insert page break after footer
        if (i !== 7) {
          addPageBreakAfterFooter(doc);
        }
      }

      // Add footer content
      const marginBottomFooter = 10; // Set margin-bottom for the footer content
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);

        const pageHeight = doc.internal.pageSize.getHeight();

        if (i === pageCount) {
          // Add content on the left (Prepared By)
          doc.setFont('helvetica', 'bold');
          doc.text("Prepared By: Jancy K Mathew", 15, pageHeight - 22, { align: "left" });
          doc.setFont('helvetica', 'normal');

          // Add content on the right (Approved By)
          doc.setFont('helvetica', 'bold');
          doc.text("Approved By: Basil Rajan", 280, pageHeight - 22, { align: "right" });
          doc.setFont('helvetica', 'normal');
        }

        doc.setLineWidth(0.5); // Set line width
        doc.setDrawColor(192, 192, 192); // Set line color to grey
        doc.line(10, pageHeight - 18, 285, pageHeight - 18); // Draw a line above the footer

        // Add content on the left
        doc.text("TSD-OPS-FOR-009-19, Rev 2", 15, pageHeight - marginBottomFooter - 3, { align: "left" });

        // Add content in the middle (random data)
        const randomData = "06-May-2023"; // static date
        const dateTextOptions = { align: "center" };
        doc.text(randomData, 155, pageHeight - marginBottomFooter - 3, dateTextOptions);
        doc.text("This document is digitally generated and does not require a signature.",
          155, pageHeight - marginBottomFooter + 1, { align: "center", maxWidth: 190 });

        // Add content on the right (page number)
        const pageString = `${i}`;
        doc.text(pageString, 280, pageHeight - marginBottomFooter - 3); // Adjusted the x-coordinate here
      }

      // Save the PDF
      doc.save('dosplan.pdf');
      setDownLoadStartWorkPlan(false);
    }, 1000); // Delay of 1 second
  };

  const previewDosplan = async () => {
    const parentDiv = document.getElementById("parentDivDOSplan");
    parentDiv.style.display = "block";
    try {
      window.scrollTo(0, 0);
      await setDownLoadStart(true);
      const input = document.getElementById("dosplan");
      const input2 = document.getElementById("dosplan2");
      const input3 = document.getElementById("dosplan3");
      const input4 = document.getElementById("dosplan4");
      const input5 = document.getElementById("dosplan5");
      const input6 = document.getElementById("dosplan6");
      const input7 = document.getElementById("dosplan7");
      const inputHeight = await input.clientHeight;
      const inputWidth = await input.clientWidth;
      const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
      html2canvas(input, {
        height: inputHeight,
        width: inputWidth,
        imageTimeout: 15000,
        scale: 0.8,
      }).then((canvas) => {
        setImageData(canvas.toDataURL("image/png"));
      });

      html2canvas(input2, {
        height: inputHeight,
        width: inputWidth,
        imageTimeout: 15000,
        scale: 0.8,
      }).then((canvas1) => {
        setImageData1(canvas1.toDataURL("image/png"));
      });

      html2canvas(input3, {
        height: inputHeight,
        width: inputWidth,
        imageTimeout: 15000,
        scale: 0.8,
      }).then((canvas2) => {
        setImageData2(canvas2.toDataURL("image/png"));
      });

      html2canvas(input4, {
        height: inputHeight,
        width: inputWidth,
        imageTimeout: 15000,
        scale: 0.8,
      }).then((canvas3) => {
        setImageData3(canvas3.toDataURL("image/png"));
      });

      html2canvas(input5, {
        height: inputHeight,
        width: inputWidth,
        imageTimeout: 15000,
        scale: 0.8,
      }).then((canvas4) => {
        setImageData4(canvas4.toDataURL("image/png"));
      });

      html2canvas(input6, {
        height: inputHeight,
        width: inputWidth,
        imageTimeout: 15000,
        scale: 0.8,
      }).then((canvas5) => {
        setImageData5(canvas5.toDataURL("image/png"));
      });

      html2canvas(input7, {
        height: inputHeight,
        width: inputWidth,
        imageTimeout: 15000,
        scale: 0.8,
      }).then((canvas6) => {
        setImageData6(canvas6.toDataURL("image/png"));
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      var position = 0;
      pdf.addImage(
        imageData,
        "PNG",
        position,
        0,
        pdfWidth,
        pdfHeight,
        "",
        "FAST"
      ); // Adjust image position and size to fit the PDF
      // Save or preview the PDF
      pdf.addPage();
      pdf.addImage(
        imageData1,
        "PNG",
        position,
        0,
        pdfWidth,
        pdfHeight,
        "",
        "FAST"
      );
      pdf.addPage();
      pdf.addImage(
        imageData2,
        "PNG",
        position,
        0,
        pdfWidth,
        pdfHeight,
        "",
        "FAST"
      );
      pdf.addPage();
      pdf.addImage(
        imageData3,
        "PNG",
        position,
        0,
        pdfWidth,
        pdfHeight,
        "",
        "FAST"
      );
      pdf.addPage();
      pdf.addImage(
        imageData4,
        "PNG",
        position,
        0,
        pdfWidth,
        pdfHeight,
        "",
        "FAST"
      );
      pdf.addPage();
      pdf.addImage(
        imageData5,
        "PNG",
        position,
        0,
        pdfWidth,
        pdfHeight,
        "",
        "FAST"
      );
      pdf.addPage();
      pdf.addImage(
        imageData6,
        "PNG",
        position,
        0,
        pdfWidth,
        pdfHeight,
        "",
        "FAST"
      );
      window.open(pdf.output("bloburl"), "_blank");
      // pdf.open("dosplan.pdf");
      setDownLoadStart(false);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
    parentDiv.style.display = "none";
  };

  const downloadPdfBtn2 = (e) => {
    e.preventDefault();
    generatePDF3(); // Delay the function call by 100 milliseconds
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    await downloadPDF();
  };
  const generatePDFa = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("ievf1");
    const inputHeight = input.clientHeight * 0.95;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        pdf.save("Euipmentinitial.pdf");
      }
    );
    setDownLoadStart(false);
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    await previewa();
  };

  const previewa = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("ievf1");
    const inputHeight = input.clientHeight * 0.95;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        window.open(pdf.output("bloburl"), "_blank");
      }
    );
    setDownLoadStart(false);
  };

  const handleSubmit3 = async (e) => {
    e.preventDefault();
    await downloadPDF2();
  };

  const generatePDFb = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("ievf2");
    const inputHeight = input.clientHeight * 0.95;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        pdf.save("Euipmentinitial.pdf");
      }
    );
    setDownLoadStart(false);
  };

  const handleSubmit4 = async (e) => {
    e.preventDefault();
    await previewb();
  };

  const handleSubmit5 = async (e) => {
    e.preventDefault();
    await previewc();
  };

  const handleSubmit6 = async (e) => {
    e.preventDefault();
    await downloadPDF3();
  };

  const handleSubmit7 = async (e) => {
    e.preventDefault();
    await previewd();
  };

  const handleSubmit8 = async (e) => {
    e.preventDefault();
    await downloadPDF4();
  };

  const generatePDFc = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("evf1");
    const inputHeight = input.clientHeight * 0.95;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        pdf.save("Euipmentfinal.pdf");
      }
    );
    setDownLoadStart(false);
  };

  const generatePDFd = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("evf2");
    const inputHeight = input.clientHeight * 0.95;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        pdf.save("Euipmentfinal.pdf");
      }
    );
    setDownLoadStart(false);
  };

  const previewb = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("ievf2");
    const inputHeight = input.clientHeight * 0.95;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        window.open(pdf.output("bloburl"), "_blank");
      }
    );
    setDownLoadStart(false);
  };

  const previewc = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("evf1");
    const inputHeight = input.clientHeight * 0.95;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        window.open(pdf.output("bloburl"), "_blank");
      }
    );
    setDownLoadStart(false);
  };

  const previewd = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("evf2");
    const inputHeight = input.clientHeight * 0.95;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        window.open(pdf.output("bloburl"), "_blank");
      }
    );
    setDownLoadStart(false);
  };

  //mobilisation start
  const addAndModulo = (num1, num2) => {
    const result = (num1 + num2) % 12;
    return result === 0 ? 12 : result;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [modifiedDate, setModifiedDate] = useState([]);

  const handleSubmitmp = (e) => {
    e.preventDefault();
    generatePDFmp();
  };

  // const generatePDF = (formData) => {
  //     const input = document.getElementById('mobplan');
  //     const inputHeight = document.body.clientHeight;
  //     const inputWidth = document.body.clientWidth;
  //     html2canvas(input, { height: inputHeight, width: inputWidth }).then((canvas) => {
  //         const imgData = canvas.toDataURL('image/png');
  //         const pdf = new jsPDF('l', 'mm', 'a4'); // Set PDF document size to A4
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const pdfHeight = pdf.internal.pageSize.getHeight();
  //         pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
  //         // Save or preview the PDF
  //         pdf.save('mobplan.pdf');
  //     });
  // };

  const generatePDFmp = async () => {
    // Initialize jsPDF with landscape orientation
    const doc = new jsPDF({
      orientation: 'landscape',
      format: [400, 210], // increase width
      // marginRight: 15 // 15-unit right margin
    });
  
    // Calculate positions and dimensions for the image
    const imgWidth = 45;  // Width of the image
    const imgHeight = 12;  // Height of the image
    const x = 10;          // Left position
    const y = 10;          // Top position
  
    doc.addImage(Erogonlogo, 'PNG', x, y, imgWidth, imgHeight);
  
    // Set header
    doc.setFont('Helvetica', 'bold');
    doc.text("MOBILISATION PLAN", doc.internal.pageSize.getWidth()/2, 20, { align: 'center', fontSize: 16 });
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(6);

    // Add table
    setTimeout(() => {
      const mobPlanTable = mobPlanTableRef.current;
      const mobProjDet = mobProjDetRef.current;
      const mobMonths = mobMonthsRef.current;

      console.log("mobPlan Table Ref", mobPlanTableRef);
      console.log("mob ProjDet Ref", mobProjDetRef);
      console.log("mob Months Ref", mobMonthsRef);

      doc.autoTable({
        html: mobProjDet,
        startY: 35,
        theme: 'grid',
        styles: {
          cellPadding: 2,
          fontSize: 10,
          cellWidth: 'wrap',
          cellHeight: 6,
        },
        columnStyles: {
          0: { cellWidth: 25, fontStyle: 'bold' },  // Example for specific column width
          1: { cellWidth: 65 },
        },
        didParseCell: function (data) {
        }
      });

      doc.autoTable({
        html: mobMonths,
        startY: 70,
        theme: 'plain',
        styles: {
          cellPadding: 2,
          fontSize: 6,
          cellWidth: 'wrap',
          cellHeight: 6,
        },
        columnStyles: {
          0: { cellWidth: 122, halign: 'right', fontStyle: 'bold' },
          1: { cellWidth: 105, halign: 'right', fontStyle: 'bold'   },
          2: { cellWidth: 103, halign: 'right', fontStyle: 'bold'   },
        },
        didParseCell: function (data) {
          console.log("mobMonths data", data);
        },
      });

      doc.autoTable({
        html: mobPlanTable,
        useCss: true,
        startY: 75,
        theme: 'grid',
        styles: {
          cellPadding: 2,
          fontSize: 4,
          cellWidth: 'wrap',
          cellHeight: 4,
        },
        columnStyles: {
          0: { cellWidth: 8 },  // Example for specific column width
        },
        didParseCell: function (data) {
          // console.log("mobplan table data", data);
          // Conditional cell styling for backgrounds
          if (data.column.index >= 4) { // Assuming colored cells start from the 5th column
            switch (data.cell.raw.className) {
              case 'blue-background':
                data.cell.styles.fillColor = [0, 0, 255]; // Darker blue
                // data.cell.styles.fillColor = [173, 216, 230]; // Light blue
                break;
              case 'green-background':
                data.cell.styles.fillColor = [0, 128, 0]; // Darker green
                // data.cell.styles.fillColor = [144, 238, 144]; // Light green
                break;
              case 'blue-background2':
                data.cell.styles.fillColor = [0, 0, 255]; // Darker blue
                break;
              case 'green-background2':
                data.cell.styles.fillColor = [0, 128, 0]; // Darker green
                break;
            }
          }
        }
      });

      //Legend
      doc.setFontSize(8);
      doc.setFont('Helvetica', 'bold');
      doc.text("LEGEND:", 15, 137);
      doc.setFont('Helvetica', 'normal');

      // Blue: PROPOSED DATE
      doc.text("PROPOSED DATE", 30, 146);
      doc.setLineWidth(6); // Set line width
      doc.setDrawColor(0, 0, 255); // Set line color to blue
      doc.line(15, 145, 25, 145); // Draw a line

      // Green: ACTUAL DATE
      doc.text("ACTUAL DATE", 30, 156);
      doc.setLineWidth(6); // Set line width
      doc.setDrawColor(0, 128, 0); // Set line color to green
      doc.line(15, 155, 25, 155); // Draw a line
  
      console.log("page width, page height", doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
      // Footer
      doc.setFontSize(10);
      doc.text("Prepared by: Glesie Paderon", 15, doc.internal.pageSize.getHeight() - 23);
      doc.text("Approved by: Nishad Majeed", doc.internal.pageSize.getWidth() - 61, doc.internal.pageSize.getHeight() - 23);

      // Grey Line
      doc.setLineWidth(0.5); // Set line width
      doc.setDrawColor(192, 192, 192); // Set line color to grey
      doc.line(15, doc.internal.pageSize.getHeight() - 20, doc.internal.pageSize.getWidth() - 15, doc.internal.pageSize.getHeight() - 20); // Draw a line above the footer
  
      // Document information like revision and date
      doc.text("TSD-OPS-FOR-016-19, Rev.1", 15, doc.internal.pageSize.getHeight() - 12);
      doc.text("1 Apr 2022", doc.internal.pageSize.getWidth()/2, doc.internal.pageSize.getHeight() - 12, { align: 'center' });
      doc.text("1", doc.internal.pageSize.getWidth() - 17, doc.internal.pageSize.getHeight() - 12);
      doc.text("This document is digitally generated and does not require a signature.", doc.internal.pageSize.getWidth()/2, doc.internal.pageSize.getHeight() - 6, { align: 'center' });

      // Save the PDF
      doc.save('mobilisation_plan.pdf');
    }, 500); // Adding a slight delay to ensure the DOM is fully loaded
  };

  const previewPDFmp = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("mobplan");
    html2canvas(input, {
      scale: 7,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
        format: [2800, 1500],
      });
      pdf.addImage(imgData, "JPEG", 10, 10);
      // pdf.output('dataurlnewwindow');
      window.open(pdf.output("bloburl"), "_blank");
    });
    setDownLoadStart(false);
  };

  const handleSubmitp1 = async (e) => {
    e.preventDefault();

    await downloadPDF5();
  };

  const generatePDFp1 = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("perv1");
    const inputHeight = input.clientHeight * 1.03;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        pdf.save("perservationform.pdf");
      }
    );
    setDownLoadStart(false);
  };

  const handleprep1 = async (e) => {
    e.preventDefault();

    await generateprep1();
  };

  const generateprep1 = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("perv1");
    const inputHeight = input.clientHeight * 1.03;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        window.open(pdf.output("bloburl"), "_blank");
      }
    );
    setDownLoadStart(false);
  };

  const handleSubmitp2 = async (e) => {
    e.preventDefault();

    await downloadPDF6();
  };

  const generatePDFp2 = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("perv2");
    const inputHeight = input.clientHeight * 1.03;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        pdf.save("perservationform.pdf");
      }
    );
    setDownLoadStart(false);
  };

  const handleprep2 = async (e) => {
    e.preventDefault();

    await generateprep2();
  };

  const generateprep2 = async () => {
    await setDownLoadStart(true);
    const input = document.getElementById("perv2");
    const inputHeight = input.clientHeight * 1.03;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        window.open(pdf.output("bloburl"), "_blank");
      }
    );
    setDownLoadStart(false);
  };

  function getMonthName(monthNumber) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
    } else {
      return "Invalid month number";
    }
  }

  function getMonthDays(monthNumber, year) {
    if (monthNumber === 2) {
      // Check if the year is a leap year
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
    }
  
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return daysInMonth[monthNumber - 1];
  }
  
  const firstday = (() => {
    if (projectList[0]?.Document_Date) {
      const dateParts = projectList[0].Document_Date.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = newDate.getDate().toString().padStart(2, "0");

      console.log(formattedDate);

      return formattedDate;
    }
  })();

  const firstmonth = (() => {
    if (projectList[0]?.Document_Date) {
      const dateParts = projectList[0].Document_Date.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = (newDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      console.log(formattedDate);
      return formattedDate;
    }
  })();

  const secondday = (() => {
    if (projectList[0]?.Estimated_Date_Of_Commencement) {
      const dateParts =
        projectList[0].Estimated_Date_Of_Commencement.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() - 1 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = newDate.getDate().toString().padStart(2, "0");

      console.log(formattedDate);

      return formattedDate;
    }
  })();

  const secondmonth = (() => {
    if (projectList[0]?.Estimated_Date_Of_Commencement) {
      const dateParts =
        projectList[0].Estimated_Date_Of_Commencement.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() - 1 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = (newDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      console.log(formattedDate);
      return formattedDate;
    }
  })();

  const thirdday = (() => {
    if (projectList[0]?.Estimated_Date_Of_Commencement) {
      const dateParts =
        projectList[0].Estimated_Date_Of_Commencement.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 0 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = newDate.getDate().toString().padStart(2, "0");

      console.log(formattedDate);

      return formattedDate;
    }
  })();

  const thirdmonth = (() => {
    if (projectList[0]?.Estimated_Date_Of_Commencement) {
      const dateParts =
        projectList[0].Estimated_Date_Of_Commencement.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 0 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = (newDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      console.log(formattedDate);
      return formattedDate;
    }
  })();

  const fourthday = (() => {
    if (projectList[0]?.Estimated_Date_Of_Commencement) {
      const dateParts =
        projectList[0].Estimated_Date_Of_Commencement.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = newDate.getDate().toString().padStart(2, "0");

      console.log(formattedDate);

      return formattedDate;
    }
  })();

  const fourthmonth = (() => {
    if (projectList[0]?.Estimated_Date_Of_Commencement) {
      const dateParts =
        projectList[0].Estimated_Date_Of_Commencement.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = (newDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      return formattedDate;
    }
  })();

  const fifthday = (() => {
    if (projectList[0]?.Estimated_Project_Completion_Month) {
      const dateParts =
        projectList[0].Estimated_Project_Completion_Month.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 0 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = newDate.getDate().toString().padStart(2, "0");

      console.log(formattedDate);

      return formattedDate;
    }
  })();

  const fifthmonth = (() => {
    if (projectList[0]?.Estimated_Project_Completion_Month) {
      const dateParts =
        projectList[0].Estimated_Project_Completion_Month.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 0 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = (newDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      return formattedDate;
    }
  })();

  const sixthday = (() => {
    if (projectList[0]?.Estimated_Project_Completion_Month) {
      const dateParts =
        projectList[0].Estimated_Project_Completion_Month.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = newDate.getDate().toString().padStart(2, "0");

      console.log(formattedDate);

      return formattedDate;
    }
  })();

  const sixthmonth = (() => {
    if (projectList[0]?.Estimated_Project_Completion_Month) {
      const dateParts =
        projectList[0].Estimated_Project_Completion_Month.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = (newDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      return formattedDate;
    }
  })();

  const seventhday = (() => {
    if (projectList[0]?.Estimated_Project_Completion_Month) {
      const dateParts =
        projectList[0].Estimated_Project_Completion_Month.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 15 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = newDate.getDate().toString().padStart(2, "0");

      console.log(formattedDate);

      return formattedDate;
    }
  })();

  const seventhmonth = (() => {
    if (projectList[0]?.Estimated_Project_Completion_Month) {
      const dateParts =
        projectList[0].Estimated_Project_Completion_Month.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 15 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = (newDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      return formattedDate;
    }
  })();

  const eightday = (() => {
    if (projectList[0]?.Estimated_Project_Completion_Month) {
      const dateParts =
        projectList[0].Estimated_Project_Completion_Month.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 18 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = newDate.getDate().toString().padStart(2, "0");

      console.log(formattedDate);

      return formattedDate;
    }
  })();

  const eightmonth = (() => {
    if (projectList[0]?.Estimated_Project_Completion_Month) {
      const dateParts =
        projectList[0].Estimated_Project_Completion_Month.split("/");
      const originalDate = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      const newDate = new Date(
        originalDate.getTime() + 18 * 24 * 60 * 60 * 1000
      );

      // Format the date as 'DD/MM/YYYY'
      const formattedDate = (newDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      return formattedDate;
    }
  })();

  const abc = (() => {
    if (projectList[0]?.Document_Date) {
      const dateParts = projectList[0].Document_Date.split("/");
      const year = parseInt(dateParts[2]);
      const originalDate = new Date(`${year}-${dateParts[1]}-${dateParts[0]}`);
      const newDate = new Date(originalDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  
      const docdatemon = newDate.getMonth() + 1; // Already in 1-based format
      return getMonthDays(docdatemon, newDate.getFullYear());
    }
  })();
  

  const z = 10; // Change this value to your desired constant
  const betaArray = []; // Array to store beta values

  for (let n = 1; n <= z; n++) {
    const beta = (() => {
      if (projectList[0]?.Document_Date) {
        const dateParts = projectList[0].Document_Date.split("/");
        const originalDate = new Date(
          `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
        );
        const newDate = new Date(
          originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
        );

        const docdatemon = (newDate.getMonth() + 1).toString().padStart(2, "0");
        const numberOfDays = getMonthDays(
          addAndModulo(parseInt(docdatemon), n), newDate.getFullYear()
        );
        return numberOfDays;
      }
    })();

    betaArray.push(beta); // Store the current beta value in the array
  }

  const headings1 = Array.from({ length: abc }, (_, index) => index + 1);
  const headings2 = Array.from(
    { length: betaArray[0] },
    (_, index) => index + 1
  );
  const headings3 = Array.from(
    { length: betaArray[1] },
    (_, index) => index + 1
  );
  const [apidocs, setapidocs] = useState(1);
  const [addnewclick, setaddnewclick] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [editrow, seteditrow] = useState("");
  const [indetails, setindetails] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [fetchFlag, setFetchFlag] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [displayPDf, setDisplayPDf] = useState(false);
  const [tempTeamSelection, setTempTeamSelection] = useState();
  const [tempSQP, setTempSQP] = useState("");
  const [tempRiskAssessment, setTempRiskAssessment] = useState();
  const [tempContractReview, setTempContractReview] = useState();
  const [tempEnquiry, setTempEnquiry] = useState();
  const [tempOrderConfirmation, setTempOrderConfirmation] = useState();
  const [removeTeamSelection, setRemoveTeamSelection] = useState(false)
  const [removeSQP, setRemoveSQP] = useState(false)
  const [removeRiskAssessment, setRemoveRiskAssessment] = useState(false)
  const [removeContractReview, setRemoveContractReview] = useState(false)
  const [removeEnquiry, setRemoveEnquiry] = useState(false)
  const [removeOrderConfirmation, setRemoveOrderConfirmation] = useState(false)

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setEditMode((prevEditMode) => !prevEditMode);
  };

  // mobilisation end
  const navigate = useNavigate();
  const handleDivClick1 = () => {
    navigate(`/project_checklist/:id`);
  };

  const handleDivClick2 = () => {
    navigate(`/manual-upload/:id`);
  };

  const handleAddNewDocument = () => {
    setindetails((previndetails) => [
      ...previndetails,
      { type: "", file: null },
    ]);
  };

  const handleCancel = () => {
    setDocuments([]);
    setEditMode(true);
  };

  const handleTypeChange = (index, value) => {
    setindetails((previndetails) => {
      const updatedDocuments = [...previndetails];
      updatedDocuments[index].type = value;
      return updatedDocuments;
    });
  };

  function getFileNameFromURL(url) {

    if (!url || typeof url !== 'string') {
      return "";
    }
    const parts = url.split("/");
    const fileName = parts[parts.length - 1];
    return fileName;
  }


  const handleFileChange = (name, event) => {
    console.log("//////////////////////////////////////////////*******")
    const temp = event.target.files[0];
    console.log(temp);
    // setDocuments((prevDocuments) => {
    //   const updatedDocuments = [...prevDocuments];
    //   updatedDocuments[index].file = temp;
    //   console.log(updatedDocuments);
    //   return updatedDocuments;
    // });

    setindetails((prevFileInputs) => {
      return prevFileInputs.map((fileInput) => {
        if (fileInput.field === name) {
          fileInput.type = name;
          fileInput.file = temp;
          return { ...fileInput, temp };
        }
        return fileInput;
      });
    });
    const temp2 = [...indetails];
    // temp2[name].file = temp;
    temp2.forEach((doc) => {
      if (event.target.name === "Team_Selection") {
        doc.type = "Team_Selection";
        doc.file = temp;
        setTempTeamSelection(temp.name)
        setteamselection(temp)
      }
      if (event.target.name === "SQP") {
        doc.type = "SQP";
        doc.file = temp;
        setTempSQP(temp.name)
        setsqp(temp)
      }
      if (event.target.name === "Risk_Assessment") {
        doc.type = "Risk_Assessment";
        doc.file = temp;
        setTempRiskAssessment(temp.name)
        setriskassessment(temp)
      }
      if (event.target.name === "Contract_Review") {
        doc.type = "Contract_Review";
        doc.file = temp;
        setTempContractReview(temp.name)
        setcontractreview(temp)
      }
      if (event.target.name === "Task_Briefing") {
        doc.type = "Task_Briefing";
        doc.file = temp;
        setTempEnquiry(temp.name)
        setenquiry(temp)
      }
      if (event.target.name === "Order_Confirmation") {
        doc.type = "Order_Confirmation";
        doc.file = temp;
        setTempOrderConfirmation(temp.name)
        setorderconfirmation(temp)
      }
    });

    setindetails(temp2);
    console.log(temp2);
  };
  const handleviewfile = (doc) => {
    if (doc?.Team_Selection) {
      return (
        <NavLink
          to={doc?.Team_Selection}
          target="_blank"
          rel="noopener noreferrer">
          {doc?.Team_Selection}
        </NavLink>
      );
    } else {
      return null; // or some fallback content
    }
  };
  const handleRemoveDocument = async (index) => {
    // const typeToRemove = indetails[index]?.type;

    // Update API with null for the corresponding file field
    // const nullValue = { [typeToRemove]: null };
    // const res = await axios.put(
    //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatemanualdocs/${id}`,
    //   nullValue,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );

    // // Handle API response if needed

    // // Remove the document from state
    // setDocuments((previndetails) => {
    //   const updatedDocuments = [...previndetails];
    //   updatedDocuments.splice(index, 1);
    //   return updatedDocuments;
    // });
    const updatedDocuments = [...documents];

    if (index === "TeamSelection") {
      setTempTeamSelection("");
      updatedDocuments[0].Team_Selection = "";
      setRemoveTeamSelection(true);
    } else if (index === "SQP") {
      setTempSQP("");
      updatedDocuments[0].SQP = "";
      setRemoveSQP(true);

    } else if (index === "RiskAssessment") {
      setTempRiskAssessment("");
      updatedDocuments[0].Risk_Assessment = "";
      setRemoveRiskAssessment(true);
    } else if (index === "ContractReview") {
      setTempContractReview("");
      updatedDocuments[0].Contract_Review = "";
      setRemoveContractReview(true);
    } else if (index === "Task_Briefing") {
      setTempEnquiry("");
      updatedDocuments[0].Task_Briefing = "";
      setRemoveEnquiry(true);
    } else if (index === "OrderConfirmation") {
      setTempOrderConfirmation("");
      updatedDocuments[0].Order_Confirmation = "";
      setRemoveOrderConfirmation(true);
    }
    setDocuments(updatedDocuments);
  };

  // const handleApiDocSave1 = async () => {
  //   // const formData = new FormData();
  //   let num = indetails.id;
  //   // const {
  //   // const Team_Selection = documents.filter((el, index) => {
  //   //   if (el.type === "Team_Selection") {
  //   //     return el.file;
  //   //   }
  //   // });
  //   // const SQP = documents.filter((el, index) => {
  //   //   if (el.type === "SQP") {
  //   //     return el.file;
  //   //   }
  //   // });
  //   // const Risk_Assessment = documents.filter((el, index) => {
  //   //   if (el.type === "Risk_Assessment") {
  //   //     return el.file;
  //   //   }
  //   // });
  //   // const Contract_Review = documents.filter((el, index) => {
  //   //   if (el.type === "Contract_Review") {
  //   //     return el.file;
  //   //   }
  //   // });
  //   // const Task_Briefing = documents.filter((el, index) => {
  //   //   if (el.type === "Task_Briefing") {
  //   //     return el.file;
  //   //   }
  //   // });
  //   // const Order_Confirmation = documents.filter((el, index) => {
  //   //   if (el.type === "Order_Confirmation") {
  //   //     return el.file;
  //   //   }
  //   // });
  //   // SQP,
  //   // Risk_Assessment,
  //   // Contract_Review,
  //   // Task_Briefing,
  //   // Order_Confirmation,
  //   // } = documents.type;
  //   // const {
  //   //   Team_Selection:documents[0]?.files,
  //   //   SQP,
  //   //   Risk_Assessment,
  //   //   Contract_Review,
  //   //   Task_Briefing,
  //   //   Order_Confirmation,
  //   // } = documents.type;
  //  const {
  //   Team_Selection,
  //   SQP,
  //   Risk_Assessment,
  //   Contract_Review,
  //   Task_Briefing,
  //   Order_Confirmation,
  //   } = indetails;

  //   const payload = {
  //     Team_Selection: Team_Selection,
  //     SQP: SQP,
  //     Risk_Assessment: Risk_Assessment,
  //     Contract_Review: Contract_Review,
  //     Task_Briefing: Task_Briefing,
  //     Order_Confirmation: Order_Confirmation,
  //   };

  //   const formData = new FormData();

  //   indetails.forEach((doc, index) => {
  //     if (doc.file) {
  //       axios
  //         .put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatemanualdocs/${index}`, doc)
  //         .then((response) =>
  //           console.log(`Document updated successfully: `, response.data)
  //         )
  //         .catch((error) => console.error(`Error updating document`));
  //     }
  //   });

  //   // console.log(payload);
  //   // const res = await axios.put(
  //   //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatemanualdocs/${id}`,
  //   //   formData,
  //   //   {
  //   //     headers: {
  //   //       "Content-Type": "multipart/form-data",
  //   //     },
  //   //   }
  //   // );
  //   // alert("API call completed");
  // };

  // const handleApiDocSave = async() => {
  //   let num = indetails.id;
  //   const {
  //     Team_Selection,
  //     SQP,
  //     Risk_Assessment,
  //     Contract_Review,
  //     Task_Briefing,
  //     Order_Confirmation
  //   } = indetails;

  //   const payload = {
  //     Team_Selection: Team_Selection,
  //     SQP: SQP,
  //     Risk_Assessment: Risk_Assessment,
  //     Contract_Review: Contract_Review,
  //     Task_Briefing: Task_Briefing,
  //     Order_Confirmation: Order_Confirmation,
  //   };

  //   indetails.forEach((doc, index) => {
  //     if (doc.file) {
  //       axios
  //         .put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatemanualdocs/${index}`, doc)
  //         .then((response) =>
  //           console.log(`Document updated successfully: `, response.data)
  //         )
  //         .catch((error) => console.error(`Error updating document`));
  //     }
  //   });
  // }

  // useEffect(() => {
  //   const fetchDocuments = async () => {
  //     const res = await axios.get(
  //       "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getmanualdocs"
  //     );
  //     const filter = res.data.data.filter((el) => el.ID_No == id);
  //     setteamselection(filter[0]?.Team_Selection);
  //     setsqp(filter[0]?.SQP);
  //     setriskassessment(filter[0]?.Risk_Assessment);
  //     setcontractreview(filter[0]?.Contract_Review);
  //     setenquiry(filter[0]?.Task_Briefing);
  //     setorderconfirmation(filter[0]?.Order_Confirmation)
  //     setindetails(res.data.data);
  //     console.log(indetails);
  //   };
  //   fetchDocuments();
  // }, [id]);

  // const handleApiDocSave = async (e) => {
  //   e.preventDefault();
  //   let num = indetails.id;
  //   // const {
  //     const Team_Selection = indetails.filter((el, index) => {
  //       if (el.type === "Team_Selection") {
  //         return el.file;
  //       }
  //     })
  //     const SQP = indetails.filter((el, index) => {
  //       if (el.type === "SQP") {
  //         return el.file;
  //       }
  //     })
  //     const Risk_Assessment = indetails.filter((el, index) => {
  //       if (el.type === "Risk_Assessment") {
  //         return el.file;
  //       }
  //     })
  //     const Contract_Review = indetails.filter((el, index) => {
  //       if (el.type === "Contract_Review") {
  //         return el.file;
  //       }
  //     })
  //     const Task_Briefing = indetails.filter((el, index) => {
  //       if (el.type === "Task_Briefing") {
  //         return el.file;
  //       }
  //     })
  //     const Order_Confirmation = indetails.filter((el, index) => {
  //       if (el.type === "Order_Confirmation") {
  //         return el.file;
  //       }
  //     })
  //     // SQP,
  //     // Risk_Assessment,
  //     // Contract_Review,
  //     // Task_Briefing,
  //     // Order_Confirmation,
  //   // } = documents.type;
  //   // const {
  //   //   Team_Selection:documents[0]?.files,
  //   //   SQP,
  //   //   Risk_Assessment,
  //   //   Contract_Review,
  //   //   Task_Briefing,
  //   //   Order_Confirmation,
  //   // } = documents.type;
  //   const payload = {
  //     Team_Selection: Team_Selection,
  //     SQP: SQP,
  //     Risk_Assessment: Risk_Assessment,
  //     Contract_Review: Contract_Review,
  //     Task_Briefing: Task_Briefing,
  //     Order_Confirmation: Order_Confirmation,
  //   };

  // const formData = new FormData();

  // indetails.forEach((doc, index) => {
  //   if (doc.file) {
  //     formData.append(doc.type, doc.file);
  //   }
  // });

  //   console.log(payload);
  //   const res = await axios.put(
  //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatemanualdocs/${id}`,
  //     formData,
  //     {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }
  //   );
  // };
  const handledownloadfile = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = "";
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };


  const handleApiDocSave = async (e) => {
    e.preventDefault();
    let flag = false



    const removeConditions = [
      { remove: removeTeamSelection, temp: tempTeamSelection, type: "Team_Selection" },
      { remove: removeSQP, temp: tempSQP, type: "SQP" },
      { remove: removeRiskAssessment, temp: tempRiskAssessment, type: "Risk_Assessment" },
      { remove: removeContractReview, temp: tempContractReview, type: "Contract_Review" },
      { remove: removeEnquiry, temp: tempEnquiry, type: "Task_Briefing" },
      { remove: removeOrderConfirmation, temp: tempOrderConfirmation, type: "Order_Confirmation" },
    ];

    for (const condition of removeConditions) {
      if (condition.remove && condition.temp === "") {
        flag = true;
        const payload = { type: condition.type };
        await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/removemanualdocs/${id}`, payload);
      }
    }

    const payloadFiles = {
      Team_Selection: teamselection,
      SQP: sqp,
      Risk_Assessment: riskassessment,
      Task_Briefing: enquiry,
    };

    // try {
    if (!flag) {
      const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatemanualdocs/${id}`, payloadFiles, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(res.data);
      setFetchFlag(true);
    }
    // } catch (error) {
    //   console.log("Error while making the API request:", error);
    // }

    setRemoveTeamSelection(false);
    setRemoveSQP(false);
    setRemoveRiskAssessment(false);
    setRemoveContractReview(false);
    setRemoveEnquiry(false);
    setRemoveOrderConfirmation(false);

    seteditclicked(false);
  };


  useEffect(() => {
    const fetchDocuments = () => {
      axios
        .get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getmanualdocs/${id}`)
        .then((res) => {
          const {
            Team_Selection,
            SQP,
            Risk_Assessment,
            Task_Briefing,
          } = res.data;
          console.log("******************", res.data);
          console.log("////////", res.data.data[0]);
          setindetails(res.data.data);
          setDocuments(res.data.data);
          console.log(indetails);
          // setDocuments({
          //   Team_Selection: Team_Selection,
          //   SQP: SQP,
          //   Risk_Assessment: Risk_Assessment,
          //   Task_Briefing: Task_Briefing,
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (fetchFlag) {
      fetchDocuments();
      setFetchFlag(false);
    }
  }, [fetchFlag]);

  return (
    <div
      style={{
        paddingBottom: "25px",
      }}>
      <div style={{ display: "flex" }}>
        <div
          className={apidocs === 1 ? styles.tabs1 : styles.tabs2}
          onClick={() => setapidocs(1)}>
          Automated
        </div>
        <div
          className={apidocs === 2 ? styles.tabs1 : styles.tabs2}
          onClick={() => setapidocs(2)}>
          Manual Upload
        </div>
      </div>
      {apidocs === 1 && (
        <div className={styles.mainDocContainer}>
          <div className={styles.documentContainer}>
            <div className={styles.nameAndStatusInfo}>
              <h5>1. DOS</h5>

              <div>
                <button
                  variant="contained"
                  className={
                    isCompleteDos
                      ? styles.completedCheckBtn
                      : styles.incompletedCheckBtn
                  }>
                  {isCompleteDos ? "complete" : "incomplete"}
                </button>
              </div>
            </div>

            <div className={styles.nameAndStatusInfo}>
              <div>
                {/* <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleDosplanPreview}>
                  Preview
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                      fill="#292D32"
                    />
                    <path
                      d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button> */}
                {showDosplanPreview && (
                  <div
                    className={`${styles.popupContainer} ${styles.pdfContainer}`}>
                    <div className={styles.popupContent}>
                      <Dosplan closeOnClick={handleDosplanClosePreview} />
                    </div>
                  </div>
                )}
              </div>

              <div>
                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={downloadPdfBtn2}>
                  Download PDF
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.993 1.66663H7.00964C3.9763 1.66663 2.16797 3.47496 2.16797 6.50829V13.4833C2.16797 16.525 3.9763 18.3333 7.00964 18.3333H13.9846C17.018 18.3333 18.8263 16.525 18.8263 13.4916V6.50829C18.8346 3.47496 17.0263 1.66663 13.993 1.66663ZM7.55964 9.14996C7.8013 8.90829 8.2013 8.90829 8.44297 9.14996L9.8763 10.5833V5.42496C9.8763 5.08329 10.1596 4.79996 10.5013 4.79996C10.843 4.79996 11.1263 5.08329 11.1263 5.42496V10.5833L12.5596 9.14996C12.8013 8.90829 13.2013 8.90829 13.443 9.14996C13.6846 9.39163 13.6846 9.79163 13.443 10.0333L10.943 12.5333C10.8846 12.5916 10.818 12.6333 10.743 12.6666C10.668 12.7 10.5846 12.7166 10.5013 12.7166C10.418 12.7166 10.343 12.7 10.2596 12.6666C10.1846 12.6333 10.118 12.5916 10.0596 12.5333L7.55964 10.0333C7.31797 9.79163 7.31797 9.39996 7.55964 9.14996ZM15.7013 14.35C14.0263 14.9083 12.268 15.1916 10.5013 15.1916C8.73464 15.1916 6.9763 14.9083 5.3013 14.35C4.9763 14.2416 4.8013 13.8833 4.90964 13.5583C5.01797 13.2333 5.36797 13.05 5.7013 13.1666C8.8013 14.2 12.2096 14.2 15.3096 13.1666C15.6346 13.0583 15.993 13.2333 16.1013 13.5583C16.2013 13.8916 16.0263 14.2416 15.7013 14.35Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.documentContainer}>
            <div className={styles.nameAndStatusInfo}>
              <h5>2. Work Plan</h5>

              <div>
                <button
                  variant="contained"
                  className={
                    isComplete
                      ? styles.completedCheckBtn
                      : styles.incompletedCheckBtn
                  }>
                  {isComplete ? "complete" : "incomplete"}
                </button>
              </div>
            </div>

            <div className={styles.nameAndStatusInfo}>
              <div>
                {/* <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handlePreview}>
                  Preview
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                      fill="#292D32"
                    />
                    <path
                      d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button> */}

                {showPreview && (
                  <div
                    className={`${styles.popupContainer} ${styles.pdfContainer}`}>
                    <div className={styles.popupContent}>
                      <Workplan ID={id} closeOnClick={handleClosePreview} />
                    </div>
                  </div>
                )}
              </div>

              <div>
                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={downloadPdfBtn}>
                  Download PDF
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.993 1.66663H7.00964C3.9763 1.66663 2.16797 3.47496 2.16797 6.50829V13.4833C2.16797 16.525 3.9763 18.3333 7.00964 18.3333H13.9846C17.018 18.3333 18.8263 16.525 18.8263 13.4916V6.50829C18.8346 3.47496 17.0263 1.66663 13.993 1.66663ZM7.55964 9.14996C7.8013 8.90829 8.2013 8.90829 8.44297 9.14996L9.8763 10.5833V5.42496C9.8763 5.08329 10.1596 4.79996 10.5013 4.79996C10.843 4.79996 11.1263 5.08329 11.1263 5.42496V10.5833L12.5596 9.14996C12.8013 8.90829 13.2013 8.90829 13.443 9.14996C13.6846 9.39163 13.6846 9.79163 13.443 10.0333L10.943 12.5333C10.8846 12.5916 10.818 12.6333 10.743 12.6666C10.668 12.7 10.5846 12.7166 10.5013 12.7166C10.418 12.7166 10.343 12.7 10.2596 12.6666C10.1846 12.6333 10.118 12.5916 10.0596 12.5333L7.55964 10.0333C7.31797 9.79163 7.31797 9.39996 7.55964 9.14996ZM15.7013 14.35C14.0263 14.9083 12.268 15.1916 10.5013 15.1916C8.73464 15.1916 6.9763 14.9083 5.3013 14.35C4.9763 14.2416 4.8013 13.8833 4.90964 13.5583C5.01797 13.2333 5.36797 13.05 5.7013 13.1666C8.8013 14.2 12.2096 14.2 15.3096 13.1666C15.6346 13.0583 15.993 13.2333 16.1013 13.5583C16.2013 13.8916 16.0263 14.2416 15.7013 14.35Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.documentContainer}>
            <div className={styles.nameAndStatusInfo}>
              <h5>3. Mobilization Plan</h5>

              <div>
                <button
                  variant="contained"
                  className={
                    isCompletemob
                      ? styles.completedCheckBtn
                      : styles.incompletedCheckBtn
                  }>
                  {isCompletemob ? "Complete" : "Incomplete"}
                </button>
              </div>
            </div>

            <div className={styles.nameAndStatusInfo}>
              <div>
                {/* <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  fontSize={15}
                  onClick={previewPDFmp}>
                  Preview
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                      fill="#292D32"
                    />
                    <path
                      d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button> */}
              </div>

              <div>
                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleSubmitmp}>
                  Download PDF

                  <svg
                    style={{ marginLeft: "5px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.993 1.66663H7.00964C3.9763 1.66663 2.16797 3.47496 2.16797 6.50829V13.4833C2.16797 16.525 3.9763 18.3333 7.00964 18.3333H13.9846C17.018 18.3333 18.8263 16.525 18.8263 13.4916V6.50829C18.8346 3.47496 17.0263 1.66663 13.993 1.66663ZM7.55964 9.14996C7.8013 8.90829 8.2013 8.90829 8.44297 9.14996L9.8763 10.5833V5.42496C9.8763 5.08329 10.1596 4.79996 10.5013 4.79996C10.843 4.79996 11.1263 5.08329 11.1263 5.42496V10.5833L12.5596 9.14996C12.8013 8.90829 13.2013 8.90829 13.443 9.14996C13.6846 9.39163 13.6846 9.79163 13.443 10.0333L10.943 12.5333C10.8846 12.5916 10.818 12.6333 10.743 12.6666C10.668 12.7 10.5846 12.7166 10.5013 12.7166C10.418 12.7166 10.343 12.7 10.2596 12.6666C10.1846 12.6333 10.118 12.5916 10.0596 12.5333L7.55964 10.0333C7.31797 9.79163 7.31797 9.39996 7.55964 9.14996ZM15.7013 14.35C14.0263 14.9083 12.268 15.1916 10.5013 15.1916C8.73464 15.1916 6.9763 14.9083 5.3013 14.35C4.9763 14.2416 4.8013 13.8833 4.90964 13.5583C5.01797 13.2333 5.36797 13.05 5.7013 13.1666C8.8013 14.2 12.2096 14.2 15.3096 13.1666C15.6346 13.0583 15.993 13.2333 16.1013 13.5583C16.2013 13.8916 16.0263 14.2416 15.7013 14.35Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.documentContainer}>
            <div className={styles.nameAndStatusInfo}>
              <h5>4. Equipment Initial Validation Form 1</h5>

              <div>
                <button
                  variant="contained"
                  className={
                    isCompleteevf1
                      ? styles.completedCheckBtn
                      : styles.incompletedCheckBtn
                  }>
                  {isCompleteevf1 ? "Complete" : "Incomplete"}
                </button>
              </div>
            </div>

            <div className={styles.nameAndStatusInfo}>
              <div>
                {/* <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleSubmit2}
                  fontSize={15}>
                  Preview
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                      fill="#292D32"
                    />
                    <path
                      d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button> */}
              </div>

              <div>
                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleSubmit1}>
                  Download PDF
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.993 1.66663H7.00964C3.9763 1.66663 2.16797 3.47496 2.16797 6.50829V13.4833C2.16797 16.525 3.9763 18.3333 7.00964 18.3333H13.9846C17.018 18.3333 18.8263 16.525 18.8263 13.4916V6.50829C18.8346 3.47496 17.0263 1.66663 13.993 1.66663ZM7.55964 9.14996C7.8013 8.90829 8.2013 8.90829 8.44297 9.14996L9.8763 10.5833V5.42496C9.8763 5.08329 10.1596 4.79996 10.5013 4.79996C10.843 4.79996 11.1263 5.08329 11.1263 5.42496V10.5833L12.5596 9.14996C12.8013 8.90829 13.2013 8.90829 13.443 9.14996C13.6846 9.39163 13.6846 9.79163 13.443 10.0333L10.943 12.5333C10.8846 12.5916 10.818 12.6333 10.743 12.6666C10.668 12.7 10.5846 12.7166 10.5013 12.7166C10.418 12.7166 10.343 12.7 10.2596 12.6666C10.1846 12.6333 10.118 12.5916 10.0596 12.5333L7.55964 10.0333C7.31797 9.79163 7.31797 9.39996 7.55964 9.14996ZM15.7013 14.35C14.0263 14.9083 12.268 15.1916 10.5013 15.1916C8.73464 15.1916 6.9763 14.9083 5.3013 14.35C4.9763 14.2416 4.8013 13.8833 4.90964 13.5583C5.01797 13.2333 5.36797 13.05 5.7013 13.1666C8.8013 14.2 12.2096 14.2 15.3096 13.1666C15.6346 13.0583 15.993 13.2333 16.1013 13.5583C16.2013 13.8916 16.0263 14.2416 15.7013 14.35Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.documentContainer}>
            <div className={styles.nameAndStatusInfo}>
              <h5>5. Equipment Initial Validation Form 2</h5>

              <div>
                <button
                  variant="contained"
                  className={
                    isCompleteevf2
                      ? styles.completedCheckBtn
                      : styles.incompletedCheckBtn
                  }>
                  {isCompleteevf2 ? "Complete" : "Incomplete"}
                </button>
              </div>
            </div>

            <div className={styles.nameAndStatusInfo}>
              <div>
                {/* <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  fontSize={15}
                  onClick={handleSubmit4}>
                  Preview
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                      fill="#292D32"
                    />
                    <path
                      d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button> */}
              </div>

              <div>
                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleSubmit3}>
                  Download PDF
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.993 1.66663H7.00964C3.9763 1.66663 2.16797 3.47496 2.16797 6.50829V13.4833C2.16797 16.525 3.9763 18.3333 7.00964 18.3333H13.9846C17.018 18.3333 18.8263 16.525 18.8263 13.4916V6.50829C18.8346 3.47496 17.0263 1.66663 13.993 1.66663ZM7.55964 9.14996C7.8013 8.90829 8.2013 8.90829 8.44297 9.14996L9.8763 10.5833V5.42496C9.8763 5.08329 10.1596 4.79996 10.5013 4.79996C10.843 4.79996 11.1263 5.08329 11.1263 5.42496V10.5833L12.5596 9.14996C12.8013 8.90829 13.2013 8.90829 13.443 9.14996C13.6846 9.39163 13.6846 9.79163 13.443 10.0333L10.943 12.5333C10.8846 12.5916 10.818 12.6333 10.743 12.6666C10.668 12.7 10.5846 12.7166 10.5013 12.7166C10.418 12.7166 10.343 12.7 10.2596 12.6666C10.1846 12.6333 10.118 12.5916 10.0596 12.5333L7.55964 10.0333C7.31797 9.79163 7.31797 9.39996 7.55964 9.14996ZM15.7013 14.35C14.0263 14.9083 12.268 15.1916 10.5013 15.1916C8.73464 15.1916 6.9763 14.9083 5.3013 14.35C4.9763 14.2416 4.8013 13.8833 4.90964 13.5583C5.01797 13.2333 5.36797 13.05 5.7013 13.1666C8.8013 14.2 12.2096 14.2 15.3096 13.1666C15.6346 13.0583 15.993 13.2333 16.1013 13.5583C16.2013 13.8916 16.0263 14.2416 15.7013 14.35Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.documentContainer}>
            <div className={styles.nameAndStatusInfo}>
              <h5>6. Equipment Validation Form 1</h5>

              <div>
                <button
                  variant="contained"
                  className={
                    isCompleteevf3
                      ? styles.completedCheckBtn
                      : styles.incompletedCheckBtn
                  }>
                  {isCompleteevf3 ? "Complete" : "Incomplete"}
                </button>
              </div>
            </div>

            <div className={styles.nameAndStatusInfo}>
              <div>
                {/* <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleSubmit5}
                  fontSize={15}>
                  Preview
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                      fill="#292D32"
                    />
                    <path
                      d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button> */}
              </div>

              <div>
                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleSubmit6}>
                  Download PDF
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.993 1.66663H7.00964C3.9763 1.66663 2.16797 3.47496 2.16797 6.50829V13.4833C2.16797 16.525 3.9763 18.3333 7.00964 18.3333H13.9846C17.018 18.3333 18.8263 16.525 18.8263 13.4916V6.50829C18.8346 3.47496 17.0263 1.66663 13.993 1.66663ZM7.55964 9.14996C7.8013 8.90829 8.2013 8.90829 8.44297 9.14996L9.8763 10.5833V5.42496C9.8763 5.08329 10.1596 4.79996 10.5013 4.79996C10.843 4.79996 11.1263 5.08329 11.1263 5.42496V10.5833L12.5596 9.14996C12.8013 8.90829 13.2013 8.90829 13.443 9.14996C13.6846 9.39163 13.6846 9.79163 13.443 10.0333L10.943 12.5333C10.8846 12.5916 10.818 12.6333 10.743 12.6666C10.668 12.7 10.5846 12.7166 10.5013 12.7166C10.418 12.7166 10.343 12.7 10.2596 12.6666C10.1846 12.6333 10.118 12.5916 10.0596 12.5333L7.55964 10.0333C7.31797 9.79163 7.31797 9.39996 7.55964 9.14996ZM15.7013 14.35C14.0263 14.9083 12.268 15.1916 10.5013 15.1916C8.73464 15.1916 6.9763 14.9083 5.3013 14.35C4.9763 14.2416 4.8013 13.8833 4.90964 13.5583C5.01797 13.2333 5.36797 13.05 5.7013 13.1666C8.8013 14.2 12.2096 14.2 15.3096 13.1666C15.6346 13.0583 15.993 13.2333 16.1013 13.5583C16.2013 13.8916 16.0263 14.2416 15.7013 14.35Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.documentContainer}>
            <div className={styles.nameAndStatusInfo}>
              <h5>7. Equipment Validation Form 2</h5>

              <div>
                <button
                  variant="contained"
                  className={
                    isCompleteevf4
                      ? styles.completedCheckBtn
                      : styles.incompletedCheckBtn
                  }>
                  {isCompleteevf4 ? "Complete" : "Incomplete"}
                </button>
              </div>
            </div>

            <div className={styles.nameAndStatusInfo}>
              <div>
                {/* <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  fontSize={15}
                  onClick={handleSubmit7}>
                  Preview
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                      fill="#292D32"
                    />
                    <path
                      d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button> */}
              </div>

              <div>
                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleSubmit8}>
                  Download PDF
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.993 1.66663H7.00964C3.9763 1.66663 2.16797 3.47496 2.16797 6.50829V13.4833C2.16797 16.525 3.9763 18.3333 7.00964 18.3333H13.9846C17.018 18.3333 18.8263 16.525 18.8263 13.4916V6.50829C18.8346 3.47496 17.0263 1.66663 13.993 1.66663ZM7.55964 9.14996C7.8013 8.90829 8.2013 8.90829 8.44297 9.14996L9.8763 10.5833V5.42496C9.8763 5.08329 10.1596 4.79996 10.5013 4.79996C10.843 4.79996 11.1263 5.08329 11.1263 5.42496V10.5833L12.5596 9.14996C12.8013 8.90829 13.2013 8.90829 13.443 9.14996C13.6846 9.39163 13.6846 9.79163 13.443 10.0333L10.943 12.5333C10.8846 12.5916 10.818 12.6333 10.743 12.6666C10.668 12.7 10.5846 12.7166 10.5013 12.7166C10.418 12.7166 10.343 12.7 10.2596 12.6666C10.1846 12.6333 10.118 12.5916 10.0596 12.5333L7.55964 10.0333C7.31797 9.79163 7.31797 9.39996 7.55964 9.14996ZM15.7013 14.35C14.0263 14.9083 12.268 15.1916 10.5013 15.1916C8.73464 15.1916 6.9763 14.9083 5.3013 14.35C4.9763 14.2416 4.8013 13.8833 4.90964 13.5583C5.01797 13.2333 5.36797 13.05 5.7013 13.1666C8.8013 14.2 12.2096 14.2 15.3096 13.1666C15.6346 13.0583 15.993 13.2333 16.1013 13.5583C16.2013 13.8916 16.0263 14.2416 15.7013 14.35Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.documentContainer}>
            <div className={styles.nameAndStatusInfo}>
              <h5>8. Equipment Preservation Form 1</h5>

              <div>
                <button
                  variant="contained"
                  className={
                    isCompletepf1
                      ? styles.completedCheckBtn
                      : styles.incompletedCheckBtn
                  }>
                  {isCompletepf1 ? "Complete" : "Incomplete"}
                </button>
              </div>
            </div>

            <div className={styles.nameAndStatusInfo}>
              <div>
                {/* <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleprep1}
                  fontSize={15}>
                  Preview
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                      fill="#292D32"
                    />
                    <path
                      d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button> */}
              </div>

              <div>
                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleSubmitp1}>
                  Download PDF
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.993 1.66663H7.00964C3.9763 1.66663 2.16797 3.47496 2.16797 6.50829V13.4833C2.16797 16.525 3.9763 18.3333 7.00964 18.3333H13.9846C17.018 18.3333 18.8263 16.525 18.8263 13.4916V6.50829C18.8346 3.47496 17.0263 1.66663 13.993 1.66663ZM7.55964 9.14996C7.8013 8.90829 8.2013 8.90829 8.44297 9.14996L9.8763 10.5833V5.42496C9.8763 5.08329 10.1596 4.79996 10.5013 4.79996C10.843 4.79996 11.1263 5.08329 11.1263 5.42496V10.5833L12.5596 9.14996C12.8013 8.90829 13.2013 8.90829 13.443 9.14996C13.6846 9.39163 13.6846 9.79163 13.443 10.0333L10.943 12.5333C10.8846 12.5916 10.818 12.6333 10.743 12.6666C10.668 12.7 10.5846 12.7166 10.5013 12.7166C10.418 12.7166 10.343 12.7 10.2596 12.6666C10.1846 12.6333 10.118 12.5916 10.0596 12.5333L7.55964 10.0333C7.31797 9.79163 7.31797 9.39996 7.55964 9.14996ZM15.7013 14.35C14.0263 14.9083 12.268 15.1916 10.5013 15.1916C8.73464 15.1916 6.9763 14.9083 5.3013 14.35C4.9763 14.2416 4.8013 13.8833 4.90964 13.5583C5.01797 13.2333 5.36797 13.05 5.7013 13.1666C8.8013 14.2 12.2096 14.2 15.3096 13.1666C15.6346 13.0583 15.993 13.2333 16.1013 13.5583C16.2013 13.8916 16.0263 14.2416 15.7013 14.35Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.documentContainer}>
            <div className={styles.nameAndStatusInfo}>
              <h5>9. Equipment Preservation Form 2</h5>

              <div>
                <button
                  variant="contained"
                  className={
                    isCompletepf2
                      ? styles.completedCheckBtn
                      : styles.incompletedCheckBtn
                  }>
                  {isCompletepf2 ? "Complete" : "Incomplete"}
                </button>
              </div>
            </div>

            <div className={styles.nameAndStatusInfo}>
              <div>
                {/* <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleprep2}
                  fontSize={15}>
                  Preview
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                      fill="#292D32"
                    />
                    <path
                      d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button> */}
              </div>

              <div>
                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={handleSubmitp2}>
                  Download PDF
                  <svg
                    style={{ marginLeft: "5px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.993 1.66663H7.00964C3.9763 1.66663 2.16797 3.47496 2.16797 6.50829V13.4833C2.16797 16.525 3.9763 18.3333 7.00964 18.3333H13.9846C17.018 18.3333 18.8263 16.525 18.8263 13.4916V6.50829C18.8346 3.47496 17.0263 1.66663 13.993 1.66663ZM7.55964 9.14996C7.8013 8.90829 8.2013 8.90829 8.44297 9.14996L9.8763 10.5833V5.42496C9.8763 5.08329 10.1596 4.79996 10.5013 4.79996C10.843 4.79996 11.1263 5.08329 11.1263 5.42496V10.5833L12.5596 9.14996C12.8013 8.90829 13.2013 8.90829 13.443 9.14996C13.6846 9.39163 13.6846 9.79163 13.443 10.0333L10.943 12.5333C10.8846 12.5916 10.818 12.6333 10.743 12.6666C10.668 12.7 10.5846 12.7166 10.5013 12.7166C10.418 12.7166 10.343 12.7 10.2596 12.6666C10.1846 12.6333 10.118 12.5916 10.0596 12.5333L7.55964 10.0333C7.31797 9.79163 7.31797 9.39996 7.55964 9.14996ZM15.7013 14.35C14.0263 14.9083 12.268 15.1916 10.5013 15.1916C8.73464 15.1916 6.9763 14.9083 5.3013 14.35C4.9763 14.2416 4.8013 13.8833 4.90964 13.5583C5.01797 13.2333 5.36797 13.05 5.7013 13.1666C8.8013 14.2 12.2096 14.2 15.3096 13.1666C15.6346 13.0583 15.993 13.2333 16.1013 13.5583C16.2013 13.8916 16.0263 14.2416 15.7013 14.35Z"
                      fill="#292D32"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          {/* <div className={styles.documentContainer}>
              <div className={styles.nameAndStatusInfo}>
                <h5>10. Coc</h5>

                <div>
                  <button
                    variant="contained"
                    className={styles.completedCheckBtn}>
                    complete
                  </button>
                </div>
              </div>

              <div className={styles.nameAndStatusInfo}>
                <div>
                  <Button
                    className={styles.previewAndDownloadBtn}
                    variant="contained"
                    fontSize={15}>
                    Preview
                    <svg
                      style={{ marginLeft: "5px" }}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                        fill="#292D32"
                      />
                      <path
                        d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                        fill="#292D32"
                      />
                    </svg>
                  </Button>
                </div>

                <div>
                  <Button
                    className={styles.previewAndDownloadBtn}
                    variant="contained">
                    Download PDF
                    <svg
                      style={{ marginLeft: "5px" }}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75963 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
                        fill="#292D32"
                      />
                      <path
                        d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
                        fill="#292D32"
                      />
                    </svg>
                  </Button>
                </div>
              </div>
            </div> */}
        </div>
      )}

      {apidocs === 2 && (
        <div>
          {editclicked === false && (
            <button
              onClick={() => seteditclicked(true)}
              className={styles.savebtn}
              style={{ marginLeft: "78%" }}>
              Edit
            </button>
          )}
          <div>
            {/* <button
                    className={styles.savebtn}
                    style={{
                      whiteSpace: "nowrap",
                      width: "300px",
                      padding: "15px",
                    }}
                    onClick={handleAddNewDocument}>
                    Add New Document
                    <svg
                      style={{ marginLeft: "6px" }}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.491 1.66667H6.50768C3.47435 1.66667 1.66602 3.475 1.66602 6.50834V13.4833C1.66602 16.525 3.47435 18.3333 6.50768 18.3333H13.4827C16.516 18.3333 18.3243 16.525 18.3243 13.4917V6.50834C18.3327 3.475 16.5243 1.66667 13.491 1.66667ZM14.9993 10.625H10.6243V15C10.6243 15.3417 10.341 15.625 9.99935 15.625C9.65768 15.625 9.37435 15.3417 9.37435 15V10.625H4.99935C4.65768 10.625 4.37435 10.3417 4.37435 10C4.37435 9.65834 4.65768 9.375 4.99935 9.375H9.37435V5.00001C9.37435 4.65834 9.65768 4.37501 9.99935 4.37501C10.341 4.37501 10.6243 4.65834 10.6243 5.00001V9.375H14.9993C15.341 9.375 15.6243 9.65834 15.6243 10C15.6243 10.3417 15.341 10.625 14.9993 10.625Z"
                        fill="black"
                      />
                    </svg>
                  </button> */}
            <div>
              {indetails?.map((doc, index) => (
                <div key={doc.index}>
                  <div key={index}>


                    <label style={{ marginLeft: "20px", marginTop: "5px", marginRight: "20px" }}>
                      SQP
                    </label>
                    {editclicked ? (
                      <div style={{ display: "inline-flex", marginLeft: "20px" }}>
                        <input
                          type="file"
                          name="SQP"
                          id="SQP"
                          onChange={(e) => handleFileChange("SQP", e)}
                          // value={doc?.SQP ? getFileNameFromURL(doc?.SQP) : "Upload File"}
                          style={{
                            display: "none",
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "127px",
                            cursor: "pointer"
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "147px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            document.getElementById("SQP").click()
                          }>
                          {documents[0]?.SQP
                            ? documents[0]?.SQP?.toString().split("/")
                              .pop()
                              .split("-")
                              .slice(1)
                              .join("-")
                            : tempSQP ? tempSQP : "Upload File"}
                        </div>
                        <svg
                          width="20"
                          onClick={() => handleRemoveDocument("SQP")}
                          style={{
                            marginTop: "7px",
                            marginLeft: "25px",
                            cursor: "pointer",
                          }}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div style={{ display: "inline-flex" }}>


                        <input
                          type="text"
                          name="SQP"
                          value={documents[0]?.SQP?.toString().split("/")
                            .pop()
                            .split("-")
                            .slice(1)
                            .join("-")}
                          style={{
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "147px",
                            pointerEvents: "none",
                            background: "lightgray"
                          }}
                        />
                        <div onClick={() => handleviewfile(doc?.SQP)}>
                          {/* <object data="http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/uploads/1702321059617-dosplan_-_2023-12-06T140623.005.pdf" type="application/pdf" width="500px" height="500px">
   
   </object> */}
                          <a href={doc?.SQP} target="_blank">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                cursor: "pointer",
                                marginTop: "3px",
                                marginLeft: "30px",
                              }}>
                              <path
                                d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                                fill="black"></path>
                              <path
                                d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                                fill="black"></path>
                            </svg>
                          </a>
                        </div>
                        <div style={{ border: "none", width: "30px", height: "30px", borderRadius: "8px", cursor: "pointer", background: '#F0F0F0' }}
                          onClick={() => handledownloadfile(doc?.SQP)}>

                          <svg style={{ marginTop: "5px", marginLeft: "5px" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32" />
                          </svg>


                        </div>
                      </div>
                    )}
                    <br />
                    <br />
                    <br />
                    <label style={{ marginLeft: "20px", marginTop: "5px", marginRight: "20px" }}>
                      Risk Assessment
                    </label>
                    {editclicked ? (
                      <div style={{ display: "inline-flex", marginLeft: "20px" }}>
                        <input

                          type="file"
                          name="Risk_Assessment"
                          id="Risk_Assessment"
                          onChange={(e) => handleFileChange("Risk_Assessment", e)}
                          // value={getFileNameFromURL(doc?.Risk_Assessment)}
                          style={{
                            display: "none",
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "117px",
                            cursor: "pointer"
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "57px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            cursor: "pointer"
                          }}
                          onClick={() => document.getElementById("Risk_Assessment").click()}>
                          {documents[0]?.Risk_Assessment ? documents[0]?.Risk_Assessment?.toString().split("/")
                            .pop()
                            .split("-")
                            .slice(1)
                            .join("-") : tempRiskAssessment ? tempRiskAssessment :
                            "Upload File"}
                        </div>
                        <svg
                          width="20"
                          onClick={() => handleRemoveDocument("RiskAssessment")}
                          style={{
                            marginTop: "7px",
                            marginLeft: "25px",
                            cursor: "pointer",
                          }}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div style={{ display: "inline-flex" }}>


                        <input
                          type="text"
                          name="Risk_Assessment"
                          value={documents[0]?.Risk_Assessment?.toString().split("/")
                            .pop()
                            .split("-")
                            .slice(1)
                            .join("-")}
                          style={{
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "57px",
                            pointerEvents: "none",
                            background: "lightgray"
                          }}
                        />
                        <div
                          onClick={() => handleviewfile(doc?.Risk_Assessment)}>
                          {/* <object data="http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/uploads/1702321059617-dosplan_-_2023-12-06T140623.005.pdf" type="application/pdf" width="500px" height="500px">
   
   </object> */}
                          <a href={doc?.Risk_Assessment} target="_blank">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                cursor: "pointer",
                                marginTop: "3px",
                                marginLeft: "30px",
                              }}>
                              <path
                                d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                                fill="black"></path>
                              <path
                                d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                                fill="black"></path>
                            </svg>
                          </a>
                        </div>
                        <div style={{ border: "none", width: "30px", height: "30px", borderRadius: "8px", cursor: "pointer", background: '#F0F0F0' }}
                          onClick={() => handledownloadfile(doc?.Risk_Assessment)}>

                          <svg style={{ marginTop: "5px", marginLeft: "5px" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32" />
                          </svg>


                        </div>
                      </div>
                    )}
                    <br />
                    <br />
                    <br />


                    <label style={{ marginLeft: "20px", marginTop: "5px", marginRight: "20px" }}>
                      Task Briefing
                    </label>
                    {editclicked ? (
                      <div style={{ display: "inline-flex", marginLeft: "20px" }}>
                        <input
                          type="file"
                          name="Task_Briefing"
                          id="Task_Briefing"
                          onChange={(e) => handleFileChange("Task_Briefing", e)}
                          // value={getFileNameFromURL(doc?.Task_Briefing)}
                          style={{
                            display: "none",
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "88px",
                            cursor: "pointer"

                          }}
                        />
                        <div style={{
                          border: "1px solid rgb(231, 235, 235)",
                          width: "200px",
                          borderRadius: "8px",
                          height: "35px",
                          alignItems: "center",
                          marginLeft: "88px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          cursor: "pointer"
                        }}
                          onClick={() =>
                            document.getElementById("Task_Briefing").click()
                          }>
                          {documents[0]?.Task_Briefing
                            ? documents[0]?.Task_Briefing?.toString().split("/")
                              .pop()
                              .split("-")
                              .slice(1)
                              .join("-") : tempEnquiry ? tempEnquiry
                              : "Upload File"}
                        </div>
                        <svg
                          width="20"
                          onClick={() => handleRemoveDocument("Task_Briefing")}
                          style={{
                            marginTop: "7px",
                            marginLeft: "25px",
                            cursor: "pointer",
                          }}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div style={{ display: "inline-flex"}}>


                        <input
                          type="text"
                          name="Task_Briefing"
                          value={documents[0]?.Task_Briefing?.toString().split("/")
                            .pop()
                            .split("-")
                            .slice(1)
                            .join("-")}
                          style={{
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "88px",
                            pointerEvents: "none",
                            background: "lightgray"
                          }}
                        />
                        <div onClick={() => handleviewfile(doc?.Task_Briefing)}>
                          {/* <object data="http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/uploads/1702321059617-dosplan_-_2023-12-06T140623.005.pdf" type="application/pdf" width="500px" height="500px">
   
   </object> */}
                          <a href={doc?.Task_Briefing} target="_blank">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                cursor: "pointer",
                                marginTop: "3px",
                                marginLeft: "30px",
                              }}>
                              <path
                                d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                                fill="black"></path>
                              <path
                                d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                                fill="black"></path>
                            </svg>
                          </a>
                        </div>
                        <div style={{ border: "none", width: "30px", height: "30px", borderRadius: "8px", cursor: "pointer", background: '#F0F0F0' }}
                          onClick={() => handledownloadfile(doc?.Task_Briefing)}>

                          <svg style={{ marginTop: "5px", marginLeft: "5px" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32" />
                          </svg>


                        </div>
                      </div>
                    )}
                    <br />
                    <br />
                    <br />
                    
                    {/* <label style={{ marginLeft: "20px", marginTop: "5px", marginRight: "20px" }}>
                      Contract Review
                    </label>
                    {editclicked ? (
                      <div style={{ display: "inline-flex", marginLeft: "20px" }}>
                        <input
                          type="file"
                          name="Contract_Review"
                          id="Contract_Review"
                          onChange={(e) => handleFileChange("Contract_Review", e)}
                          // value={getFileNameFromURL(doc?.Contract_Review)}
                          style={{
                            display: "none",
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "117px",
                          }}
                        />
                        <div style={{
                          border: "1px solid rgb(231, 235, 235)",
                          width: "200px",
                          borderRadius: "8px",
                          height: "35px",
                          alignItems: "center",
                          marginLeft: "63px",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                          onClick={() =>
                            document.getElementById("Contract_Review").click()
                          }>
                          {documents[0]?.Contract_Review
                            ? documents[0]?.Contract_Review?.toString().split("/")
                              .pop()
                              .split("-")
                              .slice(1)
                              .join("-") : tempContractReview ? tempContractReview :
                              "Upload File"}
                        </div>
                        <svg
                          width="20"
                          onClick={() => handleRemoveDocument("ContractReview")}
                          style={{
                            marginTop: "7px",
                            marginLeft: "25px",
                            cursor: "pointer",
                          }}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div style={{ display: "inline-flex" }}>


                        <input
                          type="text"
                          name="Contract_Review"
                          value={documents[0]?.Contract_Review?.toString().split("/")
                            .pop()
                            .split("-")
                            .slice(1)
                            .join("-")}
                          style={{
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "63px",
                          }}
                        />
                        <div
                          onClick={() => handleviewfile(doc?.Contract_Review)}>
                          <a href={doc?.Contract_Review} target="_blank">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                cursor: "pointer",
                                marginTop: "3px",
                                marginLeft: "30px",
                              }}>
                              <path
                                d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                                fill="black"></path>
                              <path
                                d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                                fill="black"></path>
                            </svg>
                          </a>
                        </div>
                        <div style={{ border: "none", width: "30px", height: "30px", borderRadius: "8px", cursor: "pointer", background: '#F0F0F0' }}
                          onClick={() => handledownloadfile(doc?.Contract_Review)}>

                          <svg style={{ marginTop: "5px", marginLeft: "5px" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32" />
                          </svg>


                        </div>
                      </div>
                    )}
                    <br />
                    <br />
                    <br />
                    <label style={{ marginLeft: "20px", marginTop: "5px", marginRight: "20px" }}>
                      Order Confirmation
                    </label>
                    {editclicked ? (
                      <div style={{ display: "inline-flex" }}>
                        <input
                          type="file"
                          name="Order_Confirmation"
                          id="Order_Confirmation"
                          onChange={(e) =>
                            handleFileChange("Order_Confirmation", e)
                          }
                          // value={
                          //   doc?.Order_Confirmation
                          //     ? getFileNameFromURL(doc?.Order_Confirmation)
                          //     : "-"
                          // }
                          style={{
                            display: "none",
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "10px",
                          }}
                        />
                        <div style={{
                          border: "1px solid rgb(231, 235, 235)",
                          width: "200px",
                          borderRadius: "8px",
                          height: "35px",
                          alignItems: "center",
                          marginLeft: "62px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                          onClick={() =>
                            document.getElementById("Order_Confirmation").click()
                          }>
                          {documents[0]?.Order_Confirmation
                            ? documents[0]?.Order_Confirmation?.toString().split("/")
                              .pop()
                              .split("-")
                              .slice(1)
                              .join("-") : tempOrderConfirmation ? tempOrderConfirmation
                              : "Upload File"}
                        </div>
                        <svg
                          width="20"
                          onClick={() => handleRemoveDocument("OrderConfirmation")}
                          style={{
                            marginTop: "7px",
                            marginLeft: "25px",
                            cursor: "pointer",
                          }}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div style={{ display: "inline-flex" }}>


                        <input
                          type="text"
                          name="Order_Confirmation"
                          value={documents[0]?.Order_Confirmation?.toString().split("/")
                            .pop()
                            .split("-")
                            .slice(1)
                            .join("-")}
                          style={{
                            border: "1px solid rgb(231, 235, 235)",
                            width: "200px",
                            borderRadius: "8px",
                            height: "35px",
                            alignItems: "center",
                            marginLeft: "47px",
                          }}
                        />
                        <div
                          onClick={() =>
                            handleviewfile(doc?.Order_Confirmation)
                          }>
                          <a href={doc?.Order_Confirmation} target="_blank">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                cursor: "pointer",
                                marginTop: "3px",
                                marginLeft: "30px",
                              }}>
                              <path
                                d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                                fill="black"></path>
                              <path
                                d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                                fill="black"></path>
                            </svg>
                          </a>
                        </div>
                        <div style={{ border: "none", width: "30px", height: "30px", borderRadius: "8px", cursor: "pointer", background: '#F0F0F0' }}
                          onClick={() => handledownloadfile(doc?.Order_Confirmation)}>

                          <svg style={{ marginTop: "5px", marginLeft: "5px" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32" />
                          </svg>


                        </div>
                      </div>
                    )}
                    <br />
 */}
                    {/* <button
                            onClick={() => handleRemoveDocument(index)}
                            style={{
                              background: "white",
                              color: "#567191",
                              fontSize: "14px",
                              gap: "5px",
                              marginTop: "-4px",
                            }}>
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g id="close-circle">
                                <path
                                  id="Vector"
                                  d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                                  fill="#567191"
                                />
                              </g>
                            </svg>{" "}
                          </button> */}
                    {/* <button>save</button> */}
                  </div>
                  <p>{indetails.type}</p>
                  {/* Add more details as needed */}
                </div>
              ))}
            </div>
          </div>
          {editclicked && (
            <div>
              <button
                className={styles.cancelbtn}
                style={{ marginLeft: "72%" }}
                onClick={() => seteditclicked(false)}>
                Cancel
              </button>
              <button className={styles.savebtn} onClick={handleApiDocSave}>
                Save
              </button>
            </div>
          )}
        </div>
      )}

      <div>
        <table ref={table1Ref} style={{ display: "none" }}>
          <thead>
            {/* <tr>
        <th style={{ width: "120px", border: "1px solid black" }}></th>
        <th style={{ width: "300px", border: "1px solid black" }}></th>
        <th style={{ width: "200px", border: "1px solid black" }}></th>
        <th style={{ width: "200px", border: "1px solid black" }}></th>
      </tr> */}
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  height: "30px",
                  border: "1px solid black",
                  whiteSpace: "pre-wrap",
                }}
              >
                Equipment
              </td>
              <td
                style={{
                  height: "40px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && istorque
                  ? projectData[0].Tool1
                  : "Dial Indicator"}
              </td>

              <td
                style={{
                  height: "60px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                SO No
              </td>
              <td
                style={{
                  height: "60px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Sales_Order_No}
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", border: "0.5px solid black" }}>
                Model
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Model1}
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                Sr. No
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Serial_Number1}
              </td>
            </tr>
            <tr>
              <td style={{ height: "45px", border: "0.5px solid black" }}>
                Date
              </td>
              <td
                style={{
                  height: "55px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
                // rowspan="2"
              >
                <td style={{ height: "45px", border: "0.5px solid black" }}>
                  {(projectData.length > 0 &&
                    (() => {
                      const documentDate = projectData[0].Document_Date;
                      if (!documentDate) return "";

                      const dateParts = documentDate.split("/");
                      const day = parseInt(dateParts[0], 10);
                      const month = parseInt(dateParts[1], 10);
                      const year = parseInt(dateParts[2], 10);

                      let newDay = day + 2;
                      let newMonth = month;
                      let newYear = year;

                      // Define the number of days in each month
                      const daysInMonth = {
                        1: 31, // January
                        2: newYear % 4 === 0 ? 29 : 28, // February (considering leap years)
                        3: 31, // March
                        4: 30, // April
                        5: 31, // May
                        6: 30, // June
                        7: 31, // July
                        8: 31, // August
                        9: 30, // September
                        10: 31, // October
                        11: 30, // November
                        12: 31, // December
                      };

                      // Adjust the new day and month if it exceeds the maximum days in the month
                      while (newDay > daysInMonth[newMonth]) {
                        newDay -= daysInMonth[newMonth];
                        newMonth += 1;
                        if (newMonth > 12) {
                          newMonth = 1;
                          newYear += 1;
                        }
                      }

                      return `${newDay}/${newMonth}/${newYear}`;
                    })()) ||
                    ""}
                </td>
              </td>
              <td
                style={{
                  height: "30px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                Time
              </td>
              <td
                style={{
                  height: "30px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                11:00 AM
              </td>
            </tr>
            <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Stage
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}
                    >
                    Initial
                  </td>
                </tr>
          </tbody>
        </table>

        {istorque ? (
          <table ref={table2Ref} style={{ display: "none" }}>
            <thead></thead>

            <tbody>
              <tr>
                <td
                  style={{
                    width: "30px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Description/Criteria
                </td>
                <td
                  style={{
                    width: "40px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Parameters
                </td>
                <td
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Test Criteria
                </td>
                <td
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Results(pass/fail)
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: "30px",
                    border: "1px solid black",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Inspect the ratchet assembly function
                </td>
                <td
                  style={{
                    height: "40px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Slip
                </td>
                <td
                  style={{
                    height: "60px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  No Slip
                </td>
                <td
                  style={{
                    height: "60px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
              <tr>
                <td style={{ height: "50px", border: "0.5px solid black" }}>
                  Visiblity and readability of torque markings
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Visiblity
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Visible and accurate
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
              <tr>
                <td style={{ height: "45px", border: "0.5px solid black" }}>
                  Condition of Grip
                </td>
                <td
                  style={{
                    height: "55px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                  rowspan="2"
                >
                  Firmness
                </td>
                <td
                  style={{
                    height: "30px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Firm and Strong
                </td>
                <td
                  style={{
                    height: "30px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table ref={table2Ref} style={{ display: "none" }}>
            <thead></thead>

            <tbody>
              <tr>
                <td
                  style={{
                    width: "30px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Description/Criteria
                </td>
                <td
                  style={{
                    width: "40px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Parameters
                </td>
                <td
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Test Criteria
                </td>
                <td
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Results(pass/fail)
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: "30px",
                    border: "1px solid black",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Inspect the measuring faces
                </td>
                <td
                  style={{
                    height: "40px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Zero scratches and burrs{" "}
                </td>
                <td
                  style={{
                    height: "60px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Should be free from scratches and burrs
                </td>
                <td
                  style={{
                    height: "60px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
              <tr>
                <td style={{ height: "50px", border: "0.5px solid black" }}>
                  Measurement the zero error
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  No zero error
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  No zero error
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>


      <div>

        <table ref={table3Ref} style={{ display: "none" }}>
          <thead>
            {/* <tr>
       <th style={{ width: "120px", border: "1px solid black" }}></th>
       <th style={{ width: "300px", border: "1px solid black" }}></th>
       <th style={{ width: "200px", border: "1px solid black" }}></th>
       <th style={{ width: "200px", border: "1px solid black" }}></th>
     </tr> */}
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  height: "30px",
                  border: "1px solid black",
                  whiteSpace: "pre-wrap",
                }}
              >
                Equipment
              </td>
              <td
                style={{
                  height: "40px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && istorque2
                  ? projectData[0].Tool2
                  : "Dial Indicator"}
              </td>

              <td
                style={{
                  height: "60px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                SO No
              </td>
              <td
                style={{
                  height: "60px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Sales_Order_No}
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", border: "0.5px solid black" }}>
                Model
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Model2}
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                Sr. No
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Serial_Number2}
              </td>
            </tr>
            <tr>
              <td style={{ height: "45px", border: "0.5px solid black" }}>
                Date
              </td>
              <td
                style={{
                  height: "55px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
                
              >
                <td style={{ height: "45px", border: "0.5px solid black" }}>
                  {(projectData.length > 0 &&
                    (() => {
                      const documentDate = projectData[0].Document_Date;
                      if (!documentDate) return "";

                      const dateParts = documentDate.split("/");
                      const day = parseInt(dateParts[0], 10);
                      const month = parseInt(dateParts[1], 10);
                      const year = parseInt(dateParts[2], 10);

                      let newDay = day + 2;
                      let newMonth = month;
                      let newYear = year;

                      // Define the number of days in each month
                      const daysInMonth = {
                        1: 31, // January
                        2: newYear % 4 === 0 ? 29 : 28, // February (considering leap years)
                        3: 31, // March
                        4: 30, // April
                        5: 31, // May
                        6: 30, // June
                        7: 31, // July
                        8: 31, // August
                        9: 30, // September
                        10: 31, // October
                        11: 30, // November
                        12: 31, // December
                      };

                      // Adjust the new day and month if it exceeds the maximum days in the month
                      while (newDay > daysInMonth[newMonth]) {
                        newDay -= daysInMonth[newMonth];
                        newMonth += 1;
                        if (newMonth > 12) {
                          newMonth = 1;
                          newYear += 1;
                        }
                      }

                      return `${newDay}/${newMonth}/${newYear}`;
                    })()) ||
                    ""}
                </td>
              </td>
              <td
                style={{
                  height: "30px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                Time
              </td>
              <td
                style={{
                  height: "30px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                11:00 AM
              </td>
            </tr>
            <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Stage
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}
                    >
                    Initial
                  </td>
                </tr>
          </tbody>
        </table>

        {istorque2 ? (
          <table ref={table4Ref} style={{ display: "none" }}>
            <thead></thead>

            <tbody>
              <tr>
                <td
                  style={{
                    width: "30px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Description/Criteria
                </td>
                <td
                  style={{
                    width: "40px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Parameters
                </td>
                <td
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Test Criteria
                </td>
                <td
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Results(pass/fail)
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: "30px",
                    border: "1px solid black",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Inspect the ratchet assembly function
                </td>
                <td
                  style={{
                    height: "40px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Slip
                </td>
                <td
                  style={{
                    height: "60px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  No Slip
                </td>
                <td
                  style={{
                    height: "60px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
              <tr>
                <td style={{ height: "50px", border: "0.5px solid black" }}>
                  Visiblity and readability of torque markings
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Visiblity
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Visible and accurate
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
              <tr>
                <td style={{ height: "45px", border: "0.5px solid black" }}>
                  Condition of Grip
                </td>
                <td
                  style={{
                    height: "55px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                  rowspan="2"
                >
                  Firmness
                </td>
                <td
                  style={{
                    height: "30px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Firm and Strong
                </td>
                <td
                  style={{
                    height: "30px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table ref={table4Ref} style={{ display: "none" }}>
            <thead></thead>

            <tbody>
              <tr>
                <td
                  style={{
                    width: "30px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Description/Criteria
                </td>
                <td
                  style={{
                    width: "40px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Parameters
                </td>
                <td
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Test Criteria
                </td>
                <td
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Results(pass/fail)
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: "30px",
                    border: "1px solid black",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Inspect the measuring faces
                </td>
                <td
                  style={{
                    height: "40px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Zero scratches and burrs{" "}
                </td>
                <td
                  style={{
                    height: "60px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Should be free from scratches and burrs
                </td>
                <td
                  style={{
                    height: "60px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
              <tr>
                <td style={{ height: "50px", border: "0.5px solid black" }}>
                  Measurement the zero error
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  No zero error
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  No zero error
                </td>
                <td
                  style={{
                    height: "50px",
                    borderRight: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  Pass
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>


      <div>
        <table ref={table5Ref} style={{ display: "none" }}>
          <thead>
            {/* <tr>
        <th style={{ width: "120px", border: "1px solid black" }}></th>
        <th style={{ width: "300px", border: "1px solid black" }}></th>
        <th style={{ width: "200px", border: "1px solid black" }}></th>
        <th style={{ width: "200px", border: "1px solid black" }}></th>
      </tr> */}
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  height: "30px",
                  border: "1px solid black",
                  whiteSpace: "pre-wrap",
                }}
              >
                Equipment
              </td>
              <td
                style={{
                  height: "40px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && istorque ? projectData[0].Tool1 : "Dial Indicator"}
              </td>


              <td
                style={{
                  height: "60px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                SO No
              </td>
              <td
                style={{
                  height: "60px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Sales_Order_No}
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", border: "0.5px solid black" }}>
                Model
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Model1}
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                Sr. No
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Serial_Number1}
              </td>
            </tr>
            <tr>
              <td style={{ height: "45px", border: "0.5px solid black" }}>
                Date
              </td>
              <td
                style={{
                  height: "55px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
                
              >
                <td style={{ height: "45px", border: "0.5px solid black" }}>
                  {projectData.length > 0 && projectData[0].Estimated_Date_Of_Commencement}
                </td>


              </td>
              <td
                style={{
                  height: "30px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                Time
              </td>
              <td
                style={{
                  height: "30px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                11:00 AM
              </td>
            </tr>
            <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Stage
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}
                    >
                    Final
                  </td>
                </tr>
          </tbody>
        </table>
      </div>


      <div>
        <table ref={table6Ref} style={{ display: "none" }}>
          <thead>
            {/* <tr>
        <th style={{ width: "120px", border: "1px solid black" }}></th>
        <th style={{ width: "300px", border: "1px solid black" }}></th>
        <th style={{ width: "200px", border: "1px solid black" }}></th>
        <th style={{ width: "200px", border: "1px solid black" }}></th>
      </tr> */}
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  height: "30px",
                  border: "1px solid black",
                  whiteSpace: "pre-wrap",
                }}
              >
                Equipment
              </td>
              <td
                style={{
                  height: "40px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && istorque2 ? projectData[0].Tool2 : "Dial Indicator"}
              </td>


              <td
                style={{
                  height: "60px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                SO No
              </td>
              <td
                style={{
                  height: "60px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Sales_Order_No}
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", border: "0.5px solid black" }}>
                Model
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Model2}
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                Sr. No
              </td>
              <td
                style={{
                  height: "50px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                {projectData.length > 0 && projectData[0].Serial_Number2}
              </td>
            </tr>
            <tr>
              <td style={{ height: "45px", border: "0.5px solid black" }}>
                Date
              </td>
              <td
                style={{
                  height: "55px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
                
              >
                <td style={{ height: "45px", border: "0.5px solid black" }}>
                  {projectData.length > 0 && projectData[0].Estimated_Date_Of_Commencement}
                </td>


              </td>
              <td
                style={{
                  height: "30px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                Time
              </td>
              <td
                style={{
                  height: "30px",
                  borderRight: "0.5px solid black",
                  borderBottom: "0.5px solid black",
                }}
              >
                11:00 AM
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "2px solid black",
                  padding: "5px",
                  borderRadius: "0",
                }}>
                Stage
              </td>
              <td
                style={{
                  border: "2px solid black",
                  padding: "5px",
                  borderRadius: "0",
                }}>
                Final
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Dosplan****************************************************** */}
      <div
        style={{ display: "none" }}
        id="parentDivDOSplan"
      >
        <>
          <div id="dosplan">
            <div className={styles.dosplanContainer}>
              <div className={styles.dosplanHeading}>
                {/* <svg
               onClick={props.closeOnClick}
               style={{ cursor: "pointer" }}
               width="32"
               height="32"
               viewBox="0 0 32 32"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
               <path
                 d="M20.9499 22.3641L9.63615 11.0504C9.2496 10.6638 9.2496 10.0227 9.63615 9.63618C10.0227 9.24963 10.6638 9.24963 11.0504 9.63618L22.3641 20.9499C22.7506 21.3364 22.7506 21.9776 22.3641 22.3641C21.9775 22.7507 21.3364 22.7507 20.9499 22.3641Z"
                 fill="black"
               />
               <path
                 d="M9.63592 22.3641C9.24937 21.9776 9.24937 21.3364 9.63592 20.9499L20.9496 9.63618C21.3362 9.24963 21.9773 9.24963 22.3638 9.63618C22.7504 10.0227 22.7504 10.6638 22.3638 11.0504L11.0501 22.3641C10.6636 22.7507 10.0225 22.7507 9.63592 22.3641Z"
                 fill="black"
               />
             </svg> */}
              </div>
              <table className={styles.tableContainer1} ref={dosref[1]}>
                <thead>
                  <tr>
                    <th colSpan={2} rowSpan={7}>
                      <img
                        src={Erogonlogo}
                        style={{ height: "50px", width: "150px" }}
                      />
                    </th>
                    <th
                      colSpan={3}
                      rowSpan={7}
                      style={{ fontSize: 20, fontWeight: "bold" }}>
                      Ergon Technologies Design and Development Plan
                    </th>
                    <td>Record No.</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      TSD-OPS-D.D-{projectList[0]?.Sales_Order_No.slice(3)}
                    </td>
                  </tr>
                  <tr>
                    <td>Revision No.</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>{projectList[0]?.Document_Date}</td>
                  </tr>
                  <tr>
                    <td>Sales Order No.</td>
                    <td>{projectList[0]?.Sales_Order_No}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{projectList[0]?.Customer_Name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{projectList[0]?.Service_Component}</td>
                  </tr>
                  <tr>
                    <td>Specification</td>
                    <td>{projectList[0]?.Rig_Type}</td>
                  </tr>
                  <tr>
                    <th>S. No.</th>
                    <th>Process to be carried out</th>
                    <th>Document (Reference) Number</th>
                    <th>Responsible for review</th>
                    <th>Planned Completion Date</th>
                    <th>Recommendation / Correction (if any)</th>
                    <th>Status</th>
                  </tr>
                  <tr>
                    <th colSpan={2}>I. Design Input</th>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Refer OEM Manual/ Criteria</td>
                    <td>
                      Refer OEM Manual/ Criteria for various services/ Scope
                      of work from customer/ Email from customer - Scope of
                      work to also include if it is an inspection or an
                      overhaul
                    </td>
                    <td>
                      The scope of work was reviewed by the Operations manager
                      and approved by CEO
                    </td>
                    <td>{projectList[0]?.Document_Date}</td>
                    <td>
                      If there is a change in scope of work, it has to be
                      communicated to customer
                    </td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Review of applicable legal requirements</td>
                    <td>
                      The job is to be carried out in Offshore in{" "}
                      {projectList[0]?.Rig_Location}. For this applicable{" "}
                      {projectList[0]?.Rig_Location} labor laws{" "}
                      {getLegalRequirementsFileName(projectList[0]?.Rig_Location)} were
                      reviewed. The visas were arranged by Ergon Technologies
                      and passes to the rig were arranged by Customer.
                    </td>
                    <td>
                      All applicable labor law was reviewed by the operations
                      manager and approved by CEO in contract review process.
                      Offshore passes were reviewed by the operations manager.
                    </td>
                    <td>{projectList[0]?.Document_Date}</td>
                    <td>NA</td>
                    <td>Completed</td>
                  </tr>
                </tbody>
              </table>

              <hr
                style={{ borderBottom: "2px solid black", marginTop: "60px" }}
              />
              <div className={styles.textContainer}>
                <div>TSD-OPS-FOR-009-19,Rev2</div>
                <div> 06 May 2023 </div>
                <div>1</div>
              </div>
              {/* page2 */}

              {/* page3 */}

              <footer></footer>

              {/* <div className={styles.btnContainerFixed}>
             <NavLink
               className={styles.opennewtabbtn}
               to={"/dosplan/" + projectList[0]?.ID_No}
               target="_blank"
               rel="noopener noreferrer">
               Open in New Tab
               <OpenInNewIcon />
             </NavLink>
     
             <Button
               variant="contained"
               type="submit"
               className={styles.Downloadpdfbtn}
               onClick={handleSubmit}>
               Download PDF
             </Button> */}
              {/* </div> */}
              {/* <div >react pdf dosplan</div> */}
            </div>
          </div>



          <div id="dosplan2">
            <div className={styles.dosplanContainer}>
              <table className={styles.tableContainer1} ref={dosref[2]}>
                <thead>
                  <tr>
                    <th colSpan={2} rowSpan={7}>
                      <img
                        src={Erogonlogo}
                        style={{ height: "50px", width: "150px" }}
                      />
                    </th>
                    <th
                      colSpan={3}
                      rowSpan={7}
                      style={{ fontSize: 20, fontWeight: "bold" }}>
                      Ergon Technologies Design and Development Plan
                    </th>
                    <td>Record No.</td>
                    <td style={{ whiteSpace: "nowrap" }}>  TSD-OPS-D.D-{projectList[0]?.Sales_Order_No.slice(3)}</td>
                  </tr>
                  <tr>
                    <td>Revision No.</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>{projectList[0]?.Document_Date}</td>
                  </tr>
                  <tr>
                    <td>Sales Order No.</td>
                    <td>{projectList[0]?.Sales_Order_No}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{projectList[0]?.Customer_Name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{projectList[0]?.Service_Component}</td>
                  </tr>
                  <tr>
                    <td>Specification</td>
                    <td>{projectList[0]?.Rig_Type}</td>
                  </tr>
                  <tr>
                    <th>S. No.</th>
                    <th>Process to be carried out</th>
                    <th>Document (Reference) Number</th>
                    <th>Responsible for review</th>
                    <th>Planned Completion Date</th>
                    <th>Recommendation / Correction (if any)</th>
                    <th>Status</th>
                  </tr>
                  {/* <tr>
                  <th colSpan={2}>I. Design Input</th>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                </tr> */}
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>
                      Review of requirement of any external source (e.g. OEM,
                      MPI)
                    </td>
                    <td>
                      For the {projectList[0]?.Service_Component}, OEM Manual,
                      {projectList[0]?.new_group} Operating and Maintenance
                      Manual; Parts catalogue and Accessory manuals
                    </td>
                    <td>
                      The manual was obtained and reviewed by operations
                      manager. Some roustabouts have been requested to assist
                      with the opening of covers, draining of oil etc.
                    </td>
                    <td>
                      Manual review completed at a date before mobilization
                      and mechanics/ roustabout availability confirmed
                    </td>
                    <td>NA</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      Review of service-related products to be used in this
                      project (e.g. dial gauge, compressor, etc.)
                    </td>
                    <td>
                      {
                        `Critical SRP: ${projectList[0]?.Tool1 ?
                          `${projectList[0].Tool1.trim()} of ${projectList[0].Make1.trim()} make, and model: ${projectList[0].Model1.trim()}, serial no. ${projectList[0].Serial_Number1.trim()}` :
                          ''
                        }${projectList[0]?.Tool1 && projectList[0]?.Tool2 ? ' and ' : ''
                        }${projectList[0]?.Tool2 ?
                          `${projectList[0].Tool2.trim()} of ${projectList[0].Make2.trim()} make, and model: ${projectList[0].Model2.trim()}, serial no. ${projectList[0].Serial_Number2.trim()}` :
                          ''
                        } were provided by Ergon Technologies.`
                      }
                    </td>
                    <td>
                      Calibration certificate of {projectList[0]?.Tool1}and
                      {projectList[0]?.Tool2}
                      were reviewed by the Operations manager.
                    </td>
                    <td>{projectList[0]?.Document_Date}</td>
                    <td>NA</td>
                    <td>Completed</td>
                  </tr>
                </tbody>
              </table>
              <hr
                style={{ borderBottom: "2px solid black", marginTop: "60px" }}
              />
              <div className={styles.textContainer}>
                <div>TSD-OPS-FOR-009-19,Rev2</div>
                <div>06  May 2023</div>
                <div>2</div>
              </div>
            </div>
            {/* <div style={{height:"20px"}}>
     
           </div> */}
          </div>
          <div id="dosplan3">
            <div className={styles.dosplanContainer}>
              <table className={styles.tableContainer1} ref={dosref[3]}>
                <thead>
                  <tr>
                    <th colSpan={2} rowSpan={7}>
                      <img
                        src={Erogonlogo}
                        style={{ height: "50px", width: "150px" }}
                      />
                    </th>
                    <th
                      colSpan={3}
                      rowSpan={7}
                      style={{ fontSize: 20, fontWeight: "bold" }}>
                      Ergon Technologies Design and Development Plan
                    </th>
                    <td>Record No.</td>
                    <td style={{ whiteSpace: "nowrap" }}>  TSD-OPS-D.D-{projectList[0]?.Sales_Order_No.slice(3)}</td>
                  </tr>
                  <tr>
                    <td>Revision No.</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>{projectList[0]?.Document_Date}</td>
                  </tr>
                  <tr>
                    <td>Sales Order No.</td>
                    <td>{projectList[0]?.Sales_Order_No}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{projectList[0]?.Customer_Name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{projectList[0]?.Service_Component}</td>
                  </tr>
                  <tr>
                    <td>Specification</td>
                    <td>{projectList[0]?.Rig_Type}</td>
                  </tr>
                  <tr>
                    <th>S. No.</th>
                    <th>Process to be carried out</th>
                    <th>Document (Reference) Number</th>
                    <th>Responsible for review</th>
                    <th>Planned Completion Date</th>
                    <th>Recommendation / Correction (if any)</th>
                    <th>Status</th>
                  </tr>
                  {/* <tr>
                  <th colSpan={2}>I. Design Input</th>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                </tr> */}
                </thead>
                <tbody>
                  <tr>
                    <td>5</td>
                    <td>
                      Review of environmental and operational
                      condition requirement
                    </td>
                    <td>
                      HSE Site Personnel. The job is to be carried out in the
                      months of{" "}
                      {projectList[0]?.Estimated_Date_Of_Commencement &&
                        projectList[0]?.Estimated_Date_Of_Commencement.slice(3, 10)} to{" "}
                      {projectList[0]?.Estimated_Project_Completion_Month &&
                        projectList[0]?.Estimated_Project_Completion_Month.slice(3, 10)} .{" "}
                    </td>

                    <td>
                      The operations manager assessed the condition of the
                      location so that the quality of work due to this
                      environmental and operational condition is not hampered.
                      .
                    </td>
                    <td>{projectList[0]?.Document_Date}</td>
                    <td>NA</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Review of Result of Risk Assessment </td>
                    <td>TSD-OPS-REG-002-19-,Rev1 Risk Register </td>
                    <td>
                      Review of risk assessment was done, and results recorded
                      by supervisor and approved by operations manager{" "}
                    </td>
                    <td>{projectList[0]?.Document_Date}</td>
                    <td>NA</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      Review of Historical performance and other information
                      derived from previous similar service designs{" "}
                    </td>
                    <td>Refer previous reports</td>
                    <td>
                      Review of previous jobs of similar nature was done .
                    </td>
                    <td>{projectList[0]?.Document_Date}</td>
                    <td>NA</td>
                    <td>Completed</td>
                  </tr>
                  {/* <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr> */}
                </tbody>
              </table>
              <hr
                style={{ borderBottom: "2px solid black", marginTop: "60px" }}
              />
              <div className={styles.textContainer}>
                <div>TSD-OPS-FOR-009-19,Rev2</div>
                <div>06  May 2023</div>
                <div>3</div>
              </div>
            </div>
          </div>
          <div id="dosplan4">
            <div className={styles.dosplanContainer}>
              <table className={styles.tableContainer1} ref={dosref[4]}>
                <thead>
                  <tr>
                    <th colSpan={2} rowSpan={7}>
                      <img
                        src={Erogonlogo}
                        style={{ height: "50px", width: "150px" }}
                      />
                    </th>
                    <th
                      colSpan={3}
                      rowSpan={7}
                      style={{ fontSize: 20, fontWeight: "bold" }}>
                      Ergon Technologies Design and Development Plan
                    </th>
                    <td>Record No.</td>
                    <td style={{ whiteSpace: "nowrap" }}>  TSD-OPS-D.D-{projectList[0]?.Sales_Order_No.slice(3)}</td>
                  </tr>
                  <tr>
                    <td>Revision No.</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>{projectList[0]?.Document_Date}</td>
                  </tr>
                  <tr>
                    <td>Sales Order No.</td>
                    <td>{projectList[0]?.Sales_Order_No}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{projectList[0]?.Customer_Name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{projectList[0]?.Service_Component}</td>
                  </tr>
                  <tr>
                    <td>Specification</td>
                    <td>{projectList[0]?.Rig_Type}</td>
                  </tr>
                  <tr>
                    <th>S. No.</th>
                    <th>Process to be carried out</th>
                    <th>Document (Reference) Number</th>
                    <th>Responsible for review</th>
                    <th>Planned Completion Date</th>
                    <th>Recommendation / Correction (if any)</th>
                    <th>Status</th>
                  </tr>
                  <tr>
                    <th colSpan={2}>II. Design Output</th>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Preparation of Service Quality Plan</td>
                    <td>
                      {/* {projectList[0]?.Sales_Order_No} -{" "}
                        {projectList[0]?.Po_Date} */}
                      SQP:TSD-TS-SQP-001-19,Rev4
                    </td>
                    <td>Operations Manager</td>
                    <td>{formattedDate1 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Preparation of Mobilization Plan</td>
                    <td>TSD-OPS-FOR-016-19, Rev 1</td>
                    <td>Operations Manager</td>
                    <td>{formattedDate1 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      Identification of Customer Allowance (+/- workdays)
                    </td>
                    <td>Work plan- TSD-OPS-FOR-021-21, Rev 1</td>
                    <td> Operations manager</td>
                    <td>30 Days</td>
                    <td>MR</td>
                    <td>Reviewed</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Selection of Team as per Competency requirement</td>
                    <td>
                      Competency matrix register TSD-ADM-FOR-009-19,Rev1 was referred
                      to for finalizing the team –{" "}
                      {projectList[0]?.Supervisor_Names} ,
                      {projectList[0]?.Technician_Names} ,
                      {projectList[0]?.Trainee_Names}
                    </td>
                    <td>Operations Manager</td>
                    <td>{formattedDate1 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                </tbody>
              </table>
              <hr
                style={{ borderBottom: "2px solid black", marginTop: "60px" }}
              />
              <div className={styles.textContainer}>
                <div>TSD-OPS-FOR-009-19,Rev2</div>
                <div>06  May 2023</div>
                <div>4</div>
              </div>
            </div>
          </div>
          <div id="dosplan5">
            <div className={styles.dosplanContainer}>
              <table className={styles.tableContainer1} ref={dosref[5]}>
                <thead>
                  <tr>
                    <th colSpan={2} rowSpan={7}>
                      <img
                        src={Erogonlogo}
                        style={{ height: "50px", width: "150px" }}
                      />
                    </th>
                    <th
                      colSpan={3}
                      rowSpan={7}
                      style={{ fontSize: 20, fontWeight: "bold" }}>
                      Ergon Technologies Design and Development Plan
                    </th>
                    <td>Record No.</td>
                    <td>{projectList[0]?.Sales_Order_No}</td>
                  </tr>
                  <tr>
                    <td>Revision No.</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>{projectList[0]?.Document_Date}</td>
                  </tr>
                  <tr>
                    <td>Sales Order No.</td>
                    <td>{projectList[0]?.Sales_Order_No}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{projectList[0]?.Customer_Name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{projectList[0]?.Service_Component}</td>
                  </tr>
                  <tr>
                    <td>Specification</td>
                    <td>{projectList[0]?.Rig_Type}</td>
                  </tr>
                  <tr>
                    <th>S. No.</th>
                    <th>Process to be carried out</th>
                    <th>Document (Reference) Number</th>
                    <th>Responsible for review</th>
                    <th>Planned Completion Date</th>
                    <th>Recommendation / Correction (if any)</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>5</td>
                    <td>Selection of Measuring Equipment</td>
                    <td>
                      {
                        `Critical SRP: ${projectList[0]?.Tool1 ?
                          `${projectList[0].Tool1.trim()} of ${projectList[0].Make1.trim()} make, and model: ${projectList[0].Model1.trim()}, serial no. ${projectList[0].Serial_Number1.trim()}` :
                          ''
                        }${projectList[0]?.Tool1 && projectList[0]?.Tool2 ? ' and ' : ''
                        }${projectList[0]?.Tool2 ?
                          `${projectList[0].Tool2.trim()} of ${projectList[0].Make2.trim()} make, and model: ${projectList[0].Model2.trim()}, serial no. ${projectList[0].Serial_Number2.trim()}` :
                          ''
                        } were provided by Ergon Technologies.`
                      }
                    </td>
                    <td>Operations Manager</td>
                    <td>{formattedDate1 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Identification of Acceptance Criteria</td>
                    <td>
                      <>
                        {projectList[0]?.Tool1 && (
                          <span>{`${projectList[0].Tool1.trim()} should have measurement with +/- ${projectList[0].Acceptance_Criteria1.trim()} accuracy.`}</span>
                        )}
                        {projectList[0]?.Tool1 && projectList[0]?.Tool2 && (
                          <br />
                        )}
                        {projectList[0]?.Tool2 && (
                          <span>{`${projectList[0].Tool2.trim()} with accuracy of +/- ${projectList[0].Acceptance_Criteria2.trim()}.`}</span>
                        )}
                      </>
                    </td>
                    <td>Operations Manager</td>
                    <td>{formattedDate1 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                  {/* <tr>
                      <td>7</td>
                      <td>Execution of service design - Quality of Job</td>
                      <td>
                        Daily Reports &amp; Client Feedback, general condition
                        of the internal components.
                      </td>
                      <td> Operations manager</td>
                      <td>{projectList[0]?.Document_Date}</td>
                      <td>MR</td>
                      <td>Completed</td>
                    </tr> */}
                  <tr>
                    <td>7</td>
                    <td>
                      Identification of critical service-related products{" "}
                    </td>
                    <td>
                      SQP : TSD-TS-SQP-001-19,Rev4{" "}
                      {/* {projectList[0]?.Sales_Order_No}-
                        {projectList[0]?.Po_Date}{" "} */}
                    </td>
                    <td>Operations Manager</td>
                    <td>{formattedDate1 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      Implementation of Pre-Job briefing-briefing the Techs about the Job{" "}
                    </td>
                    <td>
                      Task briefing record- TSD-ADM-FOR-010-19, Rev1
                    </td>
                    <td>Operations Manager</td>
                    <td>{formattedDate1 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>

                  

                </tbody>
              </table>
              <hr
                style={{ borderBottom: "2px solid black", marginTop: "60px" }}
              />
              <div className={styles.textContainer}>
                <div>TSD-OPS-FOR-009-19,Rev2</div>
                <div>06 May 2023</div>
                <div>5</div>
              </div>
            </div>
          </div>
          <div id="dosplan6">
            <div className={styles.dosplanContainer}>
              <table className={styles.tableContainer1} ref={dosref[6]}>
                <thead>
                  <tr>
                    <th colSpan={2} rowSpan={7}>
                      <img
                        src={Erogonlogo}
                        style={{ height: "50px", width: "150px" }}
                      />
                    </th>
                    <th
                      colSpan={3}
                      rowSpan={7}
                      style={{ fontSize: 20, fontWeight: "bold" }}>
                      Ergon Technologies Design and Development Plan
                    </th>
                    <td>Record No.</td>
                    <td style={{ whiteSpace: "nowrap" }}>  TSD-OPS-D.D-{projectList[0]?.Sales_Order_No.slice(3)}</td>
                  </tr>
                  <tr>
                    <td>Revision No.</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>{projectList[0]?.Document_Date}</td>
                  </tr>
                  <tr>
                    <td>Sales Order No.</td>
                    <td>{projectList[0]?.Sales_Order_No}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{projectList[0]?.Customer_Name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{projectList[0]?.Service_Component}</td>
                  </tr>
                  <tr>
                    <td>Specification</td>
                    <td>{projectList[0]?.Rig_Type}</td>
                  </tr>
                  <tr>
                    <th>S. No.</th>
                    <th>Process to be carried out</th>
                    <th>Document (Reference) Number</th>
                    <th>Responsible for review</th>
                    <th>Planned Completion Date</th>
                    <th>Recommendation / Correction (if any)</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td>9</td>
                    <td>
                    Purchase process for SRP-(Purchase PO issued if any SRP required for the execution of job and not available in Ergon).{" "}
                    </td>
                    <td>
                    Purchase procedure TSD-SRP-005, Rev01 and Purchase order Form TSD- PUR-FOR-002-22, Rev02.
                    </td>
                    <td>Operations Manager</td>
                    <td>{formattedDate1 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <th colSpan={2}>III. Verification</th>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>1</td>
                    <td>Design and development verification document</td>
                    <td>
                      {/* {projectList[0]?.Sales_Order_No} -{" "}
                        {projectList[0]?.Po_Date} */}
                      Daily report- TSD-OPS-FOR-026-21 Rev.1
                      <br/>
                      Final report- TSD-OPS-FOR-027-21, Rev 1
                    </td>
                    <td>Projects Manager</td>
                    <td>{formattedDate2 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <th colSpan={2} style={{ fontWeight: "bold" }}>IV. Final Review and Approval</th>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>1</td>
                    <td>Design final review</td>
                    <td>
                      {/* {projectList[0]?.Sales_Order_No} -{" "}
                        {projectList[0]?.Po_Date} */}
                      Customer feedback- TSD-OPS- FOR-002-22, Rev.1 and service execution feedback- TSD-OPS- FOR-029-22, Rev.1
                    </td>
                    <td>CEO</td>
                    <td>{formattedDate2 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>2</td>
                    <td>Final Report</td>
                    <td>
                      {/* {projectList[0]?.Sales_Order_No} -{" "}
                        {projectList[0]?.Po_Date} */}
                      TSD-OPS-FOR-027-21,Rev1
                    </td>
                    <td>Operations Manager</td>
                    <td>{formattedDate2 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                  
                </tbody>
              </table>
              <div className={styles.textContainer} style={{ fontWeight: "bold", marginTop: "60px" }}>
                <div>Prepared by: Jancy K Mathew</div>
                <div>Approved by: Basil Rajan</div>
              </div>
              <hr
                style={{ borderBottom: "2px solid black", marginTop: "10px" }}
              />
              <div className={styles.textContainer}>
                <div>TSD-OPS-FOR-009-19,Rev2</div>
                <div>06  May 2023</div>
                <div>6</div>
              </div>
              <h6 className={styles.textContainer} style={{ justifyContent: "center" }}>
                {" "}
                This document is digitally generated and does not require a
                signature.
              </h6>
            </div>
          </div>
          <div id="dosplan7">
            <div className={styles.dosplanContainer}>
              <table className={styles.tableContainer1} ref={dosref[7]}>
                <thead>
                  <tr>
                    <th colSpan={2} rowSpan={7}>
                      <img
                        src={Erogonlogo}
                        style={{ height: "50px", width: "150px" }}
                      />
                    </th>
                    <th
                      colSpan={3}
                      rowSpan={7}
                      style={{ fontSize: 20, fontWeight: "bold" }}>
                      Ergon Technologies Design and Development Plan
                    </th>
                    <td>Record No.</td>
                    <td style={{ whiteSpace: "nowrap" }}>  TSD-OPS-D.D-{projectList[0]?.Sales_Order_No.slice(3)}</td>
                  </tr>
                  <tr>
                    <td>Revision No.</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>{projectList[0]?.Document_Date}</td>
                  </tr>
                  <tr>
                    <td>Sales Order No.</td>
                    <td>{projectList[0]?.Sales_Order_No}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{projectList[0]?.Customer_Name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{projectList[0]?.Service_Component}</td>
                  </tr>
                  <tr>
                    <td>Specification</td>
                    <td>{projectList[0]?.Rig_Type}</td>
                  </tr>
                  <tr>
                    <th>S. No.</th>
                    <th>Process to be carried out</th>
                    <th>Document (Reference) Number</th>
                    <th>Responsible for review</th>
                    <th>Planned Completion Date</th>
                    <th>Recommendation / Correction (if any)</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                
                  <tr>
                    <th colSpan={2} style={{ fontWeight: "bold" }}>V. Design Changes</th>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>1</td>
                    <td>  Design and development changes to be carried out via MOC procedure </td>
                    <td>
                      {/* {projectList[0]?.Sales_Order_No} -{" "}
                        {projectList[0]?.Po_Date} */}
                      TSD-SRP-013, Rev4. MOC template
                    </td>
                    <td>Operations Manager/CEO</td>
                    <td>{formattedDate2 || "-"}</td>
                    <td>MR</td>
                    <td>Completed</td>
                  </tr>
                </tbody>
              </table>
              <div className={styles.textContainer} style={{ fontWeight: "bold", marginTop: "60px" }}>
                <div>Prepared by: Jancy K Mathew</div>
                <div>Approved by: Basil Rajan</div>
              </div>
              <hr
                style={{ borderBottom: "2px solid black", marginTop: "10px" }}
              />
              <div className={styles.textContainer}>
                <div>TSD-OPS-FOR-009-19,Rev2</div>
                <div>06  May 2023</div>
                <div>6</div>
              </div>
              <h6 className={styles.textContainer} style={{ justifyContent: "center" }}>
                {" "}
                This document is digitally generated and does not require a
                signature.
              </h6>
            </div>
          </div>
        </>
      </div>

      {/************************ * workplan **************************************************/}
      <div
        style={{ display: "none" }}
        id="parentDivWorkplan"
      >
        {/* {downLoadStartWorkPlan && ( */}
        <div className={styles.workplancontainer}
        // style={{ display: 'none' }}
        >
          <div className={styles.formContainer}>
            <form id="workplan2" style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}>
                <div style={{ alignItems: "left" }}>
                  {" "}
                  <img
                    src={Erogonlogo}
                    style={{ height: "36px", width: "120px", marginBottom: "10px" }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "20px", fontWeight: "bold", marginRight: "30%", width: "40%" }}>
                  Work Plan
                </div>
              </div>
              <table id="WorkPlanTable" ref={workplanref}>
                <thead>
                  <tr>
                    <th className={styles.darkHeading} scope="row">
                      Head
                    </th>
                    <th className={styles.darkHeading}>
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Client</th>
                    <td>{projectList[0]?.Customer_Name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Rig Name</th>
                    <td>{projectList[0]?.Rig_Name} </td>
                  </tr>
                  <tr>
                    <th scope="row">Sales Order No</th>
                    <td>{projectList[0]?.Sales_Order_No}</td>
                  </tr>
                  <tr>
                    <th scope="row">Rig Location</th>
                    <td> {projectList[0]?.Rig_Location}</td>
                  </tr>
                  <tr>
                    <th scope="row">Project Name</th>
                    <td>{projectList[0]?.Service_Component}</td>
                  </tr>
                  <tr>
                    <th scope="row">Scope of Work</th>
                    <td>{projectList[0]?.Scope_Description}</td>
                  </tr>
                  <tr>
                    <th scope="row">Team Members</th>
                    <td>
                      {projectList[0]?.Supervisor_Names?.split(',').filter(Boolean).map((supervisor, index, array) => (
                        <div key={index}>
                          {supervisor}
                          {index !== array.length - 1 ? <br /> : projectList[0]?.Technician_Names.split(',').filter(Boolean).length > 0 && <br />}
                        </div>
                      ))}
                      {projectList[0]?.Technician_Names?.split(',').filter(Boolean).map((technician, index, array) => (
                        <div key={index}>
                          {technician}
                          {index !== array.length - 1 ? <br /> : projectList[0]?.Trainee_Names.split(',').filter(Boolean).length > 0 && <br />}
                        </div>
                      ))}
                      {projectList[0]?.Trainee_Names?.split(',').filter(Boolean).map((trainee, index, array) => (
                        <div key={index}>
                          {trainee}
                          {index !== array.length - 1 && <br />}
                        </div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Mobilisation Details</th>
                    <td>
                      {projectList[0]?.Supervisor_Names?.split(',')?.filter(Boolean).map((supervisor, index, array) => (
                        <div key={index}>
                          {supervisor}, {projectList[0]?.Estimated_Date_Of_Commencement}
                          {index !== array.length - 1 ? <br /> : projectList[0]?.Technician_Names.split(',').filter(Boolean).length > 0 && <br />}
                        </div>
                      ))}
                      {projectList[0]?.Technician_Names?.split(',')?.filter(Boolean).map((technician, index, array) => (
                        <div key={index}>
                          {technician}, {projectList[0]?.Estimated_Date_Of_Commencement}
                          {index !== array.length - 1 ? <br /> : projectList[0]?.Trainee_Names.split(',').filter(Boolean).length > 0 && <br />}
                        </div>
                      ))}
                      {projectList[0]?.Trainee_Names?.split(',')?.filter(Boolean).map((trainee, index, array) => (
                        <div key={index}>
                          {trainee}, {projectList[0]?.Estimated_Date_Of_Commencement}
                          {index !== array.length - 1 && <br />}
                        </div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Project Duration</th>
                    <td>Estimated {projectList[0]?.Po_Days} days</td>
                  </tr>
                  <tr>
                    <th scope="row">Job Details</th>
                    <td>The scope is, {projectList[0]?.Service_Component}</td>
                  </tr>
                  <tr>
                    <th scope="row">Final Deliverable</th>
                    <td>
                      1. Daily Report & Final Report
                      <br />
                      2. Status Sheet
                      <br />
                      3. Feedback form
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Tools & Consumables</th>
                    <td>Available at rig</td>
                  </tr>
                  <tr>
                    <th scope="row">Reporting</th>
                    <td>
                      1. Daily Report to be sent to central team every evening
                      <br />
                      2. Final report to be prepared .<br />
                      3. Parts list to be made.
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Contact Point</th>
                    <td>
                      1st Contact: Operation Team (projects@ergontec.com,
                      +971527256908) <br />
                      2nd Contact: Technical Team (operations@ergontec.com,
                      +971521988815)
                      <br /> 3rd Contact: Mathew (mbm@ergontec.com,
                      +971585039099)
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Essential Documents Checklist</th>
                    <td>
                      1. Passport
                      <br />
                      2. BOSIET
                      <br />
                      3. Medical (Health) Certificate
                      <br />
                      4. National identity Card (or Driving License)
                      <br />
                      5. Valid PCR (Covid) Test Report
                      <br />
                      6. Entry Permit for {projectList[0]?.Rig_Location}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Points to Note</th>
                    <td>
                      1. Please ensure that the Ergon Reporting formats are
                      followed for the task.
                      <br />
                      2. Please ensure that all mandatory HSE documents are
                      filled in correctly and returned after the completion of
                      the job
                      <br />
                      3. Please ensure that daily progress reports are sent
                      into the central team on a regular basis
                      <br />
                      4. Please ensure that all DPR’s and timesheets are
                      signed and stamped by the client on completion of the
                      task.
                      <br />
                      5. In case of any variation in the Scope of Work defined
                      above, please inform the central team.
                    </td>
                  </tr>
                </tbody>
              </table>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  Prepared By
                  <div>
                    Nishad Majeed
                  </div>
                </div>
                <div>
                  Approved By
                  <div>
                    Jancy K Mathew
                  </div>
                </div>
              </div>


              <hr
                style={{ borderBottom: "2px solid black", marginTop: "60px" }}
              />

              <div className={styles.textContainer} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div>TSD-OPS-FOR-021-21, Rev 1</div>
                <div>30 April 2022</div>

                <div>1</div>
              </div>
              <p>
                {" "}
                This document is digitally generated and does not require a
                signature.
              </p>
            </form>
          </div>
        </div>
        {/* )} */}
      </div>

      {/* ************************************** /equipmentform1*/}
      <div>
        {downLoadStart && (
          <form id="ievf1">
            {/* <div className='equipmentformheading' style={{marginTop:"60px",fontSize:"20px"}}>Equipmentform1</div> */}
            <div style={{ display: "inline-flex", whiteSpace: "nowrap" }}>
              <img
                src={Erogonlogo}
                className="ergonlogo2"
                height="69"
                width="299"
                style={{ marginLeft: "7%" }}
              />
              <div
                style={{
                  marginLeft: "26%",
                  fontSize: "20px",
                  marginTop: "35px",
                }}>
                <b>Measuring Equipment Validation Form</b>
              </div>
            </div>
            <hr
              style={{
                marginTop: "20px",
                borderTop: "2px solid black",
                height: "2px",
                backgroundColor: " black",
                width: "80%",
                marginLeft: "10%",
              }}></hr>
            <table
              style={{
                height: "99px",
                width: "50%",
                borderCollapse: "collapse",
                marginLeft: "26%",
                border: "2px solid black",
                marginTop: "40px",
              }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Equipment
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Tool1}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Tag No
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    NA
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Model
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Model1}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {" "}
                    Sr.No
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Serial_Number1}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Date
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {date}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {" "}
                    Time
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    11:00 AM
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Stage
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Initial
                  </td>
                </tr>
              </tbody>
            </table>

            {projectList[0]?.Tool1.trim() == "Dial Indicator" && (
              <table
                style={{
                  height: "99px",
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "60px",
                }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Description/Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Parameters
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Test Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Results (Pass/Fail)
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Inspect the measuring faces{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Zero scratches and burrs
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Should be free from scratches and burrs
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Measurement the zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      {" "}
                      No zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      No zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  {/* <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr> */}
                </tbody>
              </table>
            )}
            {projectList[0]?.Tool1.trim() == "Torque Wrench" && (
              <table
                style={{
                  height: "99px",
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "60px",
                }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Description/Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Parameters
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Test Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Results (Pass/Fail)
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Inspect the ratchet assembly function{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Slip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      No slip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Visibility and readability of torque markings
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      {" "}
                      Visibility
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Visible and accurate{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Condition of Grip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Firmness{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Firm and strong
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  {/* <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr> */}
                </tbody>
              </table>
            )}
            {/* <h6 style={{marginTop:"100px",marginLeft:"400px"}}>Tested By</h6>
<h6 style={{marginLeft:"400px",marginTop:"50px"}}>Signature</h6>

<h6  style={{marginLeft:"610px",marginTop:"-93px"}}>Vo Doan Thang</h6>

<h6 style={{marginLeft:"810px",marginTop:"-27px"}}>Reviewed by/<br/>Approved by</h6>

<h6 style={{marginLeft:"1020px",marginTop:"-43px"}}>Nishad Majeed</h6> */}

            {/* <hr style={{marginTop:"90px",borderTop: "2px solid black", height:"2px",color:"black" ,backgroundColor:" black",marginLeft:"190px",marginRight:"190px"}}></hr> */}
            <hr
              style={{
                marginTop: "80px",
                marginLeft: "10%",
                borderTop: "2px solid black",
                height: "2px",
                backgroundColor: " black",
                width: "80%",
              }}></hr>
            <footer
              style={{
                marginTop: "8px",
                display: "flex",
                whiteSpace: "nowrap",
              }}>
              <h6 style={{ marginLeft: "21%" }}>TSD-OPS-FOR-007-19, Rev.01</h6>
              <h6 style={{ marginLeft: "18%" }}>30-Apr-22 </h6>
              <h6 style={{ marginLeft: "18%" }}>Page 1</h6>
            </footer>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                whiteSpace: "nowrap",
                marginLeft: "35%",
              }}>
              <h6>
                This document is digitally generated and does not require a
                signature.
              </h6>
            </div>
          </form>
        )}
      </div>
      <div>
        {downLoadStart && (
          <form id="ievf2">
            {/* <div className='equipmentformheading' style={{marginTop:"60px",fontSize:"20px"}}>Equipmentform1</div> */}
            <div style={{ display: "inline-flex", whiteSpace: "nowrap" }}>
              <img
                src={Erogonlogo}
                className="ergonlogo2"
                height="69"
                width="299"
                style={{ marginLeft: "7%" }}
              />
              <div
                style={{
                  marginLeft: "26%",
                  fontSize: "20px",
                  marginTop: "35px",
                }}>
                <b>Measuring Equipment Validation Form</b>
              </div>
            </div>
            <hr
              style={{
                marginTop: "20px",
                borderTop: "2px solid black",
                height: "2px",
                backgroundColor: " black",
                width: "80%",
                marginLeft: "10%",
              }}></hr>
            <table
              style={{
                height: "99px",
                width: "50%",
                borderCollapse: "collapse",
                marginLeft: "26%",
                border: "2px solid black",
                marginTop: "40px",
              }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Equipment
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Tool2}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Tag No
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    NA
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Model
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Model2}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {" "}
                    Sr.No
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Serial_Number2}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Date
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {date}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {" "}
                    Time
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    11:00 AM
                  </td>
                </tr>
              </tbody>
            </table>

            {projectList[0]?.Tool2.trim() == "Dial Indicator" && (
              <table
                style={{
                  height: "99px",
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "60px",
                }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Description/Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Parameters
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Test Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Results (Pass/Fail)
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Inspect the measuring faces{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Zero scratches and burrs
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Should be free from scratches and burrs
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Measurement the zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      {" "}
                      No zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      No zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  {/* <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr> */}
                </tbody>
              </table>
            )}
            {projectList[0]?.Tool2.trim() == "Torque Wrench" && (
              <table
                style={{
                  height: "99px",
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "60px",
                }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Description/Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Parameters
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Test Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Results (Pass/Fail)
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Inspect the ratchet assembly function{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Slip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      No slip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Visibility and readability of torque markings
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      {" "}
                      Visibility
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Visible and accurate{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Condition of Grip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Firmness{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Firm and strong
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  {/* <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr> */}
                </tbody>
              </table>
            )}
            {/* <h6 style={{marginTop:"100px",marginLeft:"400px"}}>Tested By</h6>
<h6 style={{marginLeft:"400px",marginTop:"50px"}}>Signature</h6>

<h6  style={{marginLeft:"610px",marginTop:"-93px"}}>Vo Doan Thang</h6>

<h6 style={{marginLeft:"810px",marginTop:"-27px"}}>Reviewed by/<br/>Approved by</h6>

<h6 style={{marginLeft:"1020px",marginTop:"-43px"}}>Nishad Majeed</h6> */}

            {/* <hr style={{marginTop:"90px",borderTop: "2px solid black", height:"2px",color:"black" ,backgroundColor:" black",marginLeft:"190px",marginRight:"190px"}}></hr> */}
            <hr
              style={{
                marginTop: "80px",
                marginLeft: "10%",
                borderTop: "2px solid black",
                height: "2px",
                backgroundColor: " black",
                width: "80%",
              }}></hr>
            <footer
              style={{
                marginTop: "8px",
                display: "flex",
                whiteSpace: "nowrap",
              }}>
              <h6 style={{ marginLeft: "21%" }}>TSD-OPS-FOR-007-19, Rev.01</h6>
              <h6 style={{ marginLeft: "18%" }}>30-Apr-22 </h6>
              <h6 style={{ marginLeft: "18%" }}>Page 1</h6>
            </footer>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                whiteSpace: "nowrap",
                marginLeft: "35%",
              }}>
              <h6>
                This document is digitally generated and does not require a
                signature.
              </h6>
            </div>
          </form>
        )}
      </div>
      <div>
        {downLoadStart && (
          <form id="evf1">
            {/* <div className='equipmentformheading' style={{marginTop:"60px",fontSize:"20px"}}>Equipmentform1</div> */}
            <div style={{ display: "inline-flex", whiteSpace: "nowrap" }}>
              <img
                src={Erogonlogo}
                className="ergonlogo2"
                height="69"
                width="299"
                style={{ marginLeft: "7%" }}
              />
              <div
                style={{
                  marginLeft: "26%",
                  fontSize: "20px",
                  marginTop: "35px",
                }}>
                <b>Measuring Equipment Validation Form</b>
              </div>
            </div>
            <hr
              style={{
                marginTop: "20px",
                borderTop: "2px solid black",
                height: "2px",
                backgroundColor: " black",
                width: "80%",
                marginLeft: "10%",
              }}></hr>
            <table
              style={{
                height: "99px",
                width: "50%",
                borderCollapse: "collapse",
                marginLeft: "26%",
                border: "2px solid black",
                marginTop: "40px",
              }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Equipment
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Tool1}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Tag No
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    NA
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Model
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Model1}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {" "}
                    Sr.No
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Serial_Number1}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Date
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Estimated_Date_Of_Commencement}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {" "}
                    Time
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    11:00 AM
                  </td>
                </tr>
              </tbody>
            </table>

            {projectList[0]?.Tool1.trim() == "Dial Indicator" && (
              <table
                style={{
                  height: "99px",
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "60px",
                }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Description/Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Parameters
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Test Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Results (Pass/Fail)
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Inspect the measuring faces{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Zero scratches and burrs
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Should be free from scratches and burrs
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Measurement the zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      {" "}
                      No zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      No zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  {/* <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr> */}
                </tbody>
              </table>
            )}
            {projectList[0]?.Tool1.trim() == "Torque Wrench" && (
              <table
                style={{
                  height: "99px",
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "60px",
                }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Description/Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Parameters
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Test Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Results (Pass/Fail)
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Inspect the ratchet assembly function{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Slip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      No slip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Visibility and readability of torque markings
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      {" "}
                      Visibility
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Visible and accurate{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Condition of Grip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Firmness{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Firm and strong
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  {/* <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr> */}
                </tbody>
              </table>
            )}
            {/* <h6 style={{marginTop:"100px",marginLeft:"400px"}}>Tested By</h6>
<h6 style={{marginLeft:"400px",marginTop:"50px"}}>Signature</h6>

<h6  style={{marginLeft:"610px",marginTop:"-93px"}}>Vo Doan Thang</h6>

<h6 style={{marginLeft:"810px",marginTop:"-27px"}}>Reviewed by/<br/>Approved by</h6>

<h6 style={{marginLeft:"1020px",marginTop:"-43px"}}>Nishad Majeed</h6> */}

            {/* <hr style={{marginTop:"90px",borderTop: "2px solid black", height:"2px",color:"black" ,backgroundColor:" black",marginLeft:"190px",marginRight:"190px"}}></hr> */}
            <hr
              style={{
                marginTop: "80px",
                marginLeft: "10%",
                borderTop: "2px solid black",
                height: "2px",
                backgroundColor: " black",
                width: "80%",
              }}></hr>
            <footer
              style={{
                marginTop: "8px",
                display: "flex",
                whiteSpace: "nowrap",
              }}>
              <h6 style={{ marginLeft: "21%" }}>TSD-OPS-FOR-007-19, Rev.01</h6>
              <h6 style={{ marginLeft: "18%" }}>30-Apr-22 </h6>
              <h6 style={{ marginLeft: "18%" }}>Page 1</h6>
            </footer>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                whiteSpace: "nowrap",
                marginLeft: "35%",
              }}>
              <h6>
                This document is digitally generated and does not require a
                signature.
              </h6>
            </div>
          </form>
        )}
      </div>
      <div>
        {downLoadStart && (
          <form id="evf2">
            {/* <div className='equipmentformheading' style={{marginTop:"60px",fontSize:"20px"}}>Equipmentform1</div> */}
            <div style={{ display: "inline-flex", whiteSpace: "nowrap" }}>
              <img
                src={Erogonlogo}
                className="ergonlogo2"
                height="69"
                width="299"
                style={{ marginLeft: "7%" }}
              />
              <div
                style={{
                  marginLeft: "26%",
                  fontSize: "20px",
                  marginTop: "35px",
                }}>
                <b>Measuring Equipment Validation Form</b>
              </div>
            </div>
            <hr
              style={{
                marginTop: "20px",
                borderTop: "2px solid black",
                height: "2px",
                backgroundColor: " black",
                width: "80%",
                marginLeft: "10%",
              }}></hr>
            <table
              style={{
                height: "99px",
                width: "50%",
                borderCollapse: "collapse",
                marginLeft: "26%",
                border: "2px solid black",
                marginTop: "40px",
              }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Equipment
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Tool2}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Tag No
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    NA
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Model
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Model2}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {" "}
                    Sr.No
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Serial_Number2}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    Date
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {projectList[0]?.Estimated_Date_Of_Commencement}
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    {" "}
                    Time
                  </td>
                  <td
                    style={{
                      border: "2px solid black",
                      padding: "5px",
                      borderRadius: "0",
                    }}>
                    11:00 AM
                  </td>
                </tr>
              </tbody>
            </table>

            {projectList[0]?.Tool2.trim() == "Dial Indicator" && (
              <table
                style={{
                  height: "99px",
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "60px",
                }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Description/Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Parameters
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Test Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Results (Pass/Fail)
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Inspect the measuring faces{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Zero scratches and burrs
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Should be free from scratches and burrs
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Measurement the zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      {" "}
                      No zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      No zero error
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  {/* <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr> */}
                </tbody>
              </table>
            )}
            {projectList[0]?.Tool2.trim() == "Torque Wrench" && (
              <table
                style={{
                  height: "99px",
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "60px",
                }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Description/Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Parameters
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Test Criteria
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2C4667",
                        border: "2px solid black",
                        color: "white",
                        borderRadius: "0",
                        padding: "5px",
                      }}>
                      Results (Pass/Fail)
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Inspect the ratchet assembly function{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Slip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      No slip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Visibility and readability of torque markings
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      {" "}
                      Visibility
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Visible and accurate{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Condition of Grip
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Firmness{" "}
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Firm and strong
                    </td>
                    <td
                      style={{
                        border: "2px solid black",
                        padding: "5px",
                        borderRadius: "0",
                      }}>
                      Pass
                    </td>
                  </tr>
                  {/* <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr> */}
                </tbody>
              </table>
            )}
            {/* <h6 style={{marginTop:"100px",marginLeft:"400px"}}>Tested By</h6>
<h6 style={{marginLeft:"400px",marginTop:"50px"}}>Signature</h6>

<h6  style={{marginLeft:"610px",marginTop:"-93px"}}>Vo Doan Thang</h6>

<h6 style={{marginLeft:"810px",marginTop:"-27px"}}>Reviewed by/<br/>Approved by</h6>

<h6 style={{marginLeft:"1020px",marginTop:"-43px"}}>Nishad Majeed</h6> */}

            {/* <hr style={{marginTop:"90px",borderTop: "2px solid black", height:"2px",color:"black" ,backgroundColor:" black",marginLeft:"190px",marginRight:"190px"}}></hr> */}
            <hr
              style={{
                marginTop: "80px",
                marginLeft: "10%",
                borderTop: "2px solid black",
                height: "2px",
                backgroundColor: " black",
                width: "80%",
              }}></hr>
            <footer
              style={{
                marginTop: "8px",
                display: "flex",
                whiteSpace: "nowrap",
              }}>
              <h6 style={{ marginLeft: "21%" }}>TSD-OPS-FOR-007-19, Rev.01</h6>
              <h6 style={{ marginLeft: "18%" }}>30-Apr-22 </h6>
              <h6 style={{ marginLeft: "18%" }}>Page 1</h6>
            </footer>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                whiteSpace: "nowrap",
                marginLeft: "35%",
              }}>
              <h6>
                This document is digitally generated and does not require a
                signature.
              </h6>
            </div>
          </form>
        )}
      </div>
      <div
        style={{ display: "none" }}
      >
        {/* {downLoadStart && ( */}
        <form id="mobplan">
          <div className={styles.workplancontainer}>
            <div style={{ marginTop: "-90px" }}>
              <div>
                <img
                  src={Erogonlogo}
                  className="ergonlogo2"
                  height="59px"
                  width="279px"
                  style={{
                    position: "absolute",
                    marginLeft: "5px",
                    marginTop: "0px",
                  }}
                />
              </div>
              <div className={styles.headingmp}>
                <h1>
                  <b>
                    <u>MOBILISATION PLAN</u>
                  </b>
                </h1>
              </div>
              <hr
                style={{
                  marginTop: "50px",
                  marginLeft: "-20%",
                  borderTop: "2px solid black",
                  height: "2px",
                  backgroundColor: " black",
                  width: "128%",
                }}></hr>
              <div className={styles.projectdetialsmp}>
                <table className={styles.table1} ref={mobProjDetRef}>
                  <tr>
                    <th>
                      <h4>PROJECT:</h4>
                    </th>
                    <td>
                      <h4>
                        {projectList[0]?.Rig_Name}-
                        {projectList[0]?.Service_Component}
                      </h4>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h4>S/O No:</h4>
                    </th>
                    <td>
                      <h4>{projectList[0]?.Sales_Order_No}</h4>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h4>Date: </h4>
                    </th>
                    <td>
                      <h4>
                        {projectList[0]?.Document_Date &&
                          (() => {
                            const dateParts =
                              projectList[0]?.Document_Date?.split("/");
                            const originalDate = new Date(
                              `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                            );
                            const newDate = new Date(
                              originalDate.getTime() + 3 * 24 * 60 * 60 * 1000
                            );

                            // Format the date as 'DD/MM/YYYY'
                            const formattedDate =
                              newDate.getDate().toString().padStart(2, "0") +
                              "/" +
                              (newDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0") +
                              "/" +
                              newDate.getFullYear();

                            return formattedDate;
                          })()}
                      </h4>
                    </td>
                  </tr>
                </table>
                <div className={styles.legendmp}>
                  {/* <h5> LEGEND:</h5> */}
                  <table className={styles.table2}>
                    <tbody>
                      <tr>
                        <th>PROPOSED DATE:</th>
                        <td>
                          <button className={styles.buttonmp}></button>
                        </td>
                      </tr>
                      <tr>
                        <th>ACTUAL DATE:</th>
                        <td>
                          <button className={styles.buttonmp2}></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <table ref={mobMonthsRef}>
                <tbody style={{ marginBottom: "15px" }}>
                  <tr>
                    <td className={styles.tableheading1mp} style={{ background: "white" }} >
                      {projectList[0]?.Document_Date &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Document_Date?.split("/");
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
                          );

                          const docdatemon = (newDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");

                          return getMonthName(docdatemon);
                        })()}
                    </td>
                    <td className={styles.tableheading2mp} style={{ background: "white" }}>
                      {projectList[0]?.Document_Date &&
                        (() => {
                          const dateParts =
                            projectList[0].Document_Date.split("/");
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
                          );

                          const docdatemon = (newDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const docdatemona = addAndModulo(
                            parseInt(docdatemon),
                            1
                          );

                          return getMonthName(docdatemona);
                        })()}
                    </td>
                    <td className={styles.tableheading3mp} style={{ background: "white" }}>
                      {projectList[0]?.Document_Date &&
                        (() => {
                          const dateParts =
                            projectList[0].Document_Date.split("/");
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
                          );

                          const docdatemon = (newDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const docdatemonb = addAndModulo(
                            parseInt(docdatemon),
                            2
                          );

                          return getMonthName(docdatemonb);
                        })()}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className={styles.tablemobplan} ref={mobPlanTableRef}>
                <thead>
                  <tr>
                    <th
                      className="text-center table-light"
                      background-color="white"
                      colSpan="2"></th>
                    <th colSpan="2" className={styles.vv}>
                      DATE
                    </th>
                    {headings1.map((heading, index) => (
                      <th key={index}>{heading}</th>
                    ))}
                    {headings2.map((heading, index) => (
                      <th key={index}>{heading}</th>
                    ))}
                    {headings3.map((heading, index) => (
                      <th key={index}>{heading}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>S.no</td>
                    <td>ACTIVITIES</td>
                    <td>START</td>
                    <td>END</td>
                    {headings1.map((heading, index) => (
                      <td key={index}></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td key={index}></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td key={index}></td>
                    ))}
                  </tr>
                  <tr className={styles.norad}>
                    <td rowSpan="2">1.</td>
                    <td rowSpan="2">SERVICE QUALITY PLANNING</td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Document_Date &&
                        (() => {
                          const dateParts =
                            projectList[0].Document_Date.split("/");
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
                          );

                          const docdatemon = (newDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>

                    <td className={styles.evenmp}>
                      {projectList[0]?.Document_Date &&
                        (() => {
                          const dateParts =
                            projectList[0].Document_Date.split("/");
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          index === parseInt(firstday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td key={index}></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td key={index}></td>
                    ))}
                  </tr>

                  <tr className={styles.oddmp}>
                    <td>
                      {projectList[0]?.Document_Date &&
                        (() => {
                          const dateParts =
                            projectList[0].Document_Date.split("/");
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    <td>
                      {projectList[0]?.Document_Date &&
                        (() => {
                          const dateParts =
                            projectList[0].Document_Date.split("/");
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 2 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          index === parseInt(firstday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td key={index}></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td key={index}></td>
                    ))}
                  </tr>
                  <tr>
                    <td rowSpan="2">2.</td>
                    <td rowSpan="2">TOOL BOX/PARTS MOVEMENT</td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Date_Of_Commencement &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Date_Of_Commencement.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() - 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Date_Of_Commencement &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Date_Of_Commencement.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() - 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}{" "}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(secondmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index === parseInt(secondday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(secondmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index === parseInt(secondday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(secondmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                            index === parseInt(secondday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                  </tr>
                  <tr className={styles.oddmp}>
                    <td>
                      {projectList[0]?.Estimated_Date_Of_Commencement &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Date_Of_Commencement.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() - 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    <td>
                      {projectList[0]?.Estimated_Date_Of_Commencement &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Date_Of_Commencement.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() - 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>{" "}
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(secondmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index === parseInt(secondday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(secondmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index === parseInt(secondday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(secondmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                            index === parseInt(secondday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                  </tr>
                  <tr>
                    <td rowSpan="2">3.</td>
                    <td rowSpan="2">MOBILISATION OF TECHNICIANS</td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Date_Of_Commencement}
                    </td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Date_Of_Commencement}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(thirdmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index === parseInt(thirdday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(thirdmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index === parseInt(thirdday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(thirdmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                            index === parseInt(thirdday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                  </tr>
                  <tr>
                    <td className={styles.oddmp}>
                      {projectList[0]?.Estimated_Date_Of_Commencement}
                    </td>
                    <td className={styles.oddmp}>
                      {projectList[0]?.Estimated_Date_Of_Commencement}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(thirdmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index === parseInt(thirdday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(thirdmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index === parseInt(thirdday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(thirdmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                            index === parseInt(thirdday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                  </tr>
                  <tr>
                    <td rowSpan="2">4.</td>
                    <td rowSpan="2">SERVICE ACTIVITY TO BE PERFORMED</td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Date_Of_Commencement &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Date_Of_Commencement.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Project_Completion_Month}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                          (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index >= parseInt(fourthday) + parseInt(-1) &&
                            index <= parseInt(fifthday) + parseInt(-1)
                            ? "blue-background"
                            : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                              index >= parseInt(fourthday) + parseInt(-1)
                              ? "blue-background2"
                              : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                              (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                                index >= parseInt(fourthday) + parseInt(-1)
                                ? "blue-background2"
                                : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                                (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2) &&
                                  index >= parseInt(fourthday) + parseInt(-1)
                                  ? "blue-background2"
                                  : ""
                        }>
                        {" "}
                      </td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                          (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index >= parseInt(fourthday) + parseInt(-1) &&
                            index <= parseInt(fifthday) + parseInt(-1)
                            ? "blue-background"
                            : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                              index >= parseInt(fourthday) + parseInt(-1)
                              ? "blue-background2"
                              : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                              (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                                index <= parseInt(fifthday) + parseInt(-1)
                                ? "blue-background2"
                                : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                                (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2)
                                  ? "blue-background2"
                                  : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                                  (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2)
                                    ? "blue-background2"
                                    : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                                    (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2) &&
                                      index >= parseInt(fourthday) + parseInt(-1)
                                      ? "blue-background2"
                                      : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                          (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index >= parseInt(fourthday) + parseInt(-1) &&
                            index <= parseInt(fifthday) + parseInt(-1)
                            ? "blue-background"
                            : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                              index <= parseInt(fifthday) + parseInt(-1)
                              ? "blue-background2"
                              : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                              (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                                index <= parseInt(fifthday) + parseInt(-1)
                                ? "blue-background2"
                                : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                                (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2)
                                  ? "blue-background2"
                                  : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                                  (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2)
                                    ? "blue-background2"
                                    : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                                    (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2)
                                      ? "blue-background2"
                                      : ""
                        }></td>
                    ))}{" "}
                  </tr>
                  <tr className={styles.oddmp}>
                    <td>
                      {projectList[0]?.Estimated_Date_Of_Commencement &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Date_Of_Commencement.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    <td>
                      {projectList[0]?.Estimated_Project_Completion_Month}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                          (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index >= parseInt(fourthday) + parseInt(-1) &&
                            index <= parseInt(fifthday) + parseInt(-1)
                            ? "green-background"
                            : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                              index >= parseInt(fourthday) + parseInt(-1)
                              ? "green-background2"
                              : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                              (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                                index >= parseInt(fourthday) + parseInt(-1)
                                ? "green-background2"
                                : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                                (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                                  index >= parseInt(fourthday) + parseInt(-1)
                                  ? "green-background2"
                                  : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                                  (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2) &&
                                    index >= parseInt(fourthday) + parseInt(-1)
                                    ? "green-background2"
                                    : ""
                        }>
                        {" "}
                      </td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                          (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index >= parseInt(fourthday) + parseInt(-1) &&
                            index <= parseInt(fifthday) + parseInt(-1)
                            ? "green-background"
                            : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                              index >= parseInt(fourthday) + parseInt(-1)
                              ? "green-background2"
                              : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                              (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                                index <= parseInt(fifthday) + parseInt(-1)
                                ? "green-background2"
                                : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                                (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2)
                                  ? "green-background2"
                                  : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                                  (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2)
                                    ? "green-background2"
                                    : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                                    (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2) &&
                                      index >= parseInt(fourthday) + parseInt(-1)
                                      ? "green-background2"
                                      : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                          (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index >= parseInt(fourthday) + parseInt(-1) &&
                            index <= parseInt(fifthday) + parseInt(-1)
                            ? "green-background"
                            : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                              index <= parseInt(fifthday) + parseInt(-1)
                              ? "green-background2"
                              : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                              (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                                index <= parseInt(fifthday) + parseInt(-1)
                                ? "green-background2"
                                : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                                (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2)
                                  ? "green-background2"
                                  : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                                  (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2)
                                    ? "green-background2"
                                    : (((parseInt(fourthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                                    (((parseInt(fifthmonth) - parseInt(firstmonth) + 12) % 12) > 2)
                                      ? "green-background2"
                                      : ""
                        }></td>
                    ))}{" "}
                  </tr>
                  <tr>
                    <td rowSpan="2">5.</td>
                    <td rowSpan="2">DEMOB OF TECHNICIANS</td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Project_Completion_Month &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Project_Completion_Month?.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Project_Completion_Month &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Project_Completion_Month?.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(sixthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index === parseInt(sixthday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(sixthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index === parseInt(sixthday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(sixthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                            index === parseInt(sixthday - 1)
                            ? "blue-background"
                            : ""
                        }></td>
                    ))}
                  </tr>
                  <tr className={styles.oddmp}>
                    <td>
                      {projectList[0]?.Estimated_Project_Completion_Month &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Project_Completion_Month?.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    <td>
                      {projectList[0]?.Estimated_Project_Completion_Month &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Project_Completion_Month?.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 1 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(sixthmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index === parseInt(sixthday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(sixthmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index === parseInt(sixthday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(sixthmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                            index === parseInt(sixthday - 1)
                            ? "green-background"
                            : ""
                        }></td>
                    ))}
                  </tr>
                  <tr>
                    <td rowSpan="2">6.</td>
                    <td rowSpan="2">SUBMISSION OF SERVICE REPORT</td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Project_Completion_Month &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Project_Completion_Month?.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 15 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    <td className={styles.evenmp}>
                      {projectList[0]?.Estimated_Project_Completion_Month &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Project_Completion_Month?.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 18 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                          (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index >= parseInt(seventhday - 1) &&
                            index <= parseInt(eightday - 1)
                            ? "blue-background"
                            : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                              index >= parseInt(seventhday - 1)
                              ? "blue-background"
                              : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                              (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) > 2) &&
                              index >= parseInt(seventhday - 1)
                                ? "blue-background"
                                : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                          (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index >= parseInt(seventhday - 1) &&
                            index <= parseInt(eightday - 1)
                            ? "blue-background"
                            : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                              index <= parseInt(eightday - 1)
                              ? "blue-background"
                              : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                              (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                                index >= parseInt(seventhday - 1)
                                ? "blue-background"
                                : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                                  (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) > 2) &&
                                  index >= parseInt(seventhday - 1)
                                    ? "blue-background"
                                    : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                          (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                            index >= parseInt(seventhday - 1) &&
                            index <= parseInt(eightday - 1)
                            ? "blue-background"
                            : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                              index <= parseInt(eightday - 1)
                              ? "blue-background"
                              : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                              (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) > 2) &&
                              index >= parseInt(seventhday - 1)
                                ? "blue-background"
                                : ""
                        }></td>
                    ))}
                  </tr>
                  <tr className={styles.oddmp}>
                    <td>
                      {projectList[0]?.Estimated_Project_Completion_Month &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Project_Completion_Month?.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 15 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>
                    <td>
                      {projectList[0]?.Estimated_Project_Completion_Month &&
                        (() => {
                          const dateParts =
                            projectList[0]?.Estimated_Project_Completion_Month?.split(
                              "/"
                            );
                          const originalDate = new Date(
                            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                          );
                          const newDate = new Date(
                            originalDate.getTime() + 18 * 24 * 60 * 60 * 1000
                          );

                          // Format the date as 'DD/MM/YYYY'
                          const formattedDate =
                            newDate.getDate().toString().padStart(2, "0") +
                            "/" +
                            (newDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            newDate.getFullYear();

                          return formattedDate;
                        })()}
                    </td>

                    {headings1.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                          (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            index >= parseInt(seventhday - 1) &&
                            index <= parseInt(eightday - 1)
                            ? "green-background"
                            : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                              index >= parseInt(seventhday - 1)
                              ? "green-background"
                              : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                              (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) > 2) &&
                              index >= parseInt(seventhday - 1)
                                ? "green-background"
                                : ""
                        }></td>
                    ))}
                    {headings2.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                          (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            index >= parseInt(seventhday - 1) &&
                            index <= parseInt(eightday - 1)
                            ? "green-background"
                            : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 0) &&
                            (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                              index <= parseInt(eightday - 1)
                              ? "green-background"
                              : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                              (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                                index >= parseInt(seventhday - 1)
                                ? "green-background"
                                : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                                (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) > 2) &&
                                index >= parseInt(seventhday - 1)
                                  ? "green-background"
                                  : ""
                        }></td>
                    ))}
                    {headings3.map((heading, index) => (
                      <td
                        key={index}
                        className={
                          (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                          (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                            index >= parseInt(seventhday - 1) &&
                            index <= parseInt(eightday - 1)
                            ? "green-background"
                            : (((parseInt(seventhmonth) - parseInt(firstmonth) + 12) % 12) === 1) &&
                            (((parseInt(eightmonth) - parseInt(firstmonth) + 12) % 12) === 2) &&
                              index <= parseInt(eightday - 1)
                              ? "green-background"
                              : ""
                        }></td>
                    ))}
                  </tr>
                </tbody>
              </table>
              <div style={{ display: "inline-flex", marginTop: "40px" }}> <> <h6 style={{ whiteSpace: "nowrap", marginLeft: "50%" }}> <b>Prepared by :</b> Glesie Paderon</h6><h6 style={{ whiteSpace: "nowrap", marginLeft: "50%" }} > <b>Approved by :</b> Nishad Majeed</h6></></div>
              <hr
                style={{
                  marginTop: "20px",
                  marginLeft: "-20%",
                  borderTop: "2px solid black",
                  height: "2px",
                  backgroundColor: " black",
                  width: "128%",
                }}></hr>
              <div className={styles.end}>
                <div className={styles.endmp}>TSD-OPS-FOR-016-19, Rev.1</div>
                <div className={styles.end2mp}>1 Apr 2022</div>
                <div className={styles.end3mp}>Page 1</div>
              </div>
              <h6 style={{ marginTop: "20px", marginLeft: "30%" }}>
                {" "}
                This document is digitally generated and does not require a
                signature.
              </h6>
            </div>
          </div>
        </form>
        {/* )} */}
      </div>
      <div>
          <table ref={table7Ref} style={{ display: "none" }}>
          <tr>
              <td colspan="6">
              SRP PRESERVATION FORM
              </td>
            </tr>
            <tr>
              <td >
                Rig:
              </td>
              <td  colSpan="3">
              {projectData.length > 0 && projectData[0].Rig_Name}
              </td>

              <td>
                Preservation Doc No:
              </td>
              <td >
                SRPC-{projectData.length > 0 && projectData[0].Sales_Order_No}
              </td>
            </tr>
            <tr>
              <td >P.O No:</td>
              <td colSpan="3">{projectData.length > 0 && projectData[0].Po_No}</td>
              <td>Date:</td>
              <td>{projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}</td>
            </tr>
            <tr >
              <td>
                Equipment ID/Traceability
              </td>
              <td colSpan="2">Equipment Details</td>

              <td>Preservation checklist</td>
              <td>Performed on</td>
              <td>
                Next preservation evaluation
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="3"> General Storage Checklist</td>
            </tr>
            <tr>
              <td rowspan="6">{projectData[0]?.Item_ID1}</td>
              <td >Equipment Name:</td>
              <td >{projectData.length > 0 && projectData[0].Tool1}</td>
              <td>
                {istorque? "Checked and verified for dust, oil drops or any other debris before storage ":"The grease or oil on the dial face was cleaned using a soft cloth"}
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>

            <tr>
              <td >Manufacturer:</td>
              <td>
                {projectData.length > 0 && projectData[0].Make1}
               
              </td>
              <td  rowSpan="2">
             {istorque?"Verified the torque wrench to be turned down to lowest possible setting":"Contact point of the Plunger spindle was sealed by rubber bellows, sealed by protective sleeve at the top"}
              </td>
              <td  rowSpan="2">
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td rowSpan="2">{estimatedCompletionDate}</td>
            </tr>

            <tr>
              <td>Model:</td>
              <td>{projectData.length > 0 && projectData[0].Model1}</td>
            </tr>
            <tr>
              <td>Serial No:</td>
              <td>{projectData.length > 0 && projectData[0].Serial_Number1}</td>

              <td>{istorque?"Checked and verified for any signs of wear or deterioration":"Back wall sealed , glass and outer ring tightly screwed"} </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>

            <tr>
              <td>Calibration Date:</td>
              <td>{projectData.length > 0 && projectData[0].Calibration_Date1}</td>
              <td>
              {istorque?"Verified storage of wrench in it's original case":"To be stored in a protective case"}
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td>Next Calibration Due Date:</td>
              <td>{projectData.length > 0 && projectData[0].Calibration_Due_Date1}</td>
              <td>
              {istorque?"Verified the storage location to be free of humidity to prevent moisture accumulation":"Stem to be coated with anti corrossion oil"}

              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>

            <tr>
              <td></td>
              <td ></td>
              <td></td>
              <td colSpan="3">
                <u>Transportation and Handling Checklist</u>
              </td>
            </tr>
            <tr>
              <td rowSpan="2">Supervisor:</td>

              <td colspan="2" rowSpan="2">
              {formattedName2}
              </td>

              <td >
                Checked and verified that the Sturdy case was in good condition   
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td>
                Checked and verified the smooth functioning of the tool trolly
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td rowSpan="2">Approved by:</td>

              <td colspan="2" rowSpan="2">
                Nishad Majeed
              </td>
              <td>
                Checked and verified the tidy and stable surface of the trolley platform
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td>
                Checked and verified friction-free Packing of the tool
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td colspan="6">
                Note: Preservation is valid till the next project or 1 year
                whichever is earlier. At the end of each project, preservation
                has to be carried out again using the same procedure.
              </td>
            </tr>
          </table>
        </div>
      <div>
          <table ref={table8Ref} style={{ display: "none" }}>
          <tr>
              <td colspan="6">
              SRP PRESERVATION FORM
              </td>
            </tr>
            <tr>
              <td >
                Rig:
              </td>
              <td  colSpan="3">
              {projectData.length > 0 && projectData[0].Rig_Name}
              </td>

              <td>
                Preservation Doc No:
              </td>
              <td >
                SRPC-{projectData.length > 0 && projectData[0].Sales_Order_No}
              </td>
            </tr>
            <tr>
              <td >P.O No:</td>
              <td colSpan="3">{projectData.length > 0 && projectData[0].Po_No}</td>
              <td>Date:</td>
              <td>{projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}</td>
            </tr>
            <tr >
              <td>
                Equipment ID/Traceability
              </td>
              <td colSpan="2">Equipment Details</td>

              <td>Preservation checklist</td>
              <td>Performed on</td>
              <td>
                Next preservation evaluation
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="3"> General Storage Checklist</td>
            </tr>
            <tr>
              <td rowspan="6">{projectData[0]?.Item_ID2}</td>
              <td >Equipment Name:</td>
              <td >{projectData.length > 0 && projectData[0].Tool2}</td>
              <td>
                {istorque2? "Checked and verified for dust, oil drops or any other debris before storage ":"The grease or oil on the dial face was cleaned using a soft cloth"}
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>

            <tr>
              <td >Manufacturer:</td>
              <td>
                {projectData.length > 0 && projectData[0].Make2}
               
              </td>
              <td  rowSpan="2">
             {istorque2?"Verified the torque wrench to be turned down to lowest possible setting":"Contact point of the Plunger spindle was sealed by rubber bellows, sealed by protective sleeve at the top"}
              </td>
              <td  rowSpan="2">
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td rowSpan="2">{estimatedCompletionDate}</td>
            </tr>

            <tr>
              <td>Model:</td>
              <td>{projectData.length > 0 && projectData[0].Model2}</td>
            </tr>
            <tr>
              <td>Serial No:</td>
              <td>{projectData.length > 0 && projectData[0].Serial_Number2}</td>

              <td>{istorque2?"Checked and verified for any signs of wear or deterioration":"Back wall sealed , glass and outer ring tightly screwed"} </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>

            <tr>
              <td>Calibration Date:</td>
              <td>{projectData.length > 0 && projectData[0].Calibration_Date2}</td>
              <td>
              {istorque2?"Verified storage of wrench in it's original case":"To be stored in a protective case"}
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td>Next Calibration Due Date:</td>
              <td>{projectData.length > 0 && projectData[0].Calibration_Due_Date2}</td>
              <td>
              {istorque2?"Verified the storage location to be free of humidity to prevent moisture accumulation":"Stem to be coated with anti corrossion oil"}

              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>

            <tr>
              <td></td>
              <td ></td>
              <td></td>
              <td colSpan="3">
                <u>Transportation and Handling Checklist</u>
              </td>
            </tr>
            <tr>
              <td rowSpan="2">Supervisor:</td>

              <td colspan="2" rowSpan="2">
              {formattedName2}
              </td>

              <td >
                Checked and verified that the Sturdy case was in good condition   
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td>
                Checked and verified the smooth functioning of the tool trolly
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td rowSpan="2">Approved by:</td>

              <td colspan="2" rowSpan="2">
                Nishad Majeed
              </td>
              <td>
                Checked and verified the tidy and stable surface of the trolley platform
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td>
                Checked and verified friction-free Packing of the tool
              </td>
              <td>
                {projectData.length > 0 && projectData[0].Estimated_Project_Completion_Month}
              </td>
              <td>{estimatedCompletionDate}</td>
            </tr>
            <tr>
              <td colspan="6">
                Note: Preservation is valid till the next project or 1 year
                whichever is earlier. At the end of each project, preservation
                has to be carried out again using the same procedure.
              </td>
            </tr>
          </table>
        </div>
      <div>
        {downLoadStart && (
          <form id="perv2">
            <>
              <div>
                <div style={{ display: "inline-flex" }}>
                  <img
                    src={Erogonlogo}
                    className={styles.ergonlogo2}
                    height="69"
                    width="299"
                    style={{
                      textAlign: "center",
                      marginLeft: "50px",
                      marginTop: "25px",
                    }}
                  />

                  <h3
                    style={{
                      whiteSpace: "nowrap",
                      marginLeft: "30%",
                      marginTop: "85px",
                    }}>
                    {" "}
                    SRP PRESERVATION FORM
                  </h3>
                </div>
                <div style={{ width: "90%", marginLeft: "50px" }}>
                  <table className={styles.preservetable}>
                    <tr>
                      <td style={{ backgroundColor: "white" }} colSpan="1">
                        Rig
                      </td>
                      <td style={{ backgroundColor: "white" }} colSpan="7">
                        {projectList[0]?.Rig_Name}
                      </td>

                      <td colspan="2" style={{ backgroundColor: "white" }}>
                        Preservation Doc No:
                      </td>
                      <td style={{ backgroundColor: "white" }}>
                        SRPC-{projectList[0]?.Sales_Order_No}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="1">P.O No.</td>
                      <td colSpan="7">{projectList[0]?.Po_No}</td>
                      <td colspan="2">Date</td>
                      <td>
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#dee2e6" }}>
                      <td>
                        Equipment
                        <br />
                        ID/Traceability
                      </td>
                      <td colSpan="6">Equipment Details</td>

                      <td>Preservation checklist</td>
                      <td colSpan="2">Performed on</td>
                      <td>
                        Next preservation
                        <br />
                        evaluation
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colSpan="3"></td>
                      <td colSpan="3"></td>
                      <td colspan="4"> General Storage Checklist</td>
                    </tr>
                    <tr>
                      <td rowspan="6">ET-SRP-07</td>
                      <td colSpan="3">Equipment Name :</td>
                      <td colSpan="3">{projectList[0]?.Tool2}</td>
                      <td colspan="1">
                        {projectList[0]?.Tool2.trim() === "Dial Indicator" && (
                          <>
                            The grease or oil on the dial face
                            <br />
                            was cleaned using a soft cloth
                          </>
                        )}

                        {projectList[0]?.Tool2.trim() === "Torque Wrench" && (
                          <>
                            Checked and verified for
                            <br />
                            dust, oil drops or any other
                            <br />
                            debris before storage
                          </>
                        )}
                      </td>
                      <td colspan="2">
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                      <td>{date}</td>
                    </tr>
                    <tr>
                      <td colspan="3">Manufacturer :</td>
                      <td colSpan="3">
                        {projectList[0]?.Make2}
                        <br />
                      </td>
                      <td colspan="1" rowSpan="2">
                        {projectList[0]?.Tool2.trim() === "Dial Indicator" && (
                          <>
                            Contact point of the Plunger
                            <br />
                            spindle was sealed by rubber bellows,
                            <br />
                            sealed by protective sleeve at the top
                          </>
                        )}

                        {projectList[0]?.Tool2.trim() === "Torque Wrench" && (
                          <>
                            Verified the torque wrench
                            <br />
                            to be turned down to lowest
                            <br />
                            possible setting
                          </>
                        )}
                      </td>
                      <td colspan="2" rowSpan="2">
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                      <td rowSpan="2">{date}</td>
                    </tr>
                    <tr>
                      <td colSpan="3">Model</td>
                      <td colSpan="3">{projectList[0]?.Model2}</td>
                    </tr>
                    <tr>
                      <td colSpan="3">Serial No.</td>
                      <td colSpan="3">{projectList[0]?.Serial_Number2}</td>

                      <td>
                        {projectList[0]?.Tool2.trim() === "Dial Indicator" && (
                          <>
                            Back wall sealed ,
                            <br />
                            glass and outer ring
                            <br />
                            tightly screwed
                          </>
                        )}

                        {projectList[0]?.Tool2.trim() === "Torque Wrench" && (
                          <>
                            Checked and verified for any <br />
                            signs of wear or deterioration
                          </>
                        )}
                      </td>
                      <td colSpan="2">
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                      <td>{date}</td>
                    </tr>

                    <tr>
                      <td colSpan="3">Calibration Date</td>
                      <td colSpan="3">{projectList[0]?.Calibration_Date1}</td>
                      <td colspan="1">
                        {projectList[0]?.Tool2.trim() === "Dial Indicator" && (
                          <>To be stored in a protective case</>
                        )}

                        {projectList[0]?.Tool2.trim() === "Torque Wrench" && (
                          <>
                            Verified storage of wrench
                            <br />
                            in it's original case
                          </>
                        )}
                      </td>
                      <td colspan="2">
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                      <td>{date}</td>
                    </tr>
                    <tr>
                      <td colSpan="3">Next Calibration Due Date</td>
                      <td colSpan="3">
                        {projectList[0]?.Calibration_Due_Date2}
                      </td>
                      <td colspan="1">
                        {projectList[0]?.Tool2.trim() === "Dial Indicator" && (
                          <>
                            Stem to be coated with
                            <br />
                            anti corrossion oil
                          </>
                        )}

                        {projectList[0]?.Tool2.trim() === "Torque Wrench" && (
                          <>
                            Verified the storage location
                            <br />
                            to be free of humidity to
                            <br />
                            prevent moisture
                            <br />
                            accumulation{" "}
                          </>
                        )}
                      </td>
                      <td colspan="2">
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                      <td>{date}</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td colSpan="3"></td>
                      <td colSpan="3"></td>
                      <td colSpan="4">
                        <u>Transportation and Handling Checklist</u>
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2">Supervisor</td>

                      <td colspan="5" rowSpan="2">
                        Roland Wayne Benn
                      </td>

                      <td colspan="2">
                        Checked and verified that
                        <br />
                        the Sturdy case was in good
                        <br />
                        condition
                      </td>
                      <td colspan="2">
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                      <td>{date}</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        Checked and verified the
                        <br />
                        smooth functioning of the
                        <br />
                        tool trolly
                      </td>
                      <td colspan="2">
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                      <td>{date}</td>
                    </tr>
                    <tr>
                      <td rowSpan="2">Approved by</td>

                      <td colspan="5" rowSpan="2">
                        Nishad Majeed
                      </td>
                      <td colspan="2">
                        Checked and verified the tidy
                        <br />
                        and stable surface of the
                        <br />
                        trolley platform
                      </td>
                      <td colspan="2">
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                      <td>{date}</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        Checked and verified friction-
                        <br />
                        free Packing of the tool
                      </td>
                      <td colspan="2">
                        {projectList[0]?.Estimated_Project_Completion_Month}
                      </td>
                      <td>{date}</td>
                    </tr>
                    <tr>
                      <td colspan="11">
                        Note: Preservation is valid till the next project or 1
                        year whichever is earlier. At the end of each project,
                        preservation has to be carried out again using the same
                        procedure.
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <hr style={{ marginTop: "50px", border: "2px solid black" }}></hr>
              <footer
                style={{
                  marginTop: "50px",
                  display: "flex",
                  whiteSpace: "nowrap",
                }}>
                <h6 style={{ marginLeft: "2%" }}>TSD-OPS-FOR-022-21, Rev.1</h6>
                <h6 style={{ marginLeft: "35%" }}>30-Apr-22 </h6>
                <h6 style={{ marginLeft: "35%" }}>Page 1</h6>
              </footer>
              <h6
                style={{
                  marginTop: "20px",
                  display: "flex",
                  whiteSpace: "nowrap",
                  marginLeft: "30%",
                }}>
                This document is digitally generated and does not require a
                signature.
              </h6>
            </>
          </form>
        )}
      </div>
    </div>
  );
};

export default Apidocs;
