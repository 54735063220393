import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  getNativeSelectUtilityClasses,
} from "@mui/material";
import styles from "./css/workshop_spares_trading_dispatch_update.module.css"; 
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Adjust the icon import if necessary
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

const userEmail = localStorage.getItem("useremail");
if (userEmail) {
  console.log("Found value:", userEmail);
} else {
  console.log("No value found");
}
const Name = localStorage.getItem("Name");
if (Name) {
  console.log("Found value:", Name);
} else {
  console.log("No value found");
}

function SparestradingDispatchUpdate() {
  const { project_id, id } = useParams(); // Project ID taken from URL
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks
  const [editItemMode, setEditItemMode] = useState(null);
  const [getDispatch, setGetDispatch] = useState([]);
  const [projectList, setProjectList] = useState({});

  const [filterWip, setFilterWip] = useState([]);
  const [filterFg, setFilterFg] = useState([]);
  const [isfillsameWip, setIsFillSameWip] = useState(false);
  const [isfillsameFg, setIsFillSameFg] = useState(false);
  const [isedit1, setIsedit1] = useState(false);
  const [isedit2, setIsedit2] = useState(false);
  const [isDocument, setIsDocument] = useState(true);
  const [isDocumentEdit, setIsDocumentEdit] = useState(false);
  const [isItemsEdit, setIsItemsEdit] = useState(false);
  const [activeTab, setActiveTab] = useState("Documents");
  const [inventoryUtilisedItems, setInventoryUtilisedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItems2, setFilteredItems2] = useState([]);
  const [featureincomingdeliverydocedit, setfeatureincomingdeliverydocedit] =
    useState([]);
  const [featureincomingdeliveryitemedit, setfeatureincomingdeliveryitemedit] =
    useState([]);

  const [activeStep, setActiveStep] = useState(0); //to store the activeStep for the stepper and to render the tables, 0 for "Material Produced", 1 for "Material Consumed"
  const [fileName3, setFileName3] = useState("");
  const [fileName4, setFileName4] = useState("");
  const [fileNamepo, setFileNamepo] = useState("");
  const [documents, setDocuments] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [documentToUpload3, setDocumentToUpload3] = useState(null);
  const [documentToUpload4, setDocumentToUpload4] = useState(null);
  //   const [selectedPurpose, setSelectedPurpose] = useState("");
  const [inputValues, setInputValues] = useState({});
  const [inputValues2, setInputValues2] = useState({});
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [mappedValues, setMappedValues] = useState({});
  const [finalDispatchData, setFinalDispatchData] = useState([]);
  const [removepodocument, setRemovePODocument] = useState(false);
  const [removequotedocument, setRemoveQuoteDocument] = useState(false);
  const [isPODocumentMarkedForRemoval, setIsPODocumentMarkedForRemoval] =
    useState(false);
  const [isQuoteDocumentMarkedForRemoval, setIsQuoteDocumentMarkedForRemoval] =
    useState(false);

  const [refresh, setRefresh] = useState(false);
  const [Project_SO, setProjectSO] = useState({});

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",
    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });
  const [dispatchdata, setDispatchdata] = useState({
    Purpose: "",
    Receiving_Party: "",
    Deliverydate: "",
    Ponumber: "",
    Quotenumber: "",
    Dispatchqty: "",
    PO_document: "",
    Quote_document: "",
  });

  const [originalDispatchData, setOriginalDispatchData] = useState({
    Purpose: "",
    Receiving_Party: "",
    Deliverydate: "",
    Ponumber: "",
    Quotenumber: "",
    Dispatchqty: "",
    PO_document: "",
    Quote_document: "",
  });
  const [selectedPurpose, setSelectedPurpose] = useState(
    dispatchdata.Purpose || ""
  );



  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == project_id);
      const userRes = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_user_details_project_details?Email=${userEmail}`
      );

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
        Done_By: userRes.data.data[0].Name,
      });
      // console.log(filter);
      // console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  const [formData, setFormData] = useState({
    Sales_Order_No: "",
    PO_No: "",
    PO_Date: null,
    Quote_No: "",
    Quote_Date: null,
    Costing_ID: "",
    Customer_Name: "",
    Scope: "",
    Scope_Description: "",
    Estimated_Date_Of_Dispatch: null,
    Ops_Engineer: "",
    Current_Status: "",
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );

        if (response.data.success) {
          const data = response.data.data[0];
          setFormData({
            Sales_Order_No: data.Sales_Order_No,
            PO_No: data.PO_No,
            PO_Date: data.PO_Date,
            Quote_No: data.Quote_No,
            Quote_Date: data.Quote_Date,
            Costing_ID: data.Costing_ID,
            Customer_Name: data.Customer_Name,
            Scope: data.Scope,
            Scope_Description: data.Scope_Description,
            Estimated_Date_Of_Dispatch: data.Estimated_Date_Of_Dispatch,
            Ops_Engineer: data.Ops_Engineer,
            Current_Status: data.Current_Status,
          });
          // setOriginalData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error here
      }
    };

    fetchData();
  }, [id]);
 


  const handleProdBackButton = () => {
    navigate(`/workshop-spares-trading-dispatch/${id}`);
  };

  const handleEditDocuments = () => {
    setIsDocumentEdit(true);
    setIsPODocumentMarkedForRemoval(false);
    setIsQuoteDocumentMarkedForRemoval(false);
  };
  const handleSaveDocuments = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    // console.log("Dispatch Data Before Sending:", getDispatch);
    const dispatchData = getDispatch[0]; // Assuming updating the first entry

    const payload = {
      Purpose: dispatchData.Purpose,
      Receiving_Party: dispatchData.Receiving_Party,
      Delivery_date: dispatchData.Delivery_date,
      Ponumber: dispatchData.Ponumber,
      Quote_number: dispatchData.Quote_number,
      dispatch_id: project_id,
    };

    const deliveryDatePayload = {
      Delivery_Date: dispatchData.Delivery_date,
    }

    try {
      // Update dispatch data
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch?dispatch_id=${project_id}`,
        payload
      );

      const deliveryDateRes = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_dispatch_to_delivery_date_update?Dispatch_ID=${project_id}`,
        deliveryDatePayload
      )

      // // Handle file uploads and removals
      // await handleFileOperations();

      alert("Details updated successfully");
      setRefresh(!refresh);
      window.location.reload(); // Consider replacing this with more React-friendly navigation
    } catch (error) {
      console.error("Error updating details:", error);
      alert("Failed to update details");
    }
    setIsLoading(false);
  };

  // Function to handle file uploads and removals


  const handleCancelDocuments = () => {
    setIsDocumentEdit(false);
    setFileName3(
      originalDispatchData.PO_document
        ? extractFileName(originalDispatchData.PO_document)
        : ""
    );
    setFileName4(
      originalDispatchData.Quote_document
        ? extractFileName(originalDispatchData.Quote_document)
        : ""
    );
    setRefresh(!refresh);
  };
  const handleEditItems = () => {
    setIsItemsEdit(true);
  };

  //   setIsItemsEdit(false);
  //   try {
  //     // Sending the finalDispatchData array to the backend for updating
  //     const res = await axios.put(
  //       "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_final_dispatch",
  //       finalDispatchData
  //     );

  //     if (res.status === 200 && res.data.success) {
  //       alert("Data updated successfully");
  //       window.location.reload();
  //     } else {
  //       alert("Failed to update data");
  //     }
  //   } catch (error) {
  //     console.error("Error updating quantities:", error);
  //     alert("An error occurred while updating data");
  //   }
  // };

  //this is fully working handlesave
  const handleSaveItems = async () => {
    setIsLoading(true);
    setIsItemsEdit(false); // Assuming this controls whether you are in edit mode or not
    try {
      const res = await axios.put(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_sparestrading_dispatch",
        finalDispatchData
      );

      const resInspections = await axios.put(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch_qty_sparestrading_outgoing_inspections",
        finalDispatchData
      );

      if (res.status === 200 && res.data.success && resInspections.status === 200 && resInspections.data.success) {
        alert("Data updated successfully");
        // Update the state to reflect the saved data instead of reloading the page
        setFinalDispatchData(res.data.updatedData);
        setRefresh(!refresh);
        // window.location.reload(); // Assuming the server returns the updated list
      } else {
        alert("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating quantities:", error);
      alert("An error occurred while updating data");
    }
    setIsLoading(false);
  };

  const handleCancelItems = () => {
    setIsItemsEdit(false);
    setRefresh(!refresh);
    setErrors({});
  };

  // Function to handle tab switching
  const handleTabSwitch = (tabName) => {
    setActiveTab(tabName);
    setIsDocumentEdit(false);
    setIsItemsEdit(false);
    setRefresh(!refresh);
  };
  // const handleDates = (name, value) => {
  //   if (value !== null && value !== undefined) {
  //     const selectedDate = value.format("DD/MM/YYYY");
  //     setDispatchdata((prevState) => ({
  //       ...prevState,
  //       [name]: selectedDate, // Update the state with formatted date
  //     }));
  //     console.log(name, selectedDate);
  //   } else {
  //     console.log("Invalid Date");
  //   }
  // };

  const handleDates = (name, value) => {
    setGetDispatch((prevState) =>
      prevState.map((item, index) =>
        index === name
          ? {
            ...item,
            Delivery_date: value ? value.format("YYYY-MM-DD") : null,
          }
          : item
      )
    );
  };
  // const handleDates = (index, name, value) => {
  //   setGetDispatch((prevDispatch) => {
  //     const newDispatch = [...prevDispatch];
  //     newDispatch[index] = {
  //       ...newDispatch[index],
  //       [name]: value?.format("DD/MM/YYYY"),
  //     };
  //     return newDispatch;
  //   });
  // };
  // mui styling for date picker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "230px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));
  // const handlecross = (name) => {
  //   alert("clicked");
  //   switch (name) {
  //     case "PO_document":
  //       setFileName3("");
  //       setDocumentToUpload3(null);
  //       setRemovePODocument(true);
  //       resetFileInput("upload3");  // Reset the file input after removing the file
  //       break;
  //     case "Quote_document":
  //       setFileName4("");
  //       setDocumentToUpload4(null);
  //       setRemoveQuoteDocument(true);
  //       resetFileInput("upload4");  // Reset the file input after removing the file
  //       break;
  //     default:
  //       break;
  //   }
  // };
  const handlefileupload3 = (name, event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setDocumentToUpload3(file);
      setFileName3(file.name);
      setFileNamepo(file.name);

      // Update the specific getDispatch item
      setGetDispatch((prevDispatch) => {
        const newDispatch = [...prevDispatch];
        newDispatch[0] = { ...newDispatch[0], PO_document: fileURL };
        return newDispatch;
      });

      // console.log(file);
      // console.log(file.name);
      // console.log(fileName3);
      // console.log(fileNamepo);
      setIsPODocumentMarkedForRemoval(false);
    }
  };


  const resetFileInput = (fileInputId) => {
    // Debugging: Log the fileInputId to ensure it's what we expect
    // console.log("resetFileInput called with id:", fileInputId);

    const fileInput = document.getElementById(fileInputId);
    if (fileInput) {
      fileInput.value = "";
      console.log("File input reset successfully.");
    } else {
      console.log("Failed to find file input with id:", fileInputId);
    }

    // Assuming setFileName3 is correctly defined and intended to reset some related state
    setFileName3("");

    // Additional debugging: Log after attempting to reset to ensure function completes
    console.log("setFileName3 called with empty string.");
  };

 
  useEffect(() => {
    return () => {
      // Restore original dispatch data if not saved
      setDispatchdata(originalDispatchData);
    };
  }, []);
  const extractFileNames = (filePaths) => {
    return filePaths.map((filePath) => {
      if (!filePath) return "";
      const fileName = filePath.split("/").pop().split("-").slice(1).join("-");
      return fileName.trim();
    });
  };

  const extractFileName = (filePath) => {
    if (!filePath) return "";
    const fileNameParts = filePath.toString().split("/").pop().split("-");
    fileNameParts.shift(); // Remove the first element (number)
    const finalname = fileNameParts.join("-");
    return finalname;
  };

  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const updatedDispatch = [...getDispatch];
    updatedDispatch[index] = { ...updatedDispatch[index], [name]: value };
    setGetDispatch(updatedDispatch);
  };



 
  const handleQuantityChange = (event, index) => {
    const newDispatch_qty = parseInt(event.target.value, 10); // Ensure you are working with numbers

    setFinalDispatchData((currentData) =>
      currentData?.map((item, idx) =>
        idx === index ? { ...item, Dispatch_qty: newDispatch_qty } : item
      )
    );

    if (newDispatch_qty > finalDispatchData[index].availableQty) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: `Incoming Qty can't exceed ${finalDispatchData[index].availableQty}`,
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[index];
        return newErrors;
      });
    }
  };

  console.log("errors", errors);

  const handleEdit1 = () => {
    setIsedit1(true);
    // Add logic to handle edit click
  };
  const handleEdit2 = () => {
    setIsedit2(true);
    // Add logic to handle edit click
  };

  const handleCancel = () => {
    setIsedit1(false);
    setDispatchdata(originalDispatchData); // Restore original dispatch data
    setIsPODocumentMarkedForRemoval(false);
    setIsQuoteDocumentMarkedForRemoval(false);
    setFormData({
      Receiving_Party: dispatchdata.Receiving_Party || "",
      Deliverydate: dispatchdata.Deliverydate || "",
      Ponumber: dispatchdata.Ponumber || "",
      Quotenumber: dispatchdata.Quotenumber || "",
      Dispatchqty: dispatchdata.Dispatchqty || "",
      PO_document: dispatchdata.PO_document || "",
      Quote_document: dispatchdata.Quote_document || "",
    });
    setSelectedPurpose(dispatchdata.Purpose || "");
  };

  const handleCancel2 = () => {
    setIsedit2(false);
    setFormData({
      Dispatchqty: "",
    });
    setFormData(dispatchdata.Dispatchqty || 0);
  };

  const handleSave1 = async (e) => {
    e.preventDefault();

    // console.log("Dispatch Data Before Sending:", getDispatch);

    const dispatchData = getDispatch[0]; // Assuming updating first entry for simplicity

    const payload = {
      Purpose: dispatchData.Purpose,
      Receiving_Party: dispatchData.Receiving_Party,
      Delivery_date: dispatchData.Delivery_date,
      Ponumber: dispatchData.Ponumber,
      Quote_number: dispatchData.Quote_number,
      dispatch_id: project_id,
    };

    console.log("Payload to be sent:", payload);

    let payloadfiles1 = null;
    let payloadfiles2 = null;

    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch?dispatch_id=${project_id}`,
        payload
      );
      console.log("Response:", res);

      if (documentToUpload3) {
        payloadfiles1 = new FormData();
        payloadfiles1.append("dispatch_id", project_id);
        payloadfiles1.append("PO_document", documentToUpload3);

        await axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch_details?dispatch_id=${project_id}`,
          payloadfiles1,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      }

      if (documentToUpload4) {
        payloadfiles2 = new FormData();
        payloadfiles2.append("dispatch_id", project_id);
        payloadfiles2.append("Quote_document", documentToUpload4);

        await axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch_details2?dispatch_id=${project_id}`,
          payloadfiles2,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      }

      const removeConditions = [
        {
          remove: isPODocumentMarkedForRemoval,
          temp: fileName3,
          type: "PO_document",
        },
        {
          remove: isQuoteDocumentMarkedForRemoval,
          temp: fileName4,
          type: "Quote_document",
        },
      ];

      for (const condition of removeConditions) {
        if (condition.remove && condition.temp === "") {
          const payloadRemoveFiles = { type: condition.type };
          const url = `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/remove_dispatch_files/${project_id}`;
          console.log("Remove File URL:", url); // Log URL to verify
          await axios.put(url, payloadRemoveFiles);
        }
      }

      if (
        res.status === 200 &&
        (!documentToUpload3 ||
          (payloadfiles1 && payloadfiles1.status === 200)) &&
        (!documentToUpload4 || (payloadfiles2 && payloadfiles2.status === 200))
      ) {
        alert("Incoming delivery updated successfully");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating quantities:", error);
    }

    setIsedit1(false);
    // Update the original data to reflect the saved state
    setOriginalDispatchData(dispatchdata);
  };

  const handleSave2 = async () => {
    try {
      // Sending the finalDispatchData array to the backend for updating
      const res = await axios.put(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_final_dispatch",
        finalDispatchData
      );

      if (res.status === 200 && res.data.success) {
        alert("Data updated successfully");
        window.location.reload();
      } else {
        alert("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating quantities:", error);
      alert("An error occurred while updating data");
    }
  };
  useEffect(() => {
    async function dispatchdetails() {
      try {
        // Determine the series based on the project_id
        const series = project_id.startsWith("18") ? "18" : "16";

        // Make API request with the series query parameter
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getdispatchdetails?series=${series}`
        );

        // Filter results based on the specific project_id
        const filter = res.data.results.filter(
          (el) => el.dispatch_id == project_id
        );

        // Update state with filtered results
        setGetDispatch(filter);

        // console.log(getDispatch);
        // console.log(dispatchdata);

      } catch (error) {
        console.error(error);
      }
    }

    dispatchdetails();
  }, [project_id, refresh]);


  useEffect(() => {
    const fetchFinalDispatchDetails = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getsparesdispatchdetails`
        );
        const data = response.data.results.filter(
          (el) => el.dispatch_id === project_id
        );
        const updatedData = data.map((item) => ({
          ...item,
          Dispatch_qty: item.Dispatch_qty,
        }));

        // Only update state if data has changed
        if (JSON.stringify(finalDispatchData) !== JSON.stringify(updatedData)) {
          setFinalDispatchData(updatedData);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFinalDispatchDetails();
  }, [project_id, refresh]);




  const handleDispatchQtyChange = (index, value) => {
    const updatedData = [...finalDispatchData];
    updatedData[index].Dispatch_Qty = Number(value); // Ensure the value is a number
    setFinalDispatchData(updatedData);
  };

  useEffect(() => {
    async function fetchItemsReceivedDetails() {
      try {
        // Fetch item details
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_get`
        );
        const itemsReceivedData = res1.data.data.filter(
          (el) => el.Spares_Trading_Project_ID == id
        );
        // console.log("itemsReceivedData", itemsReceivedData);
  
        // Fetch Sales Order No data
        const resSalesOrderNo = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );
  
        const data = resSalesOrderNo.data.data[0]; // Ensure data is correctly assigned
  
        setProjectSO(data?.Sales_Order_No);
  
        // Fetch inventory utilization data
        const resInventoryUtil = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_projectSO_get?Project_SO=${data?.Sales_Order_No}`
        );
        const inventoryUtilData = resInventoryUtil.data.data;
  
        // Fetch the current dispatch details
        const getDispatchDetails = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getsparesdispatchdetails`,
          {
            params: {
              Project_ID: data.ID_No,
            },
          }
        );
  
        // Raw data from API
        const dispatchRawData = getDispatchDetails.data.results;

        // Filter the data to ensure Project_ID matches `id` from useParams
        const allDispatchDetails = dispatchRawData.filter((item) => {
          return String(item.Project_ID) === String(id); // Convert both to strings for comparison
        });  
        // Exclude the dispatch we are updating (assuming 'project_id' is the dispatch_id being updated)
        const dispatchDetails = allDispatchDetails.filter(
          (item) =>
            String(item.Project_ID) === String(id) &&
            String(item.dispatch_id) !== String(project_id)
        );
  
        // For each item in itemsReceivedData
        const updatedItems = itemsReceivedData
          .map((item) => {
            const trimmedItemPartNumber = item.Part_Number
              ? item.Part_Number.trim()
              : "";
            const trimmedItemName = item.Item_Name
              ? item.Item_Name.trim()
              : "";
  
            // Find matching items in inventoryUtilData
            const matchingInventoryItems = inventoryUtilData.filter(
              (inventoryItem) => {
                const trimmedInventoryPartNumber = inventoryItem.Part_Number
                  ? inventoryItem.Part_Number.trim()
                  : "";
                const trimmedInventoryName = inventoryItem.Item_Name
                  ? inventoryItem.Item_Name.trim()
                  : "";
  
                // Apply matching logic
                if (trimmedItemPartNumber && trimmedInventoryPartNumber) {
                  return (
                    trimmedItemPartNumber === trimmedInventoryPartNumber
                  );
                }
                if (!trimmedItemPartNumber && !trimmedInventoryPartNumber) {
                  return (
                    trimmedItemName === trimmedInventoryName &&
                    trimmedInventoryPartNumber === ""
                  );
                }
                return trimmedItemName === trimmedInventoryName;
              }
            );
  
            // Sum Qty_Utilized for matching inventory items
            const totalQtyUtilized = matchingInventoryItems.reduce(
              (sum, inventoryItem) =>
                sum + (parseFloat(inventoryItem.Qty_Utilized) || 0),
              0
            );
  
            // Find matching items in dispatchDetails
            const matchingDispatchItems = dispatchDetails.filter(
              (dispatchItem) => {
                const trimmedDispatchPartNumber = dispatchItem.Part_Number
                  ? dispatchItem.Part_Number.trim()
                  : "";
                const trimmedDispatchName = dispatchItem.Item_Name
                  ? dispatchItem.Item_Name.trim()
                  : "";
  
                // Apply matching logic
                if (trimmedItemPartNumber && trimmedDispatchPartNumber) {
                  return (
                    trimmedItemPartNumber === trimmedDispatchPartNumber
                  );
                }
                if (!trimmedItemPartNumber && !trimmedDispatchPartNumber) {
                  return (
                    trimmedItemName === trimmedDispatchName &&
                    trimmedDispatchPartNumber === ""
                  );
                }
                return trimmedItemName === trimmedDispatchName;
              }
            );
  
            // Sum the Dispatch_qty for matching dispatch items (exclude current dispatch)
            const totalDispatchQty = matchingDispatchItems.reduce(
              (sum, dispatchItem) =>
                sum + (parseFloat(dispatchItem.Dispatch_qty) || 0),
              0
            );
  
            // Calculate stockQty
            const stockQty = totalQtyUtilized - totalDispatchQty;
  
            // console.log("totalQtyUtilized, totalDispatchQty", totalQtyUtilized, totalDispatchQty);
            // console.log("stockQty", stockQty)

            // Find the Dispatch_qty of the current dispatch for this item
            const currentDispatchItem = allDispatchDetails.find(
              (dispatchItem) => {
                const trimmedDispatchPartNumber = dispatchItem.Part_Number
                  ? dispatchItem.Part_Number.trim()
                  : "";
                const trimmedDispatchName = dispatchItem.Item_Name
                  ? dispatchItem.Item_Name.trim()
                  : "";
  
                // Apply matching logic
                if (trimmedItemPartNumber && trimmedDispatchPartNumber) {
                  return (
                    trimmedItemPartNumber === trimmedDispatchPartNumber &&
                    String(dispatchItem.dispatch_id) === String(project_id)
                  );
                }
                if (!trimmedItemPartNumber && !trimmedDispatchPartNumber) {
                  return (
                    trimmedItemName === trimmedDispatchName &&
                    trimmedDispatchPartNumber === "" &&
                    String(dispatchItem.dispatch_id) === String(project_id)
                  );
                }
                return (
                  trimmedItemName === trimmedDispatchName &&
                  String(dispatchItem.dispatch_id) === String(project_id)
                );
              }
            );
  
            const currentDispatchQty = currentDispatchItem
              ? parseFloat(currentDispatchItem.Dispatch_qty) || 0
              : 0;
  
            // Adjust stockQty by subtracting currentDispatchQty
            const adjustedStockQty = stockQty - currentDispatchQty;
  
            // Also calculate availableQty as adjustedStockQty
            const availableQty = adjustedStockQty;

            // console.log("currentDispatchItem, currentDispatchQty", currentDispatchItem, currentDispatchQty);
            // console.log("adjustedStockQty, availableQty", adjustedStockQty, availableQty);
  
            return {
              ...item,
              Stock_Qty: adjustedStockQty,
              Qty_in_transit: totalDispatchQty,
              availableQty: availableQty,
            };
          })
          .filter((item) => item.availableQty >= 0);
  
        // Set the state for items received
        setFilteredItems(updatedItems);
        // console.log("filteredItems", updatedItems);
      } catch (error) {
        console.error(error);
      }
    }
    fetchItemsReceivedDetails();
  }, [refresh]);
  
  useEffect(() => {
    const availableQtyFinalDispatch = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getsparesdispatchdetails`
        );
        const data = response.data.results.filter(
          (el) => el.dispatch_id === project_id
        );
        const tempDispatchData = data.map((item) => ({
          ...item,
          Dispatch_qty: item.Dispatch_qty,
        }));

        const updatedData = tempDispatchData?.map((item) => {
          const matchedItem = filteredItems.find(
            (fi) =>
              fi.Item_Name === item.Item_Name && fi.Part_Number === item.Part_Number
          );
          const availableQty = matchedItem
            ? (parseFloat(matchedItem.availableQty) + parseFloat(item.Dispatch_qty))
            : 0;
          return { ...item, availableQty };
        });

        if (JSON.stringify(finalDispatchData) !== JSON.stringify(updatedData)) {
          setFinalDispatchData(updatedData);
        }

      } catch (error) {
        console.error(error);
      }
    };
    availableQtyFinalDispatch();
  }, [filteredItems, mappedValues, refresh]);

  // useEffect(() => {
  //   async function finaldispatchdetails() {
  //     try {
  //       const res = await axios.get(
  //         `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getfinaldispatchdetails`
  //       );
  //       const filter = res.data.results.filter(
  //         (el) => el.dispatch_id === project_id
  //       );
  //       setFinalDispatchData(filter);

  //       // Fetch items received and calculate available quantities
  //       const res1 = await axios.get(
  //         `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get2`
  //       );

  //       const itemsReceivedData = res1.data.data;
  //       const newMappedValues = {};
  //       itemsReceivedData.forEach((item) => {
  //         const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
  //         newMappedValues[key] = item.Total_Qty_Used;
  //       });
  //       setMappedValues(newMappedValues);

  //       const filteredItems = itemsReceivedData.filter((item) => {
  //         const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
  //         const totalQtyUsed = newMappedValues[key] || 0;
  //         return item.Quantity_Received - totalQtyUsed !== 0;
  //       });

  //       setFilteredItems(filteredItems);

  //       // Calculate available quantities
  //       const updatedDispatchData = filter.map((dispatchItem) => {
  //         const matchedItem = filteredItems.find(
  //           (item) =>
  //             item.Item_Name === dispatchItem.Item_Name &&
  //             item.Part_Number === dispatchItem.Part_Number
  //         );
  //         const availableQty = matchedItem
  //           ? matchedItem.Quantity_Received - newMappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0
  //           : 0;

  //         return {
  //           ...dispatchItem,
  //           availableQty: availableQty
  //         };
  //       });

  //       setFinalDispatchData(updatedDispatchData);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   finaldispatchdetails();
  // }, [project_id]);

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        const workshoppurchasesincomingdeliveriesdocedit = response.data.filter(
          (el) => {
            return (
              el.Group_Name === localStorage.getItem("Group") &&
              el.Header_Name === "Workshop Projects" &&
              el.Feature_Name ===
              "Workshop Purchases Incoming Deliveries Documents Edit" &&
              (el.Access === "Can View" || el.Access === "Can Edit")
            );
          }
        );

        const workshoppurchasesincomingdeliveriesitemedit =
          response.data.filter((el) => {
            return (
              el.Group_Name === localStorage.getItem("Group") &&
              el.Header_Name === "Workshop Projects" &&
              el.Feature_Name ===
              "Workshop Purchases Incoming Deliveries Items Edit" &&
              (el.Access === "Can View" || el.Access === "Can Edit")
            );
          });
        // console.log("I am here ");
        // console.log(workshoppurchasesincomingdeliveriesitemedit);
        setfeatureincomingdeliverydocedit(
          workshoppurchasesincomingdeliveriesdocedit
        );
        setfeatureincomingdeliveryitemedit(
          workshoppurchasesincomingdeliveriesitemedit
        );
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  // const handlepurposechange = (e) => {
  //   setSelectedPurpose(e.target.value);
  //   setDispatchdata({
  //     ...dispatchdata,
  //     Purpose: e.target.value,
  //   });
  // };

  // const handlepurposechange = (event) => {
  //   const { value } = event.target;
  //   setGetDispatch((prevState) =>
  //     prevState.map((item, index) =>
  //       index === event.target.name
  //         ? { ...item, Purpose: value }
  //         : item
  //     )
  //   );
  // };

  const handlepurposechange = (event, index) => {
    const { value } = event.target;
    setGetDispatch((prevState) =>
      prevState.map((item, i) =>
        i === index ? { ...item, Purpose: value } : item
      )
    );
  };

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // console.log("filteredItems", filteredItems);
  // console.log("getDispatch", getDispatch);
  // console.log("dispatchdata", dispatchdata);
  // console.log("finalDispatchData", finalDispatchData);

  return (
    <div>
      <LoadingScreen isLoading={isLoading} />
      <div className={styles.topButtonContainer} 
        style={{ margin: "80px 0 0 0"}}
      >
        <button className={styles.topBackButton} onClick={handleProdBackButton}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
              fill="#5B6BE1"
            />
          </svg>{" "}
          Dispatch
        </button>
      </div>

      <div style={{ marginTop: "100px", marginLeft: "1%", marginRight: "1%" }}>
        {Array.isArray(getDispatch) &&
          getDispatch.map((dispatchdata, index) => (
            <div key={index} className={styles.box} style={{ marginBottom: "10px" }}>
              <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Dispatch ID: {dispatchdata.dispatch_id}
                </label>
              </div>
              <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Purpose: {dispatchdata.Purpose}
                </label>
              </div>
              <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Customer Name: {dispatchdata.Receiving_Party}
                </label>
              </div>
              <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Delivery Date: {dispatchdata.Delivery_date}
                </label>
              </div>

              {/* <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Created By: {formdata1.Done_By}
                </label>
              </div> */}
            </div>
          ))}
        <div>
          <button
            className={`${styles["incomingButton2"]} ${activeTab === "Documents"
              ? styles.activeTab
              : styles["not-incoming2"]
              }`}
            onClick={() => handleTabSwitch("Documents")}>
            Details
          </button>
          <button
            className={`${styles["outgoingButton2"]} ${activeTab === "Items" ? styles.activeTab : styles["not-outgoing2"]
              }`}
            onClick={() => handleTabSwitch("Items")}>
            Items
          </button>
        </div>
      </div>
      {activeTab === "Documents" ? (
        <div style={{marginRight: "1%"}}>
          {!isDocumentEdit ? (
            <>
              <button
                onClick={handleEditDocuments}
                className={`${styles["incomingButton2"]} ${isDocument ? styles["incoming2"] : styles["not-incoming2"]
                  }`}>
                Edit
              </button>
              <form>
                {Array.isArray(getDispatch) &&
                  getDispatch.map((dispatchdata, index) => (
                    <div key={index}>

                      <div className={styles.formElement}>
                        <label>Purpose:</label>
                        <input
                          type="text"
                          name="Purpose"
                          value="Final Delivery"
                          onChange={(e) => handleOnChange(e, index)}
                          disabled={!isedit1}
                        />
                      </div>





                      <div className={styles.formElement}>
                        <label>Customer Name:</label>
                        <input
                          type="text"
                          name="Receiving_Party"
                          value={dispatchdata.Receiving_Party}
                          onChange={(e) => handleOnChange(e, index)}
                          disabled={!isedit1}
                        />
                      </div>
                      <div
                        className={`${styles.formElement} ${styles.datepickerInput}`}
                      >
                        <label>Delivery Date:</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <CustomDatePicker
                            name="Deliverydate"
                            value={
                              dispatchdata.Delivery_date
                                ? dayjs(dispatchdata.Delivery_date, "YYYY-MM-DD")
                                : null
                            }
                            onChange={(value) => handleDates(index, value)}
                            slotProps={{
                              textField: {
                                size: "small",
                                InputProps: {
                                  style: {
                                    color: "black", // Set the font color to black
                                  },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton>
                                        <CalendarTodayIcon
                                          style={{ color: "black" }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                },
                                InputLabelProps: {
                                  style: {
                                    color: "black", // Also set the label color to black if any
                                  },
                                },
                              },
                            }}
                            format="DD/MM/YYYY"
                            sx={{
                              "& .MuiInputBase-root": {
                                backgroundColor: "rgba(239, 239, 239, 0.45)",
                                borderRadius: "8px",
                              },
                              "& .MuiInputBase-input": {
                                color: "black !important",
                                "-webkit-text-fill-color": "black !important",
                              },
                            }}
                            disabled={!isedit1}
                          />
                        </LocalizationProvider>
                      </div>



                    </div>
                  ))}
              </form>
            </>
          ) : (
            <>
              <div style={{ marginLeft: "78%" }}>
                <button
                  onClick={handleSaveDocuments}
                  className={styles.saveButton}
                  disabled={isLoading}
                >
                  Save
                </button>
                <button
                  onClick={handleCancelDocuments}
                  className={styles.cancelBtn}>
                  Cancel
                </button>
              </div>
              <div>
                <form>
                  <>
                    {Array.isArray(getDispatch) &&
                      getDispatch.map((dispatchdata, index) => (
                        <div key={index}>
                          <div className={styles.formElement}>
                            <label>Purpose:</label>
                            <input
                              type="text"
                              name="Purpose"
                              value="Final Delivery"
                              onChange={(e) => handleOnChange(e, index)}
                              disabled={!isedit1}
                            />
                          </div>

                          {/* Conditionally render fields based on the selected purpose */}

                          <>
                            {/* Display only the Receiving Party and Delivery Date fields */}
                            <div className={styles.formElement}>
                              <label>Customer Name:</label>
                              <input
                                type="text"
                                name="Receiving_Party"
                                value={dispatchdata.Receiving_Party}
                                onChange={(e) => handleOnChange(e, index)}
                                disabled={!isedit1}
                              />
                            </div>
                            <div className={`${styles.formElement} ${styles.datepickerInput}`}>
                              <label>Delivery Date:</label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CustomDatePicker
                                  name="Deliverydate"
                                  value={
                                    dispatchdata.Delivery_date
                                      ? dayjs(dispatchdata.Delivery_date, "YYYY-MM-DD")
                                      : null
                                  }
                                  onChange={(value) => handleDates(index, value)}
                                  slotProps={{ textField: { size: "small" } }}
                                  format="DD/MM/YYYY"
                                  sx={{
                                    backgroundColor: "white",
                                    borderRadius: "8px",
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </>



                        </div>
                      ))}
                  </>
                </form>

              </div>
            </>
          )}
        </div>
      ) : (
        <div style={{marginLeft: "1%", marginRight: "1%" }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            {!isItemsEdit ? (
              <button
                style={{ borderRadius: '14px' }}
                onClick={handleEditItems}
                className={`${styles["outgoingButton2"]} ${!isedit2 ? styles["outgoing2"] : styles["not-outgoing2"]}`}
              >
                Edit
              </button>
            ) : (
              <>
                <button
                  onClick={handleSaveItems}
                  variant="contained"
                  className={styles.saveButton}
                  disabled = {Object.keys(errors).length > 0 || isLoading} 
                  style={{ opacity: Object.keys(errors).length > 0 ? 0.8 : 1 }} // Reduce opacity when disabled
                >
                  Save
                </button>
                <button
                  onClick={handleCancelItems}
                  variant="outlined"
                  className={styles.cancelBtn}
                >
                  Cancel
                </button>
              </>
            )}
          </div>

          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>UoM</th>
                  <th>Available Qty for dispatch</th>
                  <th>Dispatch Qty</th>
                </tr>
              </thead>
              <tbody>
                {finalDispatchData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.Item_Name}</td>
                    <td>{item.Part_Number}</td>
                    <td>{item.UoM}</td>
                    <td>{item.availableQty}</td>
                    <td style={{ position: 'relative' }}>
                      <input
                        type="number"
                        style={{
                          border: '1px solid #5B6BE1',
                          maxWidth: '55px',
                          outline: 'none',
                          paddingLeft: '5px',
                        }}
                        value={item.Dispatch_qty}
                        onChange={(event) => handleQuantityChange(event, index)}
                        disabled={!isItemsEdit && !isedit2}
                        min="0"
                      />
                      {errors[index] && (
                        <p
                          style={{
                            color: 'red',
                            fontWeight: '400',
                            fontSize: '13px',
                            position: 'absolute',
                            left: 0,
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          Dispatch Qty can't exceed {item.availableQty}
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>


      )}
    </div>
  );
}

export default SparestradingDispatchUpdate;
