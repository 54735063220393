import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Button, Box, Modal, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfModal = ({ show, handleClose, pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);

  return (
    <Dialog open={show} onClose={handleClose} maxWidth="xl">
      <DialogTitle>
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            aria-label="Close"
            size="small"
          >
            Close
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{
          maxHeight: "80vh",  
          overflowY: "auto",  
          borderTop: "2px solid black",
          borderBottom: "2px solid black"
        }}
      >
        {pdfUrl ? (
          <Document
            file={pdfUrl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            onLoadError={(error) => console.error("Error loading PDF:", error)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={750} 
              />
            ))}
          </Document>
        ) : (
          <h5 className="text-center">The required document doesn’t exist</h5>
        )}
      </DialogContent>
    </Dialog>
  );
};

const PurcheaseInspectionMadal = ({open,handlecloseModal, approvedBy, setApprovedBy, 
  preparedBy, setPreparedby,handleDownloadPDF})=>{

  return (
    <div>
      <Modal
        open={open}
        onClose={handlecloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ color: 'blue' }}>
            Download Report
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Prepared by</InputLabel>
            <Select
              value={preparedBy}
              onChange={(e)=>setPreparedby(e.target.value)}
              label="Prepared by"
              name="preparedBy"
            >
              <MenuItem value="M. Haneef">M. Haneef</MenuItem>
              <MenuItem value="Ajhar Hussain">Ajhar Hussain</MenuItem>
              <MenuItem value="Irfan Ahmad">Irfan Ahmad</MenuItem>
              <MenuItem value="Basil Rajan">Basil Rajan</MenuItem>
              <MenuItem value="Anvar P">Anvar P</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Approved by</InputLabel>
            <Select
             value={approvedBy}
             onChange={(e)=>setApprovedBy(e.target.value)}
              label="Approved by"
              name="approvedBy"
            >
             <MenuItem value="M. Haneef">M. Haneef</MenuItem>
              <MenuItem value="Ajhar Hussain">Ajhar Hussain</MenuItem>
              <MenuItem value="Irfan Ahmad">Irfan Ahmad</MenuItem>
              <MenuItem value="Basil Rajan">Basil Rajan</MenuItem>
              <MenuItem value="Anvar P">Anvar P</MenuItem>
            </Select>
          </FormControl>

          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
              Download
            </Button>
            <Button variant="outlined" color="secondary" onClick={handlecloseModal}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export {PdfModal, PurcheaseInspectionMadal};

