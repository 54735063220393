// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fielddocs_statussheet_savebtncustomerfeedback__z7FGA{\n  background: rgb(91, 107, 225);\n  width: 171px;\n  height: 50px;\n  border: 1px solid rgb(91, 107, 225);\n  border-radius: 8px;\n  padding: 15px 59px 15px 58px;\n  font-weight: bold;\n  &:hover{\n    background: rgb(91, 107, 225);\n  }\n}\n\n\n.fielddocs_statussheet_cancelbtn__Q-vvD{\n  background: white;\n  width: 171px;\n  height: 50px;\n  border: 1px solid rgb(91, 107, 225);\n  border-radius: 8px;\n  padding: 15px 59px 15px 58px;\n  font-weight: bold;\n  color: rgb(91, 107, 225);\n  &:hover{\n    background: white;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/Components/css/fielddocs_statussheet.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,mCAAmC;EACnC,kBAAkB;EAClB,4BAA4B;EAC5B,iBAAiB;EACjB;IACE,6BAA6B;EAC/B;AACF;;;AAGA;EACE,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,mCAAmC;EACnC,kBAAkB;EAClB,4BAA4B;EAC5B,iBAAiB;EACjB,wBAAwB;EACxB;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".savebtncustomerfeedback{\n  background: rgb(91, 107, 225);\n  width: 171px;\n  height: 50px;\n  border: 1px solid rgb(91, 107, 225);\n  border-radius: 8px;\n  padding: 15px 59px 15px 58px;\n  font-weight: bold;\n  &:hover{\n    background: rgb(91, 107, 225);\n  }\n}\n\n\n.cancelbtn{\n  background: white;\n  width: 171px;\n  height: 50px;\n  border: 1px solid rgb(91, 107, 225);\n  border-radius: 8px;\n  padding: 15px 59px 15px 58px;\n  font-weight: bold;\n  color: rgb(91, 107, 225);\n  &:hover{\n    background: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"savebtncustomerfeedback": "fielddocs_statussheet_savebtncustomerfeedback__z7FGA",
	"cancelbtn": "fielddocs_statussheet_cancelbtn__Q-vvD"
};
export default ___CSS_LOADER_EXPORT___;
