import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/dailytrackerinquiry.module.css";
import TabsBarDailyTracker from "./TabsBarDailyTracker.js";
import { NavLink } from "react-router-dom";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

const DailyTrackerInquiry = () => {
  const [activeStatus, setActiveStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [openQuoteData, setOpenQuoteData] = useState([]);
  const [openPOData, setOpenPOData] = useState([]);
  const [closedData, setClosedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
 
    return `${day}-${month}-${year}`;
  }

  // Fetch data based on the active status from TabsBarDailyTracker
  useEffect(() => {
    const fetchData = async () => {
      if (activeStatus === 'Open Quote') {
        try {
          const response = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/daily_tracker/inquiry/open_quote');
          setOpenQuoteData(response.data); 
        } catch (error) {
          console.error('Error fetching Open Quote data:', error);
        }

      } else if (activeStatus === 'Open PO') {
        try {
          const response = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/daily_tracker/inquiry/open_po');
          setOpenPOData(response.data); 
        } catch (error) {
          console.error('Error fetching Open PO data:', error);
        }
        
      } else if (activeStatus === 'Closed') {
        try {
          const response = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/daily_tracker/inquiry/closed');
          setClosedData(response.data); 
        } catch (error) {
          console.error('Error fetching Closed data:', error);
        }
      }
    };

    fetchData(); // Fetch data whenever activeStatus changes
  }, [activeStatus]);

  useEffect(() => {
    let dataToFilter = [];
    if (activeStatus === 'Open Quote') dataToFilter = openQuoteData;
    else if (activeStatus === 'Open PO') dataToFilter = openPOData;
    else if (activeStatus === 'Closed') dataToFilter = closedData;

    // Filter data based on Inquiry_ID matching searchTerm
    const filtered = dataToFilter.sort((a,b) => b.Inquiry_ID - a.Inquiry_ID).filter((item) =>
      item.Inquiry_ID.toString().includes(searchTerm)
    );
    setFilteredData(filtered);
  }, [searchTerm, activeStatus, openQuoteData, openPOData, closedData]);


  return (
    <div className={styles.listContainer}>
      <TabsBarDailyTracker activeTab="Inquiry" activeStatus={activeStatus} setActiveStatus={setActiveStatus} setSearchTerm={setSearchTerm} />

    <div className={styles.listingContainer}>
      <table className={styles.listTable}>
        <thead>
            <tr>
            <th style={{ minWidth: "60px", width: "60px" }}>S. No</th>
            <th>ID No</th>
            <th>Inquiry Date</th>
            <th>Expiry Date</th>
            <th>Customer Name</th>
            <th>Rig Name</th>
            <th>Current Status</th>
            {activeStatus === 'Open Quote' && (
                <>
                  <th>Items pending supplier allocation</th>
                  <th>Items pending supplier quotes</th>
                </>
              )}
              {activeStatus === 'Open PO' && 
              <>
                <th>Quote No</th>
                <th>Quote sent on</th>
              </>}
              {activeStatus === 'Closed' && (
                <>
                  <th>Quote No</th>
                  <th>PO No</th>
                  <th>Linked with Order</th>
                </>
              )}
          </tr>
        </thead>
        <tbody>
            {filteredData.map((item, index) => (
              <tr key={item.Inquiry_ID}>
                <td>{index + 1}</td> 
                <td><NavLink
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`/workshopspares-inquiry-details/${item.Inquiry_ID}`, "_blank", "noopener,noreferrer");
                    }}
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                  >
                    {item.Inquiry_ID || "-"}
                  </NavLink></td>
                <td>{formatDate(item.Inquiry_Date) || "-"}</td>
                <td>{formatDate(item.Inquiry_Expiry_Date) || "-"}</td>
                <td>{item.Customer_Name || "-"}</td>
                <td>{item.Rig_Name || "-"}</td>
                <td>{item.Current_Status || "-"}</td>
                {activeStatus === 'Open Quote' && (
                  <>
                    <td>{item.Items_Pending_Supplier_Allocation}/{item.Total_Items}</td>
                    <td>
                    {item.Total_Items === 0 || item.Items_Pending_Supplier_Allocation > 0
                        ? "-"  
                        : `${item.Items_Pending_Vendor_Quotes}/${item.Total_Items}`}
                      </td>
                  </>
                )}
                {activeStatus === 'Open PO' && (
                  <>
                    <td>{item.Quote_No || "-"}</td>
                    <td>{formatDate(item.Quote_sent_on) || "-"}</td>
                  </>)}
                {activeStatus === 'Closed' && (
                  <>
                    <td>{item.Quote_No || "-"}</td>
                    <td>{item.PO_No || "-"}</td>
                    <td>{item.Linked_SO_No || "-"}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
      </table>
    </div>
    </div>
  )
}

export default DailyTrackerInquiry