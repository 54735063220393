import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styles from "../Components/css/adminpermission_roles.module.css";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import style from "./css/modal.module.css";
import { styled } from "@mui/system";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { blue } from '@mui/material/colors';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function AdminPermissionsRoles() {

    const navigate = useNavigate();
    const [openModal,setOpenModal] = useState(false);
    const [roleName, setRoleName] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [roles, setRoles] = useState([]);
    const [refresh,setRefresh] = useState(false);

    useEffect(() => {
        const fetchRolesAndCounts = async () => {
          try {
            const rolesResponse = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/admin/get-roles");
            const countsResponse = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/role-member-count");
      
            if (rolesResponse.data.success && countsResponse.data.success) {
              const roleCounts = countsResponse.data.data;
              const rolesWithCounts = rolesResponse.data.data.map(role => {
                const count = roleCounts.find(rc => rc.role_name === role.Role_Name)?.Member_Count || 0;
                return { ...role, Member_Count: count };
              });
              setRoles(rolesWithCounts);
            }
          } catch (error) {
            console.error("Error fetching roles or counts:", error);
            alert("An error occurred while fetching roles or member counts.");
          }
        };
      
        fetchRolesAndCounts();
      }, [refresh]);
      

    console.log("roles",roles)

    const handleNewUser = () => {
      setOpenModal(true);
    }

    const handleClose = () => {
      setRoleName(""); 
      setOpenModal(false);
    }

    const handleSave = async () => {
      if (!roleName.trim()) {
        alert("Role name cannot be empty");
        return;
      }

      const payload = {
        Role_Name: roleName,
      }
    
      try {
        const response = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/admin/add-role', payload);
        if (response.status === 200) {
          alert('Role added successfully');
          setRoleName(""); 
          setOpenModal(false); 
          setRefresh(true);
        } else {
          alert('Failed to add role');
        }
      } catch (error) {
        console.error("Error adding role:", error);
        alert('Role Name already exits.');
      }
    };
    

    const handlerole = () => {
        navigate("/adminpermission-roles");
    }

    const handleuser = () => {
        navigate("/adminpermission");
    }

    const handleView = (role) => {
      navigate(`/adminpermission-roles/${role}`)
    }

    const handleRoleName = (event) => {
      setRoleName(event.target.value)
    }

    const handleSearch = (event) => {
      const searchTerm = event.target.value;
      setSearchQuery(searchTerm);
    }

    // Filter roles based on search query
  const filteredRoles = roles.filter((role) =>
    role.Role_Name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <>
      <div className={styles.upmost}>
        <div className={styles.dashboardheading}> Admin Permissions</div>
        <button
          className={styles.buttonap1}
          onClick={handleuser}
        >
          Users
        </button>
        <button
          className={styles.buttonap2}
          onClick={handleuser}
        >
          Groups
        </button>
        <button className={styles.buttonap3} onClick={handlerole}>
          Roles
        </button>
      </div>

      <div className={styles.mainap}>
        <div>
        <input
          type="text"
          placeholder="Search Role name "
          onChange={handleSearch}
          value={searchQuery}
          className={styles.inputboxap}
        ></input>
        <svg
            className={styles.searchIconFilter}
            style={{ position: "relative", right: "2.5%"}}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#0000008F"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#0000008F"
            />
          </svg>
          </div>
        <button className={styles.buttonapuser} 
        onClick={handleNewUser}
        >
          <svg
            style={{ marginRight: "10px" }}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-plus-square-fill"
            viewBox="0 0 16 16"
          >
            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
          </svg>
          Add New Role
        </button>
        <label className={styles.labeluser}>
          <b>
          Total Role Count : 
          {" "}{roles.length}
          </b>
          
        </label>
      </div>

      

      {openModal && (
        <div>
          <Modal
          open={openModal}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            backdropFilter: "blur(4px)",
          }}>
            <Fade in={openModal}>
                <Box
                  sx={{
                    width: 650,
                    height: 350,
                    p: 3,
                    backgroundColor: "#fff",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{display: "flex", flexDirection: "column", gap: "70px", margin: "10px", fontSize:"21px",
                     fontWeight: "bold"
                  }}>
                    <label style={{color: "#5B6BE1", fontSize: "24px"}}>Add New Role</label>
                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                      <label>Role Name: </label>
                      <input
                      type='text'
                      placeholder='Enter role name'
                      value={roleName}
                      onChange={handleRoleName}
                      className={styles.groupnameinput}
                      />
                    </div>

                    <div style={{marginTop: "20px", display: "flex", justifyContent: "flex-end"}}>
                      <button className={styles.submitBtn} onClick={handleSave}>Save</button>
                      <button  className={styles.cancelBtn} onClick={handleClose}>Cancel</button>
                    </div>
                    
                  </div>
                </Box>
                </Fade>
          </Modal>
        </div>
      )}

      <div className={styles.listDiv}>
        {filteredRoles.length > 0 ? (
          filteredRoles.sort((a,b) => b.SNo - a.SNo).map((role) => (
            <div className={styles.list} key={role.id}>
              <div className={styles.leftList}>
                <label style={{ fontSize: "22px" }}>
                  <b>{role.Role_Name}</b>
                </label>
                <label style={{ color: "#394759", fontWeight: "650" }}>
                  Members: {role.Member_Count}
                </label>
              </div>
              <div className={styles.rightList}>
                <button className={styles.viewBtn} onClick={() => handleView(role.Role_Name)}>View Details</button>
              </div>
            </div>
          ))
        ) : (
          <div style={{textAlign: "center"}}><h2>No roles found</h2></div>
        )}
      </div>


    </>
  );
}

export default AdminPermissionsRoles