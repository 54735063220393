import { useNavigate, useParams } from "react-router-dom";
import styles from "../css/supplierdetailsviewdetails.module.css";
import { useEffect, useRef, useState } from "react";
import styles2 from '../css/proffessionalSummary.module.css';

export const ProfessionalTabs = ({ isEditing, setIsEditing, activeTab, setActiveTab, handleDownloadAllFiles,
    applyFilters, setSearchQuery, searchQuery, handleSubmit, toggleFilterContainer, filterContainer, filterOptions,
    selectedScopes, handleCheckboxChange, rigOption }) => {

    const { name, id } = useParams();
    const handleEdit = () => setIsEditing(true);
    const handleCancel = () => setIsEditing(false);
    const [rigDesignToggle, setRigDesignToggle] = useState(false);
    const [scopeToggle, setScopeToggle] = useState(false);
    const [rigDesignSearchQuery, setRigDesignSearchQuery] = useState('');
    const filterDropdownRef = useRef(null);
    const [filterPosition, setFilterPosition] = useState({ left: 0, top: 0 });

    useEffect(() => {
        if (filterDropdownRef.current) {
            const rect = filterDropdownRef.current.getBoundingClientRect();
            setFilterPosition({
                left: rect.left - rect.width - 145,
                top: rect.top
            });
        }
    }, [filterDropdownRef.current, rigDesignToggle, scopeToggle]);

    const handleRigDesignClick = () => {
        // Toggle the visibility of the Rig design dropdown
        setRigDesignToggle(prev => !prev);
        if (!rigDesignToggle) {
            setScopeToggle(false); // Close Scope dropdown when Rig design is clicked
        }
    };

    const handleScopeClick = () => {
        // Toggle the visibility of the Scope dropdown
        setScopeToggle(prev => !prev);
        if (!scopeToggle) {
            setRigDesignToggle(false); // Close Rig design dropdown when Scope is clicked
        }
    };

    return (
        <>
            <div className={styles.tabsBar} style={{ marginTop: "90px" }}>
                <div>
                    <h3 className={styles.heading11}>{`Professional Details > ${name}`}</h3>
                </div>

                <div className={styles.tab}>
                    {["Details", "Docs", "Projects", "Experience"].map((tab) => (
                        <div
                            key={tab}
                            className={activeTab === tab ? styles.DivTabs1 : styles.DivTabs2}
                            onClick={() => setActiveTab(tab)}
                            style={
                                tab === "Details"
                                    ? { borderRadius: "8px 0 0 8px" }
                                    : tab === "Experience"
                                        ? { borderRadius: "0 8px 8px 0" }
                                        : {}
                            }
                        >
                            {tab}
                        </div>
                    ))}
                </div>

                {activeTab === "Projects" ?
                    <>
                        <div style={{ width: "360px", display: "flex", justifyContent: "flex-end" }}>
                            <div className={styles2.filterContainer}>
                                <button onClick={toggleFilterContainer} className={styles2.filterButton}>Filter <svg className={styles2.svg1} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" ><path d="M13.491 1.66699H6.50768C3.47435 1.66699 1.66602 3.47533 1.66602 6.50866V13.4837C1.66602 16.5253 3.47435 18.3337 6.50768 18.3337H13.4827C16.516 18.3337 18.3243 16.5253 18.3243 13.492V6.50866C18.3327 3.47533 16.5243 1.66699 13.491 1.66699ZM11.1077 14.167H8.88268C8.54102 14.167 8.25768 13.8837 8.25768 13.542C8.25768 13.2003 8.54102 12.917 8.88268 12.917H11.1077C11.4493 12.917 11.7327 13.2003 11.7327 13.542C11.7327 13.8837 11.4577 14.167 11.1077 14.167ZM13.3327 10.6253H6.66602C6.32435 10.6253 6.04102 10.342 6.04102 10.0003C6.04102 9.65866 6.32435 9.37533 6.66602 9.37533H13.3327C13.6743 9.37533 13.9577 9.65866 13.9577 10.0003C13.9577 10.342 13.6743 10.6253 13.3327 10.6253ZM14.9993 7.08366H4.99935C4.65768 7.08366 4.37435 6.80033 4.37435 6.45866C4.37435 6.11699 4.65768 5.83366 4.99935 5.83366H14.9993C15.341 5.83366 15.6243 6.11699 15.6243 6.45866C15.6243 6.80033 15.341 7.08366 14.9993 7.08366Z" fill="#292D32"></path></svg></button>
                                {filterContainer && (
                                    <>
                                        <div ref={filterDropdownRef} className={styles2.filterDropdown}>

                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1%" }}>
                                                <button onClick={handleRigDesignClick}
                                                    style={{ background: "white", color: "black", fontSize: "14px" }}>Rig design</button>
                                                <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6.75 13.5L11.25 9L6.75 4.5"
                                                        stroke="black"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>

                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1%" }}>
                                                <button onClick={handleScopeClick}
                                                    style={{ background: "white", color: "black", fontSize: "14px" }}>Scope</button>
                                                <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6.75 13.5L11.25 9L6.75 4.5"
                                                        stroke="black"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>

                                            </div>
                                            <button className={styles2.applyButton} onClick={applyFilters}>Apply</button> {/* Apply Button */}
                                        </div>
                                        {rigDesignToggle && <div className={styles2.filterDropdown2}
                                            style={{
                                                left: `${filterPosition.left}px`,  // Adjusted to add space
                                                top: `${filterPosition.top}px`,  // Adjusted to add space
                                                width: `${filterDropdownRef.current?.offsetWidth + 130}px`
                                            }}
                                        >
                                            <input
                                                type="text"
                                                placeholder="Type to search..."
                                                value={rigDesignSearchQuery}
                                                onChange={(e) => setRigDesignSearchQuery(e.target.value)}
                                                className={styles2.filterSearch}
                                            />
                                            <div className={styles2.filterOptions}>
                                                {rigOption
                                                    .filter(option => option.toLowerCase().includes(rigDesignSearchQuery.toLowerCase()))
                                                    .map((option, index) => (
                                                        <div key={index} className={styles2.filterOption}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    value={option}
                                                                    checked={selectedScopes.includes(option)}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                                {option}
                                                            </label>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>}
                                        {scopeToggle && <div className={styles2.filterDropdown3}
                                            style={{
                                                left: `${filterPosition.left}px`,  // Adjusted to add space
                                                top: `${filterPosition.top}px`,  // Conditional positioning
                                                width: `${filterDropdownRef.current?.offsetWidth + 130}px`  // Ensure width matches parent
                                            }}
                                        >
                                            <input
                                                type="text"
                                                placeholder="Type to search..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                className={styles2.filterSearch}
                                            />
                                            <div className={styles2.filterOptions}>
                                                {filterOptions
                                                    .filter(option => option.toLowerCase().includes(searchQuery.toLowerCase()))
                                                    .map((option, index) => (
                                                        <div key={index} className={styles2.filterOption}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    value={option}
                                                                    checked={selectedScopes.includes(option)}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                                {option}
                                                            </label>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                    :
                    <div style={{ width: "360px", display: "flex", justifyContent: "flex-end" }}>
                        {isEditing ? (
                            <div>
                                <button className={styles.editBtn} onClick={handleSubmit}>Save</button>
                                &nbsp;&nbsp;
                                <button className={styles.cancelBtn} onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        ) : (
                            <div>
                                {activeTab === "Docs" &&
                                    <>
                                        <button className={styles.cancelBtn} onClick={handleDownloadAllFiles} style={{ width: "fit-content" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                Download All
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                    <path
                                                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                                                        fill="#5B6BE1"
                                                    />
                                                </svg>
                                            </div>
                                        </button>
                                        &nbsp;&nbsp;
                                    </>
                                }
                                <button className={styles.editBtn} onClick={handleEdit}>
                                    Edit
                                </button>
                            </div>
                        )}
                    </div>
                }
            </div>
        </>
    );
};
