import React, { useEffect, useState } from "react";
import styles from "./css/itemsmaster.module.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import * as XLSX from 'xlsx';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Itemmaster() {
  const [featureCreateNewItem, setfeatureCreateNewItem] = useState([])
  const [itemdetails, setItemdetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Item_Name: "",
    Part_Number: "",
    Manufacturer_Part_Number: ""
  });
  // console.log("formData", formData);
  const [errorMessage, setErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster_manufacturer_part_numbers_duplicates?searchTerm=${encodeURIComponent(searchQuery.trim())}`);
      setItemdetails(res.data);
    };
    fetchProject();
  }, [searchQuery, refresh]);

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        // Retrieve group and roles from localStorage
        const group = localStorage.getItem("Group");
        const roles = JSON.parse(localStorage.getItem("Roles")) || [];

        // Ensure "Can View" overrides "Can Edit"
        const prioritizeAccess = (features) => {
          const featureMap = {};
          features.forEach((feature) => {
            const key = `${feature.Header_Name}-${feature.Feature_Name}`;
            if (!featureMap[key] || feature.Access === "Can View") {
              featureMap[key] = feature;
            }
          });

          console.log("featureMap", featureMap);
          return Object.values(featureMap);
        };

        // Combine group and role filters for a given feature
        const combineFeatures = (headerName, groupFeatureName, roleFeatureName) => {
          const groupFeatures = response.data.groupFeatures.filter((el) => {
            return (
              el.Group_Name === group &&
              el.Header_Name === headerName &&
              el.Feature_Name === groupFeatureName &&
              (el.Access === "Can View" || el.Access === "Can Edit")
            );
          });

          const roleFeatures = response.data.roleFeatures.filter((el) => {
            return (
              roles.includes(el.Role_Name) &&
              el.Header === headerName &&
              el.Feature === roleFeatureName &&
              el.Access === "Can View"
            );
          })
            .map((feature) => ({
              ...feature,
              Header_Name: feature.Header,  // unify property
              Feature_Name: feature.Feature,
            }));
          return [...groupFeatures, ...roleFeatures];
        };

        // List of features to process
        const featuresConfig = [
          { stateSetter: setfeatureCreateNewItem, header: "Items Master List", groupFeature: "Create New Item", roleFeature: "Create New Item" },
        ];

        // Process each feature and set the state
        featuresConfig.forEach(({ stateSetter, header, groupFeature, roleFeature }) => {
          const combinedFeatures = combineFeatures(header, groupFeature, roleFeature);
          stateSetter(prioritizeAccess(combinedFeatures));
          console.log("combinedFeatures", combinedFeatures);
        });
      } catch (error) {
        console.error(error);
      }
    }

    fetchRigDetails();
  }, []);


  const handleSearchItem = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    try {
      const response = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/items_master_list_post_stacked_duplicates",
        formData
      );

      if (response.data.success) {
        // alert(response.data.message); 
        setIsModalOpen(false);
        setFormData({
          Item_Name: "",
          Part_Number: "",
          Manufacturer_Part_Number: ""
        });

        setSearchQuery("");
        setRefresh(!refresh);
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage("An error occurred while submitting. Please try again.");
    }
  };

  const handleCancel = async () => {
    setIsModalOpen(false);
    setFormData({
      Item_Name: "",
      Part_Number: "",
      Manufacturer_Part_Number: ""
    });
  }

  const handleDownloadExcel = () => {
    // Define header row with bold font
    const header = [
      { v: 'S. No', s: { bold: true } },
      { v: 'Item ID', s: { bold: true } },
      { v: 'Item Name', s: { bold: true } },
      { v: 'Part Number', s: { bold: true } },
      { v: 'Manufacturer Part Number', s: { bold: true } },
    ];

    const data = itemdetails.map((item, index) => {
      return [
        index + 1,
        item.Item_Id,
        item.Item_Name,
        item.Part_Number,
        item.Manufacturer_Part_Number,
      ];
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);

    const columnWidths = [
      { wch: 5 }, // S. No
      { wch: 25 }, // Item ID
      { wch: 50 }, // Item Name
      { wch: 30 }, // Part Number
      { wch: 50 }, // Manufacturer Part Number
    ];
    worksheet['!cols'] = columnWidths;


    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Incoming Inspections');

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'Items_Master_List.xlsx');
  };

  return (
    <div>
      <div className={styles.topContainer}>
        <div className={styles.searchContainer}>
          <h1 className={styles.itemMasterHeading}>Items Master List</h1>
          <div className={styles.searchBarWrapper}>
            <input
              type="text"
              placeholder="Search by Item Id, Item Name and Part Number"
              value={searchQuery}
              onChange={handleSearchItem}
              className={styles.searchBox}
            />
            <svg
              className={styles.searchIconFilter}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                fill="#BCBCBC"
              />
              <path
                d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
                fill="#BCBCBC"
              />
            </svg>
          </div>
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
            <div className={styles.downloadButton}>
              <svg
                width="50"
                height="50"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={handleDownloadExcel}
              >
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                  fill="#5B6BE1"
                />
              </svg>
            </div>
            {featureCreateNewItem.length > 0 &&
              <button className={styles.addButton} onClick={() => setIsModalOpen(true)}>+ Create Item</button>
            }
          </div>

        </div>
      </div>

      <div className={styles.itemstablecontainer}>
        <table className={styles.itemstable}>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Item ID</th>
              <th>Item Name</th>
              <th>Part Number</th>
              <th>Manufacturer Part Number</th>
            </tr>
          </thead>
          <tbody>
            {itemdetails.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.Item_Id}</td>
                <td>{item.Item_Name}</td>
                <td>{item.Part_Number}</td>
                <td>{item.Manufacturer_Part_Number}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        open={isModalOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
        sx={{ backdropFilter: "blur(4px)" }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              width: "600px",
              p: 3,
              backgroundColor: "#fff",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: "5px 4px 20px rgba(0, 0, 0, 0.2)",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h5" component="h2" sx={{ color: "#5b6be1", textAlign: "center", fontWeight: 600, marginBottom: "30px" }}>
              Create Item
            </Typography>
            <div className={styles.inputRow}>
              <label>Item Name:</label>
              <input type="text" name="Item_Name" value={formData.Item_Name} onChange={handleInputChange} />
            </div>
            <div className={styles.inputRow}>
              <label>Part Number:</label>
              <input type="text" name="Part_Number" value={formData.Part_Number} onChange={handleInputChange} />
            </div>
            <div className={styles.inputRow}>
              <label>Manufacturer Part Number:</label>
              <input type="text" name="Manufacturer_Part_Number" value={formData.Manufacturer_Part_Number} onChange={handleInputChange} />
            </div>

            {errorMessage && (
              <Typography variant="body2" sx={{ color: "red", textAlign: "center", marginTop: "10px" }}>
                {errorMessage}
              </Typography>
            )}

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px", gap: "10px" }}>
              <button className={styles.submitButton} onClick={handleSubmit}>Submit</button>
              <button className={styles.cancelButton} onClick={handleCancel}>Cancel</button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default Itemmaster;