import React, { useState, useEffect, useCallback } from 'react'
import styles from "../Components/css/qmstracker.module.css";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { NavLink, useParams, useNavigate  } from "react-router-dom";
import axios from "axios";

import { ReactComponent as PreviewIcon } from "../Components/newtabpreviewicon.svg";
import { ReactComponent as DownloadIcon } from "../Components/downloadicon.svg";

import crossmarkIcon from "../Components/crossmark.png";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const QMStracker = () => {

  const [isEditing, setIsEditing] = useState(false);
  const [showeditRowA, setShoweditRowA] = useState(false);
  const [showeditRowB, setShoweditRowB] = useState(false);
  const [showeditRowC, setShoweditRowC] = useState(false);
  const [showeditRowD, setShoweditRowD] = useState(false);
  const [showeditRowE, setShoweditRowE] = useState(false);

  const [qmsTableLevel1, setQmsTableLevel1] = useState([]);
  const [qmsTableLevel2, setQmsTableLevel2] = useState([]);
  const [qmsTableLevel3, setQmsTableLevel3] = useState([]);
  const [qmsTableLevel4, setQmsTableLevel4] = useState([]);
  const [qmsTableLevel5, setQmsTableLevel5] = useState([]);

  const [editModesm, seteditModesm] = useState(false);
  const [editModesm1, seteditModesm1] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showEditIconA, setShowEditIconA] = useState(false);
  const [showEditIconB, setShowEditIconB] = useState(false);
  const [showEditIconC, setShowEditIconC] = useState(false);
  const [showEditIconD, setShowEditIconD] = useState(false);
  const [showEditIconE, setShowEditIconE] = useState(false);
  const [uploadfile, setUploadfile] = useState("");

  const [smResults, setsmResults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [tempQMS,setTempQMS] = useState("");
  const [documentToUpload, setDocumentToUpload] = useState("");
  const [uploadDocumentURL, setUploadDocumentURL] = useState(null);

  const [rowsA, setRowsA] = useState([]);
  const [rowsB, setRowsB] = useState([]);
  const [rowsC, setRowsC] = useState([]);
  const [rowsD, setRowsD] = useState([]);
  const [rowsE, setRowsE] = useState([]);

  const [editingRow, setEditingRow] = useState(false);

  const [editindex, setditindex] = useState();
  const [showInputRowA, setShowInputRowA] = useState(false);
  const [showInputRowB, setShowInputRowB] = useState(false);
  const [showInputRowC, setShowInputRowC] = useState(false);
  const [showInputRowD, setShowInputRowD] = useState(false);
  const [showInputRowE, setShowInputRowE] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [titleToDelete, setTitleToDelete] = useState(null);
  const [docLevelToDelete, setDocLevelToDelete] = useState(null);
  const [trackerIdToDelete, setTrackerIdToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [docPreview, setDocPreview] = useState(true);

  const [formDatasm, setFormDatasm] = useState([{
    doc_Title: "",
    doc_Number: "",
    doc_Level: "",
    Revision_no: "",
    Revision_date: "",
    Document: "",
  }]);

  const handleEditClick = () => {
    setIsEditing(true);
    // Add logic to handle edit click
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    window.location.reload();
    // Add logic to save changes
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setIsButtonDisabled(false);

    if (!showeditRowA) {
      handleshift();
    }
    if (showInputRowA) {
      handleDeleteRowA();
    }

    if (!showeditRowB) {
      handleshift();
    }
    if (showInputRowB) {
      handleDeleteRowB();
    }

    if (!showeditRowC) {
      handleshift();
    }
    if (showInputRowC) {
      handleDeleteRowC();
    }

    if (!showeditRowD) {
      handleshift();
    }
    if (showInputRowD) {
      handleDeleteRowD();
    }

    if (!showeditRowE) {
      handleshift();
    }
    if (showInputRowE) {
      handleDeleteRowE();
    }
    // Add logic to handle cancel click
  };

  const { id } = useParams();

  const handletick = async(e, doc_Number, doc_Title, doc_Level, tracker_id) => {
    e.preventDefault();
    setShowEditIconA(false);
    setShowEditIconB(false);
    setShowEditIconC(false);
    setShowEditIconD(false);
    setShowEditIconE(false);

    seteditModesm1(false);

    const formData = new FormData();
    formData.append("Document", documentToUpload);
    
    const payload = {
      doc_Title: formDatasm[0].doc_Title,
      doc_Number: formDatasm[0].doc_Number,
      doc_Level: formDatasm[0].doc_Level,
      Revision_no: formDatasm[0].Revision_no,
      Revision_date: formDatasm[0].Revision_date,
      Document: formDatasm[0].Document,
    };
    console.log(formDatasm);
    console.log(payload);
    console.log(doc_Number);
    console.log(doc_Title);
    console.log(doc_Level);
    try{
      console.log(doc_Number);
      console.log(doc_Title);
      console.log(doc_Level);
      console.log("formData", formData);

      const res = await axios.put(
       `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getqmstrackerdetails/${tracker_id}`,
        payload
        
      );
 


      const res1 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/qms_files/update?tracker_id=${tracker_id}`,
         formData,
         {
          headers: {
            "Content-Type": "multipart/form-data",
          },
         }
      );

      if (res.status === 200 && res1.status === 200) {
        alert("project updated successfully");
        setRefresh(!refresh);
        console.log("Updated Values:", formDatasm);
        console.log("SQL Query:", "UPDATE reference_details.qms_tracker SET ? WHERE doc_Number = ?", [payload, doc_Number]);
      }

      setFormDatasm([{
        doc_Title: "",
        doc_Number: "",
        doc_Level: "",
        Revision_no: "",
        Revision_date: "",
        Document: "",
      }]);

    } catch(error){
      console.error(error);
      console.log(id);
    }
      setTempQMS("");
      setDocumentToUpload("");
      fetchQMSTable1();
      fetchQMSTable2();
      fetchQMSTable3();
      fetchQMSTable4();
      fetchQMSTable5();
      setIsButtonDisabled(false);
  };

  const fetchQMSTable1 = async () => {
    try {
      const res = await axios.get(
        /*"http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/qmstracker"*/
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getqmstrackerdetails"
        
      );
      setQmsTableLevel1(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      console.error("Error fetching QMS tracker:", error);
    }
  };
  const fetchQMSTable2 = async () => {
    try {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getqmstrackerdetails"
      );
      setQmsTableLevel2(res.data.data);
    } catch (error) {
      console.error("Error fetching QMS tracker:", error);
    }
  };
  const fetchQMSTable3 = async () => {
    try {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getqmstrackerdetails"
      );
      setQmsTableLevel3(res.data.data);
    } catch (error) {
      console.error("Error fetching QMS tracker:", error);
    }
  };
  const fetchQMSTable4 = async () => {
    try {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getqmstrackerdetails"
      );
      setQmsTableLevel4(res.data.data);
    } catch (error) {
      console.error("Error fetching QMS tracker:", error);
    }
  };
  const fetchQMSTable5 = async () => {
    try {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getqmstrackerdetails"
      );
      setQmsTableLevel5(res.data.data);
    } catch (error) {
      console.error("Error fetching QMS tracker:", error);
    }
  };

  useEffect(() => {

    fetchQMSTable1();
    fetchQMSTable2();
    fetchQMSTable3();
    fetchQMSTable4();
    fetchQMSTable5();
    }, []);

  const handleRemove = (id) => {
    const updatedsmResults = smResults.filter((sm) => sm.ID_No !== id);
    setsmResults(updatedsmResults);
    console.log("Updated smResults:", updatedsmResults);
    setIdToDelete(id);
    setIsModalOpen(true);
    // window.location.reload();
  };

  const handlecancelsm = useCallback(
    (doc_Number, doc_Title, doc_Level, tracker_id) => {
      console.log("called" + doc_Number);
      setIdToDelete(doc_Number);
      setTitleToDelete(doc_Title);
      setDocLevelToDelete(doc_Level);
      setTrackerIdToDelete(tracker_id);
      setIsButtonDisabled(false);
      setIsModalOpen(true);
    },
    [setIdToDelete, setTitleToDelete, setDocLevelToDelete, setTrackerIdToDelete, setIsButtonDisabled]
  );

  const handleYesClick = async () => {
    try {
      console.log("doc_Number to delete:" + idToDelete);
      console.log("doc_Title to delete:" + titleToDelete);
      console.log("Level of document being deleted:" + docLevelToDelete);
      console.log("tracker_id to delete: "+ trackerIdToDelete);

      const deleteData = {
/*         doc_Number: idToDelete,
        doc_Title: titleToDelete,
        doc_Level: docLevelToDelete,
 */        tracker_id: trackerIdToDelete,
      };
  
      await axios.delete('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getqmstrackerdetails', {
        data: deleteData,
      });
  
      const updatedQmsTableLevel1 = qmsTableLevel1.filter(
        (el) => el.tracker_id !== trackerIdToDelete
      );
      const updatedQmsTableLevel2 = qmsTableLevel2.filter(
        (el) => el.tracker_id !== trackerIdToDelete
      );
      const updatedQmsTableLevel3 = qmsTableLevel3.filter(
        (el) => el.tracker_id !== trackerIdToDelete
      );
      const updatedQmsTableLevel4 = qmsTableLevel4.filter(
        (el) => el.tracker_id !== trackerIdToDelete
      );
      const updatedQmsTableLevel5 = qmsTableLevel5.filter(
        (el) => el.tracker_id !== trackerIdToDelete
      );

      setQmsTableLevel1(updatedQmsTableLevel1);
      setQmsTableLevel2(updatedQmsTableLevel2);
      setQmsTableLevel3(updatedQmsTableLevel3);
      setQmsTableLevel4(updatedQmsTableLevel4);
      setQmsTableLevel5(updatedQmsTableLevel5);
      setRefresh(!refresh);

      console.log("Updated QMS Table Level 1:", updatedQmsTableLevel1);
      console.log("Updated QMS Table Level 2:", updatedQmsTableLevel2);
      console.log("Updated QMS Table Level 3:", updatedQmsTableLevel3);
      console.log("Updated QMS Table Level 4:", updatedQmsTableLevel4);
      console.log("Updated QMS Table Level 5:", updatedQmsTableLevel5);

      setIdToDelete(null);
      setTrackerIdToDelete(null);
      setTitleToDelete(null);
      setDocLevelToDelete(null);
      console.log("idToDelete:", idToDelete);

    } catch (error) {
      console.error("Error removing data:", error);
    }
    setIsModalOpen(false);
    fetchQMSTable1();
    fetchQMSTable2();
    fetchQMSTable3();
    fetchQMSTable4();
    fetchQMSTable5();

  };

/*   useEffect(() => {
    fetchQMSTable1
    fetchQMSTable2();
    fetchQMSTable3();
    fetchQMSTable4();
    fetchQMSTable5();
  }, [fetchQMSTable1 , fetchQMSTable2 , fetchQMSTable3 , fetchQMSTable4 , fetchQMSTable5]);
 */

  const handleNoClick = () => {
    // Close the modal without removing the row
    setIsModalOpen(false);
  };

  const handleshift = () => {
    setShowEditIconA(false);
    setShowEditIconB(false);
    setShowEditIconC(false);
    setShowEditIconD(false);
    setShowEditIconE(false);
    seteditModesm1(false);
    setIsButtonDisabled(false);
  }

  const handleeditsm1 = async (index) => {
    setShowEditIconA(!showEditIconA);
    setIsButtonDisabled(true);
    setShoweditRowA(false);
    setShoweditRowB(false);
    setShoweditRowC(false);
    setShoweditRowD(false);
    setShoweditRowE(false);
    seteditModesm1(true);
    setRefresh(!refresh);
    setditindex(index);
    setFormDatasm([{
      doc_Title: filteredQmsTableLevel1[index].doc_Title,
      doc_Number: filteredQmsTableLevel1[index].doc_Number,
      doc_Level: filteredQmsTableLevel1[index].doc_Level,
      Revision_no: filteredQmsTableLevel1[index].Revision_no,
      Revision_date: filteredQmsTableLevel1[index].Revision_date,
      Document: filteredQmsTableLevel1[index].Document,
  }])  
  setIsButtonDisabled(true);
  console.log(filteredQmsTableLevel1[index].professional_name);
  console.log(index)
  };

  const handleeditsm2 = async (index) => {
    setShowEditIconB(!showEditIconB);
    setIsButtonDisabled(true);
    setShoweditRowA(false);
    setShoweditRowB(false);
    setShoweditRowC(false);
    setShoweditRowD(false);
    setShoweditRowE(false);
    seteditModesm1(true);
    setRefresh(!refresh);
    setditindex(index);
    setFormDatasm([{
      doc_Title: filteredQmsTableLevel2[index].doc_Title,
      doc_Number: filteredQmsTableLevel2[index].doc_Number,
      doc_Level: filteredQmsTableLevel2[index].doc_Level,
      Revision_no: filteredQmsTableLevel2[index].Revision_no,
      Revision_date: filteredQmsTableLevel2[index].Revision_date,
      Document: filteredQmsTableLevel2[index].Document,
  }])  
  setIsButtonDisabled(true);
  console.log(filteredQmsTableLevel2[index].professional_name);
  };

  const handleeditsm3 = async (index) => {
    setShowEditIconC(!showEditIconC);
    setIsButtonDisabled(true);
    setShoweditRowA(false);
    setShoweditRowB(false);
    setShoweditRowC(false);
    setShoweditRowD(false);
    setShoweditRowE(false);
    seteditModesm1(true);
    setRefresh(!refresh);
    setditindex(index);
    setFormDatasm([{
      doc_Title: filteredQmsTableLevel3[index].doc_Title,
      doc_Number: filteredQmsTableLevel3[index].doc_Number,
      doc_Level: filteredQmsTableLevel3[index].doc_Level,
      Revision_no: filteredQmsTableLevel3[index].Revision_no,
      Revision_date: filteredQmsTableLevel3[index].Revision_date,
      Document: filteredQmsTableLevel3[index].Document,
  }])  
  setIsButtonDisabled(true);
  console.log(filteredQmsTableLevel3[index].professional_name);
  };

  const handleeditsm4 = async (index) => {
    setShowEditIconD(!showEditIconD);
    setIsButtonDisabled(true);
    setShoweditRowA(false);
    setShoweditRowB(false);
    setShoweditRowC(false);
    setShoweditRowD(false);
    setShoweditRowE(false);
    seteditModesm1(true);
    setRefresh(!refresh);
    setditindex(index);
    setFormDatasm([{
      doc_Title: filteredQmsTableLevel4[index].doc_Title,
      doc_Number: filteredQmsTableLevel4[index].doc_Number,
      doc_Level: filteredQmsTableLevel4[index].doc_Level,
      Revision_no: filteredQmsTableLevel4[index].Revision_no,
      Revision_date: filteredQmsTableLevel4[index].Revision_date,
      Document: filteredQmsTableLevel4[index].Document,
  }])  
  setIsButtonDisabled(true);
  console.log(filteredQmsTableLevel4[index].professional_name);
  };

  const handleeditsm5 = async (index) => {
    setShowEditIconE(!showEditIconE);
    setIsButtonDisabled(true);
    setShoweditRowA(false);
    setShoweditRowB(false);
    setShoweditRowC(false);
    setShoweditRowD(false);
    setShoweditRowE(false);
    seteditModesm1(true);
    setRefresh(!refresh);
    setditindex(index);
    setFormDatasm([{
      doc_Title: filteredQmsTableLevel5[index].doc_Title,
      doc_Number: filteredQmsTableLevel5[index].doc_Number,
      doc_Level: filteredQmsTableLevel5[index].doc_Level,
      Revision_no: filteredQmsTableLevel5[index].Revision_no,
      Revision_date: filteredQmsTableLevel5[index].Revision_date,
      Document: filteredQmsTableLevel5[index].Document,
  }])  
  setIsButtonDisabled(true);
  console.log(filteredQmsTableLevel5[index].professional_name);
  };

  
  const handleDeleteRowA = (index) => {
    const updatedRowsA = rowsA.filter((_, i) => i !== index);
    setRowsA(updatedRowsA);
    setShowInputRowA(false);
    setIsButtonDisabled(false);
  };
  const handleDeleteRowB = (index) => {
    const updatedRowsB = rowsB.filter((_, i) => i !== index);
    setRowsB(updatedRowsB);
    setShowInputRowB(false);
    setIsButtonDisabled(false);
  };  
  const handleDeleteRowC = (index) => {
    const updatedRowsC = rowsC.filter((_, i) => i !== index);
    setRowsC(updatedRowsC);
    setShowInputRowC(false);
    setIsButtonDisabled(false);
  };  
  const handleDeleteRowD = (index) => {
    const updatedRowsD = rowsD.filter((_, i) => i !== index);
    setRowsD(updatedRowsD);
    setShowInputRowD(false);
    setIsButtonDisabled(false);
  };  
  const handleDeleteRowE = (index) => {
    const updatedRowsE = rowsE.filter((_, i) => i !== index);
    setRowsE(updatedRowsE);
    setShowInputRowE(false);
    setIsButtonDisabled(false);
  };

  const inputdetailsmanually_A = () => {
    setIsButtonDisabled(true);
    setShowInputRowA(true);
  };
  const inputdetailsmanually_B = () => {
    setIsButtonDisabled(true);
    setShowInputRowB(true);
  };
  const inputdetailsmanually_C = () => {
    setIsButtonDisabled(true);
    setShowInputRowC(true);
  };
  const inputdetailsmanually_D = () => {
    setIsButtonDisabled(true);
    setShowInputRowD(true);
  };
  const inputdetailsmanually_E = () => {
    setIsButtonDisabled(true);
    setShowInputRowE(true);
  };

  const handleInputChangevis = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
/*    setFormDatasm((prev) => (
      {
      ...prev,
      [name]: value,
    }));
 */    
    const updatedData = [...formDatasm];
    updatedData[0] = {
      ...updatedData[0],
      [name]: value,
    };
    setFormDatasm(updatedData);
    console.log(formDatasm);
  };

  const handleSubmitTickA = async (e) => {
    e.preventDefault();
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log(formDatasm);
    try {
      await handleSubmitsm1(e);
    } catch (error) {
  }
};
  const handleSubmitTickB = async (e) => {
    e.preventDefault();
    console.log(formDatasm[0].doc_Level);
    await new Promise(resolve => setTimeout(resolve, 1000));
    try {
      await handleSubmitsm2(e);
    } catch (error) {
  }
};
  const handleSubmitTickC = async (e) => {
    e.preventDefault();
    console.log(formDatasm[0].doc_Level);
    await new Promise(resolve => setTimeout(resolve, 1000));
    try {
      await handleSubmitsm3(e);
    } catch (error) {
  }
};
  const handleSubmitTickD = async (e) => {
    e.preventDefault();
    console.log(formDatasm[0].doc_Level);
    await new Promise(resolve => setTimeout(resolve, 1000));
    try {
      await handleSubmitsm4(e);
    } catch (error) {
  }
};
  const handleSubmitTickE = async (e) => {
    e.preventDefault();
    console.log(formDatasm[0].doc_Level);
    await new Promise(resolve => setTimeout(resolve, 1000));
    try {
        await handleSubmitsm5(e);
      } catch (error) {
    }
  };

  const handleSubmitsm1 = async (e) => {
    e.preventDefault();
    console.log("formDatasm:", formDatasm);
    const payload = {
      doc_Title: formDatasm[0].doc_Title,
      doc_Number: formDatasm[0].doc_Number,
      doc_Level: "1",
      Revision_no: formDatasm[0].Revision_no,
      Revision_date: formDatasm[0].Revision_date,
      Document: formDatasm[0].Document,
    };

    const formData = new FormData();
    formData.append("Document", documentToUpload);

    return new Promise(async (resolve, reject) => {
      try {
        try{                
          console.log("payload", payload);
          console.log(payload.doc_Level);
          const res = await axios.post(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/postqmstrackerdetails`,
            payload
          );

          const tracker_id = res.data.result.tracker_id;

          const res1 = await axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/qms_files_addItem/update?doc_Title=${payload.doc_Title}&doc_Number=${payload.doc_Number}&doc_Level=${payload.doc_Level}&tracker_id=${tracker_id}`,
             formData,
             {
              headers: {
                "Content-Type": "multipart/form-data",
              },
             }
          );

          // Check if the response has a truthy s_no value
          const doc_Number = res.doc_Number || null;
          console.log(doc_Number);

          setFormDatasm([{
            doc_Title: "",
            doc_Number: "",
            doc_Level: "",
            Revision_no: "",
            Revision_date: "",
            Document: "",
          }]);
    
          if (res.status === 200 && res1.status === 200) {
            alert("Details Added Successfully");
            setRefresh(!refresh);
          }
        } 
        catch(error){
          console.error("Error submitting data in post request:", error);
          console.log(id);
          alert("Error adding details. Please enter correct details");
        }
        setTempQMS("");
        setDocumentToUpload("");  
        setShowEditIconA(false);
        setShowEditIconB(false);
        setShowEditIconC(false);
        setShowEditIconD(false);
        setShowEditIconE(false);
        seteditModesm1(false);
        fetchQMSTable1();
        fetchQMSTable2();
        fetchQMSTable3();
        fetchQMSTable4();
        fetchQMSTable5();
        setIsButtonDisabled(false);
        setShowInputRowA(false);
        setShowInputRowB(false);
        setShowInputRowC(false);
        setShowInputRowD(false);
        setShowInputRowE(false);

        resolve();
      } catch(error){reject(error);}
  });
}
  const handleSubmitsm2 = async (e) => {
    e.preventDefault();
    console.log("formDatasm:", formDatasm);
    const payload = {
      doc_Title: formDatasm[0].doc_Title,
      doc_Number: formDatasm[0].doc_Number,
      doc_Level: "2",
      Revision_no: formDatasm[0].Revision_no,
      Revision_date: formDatasm[0].Revision_date,
      Document: formDatasm[0].Document,
    };

    const formData = new FormData();
    formData.append("Document", documentToUpload);

    return new Promise(async (resolve, reject) => {
      try {
        try{                
          console.log("payload", payload);
          console.log(payload.doc_Level);
          const res = await axios.post(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/postqmstrackerdetails`,
            payload
          );

          const tracker_id = res.data.result.tracker_id;

          const res1 = await axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/qms_files_addItem/update?doc_Title=${payload.doc_Title}&doc_Number=${payload.doc_Number}&doc_Level=${payload.doc_Level}&tracker_id=${tracker_id}`,
             formData,
             {
              headers: {
                "Content-Type": "multipart/form-data",
              },
             }
          );


          // Check if the response has a truthy s_no value
          const doc_Number = res.doc_Number || null;
          console.log(doc_Number);

          setFormDatasm([{
            doc_Title: "",
            doc_Number: "",
            doc_Level: "",
            Revision_no: "",
            Revision_date: "",
            Document: "",
          }]);

          if (res.status === 200 && res1.status === 200) {
            alert("Details Added Successfully");
            setRefresh(!refresh);
          }

        } 
        catch(error){
          console.error("Error submitting data in post request:", error);
          console.log(id);
          alert("Error adding details. Please enter correct details");
        }
        setTempQMS("");
        setDocumentToUpload("");  
        setShowEditIconA(false);
        setShowEditIconB(false);
        setShowEditIconC(false);
        setShowEditIconD(false);
        setShowEditIconE(false);
        seteditModesm1(false);
        fetchQMSTable1();
        fetchQMSTable2();
        fetchQMSTable3();
        fetchQMSTable4();
        fetchQMSTable5();
        setIsButtonDisabled(false);
        setShowInputRowA(false);
        setShowInputRowB(false);
        setShowInputRowC(false);
        setShowInputRowD(false);
        setShowInputRowE(false);

        resolve();
      } catch(error){reject(error);}
  });
  }
  const handleSubmitsm3 = async (e) => {
    e.preventDefault();
    console.log("formDatasm:", formDatasm);
    const payload = {
      doc_Title: formDatasm[0].doc_Title,
      doc_Number: formDatasm[0].doc_Number,
      doc_Level: "3",
      Revision_no: formDatasm[0].Revision_no,
      Revision_date: formDatasm[0].Revision_date,
      Document: formDatasm[0].Document,
    };

    const formData = new FormData();
    formData.append("Document", documentToUpload);

    return new Promise(async (resolve, reject) => {
      try {
        try{                
          console.log("payload", payload);
          console.log(payload.doc_Level);
          const res = await axios.post(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/postqmstrackerdetails`,
            payload
          );

          const tracker_id = res.data.result.tracker_id;

          const res1 = await axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/qms_files_addItem/update?doc_Title=${payload.doc_Title}&doc_Number=${payload.doc_Number}&doc_Level=${payload.doc_Level}&tracker_id=${tracker_id}`,
             formData,
             {
              headers: {
                "Content-Type": "multipart/form-data",
              },
             }
          );

          // Check if the response has a truthy s_no value
          const doc_Number = res.doc_Number || null;
          console.log(doc_Number);

          setFormDatasm([{
            doc_Title: "",
            doc_Number: "",
            doc_Level: "",
            Revision_no: "",
            Revision_date: "",
            Document: "",
          }]);
    
          if (res.status === 200 && res1.status === 200) {
            alert("Details Added Successfully");
            setRefresh(!refresh);
          }

        } 
        catch(error){
          console.error("Error submitting data in post request:", error);
          console.log(id);
          alert("Error adding details. Please enter correct details");
        }
        setTempQMS("");
        setDocumentToUpload("");  
        setShowEditIconA(false);
        setShowEditIconB(false);
        setShowEditIconC(false);
        setShowEditIconD(false);
        setShowEditIconE(false);
        seteditModesm1(false);
        fetchQMSTable1();
        fetchQMSTable2();
        fetchQMSTable3();
        fetchQMSTable4();
        fetchQMSTable5();
        setIsButtonDisabled(false);
        setShowInputRowA(false);
        setShowInputRowB(false);
        setShowInputRowC(false);
        setShowInputRowD(false);
        setShowInputRowE(false);

        resolve();
      } catch(error){reject(error);}
  });
  }
  const handleSubmitsm4 = async (e) => {
    e.preventDefault();
    console.log("formDatasm:", formDatasm);
    const payload = {
      doc_Title: formDatasm[0].doc_Title,
      doc_Number: formDatasm[0].doc_Number,
      doc_Level: "4",
      Revision_no: formDatasm[0].Revision_no,
      Revision_date: formDatasm[0].Revision_date,
      Document: formDatasm[0].Document,
    };

    const formData = new FormData();
    formData.append("Document", documentToUpload);

    return new Promise(async (resolve, reject) => {
      try {
        try{                
          console.log("payload", payload);
          console.log(payload.doc_Level);
          const res = await axios.post(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/postqmstrackerdetails`,
            payload
          );

          const tracker_id = res.data.result.tracker_id;

          const res1 = await axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/qms_files_addItem/update?doc_Title=${payload.doc_Title}&doc_Number=${payload.doc_Number}&doc_Level=${payload.doc_Level}&tracker_id=${tracker_id}`,
             formData,
             {
              headers: {
                "Content-Type": "multipart/form-data",
              },
             }
          );

          // Check if the response has a truthy s_no value
          const doc_Number = res.doc_Number || null;
          console.log(doc_Number);

          setFormDatasm([{
            doc_Title: "",
            doc_Number: "",
            doc_Level: "",
            Revision_no: "",
            Revision_date: "",
            Document: "",
          }]);
    
          if (res.status === 200 && res1.status === 200) {
            alert("Details Added Successfully");
            setRefresh(!refresh);
          }

        } 
        catch(error){
          console.error("Error submitting data in post request:", error);
          console.log(id);
          alert("Error adding details. Please enter correct details");

        }
        setTempQMS("");
        setDocumentToUpload("");  
        setShowEditIconA(false);
        setShowEditIconB(false);
        setShowEditIconC(false);
        setShowEditIconD(false);
        setShowEditIconE(false);
        seteditModesm1(false);
        fetchQMSTable1();
        fetchQMSTable2();
        fetchQMSTable3();
        fetchQMSTable4();
        fetchQMSTable5();
        setIsButtonDisabled(false);
        setShowInputRowA(false);
        setShowInputRowB(false);
        setShowInputRowC(false);
        setShowInputRowD(false);
        setShowInputRowE(false);

        resolve();
      } catch(error){reject(error);}
  });
  }
  const handleSubmitsm5 = async (e) => {
    e.preventDefault();
    console.log("formDatasm:", formDatasm);
    const payload = {
      doc_Title: formDatasm[0].doc_Title,
      doc_Number: formDatasm[0].doc_Number,
      doc_Level: "5",
      Revision_no: formDatasm[0].Revision_no,
      Revision_date: formDatasm[0].Revision_date,
      Document: formDatasm[0].Document,
    };

    const formData = new FormData();
    formData.append("Document", documentToUpload);

    return new Promise(async (resolve, reject) => {
      try {
        try{                
          console.log("payload", payload);
          console.log(payload.doc_Level);
          const res = await axios.post(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/postqmstrackerdetails`,
            payload
          );

          const tracker_id = res.data.result.tracker_id;

          const res1 = await axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/qms_files_addItem/update?doc_Title=${payload.doc_Title}&doc_Number=${payload.doc_Number}&doc_Level=${payload.doc_Level}&tracker_id=${tracker_id}`,
             formData,
             {
              headers: {
                "Content-Type": "multipart/form-data",
              },
             }
          );

          // Check if the response has a truthy s_no value
          const doc_Number = res.doc_Number || null;
          console.log(doc_Number);

          setFormDatasm([{
            doc_Title: "",
            doc_Number: "",
            doc_Level: "",
            Revision_no: "",
            Revision_date: "",
            Document: "",
          }]);
    
          if (res.status === 200 && res1.status === 200) {
            alert("Details Added Successfully");
            setRefresh(!refresh);
          }
        } 
        catch(error){
          console.error("Error submitting data in post request:", error);
          console.log(id);
          alert("Error adding details. Please enter correct details");
        }
        setTempQMS("");
        setDocumentToUpload("");  
        setShowEditIconA(false);
        setShowEditIconB(false);
        setShowEditIconC(false);
        setShowEditIconD(false);
        setShowEditIconE(false);
        seteditModesm1(false);
        fetchQMSTable1();
        fetchQMSTable2();
        fetchQMSTable3();
        fetchQMSTable4();
        fetchQMSTable5();
        setIsButtonDisabled(false);
        setShowInputRowA(false);
        setShowInputRowB(false);
        setShowInputRowC(false);
        setShowInputRowD(false);
        setShowInputRowE(false);

        resolve();
      } catch(error){reject(error);}
  });
  }

  const handlecancelsm1 = () => {
    setShowEditIconA(false);
    setShowEditIconB(false);
    setShowEditIconC(false);
    setShowEditIconD(false);
    setShowEditIconE(false);
  }
 
  const handlePreview = () => {

  };

  const handleDownload = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = "QMSdocument"; // Set desired filename
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };

  const handleUpload = () => {

  };

  const handleFileChange = async(name, event, index) => {
    event.preventDefault();
    const temp = event.target.files[0];
    console.log("//////////////////////////////////////////////*******")
    console.log(temp);
    setDocumentToUpload(temp);
    setTempQMS(temp.name);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    const updatedData = [...formDatasm];
    updatedData[0] = {
      ...updatedData[0],
      [name]: value,
    };
    setFormDatasm(updatedData);
    console.log(updatedData);
  };

  const CustomBackdrop = () => (
    <div
      style={{
        backdropFilter: 'blur(2px)',
        backgroundColor: 'rgba(0, 0, 0, 0.15)', // Set the background color to transparent
        height: '100%', // Adjust height as needed
      }}
    />
  );  

  const filteredQmsTableLevel1 = qmsTableLevel1?.filter((doc_A)=>doc_A.doc_Level? (doc_A.doc_Level === "1")  : false);
  const filteredQmsTableLevel2 = qmsTableLevel2?.filter((doc_B)=>doc_B.doc_Level? (doc_B.doc_Level === "2")  : false);
  const filteredQmsTableLevel3 = qmsTableLevel3?.filter((doc_C)=>doc_C.doc_Level? (doc_C.doc_Level === "3")  : false);
  const filteredQmsTableLevel4 = qmsTableLevel4?.filter((doc_D)=>doc_D.doc_Level? (doc_D.doc_Level === "4")  : false);
  const filteredQmsTableLevel5 = qmsTableLevel5?.filter((doc_E)=>doc_E.doc_Level? (doc_E.doc_Level === "5")  : false);

  return (
    <div className={styles.maindiv}>

      {!isEditing ? (

        <div className={styles.maindiv}>

          <div className={styles.buttonsdiv}>
            <button onClick={handleEditClick}>
              Edit{" "}
              <svg
                width="12"
                height="12"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-5px", marginLeft: "5px" }}
              >
                <path
                  d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>

            <div className={styles.levelHeader}>
            <h5>A. QUALITY POLICY, QUALITY OBJECTIVES, QMS MANUAL | LEVEL 1</h5>
            </div>

            <div className={styles.qmstablecontainer}>
              <table className={styles.qmstable}>
                <thead>
                  <tr>
                    <th>S. NO.</th>
                    <th>Document Title</th>
                    <th>Document Number</th>
                    <th>Rev. No.</th>
                    <th>Revision Date</th>
                    <th>Document</th>
                  </tr>
                </thead>

                <tbody>
                  {rowsA.map((row) => row)}
                    {Array.isArray(qmsTableLevel1) &&
                      filteredQmsTableLevel1.map((el_A, index) => (
                        <tr key={el_A.tracker_id}>

                        <td>{index + 1}</td>
                        <td>
                          {!(!showEditIconA || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_A.doc_Title}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_A.doc_Title ? (
                            el_A.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconA || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_A.doc_Number}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_A.doc_Number ? (
                            el_A.doc_Number
                          ) : (
                            "-"
                          )}

                        </td>

                        <td>
                          {!(!showEditIconA || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_A.Revision_no}
                                style={{ width: "30px" }}
                                // onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_A.Revision_no ? (
                            `Rev. ${el_A.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconA || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Revision_Date"
                              Value={el_A.Revision_date}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_A.Revision_date ? (
                            new Date(el_A.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                            {/* {!(!showEditIconA || editindex !== index) ? (
                              <div>
                                <input
                                  type="text"
                                  className={styles.inputFieldsm}
                                  name="Document"
                                  Value={el_A.Document}
                                  // onInput={(e) => handleInputChange(e, index)}
                                />
                              </div>
                            ) : el_A.Document ? (
                              el_A.Document
                            ) : (
                              "-"
                            )} */}
                          { el_A.Document === null ? (
                              "-"
                          ) : (
                          <div style={{display:"flex", justifyContent:"center"}}>
                            {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                              View File
                            </div> */}
                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                              <a href={el_A.Document} rel="noreferrer" target="_blank">
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                  </svg>
                              </a> 
                              {console.log(el_A.Document)}

                            </div>

                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                              onClick={() =>handleDownload(el_A.Document)}>
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                </svg>
                            </div>
                          </div>
                        )}
                        </td>
                      </tr>
                      ))}
                </tbody>
              </table>
            </div>

            <hr style={{height:"2px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>

            <div className={styles.levelHeader}>
              <h5>B. QUALITY SYSTEM PROCEDURES | LEVEL 2</h5>
            </div>

            <div className={styles.qmstablecontainer}>
              <table className={styles.qmstable}>
                <thead>
                  <tr>
                    <th>S. NO.</th>
                    <th>Document Title</th>
                    <th>Document Number</th>
                    <th>Rev. No.</th>
                    <th>Revision Date</th>
                    <th>Document</th>
                  </tr>
                </thead>

                <tbody>
                  {rowsB.map((row) => row)}
                    {Array.isArray(qmsTableLevel2) &&
                      filteredQmsTableLevel2.map((el_B, index) => (
                        <tr key={el_B.tracker_id}>

                        <td>{index + 1}</td>
                        <td>
                          {!(!showEditIconB || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_B.doc_Title}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_B.doc_Title ? (
                            el_B.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconB || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_B.doc_Number}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_B.doc_Number ? (
                            el_B.doc_Number
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconB || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_B.Revision_no}
                                style={{ width: "30px" }}
                                // onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_B.Revision_no ? (
                            `Rev. ${el_B.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconB || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Revision_date"
                              Value={el_B.Revision_date}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_B.Revision_date ? (
                            new Date(el_B.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
{/*                           {!(!showEditIconB || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Document"
                              Value={el_B.Document}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_B.Document ? (
                            el_B.Document
                          ) : (
                            "-"
                          )}
 */}
                          { el_B.Document === null ? (
                            "-"
                          ) : (

                          <div style={{display:"flex", justifyContent:"center"}}>
                            {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                              View File
                            </div> */}
                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                              <a href={el_B.Document} rel="noreferrer" target="_blank">
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                  </svg>
                              </a> 
                            </div>

                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                              onClick={() =>handleDownload(el_B.Document)}>
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                </svg>
                            </div>
                          </div>
                          )}
                        </td>
                      </tr>
                      ))}
                </tbody>
              </table>
            </div>

            <hr style={{height:"2px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>

            <div className={styles.levelHeader}>
              <h5>C. SERVICE REALIZATION PROCESS | LEVEL 3</h5>
            </div>

            <div className={styles.qmstablecontainer}>
              <table className={styles.qmstable}>
                <thead>
                  <tr>
                    <th>S. NO.</th>
                    <th>Document Title</th>
                    <th>Document Number</th>
                    <th>Rev. No.</th>
                    <th>Revision Date</th>
                    <th>Document</th>
                  </tr>
                </thead>

                <tbody>
                  {rowsC.map((row) => row)}
                    {Array.isArray(qmsTableLevel3) &&
                      filteredQmsTableLevel3.map((el_C, index) => (
                        <tr key={el_C.tracker_id}>

                        <td>{index + 1}</td>
                        <td>
                          {!(!showEditIconC || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_C.doc_Title}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_C.doc_Title ? (
                            el_C.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconC || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_C.doc_Number}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_C.doc_Number ? (
                            el_C.doc_Number
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconC || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_C.Revision_no}
                                style={{ width: "30px" }}
                                // onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_C.Revision_no ? (
                            `Rev. ${el_C.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconC || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Revision_date"
                              Value={el_C.Revision_date}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_C.Revision_date ? (
                            new Date(el_C.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
{/*                           {!(!showEditIconC || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Document"
                              Value={el_C.Document}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_C.Document ? (
                            el_C.Document
                          ) : (
                            "-"
                          )}
 */}
                          { el_C.Document === null ? (
                            "-"
                          ) : (
                          <div style={{display:"flex", justifyContent:"center"}}>
                            {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                              View File
                            </div> */}
                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                              <a href={el_C.Document} rel="noreferrer" target="_blank">
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                  </svg>
                              </a> 
                            </div>

                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                              onClick={() =>handleDownload(el_C.Document)}>
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                </svg>
                            </div>
                          </div>
                          )}
                        </td>
                      </tr>
                      ))}
                </tbody>
              </table>
            </div>

            <hr style={{height:"2px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>

            <div className={styles.levelHeader}>
              <h5>D. TEMPLATES, FORMS & REPORTS | LEVEL 4</h5>
            </div>

            <div className={styles.qmstablecontainer}>
              <table className={styles.qmstable}>
                <thead>
                  <tr>
                    <th>S. NO.</th>
                    <th>Document Title</th>
                    <th>Document Number</th>
                    <th>Rev. No.</th>
                    <th>Revision Date</th>
                    <th>Document</th>
                  </tr>
                </thead>

                <tbody>
                  {rowsD.map((row) => row)}
                    {Array.isArray(qmsTableLevel4) &&
                      filteredQmsTableLevel4.map((el_D, index) => (
                        <tr key={el_D.tracker_id}>

                        <td>{index + 1}</td>
                        <td>
                          {!(!showEditIconD || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_D.doc_Title}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_D.doc_Title ? (
                            el_D.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconD || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_D.doc_Number}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_D.doc_Number ? (
                            el_D.doc_Number
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconD || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_D.Revision_no}
                                style={{ width: "30px" }}
                                // onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_D.Revision_no ? (
                            `Rev. ${el_D.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconD || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Revision_date"
                              Value={el_D.Revision_date}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_D.Revision_date ? (
                            new Date(el_D.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
{/*                           {!(!showEditIconD || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Document"
                              Value={el_D.Document}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_D.Document ? (
                            el_D.Document
                          ) : (
                            "-"
                          )}
 */}
                           { el_D.Document === null ? (
                            "-"
                          ) : (
                          <div style={{display:"flex", justifyContent:"center"}}>
                            {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                              View File
                            </div> */}
                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                              <a href={el_D.Document} rel="noreferrer" target="_blank">
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                  </svg>
                              </a> 
                            </div>

                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                              onClick={() =>handleDownload(el_D.Document)}>
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                </svg>
                            </div>
                          </div>
                          )}
                        </td>
                      </tr>
                      ))}
                </tbody>
              </table>
            </div>

            <hr style={{height:"2px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>

            <div className={styles.levelHeader}>
              <h5>E. EXTERNAL DOCUMENTS | LEVEL 5</h5>
            </div>

            <div className={styles.qmstablecontainer}>
              <table className={styles.qmstable}>
                <thead>
                  <tr>
                    <th>S. NO.</th>
                    <th>Document Title</th>
                    <th>Document Number</th>
                    <th>Rev. No.</th>
                    <th>Revision Date</th>
                    <th>Document</th>
                  </tr>
                </thead>

                <tbody>
                  {rowsE.map((row) => row)}
                    {Array.isArray(qmsTableLevel5) &&
                      filteredQmsTableLevel5.map((el_E, index) => (
                        <tr key={el_E.tracker_id}>

                        <td>{index + 1}</td>
                        <td>
                          {!(!showEditIconE || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_E.doc_Title}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_E.doc_Title ? (
                            el_E.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconE || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_E.doc_Number}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_E.doc_Number ? (
                            el_E.doc_Number
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconE || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_E.Revision_no}
                                style={{ width: "30px" }}
                                // onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_E.Revision_no ? (
                            `Rev. ${el_E.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconE || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Revision_date"
                              Value={el_E.Revision_date}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_E.Revision_date ? (
                            new Date(el_E.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
{/*                           {!(!showEditIconE || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Document"
                              Value={el_E.Document}
                              // onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_E.Document ? (
                            el_E.Document
                          ) : (
                            "-"
                          )}
 */}
                           { el_E.Document === null ? (
                            "-"
                          ) : (
                          <div style={{display:"flex", justifyContent:"center"}}>
                            {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                              View File
                            </div> */}
                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                              <a href={el_E.Document} rel="noreferrer" target="_blank">
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                  </svg>
                              </a> 
                            </div>

                            <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                              onClick={() =>handleDownload(el_E.Document)}>
                                {/*<xml version="1.0" encoding="utf-8">*/}
                                <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                </svg>
                            </div>
                          </div>
                          )}
                        </td>
                      </tr>
                      ))}
                </tbody>
              </table>
            </div>

            <hr style={{height:"2px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>

        </div>

        ) : (

          <div className={styles.maindiv}>

            <div className={styles.buttonsdiv} >
              <button onClick={handleSaveClick} style={{ marginRight: "10px"}}>
                Save{" "}
              </button>
            
              <button onClick={handleCancelClick} style={{ background: "white", border: "1px solid #5B6BE1", color: "#5B6BE1", justifyContent: "center", padding: "5px", borderRadius: "5px"}}>
                Cancel{" "}
              </button>
            </div>

            <div className={styles.levelHeader}>
              <h5>A. QUALITY POLICY, QUALITY OBJECTIVES, QMS MANUAL | LEVEL 1</h5>
            </div>

            <div className={styles.qmstablecontainer}>
              <table className={styles.qmstable}>
                <thead>
                  <tr>
                    {editModesm ? (
                      <>
                        {/* <th></th> */}
                        <th>S. No.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                        {showeditRowA}
                        {showInputRowA}
                      </>
                    ) : (
                      <>
                        <th></th>
                        <th>S. NO.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {rowsA.map((row) => row)}
                  {Array.isArray(qmsTableLevel1) &&
                    filteredQmsTableLevel1.map((el_A, index) => (
                      <tr key={el_A.tracker_id}>
                        {showeditRowA && (
                          <td>
                            <div
                              className={styles.removeTxtBtn}
                              onClick={() => handleRemove(el_A.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none">
                                <path
                                  d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                  fill="#567191"
                                />
                              </svg>
                              Remove
                            </div>
                          </td>
                        )}
                        {/* {showInputRow && <td></td>} */}
                        {/* <td></td> */}
                        <td>
                          {!showEditIconA || editindex !== index ? (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={()=>handleeditsm1(index)}>
                                <path
                                  d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={() => handlecancelsm(el_A.doc_Number, el_A.doc_Title, el_A.doc_Level, el_A.tracker_id)}
                                style={{ marginLeft: "20px" }}>
                                <path
                                  d="M8 0C3.592 0 0 3.592 0 8C0 12.408 3.592 16 8 16C12.408 16 16 12.408 16 8C16 3.592 12.408 0 8 0ZM10.688 9.84C10.92 10.072 10.92 10.456 10.688 10.688C10.568 10.808 10.416 10.864 10.264 10.864C10.112 10.864 9.96 10.808 9.84 10.688L8 8.848L6.16 10.688C6.04 10.808 5.888 10.864 5.736 10.864C5.584 10.864 5.432 10.808 5.312 10.688C5.08 10.456 5.08 10.072 5.312 9.84L7.152 8L5.312 6.16C5.08 5.928 5.08 5.544 5.312 5.312C5.544 5.08 5.928 5.08 6.16 5.312L8 7.152L9.84 5.312C10.072 5.08 10.456 5.08 10.688 5.312C10.92 5.544 10.92 5.928 10.688 6.16L8.848 8L10.688 9.84Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="24"
                                viewBox="0 0 26 24"
                                fill="none"
                                onClick={(e) => handletick(e, el_A.doc_Number, el_A.doc_Title, el_A.doc_Level, el_A.tracker_id)}>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                                  fill="#66D171"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={handleshift}>
                                <path
                                  d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                  fill="#F97575"
                                />
                                <path
                                  d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                  fill="#F97575"
                                />
                              </svg>
                            </div>
                          )}
                        </td>
                        <td>{index + 1}</td>

                        <td>
                          {!(!showEditIconA || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_A.doc_Title}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_A.doc_Title ? (
                            el_A.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconA || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_A.doc_Number}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_A.doc_Number ? (
                            el_A.doc_Number
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconA || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_A.Revision_no}
                                style={{ width: "30px" }}
                                onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_A.Revision_no ? (
                            `Rev. ${el_A.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconA || editindex !== index) ? (
                            <input
                              type="date"
                              className={styles.inputFieldsm}
                              name="Revision_date"
                              Value={el_A.Revision_date}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_A.Revision_date ? (
                            new Date(el_A.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconA || editindex !== index) ? (
                            <div>
      {/*                       <input
                                    type="text"
                                    className={styles.inputFieldsm}
                                    name="Document"
                                    Value={el_A.Document}
                                    onInput={(e) => handleInputChange(e, index)}
                                  />
                                ) : el_A.Document ? (
                                  el_A.Document
                                ) : (
                                  "-"
                                )}
      */}                     
                              <div style={{ display: "inline-flex", marginLeft: "0px" }}>
                                <input
                                  type="file"
                                  name="QMS"
                                  id="QMS"
                                  onInput={(e) => handleFileChange("QMS", e, el_A.doc_Number)}
                                  //Value={el_A?.Document ? el_A.Document : "Upload File"}
                                style={{
                                    display: "none",
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                  }}
                                />
                                  {console.log(el_A)}
                                <div
                                  style={{
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "10px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() =>
                                    document.getElementById("QMS").click()
                                  }>
                                  {el_A?.Document
                                    ? el_A?.Document?.toString()
                                        .split("/")
                                        .pop()
                                        .split("-")
                                        .slice(1)
                                        .join("-")
                                    : tempQMS
                                    ? tempQMS
                                    : "Upload File"}
                                </div>
                                  <div>
                                    <img src = "crossmark" alt=''/>
                                </div>
                              </div>
                            </div>
                          ) : (
                            el_A.Document === null ? (
                              "-"
                            ) : (
  
                            <div style={{display:"flex", justifyContent:"center"}}>
                              {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                                View File
                              </div> */}
                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                                <a href={el_A.Document} rel="noreferrer" target="_blank">
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                    <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                    </svg>
                                </a> 
                              </div>

                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                                onClick={() =>handleDownload(el_A.Document)}>
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                  </svg>
                              </div>
                            </div>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)} // Close the modal when clicked outside
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    slots={{ backdrop: CustomBackdrop }}
                    /* slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                    sx={{
                      backdropFilter: "blur(3px) opacity(0.6)",
                    }} */
                    >
                    {/* Modal content goes here */}
                    <Fade in={isModalOpen}>
                      <Box
                        className={styles.ModalBox}
                        sx={{
                          width: 700,
                          height: 300,
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: 10,
                          backgroundColor: "#fff",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                          borderRadius: "8px",
                        }}>
                        <label variant="h6" component="h2" className={styles.mstyl}>
                          Are you sure you want to remove this row?
                        </label>
                        <button
                          style={{
                            marginLeft: "120px",
                          }}
                          className={styles.btnyes}
                          onClick={handleYesClick}>
                          Yes
                        </button>
                        <button className={styles.btnyes} onClick={handleNoClick} style={{ background: "white", border: "2px solid #5B6BE1", color: "#5B6BE1"}}>
                          No
                        </button>
                      </Box>
                    </Fade>
                  </Modal>

                  {showInputRowA && (
                    <tr key={rowsA.length} className={styles.addinputspares}>
                      <td className={styles.tickcross}>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={(e) => handleSubmitTickA(e)}>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                            fill="#66D171"
                          />
                        </svg>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={handleDeleteRowA}>
                          <path
                            d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                            fill="#F97575"
                          />
                          <path
                            d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                            fill="#F97575"
                          />
                        </svg>
                      </td>
                      <td></td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Title"
                          value={formDatasm.doc_Title}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Number"
                          value={formDatasm.doc_Number}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <div>
                          Rev.{' '}
                          <input
                            type="number"
                            pattern="[0-9]*"
                            className={styles.inputFieldsm}
                            name="Revision_no"
                            value={formDatasm.Revision_no}
                            onInput={handleInputChangevis}
                            style={{ width: "30px" }}
                          />
                        </div>
                      </td>

                      <td>
                        <input
                          type="date"
                          className={styles.inputFieldsm}
                          name="Revision_date"
                          value={formDatasm.Revision_date}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
{/*                         <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="Document"
                          value={formDatasm.Document}
                          onInput={handleInputChangevis}
                        />
 */}                                                  
                        <div
                          style={{ display: "inline-flex", marginLeft: "0px" }}>
                          <input
                            type="file"
                            name="QMS"
                            id="QMS"
                            onInput={(e) => handleFileChange("QMS", e, formDatasm.doc_Number)}
                            // value={doc?.QMS ? getFileNameFromURL(doc?.QMS) : "Upload File"}
                            style={{
                              display: "none",
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          />
                          <div
                            style={{
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() =>
                              document.getElementById("QMS").click()
                            }>
                            {qmsTableLevel1[0]?.QMS
                              ? qmsTableLevel1[0]?.QMS?.toString()
                                  .split("/")
                                  .pop()
                                  .split("-")
                                  .slice(1)
                                  .join("-")
                              : tempQMS
                              ? tempQMS
                              : "Upload File"}
                          </div>
                        </div>

                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>

            <div 
              className={styles.buttonsdiv} 
              style={{ width: "90vw", marginTop: "0px", justifyContent: "flex-start"}}
            >
              <button onClick={inputdetailsmanually_A} style={{ width: "100%", margin: "0"}} disabled={isButtonDisabled}>
                Add New Item{" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.4915 1.66602H6.50817C3.47484 1.66602 1.6665 3.47435 1.6665 6.50768V13.4827C1.6665 16.5243 3.47484 18.3327 6.50817 18.3327H13.4832C16.5165 18.3327 18.3248 16.5243 18.3248 13.491V6.50768C18.3332 3.47435 16.5248 1.66602 13.4915 1.66602ZM14.9998 10.6243H10.6248V14.9993C10.6248 15.341 10.3415 15.6243 9.99984 15.6243C9.65817 15.6243 9.37484 15.341 9.37484 14.9993V10.6243H4.99984C4.65817 10.6243 4.37484 10.341 4.37484 9.99935C4.37484 9.65768 4.65817 9.37435 4.99984 9.37435H9.37484V4.99935C9.37484 4.65768 9.65817 4.37435 9.99984 4.37435C10.3415 4.37435 10.6248 4.65768 10.6248 4.99935V9.37435H14.9998C15.3415 9.37435 15.6248 9.65768 15.6248 9.99935C15.6248 10.341 15.3415 10.6243 14.9998 10.6243Z"
                    fill={isButtonDisabled ? '#5B6BE1' : 'white'}
                  />
                </svg>
              </button>
            </div>

            <hr style={{height:"25px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>

            <div className={styles.levelHeader}>
              <h5>B. QUALITY SYSTEM PROCEDURES | LEVEL 2</h5>
            </div>

            <div className={styles.qmstablecontainer}>
            <table className={styles.qmstable}>
                <thead>
                  <tr>
                    {editModesm ? (
                      <>
                        {/* <th></th> */}
                        <th>S. No.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                        {showeditRowB}
                        {showInputRowB}
                      </>
                    ) : (
                      <>
                        <th></th>
                        <th>S. NO.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {rowsB.map((row) => row)}
                  {Array.isArray(qmsTableLevel2) &&
                    filteredQmsTableLevel2.map((el_B, index) => (
                      <tr key={el_B.tracker_id}>
                        {showeditRowB && (
                          <td>
                            <div
                              className={styles.removeTxtBtn}
                              onClick={() => handleRemove(el_B.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none">
                                <path
                                  d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                  fill="#567191"
                                />
                              </svg>
                              Remove
                            </div>
                          </td>
                        )}
                        {/* {showInputRow && <td></td>} */}
                        {/* <td></td> */}
                        <td>
                          {!showEditIconB || editindex !== index ? (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={()=>handleeditsm2(index)}>
                                <path
                                  d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={() => handlecancelsm(el_B.doc_Number, el_B.doc_Title, el_B.doc_Level, el_B.tracker_id)}
                                style={{ marginLeft: "20px" }}>
                                <path
                                  d="M8 0C3.592 0 0 3.592 0 8C0 12.408 3.592 16 8 16C12.408 16 16 12.408 16 8C16 3.592 12.408 0 8 0ZM10.688 9.84C10.92 10.072 10.92 10.456 10.688 10.688C10.568 10.808 10.416 10.864 10.264 10.864C10.112 10.864 9.96 10.808 9.84 10.688L8 8.848L6.16 10.688C6.04 10.808 5.888 10.864 5.736 10.864C5.584 10.864 5.432 10.808 5.312 10.688C5.08 10.456 5.08 10.072 5.312 9.84L7.152 8L5.312 6.16C5.08 5.928 5.08 5.544 5.312 5.312C5.544 5.08 5.928 5.08 6.16 5.312L8 7.152L9.84 5.312C10.072 5.08 10.456 5.08 10.688 5.312C10.92 5.544 10.92 5.928 10.688 6.16L8.848 8L10.688 9.84Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="24"
                                viewBox="0 0 26 24"
                                fill="none"
                                onClick={(e) => handletick(e, el_B.doc_Number, el_B.doc_Title, el_B.doc_Level, el_B.tracker_id)}>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                                  fill="#66D171"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={handleshift}>
                                <path
                                  d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                  fill="#F97575"
                                />
                                <path
                                  d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                  fill="#F97575"
                                />
                              </svg>
                            </div>
                          )}
                        </td>
                        <td>{index + 1}</td>
                        <td>
                          {!(!showEditIconB || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_B.doc_Title}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_B.doc_Title ? (
                            el_B.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconB || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_B.doc_Number}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_B.doc_Number ? (
                            el_B.doc_Number
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconB || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_B.Revision_no}
                                style={{ width: "30px" }}
                                onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_B.Revision_no ? (
                            `Rev. ${el_B.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconB || editindex !== index) ? (
                            <input
                              type="date"
                              className={styles.inputFieldsm}
                              name="Revision_date"
                              Value={el_B.Revision_date}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_B.Revision_date ? (
                            new Date(el_B.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                        {!(!showEditIconB || editindex !== index) ? (
                            <div>
                              <div style={{ display: "inline-flex", marginLeft: "0px" }}>
                                <input
                                  type="file"
                                  name="QMS"
                                  id="QMS"
                                  onInput={(e) => handleFileChange("QMS", e, el_B.doc_Number)}
                                  //Value={el_B?.Document ? el_B.Document : "Upload File"}
                                style={{
                                    display: "none",
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                  }}
                                />
                                <div
                                  style={{
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() =>
                                    document.getElementById("QMS").click()
                                  }>
                                  {el_B?.Document
                                    ? el_B?.Document?.toString()
                                        .split("/")
                                        .pop()
                                        .split("-")
                                        .slice(1)
                                        .join("-")
                                    : tempQMS
                                    ? tempQMS
                                    : "Upload File"}
                                </div>
                                  <div>
                                    <img src = "crossmark" alt=''/>
                                </div>
                              </div>
                            </div>
                          ) : (
                            el_B.Document === null ? (
                              "-"
                            ) : (
  
                            <div style={{display:"flex", justifyContent:"center"}}>
                              {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                                View File
                              </div> */}
                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                                <a href={el_B.Document} rel="noreferrer" target="_blank">
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                    <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                    </svg>
                                </a> 
                              </div>

                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                                onClick={() =>handleDownload(el_B.Document)}>
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                  </svg>
                              </div>
                            </div>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)} // Close the modal when clicked outside
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    slots={{ backdrop: CustomBackdrop }}
                    /* slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                    sx={{
                      backdropFilter: "blur(3px) opacity(0.6)",
                    }} */
                    >
                    {/* Modal content goes here */}
                    <Fade in={isModalOpen}>
                      <Box
                        className={styles.ModalBox}
                        sx={{
                          width: 700,
                          height: 300,
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: 10,
                          backgroundColor: "#fff",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                          borderRadius: "8px",
                        }}>
                        <label variant="h6" component="h2" className={styles.mstyl}>
                          Are you sure you want to remove this row?
                        </label>
                        <button
                          style={{
                            marginLeft: "120px",
                          }}
                          className={styles.btnyes}
                          onClick={handleYesClick}>
                          Yes
                        </button>
                        <button className={styles.btnyes} onClick={handleNoClick} style={{ background: "white", border: "2px solid #5B6BE1", color: "#5B6BE1"}}>
                          No
                        </button>
                      </Box>
                    </Fade>
                  </Modal>

                  {showInputRowB && (
                    <tr key={rowsB.length} className={styles.addinputspares}>
                      <td className={styles.tickcross}>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={(e) => handleSubmitTickB(e)}>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                            fill="#66D171"
                          />
                        </svg>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={handleDeleteRowB}>
                          <path
                            d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                            fill="#F97575"
                          />
                          <path
                            d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                            fill="#F97575"
                          />
                        </svg>
                      </td>
                      <td></td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Title"
                          value={formDatasm.doc_Title}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Number"
                          value={formDatasm.doc_Number}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <div>
                          Rev.{' '}
                          <input
                            type="number"
                            pattern="[0-9]*"
                            className={styles.inputFieldsm}
                            name="Revision_no"
                            value={formDatasm.Revision_no}
                            onInput={handleInputChangevis}
                            style={{ width: "30px" }}
                          />
                        </div>
                      </td>

                      <td>
                        <input
                          type="date"
                          className={styles.inputFieldsm}
                          name="Revision_date"
                          value={formDatasm.Revision_date}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <div
                          style={{ display: "inline-flex", marginLeft: "0px" }}>
                          <input
                            type="file"
                            name="QMS"
                            id="QMS"
                            onInput={(e) => handleFileChange("QMS", e, formDatasm.doc_Number)}
                            // value={doc?.QMS ? getFileNameFromURL(doc?.QMS) : "Upload File"}
                            style={{
                              display: "none",
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          />
                          <div
                            style={{
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() =>
                              document.getElementById("QMS").click()
                            }>
                            {qmsTableLevel2[0]?.QMS
                              ? qmsTableLevel2[0]?.QMS?.toString()
                                  .split("/")
                                  .pop()
                                  .split("-")
                                  .slice(1)
                                  .join("-")
                              : tempQMS
                              ? tempQMS
                              : "Upload File"}
                          </div>
                        </div>

                      </td>


                    </tr>
                  )}

                </tbody>
              </table>            
              </div>

            <div 
              className={styles.buttonsdiv} 
              style={{ width: "90vw", marginTop: "0px", justifyContent: "flex-start"}}
            >
              <button onClick={inputdetailsmanually_B} style={{ width: "100%", margin: "0"}} disabled={isButtonDisabled}>
                Add New Item{" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.4915 1.66602H6.50817C3.47484 1.66602 1.6665 3.47435 1.6665 6.50768V13.4827C1.6665 16.5243 3.47484 18.3327 6.50817 18.3327H13.4832C16.5165 18.3327 18.3248 16.5243 18.3248 13.491V6.50768C18.3332 3.47435 16.5248 1.66602 13.4915 1.66602ZM14.9998 10.6243H10.6248V14.9993C10.6248 15.341 10.3415 15.6243 9.99984 15.6243C9.65817 15.6243 9.37484 15.341 9.37484 14.9993V10.6243H4.99984C4.65817 10.6243 4.37484 10.341 4.37484 9.99935C4.37484 9.65768 4.65817 9.37435 4.99984 9.37435H9.37484V4.99935C9.37484 4.65768 9.65817 4.37435 9.99984 4.37435C10.3415 4.37435 10.6248 4.65768 10.6248 4.99935V9.37435H14.9998C15.3415 9.37435 15.6248 9.65768 15.6248 9.99935C15.6248 10.341 15.3415 10.6243 14.9998 10.6243Z"
                    fill={isButtonDisabled ? '#5B6BE1' : 'white'}
                  />
                </svg>
              </button>
            </div>

            <hr style={{height:"25px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>
            
            <div className={styles.levelHeader}>
              <h5>C. SERVICE REALIZATION PROCESS | LEVEL 3</h5>
            </div>

            <div className={styles.qmstablecontainer}>
            <table className={styles.qmstable}>
                <thead>
                  <tr>
                    {editModesm ? (
                      <>
                        {/* <th></th> */}
                        <th>S. No.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                        {showeditRowC}
                        {showInputRowC}
                      </>
                    ) : (
                      <>
                        <th></th>
                        <th>S. NO.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {rowsC.map((row) => row)}
                  {Array.isArray(qmsTableLevel3) &&
                    filteredQmsTableLevel3.map((el_C, index) => (
                      <tr key={el_C.tracker_id}>
                        {showeditRowC && (
                          <td>
                            <div
                              className={styles.removeTxtBtn}
                              onClick={() => handleRemove(el_C.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none">
                                <path
                                  d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                  fill="#567191"
                                />
                              </svg>
                              Remove
                            </div>
                          </td>
                        )}
                        {/* {showInputRow && <td></td>} */}
                        {/* <td></td> */}
                        <td>
                          {!showEditIconC || editindex !== index ? (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={()=>handleeditsm3(index)}>
                                <path
                                  d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={() => handlecancelsm(el_C.doc_Number, el_C.doc_Title, el_C.doc_Level, el_C.tracker_id)}
                                style={{ marginLeft: "20px" }}>
                                <path
                                  d="M8 0C3.592 0 0 3.592 0 8C0 12.408 3.592 16 8 16C12.408 16 16 12.408 16 8C16 3.592 12.408 0 8 0ZM10.688 9.84C10.92 10.072 10.92 10.456 10.688 10.688C10.568 10.808 10.416 10.864 10.264 10.864C10.112 10.864 9.96 10.808 9.84 10.688L8 8.848L6.16 10.688C6.04 10.808 5.888 10.864 5.736 10.864C5.584 10.864 5.432 10.808 5.312 10.688C5.08 10.456 5.08 10.072 5.312 9.84L7.152 8L5.312 6.16C5.08 5.928 5.08 5.544 5.312 5.312C5.544 5.08 5.928 5.08 6.16 5.312L8 7.152L9.84 5.312C10.072 5.08 10.456 5.08 10.688 5.312C10.92 5.544 10.92 5.928 10.688 6.16L8.848 8L10.688 9.84Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="24"
                                viewBox="0 0 26 24"
                                fill="none"
                                onClick={(e) => handletick(e, el_C.doc_Number, el_C.doc_Title, el_C.doc_Level, el_C.tracker_id)}>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                                  fill="#66D171"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={handleshift}>
                                <path
                                  d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                  fill="#F97575"
                                />
                                <path
                                  d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                  fill="#F97575"
                                />
                              </svg>
                            </div>
                          )}
                        </td>
                        <td>{index + 1}</td>

                        <td>
                          {!(!showEditIconC || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_C.doc_Title}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_C.doc_Title ? (
                            el_C.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconC || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_C.doc_Number}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_C.doc_Number ? (
                            el_C.doc_Number
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconC || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_C.Revision_no}
                                style={{ width: "30px" }}
                                onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_C.Revision_no ? (
                            `Rev. ${el_C.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconC || editindex !== index) ? (
                            <input
                              type="date"
                              className={styles.inputFieldsm}
                              name="Revision_date"
                              Value={el_C.Revision_date}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_C.Revision_date ? (
                            new Date(el_C.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                        {!(!showEditIconC || editindex !== index) ? (
                            <div>
                              <div style={{ display: "inline-flex", marginLeft: "0px" }}>
                                <input
                                  type="file"
                                  name="QMS"
                                  id="QMS"
                                  onInput={(e) => handleFileChange("QMS", e, el_C.doc_Number)}
                                  //Value={el_C?.Document ? el_C.Document : "Upload File"}
                                style={{
                                    display: "none",
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                  }}
                                />
                                <div
                                  style={{
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() =>
                                    document.getElementById("QMS").click()
                                  }>
                                  {el_C?.Document
                                    ? el_C?.Document?.toString()
                                        .split("/")
                                        .pop()
                                        .split("-")
                                        .slice(1)
                                        .join("-")
                                    : tempQMS
                                    ? tempQMS
                                    : "Upload File"}
                                </div>
                                  <div>
                                    <img src = "crossmark" alt=''/>
                                </div>
                              </div>
                            </div>
                          ) : (
                            el_C.Document === null ? (
                              "-"
                            ) : (
  
                            <div style={{display:"flex", justifyContent:"center"}}>
                              {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                                View File
                              </div> */}
                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                                <a href={el_C.Document} rel="noreferrer" target="_blank">
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                    <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                    </svg>
                                </a> 
                              </div>

                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                                onClick={() =>handleDownload(el_C.Document)}>
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                  </svg>
                              </div>
                            </div>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)} // Close the modal when clicked outside
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    slots={{ backdrop: CustomBackdrop }}
                    /* slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                    sx={{
                      backdropFilter: "blur(3px) opacity(0.6)",
                    }} */
                    >
                    {/* Modal content goes here */}
                    <Fade in={isModalOpen}>
                      <Box
                        className={styles.ModalBox}
                        sx={{
                          width: 700,
                          height: 300,
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: 10,
                          backgroundColor: "#fff",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                          borderRadius: "8px",
                        }}>
                        <label variant="h6" component="h2" className={styles.mstyl}>
                          Are you sure you want to remove this row?
                        </label>
                        <button
                          style={{
                            marginLeft: "120px",
                          }}
                          className={styles.btnyes}
                          onClick={handleYesClick}>
                          Yes
                        </button>
                        <button className={styles.btnyes} onClick={handleNoClick} style={{ background: "white", border: "2px solid #5B6BE1", color: "#5B6BE1"}}>
                          No
                        </button>
                      </Box>
                    </Fade>
                  </Modal>

                  {showInputRowC && (
                    <tr key={rowsC.length} className={styles.addinputspares}>
                      <td className={styles.tickcross}>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={(e) => handleSubmitTickC(e)}>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                            fill="#66D171"
                          />
                        </svg>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={handleDeleteRowC}>
                          <path
                            d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                            fill="#F97575"
                          />
                          <path
                            d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                            fill="#F97575"
                          />
                        </svg>
                      </td>
                      <td></td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Title"
                          value={formDatasm.doc_Title}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Number"
                          value={formDatasm.doc_Number}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <div>
                          Rev.{' '}
                          <input
                            type="number"
                            pattern="[0-9]*"
                            className={styles.inputFieldsm}
                            name="Revision_no"
                            value={formDatasm.Revision_no}
                            onInput={handleInputChangevis}
                            style={{ width: "30px" }}
                          />
                        </div>
                      </td>

                      <td>
                        <input
                          type="date"
                          className={styles.inputFieldsm}
                          name="Revision_date"
                          value={formDatasm.Revision_date}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                      <div
                          style={{ display: "inline-flex", marginLeft: "0px" }}>
                          <input
                            type="file"
                            name="QMS"
                            id="QMS"
                            onInput={(e) => handleFileChange("QMS", e, formDatasm.doc_Number)}
                            // value={doc?.QMS ? getFileNameFromURL(doc?.QMS) : "Upload File"}
                            style={{
                              display: "none",
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          />
                          <div
                            style={{
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() =>
                              document.getElementById("QMS").click()
                            }>
                            {qmsTableLevel3[0]?.QMS
                              ? qmsTableLevel3[0]?.QMS?.toString()
                                  .split("/")
                                  .pop()
                                  .split("-")
                                  .slice(1)
                                  .join("-")
                              : tempQMS
                              ? tempQMS
                              : "Upload File"}
                          </div>
                          </div>

                      </td>
                    </tr>
                  )}

                </tbody>
            </table>
            </div>

            <div 
              className={styles.buttonsdiv} 
              style={{ width: "90vw", marginTop: "0px", justifyContent: "flex-start"}}
            >
              <button onClick={inputdetailsmanually_C} style={{ width: "100%", margin: "0"}} disabled={isButtonDisabled}>
                Add New Item{" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.4915 1.66602H6.50817C3.47484 1.66602 1.6665 3.47435 1.6665 6.50768V13.4827C1.6665 16.5243 3.47484 18.3327 6.50817 18.3327H13.4832C16.5165 18.3327 18.3248 16.5243 18.3248 13.491V6.50768C18.3332 3.47435 16.5248 1.66602 13.4915 1.66602ZM14.9998 10.6243H10.6248V14.9993C10.6248 15.341 10.3415 15.6243 9.99984 15.6243C9.65817 15.6243 9.37484 15.341 9.37484 14.9993V10.6243H4.99984C4.65817 10.6243 4.37484 10.341 4.37484 9.99935C4.37484 9.65768 4.65817 9.37435 4.99984 9.37435H9.37484V4.99935C9.37484 4.65768 9.65817 4.37435 9.99984 4.37435C10.3415 4.37435 10.6248 4.65768 10.6248 4.99935V9.37435H14.9998C15.3415 9.37435 15.6248 9.65768 15.6248 9.99935C15.6248 10.341 15.3415 10.6243 14.9998 10.6243Z"
                    fill={isButtonDisabled ? '#5B6BE1' : 'white'}
                  />
                </svg>
              </button>
            </div>

            <hr style={{height:"25px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>
            
            <div className={styles.levelHeader}>
              <h5>D. TEMPLATES, FORMS & REPORTS | LEVEL 4</h5>
            </div>

            <div className={styles.qmstablecontainer}>
            <table className={styles.qmstable}>
                <thead>
                  <tr>
                    {editModesm ? (
                      <>
                        {/* <th></th> */}
                        <th>S. No.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                        {showeditRowD}
                        {showInputRowD}
                      </>
                    ) : (
                      <>
                        <th></th>
                        <th>S. NO.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {rowsD.map((row) => row)}
                  {Array.isArray(qmsTableLevel4) &&
                    filteredQmsTableLevel4.map((el_D, index) => (
                      <tr key={el_D.tracker_id}>
                        {showeditRowD && (
                          <td>
                            <div
                              className={styles.removeTxtBtn}
                              onClick={() => handleRemove(el_D.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none">
                                <path
                                  d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                  fill="#567191"
                                />
                              </svg>
                              Remove
                            </div>
                          </td>
                        )}
                        {/* {showInputRow && <td></td>} */}
                        {/* <td></td> */}
                        <td>
                          {!showEditIconD || editindex !== index ? (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={()=>handleeditsm4(index)}>
                                <path
                                  d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={() => handlecancelsm(el_D.doc_Number, el_D.doc_Title, el_D.doc_Level, el_D.tracker_id)}
                                style={{ marginLeft: "20px" }}>
                                <path
                                  d="M8 0C3.592 0 0 3.592 0 8C0 12.408 3.592 16 8 16C12.408 16 16 12.408 16 8C16 3.592 12.408 0 8 0ZM10.688 9.84C10.92 10.072 10.92 10.456 10.688 10.688C10.568 10.808 10.416 10.864 10.264 10.864C10.112 10.864 9.96 10.808 9.84 10.688L8 8.848L6.16 10.688C6.04 10.808 5.888 10.864 5.736 10.864C5.584 10.864 5.432 10.808 5.312 10.688C5.08 10.456 5.08 10.072 5.312 9.84L7.152 8L5.312 6.16C5.08 5.928 5.08 5.544 5.312 5.312C5.544 5.08 5.928 5.08 6.16 5.312L8 7.152L9.84 5.312C10.072 5.08 10.456 5.08 10.688 5.312C10.92 5.544 10.92 5.928 10.688 6.16L8.848 8L10.688 9.84Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="24"
                                viewBox="0 0 26 24"
                                fill="none"
                                onClick={(e) => handletick(e, el_D.doc_Number, el_D.doc_Title, el_D.doc_Level, el_D.tracker_id)}>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                                  fill="#66D171"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={handleshift}>
                                <path
                                  d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                  fill="#F97575"
                                />
                                <path
                                  d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                  fill="#F97575"
                                />
                              </svg>
                            </div>
                          )}
                        </td>
                        <td>{index + 1}</td>

                        <td>
                          {!(!showEditIconD || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_D.doc_Title}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_D.doc_Title ? (
                            el_D.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconD || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_D.doc_Number}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_D.doc_Number ? (
                            el_D.doc_Number
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconD || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_D.Revision_no}
                                style={{ width: "30px" }}
                                onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_D.Revision_no ? (
                            `Rev. ${el_D.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconD || editindex !== index) ? (
                            <input
                              type="date"
                              className={styles.inputFieldsm}
                              name="Revision_date"
                              Value={el_D.Revision_date}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_D.Revision_date ? (
                            new Date(el_D.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                        {!(!showEditIconD || editindex !== index) ? (
                            <div>
                              <div style={{ display: "inline-flex", marginLeft: "0px" }}>
                                <input
                                  type="file"
                                  name="QMS"
                                  id="QMS"
                                  onInput={(e) => handleFileChange("QMS", e, el_D.doc_Number)}
                                  //Value={el_D?.Document ? el_D.Document : "Upload File"}
                                style={{
                                    display: "none",
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                  }}
                                />
                                <div
                                  style={{
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() =>
                                    document.getElementById("QMS").click()
                                  }>
                                  {el_D?.Document
                                    ? el_D?.Document?.toString()
                                        .split("/")
                                        .pop()
                                        .split("-")
                                        .slice(1)
                                        .join("-")
                                    : tempQMS
                                    ? tempQMS
                                    : "Upload File"}
                                </div>
                                  <div>
                                    <img src = "crossmark" alt=''/>
                                </div>
                              </div>
                            </div>
                          ) : (
                            el_D.Document === null ? (
                              "-"
                            ) : (
  
                            <div style={{display:"flex", justifyContent:"center"}}>
                              {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                                View File
                              </div> */}
                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                                <a href={el_D.Document} rel="noreferrer" target="_blank">
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                    <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                    </svg>
                                </a> 
                              </div>

                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                                onClick={() =>handleDownload(el_D.Document)}>
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                  </svg>
                              </div>
                            </div>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)} // Close the modal when clicked outside
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    slots={{ backdrop: CustomBackdrop }}
                    /* slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                    sx={{
                      backdropFilter: "blur(3px) opacity(0.6)",
                    }} */
                    >
                    {/* Modal content goes here */}
                    <Fade in={isModalOpen}>
                      <Box
                        className={styles.ModalBox}
                        sx={{
                          width: 700,
                          height: 300,
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: 10,
                          backgroundColor: "#fff",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                          borderRadius: "8px",
                        }}>
                        <label variant="h6" component="h2" className={styles.mstyl}>
                          Are you sure you want to remove this row?
                        </label>
                        <button
                          style={{
                            marginLeft: "120px",
                          }}
                          className={styles.btnyes}
                          onClick={handleYesClick}>
                          Yes
                        </button>
                        <button className={styles.btnyes} onClick={handleNoClick} style={{ background: "white", border: "2px solid #5B6BE1", color: "#5B6BE1"}}>
                          No
                        </button>
                      </Box>
                    </Fade>
                  </Modal>

                  {showInputRowD && (
                    <tr key={rowsD.length} className={styles.addinputspares}>
                      <td className={styles.tickcross}>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={(e) => handleSubmitTickD(e)}>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                            fill="#66D171"
                          />
                        </svg>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={handleDeleteRowD}>
                          <path
                            d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                            fill="#F97575"
                          />
                          <path
                            d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                            fill="#F97575"
                          />
                        </svg>
                      </td>
                      <td></td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Title"
                          value={formDatasm.doc_Title}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Number"
                          value={formDatasm.doc_Number}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <div>
                          Rev.{' '}
                          <input
                            type="number"
                            pattern="[0-9]*"
                            className={styles.inputFieldsm}
                            name="Revision_no"
                            value={formDatasm.Revision_no}
                            onInput={handleInputChangevis}
                            style={{ width: "30px" }}
                          />
                        </div>
                      </td>

                      <td>
                        <input
                          type="date"
                          className={styles.inputFieldsm}
                          name="Revision_date"
                          value={formDatasm.Revision_date}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                      <div
                          style={{ display: "inline-flex", marginLeft: "0px" }}>
                          <input
                            type="file"
                            name="QMS"
                            id="QMS"
                            onInput={(e) => handleFileChange("QMS", e, formDatasm.doc_Number)}
                            // value={doc?.QMS ? getFileNameFromURL(doc?.QMS) : "Upload File"}
                            style={{
                              display: "none",
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          />
                          <div
                            style={{
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() =>
                              document.getElementById("QMS").click()
                            }>
                            {qmsTableLevel4[0]?.QMS
                              ? qmsTableLevel4[0]?.QMS?.toString()
                                  .split("/")
                                  .pop()
                                  .split("-")
                                  .slice(1)
                                  .join("-")
                              : tempQMS
                              ? tempQMS
                              : "Upload File"}
                          </div>
                          </div>

                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>

            <div 
              className={styles.buttonsdiv} 
              style={{ width: "90vw", marginTop: "0px", justifyContent: "flex-start"}}
            >
              <button onClick={inputdetailsmanually_D} style={{ width: "100%", margin: "0"}} disabled={isButtonDisabled}>
                Add New Item{" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.4915 1.66602H6.50817C3.47484 1.66602 1.6665 3.47435 1.6665 6.50768V13.4827C1.6665 16.5243 3.47484 18.3327 6.50817 18.3327H13.4832C16.5165 18.3327 18.3248 16.5243 18.3248 13.491V6.50768C18.3332 3.47435 16.5248 1.66602 13.4915 1.66602ZM14.9998 10.6243H10.6248V14.9993C10.6248 15.341 10.3415 15.6243 9.99984 15.6243C9.65817 15.6243 9.37484 15.341 9.37484 14.9993V10.6243H4.99984C4.65817 10.6243 4.37484 10.341 4.37484 9.99935C4.37484 9.65768 4.65817 9.37435 4.99984 9.37435H9.37484V4.99935C9.37484 4.65768 9.65817 4.37435 9.99984 4.37435C10.3415 4.37435 10.6248 4.65768 10.6248 4.99935V9.37435H14.9998C15.3415 9.37435 15.6248 9.65768 15.6248 9.99935C15.6248 10.341 15.3415 10.6243 14.9998 10.6243Z"
                    fill={isButtonDisabled ? '#5B6BE1' : 'white'}
                  />
                </svg>
              </button>
            </div>

            <hr style={{height:"25px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>
                        
            <div className={styles.levelHeader}>
              <h5>E. EXTERNAL DOCUMENTS | LEVEL 5</h5>
            </div>

            <div className={styles.qmstablecontainer}>
            <table className={styles.qmstable}>
                <thead>
                  <tr>
                    {editModesm ? (
                      <>
                        {/* <th></th> */}
                        <th>S. No.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                        {showeditRowE}
                        {showInputRowE}
                      </>
                    ) : (
                      <>
                        <th></th>
                        <th>S. NO.</th>
                        <th>Document Title</th>
                        <th>Document Number</th>
                        <th>Rev. No.</th>
                        <th>Revision Date</th>
                        <th>Document</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {rowsE.map((row) => row)}
                  {Array.isArray(qmsTableLevel5) &&
                    filteredQmsTableLevel5.map((el_E, index) => (
                      <tr key={el_E.tracker_id}>
                        {showeditRowE && (
                          <td>
                            <div
                              className={styles.removeTxtBtn}
                              onClick={() => handleRemove(el_E.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none">
                                <path
                                  d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                  fill="#567191"
                                />
                              </svg>
                              Remove
                            </div>
                          </td>
                        )}
                        {/* {showInputRow && <td></td>} */}
                        {/* <td></td> */}
                        <td>
                          {!showEditIconE || editindex !== index ? (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={()=>handleeditsm5(index)}>
                                <path
                                  d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                onClick={() => handlecancelsm(el_E.doc_Number, el_E.doc_Title, el_E.doc_Level, el_E.tracker_id)}
                                style={{ marginLeft: "20px" }}>
                                <path
                                  d="M8 0C3.592 0 0 3.592 0 8C0 12.408 3.592 16 8 16C12.408 16 16 12.408 16 8C16 3.592 12.408 0 8 0ZM10.688 9.84C10.92 10.072 10.92 10.456 10.688 10.688C10.568 10.808 10.416 10.864 10.264 10.864C10.112 10.864 9.96 10.808 9.84 10.688L8 8.848L6.16 10.688C6.04 10.808 5.888 10.864 5.736 10.864C5.584 10.864 5.432 10.808 5.312 10.688C5.08 10.456 5.08 10.072 5.312 9.84L7.152 8L5.312 6.16C5.08 5.928 5.08 5.544 5.312 5.312C5.544 5.08 5.928 5.08 6.16 5.312L8 7.152L9.84 5.312C10.072 5.08 10.456 5.08 10.688 5.312C10.92 5.544 10.92 5.928 10.688 6.16L8.848 8L10.688 9.84Z"
                                  fill="#5B6BE1"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="24"
                                viewBox="0 0 26 24"
                                fill="none"
                                onClick={(e) => handletick(e, el_E.doc_Number, el_E.doc_Title, el_E.doc_Level, el_E.tracker_id)}>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                                  fill="#66D171"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={handleshift}>
                                <path
                                  d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                  fill="#F97575"
                                />
                                <path
                                  d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                  fill="#F97575"
                                />
                              </svg>
                            </div>
                          )}
                        </td>
                        <td>{index + 1}</td>

                        <td>
                          {!(!showEditIconE || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Title"
                              Value={el_E.doc_Title}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_E.doc_Title ? (
                            el_E.doc_Title
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconE || editindex !== index) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="doc_Number"
                              Value={el_E.doc_Number}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_E.doc_Number ? (
                            el_E.doc_Number
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconE || editindex !== index) ? (
                            <div>
                              Rev.{' '}
                              <input
                                type="number"
                                pattern="[0-9]*"
                                className={styles.inputFieldsm}
                                name="Revision_no"
                                Value={el_E.Revision_no}
                                style={{ width: "30px" }}
                                onInput={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          ) : el_E.Revision_no ? (
                            `Rev. ${el_E.Revision_no}`
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {!(!showEditIconE || editindex !== index) ? (
                            <input
                              type="date"
                              className={styles.inputFieldsm}
                              name="Revision_date"
                              Value={el_E.Revision_date}
                              onInput={(e) => handleInputChange(e, index)}
                            />
                          ) : el_E.Revision_date ? (
                            new Date(el_E.Revision_date).toLocaleDateString('en-GB')
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                        {!(!showEditIconE || editindex !== index) ? (
                            <div>
                              <div style={{ display: "inline-flex", marginLeft: "0px" }}>
                                <input
                                  type="file"
                                  name="QMS"
                                  id="QMS"
                                  onInput={(e) => handleFileChange("QMS", e, el_E.doc_Number)}
                                  //Value={el_E?.Document ? el_E.Document : "Upload File"}
                                style={{
                                    display: "none",
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                  }}
                                />
                                <div
                                  style={{
                                    border: "1px solid rgb(231, 235, 235)",
                                    width: "150px",
                                    borderRadius: "8px",
                                    height: "35px",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() =>
                                    document.getElementById("QMS").click()
                                  }>
                                  {el_E?.Document
                                    ? el_E?.Document?.toString()
                                        .split("/")
                                        .pop()
                                        .split("-")
                                        .slice(1)
                                        .join("-")
                                    : tempQMS
                                    ? tempQMS
                                    : "Upload File"}
                                </div>
                                  <div>
                                    <img src = "crossmark" alt=''/>
                                </div>
                              </div>
                            </div>
                          ) : (
                            el_E.Document === null ? (
                              "-"
                            ) : (
  
                            <div style={{display:"flex", justifyContent:"center"}}>
                              {/* <div style={{border:"none", margin:"2px", marginTop:"5px", marginRight:"5px"}}>
                                View File
                              </div> */}
                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}>
                                <a href={el_E.Document} rel="noreferrer" target="_blank">
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                    <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" d="M5,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.51283584 7.61395981,3.93550716 7.11662113,3.99327227 L7,4 L5,4 C4.48716416,4 4.06449284,4.38604019 4.00672773,4.88337887 L4,5 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,5 C2,3.40231912 3.24891996,2.09633912 4.82372721,2.00509269 L5,2 L7,2 L5,2 Z M21,2 L21.081,2.003 L21.2007258,2.02024007 L21.2007258,2.02024007 L21.3121425,2.04973809 L21.3121425,2.04973809 L21.4232215,2.09367336 L21.5207088,2.14599545 L21.5207088,2.14599545 L21.6167501,2.21278596 L21.7071068,2.29289322 L21.7071068,2.29289322 L21.8036654,2.40469339 L21.8036654,2.40469339 L21.8753288,2.5159379 L21.9063462,2.57690085 L21.9063462,2.57690085 L21.9401141,2.65834962 L21.9401141,2.65834962 L21.9641549,2.73400703 L21.9641549,2.73400703 L21.9930928,2.8819045 L21.9930928,2.8819045 L22,3 L22,3 L22,9 C22,9.55228475 21.5522847,10 21,10 C20.4477153,10 20,9.55228475 20,9 L20,5.414 L13.7071068,11.7071068 C13.3466228,12.0675907 12.7793918,12.0953203 12.3871006,11.7902954 L12.2928932,11.7071068 C11.9324093,11.3466228 11.9046797,10.7793918 12.2097046,10.3871006 L12.2928932,10.2928932 L18.584,4 L15,4 C14.4477153,4 14,3.55228475 14,3 C14,2.44771525 14.4477153,2 15,2 L21,2 Z"/>
                                    </svg>
                                </a> 
                              </div>

                              <div style={{border:"none", margin:"2px", width:"30px", height:"30px", borderRadius:"8px", cursor:"pointer", background:'#FFFFFF'}}
                                onClick={() =>handleDownload(el_E.Document)}>
                                  {/*<xml version="1.0" encoding="utf-8">*/}
                                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M21,16 C21.5128358,16 21.9355072,16.3860402 21.9932723,16.8833789 L22,17 L22,19 C22,20.5976809 20.75108,21.9036609 19.1762728,21.9949073 L19,22 L5,22 C3.40231912,22 2.09633912,20.75108 2.00509269,19.1762728 L2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 C3.51283584,16 3.93550716,16.3860402 3.99327227,16.8833789 L4,17 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,17 C20,16.4477153 20.4477153,16 21,16 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,12.585 L14.2928932,11.2928932 C14.6533772,10.9324093 15.2206082,10.9046797 15.6128994,11.2097046 L15.7071068,11.2928932 C16.0675907,11.6533772 16.0953203,12.2206082 15.7902954,12.6128994 L15.7071068,12.7071068 L12.7071068,15.7071068 L12.663147,15.7485042 L12.663147,15.7485042 L12.5953066,15.8036654 L12.5953066,15.8036654 L12.4840621,15.8753288 L12.4840621,15.8753288 L12.371336,15.9287745 L12.371336,15.9287745 L12.265993,15.9641549 L12.265993,15.9641549 L12.1174742,15.9931658 L12.1174742,15.9931658 L12,16 L12,16 L11.9247615,15.9972121 L11.9247615,15.9972121 L11.7992742,15.9797599 L11.7992742,15.9797599 L11.6878575,15.9502619 L11.6878575,15.9502619 L11.5767785,15.9063266 L11.5767785,15.9063266 L11.4792912,15.8540045 L11.4792912,15.8540045 L11.3832499,15.787214 C11.3515341,15.7623312 11.3213595,15.7355731 11.2928932,15.7071068 L8.29289322,12.7071068 C7.90236893,12.3165825 7.90236893,11.6834175 8.29289322,11.2928932 C8.65337718,10.9324093 9.22060824,10.9046797 9.61289944,11.2097046 L9.70710678,11.2928932 L11,12.585 L11,3 C11,2.44771525 11.4477153,2 12,2 Z"/>
                                  </svg>
                              </div>
                            </div>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)} // Close the modal when clicked outside
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    slots={{ backdrop: CustomBackdrop }}
                    /* slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                    sx={{
                      backdropFilter: "blur(3px) opacity(0.6)",
                    }} */
                    >
                    {/* Modal content goes here */}
                    <Fade in={isModalOpen}>
                      <Box
                        className={styles.ModalBox}
                        sx={{
                          width: 700,
                          height: 300,
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: 10,
                          backgroundColor: "#fff",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                          borderRadius: "8px",
                        }}>
                        <label variant="h6" component="h2" className={styles.mstyl}>
                          Are you sure you want to remove this row?
                        </label>
                        <button
                          style={{
                            marginLeft: "120px",
                          }}
                          className={styles.btnyes}
                          onClick={handleYesClick}>
                          Yes
                        </button>
                        <button className={styles.btnyes} onClick={handleNoClick} style={{ background: "white", border: "2px solid #5B6BE1", color: "#5B6BE1"}}>
                          No
                        </button>
                      </Box>
                    </Fade>
                  </Modal>

                  {showInputRowE && (
                    <tr key={rowsE.length} className={styles.addinputspares}>
                      <td className={styles.tickcross}>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={(e) => handleSubmitTickE(e)}>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                            fill="#66D171"
                          />
                        </svg>
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={handleDeleteRowE}>
                          <path
                            d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                            fill="#F97575"
                          />
                          <path
                            d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                            fill="#F97575"
                          />
                        </svg>
                      </td>
                      <td></td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Title"
                          value={formDatasm.doc_Title}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="doc_Number"
                          value={formDatasm.doc_Number}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                        <div>
                          Rev.{' '}
                          <input
                            type="number"
                            pattern="[0-9]*"
                            className={styles.inputFieldsm}
                            name="Revision_no"
                            value={formDatasm.Revision_no}
                            onInput={handleInputChangevis}
                            style={{ width: "30px" }}
                          />
                        </div>
                      </td>

                      <td>
                        <input
                          type="date"
                          className={styles.inputFieldsm}
                          name="Revision_date"
                          value={formDatasm.Revision_date}
                          onInput={handleInputChangevis}
                        />
                      </td>

                      <td>
                      <div
                          style={{ display: "inline-flex", marginLeft: "0px" }}>
                          <input
                            type="file"
                            name="QMS"
                            id="QMS"
                            onInput={(e) => handleFileChange("QMS", e, formDatasm.doc_Number)}
                            // value={doc?.QMS ? getFileNameFromURL(doc?.QMS) : "Upload File"}
                            style={{
                              display: "none",
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          />
                          <div
                            style={{
                              border: "1px solid rgb(231, 235, 235)",
                              width: "150px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginLeft: "20px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() =>
                              document.getElementById("QMS").click()
                            }>
                            {qmsTableLevel5[0]?.QMS
                              ? qmsTableLevel5[0]?.QMS?.toString()
                                  .split("/")
                                  .pop()
                                  .split("-")
                                  .slice(1)
                                  .join("-")
                              : tempQMS
                              ? tempQMS
                              : "Upload File"}
                          </div>
                          </div>

                      </td>

                    </tr>
                  )}

                </tbody>
              </table>
            </div>

            <div 
              className={styles.buttonsdiv} 
              style={{ width: "90vw", marginTop: "0px", justifyContent: "flex-start"}}
            >
              <button onClick={inputdetailsmanually_E} style={{ width: "100%", margin: "0"}} disabled={isButtonDisabled}>
                Add New Item{" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.4915 1.66602H6.50817C3.47484 1.66602 1.6665 3.47435 1.6665 6.50768V13.4827C1.6665 16.5243 3.47484 18.3327 6.50817 18.3327H13.4832C16.5165 18.3327 18.3248 16.5243 18.3248 13.491V6.50768C18.3332 3.47435 16.5248 1.66602 13.4915 1.66602ZM14.9998 10.6243H10.6248V14.9993C10.6248 15.341 10.3415 15.6243 9.99984 15.6243C9.65817 15.6243 9.37484 15.341 9.37484 14.9993V10.6243H4.99984C4.65817 10.6243 4.37484 10.341 4.37484 9.99935C4.37484 9.65768 4.65817 9.37435 4.99984 9.37435H9.37484V4.99935C9.37484 4.65768 9.65817 4.37435 9.99984 4.37435C10.3415 4.37435 10.6248 4.65768 10.6248 4.99935V9.37435H14.9998C15.3415 9.37435 15.6248 9.65768 15.6248 9.99935C15.6248 10.341 15.3415 10.6243 14.9998 10.6243Z"
                    fill={isButtonDisabled ? '#5B6BE1' : 'white'}
                  />
                </svg>
              </button>
            </div>

            <hr style={{height:"25px", border: "1px solid #ffffff", marginTop:"0px"}}></hr>
            
          </div>
      )}
    </div>
  );
}    

export default QMStracker