import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  getNativeSelectUtilityClasses,
} from "@mui/material";
import styles from "./css/dispatchupdate.module.css";
import styles2 from "./css/dispatchupdate2.module.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Adjust the icon import if necessary


const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

const userEmail = localStorage.getItem("useremail");
if (userEmail) {
  console.log("Found value:", userEmail);
} else {
  console.log("No value found");
}
const Name = localStorage.getItem("Name");
if (Name) {
  console.log("Found value:", Name);
} else {
  console.log("No value found");
}

function DispatchUpdate() {
  const { project_id, id } = useParams(); // Project ID taken from URL
  const navigate = useNavigate();
  const [editItemMode, setEditItemMode] = useState(null);
  const [getDispatch, setGetDispatch] = useState([]);
  const [projectList, setProjectList] = useState({});

  const [filterWip, setFilterWip] = useState([]);
  const [filterFg, setFilterFg] = useState([]);
  const [isfillsameWip, setIsFillSameWip] = useState(false);
  const [isfillsameFg, setIsFillSameFg] = useState(false);
  const [isedit1, setIsedit1] = useState(false);
  const [isedit2, setIsedit2] = useState(false);
  const [isDocument, setIsDocument] = useState(true);
  const [isDocumentEdit, setIsDocumentEdit] = useState(false);
  const [isItemsEdit, setIsItemsEdit] = useState(false);
  const [activeTab, setActiveTab] = useState("Documents");
  const [inventoryUtilisedItems, setInventoryUtilisedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItems2, setFilteredItems2] = useState([]);
  const [featureincomingdeliverydocedit, setfeatureincomingdeliverydocedit] =
    useState([]);
  const [featureincomingdeliveryitemedit, setfeatureincomingdeliveryitemedit] =
    useState([]);

  const [activeStep, setActiveStep] = useState(0); //to store the activeStep for the stepper and to render the tables, 0 for "Material Produced", 1 for "Material Consumed"
  const [fileName3, setFileName3] = useState("");
  const [fileName4, setFileName4] = useState("");
  const [fileNamepo, setFileNamepo] = useState("");
  const [documents, setDocuments] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [documentToUpload3, setDocumentToUpload3] = useState(null);
  const [documentToUpload4, setDocumentToUpload4] = useState(null);
  //   const [selectedPurpose, setSelectedPurpose] = useState("");
  const [inputValues, setInputValues] = useState({});
  const [inputValues2, setInputValues2] = useState({});
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [mappedValues, setMappedValues] = useState({});
  const [finalDispatchData, setFinalDispatchData] = useState([]);
  const [removepodocument, setRemovePODocument] = useState(false);
  const [removequotedocument, setRemoveQuoteDocument] = useState(false);
  const [isPODocumentMarkedForRemoval, setIsPODocumentMarkedForRemoval] =
    useState(false);
  const [isQuoteDocumentMarkedForRemoval, setIsQuoteDocumentMarkedForRemoval] =
    useState(false);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",
    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });
  const [dispatchdata, setDispatchdata] = useState({
    Purpose: "",
    Receiving_Party: "",
    Deliverydate: "",
    Ponumber: "",
    Quotenumber: "",
    Dispatchqty: "",
    PO_document: "",
    Quote_document: "",
  });

  const [originalDispatchData, setOriginalDispatchData] = useState({
    Purpose: "",
    Receiving_Party: "",
    Deliverydate: "",
    Ponumber: "",
    Quotenumber: "",
    Dispatchqty: "",
    PO_document: "",
    Quote_document: "",
  });
  const [selectedPurpose, setSelectedPurpose] = useState(
    dispatchdata.Purpose || ""
  );
  const [formData, setFormData] = useState({
    Receiving_Party: dispatchdata.Receiving_Party || "",
    Deliverydate: dispatchdata.Deliverydate || "",
    Ponumber: dispatchdata.Ponumber || "",
    Quotenumber: dispatchdata.Quotenumber || "",
    Dispatchqty: dispatchdata.Dispatchqty || "",
    PO_document: dispatchdata.PO_document || "",
    Quote_document: dispatchdata.Quote_document || "",
  });

  useEffect(() => {
    setFormData({
      Receiving_Party: dispatchdata.Receiving_Party || "",
      Deliverydate: dispatchdata.Deliverydate || "",
      Ponumber: dispatchdata.Ponumber || "",
      Quotenumber: dispatchdata.Quotenumber || "",
      Dispatchqty: dispatchdata.Dispatchqty || "",
      PO_document: dispatchdata.PO_document || "",
      Quote_document: dispatchdata.Quote_document || "",
    });
    setSelectedPurpose(dispatchdata.Purpose || "");
  }, [dispatchdata]);
  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == project_id);
      const userRes = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_user_details_project_details?Email=${userEmail}`
      );

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
        Done_By: userRes.data.data[0].Name,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  // useEffect(() => {
  //   const fetchDispatchData = async () => {
  //     try {
  //       const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_dispatch/${id}`);
  //       const data = response.data;
  //       setDispatchdata(data);
  //       setOriginalDispatchData(data);
  //       setFileName3(data.PO_document ? extractFileName(data.PO_document) : "");
  //       console.log(fileName3)
  //       setFileName4(data.Quote_document ? extractFileName(data.Quote_document) : "");
  //     } catch (error) {
  //       console.error("Error fetching dispatch data:", error);
  //     }
  //   };

  //   fetchDispatchData();
  // }, [id]);

  const handleforwarddoc = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = ""; // Set desired filename
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };

  const handleOpenFile = (url) => {
    if (url) {
      // Check file extension to handle different file types
      const fileExtension = url.split('.').pop().toLowerCase();
      if (fileExtension === 'pdf') {
        // For PDF files, try opening in a new tab
        window.open(url, '_blank');
      } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
        // For image files, open in a new tab
        window.open(url, '_blank');
      } else {
        console.log('Unsupported file type');
      }
    }
  };


  const handleProdBackButton = () => {
    navigate(`/dispatch/${id}`);
  };

  const handleEditDocuments = () => {
    setIsDocumentEdit(true);
    setIsPODocumentMarkedForRemoval(false);
    setIsQuoteDocumentMarkedForRemoval(false);
  };
  const handleSaveDocuments = async (e) => {
    e.preventDefault();
    console.log("Dispatch Data Before Sending:", getDispatch);
    const dispatchData = getDispatch[0]; // Assuming updating the first entry

    const payload = {
      Purpose: dispatchData.Purpose,
      Receiving_Party: dispatchData.Receiving_Party,
      Delivery_date: dispatchData.Delivery_date,
      Ponumber: dispatchData.Ponumber,
      Quote_number: dispatchData.Quote_number,
      dispatch_id: project_id,
    };

    try {
      // Update dispatch data
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch?dispatch_id=${project_id}`,
        payload
      );

      // Handle file uploads and removals
      await handleFileOperations();

      alert("Details updated successfully");
      window.location.reload(); // Consider replacing this with more React-friendly navigation
    } catch (error) {
      console.error("Error updating details:", error);
      alert("Failed to update details");
    }
  };

  // Function to handle file uploads and removals
  const handleFileOperations = async () => {
    if (documentToUpload3) {
      const formData = new FormData();
      formData.append("PO_document", documentToUpload3);
      await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch_details?dispatch_id=${project_id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    } else if (isPODocumentMarkedForRemoval) {
      await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/remove_dispatch_files/${project_id}`,
        { type: "PO_document" }
      );
    }

    if (documentToUpload4) {
      const formData = new FormData();
      formData.append("Quote_document", documentToUpload4);
      await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch_details2?dispatch_id=${project_id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    } else if (isQuoteDocumentMarkedForRemoval) {
      await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/remove_dispatch_files/${project_id}`,
        { type: "Quote_document" }
      );
    }
  };

  const handleCancelDocuments = () => {
    setIsDocumentEdit(false);
    setFileName3(
      originalDispatchData.PO_document
        ? extractFileName(originalDispatchData.PO_document)
        : ""
    );
    setFileName4(
      originalDispatchData.Quote_document
        ? extractFileName(originalDispatchData.Quote_document)
        : ""
    );
  };
  const handleEditItems = () => {
    setIsItemsEdit(true);
  };
  // const handleSaveItems = async () => {
  //   setIsItemsEdit(false);
  //   try {
  //     // Sending the finalDispatchData array to the backend for updating
  //     const res = await axios.put(
  //       "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_final_dispatch",
  //       finalDispatchData
  //     );

  //     if (res.status === 200 && res.data.success) {
  //       alert("Data updated successfully");
  //       window.location.reload();
  //     } else {
  //       alert("Failed to update data");
  //     }
  //   } catch (error) {
  //     console.error("Error updating quantities:", error);
  //     alert("An error occurred while updating data");
  //   }
  // };

  //this is fully working handlesave
  const handleSaveItems = async () => {
    setIsItemsEdit(false); // Assuming this controls whether you are in edit mode or not
    try {
      const res = await axios.put(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_final_dispatch",
        finalDispatchData
      );

      if (res.status === 200 && res.data.success) {
        alert("Data updated successfully");
        // Update the state to reflect the saved data instead of reloading the page
        setFinalDispatchData(res.data.updatedData);
        window.location.reload(); // Assuming the server returns the updated list
      } else {
        alert("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating quantities:", error);
      alert("An error occurred while updating data");
    }
  };

  const handleCancelItems = () => {
    setIsItemsEdit(false);
  };

  // Function to handle tab switching
  const handleTabSwitch = (tabName) => {
    setActiveTab(tabName);
  };
  // const handleDates = (name, value) => {
  //   if (value !== null && value !== undefined) {
  //     const selectedDate = value.format("DD/MM/YYYY");
  //     setDispatchdata((prevState) => ({
  //       ...prevState,
  //       [name]: selectedDate, // Update the state with formatted date
  //     }));
  //     console.log(name, selectedDate);
  //   } else {
  //     console.log("Invalid Date");
  //   }
  // };

  const handleDates = (name, value) => {
    setGetDispatch((prevState) =>
      prevState.map((item, index) =>
        index === name
          ? {
            ...item,
            Delivery_date: value ? value.format("DD/MM/YYYY") : null,
          }
          : item
      )
    );
  };
  // const handleDates = (index, name, value) => {
  //   setGetDispatch((prevDispatch) => {
  //     const newDispatch = [...prevDispatch];
  //     newDispatch[index] = {
  //       ...newDispatch[index],
  //       [name]: value?.format("DD/MM/YYYY"),
  //     };
  //     return newDispatch;
  //   });
  // };
  // mui styling for date picker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "230px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));
  // const handlecross = (name) => {
  //   alert("clicked");
  //   switch (name) {
  //     case "PO_document":
  //       setFileName3("");
  //       setDocumentToUpload3(null);
  //       setRemovePODocument(true);
  //       resetFileInput("upload3");  // Reset the file input after removing the file
  //       break;
  //     case "Quote_document":
  //       setFileName4("");
  //       setDocumentToUpload4(null);
  //       setRemoveQuoteDocument(true);
  //       resetFileInput("upload4");  // Reset the file input after removing the file
  //       break;
  //     default:
  //       break;
  //   }
  // };
  const handlefileupload3 = (name, event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setDocumentToUpload3(file);
      setFileName3(file.name);
      setFileNamepo(file.name);

      // Update the specific getDispatch item
      setGetDispatch((prevDispatch) => {
        const newDispatch = [...prevDispatch];
        newDispatch[0] = { ...newDispatch[0], PO_document: fileURL };
        return newDispatch;
      });

      console.log(file);
      console.log(file.name);
      console.log(fileName3);
      console.log(fileNamepo);
      setIsPODocumentMarkedForRemoval(false);
    }
  };

  // const handlefileupload4 = (name, event) => {
  //   event.preventDefault();
  //   const file = event.target.files[0];
  //   if (file) {
  //     setDocumentToUpload4(file);
  //     setFileName4(file.name);
  //     setIsQuoteDocumentMarkedForRemoval(false);
  //     setDispatchdata((prevData) => ({ ...prevData, Quote_document: URL.createObjectURL(file) }));
  //   }
  // };

  const handlefileupload4 = (name, event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setDocumentToUpload4(file);
      setFileName4(file.name);

      // Update the specific getDispatch item
      setGetDispatch((prevDispatch) => {
        const newDispatch = [...prevDispatch];
        newDispatch[0] = { ...newDispatch[0], Quote_document: fileURL };
        return newDispatch;
      });

      setIsQuoteDocumentMarkedForRemoval(false);
    }
  };

  const handlecross = (name) => {
    console.log("Cross handler called with:", name); // Debug log
    switch (name) {
      case "PO_document":
        console.log("Handling PO_document removal");
        setFileName3(""); // This will clear the displayed filename
        setDocumentToUpload3(null); // Clear the document to upload state
        setIsPODocumentMarkedForRemoval(true); // Mark the document for removal
        resetFileInput("upload3"); // Reset the file input form
        setDispatchdata((prevData) => {
          const newData = { ...prevData, PO_document: "" }; // Remove the document from dispatch data
          console.log("Dispatch data after removal:", newData); // Debug log
          return newData;
        });
        break;
      case "Quote_document":
        console.log("Handling Quote_document removal");
        setFileName4(""); // This will clear the displayed filename
        setDocumentToUpload4(null); // Clear the document to upload state
        setIsQuoteDocumentMarkedForRemoval(true); // Mark the document for removal
        resetFileInput("upload4"); // Reset the file input form
        setDispatchdata((prevData) => {
          const newData = { ...prevData, Quote_document: "" }; // Remove the document from dispatch data
          console.log("Dispatch data after removal:", newData); // Debug log
          return newData;
        });
        break;
      default:
        console.log("No valid document type found");
        break;
    }
  };

  const resetFileInput = (fileInputId) => {
    // Debugging: Log the fileInputId to ensure it's what we expect
    console.log("resetFileInput called with id:", fileInputId);

    const fileInput = document.getElementById(fileInputId);
    if (fileInput) {
      fileInput.value = "";
      console.log("File input reset successfully.");
    } else {
      console.log("Failed to find file input with id:", fileInputId);
    }

    // Assuming setFileName3 is correctly defined and intended to reset some related state
    setFileName3("");

    // Additional debugging: Log after attempting to reset to ensure function completes
    console.log("setFileName3 called with empty string.");
  };

  // const handlecross = (name) => {
  //   alert("clicked");
  //   switch (name) {
  //     case "PO_document":
  //       setFileName3("");
  //       setDocumentToUpload3(null);
  //       setIsPODocumentMarkedForRemoval(true);
  //       resetFileInput("upload3");
  //       setDispatchdata((prevData) => ({ ...prevData, PO_document: "" }));
  //       break;
  //     case "Quote_document":
  //       setFileName4("");
  //       setDocumentToUpload4(null);
  //       setIsQuoteDocumentMarkedForRemoval(true);
  //       resetFileInput("upload4");
  //       setDispatchdata((prevData) => ({ ...prevData, Quote_document: null }));
  //       break;
  //     default:
  //       break;
  //   }
  // };

  useEffect(() => {
    return () => {
      // Restore original dispatch data if not saved
      setDispatchdata(originalDispatchData);
    };
  }, []);
  const extractFileNames = (filePaths) => {
    return filePaths.map((filePath) => {
      if (!filePath) return "";
      const fileName = filePath.split("/").pop().split("-").slice(1).join("-");
      return fileName.trim();
    });
  };

  const extractFileName = (filePath) => {
    if (!filePath) return "";
    const fileNameParts = filePath.toString().split("/").pop().split("-");
    fileNameParts.shift(); // Remove the first element (number)
    const finalname = fileNameParts.join("-");
    return finalname;
  };
  // useEffect(()=>{
  //   if(dispatchdata.Podocument){
  //    const filepaths = dispatchdata.Podocument.split(",");
  //     const trimmedNames = extractFileNames(filepaths);
  //   }
  // })

  // useEffect(() => {
  //   if (getDispatch.PO_document) {
  //     const filepaths = getDispatch.PO_document.split(",");
  //     const trimmedNames = extractFileNames(filepaths);
  //   }
  // }, [getDispatch.PO_document]);
  // useEffect(()=>{
  //   setFileName3(extractFileName(dispatchdata.PO_document));
  // },[dispatchdata.PO_document]);
  // console.log(dispatchdata)

  // useEffect(() => {
  //   setFileName3(extractFileName(getDispatch.PO_document));
  // }, [getDispatch.PO_document]);

  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const updatedDispatch = [...getDispatch];
    updatedDispatch[index] = { ...updatedDispatch[index], [name]: value };
    setGetDispatch(updatedDispatch);
  };

  // const handleOnChange = (event, field) => {
  //   const { name, value } = event.target;

  //   setGetDispatch((prevState) =>
  //     prevState.map((item, index) =>
  //       index === field ? { ...item, [name]: value } : item
  //     )
  //   );
  // };
  // const handleOnChange = (event) => {
  //   const { name, value } = event.target;

  //   setDispatchdata({
  //     ...dispatchdata,
  //     [name]: value,
  //   });
  // };

  // const handleOnChange = (e, index) => {
  //   const { name, value } = e.target;
  //   setGetDispatch((prevDispatch) => {
  //     const newDispatch = [...prevDispatch];
  //     newDispatch[index] = {
  //       ...newDispatch[index],
  //       [name]: value,
  //     };
  //     return newDispatch;
  //   });
  // };
  const handleOnChange2 = (e, wip, index) => {
    const { value } = e.target;
    const matchedItem = filteredItems.find(
      (item) =>
        item.Item_Name === wip.Item_Name && item.Part_Number === wip.Part_Number
    );
    const totalQtyUsed = matchedItem
      ? mappedValues[
      `${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`
      ] || 0
      : 0;
    const availableQty = matchedItem
      ? matchedItem.Quantity_Received - totalQtyUsed
      : 0;

    if (Number(value) > availableQty) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: "Dispatch quantity cannot exceed available quantity",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: "",
      }));
    }

    setInputValues((prevValues) => ({
      ...prevValues,
      [index]: value,
    }));
  };

  // const handleQuantityChange = (event, index) => {
  //   const { value } = event.target;
  //   const updatedData = [...finalDispatchData];
  //   updatedData[index].Dispatch_qty = value;
  //   setFinalDispatchData(updatedData);
  // };

  // const handleQuantityChange = (event, index) => {
  //   const { value } = event.target;
  //   const updatedData = [...finalDispatchData];

  //   // Check if the dispatch quantity exceeds available quantity
  //   if (Number(value) > updatedData[index].availableQty) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [index]: `Incoming Qty can't exceed ${updatedData[index].availableQty}`,
  //     }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [index]: "",
  //     }));
  //   }

  //   updatedData[index].Dispatch_qty = value;
  //   setFinalDispatchData(updatedData);
  // };

  const handleQuantityChange = (event, index) => {
    const newDispatch_qty = parseInt(event.target.value, 10); // Ensure you are working with numbers

    setFinalDispatchData((currentData) =>
      currentData?.map((item, idx) =>
        idx === index ? { ...item, Dispatch_qty: newDispatch_qty } : item
      )
    );

    if (newDispatch_qty > finalDispatchData[index].availableQty) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: `Incoming Qty can't exceed ${finalDispatchData[index].availableQty}`,
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[index];
        return newErrors;
      });
    }
  };

  const handleOnChange3 = (e, fgItem, index) => {
    const { value } = e.target;
    const matchedItem = inventoryUtilisedItems.find(
      (item) =>
        item.Item_Name === fgItem.Item_Name &&
        item.Part_Number === fgItem.Part_Number
    );
    const totalQtyUsed = matchedItem
      ? mappedValues[
      `${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`
      ] || 0
      : 0;
    const availableQty = matchedItem
      ? matchedItem.Qty_Utilized - totalQtyUsed
      : 0;

    if (Number(value) > availableQty) {
      setErrors2((prevErrors) => ({
        ...prevErrors,
        [index]: "Dispatch quantity cannot exceed available quantity",
      }));
    } else {
      setErrors2((prevErrors) => ({
        ...prevErrors,
        [index]: "",
      }));
    }

    setInputValues2((prevValues) => ({
      ...prevValues,
      [index]: value,
    }));
  };
  // const handlefileupload3 = (name, event) => {
  //   event.preventDefault();
  //   const temp3 = event.target.files[0];
  //   setDocumentToUpload3(temp3);

  //   const inputElement3 = document.getElementsByName(name)[0];
  //   const selectedFile3 = inputElement3.files[0];

  //   if (selectedFile3) {
  //     const fileName3 = selectedFile3.name;
  //     setFileName3(fileName3 ? fileName3 : "");
  //   } else {
  //     setFileName3("");
  //   }
  //   inputElement3.value = "";
  //   temp3.value = "";
  //   {console.log(temp3)}
  // };

  // const handlefileupload4 = (name, event) => {
  //   event.preventDefault();
  //   const temp4 = event.target.files[0];
  //   setDocumentToUpload4(temp4);

  //   const inputElement4 = document.getElementsByName(name)[0];
  //   const selectedFile4 = inputElement4.files[0];

  //   if (selectedFile4) {
  //     const fileName4 = selectedFile4.name;
  //     setFileName4(fileName4 ? fileName4 : "");
  //   } else {
  //     setFileName4("");
  //   }
  //   inputElement4.value = "";
  //   temp4.value = "";
  // };

  //these are perfectly working handlefileuploads
  // const handlefileupload3 = (name, event) => {
  //   event.preventDefault();
  //   const file = event.target.files[0];
  //   if (file) {
  //     setDocumentToUpload3(file);
  //     setFileName3(file.name);
  //   }
  //   resetFileInput(name);  // Reset the file input after handling the file
  // };

  // const handlefileupload4 = (name, event) => {
  //   event.preventDefault();
  //   const file = event.target.files[0];
  //   if (file) {
  //     setDocumentToUpload4(file);
  //     setFileName4(file.name);
  //   }
  //   resetFileInput(name);  // Reset the file input after handling the file
  // };

  const handleEdit1 = () => {
    setIsedit1(true);
    // Add logic to handle edit click
  };
  const handleEdit2 = () => {
    setIsedit2(true);
    // Add logic to handle edit click
  };

  const handleCancel = () => {
    setIsedit1(false);
    setDispatchdata(originalDispatchData); // Restore original dispatch data
    setIsPODocumentMarkedForRemoval(false);
    setIsQuoteDocumentMarkedForRemoval(false);
    setFormData({
      Receiving_Party: dispatchdata.Receiving_Party || "",
      Deliverydate: dispatchdata.Deliverydate || "",
      Ponumber: dispatchdata.Ponumber || "",
      Quotenumber: dispatchdata.Quotenumber || "",
      Dispatchqty: dispatchdata.Dispatchqty || "",
      PO_document: dispatchdata.PO_document || "",
      Quote_document: dispatchdata.Quote_document || "",
    });
    setSelectedPurpose(dispatchdata.Purpose || "");
  };

  const handleCancel2 = () => {
    setIsedit2(false);
    setFormData({
      Dispatchqty: "",
    });
    setFormData(dispatchdata.Dispatchqty || 0);
  };

  const handleSave1 = async (e) => {
    e.preventDefault();

    console.log("Dispatch Data Before Sending:", getDispatch);

    const dispatchData = getDispatch[0]; // Assuming updating first entry for simplicity

    const payload = {
      Purpose: dispatchData.Purpose,
      Receiving_Party: dispatchData.Receiving_Party,
      Delivery_date: dispatchData.Delivery_date,
      Ponumber: dispatchData.Ponumber,
      Quote_number: dispatchData.Quote_number,
      dispatch_id: project_id,
    };

    console.log("Payload to be sent:", payload);

    let payloadfiles1 = null;
    let payloadfiles2 = null;

    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch?dispatch_id=${project_id}`,
        payload
      );
      console.log("Response:", res);

      if (documentToUpload3) {
        payloadfiles1 = new FormData();
        payloadfiles1.append("dispatch_id", project_id);
        payloadfiles1.append("PO_document", documentToUpload3);

        await axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch_details?dispatch_id=${project_id}`,
          payloadfiles1,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      }

      if (documentToUpload4) {
        payloadfiles2 = new FormData();
        payloadfiles2.append("dispatch_id", project_id);
        payloadfiles2.append("Quote_document", documentToUpload4);

        await axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_dispatch_details2?dispatch_id=${project_id}`,
          payloadfiles2,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      }

      const removeConditions = [
        {
          remove: isPODocumentMarkedForRemoval,
          temp: fileName3,
          type: "PO_document",
        },
        {
          remove: isQuoteDocumentMarkedForRemoval,
          temp: fileName4,
          type: "Quote_document",
        },
      ];

      for (const condition of removeConditions) {
        if (condition.remove && condition.temp === "") {
          const payloadRemoveFiles = { type: condition.type };
          const url = `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/remove_dispatch_files/${project_id}`;
          console.log("Remove File URL:", url); // Log URL to verify
          await axios.put(url, payloadRemoveFiles);
        }
      }

      if (
        res.status === 200 &&
        (!documentToUpload3 ||
          (payloadfiles1 && payloadfiles1.status === 200)) &&
        (!documentToUpload4 || (payloadfiles2 && payloadfiles2.status === 200))
      ) {
        alert("Incoming delivery updated successfully");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating quantities:", error);
    }

    setIsedit1(false);
    // Update the original data to reflect the saved state
    setOriginalDispatchData(dispatchdata);
  };

  const handleSave2 = async () => {
    try {
      // Sending the finalDispatchData array to the backend for updating
      const res = await axios.put(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_final_dispatch",
        finalDispatchData
      );

      if (res.status === 200 && res.data.success) {
        alert("Data updated successfully");
        window.location.reload();
      } else {
        alert("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating quantities:", error);
      alert("An error occurred while updating data");
    }
  };
  useEffect(() => {
    async function dispatchdetails() {
      try {
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getdispatchdetails`
        );
        const filter = res.data.results.filter(
          (el) => el.dispatch_id == project_id
        );
        setGetDispatch(filter);
        console.log(getDispatch);
        console.log(dispatchdata);
      } catch (error) {
        console.error(error);
      }
    }
    dispatchdetails();
  }, [project_id]);

  useEffect(() => {
    const fetchFinalDispatchDetails = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getfinaldispatchdetails`
        );
        const data = response.data.results.filter(
          (el) => el.dispatch_id === project_id
        );
        const updatedData = data.map((item) => ({
          ...item,
          Dispatch_qty: item.Dispatch_qty,
        }));

        // Only update state if data has changed
        if (JSON.stringify(finalDispatchData) !== JSON.stringify(updatedData)) {
          setFinalDispatchData(updatedData);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFinalDispatchDetails();
  }, [project_id]);

  const handleDispatchQtyChange = (index, value) => {
    const updatedData = [...finalDispatchData];
    updatedData[index].Dispatch_Qty = Number(value); // Ensure the value is a number
    setFinalDispatchData(updatedData);
  };

  useEffect(() => {
    const fetchItemsReceivedDetails = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get2`
        );
        const itemsReceivedData = response.data.data;
        const newMappedValues = {};
        const newFilteredItems = itemsReceivedData.filter((item) => {
          const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
          const totalQtyUsed = newMappedValues[key] || 0;
          newMappedValues[key] = totalQtyUsed;
          return item.Quantity_Received - totalQtyUsed > 0;
        });

        setMappedValues(newMappedValues);
        setFilteredItems(newFilteredItems);
      } catch (error) {
        console.error(error);
      }
    };
    fetchItemsReceivedDetails();
  }, []);

  useEffect(() => {
    const updatedData = finalDispatchData?.map((item) => {
      const matchedItem = filteredItems.find(
        (fi) =>
          fi.Item_Name === item.Item_Name && fi.Part_Number === item.Part_Number
      );
      const availableQty = matchedItem
        ? matchedItem.Quantity_Received -
        (mappedValues[
          `${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`
        ] || 0)
        : 0;
      return { ...item, availableQty };
    });

    if (JSON.stringify(finalDispatchData) !== JSON.stringify(updatedData)) {
      setFinalDispatchData(updatedData);
    }
  }, [filteredItems, mappedValues, finalDispatchData]);

  // useEffect(() => {
  //   async function finaldispatchdetails() {
  //     try {
  //       const res = await axios.get(
  //         `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getfinaldispatchdetails`
  //       );
  //       const filter = res.data.results.filter(
  //         (el) => el.dispatch_id === project_id
  //       );
  //       setFinalDispatchData(filter);

  //       // Fetch items received and calculate available quantities
  //       const res1 = await axios.get(
  //         `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get2`
  //       );

  //       const itemsReceivedData = res1.data.data;
  //       const newMappedValues = {};
  //       itemsReceivedData.forEach((item) => {
  //         const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
  //         newMappedValues[key] = item.Total_Qty_Used;
  //       });
  //       setMappedValues(newMappedValues);

  //       const filteredItems = itemsReceivedData.filter((item) => {
  //         const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
  //         const totalQtyUsed = newMappedValues[key] || 0;
  //         return item.Quantity_Received - totalQtyUsed !== 0;
  //       });

  //       setFilteredItems(filteredItems);

  //       // Calculate available quantities
  //       const updatedDispatchData = filter.map((dispatchItem) => {
  //         const matchedItem = filteredItems.find(
  //           (item) =>
  //             item.Item_Name === dispatchItem.Item_Name &&
  //             item.Part_Number === dispatchItem.Part_Number
  //         );
  //         const availableQty = matchedItem
  //           ? matchedItem.Quantity_Received - newMappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0
  //           : 0;

  //         return {
  //           ...dispatchItem,
  //           availableQty: availableQty
  //         };
  //       });

  //       setFinalDispatchData(updatedDispatchData);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   finaldispatchdetails();
  // }, [project_id]);

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        const workshoppurchasesincomingdeliveriesdocedit = response.data.filter(
          (el) => {
            return (
              el.Group_Name === localStorage.getItem("Group") &&
              el.Header_Name === "Workshop Projects" &&
              el.Feature_Name ===
              "Workshop Purchases Incoming Deliveries Documents Edit" &&
              (el.Access === "Can View" || el.Access === "Can Edit")
            );
          }
        );

        const workshoppurchasesincomingdeliveriesitemedit =
          response.data.filter((el) => {
            return (
              el.Group_Name === localStorage.getItem("Group") &&
              el.Header_Name === "Workshop Projects" &&
              el.Feature_Name ===
              "Workshop Purchases Incoming Deliveries Items Edit" &&
              (el.Access === "Can View" || el.Access === "Can Edit")
            );
          });
        console.log("I am here ");
        console.log(workshoppurchasesincomingdeliveriesitemedit);
        setfeatureincomingdeliverydocedit(
          workshoppurchasesincomingdeliveriesdocedit
        );
        setfeatureincomingdeliveryitemedit(
          workshoppurchasesincomingdeliveriesitemedit
        );
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  // const handlepurposechange = (e) => {
  //   setSelectedPurpose(e.target.value);
  //   setDispatchdata({
  //     ...dispatchdata,
  //     Purpose: e.target.value,
  //   });
  // };

  // const handlepurposechange = (event) => {
  //   const { value } = event.target;
  //   setGetDispatch((prevState) =>
  //     prevState.map((item, index) =>
  //       index === event.target.name
  //         ? { ...item, Purpose: value }
  //         : item
  //     )
  //   );
  // };

  const handlepurposechange = (event, index) => {
    const { value } = event.target;
    setGetDispatch((prevState) =>
      prevState.map((item, i) =>
        i === index ? { ...item, Purpose: value } : item
      )
    );
  };

  return (
    <div>
      <div className={styles.topButtonContainer}>
        <button className={styles.topBackButton} onClick={handleProdBackButton}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
              fill="#5B6BE1"
            />
          </svg>{" "}
          Dispatch
        </button>
      </div>

      <div style={{ marginTop: "115px", marginLeft: "1%", marginRight: "1%" }}>
        {Array.isArray(getDispatch) &&
          getDispatch.map((dispatchdata, index) => (
            <div key={index} className={styles.box}>
              <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Dispatch ID: {dispatchdata.dispatch_id}
                </label>
              </div>
              <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Purpose: {dispatchdata.Purpose}
                </label>
              </div>
              <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Receiving Party: {dispatchdata.Receiving_Party}
                </label>
              </div>
              <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Delivery Date: {dispatchdata.Delivery_date}
                </label>
              </div>

              <div className={styles.smallcontainer}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Created By: {formdata1.Done_By}
                </label>
              </div>
            </div>
          ))}
        <div>
          <button
            className={`${styles["incomingButton2"]} ${activeTab === "Documents"
              ? styles.activeTab
              : styles["not-incoming2"]
              }`}
            onClick={() => handleTabSwitch("Documents")}>
            Documents
          </button>
          <button
            className={`${styles["outgoingButton2"]} ${activeTab === "Items" ? styles.activeTab : styles["not-outgoing2"]
              }`}
            onClick={() => handleTabSwitch("Items")}>
            Items
          </button>
        </div>
      </div>
      {activeTab === "Documents" ? (
        <div>
          {!isDocumentEdit ? (
            <>
              <button
                onClick={handleEditDocuments}
                className={`${styles["incomingButton2"]} ${isDocument ? styles["incoming2"] : styles["not-incoming2"]
                  }`}>
                Edit
              </button>
              <form>
                {Array.isArray(getDispatch) &&
                  getDispatch.map((dispatchdata, index) => (
                    <div key={index}>
                      <div className={styles.formElement}>
                        <label>Purpose:</label>
                        <select
                          value={dispatchdata.Purpose}
                          disabled={!isedit1}
                          style={{ backgroundColor: "rgba(239, 239, 239, 0.45)", }}
                          onChange={(e) => handlepurposechange(e, index)}>
                          <option value="">Select Purpose</option>
                          <option value="Outsourced job">Outsourced job</option>
                          <option value="Final Delivery">Final Delivery</option>
                        </select>
                      </div>
                      <div className={styles.formElement}>
                        <label>Receiving Party:</label>
                        <input
                          type="text"
                          name="Receiving_Party"
                          value={dispatchdata.Receiving_Party}
                          onChange={(e) => handleOnChange(e, index)}
                          disabled={!isedit1}
                        />
                      </div>
                      <div className={`${styles.formElement} ${styles.datepickerInput}`}>
                        <label>Delivery Date:</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <CustomDatePicker
                            name="Deliverydate"
                            value={
                              dispatchdata.Delivery_date
                                ? dayjs(dispatchdata.Delivery_date, "DD/MM/YYYY")
                                : null
                            }
                            onChange={(value) => handleDates(index, value)}
                            slotProps={{
                              textField: {
                                size: "small",
                                InputProps: {
                                  style: {
                                    color: "black", // Set the font color to black
                                  },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton>
                                        <CalendarTodayIcon style={{ color: 'black' }} /> {/* Set the calendar icon color to black */}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                },
                                InputLabelProps: {
                                  style: {
                                    color: "black", // Also set the label color to black if any
                                  },
                                },
                              },
                            }}
                            format="DD/MM/YYYY"
                            sx={{
                              '& .MuiInputBase-root': {
                                backgroundColor: "rgba(239, 239, 239, 0.45)",
                                borderRadius: "8px",
                              },
                              '& .MuiInputBase-input': {
                                color: "black !important",
                                '-webkit-text-fill-color': 'black !important', // Override the fill color for WebKit browsers
                              },
                            }}
                            disabled={!isedit1}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className={styles.formElement}>
                        <label>
                          PO Number:
                          <br />
                          (Issued by Ergon)
                        </label>
                        <input
                          type="number"
                          name="Ponumber"
                          value={dispatchdata.Ponumber}
                          onChange={(e) => handleOnChange(e, index)}
                          disabled={!isedit1}
                        />
                      </div>
                      <div className={styles.formElement}>
                        <label>
                          Quote Number:
                          <br />
                          (Issued by Vendor)
                        </label>
                        <input
                          type="number"
                          name="Quote_number"
                          value={dispatchdata.Quote_number}
                          onChange={(e) => handleOnChange(e, index)}
                          disabled={!isedit1}
                        />
                      </div>
                      <div style={{ marginTop: "-7.50%" }}>
                        <div>
                          <div
                            style={{
                              display: "inline-flex",
                              marginLeft: "489px",
                              padding: "10px 0px",
                              alignItems: "center",
                              border: "1px",
                              marginTop: "-160px",
                            }}>
                            <label
                              htmlFor="upload3"
                              style={{ color: "black", fontWeight: "600" }}>
                              PO Document:
                            </label>
                            <input
                              type="file"
                              name="upload3"
                              id="upload3"
                              onInput={(e) => handlefileupload3("upload3", e)}
                              style={{ display: "none" }}
                              disabled={!isedit1}

                            />
                            <div
                              className={styles.textbox2}
                              style={{
                                position: "relative",
                                marginLeft: "56px",
                                marginBottom: "3px",
                              }}
                              onClick={() =>
                                document.getElementById("upload3").click()
                              }>
                              <label
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "5px",
                                  // color: "rgba(128, 128, 128, 0.8)",
                                  overflow: "hidden", // Hide overflow text
                                  textOverflow: "ellipsis", // Display ellipsis for overflow text
                                  whiteSpace: "nowrap", // Prevent wrapping
                                  maxWidth: "180px", // Set maximum width for the file name
                                  alignItems: "center",
                                }}>
                                <span>
                                  {dispatchdata.PO_document
                                    ? extractFileName(dispatchdata.PO_document)
                                    : "Upload File"}
                                </span>
                              </label>

                              <svg
                                width="25"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "10px", // Adjust as needed
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                height="25"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                  fill="#212529"
                                />
                                <path
                                  d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                  fill="#212529"
                                />
                              </svg>
                            </div>{" "}
                            {dispatchdata?.PO_document && (
                              <div>
                                <div onClick={() => handleOpenFile(dispatchdata?.PO_document)} style={{ cursor: 'pointer' }}>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}

                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              display: "inline-flex",
                              marginLeft: "487px",
                              padding: "10px 0px",
                              alignItems: "center",
                              border: "1px",
                              marginTop: "29px",
                            }}>
                            <label
                              htmlFor="upload4"
                              style={{ color: "black", fontWeight: "600" }}>
                              Quote Document:
                            </label>
                            <input
                              type="file"
                              name="upload4"
                              id="upload4"
                              onInput={(e) => handlefileupload4("upload4", e)}
                              style={{ display: "none" }}
                              disabled={!isedit1}
                            />
                            <div
                              className={styles.textbox2}
                              style={{ position: "relative" }}
                              onClick={() =>
                                document.getElementById("upload4").click()
                              }>
                              <label
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "5px",
                                  // color: "rgba(128, 128, 128, 0.8)",
                                  overflow: "hidden", // Hide overflow text
                                  textOverflow: "ellipsis", // Display ellipsis for overflow text
                                  whiteSpace: "nowrap", // Prevent wrapping
                                  maxWidth: "180px", // Set maximum width for the file name
                                  alignItems: "center",
                                }}>
                                {dispatchdata.Quote_document
                                  ? extractFileName(dispatchdata.Quote_document)
                                  : "Upload File"}
                              </label>

                              <svg
                                width="25"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "10px", // Adjust as needed
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                height="25"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                  fill="#212529"
                                />
                                <path
                                  d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                  fill="#212529"
                                />
                              </svg>
                            </div>{" "}
                            {dispatchdata?.Quote_document && (
                              <div>
                                <div onClick={() => handleOpenFile(dispatchdata?.Quote_document)} style={{ cursor: 'pointer' }}>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </form>
            </>
          ) : (
            <>
              <div style={{ marginLeft: "78%" }}>
                <button
                  onClick={handleSaveDocuments}
                  className={styles.saveButton}>
                  Save
                </button>
                <button
                  onClick={handleCancelDocuments}
                  className={styles.cancelBtn}>
                  Cancel
                </button>
              </div>
              <div>
                <form>
                  <>
                    {Array.isArray(getDispatch) &&
                      getDispatch.map((dispatchdata, index) => (
                        <div key={index}>
                          <div className={styles.formElement}>
                            <label>Purpose:</label>
                            <select
                              value={dispatchdata.Purpose}
                              onChange={(e) => handlepurposechange(e, index)}>
                              <option value="">Select Purpose</option>
                              <option value="Outsourced job">
                                Outsourced job
                              </option>
                              <option value="Final Delivery">
                                Final Delivery
                              </option>
                            </select>
                            <svg
                              style={{ position: "absolute", left: "355" }}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                fill="black"
                              />
                            </svg>
                          </div>

                          <>
                            <div className={styles.formElement}>
                              <label>Receiving Party:</label>
                              <input
                                type="text"
                                name="Receiving_Party"
                                value={dispatchdata.Receiving_Party}
                                // onChange={(e) =>
                                //   handleOnChange(e, "Receiving_Party")
                                // }
                                onChange={(e) => handleOnChange(e, index)}
                              />
                            </div>
                            <div
                              className={`${styles.formElement} ${styles.datepickerInput}`}>
                              <label>Delivery Date:</label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CustomDatePicker
                                  name="Deliverydate"
                                  value={
                                    dispatchdata.Delivery_date
                                      ? dayjs(
                                        dispatchdata.Delivery_date,
                                        "DD/MM/YYYY"
                                      )
                                      : null
                                  }
                                  // onChange={(value) =>
                                  //   handleDates("Delivery_date", value)
                                  // }
                                  onChange={(value) =>
                                    handleDates(index, value)
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  format="DD/MM/YYYY"
                                  sx={{
                                    backgroundColor: "white",
                                    borderRadius: "8px",
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className={styles.formElement}>
                              <label>
                                PO Number:
                                <br />
                                (Issued by Ergon)
                              </label>
                              <input
                                type="number"
                                name="Ponumber"
                                value={dispatchdata.Ponumber}
                                // onChange={(e) => handleOnChange(e, "Ponumber")}
                                onChange={(e) => handleOnChange(e, index)}
                              />
                            </div>
                            <div className={styles.formElement}>
                              <label>
                                Quote Number:
                                <br />
                                (Issued by Vendor)
                              </label>
                              <input
                                type="number"
                                name="Quote_number"
                                value={dispatchdata.Quote_number}
                                // onChange={(e) =>
                                //   handleOnChange(e, "Quote_number")
                                // }
                                onChange={(e) => handleOnChange(e, index)}
                              />
                            </div>
                            <div style={{ marginTop: "-7.50%" }}>
                              <div>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    marginLeft: "489px",
                                    padding: "10px 0px",
                                    alignItems: "center",
                                    border: "1px",
                                    marginTop: "-160px",
                                  }}>
                                  <label
                                    htmlFor="upload3"
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                    }}>
                                    PO Document:
                                  </label>
                                  <input
                                    type="file"
                                    name="upload3"
                                    id="upload3"
                                    onInput={(e) =>
                                      handlefileupload3("upload3", e)
                                    }
                                    style={{ display: "none", }}
                                  />
                                  <div
                                    className={styles.textbox3}
                                    style={{
                                      position: "relative",
                                      marginLeft: "56px",
                                      marginBottom: "3px",
                                      
                                    }}
                                    onClick={() =>
                                      document.getElementById("upload3").click()
                                    }>
                                    <label
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "5px",
                                        // color: "rgba(128, 128, 128, 0.8)",
                                        overflow: "hidden", // Hide overflow text
                                        textOverflow: "ellipsis", // Display ellipsis for overflow text
                                        whiteSpace: "nowrap", // Prevent wrapping
                                        maxWidth: "180px", // Set maximum width for the file name
                                        alignItems: "center",

                                      }}>
                                      {isPODocumentMarkedForRemoval
                                        ? "Upload File"
                                        : dispatchdata.PO_document
                                          ? extractFileName(
                                            dispatchdata.PO_document
                                          )
                                          : "Upload File"}
                                    </label>

                                    <svg
                                      width="25"
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "10px", // Adjust as needed
                                        transform: "translateY(-50%)",
                                        cursor: "pointer",
                                      }}
                                      height="25"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                        fill="#212529"
                                      />
                                      <path
                                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                        fill="#212529"
                                      />
                                    </svg>
                                  </div>
                                  {dispatchdata?.PO_document && (
                                    <div
                                      className="btnClose"
                                      onClick={() =>
                                        handlecross("PO_document")
                                      }>
                                      <svg
                                        width="25"
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                        }}
                                        height="25"
                                        viewBox="0 0 20 20"
                                        fill="grey"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                                          fill="#212529"
                                        />
                                        <path
                                          d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                                          fill="#212529"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    marginLeft: "487px",
                                    padding: "10px 0px",
                                    alignItems: "center",
                                    border: "1px",
                                    marginTop: "29px",
                                  }}>
                                  <label
                                    htmlFor="upload4"
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                    }}>
                                    Quote Document:
                                  </label>
                                  <input
                                    type="file"
                                    name="upload4"
                                    id="upload4"
                                    onInput={(e) =>
                                      handlefileupload4("upload4", e)
                                    }
                                    style={{ display: "none" }}
                                  />
                                  <div
                                    className={styles.textbox3}
                                    style={{ position: "relative" }}
                                    onClick={() =>
                                      document.getElementById("upload4").click()
                                    }>
                                    <label
                                      style={{
                                        marginLeft: "15px",
                                        marginTop: "5px",
                                        // color: "rgba(128, 128, 128, 0.8)",
                                        overflow: "hidden", // Hide overflow text
                                        textOverflow: "ellipsis", // Display ellipsis for overflow text
                                        whiteSpace: "nowrap", // Prevent wrapping
                                        maxWidth: "180px", // Set maximum width for the file name
                                        alignItems: "center",
                                      }}>
                                      {isQuoteDocumentMarkedForRemoval
                                        ? "Upload File"
                                        : dispatchdata.Quote_document
                                          ? extractFileName(
                                            dispatchdata.Quote_document
                                          )
                                          : "Upload File"}
                                    </label>

                                    <svg
                                      width="25"
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "10px", // Adjust as needed
                                        transform: "translateY(-50%)",
                                        cursor: "pointer",
                                      }}
                                      height="25"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                        fill="#212529"
                                      />
                                      <path
                                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                        fill="#212529"
                                      />
                                    </svg>
                                  </div>{" "}
                                  {dispatchdata?.Quote_document && (
                                    <div
                                      className="btnClose"
                                      onClick={() =>
                                        handlecross("Quote_document")
                                      }>
                                      <svg
                                        width="25"
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                        }}
                                        height="25"
                                        viewBox="0 0 20 20"
                                        fill="grey"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                                          fill="#212529"
                                        />
                                        <path
                                          d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                                          fill="#212529"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      ))}
                  </>
                </form>
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
        {!isItemsEdit ? (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
              <button
                style={{ borderRadius: '14px' }}
                onClick={handleEditItems}
                className={`${styles["outgoingButton2"]} ${!isedit2 ? styles["outgoing2"] : styles["not-outgoing2"]}`}
              >
                Edit
              </button>
            </div>
            <div className={styles.itemstablecontainer}>
              <table className={styles.itemstable}>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Item Name</th>
                    <th>Part Number</th>
                    <th>Part Traceability Number</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Equipment Traceability Number</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Ergon Traceability Number</th>
                    <th>Type</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Max Available Qty</th>
                    <th>Dispatch Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {finalDispatchData?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.Item_Name}</td>
                      <td>{item.Part_Number}</td>
                      <td>{item.Part_Traceability_Number}</td>
                      <td>{item.Equipment_Traceability_Number}</td>
                      <td>{item.Ergon_Traceability_Number}</td>
                      <td>{item.Production_Type}</td>
                      <td>{item.availableQty}</td>
                      <td style={{ position: 'relative' }}>
                        <input
                          type="number"
                          style={{
                            border: '1px solid #5B6BE1',
                            maxWidth: '55px',
                            outline: 'none',
                            paddingLeft: '5px',
                          }}
                          value={item.Dispatch_qty}
                          onChange={(event) => handleQuantityChange(event, index)}
                          disabled={!isedit2}
                          min="0"
                        />
                        {errors[index] && (
                          <p
                            style={{
                              color: 'red',
                              fontWeight: '400',
                              fontSize: '13px',
                              position: 'absolute',
                              left: 0,
                              width: '100%',
                              textAlign: 'center',
                            }}
                          >
                            Incoming Qty can't exceed {item.availableQty}
                          </p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginBottom: '10px' }}>
              <button
                onClick={handleSaveItems}
                variant="contained"
                className={styles.saveButton}
              >
                Save
              </button>
              <button
                onClick={handleCancelItems}
                variant="outlined"
                className={styles.cancelBtn}
              >
                Cancel
              </button>
            </div>
            <div className={styles.itemstablecontainer}>
              <table className={styles.itemstable}>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Item Name</th>
                    <th> Part Number</th>
                    <th> Part Traceability Number</th>
                    <th>Equipment Traceability Number</th>
                    <th>Ergon Traceability Number</th>
                    <th>Type</th>
                    <th>Max Available Qty</th>
                    <th>Dispatch Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {finalDispatchData?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.Item_Name}</td>
                      <td>{item.Part_Number}</td>
                      <td>{item.Part_Traceability_Number}</td>
                      <td>{item.Equipment_Traceability_Number}</td>
                      <td>{item.Ergon_Traceability_Number}</td>
                      <td>{item.Production_Type}</td>
                      <td>{item.availableQty}</td>
                      <td style={{ position: 'relative' }}>
                        <input
                          type="number"
                          style={{
                            border: '1px solid #5B6BE1',
                            maxWidth: '55px',
                            outline: 'none',
                            paddingLeft: '5px',
                          }}
                          value={item.Dispatch_qty}
                          onChange={(event) => handleQuantityChange(event, index)}
                          min="0"
                        />
                        {errors[index] && (
                          <p
                            style={{
                              color: 'red',
                              fontWeight: '400',
                              fontSize: '13px',
                              position: 'absolute',
                              left: 0,
                              width: '100%',
                              textAlign: 'center',
                            }}
                          >
                            Incoming Qty can't exceed {item.availableQty}
                          </p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      
      )}
    </div>
  );
}

export default DispatchUpdate;
