import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "../Components/css/Timesheet.module.css";
import { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import the plugin
import axios from "axios";
import saveAs from "file-saver";
//import Timesheettemp from './Timesheettemp.js';
import Modal from "@mui/material/Modal";
//import html2pdf from 'html2pdf.js';
import { useParams, NavLink } from "react-router-dom";
import Erogonlogo from "../../src/Components/Erogonlogo.png";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Timesheet(props) {
  const { ID, superarray, techarray, trainarray } = props;
  const [monthValue, setMonthValue] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [yearValue, setYearValue] = useState(null);
  const [data, setData] = useState([]);
  const [uploadopen, setUploadopen] = useState(false);
  const mergedarray = [
    ...(Array.isArray(superarray) ? superarray : []),
    ...(Array.isArray(techarray) ? techarray : []),
    ...(Array.isArray(trainarray) ? trainarray : []),
  ];
  const [uploadfile, setUploadfile] = useState("");
  const [filedata, setFiledata] = useState([]);
  const [timesheetpath, setTimesheetpath] = useState("");
  //const [temptimesheet, setTemptimesheet] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [featureuploadts, setfeatureuploadts] = useState([]);
  const [featuredownts, setfeaturedownts] = useState([]);
  const [featureviewts, setfeatureviewts] = useState([]);
  const [featureuploadagain, setfeatureuploadagain] = useState([]);
  const [filename, setFilename] = useState("");
  const { id } = useParams();

  const all = 2;
  //const [dynamicContent, setdynamiccontent] = useState([])

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}/${month}/${year}`;
  }

  function getyear() {
    const today = new Date();
    const year = today.getFullYear();
    return `${year}`;
  }

  const [currentDate, setCurrentDate] = useState(getDate());
  const [currentyear, setCurrentyear] = useState(getyear());
  const [selectedyear, setselectedyear] = useState(getyear());

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        const filgroupfeatures15 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Timesheet" &&
            el.Feature_Name === "Upload Signed Timesheet" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
        const filgroupfeatures16 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Timesheet" &&
            el.Feature_Name === "Download Timesheet" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
        const filgroupfeatures17 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Timesheet" &&
            el.Feature_Name === "View Signed Timesheet" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
        const filgroupfeatures18 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Timesheet" &&
            el.Feature_Name === "Upload Again" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
        setfeatureuploadts(filgroupfeatures15);
        setfeaturedownts(filgroupfeatures16);
        setfeatureviewts(filgroupfeatures17);
        setfeatureuploadagain(filgroupfeatures18);
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      console.log("@@@@@@@@@@@@2", props.ID);
      console.log("#############", res.data.data);
      console.log(filter);

      setProjectList(filter);
      console.log(projectList);
    };

    fetchProject();
  }, []);

  const handlesubmit = () => {
    setUploadopen(true);
  };

  const uploadFile = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const fileInput = document.getElementById("fileInput");

    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        return;
      }

      setFilename(selectedFile.name);

      setUploadfile(selectedFile);

      const fileURL = URL.createObjectURL(selectedFile);

      // Set the file preview URL in the state
      setFilePreview(fileURL);

      // const formData = new FormData();
      // formData.append('Time_sheet', uploadfile);

      // const res = await axios.put(
      //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheetdata_update?ID_No=${ID}&Month=${monthValue}&Year=${yearValue}`,
      //   formData,
      //   {
      //     headers: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      //   }
      // );
      // console.log(res.data.message);

      // const filePath = res.data.filePath;

      // const res1 = await axios.get(
      //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheet_path?ID_No=${ID}&Month=${monthValue}&Year=${yearValue}`
      // );

      // setFiledata(res1.data);
      // setTimesheetpath(filedata.timesheet);
      // setTemptimesheet(filedata.temp);

      setUploadopen(true);
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed
    }
  };

  const handlePreviewButtonClick = () => {
    // Display the file preview when the button is clicked
    if (filePreview) {
      window.open(filePreview, "_blank");
    }
  };

  const handleClose = () => {
    setUploadopen(false);
  };

  // const dynamicContent = Timesheettemp(data);

  const [downLoadStart, setDownLoadStart] = useState(false);

  const table1Ref = useRef(null);
  const table2Ref = useRef(null);
  const table3Ref = useRef(null);


  const generatePDF1 = async () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      format: [375, 265],
    });
  
    const imgWidth = 45;
    const imgHeight = 12;
    const x = doc.internal.pageSize.getWidth() - 60;
    const y = 12;
  
    doc.addImage(Erogonlogo, 'PNG', x, y, imgWidth, imgHeight);
  
    const headerText = "ERGON TECHNOLOGIES TIMESHEET";
    const headerFontSize = 16;
    const headerHeight = 18;
    const headerMargin = 9;
  
    // Calculate the width of the text
    doc.setFontSize(headerFontSize);
    const textWidth = doc.getTextWidth(headerText);
    const textX = (doc.internal.pageSize.getWidth() - textWidth) / 2;
  
    // Draw the box
    doc.setFillColor('#5B6BE1');
    doc.rect(12, headerMargin, doc.internal.pageSize.getWidth() - 78, headerHeight, 'F');
  
    // Set the text color to white
    doc.setTextColor(255, 255, 255);
    doc.setFont('Helvetica', 'bold');
      
    doc.text("ERGON TECHNOLOGIES TIMESHEET", (doc.internal.pageSize.getWidth() - 55) / 2, 20, { align: 'center', fontSize: 14 });

    // Reset text color to black for the rest of the document
    doc.setTextColor(0, 0, 0);
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(6);
  
    setTimeout(() => {
      const table1 = table1Ref.current;
      const table2 = table2Ref.current;
      const table3 = table3Ref.current;
  
      console.log("table1", table1);
      console.log("table2", table2);
      console.log("table3", table3);
  
      doc.autoTable({
        html: table1,
        startY: 35,
        theme: 'grid',
        styles: {
          cellPadding: 2,
          fontSize: 10,
          cellWidth: 'wrap',
          cellHeight: 6,
        },
        columnStyles: {
          0: { cellWidth: 40, fontStyle: 'bold' },
          1: { cellWidth: 65 },
        },
        tableLineColor: [0, 0, 0],
        didDrawCell: function (data) {
          const { cell, doc } = data;
          doc.setDrawColor(0, 0, 0);
          doc.rect(cell.x, cell.y, cell.width, cell.height);
        },  
      });
  
      doc.autoTable({
        html: table2,
        startY: doc.autoTable.previous.finalY + 10,
        theme: 'grid',
        headStyles: {
          fillColor: '#5B6BE1',
          textColor: [255, 255, 255],
          fontSize: 10,
          fontStyle: 'bold',
          halign: 'center',
          align: 'center',
        },
        bodyStyles: {
          cellPadding: 2,
          fontSize: 8,
          cellWidth: 'wrap',
          cellHeight: 6,
        },
        columnStyles: {
          0: { cellWidth: 12 },
        },
        didParseCell: function (data) {
          console.log("table2 data", data);
          if (data.row.index === 0 && data.row.section === "body") {
            data.cell.styles.fontStyle = "bold"; // Make the text bold
          }
        },
        tableLineColor: [0, 0, 0],
        didDrawCell: function (data) {
          const { cell, doc } = data;
          doc.setDrawColor(0, 0, 0);
          doc.rect(cell.x, cell.y, cell.width, cell.height);
        },  
      });
  
      doc.autoTable({
        html: table3,
        startY: doc.autoTable.previous.finalY + 10,
        theme: 'grid',
        headStyles: {
          fillColor: '#5B6BE1',
          textColor: [255, 255, 255],
          fontSize: 10,
          fontStyle: 'bold',
          halign: 'center',
          align: 'center',
        },
        styles: {
          cellPadding: 2,
          fontSize: 10,
          cellWidth: 'wrap',
        },
        columnStyles: {
          0: { cellWidth: 80 },
          2: { cellWidth: 100 },
        },
        didParseCell: function (data) {
          console.log("table3 data", data);
          if (data.section === "body") {
            data.row.height = 50;
          }
        },
        tableLineColor: [0, 0, 0],
        didDrawCell: function (data) {
          const { cell, doc } = data;
          doc.setDrawColor(0, 0, 0);
          doc.rect(cell.x, cell.y, cell.width, cell.height);
        },  
      });
  
      doc.setDrawColor(192, 192, 192);
      doc.line(12, doc.internal.pageSize.getHeight() - 12, doc.internal.pageSize.getWidth() - 12, doc.internal.pageSize.getHeight() - 12); // Draw a line in the footer
      doc.setFontSize(10);
      doc.text("TSD-OPS-FOR-001-19,Rev2", 15, doc.internal.pageSize.getHeight() - 6);
      doc.text("30 May 2024", doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 6, { align: 'center' });
      doc.text("1", doc.internal.pageSize.getWidth() - 17, doc.internal.pageSize.getHeight() - 6);
      doc.save('TIMESHEET.pdf');
    }, 500);
  };
    
  // const generatePDF1 = async () => {
  //   await setDownLoadStart(true);
  //   const input = document.getElementById("TIMESHEET");
  //   const inputHeight = document.body.clientHeight - 870;
    //   const inputWidth = document.body.clientWidth - 30;
    //   html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      //     (canvas) => {
        //       const imgData = canvas.toDataURL("image/png");
        //       const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        //       const pdfWidth = pdf.internal.pageSize.getWidth();
        //       const pdfHeight = pdf.internal.pageSize.getHeight();
        //       pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        //       // Save or preview the PDF
        //       pdf.save("TIMESHEET.pdf");
      //     }
  //   );
    //   setDownLoadStart(false);
  // };
  const downloadPdfBtn = (e) => {
    e.preventDefault();
    generatePDF1();
  };

  const handleedit = async (e) => {
    e.preventDefault();
    setUploadopen(false);

    const formData = new FormData();
    formData.append("Time_sheet", uploadfile);

    const res = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheetdata_update?ID_No=${ID}&Month=${monthValue}&Year=${yearValue}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
   
    console.log(res.data.message);

    const res1 = await axios.get(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheet_path?ID_No=${ID}&Month=${monthValue}&Year=${yearValue}`
    );
   // alert(JSON.stringify(res1.data));
    console.log(yearValue);
    console.log(monthValue);
    setFiledata(res1.data);

    console.log(res1.data.message);

    // setTemptimesheet(filedata.temp);
  };

  useEffect(() => {
    setTimesheetpath(filedata.timesheet);
  }, [filedata]);

  const handledownloadfile = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = ""; // Set desired filename
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };

  const fetchAttendanceData = async (i, j,k) => {
    try {
      const response = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheetdata?ID_No=${k}&Month=${i}&Year=${j}`
      );

      setData(response.data);

      const res1 = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheet_path?ID_No=${k}&Month=${i}&Year=${j}`
      );

      setTimesheetpath(res1.data.timesheet);
      //setTemptimesheet(res1.data.temp);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const calculatestatus = (data, tech) => {
    const statusCounts = { W: 0, S: 0, T: 0 };
    for (const entry of data) {
      if (entry.tech === tech) {
        if (entry.status === "Work") {
          statusCounts.W++;
        } else if (entry.status === "Standby") {
          statusCounts.S++;
        } else if (entry.status === "Travel") {
          statusCounts.T++;
        }
      }
    }

    return statusCounts;
  };

  useEffect(() => {
    fetchAttendanceData(monthValue,yearValue, ID);
  }, [monthValue,yearValue]);

  function numberOfDaysInMonth(monthName) {
    const monthsWith31Days = [
      "January",
      "March",
      "May",
      "July",
      "August",
      "October",
      "December",
    ];
    const monthsWith30Days = ["April", "June", "September", "November"];

    if (monthsWith31Days.includes(monthName)) {
      return 31;
    } else if (monthsWith30Days.includes(monthName)) {
      return 30;
    } else if (monthName === "February") {
      // You might need to implement a more complex logic to handle leap years
      return 28;
    } else if (monthName === "Select Month") {
      return 0;
    }
  }

  const months = [
    "Select Month",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setMonthValue(selectedMonth);
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setYearValue(selectedYear);
  };

  const headings1 = Array.from(
    { length: numberOfDaysInMonth(monthValue) },
    (_, index) => index + 1
  );
  console.log(headings1);

  return (
    <>
      <div>
        {true && (
          <div className="workplancontainer" style={{ display: "none" }}>
            <div
              className="col col-lg-6 mx-auto d-flex justify-content-center"
              style={{ marginTop: "33px" }}>
              <form id="TIMESHEET">
                <div style={{ display: "inline-flex", width: "105%" }}>
                  <div className={styles.mainheadingts}>
                    ERGON TECHNOLOGIES TIMESHEET{" "}
                  </div>
                  <img
                    src={Erogonlogo}
                    className="ergonlogo2"
                    height="69"
                    width="299"
                    style={{ marginLeft: "1%" }}
                  />
                </div>
                <table ref={table1Ref} className={styles.table1ts}>
                  <tbody>
                    <tr>
                      <td scope="row">DATE</td>
                      <td>{currentDate}</td>
                    </tr>
                    <tr>
                      <td scope="row">RIG </td>
                      <td>{projectList[0]?.Rig_Name} </td>
                    </tr>
                    <tr>
                      <td scope="row">LOCATION</td>
                      <td>Offshore </td>
                    </tr>
                    <tr>
                      <td scope="row">PO NUMBER</td>
                      <td>{projectList[0]?.Po_No}</td>
                    </tr>
                    <tr>
                      <td scope="row">COUNTRY</td>
                      <td>{projectList[0]?.Rig_Location}</td>
                    </tr>
                    <tr>
                      <td scope="row">TASK DESCRIPTION</td>
                      <td>{projectList[0]?.Service_Component}</td>
                    </tr>
                  </tbody>
                </table>

                <table ref={table2Ref} className={styles.table2ts}>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th colSpan={all} className={styles.aligncenter}>
                        Personnel
                      </th>
                      <th
                        colSpan={numberOfDaysInMonth(monthValue)}
                        className={styles.aligncenter2}>
                        {monthValue}-{currentyear}
                      </th>
                      <th>TOTAL WORK DAYS</th>
                      <th>TOTAL STANDBY DAYS</th>
                      <th>TOTAL TRAVEL DAYS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={styles.addborder}>
                      <td
                        style={{
                          padding: "20px 7px",
                          border: "1px solid black",
                          borderRadius: "0px",
                        }}></td>
                      <td
                        style={{
                          padding: "20px 7px",
                          border: "1px solid black",
                          borderRadius: "0px",
                        }}>
                        Name
                      </td>
                      <td
                        style={{
                          padding: "20px 7px",
                          border: "1px solid black",
                          borderRadius: "0px",
                        }}>
                        Position
                      </td>
                      {headings1.map((heading, index) => (
                        <td
                          key={index}
                          style={{
                            padding: "20px 7px",
                            marginTop: "10px",
                            border: "1px solid black",
                            borderRadius: "0px",
                          }}>
                          {index + 1}
                        </td>
                      ))}
                      <td
                        style={{
                          padding: "20px 7px",
                          border: "1px solid black",
                          borderRadius: "0px",
                        }}>
                        W
                      </td>
                      <td
                        style={{
                          padding: "20px 7px",
                          border: "1px solid black",
                          borderRadius: "0px",
                        }}>
                        S
                      </td>
                      <td
                        style={{
                          padding: "20px 7px",
                          border: "1px solid black",
                          borderRadius: "0px",
                        }}>
                        T
                      </td>
                    </tr>
                    {mergedarray.map((e, index) => {
                      const techstatuscount = calculatestatus(data, e); // Calculate status count here

                      if (e !== "") {
                        return (
                          <tr key={index} className={styles.blackborder}>
                            <td
                              style={{
                                padding: "5px 7px",
                                marginTop: "5px",
                                border: "1px solid black",
                              }}
                              className={styles.blackborder}>
                              {index + 1}
                            </td>
                            <td
                              style={{
                                padding: "5px 7px",
                                marginTop: "5px",
                                border: "1px solid black",
                              }}
                              className={styles.blackborder}>
                              {e}
                            </td>
                            {superarray && superarray.includes(e) && (
                              <td
                                style={{
                                  padding: "5px 7px",
                                  marginTop: "5px",
                                  border: "1px solid black",
                                }}
                                className={styles.blackborder}>
                                Supervisor
                              </td>
                            )}
                            {techarray && techarray.includes(e) && (
                              <td
                                style={{
                                  padding: "5px 7px",
                                  marginTop: "5px",
                                  border: "1px solid black",
                                }}
                                className={styles.blackborder}>
                                Technician
                              </td>
                            )}
                            {trainarray && trainarray.includes(e) && (
                              <td
                                style={{
                                  padding: "5px 7px",
                                  marginTop: "5px",
                                  border: "1px solid black",
                                }}
                                className={styles.blackborder}>
                                Trainee
                              </td>
                            )}
                            {headings1.map((heading, colindex) => {
                              const found = data.find(
                                (item) =>
                                  parseInt(item.day) === colindex + 1 &&
                                  item.tech === e
                              );

                              let displayValue = "";
                              if (found) {
                                if (found.status === "Work") {
                                  displayValue = "W";
                                } else if (found.status === "Travel") {
                                  displayValue = "T";
                                } else if (found.status === "Standby") {
                                  displayValue = "S";
                                }
                              }
                              return (
                                <td
                                  key={colindex}
                                  style={{
                                    padding: "20px 7px",
                                    marginTop: "10px",
                                    border: "1px solid black",
                                  }}
                                  className={styles.blackborder}>
                                  {displayValue}
                                </td>
                              );
                            })}
                            <td
                              style={{
                                padding: "5px 7px",
                                marginTop: "10px",
                                border: "1px solid black",
                              }}
                              className={styles.blackborder}>
                              {techstatuscount.W}
                            </td>
                            <td
                              style={{
                                padding: "5px 7px",
                                marginTop: "10px",
                                border: "1px solid black",
                              }}
                              className={styles.blackborder}>
                              {techstatuscount.S}
                            </td>
                            <td
                              style={{
                                padding: "5px 7px",
                                marginTop: "10px",
                                border: "1px solid black",
                              }}
                              className={styles.blackborder}>
                              {techstatuscount.T}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
                <table ref={table3Ref} className={styles.table3ts}>
                  <thead>
                    <tr>
                      <th>Comments:</th>
                      <th>Client Representative Signature and Official Stamp</th>
                      <th>Employee Signature</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                 <div className={styles.timesheettextContainer}>
          <div>TSD-OPS-FOR-001-19,Rev2</div>
          <div> 30 May 2024 </div>
          <div>1</div>
        </div>
              </form>
            </div>
          </div>
        )}
      </div>

<div className={styles.boxgroup}>
  <div className={styles.relativeContainer}>
    <label className={styles.monthlabel}>Select Month</label>
    <div className={styles.selectContainer}>
      <select
        onChange={handleMonthChange}

        value={monthValue}
        className={styles.monthinputbox}
        style={{ position: "relative" }}>
        {months.map((month, index) => (
          <option key={index} value={month} className={styles.drop}>
            {month}
          </option>
        ))}
      </select>
      <svg
                        className={styles.svgSelect}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z"
                          fill="black"
                        />
                      </svg>
    </div>
  </div>
        {/* <div>
          {timesheetpath && monthValue && featuredownts.length > 0 && (
            <button className={styles.button2} onClick={downloadPdfBtn}>
              Download Timesheet for Signature{" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </div> */}

        <div className={styles.relativeContainer}>
    <label className={styles.monthlabel}>Select Year</label>
    <div className={styles.selectContainer}>
      <select
        onChange={handleYearChange}
        value={selectedYear}
        className={styles.monthinputbox}
        style={{ position: "relative" }}>
        <option value="Select Year" className={styles.drop}>Select Year</option>  
        <option value="2022" className={styles.drop}>2022</option>
        <option value="2023" className={styles.drop}>2023</option>
        <option value="2024"className={styles.drop}>2024</option>
        {/* Add more years as needed */}
        <svg
                        className={styles.svgSelect}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z"
                          fill="black"
                        />
                      </svg>
      </select>
      <svg
                        className={styles.svgSelect}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z"
                          fill="black"
                        />
                      </svg>
    </div>
  </div>
    </div>

      

      {monthValue && monthValue !== "Select Month" && (
        <div className={styles.buttongroupts}>
          {!timesheetpath ? (
            featureuploadts.length > 0 && (
              <>
                <button
                  className={styles.button1}
                  for="fileInput"
                  onClick={uploadFile}>
                  Upload Signed Timesheet
                </button>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  id="fileInput"
                  className={styles.fileInput}
                />
              </>
            )
          ) : (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  marginTop: "20px",
                  color: "#586BE1",
                  fontWeight: "700",
                  marginRight: "20px",
                }}>
                Signed Timesheet:{" "}
              </div>
              {featureviewts.length > 0 && (
                <a href={timesheetpath} target="_blank" rel="noreferrer">
                  <button className={styles.button1} style={{ width: "80px" }}>
                    View
                  </button>
                </a>
              )}
              <button
                className={styles.button1}
                onClick={() => handledownloadfile(timesheetpath)}>
                Download
              </button>
              {featureuploadagain.length > 0 && (
                <>
                  <button className={styles.button1} onClick={uploadFile}>
                    Upload Again
                  </button>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".pdf"
                    id="fileInput"
                    className={styles.fileInput}
                  />
                   <div>
          {timesheetpath && monthValue && featuredownts.length > 0 && (
            <button className={styles.button2} onClick={downloadPdfBtn}>
              Download Timesheet for Signature{" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </div>
                </>
              )}
            </div>
          )}
          <Modal
            open={uploadopen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backdropFilter: "blur(4px)" }}>
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                background: "white",
                border: "none",
                boxShadow: 24,
                padding: "30px",
              }}>
              <div style={{ fontFamily: "Open Sans" }}>
                <h5 style={{ textAlign: "center" }}>Upload Signed Timesheet</h5>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "30px",
                    marginLeft: "45px",
                  }}>
                  <h6
                    style={{
                      fontWeight: "400",
                      color: "#567191",
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                    }}>
                    Selected Month:
                  </h6>
                  <div style={{ fontWeight: "700", fontSize: "16px" }}>
                    {monthValue}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "15px",
                    marginLeft: "45px",
                  }}>
                  <h6 style={{ fontWeight: "400", color: "#567191" }}>
                    Document:
                  </h6>
                  <input
                    type="text"
                    disabled
                    value={filename}
                    style={{
                      border: "none",
                      background: "white",
                      marginLeft: "30px",
                    }}></input>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "15px",
                    marginLeft: "45px",
                  }}>
                  <h6 style={{ fontWeight: "400", color: "#567191" }}>
                    Actions:
                  </h6>
                  <label
                    for="fileInput"
                    onClick={uploadFile}
                    style={{
                      marginLeft: "25px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      fontSize: "14px",
                    }}>
                    Upload Again{" "}
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.83398 10.9999C1.83398 6.67871 1.83398 4.5181 3.17642 3.17568C4.51884 1.83325 6.67944 1.83325 11.0007 1.83325C15.3218 1.83325 17.4825 1.83325 18.8249 3.17568C20.1673 4.5181 20.1673 6.67871 20.1673 10.9999C20.1673 15.3211 20.1673 17.4818 18.8249 18.8241C17.4825 20.1666 15.3218 20.1666 11.0007 20.1666C6.67944 20.1666 4.51884 20.1666 3.17642 18.8241C1.83398 17.4818 1.83398 15.3211 1.83398 10.9999ZM11.0007 16.2708C11.3803 16.2708 11.6882 15.9629 11.6882 15.5833V10.8264L13.2645 12.4027C13.533 12.6712 13.9683 12.6712 14.2368 12.4027C14.5053 12.1342 14.5053 11.699 14.2368 11.4305L11.4868 8.68045C11.3579 8.55152 11.183 8.47909 11.0007 8.47909C10.8183 8.47909 10.6434 8.55152 10.5145 8.68045L7.76452 11.4305C7.49603 11.699 7.49603 12.1342 7.76452 12.4027C8.033 12.6712 8.4683 12.6712 8.73679 12.4027L10.3132 10.8264V15.5833C10.3132 15.9629 10.621 16.2708 11.0007 16.2708ZM7.33398 7.10409C6.95429 7.10409 6.64648 6.79628 6.64648 6.41659C6.64648 6.03689 6.95429 5.72909 7.33398 5.72909H14.6673C15.047 5.72909 15.3548 6.03689 15.3548 6.41659C15.3548 6.79628 15.047 7.10409 14.6673 7.10409H7.33398Z"
                        fill="black"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".pdf"
                    className={styles.fileInput}
                  />
                  |
                  <a
                    href={filePreview}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}>
                    View
                  </a>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "30px",
                    marginLeft: "25px",
                  }}>
                  <button
                    style={{
                      borderRadius: "8px",
                      background: "#586BE1",
                      boxShadow: "1px 8px 24px 0px rgba(125, 142, 231, 0.40)",
                      width: "160px",
                    }}
                    onClick={handleedit}>
                    Submit
                  </button>
                  <button
                    style={{
                      borderRadius: "8px",
                      background: "#586BE1",
                      boxShadow: "1px 8px 24px 0px rgba(125, 142, 231, 0.40)",
                      width: "160px",
                    }}
                    onClick={handleClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          {!timesheetpath && featuredownts.length > 0 && (
            <button className={styles.button2} onClick={downloadPdfBtn}>
              Download Timesheet for Signature{" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </div>
      )}
      {monthValue && monthValue !== "Select Month" && (
        <table className={styles.maintable}>
          <thead>
            <th
              style={{
                padding: "20px 7px",
                marginTop: "10px",
                border: "0.5px solid lightgrey",
                borderRadius: "0px",
              }}>
              S.No
            </th>
            <th
              style={{
                padding: "20px 7px",
                marginTop: "10px",
                border: "0.5px solid lightgrey",
                borderRadius: "0px",
              }}>
              Name
            </th>
            <th
              style={{
                padding: "20px 7px",
                marginTop: "10px",
                border: "0.5px solid lightgrey",
                borderRadius: "0px",
              }}>
              Designation
            </th>
            <th
              style={{
                padding: "20px 7px",
                marginTop: "10px",
                border: "0.5px solid lightgrey",
                borderRadius: "0px",
              }}>
              W
            </th>
            <th
              style={{
                padding: "20px 7px",
                marginTop: "10px",
                border: "0.5px solid lightgrey",
                borderRadius: "0px",
              }}>
              S
            </th>
            <th
              style={{
                padding: "20px 7px",
                marginTop: "10px",
                border: "0.5px solid lightgrey",
                borderRadius: "0px",
              }}>
              T
            </th>
            {headings1.map((heading, index) => (
              <th
                key={index}
                style={{
                  padding: "20px 7px",
                  marginTop: "10px",
                  border: "0.5px solid lightgrey",
                  borderRadius: "0px",
                }}>
                {index + 1}
              </th>
            ))}
          </thead>
          <tbody>
            {mergedarray.map((e, index) => {
              const techstatuscount = calculatestatus(data, e); // Calculate status count here

              if (e !== "") {
                return (
                  <tr key={index}>
                    <td
                      style={{
                        padding: "20px 7px",
                        marginTop: "10px",
                        border: "0.5px solid lightgrey",
                      }}>
                      {index + 1}
                    </td>
                    <td
                      style={{
                        padding: "20px 7px",
                        marginTop: "10px",
                        border: "0.5px solid lightgrey",
                      }}>
                      {e}
                    </td>
                    {superarray && superarray.includes(e) && (
                      <td
                        style={{
                          padding: "20px 7px",
                          marginTop: "10px",
                          border: "0.5px solid lightgrey",
                        }}>
                        Supervisor
                      </td>
                    )}
                    {techarray && techarray.includes(e) && (
                      <td
                        style={{
                          padding: "20px 7px",
                          marginTop: "10px",
                          border: "0.5px solid lightgrey",
                        }}>
                        Technician
                      </td>
                    )}
                    {trainarray && trainarray.includes(e) && (
                      <td
                        style={{
                          padding: "20px 7px",
                          marginTop: "10px",
                          border: "0.5px solid lightgrey",
                        }}>
                        Trainee
                      </td>
                    )}
                    <td
                      style={{
                        padding: "20px 7px",
                        marginTop: "10px",
                        border: "0.5px solid lightgrey",
                      }}>
                      {techstatuscount.W}
                    </td>
                    <td
                      style={{
                        padding: "20px 7px",
                        marginTop: "10px",
                        border: "0.5px solid lightgrey",
                      }}>
                      {techstatuscount.S}
                    </td>
                    <td
                      style={{
                        padding: "20px 7px",
                        marginTop: "10px",
                        border: "0.5px solid lightgrey",
                      }}>
                      {techstatuscount.T}
                    </td>
                    {headings1.map((heading, colindex) => {
                      const found = data.find(
                        (item) =>
                          parseInt(item.day) === colindex + 1 && item.tech === e
                      );

                      let displayValue = "";
                      if (found) {
                        if (found.status === "Work") {
                          displayValue = "W";
                        } else if (found.status === "Travel") {
                          displayValue = "T";
                        } else if (found.status === "Standby") {
                          displayValue = "S";
                        }
                      }
                      return (
                        <td
                          key={colindex}
                          style={{
                            padding: "20px 7px",
                            marginTop: "10px",
                            border: "0.5px solid lightgrey",
                          }}>
                          {displayValue}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
         
      )}
      
      {/* <div style={{display:"none"}}><Timesheettemp ID={ID} mergedarray={mergedarray} data={data}/></div> */}
    </>
  );
}
export default Timesheet;