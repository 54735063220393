import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../css/proffessionalSummary.module.css';
import {ProfessionalTabs} from "./professional_Tabs";


const ProfessionalSummary = ({activeTab, setActiveTab}) => {
    const { name } = useParams(); 
    const [projects, setProjects] = useState([]);
    const [CustomerName, setRigName] = useState(''); 
    const [filterContainer, setFilterContainer] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedScopes, setSelectedScopes] = useState([]); 
    const [documents, setDocuments] = useState({}); 
    const [allProjects, setAllProjects] = useState([]); 
    const navigate = useNavigate(); 
    const [filterItems, setFilterItems] = useState({
    });

    // const openFilterContainer = (e) => {
    //     e.preventDefault();
    //     setFilterContainer(!filterContainer);
    //     setFilterItems({
    //         RigDesign: false,
    //         Scope: false,

    //     });
    // };

    // const handleFilterItemsClick = (e) => {
    //     const name = e.currentTarget.getAttribute("name");
    //     console.log("ans", name)

    //     const updatedFilterItems = {};
    //     for (const key in filterItems) {
    //         console.log('key', key)
    //         updatedFilterItems[key] = key === name;
    //     }

    //     setFilterItems(updatedFilterItems);
    //     console.log("updateFilterItems", updatedFilterItems);
    // };

    const [filterOptions, setFilterOptions] = useState([
        "Jacking System Inspection",
        "Jacking System Remediation",
        "Jacking System Overhaul",
        "Crane System Inspection",
        "Crane Condition Evaluation",
        "Crane System Remediation",
        "Crane System Overhaul",
        "Fixation System Inspection",
        "Fixation System Remediation",
        "Fixation system Overhaul",
        "Skidding System Inspection",
        "Skidding System Remediation",
        "Skidding System Overhaul",
        "Drilling Equipment Inspection",
        "Drilling Equipment Remediation",
        "Braking System Inspection",
        "Braking System Remediation",
        "Anchor Winch Inspection",
        "Supply Of Mechanics",
        "Supply Of Supervisors",
        "Supply Of Electricians"
    ]);

    const [rigOption, setRigOption] = useState([
        "MLT",
        "BMC",
        "Keppel",
        "F&G",
        "Gusto",
        "Modec"
    ])

    // Function to handle navigation
    const handleNavigate = (idNo) => {
        navigate(`/project_checklist/${idNo}`);
    };

    // Fetch rig details and projects
    const fetchRigDetailsAndProjects = async () => {
        try {
            const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/project-details-by-customer/${name}`);
            if (response.data.length > 0) {
                setProjects(response.data); // Set the project details
                setAllProjects(response.data); // Store all projects for future filtering
                fetchDocuments(response.data.map(project => project.ID_No)); // Fetch documents for all projects
            } else {
                console.log("No projects found for this rig.");
            }
        } catch (error) {
            console.error('Error fetching rig details and projects:', error);
        }
    };

    /// Fetch documents based on ID_Nos of projects
    const fetchDocuments = async (idNos) => {
        try {
            const docs = {};
            for (let idNo of idNos) {
                const formData = new FormData();
                formData.append("file", idNo); // Assuming the file needs to be sent for the POST request



                const fieldResponse = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getmanualdocs/${idNo}`

                );

                docs[idNo] = fieldResponse.data; // Store the response data in the documents state
            }
            setDocuments(docs); // Store all fetched documents

        } catch (error) {
            console.error('Error fetching field details:', error);
        }
    };

    useEffect(() => {
        fetchRigDetailsAndProjects();
    }, [name]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedScopes(prevScopes => [...prevScopes, value]);
        } else {
            setSelectedScopes(prevScopes => prevScopes.filter(scope => scope !== value));
        }
    };

    const applyFilters = () => {
        if (selectedScopes.length > 0) {
            // setProjects(allProjects.filter(project => selectedScopes.includes(project.Service_Component, project.Rig_Name)));
            setProjects(allProjects.filter(project =>
                selectedScopes.includes(project.Service_Component) ||  selectedScopes.includes(project.Rig_Type.split(/[ -]/)[0]) 
            ));
        } else {
            setProjects(allProjects); // Show all projects if no scopes are selected
        }
        setFilterContainer(false); // Close the dropdown after applying filters
    };


    const toggleFilterContainer = () => {
        setFilterContainer(!filterContainer);
    };


    const handledownloadfile = (fileUrl) => {
        if (fileUrl !== null) {
            fetch(fileUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = ""; // You can set a filename here
                    link.click();
                    URL.revokeObjectURL(blobUrl);
                })
                .catch((error) => {
                    console.error("Error downloading file:", error);
                });
        } else {
            console.log("There is no file to download");
        }
    };

    return (
        <div className={styles.summaryContainer}>
            <ProfessionalTabs activeTab={activeTab} setActiveTab={setActiveTab} 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery}
            applyFilters={applyFilters}
            toggleFilterContainer={toggleFilterContainer}
            filterContainer={filterContainer}
            filterOptions={filterOptions}
            selectedScopes={selectedScopes}
            handleCheckboxChange={handleCheckboxChange}
            rigOption={rigOption}
            />
            <div className={styles.projectsTableWrapper}>
                <table className={styles.projectsTable}>
                    <thead className={styles.phead} style={{ borderRadius: "10px" }}>
                        <tr>
                            <th style={{ minWidth: "50px", width: "50px" }}>S.No</th>
                            <th style={{ width: "150px", minWidth: "150px" }}>SO No</th>
                            <th style={{ width: "100px", minWidth: "150px" }}>Rig name</th>
                            <th style={{ width: "100px", minWidth: "150px" }}>Design</th>
                            <th style={{ width: "270px", minWidth: "220px" }}>Scope</th>
                            <th style={{ width: "200px", minWidth: "200px" }}>Supervisor</th>
                            <th style={{ width: "250px", minWidth: "250px" }}>Team Details</th>
                            <th style={{ width: "200px", minWidth: "200px" }}>Status Sheet</th>
                            <th style={{ width: "200px", minWidth: "200px" }}>Final Report</th>
                            <th style={{ width: "250px", minWidth: "250px" }}>Duration</th>

                        </tr>
                    </thead>
                    <tbody>
                        {projects.map((project, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td><div className={styles.navigate} onClick={() => handleNavigate(project.ID_No)}>{project.Sales_Order_No}</div></td>
                                <td>{project.Rig_Name}</td>
                                <td>{project.Rig_Type}</td>
                                <td>{project.Service_Component}</td>
                                <td>{project.Supervisor_Names}</td>
                                <td>{project.Technician_Names}</td>

                                <td>
                                    {documents[project.ID_No]?.data[0]?.Status_Sheet ? (
                                        <div className={styles.report}>
                                            <div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" }} >{documents[project.ID_No].data[0]?.Status_Sheet.split('-').pop().split('.').slice(0, -1).join('.')}
                                            </div>
                                            <a href={documents[project.ID_No].data[0]?.Status_Sheet} target="_blank" rel="noopener noreferrer">
                                                <svg className={styles.svg1}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{
                                                        cursor: "pointer"
                                                    }}>
                                                    <path
                                                        d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                                                        fill="black"></path>
                                                    <path
                                                        d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                                                        fill="black"></path>
                                                </svg>
                                            </a>
                                            <button className={styles.download}
                                                onClick={() => handledownloadfile(documents[project.ID_No]?.data[0]?.Status_Sheet)}
                                            >
                                                <svg className={styles.svg1}
                                                    style={{ cursor: "pointer", backgroundColor: "white" }}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                                                        fill="#292D32"
                                                    />
                                                </svg>
                                            </button>
                                        </div>

                                    ) : '-'}


                                </td>
                                <td >
                                    {documents[project.ID_No]?.data[0]?.Final_Report ? (
                                        <div className={styles.report}>
                                            <div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" }}>{documents[project.ID_No].data[0]?.Final_Report.split('-').pop().split('.').slice(0, -1).join('.')}

                                            </div>
                                            <a href={documents[project.ID_No].data[0]?.Final_Report} target="_blank" rel="noopener noreferrer">
                                                <svg className={styles.svg1}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{
                                                        cursor: "pointer"
                                                    }}>
                                                    <path
                                                        d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                                                        fill="black"></path>
                                                    <path
                                                        d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                                                        fill="black"></path>
                                                </svg>
                                            </a>
                                            <button className={styles.download}
                                                onClick={() => handledownloadfile(documents[project.ID_No]?.data[0]?.Final_Report)}
                                            >
                                                <svg className={styles.svg1}
                                                    style={{ cursor: "pointer", backgroundColor: "white" }}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                                                        fill="#292D32"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    ) : '-'}


                                </td>
                                <td>{`${project.Estimated_Date_Of_Commencement} - ${project.Estimated_Project_Completion_Month}`}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    )
}

export default ProfessionalSummary;