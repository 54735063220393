import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_trading_item_details.module.css";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import TabsBarSparesTrading from './TabsBarSparesTrading';
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparesTradingWorkshopItemDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks
  const [openModal, setOpenModal] = useState(false);
  const [inquiryDetails, setInquiryDetails] = useState({ inquiryId: null, inquiryNo: null });
  const [inquiryItems, setInquiryItems] = useState([]); // State to store inquiry items


  const [formData, setFormData] = useState({
    Sales_Order_No: "",
    PO_No: "",
    PO_Date: null,
    Quote_No: "",
    Quote_Date: null,
    Costing_ID: "",
    Customer_Name: "",
    Scope: "",
    Scope_Description: "",
    Estimated_Date_Of_Dispatch: null,
    Ops_Engineer: "",
    Current_Status: "",
  });

  // Function to fetch the inquiry ID based on the PO number
  const fetchInquiryDetails = async () => {
    try {
      const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inquiry_by_po/${formData.PO_No}`);
      if (response.data.length > 0) {
        setInquiryDetails({
          inquiryId: response.data[0].Inquiry_ID,
          inquiryNo: response.data[0].Inquiry_No
        });
        fetchInquiryItems(response.data[0].Inquiry_ID); // Fetch the inquiry items when the inquiry ID is available
      } else {
        setInquiryDetails({ inquiryId: null, inquiryNo: null });
        console.error('No inquiry found for this PO number');
      }
    } catch (error) {
      console.error('Error fetching inquiry details:', error);
    }
  };

   // Function to fetch inquiry items using the inquiry ID
   const fetchInquiryItems = async (inquiryId) => {
    try {
      const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inquiry_items/${inquiryId}`);
      const itemsWithSelection = response.data.map((item) => ({
        ...item,
        isSelected: false,
        qty: "",  // default quantity input value
        uom: "pcs",  // default UoM selection
      }));
      setInquiryItems(itemsWithSelection);
    } catch (error) {
      console.error("Error fetching inquiry items:", error);
    }
  };


  const handleCheckboxChange = (index) => {
    const updatedItems = [...inquiryItems];
    updatedItems[index].isSelected = !updatedItems[index].isSelected;
    setInquiryItems(updatedItems);
  };

  const handleQuantityChange = (index, qty) => {
    const updatedItems = [...inquiryItems];
    updatedItems[index].qty = qty;
      // Automatically check the checkbox when a quantity is entered
  if (qty > 0) {
    updatedItems[index].isSelected = true;
  } else {
    updatedItems[index].isSelected = false;
  }
    setInquiryItems(updatedItems);
  };

  const handleUoMChange = (index, uom) => {
    const updatedItems = [...inquiryItems];
    updatedItems[index].uom = uom;
    setInquiryItems(updatedItems);
  };

  const handleSubmitSelectedItems = async () => {
    const itemsToSubmit = inquiryItems.filter(item => item.isSelected && item.qty > 0);

    const payload = itemsToSubmit.map(item => ({
      Spares_Trading_Project_ID: id,
      Item_Name: item.Item_Name?.trim(),
      Part_Number: item.Part_Number?.trim(),
      UoM: item.uom?.trim(),
      PO_Qty: item.qty?.trim()
    }));

    try {
      const response = await axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_post2", payload);
      if (response.status === 200) {
        alert("Items added successfully!");
        setOpenModal(false);
        setRefresh(!refresh);
      }
    } catch (error) {
      console.error("Error posting selected items:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );

        if (response.data.success) {
          const data = response.data.data[0];
          setFormData({
            Sales_Order_No: data.Sales_Order_No,
            PO_No: data.PO_No,
            PO_Date: data.PO_Date,
            Quote_No: data.Quote_No,
            Quote_Date: data.Quote_Date,
            Costing_ID: data.Costing_ID,
            Customer_Name: data.Customer_Name,
            Scope: data.Scope,
            Scope_Description: data.Scope_Description,
            Estimated_Date_Of_Dispatch: data.Estimated_Date_Of_Dispatch,
            Ops_Engineer: data.Ops_Engineer,
            Current_Status: data.Current_Status,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
    };
    fetchData();
  }, [id]);

  const CustomModal = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

  const [fileContent, setFileContent] = useState([]);   //to store excel/csv file content

  const [rows, setRows] = useState([]);   //to track the rows in the table

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);    // to set the Add Items Manually disabled state

  const [smResults, setsmResults] = useState([]);   // stores the main item details data to be displayed in a table
  const [showInputRow, setShowInputRow] = useState(false);    //an empty input row is added when "Add Items Manually" button is clicked which sets showInputRow to true
  const [showeditRow, setShoweditRow] = useState(false);    //on clicking "Edit" button showeditRow is set true, an additional column containing "Remove" button is displayed
  const [editModesm, seteditModesm] = useState(false);    //on clicking "Edit" button showeditRow is set true, all the rows in the table go into editable state with inputs
  const [refresh, setRefresh] = useState(false);    //refresh state is used to control the useEffect where all the get requests are, explicitly used in Edit, Save, Cancel and other buttons to refresh the data
  const [openRemoveConfirmationModal, setOpenRemoveConfirmationModal] = useState(false);
  const [SNoToDelete, setSNoToDelete] = useState([]);   //to store the S_Nos to delete
  const [confirmationData, setConfirmationData] = useState({
    extractedSNo: null,
    ItemName: "",
    PartNumber: "",
  });   //to store the data of the entry being removed
  const [featureitemdetails, setfeatureitemdetails] = useState([]);
  const [featureitemdetailsedit, setfeatureitemdetailsedit] = useState([]);
  const [linkedSO, setLinkedSO] = useState([]);    //used for storing the Linked Project SO's to display in the select dropdown
  const [itemsMaster, setItemsMaster] = useState([]);   //used for storing the Items Master List data to display in the Item_Name dropdown when anything is typed
  const [formDatasm, setFormDatasm] = useState({    //used for storing the table data in the editable state, editModesm
    data: [],
  });

  const [formDataAddRow, setFormDataAddRow] = useState({    //used for storing the data for the input row on clicking the "Add Items Manually" button
    data: [],
  });

  // ***
  const handleSubmitsm = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const {
      Item_Name,
      Part_Number,
      Linked_PO,
      UoM,
      PO_Qty,
      Remarks,
    } = formDataAddRow;

    const payloadsm = {
      Spares_Trading_Project_ID: id,
      Item_Name: Item_Name?.trim(),
      Part_Number: Part_Number?.trim(),
      Linked_PO: Linked_PO?.trim(),
      UoM: UoM?.trim(),
      PO_Qty: PO_Qty?.trim(),
      Remarks: Remarks?.trim(),
    };

    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_post", // create api in the backend
        payloadsm
      );
      console.log("payloadsm:", payloadsm);

      setFormDataAddRow({
        Item_Name: "",
        Part_Number: "",
        Linked_PO: "",
        UoM: "",
        PO_Qty: "",
        Remarks: "",
      });

      if (res.status === 200) {
        alert("Item Added Successfully");
        setIsLoading(false);
        setRefresh(!refresh);
        setHeightRefresh(!heightRefresh);
      }
      console.log("post POST smResults", smResults);
    } catch (error) {
      console.error("Error submitting data:", error);
      setIsLoading(false);
    }
    setIsButtonDisabled(false);
    setShowInputRow(false);
    setIsLoading(false);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setFormDatasm((prevFormDatasm) => {
      const updatedData = [...prevFormDatasm.data];
      if (!updatedData[index]) {
        updatedData[index] = {};
      }
      updatedData[index] = {
        ...updatedData[index],
        [name]: value,
      };
      return {
        ...prevFormDatasm,
        data: updatedData,
      };
    });
    console.log("formDatasm input change:", formDatasm);
  };

  const handleDropdownSelection = (selectedItem, index) => {
    const { Item_Name, Part_Number } = selectedItem;
    const newData = [...formDatasm.data];
    newData[index] = { ...newData[index], Item_Name, Part_Number: Part_Number };
    setFormDatasm({ data: newData });
    setDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: false }));
    setPartDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: false }));
    setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [index]: true }));
  };

  const handleInputChangevis = (e) => {
    const { name, value } = e.target;
    setFormDataAddRow((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log("formDataAddRow input change:", formDataAddRow);
  };

  const handleDropdownSelectionAddRow = (selectedItem) => {
    const { Item_Name, Part_Number } = selectedItem;
    setFormDataAddRow((prev) => ({
      ...prev,
      Item_Name: Item_Name,
      Part_Number: Part_Number,
    }));
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setIsOEMDisabledAddRow(true);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    setShowInputRow(false);
    setIsButtonDisabled(false);
    setShoweditRow(false);
    seteditModesm(false);
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setDropdownVisible(false);
    setPartDropdownVisible(false);
    for (let i = 0; i < smResults.length; i++) {
      setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [i]: true }));
    }
    setIsOEMDisabledAddRow(true);
    setFormDataAddRow({
      Item_Name: "",
      Part_Number: "",
      Linked_PO: "",
      UoM: "",
      PO_Qty: "",
      Remarks: "",
    });
    setRefresh(!refresh);
  };

  const handleManualInputClick = () => {
    setIsButtonDisabled(true);
    setShowInputRow(true);
    setHeightRefresh(!refresh);
    setFormDataAddRow((prev) => ({
      ...prev,
      UoM: "pcs",
    }));
  };

  console.log("formDataAddRow", formDataAddRow);

  const handleeditsm = () => {
    setShoweditRow(true);
    seteditModesm(true);
    setIsButtonDisabled(true);
  };

  const handlecancelsm = () => {
    setShoweditRow(false);
    seteditModesm(false);
    setShowInputRow(false);
    setIsButtonDisabled(false);
    setSNoToDelete([]);
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setDropdownVisible(false);
    setPartDropdownVisible(false);
    for (let i = 0; i < smResults.length; i++) {
      setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [i]: true }));
    }
    setIsOEMDisabledAddRow(true);
    setFormDataAddRow({
      Item_Name: "",
      Part_Number: "",
      Linked_PO: "",
      UoM: "",
      PO_Qty: "",
      Remarks: "",
    });
    setRefresh(!refresh);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setDropdownVisible(false);
    setPartDropdownVisible(false);
    try {
      await Promise.all(SNoToDelete.map(async (extractedSNo) => {
        await axios.delete(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_delete?S_No=${extractedSNo}`
        );
      }));

      // Clear the SNoToDelete array after successful deletions
      setSNoToDelete([]);

      const updatePromises = formDatasm.data.map(async (el) => {
        const updatePayload = {
          S_No: el.S_No,
          Spares_Trading_Project_ID: id,
          Item_Name: el.Item_Name?.trim(),
          Part_Number: el.Part_Number?.trim(),
          Linked_PO: el.Linked_PO?.trim(),
          UoM: el.UoM?.trim(),
          PO_Qty: el.PO_Qty?.trim(),
          Remarks: el.Remarks?.trim(),
        };
        console.log("updatePayload", updatePayload);

        const updatePayload1 = {
          Item_Name: el.Item_Name?.trim(),
          Part_Number: el.Part_Number?.trim(),
          Manufacturer_Part_Number: el.Manufacturer_Part_Number?.trim(),
          UoM: el.UoM?.trim(),
        }

        // // Check if there are differences in Part_Number or Manufacturer_Part_Number to be put in incoming deliveries purchase_incoming_items table and the purchase_incoming_inspections table
        // const smResult = smResults.find((result) => result.S_No === el.S_No);
        // if (smResult && (smResult.Part_Number !== el.Part_Number || smResult.Manufacturer_Part_Number !== el.Manufacturer_Part_Number || smResult.UoM !== el.UoM)) {
        //   // PUT request for item_details_incoming_items_put
        //   await axios.put(
        //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/item_details_incoming_items_put?Spares_Trading_Project_ID=${id}&Item_Name=${smResult.Item_Name}&Manufacturer_Part_Number=${smResult.Manufacturer_Part_Number}&Part_Number=${smResult.Part_Number}`,
        //     updatePayload1
        //   );

        //   // PUT request for item_details_incoming_inspections_put
        //   await axios.put(
        //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/item_details_incoming_inspections_put?Spares_Trading_Project_ID=${id}&Item_Name=${smResult.Item_Name}&Manufacturer_Part_Number=${smResult.Manufacturer_Part_Number}&Part_Number=${smResult.Part_Number}`,
        //     updatePayload1
        //   );
        // }


        return axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_put?S_No=${el.S_No}`,
          updatePayload
        );
      });

      // Wait for all promises to resolve before proceeding
      await Promise.all(updatePromises);

      // Clear the formDatasm after successful updates
      setFormDatasm({
        data: [],
      });

    } catch (error) {
      console.error("Error submitting data:", error);
    }

    setShoweditRow(false);
    seteditModesm(false);
    setShowInputRow(false);
    setIsButtonDisabled(false);
    setRefresh(!refresh);
    setHeightRefresh(!heightRefresh);
    setIsLoading(false);
  }

  const handleOpenRemoveConfirmationModal = async (e, SNo, ItemName, PartNumber) => {
    e.preventDefault();
    setConfirmationData({
      extractedSNo: SNo,
      ItemName: ItemName,
      PartNumber: PartNumber,
    });
    setOpenRemoveConfirmationModal(true);
  }

  const handleCloseRemoveConfirmationModal = async (e) => {
    e.preventDefault();
    setConfirmationData({
      extractedSNo: null,
      ItemName: "",
      PartNumber: "",
    });

    setOpenRemoveConfirmationModal(false);
  }

  const handleRemove = (e, extractedSNo) => {
    try {
      if (extractedSNo !== null) {
        console.log("idToDelete:" + extractedSNo);

        // Store the extractedSNo in the SNoToDelete array
        setSNoToDelete(prevState => [...prevState, extractedSNo]);

        // Update formDatasm and smResults on the frontend
        const updatedFormData = {
          ...formDatasm,
          data: formDatasm.data.filter((el) => el.S_No !== extractedSNo)
        };
        setFormDatasm(updatedFormData);

        const updatedsmResults = smResults.filter((sm) => sm.S_No !== extractedSNo);
        setsmResults(updatedsmResults);

        console.log("updatedFormDatasm, updatesmResults", updatedFormData, updatedsmResults);

        setOpenRemoveConfirmationModal(false);
        // setRefresh(!refresh);
      } else {
        console.log("No ID to delete");
      }
    } catch (error) {
      console.error("Error removing data:", error);
    }
  };


  useEffect(() => {
    async function fetchsmDetails() { //first loading of the page data and states, also used to refresh the page when save, cancel, cross etc. are clicked
      setIsLoading(true);
      try {
        const resSalesOrderNo = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );

        const data = resSalesOrderNo.data.data[0];

        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_get"
        );

        const resItemsMaster = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
        );

        const filter = response.data.data.filter((el) => el.Spares_Trading_Project_ID == id); //get all item details where Spares_Trading_Project_ID is equal to the useParams id

        // Fetch the inventory utilization data
        const resInventoryUtil = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_projectSO_get?Project_SO=${data?.Sales_Order_No}`
        );

        // Fetch the dispatch data
        const resDispatchDetails = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_getsparesdispatchdetails`, {
          params: {
            Project_ID: data?.ID_No
          }
        });

        const dispatchDetails = resDispatchDetails.data.results;

        // Fetch delivery data
        const resDeliveryDetails = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_sparestrading_itemdetails_deliveries_data_get"
        );

        const deliveryDetails = resDeliveryDetails.data.data;

        // Loop through the filtered data and find matching items in inventory, dispatch, and delivery details
        const updatedFilter = filter.map((item) => {

          const trimmedItemPartNumber = item.Part_Number ? item.Part_Number.trim() : "";
          const trimmedItemName = item.Item_Name ? item.Item_Name.trim() : "";
        
          // Find matching items in inventory utilization details
          const matchingItemsInventory = resInventoryUtil.data.data.filter((inventoryItem) => {
            const trimmedInventoryPartNumber = inventoryItem.Part_Number ? inventoryItem.Part_Number.trim() : "";
            const trimmedInventoryName = inventoryItem.Item_Name ? inventoryItem.Item_Name.trim() : "";
        
            // First condition: If Part_Number exists and is not null/empty/undefined, match trimmed Part_Number
            if (trimmedItemPartNumber && trimmedInventoryPartNumber) {
              return trimmedItemPartNumber === trimmedInventoryPartNumber;
            }
            // Second condition: If Part_Number is null/empty/undefined, match both Part_Number and Item_Name
            if (!trimmedItemPartNumber && !trimmedInventoryPartNumber) {
              return trimmedItemName === trimmedInventoryName && trimmedInventoryPartNumber === "";
            }
            // Third condition: If none of the above, match Item_Name only
            return trimmedItemName === trimmedInventoryName;
          });
        
          // Sum the Qty_Utilized for the matching items
          const totalQtyUtilized = matchingItemsInventory.reduce(
            (sum, inventoryItem) => sum + (parseInt(inventoryItem.Qty_Utilized) || 0),
            0
          );

          // Find matching items in dispatch details
          const matchingItemsDispatch = dispatchDetails.filter((dispatchItem) => {
            const trimmedDispatchPartNumber = dispatchItem.Part_Number ? dispatchItem.Part_Number.trim() : "";
            const trimmedDispatchName = dispatchItem.Item_Name ? dispatchItem.Item_Name.trim() : "";
        
            // First condition: If Part_Number exists and is not null/empty/undefined, match trimmed Part_Number
            if (trimmedItemPartNumber && trimmedDispatchPartNumber) {
              return trimmedItemPartNumber === trimmedDispatchPartNumber;
            }
            // Second condition: If Part_Number is null/empty/undefined, match both Part_Number and Item_Name
            if (!trimmedItemPartNumber && !trimmedDispatchPartNumber) {
              return trimmedItemName === trimmedDispatchName && trimmedDispatchPartNumber === "";
            }
            // Third condition: If none of the above, match Item_Name only
            return trimmedItemName === trimmedDispatchName;
          });

          // Separate the quantities based on delivery status
          let totalDispatchQty = 0;
          let totalDeliveredQty = 0;

          matchingItemsDispatch.forEach((dispatchItem) => {
            const deliveryItem = deliveryDetails.find(
              (delivery) => delivery.Dispatch_ID == dispatchItem.dispatch_id
            );

            console.log("deliveryItem deliveryItem", deliveryItem);

            if (deliveryItem && deliveryItem.Delivery_Status === "Delivered") {
              totalDeliveredQty += parseInt(dispatchItem.Dispatch_qty) || 0;
            } else {
              totalDispatchQty += parseInt(dispatchItem.Dispatch_qty) || 0;
            }
          });

          // Calculate Qty_pending based on PO_Qty, Qty_in_transit, and Delivered_Qty
          const Qty_pending = item.PO_Qty
            ? (parseInt(item.PO_Qty) - totalDeliveredQty)
            : "";

          return {
            ...item,
            Stock_Qty: (totalQtyUtilized - totalDispatchQty - totalDeliveredQty),
            Qty_in_transit: totalDispatchQty,
            Delivered_Qty: totalDeliveredQty,
            Qty_pending: Qty_pending
          };
        });


        // // Loop through the filtered data and find matching items in inventory utilization data and dispatch data
        // const updatedFilter = filter.map((item) => {

        //   // Find matching items in inventory utilization details
        //   const matchingItemsInventory = resInventoryUtil.data.data.filter(
        //     (inventoryItem) =>
        //       inventoryItem.Item_Name === item.Item_Name &&
        //       inventoryItem.Part_Number === item.Part_Number
        //   );

        //   // Sum the Qty_Utilized for the matching items
        //   const totalQtyUtilized = matchingItemsInventory.reduce(
        //     (sum, inventoryItem) => sum + (parseInt(inventoryItem.Qty_Utilized) || 0),
        //     0
        //   );


        //   // Find matching items in dispatch details
        //   const matchingItemsDispatch = dispatchDetails.filter(
        //     (dispatchItem) =>
        //       dispatchItem.Item_Name === item.Item_Name &&
        //       dispatchItem.Part_Number === item.Part_Number
        //   );

        //   // Sum the Dispatch_qty for the matching items in dispatch details
        //   const totalDispatchQty = matchingItemsDispatch.reduce(
        //     (sum, dispatchItem) => sum + (parseInt(dispatchItem.Dispatch_qty) || 0),
        //     0
        //   );

        //   // Assign the totalQtyUtilized to Stock_Qty
        //   return {
        //     ...item,
        //     Stock_Qty: totalQtyUtilized,
        //     Qty_in_transit: totalDispatchQty,
        //     Qty_pending: item.PO_Qty? (parseInt(item.PO_Qty) - parseInt(totalDispatchQty)) : ""
        //   };
        // });

        setItemsMaster(resItemsMaster.data);
        setsmResults(updatedFilter);
        console.log("smResults:", updatedFilter);
        console.log("Items Master:", resItemsMaster.data);
        setFormDatasm({
          data: updatedFilter,
        });
        setSNoToDelete([]);
        //Setting the Part_Number input to disabled 
        for (let i = 0; i < updatedFilter.length; i++) {
          setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [i]: true }));
        }
        setIsOEMDisabledAddRow(true);

        //Adding items to Items Master List if they don't exist already based on Part Number
        const itemsToPost = [];

        updatedFilter.forEach((smResult) => {
          // Normalize Manufacturer_Part_Number to null if it's an empty string
          const normalizedManufacturerPartNumber = smResult.Manufacturer_Part_Number ? (smResult.Manufacturer_Part_Number === "" ? null : smResult.Manufacturer_Part_Number) : null;

          const matchingItem = resItemsMaster.data.find(
            (item) =>
              item.Part_Number === smResult.Part_Number &&
              item.Item_Name === smResult.Item_Name &&
              item.Manufacturer_Part_Number === normalizedManufacturerPartNumber
          );

          if (!matchingItem) {
            // If matchingItem is not found, add it to the itemsToPost array
            itemsToPost.push({
              Item_Name: smResult.Item_Name,
              Part_Number: smResult.Part_Number,
              Manufacturer_Part_Number: normalizedManufacturerPartNumber,
            });
          }
        });

        // Perform POST requests for itemsToPost
        for (const itemToPost of itemsToPost) {
          try {
            const res = await axios.post(
              "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/items_master_list_post",
              itemToPost
            );
            console.log("Item added to itemsMaster:", itemToPost);
          } catch (error) {
            console.error("Error occurred while making POST request:", error);
          }
        }

        if (itemsToPost.length > 0) {
          const updatedItemsMaster = await axios.get(
            "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
          );
          setItemsMaster(updatedItemsMaster.data);
          console.log("Updated Items Master:", updatedItemsMaster.data)
        }

      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    }

    fetchsmDetails();
  }, [refresh]);

  const handleSelectInquiry = () => {
    setOpenModal(true);
    fetchInquiryDetails();
  }

  const handleCancelModal = () => {
    setOpenModal(false);
  };


  const [loading, setLoading] = useState(false); //loading while excel/csv file uploads

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Set loading state to true
      setLoading(true);

      const readFilePromise = new Promise((resolve) => {
        const reader = new FileReader();

        reader.onloadstart = () => {
          console.log("File reading started");
        };

        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const firstSheet = workbook.SheetNames[0];
          const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet], { header: 1 });
          resolve(sheetData);
        };

        reader.onloadend = () => {
          console.log("File reading completed");
          // Set loading state to false when reading is complete
          setLoading(false);
        };

        reader.onerror = (error) => {
          console.error("File reading error:", error);
          // Set loading state to false in case of an error
          setLoading(false);
        };

        if (
          selectedFile.type === "application/vnd.ms-excel" ||
          selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          reader.readAsBinaryString(selectedFile);
        } else if (selectedFile.type === "text/csv") {
          reader.readAsText(selectedFile);
        } else {
          console.log("Invalid file type.");
        }
      });

      try {
        const fileContent = await readFilePromise;
        setFileContent(fileContent);
        setFilteredTable(fileContent.slice(1)); //remove header row from fileContent and store the data in filteredTable
        console.log("Filtered Table ", filteredTable);
        console.log("fileContent", fileContent);
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }
  };

  const handleDownloadExcelTemplate = (e) => {
    e.preventDefault();
    // Define header row with bold font
    const header = [
      { v: 'S. No', s: { bold: true } },
      { v: 'Item Name', s: { bold: true } },
      { v: 'Part Number', s: { bold: true } },
      { v: 'Linked PO', s: { bold: true } },
      { v: 'UoM', s: { bold: true } },
      { v: 'PO Qty', s: { bold: true } },
      { v: 'Remarks', s: { bold: true } },
    ];

    // Create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([header]);

    // Set column widths
    const columnWidths = [
      { wch: 5 }, // S. No
      { wch: 20 }, // Item Name
      { wch: 20 }, // Part Number
      { wch: 30 }, // Linked PO
      { wch: 5 }, // UoM
      { wch: 15 }, // PO Qty
      { wch: 35 }, // Remarks
    ];
    worksheet['!cols'] = columnWidths;


    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Spares Trading Item Details');

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'SparesTradingItemDetails.xlsx');
  };


  useEffect(() => {
    const body = document.querySelector("body");
    if (fileContent.length !== 0) {
      body.classList.add(styles.noScroll);
    } else {
      body.classList.remove(styles.noScroll);
    }

    return () => {
      body.classList.remove(styles.noScroll);
    };
  }, [fileContent]);

  const handleCancel = () => {
    setFileContent([]);
  };

  const [filteredTable, setFilteredTable] = useState([]); //used for storing the uploaded excel file data, with 1 row removed from the top for header

  let payl;
  const store = filteredTable.map((el, index) => {
    payl = {
      Spares_Trading_Project_ID: id,
      Item_Name: el[1],
      Part_Number: el[2],
      Linked_PO: el[3],
      UOM: el[4],
      PO_Qty: el[5],
      Remarks: el[6],
    };
  });
  console.log(payl);

  const handlesubmittt = async () => {
    try {
      const responses = [];

      for (const el of filteredTable) {
        const payload = {
          Spares_Trading_Project_ID: id,
          Item_Name: el[1]?.trim(),
          Part_Number: el[2]?.trim(),
          Linked_PO: el[3]?.trim(),
          UoM: el[4]?.trim(),
          PO_Qty: el[5]?.trim(),
          Remarks: el[6]?.trim(),
        };

        const res = await axios.post(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_itemdetails_post",
          payload
        );
        responses.push(res);
      }

      const allSuccessful = responses.every((res) => res.status === 200);

      if (allSuccessful) {
        alert("Added all data from file successfully");
        setRefresh(!refresh);
        setHeightRefresh(!heightRefresh);
      }

      setFormDataAddRow({
        Item_Name: "",
        Part_Number: "",
        Linked_PO: "",
        UoM: "",
        PO_Qty: "",
        Remarks: "",
      });

    } catch (error) {
      console.error("Error submitting data:", error);
    }

    setRefresh(!refresh);
    setHeightRefresh(!heightRefresh);
    setFileContent([]);
    setOpen(false);
  };

  const maxColumns = 6;    // excel/csv file upload number of columns to be displayed in modal

  const [open, setOpen] = useState(false);    // to open or close the Modal on clicking "Import using Excel"
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isDropdownVisible, setDropdownVisible] = useState(false);    //used to control dropdown visibility under Item Name input in editable state, editModesm
  const [isPartDropdownVisible, setPartDropdownVisible] = useState(false);    //used to control dropdown visibility under Part Number input in editable state, editModesm
  const [isOEMDisabled, setIsOEMDisabled] = useState([]);   //used to control the disabled state of the OEM Part Number input in editable state, editModesm

  const [isDropdownVisibleAddRow, setDropdownVisibleAddRow] = useState(false);    //used to control dropdown visibility under Item Name input when "Add Items Manually" is clicked, showInputRow state true
  const [isPartDropdownVisibleAddRow, setPartDropdownVisibleAddRow] = useState(false);    //used to control dropdown visibility under Part Number input when "Add Items Manually" is clicked, showInputRow state true
  const [isOEMDisabledAddRow, setIsOEMDisabledAddRow] = useState(true);    //used to control the disabled state of the OEM Part Number input when "Add Items Manually" is clicked, showInputRow state true

  console.log("isOEMDisabled", isOEMDisabled);

  const [initialHeight, setInitialHeight] = useState();   // State variable to track the initial height of the container
  const [heightRefresh, setHeightRefresh] = useState(false);    //to refresh the height of smtablecontainer div which contains the table and dropdowns
  const [containerStyle, setContainerStyle] = useState({});   // State variable to store container style

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const observer = new ResizeObserver((entries) => {
      // Since there might be multiple entries, we take the first one
      const newHeight = entries[0].contentRect.height;
      setInitialHeight(newHeight);
    });

    observer.observe(container);

    return () => observer.disconnect();
  }, [heightRefresh]);

  console.log("initialHeight", initialHeight);

  useEffect(() => {
    // Calculate the new height based on the initial height and whether the dropdowns are visible
    const newHeight = ((isDropdownVisible || isDropdownVisibleAddRow || isPartDropdownVisible || isPartDropdownVisibleAddRow) ? (initialHeight + 270) : (initialHeight + 20));
    // const newHeightInt = parseInt(newHeight);

    console.log("newHeight", newHeight);
    setContainerStyle({ height: newHeight });
  }, [isDropdownVisible, isDropdownVisibleAddRow, isPartDropdownVisible, isPartDropdownVisibleAddRow, initialHeight, showInputRow, editModesm]);

  console.log("isDropdownVisible, isDropdownVisibleAddRow", isDropdownVisible, isDropdownVisibleAddRow);
  console.log("isPartDropdownVisible, isPartDropdownVisibleAddRow", isPartDropdownVisible, isPartDropdownVisibleAddRow);
  console.log("ppppppppp", containerStyle);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.getElementById('myDropdown');

      // Check if the clicked element is inside the dropdown
      if (dropdown && !dropdown.contains(event.target)) {
        // If the clicked element is outside the dropdown, hide it
        setDropdownVisible(false);
        setDropdownVisibleAddRow(false);
        setPartDropdownVisible(false);
        setPartDropdownVisibleAddRow(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleAddToItemsMaster = (index) => {
    // const newData = [...formDatasm.data];
    // newData[index] = { ...newData[index], Part_Number: "" };
    // setFormDatasm({ data: newData });
    setDropdownVisible(false);
    setPartDropdownVisible(false);
    setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [index]: false }));
  };

  const handleAddToItemsMasterAddRow = () => {
    setFormDataAddRow((prev) => ({
      ...prev,
      Item_Name: formDataAddRow.Item_Name,
      Part_Number: formDataAddRow.Part_Number || "",
    }));
    setDropdownVisibleAddRow(false);
    setPartDropdownVisibleAddRow(false);
    setIsOEMDisabledAddRow(false);
  };


  return (
    <div>
      <LoadingScreen isLoading={isLoading} />

      <div className={styles.topdiv}>
        <label className={styles.aryl1}>
          SO: {formData.Sales_Order_No}
        </label>
        <label className={styles.aryl}>
          PO: {formData.PO_No}
        </label>
        <label className={styles.aryl}>
          Customer Name: {formData.Customer_Name}
        </label>
      </div>

      <TabsBarSparesTrading activeTab="Item Details" />

      <div className={styles.sections2}>
        <div className={styles.buttonsdiv}
          style={{
            border: "2px solid #E7EBFF",
            borderTop: "none",
            borderBottom: "none",
          }}>
          {showeditRow ? (
            <>

              <button
                variant="contained"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                Save Changes
              </button>
              <button
                variant="contained"
                className={styles.cancel}
                onClick={handlecancelsm}>
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                variant="contained"
                onClick={handleSelectInquiry}
                className={styles.selectInquiry}
                >
                Select items from Inquiry
              </button>

              <button
                variant="contained"
                className={styles.editBtn}
                onClick={handleeditsm}>
                Edit
              </button>
              {/* <button
                variant="contained"
                onClick={setOpen}
              >
                Import using excel
                <svg viewBox="0 0 50 50" width="20px" height="20px" style={{ marginLeft: "8px" }}>
                  <path d="M 28.8125 0.03125 L 0.8125 5.34375 C 0.339844 5.433594 0 5.863281 0 6.34375
                           L 0 43.65625 C 0 44.136719 0.339844 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 28.875
                           49.980469 28.9375 50 29 50 C 29.230469 50 29.445313 49.929688 29.625 49.78125 C 29.855469 49.589844 30 49.296875
                           30 49 L 30 1 C 30 0.703125 29.855469 0.410156 29.625 0.21875 C 29.394531 0.0273438 29.105469 -0.0234375 28.8125 0.03125 Z M 32 6 L 32 13 L 34 13 L 34 15 L 32 15 L 32 20 L 34 20 L 34 22 L 32 22 L 32 27 L 34 27 L 34 29 L 32 29 L 32 35 L 34 35 L 34 37 L 32 37 L 32 44 L 47 44 C 48.101563 44 49 43.101563 49 42 L 49 8 C 49 6.898438 48.101563 6 47 6 Z M 36 13 L 44 13 L 44 15 L 36 15 Z M 6.6875 15.6875 L 11.8125 15.6875 L 14.5 21.28125 C 14.710938 21.722656 14.898438 22.265625 15.0625 22.875 L 15.09375 22.875 C 15.199219 22.511719 15.402344 21.941406 15.6875 21.21875 L 18.65625 15.6875 L 23.34375 15.6875 L 17.75 24.9375 L 23.5 34.375 L 18.53125 34.375 L 15.28125 28.28125 C 15.160156 28.054688 15.035156 27.636719 14.90625 27.03125 L 14.875 27.03125 C 14.8125 27.316406 14.664063 27.761719 14.4375 28.34375 L 11.1875 34.375 L 6.1875 34.375 L 12.15625 25.03125 Z M 36 20 L 44 20 L 44 22 L 36 22 Z M 36 27 L 44 27 L 44 29 L 36 29 Z M 36 35 L 44 35 L 44 37 L 36 37 Z"
                    fill="white" />
                </svg>
              </button> */}
            </>
          )}
        </div>

        {openModal && (
        <div style={{ position: "", zIndex: "2" }}>
            <Modal
              open={openModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
              sx={{
                backdropFilter: "blur(4px)",
              }}
            >
              <Fade in={openModal}>
                <Box
                  sx={{
                    width: 1440,
                    height: 700,
                    p: 3,
                    backgroundColor: "#fff",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{display: "flex", gap: "50px", justifyContent: "center", marginTop: "40px", fontSize:"21px",
                    color: "#567191", fontWeight: "bold"
                  }}>
                    <label>Picking Items from:- </label>
                    <label>Inquiry Reference: {inquiryDetails.inquiryNo} </label>
                    <label>PO Number: {formData.PO_No}</label>
                  </div>
                  

                  <div className={styles.modalContainer}>
                    <table className={styles.tableModal}>
                      <thead>
                        <tr>
                          <th>Select</th>
                          <th>S.No</th>
                          <th>Item Name</th>
                          <th>Part Number</th>
                          <th>Order Qty</th>
                          <th>UoM</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inquiryItems.map((item,index) => (
                        <tr key={index}>
                          <td><input
                          type="checkbox" checked={item.isSelected} onChange={() => handleCheckboxChange(index)}/>
                          </td>
                          <td>{index+1}</td>
                          <td>{item.Item_Name}</td>
                          <td>{item.Part_Number}</td>
                          <td><input type="number" min="0" className={styles.qtyField}
                          value={item.qty} onChange={(e) => handleQuantityChange(index, e.target.value)}/></td>
                          <td>
                          <select defaultValue="pcs" className={styles.selectField}
                          value={item.uom} onChange={(e) => handleUoMChange(index, e.target.value)}>
                            <option value="pcs">pcs</option>
                            <option value="kg">kg</option>
                            <option value="mm">mm</option>
                            <option value="inch">inch</option>
                            <option value="m">m</option>
                            
                          </select>
                          <svg
                  style={{ position: "relative", right: "26" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                    fill="black"
                  />
                </svg>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>

                  <div className={styles.modalBtn}>
                    <button 
                    className={styles.submitBtn}
                    onClick={handleSubmitSelectedItems}>
                      Submit
                    </button>
                    <button
                    className={styles.cancelBtn}
                    onClick={handleCancelModal}>
                      Cancel
                    </button>

                  </div>

                  
                </Box>
              </Fade>
            </Modal>
          </div>
        )}

        <div>
          <div className={styles.smtablecontainer} style={containerStyle}>
            <table ref={containerRef} className={styles.smtable}>
              <thead>
                <tr className={styles.headrowsm}>
                  {showeditRow && <th></th>}
                  {showInputRow && !editModesm && <th></th>}
                  <th className={styles.fileUploadheading}>S.No</th>
                  <th className={styles.fileUploadheading}>Item Name</th>
                  <th className={styles.fileUploadheading}>Part Number</th>
                  <th className={styles.fileUploadheading}>Linked PO</th>
                  <th className={styles.fileUploadheading}>UoM</th>
                  <th className={styles.fileUploadheading}>PO Qty</th>
                  <th className={styles.fileUploadheading}>Allocated Qty</th>
                  <th className={styles.fileUploadheading}>Qty in-transit</th>
                  <th className={styles.fileUploadheading}>Delivered Qty</th>
                  <th className={styles.fileUploadheading}>Qty Pending for Delivery</th>
                  <th className={styles.fileUploadheading}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {/* {rows.map((row) => row)} */}
                {smResults.map((sm, index) => (
                  <tr key={sm.S_No}>
                    {showeditRow && (
                      <td>
                        <div
                          className={styles.removeTxtBtn}
                          onClick={(e) => handleOpenRemoveConfirmationModal(e, sm.S_No, sm.Item_Name, sm.Part_Number)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none">
                            <path
                              d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                              fill="#567191"
                            />
                          </svg>
                          Remove
                        </div>
                      </td>
                    )}
                    {showInputRow && !editModesm && <td></td>}
                    <td>{index + 1}</td>
                    <td>
                      {editModesm ? (
                        <>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Item_Name"
                              id="inputItemName"
                              value={formDatasm.data[index].Item_Name}
                              onChange={(e) => {
                                handleInputChange(e, index);
                                setDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                              }}
                            />
                            {isDropdownVisible[index] && formDatasm.data[index].Item_Name && (
                              <div id="myDropdown" className={styles.dropdown}>
                                {itemsMaster
                                  .filter((item) =>
                                    item.Item_Name && item.Item_Name.toLowerCase().includes((formDatasm.data[index].Item_Name || '').toLowerCase())
                                  )
                                  .map((item) => (
                                    <div
                                      key={item.Item_Id}
                                      onClick={() => handleDropdownSelection(item, index)}
                                      className={styles.dropdownItem}>
                                      {item.Item_Name}
                                    </div>
                                  ))}
                                {/* {!itemsMaster.some((item) =>
                                  item.Item_Name && item.Item_Name.toLowerCase() === (formDatasm.data[index].Item_Name || '').toLowerCase()
                                ) && ( */}
                                <div
                                  className={styles.dropdownItem}
                                  style={{ color: "#567191", fontWeight: "700" }}
                                  onClick={() => handleAddToItemsMaster(index)}>
                                  {`+ Add "${formDatasm.data[index].Item_Name}" to Items Master List`}
                                </div>
                                {/* )} */}
                              </div>
                            )}
                          </div>
                        </>
                      ) : sm.Item_Name ? (
                        sm.Item_Name
                      ) : (
                        "-"
                      )}
                    </td>

                    <td>
                      {editModesm ? (
                        <>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Part_Number"
                              value={formDatasm.data[index].Part_Number}
                              onChange={(e) => {
                                handleInputChange(e, index);
                                setPartDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                              }}
                            />
                            {isPartDropdownVisible[index] && formDatasm.data[index].Part_Number && (
                              <div id="myDropdown" className={styles.dropdown}>
                                {itemsMaster
                                  .filter((item) =>
                                    item.Part_Number && item.Part_Number.toLowerCase().includes((formDatasm.data[index].Part_Number || '').toLowerCase())
                                  )
                                  .map((item) => (
                                    <div
                                      key={item.Item_Id}
                                      onClick={() => handleDropdownSelection(item, index)}
                                      className={styles.dropdownItem}>
                                      {item.Part_Number}
                                    </div>
                                  ))}
                                <div
                                  className={styles.dropdownItem}
                                  style={{ color: "#567191", fontWeight: "700" }}
                                  onClick={() => handleAddToItemsMaster(index)}>
                                  {`+ Add "${formDatasm.data[index].Part_Number}" to Items Master List`}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ) : sm.Part_Number ? (
                        sm.Part_Number
                      ) : (
                        "-"
                      )}
                    </td>

                    {/* <td>
                      {editModesm ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="Part_Number"
                          value={formDatasm.data[index].Part_Number}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled={isOEMDisabled[index]}
                        />
                      ) : sm.Part_Number ? (
                        sm.Part_Number
                      ) : (
                        "-"
                      )}
                    </td> */}
                    <td>
                      {editModesm ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          style={{ width: "80px" }}
                          name="Linked_PO"
                          value={formDatasm.data[index].Linked_PO}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      ) : sm.Linked_PO ? (
                        sm.Linked_PO
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {editModesm ? (
                        <>
                          <select
                            className={styles.inputFieldsm}
                            style={{ paddingRight: "30px" }}
                            name="UoM"
                            value={formDatasm.data[index].UoM}
                            onChange={(e) => handleInputChange(e, index)}>
                            <option value="">Select Unit</option>
                            <option value="pcs">pcs</option>
                            <option value="kg">kg</option>
                            <option value="mm">mm</option>
                            <option value="inch">inch</option>
                            <option value="m">m</option>
                          </select>
                          <svg
                            style={{ marginLeft: "-25px", marginTop: "-1%", pointerEvents: "none" }}
                            width="24"
                            height="24"
                            viewBox="0 0 28 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                              fill="black"
                            />
                          </svg>
                        </>
                      ) : sm.UoM ? (
                        sm.UoM
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {editModesm ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          style={{ width: "80px" }}
                          name="PO_Qty"
                          value={formDatasm.data[index].PO_Qty}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      ) : sm.PO_Qty ? (
                        sm.PO_Qty
                      ) : (
                        "-"
                      )}
                    </td>

                    <td>
                      {editModesm ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          style={{ width: "80px" }}
                          name="Stock_Qty"
                          value={formDatasm.data[index].Stock_Qty}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled
                        />
                      ) : sm.Stock_Qty ? (
                        sm.Stock_Qty
                      ) : (
                        "-"
                      )}
                    </td>

                    <td>
                      {editModesm ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          style={{ width: "80px" }}
                          name="Stock_Qty"
                          value={formDatasm.data[index].Qty_in_transit}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled
                        />
                      ) : sm.Qty_in_transit ? (
                        sm.Qty_in_transit
                      ) : (
                        "-"
                      )}
                    </td>


                    <td>
                      {editModesm ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          style={{ width: "80px" }}
                          name="Delivered_Qty"
                          value={formDatasm.data[index].Delivered_Qty}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled
                        />
                      ) : sm.Delivered_Qty ? (
                        sm.Delivered_Qty
                      ) : (
                        "-"
                      )}
                    </td>

                    <td>
                      {editModesm ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          style={{ width: "80px" }}
                          name="Qty_pending"
                          value={formDatasm.data[index].Qty_pending}
                          onChange={(e) => handleInputChange(e, index)}
                          disabled
                        />
                      ) : sm.Qty_pending ? (
                        sm.Qty_pending
                      ) : "-"
                      }
                    </td>

                    <td>
                      {editModesm ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="Remarks"
                          value={formDatasm.data[index].Remarks}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      ) : sm.Remarks ? (
                        sm.Remarks
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
                {showInputRow && !editModesm && (
                  <tr key={rows.length} className={styles.addinputspares}>
                    <td className={styles.tickcross}>
                      <svg
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={handleSubmitsm}
                        disabled={isLoading}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                          fill="#66D171"
                        />
                      </svg>
                      <svg
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={handleDeleteRow}>
                        <path
                          d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                          fill="#F97575"
                        />
                        <path
                          d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                          fill="#F97575"
                        />
                      </svg>
                    </td>

                    <td></td>

                    <td>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="Item_Name"
                          value={formDataAddRow.Item_Name}
                          onChange={(e) => {
                            handleInputChangevis(e);
                            setDropdownVisibleAddRow(true); // Show the dropdown when typing
                          }}
                        />
                        {isDropdownVisibleAddRow && formDataAddRow.Item_Name && (
                          <div id="myDropdown" className={styles.dropdown}>
                            {itemsMaster
                              .filter((item) =>
                                item.Item_Name && item.Item_Name.toLowerCase().includes((formDataAddRow.Item_Name || '').toLowerCase())
                              )
                              .map((item) => (
                                <div
                                  key={item.Item_Id}
                                  onClick={() => handleDropdownSelectionAddRow(item)}
                                  className={styles.dropdownItem}>
                                  {item.Item_Name}
                                </div>
                              ))}
                            {/* {!itemsMaster.some((item) =>
                              item.Item_Name && item.Item_Name.toLowerCase() === (formDataAddRow.Item_Name || '').toLowerCase()
                            ) && ( */}
                            <div
                              className={styles.dropdownItem}
                              style={{ color: "#567191", fontWeight: "700" }}
                              onClick={handleAddToItemsMasterAddRow}>
                              {`+ Add "${formDataAddRow.Item_Name}" to Items Master List`}
                            </div>
                            {/* )} */}
                          </div>
                        )}
                      </div>
                    </td>

                    <td>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="Part_Number"
                          value={formDataAddRow.Part_Number}
                          onChange={(e) => {
                            handleInputChangevis(e);
                            setPartDropdownVisibleAddRow(true); // Show the dropdown when typing
                          }}
                        />
                        {isPartDropdownVisibleAddRow && formDataAddRow.Part_Number && (
                          <div id="myDropdown" className={styles.dropdown}>
                            {itemsMaster
                              .filter((item) =>
                                item.Part_Number && item.Part_Number.toLowerCase().includes((formDataAddRow.Part_Number || '').toLowerCase())
                              )
                              .map((item) => (
                                <div
                                  key={item.Item_Id}
                                  onClick={() => handleDropdownSelectionAddRow(item)}
                                  className={styles.dropdownItem}>
                                  {item.Part_Number}
                                </div>
                              ))}
                            <div
                              className={styles.dropdownItem}
                              style={{ color: "#567191", fontWeight: "700" }}
                              onClick={handleAddToItemsMasterAddRow}>
                              {`+ Add "${formDataAddRow.Part_Number}" to Items Master List`}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        name="Linked_PO"
                        value={formDataAddRow.Linked_PO}
                        onChange={handleInputChangevis}
                      />
                    </td>

                    <td>
                      <select
                        className={styles.inputFieldsm}
                        style={{ paddingRight: "30px" }}
                        name="UoM"
                        value={formDataAddRow.UoM}
                        onChange={handleInputChangevis}>
                        <option value="">Select Unit</option>
                        <option value="pcs">pcs</option>
                        <option value="kg">kg</option>
                        <option value="mm">mm</option>
                        <option value="inch">inch</option>
                        <option value="m">m</option>
                      </select>
                      <svg
                        style={{ marginLeft: "-25px", marginTop: "-1%", pointerEvents: "none" }}
                        width="24"
                        height="24"
                        viewBox="0 0 28 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        style={{ width: "80px" }}
                        name="PO_Qty"
                        value={formDataAddRow.PO_Qty}
                        onChange={handleInputChangevis}
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        style={{ width: "80px" }}
                        name="Stock_Qty"
                        value={formDataAddRow.Stock_Qty}
                        onChange={handleInputChangevis}
                        disabled
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        style={{ width: "80px" }}
                        name="Qty_in_transit"
                        value={formDataAddRow.Qty_in_transit}
                        onChange={handleInputChangevis}
                        disabled
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        style={{ width: "80px" }}
                        name="Delivered_Qty"
                        value={formDataAddRow.Delivered_Qty}
                        onChange={handleInputChangevis}
                        disabled
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        style={{ width: "80px" }}
                        name="Qty_pending"
                        value={formDataAddRow.Qty_pending}
                        onChange={handleInputChangevis}
                        disabled
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        className={styles.inputFieldsm}
                        name="Remarks"
                        value={formDataAddRow.Remarks}
                        onChange={handleInputChangevis}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {!isButtonDisabled &&
            <button
              className={styles.manualbuttonsm}
              onClick={handleManualInputClick}
              disabled={isButtonDisabled}>
              Add Items Manually
              <svg
                style={{ marginLeft: "10px" }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.493 1.66669H6.50964C3.4763 1.66669 1.66797 3.47502 1.66797 6.50835V13.4834C1.66797 16.525 3.4763 18.3334 6.50964 18.3334H13.4846C16.518 18.3334 18.3263 16.525 18.3263 13.4917V6.50835C18.3346 3.47502 16.5263 1.66669 13.493 1.66669ZM15.0013 10.625H10.6263V15C10.6263 15.3417 10.343 15.625 10.0013 15.625C9.65964 15.625 9.3763 15.3417 9.3763 15V10.625H5.0013C4.65964 10.625 4.3763 10.3417 4.3763 10C4.3763 9.65835 4.65964 9.37502 5.0013 9.37502H9.3763V5.00002C9.3763 4.65835 9.65964 4.37502 10.0013 4.37502C10.343 4.37502 10.6263 4.65835 10.6263 5.00002V9.37502H15.0013C15.343 9.37502 15.6263 9.65835 15.6263 10C15.6263 10.3417 15.343 10.625 15.0013 10.625Z"
                  fill={isButtonDisabled ? '#5B6BE1' : 'white'}
                />
              </svg>
            </button>
          }
        </div>

        <div style={{ position: "", zIndex: "2" }}>
          <Modal
            open={openRemoveConfirmationModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              backdropFilter: "blur(4px)",
            }}
          >
            <Fade in={openRemoveConfirmationModal}>
              <Box
                sx={{
                  width: 440,
                  height: "auto",
                  p: 3,
                  backgroundColor: "#fff",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginLeft: "50px"  // Change justifyContent to space-between
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <svg
                      width="25"
                      height="25"
                      style={{ display: "flex" }}
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 47.5 47.5"
                      viewBox="0 0 47.5 47.5"
                      id="warning"
                    >
                      <defs>
                        <clipPath id="a">
                          <path d="M0 38h38V0H0v38Z"></path>
                        </clipPath>
                      </defs>
                      <g clip-path="url(#a)" transform="matrix(1.25 0 0 -1.25 0 47.5)">
                        <path
                          fill="#ffcc4d"
                          d="M0 0c-1.842 0-2.654 1.338-1.806 2.973l15.609 30.055c.848 1.635 2.238 1.635 3.087 0L32.499 2.973C33.349 1.338 32.536 0 30.693 0H0Z"
                          transform="translate(3.653 2)"
                        ></path>
                        <path
                          fill="#231f20"
                          d="M0 0c0 1.302.961 2.108 2.232 2.108 1.241 0 2.233-.837 2.233-2.108v-11.938c0-1.271-.992-2.108-2.233-2.108-1.271 0-2.232.807-2.232 2.108V0Zm-.187-18.293a2.422 2.422 0 0 0 2.419 2.418 2.422 2.422 0 0 0 2.419-2.418 2.422 2.422 0 0 0-2.419-2.419 2.422 2.422 0 0 0-2.419 2.419"
                          transform="translate(16.769 26.34)"
                        ></path>
                      </g>
                    </svg>
                    <h3
                      style={{
                        textAlign: "center",
                        font: "default",
                        fontWeight: "600",
                        fontSize: "25px",
                        marginLeft: "30px",
                      }}
                    >
                      CONFIRMATION
                    </h3>
                  </div>
                  {/* <button
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      marginLeft: "0px",
                      marginTop: "2px",
                      font: "default",
                      fontWeight: "600",
                      fontSize: "25px",
                    }}
                    onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                  >
                    x
                  </button> */}
                  <svg
                    width="20"
                    onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                    style={{
                      marginLeft: "0px",
                      cursor: "pointer",
                    }}
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="black"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>Are you sure you want to remove this item?</h2>
                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>
                  Item Name: &nbsp;
                  <label style={{ fontSize: "17px", fontWeight: "700" }}>
                    {confirmationData.ItemName}
                  </label>
                </h2>
                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "15px" }}>
                  Part Number:  &nbsp;
                  <label style={{ fontSize: "17px", fontWeight: "700" }}>
                    {confirmationData.PartNumber}
                  </label>
                </h2>


                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#5b6be1",
                      border: "1px solid #5b6be1",
                      borderRadius: "6px",
                      justifyContent: "center",
                      minWidth: "74px",
                      fontWeight: 600,
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                    onClick={(e) => handleRemove(e, confirmationData.extractedSNo)}
                  >
                    Yes
                  </button>
                  <button
                    style={{
                      backgroundColor: "white",
                      color: "#5b6be1",
                      border: "1px solid #5b6be1",
                      borderRadius: "6px",
                      justifyContent: "center",
                      minWidth: "74px",
                      fontWeight: 600,
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                    onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                  >
                    No
                  </button>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </div>

    </div>
  )
}

export default SparesTradingWorkshopItemDetails;