import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styles from "../Components/css/professionaldetails.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import ProfessionalDetailsEdit from "../Components/Professionaldetailsedit";
import UploadDocument from "./Uploaddocument";
import { Button, Tooltip, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// date picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import { Grid, TextField } from "@mui/material";
import dayjs from "dayjs";

import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const names = ["MLT", "F&G", "Keppel", "BMC", "Modec"];

const ProfessionalDetails = () => {
  const [editIndex, setEditIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [featurecn, setfeaturecn] = useState([]);
  const [featureedit, setfeatureedit] = useState([]);

  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiInputBase-input": {
      border: "transparent",
      padding: "0",
      paddingLeft: "5px",
    },
    "& .MuiFormLabel-root": {
      paddingTop: "3px",
    },
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      height: "45px",
      borderRadius: "8px",
      "&:hover": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
      },
    },
  }));


  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );
  
        const group = localStorage.getItem("Group");
        const roles = JSON.parse(localStorage.getItem("Roles")) || []; // Retrieve roles from localStorage
  
        // Group feature filter for "Add New"
        const addNewGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Professional Details" &&
            el.Feature_Name === "Add New" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Role feature filter for "Add New"
        const addNewRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Professional Details" &&
            el.Feature === "Add New" &&
            el.Access === "Can View"
          );
        });
  
        // Combine group and role features for "Add New"
        const combinedAddNew = [...addNewGroup, ...addNewRole];
  
        // Group feature filter for "Edit"
        const editGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Professional Details" &&
            el.Feature_Name === "Edit" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Role feature filter for "Edit"
        const editRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Professional Details" &&
            el.Feature === "Edit" &&
            el.Access === "Can View"
          );
        });
  
        // Combine group and role features for "Edit"
        const combinedEdit = [...editGroup, ...editRole];
  
        // Prioritize "Can View" over "Can Edit"
        const prioritizeAccess = (features) => {
          const featureMap = {};
          features.forEach((feature) => {
            const key = `${feature.Header_Name || feature.Header}-${feature.Feature_Name || feature.Feature}`;
            if (!featureMap[key] || feature.Access === "Can View") {
              featureMap[key] = feature;
            }
          });
          return Object.values(featureMap);
        };
  
        // Set features with prioritized access
        setfeaturecn(prioritizeAccess(combinedAddNew));
        setfeatureedit(prioritizeAccess(combinedEdit));
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  // for checking availability
  // const [availabilityStatus, setAvailabilityStatus] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [newEmployee, setNewEmployee] = useState();
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    // signature: null,
    retainership: "",
    employeeid: "",
    experienceOfRigTypes: "",
    jackExperience: "",
    skidExperience: "",
    craneExperience: "",
    datesBookedForProjectFrom: "",
    datesBookedForProjectTo: "",
    fixationExperience: "",
    seamanBookExpiry: "",
    seamanIssuingCountry: "",
    bosietExpiry: "",
    h2sExpiry: "",
    medicalExpiry: "",
    insuranceExpiry: "",
    sntEyeTestExpiry: "",
    yellowFeverExpiry:"",
    passportExpiry: "",
    passportIssuingCountry: "",
    visaFields: [{ visaIssuingCountry: "", visaExpiry: "" }],
    // cvFile: null,
    nationality:"",
    status: "",
    pccExpiry: "",
  });

  const [selectedFile, setSelectedFile] = useState({
    signature: null,
    seamanDoc: null,
    bosietDoc: null,
    h2sDoc: null,
    medicalDoc: null,
    insuranceDoc: null,
    sntEyeTestDoc: null,
    yellowFeverDoc:null,
    passportDoc: null,
    visaDoc1: null,
    visaDoc2: null,
    visaDoc3: null,
    visaDoc4: null,
    visaDoc5: null,
    visaDoc6: null,
    photoUpload:null,
    vaccinationCertificate:null,
    pcc:null,
  });

  const [selectedFileName, setSelectedFileName] = useState({
    signature: "",
    seamanDoc: "",
    bosietDoc: "",
    h2sDoc: "",
    medicalDoc: "",
    insuranceDoc: "",
    sntEyeTestDoc: "",
    yellowFeverDoc: "",
    passportDoc: "",
    visaDoc1: "",
    visaDoc2: "",
    visaDoc3: "",
    visaDoc4: "",
    visaDoc5: "",
    visaDoc6: "",
    photoUpload:"",
    vaccinationCertificate:"",
    pcc:"",
  });

  // const [selectedFileOriginalName, setSelectedFileOriginalName] = useState({
  //   signature: "",
  //   seamanDoc: "",
  //   bosietDoc: "",
  //   h2sDoc: "",
  //   medicalDoc: "",
  //   insuranceDoc: "",
  //   sntEyeTestDoc: "",
  //   passportDoc: "",
  //   visaDoc1: "",
  //   visaDoc2: "",
  //   visaDoc3: "",
  //   visaDoc4: "",
  //   visaDoc5: "",
  //   visaDoc6: "",
  // });

  // removing scroll whe popup is open
  useEffect(() => {
    const body = document.querySelector("body");
    if (showForm) {
      body.classList.add(styles.noScroll);
    } else {
      body.classList.remove(styles.noScroll);
    }

    return () => {
      body.classList.remove(styles.noScroll);
    };
  }, [showForm]);

  const addVisas = () => {
    if (formData.visaFields.length < 6) {
      setFormData({
        ...formData,
        visaFields: [
          ...formData.visaFields,
          { visaIssuingCountry: "", visaExpiry: "" },
        ],
      });
    }
  };

  const passportIssuingCountryToNationality = {
    "South Africa": "South Africa",
    India: "India",
    Vietnam: "Vietnam",
    Singapore: "Singapore",
    UK: "UK",
    Croatia: "Croatia",
  
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!formData.retainership){
      alert("please enter the Retainership Status");
      return;
    }
    const updatedNationality = passportIssuingCountryToNationality[formData.passportIssuingCountry] || "";
    const {
      name,
      designation,
      // signature,
      retainership,
      employeeid,
      experienceOfRigTypes,
      jackExperience,
      skidExperience,
      craneExperience,
      datesBookedForProjectFrom,
      datesBookedForProjectTo,
      fixationExperience,
      seamanBookExpiry,
      seamanIssuingCountry,
      // seamanDoc,
      bosietExpiry,
      // bosietDoc,
      h2sExpiry,
      // h2sDoc,
      medicalExpiry,
      // medicalDoc,
      insuranceExpiry,
      // insuranceDoc,
      sntEyeTestExpiry,
      yellowFeverExpiry,
      passportExpiry,
      passportIssuingCountry,
      // visaIssuingCountry1,
      // visaIssuingCountry2,
      // visaIssuingCountry3,
      // visaIssuingCountry4,
      // visaIssuingCountry5,
      // visaIssuingCountry6,
      visaExpiry1,
      visaExpiry2,
      visaExpiry3,
      visaExpiry4,
      visaExpiry5,
      visaExpiry6,
      // sntEyeTestDoc,
      // cvFile,
      nationality,
      status,
      pccExpiry,
    } = formData;

    const {
      signature,
      seamanDoc,
      bosietDoc,
      h2sDoc,
      medicalDoc,
      insuranceDoc,
      sntEyeTestDoc,
      yellowFeverDoc,
      passportDoc,
      visaDoc1,
      visaDoc2,
      visaDoc3,
      visaDoc4,
      visaDoc5,
      visaDoc6,
      photoUpload,
      vaccinationCertificate,
      pcc,
    } = selectedFile;

    let fromDate = new Date(datesBookedForProjectFrom);
    let toDate = new Date(datesBookedForProjectTo);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    let period =
      fromDate.toLocaleDateString("en-GB", options) +
      "-" +
      toDate.toLocaleDateString("en-GB", options);

    // let seamanExpiryDate = new Date(seamanBookExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let BOSIETExpiryDate = new Date(bosietExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let H2SExpiryDate = new Date(h2sExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let medicalExpiryDate = new Date(medicalExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let insuranceExpriyDate = new Date(insuranceExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let SNTEyeTestExpriyDate = new Date(sntEyeTestExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    const rigExperience = rigName.toString();

    const payload = {
      Customer_Name: name,
      Designation: designation,
      // Retainership:
      // formData.designation === "Trainee" ? "Salaried" : formData.retainership,
      Retainership:retainership,
      Employee_ID: employeeid,
      Signature: signature,
      Active_Visas_One: formData.visaFields[0]?.visaIssuingCountry1,
      Active_Visas_Two: formData.visaFields[1]?.visaIssuingCountry2,
      Active_Visas_Three: formData.visaFields[2]?.visaIssuingCountry3,
      Active_Visas_Four: formData.visaFields[3]?.visaIssuingCountry4,
      Active_Visas_Five: formData.visaFields[4]?.visaIssuingCountry5,
      Active_Visas_Six: formData.visaFields[5]?.visaIssuingCountry6,
      Active_Visas_One_Expiry: formData.visaFields[0]?.visaExpiry1,
      Active_Visas_Two_Expiry: formData.visaFields[1]?.visaExpiry2,
      Active_Visas_Three_Expiry: formData.visaFields[2]?.visaExpiry3,
      Active_Visas_Four_Expiry: formData.visaFields[3]?.visaExpiry4,
      Active_Visas_Five_Expiry: formData.visaFields[4]?.visaExpiry5,
      Active_Visas_Six_Expiry: formData.visaFields[5]?.visaExpiry6,
      Active_Visas_One_Doc: visaDoc1,
      Active_Visas_Two_Doc: visaDoc2,
      Active_Visas_Three_Doc: visaDoc3,
      Active_Visas_Four_Doc: visaDoc4,
      Active_Visas_Five_Doc: visaDoc5,
      Active_Visas_Six_Doc: visaDoc6,
      Rigs: rigExperience,
      jackingExperience: jackExperience,
      skiddingExperience: skidExperience,
      craneExperience: craneExperience,
      // upcomingProjectDuration: period,
      Fixation_Experience: fixationExperience,
      Seaman_Book_Expiry: seamanBookExpiry,
      Seaman_Issuing_Country: seamanIssuingCountry,
      Seaman_Doc: seamanDoc,
      Bosiet_Expiry: bosietExpiry,
      Bosiet_Doc: bosietDoc,
      H2s_Expiry: h2sExpiry,
      H2s_Doc: h2sDoc,
      Medical_Expiry: medicalExpiry,
      Medical_Doc: medicalDoc,
      Insurance_Expiry: insuranceExpiry,
      Insurance_Doc: insuranceDoc,
      SNT_Eye_Test_Expiry: sntEyeTestExpiry,
      SNT_Eye_Test_Doc: sntEyeTestDoc,
      Yellow_Fever_Expiry:yellowFeverExpiry,
      Yellow_Fever_Doc:yellowFeverDoc,
      Passport_Expiry: passportExpiry,
      Passport_Issuing_Country: passportIssuingCountry,
      Passport_Doc: passportDoc,
      Signature_Doc_Name: selectedFileName.signature,
      Seaman_Doc_Name: selectedFileName.seamanDoc,
      Bosiet_Doc_Name: selectedFileName.bosietDoc,
      H2S_Doc_Name: selectedFileName.h2sDoc,
      Medical_Doc_Name: selectedFileName.medicalDoc,
      Insurance_Doc_Name: selectedFileName.insuranceDoc,
      Snt_Doc_Name: selectedFileName.sntEyeTestDoc,
      Yellow_Fever_Doc_Name:selectedFileName.yellowFeverDoc,
      Passport_Doc_Name: selectedFileName.passportDoc,
      Active_Visa1_Doc_Name: selectedFileName.visaDoc1,
      Active_Visa2_Doc_Name: selectedFileName.visaDoc2,
      Active_Visa3_Doc_Name: selectedFileName.visaDoc3,
      Active_Visa4_Doc_Name: selectedFileName.visaDoc4,
      Active_Visa5_Doc_Name: selectedFileName.visaDoc5,
      Active_Visa6_Doc_Name: selectedFileName.visaDoc6,
      // CV_File: cvFile,
      Photo_Upload:photoUpload,
      Photo_Upload_Doc_Name:selectedFileName.photoUpload,
      Vaccination_Certificate:vaccinationCertificate,
      Vaccination_Certificate_Doc_Name:selectedFileName.vaccinationCertificate,
      PCC:pcc,
      PCC_Doc_Name:selectedFileName.pcc,
      PCC_Expiry:pccExpiry,
      Nationality:nationality,
      status: status,
    };
    const res = await axios.post(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professional_details",
      payload
    );

    console.log("*************", payload);

    setShowForm(false);
    setFormData({
      name: "",
      designation: "",
      retainership: "",
      employeeid: "",
      experienceOfRigTypes: "",
      jackExperience: "",
      skidExperience: "",
      craneExperience: "",
      datesBookedForProjectFrom: "",
      datesBookedForProjectTo: "",
      fixationExperience: "",
      seamanBookExpiry: "",
      seamanIssuingCountry: "",
      bosietExpiry: "",
      h2sExpiry: "",
      medicalExpiry: "",
      insuranceExpiry: "",
      sntEyeTestExpiry: "",
      yellowFeverExpiry: "",
      passportExpiry: "",
      passportIssuingCountry: "",
      visaFields: [{ visaIssuingCountry: "", visaExpiry: "" }],
      nationality:"",
      status: "",
      pccExpiry:"",
    });

    setSelectedFile({
      signature: null,
      seamanDoc: null,
      bosietDoc: null,
      h2sDoc: null,
      medicalDoc: null,
      insuranceDoc: null,
      sntEyeTestDoc: null,
      yellowFeverDoc: null,
      passportDoc: null,
      visaDoc1: null,
      visaDoc2: null,
      visaDoc3: null,
      visaDoc4: null,
      visaDoc5: null,
      visaDoc6: null,
      photoUpload:null,
      vaccinationCertificate:null,
      pcc:null,
    });

    setSelectedFileName({
      signature: "",
      seamanDoc: "",
      bosietDoc: "",
      h2sDoc: "",
      medicalDoc: "",
      insuranceDoc: "",
      sntEyeTestDoc: "",
      yellowFeverDoc: "",
      passportDoc: "",
      visaDoc1: "",
      visaDoc2: "",
      visaDoc3: "",
      visaDoc4: "",
      visaDoc5: "",
      visaDoc6: "",
      photoUpload:"",
      vaccinationCertificate:"",
      pcc:"",
    });

    console.log(res);
    if (res.status === 200) {
      alert("Professional Details Added Successfully");
      setRefresh(!refresh);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    console.log(name, value);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

 
  const handleInputChange2 = (event) => {
    const { name, value } = event.target;
  
    // If the changed input is passportIssuingCountry, update nationality
    if (name === "passportIssuingCountry") {
      const trimmedCountry = value.trim();
      const updatedNationality =
        passportIssuingCountryToNationality[trimmedCountry] || "";
  
      // Update formData with the new nationality
      setFormData((prevData) => ({
        ...prevData,
        nationality: updatedNationality,
        [name]: value, // Update the passportIssuingCountry value
      }));
    } else {
      // For other inputs, update formData as usual
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  
  const handleVisaCountryChange = (index, e) => {
    const { name, value } = e.target;

    const updatedVisaFields = [...formData.visaFields];

    const currentVisaField = updatedVisaFields[index];

    currentVisaField[name] = value;
    setFormData({
      ...formData,
      visaFields: updatedVisaFields,
    });
  };

  const removeVisaField = (index) => {
    const updatedVisaFields = [...formData.visaFields];
    updatedVisaFields.splice(index, 1);

    updatedVisaFields.forEach((el, index) => {
      const newIndex = index + 1;
      const oldIndex = index + 2;

      for (const prop in el) {
        if (prop === `visaExpiry${oldIndex}`) {
          el[`visaExpiry${newIndex}`] = el[prop];
          delete el[prop];
        } else if (prop === `visaIssuingCountry${oldIndex}`) {
          el[`visaIssuingCountry${newIndex}`] = el[prop];
          delete el[prop];
        }
      }
    });

    setFormData({
      ...formData,
      visaFields: updatedVisaFields,
    });
  };

  const dateChanges = (name, value) => {
    const selectedDate = value?.format("DD/MM/YYYY");
    setFormData({
      ...formData,
      [name]: selectedDate,
    });
    console.log(name, selectedDate);
  };

  const handleVisaDate = (name, value, index) => {
    const selectedDate = value?.format("DD/MM/YYYY");
    const updatedVisaFields = [...formData.visaFields];

    const currentVisaField = updatedVisaFields[index];

    currentVisaField[name] = selectedDate;

    setFormData({
      ...formData,
      visaFields: updatedVisaFields,
    });

    console.log(name, selectedDate);
  };

  const deleteUploadedVisaFile = async (fieldName) => {
    const fileApiName = selectedFileName[fieldName];
    console.log(fileApiName);
    try {
      const res = await axios.delete(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/deletefile/${fileApiName}`
      );

      console.log("deleted File    ", res);
    } catch (err) {
      console.log(err);
    }
    console.log("After delete   ", selectedFile);
    setRefresh(!refresh);
  };

  const removeFile = async (fieldName, e, index) => {
    e.preventDefault();

    setSelectedFile((prevSelectedFile) => ({
      ...prevSelectedFile,
      [fieldName]: null,
    }));

    clearFileInput(fieldName, index);
    await deleteUploadedVisaFile(fieldName);
  };

  const clearFileInput = (fieldName, index) => {
    const fileInput = document.querySelector(`input[name="${fieldName}"]`);
    if (fileInput) {
      fileInput.value = "";
    }

    const nextFieldName = `visaDoc${index + 2}`;

    setSelectedFileName({
      ...selectedFileName,
      [fieldName]: selectedFileName[nextFieldName] || "",
    });
    setSelectedFile({
      ...selectedFile,
      [fieldName]: selectedFile[nextFieldName] || "",
    });
  };

  const employeeAndDate = (para) => {
    setEmployees(para);
    setFilteredEmployees(para);

    // --------------------------------------------for status available and not available --------------------------------------------
    // let arr = para.map((elm) => {
    //   const tableDate = elm.upcomingProjectDuration;
    //   if (
    //     elm.upcomingProjectDuration === null ||
    //     elm.upcomingProjectDuration === undefined
    //   ) {
    //     return {
    //       ...elm,
    //       isAvailable: true,
    //     };
    //   } else if (tableDate) {
    //     const dateRange = tableDate?.split("-");
    //     let startDate = dateRange[0].split("/");
    //     startDate = new Date(`${startDate[1]}/${startDate[0]}/${startDate[2]}`);
    //     let endDate = dateRange[1].split("/");
    //     endDate = new Date(`${endDate[1]}/${endDate[0]}/${endDate[2]}`);

    //     console.log("@@@@@@@@@@@", startDate);
    //     console.log("############", endDate);
    //     const presentDate = new Date();

    //     if (presentDate >= startDate && presentDate < endDate) {
    //       return {
    //         ...elm,
    //         isAvailable: false,
    //       };
    //     } else {
    //       return {
    //         ...elm,
    //         isAvailable: true,
    //       };
    //     }
    //   }
    // });
    // console.log("______________________________", arr);
    // setEmployees(arr);

    // --------------------------------------------for status available and not available end --------------------------------------------
  };

  useEffect(() => {
    const fetchProfessionalDetails = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details"
        );

        employeeAndDate(response.data);

        const res = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/legal_details"
        );

        setCountryName(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProfessionalDetails();
  }, [refresh]);

  const handleEditClick = (index) => {
    setEditIndex(index);
    setNewEmployee(employees[index]);
  };

  const handleCancelEditClick = () => {
    setEditIndex(-1);
    //###############################
    setNewEmployee(formData);
    //###############################
  };

  const handleSearchOptions = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleHandbookClick = () => {
    // Render a handbook of the rigs
  };

  const handleStopClose = (e) => {
    e.stopPropagation();
  };

  const handleSaveClick = () => {
    if (editIndex !== -1) {
      employees[editIndex] = newEmployee;
    } else {
      employees.push(newEmployee);
    }
    setEditIndex(-1);
    //##########################################3
    setNewEmployee(formData);
    //###########################################
  };

  const handleDeleteClick = (index) => {
    employees.splice(index, 1);
    setEditIndex(-1);
  };

  const getAvailabilityColor = (availability) => {
    if (availability >= 80) {
      return "green";
    } else if (availability >= 50) {
      return "orange";
    } else {
      return "red";
    }
  };

  // ########################## edit functionality
  //
  //
  const [enableEdit, setEnableEdit] = useState(false);
  //
  //
  // ########################## edit functionality

  // +++++++++++++++++++++++++++++++ for reset ++++++++++++++++++++++++++++

  const [selectedValue, setSelectedValue] = useState({
    selectedDesignation: "",
    selectedRig: "",
    activeVisa: "",
    status: "",
  });

  const [selectedValue2, setSelectedValue2] = useState({
    fromDuration: "",
    toDuration: "",
    seamanbook: "",
    insurance: "",
  });

  const [selectedValue3, setSelectedValue3] = useState({
    jackingExperience: "",
    skiddingExperience: "",
    craneExperience: "",
    fixationExperience: "",
  });

  const isAnyValueFilled = () => {
    return (
      Object.values(selectedValue).some((value) => value !== "") ||
      Object.values(selectedValue2).some((value) => value !== "") ||
      Object.values(selectedValue3).some((value) => value !== "")
    );
  };

  const handleDropdownValues = (e) => {
    const { name, value } = e.target;
    setSelectedValue({
      ...selectedValue,
      [name]: value,
    });
  };

  const handleDropdownValues2 = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setSelectedValue2({
      ...selectedValue2,
      [name]: value,
    });
  };
  const handleDropdownValues3 = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setSelectedValue3({
      ...selectedValue3,
      [name]: value,
    });
  };

  const handleDates = (name, value) => {
    const selectedDate = value.format("DD/MM/YYYY");
    setSelectedValue2({
      ...selectedValue2,
      [name]: value,
    });
    console.log(name, selectedDate);
  };

  const handleReset = () => {
    setSelectedValue({
      selectedDesignation: "",
      selectedRig: "",
      jackingExperience: "",
      skiddingExperience: "",
      craneExperience: "",
      fixationExperience: "",
      activeVisa: "",
      status: "",
    });

    setSelectedValue2({
      fromDuration: "",
      toDuration: "",
      seamanbook: "",
      insurance: "",
    });

    setSelectedValue3({
      jackingExperience: "",
      skiddingExperience: "",
      craneExperience: "",
      fixationExperience: "",
    });
    setNewArr([]);
    setNewArr2([]);
    setNewArr3([]);
  };

  // +++++++++++++++++++++++++++++++ for reset end ++++++++++++++++++++++++++++

  //_____________________________________________________________________________ for dropdowns

  // const dropdownRig = [...new Set(employees.map((elm) => elm.Rigs))].filter(
  //   Boolean
  // );

  // const dropdownJacking = [
  //   ...new Set(employees.map((elm) => elm.jackingExperience)),
  // ].filter(Boolean);

  // const dropdownSkidding = [
  //   ...new Set(employees.map((elm) => elm.skiddingExperience)),
  // ].filter(Boolean);

  // const dropdownCrane = [
  //   ...new Set(employees.map((elm) => elm.craneExperience)),
  // ].filter(Boolean);

  // const dropdownFixation = [
  //   ...new Set(employees.map((elm) => elm.Fixation_Experience)),
  // ].filter(Boolean);

  const dropdownVisa = [...new Set(countryName.map((el) => el.country))].filter(
    Boolean
  );

  const handleDetailsClick = (employee) => {
    console.log(employee);
    navigate(`/professional-detail-summary/${employee.Customer_Name}`);

  };

  // ___________________________________________________________________________________

  const handleSearchName = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value.toLowerCase();
    setSearchTerm(lowerCaseValue);

    const filtered = employees.filter((el, index) => {
      const name = el.Customer_Name ? el.Customer_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    });

    setFilteredEmployees(filtered);
  };

  const [newArr, setNewArr] = useState([]);
  const [newArr2, setNewArr2] = useState([]);
  const [newArr3, setNewArr3] = useState([]);

  const runAdvanceSearchFilter = () => {
    const filterData = Object.values(selectedValue);
    let filteredArray = filterData.filter((term) => term);

    let filterData2 = Object.values(selectedValue2);
    let filteredArray2 = filterData2.filter((term) => term);

    // let filterData3 = Object.values(selectedValue3);
    // let filteredArray3 = filterData3.filter((term) => term);

    // const filteredResultWithAdvanceSearch = filteredEmployees.filter(emp => emp?.status.toLowerCase() === filteredArray[0].toLowerCase());
    // console.log("filteredResultWithAdvanceSearch::: =>", filteredResultWithAdvanceSearch);
    // setFilteredEmployees([]);

    setNewArr(filteredArray);
    setNewArr2(filteredArray2);
    setNewArr3(selectedValue3);
  };

  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const filterEmployeesFunction = () => {
    let data = employees.filter((item) => {
      if (newArr.length === 0) return item;

      let searchLowerTerm = newArr.map((term) => term.toLowerCase());

      return searchLowerTerm.every((term) => {
        let designation = item.Designation?.toLowerCase();
        let rigs = item.Rigs?.toLowerCase();
        let activeVisa1 = item.Active_Visas_One?.toLowerCase();
        let activeVisa2 = item.Active_Visas_Two?.toLowerCase();
        let activeVisa3 = item.Active_Visas_Three?.toLowerCase();
        let activeVisa4 = item.Active_Visas_Four?.toLowerCase();
        let activeVisa5 = item.Active_Visas_Five?.toLowerCase();
        let activeVisa6 = item.Active_Visas_Six?.toLowerCase();
        let status = item.status?.toLowerCase();
        return (
          (designation && designation.includes(term)) ||
          (rigs && rigs.includes(term)) ||
          (activeVisa1 && activeVisa1.includes(term)) ||
          (activeVisa2 && activeVisa2.includes(term)) ||
          (activeVisa3 && activeVisa3.includes(term)) ||
          (activeVisa4 && activeVisa4.includes(term)) ||
          (activeVisa5 && activeVisa5.includes(term)) ||
          (activeVisa6 && activeVisa6.includes(term)) ||
          (status && status.includes(term))
        );
      });
    });
    filterEmployeesFunction2(data);
  };

  const filterEmployeesFunction2 = (items) => {
    let data = items.filter((item) => {
      // date checker
      let startDate;
      let endDate;
      const datesInTable = item.upcomingProjectDuration;
      if (
        item.upcomingProjectDuration === null ||
        item.upcomingProjectDuration === undefined
      ) {
        console.log("Invalid date");
      } else if (datesInTable) {
        const dateRange = datesInTable.split("-");

        startDate = dateRange[0].split("/");
        endDate = dateRange[1].split("/");

        startDate = new Date(`${startDate[1]}/${startDate[0]}/${startDate[2]}`);
        endDate = new Date(`${endDate[1]}/${endDate[0]}/${endDate[2]}`);
      }
      // date checker end

      return (
        ((selectedValue2.fromDuration !== "" &&
          selectedValue2.toDuration !== "" &&
          new Date(selectedValue2.fromDuration) > endDate &&
          new Date(selectedValue2.toDuration) > endDate) ||
          (new Date(selectedValue2.fromDuration) < startDate &&
            new Date(selectedValue2.toDuration) < startDate) ||
          selectedValue2.fromDuration === "" ||
          selectedValue2.toDuration === "") &&
        ((selectedValue2.insurance !== "" &&
          item.Insurance_Expiry &&
          item.Insurance_Expiry !== "") ||
          selectedValue2.insurance === "") &&
        ((selectedValue2.seamanbook !== "" &&
          item.Seaman_Book_Expiry &&
          item.Seaman_Book_Expiry !== "") ||
          selectedValue2.seamanbook === "")
      );
    });
    filterEmployeesFunction3(data);
  };

  const filterEmployeesFunction3 = (items) => {
    const arr = Object.entries(newArr3);

    let completeFilter = items.filter((el, index) => {
      return arr.every((it, idx) => {
        const jack = el.jackingExperience;
        const skid = el.skiddingExperience;
        const crane = el.craneExperience;
        const fixation = el.Fixation_Experience;

        switch (it[0]) {
          case "jackingExperience":
            return !it[1] || (jack && parseInt(jack) >= parseInt(it[1]));
          case "skiddingExperience":
            return !it[1] || (skid && parseInt(skid) >= parseInt(it[1]));
          case "craneExperience":
            return !it[1] || (crane && parseInt(crane) >= parseInt(it[1]));
          case "fixationExperience":
            return (
              !it[1] || (fixation && parseInt(fixation) >= parseInt(it[1]))
            );
        }
      });
    });

    setFilteredEmployees(completeFilter);
  };

  useEffect(() => {
    filterEmployeesFunction();
  }, [newArr, newArr2, newArr3]);

  // file upload

  const handleFileUpload = async (file, fieldName) => {
    const formData = new FormData();

    formData.append("file", file);
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/uploadfile",
        formData
      );

      // console.log(res.data);
      const pathOfFile = res.data.fileUrl;
      const fileNameApi = res.data.filename;
      // const originalName = res.data.originalname;

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: fileNameApi,
      });

      setSelectedFile({
        ...selectedFile,
        [fieldName]: pathOfFile,
      });

      // setSelectedFileOriginalName({
      //   ...selectedFileOriginalName,
      //   [fieldName]: originalName,
      // });
    } catch (error) {
      console.error("Error uploading signature:", error);
    }
  };

  // multiselect for experience
  const [rigName, setRigName] = useState([]);

  const handleMultiSelectChange = (e) => {
    const { value } = e.target;
    setRigName(typeof value === "string" ? value.split(",") : value);
    console.log(value);
  };

  return (
    <div>
      <div className={styles.topContainer}>
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearchName}
            className={styles.searchBox}
          />
          <svg
            className={styles.searchIconFilter}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#BCBCBC"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#BCBCBC"
            />
          </svg>
        </div>

        <div className={styles.addAndEditButton}>
          {!enableEdit ? (
            <>
              {featureedit.length > 0 && (<Button
                variant="contained"
                className={styles.topButtons}
                onClick={() => setEnableEdit(!enableEdit)}>
                Edit
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.5 18.334H2.5C2.15833 18.334 1.875 18.0507 1.875 17.709C1.875 17.3673 2.15833 17.084 2.5 17.084H17.5C17.8417 17.084 18.125 17.3673 18.125 17.709C18.125 18.0507 17.8417 18.334 17.5 18.334Z"
                    fill="white"
                  />
                  <path
                    d="M15.85 2.90005C14.2333 1.28338 12.65 1.24172 10.9916 2.90005L9.98331 3.90838C9.89998 3.99172 9.86665 4.12505 9.89998 4.24172C10.5333 6.45005 12.3 8.21672 14.5083 8.85005C14.5416 8.85838 14.575 8.86672 14.6083 8.86672C14.7 8.86672 14.7833 8.83338 14.85 8.76672L15.85 7.75838C16.675 6.94172 17.075 6.15005 17.075 5.35005C17.0833 4.52505 16.6833 3.72505 15.85 2.90005Z"
                    fill="white"
                  />
                  <path
                    d="M13.0084 9.60768C12.7668 9.49101 12.5334 9.37435 12.3084 9.24102C12.1251 9.13268 11.9501 9.01602 11.7751 8.89102C11.6334 8.79935 11.4668 8.66602 11.3084 8.53268C11.2918 8.52435 11.2334 8.47435 11.1668 8.40768C10.8918 8.17435 10.5834 7.87435 10.3084 7.54102C10.2834 7.52435 10.2418 7.46602 10.1834 7.39102C10.1001 7.29102 9.95844 7.12435 9.83344 6.93268C9.73344 6.80768 9.61677 6.62435 9.50844 6.44102C9.3751 6.21602 9.25844 5.99102 9.14177 5.75768C8.9888 5.4299 8.55859 5.33253 8.30282 5.5883L3.61677 10.2743C3.50844 10.3827 3.40844 10.591 3.38344 10.7327L2.93344 13.9243C2.8501 14.491 3.00844 15.0243 3.35844 15.3827C3.65844 15.6743 4.0751 15.8327 4.5251 15.8327C4.6251 15.8327 4.7251 15.8243 4.8251 15.8077L8.0251 15.3577C8.1751 15.3327 8.38344 15.2327 8.48344 15.1243L13.1772 10.4306C13.4279 10.1799 13.3337 9.74863 13.0084 9.60768Z"
                    fill="white"
                  />
                </svg>
              </Button>)}
              {featurecn.length > 0 && (<Button
                variant="contained"
                className={styles.topButtons}
                onClick={() => setShowForm(true)}>
                Add New
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.4915 1.66602H6.50817C3.47484 1.66602 1.6665 3.47435 1.6665 6.50768V13.4827C1.6665 16.5243 3.47484 18.3327 6.50817 18.3327H13.4832C16.5165 18.3327 18.3248 16.5243 18.3248 13.491V6.50768C18.3332 3.47435 16.5248 1.66602 13.4915 1.66602ZM14.9998 10.6243H10.6248V14.9993C10.6248 15.341 10.3415 15.6243 9.99984 15.6243C9.65817 15.6243 9.37484 15.341 9.37484 14.9993V10.6243H4.99984C4.65817 10.6243 4.37484 10.341 4.37484 9.99935C4.37484 9.65768 4.65817 9.37435 4.99984 9.37435H9.37484V4.99935C9.37484 4.65768 9.65817 4.37435 9.99984 4.37435C10.3415 4.37435 10.6248 4.65768 10.6248 4.99935V9.37435H14.9998C15.3415 9.37435 15.6248 9.65768 15.6248 9.99935C15.6248 10.341 15.3415 10.6243 14.9998 10.6243Z"
                    fill="white"
                  />
                </svg>
              </Button>)}
            </>
          ) : (
            <Button
              variant="contained"
              className={styles.topButtons}
              onClick={() => setEnableEdit(!enableEdit)}>
              Cancel
            </Button>
          )}
        </div>
      </div>

      {/* <div className={styles.dropdownContainer}>
        <div className={styles.dropDownStyles}>
          <h5>Designation</h5>
          <div className={styles.iconInputContainer}>
            <select
              id="selectopen"
              name="selectedDesignation"
              onChange={handleDropdownValues}
              value={selectedValue.selectedDesignation}>
              <option key="" value="">
                Select
              </option>
              <option value="Supervisor">Supervisor</option>
              <option value="Technician">Technician</option>
              <option value="Trainee">Trainee</option>
            </select>
            <svg
              className={styles.svgIconStyle}
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9197 8.18005H11.6897H6.07975C5.11975 8.18005 4.63975 9.34005 5.31975 10.0201L10.4997 15.2001C11.3297 16.0301 12.6797 16.0301 13.5097 15.2001L15.4797 13.2301L18.6897 10.0201C19.3597 9.34005 18.8797 8.18005 17.9197 8.18005Z"
                fill="#394759"
              />
            </svg>
          </div>
        </div>

        <div className={styles.dropDownStyles}>
          <h5>Rig Types</h5>
          <div className={styles.iconInputContainer}>
            <select
              name="selectedRig"
              value={selectedValue.selectedRig}
              onChange={handleDropdownValues}>
              <option key="" value="">
                Select
              </option>
              {names.map((currelm) => (
                <option value={currelm} key={currelm}>
                  {currelm}
                </option>
              ))}
            </select>
            <svg
              className={styles.svgIconStyle}
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9197 8.18005H11.6897H6.07975C5.11975 8.18005 4.63975 9.34005 5.31975 10.0201L10.4997 15.2001C11.3297 16.0301 12.6797 16.0301 13.5097 15.2001L15.4797 13.2301L18.6897 10.0201C19.3597 9.34005 18.8797 8.18005 17.9197 8.18005Z"
                fill="#394759"
              />
            </svg>
          </div>
        </div>

        <div className={styles.dropDownStyles}>
          <h5>Jacking Experience</h5>
          <div className={styles.iconInputContainer}>
            <span className={styles.spanArrow}>{`>`}</span>
            <input
              type="number"
              name="jackingExperience"
              onChange={handleDropdownValues3}
              value={selectedValue3.jackingExperience}
              className={styles.selectDropDown}
            />
            <select name="yearsOrMonths" className={styles.monthAndYear}>
              <option value="Months">Months</option>
              <option value="Years">Years</option>
            </select>
            <svg
              className={styles.svgIconStyle}
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9197 8.18005H11.6897H6.07975C5.11975 8.18005 4.63975 9.34005 5.31975 10.0201L10.4997 15.2001C11.3297 16.0301 12.6797 16.0301 13.5097 15.2001L15.4797 13.2301L18.6897 10.0201C19.3597 9.34005 18.8797 8.18005 17.9197 8.18005Z"
                fill="#394759"
              />
            </svg>
          </div>
        </div>

        <div className={styles.dropDownStyles}>
          <h5>Skidding Experience</h5>
          <div className={styles.iconInputContainer}>
            <span className={styles.spanArrow}>{`>`}</span>
            <input
              type="number"
              name="skiddingExperience"
              onChange={handleDropdownValues3}
              value={selectedValue3.skiddingExperience}
              className={styles.selectDropDown}
            />
            <select name="yearsOrMonths" className={styles.monthAndYear}>
              <option value="Months">Months</option>
              <option value="Years">Years</option>
            </select>
            <svg
              className={styles.svgIconStyle}
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9197 8.18005H11.6897H6.07975C5.11975 8.18005 4.63975 9.34005 5.31975 10.0201L10.4997 15.2001C11.3297 16.0301 12.6797 16.0301 13.5097 15.2001L15.4797 13.2301L18.6897 10.0201C19.3597 9.34005 18.8797 8.18005 17.9197 8.18005Z"
                fill="#394759"
              />
            </svg>
          </div>
        </div>

        <div className={styles.dropDownStyles}>
          <h5>Crane Experience </h5>
          <div className={styles.iconInputContainer}>
            <span className={styles.spanArrow}>{`>`}</span>
            <input
              type="number"
              name="craneExperience"
              onChange={handleDropdownValues3}
              value={selectedValue3.craneExperience}
              className={styles.selectDropDown}
            />
            <select name="yearsOrMonths" className={styles.monthAndYear}>
              <option value="Months">Months</option>
              <option value="Years">Years</option>
            </select>
            <svg
              className={styles.svgIconStyle}
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9197 8.18005H11.6897H6.07975C5.11975 8.18005 4.63975 9.34005 5.31975 10.0201L10.4997 15.2001C11.3297 16.0301 12.6797 16.0301 13.5097 15.2001L15.4797 13.2301L18.6897 10.0201C19.3597 9.34005 18.8797 8.18005 17.9197 8.18005Z"
                fill="#394759"
              />
            </svg>
          </div>
        </div>

        <div className={styles.dropDownStyles}>
          <h5>Fixation Experience</h5>
          <div className={styles.iconInputContainer}>
            <span className={styles.spanArrow}>{`>`}</span>
            <input
              type="number"
              name="fixationExperience"
              onChange={handleDropdownValues3}
              value={selectedValue3.fixationExperience}
              className={styles.selectDropDown}
            />
            <select name="yearsOrMonths" className={styles.monthAndYear}>
              <option value="Months">Months</option>
              <option value="Years">Years</option>
            </select>
            <svg
              className={styles.svgIconStyle}
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9197 8.18005H11.6897H6.07975C5.11975 8.18005 4.63975 9.34005 5.31975 10.0201L10.4997 15.2001C11.3297 16.0301 12.6797 16.0301 13.5097 15.2001L15.4797 13.2301L18.6897 10.0201C19.3597 9.34005 18.8797 8.18005 17.9197 8.18005Z"
                fill="#394759"
              />
            </svg>
          </div>
        </div>

        <div className={styles.dropDownStyles}>
          <h5>Active Visa</h5>
          <div className={styles.iconInputContainer}>
            <select
              name="activeVisa"
              onChange={handleDropdownValues}
              value={selectedValue.activeVisa}>
              <option value="">Select</option>
              {dropdownVisa.map((elm) => (
                <option value={elm} key={elm}>
                  {elm}
                </option>
              ))}
            </select>
            <svg
              className={styles.svgIconStyle}
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9197 8.18005H11.6897H6.07975C5.11975 8.18005 4.63975 9.34005 5.31975 10.0201L10.4997 15.2001C11.3297 16.0301 12.6797 16.0301 13.5097 15.2001L15.4797 13.2301L18.6897 10.0201C19.3597 9.34005 18.8797 8.18005 17.9197 8.18005Z"
                fill="#394759"
              />
            </svg>
          </div>
        </div>

        <div className={styles.durationProjectContainer}>
          <h5>Duration of Project</h5>

          <div className={styles.durationPositioning}>
            <div className={styles.addingSpacingBetweenDates}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="fromDuration"
                  sx={{
                    width: "150px",
                  }}
                  value={
                    selectedValue2.fromDuration
                      ? dayjs(selectedValue2.fromDuration, "DD/MM/YYYY")
                      : null
                  }
                  onChange={(value) => handleDates("fromDuration", value)}
                  slotProps={{ textField: { size: "small" } }}
                  format="DD/MM/YYYY"
                  label="From"
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="toDuration"
                  sx={{
                    width: "150px",
                  }}
                  value={
                    selectedValue2.toDuration
                      ? dayjs(selectedValue2.toDuration, "DD/MM/YYYY")
                      : null
                  }
                  onChange={(value) => handleDates("toDuration", value)}
                  slotProps={{ textField: { size: "small" } }}
                  format="DD/MM/YYYY"
                  label="To"
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <div className={styles.dropDownStyles}>
          <h5>Insurance</h5>
          <div className={styles.iconInputContainer}>
            <select
              name="insurance"
              onChange={handleDropdownValues2}
              value={selectedValue2.insurance}>
              <option value="">Select</option>
              <option value="Yes">Yes</option>
            </select>
            <svg
              className={styles.svgIconStyle}
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9197 8.18005H11.6897H6.07975C5.11975 8.18005 4.63975 9.34005 5.31975 10.0201L10.4997 15.2001C11.3297 16.0301 12.6797 16.0301 13.5097 15.2001L15.4797 13.2301L18.6897 10.0201C19.3597 9.34005 18.8797 8.18005 17.9197 8.18005Z"
                fill="#394759"
              />
            </svg>
          </div>
        </div>

        <div className={styles.dropDownStyles}>
          <h5>Seaman Book</h5>
          <div className={styles.iconInputContainer}>
            <select
              name="seamanbook"
              onChange={handleDropdownValues2}
              value={selectedValue2.seamanbook}>
              <option value="">Select</option>
              <option value="Yes">Yes</option>
            </select>
            <svg
              className={styles.svgIconStyle}
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9197 8.18005H11.6897H6.07975C5.11975 8.18005 4.63975 9.34005 5.31975 10.0201L10.4997 15.2001C11.3297 16.0301 12.6797 16.0301 13.5097 15.2001L15.4797 13.2301L18.6897 10.0201C19.3597 9.34005 18.8797 8.18005 17.9197 8.18005Z"
                fill="#394759"
              />
            </svg>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          {isAnyValueFilled() && (
            <div>
              <Button
                variant="outlined"
                onClick={handleReset}
                className={`${styles.filterButtons} ${styles.leftButton}`}>
                Reset
              </Button>
            </div>
          )}

          <div>
            <Button
              variant="contained"
              className={`${styles.filterButtons} ${styles.rightButton}`}
              onClick={runAdvanceSearchFilter}>
              Advance Search
            </Button>
          </div>
        </div>
      </div> */}

      <div>
        {showForm && (
          <div
            className={styles.popupContainer}
            onClick={(e) => {
              e.stopPropagation();
              setShowForm(false);
            }}>
            <div className={styles.popup} onClick={handleStopClose}>
              <form>
                <div className={styles.popupItems}>
                  <b>Add New</b>
                  <div style={{ cursor: "pointer" }}>
                    <svg
                      onClick={() => setShowForm(false)}
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.4998 2.41663C7.84192 2.41663 2.4165 7.84204 2.4165 14.5C2.4165 21.1579 7.84192 26.5833 14.4998 26.5833C21.1578 26.5833 26.5832 21.1579 26.5832 14.5C26.5832 7.84204 21.1578 2.41663 14.4998 2.41663ZM18.5598 17.2791C18.9103 17.6295 18.9103 18.2095 18.5598 18.56C18.3786 18.7412 18.149 18.8258 17.9194 18.8258C17.6898 18.8258 17.4603 18.7412 17.279 18.56L14.4998 15.7808L11.7207 18.56C11.5394 18.7412 11.3098 18.8258 11.0803 18.8258C10.8507 18.8258 10.6211 18.7412 10.4398 18.56C10.0894 18.2095 10.0894 17.6295 10.4398 17.2791L13.219 14.5L10.4398 11.7208C10.0894 11.3704 10.0894 10.7904 10.4398 10.44C10.7903 10.0895 11.3703 10.0895 11.7207 10.44L14.4998 13.2191L17.279 10.44C17.6294 10.0895 18.2094 10.0895 18.5598 10.44C18.9103 10.7904 18.9103 11.3704 18.5598 11.7208L15.7807 14.5L18.5598 17.2791Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div className={styles.popupItems}>
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>Designation:</label>
                  <select
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}>
                    <option value="">Select Value</option>
                    <option value="Supervisor">Supervisor</option>
                    <option value="Technician">Technician</option>
                    <option value="Trainee">Trainee</option>
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.popupItems}>
                  <label>RetainerShip:</label>
                  <select
                    name="retainership"
                    // value={
                    //   formData.designation === "Trainee"
                    //     ? "Salaried"
                    //     : formData.retainership
                    // }
                    value={formData.retainership}
                    onChange={handleInputChange}
                    // disabled={formData.designation === "Trainee"}
                    >
                    <option value="">Select Value</option>
                    {/* {formData.designation === "Supervisor" ||
                    formData.designation === "Technician" ? (
                      <>
                        <option value="Retainer">Retainer</option>
                        <option value="Non-retainer">Non-retainer</option>
                      </>
                    ) : (
                      <option value="Salaried">Salaried</option>
                    )} */}
                      <option value="Monthly-Fixed">Monthly-Fixed</option>
                   <option value="Daily-Retainer">Daily-Retainer</option>
                  <option value="Non-retainer">Non-retainer</option>
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.popupItems}>
                  <label>Employee ID:</label>
                  <input
                    type="text"
                    name="employeeid"
                    value={formData.Employee_ID}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>Signature:</label>
                  <div>
                    <UploadDocument
                      fieldName={"signature"}
                      selectedFile={selectedFile.signature}
                      selectedFileName={selectedFileName.signature}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>Experience of Rig Types:</label>
                  {/* <select
                    name="experienceOfRigTypes"
                    value={formData.experienceOfRigTypes}
                    onChange={handleInputChange}>
                    <option value="">Select Value</option>
                    <option value="MLT">MLT</option>
                    <option value="F&G">F&G</option>
                    <option value="Keppel">Keppel</option>
                    <option value="BMC">BMC</option>
                    <option value="Modec">Modec</option>
                  </select>
                   */}

                  <Select
                    multiple
                    sx={{
                      position: "relative",
                      backgroundColor: "white",
                      width: 210,
                      backgroundColor: "white",
                      borderRadius: "8px",
                      border: "1px solid #dadce0;",
                      height: "45px",
                      fontSize: "16px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    value={rigName}
                    onChange={handleMultiSelectChange}
                    renderValue={(selected) => selected.join(",")}
                    MenuProps={MenuProps}
                    IconComponent={() => null}>
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={rigName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Jacking Experience <br /> (in months)
                  </label>
                  <input
                    type="text"
                    name="jackExperience"
                    value={formData.jackExperience}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Skidding Experience <br /> (in months)
                  </label>
                  <input
                    type="text"
                    name="skidExperience"
                    value={formData.skidExperience}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Crane Experience <br /> (in months)
                  </label>
                  <input
                    type="text"
                    name="craneExperience"
                    value={formData.craneExperience}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Fixation Experience <br /> (in months)
                  </label>
                  <input
                    type="text"
                    name="fixationExperience"
                    value={formData.fixationExperience}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  className={styles.popupItems}
                  style={{ paddingBottom: "0" }}>
                  <label>
                    <div>
                      Active Visas <br /> of countries:
                    </div>

                    {/* <div>
                      <Tooltip
                        title="Country name must be added to legal details page to reflect in drop-down"
                        placement="top">
                        <InfoOutlinedIcon sx={{ fontSize: "20px" }} />
                      </Tooltip>
                    </div> */}
                  </label>
                  <div>
                    {formData?.visaFields?.map((el, index) => (
                      <div className={styles.nameFieldsspacing} key={index + 1}>
                        <div className={styles.flexSpacing}>
                          {formData.visaFields.length > 1 && (
                            <IconButton
                              onClick={(e) => {
                                removeVisaField(index);
                                if (
                                  selectedFile[`visaDoc${index + 1}`] !== null
                                ) {
                                  removeFile(`visaDoc${index + 1}`, e, index);
                                }
                              }}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M5.38681 15.8327C5.07701 15.8327 4.7672 15.7186 4.52262 15.4741C4.04975 15.0014 4.04975 14.219 4.52262 13.7463L13.7516 4.52054C14.2245 4.04784 15.0071 4.04784 15.48 4.52054C15.9529 4.99324 15.9529 5.77563 15.48 6.24833L6.25101 15.4741C6.02273 15.7186 5.69662 15.8327 5.38681 15.8327Z"
                                  fill="black"
                                />
                                <path
                                  d="M14.6158 15.8327C14.306 15.8327 13.9962 15.7186 13.7516 15.4741L4.52262 6.24833C4.04975 5.77563 4.04975 4.99324 4.52262 4.52054C4.99548 4.04784 5.77815 4.04784 6.25101 4.52054L15.48 13.7463C15.9529 14.219 15.9529 15.0014 15.48 15.4741C15.2354 15.7186 14.9256 15.8327 14.6158 15.8327Z"
                                  fill="black"
                                />
                              </svg>
                            </IconButton>
                          )}
                          <h6>Visa {index + 1}</h6>
                        </div>
                        <div className={styles.spaceContainers}>
                          <div className={styles.flexSpacing}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <CustomDatePicker
                                name={"visaExpiry" + (index + 1)}
                                value={
                                  formData.visaFields[index][
                                    "visaExpiry" + (index + 1)
                                  ]
                                    ? dayjs(
                                        formData.visaFields[index][
                                          "visaExpiry" + (index + 1)
                                        ],
                                        "DD/MM/YYYY"
                                      )
                                    : null
                                }
                                onChange={(value) =>
                                  handleVisaDate(
                                    "visaExpiry" + (index + 1),
                                    value,
                                    index
                                  )
                                }
                                slotProps={{
                                  textField: {
                                    size: "small",
                                  },
                                }}
                                sx={{
                                  width: "102px",
                                  backgroundColor: "white",
                                  borderRadius: "8px",
                                }}
                                format="DD/MM/YYYY"
                                label="Expiry"
                              />
                            </LocalizationProvider>

                            <select
                              style={{ width: "102px" }}
                              name={"visaIssuingCountry" + (index + 1)}
                              value={
                                formData.visaFields[index][
                                  "visaIssuingCountry" + (index + 1)
                                ]
                              }
                              onChange={(e) =>
                                handleVisaCountryChange(index, e)
                              }>
                              <option value="">Country</option>
                              {dropdownVisa.map((elm) => (
                                <option value={elm} key={elm}>
                                  {elm}
                                </option>
                              ))}
                            </select>
                            <svg
                              className={styles.svgIconStyle}
                              style={{ right: "15px" }}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                fill="black"
                              />
                            </svg>
                          </div>

                          <div>
                            <UploadDocument
                              fieldName={`visaDoc${index + 1}`}
                              selectedFile={selectedFile[`visaDoc${index + 1}`]}
                              selectedFileName={
                                selectedFileName[`visaDoc${index + 1}`]
                              }
                              setSelectedFile={setSelectedFile}
                              uploadFile={handleFileUpload}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.addMoreContainer}>
                  <Button
                    onClick={addVisas}
                    className={styles.addMoreButton}
                    variant="contained">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.493 1.66602H6.50964C3.4763 1.66602 1.66797 3.47435 1.66797 6.50768V13.4827C1.66797 16.5243 3.4763 18.3327 6.50964 18.3327H13.4846C16.518 18.3327 18.3263 16.5243 18.3263 13.491V6.50768C18.3346 3.47435 16.5263 1.66602 13.493 1.66602ZM15.0013 10.6243H10.6263V14.9993C10.6263 15.341 10.343 15.6243 10.0013 15.6243C9.65964 15.6243 9.3763 15.341 9.3763 14.9993V10.6243H5.0013C4.65964 10.6243 4.3763 10.341 4.3763 9.99935C4.3763 9.65768 4.65964 9.37435 5.0013 9.37435H9.3763V4.99935C9.3763 4.65768 9.65964 4.37435 10.0013 4.37435C10.343 4.37435 10.6263 4.65768 10.6263 4.99935V9.37435H15.0013C15.343 9.37435 15.6263 9.65768 15.6263 9.99935C15.6263 10.341 15.343 10.6243 15.0013 10.6243Z"
                        fill="white"
                      />
                    </svg>
                    Add Visa
                  </Button>
                </div>

                {/* <label>Dates Booked For Project :- </label>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <div className={styles.popupItems}>
                    <label>From:</label>
                    <input
                      type="date"
                      name="datesBookedForProjectFrom"
                      value={formData.datesBookedForProjectFrom}
                      onChange={handleInputChange}
                      style={{ width: "150px" }}
                    />
                  </div>

                  <div className={styles.popupItems}>
                    <label>To:</label>
                    <input
                      type="date"
                      name="datesBookedForProjectTo"
                      value={formData.datesBookedForProjectTo}
                      onChange={handleInputChange}
                      style={{ width: "150px" }}
                    />
                  </div>
                </div> */}

                <div className={styles.popupItems}>
                  <label>
                    Seaman Book <br /> Expiry | Issuing Country | Doc
                  </label>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDatePicker
                          name="seamanBookExpiry"
                          value={
                            formData.seamanBookExpiry
                              ? dayjs(formData.seamanBookExpiry, "DD/MM/YYYY")
                              : null
                          }
                          onChange={(value) =>
                            dateChanges("seamanBookExpiry", value)
                          }
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                          sx={{
                            width: "102px",
                            backgroundColor: "white",
                            borderRadius: "8px",
                          }}
                          format="DD/MM/YYYY"
                          label="Expiry"
                        />
                      </LocalizationProvider>

                      <select
                        style={{ width: "102px" }}
                        name="seamanIssuingCountry"
                        value={formData.seamanIssuingCountry}
                        onChange={handleInputChange}>
                        <option value="">Country</option>
                        <option value="Belize">Belize</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Indian">Indian</option>
                        <option value="Curacao">Curacao</option>
                        <option value="Palau">Palau</option>
                        <option value="Bahamas">Bahamas </option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </div>

                    <div>
                      <UploadDocument
                        fieldName={"seamanDoc"}
                        selectedFile={selectedFile.seamanDoc}
                        selectedFileName={selectedFileName.seamanDoc}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    BOSIET <br /> Expiry | Doc:
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      <UploadDocument
                        fieldName={"bosietDoc"}
                        selectedFile={selectedFile.bosietDoc}
                        selectedFileName={selectedFileName.bosietDoc}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="bosietExpiry"
                        value={
                          formData.bosietExpiry
                            ? dayjs(formData.bosietExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => dateChanges("bosietExpiry", value)}
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    H2S <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      <UploadDocument
                        fieldName={"h2sDoc"}
                        selectedFile={selectedFile.h2sDoc}
                        selectedFileName={selectedFileName.h2sDoc}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="h2sExpiry"
                        value={
                          formData.h2sExpiry
                            ? dayjs(formData.h2sExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => dateChanges("h2sExpiry", value)}
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Medical <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      <UploadDocument
                        fieldName={"medicalDoc"}
                        selectedFile={selectedFile.medicalDoc}
                        selectedFileName={selectedFileName.medicalDoc}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="medicalExpiry"
                        value={
                          formData.medicalExpiry
                            ? dayjs(formData.medicalExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          dateChanges("medicalExpiry", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Yellow Fever <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      <UploadDocument
                        fieldName={"yellowFever"}
                        selectedFile={selectedFile.yellowFever}
                        selectedFileName={selectedFileName.yellowFever}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="yellowFeverExpiry"
                        value={
                          formData.yellowFeverExpiry
                            ? dayjs(formData.yellowFeverExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          dateChanges("yellowFeverExpiry", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Insurance <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      <UploadDocument
                        fieldName={"insuranceDoc"}
                        selectedFile={selectedFile.insuranceDoc}
                        selectedFileName={selectedFileName.insuranceDoc}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="insuranceExpiry"
                        value={
                          formData.insuranceExpiry
                            ? dayjs(formData.insuranceExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          dateChanges("insuranceExpiry", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                    {/* <input
                      style={{ width: "102px" }}
                      type="date"
                      name="insuranceExpiry"
                      value={formData.insuranceExpiry}
                      onChange={handleInputChange}
                    /> */}
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    SNT Eye Test <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      <UploadDocument
                        fieldName={"sntEyeTestDoc"}
                        selectedFile={selectedFile.sntEyeTestDoc}
                        selectedFileName={selectedFileName.sntEyeTestDoc}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="sntEyeTestExpiry"
                        value={
                          formData.sntEyeTestExpiry
                            ? dayjs(formData.sntEyeTestExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          dateChanges("sntEyeTestExpiry", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

               

                <div className={styles.popupItems}>
                  <label>
                    Passport
                    <br /> Expiry | Issuing Country | Doc
                  </label>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDatePicker
                          name="passportExpiry"
                          value={
                            formData.passportExpiry
                              ? dayjs(formData.passportExpiry, "DD/MM/YYYY")
                              : null
                          }
                          onChange={(value) =>
                            dateChanges("passportExpiry", value)
                          }
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                          sx={{
                            width: "102px",
                            backgroundColor: "white",
                            borderRadius: "8px",
                          }}
                          format="DD/MM/YYYY"
                          label="Expiry"
                        />
                      </LocalizationProvider>
                      <select
                        style={{ width: "102px" }}
                        name="passportIssuingCountry"
                        value={formData.passportIssuingCountry}
                        onChange={handleInputChange2}>
                        <option value="">Country</option>
                        <option value="South Africa">South Africa</option>
                        <option value="India">India</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Singapore">Singapore</option>
                        <option value="UK">UK</option>
                        <option value="Croatia">Croatia</option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </div>

                    <div>
                      <UploadDocument
                        fieldName={"passportDoc"}
                        selectedFile={selectedFile.passportDoc}
                        selectedFileName={selectedFileName.passportDoc}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.popupItems}>
                  <label>Nationality:</label>
                  <input
                    type="text"
                    name="nationality"
                    value={formData.nationality}
                    onChange={handleInputChange2}
                  />
                </div>


                {/* ------------------------------------------------------------change state variable used here ---------------------------------------------- */}

                {/* <div className={styles.popupItems}>
                  <label>CV:</label> */}
                {/* <input
                    type="file"
                    name="cvFile"
                    value={formData.cvFile}
                    onChange={handleInputChange}
                  /> */}
                {/* <div>
                    <UploadDocument
                      fieldName={"cvFile"}
                      selectedFile={selectedFile.cvFile}
                      selectedFileName={selectedFileName.cvFile}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  </div>
                </div> */}
                 <div className={styles.popupItems}>
                  <label>Photo Upload:</label>
                  <div>
                    <UploadDocument
                      fieldName={"photoUpload"}
                      selectedFile={selectedFile.photoUpload}
                      selectedFileName={selectedFileName.photoUpload}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>Vaccination Certificate :</label>
                  <div>
                    <UploadDocument
                      fieldName={"vaccinationCertificate"}
                      selectedFile={selectedFile.vaccinationCertificate}
                      selectedFileName={selectedFileName.vaccinationCertificate}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    PCC <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                    <UploadDocument
                      fieldName={"pcc"}
                      selectedFile={selectedFile.pcc}
                      selectedFileName={selectedFileName.pcc}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="pccExpiry"
                        value={
                          formData.pccExpiry
                            ? dayjs(formData.pccExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          dateChanges("pccExpiry", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>
          
                 
                
                {/* ------------------------------------------------------------change state variable used here ---------------------------------------------- */}
                <br />
                <div className={styles.popUpButtons}>
                  <Button
                    onClick={handleSubmit}
                    className={styles.SubmitButton}
                    size="large"
                    variant="contained"
                    type="submit">
                    Submit
                  </Button>
                  <Button
                    size="large"
                    className={styles.CancelButton}
                    variant="outlined"
                    onClick={() => setShowForm(false)}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      <div className={styles.tableWrapper}>
        <table className={styles.professionaltable}>
          <thead>
            <tr>
              <th className={styles.changeWidth}>S.No</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Retainership</th>
              <th>Employee ID</th>
              <th>
                Experience of <br /> Rig Types
              </th>
              <th>
                Jacking | Skidding | Crane | Fixation Experience <br />
                (in months)
              </th>
              <tr className={styles.removeExtraBorder}>
                <th colSpan={6}>Active Visas of Countries</th>
              </tr>
              <tr className={styles.removeExtraBorder}>
                <th>Visa 1</th>
                <th>Visa 2</th>
                <th>Visa 3</th>
                <th>Visa 4</th>
                {/* <th>Visa 5</th>
                <th>Visa 6</th> */}
              </tr>
              {/* <th>
                Dates booked for projects <br /> [Upcoming project(s)]
              </th> */}
              <th>
                Seaman Book <br /> Expiry | Issuing Country | Doc
              </th>
              <th>
                BOSIET <br /> Expiry | Doc
              </th>
              <th>
                H2S <br />
                Expiry | Doc
              </th>
              <th>
                Medical <br />
                Expiry | Doc
              </th>
              <th>
                Yellow Fever <br />
                Expiry | Doc
              </th>
              <th>
                Insurance <br />
                Expiry | Doc
              </th>
              <th>
                SNT Eye Test <br />
                Expiry | Doc
              </th>
              <th>
                Passport <br /> Expiry | Issuing Country | Doc
              </th>
              {/* <th>CV</th> */}
              <th>Photo Upload</th>
              <th>Vaccination Certificate</th>
              <th>
                PCC <br />
                Expiry | Doc
              </th>
              <th>Nationality</th>
            </tr>
          </thead>
          <tbody>
            { Array.isArray(filteredEmployees) && filteredEmployees.length === 0 ? (
              <div style={{ position: "absolute", left: "40%", top: "90vh" }}>
                <h1>No Results Found</h1>
              </div>
            ) : (
              Array.isArray(filteredEmployees) &&
              filteredEmployees.map((employee, index) => 
           (
                <tr key={employee.S_No}>
                  <td className={styles.changeWidth}>
                    {enableEdit && (
                      <>
                        <ProfessionalDetailsEdit
                          setEnableEdit={setEnableEdit}
                          enableEdit={enableEdit}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          S_No={employee.S_No}
                          name={employee.Customer_Name}
                          designation={employee.Designation}
                          retainership={employee.Retainership}
                          employeeid={employee.Employee_ID}
                          signature={employee.Signature}
                          rigName={employee.Rigs}
                          jackExperience={employee.jackingExperience}
                          skidExperience={employee.skiddingExperience}
                          craneExperience={employee.craneExperience}
                          // datesBookedForProject={
                          //   employee.upcomingProjectDuration
                          // }
                          fixationExperience={employee.Fixation_Experience}
                          seamanBookExpiry={employee.Seaman_Book_Expiry}
                          seamanIssuingCountry={employee.Seaman_Issuing_Country}
                          seamanDoc={employee.Seaman_Doc}
                          bosietExpiry={employee.Bosiet_Expiry}
                          bosietDoc={employee.Bosiet_Doc}
                          h2sExpiry={employee.H2s_Expiry}
                          h2sDoc={employee.H2s_Doc}
                          medicalExpiry={employee.Medical_Expiry}
                          medicalDoc={employee.Medical_Doc}
                          yellowFeverExpiry={employee.Yellow_Fever_Expiry}
                          yellowFeverDoc={employee.Yellow_Fever_Doc}
                          insuranceExpiry={employee.Insurance_Expiry}
                          insuranceDoc={employee.Insurance_Doc}
                          sntEyeTestExpiry={employee.SNT_Eye_Test_Expiry}
                          sntEyeTestDoc={employee.SNT_Eye_Test_Doc}
                          passportExpiry={employee.Passport_Expiry}
                          passportIssuingCountry={
                            employee.Passport_Issuing_Country
                          }
                          passportDoc={employee.Passport_Doc}
                          // cvFile={employee.CV_File}
                          photoUpload={employee.Photo_Upload}
                          vaccinationCertificate={employee.Vaccination_Certificate}
                          pcc={employee.PCC}
                          pccExpiry={employee.PCC_Expiry}
                          visaIssuingCountry1={employee.Active_Visas_One}
                          visaIssuingCountry2={employee.Active_Visas_Two}
                          visaIssuingCountry3={employee.Active_Visas_Three}
                          visaIssuingCountry4={employee.Active_Visas_Four}
                          // visaIssuingCountry5={employee.Active_Visas_Five}
                          // visaIssuingCountry6={employee.Active_Visas_SIx}
                          visaExpiry1={employee.Active_Visas_One_Expiry}
                          visaExpiry2={employee.Active_Visas_Two_Expiry}
                          visaExpiry3={employee.Active_Visas_Three_Expiry}
                          visaExpiry4={employee.Active_Visas_Four_Expiry}
                          visaExpiry5={employee.Active_Visas_Five_Expiry}
                          visaExpiry6={employee.Active_Visas_Six_Expiry}
                          visaDoc1={employee.Active_Visas_One_Doc}
                          visaDoc2={employee.Active_Visas_Two_Doc}
                          visaDoc3={employee.Active_Visas_Three_Doc}
                          visaDoc4={employee.Active_Visas_Four_Doc}
                          // visaDoc5={employee.Active_Visas_Five_Doc}
                          // visaDoc6={employee.Active_Visas_Six_Doc}
                          dropdownVisa={dropdownVisa}
                          signatureDocName={employee.Signature_Doc_Name}
                          seamanDocDocName={employee.Seaman_Doc_Name}
                          bosietDocDocName={employee.Bosiet_Doc_Name}
                          h2sDocDocName={employee.H2S_Doc_Name}
                          medicalDocDocName={employee.Medical_Doc_Name}
                          yellowFeverDocDocName={employee.Yellow_Fever_Doc_Name}
                          insuranceDocDocName={employee.Insurance_Doc_Name}
                          sntEyeTestDocDocName={employee.Snt_Doc_Name}
                          passportDocDocName={employee.Passport_Doc_Name}
                          visaDoc1DocName={employee.Active_Visa1_Doc_Name}
                          visaDoc2DocName={employee.Active_Visa2_Doc_Name}
                          visaDoc3DocName={employee.Active_Visa3_Doc_Name}
                          visaDoc4DocName={employee.Active_Visa4_Doc_Name}
                          // visaDoc5DocName={employee.Active_Visa5_Doc_Name}
                          // visaDoc6DocName={employee.Active_Visa6_Doc_Name}
                          PhotoUploadDocName={employee.Photo_Upload_Doc_Name}
                          VaccinationCertificateDocName={employee.Vaccination_Certificate_Doc_Name}
                          pccDocName={employee.PCC_Doc_Name}
                          Nationality={employee.Nationality}
                        />
                      </>
                    )}
                    {index + 1}
                  </td>

                  {/* <td>{employee.isAvailable ? "Available" : "Engaged"}</td> */}
                  <td >
                    <span
                        className={styles.bolder}
                        onClick={() => handleDetailsClick(employee)}
                        style={{ cursor: "pointer"}}
                      >
                    {employee.Customer_Name ? employee.Customer_Name : "-"}
                    </span>
                  </td>
                  <td>{employee.Designation ? employee.Designation : "-"}</td>
                  <td>{employee.Retainership ? employee.Retainership : "-"}</td>
                  <td>{employee.Employee_ID ? employee.Employee_ID : "-"}</td>
                  {/* <td></td> */}
                  <td>{employee.Rigs ? employee.Rigs : "-"}</td>
                  <td>
                    {employee.jackingExperience
                      ? employee.jackingExperience
                      : "-"}
                    {" | "}
                    {employee.skiddingExperience
                      ? employee.skiddingExperience
                      : "-"}
                    {" | "}
                    {employee.craneExperience ? employee.craneExperience : "-"}
                    {" | "}
                    {employee.Fixation_Experience
                      ? employee.Fixation_Experience
                      : "-"}{" "}
                  </td>
                  <tr className={styles.removeExtraBorder}>
                    <td>
                      {employee.Active_Visas_One_Doc ? (
                        <NavLink
                          to={employee.Active_Visas_One_Doc}
                          target="_blank"
                          rel="noopener noreferrer">
                          {employee.Active_Visas_One
                            ? employee.Active_Visas_One
                            : "-"}
                        </NavLink>
                      ) : (
                        "-"
                      )}

                      {" | "}
                      {employee.Active_Visas_One_Expiry
                        ? employee.Active_Visas_One_Expiry
                        : "-"}
                    </td>
                    <td>
                      {employee.Active_Visas_Two_Doc ? (
                        <NavLink
                          to={employee.Active_Visas_Two_Doc}
                          target="_blank"
                          rel="noopener noreferrer">
                          {employee.Active_Visas_Two
                            ? employee.Active_Visas_Two
                            : "-"}
                        </NavLink>
                      ) : (
                        "-"
                      )}

                      {" | "}
                      {employee.Active_Visas_Two_Expiry
                        ? employee.Active_Visas_Two_Expiry
                        : "-"}
                    </td>
                    <td>
                      {employee.Active_Visas_Three_Doc ? (
                        <NavLink
                          to={employee.Active_Visas_Three_Doc}
                          target="_blank"
                          rel="noopener noreferrer">
                          {employee.Active_Visas_Three
                            ? employee.Active_Visas_Three
                            : "-"}
                        </NavLink>
                      ) : (
                        "-"
                      )}

                      {" | "}
                      {employee.Active_Visas_Three_Expiry
                        ? employee.Active_Visas_Three_Expiry
                        : "-"}
                    </td>
                    <td>
                      {employee.Active_Visas_Four_Doc ? (
                        <NavLink
                          to={employee.Active_Visas_Four_Doc}
                          target="_blank"
                          rel="noopener noreferrer">
                          {employee.Active_Visas_Four
                            ? employee.Active_Visas_Four
                            : "-"}
                        </NavLink>
                      ) : (
                        "-"
                      )}

                      {" | "}
                      {employee.Active_Visas_Four_Expiry
                        ? employee.Active_Visas_Four_Expiry
                        : "-"}
                    </td>
                    {/* <td>
                      {employee.Active_Visas_Five_Doc ? (
                        <NavLink
                          to={employee.Active_Visas_Five_Doc}
                          target="_blank"
                          rel="noopener noreferrer">
                          {employee.Active_Visas_Five
                            ? employee.Active_Visas_Five
                            : "-"}
                        </NavLink>
                      ) : (
                        "-"
                      )}

                      {" | "}
                      {employee.Active_Visas_Five_Expiry
                        ? employee.Active_Visas_Five_Expiry
                        : "-"}
                    </td>
                    <td>
                      {employee.Active_Visas_Six_Doc ? (
                        <NavLink
                          to={employee.Active_Visas_Six_Doc}
                          target="_blank"
                          rel="noopener noreferrer">
                          {employee.Active_Visas_Six
                            ? employee.Active_Visas_Six
                            : "-"}
                        </NavLink>
                      ) : (
                        "-"
                      )}

                      {" | "}
                      {employee.Active_Visas_Six_Expiry
                        ? employee.Active_Visas_Six_Expiry
                        : "-"}
                    </td> */}
                  </tr>
                  {/* <td>
                    {employee.upcomingProjectDuration
                      ? employee.upcomingProjectDuration
                      : "-"}{" "}
                    <span>
                      {" | "}
                      <NavLink to={"/project_checklist/170001"}>623111</NavLink>
                    </span>
                  </td> */}
                  <td>
                    {employee.Seaman_Book_Expiry
                      ? employee.Seaman_Book_Expiry
                      : "-"}
                    {" | "}
                    {employee.Seaman_Issuing_Country
                      ? employee.Seaman_Issuing_Country
                      : "-"}
                    {" | "}

                    {employee.Seaman_Doc ? (
                      <NavLink
                        to={employee.Seaman_Doc}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {employee.Bosiet_Expiry ? employee.Bosiet_Expiry : "-"}
                    {" | "}
                    {employee.Bosiet_Doc ? (
                      <NavLink
                        to={employee.Bosiet_Doc}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {employee.H2s_Expiry ? employee.H2s_Expiry : "-"}
                    {" | "}
                    {employee.H2s_Doc ? (
                      <NavLink
                        to={employee.H2s_Doc}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {employee.Medical_Expiry ? employee.Medical_Expiry : "-"}
                    {" | "}
                    {employee.Medical_Doc ? (
                      <NavLink
                        to={employee.Medical_Doc}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {employee.Yellow_Fever_Expiry ? employee.Yellow_Fever_Expiry : "-"}
                    {" | "}
                    {employee.Yellow_Fever_Doc ? (
                      <NavLink
                        to={employee.Yellow_Fever_Doc}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {employee.Insurance_Expiry
                      ? employee.Insurance_Expiry
                      : "-"}
                    {" | "}
                    {employee.Insurance_Doc ? (
                      <NavLink
                        to={employee.Insurance_Doc}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {employee.SNT_Eye_Test_Expiry
                      ? employee.SNT_Eye_Test_Expiry
                      : "-"}
                    {" | "}
                    {employee.SNT_Eye_Test_Doc ? (
                      <NavLink
                        to={employee.SNT_Eye_Test_Doc}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {employee.Passport_Expiry ? employee.Passport_Expiry : "-"}
                    {" | "}
                    {employee.Passport_Issuing_Country
                      ? employee.Passport_Issuing_Country
                      : "-"}
                    {" | "}

                    {employee.Passport_Doc ? (
                      <NavLink
                        to={employee.Passport_Doc}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}
                  </td>
                  {/* <td>{employee.CV_File ? employee.CV_File : "-"}</td> */}
                 <td>   {employee.Photo_Upload ? (
                      <NavLink
                        to={employee.Photo_Upload}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}</td>

                <td>   {employee.Vaccination_Certificate ? (
                      <NavLink
                        to={employee.Vaccination_Certificate}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                    )}</td>

                <td>
                  {employee.PCC_Expiry
                    ? employee.PCC_Expiry
                    : "-"}
                  {" | "}
                  {employee.PCC ? (
                      <NavLink
                        to={employee.PCC}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                    ) : (
                      "-"
                  )}
                </td>
                    
                    <td>  {employee.Nationality ? employee.Nationality : "-"}</td>
                  {/* <td className={getAvailabilityColor(employee.availability)}>
                {employee.availability}% available
              </td> */}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfessionalDetails;
