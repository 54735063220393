import React from 'react'
import { Stepper, Step, StepLabel, Box } from '@mui/material';
// import styles from "./css/Production_Create_New.module.css";    //css for the Material Produced screen, step 1 of the flow
// import styles2 from "../Components/css/Production_Create_New_2.module.css";   //css for the Material Consumed screen, step 2 of the flow
import style from "./css/modal.module.css";
import styles from "./css/dispatchcreatenew.module.css";
import styles2 from "./css/dispatchcreatenew2.module.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import dayjs from "dayjs";

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function DispatchCreateNew() {
    const { project_id, id } = useParams();   //Project ID taken from url
    const navigate = useNavigate();

    const [formDataProduced, setFormDataProduced] = useState([]);   //to store the "Material Produced" table data
    const [itemsMaster, setItemsMaster] = useState([]);   //used for storing the Items Master List data to display in the Item_Name dropdown when anything is typed
    const [prodType, setProdType] = useState("WIP");    //state to store the Production_Type option selected using radio buttons
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);    // to set the Add More Items button disabled state
    const [addItemMode, setAddItemMode] = useState(false);    //when "Add More Items" button is clicked, this state is activated
    const [editItemMode, setEditItemMode] = useState(null);   //when the edit pencil icon is clicked, this state is activated
    const [initialEditData, setInitialEditData] = useState(null);   //state to store the initial data stored in formDataProduced whenever the edit "pencil" icon is clicked, this will be used to restore the data in case the edit is cancelled
    const [refresh, setRefresh] = useState(false);    //refresh state is used to control the useEffect where all the get requests are, explicitly used in Edit, Save, Cancel and other buttons to refresh the data
    const [filteredItems, setFilteredItems] = useState([]);
    const [filterWip, setFilterWip] = useState([]);
    const [filterFg, setFilterFg] = useState([]);
    const [isDropdownVisible, setDropdownVisible] = useState(false);    //used to control dropdown visibility under Item Name input in editable state, editItemMode, addItemMode
    const [isPartNoDisabled, setIsPartNoDisabled] = useState([]);   //used to control the disabled state of the Part Number input in editable state, editItemMode

    const [isPartNoDisabledAddRow, setIsPartNoDisabledAddRow] = useState(true);    //used to control the disabled state of the Part Number input when "Add More Items " is clicked, addItemMode state true
    const containerRef = useRef(null);
    const [initialHeight, setInitialHeight] = useState();   // State variable to track the initial height of the container
    const [heightRefresh, setHeightRefresh] = useState(false);    //to refresh the height of smtablecontainer div which contains the table and dropdowns
    const [containerStyle, setContainerStyle] = useState({});   // State variable to store container style
    const [fileName3, setFileName3] = useState("");
    const [fileName4, setFileName4] = useState("");
    const [documentToUpload3, setDocumentToUpload3] = useState("");
    const [documentToUpload4, setDocumentToUpload4] = useState("");
    const [selectedPurpose, setSelectedPurpose] = useState("");
    const [incomingQuantitiesWip, setIncomingQuantitiesWip] = useState([]);
    const [incomingQuantitiesFG, setIncomingQuantitiesFg] = useState([]);
    const [pendingQty, setPendingQty] = useState([]);
    const [differencesArray, setDifferencesArray] = useState([]);
    const [mergedArray, setMergedArray] = useState([]);
    // const [isfillsame, setIsfillsame] = useState(false);
    const [isfillsameWip, setIsFillSameWip] = useState(false);
    const [isfillsameFg, setIsFillSameFg] = useState(false);
    const [isCsp, setIsCsp] = useState(true);
    const [projectList, setProjectList] = useState({});
    // const [errors, setErrors] = useState(Array(filteredItems.length).fill(false));
    const [errors, setErrors] = useState({});
    const [errors2, setErrors2] = useState({});
    const [dispatchIdState, setDispatchIdState] = useState(null);
    const [incomingData, setIncomingData] = useState([]);
    const [isPODocumentMarkedForRemoval, setIsPODocumentMarkedForRemoval] =
        useState(false);
    const [isQuoteDocumentMarkedForRemoval, setIsQuoteDocumentMarkedForRemoval] =
        useState(false);


    const [formdata1, setFormData1] = useState({
        salesOrderNo: "",

        PONO: "",
        PODays: "",
        PODate: "",
        QuoteNO: "",
        QuoteDate: "",
        CostingID: "",
        ProjectName: "",
        Scope: "",
        EstimatedDateCommencement: "",
        CustomerName: "",
        FirstArrival: "",
        TargetDaysForCompletion: "",
        TargetManhours: "",
        opsEngineer: "",
        quantity: "",
        projectDescription: "",
        currentStatus: "",
        Design: "",
        scopeDescription: "",
        DmccFze: "",
        FzeDmccPo: "",
    });

    const [dispatchdata, setDispatchdata] = useState({
        Purpose: "",
        ReceivingParty: "",
        Deliverydate: "",
        Ponumber: "",
        Quotenumber: "",
        Dispatchqty: "",




    })

    // useEffect(() => {
    //     const fetchProject = async () => {
    //         const res = await axios.get(
    //             "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
    //         );
    //         const filter = res.data.data.filter((el) => el.ID_No == project_id);

    //         setFormData1({
    //             ...formdata1,
    //             salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
    //             PONO: filter[0]?.PO_No?.toString(),
    //             PODate: filter[0]?.PO_Date,
    //             QuoteNO: filter[0]?.Quote_No,
    //             QuoteDate: filter[0]?.Quote_Date,
    //             CostingID: filter[0]?.Costing_ID,
    //             ProjectName: filter[0]?.Project_Name,
    //             Scope: filter[0]?.Scope,
    //             projectDescription: filter[0]?.Project_Description,
    //             quantity: filter[0]?.Quantity,
    //             CustomerName: filter[0]?.Customer_Name,
    //             FirstArrival: filter[0]?.First_Arrival,
    //             TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
    //             opsEngineer: filter[0]?.Ops_Engineer,
    //             TargetManhours: filter[0]?.Target_Manhours,
    //             currentStatus: filter[0]?.Current_Status,
    //             scopeDescription: filter[0]?.Scope_Description,
    //             EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
    //             Design: filter[0]?.Design,
    //             DmccFze: filter[0]?.Dmcc_fze,
    //             FzeDmccPo: filter[0]?.Fze_dmcc_po,
    //         });
    //         if (filter[0]?.Scope === "Assembly") { setProdType("FG") }
    //         console.log(filter);
    //         console.log(project_id);

    //     };
    //     fetchProject();
    // }, [project_id]);


    useEffect(() => {
        const fetchProject = async () => {
            const res = await axios.get(
                "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
            );
            const filter = res.data.data.filter((el) => el.ID_No == id);
            //   const userRes = await axios.get(
            //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_user_details_project_details?Email=${userEmail}`
            //   );

            setFormData1({
                ...formdata1,
                salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
                PONO: filter[0]?.PO_No?.toString(),
                PODate: filter[0]?.PO_Date,
                QuoteNO: filter[0]?.Quote_No,
                QuoteDate: filter[0]?.Quote_Date,
                CostingID: filter[0]?.Costing_ID,
                ProjectName: filter[0]?.Project_Name,
                Scope: filter[0]?.Scope,
                projectDescription: filter[0]?.Project_Description,
                quantity: filter[0]?.Quantity,
                CustomerName: filter[0]?.Customer_Name,
                FirstArrival: filter[0]?.First_Arrival,
                TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
                opsEngineer: filter[0]?.Ops_Engineer,
                TargetManhours: filter[0]?.Target_Manhours,
                currentStatus: filter[0]?.Current_Status,
                scopeDescription: filter[0]?.Scope_Description,
                EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
                Design: filter[0]?.Design,
                DmccFze: filter[0]?.Dmcc_fze,
                FzeDmccPo: filter[0]?.Fze_dmcc_po,
                // Done_By: userRes.data.data[0].Name,
            });
            console.log(filter);
            console.log(id);
            setProjectList(filter[0]);
        };
        fetchProject();
    }, [id]);


    // useEffect(() => {
    //   // Check if incomingQuantities is not empty and contains any non-empty values
    //   const hasNonEmptyValues =
    //   incomingQuantitiesWip.length > 0 &&
    //   incomingQuantitiesWip.some(
    //       (val) => typeof val === "string" && val.trim() !== ""
    //     );

    //   if (hasNonEmptyValues) {
    //     // Check if any error exists, if yes, disable the save button
    //     const hasError = errors.some((error) => error);
    //     setSaveDisabled(hasError);
    //   } else if (isfillsame == true) {
    //     setSaveDisabled(false);
    //   } else {
    //     setSaveDisabled(true); // Disable save button if incomingQuantities is empty or contains only empty strings
    //   }

    //   // Check if the sum of differencesArray is 0
    //   // Disable input if sum is 0
    // }, [errors, differencesArray, incomingQuantitiesWip]);


    // useEffect(() => {
    //   const hasNonEmptyValues = incomingQuantitiesWip.length > 0 && incomingQuantitiesWip.some(val => typeof val === "string" && val.trim() !== "");
    //   if (hasNonEmptyValues) {
    //     const hasError = errors.some(error => error);
    //     setSaveDisabled(hasError);
    //   } else if (isfillsame) {
    //     setSaveDisabled(false);
    //   } else {
    //     setSaveDisabled(true);
    //   }
    // }, [errors, differencesArray, incomingQuantitiesWip]);

    useEffect(() => {
        // Merge filteredItems and incomingQuantities arrays
        const mergedArray = filteredItems.map((item, index) => ({
            ...item,
            Dispatchqty: incomingQuantitiesWip[index] || "", // Adding incomingQuantity to each item
        }));
        setMergedArray(mergedArray);
    }, [incomingQuantitiesWip]);

    const isSaveDisabled = () => {
        if (isCsp) {
            return Object.values(inputValues).length === 0 || Object.values(inputValues).some(value => value === "" || value === 0);
        } else {
            return Object.values(inputValues2).length === 0 || Object.values(inputValues2).some(value => value === "" || value === 0);
        }
    };

    // const handleQuantityChange = (event, index) => {
    //   const { value } = event.target;
    //   const parsedValue = parseInt(value);

    //   const updatedQuantities = [...incomingQuantities];
    //   updatedQuantities[index] = parsedValue;
    //   setIncomingQuantities(updatedQuantities);

    //   const updatedData = [...incomingData];
    //   updatedData[index].Dispatchqty = parsedValue;
    //   setIncomingData(updatedData);

    //   const newErrors = [...errors];
    //   if (parsedValue > sumState[index]) {
    //     newErrors[index] = true; // Set error state for this index
    //   } else {
    //     newErrors[index] = false; // Clear error state for this index
    //   }
    //   setErrors(newErrors);
    // };




    useEffect(() => {
        // Calculate differencesArray whenever filteredItems or pendingQty change
        const newDifferencesArray = filteredItems.map((item) => {
            const pendingItem = pendingQty.find(
                (pItem) => pItem.OEM_Part_Number === item.OEM_Part_Number
            );
            return pendingItem
                ? item.Qty_ordered - pendingItem.Scheduled_Quantity
                : item.Qty_ordered;
        });
        setDifferencesArray(newDifferencesArray);
    }, [filteredItems, pendingQty]);

    const [sumState, setSumState] = useState([]);
    useEffect(() => {
        // Ensure both incomingQuantities and differencesArray have the same length
        if (incomingQuantitiesWip.length === differencesArray.length) {
            // Calculate the sum of corresponding elements from incomingQuantities and differencesArray
            const sumArray = incomingQuantitiesWip.map(
                (val, index) => val + differencesArray[index]
            );
            setSumState(sumArray);
        }
    }, []);
    useEffect(() => {
        if (incomingQuantitiesWip.length > 0) {
            // Ensure both incomingQuantities and differencesArray have the same length
            {
                // Calculate the sum of corresponding elements from incomingQuantities and differencesArray
                const sumArray = incomingQuantitiesWip.map(
                    (val, index) => val + differencesArray[index]
                );
                setSumState(sumArray);
            }
        }
    }, [differencesArray]);
    console.log("setSumState", sumState);

    //useEffect to get the Items Master List Data on first loading of the page data and states
    useEffect(() => {
        async function fetchItemsMaster() {
            try {
                const resItemsMaster = await axios.get(
                    "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
                );

                setItemsMaster(resItemsMaster.data);
                console.log("Items Master:", resItemsMaster.data);








            } catch (error) {
                console.error(error);
            }
        }
        fetchItemsMaster();
    }, [refresh]);

    // Use effect to initialize and update isPartNoDisabled
    useEffect(() => {
        setIsPartNoDisabled(formDataProduced.map(() => true));
    }, [formDataProduced.length]);


    const handleProdBackButton = () => {
        navigate(`/dispatch/${id}`);
    };

    console.log(id);

    //Stepper states and functions

    const steps = [
        { label: 'Dispatch details' },
        { label: 'Dispatch items' }
    ];

    const [activeStep, setActiveStep] = useState(0);    //to store the activeStep for the stepper and to render the tables, 0 for "Material Produced", 1 for "Material Consumed"

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };













    useEffect(() => {
        const container = containerRef.current;

        if (!container) return;

        const observer = new ResizeObserver((entries) => {
            // Since there might be multiple entries, we take the first one
            const newHeight = entries[0].contentRect.height;
            setInitialHeight(newHeight);
        });

        observer.observe(container);

        return () => observer.disconnect();
    }, [heightRefresh, activeStep]);

    console.log("initialHeight", initialHeight);

    useEffect(() => {
        // Calculate the new height based on the initial height and whether the dropdowns are visible
        const newHeight = ((isDropdownVisible) ? (initialHeight + 270) : (initialHeight + 20));
        // const newHeightInt = parseInt(newHeight);

        console.log("newHeight", newHeight);
        setContainerStyle({ height: newHeight });
    }, [isDropdownVisible, initialHeight, addItemMode, isButtonDisabled, editItemMode, formDataProduced, activeStep]);

    console.log("isDropdownVisible saSasASasAS", isDropdownVisible)
    console.log("containerStyle ppppppppp", containerStyle);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdown = document.getElementById('myDropdown');

            // Check if the clicked element is inside the dropdown
            if (dropdown && !dropdown.contains(event.target)) {
                // If the clicked element is outside the dropdown, hide it
                setDropdownVisible(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!isCsp) {
            async function fetchFgItems() {
                try {
                    const res = await axios.get(
                        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsproduced_get2id?Project_ID=${id}`
                    );
                    setFilterFg(res.data.data);
                } catch (error) {
                    console.error(error);
                }
            }
            fetchFgItems();
        }
    }, [isCsp, id]);
    { console.log(filterFg) }

    useEffect(() => {
        if (isCsp) {
            async function fetchWipItems() {
                try {
                    const res = await axios.get(
                        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsproduced_get?Project_ID=${id}`
                    );
                    setFilterWip(res.data.data);
                } catch (error) {
                    console.error(error);
                }
            }
            fetchWipItems();
        }
    }, [isCsp, id]);
    { console.log(filterWip) }





    const handlecross = (name) => {
        switch (name) {
            case "PO_document":
                setFileName3("");
                setDocumentToUpload3(null);
                setIsPODocumentMarkedForRemoval(true)
                break;
            case "Quote_document":
                setFileName4("");
                setDocumentToUpload4(null);
                setIsQuoteDocumentMarkedForRemoval(true)
                break;
            default:
                break;
        }
    };

    const handlefileupload3 = (name, event) => {
        event.preventDefault();
        const temp3 = event.target.files[0];
        setDocumentToUpload3(temp3);

        const inputElement3 = document.getElementsByName(name)[0];
        const selectedFile3 = inputElement3.files[0];

        if (selectedFile3) {
            const fileName3 = selectedFile3.name;
            setFileName3(fileName3 ? fileName3 : "");
        } else {
            setFileName3("");
        }
        inputElement3.value = "";
        temp3.value = "";
    };
    const handlefileupload4 = (name, event) => {
        event.preventDefault();
        const temp4 = event.target.files[0];
        setDocumentToUpload4(temp4);

        const inputElement4 = document.getElementsByName(name)[0];
        const selectedFile4 = inputElement4.files[0];

        if (selectedFile4) {
            const fileName4 = selectedFile4.name;
            setFileName4(fileName4 ? fileName4 : "");
        } else {
            setFileName4("");
        }
        inputElement4.value = "";
        temp4.value = "";
    };
    //states and functions for the "Material Consumed" screen, step 2 on the frontend

    const [saveDisabled, setSaveDisabled] = useState(true);

    const [consumedItems, setConsumedItems] = useState([]);
    const [inventoryUtilisedItems, setInventoryUtilisedItems] = useState([]);
    const [inputDisabled, setInputDisabled] = useState(true);


    const [billEntries, setBillEntries] = useState({});
    const Navigate = useNavigate();
    const [mappedValues, setMappedValues] = useState({});
    const [changes, setChanges] = useState([]);
    const [hasErrors, setHasErrors] = useState(false);
    const [hasErrors2, setHasErrors2] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isDisabled2, setIsDisabled2] = useState(true);

    const [inputValues, setInputValues] = useState({});
    const [inputValues2, setInputValues2] = useState({});
    const Project_SO = formdata1.salesOrderNo; //Project_SO taken from formdata1





    const handleOnChange2 = (e, wip, index) => {
        const { value } = e.target;
        const matchedItem = filteredItems.find(item => item.Item_Name === wip.Item_Name && item.Part_Number === wip.Part_Number);
        const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
        const availableQty = matchedItem ? matchedItem.Quantity_Received - totalQtyUsed : 0;

        if (Number(value) > availableQty) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [index]: 'Dispatch quantity cannot exceed available quantity'
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [index]: ''
            }));
        }

        setInputValues(prevValues => ({
            ...prevValues,
            [index]: value
        }));
    };




    const handleOnChange3 = (e, fgItem, index) => {
        const { value } = e.target;
        const matchedItem = inventoryUtilisedItems.find(item => item.Item_Name === fgItem.Item_Name && item.Part_Number === fgItem.Part_Number);
        const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
        const availableQty = matchedItem ? matchedItem.Qty_Utilized - totalQtyUsed : 0;

        if (Number(value) > availableQty) {
            setErrors2(prevErrors => ({
                ...prevErrors,
                [index]: 'Dispatch quantity cannot exceed available quantity'
            }));
        } else {
            setErrors2(prevErrors => ({
                ...prevErrors,
                [index]: ''
            }));
        }

        setInputValues2(prevValues => ({
            ...prevValues,
            [index]: value
        }));
    };

    console.log("setInventoryUtilisedItems", inventoryUtilisedItems);
    useEffect(() => {
        async function fetchConsumptions() {
            try {
                const res1 = await axios.get(
                    `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_consumed_get?Project_SO=${Project_SO}`
                );

                const itemsReceivedData = res1.data.data;

                // Set the state for items consumed
                setConsumedItems(itemsReceivedData);

                const newMappedValues = {};
                itemsReceivedData.forEach((item) => {
                    const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
                    newMappedValues[key] = item.Total_Qty_Used;
                });
                setMappedValues(newMappedValues);
            } catch (error) {
                console.error(error);
            }
        }
        fetchConsumptions();
    }, [Project_SO]);

    console.log("setConsumedItems", consumedItems);


    // useEffect(()=>{
    //     if(isCsp){
    //      filterWip
    //       }
    //       else{
    //         filterFg
    //       }
    //     },[isCsp,id])

    //     {console.log(filterWip)}
    //     {console.log(filterFg)}


    // const handleCheckboxChangeWip = (checked) => {
    //     setIsFillSameWip(checked);
    //     if (checked) {
    //         const newValues = {};
    //         filterWip.forEach((wip, index) => {
    //             const matchedItem = filteredItems.find(item => item.Item_Name === wip.Item_Name && item.Part_Number === wip.Part_Number);
    //             const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
    //             const availableQty = matchedItem ? matchedItem.Quantity_Received - totalQtyUsed : 0;
    //             newValues[index] = availableQty;
    //         });
    //         setInputValues(newValues);
    //         setIsDisabled(false); // Enable the button
    //     } else {
    //         setIsDisabled(Object.values(inputValues).some(val => val === "" || val === 0));
    //     }
    // };
    //except zero values it allows all to fill and unfill 
    // const handleCheckboxChangeWip = (checked) => {
    //     setIsFillSameWip(checked);
    //     const newValues = {};

    //     filterWip.forEach((wip, index) => {
    //         if (checked) {
    //             const matchedItem = filteredItems.find(item => item.Item_Name === wip.Item_Name && item.Part_Number === wip.Part_Number);
    //             const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
    //             newValues[index] = matchedItem ? matchedItem.Quantity_Received - totalQtyUsed : "";
    //         } else {
    //             newValues[index] = ""; // Clear values when unchecked
    //         }
    //     });

    //     setInputValues(newValues);
    //   };

    const handleCheckboxChangeWip = (checked) => {
        setIsFillSameWip(checked);
        const newValues = {};

        filterWip.forEach((wip, index) => {
            if (checked) {
                const matchedItem = filteredItems.find(item => item.Item_Name === wip.Item_Name && item.Part_Number === wip.Part_Number);
                const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
                const availableQty = matchedItem ? matchedItem.Quantity_Received - totalQtyUsed : 0;
                newValues[index] = availableQty;  // Set availableQty regardless of its value (including zero)
            } else {
                newValues[index] = "";  // Clear all values when unchecked
            }
        });

        setInputValues(newValues);
    };


    // const handleCheckboxChangeFg = (checked) => {
    //     setIsFillSameFg(checked);
    //     if (checked) {
    //         const newValues = {};
    //         filterFg.forEach((fgItem, index) => {
    //             const matchedItem = inventoryUtilisedItems.find(item => item.Item_Name === fgItem.Item_Name && item.Part_Number === fgItem.Part_Number);
    //             const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
    //             const availableQty = matchedItem ? matchedItem.Qty_Utilized - totalQtyUsed : 0;
    //             newValues[index] = availableQty;
    //         });
    //         setInputValues2(newValues);
    //         setIsDisabled2(false); // Enable the button
    //     } else {
    //         setIsDisabled2(Object.values(inputValues2).some(val => val === "" || val === 0));
    //     }
    // }

    // const handleCheckboxChangeFg = (checked) => {
    //     setIsFillSameFg(checked);
    //     const newValues = {};

    //     filterFg.forEach((fgItem, index) => {
    //         if (checked) {
    //             const matchedItem = inventoryUtilisedItems.find(item => item.Item_Name === fgItem.Item_Name && item.Part_Number === fgItem.Part_Number);
    //             const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
    //             newValues[index] = matchedItem ? matchedItem.Qty_Utilized - totalQtyUsed : "";
    //         } else {
    //             newValues[index] = ""; // Clear values when unchecked
    //         }
    //     });

    //     setInputValues2(newValues);
    //   };

    const handleCheckboxChangeFg = (checked) => {
        setIsFillSameFg(checked);
        const newValues = {};
        let allHaveValues = true;  // This will check if all filled values are valid to enable the Save button.

        filterFg.forEach((fgItem, index) => {
            const matchedItem = inventoryUtilisedItems.find(item => item.Item_Name === fgItem.Item_Name && item.Part_Number === fgItem.Part_Number);
            const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
            const availableQty = matchedItem ? matchedItem.Qty_Utilized - totalQtyUsed : 0;

            newValues[index] = availableQty;  // Set availableQty regardless of its value (including zero).

            // Modify condition for checking valid values and errors.
            if (checked) {
                if (availableQty < 0) {  // Only consider it invalid if less than zero.
                    allHaveValues = false;
                    setErrors2(prevErrors => ({
                        ...prevErrors,
                        [index]: 'Dispatch quantity cannot exceed available quantity'
                    }));
                } else {
                    setErrors2(prevErrors => ({
                        ...prevErrors,
                        [index]: ''  // Clear errors if available quantity is zero or more.
                    }));
                }
            }
        });

        setInputValues2(newValues);
        setIsDisabled2(!allHaveValues && checked);  // Ensure button is enabled if 'Fill Same' is checked and all values are zero or more.
        setHasErrors2(false);  // Clear all errors if 'Fill Same' is handling zero values correctly.
    };


    const handleOnChange = (event) => {
        const { name, value } = event.target;

        setDispatchdata({
            ...dispatchdata,
            [name]: value
        })
    }

    const handlepurposechange = (e) => {
        setSelectedPurpose(e.target.value);
        setDispatchdata({
            ...dispatchdata,
            Purpose: e.target.value,
        });

    }

    useEffect(() => {
        const areAllInputsEmpty = () => {
            return Object.values(inputValues).every(
                (value) => value === "" || value === undefined
            );
        };

        setIsDisabled(areAllInputsEmpty());
    }, [inputValues, isCsp]);

    useEffect(() => {
        const areAllInputsEmpty = () => {
            return Object.values(inputValues2).every(
                (value) => value === "" || value === undefined
            );
        };

        setIsDisabled2(areAllInputsEmpty());
    }, [inputValues2, isCsp]);

    useEffect(() => {
        const fetchDeliveries = async () => {
            try {
                const response = await axios.get(
                    "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries2"
                );
                if (response.data.success) {
                    const hashArray = response.data.data.reduce((acc, current) => {
                        acc[current.S_No] = current.Entry_Bill_No;
                        return acc;
                    }, {});
                    setBillEntries(hashArray);
                } else {
                    console.error("Failed to fetch data:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDeliveries();
    }, []); // Empty dependency array ensures this runs only once on mount

    useEffect(() => {
        async function fetchDetails() {
            try {
                const res1 = await axios.get(
                    `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get2`
                );

                const itemsReceivedData = res1.data.data;
                console.log(itemsReceivedData)
                const filteredItems = itemsReceivedData.filter((item) => {
                    const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
                    const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
                    return item.Quantity_Received - totalQtyUsed !== 0;
                });

                // Set the state for items received
                setFilteredItems(filteredItems);
            } catch (error) {
                console.error(error);
            }
        }
        fetchDetails();
    }, [mappedValues]);

    console.log("setFilteredItems", filteredItems);
    //data from inventory utilised
    useEffect(() => {
        async function fetchDetails() {
            try {
                const res1 = await axios.get(
                    `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_inventory_utilised?Project_SO=${Project_SO}`
                );

                const itemsReceivedData2 = res1.data.data;
                const filteredItems = itemsReceivedData2.filter((item) => {
                    const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
                    const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
                    return item.Qty_Utilized - totalQtyUsed !== 0;
                });

                // Set the state for items received
                setInventoryUtilisedItems(filteredItems);
            } catch (error) {
                console.error(error);
            }
        }
        fetchDetails();
    }, [Project_SO, mappedValues]);



    const handleInventoryClick = () => {
        setIsCsp(false);
    };

    const handleCspClick = () => {
        setIsCsp(true);
    };

    const handleCancel = () => {
        Navigate(-1);
    };





    // date changes
    const handleDates = (name, value) => {
        if (value !== null && value !== undefined) {
            const selectedDate = value.format("DD/MM/YYYY");
            setDispatchdata(prevState => ({
                ...prevState,
                [name]: selectedDate // Update the state with formatted date
            }));
            console.log(name, selectedDate);
        } else {
            console.log("Invalid Date");
        }
    };
    // mui styling for date picker
    const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
        "& .MuiPickersBasePicker-pickerView": {
            backgroundColor: "#DADCE0",
        },
        "& .MuiOutlinedInput-root": {
            width: "230px",
            "&:hover": {
                "& fieldset": {
                    borderColor: "1px solid #DADCE0",
                },
            },
            "&:not(.Mui-focused)": {
                "& fieldset": {
                    border: "1px solid #DADCE0",
                    borderRadius: "8px",
                },
            },
            "&.Mui-focused": {
                "& fieldset": {
                    border: "1px solid #DADCE0",
                    borderRadius: "8px",
                },
            },
        },
    }));
    //end of states and functions for the "Material Consumed" screen, step 2 on the frontend
    // const handlecreate = async () => {
    //   try {
    //     const {
    //       Purpose,
    //       ReceivingParty,
    //       Deliverydate,
    //       Ponumber,
    //       Quotenumber,



    //     } = dispatchdata;

    //     const uploadFormData = new FormData();
    //     uploadFormData.append('Purpose', Purpose);
    //     uploadFormData.append('Receiving_Party', ReceivingParty);
    //     uploadFormData.append('Delivery_date', Deliverydate);
    //     uploadFormData.append('Ponumber', Ponumber);
    //     uploadFormData.append('Quote_number', Quotenumber);
    //     uploadFormData.append("Project_Id", id);

    //     // uploadFormData.append('Dispatch_qty', Dispatchqty);

    //     if (documentToUpload3) uploadFormData.append('PO_document', documentToUpload3);
    //     if (documentToUpload4) uploadFormData.append('Quote_document', documentToUpload4);

    //     const res = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/dispatch', uploadFormData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     });

    //     console.log(res.data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };




    // const handlecreate = async () => {
    //   try {
    //     const {
    //       Purpose,
    //       ReceivingParty,
    //       Deliverydate,
    //       Ponumber,
    //       Quotenumber,
    //     } = dispatchdata;

    //     const uploadFormData = new FormData();
    //     uploadFormData.append('Purpose', Purpose);
    //     uploadFormData.append('Receiving_Party', ReceivingParty);
    //     uploadFormData.append('Delivery_date', Deliverydate);
    //     uploadFormData.append('Ponumber', Ponumber);
    //     uploadFormData.append('Quote_number', Quotenumber);
    //     uploadFormData.append("Project_Id", id);

    //     if (documentToUpload3) uploadFormData.append('PO_document', documentToUpload3);
    //     if (documentToUpload4) uploadFormData.append('Quote_document', documentToUpload4);

    //     // Send the first request to create a new dispatch
    //     const res = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/dispatch', uploadFormData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     });

    //     const dispatchId = res.data.dispatch_id; // Assuming the API returns the dispatch_id
    //     const finalDispatchData = [];

    //     // Prepare data for final_dispatch based on active tab
    //     if (isCsp) {
    //       // WIP
    //       filterWip.forEach((wip, index) => {
    //         const qty = inputValues[index];
    //         if (qty) {
    //           finalDispatchData.push({
    //             Project_ID: id,
    //             dispatch_id: dispatchId,
    //             Production_Type: 'WIP',
    //             Item_Name: wip.Item_Name,
    //             Part_Number: wip.Part_Number,
    //             Part_Traceability_Number: wip.Part_Traceability_Number,
    //             Equipment_Traceability_Number: wip.Equipment_Traceability_Number,
    //             Dispatch_qty: qty,
    //           });
    //         }
    //       });
    //     } else {
    //       // FG
    //       filterFg.forEach((fgItem, index) => {
    //         const qty = inputValues2[index];
    //         if (qty) {
    //           finalDispatchData.push({
    //             Project_ID: id,
    //             dispatch_id: dispatchId,
    //             Production_Type: 'FG',
    //             Item_Name: fgItem.Item_Name,
    //             Part_Number: fgItem.Part_Number,
    //             Ergon_Traceability_Number: fgItem.Ergon_Traceability_Number,
    //             Equipment_Traceability_Number: fgItem.Equipment_Traceability_Number,
    //             Dispatch_qty: qty,
    //           });
    //         }
    //       });
    //     }

    //     // Send the data for final_dispatch
    //     await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/final_dispatch', finalDispatchData, {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     });

    //     console.log('Data inserted successfully');
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };

    const handlecreate = async () => {
        try {
            const {
                Purpose,
                ReceivingParty,
                Deliverydate,
                Ponumber,
                Quotenumber,
            } = dispatchdata;

            const uploadFormData = new FormData();
            uploadFormData.append('Purpose', Purpose);
            uploadFormData.append('Receiving_Party', ReceivingParty);
            uploadFormData.append('Delivery_date', Deliverydate);
            uploadFormData.append('Ponumber', Ponumber);
            uploadFormData.append('Quote_number', Quotenumber);
            uploadFormData.append("Project_Id", id);

            if (documentToUpload3) uploadFormData.append('PO_document', documentToUpload3);
            if (documentToUpload4) uploadFormData.append('Quote_document', documentToUpload4);

            let dispatchId = dispatchIdState; // Retrieve the dispatchId from state

            if (!dispatchId) {
                // Send the first request to create a new dispatch if dispatchId is not already present
                const res = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/dispatch', uploadFormData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                dispatchId = res.data.dispatch_id; // Assuming the API returns the dispatch_id
                setDispatchIdState(dispatchId); // Save the dispatchId to state
            }

            const finalDispatchData = [];

            // Prepare data for final_dispatch based on active tab
            if (isCsp) {
                // WIP
                filterWip.forEach((wip, index) => {
                    const qty = inputValues[index];
                    if (qty) {
                        finalDispatchData.push({
                            Project_ID: id,
                            dispatch_id: dispatchId,
                            Production_Type: 'WIP',
                            Item_Name: wip.Item_Name,
                            Part_Number: wip.Part_Number,
                            Part_Traceability_Number: wip.Part_Traceability_Number,
                            Equipment_Traceability_Number: wip.Equipment_Traceability_Number,
                            Dispatch_qty: qty,
                        });
                    }
                });
            } else {
                // FG
                filterFg.forEach((fgItem, index) => {
                    const qty = inputValues2[index];
                    if (qty) {
                        finalDispatchData.push({
                            Project_ID: id,
                            dispatch_id: dispatchId,
                            Production_Type: 'FG',
                            Item_Name: fgItem.Item_Name,
                            Part_Number: fgItem.Part_Number,
                            Ergon_Traceability_Number: fgItem.Ergon_Traceability_Number,
                            Equipment_Traceability_Number: fgItem.Equipment_Traceability_Number,
                            Dispatch_qty: qty,
                        });
                    }
                });
            }

            // Send the data for final_dispatch
            await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/final_dispatch', finalDispatchData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            console.log('Data inserted successfully');

            // Navigate after successful creation/update
            navigate(`/dispatch/${id}`);
        } catch (error) {
            console.error("Failed to create/update dispatch:", error);
        }
    };



    // Render Buttons
    {
        activeStep === 1 &&
            <>
                <div
                    style={{
                        marginTop: "0px",
                        display: "flex",
                        flexDirection: "row",
                        flex: "1",
                    }}
                >
                    <button
                        className={`${styles2["incomingButton"]} ${isCsp ? styles2["incoming"] : styles2["not-incoming"]}`}
                        onClick={handleCspClick}
                    >
                        WIP
                    </button>
                    <button
                        className={`${styles2["outgoingButton"]} ${!isCsp ? styles2["outgoing"] : styles2["not-outgoing"]}`}
                        onClick={handleInventoryClick}
                    >
                        FG
                    </button>
                </div>

                <button
                    className={`${styles["buttonsDivButton"]} ${styles["save"]}`}
                    onClick={handlecreate}
                    disabled={(isCsp ? isDisabled : isDisabled2) || (isCsp ? hasErrors : hasErrors2)}
                    style={{ opacity: (isCsp ? isDisabled : isDisabled2) || (isCsp ? hasErrors : hasErrors2) ? 0.8 : 1 }} // Reduce opacity when disabled
                >
                    {isCsp ? "Create" : "Save"}
                </button>
                <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]}`}
                    onClick={handleBackStep}
                >
                    Back
                </button>
                <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]}`}
                    onClick={handleProdBackButton}
                >
                    Cancel
                </button>
            </>
    }

    return (
        <div>
            <div className={styles.topButtonContainer}>
                <button
                    className={styles.topBackButton}
                    onClick={handleProdBackButton}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
                            fill="#5B6BE1"
                        />
                    </svg>
                    {' '}Dispatch
                </button>

                <Box sx={{ width: '50%', mt: "8px", ml: "278px" }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#5B6BE1',
                                        '& .MuiStepLabel-label': {
                                            mt: '6px !important',
                                            fontWeight: 'bold !important',
                                            color: '#5B6BE1 !important',
                                        },
                                        '& .MuiStepLabel-label.Mui-active': {
                                            fontWeight: 'bold !important',
                                            color: '#5B6BE1 !important',
                                        },
                                        '& .MuiStepLabel-label.Mui-completed': {
                                            fontWeight: 'bold !important',
                                            color: '#5B6BE1 !important',
                                        },
                                    }}
                                >
                                    {step.label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

            </div>



            <div className={styles.mainDiv}>
                <div className={styles.buttonsdiv} style={{ minHeight: "63px", marginTop: "15px" }}>
                    {activeStep === 0 &&
                        <>
                            <div style={{ display: "flex", flexDirection: "column", flex: "1", justifyContent: "center", color: "#5B6BE1", marginLeft: "2%" }}>
                                <h4 style={{ borderBottom: "2px solid #5B6BE1", width: "fit-content", fontWeight: "bold" }}>
                                    Dispatch Details
                                </h4>
                            </div>
                            <button
                                className={styles.buttonsDivButton}
                                onClick={handleNextStep}
                            >
                                Next
                            </button>
                            <button
                                variant="contained"
                                className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                                    }`}
                                onClick={handleProdBackButton}>
                                Cancel
                            </button>
                        </>
                    }
                    {activeStep === 1 &&
                        <>
                            {isCsp ? (
                                <>
                                    <div
                                        style={{
                                            marginTop: "0px",
                                            display: "flex",
                                            flexDirection: "row",
                                            // justifyContent: "space-between",
                                            flex: "1",
                                        }}
                                    >
                                        <button
                                            className={`${styles2["incomingButton"]} ${isCsp ? styles2["incoming"] : styles2["not-incoming"]
                                                }`}
                                            onClick={handleCspClick}
                                        >
                                            WIP
                                        </button>
                                        <button
                                            className={`${styles2["outgoingButton"]} ${!isCsp ? styles2["outgoing"] : styles2["not-outgoing"]
                                                }`}
                                            onClick={handleInventoryClick}
                                        >
                                            FG
                                        </button>
                                    </div>

                                    <button
                                        className={`${styles["buttonsDivButton"]} ${styles["save"]
                                            }`}
                                        onClick={handlecreate}
                                        disabled={isDisabled || hasErrors}
                                        style={{ opacity: isDisabled || hasErrors ? 0.8 : 1 }} // Reduce opacity when disabled
                                    >
                                        Create
                                    </button>
                                    <button
                                        variant="contained"
                                        className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                                            }`}
                                        onClick={handleBackStep}>
                                        Back
                                    </button>
                                    <button
                                        variant="contained"
                                        className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                                            }`}
                                        onClick={handleProdBackButton}>
                                        Cancel
                                    </button>
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            marginTop: "0px",
                                            display: "flex",
                                            flexDirection: "row",
                                            // justifyContent: "space-between",
                                            flex: "1",
                                        }}
                                    >
                                        <button
                                            className={`${styles2["incomingButton"]} ${isCsp ? styles2["incoming"] : styles2["not-incoming"]
                                                }`}
                                            onClick={handleCspClick}
                                        >
                                            WIP
                                        </button>
                                        <button
                                            className={`${styles2["outgoingButton"]} ${!isCsp ? styles2["outgoing"] : styles2["not-outgoing"]
                                                }`}
                                            onClick={handleInventoryClick}
                                        >
                                            FG
                                        </button>
                                    </div>

                                    <button
                                        className={`${styles["buttonsDivButton"]} ${styles["save"]
                                            }`}
                                        onClick={handlecreate}
                                        disabled={isDisabled2 || hasErrors2}
                                        style={{ opacity: isDisabled2 || hasErrors2 ? 0.8 : 1 }} // Reduce opacity when disabled
                                    >
                                        Create
                                    </button>
                                    <button
                                        variant="contained"
                                        className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                                            }`}
                                        onClick={handleBackStep}>
                                        Back
                                    </button>
                                    <button
                                        variant="contained"
                                        className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                                            }`}
                                        onClick={handleProdBackButton}>
                                        Cancel
                                    </button>
                                </>
                            )}
                        </>
                    }
                </div>

                {activeStep === 0 && (
                    <>
                        <div className={styles.formElement}>
                            <label>Purpose:</label>
                            <select onChange={handlepurposechange}>
                                <option value="">Select Purpose</option>
                                <option value="Outsourced job">Outsourced job</option>
                                <option value="Final Delivery">Final Delivery</option>
                            </select>
                            <svg style={{ position: "absolute", left: "355" }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z" fill="black" />
                            </svg>
                        </div>
                        {selectedPurpose === 'Outsourced job' && (
                            <>
                                <div className={styles.formElement}>
                                    <label>Receiving Party:</label>
                                    <input type="text"
                                        name="ReceivingParty"
                                        value={dispatchdata.ReceivingParty}
                                        onChange={handleOnChange}
                                    />
                                </div>
                                <div className={`${styles.formElement} ${styles.datepickerInput}`}>
                                    <label>Delivery Date:</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <CustomDatePicker name="Deliverydate" value={dispatchdata.Deliverydate ? dayjs(dispatchdata.Deliverydate, "DD/MM/YYYY") : null} onChange={(value) => handleDates("Deliverydate", value)} slotProps={{ textField: { size: "small" } }} format="DD/MM/YYYY" sx={{ backgroundColor: "white", borderRadius: "8px" }} />
                                    </LocalizationProvider>
                                </div>
                                <div className={styles.formElement}>
                                    <label>PO Number:<br />(Issued by Ergon)</label>
                                    <input type="number"
                                        name="Ponumber"
                                        value={dispatchdata.Ponumber}
                                        onChange={handleOnChange}
                                    />
                                </div>
                                <div className={styles.formElement}>
                                    <label>Quote Number:<br />(Issued by Vendor)</label>
                                    <input type="number"
                                        name="Quotenumber"
                                        value={dispatchdata.Quotenumber}
                                        onChange={handleOnChange}

                                    />
                                </div>
                                <div
                                    style={{
                                        display: "inline-flex",
                                        marginLeft: "489px",
                                        padding: "10px 0px",
                                        alignItems: "center",
                                        border: "1px",
                                        marginTop: "-160px"
                                    }}
                                >
                                    <label
                                        htmlFor="upload3"
                                        style={{ color: "black", fontWeight: "600" }}
                                    >
                                        PO Document:
                                    </label>
                                    <input
                                        type="file"
                                        name="upload3"
                                        id="upload3"
                                        onInput={(e) => handlefileupload3("upload3", e)}
                                        style={{
                                            display: "none",
                                        }}
                                    />
                                    <div
                                        className={styles.textbox2}
                                        style={{ position: "relative", marginLeft: "56px" }}
                                        onClick={() => document.getElementById("upload3").click()}
                                    >
                                        <label
                                            style={{
                                                marginLeft: "15px",
                                                marginTop: "5px",
                                                color: "rgba(128, 128, 128, 0.8)",
                                                overflow: "hidden", // Hide overflow text
                                                textOverflow: "ellipsis", // Display ellipsis for overflow text
                                                whiteSpace: "nowrap", // Prevent wrapping
                                                maxWidth: "180px", // Set maximum width for the file name
                                                alignItems: "center",
                                            }}
                                        >
                                            {fileName3 ? fileName3 : "Upload File"}
                                        </label>

                                        <svg
                                            width="25"
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "10px", // Adjust as needed
                                                transform: "translateY(-50%)",
                                                cursor: "pointer",
                                            }}
                                            height="25"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                                fill="#212529"
                                            />
                                            <path
                                                d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                                fill="#212529"
                                            />
                                        </svg>
                                    </div>{" "}
                                    {fileName3 && (
                                        <svg
                                            width="25"
                                            onClick={() => handlecross("PO_document")}
                                            style={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                            }}
                                            height="25"
                                            viewBox="0 0 20 20"
                                            fill="#212529"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                                                fill="#212529"
                                            />
                                            <path
                                                d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                                                fill="#212529"
                                            />
                                        </svg>
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: "inline-flex",
                                        marginLeft: "487px",
                                        padding: "10px 0px",
                                        alignItems: "center",
                                        border: "1px",
                                        marginTop: "29px"
                                    }}
                                >
                                    <label
                                        htmlFor="upload4"
                                        style={{ color: "black", fontWeight: "600" }}
                                    >
                                        Quote Document:
                                    </label>
                                    <input
                                        type="file"
                                        name="upload4"
                                        id="upload4"
                                        onInput={(e) => handlefileupload4("upload4", e)}
                                        style={{
                                            display: "none",
                                        }}
                                    />
                                    <div
                                        className={styles.textbox2}
                                        style={{ position: "relative" }}
                                        onClick={() => document.getElementById("upload4").click()}
                                    >
                                        <label
                                            style={{
                                                marginLeft: "15px",
                                                marginTop: "5px",
                                                color: "rgba(128, 128, 128, 0.8)",
                                                overflow: "hidden", // Hide overflow text
                                                textOverflow: "ellipsis", // Display ellipsis for overflow text
                                                whiteSpace: "nowrap", // Prevent wrapping
                                                maxWidth: "180px", // Set maximum width for the file name
                                                alignItems: "center",
                                            }}
                                        >
                                            {fileName4 ? fileName4 : "Upload File"}
                                        </label>

                                        <svg
                                            width="25"
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "10px", // Adjust as needed
                                                transform: "translateY(-50%)",
                                                cursor: "pointer",
                                            }}
                                            height="25"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                                fill="#212529"
                                            />
                                            <path
                                                d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                                fill="#212529"
                                            />
                                        </svg>
                                    </div>{" "}
                                    {fileName4 && (
                                        <svg
                                            width="25"
                                            onClick={() => handlecross("Quote_document")}
                                            style={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                            }}
                                            height="25"
                                            viewBox="0 0 20 20"
                                            fill="#212529"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                                                fill="#212529"
                                            />
                                            <path
                                                d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                                                fill="#212529"
                                            />
                                        </svg>
                                    )}
                                </div>
                            </>
                        )}
                        {selectedPurpose === 'Final Delivery' && (
                            <>
                                <div className={styles.formElement}>
                                    <label>Receiving Party:</label>
                                    <input type="text"
                                        name="CustomerName"
                                        value={formdata1.CustomerName}
                                        onChange={handleOnChange}
                                        disabled
                                    />
                                </div>
                                <div className={`${styles.formElement} ${styles.datepickerInput}`}>
                                    <label>Delivery Date:</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <CustomDatePicker name="QuoteDate" value={dispatchdata.Deliverydate ? dayjs(dispatchdata.Deliverydate, "DD/MM/YYYY") : null}
                                            onChange={(value) => handleDates("dispatchdata.Deliverydate ", value)}
                                            slotProps={{ textField: { size: "small" } }}
                                            format="DD/MM/YYYY"
                                            sx={{ backgroundColor: "white", borderRadius: "8px" }} />
                                    </LocalizationProvider>
                                </div>
                            </>
                        )}
                    </>
                )}
                {activeStep === 1 &&
                    <div className={styles2.itemstablecontainer}>
                        <table className={styles2.itemstable}>
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Item Name</th>
                                    <th>Part Number</th>
                                    {isCsp ? (
                                        <>
                                            <th style={{ whiteSpace: "nowrap" }}>Part Traceability Number</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Equipment Traceability Number</th>
                                        </>
                                    ) : (
                                        <>
                                            <th>Ergon Traceability Number</th>
                                            <th>Equipment Traceability Number</th>
                                        </>
                                    )}
                                    <th>UoM</th>
                                    <th>Available Qty</th>
                                    <th>
                                        Dispatch Qty
                                        {isCsp ? (
                                            <input
                                                type="checkbox"
                                                checked={isfillsameWip}
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                    marginLeft: "15px",
                                                }}
                                                onChange={(e) => handleCheckboxChangeWip(e.target.checked)}
                                            />
                                        ) : (
                                            <input
                                                type="checkbox"
                                                checked={isfillsameFg}
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                    marginLeft: "15px",
                                                }}
                                                onChange={(e) => handleCheckboxChangeFg(e.target.checked)}
                                            />
                                        )}
                                        <label
                                            style={{
                                                marginLeft: "5px",
                                                color: "#567191",
                                                fontWeight: "400",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {" "}
                                            Fill Same
                                        </label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isCsp ? (
                                    filterWip.length === 0 ? (
                                        <tr>
                                            <td colSpan="8" style={{ textAlign: 'center' }}>No results found</td>
                                        </tr>
                                    ) : (
                                        filterWip.map((wip, index) => {
                                            const matchedItem = filteredItems.find(item => item.Item_Name === wip.Item_Name && item.Part_Number === wip.Part_Number);
                                            const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
                                            const availableQty = matchedItem ? matchedItem.Quantity_Received - totalQtyUsed : 0;
                                            const uniqueKey = `${wip.Project_ID}_${wip.Production_Type}_${wip.Item_Name}_${wip.Part_Number}_${index}`;
                                            return (
                                                <tr key={uniqueKey}>
                                                    <td>{index + 1}</td>
                                                    <td>{wip.Item_Name}</td>
                                                    <td>{wip.Part_Number}</td>
                                                    <td>{wip.Part_Traceability_Number}</td>
                                                    <td>{wip.Equipment_Traceability_Number}</td>
                                                    <td>{wip.UoM}</td>
                                                    <td>{availableQty}</td>
                                                    <td>
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            <input
                                                                type="number"
                                                                name="csp"
                                                                style={{
                                                                    border: "1px solid #5B6BE1",
                                                                    maxWidth: "55px",
                                                                    outline: "none",
                                                                    marginBottom: "5px",
                                                                }}
                                                                // value={inputValues[wip.S_No] || ""}
                                                                // value={isfillsameWip ? availableQty : inputValues[index] || ""}
                                                                // onChange={(e) => handleOnChange2(e, wip, index)}
                                                                // disabled={isfillsameWip}
                                                                value={isfillsameWip ? (inputValues[index] !== undefined ? inputValues[index] : availableQty) : inputValues[index] || ""}
                                                                onChange={(e) => handleOnChange2(e, wip, index)}
                                                                disabled={isfillsameWip && inputValues[index] === undefined}

                                                            />
                                                        </div>
                                                        {errors[index] && (
                                                            <div style={{ color: "red", fontSize: "0.8rem", fontWeight: "normal" }}>
                                                                {errors[index]}
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )
                                ) : (
                                    filterFg.length === 0 ? (
                                        <tr>
                                            <td colSpan="8" style={{ textAlign: 'center' }}>No results found</td>
                                        </tr>
                                    ) : (
                                        filterFg.map((fgItem, index) => {
                                            const matchedItem = inventoryUtilisedItems.find(item => item.Item_Name === fgItem.Item_Name && item.Part_Number === fgItem.Part_Number);
                                            const totalQtyUsed = matchedItem ? mappedValues[`${matchedItem.Item_Name}_/${matchedItem.Part_Number}_/${matchedItem.Incoming_Delivery_ID}`] || 0 : 0;
                                            const availableQty = matchedItem ? matchedItem.Qty_Utilized - totalQtyUsed : 0;
                                            const uniqueKey = `${fgItem.Project_ID}_${fgItem.Production_Type}_${fgItem.Item_Name}_${fgItem.Part_Number}_${index}`;
                                            return (
                                                <tr key={uniqueKey}>
                                                    <td>{index + 1}</td>
                                                    <td>{fgItem.Item_Name}</td>
                                                    <td>{fgItem.Part_Number}</td>
                                                    <td>{fgItem.Ergon_Traceability_Number}</td>
                                                    <td>{fgItem.UoM}</td>
                                                    <td>{fgItem.Equipment_Traceability_Number}</td>
                                                    <td>{availableQty}</td>
                                                    <td>
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            <input
                                                                type="number"
                                                                name="Inventory"
                                                                style={{
                                                                    border: "1px solid #5B6BE1",
                                                                    maxWidth: "55px",
                                                                    outline: "none",
                                                                    marginBottom: "5px",
                                                                }}
                                                                onChange={(e) => handleOnChange3(e, fgItem, index)}
                                                                value={isfillsameFg ? (inputValues2[index] !== undefined ? inputValues2[index] : availableQty) : inputValues2[index] || ""}
                                                                disabled={isfillsameFg && inputValues2[index] === undefined}
                                                            />
                                                        </div>
                                                        {errors2[index] && (
                                                            <div style={{ color: "red", fontSize: "0.8rem", fontWeight: "normal" }}>
                                                                {errors2[index]}
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )
                                )}
                            </tbody>

                        </table>
                    </div>
                }

            </div>
        </div>
    );
}

export default DispatchCreateNew
