class WebSocketService {
    constructor(url) {
      this.url = url;
      this.ws = null;
      this.callbacks = {};
    }
  
    connect() {
      this.ws = new WebSocket(this.url);
  
      this.ws.onopen = () => {
        console.log('Connected to the server');
      };
  
      this.ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        const { event: eventName, data } = message;
  
        if (this.callbacks[eventName]) {
          this.callbacks[eventName](data);
        }
      };
  
      this.ws.onclose = () => {
        console.log('Disconnected from the server');
      };
    }
  
    send(event, data) {
      const message = { event, data };
      this.ws.send(JSON.stringify(message));
    }
  
    on(event, callback) {
      this.callbacks[event] = callback;
    }
  }
  
  export default WebSocketService;
  