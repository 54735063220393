import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "../css/professionaldetails_edit_details.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import { ProfessionalTabs } from "./professional_Tabs";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

// Custom DatePicker styling
const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiPickersBasePicker-pickerView": {
    backgroundColor: "#DADCE0",
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    width: "280px",
    "&:hover": {
      "& fieldset": {
        borderColor: "1px solid #DADCE0",
        color: "black",
      },
    },
    "&:not(.Mui-focused)": {
      "& fieldset": {
        border: "1px solid #DADCE0",
        borderRadius: "8px",
        color: "black",
      },
    },
    "&.Mui-focused": {
      "& fieldset": {
        border: "1px solid #DADCE0",
        borderRadius: "8px",
        color: "black",
      },
    },
  },
  "& input.Mui-disabled": {
    opacity: 1,
    "-webkit-text-fill-color": "black", // For WebKit-based browsers
    color: "black", // Fallback for other browsers
  },
}));

export const ProfessionalDetails_EditDetails = ({
  activeTab,
  setActiveTab,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [featureUploadContract, setFeatureUploadContract] = useState([]);

  const [formData, setFormData] = useState({
    Customer_Name: "",
    Designation: "",
    Specialization: "",
    Retainership: "",
    Employee_ID: "",
    Nationality: "",
    Date_of_Joining: "",
    Date_of_Exit: "",
    Activity_Status: "",
    Employment_Status: "",
    Email: "",
    Contact_Number: "",
    Date_of_Birth: "",
    Existing_Photo_Upload: null,
    Existing_Signature: null,
    Existing_Contract_Doc: null,
  });

  // This state holds the file fields (photo, signature, contract, etc.)
  const [files, setFiles] = useState({
    Photo_Upload: null,
    Signature: null,
    Contract_Doc: null,
  });

  //Admin Permissions
  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        // Retrieve group and roles from localStorage
        const group = localStorage.getItem("Group");
        const roles = JSON.parse(localStorage.getItem("Roles")) || [];

        // Ensure "Can View" overrides "Can Edit"
        const prioritizeAccess = (features) => {
          const featureMap = {};
          features.forEach((feature) => {
            const key = `${feature.Header_Name}-${feature.Feature_Name}`;
            if (!featureMap[key] || feature.Access === "Can View") {
              featureMap[key] = feature;
            }
          });
          return Object.values(featureMap);
        };

        // Combine group and role filters for a given feature
        const combineFeatures = (headerName, groupFeatureName, roleFeatureName) => {
          const groupFeatures = response.data.groupFeatures.filter((el) => {
            return (
              el.Group_Name === group &&
              el.Header_Name === headerName &&
              el.Feature_Name === groupFeatureName &&
              (el.Access === "Can View" || el.Access === "Can Edit")
            );
          });

          const roleFeatures = response.data.roleFeatures.filter((el) => {
            return (
              roles.includes(el.Role_Name) &&
              el.Header === headerName &&
              el.Feature === roleFeatureName &&
              el.Access === "Can View"
            );
          });

          return [...groupFeatures, ...roleFeatures];
        };

        // List of features to process
        const featuresConfig = [
          { stateSetter: setFeatureUploadContract, header: "Professional Details", groupFeature: "Upload Contract", roleFeature: "Upload Contract" },
        ];

        // Process each feature and set the state
        featuresConfig.forEach(({ stateSetter, header, groupFeature, roleFeature }) => {
          const combinedFeatures = combineFeatures(header, groupFeature, roleFeature);
          stateSetter(prioritizeAccess(combinedFeatures));
        });

      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  // Fetch existing data
  const getAllData = async () => {
    try {
      const response = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/professional_details_get_all_data/${id}`
      );
      const data = response.data[0] || {};

      // Pre-fill formData
      setFormData((prev) => ({
        ...prev,
        Customer_Name: data.Customer_Name || "",
        Designation: data.Designation || "",
        Specialization: data.Specialization || "",
        Retainership: data.Retainership || "",
        Employee_ID: data.Employee_ID || "",
        Nationality: data.Nationality || "",
        Date_of_Joining: data.Date_of_Joining || "",
        Date_of_Exit: data.Date_of_Exit || "",
        Activity_Status: data.Activity_Status || "",
        Employment_Status: data.Employment_Status || "",
        Email: data.Email || "",
        Contact_Number: data.Contact_Number || "",
        Date_of_Birth: data.Date_of_Birth || "",

        // Store existing file links so we can reuse them if no new file is uploaded
        Existing_Photo_Upload: data.Photo_Upload || null,
        Existing_Signature: data.Signature || null,
        Existing_Contract_Doc: data.Contract_Doc || null,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  // Handle text/dropdown changes
  const handleOnChange = (e) => {
    if (!isEditing) return;
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file changes
  const handleFileChange = (e) => {
    if (!isEditing) return;
    const { name } = e.target;
    const selectedFile = e.target.files[0] || null;
    setFiles((prev) => ({
      ...prev,
      [name]: selectedFile,
    }));
  };

  // Handle date changes
  const handleDateChange = (fieldName, value) => {
    if (!isEditing) return;
    if (value) {
      // Convert date to DD/MM/YYYY
      const selectedDate = value.format("DD/MM/YYYY");
      setFormData((prev) => ({ ...prev, [fieldName]: selectedDate }));
    } else {
      // If user clears the date
      setFormData((prev) => ({ ...prev, [fieldName]: "" }));
    }
  };

  // Toggle edit mode
  const handleToggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  // Discard or Cancel
  const handleDiscard = () => {
    if (isEditing) {
      getAllData();
      setIsEditing(false);
    } else {
      navigate("/professionaldetails");
    }
  };

  // Submit (Update)
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const fd = new FormData();
      // Append the text fields
      Object.keys(formData).forEach((key) => {
        fd.append(key, formData[key] ? formData[key] : "");
      });

      // Append file fields (only if new file is chosen)
      if (files.Photo_Upload) {
        fd.append("Photo_Upload", files.Photo_Upload, files.Photo_Upload.name);
      }
      if (files.Signature) {
        fd.append("Signature", files.Signature, files.Signature.name);
      }
      if (files.Contract_Doc) {
        fd.append(
          "Contract_Doc",
          files.Contract_Doc,
          files.Contract_Doc.name
        );
      }

      // Make PUT request to our update endpoint
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professional_details_update_details_with_file/${id}`,
        fd,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (res.status === 200) {
        navigate("/professionaldetails");
      }
    } catch (error) {
      console.error("Error updating professional details:", error);
    }
  };

  const handleViewFile = (fileData) => {
    // fileData can be a link (string) or a File object
    if (typeof fileData === "string") {
      window.open(fileData, "_blank");
    } else if (fileData instanceof File) {
      const url = URL.createObjectURL(fileData);
      window.open(url, "_blank");
    }
  };

  // Helper function to extract the file name without the timestamp
  const fileNameFromLink = (link) => {
    if (typeof link === "string" && link) {
      // Splits by "/" then removes the timestamp part from the filename
      return link.split("/").pop().split("-").slice(1).join("-");
    }
    return "";
  };

  const downloadFile = (fileUrl) => {
    if (fileUrl !== null) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          // Use the helper to generate a clean file name
          link.download = fileNameFromLink(fileUrl);
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("There is no file to download");
    }
  };

  // Updated download handler that supports both file links and File objects
  const handleDownloadFile = (fileData) => {
    if (typeof fileData === "string") {
      downloadFile(fileData);
    } else if (fileData instanceof File) {
      // For a File object, create a blob URL and trigger the download directly
      const url = URL.createObjectURL(fileData);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileData.name;
      link.click();
      URL.revokeObjectURL(url);
    }
  };


  return (
    <>
      <ProfessionalTabs
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSubmit={handleSubmit}
      />

      <div className={styles.createContainer}>
        <div className={styles.supplierContainer}>
          <form>
            <div className={styles.heading1}>
              <h5>Professional Details</h5>
            </div>

            <div className={styles.container1}>
              {/* LEFT COLUMN */}
              <div className={styles.leftContainer}>
                <div className={styles.formElement}>
                  <label>Name</label>
                  <input
                    type="text"
                    value={formData.Customer_Name}
                    name="Customer_Name"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Designation</label>
                  <select
                    value={formData.Designation}
                    name="Designation"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  >
                    <option value="">Select Value</option>
                    <option value="Supervisor">Supervisor</option>
                    <option value="Technician">Technician</option>
                    <option value="Trainee">Trainee</option>
                  </select>
                  {isEditing && (
                    <svg
                      style={{ position: "absolute", left: "455px", pointerEvents: "none" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Specialization</label>
                  <input
                    type="text"
                    value={formData.Specialization}
                    name="Specialization"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Retainership</label>
                  <select
                    value={formData.Retainership}
                    name="Retainership"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  >
                    <option value="">Select Value</option>
                    <option value="Monthly-Fixed">Monthly-Fixed</option>
                    <option value="Daily-Retainer">Daily-Retainer</option>
                    <option value="Non-retainer">Non-retainer</option>
                  </select>
                  {isEditing && (
                    <svg
                      style={{ position: "absolute", left: "455px", pointerEvents: "none" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Employee ID*</label>
                  <input
                    type="text"
                    value={formData.Employee_ID}
                    name="Employee_ID"
                    onChange={handleOnChange}
                    required
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Nationality</label>
                  <input
                    type="text"
                    value={formData.Nationality}
                    name="Nationality"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Date of Joining</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="Date_of_Joining"
                        value={
                          formData.Date_of_Joining
                            ? dayjs(formData.Date_of_Joining, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange("Date_of_Joining", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="Date_of_Joining"
                      value={
                        formData.Date_of_Joining
                          ? dayjs(formData.Date_of_Joining, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Date of Exit</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="Date_of_Exit"
                        value={
                          formData.Date_of_Exit
                            ? dayjs(formData.Date_of_Exit, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => handleDateChange("Date_of_Exit", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="Date_of_Exit"
                      value={
                        formData.Date_of_Exit
                          ? dayjs(formData.Date_of_Exit, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>
              </div>

              {/* RIGHT COLUMN */}
              <div className={styles.rightContainer}>
                <div className={styles.formElement}>
                  <label>Photo Upload</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Photo_Upload"
                      onChange={handleFileChange}
                      id="Photo_Upload"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Photo_Upload
                          ? files.Photo_Upload.name
                          : formData.Existing_Photo_Upload
                            ? formData.Existing_Photo_Upload.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Photo_Upload").click()
                      }
                      placeholder="Choose file..."
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Photo_Upload || formData.Existing_Photo_Upload) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Photo_Upload || formData.Existing_Photo_Upload
                            )
                          }
                        />
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Photo_Upload || formData.Existing_Photo_Upload
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.formElement}>
                  <label>Signature</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Signature"
                      onChange={handleFileChange}
                      id="Signature"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Signature
                          ? files.Signature.name
                          : formData.Existing_Signature
                            ? formData.Existing_Signature.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Signature").click()
                      }
                      placeholder="Choose file..."
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Signature || formData.Existing_Signature) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Signature || formData.Existing_Signature
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Signature || formData.Existing_Signature
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.formElement}>
                  <label>Activity Status</label>
                  <select
                    value={formData.Activity_Status}
                    name="Activity_Status"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  >
                    <option value="">Select Value</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  {isEditing && (
                    <svg
                      style={{ position: "absolute", left: "455px", pointerEvents: "none" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Employment Status</label>
                  <select
                    value={formData.Employment_Status}
                    name="Employment_Status"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  >
                    <option value="">Select Value</option>
                    <option value="Regular">Regular</option>
                    <option value="Temporary">Temporary</option>
                  </select>
                  {isEditing && (
                    <svg
                      style={{ position: "absolute", left: "455px", pointerEvents: "none" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Mail ID</label>
                  <input
                    type="text"
                    value={formData.Email}
                    name="Email"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Contact Number</label>
                  <input
                    type="text"
                    value={formData.Contact_Number}
                    name="Contact_Number"
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Date of Birth</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="Date_of_Birth"
                        value={
                          formData.Date_of_Birth
                            ? dayjs(formData.Date_of_Birth, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange("Date_of_Birth", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="Date_of_Birth"
                      value={
                        formData.Date_of_Birth
                          ? dayjs(formData.Date_of_Birth, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                {featureUploadContract.length > 0 && (
                  <div className={styles.formElement}>
                    <label>Upload Contract</label>
                    <div className={styles.fileInputWrapper}>
                      {/* Hidden file input */}
                      <input
                        type="file"
                        name="Contract_Doc"
                        onChange={handleFileChange}
                        id="Contract_Doc"
                        style={{ display: "none" }}
                        className={isEditing ? styles.fileUpload : styles.textbox1}
                        disabled={!isEditing}
                      />
                      {/* Display file name in a read-only text input */}
                      <input
                        type="text"
                        readOnly
                        className={isEditing ? styles.fileUpload : styles.textbox1}
                        value={
                          files.Contract_Doc
                            ? files.Contract_Doc.name
                            : formData.Existing_Contract_Doc
                              ? formData.Existing_Contract_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                              : ""
                        }
                        onClick={() =>
                          isEditing && document.getElementById("Contract_Doc").click()
                        }
                        placeholder="Choose file..."
                      />
                      {isEditing && (
                        <svg
                          width="28"
                          height="25"
                          viewBox="0 0 28 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            position: "absolute",
                            left: "460px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                          }}
                        >
                          <path
                            d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                            fill="black"
                          />
                          <path
                            d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                            fill="black"
                          />
                        </svg>
                      )}

                      {/* If a file exists (either a new one or from existing data), show the buttons */}
                      {(files.Contract_Doc || formData.Existing_Contract_Doc) && (
                        <div className={styles.fileActions}>
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                            alt="preview"
                            width="40"
                            height="40"
                            style={{ marginLeft: "4px", cursor: "pointer" }}
                            onClick={() =>
                              handleViewFile(
                                files.Contract_Doc || formData.Existing_Contract_Doc
                              )
                            }
                          />

                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                            alt="preview"
                            width="40"
                            height="40"
                            style={{ marginLeft: "2px", cursor: "pointer" }}
                            onClick={() =>
                              handleDownloadFile(
                                files.Contract_Doc || formData.Existing_Contract_Doc
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
