import React from "react";
import "../App.css";
import styles from ".././Components/css/workshop.module.css";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import redirecticon from "../Components/redirect.svg"
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Admintaskbar() {
  const [teamdetails, setteamdetails] = useState(false);
  const[data,setdata]=useState([]);
  const[fildata,setfildata]=useState([]);

  const[popupdate,setpopupdate]=useState();
  const [refresh, setRefresh] = useState(true);
  const [teamdetailsedit, setteamdetailsedit] = useState(false);
  const [SuperArray, setSuperArray] = useState([]);
  const [maxarray, setMaxArray] = useState([]);
  const [TechArray, setTechArray] = useState([]);
  const [TrainArray, setTrainArray] = useState([]);
  const [editMode3, setEditMode3] = useState(true);
  const [Supervisor, setSupervisorData] = useState({});
  const [Technician, setTechnicianData] = useState({});
  const [Trainee, setTraineeData] = useState({});
  const [addTrainee, setAddTrainee] = useState([""]);
  const [addSupervisor, setAddSupervisor] = useState([""]);
  const [addTechnician, setAddTechnician] = useState([""]);
  const [traineeNamesList, setTraineeNamesList] = useState([]);
  const [supervisorNamesList, setSupervisorNamesList] = useState([]);
  const [technicianNamesList, setTechnicianNamesList] = useState([]);
  const[tskdes,settskdes]=useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
        );
        setProjects(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    axios
      .get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supervisor_customer_names")
      .then((res) => setSupervisorNamesList(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/technician_customer_names")
      .then((res) => setTechnicianNamesList(res.data))
      .catch((err) => console.log(err));
  }, []);


  useEffect(() => {
    axios
    .get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/trainee_customer_names")
    .then((res) => setTraineeNamesList(res.data))
    .catch((err) => console.log(err));
  },[])

  const handleaddmoresuper = (e) => {
    e.preventDefault();

    setSupervisorData((prevSupervisor) => {
      // Calculate the index for the new supervisor
      const newIndex = Object.keys(prevSupervisor).length + 1;

      return {
        ...prevSupervisor,
        [`Supervisor${newIndex}`]: "",
      };
    });
  };
  const handleaddmoretech = (e) => {
    e.preventDefault();
    setTechnicianData((prevTechnician) => {
      // Calculate the index for the new supervisor
      const newIndex = Object.keys(prevTechnician).length + 1;

      return {
        ...prevTechnician,
        [`Technician${newIndex}`]: "",
      };
    });
  };

  const handleaddmoretrain = (e) => {
    e.preventDefault();
    setTraineeData((prevTrainee) => {
      const newIndex = Object.keys(prevTrainee).length + 1;

      return {
        ...prevTrainee,
        [`Trainee${newIndex}`]: "",
      };
    });
  };

  const handleonchangesuper = (e, index) => {
    const value1 = e.target.value;

    if (Object.values(Supervisor).includes(value1)) {
      console.log("Supervisor already added");
      return;
    }
    console.log("superrrrr<<<<<<<<<<<------", value1);
    setSupervisorData({
      ...Supervisor,
      [`Supervisor${index + 1}`]: value1,
    });
  };

  const handleonchangetech = (e, index) => {
    const value1 = e.target.value;

    if (Object.values(Technician).includes(value1)) {
      console.log("Supervisor already added");
      return;
    }
    console.log("Techhhhh>>>>>>>>>>>------", value1);
    setTechnicianData({
      ...Technician,
      [`Technician${index + 1}`]: value1,
    });
  };

  const handleonchangetrain = (e, index) => {
    const value1 = e.target.value;

    if (Object.values(Trainee).includes(value1)){
      console.log("Trainee already added");
      return;
    }
    console.log("Train>>>>>>>>>>>>----------", value1);
    setTraineeData({
      ...Trainee,
      [`Trainee${index + 1}`]: value1,
    });
  }

  const handleremovesuper = async (index) => {
    const updatedSupervisors = [...Object.values(Supervisor)];
    const removesup = updatedSupervisors[index];
    updatedSupervisors.splice(index, 1);
    //setAddSupervisor(updatedSupervisors);
    let superobj = {};

    updatedSupervisors.forEach((elm, index) => {
      superobj[`Supervisor${index + 1}`] = elm;
    });

    setSupervisorData(superobj);
  };

  const handleRemoveTrain = async (index) => {
    const updatedTrainees = [...Object.values(Trainee)];
    const removetrain = updatedTrainees[index];
    updatedTrainees.splice(index, 1);
    let trainobj = {};

    updatedTrainees.forEach((elm, index) => {
      trainobj[`Trainee${index + 1}`] = elm;
    });
    setTraineeData(trainobj);
  }

  const handleremovetech = async (index) => {
    const updatedTechnicians = [...Object.values(Technician)];
    const removetec = updatedTechnicians[index];
    updatedTechnicians.splice(index, 1);
    //setAddTechnician(updatedTechnicians);
    let techobj = {};

    updatedTechnicians.forEach((elm, index) => {
      techobj[`Technician${index + 1}`] = elm;
    });

    setTechnicianData(techobj);

    
  };

  const handleCancel3 = () => {
   setteamdetailsedit(false);
    setRefresh(!refresh);
  };


  const handleEdit = async (e) => {
    e.preventDefault();


    const convertsuperArray = Object.values(Supervisor)
      .filter((value) => value !== "")
      .join(",");
    const converttechArray = Object.values(Technician)
      .filter((value) => value !== "")
      .join(",");

    const converttrainArray = Object.values(Trainee)
      .filter((value) => value !== "")
      .join(",");

      const payload = {
        Supervisor: convertsuperArray,
        Technicians: converttechArray,
        Trainee: converttrainArray,
      };
  

      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/techworkshop_update`,
        payload
      );
      console.log("$$$$$$$$$$$$$$$$$$$4", res.data);
      setRefresh(!refresh);

setteamdetailsedit(false);
      }


  const handleteam=()=>{
    setteamdetails(true);
    setteamdetailsedit(false);
  }

  //task for today
  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolbox_data"
        );
  
        const currentDate = new Date();
        const dd = String(currentDate.getDate()).padStart(2, '0');
        const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const yyyy = currentDate.getFullYear();
        const today = dd + '/' + mm + '/' + yyyy; // Format as 'DD/MM/YYYY'
  
        const data = response.data.data; // Assuming the API response is an array of objects
        setdata(response.data.data);
  
        const todaystasks = data.filter((el) => el.Date === today);
        const taskDescriptions = todaystasks.map((task) => task.Task_description);
        settskdes(todaystasks.map((task) => task.Task_description));

        console.log(taskDescriptions); 
  
  
      } catch (error) {
        console.error(error);
      }
    }
  
    fetchRigDetails();
  }, []);

  const handlepop=()=>{
    const currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const yyyy = currentDate.getFullYear();
    const today = dd + '/' + mm + '/' + yyyy; // Format as 'DD/MM/YYYY'
    setpopupdate(today);
    setfildata( data.filter(el => el.Date === today));
    console.log(data.filter(el => el.Date ===  today));
    
          }
    
    
          const handlecrss=()=>{
            setpopupdate();
            setfildata();
          };
    
  
useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/techworkshop_data"
        );

        console.log(response.data);

        const dataObject = response.data.data[0];
        console.log(dataObject.Supervisor);

        const superarray1 = dataObject.Supervisor.split(",");
        const techarray1 = dataObject.Technicians.split(",");
        const trainarray1 = dataObject.Trainee.split(",");

     

        setSuperArray(superarray1);
        setTechArray(techarray1);
        setTrainArray(trainarray1);

        const arrays = [superarray1, techarray1, trainarray1];

        const maxLength = Math.max(...arrays.map(arr => arr.length));

        const maxArrayIndex = arrays.findIndex(arr => arr.length === maxLength);
        const maxArray = arrays[maxArrayIndex];

        setMaxArray(maxArray);
        console.log(maxArray);
        let supobj = {};
        if (dataObject?.Supervisor) {
          dataObject?.Supervisor.split(",")?.map((elm, index) => {
            supobj[`Supervisor${index + 1}`] = elm;
          });
  
          setAddSupervisor(dataObject?.Supervisor.split(","));
        }
  
        console.log("check this empty      ", dataObject?.Supervisor);
  
        setSupervisorData(supobj);
  
        let tecobj = {};
        if (dataObject?.Technicians) {
          dataObject?.Technicians.split(",")?.map((elm, index) => {
            tecobj[`Technician${index + 1}`] = elm;
          });
          setAddTechnician(dataObject?.Technicians.split(","));
        }
  
  
        setTechnicianData(tecobj);
  
        let trainNames = dataObject?.Trainee?.split(",");
  
        // setTrainArray(trainNames);
  
        let trainobj = {};
        if (dataObject?.Trainee) {
          dataObject?.Trainee.split(",")?.map((elm, index) => {
            trainobj[`Trainee${index + 1}`] = elm;
          });
          setAddTrainee(dataObject?.Trainee.split(","));
        }
  
        setTraineeData(trainobj);
        console.log(trainobj);
  
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, [refresh]);

  return (
    <>
    {popupdate && (
        <div className={styles.div2a}> 
        <div className={styles.modalcontenta}>
            <div style={{backgroundColor:"#D1D9FF" , marginTop:"0px" , height:"50px",  paddingTop:"10px"}}>
             <h4 style={{marginRight:"73%" }}>Toolbox Talk  {popupdate} </h4>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={handlecrss} style={{cursor:"pointer", marginLeft:"95%" , marginTop:"-50px" }}>
<path opacity="0.15" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="black"/>
<path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </div>
        <table className={styles.rigtablea} >
            <thead>
            <tr>
                <th>S.No</th>
                <th>Task Description</th>
                <th>Project</th>
                <th>Team Assigned</th>
                <th>Target units</th>
                <th>Achieved Units</th>
                <th>Reason for Shortfall</th>
                <th>Additional tools requested</th>
            </tr>
            </thead>
           
            {fildata.map((item, index) => (
          <tr key={index}>
            <td>{index+1}</td>
            <td>{item.Task_description}</td>
            <td>{item.Project_so}</td>
            <td>{item.Team_assigned}</td>
            <td>{item.Target_units}</td>
            <td>{item.Achieved_Units}</td>
            <td>{item.Reason}</td>
            <td>{item.Additional_Tools}</td>       
          </tr>
        ))}


        </table>
        </div>
        </div>
       )
}
    {teamdetails && (
        <>
        <div className={styles.modal}>
        <div className={styles.modalcontent}>
<div>
{/* <div className={styles.modalcontent3} onClick={() => setteamdetails(false)} ><svg xmlns="http://www.w3.org/2000/svg" width="34" height="24" viewBox="0 0 34 24" fill="none">
<path d="M0.939049 10.9393C0.353264 11.5251 0.353264 12.4749 0.939049 13.0607L10.485 22.6066C11.0708 23.1924 12.0205 23.1924 12.6063 22.6066C13.1921 22.0208 13.1921 21.0711 12.6063 20.4853L4.12103 12L12.6063 3.51472C13.1921 2.92893 13.1921 1.97919 12.6063 1.3934C12.0205 0.807611 11.0708 0.807611 10.485 1.3934L0.939049 10.9393ZM33.1445 10.5L1.99971 10.5V13.5L33.1445 13.5V10.5Z" fill="#5B6BE1"/>
</svg>{"  "}Techs in Workshop</div> */}
<div style={{backgroundColor:"#D1D9FF" , marginTop:"0px" , height:"50px",  paddingTop:"10px"}}>
              <h4 style={{marginRight:"75%" }}>{"  "}Techs in Workshop </h4>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => setteamdetails(false)}style={{cursor:"pointer", marginLeft:"95%" , marginTop:"-50px" }}>
<path opacity="0.15" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="black"/>
<path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </div>

{!teamdetailsedit &&<button className={styles.editbutton} onClick={() => setteamdetailsedit(true)}>Edit</button>}
        {!teamdetailsedit &&  <table className={styles.rigtable}>
            <thead>
                <th>Supervisor</th>
                <th>Technicians</th>
                <th>Trainees</th>
            </thead>
            <tbody>
                {maxarray.map((el,index)=>(
                    <tr>
                    <td>{SuperArray[index]}</td>
                    <td>{TechArray[index]}</td>
                    <td>{TrainArray[index]}</td>
                    </tr>
              

                )

                )
}

            </tbody>
            </table> 
}
{ teamdetailsedit && (
  <>
 <div
 style={{ width: "100%", borderTop: 0 }}
 fontSize={19}>
 {/* {featureteamdetails.length > 0 ? ( */}
 <Box
   sx={{
     mt: "0px",
     ml: "1.5%",
     bgcolor: "#D9D9D9",

     borderRight: 1,
     borderLeft: 1,
     borderBottom: 1,

     background: "white",
     width: "97%",
     border: "1px solid #E7EBFF",
     // borderTop:0,
   }}>
   <Box
     sx={{
       display: "flex",
       justifyContent: "left",
       marginLeft: "-10px",
       gap: "5%",
       marginTop: "25px",
       borderTop: 0,
     }}>
     <div
       style={{
         display: "flex",
         flexDirection: "column",
         gap: "1rem",
         marginLeft: "2.5rem",
       }}>
       <h5
         style={{
           fontSize: "20px",
           fontWeight: "700",
           marginBottom: "15px",
         }}>
         Supervisors
       </h5>
       {editMode3 ? (
         <>
           {Object.values(Supervisor).map((elm, index) => (
             <div style={{ display: "flex", marginTop: "5px" }}>
               <Typography
                 style={{
                   color: "black",
                   width: "105px",
                   fontFamily: "Open Sans",
                   fontSize: "16px",
                   fontWeight: "400",
                   lineHeight: "normal",
                   marginTop: "7px",
                 }}>
                 Supervisor {index + 1}:
               </Typography>
               <select
                 style={{
                   width: "150px",
                   height: "35px",
                   fontSize:"12px",
                   border: "1px solid #E7EBFF",
                   borderRadius: "8px",
                 }}
                 className="editOpen"
                 name="supervisorNames"
                 value={Supervisor[`Supervisor${index + 1}`]}
                 onChange={(e) => handleonchangesuper(e, index)}>
                 <option value="">Select Option</option>
                 {supervisorNamesList.map((elm) => (
                   <option key={elm}>{elm}</option>
                 ))}
               </select>
               {addSupervisor.length > 0 && (
                 <button
                   onClick={() => handleremovesuper(index)}
                   style={{
                     background: "white",
                     color: "#567191",
                     fontSize: "14px",
                     gap: "5px",
                     marginTop: "-3px",
                     border:"none",
                     marginLeft:"10px",
                   }}>
                   <svg
                     width="19"
                     height="19"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                     <g id="close-circle">
                       <path
                         id="Vector"
                         d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                         fill="#567191"
                       />
                     </g>
                   </svg>{" "}
                 </button>
               )}
               {/* <input
                className="editOpen"
                type="text"
                name="supervisorNames"
                value={formdata1.supervisorNames}
                onChange={handleInputs}
              /> */}
             </div>
           ))}
         </>
       ) : (
         // Render when editMode3 is false
         <>
           {
             SuperArray.map((elm, index) => (
               elm === "" ? null : (
                 <div key={index} style={{ display: "flex" }}>
                 <Typography
                   style={{
                     color: "black",
                     width: "105px",
                     fontFamily: "Open Sans",
                     fontSize: "16px",
                     fontWeight: "400",
                     lineHeight: "normal",
                     marginTop: "3px",
                   }}>
                   Supervisor {index + 1}:
                 </Typography>
                 <input
                   style={{
                     border: "none",
                     background: "white",
                     fontWeight: "bold",
                     fontSize:"14px",
                     width:"250px"
                   }}
                   type="text"
                   value={elm}
                   disabled
                   className="editclosed"
                 />
               </div>
               )
             ))}
         </>
       )}
       <div>
         {editMode3 ? (
           <button
             onClick={handleaddmoresuper}
             className={styles.addNamesBtn}>
             Add More{" "}
             <svg
               style={{ marginLeft: "6px" }}
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
               <path
                 d="M13.491 1.66667H6.50768C3.47435 1.66667 1.66602 3.475 1.66602 6.50834V13.4833C1.66602 16.525 3.47435 18.3333 6.50768 18.3333H13.4827C16.516 18.3333 18.3243 16.525 18.3243 13.4917V6.50834C18.3327 3.475 16.5243 1.66667 13.491 1.66667ZM14.9993 10.625H10.6243V15C10.6243 15.3417 10.341 15.625 9.99935 15.625C9.65768 15.625 9.37435 15.3417 9.37435 15V10.625H4.99935C4.65768 10.625 4.37435 10.3417 4.37435 10C4.37435 9.65834 4.65768 9.375 4.99935 9.375H9.37435V5.00001C9.37435 4.65834 9.65768 4.37501 9.99935 4.37501C10.341 4.37501 10.6243 4.65834 10.6243 5.00001V9.375H14.9993C15.341 9.375 15.6243 9.65834 15.6243 10C15.6243 10.3417 15.341 10.625 14.9993 10.625Z"
                 fill="black"
               />
             </svg>
           </button>
         ) : (
           <div></div>
         )}
       </div>
     </div>

     <div
       style={{
         display: "flex",
         flexDirection: "column",
         gap: "1rem",
         mt: "10px",
       }}>
       <h5 style={{ fontWeight: "700", marginBottom: "15px" }}>
         Technicians
       </h5>
       {editMode3 ? (
         <>
           {Object.values(Technician).map((elm, index) => (
             // Render elements here for editMode3 = true
             <div style={{ display: "flex" }}>
               <Typography
                 style={{
                   color: "black",
                   width: "105px",
                   fontFamily: "Open Sans",
                   fontSize: "16px",
                   fontWeight: "400",
                   lineHeight: "normal",
                   marginTop: "7px",
                 }}>
                 Technician {index + 1}:
               </Typography>
               <select
                 style={{
                   width: "150px",
                   height: "35px",
                   border: "1px solid #E7EBFF",
                   borderRadius: "8px",
                   fontSize:"12px"
                 }}
                 className="editOpen"
                 name="technicianNames"
                 value={Technician[`Technician${index + 1}`]}
                 onChange={(e) => handleonchangetech(e, index)}>
                 <option value="">Select Option</option>
                 {technicianNamesList.map((elm) => (
                   <option key={elm}>{elm}</option>
                 ))}
               </select>
               {addTechnician.length > 0 && (
                 <button
                   onClick={() => handleremovetech(index)}
                   style={{
                     background: "white",
                     color: "#567191",
                     fontSize: "14px",
                     gap: "10px",
                     marginTop: "-2px",
                     border:"none",
                     marginLeft:"10px",
                   }}>
                   <svg
                     width="19"
                     height="19"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                     <g id="close-circle">
                       <path
                         id="Vector"
                         d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                         fill="#567191"
                       />
                     </g>
                   </svg>{" "}
                 </button>
               )}
             </div>
           ))}
         </>
       ) : (
         <>
           {
             TechArray.map((elm, index) => (
               elm === "" ? null : (
                 <div key={index} style={{ display: "flex" }}>
                 <Typography
                   style={{
                     color: "black",
                     width: "105px",
                     fontFamily: "Open Sans",
                     fontSize: "16px",
                     fontWeight: "400",
                     lineHeight: "normal",
                   }}>
                   Technician {index + 1}:
                 </Typography>
                 <input
                   style={{
                     border: "none",
                     background: "white",
                     fontWeight: "bold",
                     marginTop: "-5px",
                     fontSize:"14px",
                     width:"250px"
                   }}
                   type="text"
                   value={elm}
                   disabled
                   className="editclosed"
                 />
               </div>
               )
             ))}
         </>
       )}

       <div>
         {editMode3 ? (
           <button
             onClick={handleaddmoretech}
             className={styles.addNamesBtn}>
             Add More{" "}
             <svg
               style={{ marginLeft: "6px" }}
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
               <path
                 d="M13.491 1.66667H6.50768C3.47435 1.66667 1.66602 3.475 1.66602 6.50834V13.4833C1.66602 16.525 3.47435 18.3333 6.50768 18.3333H13.4827C16.516 18.3333 18.3243 16.525 18.3243 13.4917V6.50834C18.3327 3.475 16.5243 1.66667 13.491 1.66667ZM14.9993 10.625H10.6243V15C10.6243 15.3417 10.341 15.625 9.99935 15.625C9.65768 15.625 9.37435 15.3417 9.37435 15V10.625H4.99935C4.65768 10.625 4.37435 10.3417 4.37435 10C4.37435 9.65834 4.65768 9.375 4.99935 9.375H9.37435V5.00001C9.37435 4.65834 9.65768 4.37501 9.99935 4.37501C10.341 4.37501 10.6243 4.65834 10.6243 5.00001V9.375H14.9993C15.341 9.375 15.6243 9.65834 15.6243 10C15.6243 10.3417 15.341 10.625 14.9993 10.625Z"
                 fill="black"
               />
             </svg>
           </button>
         ) : (
           <div></div>
         )}
       </div>
     </div>
     <div
       style={{
         display: "flex",
         flexDirection: "column",
         gap: "1rem",
         mt: "0px",
       }}
     >
       <h5 style={{ fontWeight: "700", marginBottom: "15px" }}>
         Trainees
       </h5>
       {editMode3 ? (
         <>
           {Object.values(Trainee).map((elm, index) => (
             <div style={{ display: "flex" }} key={index}>
               <Typography
                 style={{
                   color: "black",
                   width: "85px",
                   fontFamily: "Open Sans",
                   fontSize: "16px",
                   fontWeight: "400",
                   lineHeight: "normal",
                   marginTop: "7px",
                 }}
               >
                 Trainee {index + 1}:
               </Typography>
               <select
                 style={{
                   width: "150px",
                   height: "35px",
                   border: "1px solid #E7EBFF",
                   borderRadius: "8px",
                   fontSize:"12px"
                 }}
                 className="editOpen"
                 name="traineeNames"
                 value={Trainee[`Trainee${index + 1}`]}
                 onChange={(e) => handleonchangetrain(e, index)}
               >
                 <option value="">Select Option</option>
                 {traineeNamesList.map((elm) => (
                   <option key={elm}>{elm}</option>
                 ))}
               </select>
               {addTrainee.length > 0 && (
                 <button
                   onClick={() => handleRemoveTrain(index)}
                   style={{
                     background: "white",
                     color: "#567191",
                     fontSize: "14px",
                     gap: "2px",
                     marginTop: "-2px",
                     marginLeft:"10px",
                     border:"none",
                   }}
                 >
                   <svg
                     width="19"
                     height="19"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <g id="close-circle">
                       <path
                         id="Vector"
                         d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                         fill="#567191"
                       />
                     </g>
                   </svg>{" "}
                   
                 </button>
               )}
             </div>
           ))}
         </>
       ) : (
         <>
             {
             TrainArray && TrainArray.map((elm, index) => (
               elm === "" ? null : (
                 <div key={index} style={{ display: "flex" }}>
                 <Typography
                   style={{
                     color: "black",
                     width: "85px",
                     fontFamily: "Open Sans",
                     fontSize: "16px",
                     fontWeight: "400",
                     lineHeight: "normal",
                   }}>
                   Trainee {index + 1}:
                 </Typography>
                 <input
                   style={{
                     border: "none",
                     background: "white",
                     fontWeight: "bold",
                     marginTop: "-5px",
                     fontSize:"14px",
                     width:"250px"
                   }}
                   type="text"
                   value={elm}
                   disabled
                   className="editclosed"
                 />
               </div>
               )
             ))}
           {/* {addTrainee[0] !== "" &&
             addTrainee.map((elm, index) => (
               <div key={index} style={{ display: "flex", gap: "1rem", marginTop:"20px" }}>
                 <Typography
                   style={{
                     color: "black",
                     width: "150px",
                     fontFamily: "Open Sans",
                     fontSize: "16px",
                     fontWeight: "400",
                     lineHeight: "normal",
                   }}
                 >
                   Trainee {index + 1}:
                 </Typography>
                 <input
                   style={{
                     border: "none",
                     background: "white",
                     fontWeight: "bold",
                     marginTop: "-5px",
                   }}
                   type="text"
                   value={elm}
                   disabled
                   className="editclosed"
                 />
               </div>
             ))} */}


         </>
       )}
       <div>
         {editMode3 ? (
           <button
             onClick={handleaddmoretrain}
             className={styles.addNamesBtn}>
             Add More{" "}
             <svg
               style={{ marginLeft: "6px" }}
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
               <path
                 d="M13.491 1.66667H6.50768C3.47435 1.66667 1.66602 3.475 1.66602 6.50834V13.4833C1.66602 16.525 3.47435 18.3333 6.50768 18.3333H13.4827C16.516 18.3333 18.3243 16.525 18.3243 13.4917V6.50834C18.3327 3.475 16.5243 1.66667 13.491 1.66667ZM14.9993 10.625H10.6243V15C10.6243 15.3417 10.341 15.625 9.99935 15.625C9.65768 15.625 9.37435 15.3417 9.37435 15V10.625H4.99935C4.65768 10.625 4.37435 10.3417 4.37435 10C4.37435 9.65834 4.65768 9.375 4.99935 9.375H9.37435V5.00001C9.37435 4.65834 9.65768 4.37501 9.99935 4.37501C10.341 4.37501 10.6243 4.65834 10.6243 5.00001V9.375H14.9993C15.341 9.375 15.6243 9.65834 15.6243 10C15.6243 10.3417 15.341 10.625 14.9993 10.625Z"
                 fill="black"
               />
             </svg>
           </button>
         ) : (
           <div></div>
         )}
       </div>
     </div>
   </Box>
   <Box
     sx={{
       display: "flex",
       justifyContent: "flex-end",
       mt: "20px",
       mr: "20px",
     }}>
     {editMode3 ? (
       <>
         <button
           style={{
             background: "white",
             color: "#5B6BE1",
             border: "1px solid #5B6BE1",
             borderRadius: "8px",
             width: "171px",
             height: "50px",
             padding: "15px 59px 15px 58px",
             fontWeight: "bold",
             marginRight:"50px",
             marginBottom:"20px",
           }}
           onClick={handleCancel3}>
           Cancel
         </button>
         <button
           style={{
             background: "#5B6BE1",
             width: "171px",
             height: "50px",
             padding: "15px 59px 15px 58px",
             border: "1px solid #5B6BE1",
             borderRadius: "8px",
             fontWeight: "bold",
             color:"white",
           }}
           onClick={handleEdit}>
           Save
         </button>
       </>
     ) : (
       // featureteamdetailsedit.length > 0 && (
       <button
         style={{
           background: "#5B6BE1",
           width: "171px",
           height: "50px",
           border: "1px solid #5B6BE1",
           borderRadius: "8px",
           padding: "15px 59px 15px 58px",
           fontWeight: "bold",
         }}
         onClick={() => setEditMode3(true)}>
         Edit_
         <svg
           xmlns="http://www.w3.org/2000/svg"
           width="13"
           height="14"
           fill="currentColor"
           class="bi bi-pen-fill"
           viewBox="0 0 16 16">
           <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
         </svg>
       </button>
       // )
     )}
   </Box>
 </Box>
 {/* ):(
   <Box className={styles.tabpanelStyling}>
   <div style={{marginLeft:"30%", marginTop:"15%"}}>
     <h3>
       You do not have permissions to view this page
     </h3>
   </div>
 </Box>
 )} */}
</div>
</>
    )
}


          </div>
        </div>
      </div>
      </>
    )}
      <div className={styles.mainhead}><h4>Admin Dashboard</h4></div>
      <div className={styles.mainmaindiv}>
      <div className={styles.maindiv1}>
        <h5 className={styles.mainhead2}>Admin Task</h5>
        <div className={styles.maindiv2}>
          <div className={styles.maindiv3}>Toolbox Talk</div>
          <div className={styles.maindiv4}>
            <div className={styles.svg1}>
            <NavLink to ="/toolbox_createnew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22713)">
                  <path
                    d="M8.99912 0C4.03707 0 0 4.03781 0 9.00037C0 13.9626 4.03712 18 8.99912 18C13.9615 18 17.9986 13.9626 17.9986 9.00037C17.9986 4.03781 13.9619 0 8.99912 0ZM13.13 10.1218H9.84591V13.131C9.84591 13.5534 9.56929 13.8952 9.14635 13.8952C8.72427 13.8952 8.44697 13.553 8.44697 13.131V10.1218H4.8693C4.44619 10.1218 4.10417 9.84517 4.10417 9.4224C4.10417 8.99963 4.44619 8.72285 4.8693 8.72285H8.44691V4.86896C8.44691 4.44619 8.72421 4.10389 9.1463 4.10389C9.56924 4.10389 9.84585 4.44624 9.84585 4.86896V8.7229H13.13C13.553 8.7229 13.8952 8.99969 13.8952 9.42246C13.8952 9.84523 13.5531 10.1218 13.13 10.1218Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22713">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Create New</label>
            </div>
            <div className={styles.svg2}>
                <NavLink to ="/Workshoplist">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22707)">
                  <path
                    d="M9 0C4.0295 0 0 4.02954 0 9C0 13.9705 4.0295 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02954 13.9705 0 9 0ZM13.1361 12.8776H4.8639V11.3266H13.1361V12.8776ZM13.1361 9.77537H4.8639V8.22491H13.1361V9.77537ZM13.1361 6.67364H4.8639V5.12265H13.1361V6.67364Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22707">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Listing</label>
            </div>
          </div>
          
        </div>
        {/* {<div className={styles.maindiv2}>
          <div className={styles.maindiv3}>Work permit</div>
          <div className={styles.maindiv4}>
            <div className={styles.svg1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22713)">
                  <path
                    d="M8.99912 0C4.03707 0 0 4.03781 0 9.00037C0 13.9626 4.03712 18 8.99912 18C13.9615 18 17.9986 13.9626 17.9986 9.00037C17.9986 4.03781 13.9619 0 8.99912 0ZM13.13 10.1218H9.84591V13.131C9.84591 13.5534 9.56929 13.8952 9.14635 13.8952C8.72427 13.8952 8.44697 13.553 8.44697 13.131V10.1218H4.8693C4.44619 10.1218 4.10417 9.84517 4.10417 9.4224C4.10417 8.99963 4.44619 8.72285 4.8693 8.72285H8.44691V4.86896C8.44691 4.44619 8.72421 4.10389 9.1463 4.10389C9.56924 4.10389 9.84585 4.44624 9.84585 4.86896V8.7229H13.13C13.553 8.7229 13.8952 8.99969 13.8952 9.42246C13.8952 9.84523 13.5531 10.1218 13.13 10.1218Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22713">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <label> Daily entry</label>
            </div>
            <div className={styles.svg2}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22707)">
                  <path
                    d="M9 0C4.0295 0 0 4.02954 0 9C0 13.9705 4.0295 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02954 13.9705 0 9 0ZM13.1361 12.8776H4.8639V11.3266H13.1361V12.8776ZM13.1361 9.77537H4.8639V8.22491H13.1361V9.77537ZM13.1361 6.67364H4.8639V5.12265H13.1361V6.67364Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22707">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <label> Listing</label>
            </div>
          </div>
           */}
        {/* </div> */}
        {/* <div className={styles.maindiv2}>
          <div className={styles.maindiv3}>TBRA</div>
          <div className={styles.maindiv4}>
            <div className={styles.svg1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22713)">
                  <path
                    d="M8.99912 0C4.03707 0 0 4.03781 0 9.00037C0 13.9626 4.03712 18 8.99912 18C13.9615 18 17.9986 13.9626 17.9986 9.00037C17.9986 4.03781 13.9619 0 8.99912 0ZM13.13 10.1218H9.84591V13.131C9.84591 13.5534 9.56929 13.8952 9.14635 13.8952C8.72427 13.8952 8.44697 13.553 8.44697 13.131V10.1218H4.8693C4.44619 10.1218 4.10417 9.84517 4.10417 9.4224C4.10417 8.99963 4.44619 8.72285 4.8693 8.72285H8.44691V4.86896C8.44691 4.44619 8.72421 4.10389 9.1463 4.10389C9.56924 4.10389 9.84585 4.44624 9.84585 4.86896V8.7229H13.13C13.553 8.7229 13.8952 8.99969 13.8952 9.42246C13.8952 9.84523 13.5531 10.1218 13.13 10.1218Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22713">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <label> Daily entry</label>
            </div>
            <div className={styles.svg2}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22707)">
                  <path
                    d="M9 0C4.0295 0 0 4.02954 0 9C0 13.9705 4.0295 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02954 13.9705 0 9 0ZM13.1361 12.8776H4.8639V11.3266H13.1361V12.8776ZM13.1361 9.77537H4.8639V8.22491H13.1361V9.77537ZM13.1361 6.67364H4.8639V5.12265H13.1361V6.67364Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22707">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <label> Listing</label>
            </div>
          </div>
          
        </div> */}
        <div className={styles.maindiv2}>
          <div className={styles.maindiv3}>Timesheet</div>
          <div className={styles.maindiv4}>
            <div className={styles.svg1}>
            <NavLink to="/newtimesheet">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22713)">
                  <path
                    d="M8.99912 0C4.03707 0 0 4.03781 0 9.00037C0 13.9626 4.03712 18 8.99912 18C13.9615 18 17.9986 13.9626 17.9986 9.00037C17.9986 4.03781 13.9619 0 8.99912 0ZM13.13 10.1218H9.84591V13.131C9.84591 13.5534 9.56929 13.8952 9.14635 13.8952C8.72427 13.8952 8.44697 13.553 8.44697 13.131V10.1218H4.8693C4.44619 10.1218 4.10417 9.84517 4.10417 9.4224C4.10417 8.99963 4.44619 8.72285 4.8693 8.72285H8.44691V4.86896C8.44691 4.44619 8.72421 4.10389 9.1463 4.10389C9.56924 4.10389 9.84585 4.44624 9.84585 4.86896V8.7229H13.13C13.553 8.7229 13.8952 8.99969 13.8952 9.42246C13.8952 9.84523 13.5531 10.1218 13.13 10.1218Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22713">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Create New</label>
            </div>
            <div className={styles.svg2}>
            <NavLink to="/Timesheetlist">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22707)">
                  <path
                    d="M9 0C4.0295 0 0 4.02954 0 9C0 13.9705 4.0295 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02954 13.9705 0 9 0ZM13.1361 12.8776H4.8639V11.3266H13.1361V12.8776ZM13.1361 9.77537H4.8639V8.22491H13.1361V9.77537ZM13.1361 6.67364H4.8639V5.12265H13.1361V6.67364Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22707">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Listing</label>
            </div>
          </div>
          
        </div> 

        {/* <div className={styles.maindiv2}>
          <div className={styles.maindiv3}>Deliveries</div>
          <div className={styles.maindiv4}>
            <div className={styles.svg1}>
            <NavLink to="/universal_deliveries">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22707)">
                  <path
                    d="M9 0C4.0295 0 0 4.02954 0 9C0 13.9705 4.0295 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02954 13.9705 0 9 0ZM13.1361 12.8776H4.8639V11.3266H13.1361V12.8776ZM13.1361 9.77537H4.8639V8.22491H13.1361V9.77537ZM13.1361 6.67364H4.8639V5.12265H13.1361V6.67364Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22707">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Listing</label>
            </div>
          </div>        
        </div> */}

        
        <div className={styles.maindiv2}>
          <div className={styles.maindiv3}>Inventory Utilization</div>
          <div className={styles.maindiv4}>
            <div className={styles.svg1}>
              <NavLink to="/inventory_utilization">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22713)">
                  <path
                    d="M8.99912 0C4.03707 0 0 4.03781 0 9.00037C0 13.9626 4.03712 18 8.99912 18C13.9615 18 17.9986 13.9626 17.9986 9.00037C17.9986 4.03781 13.9619 0 8.99912 0ZM13.13 10.1218H9.84591V13.131C9.84591 13.5534 9.56929 13.8952 9.14635 13.8952C8.72427 13.8952 8.44697 13.553 8.44697 13.131V10.1218H4.8693C4.44619 10.1218 4.10417 9.84517 4.10417 9.4224C4.10417 8.99963 4.44619 8.72285 4.8693 8.72285H8.44691V4.86896C8.44691 4.44619 8.72421 4.10389 9.1463 4.10389C9.56924 4.10389 9.84585 4.44624 9.84585 4.86896V8.7229H13.13C13.553 8.7229 13.8952 8.99969 13.8952 9.42246C13.8952 9.84523 13.5531 10.1218 13.13 10.1218Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22713">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Create New</label>
            </div>
            <div className={styles.svg2}>
            <NavLink to="/InventoryUtilizationLogs">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22707)">
                  <path
                    d="M9 0C4.0295 0 0 4.02954 0 9C0 13.9705 4.0295 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02954 13.9705 0 9 0ZM13.1361 12.8776H4.8639V11.3266H13.1361V12.8776ZM13.1361 9.77537H4.8639V8.22491H13.1361V9.77537ZM13.1361 6.67364H4.8639V5.12265H13.1361V6.67364Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22707">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Listing</label>
            </div>
          </div>
          
        </div>
        
        <div className={styles.maindiv2}>
          <div className={styles.maindiv3}>Purchases</div>
          <div className={styles.maindiv4}>
            <div className={styles.svg1}>
              <NavLink to="/purchasecreatenew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22713)">
                  <path
                    d="M8.99912 0C4.03707 0 0 4.03781 0 9.00037C0 13.9626 4.03712 18 8.99912 18C13.9615 18 17.9986 13.9626 17.9986 9.00037C17.9986 4.03781 13.9619 0 8.99912 0ZM13.13 10.1218H9.84591V13.131C9.84591 13.5534 9.56929 13.8952 9.14635 13.8952C8.72427 13.8952 8.44697 13.553 8.44697 13.131V10.1218H4.8693C4.44619 10.1218 4.10417 9.84517 4.10417 9.4224C4.10417 8.99963 4.44619 8.72285 4.8693 8.72285H8.44691V4.86896C8.44691 4.44619 8.72421 4.10389 9.1463 4.10389C9.56924 4.10389 9.84585 4.44624 9.84585 4.86896V8.7229H13.13C13.553 8.7229 13.8952 8.99969 13.8952 9.42246C13.8952 9.84523 13.5531 10.1218 13.13 10.1218Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22713">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Create New</label>
            </div>
            <div className={styles.svg2}>
            <NavLink to="/Purchases">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22707)">
                  <path
                    d="M9 0C4.0295 0 0 4.02954 0 9C0 13.9705 4.0295 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02954 13.9705 0 9 0ZM13.1361 12.8776H4.8639V11.3266H13.1361V12.8776ZM13.1361 9.77537H4.8639V8.22491H13.1361V9.77537ZM13.1361 6.67364H4.8639V5.12265H13.1361V6.67364Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22707">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Listing</label>
            </div>
          </div>
          
        </div>
        <h5 className={styles.mainhead2}>Mobilizations</h5>
        <div className={styles.maindiv2}>
          <div className={styles.maindiv3}>Travel Attendance</div>
          <div className={styles.maindiv4}>
            <div className={styles.svg1}>
              <NavLink to="/travelattendance_createnew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22713)">
                  <path
                    d="M8.99912 0C4.03707 0 0 4.03781 0 9.00037C0 13.9626 4.03712 18 8.99912 18C13.9615 18 17.9986 13.9626 17.9986 9.00037C17.9986 4.03781 13.9619 0 8.99912 0ZM13.13 10.1218H9.84591V13.131C9.84591 13.5534 9.56929 13.8952 9.14635 13.8952C8.72427 13.8952 8.44697 13.553 8.44697 13.131V10.1218H4.8693C4.44619 10.1218 4.10417 9.84517 4.10417 9.4224C4.10417 8.99963 4.44619 8.72285 4.8693 8.72285H8.44691V4.86896C8.44691 4.44619 8.72421 4.10389 9.1463 4.10389C9.56924 4.10389 9.84585 4.44624 9.84585 4.86896V8.7229H13.13C13.553 8.7229 13.8952 8.99969 13.8952 9.42246C13.8952 9.84523 13.5531 10.1218 13.13 10.1218Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22713">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Create New</label>
            </div>
            <div className={styles.svg2}>
            <NavLink to="/travelattendance_view">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_2408_22707)">
                  <path
                    d="M9 0C4.0295 0 0 4.02954 0 9C0 13.9705 4.0295 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02954 13.9705 0 9 0ZM13.1361 12.8776H4.8639V11.3266H13.1361V12.8776ZM13.1361 9.77537H4.8639V8.22491H13.1361V9.77537ZM13.1361 6.67364H4.8639V5.12265H13.1361V6.67364Z"
                    fill="#5B6BE1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2408_22707">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </NavLink>
              <label> Listing</label>
            </div>
          </div>
          
        </div>
      </div>
      <div  className={styles.inlinediv}>
        <div className={styles.maindivbeta}>
  <h2 className={styles.heading1}>Tasks for Today <img src={redirecticon} height="20px" width="20px" onClick={() => handlepop()} /></h2>
  <ul className={styles.svgbulletlist}>
    {tskdes.map((task, index) => (
      <li key={index}>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none" className={styles.svg3}>
          <path d="M1 2.9314L1 11.0687C1 11.6745 1 11.9774 1.1198 12.1177C1.22374 12.2394 1.37967 12.304 1.53923 12.2914C1.72312 12.277 1.93731 12.0628 2.3657 11.6344L6.4343 7.5658C6.6323 7.3678 6.7313 7.2687 6.7684 7.1546C6.8011 7.0542 6.8011 6.946 6.7684 6.8456C6.7313 6.7314 6.6323 6.6324 6.4343 6.4344L2.3657 2.3658C1.93731 1.9374 1.72312 1.7232 1.53923 1.7087C1.37967 1.6962 1.22374 1.7608 1.1198 1.8825C1 2.0227 1 2.3256 1 2.9314Z" stroke="#5B6BE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        {task}
      </li>
    ))}
  </ul>
</div>
        <div className={styles.maindivbeta}>
        <h2 className={styles.heading1}>Techs in Workshop{" "}  <img src={redirecticon}  height="20px" width="20px"  onClick={handleteam} /></h2>
        <ul className={styles.svgbulletlist} >
        {SuperArray.map((el,index)=>(
             <li >
             <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none" className={styles.svg3}>
     <path d="M1 2.9314L1 11.0687C1 11.6745 1 11.9774 1.1198 12.1177C1.22374 12.2394 1.37967 12.304 1.53923 12.2914C1.72312 12.277 1.93731 12.0628 2.3657 11.6344L6.4343 7.5658C6.6323 7.3678 6.7313 7.2687 6.7684 7.1546C6.8011 7.0542 6.8011 6.946 6.7684 6.8456C6.7313 6.7314 6.6323 6.6324 6.4343 6.4344L2.3657 2.3658C1.93731 1.9374 1.72312 1.7232 1.53923 1.7087C1.37967 1.6962 1.22374 1.7608 1.1198 1.8825C1 2.0227 1 2.3256 1 2.9314Z" stroke="#5B6BE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
            {el}
             </li>
)

)
}
{TechArray.map((el,index)=>(
             <li >
             <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none" className={styles.svg3}>
     <path d="M1 2.9314L1 11.0687C1 11.6745 1 11.9774 1.1198 12.1177C1.22374 12.2394 1.37967 12.304 1.53923 12.2914C1.72312 12.277 1.93731 12.0628 2.3657 11.6344L6.4343 7.5658C6.6323 7.3678 6.7313 7.2687 6.7684 7.1546C6.8011 7.0542 6.8011 6.946 6.7684 6.8456C6.7313 6.7314 6.6323 6.6324 6.4343 6.4344L2.3657 2.3658C1.93731 1.9374 1.72312 1.7232 1.53923 1.7087C1.37967 1.6962 1.22374 1.7608 1.1198 1.8825C1 2.0227 1 2.3256 1 2.9314Z" stroke="#5B6BE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
            {el}
             </li>
)

)
}
{TrainArray.map((el,index)=>(
             <li >
             <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none" className={styles.svg3}>
     <path d="M1 2.9314L1 11.0687C1 11.6745 1 11.9774 1.1198 12.1177C1.22374 12.2394 1.37967 12.304 1.53923 12.2914C1.72312 12.277 1.93731 12.0628 2.3657 11.6344L6.4343 7.5658C6.6323 7.3678 6.7313 7.2687 6.7684 7.1546C6.8011 7.0542 6.8011 6.946 6.7684 6.8456C6.7313 6.7314 6.6323 6.6324 6.4343 6.4344L2.3657 2.3658C1.93731 1.9374 1.72312 1.7232 1.53923 1.7087C1.37967 1.6962 1.22374 1.7608 1.1198 1.8825C1 2.0227 1 2.3256 1 2.9314Z" stroke="#5B6BE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
            {el}
             </li>
)

)
}
        </ul>
        </div>
      </div>
      <div className={styles.maindivbetabeta}>
        <h3 style={{marginTop:"20px", marginLeft:"20px"}}> Live Projects  <NavLink to="/workshopprojects"><img src={redirecticon}  height="20px" width="20px" /></NavLink></h3>
        {projects.map((el,index)=>(
             <div style={{ background:"white", marginLeft:"20px", marginTop:"20px", marginRight:"20px",height:"50px",borderRadius:"10px"}}>
                             <h6 style={{  marginLeft:"20px", marginTop:"10px", display:"inline-flex"}} >{el.Design}</h6>
             <h6 style={{  marginLeft:"20px", marginTop:"10px", display:"inline-flex"}} >{el.Project_Name}</h6>
             <h6 style={{  marginLeft:"20px", marginTop:"10px", display:"inline-flex"}} >{el.Project_Description}</h6>
             <h6 style={{  marginLeft:"20px", marginTop:"10px" ,display:"inline-flex"}} > {el.Scope}</h6>
             </div>
)

)
}
        </div>
      </div>
    </>
  );
}

export default Admintaskbar;
