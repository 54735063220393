import React, { useState } from "react";
import axios from "axios";
import UploadDocument from "./Uploaddocument";
import styles from "../Components/css/rigdetails.module.css";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const EditToolsComponent = ({
  Item_No,
  Description,
  Manufacturer,
  Model,
  Serial_No,
  Cal_Date,
  Due_Date,
  Range_Value,
  Nominal_Value,
  Measured_Value,
  Acceptance_Criteria,
  Frequency,
  Cert_No,
  Status,
  Remarks,
  tools,
  setEnableEdit,
  enableEdit,
}) => {
  const [checked, setChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({
    Item_No: Item_No,
    Description: Description,
    Manufacturer: Manufacturer,
    Model: Model,
    Serial_No: Serial_No,
    Cal_Date: Cal_Date,
    Due_Date: Due_Date,
    Range_Value: Range_Value,
    Nominal_Value: Nominal_Value,
    Measured_Value: Measured_Value,
    Acceptance_Criteria: Acceptance_Criteria,
    Frequency: Frequency,
    Cert_No: Cert_No,
    Status: Status,
    Remarks: Remarks,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStopClose = (e) => {
    e.stopPropagation();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    closePopup();
    const res = await axios.put(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatetools/" + tools,
      formData
    );
    const data = await res.data;
    console.log(data);
  };

  // _______________________________to uncheck radio button
  const closePopup = () => {
    setChecked(!checked);
    handleUncheckRadio();
    setEnableEdit(!enableEdit);
  };
  const handleRadioOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleUncheckRadio = () => {
    setSelectedOption("");
  };
  // ______________________________________________________

  return (
    <div>
      <div className={styles.editRadioButtonRig}>
        <input
          type="radio"
          name="rig"
          value="select"
          checked={selectedOption === "select"}
          onChange={handleRadioOptionChange}
          onClick={() => setChecked(!checked)}
        />
      </div>
      {checked && (
        <>
          <div
            className={styles.popupContainer}
            onClick={(e) => {
              e.stopPropagation();
              setChecked(!checked);
              handleUncheckRadio();
            }}>
            <div className={styles.popUp} onClick={handleStopClose}>
              <form onSubmit={handleSubmit}>
                {/* <label>
              S.NO
              <input
                type="text"
                name="s_no"
                value={formData.rig_name}
                onChange={handleInputChange}
              />
            </label> */}
                <label className={styles.popupItemss}>
                  ItemNo :
                  <input
                    type="text"
                    name="Item_No"
                    value={formData.Item_No}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Description :
                  <input
                    type="text"
                    name="Description"
                    value={formData.Description}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Manufacturer :
                  <input
                    type="text"
                    name="Manufacturer"
                    value={formData.Manufacturer}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Model:
                  <input
                    type="text"
                    name="Model"
                    value={formData.Model}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Serial No
                  <input
                    type="text"
                    name="Serial_No"
                    value={formData.Serial_No}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Cal Date:
                  <input
                    type="text"
                    name="Cal_Date"
                    value={formData.Cal_Date}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Due Date:
                  <input
                    type="text"
                    name="Due_Date"
                    value={formData.Due_Date}
                    onChange={handleInputChange}
                  />
                </label>

                <label className={styles.popupItemss}>
                  Range Value:
                  <input
                    type="text"
                    name="Range_Value"
                    value={formData.Range_Value}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Nominal Value:
                  <input
                    type="text"
                    name="Nominal_Value"
                    value={formData.Nominal_Value}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Measured Value :
                  <input
                    type="text"
                    name="Measured_Value"
                    value={formData.Measured_Value}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Acceptance Range:
                  <input
                    type="text"
                    name="Acceptance_Criteria"
                    value={formData.Acceptance_Criteria}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Frequency:
                  <input
                    type="text"
                    name="Frequency"
                    value={formData.Frequency}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Cert No:
                  <input
                    type="text"
                    name="Cert_No"
                    value={formData.Cert_No}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Status:
                  <input
                    type="text"
                    name="Status"
                    value={formData.Status}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Remarks:
                  <input
                    type="text"
                    name="Remarks"
                    value={formData.Remarks}
                    onChange={handleInputChange}
                  />
                </label>
                <br />
                <button type="submit" className={styles.SubmitButton}>Submit</button>
                <button onClick={closePopup} className={styles.CancelButton}>Cancel</button>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditToolsComponent;
