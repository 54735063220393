import React from "react";
import styles from "./css/IncomingDeliveries.module.css";
import styles1 from "./css/inventoryutilizationlogs.module.css"
import { useEffect, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import UploadDocument from "./Uploaddocument";
import axios from "axios";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import BasicModal from "./Modal";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function ConsumablesWorkshop() {
  const { id } = useParams();
  const [workshop, setWorkshop] = useState(12);
  const [projectList, setProjectList] = useState({});
  const [featurewsinventoryused, setfeaturewsinventoryused] = useState([]);
  const navigate = useNavigate();
  const handleDivClick = () => {
    navigate(`/project-checklist-workshop/${id}`);
  };

  const handleDivClick1 = () => {
    navigate(`/project-details-workshop/${id}`);
  };


  const handleDivClick2 = () => {
    navigate(`/timesheet-workshop/${id}`);
  };

  const handleDivClick3 = () => {
    navigate(`/incoming-deliveries-workshop/${id}`);
  };

  const handleDivClick14 = () => {
    navigate(`/project-timeline-workshop/${id}`);
  }
  const handleDivClick4 = () => {
    navigate(`/outgoing-deliveries-workshop/${id}`);
  };

  const handleDivClick5 = () => {
    navigate(`/outsourcing-workshop/${id}`);
  };

  const handleDivClick6 = () => {
    navigate(`/reports-workshop/${id}`);
  };

  const handleDivClick7 = () => {
    navigate(`/inspections-workshop/${id}`);
  };
  const handleDivClick8 = () => {
    navigate(`/items-received-workshop/${id}`);
  };
  const handleDivClick9 = () => {
    navigate(`/finished-goods-workshop/${id}`);
  };
  const handleDivClick10 = () => {
    navigate(`/team-details-workshop/${id}`);
  };
  const handleDivClick12 = () => {
    navigate(`/inventory-used-workshop/${id}`);
  };
  const handleDivClick13 = () => {
    navigate(`/purchases-workshop/${id}`);
  };
  const handleDivClick15 = () => {
    navigate(`/production-workshop/${id}`)
  }

  const handleDivClick16 = () => {
    navigate(`/Wip-workshop/${id}`);
  };
  const handleDivClick17 = () => {
    navigate(`/dispatch/${id}`);
  };
  const handleDivClick23 = () => {
    navigate(`/item-details-workshop/${id}`);
  };
  const [inventoryUtilData, setInventoryUtilData] = useState([]);
  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );

      const filter = res.data.data.filter((el) => el.ID_No == id);
      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PO_Date: filter[0]?.PODate,
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
      });

      const res1 = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_projectSO_get?Project_SO=${filter[0]?.Sales_Order_No}`
      );
      console.log("inventory used", res1.data.data);
      const inventoryUtilRes = res1.data.data;

      const updatedItemsReceived = await Promise.all(inventoryUtilRes.map(async (item) => {
        const ergonBOERes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_get_ErgonBOE?Incoming_Delivery_ID=${item.Incoming_Delivery_ID}`
        );
        console.log("ergonBOERes", ergonBOERes);
        return {
          ...item,
          Entry_Bill_No: ergonBOERes.data.data[0]?.Entry_Bill_No,
        };
      }));

      console.log("Updated itemsReceived", updatedItemsReceived);

      // Fetch consumption details
      const consumptionRes = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_used_consumption_details?Project_ID=${id}`
      );

      const consumptionDetails = consumptionRes.data.data;

      // Add Qty_Used to each item in updatedItemsReceived
      const updatedItemsWithConsumption = updatedItemsReceived.map(item => ({
        ...item,
        Qty_Used: 0, // Initialize Qty_Used with 0
      }));

      // Process each unique item and assign Qty_Used
      const itemConsumptionMap = {};

      consumptionDetails.forEach(consumptionItem => {
        const key = `${consumptionItem.Item_Name}_${consumptionItem.Part_Number}`;
        if (!itemConsumptionMap[key]) {
          itemConsumptionMap[key] = parseFloat(consumptionItem.Qty_Consumed) || 0;
        } else {
          itemConsumptionMap[key] += parseFloat(consumptionItem.Qty_Consumed) || 0;
        }
      });

      updatedItemsWithConsumption
        .sort((a, b) => new Date(b.Date_Of_Utilization) - new Date(a.Date_Of_Utilization))
        .forEach(updatedItem => {
          const key = `${updatedItem.Item_Name}_${updatedItem.Part_Number}`;
          if (itemConsumptionMap[key] && itemConsumptionMap[key] > 0) {
            const qtyUtilized = parseFloat(updatedItem.Qty_Utilized) || 0;
            const qtyToAssign = Math.min(qtyUtilized, itemConsumptionMap[key]);
            updatedItem.Qty_Used += qtyToAssign;
            itemConsumptionMap[key] -= qtyToAssign;
          }
        });

      setInventoryUtilData(updatedItemsWithConsumption);
      console.log("updatedItemsWithConsumption", updatedItemsWithConsumption);


      // setInventoryUtilData(updatedItemsReceived);

      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  // Sort the inventoryUtilData array by Date_Of_Utilization in descending order
  const sortedData = inventoryUtilData.sort((a, b) => {
    return new Date(b.Date_Of_Utilization) - new Date(a.Date_Of_Utilization);
  });

  function formatDate(inputDate) {
    //console.log(inputDate);
    if (inputDate == null || inputDate == "") {
      return "-";
    }

    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      return "-";
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );
        console.log("I  am here");
        console.log(response);

        const workshopinventoryusedfeature = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Inventory Used" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        })
        setfeaturewsinventoryused(workshopinventoryusedfeature);

      } catch (error) {
        console.error(error)
      }
    }
    fetchRigDetails();
  }, [])

  return (
    featurewsinventoryused.length > 0 ? (
      <div>
        <div className={styles.topdiv}>
          <label className={styles.aryl1}>
            Project Name: {formdata1.ProjectName}
          </label>
          <label className={styles.aryl}>Project Scope:{formdata1.Scope}</label>
          <label className={styles.aryl}>
            Project Description:{formdata1.projectDescription}
          </label>
          <label className={styles.aryl}>Quantity:{formdata1.quantity}</label>
          <label className={styles.aryl}>
            Target Days:{formdata1.TargetDaysForCompletion}
          </label>
          <label className={styles.aryl}>
            Target Manhours:{formdata1.TargetManhours}
          </label>
        </div>
        <div className={styles.aryTabs}>
          <div
            className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick}>
            Project Checklist
          </div>
          <div
            className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick1}>
            Project Details
          </div>
          <div
            className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick10}
          >
            Team Details
          </div>
          <div
            className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick12}>
            Inventory Used
          </div>
          <div
            className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick13}>
            Purchases
          </div>
          <div
            className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick2}>
            Timesheet
          </div>
          <div
            className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick3}>
            Deliveries
          </div>
          <div
            className={workshop === 23 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick23}>
            Serial Number
          </div>
          <div
            className={workshop === 15 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick15}>
            Production
          </div>

          {/* <div
          className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick4}>
          Outgoing Deliveries
        </div> */}
          <div
            className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick14}>
            Timeline
          </div>
          {/* <div
            className={workshop === 17 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick17}>
            Dispatch
          </div> */}
          <div
            className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick5}>
            Outsourcing
          </div>
          <div
            className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick6}>
            Reports
          </div>
          <div
            className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick7}>
            Inspections
          </div>
          <div
            className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick8}>
            Items Received
          </div>
          <div
            className={workshop === 11 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick9}
          >
            Finished goods
          </div>
          <div
            className={workshop === 16 ? styles.aryTabs1 : styles.aryTabs2}
            onClick={handleDivClick16}
          >
            WIP
          </div>
        </div>
        {workshop === 1 && <></>}
        {workshop === 2 && <></>}
        {workshop === 3 && <></>}
        {workshop === 4 && <></>}
        {workshop === 5 && <></>}
        {workshop === 6 && <></>}
        {workshop === 7 && <></>}
        {workshop === 8 && <></>}
        {workshop === 9 && <></>}

        <div className={styles1.sections2} style={{ marginBottom: "5px", paddingTop: "10px" }}>
          <div className={styles1.smtablecontainer}>
            {sortedData.length === 0 ? (
              <h4 style={{ textAlign: "center", fontWeight: "700", marginTop: "15px", color: "#567191" }}>No result found</h4>
            ) : (
              <table className={styles1.smtable}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Item Name</th>
                    <th>Part Number</th>
                    <th>UoM</th>
                    <th>BOE</th>
                    <th>Utilization Date from Stock</th>
                    <th>Balance Qty</th>
                    <th>Qty taken from Inventory</th>
                    <th>Qty Utilized in Projects</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.map((item, index) => (
                    <tr key={item.S_No}>
                      <td>{index + 1}</td>
                      <td>{item.Item_Name || "-"}</td>
                      <td>{item.Part_Number || "-"}</td>
                      <td>{item.UoM || "-"}</td>
                      <td>{item.Entry_Bill_No || "-"}</td>
                      <td>{formatDate(item.Date_Of_Utilization) || "-"}</td>
                      <td>{(parseFloat(item.Qty_Utilized) - parseFloat(item.Qty_Used)) ? (parseFloat(item.Qty_Utilized) - parseFloat(item.Qty_Used)) : "0"}</td>
                      <td>{item.Qty_Utilized || "-"}</td>
                      <td>{item.Qty_Used || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div style={{ marginLeft: "30%", marginTop: "15%" }}>
        <h3>
          You do not have permissions to view this page.
        </h3>
      </div>
    )
  );
}
export default ConsumablesWorkshop;