import React from 'react'
import Erogonlogo from "../Components/Erogonlogo.png"
import coclogo1 from "../Components/coclogo1.jpeg"
import coclogo2 from "../Components/coclogo2.jpeg"
import coclogo3 from "../Components/coclogo3.jpeg"
import coclogo4 from "../Components/coclogo4.jpeg"
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
function Cocform() {
  return (
    <div>
        <div>
        <div>
            <img src={Erogonlogo} className="ergonlogo2" height="59" width="279" style={{textAlign:"center",marginLeft:"50px", marginTop:"85px"}}  />
            </div>

            <div>
                <h6 style={{marginLeft:"109px",marginTop:"20px"}}>Issuance Date : 25th May 2023</h6>
                <h6 style={{marginLeft:"109px"}}> Certificate No : TSD-COC-129-22</h6>
            </div>
            <div>
                <h3 style={{marginTop:"20px",marginLeft:"395px"}}><u>Certificate of Conformance</u></h3></div>

                <table style={{border:"2px solid black",width:"900px",marginLeft:"155px"}}>
                    <tr>
                        <td style={{width:"300px"}}>Customer Name</td>
                        <td>Shelf Drilling</td>
                    
                    </tr>

                    <tr>
                        <td style={{width:"300px"}}>Rig Name</td>
                        <td>C.E. Thornton</td>
                    
                    </tr>

                    <tr>
                        <td style={{width:"300px"}}>Equipment</td>
                        <td>Crane System</td>
                    
                    </tr>

                    <tr>
                        <td style={{width:"300px"}}>Equipment Make</td>
                        <td>LeTourneau PCM 120 AS Crane</td>
                    
                    </tr>

                    <tr>
                        <td style={{width:"300px"}}>Equipment Serial Number</td>
                        <td>Port-#1313, STBD-#1368</td>
                    
                    </tr>

                    <tr>
                        <td style={{width:"300px"}}>PO Number</td>
                        <td>10394534</td>
                    
                    </tr>
                    
                </table>

                <h6 style={{marginLeft:"149px",marginTop:"30px"}}><u><b>Scope of Work</b></u></h6>
                <p style={{marginLeft:"149px"}}>CAT IV, 5-yearly Overhaul of STBD and Port Crane on Shelf Drilling’s Rig C.E. Thornton</p>

                <h6 style={{marginLeft:"149px",marginTop:"30px"}}><u><b>Job Description & Reference Procedure</b></u></h6>
                <p style={{marginLeft:"149px"}}>A complete overhaul of the STBD and Port Crane was carried out under the following procedures and recommended practices:</p>

                <p  style={{marginLeft:"149px"}}>1. API Standard Q1, 9th edition,2019- Specification for Quality Management System Requirements for Manufacturing Organizations for the Petroleum and Natural gas industry<br/>

2. API Q2, 2nd edition, 2022- Specification for Quality Management System requirements of service supply organizations for the Petroleum and Natural Gas Industries<br/>

3. API 18 LCM, 1st edition, 2022- Product Lifecycle Management System Requirements for the Petroleum and Natural Gas Industries</p>



                  <h6 style={{marginLeft:"149px",marginTop:"30px"}}><u><b>Recommendation:</b></u></h6>
                <p style={{marginLeft:"149px"}}>Upon completion of the defined scope of work, Both STBD and Port Cranes were found to be satisfactory and <br/>
                    meet all operational requirements. It is recommended that the equipment should be monitored &<br/>
                     maintained under Shelf Drilling’s Preventative Maintenance Management System for sustained performance.</p>

                  <p style={{marginLeft:"149px"}}>This certificate is issued at the request of Shelf Drilling and is based on the condition of the equipment at the time<br/>
                   of the inspection. This certificate bears no liability on Ergon Technologies in any form</p>


                   <h6 style={{textAlign:"center"}}><u><b>Authorized Signatory</b></u></h6>
                    

                <div style={{display:"flex"}}> 
                   <h6 style={{textAlign:"left",marginLeft:"149px"}}><b>Name:</b></h6>
                   <h6 style={{textAlign:"center",marginLeft:"490px"}}><b>Signature:</b></h6>
                   <h6 style={{textAlign:"right",marginLeft:"490px"}}><b>Stamp:</b></h6>

                 </div>

                 <p style={{marginTop:"30px",marginLeft:"149px"}}><b>The Integrated management system of Ergon Technologies is in conformance to API Standard 18LCM (Registration No. <br/>
                  18LCM-0004), API Spec Q1, 9th Edition (Registration No.Q1-4738), API Spec Q2, 2nd Edition (Registration No.
                   <br/> Q2-0377), British Safety Council member (number -S0445570) and ISO 9001:2015 (#60433), 14001:2015 (#60434), 
                    <br/>45001:2018(#60435).</b></p>

                    <img src={coclogo1} style={{marginLeft:"149px"}}></img>
                    
                    <img src={coclogo2} style={{marginLeft:"5px"}}></img>
                    <img src={coclogo3} style={{marginLeft:"5px"}}></img>
                    <img src={coclogo4} style={{marginLeft:"5px"}}></img>




        </div>

         <footer>
         <div>
                <div style={{textAlign:"left",marginTop:"280px"}}>TSD-OPS-FOR-022-21, Rev.1</div>
                <div style={{textAlign:"center",marginTop:"-20px"}}>30 Apr 2022</div>
            </div>
                <div style={{textAlign:"right",marginTop:"-20px"}}>1</div>

         </footer>

    </div>
  )
}

export default Cocform;