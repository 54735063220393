import React from "react";
import styles from "./css/CustomerPropertyLogs_details.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function CustomerLogsDetails() {
  const [isIncoming, setIsIncoming] = useState(true);
  const [isOutgoing, setIsOutgoing] = useState(false);
  const [isProduction, setIsProduction] = useState(false);
  const [incomingDeliveryIds, setIncomingDeliveryIds] = useState([]);
  const [dispatchingParties, setDispatchingParties] = useState({});
  const [boe, setBoe] = useState({});
  const { Item_Name, Item_Id, part_number } = useParams();
  const handleIncomingClick = () => {
    setIsIncoming(true);
    setIsOutgoing(false);
    setIsProduction(false);
  };

  const handleOutgoingClick = () => {
    setIsOutgoing(true);
    setIsIncoming(false);
    setIsProduction(false);
  };
  const handleProductionClick = () => {
    setIsIncoming(false);
    setIsOutgoing(false);
    setIsProduction(true);
  };
  const [itemsMaster, setItemsMaster] = useState([]);
  const [itemsReceived, setItemsReceived] = useState([]);
  const [itemsReceived2, setItemsReceived2] = useState([]);
  const [itemsReceived3, setItemsReceived3] = useState([]);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/customerpropertylogs`);
  };

  const [IDNumbers, setIDNumbers] = useState([]);

  const [workshopProjectDetails, setWorkshopProjectDetails] = useState([]);
  const [finalSalesOrderNo, setFinalSalesOrderNo] = useState("");

  const [itemQuantitySum, setItemQuantitySum] = useState({});
  const [itemProducedSum, setItemProducedSum] = useState({});

  useEffect(() => {
    const processItemsReceived = () => {
      let result = {};

      itemsReceived2.forEach((item) => {
        const { Item_Name, Part_Number, Qty_Consumed } = item;
        const qtyConsumedParsed = parseInt(Qty_Consumed) || 0;
        const key = `${Item_Name}_/${Part_Number}`;

        if (result[key]) {
          result[key] += qtyConsumedParsed;
        } else {
          result[key] = qtyConsumedParsed;
        }
      });

      setItemQuantitySum(result);
    };

    processItemsReceived();
  }, [itemsReceived2]);


  useEffect(() => {
    const processItemsReceived = () => {
      let result = {};

      itemsReceived3.forEach((item) => {
        const { Item_Name, Part_Number, Qty_Produced } = item;
        const qtyConsumedParsed = parseInt(Qty_Produced) || 0;
        const key = `${Item_Name}_/${Part_Number}`;

        if (result[key]) {
          result[key] += qtyConsumedParsed;
        } else {
          result[key] = qtyConsumedParsed;
        }
      });

      setItemProducedSum(result);
    };

    processItemsReceived();
  }, [itemsReceived3]);


  useEffect(() => {
    async function fetchDetails() {
      try {
        // Fetch data from the first API
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get3?Item_Name=${Item_Name}&part_number=${part_number}`
        );
        const idNumbers = res1.data.data.map(
          (item) => item.Incoming_Delivery_ID
        );
        setItemsReceived(res1.data.data);
        setIDNumbers(idNumbers);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, [Item_Name, part_number]);

  useEffect(() => {
    async function fetchDetails() {
      try {
        // Fetch data from the first API
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_outgoing_get?Item_Name=${Item_Name}&part_number=${part_number}`
        );

        const filtereditems = res1.data.data.filter((item) => {
          return item.Qty_Consumed !== "0";
        });

        setItemsReceived2(filtereditems);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, [Item_Name, part_number]);

  useEffect(() => {
    async function fetchDetails() {
      try {
        // Fetch data from the first API
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_production_get?Item_Name=${Item_Name}&part_number=${part_number}`
        );

        const filtereditems = res1.data.data.filter((item) => {
          return item.Qty_Produced !== "0";
        });

        setItemsReceived3(filtereditems);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, [Item_Name, part_number]);

  useEffect(() => {
    async function fetchSalesOrderNo() {
      try {
        // Fetch data from the second API
        const res2 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details`
        );
      
        setWorkshopProjectDetails(res2.data.data); // Access res2.data.data

        // Ensure that res2.data.data is an array before using array methods
        if (Array.isArray(res2.data.data)) {
          // Create an object to store Sales_Order_No for each ID_Number
          const salesOrderNos = {};
          res2.data.data.forEach((item) => {
            if (IDNumbers.includes(item.Project_Id)) {
              // If ID_Number already exists in salesOrderNos, append the Sales_Order_No
              // Otherwise, create a new entry with the Sales_Order_No
              salesOrderNos[item.ID_No] = salesOrderNos[item.Project_Id]
                ? [...salesOrderNos[item.Project_Id], item.Sales_Order_No]
                : [item.Sales_Order_No];
            }
          });
        

          // Set the finalSalesOrderNo state with the object
          setFinalSalesOrderNo(salesOrderNos);
        } else {
          console.error(
            "Data received from API is not an array:",
            res2.data.data
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchSalesOrderNo();
  }, [IDNumbers]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   async function fetchIncomingDeliveryIds() {
  //     try {
  //       // Fetch data from the API
  //       const res = await axios.get(
  //         `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get3?Item_Name=?${Item_Name} & part_number=${part_number}`
  //       );
  //       console.log("Items received:", res.data.data);

  //       // Extract Incoming_Delivery_Id and corresponding S_No (which is Incoming_Delivery_Id)
  //       const incomingDeliveryIds = res.data.data.map(
  //         (item) => item.Incoming_Delivery_ID
  //       );
  //       console.log("Incoming Delivery Ids:", incomingDeliveryIds);

  //       // Store Incoming_Delivery_Id in state
  //       setIncomingDeliveryIds(incomingDeliveryIds);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   fetchIncomingDeliveryIds();
  // }, [Item_Name,part_number]);

  useEffect(() => {
    async function fetchDispatchingParties() {
      try {
        // Fetch data from the API
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries`
        );
    
        const workshopProjectDetails = res.data.data;
       

        // Ensure that workshopProjectDetails is an array before using array methods
        if (Array.isArray(workshopProjectDetails)) {
          // Create objects to store Dispatching_Party and Entry_Bill_No for each S_No (Incoming_Delivery_Id)
          const dispatchingParties = {};
          const boe = {};

          workshopProjectDetails.forEach((item) => {
            if (IDNumbers.includes(item.S_No)) {
              // If S_No already exists in dispatchingParties, append the Dispatching_Party
              // Otherwise, create a new entry with the Dispatching_Party
              dispatchingParties[item.S_No] = dispatchingParties[item.S_No]
                ? [...dispatchingParties[item.S_No], item.Dispatching_Party]
                : [item.Dispatching_Party];

              boe[item.S_No] = item.Entry_Bill_No;
            }
          });

       

          // Set the state with the objects
          setDispatchingParties(dispatchingParties);
          setBoe(boe);
        } else {
          console.error(
            "Data received from API is not an array:",
            workshopProjectDetails
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchDispatchingParties();
  }, [IDNumbers]);


  const totalQuantityReceived = itemsReceived.reduce(
    (total, item) => {
      const quantity = item.Quantity_Received ? parseInt(item.Quantity_Received) : 0;
      return total + quantity;
    },
    0
  );
  

  // Get the specific item quantity sum
  const specificItemQuantitySum = itemQuantitySum[
    `${Item_Name}_/${part_number}`
  ]
    ? itemQuantitySum[`${Item_Name}_/${part_number}`]
    : 0;
  const specificItemProducedSum = itemProducedSum[
    `${Item_Name}_/${part_number}`
  ]
    ? itemProducedSum[`${Item_Name}_/${part_number}`]
    : 0;

  // Perform the subtraction
  const availableQuantity =
  (totalQuantityReceived - (specificItemQuantitySum ? specificItemQuantitySum : 0)) + (specificItemProducedSum ? specificItemProducedSum : 0);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div style={{ marginTop: "115px", marginLeft: "1%", marginRight: "1%" }}>
      <div>
        <button className={styles.mybutton} onClick={handleClick}>
          &lt; Transaction History
        </button>
      </div>
      <div>
        <div className={styles.box}>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Item Name: {Item_Name}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Part Number: {part_number !== "null" ? part_number : "-"}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Available Quantity: {availableQuantity}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Units: {itemsReceived.length > 0 ? itemsReceived[0].UOM : ""}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Item ID: {Item_Id}
            </label>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "15px" }}>
        <button
          className={`${styles["incomingButton"]} ${
            isIncoming ? styles["incoming"] : styles["not-incoming"]
          }`}
          onClick={handleIncomingClick}
        >
          Incoming
        </button>
        <button
          className={`${styles["outgoingButton"]} ${
            isOutgoing ? styles["outgoing"] : styles["not-outgoing"]
          }`}
          onClick={handleOutgoingClick}
        >
          Outgoing
        </button>
        <button
          className={`${styles["productionButton"]} ${
            isProduction ? styles["production"] : styles["not-production"]
          }`}
          onClick={handleProductionClick}
        >
          Production
        </button>
      </div>
      <div className={styles.sections}>
        {isIncoming && (
          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>SO No.</th>
                  <th>Arrival Date</th>
                  <th>Dispatching Party</th>
                  <th>Entry Bill No.</th>
                  <th>Incoming Delivery ID</th>
                  <th>Incoming Inspection ID</th>
                  <th>Qty Received</th>
                  <th>UoM</th>
                  <th>Traceability Number</th>
                  <th>Manufacturer Part Number</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {" "}
                {itemsReceived.length === 0 ? (
                  <tr>
                    <td colSpan="11">No result found</td>
                  </tr>
                ) : (
                  itemsReceived?.map((item, index) => (
                    <tr key={item.S_No}>
                      <td>{index + 1}</td>
                      <td>
                        {/* Access the corresponding Sales_Order_No from finalSalesOrderNo object */}
                        <a href={`/project-checklist-workshop/${item.Project_Id}`}>{finalSalesOrderNo[item.Project_Id]
                          ? finalSalesOrderNo[item.Project_Id].join(", ")
                          : ""}</a>
                      </td>
                      <td>{item.Arrival_Date}</td>
                      <td>
                        {dispatchingParties[item.Incoming_Delivery_ID]
                          ? dispatchingParties[item.Incoming_Delivery_ID].join(
                              ", "
                            )
                          : ""}
                      </td>
                      <td>
                        {boe[item.Incoming_Delivery_ID]
                          ? boe[item.Incoming_Delivery_ID]
                          : ""}
                      </td>
                      <td>{item.Incoming_Delivery_ID}</td>
                      <td>{item.Incoming_Inspection_ID}</td>
                      <td>{item.Quantity_Received}</td>
                      <td>{item.UOM}</td>
                      <td>{item.Traceability_Number}</td>
                      <td>{item.manufaturer_no}</td>
                      <td>{item.Location}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
        {isOutgoing && (
          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>SO No.</th>
                  <th>Qty Utilized</th>
                  <th>UoM</th>
                  <th>Utilization Date </th>
                  <th>Production ID</th>

                  <th>Material Converted to</th>
                  <th>BOE</th>
                  <th>Traceability No.</th>
                </tr>
              </thead>
              <tbody>
                {" "}
                {itemsReceived2.length === 0 ? (
                  <tr>
                    <td colSpan="9">No result found</td>
                  </tr>
                ) : (
                  itemsReceived2.map((item, index) => (
                    <tr key={item.S_No}>
                      <td>{index + 1}</td>
                      <td>{item.Project_SO}</td>
                      <td>{item.Qty_Consumed}</td>
                      <td>{item.UoM}</td>
                      <td>{item.Date}</td>
                      <td>{item.Production_ID}</td>
                      <td>{item.Production_Type}</td>
                      <td>
                        {boe[item.Incoming_Delivery_ID]
                          ? boe[item.Incoming_Delivery_ID]
                          : ""}
                      </td>

                      <td>{item.Traceability_Number}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
        {isProduction && (
          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>SO No.</th>
                  <th>Production Date </th>
                  <th>Production ID</th>
                  <th>Qty Produced</th>
                  <th>UoM</th>
                  <th>Material Converted to</th> 
                  <th>Equipment Traceability No.</th>
                  <th>Part Traceability No.</th>
                  <th>Ergon Traceability No.</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {itemsReceived3.length === 0 ? (
                  <tr>
                    <td colSpan="11">No result found</td>
                  </tr>
                ) : (
                  itemsReceived3.map((item, index) => (
                    <tr key={item.S_No}>
                      <td>{index + 1}</td>
                      <td>{item.Project_SO}</td>
                      <td>{item.Date}</td>
                      <td>{item.Production_ID}</td>
                      <td>{item.Qty_Produced}</td>
                      <td>{item.UoM}</td>
                      <td>{item.Production_Type}</td>
                      
                      <td>{item.Equipment_Traceability_Number}</td>
                      <td>{item.Part_Traceability_Number}</td>
                      <td>{item.Ergon_Traceability_Number}</td>
                      <td>{item.Location}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomerLogsDetails;
