import React from "react";
import TabsBarSparesInquiry from './TabsBarSparesInquiry';
import styles from "./css/spares_inquiry.module.css";
import styles1 from "./css/projectchecklistworkshop.module.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";

export default function SparesInquiryChecklist() {
    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [isExpanded1, setIsExpanded1] = useState(true);
    const [checkListData, setCheckListData] = useState({});
    // const [originalcheckListData, setoriginalCheckListData] = useState({});
    const [refresh, setRefresh] = useState(true);

    const [formData, setFormData] = useState({
        Customer_Inquiry_No: "",
        MOC: "",
        Inquiry_Date: "",
        Expiry_Inquiry_Date: "",
        Buyer_POC: "",
        Rig_Name: "",
        Customer_Name: "",
        Category: "",
        Description: "",
        Upload_Inquiry_Received: "",
        Suppliers_Contacted: "",
        Current_Status: "",
        Quote_No: "",
        PO_No: ""
    });
    const [selectValues1, setSelectValues1] = useState({
        item1: "",
        item2: "",
        item3: "",
        item4: "",
        item5: "",
    });
    const [inputValues1, setInputValues1] = useState({
        issue1: "",
        issue2: "",
        issue3: "",
        issue4: "",
        issue5: "",
    });

    //   const [activeStep, setActiveStep] = useState(0);

    const handleSelectChange1 = (e) => {
        const { name, value } = e.target;
        // step1IsActive();
        setSelectValues1({
            ...selectValues1,
            [name]: value,
        });
    };

    //   const step1IsActive = () => {
    //     setActiveStep(0);
    //   };

    const toggleDropdown1 = () => {
        setIsExpanded1(!isExpanded1);
        console.log("IsExpanded", isExpanded1)
    };

    const fetchData = async () => {
        try {



            const response = await axios.get(
                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_InquiryDetails?ID_No=${id}`
            );

            if (response.data.success) {

                const data = response.data.data[0];

                setFormData({
                    Customer_Inquiry_No: data.Inquiry_No,
                    MOC: data.MOC,
                    Inquiry_Date: data.Inquiry_Date,
                    Expiry_Inquiry_Date: data.Inquiry_Expiry_Date,
                    Buyer_POC: data.Buyer_POC,
                    Rig_Name: data.Rig_Name,
                    Customer_Name: data.Customer_Name,
                    Category: data.Category,
                    Description: data.Description,
                    Upload_Inquiry_Received: data.Inquiry_File,
                    Suppliers_Contacted: data.Suppliers_Contacted,
                    Current_Status: data.Current_Status,
                    Quote_No: data.Quote_No,
                    PO_No: data.PO_No,
                });

            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getChecklistData = async () => {
        try {
            const res = await axios.get(
                "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inquiry-checklistgetdetails"
            );



            const filter = res.data.data.filter((el) => el.ID_No == id);



            setInputValues1({
                issue1: filter[0]?.Issue1,
                issue2: filter[0]?.Issue2,
                issue3: filter[0]?.Issue3,
                issue4: filter[0]?.Issue4,
                issue5: filter[0]?.Issue5,
            });



            setSelectValues1({
                item1: filter[0]?.Item1,
                item2: filter[0]?.Item2,
                item3: filter[0]?.Item3,
                item4: filter[0]?.Item4,
                item5: filter[0]?.Item5,

            });




            setCheckListData(filter[0]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }

    };




    useEffect(() => {
        getChecklistData()
        fetchData();
    }, [id, refresh]);

    const handleInputValueChange1 = (e) => {
        const { name, value } = e.target;
        setInputValues1({
            ...inputValues1,
            [name]: value,
        });
    };

    const renderStatusSelect1 = (selectFor) => {
        return (
            <div className={styles1.selectContainer}>
                <select
                    className={styles1.notdisabledInput}
                    value={selectValues1[selectFor] || ""}
                    name={selectFor}
                    onChange={handleSelectChange1}>
                    <option value="">Select</option>
                    {/* <option value="Yet to Start">Yet to Start</option> */}
                    <option value="In Progress">In Progress</option>
                    <option value="Facing Issues">Facing Issues</option>
                    <option value="Completed">Completed</option>
                </select>
                <svg
                    className={styles1.svgSelect}
                    style={{
                        right: "20px",
                    }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
                        fill="#394759"
                    />
                </svg>
            </div>
        );
    };


    const handleCancel = () => {
        setEditMode(false); // Exit edit mode
        setRefresh(!refresh);
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleSave = async () => {
        const {
            item1,
            item2,
            item3,
            item4,
            item5,

        } = selectValues1;



        const { issue1, issue2, issue3, issue4, issue5 } = inputValues1;


        const payload = {

            Item1: item1,
            Item2: item2,
            Item3: item3,
            Item4: item4,
            Item5: item5,


            Issue1: issue1,
            Issue2: issue2,
            Issue3: issue3,
            Issue4: issue4,
            Issue5: issue5,

        };
        // console.log("values",inputValues1, inputValues2, selectValues1, selectValues2);


        const res = await axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inquiry_checklist/update/${id}`,
            payload
        );
        console.log("res", res);
        console.log("Payload", payload);
        handleCancel();


        if (res.status === 200) {
            alert("Form data updated successfully!");
            setRefresh(!refresh);
            setEditMode(false)
            //   getChecklistData();
        }
    }


    return (
        <div>

            <TabsBarSparesInquiry activeTab='Inquiry Checklist' />
            <div className={styles.sections}>
                <div className={styles.buttonsdiv}>
                    {editMode ? (
                        <div style={{ display: "flex" }}>
                            <button
                                variant="contained"
                                onClick={handleSave}
                            >
                                Save Changes
                            </button>
                            <button
                                variant="outlined"
                                onClick={handleCancel}
                                className={styles.cancel}
                            >
                                Cancel
                            </button>
                        </div>
                    ) : (
                        <button
                            variant="contained"
                            onClick={() => handleEdit()}
                        >
                            Edit
                        </button>
                    )}
                </div>
                <div className={styles1.containerDropDowns} onClick={toggleDropdown1}>
                    <h4 className={styles1.mainDropdowns}>1. Inquiry status </h4>
                    <span
                        style={{
                            fontSize: "16px",
                        }}>
                        {isExpanded1 ?
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                                    fill="white"
                                />
                            </svg>
                            :
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                                    fill="white"
                                />
                            </svg>
                        }
                    </span>
                </div>
                {isExpanded1 && (
                    <div className={styles1.contentInsidedropdown}>
                        <table className={styles1.projectChecklistTable}>
                            <thead>
                                <th>Task Number</th>
                                <th>Task description</th>
                                <th>Personnel Responsible</th>
                                <th>Status</th>
                                <th>Remarks</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Create Spares Inquiry</td>
                                    <td>Sandhya</td>
                                    <td>
                                        {editMode ? (
                                            <>{renderStatusSelect1("item1")}</>
                                        ) : (
                                            <div className={styles1.selectContainer}>
                                                <input
                                                    type="text"
                                                    className={styles1.disabledInput}
                                                    value={checkListData?.Item1}
                                                    disabled
                                                />
                                                <svg
                                                    style={{
                                                        right: "20px",
                                                    }}
                                                    className={styles1.svgSelect}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                                                        fill="rgba(57, 71, 89, 0.5)"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                name="issue1"
                                                value={inputValues1.issue1}
                                                onChange={handleInputValueChange1}
                                                className={styles1.notdisabledInput2}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className={styles1.disabledInput2}
                                                value={
                                                    checkListData?.Issue1 ? checkListData?.Issue1 : ""
                                                }
                                                disabled
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Send RFQ to Vendors</td>
                                    <td>Sandhya/Ramadas</td>
                                    <td>
                                        {editMode ? (
                                            <>{renderStatusSelect1("item2")}</>
                                        ) : (
                                            <div className={styles1.selectContainer}>
                                                <input
                                                    type="text"
                                                    className={styles1.disabledInput}
                                                    value={checkListData?.Item2}
                                                    disabled
                                                />
                                                <svg
                                                    style={{
                                                        right: "20px",
                                                    }}
                                                    className={styles1.svgSelect}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                                                        fill="rgba(57, 71, 89, 0.5)"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                name="issue2"
                                                value={inputValues1.issue2}
                                                onChange={handleInputValueChange1}
                                                className={styles1.notdisabledInput2}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className={styles1.disabledInput2}
                                                value={
                                                    checkListData?.Issue2 ? checkListData?.Issue2 : ""
                                                }
                                                disabled
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Add quoted rates from Vendors</td>
                                    <td>Sandhya/Ramadas</td>
                                    <td>
                                        {editMode ? (
                                            <>{renderStatusSelect1("item3")}</>
                                        ) : (
                                            <div className={styles1.selectContainer}>
                                                <input
                                                    type="text"
                                                    className={styles1.disabledInput}
                                                    value={checkListData?.Item3}
                                                    disabled
                                                />
                                                <svg
                                                    style={{
                                                        right: "20px",
                                                    }}
                                                    className={styles1.svgSelect}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                                                        fill="rgba(57, 71, 89, 0.5)"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                name="issue3"
                                                value={inputValues1.issue3}
                                                onChange={handleInputValueChange1}
                                                className={styles1.notdisabledInput2}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className={styles1.disabledInput2}
                                                value={
                                                    checkListData?.Issue3 ? checkListData?.Issue3 : ""
                                                }
                                                disabled
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Create Quote and send to customer</td>
                                    <td>Sandhya/Ramadas</td>
                                    <td>
                                        {editMode ? (
                                            <>{renderStatusSelect1("item4")}</>
                                        ) : (
                                            <div className={styles1.selectContainer}>
                                                <input
                                                    type="text"
                                                    className={styles1.disabledInput}
                                                    value={checkListData?.Item4}
                                                    disabled
                                                />
                                                <svg
                                                    style={{
                                                        right: "20px",
                                                    }}
                                                    className={styles1.svgSelect}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                                                        fill="rgba(57, 71, 89, 0.5)"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                name="issue4"
                                                value={inputValues1.issue4}
                                                onChange={handleInputValueChange1}
                                                className={styles1.notdisabledInput2}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className={styles1.disabledInput2}
                                                value={
                                                    checkListData?.Issue4 ? checkListData?.Issue4 : ""
                                                }
                                                disabled
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Status</td>
                                    <td>Ramadas</td>
                                    <td>
                                        {editMode ? (
                                            <div className={styles1.selectContainer}>
                                                <select
                                                    className={styles1.notdisabledInput}
                                                    value={selectValues1["item5"] || ""}
                                                    name="item5"
                                                    onChange={handleSelectChange1}>
                                                    <option value="">Select</option>
                                                    {/* <option value="Yet to Start">Yet to Start</option> */}
                                                    <option value="Discarded">Discarded</option>
                                                    <option value="Rejected">Rejected</option>
                                                </select>
                                                <svg
                                                    className={styles1.svgSelect}
                                                    style={{
                                                        right: "20px",
                                                    }}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
                                                        fill="#394759"
                                                    />
                                                </svg>
                                            </div>
                                        ) : (
                                            <div className={styles1.selectContainer}>
                                                <input
                                                    type="text"
                                                    className={styles1.disabledInput}
                                                    value={checkListData?.Item5}
                                                    disabled
                                                />
                                                <svg
                                                    style={{
                                                        right: "20px",
                                                    }}
                                                    className={styles1.svgSelect}
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                                                        fill="rgba(57, 71, 89, 0.5)"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                name="issue5"
                                                value={inputValues1.issue5}
                                                onChange={handleInputValueChange1}
                                                className={styles1.notdisabledInput2}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className={styles1.disabledInput2}
                                                value={
                                                    checkListData?.Issue5 ? checkListData?.Issue5 : ""
                                                }
                                                disabled
                                            />
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

        </div>

    );


};