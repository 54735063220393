import React, { createContext, useState, useContext, useEffect } from "react";
import WebSocketService from "./webSocketServices"; // Add missing import statement

// Create a context with a default value (optional)
export const MyContext = createContext(null);

const wsService = new WebSocketService("ws://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002");

const MyProvider = ({ children }) => {
  const [notificationData, setNotificationData] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    wsService.connect(); //connect to web socket

    wsService.on("notificationfrombackend", (data) => {
      console.log("data from backend", data);
      setNotificationData(data.reverse());
    });

    return () => {
      wsService.ws.close();
    };
  }, [token]);

  const sendNotificationToAll = ({
    Project_ID,
    Previous_Commencement_Date,
    New_Commencement_Date,
    Done_By,
    Reason_For_Change,
    Timestamp,
    useremail,
  }) => {
    const eventMessage = {
      Project_ID: Project_ID,
      Previous_Commencement_Date: Previous_Commencement_Date,
      New_Commencement_Date: New_Commencement_Date,
      Done_By: Done_By,
      Reason_For_Change: Reason_For_Change,
      Timestamp: Timestamp,
      useremail: useremail,
    };

    console.log("send data");
    if (wsService) {
      wsService?.send("notificationfromfrontendside", eventMessage);
    }
  };
  return (
    <MyContext.Provider value={{ notificationData, sendNotificationToAll }}>
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;
