import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/dailytrackerinquiry.module.css";
import TabsBarDailyTracker from "./TabsBarDailyTracker.js";
import { NavLink } from "react-router-dom";
import LoadingScreen from './LoadingScreen'

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

const DailyTrackerSparesTrading = () => {
    const [isLoading, setIsLoading] = useState(false); 
    const [activeStatus, setActiveStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [OngoingData, setOngoingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true); // Start loading

            try {
                const response = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/workshop-spares-trading-details');
                setOngoingData(response.data);
                await Promise.all(
                    response.data.map(async (project) => {
                        try {
                            const projectPendingData = await axios.get(
                                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/spares_trading_project_pending_qty_items_get?ID_No=${project.ID_No}&Sales_Order_No=${project.Sales_Order_No}`
                            );
                            const PendingPOData = await axios.get(
                                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/get-entry-count/${project.ID_No}`
                            );
                            const ItemStatusData = await axios.get(
                                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/fetch-items/${project.ID_No}`
                            );

                            const { itemsWithPendingQty, totalItemCount } = projectPendingData.data;
                            const { totalEntries, linkedPoCount } = PendingPOData.data;
                            const { item_14, item_15 } = ItemStatusData.data;

                            // Update ongoingData for the matched project with new properties
                            setOngoingData((prevData) =>
                                prevData.map((p) =>
                                    p.ID_No === project.ID_No && p.Sales_Order_No === project.Sales_Order_No
                                        ? { ...p, itemsWithPendingQty, totalItemCount, totalEntries, linkedPoCount, item_14, item_15 }
                                        : p
                                )
                            );
                        } catch (error) {
                            console.error(`Error fetching pending items for project ${project.ID_No}:`, error);
                            // If the API call fails, return the project without `itemsWithPendingQty`
                            return {
                                ...project,
                                itemsWithPendingQty: null, // or any default value, like 0 or undefined
                            };
                        }
                    })
                );
            } catch (error) {
                console.error('Error fetching Ongoing data:', error);


            } finally {
                setIsLoading(false);
            }
        };

        fetchData(); // Fetch data whenever activeStatus changes
    }, []);

    useEffect(() => {
        let dataToFilter = [];
        dataToFilter = OngoingData;

        // Filter data based on ID_No matching searchTerm
        const filtered = dataToFilter
            .filter((item) =>
                item.Sales_Order_No.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.PO_No?.toString().toLowerCase().includes(searchTerm.toLowerCase()) // Ensure Po_No exists
            )
            .sort((a, b) => b.Sales_Order_No - a.Sales_Order_No);


        setFilteredData(filtered);
    }, [searchTerm, activeStatus, OngoingData]);

    console.log("data:", OngoingData)

    function formatDate(inputDate) {
        if (inputDate === null || inputDate === "") {
          return "-";
        }
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = date.getFullYear();
     
        return `${day}-${month}-${year}`;
      }


    return (
        <div className={styles.listContainer}>

            <TabsBarDailyTracker activeTab='Spares Trading' activeStatus={activeStatus} setActiveStatus={setActiveStatus} setSearchTerm={setSearchTerm} />
            <LoadingScreen isLoading={isLoading} />
            <div className={styles.listingContainer}>
                <table className={styles.listTable}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: "60px", width: "60px" }}>S. No</th>
                            <th>SO No</th>
                            <th>PO No</th>
                            <th>Customer Name</th>
                            <th>PO date</th>
                            {activeStatus === 'Ongoing' && (
                                <>
                                    <th>Promised Dispatch date</th>
                                    <th>Revised Dispatch date</th>
                                    <th>Current Status</th>
                                    <th>Items pending delivery</th>
                                    <th>Items pending procurement</th>
                                    {/* // <th>Items pending receipt</th>
                  // <th>Items pending allocation</th> */}

                                </>
                            )}
                            {activeStatus === 'Delivered' && (
                                <>
                                    <th>Dispatch date</th>
                                    <th>Invoice status</th>
                                    <th>Payment receipt status</th>


                                </>
                            )}
                            {activeStatus === 'Closed' && (
                                <>
                                    <th>Dispatch date</th>
                                    <th>Invoice status</th>
                                    <th>Payment receipt status</th>


                                </>
                            )}

                        </tr>
                    </thead>
                    <tbody>
                        {activeStatus == 'Ongoing' && filteredData.filter(item =>
                            (item.item_14 !== 'Completed' || item.item_15 !== 'Completed') &&
                            (((item.totalItemCount !== null && item.totalItemCount !== undefined && item.totalItemCount !== 0) &&
                                (item.itemsWithPendingQty !== null && item.itemsWithPendingQty !== undefined && item.itemsWithPendingQty !== 0)) ||
                                (item.totalItemCount === null || item.totalItemCount === undefined || item.totalItemCount == 0 || item.totalItemCount === "")))

                            .map((item, index) => (
                                <tr key={item.ID_No}>
                                    <td>{index + 1}</td>
                                    <td><NavLink
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`/workshop-spares-trading-details-update/${item.ID_No}`, "_blank", "noopener,noreferrer");
                                        }}
                                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    >
                                        {item.Sales_Order_No || "-"}
                                    </NavLink></td>
                                    <td>{item.PO_No}</td>
                                    <td >{item.Customer_Name}</td>
                                    <td>{item.PO_Date ? formatDate(item.PO_Date) : "-"}</td>
                                    <td>{item.Promised_Dispatch_Date ? formatDate(item.Promised_Dispatch_Date) : "-" }</td>
                                    <td>{item.Estimated_Date_Of_Dispatch ? formatDate(item.Estimated_Date_Of_Dispatch) : "-" }</td>
                                    <td>{item.Current_Status}</td>
                                    <td style={{ backgroundColor: "#E2E6FF" }}>{item.totalItemCount ? `${item.itemsWithPendingQty}/${item.totalItemCount}` : "-"}</td>
                                    <td>{item.totalEntries ? `${item.linkedPoCount}/${item.totalEntries}` : "-"}</td>

                                </tr>
                            )
                            )}
                        {activeStatus == 'Delivered' && filteredData
                            .filter(item =>
                                (item.totalItemCount !== null && item.totalItemCount !== undefined && item.totalItemCount !== 0) &&
                                item.itemsWithPendingQty === 0 &&
                                (item.item_14 !== 'Completed' || item.item_15 !== 'Completed')
                            )
                            .map((item, index) => (
                                <tr key={item.ID_No}>
                                    <td>{index + 1}</td>
                                    <td><NavLink
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`/workshop-spares-trading-details-update/${item.ID_No}`, "_blank", "noopener,noreferrer");
                                        }}
                                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    >
                                        {item.Sales_Order_No || "-"}
                                    </NavLink></td>
                                    <td>{item.PO_No}</td>
                                    <td>{item.Customer_Name}</td>
                                    <td>{item.PO_Date ? formatDate(item.PO_Date) : "-"}</td>
                                    <td>{item.Estimated_Date_Of_Dispatch ? formatDate(item.Estimated_Date_Of_Dispatch) : "-" }</td>
                                    <td>{item.item_14}</td>
                                    <td>{item.item_15}</td>
                                </tr>
                            ))
                        }
                        {activeStatus == 'Closed' && filteredData
                            .filter(item =>
                                item.itemsWithPendingQty === 0 &&
                                (item.item_14 == 'Completed' && item.item_15 == 'Completed')
                            )
                            .map((item, index) => (
                                <tr key={item.ID_No}>
                                    <td>{index + 1}</td> 
                                    <td><NavLink
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`/workshop-spares-trading-details-update/${item.ID_No}`, "_blank", "noopener,noreferrer");
                                        }}
                                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    >
                                        {item.Sales_Order_No || "-"}
                                    </NavLink></td>
                                    <td>{item.PO_No}</td>
                                    <td>{item.Customer_Name}</td>
                                    <td>{item.PO_Date ? formatDate(item.PO_Date) : "-"}</td>
                                    <td>{item.Estimated_Date_Of_Dispatch ? formatDate(item.Estimated_Date_Of_Dispatch):"-"}</td>
                                    <td>{item.item_14}</td>
                                    <td>{item.item_15}</td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DailyTrackerSparesTrading;