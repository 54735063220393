import React, { useState, useRef, useEffect } from "react";
import styles from "../Components/css/mobilizations.module.css";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Modal from '@mui/material/Modal';
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { styled } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const Mobilizations = () => {
    const [mobilizations, setMobilizations] = useState(3);

    const [profdetails, setprofdetails] = useState([]);
    const [filteredprofdetails, setfilteredprofdetails] = useState([]);
    const [selectedDateDisabled, setSelectedDateDisabled] = useState(true);
    const [updatedateksa, setupdatedateksa] = useState("");
    const [updatedateuae, setupdatedateuae] = useState("");
    const [personaldetails, setpersonaldetails] = useState([]);
    const [filteredmobdetails, setfilteredmobdetails] = useState([]);
    const [filtereddemobdetails, setfiltereddemobdetails] = useState([]);
    const [visacountry, setvisacountry] = useState("UAE");
    const [filteredvisa, setfilteredvisa] = useState([]);
    const [projectdetails, setprojectdetails] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [removemodal, setremovemodal] = useState(false);
    const [renewmodal, setrenewmodal] = useState(false);
    const [editon, setediton] = useState(false);
    const [updateexit, setupdateexit] = useState("");
    const [modalopen, setmodalopen] = useState(false);
    const [profremovedoc, setprofremovedoc] = useState("");
    const [profrenewdoc, setprofrenewdoc] = useState("");
    const [profrenewdocname, setprofrenewdocname] = useState("");
    const [profremoveexpiry, setprofremoveexpiry] = useState("");
    const [profrenewexpiry, setprofrenewexpiry] = useState("");
    const [refresh, setrefresh] = useState(false);
    const [profremoveid, setprofremoveid] = useState(0);
    const [profrenewid, setprofrenewid] = useState(0);
    const [profrenewname, setprofrenewname] = useState("");
    const [renewexpiry, setrenewexpiry] = useState("");
    const [renewdoc, setrenewdoc] = useState("");
    const [renewdocURL, setrenewdocURL] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const presentdate = new Date();
    presentdate.setHours(0, 0, 0, 0);
    console.log(presentdate);
    let prevsno = 1;
    const mobilizationsTab = (tabNumber) => {
        setMobilizations(tabNumber);
    };
    const visafunction = (e) => {
        const { value } = e.target;
        setvisacountry(value);
    };
    const handleupdateexit = (e) => {
        setupdateexit(e.target.value);
        console.log(updateexit);
        if (visacountry == "KSA" && updateexit == "enter") {
            setupdatedateksa("");
        }
        if (visacountry == "UAE" && updateexit == "enter") {
            setupdatedateuae("");
        };

        if (e.target.value === "enter") {
            setSelectedDateDisabled(true); // Disable the date input if "Entering Country" is selected
        } else {
            setSelectedDateDisabled(false); // Enable the date input if "Exiting Country" is selected
        }
    }



    const handleMouseEnter = () => {
        setIsHovered(!isHovered);
    };

    const handlebutton = () => {
        setediton(true);
    };

    const handleexceldwn = () => {
        const form = document.getElementById('mobt');
        const table = form.querySelector("table");
        const rows = Array.from(table.querySelectorAll("tr"));

        const data = rows.map((row) =>
            Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
        );

        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Use XLSX.write to generate the Excel file as an ArrayBuffer
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        // Convert the ArrayBuffer to a Blob
        const blob = new Blob([wbout], { type: "application/octet-stream" });

        // Use FileSaver.js to save the file
        saveAs(blob, "table_data.xlsx");
    }



    const renewdate = (event) => {
        const inputDate = event.target.value;
        const parts = inputDate.split('-');
        let formatted = "";
        if (parts.length === 3) {
            const [year, month, day] = parts;
            formatted = `${day}/${month}/${year}`;
        }
        setrenewexpiry(formatted);
        console.log(formatted);
    }

    const removeprofdoc = (el, i, j) => {
        setprofremoveexpiry(j);
        setprofremovedoc(i);
        setprofremoveid(el);
        setremovemodal(true);
    }

    const renewprofdocs = (el, i, j, k, l) => {
        setrenewmodal(true);
        setprofrenewid(el);
        setprofrenewname(i);
        setprofrenewdocname(j);
        setprofrenewdoc(k);
        setprofrenewexpiry(l);
    }

    const handlerenewdoc = (e) => {
        const selectedFile = e.target.files[0];
        setrenewdoc(selectedFile);
        setrenewdocURL(URL.createObjectURL(selectedFile));
    }

    const renewcancel = () => {
        setrenewmodal(false);
        setprofrenewid(0);
        setprofrenewname("");
        setprofrenewdocname("");
        setprofrenewdoc("");
        setprofrenewexpiry("");
        setrenewdocURL("");
        setrenewdoc("");
        setrenewexpiry("");
    }

    const renewsave = async () => {

        const formData = new FormData();
        formData.append('Renew_Doc', renewdoc); // Append the file only


        formData.append('Doc_name', profrenewdoc);
        formData.append('Expiry', profrenewexpiry);
        formData.append('Date', renewexpiry);

        try {
            const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professionaldoc_renew?S_No=${profrenewid}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(res);
            setrenewmodal(false);
            setprofrenewid(0);
            setprofrenewname("");
            setprofrenewdocname("");
            setprofrenewdoc("");
            setprofrenewexpiry("");
            setrenewdocURL("");
            setrenewdoc("");
            setrenewexpiry("");
            setrefresh(!refresh);


        } catch (error) {
            console.error(error);

        }
    };



    const removeprofdocno = () => {
        setprofremoveexpiry("");
        setprofremovedoc("");
        setprofremoveid(0);
        setremovemodal(false);
    }

    const removeprofdocyes = async () => {
        const payload = {
            Doc_name: profremovedoc,
            Expiry: profremoveexpiry
        }
        const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professionaldoc_remove?S_No=${profremoveid}`, payload);
        console.log(res.data);
        setprofremoveexpiry("");
        setprofremovedoc("");
        setprofremoveid(0);
        setremovemodal(false);
        setrefresh(!refresh);
    }

    const handleupdate = async () => {
        console.log(updatedateksa);
        console.log(updatedateuae);

        const payload = {
            KSA_Exit_Date: updatedateksa,
            UAE_Exit_Date: updatedateuae,
        }

        try {
            const res = await axios.put(
                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professional_detailsupdation2/${filteredvisa[selectedRow]?.Customer_Name}`,
                payload
            );

            console.log(res.data);
            if (res.status === 200) {
                setSelectedDateDisabled(true);
                setmodalopen(false);
                setediton(false);
                setSelectedRow(null);
                setupdateexit("");
                setrefresh(!refresh);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const openmodal = (index) => {
        setSelectedRow(index);
        setmodalopen(true);
        if (visacountry == "KSA") {
            setupdatedateuae(filteredvisa[index].UAE_Exit_Date);
            setupdatedateksa("");
        }
        if (visacountry == "UAE") {
            setupdatedateksa(filteredvisa[index].KSA_Exit_Date);
            setupdatedateuae("");
        }
    };

    const handlecancelmodal = () => {
        setmodalopen(false);
        setediton(false);
        setSelectedRow(null);
        setSelectedDateDisabled(true);
        setupdateexit("");
    }

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    useEffect(() => {
        const fetchprof = async () => {
            const res = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details");
            const res1 = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization_all");
            const res2 = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details");
            setprofdetails(res.data);
            setpersonaldetails(res1.data);
            setprojectdetails(res2.data.data);

        }
        fetchprof();
    }, [refresh])

    useEffect(() => {
        if (personaldetails.length > 0) {
            const INT_MAX = Number.MAX_SAFE_INTEGER;
            const mob = personaldetails.filter((el) => {
                let mobdays = INT_MAX;
                if (el?.Boarding_Date) {
                    const parts = el.Boarding_Date.split("-");
                    const day = parseInt(parts[2]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[0]);
                    let mobdate = new Date(year, month, day);
                    let diff = mobdate.getTime() - presentdate.getTime();
                    mobdays = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                return mobdays <= 7 && mobdays >= 0;
            })
            setfilteredmobdetails(mob);

            const demob = personaldetails.filter((el) => {
                let demobdays = INT_MAX;
                if (el?.return_Boarding_Date) {
                    const parts = el.return_Boarding_Date.split("-");
                    const day = parseInt(parts[2]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[0]);
                    let demobdate = new Date(year, month, day);
                    let diff = demobdate.getTime() - presentdate.getTime();
                    demobdays = Math.round(diff / (24 * 60 * 60 * 1000));
                }

                return demobdays <= 7 && demobdays >= 0;
            })
            setfiltereddemobdetails(demob);

        }
    }, [personaldetails]);

    // useEffect(() => {
    //   console.log(filtereddemobdetails);
    //   console.log(filteredmobdetails);
    // },[filtereddemobdetails, filteredmobdetails]);



    useEffect(() => {
        if (profdetails.length > 0) {
            const INT_MAX = Number.MAX_SAFE_INTEGER;
            const filteredprof = profdetails.filter((el) => {
                let seamandays = INT_MAX;
                if (el?.Seaman_Book_Expiry) {
                    const parts = el.Seaman_Book_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const seamandate = new Date(year, month, day);
                    let diff = seamandate.getTime() - presentdate.getTime();
                    seamandays = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let boisetdays = INT_MAX;
                if (el?.Bosiet_Expiry) {
                    const parts = el.Bosiet_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const boisetdate = new Date(year, month, day);
                    let diff = boisetdate.getTime() - presentdate.getTime();
                    boisetdays = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let passportdays = INT_MAX;
                if (el?.Passport_Expiry) {
                    const parts = el.Passport_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const passportdate = new Date(year, month, day);
                    let diff = passportdate.getTime() - presentdate.getTime();
                    passportdays = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let insurancedays = INT_MAX;
                if (el?.Insurance_Expiry) {
                    const parts = el.Insurance_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const insurancedate = new Date(year, month, day);
                    let diff = insurancedate.getTime() - presentdate.getTime();
                    insurancedays = Math.round(diff / (24 * 60 * 60 * 1000));
                }

                let medicaldays = INT_MAX;
                if (el?.Medical_Expiry) {
                    const parts = el.Medical_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const medicaldate = new Date(year, month, day);
                    let diff = medicaldate.getTime() - presentdate.getTime();
                    medicaldays = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let h2sdays = INT_MAX;
                if (el?.H2s_Expiry) {
                    const parts = el.H2s_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const h2sdate = new Date(year, month, day);
                    let diff = h2sdate.getTime() - presentdate.getTime();
                    h2sdays = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let sntdays = INT_MAX;
                if (el?.SNT_Eye_Test_Expiry) {
                    const parts = el.SNT_Eye_Test_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const sntdate = new Date(year, month, day);
                    let diff = sntdate.getTime() - presentdate.getTime();
                    sntdays = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let visa1days = INT_MAX;
                if (el?.Active_Visas_One_Expiry) {
                    const parts = el.Active_Visas_One_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const visa1date = new Date(year, month, day);
                    let diff = visa1date.getTime() - presentdate.getTime();
                    visa1days = Math.round(diff / (24 * 60 * 60 * 1000));
                }

                let visa2days = INT_MAX;
                if (el?.Active_Visas_Two_Expiry) {
                    const parts = el.Active_Visas_Two_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const visa2date = new Date(year, month, day);
                    let diff = visa2date.getTime() - presentdate.getTime();
                    visa2days = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let visa3days = INT_MAX;
                if (el?.Active_Visas_Three_Expiry) {
                    const parts = el.Active_Visas_Three_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const visa3date = new Date(year, month, day);
                    let diff = visa3date.getTime() - presentdate.getTime();
                    visa3days = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let visa4days = INT_MAX;
                if (el?.Active_Visas_Four_Expiry) {
                    const parts = el.Active_Visas_Four_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const visa4date = new Date(year, month, day);
                    let diff = visa4date.getTime() - presentdate.getTime();
                    visa4days = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let visa5days = INT_MAX;
                if (el?.Active_Visas_Five_Expiry) {
                    const parts = el.Active_Visas_Five_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const visa5date = new Date(year, month, day);
                    let diff = visa5date.getTime() - presentdate.getTime();
                    visa5days = Math.round(diff / (24 * 60 * 60 * 1000));
                }
                let visa6days = INT_MAX;
                if (el?.Active_Visas_Six_Expiry) {
                    const parts = el.Active_Visas_Six_Expiry.split("/");
                    const day = parseInt(parts[0]);
                    const month = parseInt(parts[1]) - 1;
                    const year = parseInt(parts[2]);
                    const visa6date = new Date(year, month, day);
                    let diff = visa6date.getTime() - presentdate.getTime();
                    visa6days = Math.round(diff / (24 * 60 * 60 * 1000));
                }

                return seamandays < 60 || boisetdays < 60 || passportdays < 60 || insurancedays < 60 || medicaldays < 60 || h2sdays < 60 || sntdays < 60 || visa1days < 60 || visa2days < 60 || visa3days < 60 || visa4days < 60 || visa5days < 60 || visa6days < 60;

            })

            setfilteredprofdetails(filteredprof);
        }
        console.log(profdetails);
    }, [profdetails]);

    useEffect(() => {
        if (profdetails.length > 0) {
            const filtered = profdetails.filter((el) => {
                return (
                    el?.Active_Visas_One === visacountry ||
                    el?.Active_Visas_Two === visacountry ||
                    el?.Active_Visas_Three === visacountry ||
                    el?.Active_Visas_Four === visacountry ||
                    el?.Active_Visas_Five === visacountry ||
                    el?.Active_Visas_Six === visacountry
                );
            });
            setfilteredvisa(filtered);
        }
    }, [profdetails, visacountry]);
    const handleupdatedate = (e) => {
        if (visacountry == "UAE" && updateexit == "exit") {
            setupdatedateuae(e.target.value);
        }
        if (visacountry == "KSA" && updateexit == "exit") {
            setupdatedateksa(e.target.value);
        }
    };

    return (
        <div >
            <div
                style={{
                    marginTop: "135px",
                }}
            >
                {/* <div className={styles.allTabs}>
          <div
            style={{
              marginLeft: "20px",
            }}
            className={
              mobilizations === 3
                ? styles.activeMobilizationsTab
                : styles.mobilizationsTab
            }
            onClick={() => mobilizationsTab(3)}
          >
          Resident Visas
          </div>
          <div
            className={
              mobilizations === 2
                ? styles.activeMobilizationsTab
                : styles.mobilizationsTab
            }
            onClick={() => mobilizationsTab(2)}
          >
            Documents
          </div>
          <div
            className={
              mobilizations === 1
                ? styles.activeMobilizationsTab
                : styles.mobilizationsTab
            }
            onClick={() => mobilizationsTab(1)}
          >
            Flights
          </div>
        </div> */}
            </div>
            {/* {mobilizations === 1 && ( */}
            <div style={{ marginTop: "20px" }}>



            </div>
            {/* )} */}
            {/* {mobilizations === 2 && ( */}
            <div style={{ marginTop: "20px" }}>




            </div>
            {/* )} */}
            {/* {mobilizations === 3 && ( */}
            <div style={{ marginTop: "20px", overflowY: "hidden" }}>

                {modalopen && (
                    <div className={styles.overlay}>
                        <div className={styles.modal}>
                            <div className={styles.modalhead}><h6>Update Last Exit Date</h6></div>
                            <div className={styles.modalbody}>
                                <div>
                                    <label>Country :</label>
                                    <label>{visacountry}</label>
                                </div>

                            </div>
                            <div className={styles.modalbody}>
                                <div>
                                    <label>Professional Name :</label>
                                    <label>{filteredvisa[selectedRow]?.Customer_Name}</label>
                                </div>
                            </div>
                            <div className={styles.modalbody2}>
                                <div>
                                    <label htmlFor="enteringCountry">Entering Country</label>
                                    {visacountry == "UAE" && <input
                                        type="radio"
                                        defaultChecked
                                        name="lastdate"
                                        id="enteringCountry"
                                        value="enter"
                                        onChange={handleupdateexit}
                                    />}
                                    {visacountry == "KSA" && <input
                                        type="radio"
                                        name="lastdate"
                                        id="exitingCountry"
                                        value="exit"
                                        onChange={handleupdateexit}
                                    />}
                                </div>
                                <div>
                                    <label htmlFor="exitingCountry">Exiting Country</label>
                                    {visacountry == "UAE" && <input
                                        type="radio"
                                        name="lastdate"
                                        id="exitingCountry"
                                        value="exit"
                                        onChange={handleupdateexit}
                                    />}
                                    {visacountry == "KSA" && <input
                                        type="radio"
                                        defaultChecked
                                        name="lastdate"
                                        id="enteringCountry"
                                        value="enter"
                                        onChange={handleupdateexit}
                                    />}
                                </div>
                            </div>
                            <div className={styles.modalbody}>
                                <div>
                                    <label>Enter Last Exit Date :</label>
                                    <input
                                        style={{ borderRadius: "8px", height: "35px" }}
                                        type="date"
                                        name="selecteddate"
                                        disabled={selectedDateDisabled}
                                        onChange={handleupdatedate}
                                    />
                                </div>
                            </div>

                            <div className={styles.modalbutton}>
                                <button onClick={handleupdate} >Update</button>
                                <button onClick={handlecancelmodal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.visamaindiv2} style={{ overflowY: "hidden" }}>
                    <div style={{ display: "flex" }}>
                        <label htmlFor="message" className={styles.timeperiodlabel}>
                            Select Country:
                        </label>
                        <select className={styles.choosecountry} onChange={visafunction}>
                            <option value="UAE">UAE</option>
                            <option value="KSA">KSA</option>
                            <option value="Nigeria">Nigeria</option>
                        </select>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            style={{
                                marginLeft: "-30px",
                                marginTop: "20px"
                            }}
                        >
                            <path
                                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                fill="#394759"
                            />
                        </svg>
                    </div>
                    {(visacountry === "KSA" || visacountry === "UAE") && (<button className={styles.buttonlastupdate} onClick={handlebutton}>Update Last {visacountry === "KSA" ? "Entry" : visacountry === "UAE" ? "Exit" : null}</button>)}
                    {<svg width="40" height="37" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleexceldwn} style={{
                        marginLeft: "-67%",
                        marginRight: "20px",
                        marginTop: "8px"
                    }} >
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 5.28595 -1.19209e-07 2.92893 1.46447 1.46447C2.92893 -1.19209e-07 5.28595 0 10 0C14.714 0 17.0711 -1.19209e-07 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C-1.19209e-07 17.0711 0 14.714 0 10ZM10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V10.1893L12.4697 8.4697C12.7626 8.1768 13.2374 8.1768 13.5303 8.4697C13.8232 8.7626 13.8232 9.2374 13.5303 9.5303L10.5303 12.5303C10.3897 12.671 10.1989 12.75 10 12.75C9.8011 12.75 9.6103 12.671 9.4697 12.5303L6.46967 9.5303C6.17678 9.2374 6.17678 8.7626 6.46967 8.4697C6.76256 8.1768 7.23744 8.1768 7.53033 8.4697L9.25 10.1893V5C9.25 4.58579 9.5858 4.25 10 4.25ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75H14C14.4142 15.75 14.75 15.4142 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H6Z" fill="#5B6BE1" />
                    </svg>}
                </div>

                <form id="mobt">
                    <table className={styles.rigtable}>
                        <thead>
                            <tr>
                                <th> S.No </th>
                                <th> Professional Name</th>
                                <th> Validity </th>
                                {(visacountry === "KSA" || visacountry === "UAE") && (<th> {visacountry === "KSA" ? "Entry Date" : visacountry === "UAE" ? "Last Exit Date" : null} {""} </th>)}
                                {(visacountry === "KSA" || visacountry === "UAE") && (<th> {visacountry === "KSA" ? "In" : visacountry === "UAE" ? "Out" : null} {""} Days</th>)}
                            </tr>
                        </thead>
                        <tbody>

                            {filteredvisa.map((el, index) => {
                                let validity;
                                if (el.Active_Visas_One === visacountry) {
                                    validity = el.Active_Visas_One_Expiry;
                                }
                                if (el.Active_Visas_Two === visacountry) {
                                    validity = el.Active_Visas_Two_Expiry;
                                }
                                if (el.Active_Visas_Three === visacountry) {
                                    validity = el.Active_Visas_Three_Expiry;
                                }
                                if (el.Active_Visas_Four === visacountry) {
                                    validity = el.Active_Visas_Four_Expiry;
                                }
                                if (el.Active_Visas_Five === visacountry) {
                                    validity = el.Active_Visas_Five_Expiry;
                                }
                                if (el.Active_Visas_Six === visacountry) {
                                    validity = el.Active_Visas_Six_Expiry;
                                }


                                let parts = el.KSA_Exit_Date ? el.KSA_Exit_Date.split("-") : null;
                                let day = parts ? parseInt(parts[2]) : null;
                                let month = parts ? parseInt(parts[1]) - 1 : null;
                                let year = parts ? parseInt(parts[0]) : null;
                                const estimatedDate = parts ? new Date(year, month, day) : null;

                                let diff = estimatedDate ? presentdate.getTime() - estimatedDate.getTime() : null;
                                let days = diff ? Math.round(diff / (24 * 60 * 60 * 1000)) : null;

                                let parts2 = el.UAE_Exit_Date ? el.UAE_Exit_Date.split("-") : null;
                                let day2 = parts2 ? parseInt(parts2[2]) : null;
                                let month2 = parts2 ? parseInt(parts2[1]) - 1 : null;
                                let year2 = parts2 ? parseInt(parts2[0]) : null;
                                const estimatedDate2 = parts2 ? new Date(year2, month2, day2) : null;

                                let diff2 = estimatedDate2 ? presentdate.getTime() - estimatedDate2.getTime() : null;
                                let days2 = diff2 ? Math.round(diff2 / (24 * 60 * 60 * 1000)) : null;


                                return (<tr>
                                    <td className={styles.visaedit}>{editon && (
                                        <label className={styles.radioLabel}>
                                            <input
                                                type="radio"
                                                name="visa"
                                                checked={selectedRow === index}
                                                onChange={() => openmodal(index)}
                                            />
                                            {index + 1}
                                        </label>

                                    )}
                                        {!editon && (
                                            <>{index + 1}</>
                                        )}</td>
                                    <td>{el.Customer_Name}</td>
                                    <td>{validity}</td>

                                    {(visacountry === "KSA" || visacountry === "UAE") && (<td>{visacountry === "KSA" && days !== null ? el.KSA_Exit_Date : visacountry === "KSA" && days == null ? "Out of KSA" : visacountry === "UAE" && days2 !== null ? el.UAE_Exit_Date : visacountry === "UAE" && days2 == null ? "In UAE" : "--"}</td>)}
                                    {(visacountry === "KSA" || visacountry === "UAE") && (<td style={{ backgroundColor: (visacountry === "KSA" && days > 70) || (visacountry === "UAE" && days2 > 150) ? "#FF6A6A" : "", color: (visacountry === "KSA" && days > 70) || (visacountry === "UAE" && days2 > 150) ? "white" : "" }}>
                                        {visacountry === "KSA" && days !== null ? days : visacountry === "KSA" && days == null ? "Out of KSA" : visacountry === "UAE" && days2 !== null ? days2 : visacountry === "UAE" && days2 == null ? "In UAE" : "--"}
                                    </td>)}
                                </tr>)
                            })}

                        </tbody>
                    </table>
                </form>
            </div>
            {/* )} */}
        </div>
    );
};

export default Mobilizations;