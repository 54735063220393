import React from "react";
import styles from "./css/workshop_spares_trading_outgoing_inspections_listing.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import TabsBarSparesTrading from './TabsBarSparesTrading';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparesTradingWorkshopOutgoingInspectionsListing() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [inspectionDetails, setInspectionDetails] = useState([]);
  const [indetails, setindetails] = useState([]);

  const [formData, setFormData] = useState({
    Sales_Order_No: "",
    PO_No: "",
    PO_Date: null,
    Quote_No: "",
    Quote_Date: null,
    Costing_ID: "",
    Customer_Name: "",
    Scope: "",
    Scope_Description: "",
    Estimated_Date_Of_Dispatch: null,
    Ops_Engineer: "",
    Current_Status: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );

        if (response.data.success) {
          const data = response.data.data[0];
          setFormData({
            Sales_Order_No: data.Sales_Order_No,
            PO_No: data.PO_No,
            PO_Date: data.PO_Date,
            Quote_No: data.Quote_No,
            Quote_Date: data.Quote_Date,
            Costing_ID: data.Costing_ID,
            Customer_Name: data.Customer_Name,
            Scope: data.Scope,
            Scope_Description: data.Scope_Description,
            Estimated_Date_Of_Dispatch: data.Estimated_Date_Of_Dispatch,
            Ops_Engineer: data.Ops_Engineer,
            Current_Status: data.Current_Status,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
    };

    fetchData();
  }, [id]);

  //sparestrading_outgoing_inspections_get

  useEffect(() => {
    async function fetchsmDetails() {
      try {
        const res_inspection = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_outgoing_inspections_get?Spares_Trading_Project_ID=${id}`
        );

        setInspectionDetails(res_inspection.data.data);
        console.log("inspectionDetails", res_inspection.data.data);

        // Collate items by Outgoing_Inspection_ID
        const collatedData = res_inspection.data.data.reduce((acc, item) => {
          const {
            Outgoing_Inspection_ID,
            Outgoing_Delivery_ID,
            Dispatch_ID,
            Created_On,
            Inspection_Date,
          } = item;

          if (!acc[Outgoing_Inspection_ID]) {
            acc[Outgoing_Inspection_ID] = {
              Outgoing_Inspection_ID,
              Outgoing_Delivery_ID,
              Dispatch_ID,
              Created_On,
              Inspection_Date,
              items: [],
            };
          }

          acc[Outgoing_Inspection_ID].items.push(item);

          return acc;
        }, {});

        // Convert the collated data to an array
        const collatedArray = Object.values(collatedData);
        setindetails(collatedArray);
        console.log("indetails", collatedArray);

      } catch (error) {
        console.error(error);
      }
    }
    fetchsmDetails();
  }, []);

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }

    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const getBackgroundColor = (el) => {
    // Filter inspectionDetails for entries with the same Outgoing_Inspection_ID
    const filteredEntries = inspectionDetails.filter(
      entry => entry.Outgoing_Inspection_ID === el.Outgoing_Inspection_ID && entry.Scheduled_Outgoing_Qty !== undefined && entry.Scheduled_Outgoing_Qty !== null && entry.Scheduled_Outgoing_Qty !== 0 && entry.Scheduled_Outgoing_Qty !== "0"
    );
    console.log("filteredEntries", filteredEntries);
    // Check if any Actual_Outgoing_Qty is null
    const anyNullReceivedQty = filteredEntries.some(entry => entry.Actual_Outgoing_Qty === null || entry.Actual_Outgoing_Qty === "");

    // Check the conditions
    if (filteredEntries.length === 0 || anyNullReceivedQty) {
      return '#900909'; // Pending
    }
    else {
      return '#077D7D'; // Completed
    }
  };

  const getButtonText = (el) => {
    // Filter inspectionDetails for entries with the same Outgoing_Inspection_ID
    const filteredEntries = inspectionDetails.filter(
      entry => entry.Outgoing_Inspection_ID === el.Outgoing_Inspection_ID && entry.Scheduled_Outgoing_Qty !== undefined && entry.Scheduled_Outgoing_Qty !== null && entry.Scheduled_Outgoing_Qty !== 0 && entry.Scheduled_Outgoing_Qty !== "0"
    );

    // Check if any Actual_Outgoing_Qty is null
    const anyNullReceivedQty = filteredEntries.some(entry => entry.Actual_Outgoing_Qty === null || entry.Actual_Outgoing_Qty === "");

    // Check the conditions
    if (filteredEntries.length === 0 || anyNullReceivedQty) {
      return 'PENDING';
    }
    else {
      return 'COMPLETED';
    }
  };

  const handleInspectionDivClick = (el) => {
    navigate(`/workshop-spares-trading-outgoing-inspections-update/${id}/${el.Outgoing_Inspection_ID}`);
  }


  return (
    <div>
      <div className={styles.topdiv}>
        <label className={styles.aryl1}>
          SO: {formData.Sales_Order_No}
        </label>
        <label className={styles.aryl}>
          PO: {formData.PO_No}
        </label>
        <label className={styles.aryl}>
          Customer Name: {formData.Customer_Name}
        </label>
      </div>

      <TabsBarSparesTrading activeTab="Inspection" />

      <div className={styles.sections2}>
        {indetails
          .slice() // Create a shallow copy of the array
          .sort((a, b) => b.Dispatch_ID - a.Dispatch_ID) // Sort in descending order based on the Dispatch_ID         
          .map((el, index) => (
            <div key={index} className={styles.projectList}
              onClick={() => handleInspectionDivClick(el)}
            >
              <div className={styles.textContainer}>
                {`Outgoing Inspection ID: ${el.Outgoing_Inspection_ID || '-'} \u00a0|\u00a0 
                    Inspection Date: ${formatDate(el.Inspection_Date) || '-'} \u00a0|\u00a0 
                    Created On: ${formatDate(el.Created_On) || '-'} \u00a0|\u00a0 
                    Customer Name: ${formData.Customer_Name || '-'} \u00a0|\u00a0 
                    Outgoing Delivery ID: ${el.Outgoing_Delivery_ID || '-'}
                  `}
              </div>
              <button
                className={styles.deliveryStatusButton}
                style={{ backgroundColor: getBackgroundColor(el), color: "white" }}>
                {getButtonText(el)}
              </button>
            </div>
          ))}

      </div>

    </div>
  )
}

export default SparesTradingWorkshopOutgoingInspectionsListing
