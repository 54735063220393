import React from 'react';
import axios from 'axios';
import { useState, useEffect, useRef  } from 'react';
import  '../Components/css/chatbot.css';

const Chatbot = () => {
  var [queryStr, setQueryStr] = useState('');
  //var [queryStr1, setQueryStr1] = useState('');
  const [chat, setChat] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const endOfMessagesRef = useRef(null);

  const handleQuery = async () => {
    if (!queryStr) return;
    var querya = ( queryStr + " and please assume that dates are in varchar in the format dd/mm/yyyy");
    const newChat = [...chat, { user: true, text: queryStr }];
    setChat(newChat);
    setQueryStr('');
    setIsLoading(true);
    
    try {
      const response = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8000/query', { query: querya });
      const result = response.data.response.response;

      // Append the answer to the chat
      const updatedChat = [...newChat, { user: false, text: result }];
      setChat(updatedChat);
    } catch (error) {
      console.error('Error fetching data:', error);
      const updatedChat = [...newChat, { user: false, text: 'Error fetching data' }];
      setChat(updatedChat);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chat]);

  return (
   
    <div className="chatbot-container" style={{  marginTop: chat.length > 0 ? "6%" : "3%"}}>
        {chat.length===0 && (
      <div>
      <div className="chatbot-header">
        <div className="chatbot-logo"><svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22" cy="22" r="22" fill="#D1D3FF"/>
<line x1="31.5356" y1="13.3594" x2="31.5356" y2="37.7165" stroke="#FF6A6A"/>
<path d="M31.0356 25.592C5.36962 29.128 6.48774 26.7076 7.45429 15.7109" stroke="#FF6A6A"/>
<path d="M23.8965 23.9219L22.4902 20.2969H17.9121L16.5137 23.9219H15.1699L19.6387 12.4531H20.834L25.2715 23.9219H23.8965ZM22.0918 19.1172L20.7559 15.5156C20.7246 15.4219 20.6725 15.2682 20.5996 15.0547C20.5319 14.8411 20.4616 14.6198 20.3887 14.3906C20.3158 14.1615 20.2559 13.9766 20.209 13.8359C20.1569 14.0495 20.0996 14.263 20.0371 14.4766C19.9798 14.6849 19.9225 14.8802 19.8652 15.0625C19.8079 15.2396 19.7585 15.3906 19.7168 15.5156L18.3574 19.1172H22.0918Z" fill="#5B6BE1"/>
<circle cx="31.2321" cy="8.44308" r="0.982143" fill="#FF6A6A"/>
</svg></div>
        <div className="chatbot-title">
       
          <div style={{marginTop:"9px"}}>Jupiter’s own AI</div>
        </div>
      </div>
      <div className="chatbot-buttons">
      
        <button onClick={() => setQueryStr('Which supervisor is available in May')}>"Which supervisor is available in May"</button>
        <button onClick={() => setQueryStr('Timesheet for 28 April')}>"Timesheet for 28 April"</button>
        <button onClick={() => setQueryStr('Details of projects completed in last 30 days')}>"Details of projects completed in last 30 days"</button>
      </div>
      </div>
        )}
      <div className="chatbot-messages">
        {chat.map((message, index) => (
          <div key={index} className={`message 'user'`} style={{ borderRadius:"8px"}}>
             {index%2===0 && ( <div style={{marginRight:"10px", marginTop:"2px"}}>
                   <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="44"
                    fill="currentColor"
                    class="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
</div> )}
{index%2!==0 && (
<div style={{marginRight:"10px", marginTop:"2px"}}>
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22" cy="22" r="22" fill="#D1D3FF"/>
<line x1="31.5356" y1="13.3594" x2="31.5356" y2="37.7165" stroke="#FF6A6A"/>
<path d="M31.0356 25.592C5.36962 29.128 6.48774 26.7076 7.45429 15.7109" stroke="#FF6A6A"/>
<path d="M23.8965 23.9219L22.4902 20.2969H17.9121L16.5137 23.9219H15.1699L19.6387 12.4531H20.834L25.2715 23.9219H23.8965ZM22.0918 19.1172L20.7559 15.5156C20.7246 15.4219 20.6725 15.2682 20.5996 15.0547C20.5319 14.8411 20.4616 14.6198 20.3887 14.3906C20.3158 14.1615 20.2559 13.9766 20.209 13.8359C20.1569 14.0495 20.0996 14.263 20.0371 14.4766C19.9798 14.6849 19.9225 14.8802 19.8652 15.0625C19.8079 15.2396 19.7585 15.3906 19.7168 15.5156L18.3574 19.1172H22.0918Z" fill="#5B6BE1"/>
<circle cx="31.2321" cy="8.44308" r="0.982143" fill="#FF6A6A"/>
</svg>
</div>
)}
<div style={{width:"98%",height:"10%",backgroundColor: index % 2 === 0 ? 'rgba(209, 217, 255, 0.5)' : 'white', borderRadius:"8px"}}>
<div style={{marginLeft:"5px",fontWeight:"400",marginTop:"13px",fontSize:"20px", lineHeight:"30px",marginBottom:"14px", wordWrap: "break-word", overflowWrap: "break-word"}}> 
  {message.text} 
  </div>
  </div>
           
          </div>
          
        ))}
  <div ref={endOfMessagesRef} />       
        {isLoading && (
          <div className="message bot" >
          Analyzing &nbsp; <span className="loading"></span>
          </div>
        )}
      </div>
      <div className="chatbot-input">
        <input
          type="text"
          value={queryStr}
          onChange={(e) => setQueryStr(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleQuery()}
          placeholder="Type your query here and hit enter"
          disabled={isLoading===true}
          style={{fontWeight:"400",fontSize:"16px", lineHeight:"20px"}}
       />
        <button onClick={handleQuery}>
          &#x27A4;
        </button>
      </div>
    </div>
  );
};

export default Chatbot;