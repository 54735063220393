import React from 'react'
import { Stepper, Step, StepLabel, Box } from '@mui/material';
import styles from "./css/Production_Create_New.module.css";    //css for the Material Produced screen, step 1 of the flow
import styles2 from "../Components/css/Production_Create_New_2.module.css";   //css for the Material Consumed screen, step 2 of the flow
import style from "./css/modal.module.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from './LoadingScreen';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Production_Create_New() {
  const { project_id } = useParams();   //Project ID taken from url
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks

  const [formDataProduced, setFormDataProduced] = useState([]);   //to store the "Material Produced" table data
  const [itemsMaster, setItemsMaster] = useState([]);   //used for storing the Items Master List data to display in the Item_Name dropdown when anything is typed
  const [prodType, setProdType] = useState("WIP");    //state to store the Production_Type option selected using radio buttons
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);    // to set the Add More Items button disabled state
  const [addItemMode, setAddItemMode] = useState(false);    //when "Add More Items" button is clicked, this state is activated
  const [editItemMode, setEditItemMode] = useState(null);   //when the edit pencil icon is clicked, this state is activated
  const [initialEditData, setInitialEditData] = useState(null);   //state to store the initial data stored in formDataProduced whenever the edit "pencil" icon is clicked, this will be used to restore the data in case the edit is cancelled
  const [refresh, setRefresh] = useState(false);    //refresh state is used to control the useEffect where all the get requests are, explicitly used in Edit, Save, Cancel and other buttons to refresh the data

  const [isDropdownVisible, setDropdownVisible] = useState(false);    //used to control dropdown visibility under Item Name input in editable state, editItemMode, addItemMode
  const [isPartNoDisabled, setIsPartNoDisabled] = useState([]);   //used to control the disabled state of the Part Number input in editable state, editItemMode

  const [isPartNoDisabledAddRow, setIsPartNoDisabledAddRow] = useState(true);    //used to control the disabled state of the Part Number input when "Add More Items " is clicked, addItemMode state true
  const containerRef = useRef(null);
  const [initialHeight, setInitialHeight] = useState();   // State variable to track the initial height of the container
  const [heightRefresh, setHeightRefresh] = useState(false);    //to refresh the height of smtablecontainer div which contains the table and dropdowns
  const [containerStyle, setContainerStyle] = useState({});   // State variable to store container style

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == project_id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
      });
      if (filter[0].Scope === "Assembly") { setProdType("FG") }
      console.log(filter);
      console.log(project_id);
    };
    fetchProject();
  }, [project_id]);

  //useEffect to get the Items Master List Data on first loading of the page data and states
  useEffect(() => {
    async function fetchItemsMaster() {
      try {
        const resItemsMaster = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
        );

        setItemsMaster(resItemsMaster.data);
        console.log("Items Master:", resItemsMaster.data);

        // //Adding items to Items Master List if they don't exist already based on Part Number
        // const itemsToPost = [];

        // updatedFilter.forEach((smResult) => {
        //   const matchingItem = resItemsMaster.data.find(
        //     (item) => item.Part_Number === smResult.OEM_Part_Number && item.Item_Name === smResult.Item_Name
        //   );

        //   if (!matchingItem) {
        //     // If matchingItem is not found, add it to the itemsToPost array
        //     itemsToPost.push({
        //       Item_Name: smResult.Item_Name,
        //       Part_Number: smResult.OEM_Part_Number,
        //     });
        //   }
        // });

        // // Perform POST requests for itemsToPost
        // for (const itemToPost of itemsToPost) {
        //   try {
        //     const res = await axios.post(
        //       "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemmaster",
        //       itemToPost
        //     );
        //     console.log("Item added to itemsMaster:", itemToPost);
        //   } catch (error) {
        //     console.error("Error occurred while making POST request:", error);
        //   }
        // }

        // if (itemsToPost.length > 0) {
        //   const updatedItemsMaster = await axios.get(
        //     "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
        //   );
        //   setItemsMaster(updatedItemsMaster.data);
        //   console.log("Updated Items Master:", updatedItemsMaster.data)
        // }

      } catch (error) {
        console.error(error);
      }
    }
    fetchItemsMaster();
  }, [refresh]);

  // Use effect to initialize and update isPartNoDisabled
  useEffect(() => {
    setIsPartNoDisabled(formDataProduced.map(() => true));
  }, [formDataProduced.length]);


  const handleProdBackButton = () => {
    navigate(`/production-workshop/${project_id}`);
  };

  //Stepper states and functions

  const steps = [
    { label: 'Material Produced' },
    { label: 'Material Consumed' }
  ];

  const [activeStep, setActiveStep] = useState(0);    //to store the activeStep for the stepper and to render the tables, 0 for "Material Produced", 1 for "Material Consumed"

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //Radio button function
  const handleProdTypeRadioChange = (e) => {
    const newValue = e.target.value;
    setProdType(newValue);
  };

  //Function to add item to formDataProduced when "Add More Items" is clicked
  const handleAddItem = () => {
    setAddItemMode(true);
    setIsButtonDisabled(true);
    const newItem = {
      S_No: formDataProduced.length + 1,
      Item_Name: '',
      Part_Number: '',
      UoM: '',
      Qty_Produced: '',
      Ergon_Traceability_Number: '',
      Part_Traceability_Number: '',
      Equipment_Traceability_Number: '',
    };
    setFormDataProduced([...formDataProduced, newItem]);
  };

  // Function to save a new item when the tick button is clicked
  const saveItem = () => {
    setAddItemMode(false);
    setIsButtonDisabled(false);
    setIsPartNoDisabled(formDataProduced.map(() => true));    //explicitly setting the Part Number input to disabled state because formDataProduced.length doesn't change here
    setDropdownVisible(false);
  };

  // Function to cancel adding a new item when the cross button is clicked after "Add More Items" button-click
  const cancelAddItem = () => {
    setFormDataProduced(formDataProduced.slice(0, -1));
    setAddItemMode(false);
    setIsButtonDisabled(false);
    setDropdownVisible(false);
  };

  // Function to start editing an item when the "pencil" edit icon is clicked
  const editItem = (index) => {
    setEditItemMode(index);
    setIsButtonDisabled(true);
    setInitialEditData({ ...formDataProduced[index] });
  };

  // Function to save edited item when the tick button is clicked
  const saveEditItem = () => {
    setEditItemMode(null);
    setIsButtonDisabled(false);
    setInitialEditData(null);
    setIsPartNoDisabled(formDataProduced.map(() => true));    //explicitly setting the Part Number input to disabled state because formDataProduced.length doesn't change here
  };

  // Function to cancel editing an item when the cross button is clicked after "pencil" edit button-click 
  const cancelEditItem = () => {
    const updatedFormData = formDataProduced.map((item, index) =>
      index === editItemMode ? initialEditData : item
    );
    setFormDataProduced(updatedFormData);
    setEditItemMode(null);
    setIsButtonDisabled(false);
    setInitialEditData(null);
    setIsPartNoDisabled(formDataProduced.map(() => true));    //explicitly setting the Part Number input to disabled state because formDataProduced.length doesn't change here
  };

  // Function to delete an item when the blue cross button is clicked
  const deleteItem = (index) => {
    const updatedFormData = formDataProduced.filter((_, i) => i !== index).map((item, i) => ({
      ...item,
      S_No: i + 1
    }));
    setFormDataProduced(updatedFormData);
  };

  // Function to handle input changes
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormData = formDataProduced.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormDataProduced(updatedFormData);
  };

  const handleDropdownSelection = (selectedItem, index) => {
    const { Item_Name, Part_Number } = selectedItem;

    const updatedFormData = formDataProduced.map((item, i) =>
      i === index ? { ...item, Item_Name, Part_Number: Part_Number } : item
    );
    setFormDataProduced(updatedFormData);
    setDropdownVisible(false);
    setIsPartNoDisabled((prevVisibility) => ({ ...prevVisibility, [index]: true }));
  };

  const handleAddToItemsMaster = (index) => {
    const updatedFormData = formDataProduced.map((item, i) =>
      i === index ? { ...item, Part_Number: "" } : item
    );
    setFormDataProduced(updatedFormData);
    setDropdownVisible(false);
    setIsPartNoDisabled(formDataProduced.map(() => false));
  };

  console.log("formDataProduced", formDataProduced);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const observer = new ResizeObserver((entries) => {
      // Since there might be multiple entries, we take the first one
      const newHeight = entries[0].contentRect.height;
      setInitialHeight(newHeight);
    });

    observer.observe(container);

    return () => observer.disconnect();
  }, [heightRefresh, activeStep]);

  console.log("initialHeight", initialHeight);

  useEffect(() => {
    // Calculate the new height based on the initial height and whether the dropdowns are visible
    const newHeight = ((isDropdownVisible) ? (initialHeight + 270) : (initialHeight + 20));
    // const newHeightInt = parseInt(newHeight);

    console.log("newHeight", newHeight);
    setContainerStyle({ height: newHeight });
  }, [isDropdownVisible, initialHeight, addItemMode, isButtonDisabled, editItemMode, formDataProduced, activeStep]);

  console.log("isDropdownVisible saSasASasAS", isDropdownVisible)
  console.log("containerStyle ppppppppp", containerStyle);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.getElementById('myDropdown');

      // Check if the clicked element is inside the dropdown
      if (dropdown && !dropdown.contains(event.target)) {
        // If the clicked element is outside the dropdown, hide it
        setDropdownVisible(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  //states and functions for the "Material Consumed" screen, step 2 on the frontend

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [consumedItems, setConsumedItems] = useState([]);
  const [inventoryUtilisedItems, setInventoryUtilisedItems] = useState([]);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [isCsp, setIsCsp] = useState(true);
  const [incomingQuantities, setIncomingQuantities] = useState([]);
  const [billEntries, setBillEntries] = useState({});
  const Navigate = useNavigate();
  const [mappedValues, setMappedValues] = useState({});
  const [changes, setChanges] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [hasErrors2, setHasErrors2] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabled2, setIsDisabled2] = useState(true);
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [inputValues2, setInputValues2] = useState({});
  const Project_SO = formdata1.salesOrderNo; //Project_SO taken from formdata1

  const handleOnChange2 = (e, item, index) => {
    const value = e.target.value;
    const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
    const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
    setInputValues((prevValues) => ({
      ...prevValues,
      [item.S_No]: value,
    }));

    if (parseFloat(value) > item.Quantity_Received - totalQtyUsed) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [item.S_No]: `Input can't exceed ${item.Quantity_Received - totalQtyUsed}`,
      }));
      setHasErrors(true);
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [item.S_No]: "",
      }));
      setHasErrors(false);
    }
  };
  console.log("InputValues", inputValues);
  const handleOnChange3 = (e, item, index) => {
    const value = e.target.value;
    const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
    const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
    setInputValues2((prevValues2) => ({
      ...prevValues2,
      [item.S_No]: value,
    }));

    if (parseFloat(value) > item.Qty_Utilized - totalQtyUsed) {
      setErrors2((prevErrors2) => ({
        ...prevErrors2,
        [item.S_No]: `Input can't exceed ${item.Qty_Utilized - totalQtyUsed}`,
      }));
      setHasErrors2(true);
    } else {
      setErrors2((prevErrors) => ({
        ...prevErrors,
        [item.S_No]: "",
      }));
      setHasErrors2(false);
    }
  };
  console.log("InputValues2", inputValues2);
  console.log("setInventoryUtilisedItems", inventoryUtilisedItems);
  useEffect(() => {
    async function fetchConsumptions() {
      try {
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_consumed_get?Project_SO=${Project_SO}`
        );

        const itemsReceivedData = res1.data.data;

        // Set the state for items consumed
        setConsumedItems(itemsReceivedData);

        const newMappedValues = {};
        itemsReceivedData.forEach((item) => {
          const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
          newMappedValues[key] = item.Total_Qty_Used;
        });
        setMappedValues(newMappedValues);
      } catch (error) {
        console.error(error);
      }
    }
    fetchConsumptions();
  }, [Project_SO]);

  console.log("setMappedValues", mappedValues);

  // useEffect(() => {
  //   if (isCsp) {
  //     // setInputValues2({});
  //     setErrors2({});
  //     setHasErrors2(false);
  //   }
  //   else {
  //     // setInputValues({});
  //     setErrors({});
  //     setHasErrors(false);
  //   }

  // }, [isCsp]);

  const handleSaveUnified = async () => {
    setIsLoading(true);
    const currentDate = new Date().toISOString().split('T')[0]; // Only the date part

    try {
      // First POST request (common for both scenarios)
      const productionDetailsPayload = {
        Project_ID: project_id,
        Production_Type: prodType,
        Production_Date: currentDate
      };

      const productionResponse = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/production_details_post', productionDetailsPayload);
      const productionID = productionResponse.data.data.insertId;

      // Second and Third POST requests for each item in formDataProduced (common for both scenarios)
      const materialProducedPromises = formDataProduced.map((item) => {
        const materialProducedPayload = {
          Production_ID: productionID,
          Project_ID: project_id,
          Project_SO: formdata1.salesOrderNo,
          Item_Name: item.Item_Name,
          Part_Number: item.Part_Number,
          Date: currentDate,
          UoM: item.UoM,
          Production_Type: prodType,
          Qty_Produced: item.Qty_Produced,
          Ergon_Traceability_Number: item.Ergon_Traceability_Number,
          Part_Traceability_Number: item.Part_Traceability_Number,
          Equipment_Traceability_Number: item.Equipment_Traceability_Number,
        };

        const materialProducedTabsPayload = {
          Production_ID: productionID,
          Project_ID: project_id,
          Item_Name: item.Item_Name,
          Part_Number: item.Part_Number,
          UoM: item.UoM,
          Production_Type: prodType,
          Qty_Produced: item.Qty_Produced,
          Ergon_Traceability_Number: item.Ergon_Traceability_Number,
          Part_Traceability_Number: item.Part_Traceability_Number,
          Equipment_Traceability_Number: item.Equipment_Traceability_Number,
        };

        const materialProducedRequest = axios.post(
          'http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_produced_post',
          materialProducedPayload
        );

        const materialProducedTabsRequest = axios.post(
          'http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_produced_tabs_post',
          materialProducedTabsPayload
        );

        // Check if the item exists in the items master list
        const matchingItem = itemsMaster.find(
          (masterItem) =>
            masterItem.Part_Number === item.Part_Number &&
            masterItem.Item_Name === item.Item_Name &&
            (!masterItem.Manufacturer_Part_Number || masterItem.Manufacturer_Part_Number === '')
        );

        let itemMasterRequest = Promise.resolve();

        // If the item does not exist, add it to the items master list
        if (!matchingItem) {
          const itemMasterPayload = {
            Item_Name: item.Item_Name,
            Part_Number: item.Part_Number,
            Manufacturer_Part_Number: null, // or an empty string if needed
          };

          itemMasterRequest = axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemmaster", itemMasterPayload);
        }

        return Promise.all([materialProducedRequest, materialProducedTabsRequest, itemMasterRequest]);
      });

      await Promise.all(materialProducedPromises);

      // Fourth POST request for Material Consumed for CSP
      const cspPayload = filteredItems
        .filter((item) => inputValues[item.S_No] && inputValues[item.S_No].trim() !== "")
        .map((item) => ({
          Production_ID: productionID,
          Project_ID: project_id,
          Project_SO: formdata1.salesOrderNo,
          Item_Name: item.Item_Name,
          Part_Number: item.Part_Number,
          Date: currentDate,
          UoM: item.UoM,
          Production_Type: prodType,
          Traceability_Number: item.Traceability_Number,
          Qty_Consumed: inputValues[item.S_No],
          Incoming_Delivery_ID: item.Incoming_Delivery_ID,
          Consumption_Type: "CSP"
        }));

      if (cspPayload.length > 0) {
        console.log("CSP Payload:", JSON.stringify(cspPayload, null, 2));
        const cspResponse = await fetch('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_consumed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(cspPayload)
        });

        if (!cspResponse.ok) {
          throw new Error('Network response was not ok for CSP');
        }

        const cspResult = await cspResponse.json();
        console.log('CSP Success:', cspResult);
      }

      // Fourth POST request for Material Consumed for Inventory
      const inventoryPayload = inventoryUtilisedItems
        .filter((item) => inputValues2[item.S_No] && inputValues2[item.S_No].trim() !== "")
        .map((item) => ({
          Production_ID: productionID,
          Project_ID: project_id,
          Project_SO: formdata1.salesOrderNo,
          Item_Name: item.Item_Name,
          Part_Number: item.Part_Number,
          Date: currentDate,
          UoM: item.UoM,
          Production_Type: prodType,
          Traceability_Number: item.Traceability_Number,
          Qty_Consumed: inputValues2[item.S_No],
          Incoming_Delivery_ID: item.Incoming_Delivery_ID,
          Consumption_Type: "Inventory"
        }));

      if (inventoryPayload.length > 0) {
        console.log("Inventory Payload:", JSON.stringify(inventoryPayload, null, 2));
        const inventoryResponse = await fetch('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_consumed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(inventoryPayload)
        });

        if (!inventoryResponse.ok) {
          throw new Error('Network response was not ok for Inventory');
        }

        const inventoryResult = await inventoryResponse.json();
        console.log('Inventory Success:', inventoryResult);
      }

      // Navigate back if all requests were successful
      setIsLoading(false);
      Navigate(-1);

    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };


  // To simulate original handleSave or handleSave2 behavior
  // Call handleSaveUnified(true) for handleSave behavior, and handleSaveUnified(false) for handleSave2 behavior




  useEffect(() => {
    const areAllInputsEmpty = () => {
      return Object.values(inputValues).every(
        (value) => value === "" || value === undefined
      );
    };

    setIsDisabled(areAllInputsEmpty());
  }, [inputValues, isCsp]);

  useEffect(() => {
    const areAllInputsEmpty = () => {
      return Object.values(inputValues2).every(
        (value) => value === "" || value === undefined
      );
    };

    setIsDisabled2(areAllInputsEmpty());
  }, [inputValues2, isCsp]);

  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries2"
        );
        if (response.data.success) {
          const hashArray = response.data.data.reduce((acc, current) => {
            acc[current.S_No] = current.Entry_Bill_No;
            return acc;
          }, {});
          setBillEntries(hashArray);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDeliveries();
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    async function fetchDetails() {
      try {
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get21?Project_ID=${project_id}`
        );

        const itemsReceivedData = res1.data.data;

        const filteredItems = itemsReceivedData.filter((item) => {
          const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
          const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
          return item.Quantity_Received - totalQtyUsed !== 0;
        });

        // Set the state for items received
        setFilteredItems(filteredItems);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, [mappedValues]);

  console.log("setFilteredItems", filteredItems);
  //data from inventory utilised
  useEffect(() => {
    async function fetchDetails() {
      try {
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_inventory_utilised?Project_SO=${Project_SO}`
        );

        const itemsReceivedData2 = res1.data.data;
        const filteredItems = itemsReceivedData2.filter((item) => {
          const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
          const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
          return item.Qty_Utilized - totalQtyUsed !== 0;
        });

        // Set the state for items received
        setInventoryUtilisedItems(filteredItems);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, [Project_SO, mappedValues]);

  console.log("setInventoryUtilisedItems", inventoryUtilisedItems)

  const handleInventoryClick = () => {
    setIsCsp(false);
  };

  const handleCspClick = () => {
    setIsCsp(true);
  };

  const handleCancel = () => {
    Navigate(-1);
  };

  //end of states and functions for the "Material Consumed" screen, step 2 on the frontend

  return (
    <div>
      <LoadingScreen isLoading={isLoading} />
      <div className={styles.topButtonContainer}>
        <button className={styles.topBackButton} onClick={handleProdBackButton}>
          <svg width="24" height="24" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z" fill="#5B6BE1" />
          </svg>
          {' '}Production / Material Transformation
        </button>

        <Box sx={{ width: '50%', mt: "8px" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  sx={{
                    fontWeight: 'bold',
                    color: '#5B6BE1',
                    '& .MuiStepLabel-label': {
                      mt: '6px !important',
                      fontWeight: 'bold !important',
                      color: '#5B6BE1 !important',
                    },
                    '& .MuiStepLabel-label.Mui-active': {
                      fontWeight: 'bold !important',
                      color: '#5B6BE1 !important',
                    },
                    '& .MuiStepLabel-label.Mui-completed': {
                      fontWeight: 'bold !important',
                      color: '#5B6BE1 !important',
                    },
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

      </div>

      <div className={styles.topdiv}>
        <div className={styles.topsubdiv}>
          <label className={styles.projectDiv}>
            Project SO: {formdata1.salesOrderNo}
          </label>
          <label className={styles.projectDiv}>
            Project Design: {formdata1.Design}
          </label>
          <label className={styles.projectDiv}>
            Project Name: {formdata1.ProjectName}
          </label>
          <label className={styles.projectDiv}>
            Project Scope: {formdata1.Scope}
          </label>
        </div>
      </div>

      <div className={styles.mainDiv}>
        <div className={styles.buttonsdiv} style={{ minHeight: "63px", marginTop: "15px" }}>
          {activeStep === 0 &&
            <>
              <div style={{ display: "flex", flexDirection: "column", flex: "1", justifyContent: "center", color: "#5B6BE1", marginLeft: "2%" }}>
                <h4 style={{ borderBottom: "2px solid #5B6BE1", width: "fit-content", fontWeight: "bold" }}>
                  Material Produced
                </h4>
              </div>
              <button
                className={styles.buttonsDivButton}
                onClick={handleNextStep}
              >
                Next
              </button>
              <button
                variant="contained"
                className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                  }`}
                onClick={handleProdBackButton}>
                Cancel
              </button>
            </>
          }
          {activeStep === 1 &&
            <>
              {isCsp ? (
                <>
                  <div
                    style={{
                      marginTop: "0px",
                      display: "flex",
                      flexDirection: "row",
                      flex: "1",
                    }}
                  >
                    <button
                      className={`${styles2["incomingButton"]} ${isCsp ? styles2["incoming"] : styles2["not-incoming"]
                        }`}
                      onClick={handleCspClick}
                    >
                      CSP
                    </button>
                    <button
                      className={`${styles2["outgoingButton"]} ${!isCsp ? styles2["outgoing"] : styles2["not-outgoing"]
                        }`}
                      onClick={handleInventoryClick}
                    >
                      Inventory
                    </button>
                  </div>

                  <button
                    className={`${styles["buttonsDivButton"]} ${styles["save"]
                      }`}
                    onClick={handleSaveUnified}
                    disabled={hasErrors || hasErrors2 || isLoading}
                    style={{ opacity: hasErrors || hasErrors2 ? 0.8 : 1 }} // Reduce opacity when disabled
                  >
                    Save
                  </button>
                  <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                      }`}
                    onClick={handleBackStep}>
                    Back
                  </button>
                  <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                      }`}
                    onClick={handleProdBackButton}>
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <div
                    style={{
                      marginTop: "0px",
                      display: "flex",
                      flexDirection: "row",
                      flex: "1",
                    }}
                  >
                    <button
                      className={`${styles2["incomingButton"]} ${isCsp ? styles2["incoming"] : styles2["not-incoming"]
                        }`}
                      onClick={handleCspClick}
                    >
                      CSP
                    </button>
                    <button
                      className={`${styles2["outgoingButton"]} ${!isCsp ? styles2["outgoing"] : styles2["not-outgoing"]
                        }`}
                      onClick={handleInventoryClick}
                    >
                      Inventory
                    </button>
                  </div>

                  <button
                    className={`${styles["buttonsDivButton"]} ${styles["save"]
                      }`}
                    onClick={handleSaveUnified}
                    disabled={hasErrors || hasErrors2 || isLoading}
                    style={{ opacity: hasErrors || hasErrors2 ? 0.8 : 1 }} // Reduce opacity when disabled
                  >
                    Save
                  </button>
                  <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                      }`}
                    onClick={handleBackStep}>
                    Back
                  </button>
                  <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]
                      }`}
                    onClick={handleProdBackButton}>
                    Cancel
                  </button>
                </>
              )}
            </>
          }
        </div>

        {activeStep === 0 &&
          <>
            <div className={styles.buttonsdiv}>
              <div style={{ display: "flex", flexDirection: "row", flex: "1", color: "#5B6BE1", marginLeft: "2%" }}>
                <h6 style={{ width: "fit-content" }}>
                  Material being produced will be* :
                </h6>
                <div
                  className={styles.optionsListInner}
                  style={{
                    pointerEvents: formdata1.Scope === "Assembly" ? "none" : "all",
                    cursor: formdata1.Scope === "Assembly" ? "default" : "pointer",
                    opacity: formdata1.Scope === "Assembly" ? 0.4 : 1,
                  }}
                >
                  <input
                    type="radio"
                    name="Production_Type"
                    value="WIP"
                    checked={prodType === "WIP"}
                    onClick={(e) => handleProdTypeRadioChange(e)}
                  />
                </div>
                <h6 style={{ whiteSpace: "nowrap", marginLeft: "8px", opacity: formdata1.Scope === "Assembly" ? 0.4 : 1 }}>WIP material</h6>
                <div
                  className={styles.optionsListInner}
                  style={{
                    pointerEvents: formdata1.Scope === "D&I" ? "none" : "all",
                    cursor: formdata1.Scope === "D&I" ? "default" : "pointer",
                    opacity: formdata1.Scope === "D&I" ? 0.4 : 1,
                  }}
                >
                  <input
                    type="radio"
                    name="Production_Type"
                    value="FG"
                    checked={prodType === "FG"}
                    onClick={(e) => handleProdTypeRadioChange(e)}
                  />
                </div>
                <h6 style={{ whiteSpace: "nowrap", marginLeft: "8px", opacity: formdata1.Scope === "D&I" ? 0.4 : 1 }}>Finished goods (to be dispatched to customer)</h6>
              </div>
            </div>
            <div className={styles.buttonsdiv}
              style={{
                marginTop: "45px",
              }}
            >
              <div style={{ display: "flex", flex: "1", color: "#5B6BE1", marginLeft: "2%" }}>
                <h4 style={{ borderBottom: "2px solid #5B6BE1", width: "fit-content", fontWeight: "bold" }}>
                  Details
                </h4>
              </div>
            </div>

            <div className={styles.smtablecontainer} style={containerStyle}>
              <table ref={containerRef} className={styles.smtable}>
                <thead>
                  <tr className={styles.headrowsm}>
                    <th className={styles.fileUploadheading}>S.No</th>
                    <th className={styles.fileUploadheading}>Item Name</th>
                    <th className={styles.fileUploadheading}>Part Number</th>
                    {!(formdata1.Scope === "D&I") && (
                      <th className={styles.fileUploadheading}>Ergon Traceability Number</th>
                    )}
                    {(formdata1.Scope === "D&I") && (
                      <>
                        <th className={styles.fileUploadheading}>Part Traceability Number</th>
                        <th className={styles.fileUploadheading}>Equipment Traceability Number</th>
                      </>
                    )}
                    <th className={styles.fileUploadheading}>Qty</th>
                    <th className={styles.fileUploadheading}>UoM</th>
                  </tr>
                </thead>
                <tbody>
                  {formDataProduced.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div>
                            {item.S_No}
                          </div>
                          <div>
                            {addItemMode && index === formDataProduced.length - 1 ? (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="24"
                                  viewBox="0 0 26 24"
                                  fill="none"
                                  onClick={saveItem}
                                  style={{ cursor: "pointer" }}
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                                    fill="#66D171"
                                  />
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  onClick={cancelAddItem}
                                  style={{ cursor: "pointer" }}
                                >
                                  <path
                                    d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                    fill="#F97575"
                                  />
                                  <path
                                    d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                    fill="#F97575"
                                  />
                                </svg>
                              </>
                            ) : editItemMode === index ? (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="24"
                                  viewBox="0 0 26 24"
                                  fill="none"
                                  onClick={saveEditItem}
                                  style={{ cursor: "pointer" }}
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                                    fill="#66D171"
                                  />
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  onClick={cancelEditItem}
                                  style={{ cursor: "pointer" }}
                                >
                                  <path
                                    d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                    fill="#F97575"
                                  />
                                  <path
                                    d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                    fill="#F97575"
                                  />
                                </svg>
                              </>
                            ) : (
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div
                                  onClick={() => editItem(index)}
                                  style={{
                                    cursor: isButtonDisabled ? "default" : "pointer",
                                    pointerEvents: isButtonDisabled ? "none" : "all"
                                  }}
                                  disabled={isButtonDisabled}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 18 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z"
                                      fill="#5B6BE1"
                                    />
                                  </svg>
                                </div>
                                <div
                                  onClick={() => deleteItem(index)}
                                  disabled={isButtonDisabled}
                                  style={{
                                    marginLeft: "8px",
                                    cursor: isButtonDisabled ? "default" : "pointer",
                                    pointerEvents: isButtonDisabled ? "none" : "all"
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                      fill="#5B6BE1"
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        {editItemMode === index || (addItemMode && index === formDataProduced.length - 1) ? (
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              id="inputItemName"
                              name="Item_Name"
                              value={item.Item_Name}
                              onChange={(e) => {
                                handleInputChange(index, e);
                                setDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                              }}
                            />
                            {isDropdownVisible[index] && item.Item_Name && (
                              <div id="myDropdown" className={styles.dropdown}>
                                {itemsMaster
                                  .filter((masterItem) =>
                                    masterItem.Item_Name && masterItem.Item_Name.toLowerCase().includes((item.Item_Name || '').toLowerCase())
                                  )
                                  .map((masterItem) => (
                                    <div
                                      key={masterItem.Item_Id}
                                      onClick={() => handleDropdownSelection(masterItem, index)}
                                      className={styles.dropdownItem}>
                                      {masterItem.Item_Name}
                                    </div>
                                  ))}
                                <div
                                  className={styles.dropdownItem}
                                  style={{ color: "#567191", fontWeight: "700" }}
                                  onClick={() => handleAddToItemsMaster(index)}>
                                  {`+ Add "${item.Item_Name}" to Items Master List`}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : item.Item_Name ? (
                          item.Item_Name
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {editItemMode === index || (addItemMode && index === formDataProduced.length - 1) ? (
                          <input
                            type="text"
                            className={styles.inputFieldsm}
                            name="Part_Number"
                            value={item.Part_Number}
                            onChange={(e) => handleInputChange(index, e)}
                            disabled={isPartNoDisabled[index]}
                          />
                        ) : item.Part_Number ? (
                          item.Part_Number
                        ) : (
                          "-"
                        )}
                      </td>
                      {!(formdata1.Scope === "D&I") && (
                        <td>
                          {editItemMode === index || (addItemMode && index === formDataProduced.length - 1) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Ergon_Traceability_Number"
                              value={item.Ergon_Traceability_Number}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          ) : item.Ergon_Traceability_Number ? (
                            item.Ergon_Traceability_Number
                          ) : (
                            "-"
                          )}
                        </td>
                      )}
                      {(formdata1.Scope === "D&I") && (
                        <td>
                          {editItemMode === index || (addItemMode && index === formDataProduced.length - 1) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Part_Traceability_Number"
                              value={item.Part_Traceability_Number}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          ) : item.Part_Traceability_Number ? (
                            item.Part_Traceability_Number
                          ) : (
                            "-"
                          )}
                        </td>
                      )}
                      {(formdata1.Scope === "D&I") && (
                        <td>
                          {editItemMode === index || (addItemMode && index === formDataProduced.length - 1) ? (
                            <input
                              type="text"
                              className={styles.inputFieldsm}
                              name="Equipment_Traceability_Number"
                              value={item.Equipment_Traceability_Number}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          ) : item.Equipment_Traceability_Number ? (
                            item.Equipment_Traceability_Number
                          ) : (
                            "-"
                          )}
                        </td>
                      )}
                      <td>
                        {editItemMode === index || (addItemMode && index === formDataProduced.length - 1) ? (
                          <input
                            type="number"
                            className={styles.inputFieldsm}
                            style={{ width: "80px" }}
                            name="Qty_Produced"
                            value={item.Qty_Produced}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        ) : item.Qty_Produced ? (
                          item.Qty_Produced
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {editItemMode === index || (addItemMode && index === formDataProduced.length - 1) ? (
                          <>
                            <select
                              className={styles.inputFieldsm}
                              style={{ paddingRight: "30px" }}
                              name="UoM"
                              value={item.UoM}
                              onChange={(e) => handleInputChange(index, e)}
                            >
                              <option value="">Select Unit</option>
                              <option value="pcs">pcs</option>
                              <option value="kg">kg</option>
                            </select>
                            <svg
                              style={{ marginLeft: "-25px", marginTop: "-1%", pointerEvents: "none" }}
                              width="24"
                              height="24"
                              viewBox="0 0 28 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                fill="black"
                              />
                            </svg>
                          </>
                        ) : item.UoM ? (
                          item.UoM
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button
              className={styles.manualbuttonsm}
              onClick={handleAddItem}
              disabled={isButtonDisabled || editItemMode}>
              Add More Items
              <svg
                style={{ marginLeft: "10px" }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.493 1.66669H6.50964C3.4763 1.66669 1.66797 3.47502 1.66797 6.50835V13.4834C1.66797 16.525 3.4763 18.3334 6.50964 18.3334H13.4846C16.518 18.3334 18.3263 16.525 18.3263 13.4917V6.50835C18.3346 3.47502 16.5263 1.66669 13.493 1.66669ZM15.0013 10.625H10.6263V15C10.6263 15.3417 10.343 15.625 10.0013 15.625C9.65964 15.625 9.3763 15.3417 9.3763 15V10.625H5.0013C4.65964 10.625 4.3763 10.3417 4.3763 10C4.3763 9.65835 4.65964 9.37502 5.0013 9.37502H9.3763V5.00002C9.3763 4.65835 9.65964 4.37502 10.0013 4.37502C10.343 4.37502 10.6263 4.65835 10.6263 5.00002V9.37502H15.0013C15.343 9.37502 15.6263 9.65835 15.6263 10C15.6263 10.3417 15.343 10.625 15.0013 10.625Z"
                  fill={isButtonDisabled ? '#5B6BE1' : 'white'}
                />
              </svg>
            </button>
          </>
        }

        {activeStep === 1 &&
          <div style={{ marginLeft: "2vw" }}>
            {isCsp ? (
              <>
                <div className={styles2.itemstablecontainer}>
                  <table className={styles2.itemstable}>
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Item Name</th>
                        <th>Part Number</th>
                        <th>UoM</th>
                        <th>BOE</th>
                        <th>Traceability Number</th>
                        <th>Available Qty</th>
                        <th>Qty Used</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredItems.length === 0 ? (
                        <tr>
                          <td colSpan="8" style={{ textAlign: 'center' }}>No results found</td>
                        </tr>
                      ) : (
                        filteredItems.map((item, index) => {
                          const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
                          const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
                          return (
                            <tr key={key}>
                              <td>{index + 1}</td>
                              <td>{item.Item_Name}</td>
                              <td>{item.Part_Number}</td>
                              <td>{item.UOM}</td>
                              <td>{billEntries[item.Incoming_Delivery_ID]}</td>
                              <td>{item.Traceability_Number}</td>
                              <td>{item.Quantity_Received - totalQtyUsed}</td>
                              <td>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                  <input
                                    type="number"
                                    name="csp"
                                    style={{
                                      border: "1px solid #5B6BE1",
                                      maxWidth: "55px",
                                      outline: "none",
                                      marginBottom: "5px",
                                    }}
                                    value={inputValues[item.S_No] || ""} // Use value from state or empty string if undefined
                                    onChange={(e) => handleOnChange2(e, item, index)}
                                  />
                                </div>
                                {errors[item.S_No] && (
                                  <div style={{ color: "red", fontSize: "0.8rem", fontWeight: "normal" }}>
                                    {errors[item.S_No]}
                                  </div>
                                )}
                              </td>

                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className={styles2.itemstablecontainer}>
                  <table className={styles2.itemstable}>
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Item Name</th>
                        <th>Part Number</th>
                        <th>UoM</th>
                        <th>BOE</th>
                        <th>Traceability Number</th>
                        <th>Available Qty</th>
                        <th>Qty Used</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inventoryUtilisedItems.length === 0 ? (
                        <tr>
                          <td colSpan="8" style={{ textAlign: 'center' }}>No results found</td>
                        </tr>
                      ) : (
                        inventoryUtilisedItems.map((item, index) => {
                          const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
                          const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
                          return (
                            <tr key={key}>
                              <td>{index + 1}</td>
                              <td>{item.Item_Name}</td>
                              <td>{item.Part_Number}</td>
                              <td>{item.UoM}</td>
                              <td>{billEntries[item.Incoming_Delivery_ID]}</td>
                              <td>{item.Traceability_Number}</td>
                              <td>{item.Qty_Utilized - totalQtyUsed}</td>
                              <td>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                  <input
                                    type="number"
                                    name="Inventory"
                                    style={{
                                      border: "1px solid #5B6BE1",
                                      maxWidth: "55px",
                                      outline: "none",
                                      marginBottom: "5px",
                                    }}
                                    onChange={(e) => handleOnChange3(e, item, index)}
                                    value={inputValues2[item.S_No] || ""}
                                  />
                                </div>
                                {errors2[item.S_No] && (
                                  <div style={{ color: "red", fontSize: "0.8rem", fontWeight: "normal" }}>
                                    {errors2[item.S_No]}
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        }
      </div>
    </div>
  );
}

export default Production_Create_New
