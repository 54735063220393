import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/sparestradingworkshopdeliveriesupdate.module.css";

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparestradingWorkshopDeliveriesUpdate() {
  const { id, Sales_Order_No, Delivery_ID } = useParams();
  const navigate = useNavigate();
  const [projectDetails, setProjectDetails] = useState({});
  const [deliveryDetails, setDeliveryDetails] = useState({});
  const [deliveryDetails2, setDeliveryDetails2] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [inspectionID, setInspectionID] = useState({});

  const [DeliveryDate, setDeliveryDate] = useState("");
  const [CIPLDocNo, setCIPLDocNo] = useState("");
  const [CIPLDate, setCIPLDate] = useState("");
  const [ExitBillNo, setExitBillNo] = useState("");
  const [ExitBillDate, setExitBillDate] = useState("");

  const [CIPLdoc, setCIPLdoc] = useState();
  const [ExitBillDoc, setExitBillDoc] = useState();
  const [POD, setPOD] = useState();

  // State for initial values to reset on cancel
  const [initialValues, setInitialValues] = useState({});

  const [removeCIPLdoc, setRemoveCIPLdoc] = useState(false);
  const [removeExitBillDoc, setRemoveExitBillDoc] = useState(false);
  const [removePOD, setRemovePOD] = useState(false);

  const [activeTab, setActiveTab] = useState('documents'); // Default to documents tab
  const [itemsData, setItemsData] = useState([]); // Placeholder for Items Tab data

  const [dispatchStatus, setDispatchStatus] = useState(false); // For Mark Dispatched checkbox
  const [finalDispatchStatus, setFinalDispatchStatus] = useState('');
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );
        setProjectDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching items data:", error);
      }
    };
    fetchProjectDetails();

    const updateAndFetchDeliveryDetails = async () => {
      try {
        // Step 2: GET request to fetch the updated delivery details
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_delivery_update/${Delivery_ID}`
        );

        setDeliveryDetails(response.data.data);
        setDeliveryDate(response.data.data.Delivery_Date);
        setCIPLDocNo(response.data.data.CIPL_No);
        setCIPLDate(response.data.data.CIPL_Date);
        setExitBillNo(response.data.data.BOE_No);
        setExitBillDate(response.data.data.Exit_Bill_Date);
        setDispatchStatus(response.data.data.Dispatch_Status);

        // Set the initial values for cancel functionality
        setInitialValues({
          DeliveryDate: response.data.data.Delivery_Date,
          CIPLDocNo: response.data.data.CIPL_No,
          CIPLDate: response.data.data.CIPL_Date,
          ExitBillNo: response.data.data.BOE_No,
          ExitBillDate: response.data.data.Exit_Bill_Date,
          dispatchStatus: response.data.data.Dispatch_Status,
        });
      } catch (error) {
        console.error("Error fetching delivery details:", error);
      }
    };
    updateAndFetchDeliveryDetails();



    const fetchDeliveryDetails2 = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/delivery/${id}`
        );
        console.log("response2", response)
        setDeliveryDetails2(response.data); // Assume this endpoint returns item data
      } catch (error) {
        console.error("Error fetching items data:", error);
      }
    };
    fetchDeliveryDetails2();

    const Dispatch_ID = parseInt(Delivery_ID) + 67000;
    const fetchItemsData = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/fetch_dispatch/${Dispatch_ID}`
        );
        console.log("itemsdata", response.data)
        setItemsData(response.data); // Assume this endpoint returns item data
      } catch (error) {
        console.error("Error fetching items data:", error);
      }
    };
    fetchItemsData();

    const fetchInspection = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_inspection_id/${Delivery_ID}`
        );
        console.log("inspection id", response)
        setInspectionID(response.data.data); // Assume this endpoint returns item data
      } catch (error) {
        console.error("Error fetching items data:", error);
      }
    };
    fetchInspection();
  }, [Sales_Order_No, id, Delivery_ID]);

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

  const fileNameFromLink = (link) => {
    return link ? link.split('/').pop().split('-').slice(1).join('-') : '';
  };

  const handleforwarddoc = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = ""; // Set desired filename
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };

  console.log("deliveryDetails", deliveryDetails);
  console.log("POD", POD);

  const handleEditClick = () => setIsEditing(true);

  const handleSaveClick = async (e) => {
    e.preventDefault(); // Prevent form submission default behavior
    setIsEditing(false); // Disable editing mode

    const deliveryDateObj = new Date(DeliveryDate); // Delivery date object
    const currentDateObj = new Date(currentDate); // Current date object

    // Check if POD document exists (for Delivered status)
    const isDelivered = !!POD || !!deliveryDetails.POD;

    // Determine the final status based on the given conditions
    let finalDispatchStatus;

    if (deliveryDateObj > currentDateObj) {
      // Upcoming: Delivery date is greater than today
      finalDispatchStatus = "Upcoming";
    } else if (
      deliveryDateObj <= currentDateObj &&
      dispatchStatus &&
      !isDelivered
    ) {
      // Dispatched: Mark Dispatched has been selected
      finalDispatchStatus = "Dispatched";
    } else if (
      (deliveryDateObj <= currentDateObj && !dispatchStatus) ||
      !isDelivered
    ) {
      // Pending: Delivery date is less than or equal to today but not dispatched or delivered
      finalDispatchStatus = "Pending";
    } else if (
      isDelivered &&
      dispatchStatus &&
      deliveryDateObj <= currentDateObj
    ) {
      // Delivered: POD document has been uploaded
      finalDispatchStatus = "Delivered";
    } else {
      // Fallback to previous delivery status if no conditions are met
      finalDispatchStatus = deliveryDetails.Delivery_Status;
    }

    // Create the payload for the PUT request (ensure field names match the backend)
    const payload = {
      Delivery_Date: DeliveryDate || deliveryDetails.Delivery_Date,
      CIPL_No: CIPLDocNo || deliveryDetails.CIPL_No, // Ensure this matches with the DB field name
      CIPL_Date: CIPLDate || deliveryDetails.CIPL_Date,
      BOE_No: ExitBillNo || deliveryDetails.BOE_No, // Ensure this matches with the DB field name
      Exit_Bill_Date: ExitBillDate || deliveryDetails.Exit_Bill_Date,
      // CIPL_Document: CIPLdoc || deliveryDetails.CIPL_Document,
      // Exit_Bill_Doc: ExitBillDoc || deliveryDetails.Exit_Bill_Doc,
      Delivery_Status: finalDispatchStatus, // Updated dispatch status
      Dispatch_Status: dispatchStatus,
    };
    const deliveryDatePayload = {
      Delivery_date: DeliveryDate || deliveryDetails.Delivery_Date,
    }
    const dispatch_id = parseInt(Delivery_ID) + 67000;

    // Check if files are to be removed
    if (removeCIPLdoc) payload.CIPL_Document = null;
    if (removeExitBillDoc) payload.Exit_Bill_Doc = null;


    const payloadfiles = {
      POD: POD,
      Exit_Bill_Doc: ExitBillDoc,
      CIPL_Document: CIPLdoc,
    };

    console.log(payloadfiles);

    try {
      // PUT request to update the delivery details
      await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_delivery_update/${Delivery_ID}`,
        payload
      );

      const deliveryDateRes = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_delivery_to_dispatch_date_update?Dispatch_ID=${dispatch_id}`,
        deliveryDatePayload
      )

      const resFiles = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_outgoing_deliveries_files?Delivery_ID=${Delivery_ID}`,
        payloadfiles,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // GET request to fetch the updated delivery details
      const response = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_delivery_update/${Delivery_ID}`
      );

      // Update the state with the latest delivery details
      setDeliveryDetails(response.data.data);

      // Update the initial values after save
      setInitialValues({
        DeliveryDate: response.data.data.Delivery_Date,
        CIPLDocNo: response.data.data.CIPL_No,
        CIPLDate: response.data.data.CIPL_Date,
        ExitBillNo: response.data.data.BOE_No,
        ExitBillDate: response.data.data.Exit_Bill_Date,
        dispatchStatus: response.data.data.Dispatch_Status,
      });

    } catch (error) {
      console.error("Error saving delivery details:", error);
    }
  };

  const handleCancelClick = () => {
    // Revert to the initial values
    setDeliveryDate(initialValues.DeliveryDate);
    setCIPLDocNo(initialValues.CIPLDocNo);
    setCIPLDate(initialValues.CIPLDate);
    setExitBillNo(initialValues.ExitBillNo);
    setExitBillDate(initialValues.ExitBillDate);
    setDispatchStatus(initialValues.dispatchStatus);
    setIsEditing(false);
  };



  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    if (field === "CIPL_Doc") setCIPLdoc(file);
    if (field === "Exit_Bill_Doc") setExitBillDoc(file);
    if (field === "POD") setPOD(file);
  };

  const handleRemoveFile = (field) => {
    if (field === "CIPL_Doc") {
      setCIPLdoc("");
      setRemoveCIPLdoc(true);
    }
    if (field === "Exit_Bill_Doc") {
      setExitBillDoc("");
      setRemoveExitBillDoc(true);
    }
    if (field === "POD") {
      setPOD("");
      setRemovePOD(true);
    }

  };

  const getStatusColor = () => {
    const status = deliveryDetails.Delivery_Status || finalDispatchStatus;

    if (status === "Dispatched") return styles.dispatched;
    if (status === "Delivered") return styles.delivered;
    if (status === "Upcoming") return styles.upcoming;
    return styles.pending;
  };



  const handleBackButtonIncoming = () => {
    navigate(`/workshop-spares-trading-deliveries/${id}`);
  };

  const navInspect = parseInt(Delivery_ID) + 20000;
  const handleInspection = () => {
    navigate(`/workshop-spares-trading-outgoing-inspections-update/${id}/${navInspect}`);
  }

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // YYYY-MM-DD format
    setCurrentDate(formattedDate);
  }, []);

  const handledownloadfile = (fileUrl) => {
    if (fileUrl !== null) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = ""; // You can set a filename here
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("There is no file to download");
    }
  };

  const handleViewfile = (documents) => {

  };


  const renderDocumentsTab = () => (
    <div>
      {/* Your existing document fields and logic go here */}
      <div>
        {/* Edit/Save Buttons */}
        {!isEditing ? (
          <button className={styles.editButton} onClick={handleEditClick}>
            Edit
          </button>
        ) : (
          <>
            <button className={styles.saveButton} onClick={handleSaveClick}>
              Save
            </button>
            <button className={styles.cancelButton} onClick={handleCancelClick}>
              Cancel
            </button>
          </>
        )}

        <div className={styles.form}>
          {/* Delivery Date */}
          <div className={styles.formRowContainer}>
            <label className={styles.label}>Delivery Date*:</label>
            <input
              type="date"
              className={isEditing ? styles.textbox1 : styles.textbox2}
              value={DeliveryDate}
              disabled={!isEditing}
              onChange={(e) => setDeliveryDate(e.target.value)}
            />
            {isEditing && DeliveryDate <= currentDate && (
              <>
                <input
                  type="checkbox"
                  checked={dispatchStatus}
                  style={{ marginLeft: "30px", width: "12px", height: "12px" }}
                  onChange={(e) => setDispatchStatus(e.target.checked)}
                />
                <label
                  style={{
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: 600,
                  }}
                >
                  Mark Dispatched
                </label>
              </>
            )}
          </div>

          {/* CIPL Details */}
          <h3 className={styles.sectionHeader}>CIPL</h3>
          <div className={styles.formRowContainer}>
            <label className={styles.label}>Document Number:</label>
            <input
              type="number"
              className={isEditing ? styles.textbox1 : styles.textbox2}
              value={CIPLDocNo}
              disabled={!isEditing}
              onChange={(e) => setCIPLDocNo(e.target.value)}
            />
          </div>
          <div className={styles.formRowContainer}>
            <label className={styles.label}>Document Date:</label>
            <input
              type="date"
              className={isEditing ? styles.textbox1 : styles.textbox2}
              value={CIPLDate}
              disabled={!isEditing}
              onChange={(e) => setCIPLDate(e.target.value)}
            />
          </div>
          <div>
            {isEditing ? (
              <div className={styles.formRowContainer}>
                <label className={styles.label}>Upload Document:</label>
                <input
                  type="file"
                  className={
                    isEditing ? styles.inputupload : styles.inputupload2
                  }
                  style={{ display: "none", width: "0" }}
                  name="CIPL_Doc"
                  onInput={(e) => handleFileChange(e, "CIPL_Doc")}
                  id="CIPL_Doc"
                  disabled={!isEditing}
                />
                <div
                  className={
                    isEditing ? styles.inputupload : styles.inputupload2
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => document.getElementById("CIPL_Doc").click()}
                >
                  <span>
                    {CIPLdoc?.name ||
                      fileNameFromLink(deliveryDetails.CIPL_Document) ||
                      "Upload File"}
                  </span>
                </div>
                <svg
                  width="28"
                  height="25"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginLeft: "-35px",
                    pointerEvents: "none",
                  }}
                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>
              </div>
            ) : (
              <div className={styles.formRowContainer}>
                <label className={styles.label}>Upload Document:</label>

                {deliveryDetails.CIPL_Document ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      className={
                        isEditing ? styles.inputupload : styles.inputupload2
                      }
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {fileNameFromLink(deliveryDetails.CIPL_Document)}
                    </div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={styles.svgunshrinkable}
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      onClick={() => handleforwarddoc(deliveryDetails.CIPL_Document)}
                    >
                      <path
                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                        fill="#292D32"
                      />
                    </svg>
                    <a href={deliveryDetails.CIPL_Document} target=" _blank">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                          fill="black"
                        />
                        <path
                          d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                          fill="black"
                        />
                      </svg>
                    </a>
                  </div>
                ) : (
                  <div
                    className={
                      isEditing ? styles.inputupload : styles.inputupload2
                    }
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {"No file chosen"}
                  </div>
                )}
              </div>
            )}
          </div>


          {/* Exit Bill Details */}
          <h3 className={styles.sectionHeader}>Exit Bill</h3>
          <div className={styles.formRowContainer}>
            <label className={styles.label}>Document Number:</label>
            <input
              type="number"
              className={isEditing ? styles.textbox1 : styles.textbox2}
              value={ExitBillNo}
              disabled={!isEditing}
              onChange={(e) => setExitBillNo(e.target.value)}
            />
          </div>
          <div className={styles.formRowContainer}>
            <label className={styles.label}>Document Date:</label>
            <input
              type="date"
              className={isEditing ? styles.textbox1 : styles.textbox2}
              value={ExitBillDate}
              disabled={!isEditing}
              onChange={(e) => setExitBillDate(e.target.value)}
            />
          </div>
          <div>
            {isEditing ? (
              <div className={styles.formRowContainer}>
                <label className={styles.label}>Upload Document:</label>
                <input
                  type="file"
                  className={
                    isEditing ? styles.inputupload : styles.inputupload2
                  }
                  style={{ display: "none", width: "0" }}
                  name="Exit_Bill_Doc"
                  onInput={(e) => handleFileChange(e, "Exit_Bill_Doc")}
                  id="Exit_Bill_Doc"
                  disabled={!isEditing}
                />
                <div
                  className={
                    isEditing ? styles.inputupload : styles.inputupload2
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => document.getElementById("Exit_Bill_Doc").click()}
                >
                  <span>
                    {ExitBillDoc?.name ||
                      fileNameFromLink(deliveryDetails.Exit_Bill_Doc) ||
                      "Upload File"}
                  </span>
                </div>
                <svg
                  width="28"
                  height="25"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginLeft: "-35px",
                    pointerEvents: "none",
                  }}
                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>
              </div>
            ) : (
              <div className={styles.formRowContainer}>
                <label className={styles.label}>Upload Document:</label>

                {deliveryDetails.Exit_Bill_Doc ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      className={
                        isEditing ? styles.inputupload : styles.inputupload2
                      }
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {fileNameFromLink(deliveryDetails.Exit_Bill_Doc)}
                    </div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={styles.svgunshrinkable}
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      onClick={() => handleforwarddoc(deliveryDetails.Exit_Bill_Doc)}
                    >
                      <path
                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                        fill="#292D32"
                      />
                    </svg>
                    <a href={deliveryDetails.Exit_Bill_Doc} target=" _blank">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                          fill="black"
                        />
                        <path
                          d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                          fill="black"
                        />
                      </svg>
                    </a>
                  </div>
                ) : (
                  <div
                    className={
                      isEditing ? styles.inputupload : styles.inputupload2
                    }
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {"No file chosen"}
                  </div>
                )}
              </div>
            )}
          </div>


          {/* POD Details */}
          <h3 className={styles.sectionHeader}>POD</h3>
          <div>
            {isEditing ? (
              <div className={styles.formRowContainer}>
                <label className={styles.label}>Upload Document:</label>
                <input
                  type="file"
                  className={
                    isEditing ? styles.inputupload : styles.inputupload2
                  }
                  style={{ display: "none", width: "0" }}
                  name="POD"
                  onInput={(e) => handleFileChange(e, "POD")}
                  id="POD"
                  disabled={!isEditing}
                />
                <div
                  className={
                    isEditing ? styles.inputupload : styles.inputupload2
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => document.getElementById("POD").click()}
                >
                  <span>
                    {POD?.name ||
                      fileNameFromLink(deliveryDetails.POD) ||
                      "Upload File"}
                  </span>
                </div>
                <svg
                  width="28"
                  height="25"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginTop: "0.5%",
                    marginLeft: "-35px",
                    pointerEvents: "none",
                  }}
                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>

              </div>
            ) : (
              <div className={styles.formRowContainer}>
                <label className={styles.label}>Upload Document:</label>

                {deliveryDetails.POD ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      className={
                        isEditing ? styles.inputupload : styles.inputupload2
                      }
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {fileNameFromLink(deliveryDetails.POD)}
                    </div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={styles.svgunshrinkable}
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      onClick={() => handleforwarddoc(deliveryDetails.POD)}
                    >
                      <path
                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                        fill="#292D32"
                      />
                    </svg>
                    <a href={deliveryDetails.POD} target=" _blank">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                          fill="black"
                        />
                        <path
                          d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                          fill="black"
                        />
                      </svg>
                    </a>
                  </div>
                ) : (
                  <div
                    className={
                      isEditing ? styles.inputupload : styles.inputupload2
                    }
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {"No file chosen"}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const renderItemsTab = () => (
    <div className={styles.itemstablecontainer}>
      <table className={styles.itemstable}>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Item Name</th>
            <th>Part Number</th>
            <th>UOM</th>
            <th>Scheduled Dispatch Qty</th>
            {/* <th>Actual Dispatch Qty</th> */}
          </tr>
        </thead>
        <tbody>
          {itemsData.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.Item_Name}</td>
              <td>{item.Part_Number}</td>
              <td>{item.UoM}</td>
              <td>{item.Dispatch_qty}</td>
              {/* <td>{item.Stock_Qty}</td>
            <td>{item.Actual_Outgoing_Qty}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className={styles.mainbody}>
      <div className={styles.topButtonContainer}>
        <button
          className={styles.topBackButton}
          onClick={handleBackButtonIncoming}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
              fill="#5B6BE1"
            />
          </svg>{" "}
          Outgoing Deliveries
        </button>
      </div>

      <>
        <div className={styles.topdiv}>
          <label className={styles.projectDiv}>
            SO : {Sales_Order_No || "-"}
          </label>
          <label className={styles.projectDiv}>
            PO :{" "}
            {projectDetails[0]?.Sales_Order_No
              ? projectDetails[0]?.Sales_Order_No
              : "-"}{" "}
          </label>
          <label className={styles.projectDiv}>
            Customer Name :{" "}
            {projectDetails[0]?.Customer_Name
              ? projectDetails[0]?.Customer_Name
              : "-"}
          </label>
          <label className={`${styles.deliveryStatusDiv} ${getStatusColor()}`}>
            Delivery Status : {deliveryDetails.Delivery_Status || "PENDING"}
          </label>
          <label className={styles.projectDiv}>
            Delivery ID :{" "}
            {inspectionID[0]?.Delivery_ID ? inspectionID[0]?.Delivery_ID : "-"}
          </label>
          <label className={styles.projectDiv}>
            <span>Inspection ID :{" "}
              {inspectionID[0]?.Inspection_ID
                ? inspectionID[0]?.Inspection_ID
                : "-"}</span>
            <svg
              onClick={handleInspection}
              width="20" height="20" viewBox="0 0 24 24" style={{ marginLeft: "10px", pointerEvents: "all", cursor: "pointer", marginTop: "0.5%" }}>
              <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
            </svg>
          </label>
        </div>
      </>

      <div className={styles.tabContainer}>
        <button
          className={activeTab === "documents" ? styles.activeTab : styles.tab}
          style={{ borderRadius: "12px 0 0 12px" }}
          onClick={() => handleTabSwitch("documents")}
        >
          Documents
        </button>
        <button
          className={activeTab === "items" ? styles.activeTab : styles.tab}
          style={{ borderRadius: "0 12px 12px 0" }}
          onClick={() => handleTabSwitch("items")}
        >
          Items
        </button>
      </div>

      <div className={styles.maindiv}>
        {activeTab === "documents" && renderDocumentsTab()}
        {activeTab === "items" && renderItemsTab()}
      </div>
    </div>
  );
}

export default SparestradingWorkshopDeliveriesUpdate;
