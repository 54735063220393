import { useEffect, useState } from "react";
import axios from "axios";
import styles from "../Components/css/projectreport.module.css";
import style from "./css/modal.module.css";
import * as XLSX from "xlsx";

import { Button } from "@mui/material";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

import excel from "./css/image/excel.png";

// stepper mui
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useParams } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';


const CustomModal = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const getSteps = () => {
    return ["Upload Issues", "Upload Success"];
};

function ToolBoxOffshoreProjects() {
    const [fileContent, setFileContent] = useState([]);

    const [rows, setRows] = useState([]);

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [removeItemId, setRemoveItemId] = useState([]);
    const [smResults, setsmResults] = useState([]);
    const [showInputRow, setShowInputRow] = useState(false);
    const [showeditRow, setShoweditRow] = useState(false);
    const [editModesm, seteditModesm] = useState(false);
    const [showEditButton, setShowEditButton] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [featureman, setfeatureman] = useState([]);
    const [featureedit, setfeatureedit] = useState([]);
    const [featureexcel, setfeatureexcel] = useState([]);

    const { id } = useParams();

    const [formDatac, setFormDatac] = useState({
        data: [],
    });

    const [activeStep, setActiveStep] = useState(0);

    const [filteredTable, setFilteredTable] = useState([]);
    const [falseFilteredTable, setFalseFilteredTable] = useState([]);
    const steps = getSteps();
    const [validate, setValidate] = useState({
        salesOrderValidate: [false],
        locationValidate: [false],
        partNumberValidate: [false],
        itemNameValidate: [false],
        unitsValidate: [false],
        quantityRequiredValidate: [false],
        availableQuantityValidate: [false],
        statusValidate: [false],
    });


    const handleManualInputClick = () => {
        setIsButtonDisabled(true);
        setShowInputRow(true);
        setShowEditButton(false)
    };

    const handleDeleteRow = (index) => {
        setShowEditButton(true)
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        setShowInputRow(false);
        setIsButtonDisabled(false);
    };


    const handleInputChange = (e, index) => {
        let { name, value } = e.target;

        const updatedData = [...formDatac.data];
        updatedData[index][name] = value;
        setFormDatac({
            ...formDatac,
            data: updatedData,
        });
    };

    const handleInputChangevis = (e) => {
        let { name, value } = e.target;
        console.log(name, value);
        setFormDatac((prev) => ({
            ...prev,
            [name]: value,
        }));
    };


    const handleRemove = async (sNo, ind) => {
        alert(`Removing item with S_No: ${ind}`);
        setRemoveItemId((prevRemoveId) => [...prevRemoveId, sNo]);
    };
    console.log(removeItemId)

    useEffect(() => {
        if (removeItemId?.length > 0) {
            const newItems = smResults.filter((item) => !removeItemId.includes(item.S_No));
            setsmResults([...newItems]);
            setFormDatac({

                data: newItems,
            });

        }
    }, [removeItemId]);


    const handleSubmitsm = async (e) => {
        setShowEditButton(true)
        e.preventDefault();

        const {
            Item_Name,
            Quantity,
            UOM,
            Status,
            Remarks
        } = formDatac;

        const payloadc = {
            ID_No: id,
            Item_Name: Item_Name,
            Quantity: Quantity,
            UOM: UOM,
            Status: Status,
            Remarks: Remarks
        };

        try {
            const res = await axios.post(
                "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolbox",
                payloadc
            );

            setFormDatac({
                ID_No: "",
                Item_Name: "",
                Quantity_Required: "",
                UOM: "",
                Status: "",
                Remarks: "",

            });

            if (res.status === 200) {
                alert("consumables Details Added Successfully");
                setRefresh(!refresh);
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }

        setIsButtonDisabled(false);
        setShowInputRow(false);
    };


    useEffect(() => {
        async function fetchRigDetails() {
            try {
                const response = await axios.get(
                    "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
                );

                // Retrieve group and roles from localStorage
                const group = localStorage.getItem("Group");
                const roles = JSON.parse(localStorage.getItem("Roles")) || [];

                // Ensure "Can View" overrides "Can Edit"
                const prioritizeAccess = (features) => {
                    const featureMap = {};
                    features.forEach((feature) => {
                        const key = `${feature.Header_Name}-${feature.Feature_Name}`;
                        if (!featureMap[key] || feature.Access === "Can View") {
                            featureMap[key] = feature;
                        }
                    });
                    return Object.values(featureMap);
                };

                // Combine group and role filters for a given feature
                const combineFeatures = (headerName, groupFeatureName, roleFeatureName) => {
                    const groupFeatures = response.data.groupFeatures.filter((el) => {
                        return (
                            el.Group_Name === group &&
                            el.Header_Name === headerName &&
                            el.Feature_Name === groupFeatureName &&
                            (el.Access === "Can View" || el.Access === "Can Edit")
                        );
                    });

                    const roleFeatures = response.data.roleFeatures.filter((el) => {
                        return (
                            roles.includes(el.Role_Name) &&
                            el.Header === headerName &&
                            el.Feature === roleFeatureName &&
                            el.Access === "Can View"
                        );
                    });

                    return [...groupFeatures, ...roleFeatures];
                };

                // Feature configuration for Consumable
                const featuresConfig = [
                    {
                        stateSetter: setfeatureexcel,
                        header: "Consumable",
                        groupFeature: "Import Using excel",
                        roleFeature: "Import Using excel",
                    },
                    {
                        stateSetter: setfeatureedit,
                        header: "Consumable",
                        groupFeature: "Edit",
                        roleFeature: "Edit",
                    },
                    {
                        stateSetter: setfeatureman,
                        header: "Consumable",
                        groupFeature: "Input Spares Manually",
                        roleFeature: "Input Spares Manually",
                    },
                ];

                // Process the features and set the state
                featuresConfig.forEach(({ stateSetter, header, groupFeature, roleFeature }) => {
                    const combinedFeatures = combineFeatures(header, groupFeature, roleFeature);
                    stateSetter(prioritizeAccess(combinedFeatures));
                });

            } catch (error) {
                console.error(error);
            }
        }

        fetchRigDetails();
    }, []);

    useEffect(() => {
        async function fetchsmDetails() {
            try {
                const response = await axios.get(
                    "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolbox_data2"
                );

                const filter = response.data.filter((el) => el.ID_No == id);

                setsmResults(filter);
                setFormDatac({
                    data: filter,
                });
            } catch (error) {
                console.error(error);
            }
        }

        fetchsmDetails();
    }, [refresh]);

    const handlesubmittt = () => {
        setFileContent([]);
        setOpen(false);
    }

    const handleNext = () => {
        setActiveStep(1);
        // filterData();
    };


    const handlesavesm = async (e) => {
        e.preventDefault();

        try {
            // Perform delete operation on backend for marked rows
            if (removeItemId.length > 0) {
                await axios.post(
                    "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/tooldetails_delete",
                    { removeItemId } // Pass array of S_No to backend
                );
                setRemoveItemId([]); // Clear marked rows after successful delete
            }
            for (const sm of smResults) {
                const index = smResults.findIndex((item) => item.S_No === sm.S_No);
                const {
                    Item_Name,
                    Quantity,
                    UOM,
                    Status,
                    Remarks,
                } = formDatac.data[index];

                const update = {
                    S_No: sm.S_No,
                    ID_No: id,
                    Item_Name,
                    Quantity,
                    UOM,
                    Status,
                    Remarks
                };

                const res = axios.put(
                    `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolbox_data_update/${sm.S_No}`,
                    [update]
                );

                if (res.status === 200) {
                    setRefresh(!refresh);
                }
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }

        setShoweditRow(false);
        seteditModesm(false);
    };

    const handleeditsm = () => {
        setShowInputRow(false);
        setShoweditRow(true);
        seteditModesm(true);

    };

    const handlecancelsm = () => {
        setShoweditRow(false);
        seteditModesm(false);
        setRefresh(!refresh);
    };

    const handleBack = () => {
        setActiveStep(0);
    };

    const handleCancel = () => {
        setFileContent([]);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (
                selectedFile.type === "application/vnd.ms-excel" ||
                selectedFile.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "binary" });
                    const firstSheet = workbook.SheetNames[0];
                    const sheetData = XLSX.utils.sheet_to_json(
                        workbook.Sheets[firstSheet],
                        { header: 1 }
                    );
                    setFileContent(sheetData);
                };
                reader.readAsBinaryString(selectedFile);
            } else if (selectedFile.type === "text/csv") {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const csvData = e.target.result;
                    const csvRows = csvData.split("\n");
                    setFileContent(csvRows.map((row) => row.split(",")));
                };
                reader.readAsText(selectedFile);
            } else {
                console.log("Invalid file type.");
            }
        }
    };


    const maxColumns = 7;

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    return (
        <>
            <div
                className={styles.fileUploadedBtns}
                style={{
                    border: "2px solid #E7EBFF",
                    borderTop: "none",
                    borderBottom: "none",
                }}>

                {showeditRow ? (
                    <>
                        <Button
                            variant="contained"
                            className={styles.saveButton}
                            onClick={handlecancelsm}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            className={styles.saveButton}
                            onClick={handlesavesm}>
                            Save
                        </Button>
                    </>
                ) : (
                    <>
                        {/* {featureexcel.length > 0 && showEditButton && (<Button
                            variant="contained"
                            className={styles.saveButton}
                            style={{ width: "200px" }}
                            onClick={setOpen}>
                            Import using excel
                            <img style={{ marginLeft: "5px" }} src={excel} alt="excelIcon" />
                        </Button>)} */}

                        {featureedit.length > 0 && showEditButton && (<Button
                            variant="contained"
                            className={styles.saveButton}
                            onClick={handleeditsm}>
                            Edit
                        </Button>)}
                    </>
                )}

            </div>
            {fileContent.length === 0 && (
                <CustomModal
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                    sx={{
                        backdropFilter: "blur(3px)",
                    }}>
                    <Fade in={open}>
                        <Box className={style.ModalBox}>
                            <div>
                                <svg
                                    onClick={handleClose}
                                    className={style.crossButton}
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M20.9499 22.3641L9.63615 11.0504C9.2496 10.6638 9.2496 10.0227 9.63615 9.63618C10.0227 9.24963 10.6638 9.24963 11.0504 9.63618L22.3641 20.9499C22.7506 21.3364 22.7506 21.9776 22.3641 22.3641C21.9775 22.7507 21.3364 22.7507 20.9499 22.3641Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M9.63592 22.3641C9.24937 21.9776 9.24937 21.3364 9.63592 20.9499L20.9496 9.63618C21.3362 9.24963 21.9773 9.24963 22.3638 9.63618C22.7504 10.0227 22.7504 10.6638 22.3638 11.0504L11.0501 22.3641C10.6636 22.7507 10.0225 22.7507 9.63592 22.3641Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                            <div className={style.iconAndText}>
                                <div className={style.downloadText}>
                                    Download Excel Template
                                </div>

                                <div className={style.fileInputContainer}>
                                    <div style={{ padding: "5px" }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 48 48"
                                            width="30px"
                                            height="30px">
                                            <path
                                                fill="#169154"
                                                d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"
                                            />
                                            <path
                                                fill="#18482a"
                                                d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"
                                            />
                                            <path
                                                fill="#0c8045"
                                                d="M14 15.003H29V24.005000000000003H14z"
                                            />
                                            <path fill="#17472a" d="M14 24.005H29V33.055H14z" />
                                            <g>
                                                <path
                                                    fill="#29c27f"
                                                    d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"
                                                />
                                                <path
                                                    fill="#27663f"
                                                    d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"
                                                />
                                                <path
                                                    fill="#19ac65"
                                                    d="M29 15.003H44V24.005000000000003H29z"
                                                />
                                                <path fill="#129652" d="M29 24.005H44V33.055H29z" />
                                            </g>
                                            <path
                                                fill="#0c7238"
                                                d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
                                            />
                                            <path
                                                fill="#fff"
                                                d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"
                                            />
                                        </svg>
                                    </div>
                                    <div className={style.downloadIcon}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className={style.orText}>or</div>

                                <div
                                    onClick={() => {
                                        document.getElementById("fileInput").click();
                                    }}
                                    style={{ marginBottom: "30px" }}
                                    className={style.fileInputContainer}>
                                    <input
                                        id="fileInput"
                                        type="file"
                                        accept=".xls, .xlsx, .csv"
                                        onChange={handleFileChange}
                                        className={styles.hiddenInput}
                                    />
                                    <div className={style.selectFileText}>Select From Device</div>
                                    <div className={style.downloadIcon}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16.19 22H7.81C4.17 22 2 19.83 2 16.19V7.82C2 4.17 4.17 2 7.81 2H16.18C19.82 2 21.99 4.17 21.99 7.81V16.19C22 19.83 19.83 22 16.19 22ZM8.47 13.02C8.76 13.31 9.24 13.31 9.53 13.02L11.25 11.3V17.49C11.25 17.9 11.59 18.24 12 18.24C12.41 18.24 12.75 17.9 12.75 17.49V11.3L14.47 13.02C14.76 13.31 15.24 13.31 15.53 13.02C15.82 12.73 15.82 12.25 15.53 11.96L12.53 8.96C12.46 8.89 12.38 8.84 12.29 8.8C12.2 8.76 12.1 8.74 12 8.74C11.9 8.74 11.81 8.76 11.71 8.8C11.62 8.84 11.54 8.89 11.47 8.96L8.47 11.96C8.18 12.25 8.18 12.72 8.47 13.02ZM18.24 6.78C16.23 6.11 14.12 5.77 12 5.77C9.88 5.77 7.77 6.11 5.76 6.78C5.37 6.91 5.16 7.34 5.29 7.73C5.42 8.12 5.84 8.34 6.24 8.2C9.96 6.96 14.05 6.96 17.77 8.2C18.16 8.33 18.59 8.12 18.72 7.73C18.84 7.33 18.63 6.91 18.24 6.78Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </CustomModal>
            )}

            {fileContent.length > 0 && (
                <div className={styles.uploadedFile}>
                    <div className={styles.containerFile}>
                        <div>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>

                        <div className={styles.fileUploadedBtns}>
                            {activeStep === 1 ? (
                                <Button
                                    variant="contained"
                                    className={styles.saveButton}
                                    onClick={handleBack}>
                                    Back
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    className={styles.saveButton}
                                    onClick={handleCancel}>
                                    Cancel
                                </Button>
                            )}

                            {activeStep === 1 ? (
                                <Button
                                    variant="contained"
                                    className={styles.saveButton}
                                    onClick={handlesubmittt}>
                                    Submit
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    className={styles.saveButton}
                                    onClick={handleNext}>
                                    Next
                                </Button>)}
                        </div>

                        <div className={styles.uploadedFileContainer}>
                            <table>
                                <thead>
                                    <tr>
                                        {/* {fileContent[0].map((cell, index) => (
                <th key={index}>{cell}</th>
              ))} */}
                                        <th className={styles.fileUploadheading}>S.no</th>
                                        <th className={styles.fileUploadheading}>Item name</th>
                                        <th className={styles.fileUploadheading}>
                                            Quantity required
                                        </th>
                                        <th className={styles.fileUploadheading}>UOM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activeStep === 0
                                        ? falseFilteredTable.slice(1).map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                <td>{rowIndex + 1}</td>
                                                {Array.from({ length: maxColumns }).map(
                                                    (cell, cellIndex) => (
                                                        <td
                                                            className={
                                                                !validate.salesOrderValidate[rowIndex + 1] &&
                                                                styles.incorrectfieldValue
                                                            }
                                                            key={cellIndex}>
                                                            {cellIndex < row.length ? row[cellIndex] : "-"}
                                                        </td>
                                                    )
                                                )}
                                            </tr>
                                        ))
                                        : filteredTable.map((el, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                {Array.from({ length: maxColumns }).map(
                                                    (cell, idx) => (
                                                        <td key={idx}>
                                                            {idx < el.length ? el[idx] : "-"}
                                                        </td>
                                                    )
                                                )}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <table className={styles.smtable}>
                    <thead>
                        <tr className={styles.headrowsm}>
                            {showeditRow && <th></th>}
                            {showInputRow && <th></th>}
                            <th>S.no</th>
                            <th>Item name</th>
                            <th>Quantity required</th>
                            <th>UOM</th>
                            <th>Status</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row) => row)}
                        {showInputRow && (
                            <tr key={rows.length} className={styles.addinputspares}>
                                <td className={styles.tickcross}>
                                    <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        onClick={handleSubmitsm}>
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                                            fill="#66D171"
                                        />
                                    </svg>
                                    <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        onClick={handleDeleteRow}>
                                        <path
                                            d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                            fill="#F97575"
                                        />
                                        <path
                                            d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                            fill="#F97575"
                                        />
                                    </svg>
                                </td>

                                <td></td>
                                <td>
                                    <input
                                        type="text"
                                        className={styles.inputFieldsm}
                                        name="Item_Name"
                                        value={formDatac.Item_Name}
                                        onChange={handleInputChangevis}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className={styles.inputFieldsm}
                                        name="Quantity"
                                        value={formDatac.Quantity}
                                        onChange={handleInputChangevis}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className={styles.inputFieldsm}
                                        name="UOM"
                                        value={formDatac.UOM}
                                        onChange={handleInputChangevis}
                                    />
                                </td>
                                <td>
                                    <div>
                                        <select
                                            className={styles.inputFieldsm}
                                            name="Status"
                                            value={formDatac.Status}
                                            onChange={handleInputChangevis}
                                        >
                                            <option value="">Select Status</option>

                                            <option value="Under Procurement">Under Procurement</option>

                                            <option value="In Transit to FS">In Transit to FS</option>

                                            <option value="Extraction and Packaging">
                                                Extraction and Packaging
                                            </option>

                                            <option value="Obtaining EC">Obtaining EC</option>

                                            <option value="Waiting For Vessel">
                                                Waiting For Vessel
                                            </option>

                                            <option value="In Transit from FS to nearby port">
                                                In Transit from FS to nearby port
                                            </option>

                                            <option value="Under Customs Clearance">
                                                Under Customs Clearance
                                            </option>

                                            <option value="Nearby Yard">Nearby Yard</option>

                                            <option value="Portbase loading point to rig">
                                                Portbase loading point to rig
                                            </option>


                                            <option value="In Transit to rig">
                                                In Transit to rig
                                            </option>
                                            <option value="Onboard">
                                                Onboard
                                            </option>
                                        </select>
                                        <svg
                                            style={{
                                                position: 'relative',  // Make sure to position it absolutely within the div
                                                right: "25px",
                                                pointerEvents: 'none',  // Disable pointer events if it shouldn't be clickable
                                                top: '10px',
                                            }}
                                            className={styles.svgSelect}
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                                                fill="rgba(57, 71, 89, 0.5)"
                                            />
                                        </svg>
                                    </div>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className={styles.inputFieldsm}
                                        name="Remarks"
                                        value={formDatac.Remarks}
                                        onChange={handleInputChangevis}
                                    />
                                </td>
                            </tr>
                        )}
                        {smResults.map((sm, index) => (
                            <tr key={sm.id}>
                                {showeditRow && (
                                    <td>
                                        <div
                                            className={styles.removeTxtBtn}
                                            onClick={() => handleRemove(sm.S_No, index +1)}
                                            style ={{cursor: "pointer"}}>
                                            
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none">
                                                <path
                                                    d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                                    fill="#567191"
                                                />
                                            </svg>
                                            Remove
                                        </div>
                                    </td>
                                )}
                                {showInputRow && <td></td>}
                                <td>{index + 1}</td>
                                <td>
                                    {editModesm ? (
                                        <input
                                            type="text"
                                            className={styles.inputFieldsm}
                                            name="Item_Name"
                                            value={formDatac.data[index].Item_Name}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    ) : sm.Item_Name ? (
                                        sm.Item_Name
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td>
                                    {editModesm ? (
                                        <input
                                            type="text"
                                            className={styles.inputFieldsm}
                                            name="Quantity"
                                            value={formDatac.data[index].Quantity}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    ) : sm.Quantity ? (
                                        sm.Quantity
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td>
                                    {editModesm ? (
                                        <input
                                            type="text"
                                            className={styles.inputFieldsm}
                                            name="UOM"
                                            value={formDatac.data[index].UOM}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    ) : sm.UOM ? (
                                        sm.UOM
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td>
                                    {editModesm ? (
                                        <div>
                                            <select
                                                className={styles.inputFieldsm}
                                                name="Status"
                                                value={formDatac.data[index].Status}
                                                onChange={(e) => handleInputChange(e, index)}
                                            >
                                                <option value="">Select Status</option>

                                                <option value="Under Procurement">Under Procurement</option>

                                                <option value="In Transit to FS">In Transit to FS</option>

                                                <option value="Extraction and Packaging">
                                                    Extraction and Packaging
                                                </option>

                                                <option value="Obtaining EC">Obtaining EC</option>

                                                <option value="Waiting For Vessel">
                                                    Waiting For Vessel
                                                </option>

                                                <option value="In Transit from FS to nearby port">
                                                    In Transit from FS to nearby port
                                                </option>

                                                <option value="Under Customs Clearance">
                                                    Under Customs Clearance
                                                </option>

                                                <option value="Nearby Yard">Nearby Yard</option>

                                                <option value="Portbase loading point to rig">
                                                    Portbase loading point to rig
                                                </option>


                                                <option value="In Transit to rig">
                                                    In Transit to rig
                                                </option>
                                                <option value="Onboard">
                                                    Onboard
                                                </option>
                                            </select>
                                            <svg
                                                style={{
                                                    position: 'relative',  // Make sure to position it absolutely within the div
                                                    right: "25px",
                                                    pointerEvents: 'none',  // Disable pointer events if it shouldn't be clickable
                                                    top: '10px',
                                                }}
                                                className={styles.svgSelect}
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                                                    fill="rgba(57, 71, 89, 0.5)"
                                                />
                                            </svg>
                                        </div>
                                    ) : sm.Status ? (
                                        sm.Status
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td>
                                    {editModesm ? (
                                        <textarea
                                            type="text"
                                            className={styles.inputFieldsm}
                                            name="Remarks"
                                            value={formDatac.data[index].Remarks}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    ) : sm.Remarks ? (
                                        sm.Remarks
                                    ) : (
                                        "-"
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {featureman.length > 0 && showEditButton && (<button
                    className={styles.manualbuttonsm}
                    onClick={handleManualInputClick}
                    disabled={isButtonDisabled}>
                    Input Items Manually
                    <svg
                        style={{ marginLeft: "5px" }}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.493 1.66669H6.50964C3.4763 1.66669 1.66797 3.47502 1.66797 6.50835V13.4834C1.66797 16.525 3.4763 18.3334 6.50964 18.3334H13.4846C16.518 18.3334 18.3263 16.525 18.3263 13.4917V6.50835C18.3346 3.47502 16.5263 1.66669 13.493 1.66669ZM15.0013 10.625H10.6263V15C10.6263 15.3417 10.343 15.625 10.0013 15.625C9.65964 15.625 9.3763 15.3417 9.3763 15V10.625H5.0013C4.65964 10.625 4.3763 10.3417 4.3763 10C4.3763 9.65835 4.65964 9.37502 5.0013 9.37502H9.3763V5.00002C9.3763 4.65835 9.65964 4.37502 10.0013 4.37502C10.343 4.37502 10.6263 4.65835 10.6263 5.00002V9.37502H15.0013C15.343 9.37502 15.6263 9.65835 15.6263 10C15.6263 10.3417 15.343 10.625 15.0013 10.625Z"
                            fill="white"
                        />
                    </svg>
                </button>)}
            </div>

        </>
    )
}
export default ToolBoxOffshoreProjects;