import React, { useEffect, useRef, useState } from "react";
import styles from "../Components/css/projects.module.css";
import "../App.css";
import CreateProject from "../Components/Createproject";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddBoxIcon from "@mui/icons-material/AddBox";
// import TeamSelection from "./teamselection";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import UploadDocument from "./Uploaddocument";
import Select from "react-select";

// date picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";

import dayjs from "dayjs";

import BasicModal from "./Modal";

// stepper
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = ["Project Details", "Team Selection", "Tools Details"];
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const stepStyle = {
  "& .MuiStepIcon-root.Mui-active": {
    color: "white",
    border: "4px solid #5B6BE1",
    borderRadius: "100%",
    fontSize: "30px",
  },

  "& .MuiStepIcon-text": {
    fill: "#5B6BE1",
    fontWeight: 700,
    fontSize: "16px",
  },

  "& .MuiStepIcon-root": {
    color: "white",
    border: "4px solid #5B6BE166",
    borderRadius: "100%",
    fontSize: "30px",
  },
  "& .MuiStepLabel-label:not(.Mui-active)": {
    color: "#5B6BE166",
    fontWeight: 600,
  },
  "& .MuiStepLabel-label.Mui-active": {
    color: "#5B6BE1",
    fontWeight: 600,
  },
  "& .MuiStepIcon-root:not(.Mui-active)": {
    "& .MuiStepIcon-text": {
      fill: "#5B6BE166",
    },
  },

  "& .MuiStepIcon-root.Mui-completed": {
    color: "#5B6BE1",
    border: "4px solid #5B6BE166",
  },

  "& .MuiStepLabel-label.Mui-completed": {
    color: "#5B6BE1",
  },

  "& .MuiStepConnector-line": {
    borderColor: "#5B6BE166",
    borderTopWidth: "4px",
  },
  "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
    borderColor: "#5B6BE1",
  },
  "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
    borderColor: "#5B6BE1",
  },
};

function CreatingANewProject() {
  const navigate = useNavigate();
  const location = useLocation();

  let projectId;

  const [activeStep, setActiveStep] = useState(0);

  const [formdata1Display1, setFormData1Display1] = useState(true);
  const [formdata1Display2, setFormData1Display2] = useState(false);
  const [formdata1Display3, setFormData1Display3] = useState(false);
  const [isRigNameValid, setIsRigNameValid] = useState(true);


  const [selectedShortName, setSelectedShortName] = useState("");
  const [selectedItem_No, setSelectedItem_No] = useState("");

  const [Description, setDescription] = useState([false]);

  const [item_No, setItem_No] = useState([]);
  const [item_No2, setItem_No2] = useState([]);
  // const [filteredItem_No, setFilteredItem_No] = useState([false]);
  const [selectedDescription, setSelectedDescription] = useState();

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",
    salesOrderDate: "",
    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    EstimatedDateCommencement: "",
    EstimatedProject: "",
    RigName: "",
    CustomerName: "",
    RigLocation: "",
    RigType: "",
    serviceComponent: "",
    scopeDescription: "",
    documentDate: "",
    short_name: "",
    opsEngineer: "",
  });

  const [selectedFile, setSelectedFile] = useState({
    workPlan: null,
  });
  const [selectedFileName, setSelectedFileName] = useState({
    workplan: "",
  });

  const [formdata3Tool1, setFormData3Tool1] = useState({
    tool1: "",
    itemId1: "",
    make1: "",
    model1: "",
    serialNumber1: "",
    calibrationDate1: "",
    calibrationDueDate1: "",
    acceptanceCriterial1: "",
  });
  const [formdata3Tool2, setFormData3Tool2] = useState({
    tool2: "",
    itemId2: "",
    make2: "",
    model2: "",
    serialNumber2: "",
    calibrationDate2: "",
    calibrationDueDate2: "",
    acceptanceCriterial2: "",
  });

  const handleOnChange3Tool1 = (e) => {
    const { name, value } = e.target;
    setFormData3Tool1({ ...formdata3Tool1, [name]: value });
  };

  const generatepostforsuper = (i, j, k) => {
    if (j && j.includes(",")) {
      let superArray = j.split(",");
      superArray.map((elm) => {
        if (elm !== "") {
          const payloadfm = {
            Sales_Order_No: i,
            Customer_Name: elm,
            ID: k,
          };

          axios
            .post(
              "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization",
              payloadfm
            )
            .then((response) => {
              console.log("Response:", response.data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    } else {
      if (j !== "") {
        const payloadfm = {
          Sales_Order_No: i,
          Customer_Name: j,
          ID: k,
        };

        axios
          .post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization", payloadfm)
          .then((response) => {
            console.log("Response:", response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const generatepostfortech = (i, j, k) => {
    if (j && j.includes(",")) {
      let techArray = j.split(",");
      techArray.map((elm) => {
        if (elm !== "") {
          const payloadfm = {
            Sales_Order_No: i,
            Customer_Name: elm,
            ID: k,
          };

          axios
            .post(
              "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization",
              payloadfm
            )
            .then((response) => {
              console.log("Response:", response.data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    } else {
      if (j !== "") {
        const payloadfm = {
          Sales_Order_No: i,
          Customer_Name: j,
          ID: k,
        };

        axios
          .post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization", payloadfm)
          .then((response) => {
            console.log("Response:", response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const generatepostfortrainee = (i, j, k) => {
    if (j && j.includes(",")) {
      let traineeArray = j.split(",");
      traineeArray.map((elm) => {
        if (elm !== "") {
          const payloadfm = {
            Sales_Order_No: i,
            Customer_Name: elm,
            ID: k,
          };

          axios
            .post(
              "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization",
              payloadfm
            )
            .then((response) => {
              console.log("Response:", response.data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    } else {
      if (j !== "") {
        const payloadfm = {
          Sales_Order_No: i,
          Customer_Name: j,
          ID: k,
        };

        axios
          .post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization", payloadfm)
          .then((response) => {
            console.log("Response:", response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const handleOnChange3Tool2 = (e) => {
    const { name, value } = e.target;
    setFormData3Tool2({ ...formdata3Tool2, [name]: value });
  };

  // setup for form1
  // useEffect(() => {
  //   if (localStorage.getItem("formdata1")) {
  //     const form1 = JSON.parse(localStorage.getItem("formdata1"));
  //     setFormData1({ ...form1 });
  //   } else {
  //     localStorage.setItem("formdata1", JSON.stringify(formdata1));
  //   }
  // }, []);

  //increment the sales order no.
  useEffect(() => {
    const getFetchData = async () => {
      const res = await fetch(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
      );

      const data = await res.json();
      // console.log(">>>>>>>>>>>>^^^^^- ", data.data);

      const count =
        data.data.length < 9
          ? "00" + (data.data.length + 1)
          : data.data.length < 99
            ? "0" + data.data.length
            : data.data.length;

      // setFormData1((preve) => {
      //   return {
      //     ...preve,
      //     salesOrderNo: count,
      //   };
      // });
    };
    getFetchData();
  }, []);

  const [short_name, setshort_name] = useState([false]);
  const duplicateRemovedShortName = [...new Set(short_name)].filter(Boolean);
  useEffect(() => {
    fetch("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/short_name")
      .then((res) => res.json())
      .then((data) => setshort_name(data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (formdata1.short_name) {
      fetch(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get-rig-details-with-shortName?short_name=${formdata1.short_name}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("get-rig-details-with-shortName", data[0]);
          const { customer_name, design, location } = data[0];
          setFormData1({
            ...formdata1,
            CustomerName: customer_name,
            RigLocation: location,
            RigType: design,
          });
        })
        .catch((err) => console.log(err));
    } else {
      setFormData1({
        ...formdata1,
        CustomerName: "",
        RigLocation: "",
        RigType: "",
      });
    }
  }, [formdata1.short_name]);

  // Update local storage whenever the value of short_name changes
  // useEffect(() => {
  //   localStorage.setItem("short_name", formdata1.short_name); // <-- use localStorage to save the selected value
  // }, [formdata1.short_name]);

  // const handleOnChange = e => {
  //   setFormData1({ ...formdata1, short_name: e.target.value });
  // };

  // useEffect(() => {
  //   const storedShortName = localStorage.getItem("selectedShortName");
  //   if (storedShortName) {
  //     setSelectedShortName(storedShortName);
  //   }
  // }, []);

  const handleRemoveSupervisor = (index) => {
    const updatedSupervisors = [...addSupervisor];
    updatedSupervisors.splice(index, 1);
    setAddSupervisor(updatedSupervisors);
  };

  const handleRemoveTechnician = (index) => {
    const updatedTechnicians = [...addTechnician];
    updatedTechnicians.splice(index, 1);
    setAddTechnician(updatedTechnicians);
  };

  const handleRemoveTrainee = (index) => {
    const updatedTrainees = [...addTrainee];
    updatedTrainees.splice(index, 1);
    setAddTrainee(updatedTrainees);
  };

  const handleOnChangeSupervisor = (index, value) => {
    const updatedSupervisors = [...addSupervisor];
    updatedSupervisors[index] = value;
    setAddSupervisor(updatedSupervisors);
  };

  // const handleExitToProjects = () => {
  //   navigate("/projects");
  // };

  const handleSelectServiceComponent = (selectedOption) => {
    // Get the value of the selected option or an empty string if no option is selected
    const value = selectedOption ? selectedOption.value : '';
    const name = "serviceComponent";

    // Call the handleOnChange function with the extracted value
    handleOnChange({ target: { name, value } });
  };

  const handleAddMoreaddTrainee = (e) => {
    e.preventDefault();
    setAddTrainee((preve) => {
      return [...preve, "addTrainee"];
    });
    console.log("//////////////////////", "addTrainee");
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formdata1, [name]: value });
    console.log("@@@@@@@@@@@22", name);
    console.log("~~~~~~~~~~~~~", value);
    setSelectedShortName(value); // <-- update the selected short name
  };

  const handleNumeriChange = (e) => {
    let { name, value } = e.target;

    // value = value.replace(/[^0-9]/g, "");
    // if (value.length <= 3 && !isNaN(value)) {
    setFormData1({
      ...formdata1,
      [name]: value,
    });
    // }
  };

  const handleSubmitForm1 = () => {
    // e.preventDefault();
    // localStorage.setItem("formdata1", JSON.stringify(formdata1));

    setFormData1Display1(false);
    setFormData1Display2(true);

    setActiveStep(1);

    console.log(formdata1);
  };

  /****2nd step */
  // _____________________________________________________________________________________________
  const [Supervisor, setSupervisorData] = useState({}); //store Supervisor all data
  const [addSupervisor, setAddSupervisor] = useState(["addSupervisor"]);
  const [supervisorNames, setSupervisorNames] = useState([]);

  const [Technician, setTechnicianData] = useState({}); //store Technician all data
  const [addTechnician, setAddTechnician] = useState(["addTechnician"]);
  const [technicianNames, setTechnicianNames] = useState([]);

  const [Trainee, setTraineeData] = useState({}); //store Trainee all data
  const [addTrainee, setAddTrainee] = useState(["addTrainee"]);
  const [traineeNames, setTraineeNames] = useState([]);
  // _____________________________________________________________________________________________

  const handleAddMoreaddSupervisor = (e) => {
    e.preventDefault();

    setAddSupervisor((preve) => {
      return [...preve, "addSupervisor"];
    });
    console.log("-----------------", "addSupervisor");
  };

  const handleAddMoreaddTechnician = (e) => {
    e.preventDefault();
    setAddTechnician((preve) => {
      return [...preve, "addTechnician"];
    });
    console.log("//////////////////////", "addTechnician");
  };

  const handleOnChangeSupervi = (e) => {
    const { name, value } = e.target;
    console.log("superrrrr<<<<<<<<<<<------", name, value);
    setSupervisorData({
      ...Supervisor,
      [name]: value,
    });
  };

  const handleOnChangeTechnician = (e) => {
    // Handle technician dropdown change here
    const { name, value } = e.target;
    console.log("Techhhhh>>>>>>>>>>>------", name, value);
    setTechnicianData({
      ...Technician,
      [name]: value,
    });
  };

  const handleOnChangeTrainee = (e) => {
    // Handle Trainee dropdown change here
    const { name, value } = e.target;
    console.log("Trainee>>>>>>>>>>>------", name, value);
    setTraineeData({
      ...Trainee,
      [name]: value,
    });
  };

  //setup for form1
  // useEffect(() => {
  //   if (localStorage.getItem("addSupervisor")) {
  //     const addSupervisornumber = JSON.parse(
  //       localStorage.getItem("addSupervisor")
  //     );
  //     console.log("number of aupper", addSupervisornumber);

  //     setAddSupervisor(addSupervisornumber);
  //   } else {
  //     localStorage.setItem("addSupervisor", JSON.stringify(addSupervisor));
  //   }
  // }, []);

  useEffect(() => {
    fetch("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supervisor_customer_names")
      .then((res) => res.json())
      .then((data) => setSupervisorNames(data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/technician_customer_names")
      .then((res) => res.json())
      .then((data) => setTechnicianNames(data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/trainee_customer_names")
      .then((res) => { setTraineeNames(res.data); })
      .catch((err) => { console.log(err); });
  }, []);

  const handleSubmitForm2 = () => {
    // e.preventDefault();
    // localStorage.setItem("formdata1", JSON.stringify(formdata1));
    setFormData1Display1(false);
    setFormData1Display2(false);
    setFormData1Display3(true);
    setActiveStep(2);
  };

  useEffect(() => {
    fetch("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/Description")
      .then((res) => res.json())
      .then((data) => {
        const newData = [...new Set(data)].filter(Boolean);
        setDescription(newData);

      })
      .catch((err) => console.log(err));
  }, []);
  console.log("&&&&&&&&&&&&&&&&&&&&&&2", Description);

  // useEffect(() => {
  //   fetch("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/Item_No")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setItem_No(data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  useEffect(() => {
    fetch("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/Item_No")
      .then((res) => res.json())
      .then((data) => {
        setItem_No(data);
        // setFilteredItem_No(data);
      })
      .catch((err) => console.log(err));
  }, []);

  //fetch tool1 details with items no.
  useEffect(() => {
    if (formdata3Tool1.tool1) {
      fetch(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get-tools-details-with-Item_No?Description=${formdata3Tool1.tool1}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("get-tools-details-with-Item_No", data);
          // const {
          //   Item_No,
          //   // Manufacturer,
          //   // Model,
          //   // Serial_No,
          //   // Cal_Date,
          //   // Due_Date,
          //   // Acceptance_Criteria,
          // } = data[0];
          const newItemId = data.map(el => {
            return el.Item_No
          })

          console.log(newItemId)

          setItem_No(newItemId);

          //   setFormData3Tool1({
          //     ...formdata3Tool1,
          //     itemId1: Item_No,
          //     make1: Manufacturer,
          //     model1: Model,
          //     serialNumber1: Serial_No,
          //     calibrationDate1: Cal_Date,
          //     calibrationDueDate1: Due_Date,
          //     acceptanceCriterial1: Acceptance_Criteria,
          //   });
        })
        .catch((err) => console.log(err));
    } else {
      setFormData3Tool1({
        ...formdata3Tool1,
        itemId1: "",
        make1: "",
        model1: "",
        serialNumber1: "",
        calibrationDate1: "",
        calibrationDueDate1: "",
        acceptanceCriterial1: "",
      });
    }
  }, [formdata3Tool1.tool1]);

  useEffect(() => {
    if (formdata3Tool1.itemId1) {
      fetch(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get-all-tools-details?Item_No=${formdata3Tool1.itemId1}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("get-tools-details-with-Item_No", data);
          const {
            Manufacturer,
            Model,
            Serial_No,
            Cal_Date,
            Due_Date,
            Acceptance_Criteria,
          } = data[0];

          console.log(data[0])

          setFormData3Tool1({
            ...formdata3Tool1,
            make1: Manufacturer,
            model1: Model,
            serialNumber1: Serial_No,
            calibrationDate1: Cal_Date,
            calibrationDueDate1: Due_Date,
            acceptanceCriterial1: Acceptance_Criteria,
          });
        })
        .catch((err) => console.log(err));
    } else {
      setFormData3Tool1({
        ...formdata3Tool1,
        make1: "",
        model1: "",
        serialNumber1: "",
        calibrationDate1: "",
        calibrationDueDate1: "",
        acceptanceCriterial1: "",
      });
    }
  }, [formdata3Tool1.itemId1]);

  //fetch data tool2
  useEffect(() => {
    if (formdata3Tool2.tool2) {
      fetch(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get-tools-details-with-Item_No?Description=${formdata3Tool2.tool2}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("get-tools-details-with-Item_No", data);
          // const {
          //   Item_No,
          //   // Manufacturer,
          //   // Model,
          //   // Serial_No,
          //   // Cal_Date,
          //   // Due_Date,
          //   // Acceptance_Criteria,
          // } = data[0];

          const newItemId = data.map(el => {
            return el.Item_No
          })
          console.log(newItemId)
          setItem_No2(newItemId);


          // setFormData3Tool2({
          //   ...formdata3Tool2,
          //   itemId2: Item_No,
          //   make2: Manufacturer,
          //   model2: Model,
          //   serialNumber2: Serial_No,
          //   calibrationDate2: Cal_Date,
          //   calibrationDueDate2: Due_Date,
          //   acceptanceCriterial2: Acceptance_Criteria,
          // });
        })
        .catch((err) => console.log(err));
    } else {
      setFormData3Tool2({
        ...formdata3Tool2,
        itemId2: "",
        make2: "",
        model2: "",
        serialNumber2: "",
        calibrationDate2: "",
        calibrationDueDate2: "",
        acceptanceCriterial2: "",
      });
    }
  }, [formdata3Tool2.tool2]);

  useEffect(() => {
    if (formdata3Tool2.itemId2) {
      fetch(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get-all-tools-details?Item_No=${formdata3Tool2.itemId2}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("get-tools-details-with-Item_No", data);
          const {
            Manufacturer,
            Model,
            Serial_No,
            Cal_Date,
            Due_Date,
            Acceptance_Criteria,
          } = data[0];

          setFormData3Tool2({
            ...formdata3Tool2,
            make2: Manufacturer,
            model2: Model,
            serialNumber2: Serial_No,
            calibrationDate2: Cal_Date,
            calibrationDueDate2: Due_Date,
            acceptanceCriterial2: Acceptance_Criteria,
          });
        })
        .catch((err) => console.log(err));
    } else {
      setFormData3Tool2({
        ...formdata3Tool2,
        make2: "",
        model2: "",
        serialNumber2: "",
        calibrationDate2: "",
        calibrationDueDate2: "",
        acceptanceCriterial2: "",
      });
    }
  }, [formdata3Tool2.itemId2]);

  useEffect(() => {
    const fetchProject = async () => {
      const queryParams = new URLSearchParams(location.search);
      const id = queryParams.get("id");

      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
      );

      const filter = res.data.data.filter((el) => el.ID_No == id);
      console.log("--------------### ", filter);
      if (filter.length > 0) {
        setFormData1({
          salesOrderNo: filter[0]?.Sales_Order_No.toString(),
          salesOrderDate: filter[0]?.Sales_Order_Date,
          PONO: filter[0]?.Po_No,
          PODays: filter[0]?.Po_Days,
          PODate: filter[0]?.Po_Date,
          QuoteNO: filter[0]?.Quote_No,
          EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
          EstimatedProject: filter[0]?.Estimated_Project_Completion_Month,
          short_name: filter[0]?.Rig_Name,
          serviceComponent: filter[0]?.Service_Component,
          scopeDescription: filter[0]?.Scope_Description,
          documentDate: filter[0]?.Document_Date,
          opsEngineer: filter[0]?.Ops_Engineer,
        });

        let superobj = {};

        filter[0]?.Supervisor_Names.split(",")?.map((elm, index) => {
          superobj[`Supervisor${index + 1}`] = elm;
        });

        console.log("check this empty      ", filter[0]?.Supervisor_Names);

        setSupervisorData(superobj);
        setAddSupervisor(filter[0]?.Supervisor_Names.split(","));

        let techobj = {};

        filter[0]?.Technician_Names.split(",")?.map((elm, index) => {
          techobj[`Technician${index + 1}`] = elm;
        });

        setTechnicianData(techobj);
        setAddTechnician(filter[0]?.Technician_Names.split(","));


        let traineeobj = {};

        filter[0]?.Trainee_Names.split(",")?.map((elm, index) => {
          traineeobj[`Trainee${index + 1}`] = elm;
        });

        setTraineeData(traineeobj);
        setAddTrainee(filter[0]?.Trainee_Names.split(","));

        setFormData3Tool1({
          tool1: filter[0]?.Tool1,
          itemId1: filter[0]?.Item_ID1,
          make1: filter[0]?.Make1,
          model1: filter[0]?.Model1,
          serialNumber1: filter[0]?.Serial_Number1,
          calibrationDate1: filter[0]?.Calibration_Date1,
          calibrationDueDate1: filter[0]?.Calibration_Due_Date1,
          acceptanceCriterial1: filter[0]?.Acceptance_Criteria1,
        });

        setFormData3Tool2({
          tool2: filter[0]?.Tool2,
          itemId2: filter[0]?.Item_ID2,
          make2: filter[0]?.Make2,
          model2: filter[0]?.Model2,
          serialNumber2: filter[0]?.Serial_Number2,
          calibrationDate2: filter[0]?.Calibration_Date2,
          calibrationDueDate2: filter[0]?.Calibration_Due_Date2,
          acceptanceCriterial2: filter[0]?.Acceptance_Criteria2,
        });
      }
    };
    fetchProject();
  }, []);




  // date changes
  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("DD/MM/YYYY");
      setFormData1({ ...formdata1, [name]: selectedDate });
      // setFormData3Tool1({ ...formdata3Tool1, [name]: selectedDate });
      // setFormData3Tool2({ ...formdata3Tool2, [name]: selectedDate });
      console.log(name, selectedDate);
    } else {
      console.log("Invalid Date");
    }
  };

  //estimate date

  const calculateEstimatedProjectCompletion = () => {
    if (formdata1.EstimatedDateCommencement && formdata1.PODays) {
      let estimateDateCommence = dayjs(
        formdata1.EstimatedDateCommencement,
        "DD/MM/YYYY"
      );
      let podays = parseInt(formdata1.PODays);

      let estimateDateCompletion = estimateDateCommence
        .add(podays, "days")
        .format("DD/MM/YYYY");
      console.log("MMMMMMMMMMMMMMMM", estimateDateCompletion);

      setFormData1({
        ...formdata1,
        EstimatedProject: estimateDateCompletion,
      });
    }
  };
  useEffect(() => {
    calculateEstimatedProjectCompletion();
  }, [formdata1.EstimatedDateCommencement, formdata1.PODays]);

  // let dt = new Date(formdata1.EstimatedDateCommencement);
  // dt.setDate(dt.getDate() + parseInt(formdata1.PODays));

  const handleBack1 = (e) => {
    e.preventDefault();
    setFormData1Display1(true);
    setFormData1Display2(false);
    setFormData1Display3(false);
    setActiveStep(0);
  };
  const handleBack2 = (e) => {
    e.preventDefault();
    setFormData1Display1(false);
    setFormData1Display2(true);
    setFormData1Display3(false);
    setActiveStep(1);
  };

  const handleFileUpload = async (file, fieldName) => {
    const formData = new FormData();

    formData.append("file", file);
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/uploadfile",
        formData
      );

      // console.log(res.data);
      const pathOfFile = res.data.fileUrl;
      const fileNameApi = res.data.filename;

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: fileNameApi,
      });

      setSelectedFile({
        ...selectedFile,
        [fieldName]: pathOfFile,
      });
    } catch (error) {
      console.error("Error uploading signature:", error);
    }
  };

  // const handleCreateProjectAfterStep1 = async (e) => {
  //   e.preventDefault();

  //   const {
  //     salesOrderNo,
  //     salesOrderDate,
  //     PONO,
  //     PODays,
  //     PODate,
  //     QuoteNO,
  //     EstimatedDateCommencement,
  //     EstimatedProject,
  //     short_name,
  //     CustomerName,
  //     RigLocation,
  //     RigType,
  //     serviceComponent,
  //     scopeDescription,
  //     documentDate,
  //     opsEngineer,
  //   } = formdata1;

  //   // uncomment after db is updated
  //   // const { workPlan } = selectedFile;

  //   const {
  //     tool1,
  //     itemId1,
  //     make1,
  //     model1,
  //     serialNumber1,
  //     calibrationDate1,
  //     calibrationDueDate1,
  //     acceptanceCriterial1,
  //   } = formdata3Tool1;

  //   const {
  //     tool2,
  //     itemId2,
  //     make2,
  //     model2,
  //     serialNumber2,
  //     calibrationDate2,
  //     calibrationDueDate2,
  //     acceptanceCriterial2,
  //   } = formdata3Tool2;

  //   let convertSuperArray = Object.values(Supervisor)?.toString();
  //   let convertTechArray = Object.values(Technician)?.toString();
  //   let convertTraineeArray = Object.values(Trainee)?.toString();

  //   const payload = {
  //     Sales_Order_No: salesOrderNo,
  //     Sales_Order_Date: salesOrderDate,
  //     Po_No: PONO,
  //     Po_Days: PODays?.toString(),
  //     Po_Date: PODate,
  //     Quote_No: QuoteNO,
  //     Estimated_Date_Of_Commencement: EstimatedDateCommencement,
  //     Estimated_Project_Completion_Month: EstimatedProject,
  //     Rig_Name: short_name,
  //     Customer_Name: CustomerName,
  //     Rig_Location: RigLocation,
  //     Rig_Type: RigType,
  //     Service_Component: serviceComponent,
  //     Scope_Description: scopeDescription,
  //     Document_Date: documentDate,
  //     Ops_Engineer: opsEngineer,
  //     Supervisor_Names: convertSuperArray,
  //     Technician_Names: convertTechArray,
  //     Trainee_Names: convertTraineeArray,
  //     Tool1: tool1,
  //     Item_ID1: itemId1,
  //     Make1: make1,
  //     Model1: model1,
  //     Serial_Number1: serialNumber1,
  //     Calibration_Date1: calibrationDate1,
  //     Calibration_Due_Date1: calibrationDueDate1,
  //     Acceptance_Criteria1: acceptanceCriterial1,
  //     Tool2: tool2,
  //     Item_ID2: itemId2,
  //     Make2: make2,
  //     Model2: model2,
  //     Serial_Number2: serialNumber2,
  //     Calibration_Date2: calibrationDate2,
  //     Calibration_Due_Date2: calibrationDueDate2,
  //     Acceptance_Criteria2: acceptanceCriterial2,
  //   };


  //   const res = await axios.post(
  //     "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details",
  //     payload
  //   );


  //   projectId = res.data.ID_No;
  //    // ************Start of Commencement Date log post******************
  //    let { Previous_Commencement_Date, Done_By, Reason_For_Change } = commencementDateFormData;
  //    let New_Commencement_Date = formdata1.EstimatedDateCommencement;
  //    const Timestamp = new Date();  // Current date and time in ISO format
  //    const Project_ID = projectId;
  //    // Convert empty string to null, to prevent empty string post into the database
  //    if (!Previous_Commencement_Date) {
  //      Previous_Commencement_Date = null;
  //    }
  //    if (!New_Commencement_Date) {
  //      New_Commencement_Date = null;
  //    }
 
  //    // Construct the payload
  //    const payloadCommencementDate = {
  //      Project_ID,
  //      Previous_Commencement_Date,
  //      New_Commencement_Date,
  //      Done_By,
  //      Reason_For_Change,
  //      Timestamp
  //    };
  //    console.log("payloadCommencementDate", payloadCommencementDate);
     
  //    if (New_Commencement_Date !== Previous_Commencement_Date) {
  //      const commencementRes = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details_commencement_date_post', payloadCommencementDate);
  //      console.log(commencementRes.data);
  //    }
  //    // ************End of Commencement Date log post******************


  //   const payloadot = {
  //     ID_No: projectId
  //   }
  //   const ofres = await axios.post(
  //     "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/offshore_timesheet",
  //     payloadot
  //   );

  //   const idPayload = {
  //     ID_No: projectId,
  //   };

  //   const checklistPayload = {
  //     // Sales_Order_No: salesOrderNo,
  //     ID_No: projectId,
  //   };

  //   const forChecklist = await axios.post(
  //     "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_checklist",
  //     checklistPayload
  //   );

  //   const apimanualPayload = {
  //     Sales_Order_No: salesOrderNo,
  //     ID_No: projectId,
  //   }

  //   const formanualuploadapi = await axios.post(
  //     "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/manualUpload",
  //     apimanualPayload
  //   );

  //   const urls = [
  //     "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supervisor_checklist_post",
  //     // "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesmobilization_data",
  //     "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/daily_attendancePost",
  //   ];
  //   const responses = await Promise.all(
  //     urls.map((url) => axios.post(url, idPayload))
  //   );

  //   if (res.status === 200) {
  //     console.log("project created successfully");
  //     navigate(`/createnew?id=${projectId}`);
  //   }
  // };


  const handleCreateProjectAfterStep1 = async (e) => {
    e.preventDefault();
      // Assuming you're using react-router-dom v6
  
    try {
      // Destructuring form data for first payload
      const {
        salesOrderNo,
        salesOrderDate,
        PONO,
        PODays,
        PODate,
        QuoteNO,
        EstimatedDateCommencement,
        EstimatedProject,
        short_name,
        CustomerName,
        RigLocation,
        RigType,
        serviceComponent,
        documentDate,
        opsEngineer,
      } = formdata1;
  
      const payload = {
        Sales_Order_No: salesOrderNo,
        Sales_Order_Date: salesOrderDate,
        Po_No: PONO,
        Po_Days: PODays?.toString(),
        Po_Date: PODate,
        Quote_No: QuoteNO,
        Estimated_Date_Of_Commencement: EstimatedDateCommencement,
        Estimated_Project_Completion_Month: EstimatedProject,
        Rig_Name: short_name,
        Customer_Name: CustomerName,
        Rig_Location: RigLocation,
        Rig_Type: RigType,
        Service_Component: serviceComponent,
        Document_Date: documentDate,
        Ops_Engineer: opsEngineer,
      };
  
      // First API call to create project details
      const projectRes = await axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/create-project-new", payload);
      if (projectRes.status !== 200 || !projectRes.data.ID_No) {
        throw new Error('Failed to create project');
      }
      const projectId = projectRes.data.ID_No;
  
          // ************Start of Commencement Date log post******************
          let { Previous_Commencement_Date, Done_By, Reason_For_Change } = commencementDateFormData;
          let New_Commencement_Date = formdata1.EstimatedDateCommencement;
          const Timestamp = new Date();  // Current date and time in ISO format
          const Project_ID = projectId;
          // Convert empty string to null, to prevent empty string post into the database
          if (!Previous_Commencement_Date) {
            Previous_Commencement_Date = null;
          }
          if (!New_Commencement_Date) {
            New_Commencement_Date = null;
          }
      
          // Construct the payload
          const payloadCommencementDate = {
            Project_ID,
            Previous_Commencement_Date,
            New_Commencement_Date,
            Done_By,
            Reason_For_Change,
            Timestamp
          };
          console.log("payloadCommencementDate", payloadCommencementDate);
          
          if (New_Commencement_Date !== Previous_Commencement_Date) {
            const commencementRes = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details_commencement_date_post', payloadCommencementDate);
            console.log(commencementRes.data);
          }
          // ************End of Commencement Date log post******************
      
  
      // Define additional payloads using project ID
      const idPayload = { ID_No: projectId };
      const checklistPayload = { Sales_Order_No: salesOrderNo, ID_No: projectId };
  
      // Additional simultaneous API requests that depend on the project ID
      // const apiResponses = await Promise.all([
      //   axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/offshore_timesheet", idPayload),
      //   axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_checklist", checklistPayload),
      //   axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/manualUpload", checklistPayload),
      //   // Additional URLs can be added here as needed
      // ]);
  
      // Check all responses for success
      // apiResponses.forEach(response => {
      //   if (response.status !== 200) {
      //     throw new Error(`An API call failed with status: ${response.status}`);
      //   }
      // });
  
      console.log("Project created successfully");
      navigate(`/createnew?id=${projectId}`);
    } catch (error) {
      console.error("Error during project creation:", error);
      // Optionally: Set an error state or notify the user
    }
  };
  // POST API after step 2 and step 3

  const updateProjectStep2andStep3 = async (e) => {
    e.preventDefault();

    const {
      salesOrderNo,
      salesOrderDate,
      PONO,
      PODays,
      PODate,
      QuoteNO,
      EstimatedDateCommencement,
      EstimatedProject,
      short_name,
      CustomerName,
      RigLocation,
      RigType,
      serviceComponent,
      scopeDescription,
      documentDate,
      opsEngineer,
    } = formdata1;

    // un comment after db is updated
    // const { workPlan } = selectedFile;

    const {
      tool1,
      itemId1,
      make1,
      model1,
      serialNumber1,
      calibrationDate1,
      calibrationDueDate1,
      acceptanceCriterial1,
    } = formdata3Tool1;

    const {
      tool2,
      itemId2,
      make2,
      model2,
      serialNumber2,
      calibrationDate2,
      calibrationDueDate2,
      acceptanceCriterial2,
    } = formdata3Tool2;

    let convertedSuperArray = Object.values(Supervisor);
    let convertedTechArray = Object.values(Technician);
    let convertedTraineeArray = Object.values(Trainee);

    let convertSuperArray = convertedSuperArray?.toString();
    let convertTechArray = convertedTechArray?.toString();
    let convertTraineeArray = convertedTraineeArray?.toString();

    let teamSize = convertedSuperArray.length + convertedTechArray.length + convertedTraineeArray.length;

    const payload = {
      Sales_Order_No: salesOrderNo,
      Sales_Order_Date: salesOrderDate,
      Po_No: PONO,
      Po_Days: PODays?.toString(),
      Po_Date: PODate,
      Quote_No: QuoteNO,
      Estimated_Date_Of_Commencement: EstimatedDateCommencement,
      Estimated_Project_Completion_Month: EstimatedProject,
      Rig_Name: short_name,
      Customer_Name: CustomerName,
      Rig_Location: RigLocation,
      Rig_Type: RigType,
      Service_Component: serviceComponent,
      Scope_Description: scopeDescription,
      Document_Date: documentDate,
      Ops_Engineer: opsEngineer,
      Supervisor_Names: convertSuperArray,
      Technician_Names: convertTechArray,
      Trainee_Names: convertTraineeArray,
      Tool1: tool1,
      Item_ID1: itemId1,
      Make1: make1,
      Model1: model1,
      Serial_Number1: serialNumber1,
      Calibration_Date1: calibrationDate1,
      Calibration_Due_Date1: calibrationDueDate1,
      Acceptance_Criteria1: acceptanceCriterial1,
      Tool2: tool2,
      Item_ID2: itemId2,
      Make2: make2,
      Model2: model2,
      Serial_Number2: serialNumber2,
      Calibration_Date2: calibrationDate2,
      Calibration_Due_Date2: calibrationDueDate2,
      Acceptance_Criteria2: acceptanceCriterial2,
      Team_Size: teamSize,
    };

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const res = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details/update/${id}`,
      payload
    );
    console.log("---------------------", res.data);

    // ************Commencement Date log put******************
    let { Previous_Commencement_Date, Done_By, Reason_For_Change } = commencementDateFormData;
    let New_Commencement_Date = formdata1.EstimatedDateCommencement;
    const Timestamp = new Date();  // Current date and time in ISO format
    const Project_ID = id;
    // Convert empty string to null, to prevent empty string post into the database
    if (!Previous_Commencement_Date) {
      Previous_Commencement_Date = null;
    }
    if (!New_Commencement_Date) {
      New_Commencement_Date = null;
    }

    // Construct the payload
    const payloadCommencementDate = {
      Project_ID,
      Previous_Commencement_Date,
      New_Commencement_Date,
      Done_By,
      Reason_For_Change,
      Timestamp
    };
    console.log("payloadCommencementDate", payloadCommencementDate);
    
    if (New_Commencement_Date !== Previous_Commencement_Date) {
      const commencementRes = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details_commencement_date_put?Project_ID=${id}`, payloadCommencementDate);
      console.log(commencementRes.data);
    }
    // ************End of commencement Date log put******************
    
    if (res.status === 200) {
      // alert("update api called");
      console.log("correctly updated values");
    }
    if (formdata1Display2) {
      handleSubmitForm2();
    }
    if (formdata1Display3) {
      // alert("Project created successfully");
      navigate("/projects");
      // setFormData1Display3(false);
    }
  };

  const updateProjectatstep3 = async (e) => {
    e.preventDefault();

    const {
      salesOrderNo,
      salesOrderDate,
      PONO,
      PODays,
      PODate,
      QuoteNO,
      EstimatedDateCommencement,
      EstimatedProject,
      short_name,
      CustomerName,
      RigLocation,
      RigType,
      serviceComponent,
      scopeDescription,
      documentDate,
      opsEngineer,
    } = formdata1;

    // un comment after db is updated
    // const { workPlan } = selectedFile;

    const {
      tool1,
      itemId1,
      make1,
      model1,
      serialNumber1,
      calibrationDate1,
      calibrationDueDate1,
      acceptanceCriterial1,
    } = formdata3Tool1;

    const {
      tool2,
      itemId2,
      make2,
      model2,
      serialNumber2,
      calibrationDate2,
      calibrationDueDate2,
      acceptanceCriterial2,
    } = formdata3Tool2;

    let convertedSuperArray = Object.values(Supervisor);
    let convertedTechArray = Object.values(Technician);
    let convertedTraineeArray = Object.values(Trainee);

    let convertSuperArray = convertedSuperArray?.toString();
    let convertTechArray = convertedTechArray?.toString();
    let convertTraineeArray = convertedTraineeArray?.toString();

    let teamSize = convertedSuperArray.length + convertedTechArray.length + convertedTraineeArray.length;

    const payload = {
      Sales_Order_No: salesOrderNo,
      Sales_Order_Date: salesOrderDate,
      Po_No: PONO,
      Po_Days: PODays?.toString(),
      Po_Date: PODate,
      Quote_No: QuoteNO,
      Estimated_Date_Of_Commencement: EstimatedDateCommencement,
      Estimated_Project_Completion_Month: EstimatedProject,
      Rig_Name: short_name,
      Customer_Name: CustomerName,
      Rig_Location: RigLocation,
      Rig_Type: RigType,
      Service_Component: serviceComponent,
      Scope_Description: scopeDescription,
      Document_Date: documentDate,
      Ops_Engineer: opsEngineer,
      Supervisor_Names: convertSuperArray,
      Technician_Names: convertTechArray,
      Trainee_Names: convertTraineeArray,
      Tool1: tool1,
      Item_ID1: itemId1,
      Make1: make1,
      Model1: model1,
      Serial_Number1: serialNumber1,
      Calibration_Date1: calibrationDate1,
      Calibration_Due_Date1: calibrationDueDate1,
      Acceptance_Criteria1: acceptanceCriterial1,
      Tool2: tool2,
      Item_ID2: itemId2,
      Make2: make2,
      Model2: model2,
      Serial_Number2: serialNumber2,
      Calibration_Date2: calibrationDate2,
      Calibration_Due_Date2: calibrationDueDate2,
      Acceptance_Criteria2: acceptanceCriterial2,
      Team_Size: teamSize,
    };

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");

    const getResponse = await axios.get(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getforward_mobilization?ID=${id}`
    );

    const Customer_Name = getResponse.data;
    const filteredSuperArray = convertedSuperArray.filter(
      (item) => !Customer_Name.includes(item)
    );
    const filteredTechArray = convertedTechArray.filter(
      (item) => !Customer_Name.includes(item)
    );
    const filteredTraineeArray = convertedTraineeArray.filter(
      (item) => !Customer_Name.includes(item)
    );
    generatepostforsuper(salesOrderNo, filteredSuperArray, id);
    generatepostfortech(salesOrderNo, filteredTechArray, id);
    generatepostfortrainee(salesOrderNo, filteredTraineeArray, id);
    const res = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details/update/${id}`,
      payload
    );
    console.log("---------------------", res.data);

    // ************Commencement Date log put******************
    let { Previous_Commencement_Date, Done_By, Reason_For_Change } = commencementDateFormData;
    let New_Commencement_Date = formdata1.EstimatedDateCommencement;
    const Timestamp = new Date();  // Current date and time in ISO format
    const Project_ID = id;
    // Convert empty string to null, to prevent empty string post into the database
    if (!Previous_Commencement_Date) {
      Previous_Commencement_Date = null;
    }
    if (!New_Commencement_Date) {
      New_Commencement_Date = null;
    }

    // Construct the payload
    const payloadCommencementDate = {
      Project_ID,
      Previous_Commencement_Date,
      New_Commencement_Date,
      Done_By,
      Reason_For_Change,
      Timestamp
    };
    console.log("payloadCommencementDate", payloadCommencementDate);
    
    if (New_Commencement_Date !== Previous_Commencement_Date) {
      const commencementRes = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details_commencement_date_put?Project_ID=${id}`, payloadCommencementDate);
      console.log(commencementRes.data);
    }
    // ************End of commencement Date log put******************
            
    if (res.status === 200) {
      // alert("update api called");
      console.log("correctly updated values");
    }
    if (formdata1Display2) {
      handleSubmitForm2();
    }
    if (formdata1Display3) {
      // alert("Project created successfully");
      navigate("/projects");
      // setFormData1Display3(false);
    }
  };
  // POST API after step 2 and step 3

  const checkUpdateOrPost = (e) => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");

    // Check if "Rig Name" is entered
    // if (!formdata1.short_name) {
    //   // Alert or handle the case when "Rig Name" is not entered
    //   return;
    // }
    if (formdata1.short_name === "") {
      setIsRigNameValid(false);
      return;
    } else {
      setIsRigNameValid(true);
    }



    if (id) {
      updateProjectStep2andStep3(e);
      updateProjectatstep3(e);
      handleSubmitForm1();
    } else {
      handleCreateProjectAfterStep1(e);
      handleSubmitForm1();
    }
  };

  // mui styling for date picker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "230px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));

  //Start of Hooks and functions for Estimated Date of Commencement edit flow and modals
  const [commencementDateLog, setCommencementDateLog] = useState("");   //to store the Commencement Date Changes Log Data

  const [commencementDateFormData, setCommencementDateFormData] = useState({
    // Project_ID: id,  //Will be added from the project_details post response containing the newly generated Project ID
    Previous_Commencement_Date: "",
    // New_Commencement_Date: "", //Will be directly inserted into the payload from formdata1
    Done_By: "",
    Reason_For_Change: "Project Details Added",
    // Timestamp: "", //Will be directly inserted into the payload from the current date
  });
  console.log("commencementDateFormData", commencementDateFormData);

  const userEmail = localStorage.getItem('useremail');
  if (userEmail) {
    console.log('Found value:', userEmail);
  } else {
    console.log('No value found');
  }
    
  useEffect(() => {
    const fetchLoginDetails = async() => {
      const userRes = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_user_details_project_details?Email=${userEmail}`
      );
      setCommencementDateFormData(prevState => ({
        ...prevState,
        Done_By: userRes.data.data[0].Name,
      }));
    };

    fetchLoginDetails();
  }, []);
  //End of Hooks and functions for Estimated Date of Commencement edit flow and modals

  return (
    <>
      <div className={styles.projectDetailsContainer}>
        <div className={styles.sectionStep}>
          <Stepper activeStep={activeStep} alternativeLabel sx={stepStyle}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {formdata1Display1 && (
          <div className={styles.sections}>
            <form onSubmit={checkUpdateOrPost}>
              <div className={styles.splitHalfSection}>
                <div>
                  <div className={styles.formElement}>
                    <label>Sales Order No</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className={styles.salesOrderSpan}></span>
                      <input
                        style={{
                          paddingLeft: "10px",
                        }}
                        // required
                        // placeholder="Add a 3 digit Number"
                        type="text"
                        // minLength="3"
                        // maxlength="3"
                        // min="0"
                        // max="999"
                        value={formdata1.salesOrderNo}
                        name="salesOrderNo"
                        onChange={handleNumeriChange}
                      />
                    </div>
                  </div>

                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}>
                    <label>Sales Order Date</label>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="salesOrderDate"
                        value={
                          formdata1.salesOrderDate
                            ? dayjs(formdata1.salesOrderDate, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDates("salesOrderDate", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={styles.formElement}>
                    <label>PO Number</label>
                    <input
                      type="text"
                      value={formdata1.PONO}
                      name="PONO"
                      onChange={handleOnChange}
                    />
                  </div>

                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}>
                    <label>PO Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="PODate"
                        value={
                          formdata1.PODate
                            ? dayjs(formdata1.PODate, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => handleDates("PODate", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={styles.formElement}>
                    <label>PO Days</label>
                    <input
                      type="number"
                      min={0}
                      value={formdata1.PODays}
                      name="PODays"
                      onChange={handleOnChange}
                      required
                    />

                  </div>

                  <div className={styles.formElement}>
                    <label>Quote no</label>
                    <input
                      type="text"
                      value={formdata1.QuoteNO}
                      name="QuoteNO"
                      onChange={handleOnChange}
                    />
                  </div>
                </div>

                <div>
                  <div className={styles.formElement}>
                    <label>Rig Name *</label>
                    <select
                      value={formdata1.short_name}
                      name="short_name"
                      onChange={handleOnChange}
                      required
                      className={!isRigNameValid ? styles.highlightError : ''}
                    >
                      <option value="">Select value</option>
                      {duplicateRemovedShortName.map((short_name) => (
                        <option key={short_name}>{short_name}</option>
                      ))}
                    </select>
                    {/* {!isRigNameValid && (
                      <div className={styles.validationMessage}>
                        Rig Name is required.
                      </div>
                    )} */}

                    <svg
                      style={{ position: "absolute", right: "15" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  </div>

                  <div className={styles.formElement}>
                    <label>Rig Location</label>
                    <input
                      style={{
                        backgroundColor: "#F0F0F0",
                      }}
                      type="text"
                      disabled
                      value={formdata1.RigLocation}
                      name="RigLocation"
                      onChange={handleOnChange}
                    />
                  </div>

                  <div className={styles.formElement}>
                    <label>Rig Design</label>
                    <input
                      style={{
                        backgroundColor: "#F0F0F0",
                      }}
                      type="text"
                      disabled
                      value={formdata1.RigType}
                      name="RigType"
                      onChange={handleOnChange}
                    />
                  </div>

                  <div className={styles.formElement}>
                    <label>Customer name</label>
                    <input
                      style={{
                        backgroundColor: "#F0F0F0",
                      }}
                      type="text"
                      disabled
                      value={formdata1.CustomerName}
                      name="CustomerName"
                      onChange={handleOnChange}
                    />
                  </div>
                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}>
                    <label>Estimated date of commencement</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="EstimatedDateCommencement"
                        value={
                          formdata1.EstimatedDateCommencement
                            ? dayjs(
                              formdata1.EstimatedDateCommencement,
                              "DD/MM/YYYY"
                            )
                            : null
                        }
                        onChange={(value) =>
                          handleDates("EstimatedDateCommencement", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}

                      />
                    </LocalizationProvider>

                  </div>

                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}>
                    <label>Estimated Date of Completion</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="EstimatedProject"
                        value={
                          formdata1.EstimatedProject &&
                            formdata1.EstimatedDateCommencement &&
                            formdata1.PODays
                            ? dayjs(formdata1.EstimatedProject, "DD/MM/YYYY")
                            : null
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        disabled
                        sx={{
                          backgroundColor: "#F0F0F0",
                          borderRadius: "8px",
                          padding: 0,
                        }}
                        required
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div>
                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}>
                    <label>Document date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="documentDate"
                        value={
                          formdata1.documentDate
                            ? dayjs(formdata1.documentDate, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => handleDates("documentDate", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={styles.formElement}>
                    <label>Service Component</label>
                    <Select
                      name="serviceComponent"
                      value={{
                        label: formdata1.serviceComponent || "Type to search...", // Set the placeholder text if ItemName is empty
                        value: formdata1.serviceComponent,
                      }}
                      options={[
                        { value: '', label: 'Select value' },
                        { value: 'Jacking System Inspection', label: 'Jacking System Inspection' },
                        { value: 'Jacking System Remediation', label: 'Jacking System Remediation' },
                        { value: 'Jacking System Overhaul', label: 'Jacking System Overhaul' },
                        { value: 'Crane System Inspection', label: 'Crane System Inspection' },
                        { value: 'Crane Condition Evaluation', label: 'Crane Condition Evaluation' },
                        { value: 'Crane System Remediation', label: 'Crane System Remediation' },
                        { value: 'Crane System Overhaul', label: 'Crane System Overhaul' },
                        { value: 'Fixation System Inspection', label: 'Fixation System Inspection' },
                        { value: 'Fixation System Remediation', label: 'Fixation System Remediation' },
                        { value: 'Fixation system Overhaul', label: 'Fixation system Overhaul' },
                        { value: 'Skidding System Inspection', label: 'Skidding System Inspection' },
                        { value: 'Skidding System Remediation', label: 'Skidding System Remediation' },
                        { value: 'Skidding System Overhaul', label: 'Skidding System Overhaul' },
                        { value: 'Drilling Equipment Inspection', label: 'Drilling Equipment Inspection' },
                        { value: 'Drilling Equipment Remediation', label: 'Drilling Equipment Remediation' },
                        { value: 'Braking System Inspection', label: 'Braking System Inspection' },
                        { value: 'Braking System Remediation', label: 'Braking System Remediation' },
                        { value: 'Anchor Winch Inspection', label: 'Anchor Winch Inspection' },
                        { value: 'Supply Of Mechanics', label: 'Supply Of Mechanics' },
                        { value: 'Supply Of Supervisors', label: 'Supply Of Supervisors' },
                        { value: 'Supply Of Electricians', label: 'Supply Of Electricians' },
                      ]}
                      className={styles.editOpenReactSelect}
                      onChange={handleSelectServiceComponent}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "none",
                          boxShadow: "none",
                          cursor: "pointer",
                          overflowY: "hidden",
                          padding: "0px",
                          height: "21px", // Set the height of the input, 8px padding is being provided in the styles.editOpen css
                        }),
                        ValueContainer: (provided) => ({
                          ...provided,
                          padding: 0,
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: "none", // Hide the clear indicator
                        }),
                        menu: (provided) => ({
                          ...provided,
                          right: "0px", // Move the menu 10px to the left
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "200px",
                          overflowY: "scroll", // Enable vertical scrolling
                          scrollbarWidth: "thin",
                          scrollbarColor: "#c5c5c5 #f5f5f5",
                        }),
                      }}
                    />
                  </div>

                  <div className={styles.formElement}>
                    <label>Scope Description</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className={styles.salesOrderSpan}></span>
                      <input
                        style={{
                          paddingLeft: "10px",
                        }}
                        type="text"
                        value={formdata1.scopeDescription}
                        name="scopeDescription"
                      onChange={handleOnChange}
                      />
                    </div>
                  </div>


                  <div className={styles.formElement}>

<div className={styles.formElement}>
  <label>Work Plan</label>
  <div className="">
    <UploadDocument
      fieldName={"workPlan"}
      selectedFile={selectedFile.workPlan}
      selectedFileName={selectedFileName.workPlan}
      setSelectedFile={setSelectedFile}
      uploadFile={handleFileUpload}
    />
  </div>
</div>
                </div>



                
                  <div className={styles.formElement}>
                    <label>Ops Engineer</label>
                    <select
                      value={formdata1.opsEngineer}
                      name="opsEngineer"
                      onChange={handleOnChange}
                    >
                      <option value=""> Select the Member</option>
                      <option value="Jancy">Jancy</option>
                      <option value="Charulatha">Charulatha</option>
                    </select>
                    <svg
                      style={{ position: "absolute", right: "15" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className={styles.btnContainer}>
                <BasicModal postOrPut={checkUpdateOrPost} />
                <Button
                  variant="contained"
                  className={styles.proceedBtn}
                  onClick={checkUpdateOrPost}>
                  Proceed to Step 2
                </Button>
              </div>
            </form>
          </div>
        )}
        {formdata1Display2 && (
          <div className={styles.sections}>
            <div className="">
              <form onSubmit={handleSubmitForm2}>
                <div className={styles.splitHalfSection}>
                  <div style={{ width: "500px" }}>
                    {addSupervisor?.map((el, index) => {
                      return (
                        <div className={styles.formElement}>
                          <label>Supervisor {index + 1} </label>

                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                            }}>
                            {/* <select
                              name={"Supervisor" + (index + 1)}
                              value={Supervisor[`Supervisor${index + 1}`]}
                              onChange={handleOnChangeSupervi}>
                              <option value="">Select Option</option>
                              {supervisorNames.map((name) => (
                                <option key={name}>{name}</option>
                              ))}
                            </select> */}
                            <Select
                              name={"Supervisor" + (index + 1)}
                              value={{
                                label: Supervisor[`Supervisor${index + 1}`] || "Select Option", // Set the placeholder text if value is empty
                                value: Supervisor[`Supervisor${index + 1}`],
                              }}
                              options={supervisorNames.map(elm => ({ value: elm, label: elm }))}
                              onChange={(selectedOption) => handleOnChangeSupervi({ target: { name: "Supervisor" + (index + 1), value: selectedOption.value } }, index)}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  border: "1px solid #E7EBFF",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                  overflowY: "hidden",
                                  padding: "0 5px 0 0",
                                  height: "35px",
                                  minWidth: "250px",
                                }),
                                clearIndicator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the clear indicator
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  right: "0px", // Move the menu 10px to the left
                                }),
                                menuList: (provided) => ({
                                  ...provided,
                                  maxHeight: "200px",
                                  overflowY: "scroll", // Enable vertical scrolling
                                  scrollbarWidth: "thin",
                                  scrollbarColor: "#c5c5c5 #f5f5f5",
                                }),
                              }}
                            />
                          </div>
                          {index > 0 && (
                            <div
                              className={styles.removeIconBtn}
                              onClick={() => handleRemoveSupervisor(index)}>
                              <CancelIcon sx={{ fontSize: "16px" }} />
                              Remove
                            </div>
                          )}
                        </div>
                      );
                    })}

                    <Button
                      className={styles.addNamesBtn}
                      variant="contained"
                      onClick={handleAddMoreaddSupervisor}>
                      Add More
                      <AddBoxIcon />
                    </Button>
                  </div>

                  <div style={{ width: "500px" }}>
                    {addTechnician?.map((el, index) => {
                      return (
                        <div className={styles.formElement}>
                          <label>Technician {index + 1} </label>

                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                            }}>
                            {/* <select
                              name={"Technician" + (index + 1)}
                              value={Technician[`Technician${index + 1}`]}
                              onChange={handleOnChangeTechnician}>
                              <option value="">Select Option</option>
                              {technicianNames.map((name) => (
                                <option key={name} value={name}>
                                  {name}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              name={"Technician" + (index + 1)}
                              value={{
                                label: Technician[`Technician${index + 1}`] || "Select Option", // Set the placeholder text if value is empty
                                value: Technician[`Technician${index + 1}`],
                              }}
                              options={technicianNames.map(elm => ({ value: elm, label: elm }))}
                              onChange={(selectedOption) => handleOnChangeTechnician({ target: { name: "Technician" + (index + 1), value: selectedOption.value } }, index)}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  border: "1px solid #E7EBFF",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                  overflowY: "hidden",
                                  padding: "0 5px 0 0",
                                  height: "35px",
                                  minWidth: "250px",
                                }),
                                clearIndicator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the clear indicator
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  right: "0px", // Move the menu 10px to the left
                                }),
                                menuList: (provided) => ({
                                  ...provided,
                                  maxHeight: "200px",
                                  overflowY: "scroll", // Enable vertical scrolling
                                  scrollbarWidth: "thin",
                                  scrollbarColor: "#c5c5c5 #f5f5f5",
                                }),
                              }}
                            />
                          </div>

                          {index > 0 && (
                            <div
                              className={styles.removeIconBtn}
                              onClick={() => handleRemoveTechnician(index)}>
                              <CancelIcon sx={{ fontSize: "16px" }} />
                              Remove
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <Button
                      variant="contained"
                      className={styles.addNamesBtn}
                      onClick={handleAddMoreaddTechnician}>
                      Add More
                      <AddBoxIcon />
                    </Button>
                  </div>

                  <div style={{ width: "500px" }}>
                    {addTrainee?.map((el, index) => {
                      return (
                        <div className={styles.formElement}>
                          <label>Trainee {index + 1} </label>

                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                            }}>
                            {/* <select
                              name={"Trainee" + (index + 1)}
                              value={Trainee[`Trainee${index + 1}`]}
                              onChange={handleOnChangeTrainee}>
                              <option value="">Select Option</option>
                              {TraineeNames.map((name) => (
                                <option key={name} value={name}>
                                  {name}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              name={"Trainee" + (index + 1)}
                              value={{
                                label: Trainee[`Trainee${index + 1}`] || "Select Option", // Set the placeholder text if value is empty
                                value: Trainee[`Trainee${index + 1}`],
                              }}
                              options={traineeNames.map(elm => ({ value: elm, label: elm }))}
                              onChange={(selectedOption) => handleOnChangeTrainee({ target: { name: "Trainee" + (index + 1), value: selectedOption.value } }, index)}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  border: "1px solid #E7EBFF",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                  overflowY: "hidden",
                                  padding: "0 5px 0 0",
                                  height: "35px",
                                  minWidth: "250px",
                                }),
                                clearIndicator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the clear indicator
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  right: "0px", // Move the menu 10px to the left
                                }),
                                menuList: (provided) => ({
                                  ...provided,
                                  maxHeight: "200px",
                                  overflowY: "scroll", // Enable vertical scrolling
                                  scrollbarWidth: "thin",
                                  scrollbarColor: "#c5c5c5 #f5f5f5",
                                }),
                              }}
                            />
                          </div>

                          {index > 0 && (
                            <div
                              className={styles.removeIconBtn}
                              onClick={() => handleRemoveTrainee(index)}>
                              <CancelIcon sx={{ fontSize: "16px" }} />
                              Remove
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <Button
                      variant="contained"
                      className={styles.addNamesBtn}
                      onClick={handleAddMoreaddTrainee}>
                      Add More
                      <AddBoxIcon />
                    </Button>
                  </div>
                </div>
                <div className={styles.btnContainer}>
                  <BasicModal postOrPut={checkUpdateOrPost} />
                  <Button
                    variant="outlined"
                    className={styles.backBtn}
                    onClick={handleBack1}>
                    Back
                  </Button>

                  <Button
                    variant="contained"
                    className={styles.proceedBtn}
                    onClick={updateProjectStep2andStep3}>
                    Proceed to step 3
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}

        {formdata1Display3 && (
          <div className={styles.sections}>
            <div className={styles.splitHalfSection}>
              <div className={styles.step3Inputs}>
                <div>
                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>Tool 1</label>
                    <select
                      className={styles.inputFieldActive}
                      value={formdata3Tool1.tool1}
                      name="tool1"
                      onChange={handleOnChange3Tool1}
                      required>
                      <option value="">Select value</option>
                      {Description.map((Description) => (
                        <option key={Description} value={Description}>
                          {Description}
                        </option>
                      ))}
                    </select>
                    <svg
                      style={{ position: "absolute", right: "15" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>Make</label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool1.make1}
                      name="make1"
                      onChange={handleOnChange3Tool1}
                      disabled
                    />
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>S No.</label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool1.serialNumber1}
                      name="serialNumber1"
                      onChange={handleOnChange3Tool1}
                      disabled
                    />
                  </div>
                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>
                      Calibration Date
                    </label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool1.calibrationDate1}
                      name="calibrationDate1"
                      onChange={handleOnChange3Tool1}
                      disabled
                    />
                  </div>
                </div>

                <div>
                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>Item ID</label>
                    <select
                      className={
                        !formdata3Tool1.tool1
                          ? styles.inputFieldNotActive
                          : styles.inputFieldActive
                      }
                      value={formdata3Tool1.itemId1}
                      name="itemId1"
                      onChange={handleOnChange3Tool1}
                      disabled={!formdata3Tool1.tool1}
                      required>
                      <option value="">Select value</option>
                      {/* <option value={item_No}>{item_No}</option> */}
                      {item_No?.map((elm, index) => (
                        <option key={index} value={elm}>
                          {elm}
                        </option>
                      ))}
                    </select>
                    <svg
                      style={{ position: "absolute", right: "15" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                    {/* <input
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#F0F0F0",
                          border: "1px solid #DADCE0",
                        }}
                        type="text"
                        name="itemId1"
                        value={formdata3Tool1.itemId1}
                        onChange={handleOnChange3Tool1}
                        disabled
                      /> */}
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>Model</label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool1.model1}
                      name="model1"
                      onChange={handleOnChange3Tool1}
                      disabled
                    />
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>
                      Acceptance Criteria
                    </label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool1.acceptanceCriterial1}
                      name="acceptanceCriterial1"
                      onChange={handleOnChange3Tool1}
                      disabled
                    />
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>
                      Calibration Due Date
                    </label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool1.calibrationDueDate1}
                      name="calibrationDueDate1"
                      onChange={handleOnChange3Tool1}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div style={{ border: "1px solid #E7EBFF" }}></div>

              <div className={styles.step3Inputs}>
                <div>
                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>Tool 2</label>
                    <select
                      className={styles.inputFieldActive}
                      value={formdata3Tool2.tool2}
                      name="tool2"
                      onChange={handleOnChange3Tool2}
                      required>
                      <option value="">Select value</option>
                      {Description.map((Description) => (
                        <option key={Description} value={Description}>
                          {Description}
                        </option>
                      ))}
                    </select>
                    <svg
                      style={{ position: "absolute", right: "15" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>Make</label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool2.make2}
                      name="make2"
                      onChange={handleOnChange3Tool2}
                      disabled
                    />
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>S No.</label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool2.serialNumber2}
                      name="serialNumber2"
                      onChange={handleOnChange3Tool2}
                      disabled
                    />
                  </div>
                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>
                      Calibration Date
                    </label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool2.calibrationDate2}
                      name="calibrationDate2"
                      onChange={handleOnChange3Tool2}
                      disabled
                    />
                  </div>
                </div>

                <div>
                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>Item ID</label>
                    <select
                      className={
                        !formdata3Tool2.tool2
                          ? styles.inputFieldNotActive
                          : styles.inputFieldActive
                      }
                      value={formdata3Tool2.itemId2}
                      name="itemId2"
                      onChange={handleOnChange3Tool2}
                      disabled={!formdata3Tool2.tool2}
                      required>
                      <option value="">Select value</option>
                      {/* <option value={item_No2}>{item_No2}</option> */}
                      {item_No2?.map((elm, index) => (
                        <option key={index} value={elm}>
                          {elm}
                        </option>
                      ))}
                    </select>
                    <svg
                      style={{ position: "absolute", right: "15" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                    {/* <input
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#F0F0F0",
                          border: "1px solid #DADCE0",
                        }}
                        type="text"
                        name="itemId2"
                        value={formdata3Tool2.itemId2}
                        onChange={handleOnChange3Tool2}
                        disabled
                      /> */}
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>Model</label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool2.model2}
                      name="model2"
                      onChange={handleOnChange3Tool2}
                      disabled
                    />
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>
                      Acceptance Criteria
                    </label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool2.acceptanceCriterial2}
                      name="acceptanceCriterial2"
                      onChange={handleOnChange3Tool2}
                      disabled
                    />
                  </div>

                  <div className={styles.formElement}>
                    <label className={styles.labelStep3}>
                      Calibration Due Date
                    </label>
                    <input
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #DADCE0",
                      }}
                      type="text"
                      value={formdata3Tool2.calibrationDueDate2}
                      name="calibrationDueDate2"
                      onChange={handleOnChange3Tool2}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.btnContainer}>
              <BasicModal postOrPut={checkUpdateOrPost} />

              <Button
                variant="outlined"
                className={styles.backBtn}
                onClick={handleBack2}>
                Back
              </Button>

              <Button
                variant="contained"
                className={styles.proceedBtn}
                onClick={updateProjectatstep3}>
                Create Project
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CreatingANewProject;
