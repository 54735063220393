import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_inquiry_vendor_quotes_doc.module.css";
import TabsBarSparesInquiry from './TabsBarSparesInquiry';
import LoadingScreen from './LoadingScreen';
import Select from 'react-select';
import { Label } from "recharts";

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparesTradingInquiryVendorQuotesDocs() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks
    const [TabTracker, setTabTracker] = useState(2);
    const [editMode, setEditMode] = useState(false); // To track edit mode per item
    const [selectMode, setSelectMode] = useState(false);
    const navigate = useNavigate();
    const [formDatasm, setFormDatasm] = useState([]);    //used for storing the table data in the editable state, editModesm
    const [isDropdownVisible, setDropdownVisible] = useState(false);


    const handleCancel = () => {
        setEditMode(false);
        setSelectMode(false);
        setRefresh(!refresh);   // refresh page data
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleSelect = () => {
        setSelectMode(true);
    };

    async function fetchsmDetails() { //first loading of the page data and states, also used to refresh the page when save, cancel, cross etc. are clicked
        try {


            const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/workshop-spares-inquiry-vendor-quotes-docs/${id}`);


            const fetchedData = response.data.map(item => ({
                SupplierName: item.Supplier_Name,
                QuotationDoc: item.Quote_Doc,
                SupplierID: item.Supplier_ID
            }));

            setFormDatasm(fetchedData);

            console.log("response", response);
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {


        fetchsmDetails();
    }, [refresh]);

    console.log("FormDatasm", formDatasm)

    const dailyTrackerTab = (tabNumber) => {
        setTabTracker(tabNumber);
        if (tabNumber === 1) {
            navigate(`/workshop-spares-inquiry-vendor-quotes/${id}`);
        } else if (tabNumber === 2) {
            navigate(`/workshop-spares-inquiry-vendor-quotes-docs/${id}`);
        }
    };

    const downloadFile = (fileUrl) => {
        console.log("file", fileUrl)

        const a = document.createElement('a');
        if (fileUrl !== null) {
            fetch(fileUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = fileNameFromLink(fileUrl); // You can set a filename here
                    link.click();
                    URL.revokeObjectURL(blobUrl);
                })
                .catch((error) => {
                    console.error("Error downloading file:", error);
                });
        } else {
            console.log("There is no file to download");
        }
    };



    const fileNameFromLink = (link) => {
        if (typeof link === 'string' && link) {
            return link.split("/").pop().split("-").slice(1).join("-");
        }
        // Return an empty string if link is not valid
        return "";
    };

    const handleRemoveQuotationDoc = (index) => {
        // Update the formDatasm for the particular index and set QuotationDoc to null
        const updatedFormDatasm = [...formDatasm];
        updatedFormDatasm[index] = {
            ...updatedFormDatasm[index],
            QuotationDoc: null
        };
        setFormDatasm(updatedFormDatasm);
        console.log('data23', updatedFormDatasm);
    };


    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        setFormDatasm((prevFormDatasm) => {
            const updatedData = [...prevFormDatasm];
            if (!updatedData[index]) {
                updatedData[index] = {};
            }
            updatedData[index] = {
                ...updatedData[index],
                [name]: value,
            };
            return {
                ...prevFormDatasm,
                data: updatedData,
            };
        });
        console.log("formDatasm input change:", formDatasm);
    };

    const previewFile = (fileUrl) => {
        if (fileUrl) {
            window.open(fileUrl, "_blank");
        } else {
            console.log("No file to preview");
        }
    };

    console.log()

    const handleSave = async (e) => {
        e.preventDefault(); // Prevent form submission default behavior


        const formDataPromises = formDatasm
            .filter(item => item?.SupplierID) // Only process items with files and Supplier_IDs
            .map(async (item) => {
                const formDataObj = new FormData();
                // Append QuotationDoc only if it's not null or undefined
                if (item?.QuotationDoc) {
                    formDataObj.append("Quote_Doc", item.QuotationDoc);
                }
                try {
                    // Make API call for each Supplier_ID
                    const res = await axios.put(
                        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/docs/update/${id}/${item.SupplierID}`,
                        formDataObj,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            }
                        }
                    );
                    if (res.status === 200) {
                        console.log(`File uploaded successfully for Supplier ID ${item.SupplierID}`);
                    }
                } catch (error) {
                    console.error(`Error uploading file for Supplier ID ${item.SupplierID}`, error);
                }
            });

        // Wait for all the API calls to finish
        await Promise.all(formDataPromises);

        alert("All files uploaded successfully!");
        fetchsmDetails(); // Refresh data after all uploads are completed
        setEditMode(false); // Disable editing mode
    };

    return (
        <div>
            <LoadingScreen isLoading={isLoading} />

            <TabsBarSparesInquiry activeTab="Vendor Quotes" />

            <div className={styles.sections2}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {/* Left side - Tab */}
                    <>
                        <div style={{ display: "flex" }}>
                            <div
                                style={{
                                    borderBottomLeftRadius: "10px",
                                    borderTopLeftRadius: "10px",
                                    marginLeft: "10%",
                                    cursor: "pointer",  // Add this line to change the cursor to a pointer on hover

                                }}
                                className={
                                    TabTracker === 1 ? styles.aryaTabs1 : styles.aryaTabs2
                                }
                                onClick={() => dailyTrackerTab(1)}>
                                Listing
                            </div>
                            <div
                                style={{
                                    borderBottomRightRadius: "10px",
                                    borderTopRightRadius: "10px",
                                    marginLeft: "0%", // No need for margin, let them connect seamlessly
                                    cursor: "pointer",

                                }}
                                className={
                                    TabTracker === 2 ? styles.aryaTabs1 : styles.aryaTabs2
                                }
                                onClick={() => dailyTrackerTab(2)}>
                                Docs
                            </div>
                        </div>
                    </>
                    <div className={styles.buttonsdiv}
                        style={{
                            border: "2px solid #E7EBFF",
                            borderTop: "none",
                            borderBottom: "none",
                        }}>
                        {editMode || selectMode ? (
                            <>

                                <button
                                    variant="contained"
                                    onClick={handleSave}
                                    disabled={isLoading}
                                >
                                    Save Changes
                                </button>
                                <button
                                    variant="contained"
                                    className={styles.cancel}
                                    onClick={handleCancel}>
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <>
                                {/* <button
                                    variant="contained"
                                    onClick={handleSelect}>
                                    Select
                                </button> */}
                                <button
                                    variant="contained"
                                    onClick={handleEdit}>
                                    Edit
                                </button>
                            </>
                        )}
                    </div>
                </div>

                <div className={styles.smtablecontainer}>
                    <table className={styles.smtable}>
                        <thead>
                            <tr className={styles.headrowsm}>
                                <th className={styles.fileUploadheading}>S. No.</th>
                                <th className={styles.fileUploadheading}>Supplier Name</th>
                                <th className={styles.fileUploadheading}>Quotation Doc</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formDatasm.length > 0 ? ( // Only render table rows if formDatasm has data
                                formDatasm.map((data, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {data.SupplierName ? (
                                                data.SupplierName
                                            ) : (
                                                "-"
                                            )}
                                        </td>

                                        {/* Quotation Document Column */}
                                        <td>
                                            {editMode ? (
                                                <>
                                                    <div className={styles.uploadFieldContainer} style={{ position: "relative" }}>
                                                        <div className={editMode ? null : styles.textbox1}>
                                                            <div className={styles.customFileInput} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <input
                                                                    type="file"
                                                                    name="QuotationDoc"
                                                                    style={{ display: "none", width: "0" }}
                                                                    onChange={(e) => {
                                                                        if (e.target.files.length > 0) {
                                                                            const file = e.target.files[0];
                                                                            console.log("file", file);
                                                                            const updateddata = (prevFormDatasm) => {
                                                                                const updatedData = [...prevFormDatasm];
                                                                                updatedData[index] = { ...updatedData[index], QuotationDoc: file };
                                                                                return updatedData;
                                                                            }
                                                                            setFormDatasm(updateddata(formDatasm));
                                                                            console.log("data1", updateddata(formDatasm));
                                                                        }
                                                                    }}
                                                                    id={`Quotation_File_${index}`} // Unique ID per row
                                                                    disabled={!editMode}
                                                                />



                                                                <label style={{
                                                                    cursor: "pointer",
                                                                    border: '0.928px solid #e0dada',
                                                                    borderRadius: '8px',
                                                                    padding: '8px',
                                                                    width: '260px',
                                                                    fontSize: '14px',
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    marginLeft: "4px",
                                                                    marginTop: "5px",
                                                                    color: "rgba(128, 128, 128, 0.8)",
                                                                    overflow: "hidden", // Hide overflow text
                                                                    textOverflow: "ellipsis", // Display ellipsis for overflow text
                                                                    whiteSpace: "nowrap", // Prevent wrapping
                                                                    maxWidth: "280px", // Set maximum width for the file name
                                                                    alignItems: "",

                                                                }}
                                                                    onClick={() => document.getElementById(`Quotation_File_${index}`).click()}
                                                                >

                                                                    {formDatasm[index]?.QuotationDoc?.name ? formDatasm[index]?.QuotationDoc?.name : (fileNameFromLink(formDatasm[index].QuotationDoc) ? fileNameFromLink(formDatasm[index].QuotationDoc) : "Upload File")}



                                                                </label>
                                                                <div>
                                                                    <svg
                                                                        width="28"
                                                                        height="25"
                                                                        viewBox="0 0 28 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        style={{
                                                                            position: "absolute", // Use absolute positioning
                                                                            marginLeft: "-5%", // Adjust position based on your layout
                                                                            top: "55%", // Vertically center the SVG
                                                                            transform: "translateY(-50%)", // Correct vertical alignment
                                                                            pointerEvents: "none",
                                                                        }}
                                                                    >
                                                                        <path
                                                                            d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                                                            fill="black"
                                                                        />
                                                                        <path
                                                                            d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                                                            fill="black"
                                                                        />
                                                                    </svg>
                                                                </div>

                                                                {formDatasm[index]?.QuotationDoc && (
                                                                    <>
                                                                        <div style={{ marginLeft: "9px", cursor: "pointer" }}>
                                                                            <img
                                                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEJSURBVChTlZI9r0ZAEIXXbX1L0CoU/P9/IfGdUCgkSDSiolLMdeZlw3ur+yQbM2fmmLVLoRPxT36upyiKQkzTdGV/Qa0sy0+CSX3fYxrZtk1ZlkF6Ac2yLO4ZhoHYdBwHRVHEoq7rL2OapqyhFscx97IJzPNMQRBw0TAMyvOcF2JoYRhyD5AmMI6jNKqqygsxtNsAXiYAo+d53Izl+z5rT+Tp3ZxvFNu2XZkQ+76LZVmu7OIyM0mSkKZpPMF1XXIch2N8F2o30vRt6LqO2rblGBpqt5FN53h5rLirpmm4COq6Zu2euK7rx4STURSFt1NVFTc/gWaaJvdggNwe7uT7lJ6gdv5qZ0T0C/vJiM0XBD8WAAAAAElFTkSuQmCC"
                                                                                alt="preview"
                                                                                width="12.5"
                                                                                height="12.5"

                                                                                onClick={() => handleRemoveQuotationDoc(index)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {/* Optional preview or removal functionality
                                                                {!editMode && formDatasm[index]?.QuotationDoc && (
                                                                    <>



                                                                        <div className={'styles.uploadFieldContainer'}>

                                                                            <img
                                                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                                                                                alt="preview"
                                                                                width="40"
                                                                                height="40"
                                                                                style={{ marginLeft: "8px", cursor: "pointer" }}
                                                                                onClick={() => previewFile(formDatasm?.Upload_Inquiry_Received)}
                                                                            />

                                                                        </div>

                                                                        <div className={'styles.uploadFieldContainer'}>

                                                                            <img
                                                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                                                                                alt="preview"
                                                                                width="40"
                                                                                height="40"
                                                                                style={{ marginLeft: "5px", cursor: "pointer" }}
                                                                                onClick={() => downloadFile(formDatasm?.Upload_Inquiry_Received)}
                                                                            />

                                                                        </div>

                                                                    </>
                                                                )} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <label style={{
                                                        padding: '8px',
                                                        width: '240px',
                                                        fontSize: '14px',
                                                        fontFamily: 'Arial, sans-serif',
                                                        marginLeft: "4px",
                                                        marginTop: "5px",
                                                        overflow: "hidden", // Hide overflow text
                                                        textOverflow: "ellipsis", // Display ellipsis for overflow text
                                                        whiteSpace: "nowrap", // Prevent wrapping
                                                        maxWidth: "260px", // Set maximum width for the file name
                                                        alignItems: "",

                                                    }}>
                                                        {fileNameFromLink(data.QuotationDoc) || "No Document"}
                                                    </label>


                                                    {fileNameFromLink(data.QuotationDoc) && (
                                                        <>
                                                            <div className={styles.uploadFieldContainer}>
                                                                <img
                                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                                                                    alt="preview"
                                                                    width="40"
                                                                    height="40"
                                                                    style={{ marginLeft: "8px", cursor: "pointer" }}
                                                                    onClick={() => previewFile(data.QuotationDoc)}
                                                                />
                                                            </div>
                                                            <div className={styles.uploadFieldContainer}>
                                                                <img
                                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                                                                    alt="download"
                                                                    width="40"
                                                                    height="40"
                                                                    style={{ marginLeft: "5px", cursor: "pointer" }}
                                                                    onClick={() => downloadFile(data.QuotationDoc)}
                                                                />
                                                            </div>
                                                        </>
                                                    )}


                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))

                            ) : (
                                <tr>
                                    <td colSpan="3">No data available</td> {/* Render this if no data exists */}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


    )


}
export default SparesTradingInquiryVendorQuotesDocs;