import React from "react";
import styles from "./css/incomingdeliveriesupdate.module.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';


function IncomingDeliveries_Update() {
  const { id, S_No } = useParams();
  const navigate = useNavigate();
  const [indetails, setindetails] = useState([]);
  const [projectList, setProjectList] = useState({});
  const [refresh, setrefresh] = useState(false);
  const [receivedDeliveryStatus, setReceivedDeliveryStatus] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editrow, seteditrow] = useState("");
  const [tempCIPLdoc, setTempCIPLdoc] = useState("");
  const [tempCustExitDoc, setTempCustExitDoc] = useState("");
  const [tempErgonEntryDoc, setTempErgonEntryDoc] = useState("");
  const [tempMOPAAttestation, setTempMOPAAttestation] = useState("");
  const [removeCIPLdoc, setRemoveCIPLdoc] = useState(false);
  const [removeCustExitDoc, setRemoveCustExitDoc] = useState(false);
  const [removeErgonEntryDoc, setRemoveErgonEntryDoc] = useState(false);
  const [removeMOPAAttestation, setRemoveMOPAAttestation] = useState(false);
  const [featureDeliveriesedit,setfeatureDeliveriesedit] = useState([]);

  const [formDataDelivery, setFormDataDelivery] = useState({
    S_No: "",
    CIPL_Doc_No: "",
    CIPL_Doc: "",
    Customer_Exit_Bill_No: "",
    Customer_Exit_Bill_Doc: "",
    Entry_Bill_Doc: "",
    MOPA_Attestation: "",
    Dispatching_Party: "",
    Expected_Arrival_Date: "",
    ID_No: "",
    Entry_Bill_No: "",
    Entry_Bill_Date: "",
    Customer_Exit_Bill_Date: "",
    CIPL_Date: "",
    Received_Delivery_Status: "",
  });

  async function fetchsmDetails() {
    try {
      const response = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_details?ID_No=${id}`
      );
      console.log(response.data.data);
      setindetails(response.data.data);
      const filterDeliveryData = response.data.data.filter((el) => el.S_No == S_No);
      console.log(filterDeliveryData);

      setFormDataDelivery({
        ...formDataDelivery,
        S_No: filterDeliveryData[0].S_No,
        CIPL_Doc_No: filterDeliveryData[0].CIPL_Doc_No,
        CIPL_Doc: filterDeliveryData[0].CIPL_Doc,
        Customer_Exit_Bill_No: filterDeliveryData[0].Customer_Exit_Bill_No,
        Customer_Exit_Bill_Doc: filterDeliveryData[0].Customer_Exit_Bill_Doc,
        Entry_Bill_Doc: filterDeliveryData[0].Entry_Bill_Doc,
        MOPA_Attestation: filterDeliveryData[0].MOPA_Attestation,
        Dispatching_Party: filterDeliveryData[0].Dispatching_Party,
        Expected_Arrival_Date: filterDeliveryData[0].Expected_Arrival_Date,
        ID_No: filterDeliveryData[0].ID_No,
        Entry_Bill_No: filterDeliveryData[0].Entry_Bill_No,
        Entry_Bill_Date: filterDeliveryData[0].Entry_Bill_Date,
        Customer_Exit_Bill_Date: filterDeliveryData[0].Customer_Exit_Bill_Date,
        CIPL_Date: filterDeliveryData[0].CIPL_Date,
        Received_Delivery_Status: filterDeliveryData[0].Received_Delivery_Status,
        Incoming_Inspection_ID: filterDeliveryData[0].Incoming_Inspection_Id,
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchsmDetails();
    console.log(formDataDelivery);
  }, [refresh]);

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  const getBackgroundColor = () => {
    return formDataDelivery.Received_Delivery_Status === "1" ? '#077D7D' : formDataDelivery.Expected_Arrival_Date < getCurrentDate() ? '#900909' : '#EFC221';
  };

  const getTextColor = () => {
    return formDataDelivery.Received_Delivery_Status === "1" ? '#FFFFFF' : formDataDelivery.Expected_Arrival_Date < getCurrentDate() ? '#FFFFFF' : '#000000';
  };

  const getButtonText = () => {
    return formDataDelivery.Received_Delivery_Status === "1" ? 'RECEIVED' : formDataDelivery.Expected_Arrival_Date < getCurrentDate() ? 'PENDING' : 'UPCOMING';
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const currentDate = getCurrentDate();

  const handleBackButtonIncoming = () => {
    navigate(`/universal_deliveries`);
  };

  const handleInspectionNavigate = () => {
    navigate(`/incoming-inspections-workshop/${S_No}`);
  }

  const handleEditClick = () => {
    setIsEditing(true);
    seteditrow({
      S_No: formDataDelivery?.S_No,
      CIPL_Doc_No: formDataDelivery?.CIPL_Doc_No,
      CIPL_Date: formDataDelivery?.CIPL_Date,
      CIPL_Doc: formDataDelivery?.CIPL_Doc,
      Customer_Exit_Bill_No: formDataDelivery?.Customer_Exit_Bill_No,
      Customer_Exit_Bill_Date: formDataDelivery?.Customer_Exit_Bill_Date,
      Customer_Exit_Bill_Doc: formDataDelivery?.Customer_Exit_Bill_Doc,
      Entry_Bill_No: formDataDelivery?.Entry_Bill_No,
      Entry_Bill_Date: formDataDelivery?.Entry_Bill_Date,
      Entry_Bill_Doc: formDataDelivery?.Entry_Bill_Doc,
      MOPA_Attestation: formDataDelivery?.MOPA_Attestation,
      Dispatching_Party: formDataDelivery?.Dispatching_Party,
      Expected_Arrival_Date: formDataDelivery?.Expected_Arrival_Date,
      Received_Delivery_Status: formDataDelivery?.Received_Delivery_Status,
    })
    setReceivedDeliveryStatus(formDataDelivery.Received_Delivery_Status);
    console.log(receivedDeliveryStatus);
    // Add logic to handle edit click
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    setIsEditing(false);
    let num = S_No;
    const {
      CIPL_Doc_No,
      CIPL_Doc,
      CIPL_Date,
      Customer_Exit_Bill_No,
      Customer_Exit_Bill_Doc,
      Customer_Exit_Bill_Date,
      Entry_Bill_No,
      Entry_Bill_Date,
      Entry_Bill_Doc,
      MOPA_Attestation,
      Dispatching_Party,
      Expected_Arrival_Date,
      Received_Delivery_Status,
    } = editrow

    // Set Received_Delivery_Status conditionally
    const finalReceivedDeliveryStatus = Expected_Arrival_Date <= currentDate ? receivedDeliveryStatus : "0";

    const removeConditions = [
      { remove: removeCIPLdoc, temp: tempCIPLdoc, type: "CIPL_Doc" },
      { remove: removeCustExitDoc, temp: tempCustExitDoc, type: "Customer_Exit_Bill_Doc" },
      { remove: removeErgonEntryDoc, temp: tempErgonEntryDoc, type: "Entry_Bill_Doc" },
      { remove: removeMOPAAttestation, temp: tempMOPAAttestation, type: "MOPA_Attestation" },
    ];

    for (const condition of removeConditions) {
      if (condition.remove && condition.temp === "") {
        const payloadRemoveFiles = { type: condition.type };
        await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_removefiles/${S_No}`, payloadRemoveFiles);
      }
    }

    const payload = {
      CIPL_Doc_No: CIPL_Doc_No,
      Customer_Exit_Bill_No: Customer_Exit_Bill_No,
      Entry_Bill_No: Entry_Bill_No,
      Dispatching_Party: Dispatching_Party,
      Expected_Arrival_Date: Expected_Arrival_Date,
      ID_No: id,
      CIPL_Date: CIPL_Date,
      Customer_Exit_Bill_Date: Customer_Exit_Bill_Date,
      Entry_Bill_Date: Entry_Bill_Date,
      Received_Delivery_Status: finalReceivedDeliveryStatus,
    }

    const payloadfiles = {
      CIPL_Doc: CIPL_Doc,
      Customer_Exit_Bill_Doc: Customer_Exit_Bill_Doc,
      Entry_Bill_Doc: Entry_Bill_Doc,
      MOPA_Attestation: MOPA_Attestation,
    }

    console.log(payload);
    console.log(payloadfiles);

    const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_update?SNO=${num}`,
      payload);

    const res1 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_files?S_No=${num}`,
      payloadfiles,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    setTempCIPLdoc("");
    setTempCustExitDoc("");
    setTempErgonEntryDoc("");
    setTempMOPAAttestation("");
    setRemoveCIPLdoc(false);
    setRemoveCustExitDoc(false);
    setRemoveErgonEntryDoc(false);
    setRemoveMOPAAttestation(false);
    setrefresh(!refresh);
    window.location.reload();
    // Add logic to save changes
  };

  const handleCancelClick = () => {
    setTempCIPLdoc("");
    setTempCustExitDoc("");
    setTempErgonEntryDoc("");
    setTempMOPAAttestation("");
    setIsEditing(false);
    fetchsmDetails();
  };

  const handleinput1 = (e) => {
    const { name, value } = e.target
    seteditrow({ ...editrow, [name]: value })
    console.log(name, value);
    console.log(editrow);
  }

  const handlefileupload1 = (uploadname, e) => {
    const { name } = e.target
    let tempFile = e.target.files[0];
    console.log(tempFile);
    const inputElement = document.getElementsByName(uploadname)[0];
    const selectedFile = inputElement.files[0];
    // console.log(inputElement);
    // console.log(selectedFile);
    switch (name) {
      case "CIPL_Doc":
        setTempCIPLdoc(selectedFile?.name || ""); // Set to blank if no file is selected
        break;
      case "Customer_Exit_Bill_Doc":
        setTempCustExitDoc(selectedFile?.name || "");
        break;
      case "Entry_Bill_Doc":
        setTempErgonEntryDoc(selectedFile?.name || "");
        break;
      case "MOPA_Attestation":
        setTempMOPAAttestation(selectedFile?.name || "");
        break;
      // Add more cases for other file inputs if needed
      default:
        break;
    }
    seteditrow({ ...editrow, [name]: tempFile });
    inputElement.value = "";
    tempFile.value = "";
  }

  // Function to remove file name from input field using temp states, and to remove the file from payload using the remove states
  const handleRemoveFile = (name) => {
    switch (name) {
      case "CIPL_Doc":
        setTempCIPLdoc(""); // Set blank
        setRemoveCIPLdoc(true);
        break;
      case "Customer_Exit_Bill_Doc":
        setTempCustExitDoc("");
        setRemoveCustExitDoc(true);
        break;
      case "Entry_Bill_Doc":
        setTempErgonEntryDoc("");
        setRemoveErgonEntryDoc(true);
        break;
      case "MOPA_Attestation":
        setTempMOPAAttestation("");
        setRemoveMOPAAttestation(true);
        break;
  
      // Add more cases for other file inputs if needed
      default:
        break;
    }
    seteditrow({ ...editrow, [name]: null }); // Remove the file from the editrow
  };

  const handleforwarddoc = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = ""; // Set desired filename
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // const handlePutUpdate = async(e) =>{
  //     e.preventDefault();
  //     setIsEditing(false);
  //     let num = S_No;
  //     const {
  //       CIPL_Doc_No,
  //       CIPL_Doc,
  //       CIPL_Date,
  //       Customer_Exit_Bill_No,
  //       Customer_Exit_Bill_Doc,
  //       Customer_Exit_Bill_Date,
  //       Entry_Bill_No,
  //       Entry_Bill_Date,
  //       Entry_Bill_Doc,
  //       MOPA_Attestation,
  //       Dispatching_Party,
  //       Expected_Arrival_Date,
  //     } = editrow

  //     const payload = {
  //         CIPL_Doc_No: CIPL_Doc_No,
  //         Customer_Exit_Bill_No: Customer_Exit_Bill_No,
  //         Entry_Bill_No: Entry_Bill_No,
  //         Dispatching_Party: Dispatching_Party,
  //         Expected_Arrival_Date: Expected_Arrival_Date,
  //         ID_No: id,
  //         CIPL_Date: CIPL_Date,
  //         Customer_Exit_Bill_Date: Customer_Exit_Bill_Date,
  //         Entry_Bill_Date: Entry_Bill_Date,
  //     }

  //     const payloadfiles = {
  //         CIPL_Doc: CIPL_Doc,
  //         Customer_Exit_Bill_Doc: Customer_Exit_Bill_Doc,
  //         Entry_Bill_Doc: Entry_Bill_Doc,
  //         MOPA_Attestation: MOPA_Attestation,
  //     }

  //     console.log(payload);
  //     console.log(payloadfiles);

  //     const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_update?SNO=${num}`,
  //         payload);

  //     const res1 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_files?S_No=${num}`,
  //         payloadfiles,
  //       {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //     );

  //   setrefresh(!refresh)
  // }

  useEffect(()=>{
    async function fetchRigDetails(){
    try{
      const response = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
      );
    
    
 
      const workshopdeliveriesfeatureedit= response.data.filter((el)=>{
        return(
        el.Group_Name === localStorage.getItem("Group") &&
        el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Incoming Delivery Edit" && (el.Access === "Can View" || el.Access === "Can Edit")
        );
      })
 
    
      setfeatureDeliveriesedit(workshopdeliveriesfeatureedit);
    
    }catch(error){
      console.error(error);
    }
    }
    fetchRigDetails();
      },[]);

  return (
    <div>
      <div className={styles.topButtonContainer}>
        <button
          className={styles.topBackButton}
          onClick={handleBackButtonIncoming}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
              fill="#5B6BE1"
            />
          </svg>
          {' '}Incoming Deliveries
        </button>
      </div>

      <div className={styles.topdiv}>
        <div className={styles.topsubdiv}>
          <label className={styles.projectDiv}>
            Project SO: {formdata1.salesOrderNo}
          </label>
          <label className={styles.projectDiv}>
            Project Design: {formdata1.Design}
          </label>
          <label className={styles.projectDiv}>
            Project Name: {formdata1.ProjectName}
          </label>
          <label className={styles.projectDiv}>
            Project Scope: {formdata1.Scope}
          </label>
        </div>
        <div className={styles.topsubdiv}>
          <label className={styles.projectDiv} style={{ margin: "1%", marginLeft: "0.2%", marginTop: "0px", marginBottom: "0px" }}>
            Incoming Delivery ID: {S_No}
          </label>
          <label className={styles.projectDiv} style={{ margin: "1%", marginLeft: "0.2%", marginTop: "0px", marginBottom: "0px" }}>
            <span>Incoming Inspection ID: {formDataDelivery.Incoming_Inspection_ID}</span>
            <svg onClick={handleInspectionNavigate} width="20" height="20" viewBox="0 0 24 24" style={{ marginLeft: "10px", pointerEvents: "all", cursor: "pointer" }}>
              <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
            </svg>

          </label>
                    <label
            className={styles.deliveryStatusDiv}
            style={{ backgroundColor: getBackgroundColor(), color: getTextColor() }}
          >
            Delivery Status: {getButtonText()}
          </label>

        </div>
      </div>

      {!isEditing ? (
        // Non-editable display of data
        <div className={styles.maindiv}>
          <div className={styles.formdiv}>
            <h1 style={{ fontSize: "20px", fontWeight: 700, color: "black", marginBottom: "5px" }}>
              ID: {S_No}
            </h1>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Expected Arrival Date * :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={formatDate(formDataDelivery.Expected_Arrival_Date)}
                  disabled
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Dispatching Party :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={formDataDelivery.Dispatching_Party}
                  disabled
                />
              </div>
            </div>

            {/* Customer CIPL */}
            <h1 style={{ fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #5B6BE1", marginTop: "40px" }}>
              Customer CIPL
            </h1>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Number :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={formDataDelivery.CIPL_Doc_No}
                  disabled
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Date :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={formatDate(formDataDelivery.CIPL_Date)}
                  disabled
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Upload Document :
                </label>
                <input
                  className={styles.textbox1}
                  name="CIPL_Doc"
                  disabled
                  value={formDataDelivery.CIPL_Doc?.toString().split("/")
                    .pop()
                    .split("-")
                    .slice(1)
                    .join("-")}
                />
              </div>
              {formDataDelivery.CIPL_Doc && (
                <>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "20px", marginTop: "35px" }}
                    onClick={() => handleforwarddoc(formDataDelivery.CIPL_Doc)}
                  >
                    <path
                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                      fill="#292D32"
                    />
                  </svg>
                  <a href={formDataDelivery.CIPL_Doc} target=" _blank" >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginLeft: "10px", marginTop: "35px" }}
                    >
                      <path
                        d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                        fill="black"
                      />
                      <path
                        d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                        fill="black"
                      />
                    </svg>
                  </a>
                </>
              )}
            </div>

            {/* Customer Exit Bill / Delivery Note */}
            <h1 style={{ fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #5B6BE1", marginTop: "40px" }}>
              Customer Exit Bill / Delivery Note
            </h1>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Number :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={formDataDelivery.Customer_Exit_Bill_No}
                  disabled
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Date :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={formatDate(formDataDelivery.Customer_Exit_Bill_Date)}
                  disabled
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Upload Document :
                </label>
                <input
                  className={styles.textbox1}
                  name="Customer_Exit_Bill_Doc"
                  disabled
                  value={formDataDelivery.Customer_Exit_Bill_Doc?.toString().split("/")
                    .pop()
                    .split("-")
                    .slice(1)
                    .join("-")}
                />
              </div>
              {formDataDelivery.Customer_Exit_Bill_Doc && (
                <>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "20px", marginTop: "35px" }}
                    onClick={() => handleforwarddoc(formDataDelivery.Customer_Exit_Bill_Doc)}
                  >
                    <path
                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                      fill="#292D32"
                    />
                  </svg>
                  <a href={formDataDelivery.Customer_Exit_Bill_Doc} target=" _blank" >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginLeft: "10px", marginTop: "35px" }}
                    >
                      <path
                        d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                        fill="black"
                      />
                      <path
                        d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                        fill="black"
                      />
                    </svg>
                  </a>
                </>
              )}
            </div>

            {/* Ergon Entry Bill */}
            <h1 style={{ fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #5B6BE1", marginTop: "40px" }}>
              Ergon Entry Bill
            </h1>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Number :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={formDataDelivery.Entry_Bill_No}
                  disabled
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Date :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={formatDate(formDataDelivery.Entry_Bill_Date)}
                  disabled
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Upload Document :
                </label>
                <input
                  className={styles.textbox1}
                  name="Entry_Bill_Doc"
                  disabled
                  value={formDataDelivery.Entry_Bill_Doc?.toString().split("/")
                    .pop()
                    .split("-")
                    .slice(1)
                    .join("-")}
                />
              </div>
              {formDataDelivery.Entry_Bill_Doc && (
                <>

                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "20px", marginTop: "35px" }}
                    onClick={() => handleforwarddoc(formDataDelivery.Entry_Bill_Doc)}
                  >
                    <path
                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                      fill="#292D32"
                    />
                  </svg>
                  <a href={formDataDelivery.Entry_Bill_Doc} target=" _blank" >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginLeft: "10px", marginTop: "35px" }}
                    >
                      <path
                        d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                        fill="black"
                      />
                      <path
                        d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                        fill="black"
                      />
                    </svg>
                  </a>
                </>
              )}
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  MOFA Attestation :
                </label>
                <input
                  className={styles.textbox1}
                  name="MOPA_Attestation"
                  disabled
                  value={formDataDelivery.MOPA_Attestation?.toString().split("/")
                    .pop()
                    .split("-")
                    .slice(1)
                    .join("-")}
                />
              </div>
              {formDataDelivery.MOPA_Attestation && (
                <>

                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "20px", marginTop: "35px" }}
                    onClick={() => handleforwarddoc(formDataDelivery.MOPA_Attestation)}
                  >
                    <path
                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                      fill="#292D32"
                    />
                  </svg>
                  <a href={formDataDelivery.MOPA_Attestation} target=" _blank" >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginLeft: "10px", marginTop: "35px" }}
                    >
                      <path
                        d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                        fill="black"
                      />
                      <path
                        d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                        fill="black"
                      />
                    </svg>
                  </a>
                </>
              )}
            </div>

          </div>
          {featureDeliveriesedit.length !== 0 && (
          <div className={styles.buttonsdiv}>
            <button onClick={handleEditClick}>
              Edit{" "}
              <svg
                width="12"
                height="12"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-5px", marginLeft: "5px" }}
              >
                <path
                  d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          )}
        </div>
      ) : (
        // Editable data state
        <div className={styles.maindiv}>
          <div className={styles.formdiv}>
            <h1 style={{ fontSize: "20px", fontWeight: 700, color: "black", marginBottom: "5px" }}>
              ID: {S_No}
            </h1>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Expected Arrival Date * :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="date"
                  value={editrow.Expected_Arrival_Date}
                  name="Expected_Arrival_Date"
                  onChange={handleinput1}
                />
                {editrow.Expected_Arrival_Date <= currentDate && (
                  <>
                    <input
                      type="checkbox"
                      checked={receivedDeliveryStatus === "1"}
                      style={{ marginLeft: "30px", width: "12px", height: "12px" }}
                      onChange={(e) => setReceivedDeliveryStatus(e.target.checked ? "1" : "0")}
                    />
                    <label style={{ marginLeft: '10px', color: "black", fontWeight: 600 }}>Mark Received</label>
                  </>
                )}
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Dispatching Party :
                </label>
                <>
                  <select
                    className={styles.textbox1}
                    name="Dispatching_Party"
                    value={editrow.Dispatching_Party}
                    onChange={handleinput1}
                  >
                    <option value="">Select value</option>
                    <option value="Shelf Drilling">Shelf Drilling</option>
                  </select>
                  <svg
                    style={{ marginLeft: "-6.5%", marginTop: "-1%", pointerEvents: "none" }}
                    width="24"
                    height="24"
                    viewBox="0 0 28 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </>
              </div>
            </div>

            {/* Customer CIPL */}
            <h1 style={{ fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #5B6BE1", marginTop: "40px" }}>
              Customer CIPL
            </h1>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Number :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={editrow.CIPL_Doc_No}
                  name="CIPL_Doc_No"
                  onChange={handleinput1}
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Date :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="date"
                  value={editrow.CIPL_Date}
                  name="CIPL_Date"
                  onChange={handleinput1}
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Upload Document :
                </label>
                <input
                  type="file"
                  className={styles.textbox1}
                  style={{ display: "none", width: "0" }}
                  name="CIPL_Doc"
                  onInput={(e) => handlefileupload1("CIPL_Doc", e)}
                  id="cipl"
                />
                <div className={styles.uploadbox}
                  onClick={() => document.getElementById("cipl").click()}
                >
                  <span>
                    {tempCIPLdoc || (editrow.CIPL_Doc
                      ? editrow.CIPL_Doc?.toString().split("/").pop().split("-").slice(1).join("-")
                      : "Upload File")}
                  </span>
                </div>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 28 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginTop: "0.5%",
                    marginLeft: "-5.5%",
                    pointerEvents: "none",
                  }}

                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>
                {editrow.CIPL_Doc && (
                  <svg
                    width="20"
                    onClick={() => handleRemoveFile("CIPL_Doc")}
                    style={{
                      marginLeft: "15px",
                      cursor: "pointer",
                    }}
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="black"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="black"
                    />
                  </svg>
                )}
              </div>
            </div>

            {/* Customer Exit Bill / Delivery Note */}
            <h1 style={{ fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #5B6BE1", marginTop: "40px" }}>
              Customer Exit Bill / Delivery Note
            </h1>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Number :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={editrow.Customer_Exit_Bill_No}
                  name="Customer_Exit_Bill_No"
                  onChange={handleinput1}
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Date :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="date"
                  value={editrow.Customer_Exit_Bill_Date}
                  name="Customer_Exit_Bill_Date"
                  onChange={handleinput1}
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Upload Document :
                </label>
                <input
                  type="file"
                  className={styles.textbox1}
                  style={{ display: "none", width: "0" }}
                  name="Customer_Exit_Bill_Doc"
                  onInput={(e) => handlefileupload1("Customer_Exit_Bill_Doc", e)}
                  id="cust_exit_bill_doc"
                />
                <div className={styles.uploadbox}
                  onClick={() => document.getElementById("cust_exit_bill_doc").click()}
                >
                  <span>
                    {tempCustExitDoc || (editrow.Customer_Exit_Bill_Doc
                      ? editrow.Customer_Exit_Bill_Doc?.toString().split("/").pop().split("-").slice(1).join("-")
                      : "Upload File")}
                  </span>
                </div>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 28 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginTop: "0.5%",
                    marginLeft: "-5.5%",
                    cursor: "pointer",
                  }}

                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>
                {editrow.Customer_Exit_Bill_Doc && (
                  <svg
                    width="20"
                    onClick={() => handleRemoveFile("Customer_Exit_Bill_Doc")}
                    style={{
                      marginLeft: "15px",
                      cursor: "pointer",
                    }}
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="black"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="black"
                    />
                  </svg>
                )}
              </div>
            </div>

            {/* Ergon Entry Bill */}
            <h1 style={{ fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #5B6BE1", marginTop: "40px" }}>
              Ergon Entry Bill
            </h1>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Number :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="text"
                  value={editrow.Entry_Bill_No}
                  name="Entry_Bill_No"
                  onChange={handleinput1}
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Document Date :
                </label>
                <input
                  className={
                    styles.textbox1
                  }
                  type="date"
                  value={editrow.Entry_Bill_Date}
                  name="Entry_Bill_Date"
                  onChange={handleinput1}
                />
              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  Upload Document :
                </label>
                <input
                  type="file"
                  className={styles.textbox1}
                  style={{ display: "none", width: "0" }}
                  name="Entry_Bill_Doc"
                  onInput={(e) => handlefileupload1("Entry_Bill_Doc", e)}
                  id="ergon_entry_bill_doc"
                />
                <div className={styles.uploadbox}
                  onClick={() => document.getElementById("ergon_entry_bill_doc").click()}
                >
                  <span>
                    {tempErgonEntryDoc || (editrow.Entry_Bill_Doc
                      ? editrow.Entry_Bill_Doc?.toString().split("/").pop().split("-").slice(1).join("-")
                      : "Upload File")}
                  </span>
                </div>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 28 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginTop: "0.5%",
                    marginLeft: "-5%",
                    cursor: "pointer",
                  }}

                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>
                {editrow.Entry_Bill_Doc && (
                  <svg
                    width="20"
                    onClick={() => handleRemoveFile("Entry_Bill_Doc")}
                    style={{
                      marginLeft: "15px",
                      cursor: "pointer",
                    }}
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="black"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="black"
                    />
                  </svg>
                )}

              </div>
            </div>
            <div className={styles.formRowContainer}>
              <div style={{ marginTop: "25px" }}>
                <label style={{ width: "200px", fontSize: "16px", fontWeight: 600 }}>
                  MOFA Attestation :
                </label>
                <input
                  type="file"
                  className={styles.textbox1}
                  style={{ display: "none", width: "0" }}
                  name="MOPA_Attestation"
                  onInput={(e) => handlefileupload1("MOPA_Attestation", e)}
                  id="mopa_attestation"
                />
                <div className={styles.uploadbox}
                  onClick={() => document.getElementById("mopa_attestation").click()}
                >
                  <span>
                    {tempMOPAAttestation || (editrow.MOPA_Attestation
                      ? editrow.MOPA_Attestation?.toString().split("/").pop().split("-").slice(1).join("-")
                      : "Upload File")}
                  </span>
                </div>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 28 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginTop: "0.5%",
                    marginLeft: "-5%",
                    cursor: "pointer",
                  }}

                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>
                {editrow.MOPA_Attestation && (
                  <svg
                    width="20"
                    onClick={() => handleRemoveFile("MOPA_Attestation")}
                    style={{
                      marginLeft: "15px",
                      cursor: "pointer",
                    }}
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="black"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="black"
                    />
                  </svg>
                )}

              </div>
            </div>

          </div>

          <div className={styles.buttonsdiv} >
            <button onClick={handleSaveClick} style={{ marginRight: "10px" }}>
              Save{" "}
            </button>

            <button onClick={handleCancelClick} style={{ background: "white", ":hover": { background: "#F5F5F5", }, border: "1px solid #5B6BE1", color: "#5B6BE1", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
              Cancel{" "}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default IncomingDeliveries_Update
