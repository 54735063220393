import { useEffect, useState, useRef } from "react";
import axios from "axios";
import styles from "../Components/css/inventoryutilizationlogs.module.css";
import style from "./css/modal.module.css";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function InventoryUtilizationLogs() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Transaction Logs");   //to set the purple background and white color style for active tab
  const [deleteMode, setDeleteMode] = useState(false);    //set true when delete button is clicked, to display the delete icon
  const [refresh, setRefresh] = useState(false);    //used for triggering the useEffect where all the get requests are, explicitly used in Edit, Save, Cancel and other buttons to refresh the data
  const [inventoryUtilData, setInventoryUtilData] = useState([]);   // stores the main inventory utilization details data to be displayed on the page
  const [openRemoveConfirmationModal, setOpenRemoveConfirmationModal] = useState(false);
  const [SNoToDelete, setSNoToDelete] = useState([]); 
  const [featuretransactionlogs,setfeaturetransactionlogs] = useState([]);
  const [featuredelete,setfeaturedelete] = useState([]);

    //to store the S_Nos to delete
  const [confirmationData, setConfirmationData] = useState({
    extractedSNo: null,
    DateOfUtilization: "",
    ItemName: "",
    PartNumber: "",
    QtyUtilized: "",
    UoM: "",
    ProjectSO: "",
  });   //to store the data of the entry being removed

  const handleDivClick = (tab, route) => {
    setActiveTab(tab);
    navigate(`${route}`);
  };

  const handleBackButtonClick = () => {
    navigate(`/admintaskbar`);
  };

  const handleDeleteClick = () => {
    setDeleteMode(true);
  }

  const handleSaveClick = async () => {
    try {
      await Promise.all(SNoToDelete.map(async (extractedSNo) => {
        await axios.delete(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_logs_delete?S_No=${extractedSNo}`
        );
      }));

      // Clear the SNoToDelete array after successful deletions
      setSNoToDelete([]);

    } catch (error) {
      console.error("Error submitting data:", error);
    }
    setRefresh(!refresh);
    setDeleteMode(false);
  }

  const handleCancelClick = () => {
    setDeleteMode(false);
    setSNoToDelete([]);
    setRefresh(!refresh);
  }

  const CustomModal = styled(Modal)`
    .MuiBackdrop-root {
      background-color: rgba(255, 255, 255, 0.1);
    }
  `;

  function formatDate(inputDate) {
    //console.log(inputDate);
    if (inputDate == null || inputDate == "") {
      return "-";
    }

    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      return "-";
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    async function fetchInventoryUtilization() { //first loading of the page data and states, also used to refresh the page when save, cancel etc. are clicked
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_logs_get"
        );
        console.log("response.data.data", response.data.data);

        const inventoryUtilRes = response.data.data;
    
        const updatedItemsReceived = await Promise.all(inventoryUtilRes.map(async (item) => {
          const ergonBOERes = await axios.get(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_get_ErgonBOE?Incoming_Delivery_ID=${item.Incoming_Delivery_ID}`
          );
          console.log("ergonBOERes", ergonBOERes);
          return {
            ...item,
            Entry_Bill_No: ergonBOERes.data.data[0]?.Entry_Bill_No,
          };
        }));
  
        console.log("Updated itemsReceived", updatedItemsReceived);
  
        setInventoryUtilData(updatedItemsReceived);

      } catch (error) {
        console.error(error);
      }
    }

    fetchInventoryUtilization();
  }, [refresh]);

  // State to hold inventory utilization data by date
  const [utilizationByDate, setUtilizationByDate] = useState({});

  // Recalculate utilizationByDate whenever inventoryUtilData changes
  useEffect(() => {
    const calculateUtilizationByDate = () => {
      const newData = {};
      inventoryUtilData.forEach(item => {
        if (!newData[item.Date_Of_Utilization]) {
          newData[item.Date_Of_Utilization] = [];
        }
        newData[item.Date_Of_Utilization].push(item);
      });
      setUtilizationByDate(newData);
    };

    calculateUtilizationByDate();
  }, [inventoryUtilData]);

  //console.log("utilizationByDate", utilizationByDate);

  const handleOpenRemoveConfirmationModal = async (e, item) => {
    e.preventDefault();
    setConfirmationData({
      extractedSNo: item.S_No,
      DateOfUtilization: item.Date_Of_Utilization,
      ItemName: item.Item_Name,
      PartNumber: item.Part_Number,
      QtyUtilized: item.Qty_Utilized,
      UoM: item.UoM,
      ProjectSO: item.Project_SO,
    });
    setOpenRemoveConfirmationModal(true);
  }

  const handleCloseRemoveConfirmationModal = async (e) => {
    e.preventDefault();
    setConfirmationData({
      extractedSNo: null,
      DateOfUtilization: "",
      ItemName: "",
      PartNumber: "",
      QtyUtilized: "",
      UoM: "",
      ProjectSO: "",
    });
    setOpenRemoveConfirmationModal(false);
  }

  const handleRemove = (e, extractedSNo) => {
    e.preventDefault();
    try {
      if (extractedSNo !== null) {
        //console.log("idToDelete:" + extractedSNo);

        // Store the extractedSNo in the SNoToDelete array
        setSNoToDelete(prevState => [...prevState, extractedSNo]);

        // Update inventoryUtilData on the frontend
        const updatedInventoryUtilData = inventoryUtilData.filter((item) => item.S_No !== extractedSNo);
        setInventoryUtilData(updatedInventoryUtilData);

        //console.log("updatedInventoryUtilData", updatedInventoryUtilData);

        setOpenRemoveConfirmationModal(false);
      } else {
        console.log("No ID to delete");
      }
    } catch (error) {
      console.error("Error removing data:", error);
    }
  };

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );
  
        const group = localStorage.getItem("Group");
        const roles = JSON.parse(localStorage.getItem("Roles")) || []; // Retrieve roles from localStorage
  
        // Group feature filter for Inventory Transaction Logs
        const transactionLogsGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Workshop Admin Dashboard" &&
            el.Feature_Name === "Inventory Transaction Logs" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Role feature filter for Inventory Transaction Logs
        const transactionLogsRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Workshop Admin Dashboard" &&
            el.Feature === "Inventory Transaction Logs" &&
            el.Access === "Can View"
          );
        });
  
        // Combine group and role features for Inventory Transaction Logs
        const combinedTransactionLogs = [...transactionLogsGroup, ...transactionLogsRole];
  
        // Group feature filter for Inventory Delete
        const deleteFeatureGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Workshop Admin Dashboard" &&
            el.Feature_Name === "Inventory Delete" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Role feature filter for Inventory Delete
        const deleteFeatureRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Workshop Admin Dashboard" &&
            el.Feature === "Inventory Delete" &&
            el.Access === "Can View"
          );
        });
  
        // Combine group and role features for Inventory Delete
        const combinedDeleteFeature = [...deleteFeatureGroup, ...deleteFeatureRole];
  
        // Prioritize "Can View" over "Can Edit"
        const prioritizeAccess = (features) => {
          const featureMap = {};
          features.forEach((feature) => {
            const key = `${feature.Header_Name || feature.Header}-${feature.Feature_Name || feature.Feature}`;
            if (!featureMap[key] || feature.Access === "Can View") {
              featureMap[key] = feature;
            }
          });
          return Object.values(featureMap);
        };
  
        // Set features with prioritized access
        setfeaturetransactionlogs(prioritizeAccess(combinedTransactionLogs));
        setfeaturedelete(prioritizeAccess(combinedDeleteFeature));
  
        console.log(transactionLogsGroup, transactionLogsRole);
        console.log(deleteFeatureGroup, deleteFeatureRole);
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);


  return (
    <div className={styles.topdiv}>
      <div className={styles.topRowDiv}>
        <svg
          width="40"
          height="26"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <path
            d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
            fill="white"
          />
        </svg>
        <h3 style={{ marginLeft: "1.25%", paddingBottom: "0.2%" }}>
          Details of Inventory Utilization
        </h3>
      </div>

      <div className={styles.sections1}>
        {" "}
        <div
          className={
            activeTab === "New Entry" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick("New Entry", "/Inventory_Utilization")
          }
        >
          New Entry
        </div>
        {featuretransactionlogs.length > 0 &&(<div
          className={
            activeTab === "Transaction Logs" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() => handleDivClick("Transaction Logs", "/InventoryUtilizationLogs")}
        >
          Transaction Logs
        </div>
        )}
      </div>

      <div className={styles.sections2}>
        <div className={styles.buttonsdiv}
          style={{
            border: "2px solid #E7EBFF",
            borderTop: "none",
            borderBottom: "none",
          }}>
          {deleteMode ? (
            <>
              <button
                variant="contained"
                onClick={handleSaveClick}
              >
                Save
              </button>
              <button
                variant="contained"
                className={styles.cancel}
                onClick={handleCancelClick}>
                Cancel
              </button>
            </>
          ) : (
            <>
            {featuredelete.length > 0 &&(
              <button
                variant="contained"
                onClick={handleDeleteClick}>
                Delete
              </button>
              )}
            </>
          )}
        </div>

        <div className={styles.smtablecontainer}>
          {Object.keys(utilizationByDate)
            .sort((a, b) => new Date(b) - new Date(a)) // Sort keys in descending order of date
            .map(date => (
              <div key={date}>
                <h5 className={styles.dateHeader}>
                  Date: {formatDate(date)}
                </h5>
                <table className={styles.smtable}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Item Name</th>
                      <th>Part Number</th>
                      <th>UoM</th>
                      <th>Opening Qty</th>
                      <th>Qty Utilized</th>
                      <th>Closing Qty</th>
                      <th>Project SO</th>
                      <th>BOE</th>
                      <th>Traceability Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {utilizationByDate[date].map((item, index) => (
                      <tr key={item.S_No}>
                        <td>
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                            {deleteMode &&
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 20"
                                fill="none"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => handleOpenRemoveConfirmationModal(e, item)}
                              >
                                <path
                                  d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                  fill="#567191"
                                />
                              </svg>
                            }
                            {index + 1}
                          </div>
                        </td>
                        <td>{item.Item_Name || "-"}</td>
                        <td>{item.Part_Number || "-"}</td>
                        <td>{item.UoM || "-"}</td>
                        <td>{item.Available_Qty || "-"}</td>
                        <td>{item.Qty_Utilized || "-"}</td>
                        <td>{item.Available_Qty ? (item.Qty_Utilized ? (item.Available_Qty - item.Qty_Utilized) : item.Available_Qty) : "-"}</td>
                        <td>{item.Project_SO || "-"}</td>
                        <td>{item.Entry_Bill_No || "-"}</td>
                        <td>{item.Traceability_Number || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
        </div>

        <div style={{ position: "", zIndex: "2" }}>
          <Modal
            open={openRemoveConfirmationModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              backdropFilter: "blur(4px)",
            }}
          >
            <Fade in={openRemoveConfirmationModal}>
              <Box
                sx={{
                  width: 440,
                  height: "auto",
                  p: 3,
                  backgroundColor: "#fff",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginLeft: "50px"  // Change justifyContent to space-between
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <svg
                      width="25"
                      height="25"
                      style={{ display: "flex" }}
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 47.5 47.5"
                      viewBox="0 0 47.5 47.5"
                      id="warning"
                    >
                      <defs>
                        <clipPath id="a">
                          <path d="M0 38h38V0H0v38Z"></path>
                        </clipPath>
                      </defs>
                      <g clip-path="url(#a)" transform="matrix(1.25 0 0 -1.25 0 47.5)">
                        <path
                          fill="#ffcc4d"
                          d="M0 0c-1.842 0-2.654 1.338-1.806 2.973l15.609 30.055c.848 1.635 2.238 1.635 3.087 0L32.499 2.973C33.349 1.338 32.536 0 30.693 0H0Z"
                          transform="translate(3.653 2)"
                        ></path>
                        <path
                          fill="#231f20"
                          d="M0 0c0 1.302.961 2.108 2.232 2.108 1.241 0 2.233-.837 2.233-2.108v-11.938c0-1.271-.992-2.108-2.233-2.108-1.271 0-2.232.807-2.232 2.108V0Zm-.187-18.293a2.422 2.422 0 0 0 2.419 2.418 2.422 2.422 0 0 0 2.419-2.418 2.422 2.422 0 0 0-2.419-2.419 2.422 2.422 0 0 0-2.419 2.419"
                          transform="translate(16.769 26.34)"
                        ></path>
                      </g>
                    </svg>
                    <h3
                      style={{
                        textAlign: "center",
                        font: "default",
                        fontWeight: "600",
                        fontSize: "25px",
                        marginLeft: "50px",
                      }}
                    >
                      WARNING !!!
                    </h3>
                  </div>
                  <svg
                    width="20"
                    onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                    style={{
                      marginLeft: "0px",
                      cursor: "pointer",
                    }}
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="black"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>Are you sure you want to remove this transaction from logs?</h2>
                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>
                  Date: &nbsp;
                  <label style={{ fontSize: "17px", fontWeight: "700" }}>
                    {formatDate(confirmationData.DateOfUtilization)}
                  </label>
                </h2>
                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>
                  Item Name: &nbsp;
                  <label style={{ fontSize: "17px", fontWeight: "700" }}>
                    {confirmationData.ItemName}
                  </label>
                </h2>
                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>
                  Part Number:  &nbsp;
                  <label style={{ fontSize: "17px", fontWeight: "700" }}>
                    {confirmationData.PartNumber}
                  </label>
                </h2>
                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>
                  Qty Utilized: &nbsp;
                  <label style={{ fontSize: "17px", fontWeight: "700" }}>
                    {`${confirmationData.QtyUtilized} ${confirmationData.UoM}`}
                  </label>
                </h2>
                <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>
                  Project SO: &nbsp;
                  <label style={{ fontSize: "17px", fontWeight: "700" }}>
                    {confirmationData.ProjectSO}
                  </label>
                </h2>


                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#5b6be1",
                      border: "1px solid #5b6be1",
                      borderRadius: "6px",
                      justifyContent: "center",
                      minWidth: "74px",
                      fontWeight: 600,
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                    onClick={(e) => handleRemove(e, confirmationData.extractedSNo)}
                  >
                    Yes
                  </button>
                  <button
                    style={{
                      backgroundColor: "white",
                      color: "#5b6be1",
                      border: "1px solid #5b6be1",
                      borderRadius: "6px",
                      justifyContent: "center",
                      minWidth: "74px",
                      fontWeight: 600,
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                    onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                  >
                    No
                  </button>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default InventoryUtilizationLogs