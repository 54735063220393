import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import styles from "../Components/css/documents.module.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
// import 'jspdf-autotable';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Documents() {
  const [val, setval] = useState("Open Spares");
  const [projectdetails, setprojectdetails] = useState([]);
  const [fill,setfill] = useState("false");
  const [checklistdata, setchecklistdata] = useState([]);
  const [upcomingdetails, setupcomingdetails] = useState([]);
  const [upcomingdetails2, setupcomingdetails2] = useState([]);
  const [upcomingdetails2a, setupcomingdetails2a] = useState([]);
  const [upcomingdetails3, setupcomingdetails3] = useState([]);
  const [upcomingdetails3a, setupcomingdetails3a] = useState([]);
  const [upcomingdetails4, setupcomingdetails4] = useState([]);
  const [upcomingdetails4a, setupcomingdetails4a] = useState([]);
  const [upcomingdetails5, setupcomingdetails5] = useState([]);
  const [upcomingdetails5a, setupcomingdetails5a] = useState([]);
  const [upcomingdetails6a, setupcomingdetails6a] = useState([]);
  const [upcomingdetails6, setupcomingdetails6] = useState([]);
  const [ongoingdetails, setongoingdetails] = useState([]);
  const [ongoingdetails2, setongoingdetails2] = useState([]);
  const [ongoingdetails2a, setongoingdetails2a] = useState([]);
  const [ongoingdetails3, setongoingdetails3] = useState([]);
  const [ongoingdetails3a, setongoingdetails3a] = useState([]);
  const [ongoingdetails4, setongoingdetails4] = useState([]);
  const [ongoingdetails4a, setongoingdetails4a] = useState([]);
  const [ongoingdetails5, setongoingdetails5] = useState([]);
  const [ongoingdetails5a, setongoingdetails5a] = useState([]);
  const [ongoingdetails6, setongoingdetails6] = useState([]);
  const [ongoingdetails6a, setongoingdetails6a] = useState([]);
  const [ongoingdetails7, setongoingdetails7] = useState([]);
  const [ongoingdetails7a, setongoingdetails7a] = useState([]);
  const [recentlydetails, setrecentlydetails] = useState([]);
  const [recentlydetails2, setrecentlydetails2] = useState([]);
  const [recentlydetails2a, setrecentlydetails2a] = useState([]);
  const [recentlydetails3, setrecentlydetails3] = useState([]);
  const [recentlydetails3a, setrecentlydetails3a] = useState([]);
  const [completedDetails, setCompletedDetails] = useState([]);
  const [combinedResults, setCombinedResults] = useState([]);
  const [ciplStatuses, setCiplStatuses] = useState({}); 
  const [ciplStatus, setCiplStatus] = useState({});
  const [smresults,setsmresults]=useState([]);
  const [fromdate,setfromdate]=useState("");
  const [todate,settodate]=useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const presentdate = new Date();

  const handlevalChange = (event) => {
    const selectedval = event.target.value;
    setval(selectedval);
    setfill("false");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const checkAllItemsOnboard = async (id) => {
    try {
      const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/cipl_item/${id}`);
      return response.data.allNotOnboarded;
    } catch (error) {
      console.error(`Error checking onboard status for ID ${id}:`, error);
      return false; // Return false if there's an error
    }
  };
  
  
  const fetchProject = async () => {
    const res = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details");
    const res1 = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project-checklistgetdetails");
    
    const projectData = res.data.data;
    const checklistData = res1.data.data;
    const today = new Date();
  
    // Map over the projectData using Promise.all to handle async code inside map
    const updatedProjectData = await Promise.all(projectData.map(async (project) => {
      const checklistItem = checklistData.find(item => item.ID_No === project.ID_No);
      const commencementDateParts = project.Estimated_Date_Of_Commencement.split("/");
      const completionDateParts = project.Estimated_Project_Completion_Month.split("/");
  
      const commencementDate = new Date(
        parseInt(commencementDateParts[2]),
        parseInt(commencementDateParts[1]) - 1,
        parseInt(commencementDateParts[0])
      );
  
      const completionDate = new Date(
        parseInt(completionDateParts[2]),
        parseInt(completionDateParts[1]) - 1,
        parseInt(completionDateParts[0])
      );
  
      // Call cipl_item API to check if there are any items for this project
      const itemsRes = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/cipl_item/${project.ID_No}`);
      let hasItems = true;
      if ('hasItems' in itemsRes.data) {
        hasItems = itemsRes.data.hasItems;

        
      }
  
      // If no items are found, skip this project
      if (!hasItems) {
        return null;  // Skip this project
      }
  
      // Determine the status based on provided logic
      let status = "Ongoing"; // Default status
  
      if (today < commencementDate) {
        status = "Upcoming";
      } else if (today > completionDate && checklistItem?.Item32 === "Completed") {
        status = itemsRes.data.allNotOnboarded ? "Completed" : "Ongoing";
      } else if (today > commencementDate && checklistItem?.Item32 !== "Completed") {
        status = "Ongoing";
      }
  
      return { ...project, Status: status, Remarks: checklistItem ? checklistItem.Issue7 : "-" };
    }));
  
    // Filter out null values (projects without items or incomplete data)
    const filteredProjectData = updatedProjectData.filter(project => project !== null);

    console.log("updated project",filteredProjectData)
  
    setprojectdetails(filteredProjectData);
    setchecklistdata(checklistData);
  };
  


  function filtersearch2(projectdetails, searchTerm) {
    return projectdetails.filter((project) => {
      const rigName = project.Rig_Name ? project.Rig_Name.toLowerCase() : "";
  
      // Check if project matches the search term and the selected project status
      const matchesSearch = searchTerm === "" || rigName.includes(searchTerm.toLowerCase());
  
     
  
      return matchesSearch;
    });
  }
  
  
  
  useEffect(() => {
    async function fetchsmDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesmobilization_data"
        );
        setsmresults(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchsmDetails();
  }, []);

  const fetchAllCIPL = async () => {
    try {
      const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/cipl_info2");
      const ciplData = response.data;
  
      // Map CIPL data by ID_No
      const ciplMap = ciplData.reduce((acc, entry) => {
        if (!acc[entry.ID_no]) {
          acc[entry.ID_no] = [];
        }
        acc[entry.ID_no].push(`${entry.CIPL_No_spares} - ${entry.DocumentsStatus} - ${entry.Date_of_arrival_on_rig || "-" }`);
        return acc;
      }, {});
  
      setCiplStatuses(ciplMap);
      setCiplStatus(ciplData);
    } catch (error) {
      console.error("Error fetching all CIPL data:", error);
    }
  };
  


  useEffect(() => {
    const fetchData = async () => {
      await checkAllItemsOnboard();
      fetchProject();
      fetchAllCIPL();
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    if (projectdetails.length > 0) {
      const upfilteredProjects = projectdetails.filter((el) => {
        if (el.Estimated_Date_Of_Commencement) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          return presentdate < estimatedDate;
        }
        return false; // Exclude elements with no valid date
      });
      setupcomingdetails(filtersearch2(upfilteredProjects,searchTerm));
      

      const openqt1 = upcomingdetails.filter((el) => el.Quote_No === "");
      setupcomingdetails2(filtersearch2(openqt1,searchTerm));

      

      if(fill==="false"){
        setupcomingdetails2a(openqt1);
      };


      const openqt3 = upcomingdetails.filter(
        (el) => el.Quote_No !== "" && el.Po_No === ""
      );
      setupcomingdetails3(openqt3);

      if(fill==="false"){
        setupcomingdetails3a(openqt3);
      };


      const openqt5 = upcomingdetails.filter(
        (el) =>
          el.Technician_Names === "" &&
          el.Supervisor_Names === ""
      );
      setupcomingdetails4(openqt5);

      if(fill==="false"){
        setupcomingdetails4a(openqt5);
      };

      const openqt10 = upcomingdetails.filter(
        (elem) =>{
          const smresults2 = smresults.filter((ele) => ele.ID_No === elem.ID_No);
          const smresults3 = smresults2.filter((ele) => ele.Status === "Onboarded");

          return(smresults2.length !== smresults3.length &&
            elem.Service_Component !== "Jacking System Inspection" &&
            elem.Service_Component !== "Crane System Inspection" &&
            elem.Service_Component !== "Skidding System Inspection" &&
            elem.Service_Component !== "Fixation System Inspection" &&
            elem.Service_Component !== "Drilling Equipment Inspection" &&
            elem.Service_Component !== "Supply Of Mechanics") ||
          (smresults2.length === smresults3.length &&
            smresults2.length === 0 &&
            elem.Service_Component !== "Jacking System Inspection" &&
            elem.Service_Component !== "Crane System Inspection" &&
            elem.Service_Component !== "Skidding System Inspection" &&
            elem.Service_Component !== "Fixation System Inspection" &&
            elem.Service_Component !== "Drilling Equipment Inspection" &&
            elem.Service_Component !== "Supply Of Mechanics")
          });
      setupcomingdetails5(openqt10);

      if(fill==="false"){
        setupcomingdetails5a(openqt10);
      };

      const openqt11 = upcomingdetails.filter(
        (elem) =>{
          return(!(elem.Sales_Order_No &&
          elem.Document_Date &&
          elem.Customer_Name &&
          elem.Rig_Type &&
          elem.Service_Component &&
          elem.Rig_Location &&
          elem.Tool1 &&
          elem.Make1 &&
          elem.Model1 &&
          elem.Serial_Number1 &&
          elem.Acceptance_Criteria1 &&
          elem.Tool2 &&
          elem.Make2 &&
          elem.Model2 &&
          elem.Serial_Number2 &&
          elem.Acceptance_Criteria2 &&
          elem.Estimated_Date_Of_Commencement &&
          elem.Estimated_Project_Completion_Month &&
          elem.Po_Date !== "Invalid Date" &&
          elem.Supervisor_Names &&
          elem.Technician_Names &&
          elem.Po_Days &&
          elem.Rig_Name &&
          elem.Po_No))});
      setupcomingdetails6(openqt11);

      if(fill==="false"){
        setupcomingdetails6a(openqt11);
      };

      const onfilteredprojects = projectdetails.filter((el) => {
        if (
          el.Estimated_Date_Of_Commencement 
        ) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
          return (
            presentdate > estimatedDate && check[0]?.Item32 !== "Completed"
          );
        }
        return false;
      });
      setongoingdetails(filtersearch2(onfilteredprojects,searchTerm));

      const onfilteredprojects1 = projectdetails.filter((el) => {
        if (
          el.Estimated_Date_Of_Commencement 
        ) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
          return (
            presentdate > estimatedDate && check[0]?.Item32 === "Completed"
          );
        }
        return false;
      });
      setCompletedDetails(filtersearch2(onfilteredprojects1,searchTerm));

      const openqt2 = ongoingdetails.filter((el) => el.Quote_No === "");
      setongoingdetails2(openqt2);

      if(fill==="false"){
        setongoingdetails2a(openqt2);
      };

      const openqt4 = ongoingdetails.filter(
        (el) => el.Quote_No !== "" && el.Po_No === ""
      );
      setongoingdetails3(openqt4);

      if(fill==="false"){
        setongoingdetails3a(openqt4);
      };

      const openqt6 = ongoingdetails.filter(
        (el) =>
          el.Technician_Names === "" &&
          el.Supervisor_Names === ""
      );
      setongoingdetails4(openqt6);

      if(fill==="false"){
        setongoingdetails4a(openqt6);
      };

      const openqt9 = ongoingdetails.filter(
        (elem) =>{
          const smresults2 = smresults.filter((ele) => ele.ID_No === elem.ID_No);
          const smresults3 = smresults2.filter((ele) => ele.Status === "Onboarded");

          return(smresults2.length !== smresults3.length &&
            elem.Service_Component !== "Jacking System Inspection" &&
            elem.Service_Component !== "Crane System Inspection" &&
            elem.Service_Component !== "Skidding System Inspection" &&
            elem.Service_Component !== "Fixation System Inspection" &&
            elem.Service_Component !== "Drilling Equipment Inspection" &&
            elem.Service_Component !== "Supply Of Mechanics") ||
          (smresults2.length === smresults3.length &&
            smresults2.length === 0 &&
            elem.Service_Component !== "Jacking System Inspection" &&
            elem.Service_Component !== "Crane System Inspection" &&
            elem.Service_Component !== "Skidding System Inspection" &&
            elem.Service_Component !== "Fixation System Inspection" &&
            elem.Service_Component !== "Drilling Equipment Inspection" &&
            elem.Service_Component !== "Supply Of Mechanics")
          });
      setongoingdetails5(openqt9);

      if(fill==="false"){
        setongoingdetails5a(openqt9);
      };

      const openqt12 = ongoingdetails.filter(
        (elem) =>{
          return(!(elem.Sales_Order_No &&
          elem.Document_Date &&
          elem.Customer_Name &&
          elem.Rig_Type &&
          elem.Service_Component &&
          elem.Rig_Location &&
          elem.Tool1 &&
          elem.Make1 &&
          elem.Model1 &&
          elem.Serial_Number1 &&
          elem.Acceptance_Criteria1 &&
          elem.Tool2 &&
          elem.Make2 &&
          elem.Model2 &&
          elem.Serial_Number2 &&
          elem.Acceptance_Criteria2 &&
          elem.Estimated_Date_Of_Commencement &&
          elem.Estimated_Project_Completion_Month &&
          elem.Po_Date !== "Invalid Date" &&
          elem.Supervisor_Names &&
          elem.Technician_Names &&
          elem.Po_Days &&
          elem.Rig_Name &&
          elem.Po_No))});
      setongoingdetails6(openqt12);

      if(fill==="false"){
        setongoingdetails6a(openqt12);
      };


      const recentfilteredprojects = projectdetails.filter((el) => {
        if (
          el.Estimated_Date_Of_Commencement 
        ) {
          const parts1 = el.Estimated_Date_Of_Commencement.split("/");
          const day1 = parseInt(parts1[0]);
          const month1 = parseInt(parts1[1]) - 1;
          const year1 = parseInt(parts1[2]);
          const estimatedcompletion = new Date(year1, month1, day1);
          const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
          let count = 0;
          for (let i = 1; i <= 40; i++) {
            const propertyName = "Item" + i;
            if (check[0]?.[propertyName] === "Completed") {
              count++;
            }
          }
          return (
            presentdate > estimatedcompletion &&
            check[0]?.Item32 === "Completed" &&
            count !== 40
          );
        }
        return false;
      });
      setrecentlydetails(filtersearch2(recentfilteredprojects,searchTerm));

      const openqt7 = recentlydetails.filter((el) => {
        const check = checklistdata.find((elm) => elm.ID_No === el.ID_No);
        return check.Item37 !== "Completed";
      });

      setrecentlydetails2(openqt7);

      const openqt8 = recentlydetails.filter((el) => {
        const check2 = checklistdata.find((elm) => elm.ID_No === el.ID_No);
        return check2.Item39 !== "Completed";
      });

      setrecentlydetails3(openqt8);
    }


      if(fill==="true"){
    const kill2 = ongoingdetails2.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setongoingdetails2a(kill2);

    const kill = upcomingdetails2.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setupcomingdetails2a(kill);

    const kill3 = upcomingdetails3.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setupcomingdetails3a(kill3);

    const kill4 = upcomingdetails4.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setupcomingdetails4a(kill4);

    const kill5 = upcomingdetails5.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setupcomingdetails5a(kill5);

    const kill6 = upcomingdetails6.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setupcomingdetails6a(kill6);


    const kill7 = ongoingdetails3.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setongoingdetails3a(kill7);

    const kill8 = ongoingdetails4.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setongoingdetails4a(kill8);

    const kill9 = ongoingdetails5.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setongoingdetails5a(kill9);

    const kill10 = ongoingdetails6.filter((el) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);
      const estimatedDate = new Date(year, month, day);
      const parts1 = fromdate.split("-");
      const day1 = parseInt(parts1[2]);
      const month1 = parseInt(parts1[1]) - 1;
      const year1 = parseInt(parts1[0]);
      const estimatedDate1 = new Date(year1, month1, day1);
      const parts2 = todate.split("-");
      const day2 = parseInt(parts2[2]);
      const month2 = parseInt(parts2[1]) - 1;
      const year2 = parseInt(parts2[0]);
      const estimatedDate2 = new Date(year2, month2, day2);
  
      return estimatedDate1 < estimatedDate && estimatedDate2 > estimatedDate;
    });
  
    setongoingdetails6a(kill10);
   
    
  };
  });

  const handleCombine = () => {
    // List of allowed service components
    const allowedServiceComponents = [
      "Jacking System Remediation",
      "Jacking System Overhaul",
      "Crane System Remediation",
      "Crane System Overhaul",
      "Fixation System Remediation",
      "Fixation System Overhaul",
      "Skidding System Remediation",
      "Skidding System Overhaul",
      "Drilling Equipment Remediation",
      "Braking System Remediation"
    ];
  
    // Combine the arrays and filter based on the allowed service components
    const combined = [...ongoingdetails5a, ...upcomingdetails5a]
      .sort((a, b) => a.ID_No - b.ID_No);
  
    const combined1 = [...combined, ...completedDetails]
      .filter(item => allowedServiceComponents.includes(item.Service_Component))
      .sort((a, b) => a.ID_No - b.ID_No);
  
    setCombinedResults(filtersearch2(combined1,searchTerm));
  };
  

  useEffect(() => {
    handleCombine();
  })
  

  const handledwn3 = () => {
    const form = document.getElementById(val);
    const table = form.querySelector("table");
    const rows = Array.from(table.querySelectorAll("tr"));

    const data = rows.map((row) =>
      Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
    );

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.write to generate the Excel file as an ArrayBuffer
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Use FileSaver.js to save the file
    saveAs(blob, "table_data.xlsx");
  };


  const handlefromdate=(event)=>{
    setfromdate(event.target.value);
    console.log(fromdate);
  };

  const handletodate=(event)=>{
    settodate(event.target.value);
    console.log(todate);
  };

  const handledatefil =()=>{
    setfill("true");
  };


  const handlerefreshsvg = () => {
setfill("false");
setfromdate("");
settodate("");
  }


  return (
    <>
    <div style={{overflow: "hidden", overflowY: "scroll"}}>
      <div className={styles.vishdiv}>
        <div className={styles.div5}>
        <div className={styles.drophead}>Select Project Status:</div>
        <div style={{maxWidth: "240px"}}>
          <select
            className={styles.dropdown}
            onChange={handlevalChange}
            value={val}
          >
            <option value="Open Spares" className={styles.dd}>
              Open Spares
            </option>
            <option value="Open Quote" className={styles.dd}>
              Open Quote
            </option>
            <option value="Open PO" className={styles.dd}>
              Open PO
            </option>
            <option value="Open Team" className={styles.dd}>
              Open Team
            </option>
            <option value="Open Final Invoice" className={styles.dd}>
              Open Final Invoice
            </option>
            <option value="Open Final Report" className={styles.dd}>
              Open Final Report
            </option>
            <option value="Open API Docs" className={styles.dd}>
              Open API Docs
            </option>
          </select>
        </div>
        </div>
    <div className={styles.searchContainer}>
    <div className={styles.Searchbar}>
    {/* <label>Date range</label>
    <input type="date"  value={fromdate} onChange={handlefromdate} style={{marginLeft:"10px"}}></input>
    <input type="date" value={todate} onChange={handletodate} style={{marginLeft:"10px"}}></input>
   {(val !== "Open Final Invoice" && val !== "Open Final Report" ) && ( <button onClick={handledatefil} className={styles.excelbtn2} >Filter</button>)}
   {(val !== "Open Final Invoice" && val !== "Open Final Report" ) && (<svg  style={{marginLeft:"15px"}} onClick ={handlerefreshsvg} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z"/></svg>)}
    */}
    <input
      type="text"
      placeholder="Search by Rig Name..."
      onChange={handleSearch}
      value={searchTerm}
    />
    <svg
        className={styles.searchIconFilter}
        style={{ marginLeft: "5%" }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
          fill="#0000008F"
        />
        <path
          d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
          fill="#0000008F"
        />
      </svg>
   </div>
   </div>

        <div className={styles.dwn}>
          <label className={styles.dwn2}> Report:</label>
          <button onClick={handledwn3} className={styles.excelbtn}>
            Download {val}
          </button>
        </div>
      </div>
      <div className={styles.tableContainer}>
      <div class="table-wrapper" style={{OverflowX: "auto"}}>
      {val === "Open Spares" && (
          <form id="Open Spares">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Rig Name </th>
                  <th>Status</th>
                  <th>Scope</th>
                  <th>SO No</th>
                  <th>Remarks</th>
                  <th>CIPL-Current Status-ETA to rig</th>
                  <th>Commencement Date</th>
                  <th>Completion Date</th>
                </tr>
              </thead>
              <tbody>
              {combinedResults
  .filter((el) => {
    // Only proceed if the project status is "Completed"
    if (el.Status === "Completed") {
      // Extract the CIPL statuses for the current project
      const ciplStatusList = ciplStatuses[el.ID_No] || [];

      // Check if any of the CIPL current statuses is "Onboard" and remove from list
      return !ciplStatusList.some((statusString) => {
        const [, currentStatus] = statusString.split(' - '); // Destructure to get the "current status"
        return currentStatus === "Onboard";
      });
    }
    
    // If the project is not "Completed", include it in the results
    return true;
  })
  .map((el, index) => {
                  return (
                    <tr key={el.ID_No}>
                      <td>{index + 1}</td>
                      <td>
                        {" "}
                        <NavLink
                          to="#"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default behavior
                            window.open(`/project_checklist/${el.ID_No}`, "_blank", "noopener,noreferrer");
                          }}
                          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                        >
                          {el.Rig_Name || "-"}
                        </NavLink>
                      </td>
                      <td>{el.Status ? el.Status : "-"}</td>
                      <td>{el.Service_Component ? el.Service_Component : "-"}</td>
                      <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                      <td style={{padding: "4px"}}><div style={{whiteSpace: 'nowrap', }}>{el.Remarks ? el.Remarks : "-"}</div></td>
                      <td style={{padding: "4px"}}><div style={{whiteSpace: 'pre-wrap', width: "600px"}}>{ciplStatuses[el.ID_No] 
                      ? ciplStatuses[el.ID_No].join('\n')
                      : "-"}</div>
                      </td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                      <td>
                        {el.Estimated_Project_Completion_Month
                          ? el.Estimated_Project_Completion_Month
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
                
              </tbody>
            </table>
          </form>
        )}
        {val === "Open Quote" && (
          <form id="Open Quote">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th> Rig Name </th>
                  <th> Scope </th>
                  <th>PO days</th>
                  <th>No.of professionals</th>
                  <th>Estimated Date of Commencement</th>
                </tr>
              </thead>
              <tbody>
                {ongoingdetails2a.map((el, index) => {
                  return (
                    <tr key={el.ID_No}>
                      <td>{index + 1}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>
                        {el.Service_Component ? el.Service_Component : "-"}
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Supervisor_Names?.split(",").length +
                          el.Technician_Names?.split(",").length}
                      </td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
                {upcomingdetails2a.map((el, index) => {
                  return (
                    <tr key={el.ID_No}>
                      <td>{ongoingdetails2.length + index + 1}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>
                        {el.Service_Component ? el.Service_Component : "-"}
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Supervisor_Names?.split(",").length +
                          el.Technician_Names?.split(",").length}
                      </td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </form>
        )}
        {val === "Open PO" && (
          <form id="Open PO">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Quote No</th>
                  <th> Rig Name </th>
                  <th> Scope </th>
                  <th>PO days</th>
                  <th>No.of professionals</th>
                  <th>Estimated Date of Commencement</th>
                </tr>
              </thead>
              <tbody>
                {ongoingdetails3a.map((el, index) => {
                  return (
                    <tr key={el.ID_No}>
                      <td>{index + 1}</td>
                      <td>{el.Quote_No ? el.Quote_No : "-"}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>
                        {el.Service_Component ? el.Service_Component : "-"}
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Supervisor_Names?.split(",").length +
                          el.Technician_Names?.split(",").length}
                      </td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
                {upcomingdetails3a.map((el, index) => {
                  return (
                    <tr key={el.ID_No}>
                      <td>{ongoingdetails3.length + index + 1}</td>
                      <td>{el.Quote_No ? el.Quote_No : "-"}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>
                        {el.Service_Component ? el.Service_Component : "-"}
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Supervisor_Names.split(",").length +
                          el.Technician_Names.split(",").length}
                      </td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </form>
        )}
        {val === "Open Team" && (
          <form id="Open Team">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Quote No</th>
                  <th>PO.No</th>
                  <th>So no</th>
                  <th> Rig Name </th>
                  <th> Scope </th>
                  <th>PO days</th>
                  <th>Estimated Date of Commencement</th>
                </tr>
              </thead>
              <tbody>
                {ongoingdetails4a.map((el, index) => {
                  return (
                    <tr key={el.ID_No}>
                      <td>{index + 1}</td>
                      <td>{el.Quote_No ? el.Quote_No : "-"}</td>
                      <td>{el.Po_No ? el.Po_No : "-"}</td>
                      <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>
                        {el.Service_Component ? el.Service_Component : "-"}
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
                {upcomingdetails4a.map((el, index) => {
                  return (
                    <tr key={el.ID_No}>
                      <td>{ongoingdetails4.length + index + 1}</td>
                      <td>{el.Quote_No ? el.Quote_No : "-"}</td>
                      <td>{el.Po_No ? el.Po_No : "-"}</td>
                      <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>
                        {el.Service_Component ? el.Service_Component : "-"}
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </form>
        )}
        {val === "Open Final Invoice" && (
          <form id="Open Final Invoice">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Quote No</th>
                  <th>PO.No</th>
                  <th>So no</th>
                  <th> Rig Name </th>
                  <th> Scope </th>
                  <th>PO days</th>
                  <th>No of professionals</th>
                  <th>Estimated Date of Commencement</th>
                  <th> Estimated date of Completion</th>
                  <th> Issues and Remarks </th>
                </tr>
              </thead>
              <tbody>
                {recentlydetails2.map((el, index) => {
                   const check4 = checklistdata.find((elm) => elm.ID_No === el.ID_No);
                  return (
                    <tr key={el.ID_No}>
                      <td>{index + 1}</td>
                      <td>{el.Quote_No ? el.Quote_No : "-"}</td>
                      <td>{el.Po_No ? el.Po_No : "-"}</td>
                      <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>
                        {el.Service_Component ? el.Service_Component : "-"}
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Supervisor_Names.split(",").length +
                          el.Technician_Names.split(",").length}
                      </td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                      <td>
                        {el.Estimated_Project_Completion_Month
                          ? el.Estimated_Project_Completion_Month
                          : "-"}
                      </td>
                      <td>
                        {check4.Issue37
                          ? check4.Issue37
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </form>
        )}
        {val === "Open Final Report" && (
          <form id="Open Final Report">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Quote No</th>
                  <th>PO.No</th>
                  <th>So no</th>
                  <th> Rig Name </th>
                  <th> Scope </th>
                  <th>PO days</th>
                  <th>No of professionals</th>
                  <th>Estimated Date of Commencement</th>
                  <th> Estimated date of Completion</th>
                  <th> Issues and Remarks </th>
                </tr>
              </thead>
              <tbody>
                {recentlydetails3.map((el, index) => {
                  const check3 = checklistdata.find((elm) => elm.ID_No === el.ID_No);
                  return (
                    <tr key={el.ID_No}>
                      <td>{index + 1}</td>
                      <td>{el.Quote_No ? el.Quote_No : "-"}</td>
                      <td>{el.Po_No ? el.Po_No : "-"}</td>
                      <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>
                        {el.Service_Component ? el.Service_Component : "-"}
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Supervisor_Names.split(",").length +
                          el.Technician_Names.split(",").length}
                      </td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                      <td>
                        {el.Estimated_Project_Completion_Month
                          ? el.Estimated_Project_Completion_Month
                          : "-"}
                      </td>
                      <td>
                        {check3.Issue39
                          ? check3.Issue39
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </form>
        )}
        
        {val === "Open API Docs" && (
          <form id="Open API Docs">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>PO.No</th>
                  <th>So no</th>
                  <th> Rig Name </th>
                  <th>PO days</th>
                  <th>Estimated Date of Commencement</th>
                </tr>
              </thead>
              <tbody>
                {ongoingdetails6a.map((el, index) => {
                  return (
                    <tr key={el.ID_No}>
                      <td>{index + 1}</td>
                      <td>{el.Po_No ? el.Po_No : "-"}</td>
                      <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
                {upcomingdetails6a.map((el, index) => {
                  return (
                    <tr key={el.ID_No}>
                      <td>{ongoingdetails6.length + index + 1}</td>
                      <td>{el.Po_No ? el.Po_No : "-"}</td>
                      <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                      <td>
                        {" "}
                        <NavLink to={"/project_checklist/" + el.ID_No}>
                          {el.Rig_Name ? el.Rig_Name : "-"}
                        </NavLink>
                      </td>
                      <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                      <td>
                        {el.Estimated_Date_Of_Commencement
                          ? el.Estimated_Date_Of_Commencement
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </form>
        )}
      </div>
      </div>
      </div>
    </>
  );
}

export default Documents;
