import React, { useState, useEffect, useRef } from "react";
import styles from "../Components/css/PurchaseUpdate.module.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import axios from "axios";
import Select from "react-select";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function PurchaseUpdate() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { Purchase_id } = useParams();
  const [activeTab, setActiveTab] = useState("Purchase Details");
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
    // Add logic to handle edit click
  };

  const handleCancelClick = () => {
    window.location.reload();
  };

  const handleDivClick = (tab, route) => {
    setActiveTab(tab);
    navigate(`${route}${Purchase_id}`);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formdata, [name]: value });
  };

  // date changes
  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("YYYY-MM-DD");
      setFormData({ ...formdata, [name]: selectedDate });
    } else {
      console.log("Invalid Date");
    }
  };

  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#b2b3b5",
    },
    "& .MuiOutlinedInput-root": {
      width: "350px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #b2b3b5",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #b2b3b5",
          borderRadius: "6px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #b2b3b5",
          borderRadius: "6px",
        },
      },
    },
  }));

  useEffect(() => {
    const fetchSalesOrderNos = async () => {
      {
        /* To fetch all the Sales_Order_No for Dropdown*/
      }
      try {
        // Fetch data from the first API
        const res1 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details_Sales_Order_No"
        );
        const salesOrderNosArray1 = res1.data.data.map((row) => ({
          label: row.Sales_Order_No,
          value: row.Sales_Order_No,
        }));

        // Fetch data from the second API
        const res2 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_trading_Sales_Order_No"
        );
        const salesOrderNosArray2 = res2.data.data.map((row) => ({
          label: row.Sales_Order_No,
          value: row.Sales_Order_No,
        }));

        // Combine both arrays
        const combinedSalesOrderNos = [...salesOrderNosArray1, ...salesOrderNosArray2];
        console.log(combinedSalesOrderNos)
        setSalesOrderNos(combinedSalesOrderNos);

        const res3 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details_Inquiry_No"
        );
        const inquirynos = res3.data.data.map((row) => ({
          label: row.Inquiry_ID,
          value: row.Inquiry_ID,
        }));

        console.log("inquiry nos", inquirynos)
        setInquiryNos(inquirynos);

        const res4 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details_Supplier_Names"
        );
        const suppliernames = res4.data.data.map((row) => ({
          label: row.Supplier_Name,
          value: row.Supplier_Name,
        }));

        console.log("supplier names", suppliernames);
        setSupplierNames(suppliernames);
      } catch (error) {
        console.error("Error fetching Sales Order Numbers:", error);
      }
    };

    fetchSalesOrderNos();
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [salesOrderNos, setSalesOrderNos] = useState([]);
  const [supplierNames, setSupplierNames] = useState([]);
  const [inquiryNos, setInquiryNos] = useState([]);
  const [inputName, setInputName] = useState([]);
  const [inputInquiry, setInputInquiry] = useState([]);
  const [inputSupplier, setInputSupplier] = useState([]);
  const names = ["Ajhar", "Haneef", "Basil", "Irfan Sheikh", "Sandhya", "Ramadas", "Syed", "Nishad", "Arun"];
  const namesOptions = names.map((name) => ({ label: name, value: name }));
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredSalesOrderNos, setFilteredSalesOrderNos] = useState([]);
  const [featurePurchasesdetails,setfeaturePurchasesdetails] = useState([]);
  const [featurePurchasesdetailsedit,setfeaturePurchasesdetailsedit] = useState([]);
  const [formdata, setFormData] = useState({
    PONumber: "",
    PODate: "",
    QuoteNo: "",
    QuoteDate: "",
    SupplierName: "",
    LinkedInq: "",
    EarliestArrivalDate: "",
    ExpectedArrivalDate: "",
    LinkedProj: "",
    PurchaseRequisitionID: "",
    ReasonForPurchase: "",
    Raised: "",
  });
  const [visibleOptions, setVisibleOptions] = useState([]);
  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseupdate_get"
      );
      const filter = res.data.data.filter(
        (el) => el.Purchase_ID == Purchase_id
      );

      setFormData({
        ...formdata,
        PONumber: filter[0]?.PO_Number?.toString(),
        PODate: filter[0]?.PO_Date?.toString(),
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        SupplierName: filter[0]?.Supplier_Name,
        LinkedInq: filter[0]?.Linked_Inquiry,
        EarliestArrivalDate: filter[0]?.Earliest_Arrival_Date,
        ExpectedArrivalDate: filter[0]?.Expected_Arrival_Date,
        LinkedProj: filter[0]?.Linked_Project,
        PurchaseRequisitionID: filter[0]?.Purchase_Requisition_ID,
        ReasonForPurchase: filter[0]?.Reason_For_Purchase,
        Raised: filter[0]?.Request_Raised_By,
      });

      // Splitting the LinkedProj string and creating an array of objects
      const linkedProjects = filter[0]?.Linked_Project?.split(", ")
        .filter((project) => project.trim() !== "") // Filter out empty or whitespace-only values
        .map((project) => ({
          value: project,
          label: project,
        }));
      const names = filter[0]?.Request_Raised_By;
      const nameObjects = names ? [{ value: names, label: names }] : [];

      setInputName(nameObjects);

      const inquiry = filter[0]?.Linked_Inquiry;
      const inquiryObjects = inquiry ? [{value: inquiry, label: inquiry}] : [];

      setInputInquiry(inquiryObjects);

      const supplier = filter[0]?.Supplier_Name;
      const supplierObjects = supplier ? [{value: supplier, label: supplier}] : [];

      setInputSupplier(supplierObjects);

      setFilteredSalesOrderNos(linkedProjects);
      setVisibleOptions(linkedProjects);
    };

    fetchProject();
  }, [Purchase_id]);

  const handleSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      .map((option) => option.label)
      .join(", ");
    setInputValue(selectedValues);

    setFilteredSalesOrderNos(selectedOptions);
    console.log("selectedValues", selectedValues);

  };

  const handleSelectChange2 = (selectedOption) => {
    setInputName(selectedOption);
  };

  const handleSelectChange3 = (selectedOption) => {
    setInputInquiry(selectedOption);
  };

  const handleSelectChange4 = (selectedOption) => {
    setInputSupplier(selectedOption);
  };
  const handleSave = async (e) => {
    const {
      PONumber,
      PODate,
      QuoteNo,
      QuoteDate,
      SupplierName,
      EarliestArrivalDate,
      ExpectedArrivalDate,
      PurchaseRequisitionID,
      ReasonForPurchase
    } = formdata;

    if (!formdata.PONumber) {
      alert("PO Number is required.");
      return;
    }

    // Extracting selected SO numbers and joining them with commas
    const LinkedProj = filteredSalesOrderNos
      .map((option) => option.value)
      .join(", ");

    const payload = {
      Purchase_ID: Purchase_id,
      PO_Number: PONumber,
      PO_Date: PODate,
      Quote_No: QuoteNo,
      Quote_Date: QuoteDate,
      Supplier_Name: inputSupplier.value,
      Linked_Inquiry: inputInquiry.value,
      Earliest_Arrival_Date: EarliestArrivalDate,
      Expected_Arrival_Date: ExpectedArrivalDate,
      Linked_Project: LinkedProj,
      Purchase_Requisition_ID: PurchaseRequisitionID,
      Reason_For_Purchase: ReasonForPurchase,
      Request_Raised_By: inputName.value,
      // Add selected SO numbers to payload
    };

    const payload1 = {
      Dispatching_Party: SupplierName,
    };

    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_put`,
        payload
      );

      const res1 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_details_incoming_deliveries_put?Purchase_ID=${Purchase_id}`,
        payload1
      );

      if (res.status === 200 && res1.status === 200) {
        alert("Purchase Updated successfully");
        window.location.reload();

      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );
  
        // Combine Group and Role Name filters
        const group = localStorage.getItem("Group");
        const roles = JSON.parse(localStorage.getItem("Roles")) || []; // Retrieve roles from localStorage
  
        const workshoppurchasesdetails = response.data.groupFeatures.filter((el) => {
          return (
            (el.Group_Name === group ) &&
            el.Header_Name === "Workshop Projects" &&
            el.Feature_Name === "Workshop Purchases Details" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        const workshoppurchasesdetails2 = response.data.roleFeatures.filter((el) => {
          return (
            (roles.includes(el.Role_Name) ) &&
            el.Header === "Workshop Projects" &&
            el.Feature === "Workshop Purchases Details" &&
            (el.Access === "Can View" )
          );
        });

        const workshoppurchaseDetails = [...workshoppurchasesdetails, ...workshoppurchasesdetails2]
  
        const workshoppurchasesdetailsedit = response.data.groupFeatures.filter((el) => {
          return (
            (el.Group_Name === group) && 
            el.Header_Name === "Workshop Projects" &&
            el.Feature_Name === "Workshop Purchases Details Edit" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        const workshoppurchasesdetailsedit2 = response.data.roleFeatures.filter((el) => {
          return (
            (roles.includes(el.Role_Name) ) &&
            el.Header === "Workshop Projects" &&
            el.Feature === "Workshop Purchases Details" &&
            (el.Access === "Can View" )
          );
        });

        const workshoppurchaseDetailsEdit = [...workshoppurchasesdetailsedit, ...workshoppurchasesdetailsedit2]
  
        // Ensure "Can View" overrides "Can Edit"
        const prioritizeAccess = (features) => {
          const featureMap = {};
          console.log("feature",featureMap)
          features.forEach((feature) => {
            const key = `${feature.Header_Name}-${feature.Feature_Name}`;
            if (!featureMap[key] || feature.Access === "Can View") {
              featureMap[key] = feature;
            }
          });
          console.log("feature",featureMap)
          return Object.values(featureMap);
        };
  
        setfeaturePurchasesdetails(prioritizeAccess(workshoppurchaseDetails));
        setfeaturePurchasesdetailsedit(prioritizeAccess(workshoppurchaseDetailsEdit));
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);
  
  return (
    // {isEditing?():()}

    <div className={styles.topdiv}>
      <div className={styles.sections1}>
        {" "}
        {/*Top_Bar_Div*/}
        <div
          className={
            activeTab === "Purchase Details" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick("Purchase Details", "/purchase_details/")
          }
        >
          Purchase Details
        </div>
        <div
          className={
            activeTab === "Item Details" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() => handleDivClick("Item Details", "/item_details/")}
        >
          Item Details
        </div>
        <div
          className={
            activeTab === "Incoming Deliveries"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Incoming Deliveries",
              "/purchase_incoming_deliveries/"
            )
          }
        >
          Incoming Deliveries
        </div>
        <div
          className={
            activeTab === "Incoming Inspections"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Incoming Inspections",
              "/purchase_incoming_inspections/"
            )
          }
        >
          Incoming Inspections
        </div>
        <div
          className={
            activeTab === "Items Received"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Items Received",
              "/purchase_items_received/"
            )
          }
        >
          Items Received
        </div>
      </div>
      {/* ------------------------------------------------------------------- */}
      {featurePurchasesdetails.length >0 ?(

      !isEditing ? (
        <div className={styles.sections2}>
          {" "}
          {/*Second__Div*/}
          <div style={{ marginLeft: "35px", marginTop: "50px" }}>
            <div className={styles.formElement}>
              <div style={{ width: "131.91px" }}>

                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  PO Number*:
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="text"
                  value={formdata.PONumber}
                  name="PONumber"
                  //   onChange={handleOnChange}

                  readOnly
                  className={styles.textbox2}
                />
              </div>
            </div>
            {/* PO Date */}
            <div className={`${styles.formElement} ${styles.datepickerInput}`}>
              <div style={{ width: "131.91px" }}>

                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  PO Date:
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="PODate"
                    value={
                      formdata.PODate
                        ? dayjs(formdata.PODate, "YYYY-MM-DD")
                        : null
                    }
                    // onChange={(value) => handleDates("PODate", value)}

                    readOnly
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "#E4E4E4",
                      borderRadius: "6px",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "30px",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {/* PO Date Ends */}

            <div className={styles.formElement}>
              <div style={{ width: "131.91px" }}>

                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  Quote No:
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  value={formdata.QuoteNo}
                  name="QuoteNo"
                  //   onChange={handleOnChange}

                  readOnly
                  className={styles.textbox2}
                />
              </div>
            </div>
            {/* Quote Date */}
            <div className={`${styles.formElement} ${styles.datepickerInput}`}>
              <div style={{ width: "131.91px" }}>

                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  Quote Date:
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="QuoteDate"
                    value={
                      formdata.QuoteDate
                        ? dayjs(formdata.QuoteDate, "YYYY-MM-DD")
                        : null
                    }
                    // onChange={(value) => handleDates("QuoteDate", value)}

                    readOnly
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "#E4E4E4",
                      borderRadius: "6px",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "30px",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>

            {/* <div className={styles.formElement}>
              <label
                style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
              >
                Purchase
                <div>
                  Requisition ID:
                </div>
              </label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                }}
              >
                <input
                  type="text"
                  value={formdata.PurchaseRequisitionID}
                  name="PurchaseRequisitionID"
                  // onChange={handleOnChange}
                  disabled
                  className={styles.textbox2}
                />
              </div>
            </div> */}

            <div className={styles.formElement}>
              <div style={{ width: "131.91px" }}>


                <label
                  style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
                >
                  Reason for
                  <div>
                    Purchase:
                  </div>
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                }}
              >
                <textarea
                  value={formdata.ReasonForPurchase}
                  name="ReasonForPurchase"
                  // onChange={handleOnChange}
                  className={styles.textbox2}
                  disabled
                  style={{
                    minHeight: "70px",
                    // Ensure it takes full width available
                    resize: "none", // Optional: prevent resizing
                    padding: "5px", // Adjust padding as needed
                  }}
                />
              </div>
            </div>

            {/* Quote Date Ends */}
          </div>
          <div style={{ marginLeft: "7vw", marginTop: "50px" }}>
          <div className={styles.formElement}>
            <div style={{ width: "205px" }}>
                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Supplier Name:
                </label>
              </div>

              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  isMulti
                  value={formdata.SupplierName}
                  options={supplierNames}
                  // onChange={handleSelectChange2}
                  placeholder={formdata.SupplierName}
                  className={styles.textbox2z}
                  isDisabled={true}
                  isReadOnly={true}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "not-allowed",
                      overflowY: "hidden",
                      backgroundColor: "#E4E4E4",
                      width: "335px",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Set display to "none" to hide the clear indicator
                    }),

                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll",
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "black", // Set placeholder color to black
                    }),
                  }}
                />
              </div>
            </div>

            <div className={styles.formElement}>
            <div style={{ width: "205px" }}>
                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Linked to Inquiry:
                </label>
              </div>

              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  isMulti
                  value={formdata.LinkedInq}
                  options={inquiryNos}
                  // onChange={handleSelectChange2}
                  placeholder={formdata.LinkedInq}
                  className={styles.textbox2z}
                  isDisabled={true}
                  isReadOnly={true}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "not-allowed",
                      overflowY: "hidden",
                      backgroundColor: "#E4E4E4",
                      width: "335px",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Set display to "none" to hide the clear indicator
                    }),

                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll",
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "black", // Set placeholder color to black
                    }),
                  }}
                />
              </div>
            </div>

            <div className={`${styles.formElement} ${styles.datepickerInput}`}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "10px"
                }}
              >
                Earliest Arrival Date:
              </label>

              <div style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="EarliestArrivalDate"
                    value={
                      formdata.EarliestArrivalDate
                        ? dayjs(formdata.EarliestArrivalDate, "YYYY-MM-DD")
                        : null
                    }
                    // onChange={(value) =>
                    //   handleDates("EarliestArrivalDate", value)
                    // }

                    readOnly
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "#E4E4E4",
                      borderRadius: "6px",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "3vw",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className={`${styles.formElement} ${styles.datepickerInput}`}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "38px"
                }}
              >
                Last Arrival Date:
              </label>

              <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="ExpectedArrivalDate"
                    value={
                      formdata.ExpectedArrivalDate
                        ? dayjs(formdata.ExpectedArrivalDate, "YYYY-MM-DD")
                        : null
                    }
                    // onChange={(value) =>
                    //   handleDates("ExpectedArrivalDate", value)
                    // }

                    readOnly
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "#E4E4E4",
                      borderRadius: "6px",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "3vw",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <div className={styles.formElement}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "15px",
                }}
              >
                Linked to Project SO:
                <div
                  style={{
                    fontSize: "17px",
                    marginLeft: "12px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  (Can select multiple)
                </div>
              </label>

              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  isMulti
                  value={formdata.LinkedProj}
                  options={salesOrderNos}
                  // onChange={handleSelectChange}
                  placeholder={formdata.LinkedProj}
                  className={styles.textbox2z}
                  isDisabled={true}
                  isReadOnly={true}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "not-allowed",
                      overflowY: "hidden",
                      backgroundColor: "#E4E4E4",
                      width: "335px",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Set display to "none" to hide the clear indicator
                    }),

                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll",
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "black", // Set placeholder color to black
                    }),
                  }}
                />
              </div>
            </div>

            <div className={styles.formElement}>
              <div style={{ width: "205px" }}>
                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Request raised by*:
                </label>
              </div>
              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  isMulti
                  value={formdata.Raised}
                  options={namesOptions}
                  // onChange={handleSelectChange2}
                  placeholder={formdata.Raised}
                  className={styles.textbox2z}
                  isDisabled={true}
                  isReadOnly={true}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "not-allowed",
                      overflowY: "hidden",
                      backgroundColor: "#E4E4E4",
                      width: "335px",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the clear indicator
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "black", // Set placeholder color to black
                    }),
                  }}
                />
              </div>
            </div>

          </div>
          <div className={styles.buttonsdiv}>
            {featurePurchasesdetailsedit.length !==0 && (
            <button onClick={handleEditClick}>
              Edit{" "}
              <svg
                width="12"
                height="12"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-5px", marginLeft: "5px" }}
              >
                <path
                  d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                  fill="white"
                />
              </svg>
            </button>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.sections2}>
          {" "}
          {/*Second__Div*/}
          <div style={{ marginLeft: "35px", marginTop: "50px" }}>
            <div className={styles.formElement}>
              <div style={{ width: "131.91px" }}>

                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  PO Number*:
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="text"
                  value={formdata.PONumber}
                  name="PONumber"
                  onChange={handleOnChange}
                  className={styles.textbox1}
                />
              </div>
            </div>
            {/* PO Date */}
            <div className={`${styles.formElement} ${styles.datepickerInput}`}>
              <div style={{ width: "131.91px" }}>

                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  PO Date:
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="PODate"
                    value={
                      formdata.PODate
                        ? dayjs(formdata.PODate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("PODate", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "6px",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "30px",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {/* PO Date Ends */}

            <div className={styles.formElement}>
              <div style={{ width: "131.91px" }}>

                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  Quote No:
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  value={formdata.QuoteNo}
                  name="QuoteNo"
                  onChange={handleOnChange}
                  className={styles.textbox1}
                />
              </div>
            </div>
            {/* Quote Date */}
            <div className={`${styles.formElement} ${styles.datepickerInput}`}>
              <div style={{ width: "131.91px" }}>

                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  Quote Date:
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="QuoteDate"
                    value={
                      formdata.QuoteDate
                        ? dayjs(formdata.QuoteDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("QuoteDate", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "6px",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "30px",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>

            {/* <div className={styles.formElement}>
              <label
                style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
              >
                Purchase
                <div>
                  Requisition ID:
                </div>
              </label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                }}
              >
                <input
                  type="text"
                  value={formdata.PurchaseRequisitionID}
                  name="PurchaseRequisitionID"
                  onChange={handleOnChange}
                  className={styles.textbox1}
                />
              </div>
            </div> */}

            <div className={styles.formElement}>
              <div style={{ width: "131.91px" }}>


                <label
                  style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
                >
                  Reason for
                  <div>
                    Purchase:
                  </div>
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start", // Changed from 'center' to 'flex-start'
                }}
              >
                <textarea
                  value={formdata.ReasonForPurchase}
                  name="ReasonForPurchase"
                  onChange={handleOnChange}
                  className={styles.textbox1}
                  style={{
                    minHeight: "70px",
                    // Ensure it takes full width available
                    resize: "none", // Optional: prevent resizing
                    padding: "5px", // Adjust padding as needed
                  }}
                />
              </div>
            </div>
            {/* Quote Date Ends */}
          </div>
          <div style={{ marginLeft: "7vw", marginTop: "50px" }}>
            <div className={styles.formElement}>
              <div style={{ width: "205px" }}>
                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Supplier Name:
                </label>
              </div>
              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  // isMulti
                  value={inputSupplier}
                  options={supplierNames}
                  onChange={handleSelectChange4}
                  placeholder="Type to search..."
                  className={styles.textbox1z}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      overflowY: "hidden",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the clear indicator
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                  }}
                />
              </div>
            </div>

            <div className={styles.formElement}>
              <div style={{ width: "205px" }}>
                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Linked to Inquiry:
                </label>
              </div>
              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  // isMulti
                  value={inputInquiry}
                  options={inquiryNos}
                  onChange={handleSelectChange3}
                  placeholder="Type to search..."
                  className={styles.textbox1z}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      overflowY: "hidden",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the clear indicator
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                  }}
                />
              </div>
            </div>

            <div className={`${styles.formElement} ${styles.datepickerInput}`}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "10px"
                }}
              >
                Earliest Arrival Date:
              </label>

              <div style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="EarliestArrivalDate"
                    value={
                      formdata.EarliestArrivalDate
                        ? dayjs(formdata.EarliestArrivalDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) =>
                      handleDates("EarliestArrivalDate", value)
                    }
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "6px",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "3vw",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className={`${styles.formElement} ${styles.datepickerInput}`}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "38px",
                }}
              >
                Last Arrival Date:
              </label>

              <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="ExpectedArrivalDate"
                    value={
                      formdata.ExpectedArrivalDate
                        ? dayjs(formdata.ExpectedArrivalDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) =>
                      handleDates("ExpectedArrivalDate", value)
                    }
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "6px",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "3vw",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <div className={styles.formElement}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "15px",
                }}
              >
                Linked to Project SO:
                <div
                  style={{
                    fontSize: "17px",
                    marginLeft: "12px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  (Can select multiple)
                </div>
              </label>

              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  isMulti
                  value={filteredSalesOrderNos}
                  options={salesOrderNos}
                  onChange={handleSelectChange}
                  placeholder="Type to search..."
                  className={styles.textbox1z}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      overflowY: "hidden",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the clear indicator
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                  }}
                />
              </div>
            </div>
            <div className={styles.formElement}>
              <div style={{ width: "205px" }}>
                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Request raised by*:
                </label>
              </div>
              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  // isMulti
                  value={inputName}
                  options={namesOptions}
                  onChange={handleSelectChange2}
                  placeholder="Type to search..."
                  className={styles.textbox1z}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      overflowY: "hidden",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the clear indicator
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonsdiv}>
            <button onClick={handleSave} style={{ marginRight: "10px" }}>
              Save{" "}
            </button>

            <button
              onClick={handleCancelClick}
              style={{
                background: "white",
                ":hover": { background: "#F5F5F5" },
                border: "1px solid #5B6BE1",
                color: "#5B6BE1",
                justifyContent: "center",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              Cancel{" "}
            </button>
          </div>
        </div>
      )
  
    ):(
      <div style={{ marginLeft: "30%", marginTop: "15%" }}>
      <h3>
        You do not have permissions to view this page.
      </h3>
    </div>
    )}
    </div>
  );
}

export default PurchaseUpdate;
