import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import styles from "../Components/css/notificationbell.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { MyContext } from "../services/provider";
import WebSocketService from "../services/webSocketServices";

const NotificationBell = () => {
  const token = localStorage.getItem("token");
  const useremail = localStorage.getItem('useremail');
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const wsService = new WebSocketService("ws://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002");
  const [notifications, setNotifications] = useState([]);
  const [hasUnread, setHasUnread] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const panelRef = useRef(null);
  const bellRef = useRef(null);
  const [pdata, setPdata] = useState([]);
  const { notificationData,sendNotificationToAll } = useContext(MyContext);
  const fetchProjectDetails = async () => {
    try {
      const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details");
      setPdata(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details_notification");
      const data = response.data.data;
      data.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
      const filteredNotifications = data.filter(notif => 
        notif.User_Email === useremail && 
        new Date() - new Date(notif.Timestamp) <= 8 * 24 * 60 * 60 * 1000
      );
      setNotifications(filteredNotifications);
      setHasUnread(filteredNotifications.some(notif => Number(notif.Read) === 0));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {


    fetchProjectDetails();
    fetchNotifications();

    wsService.connect();
    wsService.on("notificationfrombackend",  (data) => {
      console.log("data from backend", data);

    //   setNotifications(prev => [data, ...prev]);
      fetchProjectDetails();
      fetchNotifications();

    });

    return () => {
      wsService.ws.close();
    };
  }, [useremail, token]);

  useEffect(() => {
    if (notificationData) {

    //   setNotifications(prev => [notificationData, ...prev]);
      fetchProjectDetails();
      fetchNotifications();
      //setHasUnread(true);
    }
  }, [notificationData]);

  const handleBellClick = async (e) => {
    setIsVisible(!isVisible);

};
const handleCloseClick = async (e) => {
   

    await markNotificationsAsRead();

    setIsVisible(!isVisible);
};

  const handleClickOutside = (event) => {
    if (
      panelRef.current && !panelRef.current.contains(event.target) &&
      bellRef.current && !bellRef.current.contains(event.target)
    ) {
      setIsVisible(false);
    }
  };

  const markNotificationsAsRead = async () => {
    try {
        await axios.put('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details_notification', {
            email: useremail
        });
        // Update local state to mark all notifications as read
        setNotifications(prevNotifications =>
            prevNotifications.map(notif => ({ ...notif, Read: 1 }))
        );
        setHasUnread(false);
    } catch (error) {
        console.error("Error updating data:", error);
    }
};

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getRigNameById = (rigs, id) => rigs.find(rig => rig.ID_No === id)?.Rig_Name || null;
  const getRigSONOById = (rigs, id) => rigs.find(rig => rig.ID_No === id)?.Sales_Order_No || null;

  return (
    <>
      <div onClick={handleBellClick} ref={bellRef} style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={faBell} size="2x" />
        {hasUnread && <span className={styles.unreadIndicator}></span>}
      </div>
      {isVisible && (
        <div className={styles.notificationPanel} ref={panelRef}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" , borderBottom:"1px solid #5B6BE1"}}>
            <div style={{ marginTop: "18px", fontWeight: "600", fontSize: "16px", color: "#5B6BE1", marginLeft: "10px" }}>
              Notifications
            </div>
            <button className={styles.closeButton} onClick={handleCloseClick}>Close</button>
          </div>
          {notifications.map((notif, index) => (
            <div key={index} className={styles.notificationItem}>
              <div className={styles.content}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginTop: "3px", marginRight: "4px", fontSize: "30px" }}>
                    ⚠️
                  </div>
                  <div style={{ fontSize: "13px" }}>
                    Commencement date for rig <b>{getRigNameById(pdata, notif.Project_ID)}</b>, SO <b>{getRigSONOById(pdata, notif.Project_ID)}</b> changed from <b>{notif.Previous_Commencement_Date}</b> to <b>{notif.New_Commencement_Date}</b> due to <b>{notif.Reason_For_Change}</b>
                  </div>
                </div>
                <div className={styles.projectDetails}>
                  <div className={styles.timestamp}>
                    <span>{new Date(notif.Timestamp).toLocaleString('en-US', { month: 'long', day: 'numeric' })}</span>
                    <span>{new Date(notif.Timestamp).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</span>
                    <span>{notif.Done_By}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default NotificationBell;
