import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = () => {
    const location = useLocation();
    const token = localStorage.getItem('token');

    const checkAuth = () => {
        if (!token) {
            return false;
        }
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
                localStorage.removeItem('token');
                return false;
            }
            return true;
        } catch (error) {
            localStorage.removeItem('token');
            return false;
        }
    };

    const isAuthenticated = checkAuth();

    if (!isAuthenticated) {
        // Ensure the application state is cleared or reset
        // Possibly dispatch a logout action if using Redux or Context API
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
