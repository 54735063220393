import { useState, useRef } from "react";
import axios from "axios";
import styles from "../Components/css/uploadDocument.module.css";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function UploadDocument(props) {
  const fileInputRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState(false);
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const fileType = file.type;
      if((fileType !== 'image/png' || fileType !== 'image/jpeg') && props.fieldName === "photoUpload" ) {
        setErrorMessage(true);
          fileInputRef.current.value = null;
    
      }else{
        props.setSelectedFile((prev) => ({
          ...prev,
          [props.fieldName]: file,
        }));
        props.uploadFile(file, props.fieldName);

      }

  
  };

  const deleteUploadedFile = async () => {
    const fileApiName = props.selectedFileName;
    console.log(fileApiName);
    try {
      const res = await axios.delete(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/deletefile/${fileApiName}`
      );

      console.log("deleted File    ", res);
    } catch (err) {
      console.log(err);
    }
  };

  const removeFile = (e) => {
    e.preventDefault();
    props.setSelectedFile((prev) => ({
      ...prev,
      [props.fieldName]: null,
    }));

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    deleteUploadedFile();
  };

  return (
    <div style={{ position: "relative" }}>
      {!props?.selectedFile ? (
        <input
          id="fileInput"
          ref={fileInputRef}
          className={styles.fileInputField}
          name={props.fieldName}
          type="file"
          accept={props.acceptType === true && "image/png,image/jpeg"}
          onChange={handleFileInputChange}
        />
      ) : (
        <input
          id="fileInput"
          ref={fileInputRef}
          className={styles.fileInputFieldSelected}
          name={props.fieldName}
          type="file"
          accept={props.acceptType === true && "image/png,image/jpeg"}
          onChange={handleFileInputChange}
          style={{ display:"none", width: 0, opacity: 0, zIndex: -1 }}
        />
      )}

      {errorMessage=== true && <div>only jpeg and png file formats are accepted</div>}
      {!props?.selectedFile ? (
        <div className={styles.textAndIcon}>
          <span onClick={() => fileInputRef.current.click()}>Upload</span>
          <svg
            onClick={() => fileInputRef.current.click()}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
              fill="black"
            />
            <path
              d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
              fill="black"
            />
          </svg>
        </div>
      ) : (
        <div className={styles.textAndIconNew}>
            <div className={styles.fileInputNameDisplay}>
              <div className={styles.fileNameWrapper} onClick={() => fileInputRef.current.click()}>
                <span className={styles.fileName} title={props.selectedFileName}>
                  {props.selectedFileName.toString().split("/").pop().split("-").slice(1).join("-")}
                </span>
              </div>
            </div>
        </div>
        )}
    </div>
  );
}

export default UploadDocument;