import React from "react";
import styles from "./css/projectchecklistworkshop.module.css";
import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import UploadDocument from "./Uploaddocument";
import axios from "axios";
import { Switch } from "@mui/material";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import BasicModal from "./Modal";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ProjectDetailsWorkshop from "./ProjectDetailsWorkshop";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function ProjectChecklistWorkshop() {
  const [projects, setProjects] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(true);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  const [isExpanded6, setIsExpanded6] = useState(false);
  const [isExpanded7, setIsExpanded7] = useState(false);
  const [isExpanded8, setIsExpanded8] = useState(false);
  const [projectList, setProjectList] = useState({});
  const [featureprojectchecklist, setfeatureprojectchecklist] = useState([]);
  const [featureprojectchecklistedit, setfeatureprojectchecklistedit] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [switchValue, setswitchValue] = useState(false);
  const [workshop, setWorkshop] = useState(1);
  const [checkListData, setCheckListData] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
  });

  const handleDivClick = () => {
    navigate(`/project-checklist-workshop/${id}`);
  };

  const handleDivClick1 = () => {
    navigate(`/project-details-workshop/${id}`);
  };



  const handleDivClick2 = () => {
    navigate(`/timesheet-workshop/${id}`);
  };

  const handleDivClick3 = () => {
    navigate(`/incoming-deliveries-workshop/${id}`);
  };

  const handleDivClick4 = () => {
    navigate(`/outgoing-deliveries-workshop/${id}`);
  };

  const handleDivClick5 = () => {
    navigate(`/outsourcing-workshop/${id}`);
  };

  const handleDivClick6 = () => {
    navigate(`/reports-workshop/${id}`);
  };

  const handleDivClick7 = () => {
    navigate(`/inspections-workshop/${id}`);
  };
  const handleDivClick8 = () => {
    navigate(`/items-received-workshop/${id}`);
  };
  const handleDivClick9 = () => {
    navigate(`/finished-goods-workshop/${id}`)
  };

  const handleDivClick10 = () => {
    navigate(`/team-details-workshop/${id}`);
  };

  const handleDivClick12 = () => {
    navigate(`/inventory-used-workshop/${id}`);
  }

  const handleDivClick13 = () => {
    navigate(`/purchases-workshop/${id}`);
  }

  const handleDivClick14 = () => {
    navigate(`/project-timeline-workshop/${id}`);
  }
  const handleDivClick15 = () => {
    navigate(`/production-workshop/${id}`)
  }

  const handleDivClick16 = () => {
    navigate(`/Wip-workshop/${id}`);
  };
  const handleDivClick17 = () => {
    navigate(`/dispatch/${id}`);
  };

  const handleDivClick23 = () => {
    navigate(`/item-details-workshop/${id}`);
  };
  const toggleDropdown1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const toggleDropdown2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const toggleDropdown3 = () => {
    setIsExpanded3(!isExpanded3);
  };

  const toggleDropdown4 = () => {
    setIsExpanded4(!isExpanded4);
  };

  const toggleDropdown5 = () => {
    setIsExpanded5(!isExpanded5);
  };

  const toggleDropdown6 = () => {
    setIsExpanded6(!isExpanded6);
  };

  const toggleDropdown7 = () => {
    setIsExpanded7(!isExpanded7);
  };
  const toggleDropdown8 = () => {
    setIsExpanded8(!isExpanded8);
  };
  const saveForm = async () => {
    // if (activeStep === 3) {
    //   const values = Object.values(selectValues4);
    //   const lengthOfStep4 = Object.keys(selectValues4).length;

    //   const everyValueSelected = values.every(
    //     (value) => value !== "" && value !== "Select" && value === "Completed"
    //   );
    //   if (lengthOfStep4 > 8 && everyValueSelected) {
    //     handleNextValueOfStepper();
    //   }
    // }

    const { item1, item2, item3, item4, item5, item6 } = selectValues1;

    const {
      item7,
      item8,
      item9,
      item10,
      item11,
      item12,
      item13,
      item14,
      item15,
      item16,
    } = selectValues2;

    const { item17, item17A, item18, item19, item20, item21, item22, item23, item24 } =
      selectValues3;

    const { item25, item26, item27, item28, item29, item29A, item30, item30A } = selectValues4;

    const { item31, item32, item33, item34, item35, item36 } = selectValues5;

    const { item37, item38, item39 } = selectValues6;

    const { item40, item41, item42, item42A } = selectValues7;

    const { item43, item44 } = selectValues8;

    const { issue1, issue2, issue3, issue4, issue5, issue6 } = inputValues1;
    const {
      issue7,
      issue8,
      issue9,
      issue10,
      issue11,
      issue12,
      issue13,
      issue14,
      issue15,
      issue16,
    } = inputValues2;
    const {
      issue17,
      issue17A,
      issue18,
      issue19,
      issue20,
      issue21,
      issue22,
      issue23,
      issue24,
    } = inputValues3;
    const { issue25, issue26, issue27, issue28, issue29, issue29A, issue30, issue30A } =
      inputValues4;

    const { issue31, issue32, issue33, issue34, issue35, issue36 } =
      inputValues5;

    const { issue37, issue38, issue39 } = inputValues6;

    const { issue40, issue41, issue42, issue42A } = inputValues7;

    const { issue43, issue44 } = inputValues8;

    const payload = {
      Sales_Order_No: projectList.Sales_Order_No,
      Item1: item1,
      Item2: item2,
      Item3: item3,
      Item4: item4,
      Item5: item5,
      Item6: item6,
      Item7: item7,
      Item8: item8,
      Item9: item9,
      Item10: item10,
      Item11: item11,
      Item12: item12,
      Item13: item13,
      Item14: item14,
      Item15: item15,
      Item16: item16,
      Item17: item17,
      Item17A: item17A,
      Item18: item18,
      Item19: item19,
      Item20: item20,
      Item21: item21,
      Item22: item22,
      Item23: item23,
      Item24: item24,
      Item25: item25,
      Item26: item26,
      Item27: item27,
      Item28: item28,
      Item29: item29,
      Item29: item29,
      Item29A: item29A,
      Item30: item30,
      Item30A: item30A,
      Item31: item31,
      Item32: item32,
      Item33: item33,
      Item34: item34,
      Item35: item35,
      Item36: item36,
      Item37: item37,
      Item38: item38,
      Item39: item39,
      Item40: item40,
      Item41: item41,
      Item42: item42,
      Item42: item42,
      Item42A: item42A,
      Item43: item43,
      Item44: item44,

      Issue1: issue1,
      Issue2: issue2,
      Issue3: issue3,
      Issue4: issue4,
      Issue5: issue5,
      Issue6: issue6,
      Issue7: issue7,
      Issue8: issue8,
      Issue9: issue9,
      Issue10: issue10,
      Issue11: issue11,
      Issue12: issue12,
      Issue13: issue13,
      Issue14: issue14,
      Issue15: issue15,
      Issue16: issue16,
      Issue17: issue17,
      Issue17A: issue17A,
      Issue18: issue18,
      Issue19: issue19,
      Issue20: issue20,
      Issue21: issue21,
      Issue22: issue22,
      Issue23: issue23,
      Issue24: issue24,
      Issue25: issue25,
      Issue26: issue26,
      Issue27: issue27,
      Issue28: issue28,
      Issue29: issue29,
      Issue29: issue29,
      Issue29A: issue29A,
      Issue30: issue30,
      Issue30A: issue30A,
      Issue31: issue31,
      Issue32: issue32,
      Issue33: issue33,
      Issue34: issue34,
      Issue35: issue35,
      Issue36: issue36,
      Issue37: issue37,
      Issue38: issue38,
      Issue39: issue39,
      Issue40: issue40,
      Issue41: issue41,
      Issue42: issue42,
      Issue42: issue42,
      Issue42A: issue42A,
      Issue43: issue43,
      Issue44: issue44,
      Paused_Status: switchRef.current,
    };

    const res = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshopproject_checklist/update/${projectList?.ID_No}`,

      payload
    );

    handleCancel();

    if (res.status === 200) {
      alert("Form data updated successfully!");
      setRefresh(!refresh);
    }
  };

  // %%%%%%%%%%%%%%%%%%%%%%%% Setting active %%%%%%%%%%%%%%%%%%%%%%%%%%
  const switchRef = useRef(switchValue);
  const handleSwitchValueChange = async (e) => {
    let { checked } = e.target;
    setswitchValue(checked);

    switchRef.current = checked;
    await saveForm(e);
  };
  const step1IsActive = () => {
    setActiveStep(0);
  };
  const step2IsActive = () => {
    setActiveStep(1);
  };
  const step3IsActive = () => {
    setActiveStep(2);
  };
  const step4IsActive = () => {
    setActiveStep(3);
  };
  const step5IsActive = () => {
    setActiveStep(4);
  };
  const step6IsActive = () => {
    setActiveStep(5);
  };
  const step7IsActive = () => {
    setActiveStep(6);
  };
  const step8IsActive = () => {
    setActiveStep(7);
  };
  const [inputValues1, setInputValues1] = useState({
    issue1: "",
    issue2: "",
    issue3: "",
    issue4: "",
    issue5: "",
    issue6: "",
  });
  const [inputValues2, setInputValues2] = useState({
    issue7: "",
    issue8: "",
    issue9: "",
    issue10: "",
    issue11: "",
    issue12: "",
    issue13: "",
    issue14: "",
    issue15: "",
    issue16: "",
  });

  const [inputValues3, setInputValues3] = useState({
    issue17: "",
    issue17A: "",
    issue18: "",
    issue19: "",
    issue20: "",
    issue21: "",
    issue22: "",
    issue23: "",
    issue24: "",
  });

  const [inputValues4, setInputValues4] = useState({
    issue25: "",
    issue26: "",
    issue27: "",
    issue28: "",
    issue29: "",
    issue29A: "",
    issue30: "",
    issue30A: "",
  });

  const [inputValues5, setInputValues5] = useState({
    issue31: "",
    issue32: "",
    issue33: "",
    issue34: "",
    issue35: "",
    issue36: "",
  });

  const [inputValues6, setInputValues6] = useState({
    issue37: "",
    issue38: "",
    issue39: "",
  });

  const [inputValues7, setInputValues7] = useState({
    issue40: "",
    issue41: "",
    issue42: "",
    issue42A: "",
  });

  const [inputValues8, setInputValues8] = useState({
    issue43: "",
    issue44: "",
  });
  const [selectValues1, setSelectValues1] = useState({
    item1: "",
    item2: "",
    item3: "",
    item4: "",
    item5: "",
    item6: "",
  });
  const [selectValues2, setSelectValues2] = useState({
    item7: "",
    item8: "",
    item9: "",
    item10: "",
    item11: "",
    item12: "",
    item13: "",
    item14: "",
    item15: "",
    item16: "",
  });
  const [selectValues3, setSelectValues3] = useState({
    item17: "",
    item17A: "",
    item18: "",
    item19: "",
    item20: "",
    item21: "",
    item22: "",
    item23: "",
    item24: "",
  });
  const [selectValues4, setSelectValues4] = useState({
    item25: "",
    item26: "",
    item27: "",
    item28: "",
    item29: "",
    item29A: "",
    item30: "",
    item30A: "",
  });

  const [selectValues5, setSelectValues5] = useState({
    item31: "",
    item32: "",
    item33: "",
    item34: "",
    item35: "",
    item36: "",
  });

  const [selectValues6, setSelectValues6] = useState({
    item37: "",
    item38: "",
    item39: "",
  });

  const [selectValues7, setSelectValues7] = useState({
    item40: "",
    item41: "",
    item42: "",
    item42A: "",
  });
  const [selectValues8, setSelectValues8] = useState({
    item43: "",
    item44: "",
  });
  const handleCancel = () => {
    setEditMode(false);
    setRefresh(!refresh);
  };
  const handleInputValueChange1 = (e) => {
    const { name, value } = e.target;
    setInputValues1({
      ...inputValues1,
      [name]: value,
    });
  };

  const handleInputValueChange2 = (e) => {
    const { name, value } = e.target;
    setInputValues2({
      ...inputValues2,
      [name]: value,
    });
  };

  const handleInputValueChange3 = (e) => {
    const { name, value } = e.target;
    setInputValues3({
      ...inputValues3,
      [name]: value,
    });
  };

  const handleInputValueChange4 = (e) => {
    const { name, value } = e.target;
    setInputValues4({
      ...inputValues4,
      [name]: value,
    });
  };

  const handleInputValueChange5 = (e) => {
    const { name, value } = e.target;
    setInputValues5({
      ...inputValues5,
      [name]: value,
    });
  };

  const handleInputValueChange6 = (e) => {
    const { name, value } = e.target;
    setInputValues6({
      ...inputValues6,
      [name]: value,
    });
  };

  const handleInputValueChange7 = (e) => {
    const { name, value } = e.target;
    setInputValues7({
      ...inputValues7,
      [name]: value,
    });
  };

  const handleInputValueChange8 = (e) => {
    const { name, value } = e.target;
    setInputValues8({
      ...inputValues8,
      [name]: value,
    });
  };

  const handleSelectChange1 = (e) => {
    const { name, value } = e.target;
    step1IsActive();
    setSelectValues1({
      ...selectValues1,
      [name]: value,
    });
  };

  const handleSelectChange2 = (e) => {
    const { name, value } = e.target;
    step2IsActive();
    setSelectValues2({
      ...selectValues2,
      [name]: value,
    });
  };
  const handleSelectChange3 = (e) => {
    const { name, value } = e.target;
    step3IsActive();
    setSelectValues3({
      ...selectValues3,
      [name]: value,
    });
  };
  const handleSelectChange4 = (e) => {
    const { name, value } = e.target;
    step4IsActive();
    setSelectValues4({
      ...selectValues4,
      [name]: value,
    });
  };
  const handleSelectChange5 = (e) => {
    const { name, value } = e.target;
    step5IsActive();
    setSelectValues5({
      ...selectValues5,
      [name]: value,
    });
  };
  const handleSelectChange6 = (e) => {
    const { name, value } = e.target;
    step6IsActive();
    setSelectValues6({
      ...selectValues6,
      [name]: value,
    });
  };
  const handleSelectChange7 = (e) => {
    const { name, value } = e.target;
    step7IsActive();
    setSelectValues7({
      ...selectValues7,
      [name]: value,
    });
  };
  const handleSelectChange8 = (e) => {
    const { name, value } = e.target;
    step8IsActive();
    setSelectValues8({
      ...selectValues8,
      [name]: value,
    });
  };
  const renderStatusSelect1 = (selectFor) => {
    return (
      <div className={styles.selectContainer}>
        <select
          className={styles.notdisabledInput}
          value={selectValues1[selectFor] || ""}
          name={selectFor}
          onChange={handleSelectChange1}>
          <option value="">Select</option>
          {/* <option value="Yet to Start">Yet to Start</option> */}
          <option value="In Progress">In Progress</option>
          <option value="Facing Issues">Facing Issues</option>
          <option value="Completed">Completed</option>
          <option value="N/A">N/A</option>
        </select>
        <svg
          className={styles.svgSelect}
          style={{
            right: "20px",
          }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
            fill="#394759"
          />
        </svg>
      </div>
    );
  };
  const renderStatusSelect2 = (selectFor) => {
    return (
      <div className={styles.selectContainer}>
        <select
          className={styles.notdisabledInput}
          value={selectValues2[selectFor] || ""}
          name={selectFor}
          onChange={handleSelectChange2}>
          <option value="">Select</option>
          {/* <option value="Yet to Start">Yet to Start</option> */}
          <option value="In Progress">In Progress</option>
          <option value="Facing Issues">Facing Issues</option>
          <option value="Completed">Completed</option>
          <option value="N/A">N/A</option>
        </select>
        <svg
          className={styles.svgSelect}
          style={{ right: "20px" }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
            fill="#394759"
          />
        </svg>
      </div>
    );
  };

  const renderStatusSelect3 = (selectFor) => {
    return (
      <div className={styles.selectContainer}>
        <select
          className={styles.notdisabledInput}
          value={selectValues3[selectFor] || ""}
          name={selectFor}
          onChange={handleSelectChange3}>
          <option value="">Select</option>
          {/* <option value="Yet to Start">Yet to Start</option> */}
          <option value="In Progress">In Progress</option>
          <option value="Facing Issues">Facing Issues</option>
          <option value="Completed">Completed</option>
          <option value="N/A">N/A</option>
        </select>
        <svg
          className={styles.svgSelect}
          style={{ right: "20px" }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
            fill="#394759"
          />
        </svg>
      </div>
    );
  };

  const renderStatusSelect4 = (selectFor) => {
    return (
      <div className={styles.selectContainer}>
        <select
          className={styles.notdisabledInput}
          value={selectValues4[selectFor] || ""}
          name={selectFor}
          onChange={handleSelectChange4}>
          <option value="">Select</option>
          {/* <option value="Yet to Start">Yet to Start</option> */}
          <option value="In Progress">In Progress</option>
          <option value="Facing Issues">Facing Issues</option>
          <option value="Completed">Completed</option>
          <option value="N/A">N/A</option>
        </select>
        <svg
          className={styles.svgSelect}
          style={{ right: "20px" }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
            fill="#394759"
          />
        </svg>
      </div>
    );
  };

  const renderStatusSelect5 = (selectFor) => {
    return (
      <div className={styles.selectContainer}>
        <select
          className={styles.notdisabledInput}
          value={selectValues5[selectFor] || ""}
          name={selectFor}
          onChange={handleSelectChange5}>
          <option value="">Select</option>
          {/* <option value="Yet to Start">Yet to Start</option> */}
          <option value="In Progress">In Progress</option>
          <option value="Facing Issues">Facing Issues</option>
          <option value="Completed">Completed</option>
          <option value="N/A">N/A</option>
        </select>
        <svg
          className={styles.svgSelect}
          style={{ right: "20px" }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
            fill="#394759"
          />
        </svg>
      </div>
    );
  };

  const renderStatusSelect6 = (selectFor) => {
    return (
      <div className={styles.selectContainer}>
        <select
          className={styles.notdisabledInput}
          value={selectValues6[selectFor] || ""}
          name={selectFor}
          onChange={handleSelectChange6}>
          <option value="">Select</option>
          {/* <option value="Yet to Start">Yet to Start</option> */}
          <option value="In Progress">In Progress</option>
          <option value="Facing Issues">Facing Issues</option>
          <option value="Completed">Completed</option>
          <option value="N/A">N/A</option>
        </select>
        <svg
          className={styles.svgSelect}
          style={{ right: "20px" }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
            fill="#394759"
          />
        </svg>
      </div>
    );
  };
  const renderStatusSelect7 = (selectFor) => {
    return (
      <div className={styles.selectContainer}>
        <select
          className={styles.notdisabledInput}
          value={selectValues7[selectFor] || ""}
          name={selectFor}
          onChange={handleSelectChange7}>
          <option value="">Select</option>
          {/* <option value="Yet to Start">Yet to Start</option> */}
          <option value="In Progress">In Progress</option>
          <option value="Facing Issues">Facing Issues</option>
          <option value="Completed">Completed</option>
          <option value="N/A">N/A</option>
        </select>
        <svg
          className={styles.svgSelect}
          style={{ right: "20px" }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
            fill="#394759"
          />
        </svg>
      </div>
    );
  };
  const renderStatusSelect8 = (selectFor) => {
    return (
      <div className={styles.selectContainer}>
        <select
          className={styles.notdisabledInput}
          value={selectValues8[selectFor] || ""}
          name={selectFor}
          onChange={handleSelectChange8}>
          <option value="">Select</option>
          {/* <option value="Yet to Start">Yet to Start</option> */}
          <option value="In Progress">In Progress</option>
          <option value="Facing Issues">Facing Issues</option>
          <option value="Completed">Completed</option>
          <option value="N/A">N/A</option>
        </select>
        <svg
          className={styles.svgSelect}
          style={{ right: "20px" }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
            fill="#394759"
          />
        </svg>
      </div>
    );
  };

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PO_Date: filter[0]?.PODate,
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  const getChecklistData = async () => {
    try {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshopproject-checklistgetdetails"
      );

      const filter = res.data.data.filter((el) => el.ID_No == id);

      setInputValues1({
        issue1: filter[0]?.Issue1,
        issue2: filter[0]?.Issue2,
        issue3: filter[0]?.Issue3,
        issue4: filter[0]?.Issue4,
        issue5: filter[0]?.Issue5,
        issue6: filter[0]?.Issue6,
      });

      setInputValues2({
        issue7: filter[0]?.Issue7,
        issue8: filter[0]?.Issue8,
        issue9: filter[0]?.Issue9,
        issue10: filter[0]?.Issue10,
        issue11: filter[0]?.Issue11,
        issue12: filter[0]?.Issue12,
        issue13: filter[0]?.Issue13,
        issue14: filter[0]?.Issue14,
        issue15: filter[0]?.Issue15,
        issue16: filter[0]?.Issue16,

      });

      setInputValues3({
        issue17: filter[0]?.Issue17,
        issue17A: filter[0]?.Issue17A,
        issue18: filter[0]?.Issue18,
        issue19: filter[0]?.Issue19,
        issue20: filter[0]?.Issue20,
        issue21: filter[0]?.Issue21,
        issue22: filter[0]?.Issue22,
        issue23: filter[0]?.Issue23,
        issue24: filter[0]?.Issue24,

      });

      setInputValues4({
        issue25: filter[0]?.Issue25,
        issue26: filter[0]?.Issue26,
        issue27: filter[0]?.Issue27,
        issue28: filter[0]?.Issue28,
        issue29: filter[0]?.Issue29,
        issue29A: filter[0]?.Issue29A,
        issue30: filter[0]?.Issue30,
        issue30A: filter[0]?.Issue30A,


        issue37: filter[0]?.Issue37,
        issue38: filter[0]?.Issue38,
        issue39: filter[0]?.Issue39,
        issue40: filter[0]?.Issue40,
      });

      setInputValues5({
        issue31: filter[0]?.Issue31,
        issue32: filter[0]?.Issue32,
        issue33: filter[0]?.Issue33,
        issue34: filter[0]?.Issue34,
        issue35: filter[0]?.Issue35,
        issue36: filter[0]?.Issue36,
      });

      setInputValues6({
        issue37: filter[0]?.Issue37,
        issue38: filter[0]?.Issue38,
        issue39: filter[0]?.Issue39,
      });

      setInputValues7({
        issue40: filter[0]?.Issue40,
        issue41: filter[0]?.Issue41,
        issue42: filter[0]?.Issue42,
        issue42A: filter[0]?.Issue42A,
      });

      setInputValues8({
        issue43: filter[0]?.Issue43,
        issue44: filter[0]?.Issue44,
      });

      setSelectValues1({
        item1: filter[0]?.Item1,
        item2: filter[0]?.Item2,
        item3: filter[0]?.Item3,
        item4: filter[0]?.Item4,
        item5: filter[0]?.Item5,
        item6: filter[0]?.Item6,

      });
      setSelectValues2({
        item7: filter[0]?.Item7,
        item8: filter[0]?.Item8,
        item9: filter[0]?.Item9,
        item10: filter[0]?.Item10,
        item11: filter[0]?.Item11,
        item12: filter[0]?.Item12,
        item13: filter[0]?.Item13,
        item14: filter[0]?.Item14,
        item15: filter[0]?.Item15,
        item16: filter[0]?.Item16,
      });
      setSelectValues3({
        item17: filter[0]?.Item17,
        item17A: filter[0]?.Item17A,
        item18: filter[0]?.Item18,
        item19: filter[0]?.Item19,
        item20: filter[0]?.Item20,
        item21: filter[0]?.Item21,
        item22: filter[0]?.Item22,
        item23: filter[0]?.Item23,
        item24: filter[0]?.Item24,

      });
      setSelectValues4({
        item25: filter[0]?.Item25,
        item26: filter[0]?.Item26,
        item27: filter[0]?.Item27,
        item28: filter[0]?.Item28,
        item29: filter[0]?.Item29,
        item29A: filter[0]?.Item29A,
        item30: filter[0]?.Item30,
        item30A: filter[0]?.Item30A,

      });

      setSelectValues5({
        item31: filter[0]?.Item31,
        item32: filter[0]?.Item32,
        item33: filter[0]?.Item33,
        item34: filter[0]?.Item34,
        item35: filter[0]?.Item35,
        item36: filter[0]?.Item36,
      });

      setSelectValues6({
        item37: filter[0]?.Item37,
        item38: filter[0]?.Item38,
        item39: filter[0]?.Item39,

      });

      setSelectValues7({
        item40: filter[0]?.Item40,
        item41: filter[0]?.Item41,
        item42: filter[0]?.Item42,
        item42A: filter[0]?.Item42A,

      });

      setSelectValues8({
        item43: filter[0]?.Item43,
        item44: filter[0]?.Item44,
      });

      setswitchValue(filter[0]?.Paused_Status === "1" ? true : false);

      setCheckListData(filter[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getChecklistData();
  }, [refresh]);

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );
        console.log("I  am here");
        console.log(response);

        const workshopprojcheckfeature = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Project Checklist" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        })
        const workshopprojcheckfeatureedit = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Project Checklist Edit" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        })

        setfeatureprojectchecklist(workshopprojcheckfeature);
        setfeatureprojectchecklistedit(workshopprojcheckfeatureedit);

      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, [])

  return (
    <Box className={styles.tabpanelStyling}>
      <div className={styles.topdiv}>
        <label className={styles.aryl1}>Project Name: {formdata1.ProjectName}</label>
        <label className={styles.aryl}>Project Scope: {formdata1.Scope}</label>
        <label className={styles.aryl}>Project Description: {formdata1.projectDescription}</label>
        <label className={styles.aryl}>Quantity: {formdata1.quantity}</label>
        <label className={styles.aryl}>Target Days: {formdata1.TargetDaysForCompletion}</label>
        <label className={styles.aryl}>Target Manhours: {formdata1.TargetManhours}</label>
      </div>
      <div className={styles.aryTabs}>
        <div
          className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick}>
          Project Checklist
        </div>
        <div
          className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick1}>
          Project Details
        </div>
        <div
          className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick10}
        >
          Team Details
        </div>
        <div
          className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick12}>
          Inventory Used
        </div>
        <div
          className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick13}>
          Purchases
        </div>
        <div
          className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick2}>
          Timesheet
        </div>
        <div
          className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick3}>
          Deliveries
        </div>
        <div
          className={workshop === 23 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick23}>
          Serial Number
        </div>
        <div
          className={workshop === 15 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick15}>
          Production
        </div>
        {/* <div
          className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick4}>
          Outgoing Deliveries
        </div> */}
        <div
          className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick14}>
          Timeline
        </div>
        {/* <div
          className={workshop === 17 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick17}>
          Dispatch
        </div> */}
        <div
          className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick5}
        >
          Outsourcing
        </div>

        <div
          className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick6}
        >
          Reports
        </div>

        <div
          className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick7}
        >
          Inspections
        </div>
        <div
          className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick8}
        >
          Items Received
        </div>
        <div
          className={workshop === 11 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick9}
        >
          Finished goods
        </div>
        <div
          className={workshop === 16 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick16}
        >
          WIP
        </div>

      </div>
      {workshop === 1 && <></>}
      {workshop === 2 && <ProjectDetailsWorkshop />}
      {workshop === 3 && <></>}
      {workshop === 4 && <></>}
      {workshop === 5 && <></>}
      {/*
            {workshop === 6 && (
                <></>
            )}
            {workshop === 7 && (
                <></>
            )}
            {workshop === 8 && (
                <></>
            )} */}
      <div className={styles.lowbox}>
        <div className={styles.editAndSaveButtons}>
          {editMode ? (
            <>
              <Button
                variant="outlined"
                onClick={handleCancel}
                className={styles.cancelBtn}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={saveForm}
                className={styles.saveButton}>
                Save
              </Button>
            </>
          ) : (
            featureprojectchecklistedit.length > 0 && (
              <div>

                <Button
                  className={styles.previewAndDownloadBtn}
                  variant="contained"
                  onClick={() => setEditMode(true)}>
                  Edit
                  {/* <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M15.8505 2.90005C14.2338 1.28338 12.6505 1.24172 10.9921 2.90005L9.9838 3.90838C9.90047 3.99172 9.86714 4.12505 9.90047 4.24172C10.5338 6.45005 12.3005 8.21672 14.5088 8.85005C14.5421 8.85838 14.5755 8.86672 14.6088 8.86672C14.7005 8.86672 14.7838 8.83338 14.8505 8.76672L15.8505 7.75838C16.6755 6.94172 17.0755 6.15005 17.0755 5.35005C17.0838 4.52505 16.6838 3.72505 15.8505 2.90005Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M13.0079 9.60829C12.7663 9.49163 12.5329 9.37496 12.3079 9.24163C12.1246 9.13329 11.9496 9.01663 11.7746 8.89163C11.6329 8.79996 11.4663 8.66663 11.3079 8.53329C11.2913 8.52496 11.2329 8.47496 11.1663 8.40829C10.8913 8.17496 10.5829 7.87496 10.3079 7.54163C10.2829 7.52496 10.2413 7.46663 10.1829 7.39163C10.0996 7.29163 9.95795 7.12496 9.83295 6.93329C9.73295 6.80829 9.61628 6.62496 9.50795 6.44163C9.37461 6.21663 9.25795 5.99163 9.14128 5.75829C8.98832 5.43051 8.5581 5.33314 8.30233 5.58891L3.61628 10.275C3.50795 10.3833 3.40795 10.5916 3.38295 10.7333L2.93295 13.925C2.84961 14.4916 3.00795 15.025 3.35795 15.3833C3.65795 15.675 4.07461 15.8333 4.52461 15.8333C4.62461 15.8333 4.72461 15.825 4.82461 15.8083L8.02461 15.3583C8.17461 15.3333 8.38295 15.2333 8.48295 15.125L13.1767 10.4312C13.4274 10.1805 13.3332 9.74924 13.0079 9.60829Z"
                                        fill="black"
                                    />
                                </svg> */}
                </Button>

              </div>
            )
          )}
        </div>

        <div className={styles.containerDropDowns} onClick={toggleDropdown1}>
          <h4 className={styles.mainDropdowns}>1. Pre-incoming delivery</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded1 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>
        {isExpanded1 && (
          <div className={styles.contentInsidedropdown}>
            <table className={styles.projectChecklistTable}>
              <thead>
                <th>Task Number</th>
                <th>Task description</th>
                <th>Personnel Responsible</th>
                <th>Status</th>
                <th>Issues/Remarks</th>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Email Confirmation/RFQ</td>
                  <td>Saranya</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect1("item1")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          className={styles.disabledInput}
                          type="text"
                          value={checkListData?.Item1}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        className={styles.notdisabledInput2}
                        name="issue1"
                        value={inputValues1.issue1}
                        onChange={handleInputValueChange1}
                      />
                    ) : (
                      <input
                        className={styles.disabledInput2}
                        type="text"
                        value={
                          checkListData?.Issue1 ? checkListData?.Issue1 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Prepare Costing</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect1("item2")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item2}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        className={styles.notdisabledInput2}
                        name="issue2"
                        value={inputValues1.issue2}
                        onChange={handleInputValueChange1}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue2 ? checkListData?.Issue2 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Issue Quote</td>
                  <td>Saranya</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect1("item3")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item3}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue3"
                        value={inputValues1.issue3}
                        onChange={handleInputValueChange1}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue3 ? checkListData?.Issue3 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Receive PO</td>
                  <td>Saranya</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect1("item4")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item4}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue4"
                        value={inputValues1.issue4}
                        onChange={handleInputValueChange1}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue4 ? checkListData?.Issue4 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Contract Review</td>
                  <td>Sandhya</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect1("item5")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item5}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue5"
                        value={inputValues1.issue5}
                        onChange={handleInputValueChange1}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue5 ? checkListData?.Issue5 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Order Confirmation</td>
                  <td>Sandhya</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect1("item6")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item6}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue6"
                        value={inputValues1.issue6}
                        onChange={handleInputValueChange1}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue6 ? checkListData?.Issue6 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className={styles.containerDropDowns} onClick={toggleDropdown2}>
          <h4 className={styles.mainDropdowns}>2. Incoming</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded2 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>
        {isExpanded2 && (
          <div className={styles.contentInsidedropdown}>
            <table className={styles.projectChecklistTable}>
              <thead>
                <th>Task Number</th>
                <th>Task description</th>
                <th>Personnel Responsible</th>
                <th>Status</th>
                <th>Issues/Remarks</th>
              </thead>
              <tbody>
                <tr>
                  <td>7</td>
                  <td>Confirmation on CIPL</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item7")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item7}
                          disabled
                        />
                        <svg
                          style={{
                            right: "45px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue7"
                        value={inputValues2.issue7}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue7 ? checkListData?.Issue7 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Check Space Availability</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item8")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item8}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue8"
                        value={inputValues2.issue8}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue8 ? checkListData?.Issue8 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>Prepare Scope of Work</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item9")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item9}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue9"
                        value={inputValues2.issue9}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue9 ? checkListData?.Issue9 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Prepare Spares list for assembly</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item10")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item10}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue11"
                        value={inputValues2.issue10}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        name="issue11"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue10 ? checkListData?.Issue10 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>Receive Exit Bill</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item11")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item11}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue12"
                        value={inputValues2.issue11}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue11 ? checkListData?.Issue11 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Create Entry Bill</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item12")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item12}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue12"
                        value={inputValues2.issue12}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.issue12 ? checkListData?.issue12 : ""
                          }
                          disabled
                        />
                      </div>
                    )}
                  </td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>Forklift/Crane Arrangement</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item13")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item13}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue13"
                        value={inputValues2.issue13}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.issue13 ? checkListData?.issue13 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>14</td>
                  <td>Visit Customs Office for Approval</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item14")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item14}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue14"
                        value={inputValues2.issue14}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.issue14 ? checkListData?.issue14 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>15</td>
                  <td>Incoming Inspection</td>
                  <td>Haneef</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item15")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item15}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue15"
                        value={inputValues2.issue15}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.issue15 ? checkListData?.issue15 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>16</td>
                  <td>Labelling and placement in respective zone</td>
                  <td>Haneef</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item16")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item16}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue16"
                        value={inputValues2.issue16}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.issue16 ? checkListData?.Issue16 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className={styles.containerDropDowns} onClick={toggleDropdown3}>
          <h4 className={styles.mainDropdowns}>
            3. Preparation for Production
          </h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded3 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>

        {isExpanded3 && (
          <>
            <div className={styles.contentInsidedropdown}>
              <table className={styles.projectChecklistTable}>
                <thead>
                  <th>Task Number</th>
                  <th>Task description</th>
                  <th>Personnel Responsible</th>
                  <th>Status</th>
                  <th>Issues/Remarks</th>
                </thead>
                <tbody>
                  <tr>
                    <td>17</td>
                    <td>
                      Create Shortfall list of tools/spares and consumables
                    </td>
                    <td>Ajhar/Irfan</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item17")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item17}
                            disabled
                          />

                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue17"
                          value={inputValues3.issue17}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue17 ? checkListData?.Issue17 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>17A</td>
                    <td>
                      Spares Status
                    </td>
                    <td>Ajhar/Irfan</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item17A")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item17A}
                            disabled
                          />

                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue17A"
                          value={inputValues3.issue17A}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue17A ? checkListData?.Issue17A : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>
                      Notify customers for pending customer-supplied property
                    </td>
                    <td>Ajhar/Irfan</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item18")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item18}
                            disabled
                          />
                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue18"
                          value={inputValues3.issue18}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue18 ? checkListData?.Issue18 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>19</td>
                    <td>Pending consumables/Spares-Send enquiry to vendors</td>
                    <td>Ajhar/Irfan</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item19")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item19}
                            disabled
                          />
                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue19"
                          value={inputValues3.issue19}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue19 ? checkListData?.Issue19 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>20</td>
                    <td>Comparison of Received Quotes</td>
                    <td>Charulatha/Jancy </td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item20")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item20}
                            disabled
                          />
                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue20"
                          value={inputValues3.issue20}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue20 ? checkListData?.Issue20 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>21</td>
                    <td>Issue PO for purchase</td>
                    <td>Sandhya</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item21")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item21}
                            disabled
                          />
                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue21"
                          value={inputValues3.Issue21}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue21 ? checkListData?.Issue21 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>22</td>
                    <td>
                      Checking supervisor availabilty, selecting team and
                      mobilizing supervisor
                    </td>
                    <td>Nishad /Glesie</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item22")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item22}
                            disabled
                          />
                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue22"
                          value={inputValues3.issue22}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.issue22 ? checkListData?.issue22 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>23</td>
                    <td>API Premob Document</td>
                    <td>Jancy</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item23")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item23}
                            disabled
                          />
                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue23"
                          value={inputValues3.issue23}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue23 ? checkListData?.Issue23 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>24</td>
                    <td>
                      Workshop induction and briefing on OEM Manuals, previous
                      works of similar nature
                    </td>
                    <td>Ajhar/Irfan</td>
                    <td>
                      {editMode ? (
                        <div>{renderStatusSelect3("item24")}</div>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item24}
                            disabled
                          />
                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue24"
                          value={inputValues3.issue24}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue24 ? checkListData?.Issue24 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}

        <div className={styles.containerDropDowns} onClick={toggleDropdown4}>
          <h4 className={styles.mainDropdowns}>4. Production</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded4 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>

        {isExpanded4 && (
          <div className={styles.contentInsidedropdown}>
            {/* <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}>
                                <Switch
                                    checked={switchValue}
                                    onChange={handleSwitchValueChange}
                                />
                                <h5>Pause</h5>
                            </div> */}
            <table className={styles.projectChecklistTable}>
              <thead>
                <th>Task Number</th>
                <th>Task description</th>
                <th>Personnel Responsible</th>
                <th>Status</th>
                <th>Issues/Remarks</th>
              </thead>
              <tbody>
                <tr>
                  <td>25</td>
                  <td>Move Stored item to production area</td>
                  <td>Supervisor/Techs</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect4("item25")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item25}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue25"
                        value={inputValues4.issue25}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue25 ? checkListData?.Issue25 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>26</td>
                  <td>
                    Recurring Activities-Toolbox talk, work permit, Timesheet,
                    TBRA, Daily Report
                  </td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect4("item26")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item26}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue26"
                        value={inputValues4.issue26}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue26 ? checkListData?.Issue26 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>27</td>
                  <td>Update - Spares and consumables used from inventory</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect4("item27")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item27}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue27"
                        value={inputValues4.issue27}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue27 ? checkListData?.Issue27 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>28</td>
                  <td>Prepare list of spares for reassembly(for D&I scope)</td>
                  <td>Supervisor/Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect4("item28")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item28}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue28"
                        value={inputValues4.issue28}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue28 ? checkListData?.Issue28 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>29</td>
                  <td>Prepare Inhouse MPI report</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect4("item29")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item29}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue29"
                        value={inputValues4.issue29}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue29 ? checkListData?.Issue29 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>29A</td>
                  <td>Preparation of Final Report</td>
                  <td>Charulatha</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect4("item29A")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item29A}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue29A"
                        value={inputValues4.issue29A}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue29A ? checkListData?.Issue29A : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>30</td>
                  <td>Traceability of parts</td>
                  <td>Supervisor/Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect4("item30")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item30}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue30"
                        value={inputValues4.issue30}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue30 ? checkListData?.Issue30 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>30A</td>
                  <td>Scope Status</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect4("item30A")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item30A}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue30A"
                        value={inputValues4.issue30A}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue30A ? checkListData?.Issue30A : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className={styles.containerDropDowns} onClick={toggleDropdown5}>
          <h4 className={styles.mainDropdowns}>5. Outgoing</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded5 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>

        {isExpanded5 && (
          <div className={styles.contentInsidedropdown}>
            <table className={styles.projectChecklistTable}>
              <thead>
                <th>Task Number</th>
                <th>Task description</th>
                <th>Personnel Responsible</th>
                <th>Status</th>
                <th>Issues/Remarks</th>
              </thead>
              <tbody>
                <tr>
                  <td>31</td>
                  <td>Outgoing inspection</td>
                  <td>Haneef</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect5("item31")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item31}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue31"
                        value={inputValues5.issue31}
                        onChange={handleInputValueChange5}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue31 ? checkListData?.Issue31 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>32</td>
                  <td>Weighing, packaging and labelling</td>
                  <td>Haneef</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect5("item32")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item32}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue32"
                        value={inputValues5.issue32}
                        onChange={handleInputValueChange5}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue32 ? checkListData?.Issue32 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>33</td>
                  <td>Prepare CIPL</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect5("item33")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item33}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue33"
                        value={inputValues5.issue33}
                        onChange={handleInputValueChange5}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue33 ? checkListData?.Issue33 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>34</td>
                  <td>Crane/Forklift arrangement</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect5("item34")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item34}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue34"
                        value={inputValues5.issue34}
                        onChange={handleInputValueChange5}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue34 ? checkListData?.Issue34 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>35</td>
                  <td>Prepare Exit Bill</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect5("item35")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item35}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue35"
                        value={inputValues5.issue35}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue35 ? checkListData?.Issue35 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>36</td>
                  <td>Visit Customs office for Approval</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect5("item36")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item36}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue36"
                        value={inputValues5.issue36}
                        onChange={handleInputValueChange5}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue36 ? checkListData?.Issue36 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className={styles.containerDropDowns} onClick={toggleDropdown6}>
          <h4 className={styles.mainDropdowns}>6. Outsourcing</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded6 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>

        {isExpanded6 && (
          <div className={styles.contentInsidedropdown}>
            <table className={styles.projectChecklistTable}>
              <thead>
                <th>Task Number</th>
                <th>Task description</th>
                <th>Personnel Responsible</th>
                <th>Status</th>
                <th>Issues/Remarks</th>
              </thead>
              <tbody>
                <tr>
                  <td>37</td>
                  <td>All OUTGOING procedures to be followed</td>
                  <td>Haneef/Irfan/Ajhar</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect6("item37")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item37}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue37"
                        value={inputValues6.issue37}
                        onChange={handleInputValueChange6}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue37 ? checkListData?.Issue37 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>38</td>
                  <td>Collect test reports</td>
                  <td>Haneef</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect6("item38")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item38}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue38"
                        value={inputValues6.issue38}
                        onChange={handleInputValueChange6}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue38 ? checkListData?.Issue38 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>39</td>
                  <td>All INCOMING procedures to be followed</td>
                  <td>Haneef/Irfan/Ajhar</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect6("item39")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item39}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue39"
                        value={inputValues6.issue39}
                        onChange={handleInputValueChange6}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue39 ? checkListData?.Issue39 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className={styles.containerDropDowns} onClick={toggleDropdown7}>
          <h4 className={styles.mainDropdowns}>7. Order Closing</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded7 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>

        {isExpanded7 && (
          <div className={styles.contentInsidedropdown}>
            <table className={styles.projectChecklistTable}>
              <thead>
                <th>Task Number</th>
                <th>Task description</th>
                <th>Personnel Responsible</th>
                <th>Status</th>
                <th>Issues/Remarks</th>
              </thead>
              <tbody>
                <tr>
                  <td>40</td>
                  <td>Submission of final report</td>
                  <td>Charulatha</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect7("item40")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item40}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue40"
                        value={inputValues7.issue40}
                        onChange={handleInputValueChange7}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue40 ? checkListData?.Issue40 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>41</td>
                  <td>Receive Signed CIPL from recipient</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect7("item41")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item33}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue41"
                        value={inputValues7.issue41}
                        onChange={handleInputValueChange7}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue41 ? checkListData?.Issue41 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>42</td>
                  <td>Submit invoice to Customer</td>
                  <td>Midhula</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect7("item42")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item42}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue42"
                        value={inputValues7.issue42}
                        onChange={handleInputValueChange7}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue42 ? checkListData?.Issue42 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>42A</td>
                  <td>Payment Realization</td>
                  <td>Midhula</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect7("item42A")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item42A}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue42A"
                        value={inputValues7.issue42A}
                        onChange={handleInputValueChange7}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue42A ? checkListData?.Issue42A : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className={styles.containerDropDowns} onClick={toggleDropdown8}>
          <h4 className={styles.mainDropdowns}>8. Assessment</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded8 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>

        {isExpanded8 && (
          <div className={styles.contentInsidedropdown}>
            <table className={styles.projectChecklistTable}>
              <thead>
                <th>Task Number</th>
                <th>Task description</th>
                <th>Personnel Responsible</th>
                <th>Status</th>
                <th>Issues/Remarks</th>
              </thead>
              <tbody>
                <tr>
                  <td>43</td>
                  <td>Perform Assessment of Techs and Supervisors</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect8("item43")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item43}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue43"
                        value={inputValues8.issue43}
                        onChange={handleInputValueChange8}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue43 ? checkListData?.Issue43 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>44</td>
                  <td>Skill gap analysis and training plan for techs</td>
                  <td>Ajhar/Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect8("item44")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item44}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue44"
                        value={inputValues8.issue44}
                        onChange={handleInputValueChange4}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue44 ? checkListData?.Issue44 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Box>
  );
}

export default ProjectChecklistWorkshop;
