import "../Components/css/oemmnual.css";
import redirect from "../assets/image 4.png";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

export const OEMManuals = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tableData, setTableData] = useState([])
  const [docName , setDocName] = useState("")
  const [setData, setSetData] = useState({ design: "", serviceComponent: "", uploadFile: null, fileName: "" });
  const [editData, setEditData] = useState([]);
  const addNewRowRef = useRef(null);

  const toggleAdd = () => {
    setIsAdding(true);
    setTimeout(() => {
      addNewRowRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    },10)
  }

  const handleAdd = async () => {
    try {
      const { design, serviceComponent, uploadFile, fileName } = setData;
      const fileData = new FormData();

      fileData.append("design", design);
      fileData.append("service_component", serviceComponent);
      fileData.append("file", uploadFile);
      fileData.append("file_name", fileName);

      const response = await axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/oemmanual/create", fileData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });

      if (response.status === 201) {
        alert("OEM Manual added successfully");
        setSetData({ design: "", serviceComponent: "", uploadFile: null, fileName: "" });
        setIsAdding(false);
        setDocName("");
        getAllData();
      } else {
        console.error("Failed to add OEM Manual:", response.data);
      }

      return response;
    } catch (error) {
      console.error("Error in handleAdd:", error);
      throw error; // Rethrow for further handling
    }
  };

  const handleEdit = async () => {
    try {
      const updatePromises = editData.map(async (row) => {
        const { id, design, service_component, file_name, uploadFile,upload_file } = row;
        const fileData = new FormData();

        fileData.append("design", design);
        fileData.append("service_component", service_component);
        fileData.append("file_name", file_name);

        if (uploadFile) {
          fileData.append("file", uploadFile);
        } else if (upload_file) {
          // If no new file is uploaded, send the existing filename in a separate field
          fileData.append("existing_file", upload_file);
        }

        return axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/oemmanual/update/${id}`,
          fileData,
          {
            headers: { "Content-Type": "multipart/form-data" }
          }
        );
      });

      await Promise.all(updatePromises);

      alert("OEM Manual updated successfully");
      setIsEditing(false);
      getAllData();
    } catch (error) {
      console.error("Error in handleEdit:", error);
    }
  };

  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
    if (!isEditing) {
      setEditData(tableData.map(item => ({ ...item, uploadFile: null })));
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedEditData = [...editData];
    updatedEditData[index][field] = value;
    setEditData(updatedEditData);
  };

  const handleFileChangeEdit = (index, file) => {
    const updatedEditData = [...editData];
    updatedEditData[index].uploadFile = file;
    setEditData(updatedEditData);
  };

  const handleRemoveEditFile = (index) => {
    const updatedEditData = [...editData]; // Copy of the current edit data
    if (updatedEditData[index]) {
      updatedEditData[index].uploadFile = null; // Remove the file from editData
    }
    setEditData(updatedEditData); // Update the editData state
    
    const updatedTableData = [...tableData]; // Copy of the current table data
    if (updatedTableData[index]) {
      updatedTableData[index].upload_file = null; // Remove the file from tableData if editing is done
      updatedTableData[index].serviceComponent = ""; // Reset the service component
    }
    setTableData(updatedTableData); // Update the tableData state
  };

  const handleCancel = () => {
    setIsAdding(false);
    setIsEditing(false);
    getAllData();
  }

  const handleFileChange = (e) => {
    setDocName(e.target.files[0].name);
    setSetData({ ...setData, uploadFile: e.target.files[0]});
  }

  const getAllData = async () => {
    try {
      const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/oemmanual/get`);
      if (response.status === 200) {
        setTableData(response.data);
        setEditData(response.data.map(item => ({ ...item, uploadFile: null })));
      } else {
        console.error("Failed to fetch data:", response.data);
      }
    } catch (error) {
      console.error("Error in getAllData:", error);
      throw error;
    }
  }

  useEffect(() => {
    getAllData();
  },[])



  return (
    <div className="maindiv">
      <div className="buttonContainer">
        <button
          className="Addnewbtn"
          onClick={() => {
            if (isAdding) {
              handleAdd(); 
            } else if (isEditing) {
              handleEdit(); 
            } else {
              toggleAdd(); 
            }
          }}
        >
          {isAdding || isEditing ? "Save" : "Add New"}
        </button>

        <button
          className="Editbtn"
          onClick={() => {
            if (isAdding || isEditing) {
              handleCancel(); 
            } else {
              toggleEdit();
            }
          }}
        >
          {isAdding || isEditing ? "Cancel" : "Edit"}
        </button>
      </div>
      <div className="ThirdContainer">
        <span>OEM Manuals</span>
      </div>
      <div className="table-oem">
        <div className="table-container-oem" style={{ width: "100%", maxHeight: "700px", overflowY: "auto" }}>
          <table className="table table-bordered">
            <thead style={{ position: "sticky", top: "0", background: "white", zIndex: "10" }}>
              <tr>
                <th>S.No</th>
                <th>Design</th>
                <th>Service Component</th>
                <th>Upload File</th>
                <th>File name</th>
              </tr>
            </thead>
            <tbody style={{overflowY: "auto"}}>
              {tableData?.map((data, index) => (
                <tr key={data.id}>
                  <td>{data.id}</td>
                  <td>{isEditing ? (
                    <select
                    style={{ width: "229px"}}
                    name="rig_design"
                    value={editData[index]?.design || ""}
                    onChange={(e) => handleInputChange(index, "design", e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="MLT">MLT</option>
                    <option value="BMC">BMC</option>
                    <option value="Keppel">Keppel</option>
                    <option value="F&G">F&G</option>
                    <option value="Gusto">Gusto</option>
                    <option value="Modec">Modec</option>
                  </select>
                      ) : (<span>{data.design}</span>)}</td>

                  <td>{isEditing ? (
                  <select
                  name="serviceComponent"
                  value={editData[index]?.service_component || ""}
                  onChange={(e) => handleInputChange(index, "service_component", e.target.value)}
               >
                 <option value="">Select</option>
                 <option value="Jacking">Jacking</option>
                 <option value="Skidding">Skidding</option>
                 <option value="Crane">Crane</option>
                 <option value="Fixation">Fixation</option>
               </select>
                  ) : (<span>{data.service_component}</span>)}</td>

                  <td>
  {isEditing ? (
    <label className="upload-btn">
      <input
        type="file"
        hidden
        onChange={(e) => handleFileChangeEdit(index, e.target.files[0])}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#00000080",
        }}
      >
        <span style={{ textAlign: "center" }}>
          {editData[index]?.uploadFile
            ? editData[index].uploadFile.name
            : data.upload_file
            ? `${data.service_component} Manual`
            : "Upload File"}
        </span>
        {(editData[index]?.uploadFile || data.upload_file) && (
          <svg
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              
              handleRemoveEditFile(index);
            }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.38681 15.8327C5.07701 15.8327 4.7672 15.7186 4.52262 15.4741C4.04975 15.0014 4.04975 14.219 4.52262 13.7463L13.7516 4.52054C14.2245 4.04784 15.0071 4.04784 15.48 4.52054C15.9529 4.99324 15.9529 5.77563 15.48 6.24833L6.25101 15.4741C6.02273 15.7186 5.69662 15.8327 5.38681 15.8327Z"
              fill="black"
            />
            <path
              d="M14.6158 15.8327C14.306 15.8327 13.9962 15.7186 13.7516 15.4741L4.52262 6.24833C4.04975 5.77563 4.04975 4.99324 4.52262 4.52054C4.99548 4.04784 5.77815 4.04784 6.25101 4.52054L15.48 13.7463C15.9529 14.219 15.9529 15.0014 15.48 15.4741C15.2354 15.7186 14.9256 15.8327 14.6158 15.8327Z"
              fill="black"
            />
          </svg>
        )}
      </div>
    </label>
  ) : (
    <a
      href={`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/open-file/${data.upload_file}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>{`${data.service_component} Manual`}</span>
      &nbsp;&nbsp;
      <img src={redirect} alt="redirect" width="18px" />
    </a>
  )}
</td>

                  <td>
                    {isEditing ? (<input
                        type="text"
                        value={editData[index]?.file_name || ""}
                        onChange={(e) => handleInputChange(index, "file_name", e.target.value)}
                      />) : (<span>{data.file_name}</span>)}
                  </td>
                </tr>

              ))}
              {isAdding && (
                <tr ref={addNewRowRef}>
                  <td>{tableData.length + 1}</td>
                  <td>
                    {/* <input type="text" value={setData.design}
                      name="design"
                      onChange={(e) => setSetData({ ...setData, design: e.target.value })} /> */}
                      <select
                    name="rig_design"
                    value={setData.design}
                    onChange={(e) => setSetData({ ...setData, design: e.target.value })}
                  >
                    <option value="">Select</option>
                    <option value="MLT">MLT</option>
                    <option value="BMC">BMC</option>
                    <option value="Keppel">Keppel</option>
                    <option value="F&G">F&G</option>
                    <option value="Gusto">Gusto</option>
                    <option value="Modec">Modec</option>
                  </select>
                  </td>
                  <td>
                    {/* <input type="text"
                      name="serviceComponent"
                      value={setData.serviceComponent}
                      onChange={(e) => setSetData({ ...setData, serviceComponent: e.target.value })}
                    /> */}
                    <select
                     name="serviceComponent"
                    value={setData.serviceComponent}
                    onChange={(e) => setSetData({ ...setData, serviceComponent: e.target.value })}
                  >
                    <option value="">Select</option>
                    <option value="Jacking">Jacking</option>
                    <option value="Skidding">Skidding</option>
                    <option value="Crane">Crane</option>
                    <option value="Fixation">Fixation</option>
                  </select>
                  </td>
                  <td>
                    <label className="upload-btn">
                      <input type="file"
                        name="uploadFile"
                        onChange={handleFileChange}

                        hidden />
                      <div style={{ width: "100%", display: "flex", justifyContent: "space-around", color: "#00000080", alignItems: "center" }}>
                      <span>{docName?docName: "Upload File"}</span>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17.082 8.49101H14.6737C12.6987 8.49101 11.0904 6.88268 11.0904 4.90768V2.49935C11.0904 2.04102 10.7154 1.66602 10.257 1.66602H6.7237C4.15703 1.66602 2.08203 3.33268 2.08203 6.30768V13.691C2.08203 16.666 4.15703 18.3327 6.7237 18.3327H13.2737C15.8404 18.3327 17.9154 16.666 17.9154 13.691V9.32435C17.9154 8.86601 17.5404 8.49101 17.082 8.49101ZM9.60703 11.2743C9.48203 11.3993 9.3237 11.4577 9.16536 11.4577C9.00703 11.4577 8.8487 11.3993 8.7237 11.2743L8.1237 10.6743V14.166C8.1237 14.5077 7.84036 14.791 7.4987 14.791C7.15703 14.791 6.8737 14.5077 6.8737 14.166V10.6743L6.2737 11.2743C6.03203 11.516 5.63203 11.516 5.39036 11.2743C5.1487 11.0327 5.1487 10.6327 5.39036 10.391L7.05703 8.72435C7.11537 8.67435 7.1737 8.63268 7.24036 8.59935C7.25703 8.59102 7.28203 8.58268 7.2987 8.57435C7.3487 8.55768 7.3987 8.54935 7.45703 8.54102C7.48203 8.54102 7.4987 8.54102 7.5237 8.54102C7.59036 8.54102 7.65703 8.55768 7.7237 8.58268C7.73203 8.58268 7.73203 8.58268 7.74037 8.58268C7.80703 8.60768 7.8737 8.65768 7.9237 8.70768C7.93203 8.71602 7.94036 8.71602 7.94036 8.72435L9.60703 10.391C9.8487 10.6327 9.8487 11.0327 9.60703 11.2743Z" fill="black" />
                              <path d="M14.526 7.34207C15.3177 7.3504 16.4177 7.3504 17.3594 7.3504C17.8344 7.3504 18.0844 6.79207 17.751 6.45873C16.551 5.2504 14.401 3.0754 13.1677 1.84207C12.826 1.5004 12.2344 1.73373 12.2344 2.20873V5.11707C12.2344 6.33373 13.2677 7.34207 14.526 7.34207Z" fill="black" />
                            </svg>
                      </div>

                    </label>
                  </td>
                  <td>
                    <input type="text"
                      value={setData.fileName}
                      name="fileName"
                      onChange={(e) => setSetData({ ...setData, fileName: e.target.value })}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

}

