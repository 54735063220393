import React, { useEffect, useState } from "react";
import styles from "../Components/css/projectlistingpage.module.css";
// import plus from "../Components/plus.png";
import { IoMdAddCircleOutline } from "react-icons/io";
import sort from "../Components/sort.png";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Stepper from "../Components/Projectreport";
import HorizontalLinearStepper from "../Components/Projectreport";
import { Button, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const ProjectListingPage = () => {
  const [checklist, setChecklist] = useState([]);
  const [listMode, setListMode] = useState(true);
  const [featurecn, setfeaturecn] = useState([]);
  const [featureedit, setfeatureedit] = useState([]);

  const [projectList, setProjectList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filterContainer, setFilterContainer] = useState(false);
  const [profdetails, setprofdetails] = useState([]);

  const [filterItems, setFilterItems] = useState({
    scope: false,
    location: false,
    team: false,
    pono: false,
    status: false,
  });

  const [scopeSearch, setScopeSearch] = useState("");
  const [scopeOptions, setScopeOptions] = useState([
    "Jacking System Inspection",
    "Jacking System Remediation",
    "Jacking System Overhaul",
    "Crane System Inspection",
    "Crane Condition Evaluation",
    "Crane System Remediation",
    "Crane System Overhaul",
    "Fixation System Inspection",
    "Fixation System Remediation",
    "Fixation system Overhaul",
    "Skidding System Inspection",
    "Skidding System Remediation",
    "Skidding System Overhaul",
    "Drilling Equipment Inspection",                            
    "Drilling Equipment Remediation",
    "Braking System Inspection",
    "Braking System Remediation",
    "Anchor Winch Inspection",
    "Supply Of Mechanics",
    "Supply Of Supervisors",
    "Supply Of Electricians"
]);

const handleScopeSearchChange = (e) => {
  setScopeSearch(e.target.value);
};


  const saveButtonClose = (e) => {
    e.preventDefault();
    const updatedFilterItems = {};
    for (const key in filterItems) {
      updatedFilterItems[key] = false;
    }
    setFilterItems(updatedFilterItems);
    e.stopPropagation();
  };

  useEffect(() => {
    const fetchprof = async() => {
      const res = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details");
      const fileert=  res.data.filter((el) => {
        return el.Email=== localStorage.getItem("useremail");
      })
    setprofdetails(fileert)
    }
    fetchprof();
  },[])

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        const filgroupfeatures = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "offshore projects" && el.Feature_Name === "Add New" && (el.Access === "Can View" )
          );
        });

        const filgroupfeatures2 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "offshore projects" && el.Feature_Name === "filter" && (el.Access === "Can View" )
          );
        });

        setfeaturecn(filgroupfeatures);
        setfeatureedit(filgroupfeatures2);
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);



  const [countryName, setCountryName] = useState([]);

  const handleFilterItemsClick = (e) => {
    const name = e.currentTarget.getAttribute("name");

    const updatedFilterItems = {};
    for (const key in filterItems) {
      updatedFilterItems[key] = key === name;
    }

    setFilterItems(updatedFilterItems);
  };

  const [advancedFilter, setAdvancedFilter] = useState({});
  const [scopeFilter, setScopeFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [teamFilter, setTeamFilter] = useState([]);
  const [ponoFilter, setPonoFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  const [storeFilter, setStoreFilter] = useState([]);

  const handleChange1 = (e) => {
    const { name, checked, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: checked ? value : "",
    });

    setScopeFilter({
      ...scopeFilter,
      [name]: checked ? value : "",
    });
  };

  const handleChange2 = (e) => {
    const { name, checked, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: checked ? value : "",
    });

    setLocationFilter({
      ...locationFilter,
      [name]: checked ? value : "",
    });
  };



  const handleChange3 = (e) => {
    const { name, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: value,
    });

    setTeamFilter({
      ...teamFilter,
      [name]: value,
    });
  };

  const handleChange4 = (e) => {
    const { name, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: value,
    });

    setPonoFilter({
      ...ponoFilter,
      [name]: value,
    });
  };

  const handleChange5 = (e) => {
    const { name, checked, value } = e.target;

    setAdvancedFilter({
      ...advancedFilter,
      [name]: checked ? value : "",
    });

    setStatusFilter({
      ...statusFilter,
      [name]: checked ? value : "",
    });
  };

  const [newArr1, setNewArr1] = useState([]);
  const [newArr2, setNewArr2] = useState([]);
  const [newArr3, setNewArr3] = useState([]);
  const [newArr4, setNewArr4] = useState([]);
  const [newArr5, setNewArr5] = useState([]);

  const applyFilter = () => {
    let values1 = Object.values(scopeFilter);
    let filter1 = values1.filter((el) => el);

    let values2 = Object.values(locationFilter);
    let filter2 = values2.filter((el) => el);

    let values3 = Object.values(teamFilter);
    let filter3 = values3.filter((el) => el);

    let values4 = Object.values(ponoFilter);
    let filter4 = values4.filter((el) => el);

    let values5 = Object.values(statusFilter);
    let filter5 = values5.filter((el) => el);

    setNewArr1(filter1);
    setNewArr2(filter2);
    setNewArr3(filter3);
    setNewArr4(filter4);
    setNewArr5(filter5);

    setStoreFilter([
      ...filter1,
      ...filter2,
      ...filter3,
      ...filter4,
      ...filter5,
    ]);
    setFilterContainer(false);
  };

  const removeFilter = async (e, index, name) => {
    e.preventDefault();
    const updatedStoreFilter = [...storeFilter];

    updatedStoreFilter?.splice(index, 1);

    setScopeFilter((prev) => {
      const update = { ...prev };
      delete update[name];
      return update;
    });
    setLocationFilter((prev) => {
      const update = { ...prev };
      delete update[name];
      return update;
    });
    setTeamFilter((prev) => {
      const update = { ...prev };
      delete update[name];
      return update;
    });
    setPonoFilter((prev) => {
      const update = { ...prev };
      delete update[name];
      return update;
    });
    setStatusFilter((prev) => {
      const update = { ...prev };
      delete update[name];
      return update;
    });

    let values1 = Object.values(scopeFilter);
    let values2 = Object.values(locationFilter);
    let values3 = Object.values(teamFilter);
    let values4 = Object.values(ponoFilter);
    let values5 = Object.values(statusFilter);

    let first = values1.filter((elm) => elm !== name);
    let second = values2.filter((elm) => elm !== name);
    let third = values3.filter((elm) => elm !== name);
    let fourth = values4.filter((elm) => elm !== name);
    let fifth = values5.filter((elm) => elm !== name);

    setStoreFilter([...first, ...second, ...third, ...fourth, ...fifth]);
    setNewArr1(first);
    setNewArr2(second);
    setNewArr3(third);
    setNewArr4(fourth);
    setNewArr5(fifth);

    let radioName;

    switch (name) {
      case name:
        radioName = name;
        break;
    }

    setAdvancedFilter((prev) => {
      const updateRadio = { ...prev };
      delete updateRadio[radioName];
      return updateRadio;
    });
  };

  useEffect(() => {
    scopeFilterFunc();
  }, [newArr1, newArr2, newArr3, newArr4, newArr5, storeFilter]);

  const scopeFilterFunc = async () => {
    const filter = projectList.filter((el) => {
      if (newArr1.length === 0) {
        return el;
      }
      return newArr1.some((it) => {
        const serviceComponent = el.Service_Component;

        switch (it) {
          case it:
            return !it || (serviceComponent && serviceComponent === it);
        }
      });
    });

    locationFunctionFilter(filter);
  };

  const locationFunctionFilter = (data) => {
    const filter = data.filter((el) => {
      if (newArr2.length === 0) {
        return el;
      }
      return newArr2.some((it, idx) => {
        const location = el.Rig_Location;

        switch (it) {
          case it:
            return !it || (location && location === it);
        }
      });
    });
    teamFunctionFilter(filter);
  };

  const teamFunctionFilter = (data) => {
    const filter = data.filter((el) => {
      if (newArr3.length === 0) {
        return el;
      }
      return newArr3.some((it) => {
        const team = el.Team_Size;

        switch (it) {
          case "Team = 0":
            return !it || (team && team === "0");
          case "Team > 0":
            return !it || (team && team > "0");
        }
      });
    });
    ponoFunctionFilter(filter);
  };

  const ponoFunctionFilter = (data) => {
    const filter = data.filter((el) => {
      if (newArr4.length === 0) {
        return el;
      }
      return newArr4.some((it) => {
        const pono = el.Po_No;

        switch (it) {
          case "PO No = 0":
            return !it || pono === "0" || pono === "" || pono === null;
          case "PO No > 0":
            return !it || (pono && pono > "0");
        }
      });
    });
    statusFunctionFilter(filter);
  };
  const statusFunctionFilter = (data) => {
    const filter = data.filter((el) => {
      if (newArr5.length === 0) {
        return el;
      }
      return newArr5.some((it) => {
        let day;
        let month;
        let year;

        let dayCompletion;
        let monthCompletion;
        let yearCompletion;

        if (el.Estimated_Date_Of_Commencement) {
          let parts = el.Estimated_Date_Of_Commencement.split("/");
          day = parseInt(parts[0]);
          month = parseInt(parts[1]) - 1;
          year = parseInt(parts[2]);
        }

        if (el.Estimated_Project_Completion_Month) {
          let splitCompletion =
            el.Estimated_Project_Completion_Month.split("/");
          dayCompletion = parseInt(splitCompletion[0]);
          monthCompletion = parseInt(splitCompletion[1]) - 1;
          yearCompletion = parseInt(splitCompletion[2]);
        }

        let presentDate = new Date();
        let estimatedDate = new Date(year, month, day);
        let completionDate = new Date(
          yearCompletion,
          monthCompletion,
          dayCompletion
        );

        let count = 0;

        for (let i = 1; i <= 40; i++) {
          const propertyName = "Item" + i;
          if (el[propertyName] === "Completed") {
            count++;
          }
        }

        const draftStatus =
          el.Estimated_Date_Of_Commencement;

        const pausedStatus = el.Paused_Status;
        const upcomingStatus =
          draftStatus &&
          el.Paused_Status !== "1" &&
          estimatedDate > presentDate;

        const ongoingStatus =
          draftStatus &&
          el.Paused_Status !== "1" &&
          estimatedDate < presentDate &&
          presentDate < completionDate;

        const completedStatus =
          draftStatus &&
          el.Paused_Status !== "1" &&
          completionDate < presentDate &&
          count === 40;
        const demobbedStatus =
          draftStatus &&
          el.Paused_Status !== "1" &&
          completionDate < presentDate &&
          count !== 40;

        switch (it) {
          case "Paused":
            return !it || (pausedStatus && pausedStatus === "1");
          case "Upcoming":
            return !it || upcomingStatus;
          case "Ongoing":
            return !it || ongoingStatus;
          case "Demobbed":
            return !it || demobbedStatus;
          case "Completed":
            return !it || completedStatus;
          case "Draft":
            return !it || (!draftStatus && el.Paused_Status !== "1");
        }
      });
    });
    setFilteredProjects(filter);
  };

  const navigate = useNavigate();

  const fetchProject = async () => {
    const res = await axios.get(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
    );

    const response = await axios.get(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/legal_details"
    );

    const getCountryName = [
      ...new Set(response.data.map((el) => el.country)),
    ].filter(Boolean);

    setCountryName(getCountryName);

    const result = res.data.data.reverse();

     const uniqueData = [];
    const checkDuplicates = new Set();
   
    result.forEach((item) => {
     if(!checkDuplicates.has(item.ID_No)) {
      checkDuplicates.add(item.ID_No);
      uniqueData.push(item)
     }
    });

    setProjectList(uniqueData);
    setFilteredProjects(uniqueData);
  };

  useEffect(() => {
    fetchProject();
  }, []);

  const draftClick = (id) => {
    navigate(`/createnew?id=${id}`);
  };

  useEffect(() => {
    const fetchPausedStatus = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project-checklistgetdetails"
      );
      setChecklist(res.data.data);
    };

    fetchPausedStatus();
  }, []);

  const handleSearchInputChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    const filtered = projectList.filter((project) => {
      const soNo = project.Sales_Order_No.toString();
      const poNo = project.Po_No;
      const rigName = project.Rig_Name;

      if (
        typeof soNo === "string" &&
        soNo.toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }

      if (
        typeof poNo === "string" &&
        poNo.toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }

      if (
        typeof rigName === "string" &&
        rigName.toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }

      return false;
    });

    setFilteredProjects(filtered);
  };

  const openFilterContainer = (e) => {
    e.preventDefault();
    setFilterContainer(!filterContainer);
    setFilterItems({
      scope: false,
      location: false,
      team: false,
      pono: false,
      status: false,
    });
  };

  return (
    <>
      <div className={styles.headingOfProjectListing}>
        <div className={styles.searchbar}>
          <input
            className={styles.inputtext}
            type="text"
            placeholder=" Search by SO No,PO No, Rig Short Name"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>

        <div className={styles.filterContainer}>
         {featureedit.length >0 && ( <Button
            onClick={openFilterContainer}
            variant="contained"
            className={styles["btn-filter"]}>
            Filter
            <svg
              style={{ marginLeft: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M13.491 1.66699H6.50768C3.47435 1.66699 1.66602 3.47533 1.66602 6.50866V13.4837C1.66602 16.5253 3.47435 18.3337 6.50768 18.3337H13.4827C16.516 18.3337 18.3243 16.5253 18.3243 13.492V6.50866C18.3327 3.47533 16.5243 1.66699 13.491 1.66699ZM11.1077 14.167H8.88268C8.54102 14.167 8.25768 13.8837 8.25768 13.542C8.25768 13.2003 8.54102 12.917 8.88268 12.917H11.1077C11.4493 12.917 11.7327 13.2003 11.7327 13.542C11.7327 13.8837 11.4577 14.167 11.1077 14.167ZM13.3327 10.6253H6.66602C6.32435 10.6253 6.04102 10.342 6.04102 10.0003C6.04102 9.65866 6.32435 9.37533 6.66602 9.37533H13.3327C13.6743 9.37533 13.9577 9.65866 13.9577 10.0003C13.9577 10.342 13.6743 10.6253 13.3327 10.6253ZM14.9993 7.08366H4.99935C4.65768 7.08366 4.37435 6.80033 4.37435 6.45866C4.37435 6.11699 4.65768 5.83366 4.99935 5.83366H14.9993C15.341 5.83366 15.6243 6.11699 15.6243 6.45866C15.6243 6.80033 15.341 7.08366 14.9993 7.08366Z"
                fill="#292D32"
              />
            </svg>
          </Button>)}
          {filterContainer && (
            <div className={styles.optionContainer}>
              <div
                name="scope"
                onClick={handleFilterItemsClick}
                className={
                  filterItems.scope
                    ? `${styles.optionsList} ${styles.activeOption}`
                    : styles.optionsList
                }>
                <h5>Scope</h5>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {filterItems.scope && (
                  <div className={styles.filterDropdown}>
                      <input
                      type="text"
                      placeholder="Type to search..."
                      value={scopeSearch}
                      onChange={handleScopeSearchChange}
                      className={styles.filterSearch}
                    />
                    <div className={styles.filterOptions}>
                    {scopeOptions
                .filter(option => option.toLowerCase().includes(scopeSearch.toLowerCase()))
                .map((option, index) => (
                    <div key={index} className={styles.filterOption}>
                        <label>
                      <input
                        type="checkbox"
                                name={option}
                                value={option}
                                checked={scopeFilter[option] === option}
                        onChange={handleChange1}
                      />
                            {option}
                        </label>
                    </div>
                ))}
                    </div>

                    <div className={styles.applyContainer}>
                      <Button
                        onClick={saveButtonClose}
                        className={styles.applyBtn}
                        variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div
                name="location"
                onClick={handleFilterItemsClick}
                className={
                  filterItems.location
                    ? `${styles.optionsList} ${styles.activeOption}`
                    : styles.optionsList
                }>
                <h5>Location</h5>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {filterItems.location && (
                  <div className={styles.itemsContainer}>
                    {countryName?.map((el, index) => (
                      <div className={styles.optionsList}>
                        <h5>{el}</h5>
                        <input
                          type="checkbox"
                          name={el}
                          value={el}
                          checked={advancedFilter[`${el}`] === el}
                          onChange={handleChange2}
                        />
                      </div>
                    ))}

                    <div className={styles.applyContainer}>
                      <Button
                        onClick={saveButtonClose}
                        className={styles.applyBtn}
                        variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div
                name="team"
                onClick={handleFilterItemsClick}
                className={
                  filterItems.team
                    ? `${styles.optionsList} ${styles.activeOption}`
                    : styles.optionsList
                }>
                <h5>Team</h5>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {filterItems.team && (
                  <div className={styles.itemsContainer}>
                    <div className={styles.teamOption2}>
                      <h5>No Tech Selected</h5>
                      <input
                        type="radio"
                        name="Team = 0"
                        value="Team = 0"
                        checked={advancedFilter["Team = 0"] === "Team = 0"}
                        onChange={handleChange3}
                      />
                    </div>
                    <div className={styles.teamOption}>
                      <h5>Techs Selected</h5>
                      <input
                        type="radio"
                        name="Team > 0"
                        value="Team > 0"
                        checked={advancedFilter["Team > 0"] === "Team > 0"}
                        onChange={handleChange3}
                      />
                    </div>

                    <div className={styles.applyContainer}>
                      <Button
                        onClick={saveButtonClose}
                        className={styles.applyBtn}
                        variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div
                name="pono"
                onClick={handleFilterItemsClick}
                className={
                  filterItems.pono
                    ? `${styles.optionsList} ${styles.activeOption}`
                    : styles.optionsList
                }>
                <h5>PO</h5>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {filterItems.pono && (
                  <div className={styles.itemsContainer}>
                    <div className={styles.teamOption3}>
                      <h5>No PO Exists</h5>
                      <input
                        type="radio"
                        name="PO No = 0"
                        value="PO No = 0"
                        checked={advancedFilter["PO No = 0"] === "PO No = 0"}
                        onChange={handleChange4}
                      />
                    </div>
                    <div className={styles.teamOption4}>
                      <h5>PO Exists</h5>
                      <input
                        type="radio"
                        name="PO No > 0"
                        value="PO No > 0"
                        checked={advancedFilter["PO No > 0"] === "PO No > 0"}
                        onChange={handleChange4}
                      />
                    </div>

                    <div className={styles.applyContainer}>
                      <Button
                        onClick={saveButtonClose}
                        className={styles.applyBtn}
                        variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div
                name="status"
                onClick={handleFilterItemsClick}
                className={
                  filterItems.status
                    ? `${styles.optionsList} ${styles.activeOption}`
                    : styles.optionsList
                }>
                <h5>Status</h5>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {filterItems.status && (
                  <div className={styles.itemsContainer}>
                    <div className={styles.optionsList}>
                      <h5>Upcoming</h5>
                      <input
                        type="checkbox"
                        name="Upcoming"
                        value="Upcoming"
                        checked={advancedFilter["Upcoming"] === "Upcoming"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Ongoing</h5>
                      <input
                        type="checkbox"
                        name="Ongoing"
                        value="Ongoing"
                        checked={advancedFilter["Ongoing"] === "Ongoing"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Demobbed</h5>
                      <input
                        type="checkbox"
                        name="Demobbed"
                        value="Demobbed"
                        checked={advancedFilter["Demobbed"] === "Demobbed"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Draft</h5>
                      <input
                        type="checkbox"
                        name="Draft"
                        value="Draft"
                        checked={advancedFilter["Draft"] === "Draft"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Paused</h5>
                      <input
                        type="checkbox"
                        name="Paused"
                        value="Paused"
                        checked={advancedFilter["Paused"] === "Paused"}
                        onChange={handleChange5}
                      />
                    </div>
                    <div className={styles.optionsList}>
                      <h5>Completed</h5>
                      <input
                        type="checkbox"
                        name="Completed"
                        value="Completed"
                        checked={advancedFilter["Completed"] === "Completed"}
                        onChange={handleChange5}
                      />
                    </div>

                    <div className={styles.applyContainer}>
                      <Button
                        onClick={saveButtonClose}
                        className={styles.applyBtn}
                        variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.applyContainer}>
                <Button
                  onClick={applyFilter}
                  variant="contained"
                  className={styles.applyBtn}>
                  Apply
                </Button>
              </div>
            </div>
          )}
        </div>

        {/* <>
          {listMode ? (
            <div className={styles["list-chart"]}>
              <Button
                variant="contained"
                className={styles.dbbtnActive}
                onClick={() => setListMode(true)}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                }}>
                List
              </Button>
              <Button
                variant="contained"
                className={styles.dbbtn}
                onClick={() => setListMode(false)}
                style={{
                  borderRadius: "0px 8px 8px 0px",
                }}>
                Chart
              </Button>
            </div>
          ) : (
            <div className={styles["list-chart"]}>
              <Button
                variant="contained"
                className={styles.dbbtn}
                onClick={() => setListMode(true)}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                }}>
                List
              </Button>
              <Button
                variant="contained"
                className={styles.dbbtnActive}
                onClick={() => setListMode(false)}
                style={{ borderRadius: "0px 8px 8px 0px" }}>
                Chart
              </Button>
            </div>
          )}
        </> */}

        <NavLink to="/createnew">
          {featurecn.length>0 &&(<Button variant="contained" className={styles["btn-Createnew"]}>
            Create New
            <svg
              style={{ marginLeft: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-plus-square-fill"
              viewBox="0 0 16 16">
              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
            </svg>
          </Button>)}
        </NavLink>
      </div>

      {storeFilter.length !== 0 && (
        <div className={styles.projectList} style={{ padding: "10px 0" }}>
          <div className={styles.filterContainer}>
            <div className={styles.svgContainer}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM13.33 17H10.66C10.25 17 9.91 16.66 9.91 16.25C9.91 15.84 10.25 15.5 10.66 15.5H13.33C13.74 15.5 14.08 15.84 14.08 16.25C14.08 16.66 13.75 17 13.33 17ZM16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75ZM18 8.5H6C5.59 8.5 5.25 8.16 5.25 7.75C5.25 7.34 5.59 7 6 7H18C18.41 7 18.75 7.34 18.75 7.75C18.75 8.16 18.41 8.5 18 8.5Z"
                  fill="#292D32"
                />
              </svg>
            </div>
            {storeFilter.map((el, index) => (
              <div key={index} className={styles.fieldsContainer}>
                <h5>{el}</h5>
                <IconButton
                  onClick={(e) => removeFilter(e, index, el)}
                  className={styles.iconBtn}>
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}

      {localStorage.getItem("Group") !== "Supervisor" && localStorage.getItem("Group") !== "Technician" && localStorage.getItem("Group") !== "Trainee"  ? (filteredProjects.map((el) => {
        let presentDate = new Date();

        let day;
        let month;
        let year;
        if (el.Estimated_Date_Of_Commencement) {
          let parts = el.Estimated_Date_Of_Commencement.split("/");
          day = parseInt(parts[0]);
          month = parseInt(parts[1]) - 1;
          year = parseInt(parts[2]);
        }

        let estimatedDate = new Date(year, month, day);

        let dayCompletion;
        let monthCompletion;
        let yearCompletion;
        if (el.Estimated_Project_Completion_Month) {
          let splitCompletion =
            el.Estimated_Project_Completion_Month.split("/");
          dayCompletion = parseInt(splitCompletion[0]);
          monthCompletion = parseInt(splitCompletion[1]) - 1;
          yearCompletion = parseInt(splitCompletion[2]);
        }

        let completionDate = new Date(
          yearCompletion,
          monthCompletion,
          dayCompletion
        );

        let difference = presentDate.getTime() - estimatedDate.getTime();

        let inDays = Math.round(difference / (24 * 60 * 60 * 1000));

        const paused = checklist.filter(
          (elm) => elm.ID_No === el.ID_No
        );

        let count = 0;
        for (let i = 1; i <= 40; i++) {
          const propertyName = "Item" + i;
          if (paused[0]?.[propertyName] === "Completed") {
            count++;
          }
        }

        return (
          <>
            <div className={styles.projectList} key={el.ID_No}>
            {paused[0]?.Paused_Status === "1" ? (
                <div className={styles.statusButton}>
                  <Button className={styles.pausedButton} variant="contained">
                    Paused
                  </Button>
                </div>
              ) : (
                <>
                  {el.Estimated_Date_Of_Commencement ? (
                    <>
                      {estimatedDate > presentDate ? (
                        <div className={styles.statusButton}>
                        <Button
                          className={styles.upcomingButton}
                          variant="contained">
                          Upcoming
                        </Button>
                      </div>
                      ):(
                        <>
                          {estimatedDate < presentDate && count === 40 ? (
                            <div className={styles.statusButton}>
                            <Button
                              className={styles.completedButton}
                              variant="contained">
                              Completed
                            </Button>
                          </div>
                          ):(
                            <>
                              { estimatedDate < presentDate && paused[0]?.Item32 == "Completed" ? (
                                <div className={styles.statusButton}>
                                <Button
                                  className={styles.demobbedButton}
                                  variant="contained">
                                  Demobbed
                                </Button>
                              </div>
                              ) : (
                                <div className={styles.statusButton}>
                                    <Button
                                      className={styles.ongoingButton}
                                      variant="contained">
                                      Ongoing
                                    </Button>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ):(
                    <div className={styles.statusButton}>
                      <Button
                        className={styles.draftButton}
                        onClick={() => draftClick(el.ID_No)}
                        variant="contained">
                        Draft
                      </Button>
                    </div>
                  )}
                </>
              )}

              <NavLink
                to={"/project_checklist/" + el.ID_No}
                className={styles.link}>
                <div className={styles.top}>
                  <h6>
                    SO <span>{el.Sales_Order_No ? el.Sales_Order_No : "" }</span>
                  </h6>
                  <h6>|</h6>
                  <h6>
                    PO : <span>{el.Po_No}</span>
                  </h6>
                </div>

                <div className={styles.content}>
                  <div style={{ width: "100px" }}>{el.Rig_Name}</div>
                  <div style={{ width: "100px" }}>{el.Rig_Location}</div>
                  <div style={{ width: "300px" }}>{el.Service_Component}</div>

                  <div style={{ width: "78px" }}>
                    <span style={{ color: "#606060" }}>Team:</span>{" "}
                    {el.Team_Size}
                  </div>
                  {((estimatedDate < presentDate &&
                    presentDate < completionDate) ||
                    presentDate > completionDate) && (
                    <div style={{ width: "130px" }}>
                      <span style={{ color: "#606060" }}>Days:</span> {inDays}/
                      {el.Po_Days}
                    </div>
                  )}
                  {(estimatedDate < presentDate &&
                    presentDate < completionDate) || 
                    (paused[0]?.Item32 === "Completed") && (
                      <div>
                        <span style={{ color: "#606060" }}>
                          Date of Completion:
                        </span>
                        {el.Estimated_Project_Completion_Month}
                      </div>
                    )}
                  {((estimatedDate > presentDate) || (estimatedDate < presentDate && presentDate < completionDate)) && (
                    <div>
                      <span style={{ color: "#606060" }}>
                        Date of commencement: 
                      </span>
                      {el.Estimated_Date_Of_Commencement}
                    </div>
                  )}
                </div>
              </NavLink>
            </div>
          </>
        );
      })):(
        filteredProjects.map((el) => {
          if(el.Supervisor_Names?.split(",").includes(profdetails[0]?.Customer_Name) || el.Technician_Names?.split(",").includes(profdetails[0]?.Customer_Name)  )
          {
              let presentDate = new Date();
      
              let day;
              let month;
              let year;
              if (el.Estimated_Date_Of_Commencement) {
                let parts = el.Estimated_Date_Of_Commencement.split("/");
                day = parseInt(parts[0]);
                month = parseInt(parts[1]) - 1;
                year = parseInt(parts[2]);
              }
      
              let estimatedDate = new Date(year, month, day);
      
              let dayCompletion;
              let monthCompletion;
              let yearCompletion;
              if (el.Estimated_Project_Completion_Month) {
                let splitCompletion =
                  el.Estimated_Project_Completion_Month.split("/");
                dayCompletion = parseInt(splitCompletion[0]);
                monthCompletion = parseInt(splitCompletion[1]) - 1;
                yearCompletion = parseInt(splitCompletion[2]);
              }
      
              let completionDate = new Date(
                yearCompletion,
                monthCompletion,
                dayCompletion
              );
      
              let difference = presentDate.getTime() - estimatedDate.getTime();
      
              let inDays = Math.round(difference / (24 * 60 * 60 * 1000));
      
              const paused = checklist.filter(
                (elm) => elm.ID_No === el.ID_No
              );
      
              let count = 0;
              for (let i = 1; i <= 40; i++) {
                const propertyName = "Item" + i;
                if (paused[0]?.[propertyName] === "Completed") {
                  count++;
                }
              }
      
              return (
                <>
                  <div className={styles.projectList} key={el.ID_No}>
                  {paused[0]?.Paused_Status === "1" ? (
                      <div className={styles.statusButton}>
                        <Button className={styles.pausedButton} variant="contained">
                          Paused
                        </Button>
                      </div>
                    ) : (
                      <>
                        {el.Estimated_Date_Of_Commencement ? (
                          <>
                            {estimatedDate > presentDate ? (
                              <div className={styles.statusButton}>
                              <Button
                                className={styles.upcomingButton}
                                variant="contained">
                                Upcoming
                              </Button>
                            </div>
                            ):(
                              <>
                                {estimatedDate < presentDate && count === 40 ? (
                                  <div className={styles.statusButton}>
                                  <Button
                                    className={styles.completedButton}
                                    variant="contained">
                                    Completed
                                  </Button>
                                </div>
                                ):(
                                  <>
                                    { estimatedDate < presentDate && paused[0]?.Item32 == "Completed" ? (
                                      <div className={styles.statusButton}>
                                      <Button
                                        className={styles.demobbedButton}
                                        variant="contained">
                                        Demobbed
                                      </Button>
                                    </div>
                                    ) : (
                                      <div className={styles.statusButton}>
                                        
                                          <Button
                                            className={styles.ongoingButton}
                                            variant="contained">
                                            Ongoing
                                          </Button>
                                    
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ):(
                          <div className={styles.statusButton}>
                            <Button
                              className={styles.draftButton}
                              onClick={() => draftClick(el.ID_No)}
                              variant="contained">
                              Draft
                            </Button>
                          </div>
                        )}
                      </>
                    )}
      
                    <NavLink
                      to={"/project_checklist/" + el.ID_No}
                      className={styles.link}>
                      <div className={styles.top}>
                        <h6>
                          SO <span>{el.Sales_Order_No ? el.Sales_Order_No : "" }</span>
                        </h6>
                        <h6>|</h6>
                        <h6>
                          PO : <span>{el.Po_No}</span>
                        </h6>
                      </div>
      
                      <div className={styles.content}>
                        <div style={{ width: "100px" }}>{el.Rig_Name}</div>
                        <div style={{ width: "100px" }}>{el.Rig_Location}</div>
                        <div style={{ width: "300px" }}>{el.Service_Component}</div>
      
                        <div style={{ width: "78px" }}>
                          <span style={{ color: "#606060" }}>Team:</span>{" "}
                          {el.Team_Size}
                        </div>
                        {((estimatedDate < presentDate &&
                          presentDate < completionDate) ||
                          presentDate > completionDate) && (
                          <div style={{ width: "130px" }}>
                            <span style={{ color: "#606060" }}>Days:</span> {inDays}/
                            {el.Po_Days}
                          </div>
                        )}
                        {estimatedDate < presentDate &&
                          presentDate < completionDate && (
                            <div>
                              <span style={{ color: "#606060" }}>
                                Date of Completion:
                              </span>
                              {el.Estimated_Project_Completion_Month}
                            </div>
                          )}
                        {estimatedDate > presentDate && (
                          <div>
                            <span style={{ color: "#606060" }}>
                              Date of commencement: 
                            </span>
                            {el.Estimated_Date_Of_Commencement}
                          </div>
                        )}
                      </div>
                    </NavLink>
                  </div>
                </>
              );
            }
        }
      ))}
    </>
  );
};

export default ProjectListingPage;