import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Components/css/toolsregister.css";
import "../Components/css/adding.css";
import EditToolsComponent from "../Components/EditTools";
import styles from "../Components/css/rigdetails.module.css";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const ToolsRegister = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedRig, setSelectedRig] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [featurecn, setfeaturecn] = useState([]);
  const [featureedit, setfeatureedit] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const [formData, setFormData] = useState({
   
    Item_No: "",
    Description: "",
    Manufacturer: "",
    Model: "",
    Serial_No: "",
    Cal_Date: "",
    Due_Date: "",
    Range_Value: "",
    Nominal_Value: "",
    Measured_Value: "",
    Acceptance_Criteria: "",
    Frequency: "",
    Cert_No: "",
    Status: "",
    Remarks: "",
  });
  //adding button functionality
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      s_no: formData.length + 1,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      // Id_No:"",
      Item_No,
      Description,
      Manufacturer,
      Model,
      Serial_No,
      Cal_Date,
      Due_Date,
      Range_Value,
      Nominal_Value,
      Measured_Value,
      Acceptance_Criteria,
      Frequency,
      Cert_No,
      Status,
      Remarks,
    } = formData

    const payload = {
      Item_No:Item_No,
      Description:Description.trim(),
      Manufacturer: Manufacturer,
      Model:Model,
      Serial_No:Serial_No,
      Cal_Date:Cal_Date,
      Due_Date:Due_Date,
      Range_Value:Range_Value,
      Nominal_Value:Nominal_Value,
      Measured_Value:Measured_Value,
      Acceptance_Criteria:Acceptance_Criteria,
      Frequency:Frequency,
      Cert_No:Cert_No,
      Status:Status,
      Remarks:Remarks,

    }
    const res = await axios.post(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolsregister",
      payload
    );
    setShowForm(false);
    setFormData({
      // Id_No:"",
      Item_No: "",
      Description: "",
      Manufacturer: "",
      Model: "",
      Serial_No: "",
      Cal_Date: "",
      Due_Date: "",
      Range_Value: "",
      Nominal_Value: "",
      Measured_Value: "",
      Acceptance_Criteria: "",
      Frequency: "",
      Cert_No: "",
      Status: "",
      Remarks: "",
    });
    if (res.status === 200) {
      alert("Tools Details Added Successfully");
      setRefresh(!refresh);
    }



    // fetch("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/post3", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(formData),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setShowForm(false);
    //     console.log(data);
    //     setFormData({
    //       Item_No: "",
    //       Description: "",
    //       Manufacturer: "",
    //       Model: "",
    //       Serial_No: "",
    //       Cal_Date: "",
    //       Due_Date: "",
    //       Range_Value: "",
    //       Nominal_Value: "",
    //       Measured_Value: "",
    //       Acceptance_Criteria: "",
    //       Frequency: "",
    //       Cert_No: "",
    //       Status: "",
    //       Remarks: "",
    //     });
    //   })
    //   .catch((error) => console.log(error));
  };

  useEffect(() => {
    async function fetchToolsRegister() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/gettools_register"
        );
        console.log("$$$$$$$$$$$$",response.data);
        setSearchResults(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchToolsRegister();
  }, [filteredResults,refresh]);

  const handleListClick = () => {
    // Render a list view of the rigs
  };

  const handleMapViewClick = () => {
    // Render a map view of the rigs
  };

  const handleHandbookClick = () => {
    // Render a handbook of the rigs
  };

  const handleDetailsClick = (rig) => {
    console.log(rig);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const results = searchResults.filter((item) =>
      item.Item_No.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredResults(results);
  }, [searchTerm, searchResults]);

  const handleStopClose = (e) => {
    e.stopPropagation();
  };

  const handleEditClick = (rig) => {
    setSelectedRig(rig);
    setShowForm(true);
  };
  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        const filgroupfeatures = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Tools Register" && el.Feature_Name === "Create New" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        const filgroupfeatures2 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Tools Register" && el.Feature_Name === "Edit" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        setfeaturecn(filgroupfeatures);
        setfeatureedit(filgroupfeatures2);
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  //edition functionality
  const [enableEdit, setEnableEdit] = useState(false);

  return (
    <div className="toolsre">
      <div className="search-container-Box">
        <h1 className="tools-register-heading">Tools Details</h1>
        <div className="toolssearchbox">
          <input
            type="text"
            placeholder="Search by Item No."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div btn-group>
        {!enableEdit && (
          (featurecn.length > 0 && <button
            className="addnewbuttontool"
            onClick={() => setShowForm(true)}>
            Create New
            <svg
              width="30"
              height="20"
              viewBox="-5 0 30 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.493 1.66663H6.50964C3.4763 1.66663 1.66797 3.47496 1.66797 6.50829V13.4833C1.66797 16.525 3.4763 18.3333 6.50964 18.3333H13.4846C16.518 18.3333 18.3263 16.525 18.3263 13.4916V6.50829C18.3346 3.47496 16.5263 1.66663 13.493 1.66663ZM15.0013 10.625H10.6263V15C10.6263 15.3416 10.343 15.625 10.0013 15.625C9.65964 15.625 9.3763 15.3416 9.3763 15V10.625H5.0013C4.65964 10.625 4.3763 10.3416 4.3763 9.99996C4.3763 9.65829 4.65964 9.37496 5.0013 9.37496H9.3763V4.99996C9.3763 4.65829 9.65964 4.37496 10.0013 4.37496C10.343 4.37496 10.6263 4.65829 10.6263 4.99996V9.37496H15.0013C15.343 9.37496 15.6263 9.65829 15.6263 9.99996C15.6263 10.3416 15.343 10.625 15.0013 10.625Z"
                fill="white"
              />
            </svg>
          </button>)
        )}
        {!enableEdit ? (
          featureedit.length > 0 && ( <button
            className="editbuttontool"
            onClick={() => setEnableEdit(!enableEdit)}>
            Edit
            <svg
              width="35"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                fill="black"
              />
              <path
                d="M15.8495 2.90005C14.2328 1.28338 12.6495 1.24172 10.9912 2.90005L9.98283 3.90838C9.89949 3.99172 9.86616 4.12505 9.89949 4.24172C10.5328 6.45005 12.2995 8.21672 14.5078 8.85005C14.5412 8.85838 14.5745 8.86672 14.6078 8.86672C14.6995 8.86672 14.7828 8.83338 14.8495 8.76672L15.8495 7.75838C16.6745 6.94172 17.0745 6.15005 17.0745 5.35005C17.0828 4.52505 16.6828 3.72505 15.8495 2.90005Z"
                fill="black"
              />
              <path
                d="M13.007 9.60829C12.7653 9.49163 12.532 9.37496 12.307 9.24163C12.1236 9.13329 11.9486 9.01663 11.7736 8.89163C11.632 8.79996 11.4653 8.66663 11.307 8.53329C11.2903 8.52496 11.232 8.47496 11.1653 8.40829C10.8903 8.17496 10.582 7.87496 10.307 7.54163C10.282 7.52496 10.2403 7.46663 10.182 7.39163C10.0986 7.29163 9.95697 7.12496 9.83197 6.93329C9.73197 6.80829 9.6153 6.62496 9.50697 6.44163C9.37364 6.21663 9.25697 5.99163 9.1403 5.75829C8.98734 5.43051 8.55713 5.33314 8.30135 5.58891L3.6153 10.275C3.50697 10.3833 3.40697 10.5916 3.38197 10.7333L2.93197 13.925C2.84864 14.4916 3.00697 15.025 3.35697 15.3833C3.65697 15.675 4.07364 15.8333 4.52364 15.8333C4.62364 15.8333 4.72364 15.825 4.82364 15.8083L8.02364 15.3583C8.17364 15.3333 8.38197 15.2333 8.48197 15.125L13.1758 10.4312C13.4264 10.1805 13.3322 9.74924 13.007 9.60829Z"
                fill="black"
              />
            </svg>
          </button>)
        ) : (
          <button
            className="editbuttontool"
            onClick={() => setEnableEdit(!enableEdit)}>
            Cancel
          </button>
        )}
      </div>
      <div>
        {/* add new */}
        {showForm && (
          <div
            className={styles.popupContainer}
            onClick={(e) => {
              e.stopPropagation();
              setShowForm(false);
            }}>
            <div className={styles.popUp} onClick={handleStopClose}>
              <form onSubmit={handleSubmit}>
                {/* <label>
              S.NO
              <input
                type="text"
                name="s_no"
                value={formData.rig_name}
                onChange={handleInputChange}
              />
            </label> */}
                <div className={styles.popupItemss}>
                  <b>Add New</b>
                  <div style={{ cursor: "pointer" }}>
                    <svg
                      onClick={() => setShowForm(false)}
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.4998 2.41663C7.84192 2.41663 2.4165 7.84204 2.4165 14.5C2.4165 21.1579 7.84192 26.5833 14.4998 26.5833C21.1578 26.5833 26.5832 21.1579 26.5832 14.5C26.5832 7.84204 21.1578 2.41663 14.4998 2.41663ZM18.5598 17.2791C18.9103 17.6295 18.9103 18.2095 18.5598 18.56C18.3786 18.7412 18.149 18.8258 17.9194 18.8258C17.6898 18.8258 17.4603 18.7412 17.279 18.56L14.4998 15.7808L11.7207 18.56C11.5394 18.7412 11.3098 18.8258 11.0803 18.8258C10.8507 18.8258 10.6211 18.7412 10.4398 18.56C10.0894 18.2095 10.0894 17.6295 10.4398 17.2791L13.219 14.5L10.4398 11.7208C10.0894 11.3704 10.0894 10.7904 10.4398 10.44C10.7903 10.0895 11.3703 10.0895 11.7207 10.44L14.4998 13.2191L17.279 10.44C17.6294 10.0895 18.2094 10.0895 18.5598 10.44C18.9103 10.7904 18.9103 11.3704 18.5598 11.7208L15.7807 14.5L18.5598 17.2791Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <label className={styles.popupItemss}>
                  Item No:
                  <input
                    type="text"
                    name="Item_No"
                    value={formData.Item_No}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Description :
                  <input
                    type="text"
                    name="Description"
                    value={formData.Description}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  Manufacturer :
                  <input
                    type="text"
                    name="Manufacturer"
                    value={formData.Manufacturer}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Model :
                  <input
                    type="text"
                    name="Model"
                    value={formData.Model}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Serial No :
                  <input
                    type="text"
                    name="Serial_No"
                    value={formData.Serial_No}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Cal Date :
                  <input
                    type="text"
                    name="Cal_Date"
                    value={formData.Cal_Date}
                    onChange={handleInputChange}
                  />
                </label>
               
                <label className="popuptoolsitem">
                  Due Date :
                  <input
                    type="text"
                    name="Due_Date"
                    value={formData.Due_Date}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Range Value :
                  <input
                    type="text"
                    name="Range_Value"
                    value={formData.Range_Value}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Nominal Value :
                  <input
                    type="text"
                    name="Nominal_Value"
                    value={formData.Nominal_Value}
                    onChange={handleInputChange}
                  />
                </label>
               
                <label className="popuptoolsitem">
                  Measured Value :
                  <input
                    type="text"
                    name="Measured_Value"
                    value={formData.Measured_Value}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Acceptance Range :
                  <input
                    type="text"
                    name="Acceptance_Criteria"
                    value={formData.Acceptance_Criteria}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Frequency :
                  <input
                    type="text"
                    name="Frequency"
                    value={formData.Frequency}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Cert No :
                  <input
                    type="text"
                    name="Cert_No"
                    value={formData.Cert_No}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Status :
                  <input
                    type="text"
                    name="Status"
                    value={formData.Status}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="popuptoolsitem">
                  Remarks :
                  <input
                    type="text"
                    name="Remarks"
                    value={formData.Remarks}
                    onChange={handleInputChange}
                  />
                </label>

                <br />
                <button
                  type="submit"
                  className="SubmitButtontool"
                  size="large"
                  variant="contained">
                  Submit
                </button>
                <button
                  onClick={() => setShowForm(false)}
                  className="CancelButtontool"
                  size="large">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className="table">
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Item No</th>
                <th>Description</th>
                <th>Manufacturer</th>
                <th>Model</th>
                <th>Serial No</th>
                <th>Cal Date</th>
                <th>Due Date</th>
                <th>Range Value</th>
                <th>Nominal Value</th>
                <th>Measured Value</th>
                <th>Acceptance Range</th>
                <th>Frequency</th>
                <th>Cert No</th>
                <th>Status</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {filteredResults.map((tools_register, index) => (
                <tr key={index}>
                  <td className="toolsEditRadioBtnEnabbled">
                    {enableEdit && (
                      <>
                        <EditToolsComponent
                        setEnableEdit={setEnableEdit}
                        enableEdit={enableEdit}
                          s_no={index + 1}
                          Item_No={tools_register.Item_No}
                          Description={tools_register.Description}
                          Manufacturer={tools_register.Manufacturer}
                          Model={tools_register.Model}
                          Serial_No={tools_register.Serial_No}
                          Cal_Date={tools_register.Cal_Date}
                          Due_Date={tools_register.Due_Date}
                          Range_Value={tools_register.Range_Value}
                          Nominal_Value={tools_register.Nominal_Value}
                          Measured_Value={tools_register.Measured_Value}
                          Acceptance_Criteria={
                            tools_register.Acceptance_Criteria
                          }
                          Frequency={tools_register.Frequency}
                          Cert_No={tools_register.Cert_No}
                          Status={tools_register.Status}
                          Remarks={tools_register.Remarks}
                          tools={tools_register.Id_No}
                        />
                      </>
                    )}
                    {index + 1}{" "}
                  </td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {tools_register.Item_No}
                  </td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {tools_register.Description}
                  </td>
                  <td>{tools_register.Manufacturer}</td>
                  <td>{tools_register.Model}</td>
                  <td>{tools_register.Serial_No}</td>
                  <td>{tools_register.Cal_Date}</td>
                  <td>{tools_register.Due_Date}</td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {tools_register.Range_Value}
                  </td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {tools_register.Nominal_Value}
                  </td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {tools_register.Measured_Value}
                  </td>
                  <td>{tools_register.Acceptance_Criteria}</td>
                  <td>{tools_register.Frequency}</td>
                  <td>{tools_register.Cert_No}</td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {tools_register.Status}
                  </td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {tools_register.Remarks}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ToolsRegister;
