import React from "react";
import styles from "./css/purchase_items_received.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from './LoadingScreen';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function PurchaseItemsReceived() {
  const { Purchase_id } = useParams();
  const [activeTab, setActiveTab] = useState("Items Received");

  const [itemsReceived, setItemsReceived] = useState([]);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editedLocations, setEditedLocations] = useState({});
  const [editedRemarks, setEditedRemarks] = useState({});
  const [changedItems, setChangedItems] = useState([]);
  const [featureitemsreceived,setfeatureitemsreceived] = useState([]);
  const [featureitemsreceivededit,setfeatureitemsreceivededit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks

  const handleDivClick= (tab, route) => {
    setActiveTab(tab);
    navigate(`${route}${Purchase_id}`);
  };

  const handleLocationChange = (item, value) => {
    // Construct a unique key from the item's properties
    // const key = `${item.S_No}_${item.Incoming_Delivery_ID}_${item.Item_Name}_${item.OEM_Part_Number}`;
    // console.log("location key", key);
    // Update the editedLocations with the new value, using the constructed key
    setEditedLocations((prevLocations) => ({
      ...prevLocations,
      [item.S_No]: value === "" ? null : value,
    }));
  
    // Add the item to changedItems if it's not already included
    if (!changedItems.includes(item.S_No)) {
      setChangedItems((prevItems) => [...prevItems, item.S_No]);
    }
  };
    
  const handleRemarksChange = (item, value) => {
    // Construct a unique key from the item's properties
    // const key = `${item.S_No}_${item.Incoming_Delivery_ID}_${item.Item_Name}_${item.OEM_Part_Number}`;
    // console.log("remarks key", key);

    // Update the editedRemarks with the new value, using the constructed key
    setEditedRemarks((prevRemarks) => ({
      ...prevRemarks,
      [item.S_No]: value === "" ? null : value,
    }));
  
    // Add the item to changedItems if it's not already included
    if (!changedItems.includes(item.S_No)) {
      setChangedItems((prevItems) => [...prevItems, item.S_No]);
    }
  };
    
  const handleEditClick = () => {
    setIsEditing(true);
    const initialEditedLocations = {};
    const initialEditedRemarks = {};
  
    itemsReceived.forEach((item) => {
      initialEditedLocations[item.S_No] = item.Location;
      initialEditedRemarks[item.S_No] = item.Remarks;
    });
  
    setEditedLocations(initialEditedLocations);
    setEditedRemarks(initialEditedRemarks);  
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      // Filter and map the items using the constructed unique key
      const updatedItems = itemsReceived
        .filter(item => {
          // const key = `${item.S_No}_${item.Incoming_Delivery_ID}_${item.Item_Name}_${item.OEM_Part_Number}`;
          // console.log("filter key", key);
          return changedItems.includes(item.S_No);
        })
        .map(item => {
          // const key = `${item.S_No}_${item.Incoming_Delivery_ID}_${item.Item_Name}_${item.OEM_Part_Number}`;
          // console.log("map key", key);
          const updatedItem = {
            ...item,
            Location: editedLocations[item.S_No] !== undefined ? editedLocations[item.S_No] : null,
            Remarks: editedRemarks[item.S_No] !== undefined ? editedRemarks[item.S_No] : null,
          };
          // Omitting Entry_Bill_No, Arrival_Date from updatedItem
          delete updatedItem.Entry_Bill_No;
          delete updatedItem.Arrival_Date;
          return updatedItem;
        });
  
      console.log("updatedItems:", updatedItems);
  
      // Make API calls to update each item
      await Promise.all(updatedItems.map(updatedItem => 
        axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_items_received_put?S_No=${updatedItem.S_No}&Purchase_ID=${Purchase_id}&Incoming_Delivery_ID=${updatedItem.Incoming_Delivery_ID}&Item_Name=${updatedItem.Item_Name}&OEM_Part_Number=${updatedItem.OEM_Part_Number}`,
                    updatedItem
          //           , {
          // params: {
          //   S_No: updatedItem.S_No,
          //   Purchase_ID: Purchase_id,
          //   Incoming_Delivery_ID: updatedItem.Incoming_Delivery_ID,
          //   Item_Name: updatedItem.Item_Name,
          //   OEM_Part_Number: updatedItem.OEM_Part_Number,
          // }
        // }
      )
      ));
  
      // fetch updated data
      const itemsRes = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_items_received_get?Purchase_ID=${Purchase_id}`
      );

      const itemsReceivedData = itemsRes.data.data;

      // Filter items with truthy and greater than 0 Scheduled_Quantity
      const filteredItemsReceived = itemsReceivedData.filter(item => item.Scheduled_Quantity > 0);

      const updatedItemsReceived = await Promise.all(filteredItemsReceived.map(async (item) => {
        const ergonBOERes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_itemsreceived_get_ErgonBOE?Incoming_Delivery_ID=${item.Incoming_Delivery_ID}`
        );
        console.log("ergonBOERes", ergonBOERes);
        return {
          ...item,
          Entry_Bill_No: ergonBOERes.data.data[0].Entry_Bill_No,
          Arrival_Date: ergonBOERes.data.data[0].Expected_Arrival_Date,
        };
      }));

      console.log("Updated itemsReceived", updatedItemsReceived);

      setItemsReceived(updatedItemsReceived);
      setIsEditing(false);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const itemsRes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_items_received_get?Purchase_ID=${Purchase_id}`
        );
  
        const itemsReceivedData = itemsRes.data.data;
  
        // Filter items with truthy and greater than 0 Scheduled_Quantity
        const filteredItemsReceived = itemsReceivedData.filter(item => item.Scheduled_Quantity > 0);
  
        const updatedItemsReceived = await Promise.all(filteredItemsReceived.map(async (item) => {
          const ergonBOERes = await axios.get(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_itemsreceived_get_ErgonBOE?Incoming_Delivery_ID=${item.Incoming_Delivery_ID}`
          );
          console.log("ergonBOERes", ergonBOERes);
          return {
            ...item,
            Entry_Bill_No: ergonBOERes.data.data[0].Entry_Bill_No,
            Arrival_Date: ergonBOERes.data.data[0].Expected_Arrival_Date,
          };
        }));
  
        console.log("Updated itemsReceived", updatedItemsReceived);
  
        setItemsReceived(updatedItemsReceived);
      } catch (error) {
        console.error("Failed to fetch items: ", error);
      }
    };
    fetchProject();
  }, []);
  useEffect(()=>{
    async function fetchRigDetails(){
    try{
      const response = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
      );
  const workshoppurchasesitemreceived= response.data.filter((el)=>{
    return(
    el.Group_Name === localStorage.getItem("Group") &&
    el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Purchases Item Received" && (el.Access === "Can View" || el.Access === "Can Edit")
    );
  })

  const workshoppurchasesitemreceivededit= response.data.filter((el)=>{
    return(
    el.Group_Name === localStorage.getItem("Group") &&
    el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Purchases Item Received Edit" && (el.Access === "Can View" || el.Access === "Can Edit")
    );
  })

  setfeatureitemsreceived(workshoppurchasesitemreceived);
  setfeatureitemsreceivededit(workshoppurchasesitemreceivededit);

}catch(error){
  console.error(error);
}
}
fetchRigDetails();
  },[]);
    
  return (
    
    <div className={styles.topdiv}>
      <LoadingScreen isLoading={isLoading} />
      <div className={styles.sections1}>
        {" "}
        {/*Top_Bar_Div*/}
        <div
          className={
            activeTab === "Purchase Details" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick("Purchase Details", "/purchase_details/")
          }
        >
          Purchase Details
        </div>
        <div
          className={
            activeTab === "Item Details" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() => handleDivClick("Item Details", "/item_details/")}
        >
          Item Details
        </div>
        <div
          className={
            activeTab === "Incoming Deliveries"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Incoming Deliveries",
              "/purchase_incoming_deliveries/"
            )
          }
        >
          Incoming Deliveries
        </div>
        <div
          className={
            activeTab === "Incoming Inspections"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Incoming Inspections",
              "/purchase_incoming_inspections/"
            )
          }
        >
          Incoming Inspections
        </div>
        <div
          className={
            activeTab === "Items Received"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Items Received",
              "/purchase_items_received/"
            )
          }
        >
          Items Received
        </div>
      </div>
      {featureitemsreceived.length>0 ?(
      !isEditing ? (
        // Non-editable display of data
        <div className={styles.sections2}>
          <div className={styles.buttonsdiv}>
          {featureitemsreceivededit.length >0 && (
            <button onClick={handleEditClick}>
              Edit{" "}
              <svg
                width="12"
                height="12"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-5px", marginLeft: "5px" }}
              >
                <path
                  d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                  fill="white"
                />
              </svg>
            </button>
            )}
          </div>

          <div className={styles.smtablecontainer}>
            <table className={styles.smtable}>
              <thead>
                <tr>
                <th>S. No.</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>Manufacturer Part No.</th>
                  <th>UOM</th>
                  <th>Qty Received</th>
                  <th>Arrival Date</th>
                  <th>BOE</th>
                  <th>Incoming Delivery ID</th>
                  <th>Incoming Inspection ID</th>
                  <th>Location</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {itemsReceived.length > 0 ? (
                  itemsReceived.map((item, index) => (
                    <tr key={item.S_No}>
                      <td>{index+1}</td>
                      <td>{item.Item_Name}</td>
                      <td>{item.OEM_Part_Number}</td>
                      <td>{item.Manufacturer_Part_Number}</td>
                      <td>{item.UoM}</td>
                      <td>{item.Scheduled_Quantity}</td>
                      <td>{new Date(item.Arrival_Date).toLocaleDateString('en-GB')}</td>
                      <td>{item.Entry_Bill_No}</td>
                      <td>{item.Incoming_Delivery_ID}</td>
                      <td>{item.Incoming_Inspection_ID}</td>
                      <td>{item.Location}</td>
                      <td>{item.Remarks}</td>
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan="12">No items received yet.</td>
                    </tr>
                  )
                }  
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        // Editable data state
        <div className={styles.sections2}>

          <div className={styles.buttonsdiv} >
            <button onClick={handleSaveClick} style={{ marginRight: "10px"}} disabled={isLoading}>
              Save{" "}
            </button>
          
            <button onClick={handleCancelClick} style={{ background: "white", ":hover": {background: "#F5F5F5",}, border: "1px solid #5B6BE1", color: "#5B6BE1", justifyContent: "center", padding: "5px", borderRadius: "5px"}}>
              Cancel{" "}
            </button>
          </div>

          <div className={styles.smtablecontainer}>
            <table className={styles.smtable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>Manufacturer Part No.</th>
                  <th>UOM</th>
                  <th>Qty Received</th>
                  <th>Arrival Date</th>
                  <th>BOE</th>
                  <th>Incoming Delivery ID</th>
                  <th>Incoming Inspection ID</th>
                  <th>Location</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {itemsReceived.length > 0 ? (
                  itemsReceived.map((item, index) => (
                    <tr key={item.S_No}>
                      <td>{index+1}</td>
                      <td>{item.Item_Name}</td>
                      <td>{item.OEM_Part_Number}</td>
                      <td>{item.Manufacturer_Part_Number}</td>
                      <td>{item.UoM}</td>
                      <td>{item.Scheduled_Quantity}</td>
                      <td>{new Date(item.Arrival_Date).toLocaleDateString('en-GB')}</td>
                      <td>{item.Entry_Bill_No}</td>
                      <td>{item.Incoming_Delivery_ID}</td>
                      <td>{item.Incoming_Inspection_ID}</td>
                      <td>
                        <input
                          className={styles.inputFieldsm}
                          style={{ paddingRight: "8px" }}
                          type="text"
                          value={editedLocations[item.S_No]}
                          onChange={(e) =>
                            handleLocationChange(item, e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className={styles.inputFieldsm}
                          style={{ paddingRight: "8px" }}
                          type="text"
                          value={editedRemarks[item.S_No]}
                          onChange={(e) =>
                            handleRemarksChange(item, e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan="12">No items received yet.</td>
                    </tr>
                  )
                }  
              </tbody>
            </table>
          </div>
        </div>
      )
    ):(
      <div style={{ marginLeft: "30%", marginTop: "15%" }}>
      <h3>
        You do not have permissions to view this page.
      </h3>
    </div>
    )
}
    </div>

  );
}

export default PurchaseItemsReceived
