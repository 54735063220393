// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customerfeedback_savebtncustomerfeedback__BqhT2{\r\n    background: rgb(91, 107, 225);\r\n    width: 171px;\r\n    height: 50px;\r\n    border: 1px solid rgb(91, 107, 225);\r\n    border-radius: 8px;\r\n    padding: 15px 59px 15px 58px;\r\n    font-weight: bold;\r\n    &:hover{\r\n      background: rgb(91, 107, 225);\r\n    }\r\n  }\r\n\r\n\r\n  .customerfeedback_cancelbtn__NWLWQ{\r\n    background: white;\r\n    width: 171px;\r\n    height: 50px;\r\n    border: 1px solid rgb(91, 107, 225);\r\n    border-radius: 8px;\r\n    padding: 15px 59px 15px 58px;\r\n    font-weight: bold;\r\n    color: rgb(91, 107, 225);\r\n    &:hover{\r\n      background: white;\r\n    }\r\n  }\r\n  ", "",{"version":3,"sources":["webpack://./src/Components/css/customerfeedback.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,mCAAmC;IACnC,kBAAkB;IAClB,4BAA4B;IAC5B,iBAAiB;IACjB;MACE,6BAA6B;IAC/B;EACF;;;EAGA;IACE,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,mCAAmC;IACnC,kBAAkB;IAClB,4BAA4B;IAC5B,iBAAiB;IACjB,wBAAwB;IACxB;MACE,iBAAiB;IACnB;EACF","sourcesContent":[".savebtncustomerfeedback{\r\n    background: rgb(91, 107, 225);\r\n    width: 171px;\r\n    height: 50px;\r\n    border: 1px solid rgb(91, 107, 225);\r\n    border-radius: 8px;\r\n    padding: 15px 59px 15px 58px;\r\n    font-weight: bold;\r\n    &:hover{\r\n      background: rgb(91, 107, 225);\r\n    }\r\n  }\r\n\r\n\r\n  .cancelbtn{\r\n    background: white;\r\n    width: 171px;\r\n    height: 50px;\r\n    border: 1px solid rgb(91, 107, 225);\r\n    border-radius: 8px;\r\n    padding: 15px 59px 15px 58px;\r\n    font-weight: bold;\r\n    color: rgb(91, 107, 225);\r\n    &:hover{\r\n      background: white;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"savebtncustomerfeedback": "customerfeedback_savebtncustomerfeedback__BqhT2",
	"cancelbtn": "customerfeedback_cancelbtn__NWLWQ"
};
export default ___CSS_LOADER_EXPORT___;
