import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "../css/professionaldetails_edit_docs.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import { ProfessionalTabs } from "./professional_Tabs";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

// Custom DatePicker styling
const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiPickersBasePicker-pickerView": {
    backgroundColor: "#DADCE0",
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    width: "280px",
    "&:hover": {
      "& fieldset": {
        borderColor: "1px solid #DADCE0",
        color: "black",
      },
    },
    "&:not(.Mui-focused)": {
      "& fieldset": {
        border: "1px solid #DADCE0",
        borderRadius: "8px",
        color: "black",
      },
    },
    "&.Mui-focused": {
      "& fieldset": {
        border: "1px solid #DADCE0",
        borderRadius: "8px",
        color: "black",
      },
    },
  },
  "& input.Mui-disabled": {
    opacity: 1,
    "-webkit-text-fill-color": "black", // For WebKit-based browsers
    color: "black", // Fallback for other browsers
  },
}));


export const ProfessionalDetails_EditDocs = ({ activeTab, setActiveTab }) => {
  const { id } = useParams();
  const [countryNames, setCountryNames] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const visaOrder = ["One", "Two", "Three", "Four"];
  const [visaRowCount, setVisaRowCount] = useState(1);
  const [loiRowCount, setLoiRowCount] = useState(1);

  const [formData, setFormData] = useState({
    Passport_Expiry: "",
    Passport_Issuing_Country: "",
    Passport_Doc: null,
    Seaman_Book_Expiry: "",
    Seaman_Issuing_Country: "",
    Seaman_Doc: null,
    Bosiet_Expiry: "",
    Bosiet_Doc: null,
    Insurance_Expiry: "",
    Insurance_Doc: null,
    Vaccination_Certificate: null,
    CV_File: null,
    PCC_Expiry: "",
    PCC: null,
    Medical_Expiry: "",
    Medical_Doc: null,
    H2s_Expiry: "",
    H2s_Doc: null,
    SNT_Eye_Test_Expiry: "",
    SNT_Eye_Test_Doc: null,
    Yellow_Fever_Expiry: "",
    Yellow_Fever_Doc: null,
    Aadhar_Doc: null,

    // Active Visas and LOI
    Active_Visas_One: "",
    Active_Visas_One_Expiry: "",
    Active_Visas_One_Doc: null,
    Active_Visas_Two: "",
    Active_Visas_Two_Expiry: "",
    Active_Visas_Two_Doc: null,
    Active_Visas_Three: "",
    Active_Visas_Three_Expiry: "",
    Active_Visas_Three_Doc: null,
    Active_Visas_Four: "",
    Active_Visas_Four_Expiry: "",
    Active_Visas_Four_Doc: null,

    LOI_1_Country: "",
    LOI_1_Expiry: "",
    LOI_1_Doc: null,
    LOI_2_Country: "",
    LOI_2_Expiry: "",
    LOI_2_Doc: null,
    LOI_3_Country: "",
    LOI_3_Expiry: "",
    LOI_3_Doc: null,
    LOI_4_Country: "",
    LOI_4_Expiry: "",
    LOI_4_Doc: null,
  });

  const [files, setFiles] = useState({});

  // Fetch existing data
  const getAllData = async () => {
    try {
      const response = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/professional_details_get_all_data_with_loi/${id}`
      );

      const countriesRes = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/legal_details"
      );

      const data = response.data || {};
      setFormData((prev) => ({
        ...prev,
        ...data,
      }));

      // Compute how many visa rows have data
      let newVisaCount = 0;
      visaOrder.forEach((key, index) => {
        if (
          data[`Active_Visas_${key}`] ||
          data[`Active_Visas_${key}_Doc`] ||
          data[`Active_Visas_${key}_Expiry`]
        ) {
          newVisaCount = index + 1;
        }
      });
      if (newVisaCount === 0) newVisaCount = 1;
      setVisaRowCount(newVisaCount);

      // Compute LOI row count (assuming LOI rows are numbered 1 to 4)
      let newLoiCount = 0;
      [1, 2, 3, 4].forEach((num) => {
        if (
          data[`LOI_${num}_Country`] ||
          data[`LOI_${num}_Doc`] ||
          data[`LOI_${num}_Expiry`]
        ) {
          newLoiCount = num;
        }
      });
      if (newLoiCount === 0) newLoiCount = 1;
      setLoiRowCount(newLoiCount);

      const countriesData = countriesRes.data || [];
      const uniqueCountryNames = [
        ...new Set(countriesData.map((item) => item.country)),
      ];
      setCountryNames(uniqueCountryNames);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  // Update state when a text input changes
  const handleOnChange = (e) => {
    if (!isEditing) return;
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Update state for file inputs
  const handleFileChange = (e) => {
    if (!isEditing) return;
    const { name } = e.target;
    const selectedFile = e.target.files[0] || null;
    setFiles((prev) => ({ ...prev, [name]: selectedFile }));
  };

  // Handle date changes for date fields
  const handleDateChange = (field, value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      [field]: value ? value.format("DD/MM/YYYY") : "",
    }));
  };

  // Submit handler to update docs – sends both text data and any new files
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const fd = new FormData();
      // Append text/date fields
      Object.keys(formData).forEach((key) => {
        fd.append(key, formData[key] ? formData[key] : "");
      });
      // Append any file fields if a new file is selected
      Object.keys(files).forEach((key) => {
        if (files[key]) {
          fd.append(key, files[key], files[key].name);
        }
      });

      const response = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professional_details_update_doc_files/${id}`,
        fd,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response.status === 200) {
        setFiles({});
        getAllData();
        setIsEditing(false);
      }

    } catch (error) {
      console.error("Error updating documents:", error);
    }
  };

  const handleViewFile = (fileData) => {
    // fileData can be a link (string) or a File object
    if (typeof fileData === "string") {
      window.open(fileData, "_blank");
    } else if (fileData instanceof File) {
      const url = URL.createObjectURL(fileData);
      window.open(url, "_blank");
    }
  };

  // Helper function to extract the file name without the timestamp
  const fileNameFromLink = (link) => {
    if (typeof link === "string" && link) {
      // Splits by "/" then removes the timestamp part from the filename
      return link.split("/").pop().split("-").slice(1).join("-");
    }
    return "";
  };

  const downloadFile = (fileUrl) => {
    if (fileUrl !== null) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          // Use the helper to generate a clean file name
          link.download = fileNameFromLink(fileUrl);
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("There is no file to download");
    }
  };

  // Updated download handler that supports both file links and File objects
  const handleDownloadFile = (fileData) => {
    if (typeof fileData === "string") {
      downloadFile(fileData);
    } else if (fileData instanceof File) {
      // For a File object, create a blob URL and trigger the download directly
      const url = URL.createObjectURL(fileData);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileData.name;
      link.click();
      URL.revokeObjectURL(url);
    }
  };

  const handleDownloadAllFiles = async () => {
    // Only allow download when not editing
    if (isEditing) return;

    const zip = new JSZip();

    // List of file field keys for single documents
    const fileFields = [
      "Passport_Doc",
      "Seaman_Doc",
      "Bosiet_Doc",
      "Insurance_Doc",
      "Vaccination_Certificate",
      "CV_File",
      "PCC",
      "Medical_Doc",
      "H2s_Doc",
      "SNT_Eye_Test_Doc",
      "Yellow_Fever_Doc",
      "Aadhar_Doc",
    ];

    // File fields for dynamic Active Visas and LOI.
    const activeVisaFields = visaOrder.slice(0, visaRowCount).map(
      (num) => `Active_Visas_${num}_Doc`
    );
    const loiFields = [];
    for (let i = 1; i <= loiRowCount; i++) {
      loiFields.push(`LOI_${i}_Doc`);
    }

    const allFileKeys = [...fileFields, ...activeVisaFields, ...loiFields];

    // Helper to add one file to the zip
    const addFileToZip = async (fileValue, key) => {
      if (!fileValue) return;
      if (fileValue instanceof File) {
        // File object available directly
        zip.file(fileValue.name, fileValue);
      } else if (typeof fileValue === "string") {
        try {
          const response = await fetch(fileValue);
          if (response.ok) {
            const blob = await response.blob();
            // Use the helper to extract a clean file name or fallback to the key
            const filename = fileNameFromLink(fileValue) || key;
            zip.file(filename, blob);
          } else {
            console.error(`Failed to fetch file for ${key}`);
          }
        } catch (error) {
          console.error(`Error fetching file for ${key}:`, error);
        }
      }
    };

    // Process each file field; if a file exists in the temporary files state, use that; otherwise use formData.
    const promises = allFileKeys.map(async (key) => {
      const fileValue = files[key] || formData[key];
      return addFileToZip(fileValue, key);
    });

    await Promise.all(promises);

    // Generate and download the zip file
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "Professional_Details_Files.zip");
  };


  const handleAddVisa = () => {
    if (visaRowCount < 4) {
      const newRowKey = visaOrder[visaRowCount]; // e.g. if current count is 2, new row key is "Three"
      // Initialize the new row’s fields in formData
      setFormData((prev) => ({
        ...prev,
        [`Active_Visas_${newRowKey}`]: "",
        [`Active_Visas_${newRowKey}_Doc`]: "",
        [`Active_Visas_${newRowKey}_Expiry`]: ""
      }));
      setVisaRowCount((prev) => prev + 1);
    }
  };

  const handleAddLOI = () => {
    if (loiRowCount < 4) {
      const newRowNumber = loiRowCount + 1;
      setFormData((prev) => ({
        ...prev,
        [`LOI_${newRowNumber}_Country`]: "",
        [`LOI_${newRowNumber}_Doc`]: "",
        [`LOI_${newRowNumber}_Expiry`]: ""
      }));
      setLoiRowCount((prev) => prev + 1);
    }
  };

  const handleRemoveVisa = (rowIndex) => {
    setFormData((prev) => {
      const newFormData = { ...prev };
      // If only one row, just clear it
      if (visaRowCount === 1) {
        newFormData[`Active_Visas_${visaOrder[0]}`] = "";
        newFormData[`Active_Visas_${visaOrder[0]}_Doc`] = "";
        newFormData[`Active_Visas_${visaOrder[0]}_Expiry`] = "";
      } else {
        // Shift rows upward: for each row starting at the removed one,
        // copy data from the next row, and clear the last row.
        for (let i = rowIndex; i < visaRowCount - 1; i++) {
          newFormData[`Active_Visas_${visaOrder[i]}`] =
            prev[`Active_Visas_${visaOrder[i + 1]}`] || "";
          newFormData[`Active_Visas_${visaOrder[i]}_Doc`] =
            prev[`Active_Visas_${visaOrder[i + 1]}_Doc`] || "";
          newFormData[`Active_Visas_${visaOrder[i]}_Expiry`] =
            prev[`Active_Visas_${visaOrder[i + 1]}_Expiry`] || "";
        }
        // Clear the last row
        const lastKey = visaOrder[visaRowCount - 1];
        newFormData[`Active_Visas_${lastKey}`] = "";
        newFormData[`Active_Visas_${lastKey}_Doc`] = "";
        newFormData[`Active_Visas_${lastKey}_Expiry`] = "";
      }
      return newFormData;
    });
    // Decrease row count only if more than one row exists
    if (visaRowCount > 1) {
      setVisaRowCount((prev) => prev - 1);
    }
  };

  const handleRemoveLOI = (rowIndex) => {
    setFormData((prev) => {
      const newFormData = { ...prev };
      if (loiRowCount === 1) {
        newFormData[`LOI_1_Country`] = "";
        newFormData[`LOI_1_Doc`] = "";
        newFormData[`LOI_1_Expiry`] = "";
      } else {
        for (let i = rowIndex; i < loiRowCount - 1; i++) {
          const currentKey = i + 1;
          const nextKey = i + 2;
          newFormData[`LOI_${currentKey}_Country`] =
            prev[`LOI_${nextKey}_Country`] || "";
          newFormData[`LOI_${currentKey}_Doc`] =
            prev[`LOI_${nextKey}_Doc`] || "";
          newFormData[`LOI_${currentKey}_Expiry`] =
            prev[`LOI_${nextKey}_Expiry`] || "";
        }
        // Clear the last LOI row
        newFormData[`LOI_${loiRowCount}_Country`] = "";
        newFormData[`LOI_${loiRowCount}_Doc`] = "";
        newFormData[`LOI_${loiRowCount}_Expiry`] = "";
      }
      return newFormData;
    });
    if (loiRowCount > 1) {
      setLoiRowCount((prev) => prev - 1);
    }
  };


  return (
    <>
      <ProfessionalTabs
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSubmit={handleSubmit}
        handleDownloadAllFiles={handleDownloadAllFiles}
      />

      <div className={styles.createContainer}>
        <div className={styles.supplierContainer}>
          <form>
            {/* Professional Documents Section */}
            <div className={styles.heading1}>
              <h5>Professional Documents</h5>
            </div>

            <div className={styles.container1}>
              {/* Left Column */}
              <div className={styles.leftContainer}>
                <div className={styles.formElement}>
                  <label>Passport</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Passport_Doc"
                      onChange={handleFileChange}
                      id="Passport_Doc"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Passport_Doc
                          ? files.Passport_Doc.name
                          : formData.Passport_Doc
                            ? formData.Passport_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Passport_Doc").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Passport_Doc || formData.Passport_Doc) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Passport_Doc || formData.Passport_Doc
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Passport_Doc || formData.Passport_Doc
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>Passport Country</label>
                  <select
                    name="Passport_Country"
                    value={formData.Passport_Country}
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  >
                    <option value="">Select Country</option>
                    <option value="South Africa">South Africa</option>
                    <option value="India">India</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Singapore">Singapore</option>
                    <option value="UK">UK</option>
                    <option value="Croatia">Croatia</option>
                  </select>
                  {isEditing && (
                    <svg
                      style={{ position: "absolute", left: "458px", pointerEvents: "none" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </div>
                <div className={styles.formElement}>
                  <label>Passport Expiry</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        value={
                          formData.Passport_Expiry
                            ? dayjs(formData.Passport_Expiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange("Passport_Expiry", value)
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        className={isEditing ? styles.fileUpload : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="Passport_Expiry"
                      value={
                        formData.Passport_Expiry
                          ? dayjs(formData.Passport_Expiry, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Seaman Book</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Seaman_Doc"
                      onChange={handleFileChange}
                      id="Seaman_Doc"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Seaman_Doc
                          ? files.Seaman_Doc.name
                          : formData.Seaman_Doc
                            ? formData.Seaman_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Seaman_Doc").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Seaman_Doc || formData.Seaman_Doc) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Seaman_Doc || formData.Seaman_Doc
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Seaman_Doc || formData.Seaman_Doc
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>Seaman Book Country</label>
                  <select
                    name="Seaman_Issuing_Country"
                    value={formData.Seaman_Issuing_Country}
                    onChange={handleOnChange}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  >
                    <option value="">Select Country</option>
                    <option value="Belize">Belize</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Indian">Indian</option>
                    <option value="Curacao">Curacao</option>
                    <option value="Palau">Palau</option>
                    <option value="Bahamas">Bahamas </option>
                  </select>

                  {isEditing && (
                    <svg
                      style={{ position: "absolute", left: "458px", pointerEvents: "none" }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </div>
                <div className={styles.formElement}>
                  <label>Seaman Book Expiry</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        value={
                          formData.Seaman_Book_Expiry
                            ? dayjs(formData.Seaman_Book_Expiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange("Seaman_Book_Expiry", value)
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="Seaman_Book_Expiry"
                      value={
                        formData.Seaman_Book_Expiry
                          ? dayjs(formData.Seaman_Book_Expiry, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>BOSIET</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Bosiet_Doc"
                      onChange={handleFileChange}
                      id="Bosiet_Doc"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Bosiet_Doc
                          ? files.Bosiet_Doc.name
                          : formData.Bosiet_Doc
                            ? formData.Bosiet_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Bosiet_Doc").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Bosiet_Doc || formData.Bosiet_Doc) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Bosiet_Doc || formData.Bosiet_Doc
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Bosiet_Doc || formData.Bosiet_Doc
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>BOSIET Expiry</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        value={
                          formData.Bosiet_Expiry
                            ? dayjs(formData.Bosiet_Expiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange("Bosiet_Expiry", value)
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="Bosiet_Expiry"
                      value={
                        formData.Bosiet_Expiry
                          ? dayjs(formData.Bosiet_Expiry, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Insurance Doc</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Insurance_Doc"
                      onChange={handleFileChange}
                      id="Insurance_Doc"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Insurance_Doc
                          ? files.Insurance_Doc.name
                          : formData.Insurance_Doc
                            ? formData.Insurance_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Insurance_Doc").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Insurance_Doc || formData.Insurance_Doc) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Insurance_Doc || formData.Insurance_Doc
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Insurance_Doc || formData.Insurance_Doc
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>Insurance Expiry</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        value={
                          formData.Insurance_Expiry
                            ? dayjs(formData.Insurance_Expiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange("Insurance_Expiry", value)
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="Insurance_Expiry"
                      value={
                        formData.Insurance_Expiry
                          ? dayjs(formData.Insurance_Expiry, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Vaccination Certificate</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Vaccination_Certificate"
                      onChange={handleFileChange}
                      id="Vaccination_Certificate"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Vaccination_Certificate
                          ? files.Vaccination_Certificate.name
                          : formData.Vaccination_Certificate
                            ? formData.Vaccination_Certificate.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Vaccination_Certificate").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Vaccination_Certificate || formData.Vaccination_Certificate) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Vaccination_Certificate || formData.Vaccination_Certificate
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Vaccination_Certificate || formData.Vaccination_Certificate
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>CV Doc</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="CV_File"
                      onChange={handleFileChange}
                      id="CV_File"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.CV_File
                          ? files.CV_File.name
                          : formData.CV_File
                            ? formData.CV_File.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("CV_File").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.CV_File || formData.CV_File) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.CV_File || formData.CV_File
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.CV_File || formData.CV_File
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className={styles.rightContainer}>
                <div className={styles.formElement}>
                  <label>PCC</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="PCC"
                      onChange={handleFileChange}
                      id="PCC"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.PCC
                          ? files.PCC.name
                          : formData.PCC
                            ? formData.PCC.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("PCC").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.PCC || formData.PCC) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.PCC || formData.PCC
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.PCC || formData.PCC
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>PCC Expiry</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        value={
                          formData.PCC_Expiry ? dayjs(formData.PCC_Expiry, "DD/MM/YYYY") : null
                        }
                        onChange={(value) =>
                          handleDateChange("PCC_Expiry", value)
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="PCC_Expiry"
                      value={
                        formData.PCC_Expiry
                          ? dayjs(formData.PCC_Expiry, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Medical</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Medical_Doc"
                      onChange={handleFileChange}
                      id="Medical_Doc"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Medical_Doc
                          ? files.Medical_Doc.name
                          : formData.Medical_Doc
                            ? formData.Medical_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Medical_Doc").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Medical_Doc || formData.Medical_Doc) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Medical_Doc || formData.Medical_Doc
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Medical_Doc || formData.Medical_Doc
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>Medical Expiry</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        value={
                          formData.Medical_Expiry
                            ? dayjs(formData.Medical_Expiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange("Medical_Expiry", value)
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="Medical_Expiry"
                      value={
                        formData.Medical_Expiry
                          ? dayjs(formData.Medical_Expiry, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>H2S</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="H2s_Doc"
                      onChange={handleFileChange}
                      id="H2s_Doc"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.H2s_Doc
                          ? files.H2s_Doc.name
                          : formData.H2s_Doc
                            ? formData.H2s_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("H2s_Doc").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.H2s_Doc || formData.H2s_Doc) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.H2s_Doc || formData.H2s_Doc
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.H2s_Doc || formData.H2s_Doc
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>H2S Expiry</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        value={
                          formData.H2s_Expiry ? dayjs(formData.H2s_Expiry, "DD/MM/YYYY") : null
                        }
                        onChange={(value) =>
                          handleDateChange("H2s_Expiry", value)
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="H2s_Expiry"
                      value={
                        formData.H2s_Expiry
                          ? dayjs(formData.H2s_Expiry, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>SNT Eye Test</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="SNT_Eye_Test_Doc"
                      onChange={handleFileChange}
                      id="SNT_Eye_Test_Doc"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.SNT_Eye_Test_Doc
                          ? files.SNT_Eye_Test_Doc.name
                          : formData.SNT_Eye_Test_Doc
                            ? formData.SNT_Eye_Test_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("SNT_Eye_Test_Doc").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.SNT_Eye_Test_Doc || formData.SNT_Eye_Test_Doc) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.SNT_Eye_Test_Doc || formData.SNT_Eye_Test_Doc
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.SNT_Eye_Test_Doc || formData.SNT_Eye_Test_Doc
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>SNT Eye Test Expiry</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        value={
                          formData.SNT_Eye_Test_Expiry
                            ? dayjs(formData.SNT_Eye_Test_Expiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange("SNT_Eye_Test_Expiry", value)
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        className={isEditing ? styles.fileUpload : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="SNT_Eye_Test_Expiry"
                      value={
                        formData.SNT_Eye_Test_Expiry
                          ? dayjs(formData.SNT_Eye_Test_Expiry, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Yellow Fever Doc</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Yellow_Fever_Doc"
                      onChange={handleFileChange}
                      id="Yellow_Fever_Doc"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Yellow_Fever_Doc
                          ? files.Yellow_Fever_Doc.name
                          : formData.Yellow_Fever_Doc
                            ? formData.Yellow_Fever_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Yellow_Fever_Doc").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Yellow_Fever_Doc || formData.Yellow_Fever_Doc) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Yellow_Fever_Doc || formData.Yellow_Fever_Doc
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Yellow_Fever_Doc || formData.Yellow_Fever_Doc
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <label>Yellow Fever Expiry</label>
                  {isEditing ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        value={
                          formData.Yellow_Fever_Expiry
                            ? dayjs(formData.Yellow_Fever_Expiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange("Yellow_Fever_Expiry", value)
                        }
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        className={isEditing ? null : styles.textbox1}
                        disabled={!isEditing}
                      />
                    </LocalizationProvider>
                  ) : (
                    <input
                      type="text"
                      name="Yellow_Fever_Expiry"
                      value={
                        formData.Yellow_Fever_Expiry
                          ? dayjs(formData.Yellow_Fever_Expiry, "DD/MM/YYYY").format("DD/MM/YYYY")
                          : ""
                      }
                      className={isEditing ? null : styles.textbox1}
                      disabled
                    />
                  )}
                </div>

                <div className={styles.formElement}>
                  <label>Aadhar Doc</label>
                  <div className={styles.fileInputWrapper}>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      name="Aadhar_Doc"
                      onChange={handleFileChange}
                      id="Aadhar_Doc"
                      style={{ display: "none" }}
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      disabled={!isEditing}
                    />
                    {/* Display file name in a read-only text input */}
                    <input
                      type="text"
                      readOnly
                      className={isEditing ? styles.fileUpload : styles.textbox1}
                      value={
                        files.Aadhar_Doc
                          ? files.Aadhar_Doc.name
                          : formData.Aadhar_Doc
                            ? formData.Aadhar_Doc.split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                            : ""
                      }
                      onClick={() =>
                        isEditing && document.getElementById("Aadhar_Doc").click()
                      }
                    />
                    {isEditing && (
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          position: "absolute",
                          left: "460px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {/* If a file exists (either a new one or from existing data), show the buttons */}
                    {(files.Aadhar_Doc || formData.Aadhar_Doc) && (
                      <div className={styles.fileActions}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              files.Aadhar_Doc || formData.Aadhar_Doc
                            )
                          }
                        />

                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                          alt="preview"
                          width="40"
                          height="40"
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile(
                              files.Aadhar_Doc || formData.Aadhar_Doc
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Active Visas and LOI Section */}
            <div className={styles.heading1}>
              <h5>Active Visas and LOI</h5>
            </div>

            {/* Visas */}
            <div className={styles.visaContainer}>
              <h5 style={{
                marginLeft: "10px",
                marginBottom: "5px",
                marginTop: "20px",
                textDecoration: "underline",
                color: "#5b6be1",
              }}>
                Visas
              </h5>

              {visaOrder.slice(0, visaRowCount).map((num, index) => (
                <div key={`Active_Visas_${num}`} className={styles.visaRow}>
                  {/* <h5 style={{marginTop: "20px", marginLeft: "10px"}}>Visa {num}</h5> */}
                  <div className={styles.container1}>

                    <div className={styles.leftContainer}>
                      <div className={styles.formElement2}>

                        {visaRowCount > 1 && isEditing && (
                          <svg
                            onClick={() => handleRemoveVisa(index)}
                            style={{ cursor: "pointer", marginRight: "20px" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        )}

                        <label style={{ width: "150px" }}>Visa {num}</label>
                        <div className={styles.fileInputWrapper}>
                          {/* Hidden file input */}
                          <input
                            type="file"
                            name={`Active_Visas_${num}_Doc`}
                            onChange={handleFileChange}
                            id={`Active_Visas_${num}_Doc`}
                            style={{ display: "none" }}
                            className={isEditing ? styles.fileUpload : styles.textbox1}
                            disabled={!isEditing}
                          />
                          {/* Display file name in a read-only text input */}
                          <input
                            type="text"
                            readOnly
                            className={isEditing ? styles.fileUpload : styles.textbox1}
                            value={
                              files[`Active_Visas_${num}_Doc`]
                                ? files[`Active_Visas_${num}_Doc`].name
                                : formData[`Active_Visas_${num}_Doc`]
                                  ? formData[`Active_Visas_${num}_Doc`].split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                                  : ""
                            }
                            onClick={() =>
                              isEditing && document.getElementById(`Active_Visas_${num}_Doc`).click()
                            }
                          />
                          {isEditing && (
                            <svg
                              width="28"
                              height="25"
                              viewBox="0 0 28 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                position: "absolute",
                                left: "390px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                            >
                              <path
                                d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                fill="black"
                              />
                              <path
                                d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                fill="black"
                              />
                            </svg>
                          )}

                          {/* If a file exists (either a new one or from existing data), show the buttons */}
                          {(files[`Active_Visas_${num}_Doc`] || formData[`Active_Visas_${num}_Doc`]) && (
                            <div className={styles.fileActions}>
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                                alt="preview"
                                width="40"
                                height="40"
                                style={{ marginLeft: "4px", cursor: "pointer" }}
                                onClick={() =>
                                  handleViewFile(
                                    files[`Active_Visas_${num}_Doc`] || formData[`Active_Visas_${num}_Doc`]
                                  )
                                }
                              />

                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                                alt="preview"
                                width="40"
                                height="40"
                                style={{ marginLeft: "2px", cursor: "pointer" }}
                                onClick={() =>
                                  handleDownloadFile(
                                    files[`Active_Visas_${num}_Doc`] || formData[`Active_Visas_${num}_Doc`]
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.rightContainer}>
                      <div className={styles.formElement2}>
                        <label style={{ width: "150px" }}>Visa {num} Country</label>
                        <select
                          name={`Active_Visas_${num}`}
                          value={formData[`Active_Visas_${num}`] || ""}
                          onChange={handleOnChange}
                          className={isEditing ? null : styles.textbox1}
                          disabled={!isEditing}
                        >
                          <option value="">Select Country</option>
                          {countryNames.map((country, index) => (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                        {isEditing && (
                          <svg
                            style={{ position: "absolute", left: "386px", pointerEvents: "none" }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                              fill="black"
                            />
                          </svg>
                        )}
                      </div>
                    </div>

                    <div className={styles.rightContainer}>
                      <div className={styles.formElement2}>
                        <label style={{ width: "150px" }}>Visa {num} Expiry</label>
                        {isEditing ? (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomDatePicker
                              value={
                                formData[`Active_Visas_${num}_Expiry`]
                                  ? dayjs(formData[`Active_Visas_${num}_Expiry`], "DD/MM/YYYY")
                                  : null
                              }
                              onChange={(value) =>
                                handleDateChange(`Active_Visas_${num}_Expiry`, value)
                              }
                              sx={{
                                backgroundColor: "white",
                                borderRadius: "8px",
                              }}
                              format="DD/MM/YYYY"
                              className={isEditing ? null : styles.textbox1}
                              disabled={!isEditing}
                            />
                          </LocalizationProvider>
                        ) : (
                          <input
                            type="text"
                            name={`Active_Visas_${num}_Expiry`}
                            value={
                              formData[`Active_Visas_${num}_Expiry`]
                                ? dayjs(formData[`Active_Visas_${num}_Expiry`], "DD/MM/YYYY").format("DD/MM/YYYY")
                                : ""
                            }
                            className={isEditing ? null : styles.textbox1}
                            disabled
                          />
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              ))}
            </div>

            {isEditing && (
              <button
                type="button"
                className={styles.addButton}
                style={{ marginLeft: "10px", marginBottom: "20px" }}
                onClick={handleAddVisa}
              >
                + Add Visa
              </button>
            )}

            {/* LOI */}
            <h5 style={{
              marginLeft: "10px",
              marginBottom: "5px",
              marginTop: "20px",
              textDecoration: "underline",
              color: "#5b6be1",
            }}>
              LOI
            </h5>

            <div className={styles.loiContainer}>
              {[1, 2, 3, 4].slice(0, loiRowCount).map((num, index) => (
                <div key={`LOI_${num}`} className={styles.loiRow}>
                  {/* <h5 style={{marginTop: "10px", marginLeft: "10px"}}>LOI {num}</h5> */}

                  <div className={styles.container1}>
                    <div className={styles.leftContainer}>
                      <div className={styles.formElement2}>

                        {loiRowCount > 1 && isEditing && (
                          <svg
                            onClick={() => handleRemoveLOI(index)}
                            style={{ cursor: "pointer", marginRight: "20px" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        )}

                        <label>LOI {num}</label>
                        <div className={styles.fileInputWrapper}>
                          {/* Hidden file input */}
                          <input
                            type="file"
                            name={`LOI_${num}_Doc`}
                            onChange={handleFileChange}
                            id={`LOI_${num}_Doc`}
                            style={{ display: "none" }}
                            className={isEditing ? styles.fileUpload : styles.textbox1}
                            disabled={!isEditing}
                          />
                          {/* Display file name in a read-only text input */}
                          <input
                            type="text"
                            readOnly
                            className={isEditing ? styles.fileUpload : styles.textbox1}
                            value={
                              files[`LOI_${num}_Doc`]
                                ? files[`LOI_${num}_Doc`].name
                                : formData[`LOI_${num}_Doc`]
                                  ? formData[`LOI_${num}_Doc`].split("/").pop().replace(/^\d+-/, "") // Extract file name from URL
                                  : ""
                            }
                            onClick={() =>
                              isEditing && document.getElementById(`LOI_${num}_Doc`).click()
                            }
                          />
                          {isEditing && (
                            <svg
                              width="28"
                              height="25"
                              viewBox="0 0 28 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                position: "absolute",
                                left: "390px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                            >
                              <path
                                d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                fill="black"
                              />
                              <path
                                d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                fill="black"
                              />
                            </svg>
                          )}

                          {/* If a file exists (either a new one or from existing data), show the buttons */}
                          {(files[`LOI_${num}_Doc`] || formData[`LOI_${num}_Doc`]) && (
                            <div className={styles.fileActions}>
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                                alt="preview"
                                width="40"
                                height="40"
                                style={{ marginLeft: "4px", cursor: "pointer" }}
                                onClick={() =>
                                  handleViewFile(
                                    files[`LOI_${num}_Doc`] || formData[`LOI_${num}_Doc`]
                                  )
                                }
                              />

                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                                alt="preview"
                                width="40"
                                height="40"
                                style={{ marginLeft: "2px", cursor: "pointer" }}
                                onClick={() =>
                                  handleDownloadFile(
                                    files[`LOI_${num}_Doc`] || formData[`LOI_${num}_Doc`]
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.rightContainer}>
                      <div className={styles.formElement2}>
                        <label>LOI {num} Country</label>
                        <select
                          name={`LOI_${num}_Country`}
                          value={formData[`LOI_${num}_Country`] || ""}
                          onChange={handleOnChange}
                          className={isEditing ? null : styles.textbox1}
                          disabled={!isEditing}
                        >
                          <option value="">Select Country</option>
                          {countryNames.map((country, index) => (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                        {isEditing && (
                          <svg
                            style={{ position: "absolute", left: "386px", pointerEvents: "none" }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                              fill="black"
                            />
                          </svg>
                        )}
                      </div>
                    </div>

                    <div className={styles.rightContainer}>
                      <div className={styles.formElement2}>
                        <label>LOI {num} Expiry</label>
                        {isEditing ? (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomDatePicker
                              value={
                                formData[`LOI_${num}_Expiry`]
                                  ? dayjs(formData[`LOI_${num}_Expiry`], "DD/MM/YYYY")
                                  : null
                              }
                              onChange={(value) =>
                                handleDateChange(`LOI_${num}_Expiry`, value)
                              }
                              sx={{
                                backgroundColor: "white",
                                borderRadius: "8px",
                              }}
                              format="DD/MM/YYYY"
                              className={isEditing ? null : styles.textbox1}
                              disabled={!isEditing}
                            />
                          </LocalizationProvider>
                        ) : (
                          <input
                            type="text"
                            name={`LOI_${num}_Expiry`}
                            value={
                              formData[`LOI_${num}_Expiry`]
                                ? dayjs(formData[`LOI_${num}_Expiry`], "DD/MM/YYYY").format("DD/MM/YYYY")
                                : ""
                            }
                            className={isEditing ? null : styles.textbox1}
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {isEditing && (
              <button
                type="button"
                className={styles.addButton}
                style={{ marginLeft: "10px" }}
                onClick={handleAddLOI}
              >
                + Add LOI
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
