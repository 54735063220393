import React, { useEffect, useState } from 'react'
import { Stepper, Step, StepLabel, Box } from '@mui/material';
import styles from "./css/production_update.module.css"; // css for the Material Produced screen
import styles2 from "../Components/css/production_update_2.module.css"; // css for the Material Consumed screen
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Production_Update() {
  const { project_id, production_id } = useParams(); // Production ID taken from URL
  const navigate = useNavigate();

  const [formDataProduced, setFormDataProduced] = useState([]); // to store the "Material Produced" table data
  const [prodDetails, setProdDetails] = useState({});
  const [consumedItems, setConsumedItems] = useState([]);   //to store all consumed items
  const [filteredItems, setFilteredItems] = useState([]);   //to store csp consumed items
  const [inventoryUtilisedItems, setInventoryUtilisedItems] = useState([]);   //to store ergon inventory consumed items
  const [isCsp, setIsCsp] = useState(true);
  const [mappedValues, setMappedValues] = useState({});
  const [billEntries, setBillEntries] = useState({});

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == project_id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
      });
      console.log(filter);
      console.log(project_id);
    };
    fetchProject();
  }, [project_id]);

  const Project_SO = formdata1.salesOrderNo; // Project_SO taken from formdata1

  useEffect(() => {
    const fetchProductionDetails = async () => {
      const res = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/production_details_update_get?Production_ID=${production_id}`);
      const data = res.data.data[0];
      setProdDetails(data);
    };
    fetchProductionDetails();
  }, [production_id]);

  useEffect(() => {
    const fetchMaterialProduced = async () => {
      const res = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_produced_update_get?Production_ID=${production_id}`);
      setFormDataProduced(res.data.data);
    };
    fetchMaterialProduced();
  }, [production_id]);

  useEffect(() => {
    async function fetchConsumptions() {
      try {
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/material_consumed_update_get?Production_ID=${production_id}`
        );
        const itemsReceivedData = res1.data.data;
        console.log("itemsReceivedData", itemsReceivedData);
        // setFilteredItems(itemsReceivedData.filter(item => item.Consumption_Type === 'CSP'));
        // setInventoryUtilisedItems(itemsReceivedData.filter(item => item.Consumption_Type === 'Inventory'));
        setConsumedItems(itemsReceivedData);
        const newMappedValues = {};
        itemsReceivedData.forEach((item) => {
          const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
          newMappedValues[key] = item.Total_Qty_Used;
        });
        setMappedValues(newMappedValues);
      } catch (error) {
        console.error(error);
      }
    }
    fetchConsumptions();
  }, [production_id]);

  useEffect(() => {
    async function fetchDetails() {
      try {
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get21_updatepage?Project_ID=${project_id}`
        );
  
        const itemsReceivedData = res1.data.data;
        console.log("itemsReceivedData aaaaaaaaaaaaa", itemsReceivedData);
  
        const filteredItems = itemsReceivedData.filter((item) => {
          const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
          const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
          const isInConsumedItems = consumedItems.some(
            (consumedItem) =>
              consumedItem.Item_Name === item.Item_Name &&
              consumedItem.Part_Number === item.Part_Number &&
              String(consumedItem.Incoming_Delivery_ID) === String(item.Incoming_Delivery_ID)
            );
          return isInConsumedItems;
        });
  
        // Set the state for items received
        setFilteredItems(filteredItems);
      } catch (error) {
        console.error(error);
      }
    }
    if (consumedItems.length > 0) {
      fetchDetails();
    }
  }, [mappedValues, consumedItems]);
  
  useEffect(() => {
    async function fetchDetails() {
      try {
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_inventory_utilised_updatepage?Project_SO=${Project_SO}`
        );
  
        const itemsReceivedData2 = res1.data.data;
        console.log("itemsReceivedData bbbbbbbbbbb", itemsReceivedData2);

        const filteredItems = itemsReceivedData2.filter((item) => {
          const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
          const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
          const isInConsumedItems = consumedItems.some(
            (consumedItem) =>
              consumedItem.Item_Name === item.Item_Name &&
              consumedItem.Part_Number === item.Part_Number &&
              String(consumedItem.Incoming_Delivery_ID) === String(item.Incoming_Delivery_ID)
          );
          return isInConsumedItems;
        });
  
        // Set the state for items received
        setInventoryUtilisedItems(filteredItems);
      } catch (error) {
        console.error(error);
      }
    }
    if (consumedItems.length > 0) {
      fetchDetails();
    }
  }, [Project_SO, mappedValues, consumedItems]);
  
  console.log("filteredItems", filteredItems);
  console.log("filteredItems", filteredItems);
  console.log("inventoryUtilisedItems", inventoryUtilisedItems);
  console.log("mappedValues", mappedValues);


  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries2"
        );
        if (response.data.success) {
          const hashArray = response.data.data.reduce((acc, current) => {
            acc[current.S_No] = current.Entry_Bill_No;
            return acc;
          }, {});
          setBillEntries(hashArray);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDeliveries();
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleBackButton = () => {
    navigate(`/production-workshop/${project_id}`);
  };

  // Stepper states and functions
  const steps = [
    { label: 'Material Produced' },
    { label: 'Material Consumed' }
  ];

  const [activeStep, setActiveStep] = useState(0); // to store the activeStep for the stepper and to render the tables

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInventoryClick = () => {
    setIsCsp(false);
  };

  const handleCspClick = () => {
    setIsCsp(true);
  };

  return (
    <div>
      <div className={styles.topButtonContainer}>
        <button
          className={styles.topBackButton}
          onClick={handleBackButton}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
              fill="#5B6BE1"
            />
          </svg>
          {' '}Production / Material Transformation
        </button>

        <Box sx={{ width: '50%', mt: "8px" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  sx={{
                    fontWeight: 'bold',
                    color: '#5B6BE1',
                    '& .MuiStepLabel-label': {
                      mt: '6px !important',
                      fontWeight: 'bold !important',
                      color: '#5B6BE1 !important',
                    },
                    '& .MuiStepLabel-label.Mui-active': {
                      fontWeight: 'bold !important',
                      color: '#5B6BE1 !important',
                    },
                    '& .MuiStepLabel-label.Mui-completed': {
                      fontWeight: 'bold !important',
                      color: '#5B6BE1 !important',
                    },
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>

      <div className={styles.topdiv}>
        <div className={styles.topsubdiv}>
          <label className={styles.projectDiv}>
            Project SO: {formdata1.salesOrderNo}
          </label>
          <label className={styles.projectDiv}>
            Project Design: {formdata1.Design}
          </label>
          <label className={styles.projectDiv}>
            Project Name: {formdata1.ProjectName}
          </label>
          <label className={styles.projectDiv}>
            Project Scope: {formdata1.Scope}
          </label>
        </div>
      </div>

      <div className={styles.mainDiv}>
        <div className={styles.buttonsdiv} style={{ minHeight: "63px", marginTop: "15px" }}>
          {activeStep === 0 &&
            <>
              <div style={{ display: "flex", flexDirection: "column", flex: "1", justifyContent: "center", color: "#5B6BE1", marginLeft: "2%" }}>
                <h4 style={{ borderBottom: "2px solid #5B6BE1", width: "fit-content", fontWeight: "bold" }}>
                  Material Produced
                </h4>
              </div>
              <button
                className={styles.buttonsDivButton}
                onClick={handleNextStep}
              >
                Next
              </button>
            </>
          }
          {activeStep === 1 &&
            <>
              {isCsp ? (
                <>
                  <div
                    style={{
                      marginTop: "0px",
                      display: "flex",
                      flexDirection: "row",
                      flex: "1",
                    }}
                  >
                    <button
                      className={`${styles2["incomingButton"]} ${isCsp ? styles2["incoming"] : styles2["not-incoming"]}`}
                      onClick={handleCspClick}
                    >
                      CSP
                    </button>
                    <button
                      className={`${styles2["outgoingButton"]} ${!isCsp ? styles2["outgoing"] : styles2["not-outgoing"]}`}
                      onClick={handleInventoryClick}
                    >
                      Inventory
                    </button>
                  </div>

                  <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]}`}
                    onClick={handleBackStep}>
                    Back
                  </button>
                </>
              ) : (
                <>
                  <div
                    style={{
                      marginTop: "0px",
                      display: "flex",
                      flexDirection: "row",
                      flex: "1",
                    }}
                  >
                    <button
                      className={`${styles2["incomingButton"]} ${isCsp ? styles2["incoming"] : styles2["not-incoming"]}`}
                      onClick={handleCspClick}
                    >
                      CSP
                    </button>
                    <button
                      className={`${styles2["outgoingButton"]} ${!isCsp ? styles2["outgoing"] : styles2["not-outgoing"]}`}
                      onClick={handleInventoryClick}
                    >
                      Inventory
                    </button>
                  </div>

                  <button
                    variant="contained"
                    className={`${styles["buttonsDivButton"]} ${styles["cancel"]}`}
                    onClick={handleBackStep}>
                    Back
                  </button>
                </>
              )}
            </>
          }
        </div>

        {activeStep === 0 &&
          <>
            <div className={styles.buttonsdiv}>
              <div style={{ display: "flex", flexDirection: "row", flex: "1", color: "#5B6BE1", marginLeft: "2%" }}>
                <h6 style={{ width: "fit-content" }}>
                  Material being produced will be* :
                </h6>
                <div
                  className={styles.optionsListInner}
                  style={{
                    pointerEvents: "none",
                    cursor: "default",
                    opacity: prodDetails.Production_Type === "WIP" ? 1 : 0.4,
                  }}
                >
                  <input
                    type="radio"
                    name="Production_Type"
                    value="WIP"
                    checked={prodDetails.Production_Type === "WIP"}
                    readOnly
                  />
                </div>
                <h6 style={{ whiteSpace: "nowrap", marginLeft: "8px", opacity: formdata1.Scope === "Assembly" ? 0.4 : 1 }}>WIP material</h6>
                <div
                  className={styles.optionsListInner}
                  style={{
                    pointerEvents: "none",
                    cursor: "default",
                    opacity: prodDetails.Production_Type === "FG" ? 1 : 0.4,
                  }}
                >
                  <input
                    type="radio"
                    name="Production_Type"
                    value="FG"
                    checked={prodDetails.Production_Type === "FG"}
                    readOnly
                  />
                </div>
                <h6 style={{ whiteSpace: "nowrap", marginLeft: "8px", opacity: formdata1.Scope === "D&I" ? 0.4 : 1 }}>Finished goods (to be dispatched to customer)</h6>
              </div>
            </div>
            <div className={styles.buttonsdiv}
              style={{
                marginTop: "45px",
              }}
            >
              <div style={{ display: "flex", flex: "1", color: "#5B6BE1", marginLeft: "2%" }}>
                <h4 style={{ borderBottom: "2px solid #5B6BE1", width: "fit-content", fontWeight: "bold" }}>
                  Details
                </h4>
              </div>
            </div>

            <div className={styles.smtablecontainer}>
              <table className={styles.smtable}>
                <thead>
                  <tr className={styles.headrowsm}>
                    <th className={styles.fileUploadheading}>S.No</th>
                    <th className={styles.fileUploadheading}>Item Name</th>
                    <th className={styles.fileUploadheading}>Part Number</th>
                    {!(formdata1.Scope === "D&I") && (
                      <th className={styles.fileUploadheading}>Ergon Traceability Number</th>
                    )}
                    {(formdata1.Scope === "D&I") && (
                      <>
                        <th className={styles.fileUploadheading}>Part Traceability Number</th>
                        <th className={styles.fileUploadheading}>Equipment Traceability Number</th>
                      </>
                    )}
                    <th className={styles.fileUploadheading}>Qty</th>
                    <th className={styles.fileUploadheading}>UoM</th>
                  </tr>
                </thead>
                <tbody>
                  {formDataProduced.map((item, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{item.Item_Name || '-'}</td>
                      <td>{item.Part_Number || '-'}</td>
                      {!(formdata1.Scope === "D&I") && (
                        <td>{item.Ergon_Traceability_Number || '-'}</td>
                      )}
                      {(formdata1.Scope === "D&I") && (
                        <>
                          <td>{item.Part_Traceability_Number || '-'}</td>
                          <td>{item.Equipment_Traceability_Number || '-'}</td>
                        </>
                      )}
                      <td>{item.Qty_Produced || '-'}</td>
                      <td>{item.UoM || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        }

        {activeStep === 1 &&
          <div style={{ marginLeft: "2vw" }}>
            {isCsp ? (
              <>
                <div className={styles2.itemstablecontainer}>
                  <table className={styles2.itemstable}>
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Item Name</th>
                        <th>Part Number</th>
                        <th>UoM</th>
                        <th>BOE</th>
                        <th>Traceability Number</th>
                        <th>Available Qty</th>
                        <th>Qty Used</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredItems.map((item, index) => {
                        const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
                        const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
                        return (
                          <tr key={key}>
                            <td>{index + 1}</td>
                            <td>{item.Item_Name}</td>
                            <td>{item.Part_Number}</td>
                            <td>{item.UOM}</td>
                            <td>{billEntries[item.Incoming_Delivery_ID]}</td>
                            <td>{item.Traceability_Number}</td>
                            <td>{item.Quantity_Received - totalQtyUsed}</td>
                            <td>{totalQtyUsed}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className={styles2.itemstablecontainer}>
                  <table className={styles2.itemstable}>
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Item Name</th>
                        <th>Part Number</th>
                        <th>UoM</th>
                        <th>BOE</th>
                        <th>Traceability Number</th>
                        <th>Available Qty</th>
                        <th>Qty Used</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inventoryUtilisedItems.map((item, index) => {
                        const key = `${item.Item_Name}_/${item.Part_Number}_/${item.Incoming_Delivery_ID}`;
                        const totalQtyUsed = mappedValues[key] || 0; // Use 0 if the key doesn't exist
                        return (
                          <tr key={key}>
                            <td>{index + 1}</td>
                            <td>{item.Item_Name}</td>
                            <td>{item.Part_Number}</td>
                            <td>{item.UoM}</td>
                            <td>{billEntries[item.Incoming_Delivery_ID]}</td>
                            <td>{item.Traceability_Number}</td>
                            <td>{item.Qty_Utilized - totalQtyUsed}</td>
                            <td>{totalQtyUsed}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        }
      </div>
    </div>
  )
}

export default Production_Update
