import React from "react";
import styles from "./css/live.module.css";
import { useEffect, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import UploadDocument from "./Uploaddocument";
import axios from "axios";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import BasicModal from "./Modal";
import { useNavigate, useParams, useLocation, NavLink } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function TimesheetWorkshop() {
    const { id } = useParams();
    const [workshop, setWorkshop] = useState(3);
    const [projectList, setProjectList] = useState({});
    const [uniquedates, setuniquedates] = useState([]);
    const [filtereddatee, setfiltereddatee] = useState([]);
    const [filteredtime, setfilteredtime] = useState([]);
    const [fildata, setfildata] = useState([]);
    const [datearray, setdatearray] = useState([]);
    const [man, setman] = useState([]);
    const [maindate, setmaindate] = useState('');
    const [maindate2, setmaindate2] = useState('');
    const [sum, setsum] = useState('');
    const [fildatatool, setfildatatool] = useState([]);
    const [datatool, setdatatool] = useState([]);

    const [popupdate, setpopupdate] = useState();
    const [popupdate2, setpopupdate2] = useState();
    const navigate = useNavigate();
    const handleDivClick = () => {
        navigate(`/project-checklist-workshop/${id}`);
    };

    const handleDivClick1 = () => {
        navigate(`/project-details-workshop/${id}`);
    };
    const handleDivClick2 = () => {
        navigate(`/timesheet-workshop/${id}`);
    };

    const handleDivClick3 = () => {
        navigate(`/incoming-deliveries-workshop/${id}`);
    };

    const handleDivClick4 = () => {
        navigate(`/outgoing-deliveries-workshop/${id}`);
    };

    const handleDivClick14 = () => {
        navigate(`/project-timeline-workshop/${id}`);
    }
    const handleDivClick5 = () => {
        navigate(`/outsourcing-workshop/${id}`);
    };

    const handleDivClick6 = () => {
        navigate(`/reports-workshop/${id}`);
    };

    const handleDivClick7 = () => {
        navigate(`/inspections-workshop/${id}`);
    };
    const handleDivClick8 = () => {
        navigate(`/items-received-workshop/${id}`);
    };
    const handleDivClick9 = () => {
        navigate(`/finished-goods-workshop/${id}`);
    };
    const handleDivClick10 = () => {
        navigate(`/team-details-workshop/${id}`);
    };
    const handleDivClick12 = () => {
        navigate(`/inventory-used-workshop/${id}`);
    };
    const handleDivClick13 = () => {
        navigate(`/purchases-workshop/${id}`);
    };
    const handleDivClick15 = () => {
        navigate(`/production-workshop/${id}`)
    };
    const handleDivClick16 = () => {
        navigate(`/Wip-workshop/${id}`);
    };
    const handleDivClick23 = () => {
        navigate(`/item-details-workshop/${id}`);
    };

    const [formdata1, setFormData1] = useState({
        salesOrderNo: "",
        PONO: "",
        PODays: "",
        PODate: "",
        QuoteNO: "",
        QuoteDate: "",
        CostingID: "",
        ProjectName: "",
        Scope: "",
        EstimatedDateCommencement: "",
        CustomerName: "",
        FirstArrival: "",
        TargetDaysForCompletion: "",
        TargetManhours: "",
        opsEngineer: "",
        quantity: "",
        projectDescription: "",
        currentStatus: "",
        design: "",
    });
    useEffect(() => {
        const fetchProject = async () => {
            const res = await axios.get(
                "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
            );
            const filter = res.data.data.filter((el) => el.ID_No == id);

            setFormData1({
                ...formdata1,
                salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
                PONO: filter[0]?.PO_No?.toString(),
                PO_Date: filter[0]?.PODate,
                QuoteNo: filter[0]?.Quote_No,
                QuoteDate: filter[0]?.Quote_Date,
                CostingID: filter[0]?.Costing_ID,
                ProjectName: filter[0]?.Project_Name,
                Scope: filter[0]?.Scope,
                projectDescription: filter[0]?.Project_Description,
                quantity: filter[0]?.Quantity,
                CustomerName: filter[0]?.Customer_Name,
                FirstArrival: filter[0]?.First_Arrival,
                TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
                opsEngineer: filter[0]?.Ops_Engineer,
                TargetManhours: filter[0]?.Target_Manhours,
                currentStatus: filter[0]?.Current_Status,
                design: filter[0]?.Design,
            });
            console.log(filter);
            console.log(id);
            setProjectList(filter[0]);
        };
        fetchProject();
    }, [id]);


    // useEffect(() => {
    //     async function fetchRigggDetails() {
    //       try {
    //         const response = await axios.get(
    //           "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheetwork_data"
    //         );

    //         console.log("Response:", response.data); // Log the response to check if it's received correctly

    //         const filteredData = response.data.data.filter(el => el.Project === formdata1?.ProjectName+"-"+ formdata1?.Scope+"-"+formdata1?.salesOrderNo);
    //         setfilteredtime(filteredData);
    //         setdatearray(filteredData.map(item => item.Date));
    //         setman(filteredData.map(item => item.Manhours));


    //         const alpha = filteredData.map(item => item.Date).sort((a, b) => {
    //           const dateA = new Date(b.split('/').reverse().join('/'));
    //           const dateB = new Date(a.split('/').reverse().join('/'));
    //           return dateA - dateB;
    //         });



    //         setuniquedates([...new Set(alpha)]);
    //         setfiltereddatee([...new Set(alpha)]);

    //         setsum(filteredData.map(item => item.Manhours).reduce((acc, current) => acc + current, 0));

    //       } catch (error) {
    //         console.error("Error:", error); // Log any errors
    //       }
    //     }

    //     fetchRigggDetails();
    //   }, [formdata1]);


    // useEffect(() => {
    //     async function fetchRigggDetails() {
    //       try {
    //         const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheetwork_data");
    //         console.log("Response:", response.data);

    //         // Log all data to understand what's coming from the API
    //         console.log("All Data:", response.data.data);

    //         const filteredData = response.data.data.filter(el => el.Project === `${formdata1?.ProjectName}-${formdata1?.Scope}-${formdata1?.salesOrderNo}`);
    //         console.log("Filtered Data:", filteredData);

    //         const manhoursArray = filteredData.map(item => parseFloat(item.Manhours));
    //         console.log("Manhours Array:", manhoursArray);

    //         const totalManhours = manhoursArray.reduce((acc, current) => acc + (isNaN(current) ? 0 : current), 0);
    //         console.log("Total Manhours:", totalManhours);

    //         setfilteredtime(filteredData);
    //         setdatearray(filteredData.map(item => item.Date));
    //         setman(manhoursArray);

    //         const alpha = filteredData.map(item => item.Date).sort((a, b) => {
    //           const dateA = new Date(b.split('/').reverse().join('/'));
    //           const dateB = new Date(a.split('/').reverse().join('/'));
    //           return dateA - dateB;
    //         });

    //         setuniquedates([...new Set(alpha)]);
    //         setfiltereddatee([...new Set(alpha)]);

    //         setsum(totalManhours);

    //       } catch (error) {
    //         console.error("Error:", error);
    //       }
    //     }

    //     fetchRigggDetails();
    //   }, [formdata1]);


    useEffect(() => {
        async function fetchRigggDetails() {
            try {
                const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheetwork_data");
                console.log("Response:", response.data);

                // Log all data to understand what's coming from the API
                console.log("All Data:", response.data.data);

                const filteredData = response.data.data.filter(el => {
                    const projectName = `${formdata1?.design}-${formdata1?.ProjectName}-${formdata1?.Scope}-${formdata1?.salesOrderNo}`;
                    console.log(projectName)
                    return el.Project === projectName;
                });

                console.log("Filtered Data:", filteredData);

                const manhoursArray = filteredData.map(item => item.Manhours ? parseFloat(item.Manhours) : 0);
                console.log("Manhours Array:", manhoursArray);

                const totalManhours = manhoursArray.reduce((acc, current) => acc + (current ? current : 0), 0);
                console.log("Total Manhours:", totalManhours);

                setfilteredtime(filteredData);
                setdatearray(filteredData.map(item => item.Date));
                setman(manhoursArray);

                const alpha = filteredData.map(item => item.Date).sort((a, b) => {
                    const dateA = new Date(b.split('/').reverse().join('/'));
                    const dateB = new Date(a.split('/').reverse().join('/'));
                    return dateA - dateB;
                });

                setuniquedates([...new Set(alpha)]);
                setfiltereddatee([...new Set(alpha)]);

                setsum(totalManhours);

            } catch (error) {
                console.error("Error:", error);
            }
        }

        fetchRigggDetails();
    }, [formdata1]);









    const handlepop = (date) => {

        setpopupdate(date);
        setfildata(filteredtime.filter(el => el.Date === date));

    }


    const handlecrss = () => {
        setpopupdate();
        setfildata();
    };

    const handledate = (e) => {
        const dateParts = e.target.value.split("-");
        const [year, month, day] = dateParts;
        const formattedDate = `${day}/${month}/${year}`;
        setmaindate(formattedDate);
        setmaindate2(e.target.value);
        console.log(filtereddatee.filter(el => el === formattedDate));
        setfiltereddatee(uniquedates.filter(el => el === formattedDate));
    }

    useEffect(() => {
        async function fetchRigDetails() {
            try {
                const response = await axios.get(
                    "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolbox_data"
                );



                const data = response.data.data; // Assuming the API response is an array of objects
                setdatatool(response.data.data);



            } catch (error) {
                console.error(error);
            }
        }

        fetchRigDetails();
    }, []);


    const handlepop2 = (date) => {
        setpopupdate2(date);
        setfildatatool(datatool.filter(el => el.Date === date));
        console.log("im clicked");

    }


    const handlecrss2 = () => {
        setpopupdate2();
        setfildatatool();
    };

    const handlerefreshsvg = () => {

        setfiltereddatee(uniquedates);
        setmaindate2('');
    }

    return (
        <div>
            <div className={styles.topdiv}>
                <label className={styles.aryl1}>
                    Project Name: {formdata1.ProjectName}
                </label>
                <label className={styles.aryl}>Project Scope:{formdata1.Scope}</label>
                <label className={styles.aryl}>
                    Project Description:{formdata1.projectDescription}
                </label>
                <label className={styles.aryl}>Quantity:{formdata1.quantity}</label>
                <label className={styles.aryl}>
                    Target Days:{formdata1.TargetDaysForCompletion}
                </label>
                <label className={styles.aryl}>
                    Target Manhours:{formdata1.TargetManhours}
                </label>
                <label className={styles.aryl}>
                    Total Manhours spent: {sum}
                </label>
            </div>
            <div className={styles.aryTabs}>
                <div
                    className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick}>
                    Project Checklist
                </div>
                <div
                    className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick1}>
                    Project Details
                </div>
                <div
                    className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick10}
                >
                    Team Details
                </div>
                <div
                    className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick12}>
                    Inventory Used
                </div>
                <div
                    className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick13}>
                    Purchases
                </div>
                <div
                    className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick2}>
                    Timesheet
                </div>
                <div
                    className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick3}>
                    Deliveries
                </div>
                <div
                    className={workshop === 23 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick23}>
                    Serial Number
                </div>
                <div
                    className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick15}>
                    Production
                </div>
                {/* <div
                    className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick4}>
                    Outgoing Deliveries
                </div> */}
                <div
                    className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick14}>
                    Timeline
                </div>
                <div
                    className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick5}>
                    Outsourcing
                </div>
                <div
                    className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick6}>
                    Reports
                </div>
                <div
                    className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick7}>
                    Inspections
                </div>
                <div
                    className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick8}>
                    Items Received
                </div>
                <div
                    className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick9}>
                    Finished goods
                </div>
                <div
                    className={workshop === 16 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick16}
                >
                    WIP
                </div>
            </div>
            {workshop === 1 && <></>}
            {workshop === 2 && <></>}
            {workshop === 3 && <></>}
            {workshop === 4 && <></>}
            {workshop === 5 && <></>}
            {workshop === 6 && <></>}
            {workshop === 7 && <></>}
            {workshop === 8 && <></>}
            {workshop === 9 && <></>}
            <div className={styles.maintimediv}>

                <h6 style={{ display: "inline-flex", marginLeft: "5%", marginTop: "40px", color: "#5B6BE1" }} >Filter by date :</h6>
                <input type="date" value={maindate2} onChange={handledate} style={{ marginLeft: "10px", paddingLeft: "10px", marginTop: "-4px", color: "#5B6BE1" }}></input>
                <svg style={{ marginLeft: "10px", paddingLeft: "10px", marginTop: "-4px" }} onClick={handlerefreshsvg} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z" /></svg>

                <h6 className={styles.maintimediv4}> <b>Total Manhours spent:</b> <p style={{ color: "#5B6BE1", marginLeft: "10px" }}> {sum} </p></h6>


                <div className={styles.mainndiv}>
                    {filtereddatee.map((date, index) => (
                        <div key={index} className={styles.div1}>
                            <p className={styles.visss}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M6 22H18C18.7956 22 19.5587 21.6839 20.1213 21.1213C20.6839 20.5587 21 19.7956 21 19V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H17V3C17 2.73478 16.8946 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3V5H9V3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2C7.73478 2 7.48043 2.10536 7.29289 2.29289C7.10536 2.48043 7 2.73478 7 3V5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V19C3 19.7956 3.31607 20.5587 3.87868 21.1213C4.44129 21.6839 5.20435 22 6 22ZM5 12.5C5 12.3674 5.05268 12.2402 5.14645 12.1464C5.24021 12.0527 5.36739 12 5.5 12H18.5C18.6326 12 18.7598 12.0527 18.8536 12.1464C18.9473 12.2402 19 12.3674 19 12.5V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V12.5Z" fill="#394759" />
                            </svg>{" "}{date}</p>
                            <p className={styles.visss3} onClick={() => handlepop(date)}><NavLink>{" "}View  Timesheet <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginLeft: "10px", marginRight: "20px" }}>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" fill="black" />
                            </svg></NavLink>{" "}{"  |  "}{" "}</p>
                            <p className={styles.visss2} onClick={() => handlepop2(date)} style={{ marginLeft: "20px" }} ><NavLink>{" "}View Toolbox Talk <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none" style={{ marginLeft: "10px" }}>
                                <path d="M9.77778 0.000318156V1.66699H12.9689L4.23111 9.85833L5.48444 11.0333L14.2222 2.84198V5.83365H16V0.000318156M14.2222 13.3333H1.77778V1.66667H7.77778V0.000318156L1.77778 0C1.30628 0 0.854097 0.175595 0.520699 0.488155C0.187301 0.800716 0 1.22464 0 1.66667V13.3333C0 13.7754 0.187301 14.1993 0.520699 14.5118C0.854097 14.8244 1.30628 15 1.77778 15H14.2222C14.6937 15 15.1459 14.8244 15.4793 14.5118C15.8127 14.1993 16 13.7754 16 13.3333V7.91666H14.2222V13.3333Z" fill="black" />
                            </svg></NavLink></p>
                        </div>
                    ))}

                </div>
                {popupdate && (
                    <div className={styles.div2}>
                        <div className={styles.modalcontent}>
                            <div style={{ backgroundColor: "#D1D9FF", marginTop: "0px", height: "50px", paddingTop: "10px", width: "30%", border: "1px solid #D1D9FF ", borderBottomRightRadius: "20px" }}>
                                <h4 style={{ marginLeft: "1%" }}>Date: {popupdate} </h4>
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={handlecrss} style={{ cursor: "pointer", marginLeft: "95%", marginTop: "-50px" }}>
                                <path opacity="0.15" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="black" />
                                <path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <table className={styles.rigtable} >
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Professional Name</th>
                                        <th>Manhours</th>
                                    </tr>
                                </thead>

                                {fildata.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.Tech}</td>
                                        <td>{item.Manhours}</td>
                                    </tr>
                                ))}


                            </table>
                        </div>
                    </div>
                )
                }
                {popupdate2 && (
                    <div className={styles.div2}>
                        <div className={styles.modalcontent}>
                            <div style={{ backgroundColor: "#D1D9FF", marginTop: "0px", height: "50px", paddingTop: "10px" }}>
                                <h4 style={{ marginRight: "73%" }}>Toolbox Talk   {popupdate2} </h4>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={handlecrss2} style={{ cursor: "pointer", marginLeft: "95%", marginTop: "-50px" }}>
                                    <path opacity="0.15" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="black" />
                                    <path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <table className={styles.rigtable} >
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Task Description</th>
                                        <th>Project</th>
                                        <th>Team Assigned</th>
                                        <th>Target units</th>
                                        <th>Achieved Units</th>
                                        <th>Reason for Shortfall</th>
                                        <th>Additional tools requested</th>
                                    </tr>
                                </thead>

                                {fildatatool.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.Task_description}</td>
                                        <td>{item.Project_so}</td>
                                        <td>{item.Team_assigned}</td>
                                        <td>{item.Target_units}</td>
                                        <td>{item.Achieved_Units}</td>
                                        <td>{item.Reason}</td>
                                        <td>{item.Additional_Tools}</td>
                                    </tr>
                                ))}


                            </table>
                        </div>
                    </div>
                )
                }

            </div>
        </div>
    );
}
export default TimesheetWorkshop;