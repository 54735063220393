import React, { useState, useEffect ,useContext } from 'react';
import styles from '../Components/css/dashboardalerts.module.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { MyContext } from "../services/provider"
import { Scrollbar } from 'react-scrollbars-custom';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const Dashboardalerts = () => {
  const [notifications, setNotifications] = useState([]);
  const [pdata, setPdata] = useState([]);
  const navigate = useNavigate();
  const [tabno, setTabno] = useState(2);
  const userEmail = localStorage.getItem('useremail');
  const { notificationData,sendNotificationToAll } = useContext(MyContext);

  useEffect(() => {
    const fetchProjectdetails = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
        );
        const data = response.data.data;
        setPdata(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProjectdetails();
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details_notification"
        );
        const n = response.data.data;
        n.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
        setNotifications(n.filter(notif => notif.User_Email === userEmail));
      } catch (error) {
        console.error(error);
      }
    };
    fetchNotifications(); // Fetch initially
  }, [userEmail]);

  const handleDivClick = () => {
    setTabno(1);
    navigate(`/dashboard`);
  };

  const handleDivClick1 = () => {
    setTabno(2);
    navigate(`/dashboard-alerts`);
  };

  function getRigNameById(rigs, id) {
    const rig = rigs.find(rig => rig.ID_No === id);
    return rig ? rig.Rig_Name : null;  // Returns Rigname if found, otherwise null
  }

  function getRigSONOById(rigs, id) {
    const rig = rigs.find(rig => rig.ID_No === id);
    return rig ? rig.Sales_Order_No : null;  // Returns Sales Order No if found, otherwise null
  }

  return (
    <div className={styles.maindiv}>
      <div className={styles.aryTabs}>
        <div
          className={tabno === 1 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick}>
          Dashboard
        </div>
        <div
          className={tabno === 2 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick1}>
          Alerts
        </div>
      </div>
      <div className={styles.innerdiv} style={{
      
      
        marginLeft: "10px",
        paddingLeft: "-6px",
        borderRadius:"10px",
        overflow:"scroll"
      }}>
       <Scrollbar style={{ height: "calc(100% - 50px)", marginTop: "10px" }}>
<div style={{ display: "flex", gap: "5px" }}>
          <div style={{
            marginLeft: "40px",
            marginTop: "6px",
            fontSize: "24px",
            fontWeight: "700",
            alignItems: "center",
            color: "#5B6BE1",
          
          }}>Alerts</div>
          <div style={{  marginTop: "6px",fontSize: "20px" }}>
            ⚠️
          </div>
        </div>
        <div style={{ marginTop: "10px", borderTop: "1px solid #D1D9FF", marginBottom: "10px" }} className="alerts-container">
          <ul style={{ paddingLeft: "0rem", paddingRight: "1rem", paddingTop: "8px" }}>
            {notifications.map((item, index) => (
              <li key={index} className={`${styles.alert}`}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex" ,marginTop:"0px"}}>
                    <svg height="10" width="20" style={{ marginTop: '8px' }} xmlns="http://www.w3.org/2000/svg">
                      <circle r="5" cx="5.5" cy="5" fill="#5B6BE1" />
                    </svg>
                    <div style={{ width: "70%" ,whiteSpace:"nowrap",marginTop:"0px"}}>
                      <p>Commencement date for Rig
                        <b> {getRigNameById(pdata, item.Project_ID)}</b>,
                        SO
                        <b> {getRigSONOById(pdata, item.Project_ID)}</b> changed from <b>
                        {item.Previous_Commencement_Date}</b> to <b>{item.New_Commencement_Date}</b> due to <b>
                        {item.Reason_For_Change}</b> </p>
                    </div>
                  </div>
                  <div style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                    <p>{item.Done_By}&nbsp;&bull;&nbsp;
                      <span>{new Date(item.Timestamp).toLocaleString('en-US', { month: 'long', day: 'numeric' })}</span>
                      &nbsp;&bull;&nbsp;
                      <span>{new Date(item.Timestamp).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}</span>
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        </Scrollbar>
      </div>
    </div>
  )
}

export default Dashboardalerts;
