import React, { useState, useEffect } from 'react';
import styles from '../Components/css/Travelattendance.module.css';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import { styled } from '@mui/system';
import axios from "axios";
import Select from 'react-select';

import { NavLink } from "react-router-dom";

import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';




const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
        backgroundColor: "#DADCE0",
        width: "150px",

    },
    "& .MuiOutlinedInput-root": {
        height: "38px",



        "&:hover": {
            "& fieldset": {
                borderColor: "#DADCE0",


            },
        },
        "&:not(.Mui-focused)": {
            "& fieldset": {
                borderColor: "#DADCE0",
                borderRadius: "8px",
                border: "1px solid #5B6BE1",
            },
        },
        "&.Mui-focused": {
            "& fieldset": {
                borderColor: "#DADCE0",
                borderRadius: "8px",

            },
        },
    },
}));



const Travelattendance = () => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const navigate = useNavigate();
    const [person, setPerson] = useState([]);
    const [tabno, setTabno] = useState(1);
    const [attendancestatus, setattendancestatus] = useState('');
    const [techname, settechname] = useState('');
    const [remarks, setremarks] = useState('');
    const [date, setdate] = useState('');
    const currentDate = new Date();



    const formattedDate = dayjs(currentDate).format('DD/MM/YYYY');
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(
                    "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/person_names"
                );
                console.log(response.data);
                setPerson(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchProjects();
    }, []);

    const handlechange = (selectedOption) => {
  
        setattendancestatus(selectedOption.value);
    }
    const handlechange2 = (e) => {
        const value = e.target.value;
        console.log(value);
        settechname(value);
    }
    const handleSelectChange = (selectedOption) => {
        settechname(selectedOption.value);
    };


    const handlechange3 = (e) => {
        const remark = e.target.value;
        console.log(remark);
        setremarks(remark);
    }

    const handleaddtravel = async () => {
        if (isChecked === false) {
            alert("Please confirm the checkbox to save details");
            return;
        }
        if (!techname) {
            alert("Invalid Tech Name");
            return;
        }
        if (!date) {
            alert("Please select date");
            return;
        }
        if (!attendancestatus) {
            alert("Invalid attendance status");
            return;
        }

        const payload = {
            Tech: techname,
            Date: date,
            Attendancestatus: attendancestatus,
            Remarks: remarks,
        };
        try {
            const res = await axios.post(
                "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshoptravellog",
                payload
            );
        } catch (error) {
            console.error("Error occurred while making POST request:", error);
        }
        navigate(`/travelattendance_view`);

    }

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            whiteSpace: 'pre-wrap', // Allow content to break at any space or newline
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 3, // Limit to 3 lines
            '-webkit-box-orient': 'vertical',
        },
    }));





    const handlechangedate = (name, value) => {
        const selectedDate = value?.format("YYYY-MM-DD");
        console.log(selectedDate);
        setdate(selectedDate)

    }
    const optionss = [
      { value: 'Travel', label: 'Travel' },
      { value: 'Standby', label: 'Standby' }
    ];
    const handleDivClick = () => {
        navigate(`/travelattendance_createnew`);
    };

    const handleDivClick1 = () => {
        navigate(`/travelattendance_view`);
    };

    const handleexit = async () => {
        navigate(`/admintaskbar`);
    };




    return (
        <div className={styles.maindiv}  >

            <div className={styles.backsvg}>
                <NavLink to="/admintaskbar"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                    <path d="M0.939342 10.9393C0.353556 11.5251 0.353556 12.4749 0.939342 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939342 10.9393ZM26 10.5L2 10.5V13.5L26 13.5V10.5Z" fill="white" />
                </svg></NavLink>
                <h5>Travel Attendance</h5>
            </div>
            <div className={styles.aryTabs}>
                <div
                    className={tabno === 1 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick}>
                    New Entry
                </div>
                <div
                    className={tabno === 2 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick1}>
                    Travel Logs
                </div>



            </div>
            <div className={styles.innerdiv}>

                {(<div className={styles.subtaskdiv2}>
                    <div className={styles.subtaskdiv31}>
                        <label>Tech Name :</label>
                        <Select
                            options={person.map(elm => ({ value: elm, label: elm }))}
                            value={techname ? { value: techname, label: techname } : null} // Set value to null if no initial selection
                            onChange={handleSelectChange}
                            placeholder="Select or type to search..."
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '300px',
                                    height: '40px',
                                    border: "1px solid #5B6BE1", // Corrected border syntax
                                    borderRadius:'8px',
                                    marginLeft:'-1.0%',
                                 
                                    
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: 'gray',
                                    marginTop:"-10px"
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    height:'38px',
                                    marginTop: '0px',
                                  }),
                                  dropdownIndicator: provided => ({
                                    ...provided,
                                    marginTop: '-10px',
                                  }),        
                                  singleValue: (provided) => ({
                                    ...provided,
                                   marginBottom:'9px' // Example styling after selection
                                  }),
                            }}
                        />

                    </div>
                </div>)}

                <div className={styles.datetimediv}>
                    <div>
                        <label>Date of travel to Workshop : </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomDatePicker
                                name="date"

                                onChange={
                                    (value) => handlechangedate("date", value)
                                }
                                slotProps={{ textField: { size: "small" } }}
                                format="DD/MM/YYYY"
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: "8px",
                                    border: "1px solid #5B6BE1"
                                }}
                            />
                        </LocalizationProvider>
                        {/* <input 
                    type='date'
                    name='date'
                    value={formdata.date}
                    onChange={handlechange}
                    style={{marginLeft:"50px"}}
                /> */}
                    </div>
                    <hr style={{ borderTop: "2px solid #5B6BE1", height: "2px", backgroundColor: " black", marginTop: "20px" }}></hr>


                </div>
                {(<div className={styles.subtaskdiv3}>
                    <div className={styles.subtaskdiv32}>
                        <label>Attendance Status :</label>

                        {/* <select
                            name='status'
                            value={attendancestatus}
                            onChange={handlechange}
                        >
                           <option value="" >Select Status</option>
                            <option value="Travel">Travel</option>
                            <option value="Standby">Standby</option>

                        </select> */}

                        <Select
                            options={optionss}
                            value={attendancestatus ? { value: attendancestatus, label: attendancestatus } : null} // Set value to null if no initial selection
                            onChange={handlechange}
                            placeholder="Select option"
                            isSearchable={false}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '300px',
                                    height: '40px',
                                    border: "1px solid #5B6BE1", // Corrected border syntax
                                    borderRadius:'8px',
                                    marginLeft:'-1%',
                                    
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: 'gray',
                                    marginTop:"-10px"
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    height:'38px',
                                    marginTop: '0px',
                                  }),
                                  dropdownIndicator: provided => ({
                                    ...provided,
                                    marginTop: '-10px',
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                   marginBottom:'9px' // Example styling after selection
                                  }),
                            }}
                        />
                        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "10px", marginLeft: "-55px" }}>
                            <path d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z" fill="black" />
                        </svg>
                    </div>
                </div>)}
                {(<div className={styles.subtaskdiv4}>
                    <div className={styles.subtaskdiv33}>
                        <label>Remarks(Optional) : </label>
                        <textarea

                            name='job_description'

                            onChange={handlechange3}
                        />
                    </div>
                </div>)}
                {(<div className={styles.subtaskdiv2}>


                    <label className={styles.toolkit}>
                        <input
                            style={{
                                transform: 'scale(1.5)',
                                border: "",
                                marginRight: "15px"
                            }}
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        {'  '} I confirm that Ergontec will be bearing the expenses
                        for this travel.
                        <BootstrapTooltip title={"If tech is travelling from project or going to project,add attendance details to respective project"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                color="black"
                                fill="currentColor"
                                class="bi bi-info-circle"
                                viewBox="0 0 16 16"
                                style={{
                                    cursor: "pointer", marginLeft: "100%",
                                    marginTop: "-6%", backgroundColor: "white",
                                    borderRadius:"10px"
                                }}
                            >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>

                        </BootstrapTooltip>


                    </label>

                </div>)}








                <span className={styles.buttonsdiv}>
                    <button className={styles.buttons} onClick={handleaddtravel}>Save</button>
                    <button className={styles.buttons1} onClick={handleexit}>Exit</button>

                </span>
            </div>

        </div>


    )
}

export default Travelattendance;
