import React from 'react'
import { ScaleLoader } from 'react-spinners';
import styles from "../Components/css/loadingScreen.module.css";

const LoadingScreen = ({ isLoading }) => {
    return (
      isLoading && (
        <div className={styles.loadingScreen}>
          <ScaleLoader color="#5b6be1" height={60} margin={6} radius={4} width={8} />
        </div>
      )
    );
  };
  
export default LoadingScreen
