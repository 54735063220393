import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../Components/css/IncomingDeliveries_New.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import axios from "axios";
import dayjs from "dayjs";
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const IncomingDeliveries_New = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks

  const [receivedDeliveryStatus, setReceivedDeliveryStatus] = useState(false);
  const [documentToUpload1, setDocumentToUpload1] = useState("");
  const [documentToUpload2, setDocumentToUpload2] = useState("");
  const [documentToUpload3, setDocumentToUpload3] = useState("");
  const [documentToUpload4, setDocumentToUpload4] = useState("");
  const [formdata2, setFormData2] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      setFormData2({
        ...formdata2,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
      });
    };
    fetchProject();
  }, [id]);

  const [formdata1, setFormData1] = useState({
    CiplNumber: "",
    CiplDoc: "",
    CustomerExitNumber: "",
    CustomerExitDoc: "",
    ErgonBillDoc: "",
    MOPAAttestation: "",
    DispatchingParty: "",
    ExpectedArrivalDate: "",
    ErgonBillNumber: "",
    ErgonBillDate: "",
    CustomerExitDate: "",
    CiplDate: "",
  });
  const [newRows, setNewRows] = useState("");

  const handleClick = () => {
    navigate(`/universal_deliveries`);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formdata1, [name]: value });
  };
  // date changes
  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("YYYY-MM-DD");
      setFormData1({ ...formdata1, [name]: selectedDate });
      // setFormData3Tool1({ ...formdata3Tool1, [name]: selectedDate });
      // setFormData3Tool2({ ...formdata3Tool2, [name]: selectedDate });
      console.log(name, selectedDate);
    } else {
      console.log("Invalid Date");
    }
  };
  const handleSave = async (e, el) => {
    e.preventDefault();
    setIsLoading(true);

    const {
      CiplNumber,
      CiplDoc,
      CustomerExitNumber,
      CustomerExitDoc,
      ErgonBillDoc,
      MOPAAttestation,
      DispatchingParty,
      ExpectedArrivalDate,
      ErgonBillNumber,
      ErgonBillDate,
      CustomerExitDate,
      CiplDate,
    } = formdata1;

    if (!formdata1.ExpectedArrivalDate) {
      alert("Expected Arrival Date is required.");
      setIsLoading(false);
      return; // Exit the function without saving if the date is null
    }

    const finalReceivedDeliveryStatus = ExpectedArrivalDate <= currentDate ? receivedDeliveryStatus : "0";

    const payload = {
      CIPL_Doc_No: CiplNumber,
      CIPL_Doc: CiplDoc,
      Customer_Exit_Bill_No: CustomerExitNumber,
      Customer_Exit_Bill_Doc: CustomerExitDoc,
      Entry_Bill_Doc: ErgonBillDoc,
      MOPA_Attestation: MOPAAttestation,
      Dispatching_Party: DispatchingParty,
      Expected_Arrival_Date: ExpectedArrivalDate,
      ID_No: id,
      Entry_Bill_No: ErgonBillNumber,
      Entry_Bill_Date: ErgonBillDate,
      Customer_Exit_Bill_Date: CustomerExitDate,
      CIPL_Date: CiplDate,
      Received_Delivery_Status: finalReceivedDeliveryStatus,
    };
    const payloadfiles1 = new FormData();
    const payloadfiles2 = new FormData();
    const payloadfiles3 = new FormData();
    const payloadfiles4 = new FormData();
    payloadfiles3.append("CIPL_Doc", documentToUpload1);
    payloadfiles2.append("Customer_Exit_Bill_Doc", documentToUpload2);
    payloadfiles1.append("Entry_Bill_Doc", documentToUpload3);
    payloadfiles4.append("MOPA_Attestation", documentToUpload4);

    try {
      const res = await axios.post(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveriesnew_post`,
        payload
      );
      const proId = res.data.ID_No;
      console.log(proId);

      const res1 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries_put?proId=${proId}`,
        payloadfiles1,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const res2 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries_put2?proId=${proId}`,
        payloadfiles2,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const res3 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries_put3?proId=${proId}`,
        payloadfiles3,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const res5 = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries_put4?proId=${proId}`,
        payloadfiles4,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );


      const incomingInspectionId = proId + 20000;

      const res4 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries_put_inspectionID?proId=${proId}`, {
        Incoming_Inspection_Id: incomingInspectionId,
      });
      
      console.log(proId);

      if (res.status === 200 && res1.status === 200 && res2.status === 200 && res3.status === 200 && res4.status === 200 && res5.status === 200) {
        alert("Incoming delivery created successfully");
        setIsLoading(false);
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1); // This will take the user back to the previous page they were on
  };
  

  const [fileName, setFileName] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [fileName3, setFileName3] = useState("");
  const [fileName4, setFileName4] = useState("");
  const handlecross = (name) => {
    
    switch(name){
      case"CIPL_Doc":
      setFileName("");
      setDocumentToUpload1("");
      break;
      case"Customer_Exit_Bill_Doc":
      setFileName2("");
      setDocumentToUpload2("");
      break;
      case"Entry_Bill_Doc":
      setFileName3("");
      setDocumentToUpload3("");
      break;
      case"MOPA_Attestation":
      setFileName4("");
      setDocumentToUpload4("");
      break;
    }
    
  };
  
  const handlefileupload1 = (name, event) => {
    event.preventDefault();
    const temp1 = event.target.files[0];
    setDocumentToUpload1(temp1);

    const inputElement = document.getElementsByName(name)[0];
    const selectedFile = inputElement.files[0];
    console.log(selectedFile)
    if (selectedFile) {
      const fileName = selectedFile.name;
      setFileName(fileName ? fileName : "");
    } else {
      setFileName("");
   
    
    }
    inputElement.value = "";
    temp1.value="";
  };
  const handlefileupload2 = (name, event) => {
    event.preventDefault();
    const temp2 = event.target.files[0];
    setDocumentToUpload2(temp2);

    const inputElement2 = document.getElementsByName(name)[0];
    const selectedFile2 = inputElement2.files[0];

    if (selectedFile2) {
      const fileName2 = selectedFile2.name;
      setFileName2(fileName2 ? fileName2 : "");
    } else {
      setFileName2("");
    }
    inputElement2.value = "";
    temp2.value="";
  };
  const handlefileupload3 = (name, event) => {
    event.preventDefault();
    const temp3 = event.target.files[0];
    setDocumentToUpload3(temp3);

    const inputElement3 = document.getElementsByName(name)[0];
    const selectedFile3 = inputElement3.files[0];

    if (selectedFile3) {
      const fileName3 = selectedFile3.name;
      setFileName3(fileName3 ? fileName3 : "");
    } else {
      setFileName3("");
    }
    inputElement3.value = "";
    temp3.value="";
  };
  const handlefileupload4 = (name, event) => {
    event.preventDefault();
    const temp4 = event.target.files[0];
    setDocumentToUpload4(temp4);

    const inputElement4 = document.getElementsByName(name)[0];
    const selectedFile4 = inputElement4.files[0];

    if (selectedFile4) {
      const fileName4 = selectedFile4.name;
      setFileName4(fileName4 ? fileName4 : "");
    } else {
      setFileName4("");
    }
    inputElement4.value = "";
    temp4.value="";
  };


  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "230px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const currentDate = getCurrentDate();


  return (
    <div style={{ marginTop: "115px", marginLeft: "1%", marginRight: "1%" }}>
      <LoadingScreen isLoading={isLoading} />
      <div>
        <button className={styles.mybutton} onClick={handleClick}>
          &lt; Incoming Deliveries
        </button>
      </div>
      <div>
        <div className={styles.box}>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Project SO: {formdata2.salesOrderNo}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Project Design: {formdata2.Design}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Project Name: {formdata2.ProjectName}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Project Scope: {formdata2.Scope}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Quantity: {formdata2.quantity}
            </label>
          </div>
        </div>
      </div>
      {/* form div below */}

      <div className={styles.sections}>
        <div className={styles.editAndSaveButtons}>
          <>
            <button
              variant="contained"
              onClick={handleSave}
              disabled={isLoading}
              className={styles.saveButton}
            >
              Save
            </button>

            <button
              variant="outlined"
              onClick={handleCancel}
              className={styles.cancelBtn}
            >
              Cancel
            </button>
          </>
        </div>
        <form>
          <div style={{ marginLeft: "25px" }}>
            {/* custom cipl */}
            <div>
              <div>
                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}
                >
                  <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                    Expected Arrival Date*:
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="ExpectedArrivalDate"
                      value={
                        formdata1.ExpectedArrivalDate
                          ? dayjs(formdata1.ExpectedArrivalDate, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(value) =>
                        handleDates("ExpectedArrivalDate", value)
                      }
                      slotProps={{ textField: { size: "small" } }}
                      format="DD-MM-YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #5B6BE1",
                        boxShadow: "none", // Corrected property name
                        marginLeft: "12px",
                      }}
                    />
                  </LocalizationProvider>
                  {formdata1.ExpectedArrivalDate && formdata1.ExpectedArrivalDate !== "" && formdata1.ExpectedArrivalDate <= currentDate && (
                  <>
                    <input
                      type="checkbox"
                      checked={receivedDeliveryStatus === "1"}
                      style={{marginLeft: "30px", width:"12px", height:"12px"}}
                      onChange={(e) => setReceivedDeliveryStatus(e.target.checked ? "1" : "0")}
                    />
                    <label style={{ marginLeft: '10px', color: "black", fontWeight: 600 }}>Mark Received</label>
                  </>
                  )}
                </div>
                <div className={styles.formElement}>
                  <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                    Dispatching Party:
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <select
                      value={formdata1.DispatchingParty}
                      name="DispatchingParty"
                      onChange={handleOnChange}
                      className={styles.textbox0}
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        cursor: "Pointer",
                      }}
                    >
                      <option value=""></option>
                      <option value="Shelf Drilling">Shelf Drilling</option>
                    </select>
                    <span
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "grey",
                      }}
                    >
                      ▼
                    </span>{" "}
                    {/* Dropdown icon */}
                  </div>
                </div>
              </div>
              <h2 className={styles.heading}>Customer CIPL</h2>
              {/* Document No */}
              <div className={styles.formElement}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Document Number:
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.CiplNumber}
                    name="CiplNumber"
                    onChange={handleOnChange}
                    className={styles.textbox1}
                  />
                </div>
              </div>
              {/* Document No. Ends */}

              {/* Document Date */}
              <div
                className={`${styles.formElement} ${styles.datepickerInput}`}
              >
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Document Date:
                </label>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="CiplDate"
                    value={
                      formdata1.CiplDate
                        ? dayjs(formdata1.CiplDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("CiplDate", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      border: "1px solid #5B6BE1",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "52px",
                    }}
                  />
                </LocalizationProvider>
              </div>
              {/* Document Date Ends */}

              <div
                style={{
                  display: "inline-flex",
                  marginLeft: "0px",
                  padding: "10px 0px",
                  alignItems: "center",
                  border: "1px",
                }}
              >
                <label
                  htmlFor="upload1"
                  style={{ color: "#5B6BE1", fontWeight: "600" }}
                >
                  Upload Document:
                </label>
                <input
                  type="file"
                  name="upload1"
                  id="upload1"
                  onInput={(e) => handlefileupload1("upload1", e)}
                  style={{
                    display: "none",
                  }}
                />
                <div
                  className={styles.textbox2}
                  style={{ position: "relative" }}
                  onClick={() => document.getElementById("upload1").click()}
                >
                  <label
                    style={{
                      marginLeft: "15px",
                      marginTop: "5px",
                      color: "rgba(128, 128, 128, 0.8)",
                      overflow: "hidden", // Hide overflow text
                      textOverflow: "ellipsis", // Display ellipsis for overflow text
                      whiteSpace: "nowrap", // Prevent wrapping
                      maxWidth: "180px", // Set maximum width for the file name
                      alignItems: "center",
                    }}
                  >
                    {fileName ? fileName : "Upload File"}
                  </label>

                  <svg
                    width="25"
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px", // Adjust as needed
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    height="25"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                      fill="grey"
                    />
                    <path
                      d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                      fill="grey"
                    />
                  </svg>
                </div>
                {fileName && (
                  <svg
                    width="25"
                    onClick={() => handlecross("CIPL_Doc")}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    height="25"
                    viewBox="0 0 20 20"
                    fill="grey"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="grey"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="grey"
                    />
                  </svg>
                )}
              </div>
            </div>
            {/* Customer Exit Bill/Delivery Note */}
            <div
              style={{
                marginTop: "30px",
              }}
            >
              <h2 className={styles.heading}>
                Customer Exit Bill/Delivery Note
              </h2>
              {/* Document No */}
              <div className={styles.formElement}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Document Number:
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.CustomerExitNumber}
                    name="CustomerExitNumber"
                    onChange={handleOnChange}
                    className={styles.textbox1}
                  />
                </div>
              </div>
              {/* Document No. Ends */}

              {/* Document Date */}
              <div
                className={`${styles.formElement} ${styles.datepickerInput}`}
              >
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Document Date:
                </label>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="CustomerExitDate"
                    value={
                      formdata1.CustomerExitDate
                        ? dayjs(formdata1.CustomerExitDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("CustomerExitDate", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      border: "1px solid #5B6BE1",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "52px",
                    }}
                  />
                </LocalizationProvider>
              </div>
              {/* Document Date Ends */}

              <div
                style={{
                  display: "inline-flex",
                  marginLeft: "0px",
                  padding: "10px 0px",
                  alignItems: "center",
                  border: "1px",
                }}
              >
                <label
                  htmlFor="upload2"
                  style={{ color: "#5B6BE1", fontWeight: "600" }}
                >
                  Upload Document:
                </label>
                <input
                  type="file"
                  name="upload2"
                  id="upload2"
                  onInput={(e) => handlefileupload2("upload2", e)}
                  style={{
                    display: "none",
                  }}
                />
                <div
                  className={styles.textbox2}
                  style={{ position: "relative" }}
                  onClick={() => document.getElementById("upload2").click()}
                >
                  <label
                    style={{
                      marginLeft: "15px",
                      marginTop: "5px",
                      color: "rgba(128, 128, 128, 0.8)",
                      overflow: "hidden", // Hide overflow text
                      textOverflow: "ellipsis", // Display ellipsis for overflow text
                      whiteSpace: "nowrap", // Prevent wrapping
                      maxWidth: "180px", // Set maximum width for the file name
                      alignItems: "center",
                    }}
                  >
                    {fileName2 ? fileName2 : "Upload File"}
                  </label>

                  <svg
                    width="25"
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px", // Adjust as needed
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    height="25"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                      fill="grey"
                    />
                    <path
                      d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                      fill="grey"
                    />
                  </svg>
                </div>
                {fileName2 && (
                  <svg
                    width="25"
                    onClick={() => handlecross("Customer_Exit_Bill_Doc")}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    height="25"
                    viewBox="0 0 20 20"
                    fill="grey"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="grey"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="grey"
                    />
                  </svg>
                )}
              </div>
            </div>
            {/* Ergon Entry Bill */}
            <div
              style={{
                marginTop: "30px",
              }}
            >
              <h2 className={styles.heading}>Ergon Entry Bill</h2>
              {/* Document No */}
              <div className={styles.formElement}>
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Document Number:
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formdata1.ErgonBillNumber}
                    name="ErgonBillNumber"
                    onChange={handleOnChange}
                    className={styles.textbox1}
                  />
                </div>
              </div>
              {/* Document No. Ends */}

              {/* Document Date */}
              <div
                className={`${styles.formElement} ${styles.datepickerInput}`}
              >
                <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                  Document Date:
                </label>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="ErgonBillDate"
                    value={
                      formdata1.ErgonBillDate
                        ? dayjs(formdata1.ErgonBillDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("ErgonBillDate", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      border: "1px solid #5B6BE1",
                      boxShadow: "none", // Corrected property name
                      marginLeft: "52px",
                    }}
                  />
                </LocalizationProvider>
              </div>
              {/* Document Date Ends */}

              <div
                style={{
                  display: "inline-flex",
                  marginLeft: "0px",
                  padding: "10px 0px",
                  alignItems: "center",
                  border: "1px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="upload3"
                  style={{ color: "#5B6BE1", fontWeight: "600" }}
                >
                  Upload Document:
                </label>
                <input
                  type="file"
                  name="upload3"
                  id="upload3"
                  onInput={(e) => handlefileupload3("upload3", e)}
                  style={{
                    display: "none",
                  }}
                />
                <div
                  className={styles.textbox2}
                  style={{ position: "relative" }}
                  onClick={() => document.getElementById("upload3").click()}
                >
                  <label
                    style={{
                      marginLeft: "15px",
                      marginTop: "5px",
                      color: "rgba(128, 128, 128, 0.8)",
                      overflow: "hidden", // Hide overflow text
                      textOverflow: "ellipsis", // Display ellipsis for overflow text
                      whiteSpace: "nowrap", // Prevent wrapping
                      maxWidth: "180px", // Set maximum width for the file name
                      alignItems: "center",
                    }}
                  >
                    {fileName3 ? fileName3 : "Upload File"}
                  </label>

                  <svg
                    width="25"
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px", // Adjust as needed
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    height="25"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                      fill="grey"
                    />
                    <path
                      d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                      fill="grey"
                    />
                  </svg>
                </div>{" "}
                {fileName3 && (
                  <svg
                    width="25"
                    onClick={() => handlecross("Entry_Bill_Doc")}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    height="25"
                    viewBox="0 0 20 20"
                    fill="grey"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="grey"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="grey"
                    />
                  </svg>
                )}
              </div>

              <div
                style={{
                  display: "inline-flex",
                  marginLeft: "0px",
                  padding: "10px 0px",
                  alignItems: "center",
                  border: "1px",
                }}
              >
                <label
                  htmlFor="upload4"
                  style={{ color: "#5B6BE1", fontWeight: "600" }}
                >
                  MOFA Attestation:
                </label>
                <input
                  type="file"
                  name="upload4"
                  id="upload4"
                  onInput={(e) => handlefileupload4("upload4", e)}
                  style={{
                    display: "none",
                  }}
                />
                <div
                  className={styles.textbox2}
                  style={{ position: "relative" }}
                  onClick={() => document.getElementById("upload4").click()}
                >
                  <label
                    style={{
                      marginLeft: "15px",
                      marginTop: "5px",
                      color: "rgba(128, 128, 128, 0.8)",
                      overflow: "hidden", // Hide overflow text
                      textOverflow: "ellipsis", // Display ellipsis for overflow text
                      whiteSpace: "nowrap", // Prevent wrapping
                      maxWidth: "180px", // Set maximum width for the file name
                      alignItems: "center",
                    }}
                  >
                    {fileName4 ? fileName4 : "Upload File"}
                  </label>

                  <svg
                    width="25"
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px", // Adjust as needed
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    height="25"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                      fill="grey"
                    />
                    <path
                      d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                      fill="grey"
                    />
                  </svg>
                </div>{" "}
                {fileName4 && (
                  <svg
                    width="25"
                    onClick={() => handlecross("MOPA_Attestation")}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    height="25"
                    viewBox="0 0 20 20"
                    fill="grey"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="grey"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="grey"
                    />
                  </svg>
                )}
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IncomingDeliveries_New;
