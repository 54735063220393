import React from 'react'
// import pdfDoc from "../assets/Combined_Rig_Details.pdf"
import pdfDoc from "../assets/Combined_Rig_Details.pdf"

const ShowHandbook = () => {
  return (
    < >
      {/* <embed src={pdfDoc} type="application/pdf" width="100%" height="600px"/> */}
      <iframe src={pdfDoc} width="100%" height="600px" style={{marginTop:"180px"}}></iframe>
     

    </>
  )
}


export default ShowHandbook;