import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import styles from "../Components/css/rigdetails.module.css";
import Slider from "../Components/slider";
import Editcomponent from "./Editcomponent";
import upwardarrowbox from "../Components/upwardarrowbox.png";
import ErgonProjectsMap from "../Components/css/image/ErgonProjectsMap.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import UploadDocument from "./Uploaddocument";
import CombinedRigDetails from "../Components/CombinedRigDetails.pdf";
import { Document, Page } from "@react-pdf/renderer";
import sort from "../Components/sort.png";
import pdfDoc from "../assets/Combined_Rig_Details.pdf";
// mui icons
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';


const Rigdetails = () => {
  const [tableListDisplay, setTableListDisplay] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [showHandBook, setShowHandBook] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsOriginal, setSearchResultsOriginal] = useState([]);
  const [selectedRig, setSelectedRig] = useState(null);
  const [showHandbookView, setshowHandbookView] = useState(false);
  const [ToggleBtnGroupColorRed, setToggleBtnGroupColorRed] = useState("List");
  const [openmodal, setopenmodal] = useState(false);
  const [openmodal2, setopenmodal2] = useState(false);
  const navigate = useNavigate();
  const [featurecn, setfeaturecn] = useState([]);
  const [featureedit, setfeatureedit] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const [showMapView, setShowMapView] = useState(false);
  const [selectedRigId, setSelectedRigId] = useState(null);
  const [showForm, setShowForm] = useState(false);

  // remove scroll when popup is open


  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        const filgroupfeatures = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Rig Details" && el.Feature_Name === "Add New" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        const filgroupfeatures2 = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Rig Details" && el.Feature_Name === "Edit" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        setfeaturecn(filgroupfeatures);
        setfeatureedit(filgroupfeatures2);
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    if (showForm) {
      body.classList.add(styles.noScroll);
    } else {
      body.classList.remove(styles.noScroll);
    }

    return () => {
      body.classList.remove(styles.noScroll);
    };
  }, [showForm]);

  const [formData, setFormData] = useState({
    s_no: "",
    rig_name: "",
    short_name: "",
    customer_name: "",
    // details: "",
    design: "",
    location: "",
    hull_no: "",
    design_2: "",
    new_group: "",
  });

  const [selectedFile, setSelectedFile] = useState({
    details: null,
  });

  const [selectedFileName, setSelectedFileName] = useState({
    details: "",
  });

  const handleFileUpload = async (file, fieldName) => {
    const formData = new FormData();

    formData.append("file", file);
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/uploadfile",
        formData
      );

      // console.log(res.data);
      const pathOfFile = res.data.fileUrl;
      const fileNameApi = res.data.filename;

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: fileNameApi,
      });

      setSelectedFile({
        ...selectedFile,
        [fieldName]: pathOfFile,
      });
    } catch (error) {
      console.error("Error uploading signature:", error);
    }
  };

  const handleMapClick = () => {
    setShowImage(true);
    setTableListDisplay(false);
    setShowHandBook(false);
  };

  const handleListClick = () => {
    setTableListDisplay(true);
    setShowImage(false);
    setShowHandBook(false);
  };

  const handleHandBookViewBtn = () => {
    // navigate("/handbook");
    setShowHandBook(true);
    setShowImage(false);
    setTableListDisplay(false);
  };

  const dropdowncoustomer = [
    ...new Set(searchResults.map((elm) => elm.customer_name)),
  ].filter(Boolean);

  //adding button functionality
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
      // s_no: formData.length + 1,
    });
  };

  
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
  
    // If the searchTerm is empty, display the original unfiltered data
    if (searchTerm === "") {
      setSearchTerm(searchTerm);
      setSearchResults(searchResultsOriginal);
    } else {
      // Filter the results based on short_name property only
      const filteredResults = searchResultsOriginal.filter((rig) => {
        // Check if rig and its short_name property are not null before accessing them
        if (rig && rig.short_name) {
          return rig.short_name.toLowerCase().includes(searchTerm);
        }
        return false;
      });
  
      setSearchTerm(searchTerm); // Update the searchTerm state
      setSearchResults(filteredResults); // Update the searchResults state with filtered results
    }
  };
  
  console.log('Value of searchTerm:', searchTerm);


  const handleEditClick = (rig) => {
    setSelectedRig(rig);
    setShowForm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      // s_no,
      rig_name,
      short_name,
      customer_name,
      // details,
      design,
      location,
      hull_no,
      design_2,
      new_group,
    } = formData;

    const { details } = selectedFile;

    const payload = {
      // s_no: s_no,
      rig_name: rig_name,
      short_name: short_name,
      customer_name: customer_name,
      details: details,
      design: design,
      location: location,
      hull_no: hull_no,
      design_2: design_2,
      new_group: new_group,
    };

    const res = await axios.post(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/post",
      payload
    );

    setShowForm(false);
    setFormData({
      s_no: "",
      rig_name: "",
      short_name: "",
      customer_name: "",
      // details: "",
      design: "",
      location: "",
      hull_no: "",
      design_2: "",
      new_group: "",
    });

    setSelectedFile({
      details: "",
    });

    if (res.status === 200) {
      setRefresh(!refresh);
    }
    setopenmodal(true);
  };

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getrig_details"
        );
        console.log("*************", response.data);
        setSearchResults(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, [refresh]);

  useEffect(() => {
    // Replace this with your actual data fetching logic
    const fetchData = async () => {
      try {
        const response = await fetch("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getrig_details");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSearchResultsOriginal(data); // Set the original data here
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const handleMapViewClick = () => {
    // Render a map view of the rigs
    setShowMapView(!showMapView);
  };

  const handleHandbookClick = () => {
    // Render a handbook of the
    setShowHandBook(true);
  };

  const handleDetailsClick = (rig) => {
    console.log(rig);
    navigate(`/rig-summary/${rig.short_name}`);

  };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  const MapWithImage = () => {};

  const handleStopClose = (e) => {
    e.stopPropagation();
  };

  const handleCloseHandbook = () => {
    setShowHandBook(false);
  };

  // console.log(searchResults);
  //edition functionality
  const [enableEdit, setEnableEdit] = useState(false);
  return (
    <>
    {openmodal && (
      // Your modal content goes here
      <div className={styles.modal}>
        <div className={styles.modalcontent}>
          <p className={styles.modalcontent1}>Success

          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" className={styles.modalcontent4}>
  <g clip-path="url(#clip0_1199_10150)">
    <path d="M20 40C23.9556 40 27.8224 38.827 31.1114 36.6294C34.4004 34.4318 36.9638 31.3082 38.4776 27.6537C39.9913 23.9992 40.3874 19.9778 39.6157 16.0982C38.844 12.2186 36.9392 8.65492 34.1421 5.85787C31.3451 3.06082 27.7814 1.15601 23.9018 0.384303C20.0222 -0.387401 16.0009 0.00866576 12.3463 1.52242C8.69181 3.03617 5.56824 5.59962 3.37061 8.88861C1.17298 12.1776 0 16.0444 0 20C0 25.3043 2.10714 30.3914 5.85786 34.1421C9.60859 37.8929 14.6957 40 20 40ZM10.3764 20.5327L14.5455 24.7018L29.6236 9.62365L32.1945 12.1946L14.5455 29.8436L7.80546 23.1036L10.3764 20.5327Z" fill="#0B861F"/>
  </g>
  <defs>
    <clipPath id="clip0_1199_10150">
      <rect width="40" height="40" fill="white"/>
    </clipPath>
  </defs>
</svg></p>
          <p className={styles.modalcontent2}>Rig Details Successfully Added!</p>
          {/* <button className={styles.modalcontent3} onClick={() => setopenmodal(false)} >Ok</button> */}
        </div>
      </div>
    )}
    {/* {openmodal2 && (
      // Your modal content goes here
      <div className={styles.modal}>
        <div className={styles.modalcontent}>
          <p className={styles.modalcontent1}>Success

          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" className={styles.modalcontent4}>
  <g clip-path="url(#clip0_1199_10150)">
    <path d="M20 40C23.9556 40 27.8224 38.827 31.1114 36.6294C34.4004 34.4318 36.9638 31.3082 38.4776 27.6537C39.9913 23.9992 40.3874 19.9778 39.6157 16.0982C38.844 12.2186 36.9392 8.65492 34.1421 5.85787C31.3451 3.06082 27.7814 1.15601 23.9018 0.384303C20.0222 -0.387401 16.0009 0.00866576 12.3463 1.52242C8.69181 3.03617 5.56824 5.59962 3.37061 8.88861C1.17298 12.1776 0 16.0444 0 20C0 25.3043 2.10714 30.3914 5.85786 34.1421C9.60859 37.8929 14.6957 40 20 40ZM10.3764 20.5327L14.5455 24.7018L29.6236 9.62365L32.1945 12.1946L14.5455 29.8436L7.80546 23.1036L10.3764 20.5327Z" fill="#0B861F"/>
  </g>
  <defs>
    <clipPath id="clip0_1199_10150">
      <rect width="40" height="40" fill="white"/>
    </clipPath>
  </defs>
</svg></p>
          <p className={styles.modalcontent2}>Rig Details Successfully Updated!</p>
          <button className={styles.modalcontent3} onClick={() => setopenmodal2(false)} >Ok</button>
        </div>
      </div>
    )} */}
    <div className={styles.rig}>
      {showMapView && <MapWithImage />}

      <div className={styles.btnGroup}>
        <div className={styles.searchContainerBox}>
          <h1 className={styles.rigDetailsHeading}>Rig Details</h1>

          <div className={styles.Searchbar}>
            {/* <input
          type="text"
          placeholder="Search by rigname or location"
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-box"
        /> */}
            {!showHandBook && !showImage && (
              <div className={styles.searchContainer}>
              <input
                type="text"
                placeholder="Search by Rig Shortname... "
                // onChange={(event) => {
                //   setSearchTerm(event.target.value);
                // }}
                onChange={handleSearchChange}
              />
              <svg
                className={styles.searchIconFilter}
                style={{ marginLeft: "5%" }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                  fill="#BCBCBC"
                />
                <path
                  d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
                  fill="#BCBCBC"
                />
              </svg>
              </div>
            )}
            {/* {searchResults
        .filter(
          (result) =>
            result.rig_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            result.location.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((rig, index) => (
          <div key={index} className="rig-card">
            <div className="rig-image">
              <Slider />
            </div>
            <div className="rig-content">
              <h2>{rig.rig_name}</h2>
              <p>{rig.details}</p>
              <button onClick={() => handleEditClick(rig)}>Edit</button>
            </div>
          </div>
        ))}
      {showForm && <Editcomponent rig={selectedRig} setShowForm={setShowForm} />} */}
          </div>
        </div>

        <div>
          {/* {!showHandBook && !showImage && (
            <Button variant="outlined" className={styles.rightButtonsfil}>
              Filter
              <img
                src={sort}
                height="20px"
                width="20px"
                className={styles.addnewimg}
              />
            </Button>
          )} */}
          {!showHandBook &&
            !showImage &&
            featureedit.length > 0 && 
            (!enableEdit ? (
              <Button
                variant="outlined"
                className={styles.rightButtons}
                onClick={() => setEnableEdit(!enableEdit)}>
                Edit
                <BorderColorIcon sx={{ fontSize: "17px" }} />
              </Button>
            ) : (
              <Button
                variant="outlined"
                className={styles.rightButtons}
                onClick={() => setEnableEdit(!enableEdit)}>
                Cancel
              </Button>
            ))}
          {!showHandBook && !showImage && !enableEdit && featurecn.length > 0 && (
            <Button
              variant="contained"
              className={styles.addnewbuttonrig}
              onClick={() => setShowForm(true)}>
              Create New
              <svg
              style={{ marginLeft: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-plus-square-fill"
              viewBox="0 0 16 16">
              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
            </svg>
            </Button>
          )}
        </div>
      </div>

      {/* <div className={styles.listmaphandbook_btn}>
        <Button
          variant="outlined"
          className={tableListDisplay ? styles.listbutton : styles.active}
          onClick={handleListClick}>
          List
        </Button>

        <Button
          variant="outlined"
          className={showImage ? styles.listbutton : styles.active}
          onClick={handleMapClick}>
          Map
        </Button>

        <Button
          variant="outlined"
          onClick={handleHandBookViewBtn}
          className={showHandBook ? styles.listbutton : styles.active}>
          Handbook
        </Button>
      </div> */}

      <div>
        {showForm && (
          <div
            className={styles.popupContainer}
            onClick={(e) => {
              e.stopPropagation();
              setShowForm(false);
            }}>
            <div className={styles.popUp} onClick={handleStopClose}>
              <form>
                {/* <label>
              S.NO
              <input
                type="text"
                name="s_no"
                value={formData.rig_name}
                onChange={handleInputChange}
              />
            </label> */}
                <div className={styles.popupItemss}>
                  <b>Add New</b>
                  <div style={{ cursor: "pointer" }}>
                    <svg
                      onClick={() => setShowForm(false)}
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.4998 2.41663C7.84192 2.41663 2.4165 7.84204 2.4165 14.5C2.4165 21.1579 7.84192 26.5833 14.4998 26.5833C21.1578 26.5833 26.5832 21.1579 26.5832 14.5C26.5832 7.84204 21.1578 2.41663 14.4998 2.41663ZM18.5598 17.2791C18.9103 17.6295 18.9103 18.2095 18.5598 18.56C18.3786 18.7412 18.149 18.8258 17.9194 18.8258C17.6898 18.8258 17.4603 18.7412 17.279 18.56L14.4998 15.7808L11.7207 18.56C11.5394 18.7412 11.3098 18.8258 11.0803 18.8258C10.8507 18.8258 10.6211 18.7412 10.4398 18.56C10.0894 18.2095 10.0894 17.6295 10.4398 17.2791L13.219 14.5L10.4398 11.7208C10.0894 11.3704 10.0894 10.7904 10.4398 10.44C10.7903 10.0895 11.3703 10.0895 11.7207 10.44L14.4998 13.2191L17.279 10.44C17.6294 10.0895 18.2094 10.0895 18.5598 10.44C18.9103 10.7904 18.9103 11.3704 18.5598 11.7208L15.7807 14.5L18.5598 17.2791Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div className={styles.popupItemss}>
                  <label>Name:</label>
                  <input
                    type="text"
                    name="rig_name"
                    value={formData.rig_name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.popupItemss}>
                  <label>Short Name:</label>
                  <input
                    type="text"
                    name="short_name"
                    value={formData.short_name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.popupItemss}>
                  <label> Customer Name:</label>
                  <select
                    name="customer_name"
                    value={formData.customer_name}
                    onChange={handleInputChange}>
                    {/* {dropdowncoustomer.map((currelm) => (
                      // <option value={currelm} key={currelm}>
                      //   {currelm}
                      // </option>

                    ))} */}
                     <option value="">Select</option>
                      <option value="Shelf Drilling">Shelf Drilling</option>
              <option value="Valaris">Valaris</option>
              <option value="ARO Drilling">ARO Drilling</option>
              <option value="ADES">ADES</option>
              <option value="COC">COC</option>
              <option value="Northern Offshore">Northern Offshore</option>
              <option value="Melius">Melius</option>
              <option value="Essar Shipping Ltd">Essar Shipping Ltd</option>
              <option value="Foresight Offshore Drilling">Foresight Offshore Drilling</option>
              <option value="Seadrill">Seadrill</option>
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.popupItemss}>
                  <label>
                    Details:
                    <br />
                    (Upload Document)
                  </label>
                  {/* <input
                    type="text"
                    name="details"
                    value={formData.details}
                    onChange={handleInputChange}
                  /> */}
                  <div>
                    <UploadDocument
                      fieldName={"details"}
                      selectedFile={selectedFile.details}
                      selectedFileName={selectedFileName.details}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  </div>
                </div>
                <div className={styles.popupItemss}>
                  <label> Design:</label>
                  <input
                    type="text"
                    name="design"
                    value={formData.design}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.popupItemss}>
                  <label> Location:</label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.popupItemss}>
                  <label> Hull Number:</label>
                  <input
                    type="text"
                    name="hull_no"
                    value={formData.hull_no}
                    onChange={handleInputChange}
                  />
                </div>
                {/* <div className={styles.popupItemss}>
                  <label> Design 2:</label>
                  <input
                    type="text"
                    name="design_2"
                    value={formData.design_2}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.popupItemss}>
                  <label>New Group:</label>
                  <input
                    type="text"
                    name="new_group"
                    value={formData.new_group}
                    onChange={handleInputChange}
                  />
                </div> */}
                <br />
                <div className={styles.popupButtons}>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    className={styles.SubmitButton}
                    size="large"
                    variant="contained">
                    Submit
                  </Button>
                  <Button
                    className={styles.CancelButton}
                    size="large"
                    onClick={() => setShowForm(false)}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      <div>
        {showHandBook && (
          <div className="pdf-container">
            <iframe
              src={pdfDoc}
              width="100%"
              height="600px"
              style={{ marginTop: "18px" }}></iframe>
            <Document
              file={"../Components/CombinedRigDetails.pdf"}
              onLoadSuccess={() =>
                console.log("Document loaded successfully.")
              }>
              <Page pageNumber={1} />
            </Document>
          </div>
        )}
      </div>

      <div>
        {showImage && <img src={ErgonProjectsMap} height="100%" width="100%" />}
      </div>

      {tableListDisplay && (
        <div className={styles.rigtablecontainer}>
          <table className={styles.rigtable}>
            <thead>
              <tr>
                {/* <th>Select</th> */}
                <th>S. No</th>
                <th> Rig Name </th>
                <th> Short Name </th>
                <th> Customer Name </th>
                <th>Details</th>
                <th>Design</th>
                <th>Location</th>

                <th>Hull no.</th>
                {/* <th>Design</th>
                <th>Class</th> */}
              </tr>
            </thead>
            <tbody>
              {searchResults.map((rig, index) => (
                <tr key={index}>
                  {/* <td>
                <input
                  type="radio"
                  name="rig"
                  checked={selectedRigId === rig.id}
                  onChange={() => setSelectedRigId(rig.id)}
                />
              </td> */}
                  <td className={styles.EditRadioBtnEnabbled}>
                    {enableEdit && (
                      <>
                        <Editcomponent
                          setEnableEdit={setEnableEdit}
                          enableEdit={enableEdit}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          // s_no={index + 1}
                          rig_name={rig.rig_name}
                          short_name={rig.short_name}
                          customer_name={rig.customer_name}
                          details={rig.details}
                          design={rig.design}
                          location={rig.location}
                          hull_no={rig.hull_no}
                          details_document_name={rig.details_document_name}
                          // design_2={rig.design_2}
                          // new_group={rig.new_group}
                          s_no={rig.s_no}
                        />
                      </>
                    )}

                    {index + 1}
                  </td>
                  {/* Navigate to rig summary */}
                  
                  <td>
                  <span
                    className={styles.rigName}
                    onClick={() => handleDetailsClick(rig)}
                    style={{ cursor: "pointer", color: "#567191" }}
                  >
                    {rig.rig_name ? rig.rig_name : "-"}
                  </span>
                </td>
                  <td>{rig.short_name ? rig.short_name : "-"}</td>
                  <td>{rig.customer_name ? rig.customer_name : "-"}</td>
                  <td>
                    {rig.details ? (
                      // <NavLink
                      //   className={styles.linkStyling}
                      //   to={rig.details}
                      //   target="_blank"
                      //   rel="noopener noreferrer">
                      //   View details
                      //   <OpenInNewIcon style={{ cursor: "pointer" }} />
                      // </NavLink>

                      <a href={rig.details} target="_blank" className={styles.linkStyling}>
                      View Details <OpenInNewIcon style={{ cursor: "pointer" }} />
                    </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{rig.design ? rig.design : "-"}</td>
                  <td>{rig.location ? rig.location : "-"}</td>

                  <td>{rig.hull_no ? rig.hull_no : "-"}</td>
                  {/* <td>{rig.design_2 ? rig.design_2 : "-"}</td>
                  <td>{rig.new_group ? rig.new_group : "-"}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
    </>
  );
};

export default Rigdetails;
