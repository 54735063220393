import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_inquiry_vendor_quotes.module.css";
import TabsBarSparesInquiry from './TabsBarSparesInquiry';
import LoadingScreen from './LoadingScreen';
import Select from 'react-select';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparesTradingInquiryVendorQuotes() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks
  const [refresh, setRefresh] = useState(false);
  const [quotesData, setQuotesData] = useState([]);
  const [maxSupplierItem, setMaxSupplierItem] = useState(null);
  const [TabTracker, setTabTracker] = useState(1);
  const [formDataQuotes, setFormDataQuotes] = useState([]);
  const [editMode, setEditMode] = useState(false); // To track edit mode per item
  const [selectMode, setSelectMode] = useState(false);

  const currencyOptions = [
    { value: 'USD', label: 'USD' },
    { value: 'AED', label: 'AED' },
    { value: 'INR', label: 'INR' },
  ];

  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_vendor_quotes_get?Spares_Inquiry_ID=${id}`)
      .then((response) => {
        const rawData = response.data.data;

        // Split the Rate into numeric value and currency
        const groupedDataRateSplit = rawData.map((item) => {
          if (item.Rate) {
            const rateParts = item.Rate.split(' ');
            item.RateNumeric = rateParts[0]; // Numeric part of the rate
            item.RateCurrency = rateParts[1] || 'USD'; // Currency part, defaulting to USD if missing
          } else {
            item.RateNumeric = '';
            item.RateCurrency = 'USD'; // Default currency
          }
          return item;
        });

        const groupedData = groupByItemDetailsSNo(groupedDataRateSplit);
        setQuotesData(groupedData);
        setFormDataQuotes(groupedData); // Initialize formDataQuotes
        findMaxSupplierItem(groupedData); // Find item with the max suppliers
      })
      .catch((error) => {
        console.error("Error fetching the data", error);
      });
  }, [refresh]);

  console.log("formDataQuotes", formDataQuotes);

  // Group data by Item_Details_S_No

  const navigate = useNavigate();
  const dailyTrackerTab = (tabNumber) => {
    setTabTracker(tabNumber);
    if (tabNumber === 1) {
      navigate(`/workshop-spares-inquiry-vendor-quotes/${id}`);
    } else if (tabNumber === 2) {
      navigate(`/workshop-spares-inquiry-vendor-quotes-docs/${id}`);
    }
  };
  const groupByItemDetailsSNo = (data) => {
    const grouped = {};
    data.forEach((item) => {
      const { Item_Details_S_No } = item;
      if (!grouped[Item_Details_S_No]) {
        grouped[Item_Details_S_No] = { itemDetails: item, suppliers: [] };
      }
      grouped[Item_Details_S_No].suppliers.push(item);

      console.log("grouped", grouped);
    });
    return Object.values(grouped);
  };

  const findMaxSupplierItem = (data) => {
    if (data.length === 0) return;

    // Set to track unique Supplier_IDs
    const uniqueSuppliersSet = new Set();

    // Array to store unique supplier objects
    const uniqueSuppliers = [];

    // Iterate through each item
    data.forEach((item) => {
      // Iterate through each supplier of the item
      item.suppliers.forEach((supplier) => {
        const supplierID = supplier.Supplier_ID.trim(); // Make sure to trim any extra spaces
        // If the supplier is not already in the set, add the supplier object
        if (!uniqueSuppliersSet.has(supplierID)) {
          uniqueSuppliersSet.add(supplierID); // Track this Supplier_ID as unique
          uniqueSuppliers.push(supplier);     // Add the supplier object to the unique list
        }
      });
    });

    // You can now use uniqueSuppliers, which contains all unique supplier objects
    setMaxSupplierItem(uniqueSuppliers); // Assuming you're using setMaxSupplierItem to store the unique suppliers
  };

  console.log("maxSupplierItem", maxSupplierItem);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSelect = () => {
    setSelectMode(true);
  };

  const handleInputChange = (e, itemIndex, supplierIndex, field) => {
    const updatedFormData = [...formDataQuotes];

    if (field === "RateNumeric" || field === "RateCurrency") {
      // Update the numeric or currency part of the rate
      updatedFormData[itemIndex].suppliers[supplierIndex][field] = e.target.value;

      // Update the full Rate by concatenating the numeric and currency
      updatedFormData[itemIndex].suppliers[supplierIndex].Rate =
        `${updatedFormData[itemIndex].suppliers[supplierIndex].RateNumeric || ""} ${updatedFormData[itemIndex].suppliers[supplierIndex].RateCurrency || ""}`;
    } else {
      // Update other fields as usual
      updatedFormData[itemIndex].suppliers[supplierIndex][field] = e.target.value;
    }

    setFormDataQuotes(updatedFormData);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      // Loop through each item and supplier in formDataQuotes and prepare update requests
      const updatePromises = formDataQuotes.map(async (item) => {
        const supplierPromises = item.suppliers.map(async (supplier) => {
          const updatePayload = {
            Rate: supplier.Rate,
            Manufacturer_Part_Number: supplier.Manufacturer_Part_Number,
            Delivery_Time: supplier.Delivery_Time,
            Ex_Works: supplier.Ex_Works,
            Quoted_On: supplier.Quoted_On,
            Selected: supplier.Selected,
          };

          return axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_vendor_quotes_put?S_No=${supplier.S_No}`,
            updatePayload
          );
        });

        return Promise.all(supplierPromises);
      });

      await Promise.all(updatePromises);

      console.log("Data saved successfully");
      setEditMode(false);
      setSelectMode(false);
      setRefresh(!refresh); // Trigger a refresh or re-fetch of data
      setIsLoading(false);
    } catch (error) {
      console.error("Error submitting data:", error);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setSelectMode(false);
    setRefresh(!refresh);   // refresh page data
  };

  console.log("edit Mode", editMode);

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // Handle change for the Select dropdown
  const handleSelectChange = (e, itemIndex) => {
    const selectedSupplierId = e.target.value;
    const updatedFormData = [...formDataQuotes];

    // Update Selected values: Set the selected supplier to 1, and all others to null
    updatedFormData[itemIndex].suppliers.forEach((supplier) => {
      supplier.Selected = supplier.Supplier_ID === selectedSupplierId ? 1 : null;
    });

    setFormDataQuotes(updatedFormData);
  };

  return (
    <div>
      <LoadingScreen isLoading={isLoading} />

      <TabsBarSparesInquiry activeTab="Vendor Quotes" />

      <div className={styles.sections2}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {/* Left side - Tab */}
          <>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  borderBottomLeftRadius: "10px",
                  borderTopLeftRadius: "10px",
                  marginLeft: "10%",
                  cursor: "pointer",  // Add this line to change the cursor to a pointer on hover

                }}
                className={
                  TabTracker === 1 ? styles.aryaTabs1 : styles.aryaTabs2
                }
                onClick={() => dailyTrackerTab(1)}>
                Listing
              </div>
              <div
                style={{
                  borderBottomRightRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginLeft: "0%", // No need for margin, let them connect seamlessly
                  cursor: "pointer",

                }}
                className={
                  TabTracker === 2 ? styles.aryaTabs1 : styles.aryaTabs2
                }
                onClick={() => dailyTrackerTab(2)}>
                Docs
              </div>
            </div>
          </>


          <div className={styles.buttonsdiv}
            style={{
              border: "2px solid #E7EBFF",
              borderTop: "none",
              borderBottom: "none",
            }}>
            {editMode || selectMode ? (
              <>

                <button
                  variant="contained"
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  Save Changes
                </button>
                <button
                  variant="contained"
                  className={styles.cancel}
                  onClick={handleCancel}>
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  variant="contained"
                  onClick={handleSelect}>
                  Select
                </button>
                <button
                  variant="contained"
                  onClick={handleEdit}>
                  Edit
                </button>
              </>
            )}
          </div>
        </div>

        <div className={styles.smtablecontainer}>
          <table className={styles.smtable}>
            <thead>
              <tr className={styles.headrowsm}>
                <th className={styles.fileUploadheading}>S. No.</th>
                <th className={styles.fileUploadheading}>Item Name</th>
                <th className={styles.fileUploadheading}>Part Number</th>
                <th className={styles.fileUploadheading}>UoM</th>
                <th className={styles.fileUploadheading}>Select</th>
                {/* Render the headers for each supplier */}
                {maxSupplierItem &&
                  maxSupplierItem.map((_, index) => (
                    <React.Fragment key={index}>
                      <th className={styles.fileUploadheading}>Rate</th>
                      <th className={styles.fileUploadheading}>Manufacturer Part Number</th>
                      <th className={styles.fileUploadheading}>Delivery Time</th>
                      <th className={styles.fileUploadheading}>Ex Works</th>
                      <th className={styles.fileUploadheading}>Quoted On</th>
                    </React.Fragment>
                  ))}
              </tr>
              <tr className={styles.headrowsm}>
                <th style={{ backgroundColor: "white", border: "none" }}></th>
                <th style={{ backgroundColor: "white", border: "none" }}></th>
                <th style={{ backgroundColor: "white", border: "none" }}></th>
                <th style={{ backgroundColor: "white", border: "none" }}></th>
                <th style={{ backgroundColor: "white", border: "none" }}></th>
                {/* Render supplier-specific columns dynamically based on the max suppliers */}
                {maxSupplierItem &&
                  maxSupplierItem.map((supplier, index) => (
                    <th className={styles.fileUploadheading}
                      colSpan={5}
                      key={index}
                      style={{ background: "#5b6be1", color: "white", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", borderTopRightRadius: "0" }}
                    >
                      {supplier.Supplier_Name}
                    </th>
                  ))}
              </tr>
              {/* Spacer Row */}
              <tr>
                <td colSpan={maxSupplierItem ? (maxSupplierItem.length * 5 + 5) : 5} style={{ height: "15px", border: "none", backgroundColor: "white", padding: "0" }}></td>
              </tr>
            </thead>

            <tbody>
              {formDataQuotes.map((group, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{group.itemDetails.Item_Name}</td>
                  <td>{group.itemDetails.Part_Number}</td>
                  <td>{group.itemDetails.UoM}</td>
                  <td>
                    {selectMode ? (
                      <Select
                        value={group.suppliers.find(supplier => supplier.Selected == 1)
                          ? { label: group.suppliers.find(supplier => supplier.Selected == 1).Supplier_Name, value: group.suppliers.find(supplier => supplier.Selected == 1).Supplier_ID }
                          : null}
                        onChange={(selectedOption) => {
                          const selectedSupplierIndex = group.suppliers.findIndex(
                            (supplier) => supplier.Supplier_ID == selectedOption.value
                          );
                          handleSelectChange({ target: { value: selectedOption.value } }, index);
                        }}
                        options={[
                          { label: "Select Supplier", value: "" }, // Default option
                          ...group.suppliers.map(supplier => ({
                            label: supplier.Supplier_Name,
                            value: supplier.Supplier_ID
                          }))
                        ]}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: '150px',
                            height: '40px'
                          }),
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #d1d8fd",
                            borderRadius: "6px",
                            background: "#fdfdfd",
                            height: '40px',
                            boxShadow: "none",
                            cursor: "pointer",
                            overflowY: "hidden",
                          }),
                          clearIndicator: (provided) => ({
                            ...provided,
                            display: "none", // Hide the clear indicator
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "200px",
                            overflowY: "scroll", // Enable vertical scrolling
                            scrollbarWidth: "thin",
                            scrollbarColor: "#c5c5c5 #f5f5f5",
                            zIndex: "100"
                          }),
                        }}
                        isClearable={false}
                      />
                    ) : (
                      group.suppliers.find((supplier) => supplier.Selected == 1)?.Supplier_Name || "-"
                    )}
                  </td>

                  {/* Render supplier-specific data dynamically */}
                  {maxSupplierItem &&
                    maxSupplierItem.map((maxSupplier, maxSupplierIndex) => {
                      // Find the matching supplier in the group for this maxSupplier
                      const matchedSupplier = group.suppliers.find(
                        (supplier) => supplier.Supplier_ID === maxSupplier.Supplier_ID
                      );

                      return (
                        <React.Fragment key={maxSupplierIndex}>
                          {/* If matchedSupplier exists, render its data, otherwise render an empty cell */}
                          {/* <td>
                            {(matchedSupplier && editMode) ? (
                              <input
                                type="text"
                                className={styles.inputFieldsm}
                                value={matchedSupplier ? matchedSupplier.Rate || "" : ""}
                                onChange={(e) =>
                                  handleInputChange(e, index, group.suppliers.indexOf(matchedSupplier), "Rate")
                                }
                                disabled={!matchedSupplier}
                              />
                            ) : (
                              matchedSupplier ? matchedSupplier.Rate || "-" : ""
                            )}
                          </td> */}
                          <td>
                            {(matchedSupplier && editMode) ? (
                              <div style={{ display: 'flex' }}>
                                <input
                                  type="number"
                                  className={styles.inputFieldsm}
                                  value={matchedSupplier ? matchedSupplier.RateNumeric || "" : ""}
                                  onChange={(e) =>
                                    handleInputChange(e, index, group.suppliers.indexOf(matchedSupplier), "RateNumeric")
                                  }
                                  disabled={!matchedSupplier}
                                  style={{ width: "100px", marginRight: "5px" }}
                                />
                                <Select
                                  value={currencyOptions.find(option => option.value === matchedSupplier.RateCurrency)}
                                  onChange={(selectedOption) => handleInputChange({ target: { value: selectedOption.value } }, index, group.suppliers.indexOf(matchedSupplier), "RateCurrency")}
                                  options={currencyOptions}
                                  styles={{
                                    container: (provided) => ({
                                      ...provided,
                                      width: '90px',
                                      height: '40px'
                                    }),
                                    control: (provided) => ({
                                      ...provided,
                                      border: "1px solid #d1d8fd",
                                      borderRadius: "6px",
                                      background: "#fdfdfd",
                                      height: '40px',
                                      boxShadow: "none",
                                      cursor: "pointer",
                                      overflowY: "hidden",
                                    }),
                                    clearIndicator: (provided) => ({
                                      ...provided,
                                      display: "none", // Hide the clear indicator
                                    }),
                                    menuList: (provided) => ({
                                      ...provided,
                                      maxHeight: "200px",
                                      overflowY: "scroll", // Enable vertical scrolling
                                      scrollbarWidth: "thin",
                                      scrollbarColor: "#c5c5c5 #f5f5f5",
                                      zIndex: "100"
                                    }),
                                  }}
                                />
                              </div>
                            ) : (
                              matchedSupplier ? matchedSupplier.Rate || "-" : ""
                            )}
                          </td>

                          <td>
                            {(matchedSupplier && editMode) ? (
                              <input
                                type="text"
                                className={styles.inputFieldsm}
                                value={matchedSupplier ? matchedSupplier.Manufacturer_Part_Number || "" : ""}
                                onChange={(e) =>
                                  handleInputChange(e, index, group.suppliers.indexOf(matchedSupplier), "Manufacturer_Part_Number")
                                }
                                disabled={!matchedSupplier}
                              />
                            ) : (
                              matchedSupplier ? matchedSupplier.Manufacturer_Part_Number || "-" : ""
                            )}
                          </td>
                          <td>
                            {(matchedSupplier && editMode) ? (
                              <input
                                type="text"
                                className={styles.inputFieldsm}
                                value={matchedSupplier ? matchedSupplier.Delivery_Time || "" : ""}
                                onChange={(e) =>
                                  handleInputChange(e, index, group.suppliers.indexOf(matchedSupplier), "Delivery_Time")
                                }
                                disabled={!matchedSupplier}
                              />
                            ) : (
                              matchedSupplier ? matchedSupplier.Delivery_Time || "-" : ""
                            )}
                          </td>
                          <td>
                            {(matchedSupplier && editMode) ? (
                              <input
                                type="text"
                                className={styles.inputFieldsm}
                                value={matchedSupplier ? matchedSupplier.Ex_Works || "" : ""}
                                onChange={(e) =>
                                  handleInputChange(e, index, group.suppliers.indexOf(matchedSupplier), "Ex_Works")
                                }
                                disabled={!matchedSupplier}
                              />
                            ) : (
                              matchedSupplier ? matchedSupplier.Ex_Works || "-" : ""
                            )}
                          </td>
                          <td>
                            {(matchedSupplier && editMode) ? (
                              <input
                                type="date"
                                className={styles.inputFieldsm}
                                value={matchedSupplier ? matchedSupplier.Quoted_On || "" : ""}
                                onChange={(e) =>
                                  handleInputChange(e, index, group.suppliers.indexOf(matchedSupplier), "Quoted_On")
                                }
                                disabled={!matchedSupplier}
                              />
                            ) : (
                              matchedSupplier ? (matchedSupplier.Quoted_On ? formatDate(matchedSupplier.Quoted_On) : "-") : ""
                            )}
                          </td>
                        </React.Fragment>
                      );
                    })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SparesTradingInquiryVendorQuotes;