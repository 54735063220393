import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./css/purchases.module.css";
import { Button, IconButton } from "@mui/material";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Purchases() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [purchases, setPurchases] = useState([]);
  const [filteredPurchases, setFilteredPurchases] = useState([]);
  const [filterContainer, setFilterContainer] = useState(false);
  const [storeFilter, setStoreFilter] = useState([]);
  const [purchaseStatus, setPurchaseStatus] = useState([]); //controls the pending/completed indicator, false is pending, true is completed
  const [completedChecked, setCompletedChecked] = useState(false);
  const [pendingChecked, setPendingChecked] = useState(false);
  const [removeFilterTrigger, setRemoveFilterTrigger] = useState(false);
  const [purchaseItems, setPurchaseItems] = useState([]);
  const [filterItems, setFilterItems] = useState({});
  const [featurePurchasescreate,setfeaturePurchasescreate] = useState([]);
   


  const handleCreateNewClick = () =>{
    navigate(`/purchasecreatenew`);
  }

  const handleFilterItemsClick = (e) => {
    const name = e.currentTarget.getAttribute("name");

    const updatedFilterItems = {};
    for (const key in filterItems) {
      updatedFilterItems[key] = key === name;
    }

    setFilterItems(updatedFilterItems);
  };

  useEffect(() => {
    const fetchPurchases = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchases_get"
        );

        const resItems = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/full_item_details_get"
        );
        const filter = resItems.data.data; //get all item details where Purchase_ID is equal to the useParams Purchase_id
        setPurchaseItems(filter);           
        setPurchases(response.data.data);
        setFilteredPurchases(response.data.data);
        calculatePurchaseStatus(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPurchases();
  }, []);

  console.log(setSearchQuery);
  console.log(purchases);
  console.log(filteredPurchases);

  const handleSearchInputChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    // Filter purchases based on the search query
    const filtered = purchases.filter((project) => {
      const PO_Number = project?.PO_Number
        ? project.PO_Number.toString()
        : "";
      const Linked_Project = project?.Linked_Project ? project.Linked_Project.toString() : "";
      const Supplier_Name = project?.Supplier_Name
        ? project.Supplier_Name.toString()
        : "";

      const matchSearchQuery =
        PO_Number.toLowerCase().includes(value.toLowerCase()) ||
        Linked_Project.toLowerCase().includes(value.toLowerCase()) ||
        Supplier_Name.toLowerCase().includes(value.toLowerCase());

      return matchSearchQuery;
    });

    console.log("Filtered: ", filtered);
    setFilteredPurchases(filtered);
  };

  const openFilterContainer = (e) => {
    e.preventDefault();
    setFilterContainer(!filterContainer);
    setFilterItems({
      status: false,
    });
  };

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }

    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  
  // const getPendingQuantity = (Purchase_ID) => {
  //   // Replace this with logic to fetch qty_pending for the given Purchase_ID
  //   // For now, assuming qty_pending is not available and returning 1 and 0 for alternating Purchase_IDs
  //   if (Purchase_ID % 2 !== 0) {
  //     return 0;
  //   }
  // };
  
  const getPendingQuantity = async (Purchase_ID) => {
    try {
      // Fetch item details for the given Purchase_ID
      const response = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemdetails_get`
      );
      const filter = response.data.data.filter((el) => el.Purchase_ID == Purchase_ID);
     console.log( filter);


      // Fetch incoming items for the given Purchase_ID
      const purchaseIncomingItems = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_items_get?Purchase_ID=${Purchase_ID}`
      );
      const incomingItems = purchaseIncomingItems.data.data;
  
      // Initialize Qty_received, Qty_in_transit, and Qty_pending properties
      const updatedFilter = filter.map((item) => ({
        ...item,
        Qty_received: null,
        Qty_in_transit: null,
        Qty_pending: null,
      }));
  
      // Calculate Qty_received and Qty_in_transit for each item
      incomingItems.forEach((incomingItem) => {
        const index = updatedFilter.findIndex(
          (item) => item.OEM_Part_Number === incomingItem.OEM_Part_Number && item.Item_Name === incomingItem.Item_Name && item.Manufacturer_Part_Number === incomingItem.Manufacturer_Part_Number
        );
  
        if (index !== -1) {
          if (incomingItem.Received_Delivery_Status === 1) {
            updatedFilter[index].Qty_received =
              (updatedFilter[index].Qty_received || 0) +
              incomingItem.Scheduled_Quantity;
          } else if (incomingItem.Received_Delivery_Status === 0) {
            updatedFilter[index].Qty_in_transit =
              (updatedFilter[index].Qty_in_transit || 0) +
              incomingItem.Scheduled_Quantity;
          }
  
          // Update Qty_pending
          updatedFilter[index].Qty_pending =
            updatedFilter[index].Qty_ordered -
            updatedFilter[index].Qty_received -
            updatedFilter[index].Qty_in_transit;
        }
      });
      console.log("updatedFilter", updatedFilter);
      // Check if any item has Qty_pending greater than 0
      const isPending = updatedFilter.length === 0 || (updatedFilter.some(
        (item) => item.Qty_pending > 0 || item.Qty_pending == null || item.Qty_pending == undefined
      ));
      console.log("isPending", isPending);
      // Return 1 if any item has Qty_pending greater than 0, otherwise return 0
      return isPending ? 1 : 0;
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  };

  const calculatePurchaseStatus = async(purchaseData) => {
    const updatedStatus = await Promise.all(purchaseData.map(async (purchase) => {
      const { Purchase_ID } = purchase;
  
      const pending_qty = await getPendingQuantity(Purchase_ID); 
      console.log("Purchase_ID, pending_qty", Purchase_ID, pending_qty);
  
      if (pending_qty === null || pending_qty === undefined || pending_qty > 0) {
        return { Purchase_ID, status: false };
      } else if (pending_qty === 0) {
        return { Purchase_ID, status: true };
      }
  
      return null;
    }));
  
    // Remove null entries from the array
    const filteredStatus = updatedStatus.filter((status) => status !== null);
  
    setPurchaseStatus(filteredStatus);
    console.log("filteredStatus",filteredStatus);
  };

  const handleApplyFilters = () => {
    updateStoreFilterOnApply();

    if (completedChecked || pendingChecked) {
      const filteredStatus = purchaseStatus.filter((status) => {
        return (
          (completedChecked && status.status) ||
          (pendingChecked && !status.status)
        );
      });
  
      const filteredPurchaseIds = filteredStatus.map((status) => status.Purchase_ID);
      const filteredPurchasesData = purchases.filter((project) =>
        filteredPurchaseIds.includes(project.Purchase_ID)
      );
  
      setFilteredPurchases(filteredPurchasesData);
    } else {
      // Neither checkbox is checked, revert to original data
      setFilteredPurchases(purchases);
    }
    console.log(storeFilter);
    setFilterContainer(false);
  };
  
  const handleCompletedCheckboxChange = (e) => {
    setCompletedChecked(e.target.checked);
  };
  
  const handlePendingCheckboxChange = (e) => {
    setPendingChecked(e.target.checked);
  };  
  
  const updateStoreFilterOnApply = () => {
    const updatedFilter = [];
    if (completedChecked) {
      updatedFilter.push('Completed');
    }
    if (pendingChecked) {
      updatedFilter.push('Pending');
    }
    setStoreFilter(updatedFilter);
  };
    
  const removeFilter = (e, index, filter) => {
    e.preventDefault();
    const updatedFilters = [...storeFilter];
    updatedFilters.splice(index, 1);
    setStoreFilter(updatedFilters);
    setRemoveFilterTrigger(!removeFilterTrigger);
    // Handle removing the corresponding checkbox
    if (filter === 'Pending') {
      setPendingChecked(false);
    } else if (filter === 'Completed') {
      setCompletedChecked(false);
    }
    
    handleApplyFilters();

    console.log(storeFilter);
  };

  useEffect(() => {
    handleApplyFilters();
  }, [removeFilterTrigger]);
    
  useEffect(()=>{
    async function fetchRigDetails(){
    try{
      const response = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
      );
    
    
 
      const workshoppurchasescreate= response.data.filter((el)=>{
        return(
        el.Group_Name === localStorage.getItem("Group") &&
        el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Purchases Create" && (el.Access === "Can View" || el.Access === "Can Edit")
        );
      })
 
    
      setfeaturePurchasescreate(workshoppurchasescreate);
    
    }catch(error){
      console.error(error);
    }
    }
    fetchRigDetails();
      },[]);
    
  return (
    <div>
      <div className={styles.toprowcontainer}>
        <div className={styles.topheaderlabel}>
          Purchases
        </div>
        <div className={styles.searchbar}>
          <input
            className={styles.inputtext}
            type="text"
            placeholder= "Search by PO No, Linked SO, Supplier Name"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className={styles.filterContainerworkshop}>
          <Button
            onClick={openFilterContainer}
            variant="contained"
            className={styles["btn-filter"]}>
            Filter
            <svg
              style={{ marginLeft: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M13.491 1.66699H6.50768C3.47435 1.66699 1.66602 3.47533 1.66602 6.50866V13.4837C1.66602 16.5253 3.47435 18.3337 6.50768 18.3337H13.4827C16.516 18.3337 18.3243 16.5253 18.3243 13.492V6.50866C18.3327 3.47533 16.5243 1.66699 13.491 1.66699ZM11.1077 14.167H8.88268C8.54102 14.167 8.25768 13.8837 8.25768 13.542C8.25768 13.2003 8.54102 12.917 8.88268 12.917H11.1077C11.4493 12.917 11.7327 13.2003 11.7327 13.542C11.7327 13.8837 11.4577 14.167 11.1077 14.167ZM13.3327 10.6253H6.66602C6.32435 10.6253 6.04102 10.342 6.04102 10.0003C6.04102 9.65866 6.32435 9.37533 6.66602 9.37533H13.3327C13.6743 9.37533 13.9577 9.65866 13.9577 10.0003C13.9577 10.342 13.6743 10.6253 13.3327 10.6253ZM14.9993 7.08366H4.99935C4.65768 7.08366 4.37435 6.80033 4.37435 6.45866C4.37435 6.11699 4.65768 5.83366 4.99935 5.83366H14.9993C15.341 5.83366 15.6243 6.11699 15.6243 6.45866C15.6243 6.80033 15.341 7.08366 14.9993 7.08366Z"
                fill="#292D32"
              />
            </svg>
          </Button>
          {filterContainer && (
            <div className={styles.optionContainerworkshop}>
              <div
                name="status"
                onClick={handleFilterItemsClick}
                className={
                  filterItems.status
                    ? `${styles.optionsList} ${styles.activeOption}`
                    : styles.optionsList
                }>
                <h5>Status</h5>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {filterItems.status && (
                  <div className={styles.itemsContainer}>
                    <div className={styles.optionsList} style={{cursor:"default"}}>
                      <h5 style={{ whiteSpace: "nowrap" }}>Pending</h5>
                      <input
                        type="checkbox"
                        name="Pending"
                        value="Pending"
                        checked={pendingChecked}
                        onChange={handlePendingCheckboxChange}
                      />
                    </div>
                    <div className={styles.optionsList} style={{cursor:"default"}}>
                      <h5>Completed</h5>
                      <input
                        type="checkbox"
                        name="Completed"
                        value="Completed"
                        checked={completedChecked}
                        onChange={handleCompletedCheckboxChange}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.applyContainer}>
                <Button
                  onClick={handleApplyFilters}
                  variant="contained"
                  className={styles.applyBtn}>
                  Apply
                </Button>
              </div>
            </div>
          )}
{featurePurchasescreate.length !==0 &&(
          <Button
            variant="contained" 
            className={styles["btn-Createnew"]}
            onClick={handleCreateNewClick}
          >
            Create New
            <svg
              style={{ marginLeft: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-plus-square-fill"
              viewBox="0 0 16 16">
              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
            </svg>
          </Button>
          )}
        </div>
      </div>

      {storeFilter.length !== 0 && (
        <div className={styles.projectList} style={{ padding: "10px 0" }}>
          <div className={styles.filterContainer}>
            <div className={styles.svgContainer}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM13.33 17H10.66C10.25 17 9.91 16.66 9.91 16.25C9.91 15.84 10.25 15.5 10.66 15.5H13.33C13.74 15.5 14.08 15.84 14.08 16.25C14.08 16.66 13.75 17 13.33 17ZM16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75ZM18 8.5H6C5.59 8.5 5.25 8.16 5.25 7.75C5.25 7.34 5.59 7 6 7H18C18.41 7 18.75 7.34 18.75 7.75C18.75 8.16 18.41 8.5 18 8.5Z"
                  fill="#292D32"
                />
              </svg>
            </div>
            {storeFilter.map((el, index) => (
              <div key={index} className={styles.fieldsContainer}>
                <h5>{el}</h5>
                <IconButton
                  onClick={(e) => removeFilter(e, index, el)}
                  className={styles.iconBtn}>
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}

      {filteredPurchases
        .sort((a, b) => b.Purchase_ID - a.Purchase_ID)
        .map((el) => {
        const statusInfo = purchaseStatus.find((status) => status.Purchase_ID === el.Purchase_ID);

        return (
          <div className={styles.projectList} key={el.Purchase_ID}>
            <NavLink to={`/purchase_details/${el.Purchase_ID}`} className={styles.link}>
              {statusInfo ? (
                <div className={styles.statusButtonWorkshop}>
                  {statusInfo.status ? (
                    <button className={styles.completedButton} variant="contained">
                      Completed
                    </button>
                  ) : (
                    <button className={styles.pendingButton} variant="contained">
                      Pending
                    </button>
                  )}
                </div>
              ) : (
                // Handle the case where statusInfo is not found
                <div className={styles.statusButtonWorkshop}>
                  <button className={styles.pendingButton} variant="contained">
                    Pending
                  </button>
                </div>
              )}

              <div className={styles.top}>
                <h6>
                  PO : <span>{el.PO_Number || '-'}</span>
                </h6>
                <h6>|</h6>
                <h6>
                  Linked SO : <span>{el.Linked_Project || '-'}</span>
                </h6>
              </div>
              <div className={styles.content}>
                <div style={{ width: "auto", minWidth: "320px", whiteSpace: "nowrap" }}>
                  {el.Supplier_Name || '-'}
                </div>
                <div style={{ width: "78px", display: "flex", marginLeft: "5%" }}>
                  <span style={{ whiteSpace: "nowrap" }}>
                    Expected Arrival Date: &nbsp;{formatDate(el.Expected_Arrival_Date) || '-'}
                  </span>{" "}
                  &nbsp;
                </div>
              </div>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
}

export default Purchases