import React, { useState, useEffect ,useContext } from 'react';
import styles from '../Components/css/dashboard.module.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import redirecticon from "../Components/redirect.svg"
import { MyContext } from "../services/provider";

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const Dashboard = () => {
  const [notifications, setNotifications] = useState([]);
  const [pdata, setPdata] = useState([]);
  const navigate = useNavigate();
  const [tabno, setTabno] = useState(1);
  const { notificationData,sendNotificationToAll } = useContext(MyContext);

  useEffect(() => {
    const fetchProjectdetails = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
        );
        const data = response.data.data;
        setPdata(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProjectdetails();
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details_notification"
        );
        const userEmail = localStorage.getItem('useremail');
        const n = response.data.data;
        n.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
        setNotifications(n.filter(notif => notif.User_Email === userEmail));
      } catch (error) {
        console.error(error);
      }
    };
    fetchNotifications(); // Fetch initially
  }, []);

 

  const handleDivClick = () => {
    setTabno(1);
    navigate(`/dashboard`);
  };

  const handleDivClick1 = () => {
    setTabno(2);
    navigate(`/dashboard-alerts`);
  };

  const handleDivclick2 = () => {
    navigate(`/dashboard-alerts`);
  };

  function getRigNameById(rigs, id) {
    const rig = rigs.find(rig => rig.ID_No === id);
    return rig ? rig.Rig_Name : null;  // Returns Rigname if found, otherwise null
  }

  function getRigSONOById(rigs, id) {
    const rig = rigs.find(rig => rig.ID_No === id);
    return rig ? rig.Sales_Order_No : null;  // Returns Sales Order No if found, otherwise null
  }

  return (
    <div className={styles.maindiv}>
      <div className={styles.aryTabs}>
        <div
          className={tabno === 1 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick}>
          Dashboard
        </div>
        <div
          className={tabno === 2 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick1}>
          Alerts
        </div>
      </div>
      <div className={styles.innerdiv}>
        <div style={{ display: "flex", gap: "5px" }}>
          <div style={{ marginLeft: "30px", marginTop: "20px", fontSize: "24px", fontWeight: "700", alignItems: "center", color: "#5B6BE1" }}>
            Alerts
          </div>
          <div style={{marginTop:"21px",fontSize: "20px"}}>
            ⚠️
          </div>
          <div 
            style={{
              textDecoration: "underline",
              textDecorationColor: "#5B6BE1",
              color: "black",
              fontWeight: 'bold',
              fontSize: '18px',
              marginLeft: "auto",
              marginTop: "7%",
              whiteSpace: "nowrap",
              cursor: "pointer",
              display: "flex",
              gap: "1px"
            }}
            onClick={handleDivclick2}
          >
            see more
            <div><img src={redirecticon} height="20px" width="20px" alt="redirect icon" /></div>
          </div>
        </div>
        <div style={{ marginTop: "15px", borderTop: "1px solid #D1D9FF", marginLeft: "-35px" }} className="alerts-container">
          <ul>
            {notifications.map((item, index) => (
              <li key={index} className={`${styles.alert}`}>
                <div style={{ display: "flex" }}>
                  <div>
                    <svg height="10" width="20" xmlns="http://www.w3.org/2000/svg">
                      <circle r="5" cx="5.5" cy="5" fill="#5B6BE1" />
                    </svg>
                  </div>
                  <div style={{ width: "1000%", display: "flex", flexDirection: "row", gap: "70px" }}>
                    <p style={{fontSize:"13px"}}>
                      Commencement date for Rig <b>{getRigNameById(pdata, item.Project_ID)}</b>,
                      SO <b>{getRigSONOById(pdata, item.Project_ID)}</b> changed from <b>{item.Previous_Commencement_Date}</b> to <b>{item.New_Commencement_Date}</b> due to <b>{item.Reason_For_Change}</b>
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
