import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/dailytrackerinquiry.module.css";
import TabsBarDailyTracker from "./TabsBarDailyTracker.js";
import { NavLink } from "react-router-dom";
import LoadingScreen from './LoadingScreen'

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

const DailyTrackerSparesTrading = () => {
    // const [isLoading, setIsLoading] = useState(false); 
    const [activeStatus, setActiveStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [OngoingData, setOngoingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                // Step 1: Fetch the main workshop spares trading details
                const response = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/workshop-spares-trading-details');
                const projects = response.data;

                // Extract ID_No and Sales_Order_No for each project
                const projectIds = projects.map(project => project.ID_No).join(',');
                const salesOrderNos = projects.map(project => project.Sales_Order_No).join(',');

                // Step 2: Fetch data in bulk using multiple IDs
                const [
                    projectPendingDataResponse,
                    pendingPODataResponse,
                    itemStatusDataResponse,
                ] = await Promise.all([
                    axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/spares_trading_project_pending_qty_items_get_two?ID_Nos=${projectIds}&Sales_Order_Nos=${salesOrderNos}`),
                    axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/get-entry-count?ID_Nos=${projectIds}`),
                    axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/fetch-items?ID_Nos=${projectIds}`),
                ]);

                const projectPendingData = projectPendingDataResponse.data.results;
                const pendingPOData = pendingPODataResponse.data;
                const itemStatusData = itemStatusDataResponse.data;

                // Step 3: Merge all data before updating OngoingData
                const mergedData = projects.map((project) => {
                    const pendingData = projectPendingData.find(
                        (data) => data.ID_No == project.ID_No && data.Sales_Order_No == project.Sales_Order_No
                    );
                    const poData = pendingPOData.find(
                        (data) => data.projectId == project.ID_No
                    );
                    const statusData = itemStatusData.find(
                        (data) => data.idNo == project.ID_No
                    );

                    return {
                        ...project,
                        itemsWithPendingQty: pendingData?.itemsWithPendingQty ?? null,
                        totalItemCount: pendingData?.totalItemCount ?? null,
                        totalEntries: poData?.totalEntries ?? null,
                        linkedPoCount: poData?.linkedPoCount ?? null,
                        item_14: statusData?.item_14 ?? null,
                        item_15: statusData?.item_15 ?? null,
                    };
                });

                // Step 4: Set OngoingData with all data at once
                setOngoingData(mergedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        let dataToFilter = [];
        dataToFilter = OngoingData;

        // Filter data based on ID_No matching searchTerm
        const filtered = dataToFilter
            .filter((item) =>
                item.Sales_Order_No.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.PO_No?.toString().toLowerCase().includes(searchTerm.toLowerCase()) // Ensure Po_No exists
            )
            .sort((a, b) => b.Sales_Order_No - a.Sales_Order_No);


        setFilteredData(filtered);
    }, [searchTerm, activeStatus, OngoingData]);

    function formatDate(inputDate) {
        if (inputDate === null || inputDate === "") {
            return "-";
        }
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }


    return (
        <div className={styles.listContainer}>

            <TabsBarDailyTracker activeTab='Spares Trading' activeStatus={activeStatus} setActiveStatus={setActiveStatus} setSearchTerm={setSearchTerm} />
            {/* <LoadingScreen isLoading={isLoading} /> */}
            <div className={styles.listingContainer}>
                <table className={styles.listTable}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: "60px", width: "60px" }}>S. No</th>
                            <th>SO No</th>
                            <th>PO No</th>
                            <th>Customer Name</th>
                            <th>Rig Name</th>
                            <th>PO date</th>
                            {activeStatus === 'Ongoing' && (
                                <>
                                    <th>Promised Dispatch date</th>
                                    <th>Revised Dispatch date</th>
                                    <th>Current Status</th>
                                    <th>Items pending delivery</th>
                                    <th>Items pending procurement</th>
                                    {/* // <th>Items pending receipt</th>
                  // <th>Items pending allocation</th> */}

                                </>
                            )}
                            {activeStatus === 'Delivered' && (
                                <>
                                    <th>Dispatch date</th>
                                    <th>Invoice status</th>
                                    <th>Payment receipt status</th>


                                </>
                            )}
                            {activeStatus === 'Closed' && (
                                <>
                                    <th>Dispatch date</th>
                                    <th>Invoice status</th>
                                    <th>Payment receipt status</th>


                                </>
                            )}

                        </tr>
                    </thead>
                    <tbody>
                        {activeStatus == 'Ongoing' && filteredData.filter(item =>
                            (item.item_14 !== 'Completed' || item.item_15 !== 'Completed') &&
                            (((item.totalItemCount !== null && item.totalItemCount !== undefined && item.totalItemCount !== 0) &&
                                (item.itemsWithPendingQty !== null && item.itemsWithPendingQty !== undefined && item.itemsWithPendingQty !== 0)) ||
                                (item.totalItemCount === null || item.totalItemCount === undefined || item.totalItemCount == 0 || item.totalItemCount === "")))

                            .map((item, index) => (
                                <tr key={item.ID_No}>
                                    <td>{index + 1}</td>
                                    <td><NavLink
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`/workshop-spares-trading-details-update/${item.ID_No}`, "_blank", "noopener,noreferrer");
                                        }}
                                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    >
                                        {item.Sales_Order_No || "-"}
                                    </NavLink></td>
                                    <td>{item.PO_No}</td>
                                    <td >{item.Customer_Name}</td>
                                    <td >{item.Rig_Name || "-"}</td>
                                    <td>{item.PO_Date ? formatDate(item.PO_Date) : "-"}</td>
                                    <td>{item.Promised_Dispatch_Date ? formatDate(item.Promised_Dispatch_Date) : "-"}</td>
                                    <td>{item.Estimated_Date_Of_Dispatch ? formatDate(item.Estimated_Date_Of_Dispatch) : "-"}</td>
                                    <td>{item.Current_Status}</td>
                                    <td style={{ backgroundColor: item.itemsWithPendingQty > 0 && item.itemsWithPendingQty < item.totalItemCount ? '#ffc989ba' : '#E2E6FF' }}>{item.totalItemCount ? `${item.itemsWithPendingQty}/${item.totalItemCount}` : "-"}</td>
                                    <td>{item.totalEntries ? `${item.linkedPoCount}/${item.totalEntries}` : "-"}</td>

                                </tr>
                            )
                            )}
                        {activeStatus == 'Delivered' && filteredData
                            .filter(item =>
                                (item.totalItemCount !== null && item.totalItemCount !== undefined && item.totalItemCount !== 0) &&
                                item.itemsWithPendingQty === 0 &&
                                (item.item_14 !== 'Completed' || item.item_15 !== 'Completed')
                            )
                            .map((item, index) => (
                                <tr key={item.ID_No}>
                                    <td>{index + 1}</td>
                                    <td><NavLink
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`/workshop-spares-trading-details-update/${item.ID_No}`, "_blank", "noopener,noreferrer");
                                        }}
                                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    >
                                        {item.Sales_Order_No || "-"}
                                    </NavLink></td>
                                    <td>{item.PO_No}</td>
                                    <td>{item.Customer_Name}</td>
                                    <td >{item.Rig_Name || "-"}</td>
                                    <td>{item.PO_Date ? formatDate(item.PO_Date) : "-"}</td>
                                    <td>{item.Estimated_Date_Of_Dispatch ? formatDate(item.Estimated_Date_Of_Dispatch) : "-"}</td>
                                    <td>{item.item_14}</td>
                                    <td>{item.item_15}</td>
                                </tr>
                            ))
                        }
                        {activeStatus == 'Closed' && filteredData
                            .filter(item =>
                                item.itemsWithPendingQty === 0 &&
                                (item.item_14 == 'Completed' && item.item_15 == 'Completed')
                            )
                            .map((item, index) => (
                                <tr key={item.ID_No}>
                                    <td>{index + 1}</td>
                                    <td><NavLink
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`/workshop-spares-trading-details-update/${item.ID_No}`, "_blank", "noopener,noreferrer");
                                        }}
                                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    >
                                        {item.Sales_Order_No || "-"}
                                    </NavLink></td>
                                    <td>{item.PO_No}</td>
                                    <td>{item.Customer_Name}</td>
                                    <td >{item.Rig_Name || "-"}</td>
                                    <td>{item.PO_Date ? formatDate(item.PO_Date) : "-"}</td>
                                    <td>{item.Estimated_Date_Of_Dispatch ? formatDate(item.Estimated_Date_Of_Dispatch) : "-"}</td>
                                    <td>{item.item_14}</td>
                                    <td>{item.item_15}</td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DailyTrackerSparesTrading;