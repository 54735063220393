import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_trading_dispatch.module.css"; // Use the same CSS module
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TabsBarSparesTrading from "./TabsBarSparesTrading";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";
const userEmail = localStorage.getItem('useremail');
const Name = localStorage.getItem('Name');

const SparestradingWorkshopDispatch = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState({});
  const [formData, setFormData] = useState({
    Sales_Order_No: "",
    PO_No: "",
    PO_Date: null,
    Quote_No: "",
    Quote_Date: null,
    Costing_ID: "",
    Customer_Name: "",
    Scope: "",
    Scope_Description: "",
    Estimated_Date_Of_Dispatch: null,
    Ops_Engineer: "",
    Current_Status: "",
  });
  const [originalData, setOriginalData] = useState({});
  const [getDispatch, setGetDispatch] = useState([]);
  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",
    PONO: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);
      const userRes = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_user_details_project_details?Email=${userEmail}`
      );

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
        Done_By: userRes.data.data[0].Name,
      });
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );

        if (response.data.success) {
          const data = response.data.data[0];
          setFormData({
            Sales_Order_No: data.Sales_Order_No,
            PO_No: data.PO_No,
            PO_Date: data.PO_Date,
            Quote_No: data.Quote_No,
            Quote_Date: data.Quote_Date,
            Costing_ID: data.Costing_ID,
            Customer_Name: data.Customer_Name,
            Scope: data.Scope,
            Scope_Description: data.Scope_Description,
            Estimated_Date_Of_Dispatch: data.Estimated_Date_Of_Dispatch,
            Ops_Engineer: data.Ops_Engineer,
            Current_Status: data.Current_Status,
          });
          setOriginalData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCreateNewClick = () => {
    navigate(`/workshop-spares-trading-dispatchcreatenew/${id}`);
  };

  const handleUpdateClick = (project_id) => {
    navigate(`/workshop-spares-trading-dispatchupdate/${id}/${project_id}`);
  };

  useEffect(() => {
    async function dispatchDetails() {
      try {
        const series = "16";
        const dispatch_id = "89"; // Example ID, replace with actual ID or logic to obtain it
        const res = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getdispatchdetails`, {
          params: {
            series: series,
            dispatch_id: dispatch_id
          }
        });
        const filter = res.data.results.reverse().filter(item => item.Project_ID == id)

        // Fetch delivery data
        const resDeliveryDetails = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_sparestrading_itemdetails_deliveries_data_get"
        );

        const deliveryDetails = resDeliveryDetails.data.data;

        // Add Delivery_Status to each item in filter
        const updatedFilter = filter.map((item) => {
          // Find matching item in deliveryDetails by dispatch_id
          const matchingDelivery = deliveryDetails.find(
            (delivery) => delivery.Dispatch_ID === item.dispatch_id
          );

          // If matching delivery is found, use its Delivery_Status; otherwise, set as "Pending"
          return {
            ...item,
            Delivery_Status: matchingDelivery ? matchingDelivery.Delivery_Status : "Pending"
          };
        });

        console.log("filter", filter);
        console.log("updatedFilter", updatedFilter);
        setGetDispatch(updatedFilter);
      } catch (error) {
        console.error(error);
      }
    }
    dispatchDetails();
  }, []);
  
  // Function to determine the dispatch status
  const getDispatchStatus = (el) => {
    const deliveryDate = dayjs(el.Delivery_date, "YYYY-MM-DD");
    const today = dayjs();
    
    if (el.Delivery_Status === "Delivered") {
      return 'DELIVERED';
    } else if (el.Delivery_Status === "Dispatched") {
      return 'DISPATCHED';
    } else if (el.Delivery_Status === "Upcoming") {
      return 'UPCOMING';
    } else if (el.Delivery_Status === "Pending") {
      return 'PENDING';
    } else {
      return 'PENDING';
    }
  };

  // Function to determine the background color based on status
  const getBackgroundColor = (status) => {
    switch (status) {
      case 'DELIVERED':
        return '#077D7D'; // Green
      case 'DISPATCHED':
        return '#5B6BE1'; // Red
      case 'UPCOMING':
        return '#2C4667'; // Blue
case 'PENDING':
        return '#900909'; // Red  
      default:
        return '#6c757d'; // Gray for any undefined status
    }
  };

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }

    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <div>
      <div className={styles.topdiv}>
        <label className={styles.aryl1}>SO: {formData.Sales_Order_No}</label>
        <label className={styles.aryl}>PO: {formData.PO_No}</label>
        <label className={styles.aryl}>Customer Name: {formData.Customer_Name}</label>
      </div>

      <TabsBarSparesTrading activeTab="Dispatch" />

      <div className={styles.maindiv}>
        <div className={styles.sections2}>

          <div className={styles.buttonContainer}>
            <button
              variant="contained"
              className={styles["btn-Createnew"]}
              onClick={handleCreateNewClick}
            >
              <svg
                style={{marginRight: "4px"}}
                width="24"
                height="24"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 20C10.5 20.3978 10.658 20.7794 10.9393 21.0607C11.2206 21.342 11.6022 21.5 12 21.5C12.3978 21.5 12.7794 21.342 13.0607 21.0607C13.342 20.7794 13.5 20.3978 13.5 20V13.5H20C20.3978 13.5 20.7794 13.342 21.0607 13.0607C21.342 12.7794 21.5 12.3978 21.5 12C21.5 11.6022 21.342 11.2206 21.0607 10.9393C20.7794 10.658 20.3978 10.5 20 10.5H13.5V4C13.5 3.60218 13.342 3.22064 13.0607 2.93934C12.7794 2.65804 12.3978 2.5 12 2.5C11.6022 2.5 11.2206 2.65804 10.9393 2.93934C10.658 3.22064 10.5 3.60218 10.5 4V10.5H4C3.60218 10.5 3.22064 10.658 2.93934 10.9393C2.65804 11.2206 2.5 11.6022 2.5 12C2.5 12.3978 2.65804 12.7794 2.93934 13.0607C3.22064 13.342 3.60218 13.5 4 13.5H10.5V20Z"
                  fill="white"
                />
                <defs>
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </defs>
              </svg>
              Create Dispatch
            </button>
          </div>

          {Array.isArray(getDispatch) &&
            getDispatch.map((el, index) => {
              const status = getDispatchStatus(el);
              return (
                <div
                  key={index}
                  className={`${styles.projectList} ${styles.flexContainer}`}
                  onClick={() => handleUpdateClick(el.dispatch_id)}
                >
                  <div className={styles.textContainer}>
                    {`Dispatch ID: ${el.dispatch_id} \u00a0|\u00a0 
                      Purpose: ${el.Purpose || '-'} \u00a0|\u00a0 
                      Customer Name: ${el.Receiving_Party || '-'} \u00a0|\u00a0 
                      Delivery Date: ${formatDate(el.Delivery_date) || '-'}
                    `}
                  </div>
                  <button
                    className={styles.deliveryStatusButton}
                    style={{ backgroundColor: getBackgroundColor(status), color: "white" }}
                  >
                    {status}
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SparestradingWorkshopDispatch;
