import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/professionaldetailsaddnew.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/system";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

function ProfessionalDetailsAddNew() {
  const navigate = useNavigate();

  // For text, dropdown, date fields
  const [formData, setFormData] = useState({
    Customer_Name: "",
    Designation: "",
    Specialization: "",
    Retainership: "",
    Employee_ID: "",
    Nationality: "",
    Date_of_Joining: "",
    Date_of_Exit: "",
    Activity_Status: "",
    Employment_Status: "",
    Email: "",
    Contact_Number: "",
    Date_of_Birth: "",
    // We can store your doc fields here as text, if you wish,
    // but the actual file will go in the `files` state below.
    Contract_Doc: "", 
  });

  // For file inputs (Photo_Upload, Signature, etc.)
  const [files, setFiles] = useState({
    Photo_Upload: null,
    Signature: null,
    Contract_Doc: null,
  });

  // MUI styling for DatePicker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "280px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));

  // Handle text/dropdown changes
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file changes
  const handleFileChange = (e) => {
    const { name } = e.target;
    const selectedFile = e.target.files[0] || null;
    setFiles((prev) => ({
      ...prev,
      [name]: selectedFile,
    }));
  };

  // Handle date changes
  const handleDateChange = (fieldName, value) => {
    if (value) {
      // Convert date to DD/MM/YYYY or whichever format you prefer
      const selectedDate = value.format("DD/MM/YYYY");
      setFormData((prev) => ({ ...prev, [fieldName]: selectedDate }));
    } else {
      // If user clears the date
      setFormData((prev) => ({ ...prev, [fieldName]: "" }));
    }
  };

  // Discard or Cancel - just navigate back
  const handleDiscard = () => {
    navigate("/professionaldetails");
  };

  // Submit/Save the data
  const handleAdd = async (e) => {
    e.preventDefault();

    // Validation example for required fields
    if (!formData.Employee_ID || formData.Employee_ID.trim() === "") {
      alert("Employee ID is required and cannot be empty.");
      return;
    }

    try {
      const fd = new FormData();
      // Append text fields
      Object.keys(formData).forEach((key) => {
        fd.append(key, formData[key] ? formData[key] : "");
      });

      // Append file fields
      Object.keys(files).forEach((fileField) => {
        if (files[fileField]) {
          fd.append(fileField, files[fileField], files[fileField].name);
        }
      });

      // Make POST request
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professional_details_post_with_file",
        fd,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 201) {
        alert("Professional Details Added successfully");
        navigate("/professionaldetails");
      }
    } catch (error) {
      console.error("Error adding professional details:", error);
      alert("Failed to add Professional Details. Check console for details.");
    }
  };

  return (
    <div className={styles.createContainer}>
      <div className={styles.topContainer}>
        <div className={styles.top1}>
          <h3 className={styles.createHeading}>
            {"Professional Details > Create New"}
          </h3>
        </div>

        <div style={{ display: "flex", gap: "15px" }}>
          <button className={styles.addButton} onClick={handleAdd}>
            Create
          </button>
          <button className={styles.discardButton} onClick={handleDiscard}>
            Cancel
          </button>
        </div>
      </div>

      <div className={styles.supplierContainer}>
        <form>
          <div className={styles.heading1}>
            <h5>Professional Details</h5>
          </div>

          <div className={styles.container1}>
            {/* LEFT COLUMN */}
            <div className={styles.leftContainer}>
              <div className={styles.formElement}>
                <label>Name</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formData.Customer_Name}
                    name="Customer_Name"
                    onChange={handleOnChange}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Designation</label>
                <select
                  value={formData.Designation}
                  name="Designation"
                  onChange={handleOnChange}
                >
                  <option value="">Select Value</option>
                  <option value="Supervisor">Supervisor</option>
                  <option value="Technician">Technician</option>
                  <option value="Trainee">Trainee</option>
                </select>
                <svg
                  style={{ position: "absolute", right: "15" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div className={styles.formElement}>
                <label>Specialization</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formData.Specialization}
                    name="Specialization"
                    onChange={handleOnChange}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Retainership</label>
                <select
                  value={formData.Retainership}
                  name="Retainership"
                  onChange={handleOnChange}
                >
                  <option value="">Select Value</option>
                  <option value="Monthly-Fixed">Monthly-Fixed</option>
                  <option value="Daily-Retainer">Daily-Retainer</option>
                  <option value="Non-retainer">Non-retainer</option>
                </select>
                <svg
                  style={{ position: "absolute", right: "15" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div className={styles.formElement}>
                <label>Employee ID*</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formData.Employee_ID}
                    name="Employee_ID"
                    onChange={handleOnChange}
                    required
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Nationality</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formData.Nationality}
                    name="Nationality"
                    onChange={handleOnChange}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Date of Joining</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="Date_of_Joining"
                    value={
                      formData.Date_of_Joining
                        ? dayjs(formData.Date_of_Joining, "DD/MM/YYYY")
                        : null
                    }
                    onChange={(value) => handleDateChange("Date_of_Joining", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.formElement}>
                <label>Date of Exit</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="Date_of_Exit"
                    value={
                      formData.Date_of_Exit
                        ? dayjs(formData.Date_of_Exit, "DD/MM/YYYY")
                        : null
                    }
                    onChange={(value) => handleDateChange("Date_of_Exit", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>

            {/* RIGHT COLUMN */}
            <div className={styles.rightContainer}>
              <div className={styles.formElement}>
                <label>Photo Upload</label>
                <input
                  type="file"
                  className={styles.fileUpload}
                  name="Photo_Upload"
                  onChange={handleFileChange}
                />
                <svg
                  width="28"
                  height="25"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: "absolute", // Use absolute positioning
                    left: "460px", // Adjust position based on your layout
                    top: "50%", // Vertically center the SVG
                    transform: "translateY(-50%)", // Correct vertical alignment
                    pointerEvents: "none",
                  }}
                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div className={styles.formElement}>
                <label>Signature</label>
                <input
                  type="file"
                  className={styles.fileUpload}
                  name="Signature"
                  onChange={handleFileChange}
                />
                <svg
                  width="28"
                  height="25"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: "absolute", // Use absolute positioning
                    left: "460px", // Adjust position based on your layout
                    top: "50%", // Vertically center the SVG
                    transform: "translateY(-50%)", // Correct vertical alignment
                    pointerEvents: "none",
                  }}
                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div className={styles.formElement}>
                <label>Activity Status</label>
                <select
                  value={formData.Activity_Status}
                  name="Activity_Status"
                  onChange={handleOnChange}
                >
                  <option value="">Select Value</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                <svg
                  style={{ position: "absolute", right: "15" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div className={styles.formElement}>
                <label>Employment Status</label>
                <select
                  value={formData.Employment_Status}
                  name="Employment_Status"
                  onChange={handleOnChange}
                >
                  <option value="">Select Value</option>
                  <option value="Regular">Regular</option>
                  <option value="Temporary">Temporary</option>
                </select>
                <svg
                  style={{ position: "absolute", right: "15" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div className={styles.formElement}>
                <label>Mail ID</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formData.Email}
                    name="Email"
                    onChange={handleOnChange}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Contact Number</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    value={formData.Contact_Number}
                    name="Contact_Number"
                    onChange={handleOnChange}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Date of Birth</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="Date_of_Birth"
                    value={
                      formData.Date_of_Birth
                        ? dayjs(formData.Date_of_Birth, "DD/MM/YYYY")
                        : null
                    }
                    onChange={(value) => handleDateChange("Date_of_Birth", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.formElement}>
                <label>Upload Contract</label>
                <input
                  type="file"
                  className={styles.fileUpload}
                  name="Contract_Doc"
                  onChange={handleFileChange}
                />
                <svg
                  width="28"
                  height="25"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: "absolute", // Use absolute positioning
                    left: "460px", // Adjust position based on your layout
                    top: "50%", // Vertically center the SVG
                    transform: "translateY(-50%)", // Correct vertical alignment
                    pointerEvents: "none",
                  }}
                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfessionalDetailsAddNew;