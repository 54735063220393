import { ProfessionalTabs } from "./professional_Tabs";
import "../css/professionalEXP.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

export const ProfessionalExp = ({ activeTab, setActiveTab }) => {
  const { id } = useParams(); 
  const columnsRef = useRef({
    "Jacking": "Jacking",
    "Skidding": "Skidding",
    "Crane": "Crane",
    "Fixation": "Fixation",
    "Drilling Equipment": "Drilling Equipment",
    "Raw water tower": "Raw water tower",
    "Jacking Brake": "Jacking Brake",
  });

  const rowsRef = useRef({
    "MLT": "MLT",
    "BMC": "BMC",
    "Keppel": "Keppel",
    "F&G": "F&G",
    "Gusto": "Gusto",
    "Modec": "Modec",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({
    MLT: {
      "Jacking": false,
      "Skidding": false,
      "Crane": false,
      "Fixation": false,
      "Drilling Equipment": false,
      "Raw water tower": false,
      "Jacking Brake": false,
    },
    BMC: {
      "Jacking": false,
      "Skidding": false,
      "Crane": false,
      "Fixation": false,
      "Drilling Equipment": false,
      "Raw water tower": false,
      "Jacking Brake": false,
    },
    Keppel: {
      "Jacking": false,
      "Skidding": false,
      "Crane": false,
      "Fixation": false,
      "Drilling Equipment": false,
      "Raw water tower": false,
      "Jacking Brake": false,
    },
    "F&G": {
        "Jacking": false,
        "Skidding": false,
        "Crane": false,
        "Fixation": false,
        "Drilling Equipment": false,
        "Raw water tower": false,
        "Jacking Brake": false,
      },
    Gusto: {
      "Jacking": false,
      "Skidding": false,
      "Crane": false,
      "Fixation": false,
      "Drilling Equipment": false,
      "Raw water tower": false,
      "Jacking Brake": false,
    },
    Modec: {
      "Jacking": false,
      "Skidding": false,
      "Crane": false,
      "Fixation": false,
      "Drilling Equipment": false,
      "Raw water tower": false,
      "Jacking Brake": false,
    },
  });


    const getAllData = async () => {
      try {
        const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/proffessional/getAll/${id}`);
        const data = response.data;
        
        const updatedCheckboxValues = { ...checkboxValues };

        data?.forEach((item) => {
          const { Rig_Design, Scope, Experience_Value } = item;
       
          if (rowsRef.current[Rig_Design] && columnsRef.current[Scope]) {
            updatedCheckboxValues[Rig_Design][Scope] = Experience_Value === "Y";
          }
        });

        setCheckboxValues(updatedCheckboxValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  useEffect(() => {
    getAllData();
  }, []);

  const handleCheckboxChange = (row, column) => {
    setCheckboxValues((prevState) => ({
      ...prevState,
      [row]: {
        ...prevState[row],
        [column]: !prevState[row][column],
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const dataToSubmit = [];
  
      Object.keys(checkboxValues).forEach((row) => {
        Object.keys(checkboxValues[row]).forEach((column) => {
          const isChecked = checkboxValues[row][column];
          const rigDesignData = rowsRef.current[row];
          const scope = columnsRef.current[column];
  
          const experienceValue = isChecked ? "Y" : null;
  
          dataToSubmit.push({
            Prof_Details_S_No: id,
            Rig_Design: rigDesignData,
            Scope: scope,
            Experience_Value: experienceValue,  
          });
        });
      });
  
      const response = await axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/proffessional/create", dataToSubmit);
      if (response.status === 201) {
        getAllData();
        setIsEditing(false);
      } else if (response.status === 409) {
        console.log("Record already exists");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error.message);
    }
  };
  
  
  
  return (
    <>
      <ProfessionalTabs isEditing={isEditing} setIsEditing={setIsEditing} activeTab={activeTab} setActiveTab={setActiveTab} handleSubmit={handleSubmit} />
      <div className="table-prof">
        <div className="table-container-prof" style={{ width: "100%", maxHeight: "700px", overflowY: "auto" }}>
          <table className="table table-bordered">
            <thead style={{ position: "sticky", top: "0", background: "white", zIndex: "10" }}>
              <tr>
                <th>S.No</th>
                <th>Rig design</th>
                <th>Jacking</th>
                <th>Skidding</th>
                <th>Crane</th>
                <th>Fixation</th>
                <th>Drilling Equipment</th>
                <th>Raw water tower</th>
                <th>Jacking Brake</th>
              </tr>
            </thead>
            <tbody>
              {["MLT", "BMC", "Keppel", "F&G", "Gusto", "Modec"].map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row}</td>
                  {["Jacking", "Skidding", "Crane", "Fixation", "Drilling Equipment", "Raw water tower", "Jacking Brake"].map(
                    (column, colIndex) => (
                      <td key={colIndex}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                          {isEditing ? (
                            <>
                            <input
                              type="checkbox"
                              checked={checkboxValues[row][column]}
                              onChange={() => handleCheckboxChange(row, column)}
                            />
                            &nbsp;&nbsp;
                            <label>Y</label>
                            </>
                          ) : (
                            <div style={{color: "#26C77E"}}>{checkboxValues[row][column] ? "Y" : null}</div>
                          )}
                        </div>
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
