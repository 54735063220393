import React from "react";
import styles from "./css/ergonlogsdetails.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function ErgonInventoryLogsDetails() {
  const [isIncoming, setIsIncoming] = useState(true);

  const handleIncomingClick = () => {
    setIsIncoming(true);
  };

  const handleOutgoingClick = () => {
    setIsIncoming(false);
  };
  const [itemsMaster, setItemsMaster] = useState([]);
  const [itemsReceived, setItemsReceived] = useState([]);
  const [itemsReceived2, setItemsReceived2] = useState([]);
  const { Item_Id, part_number, Item_Name,Manufacturer_Part_Number } = useParams();
  // const decodedItemName = decodeURIComponent(Item_Name || '').replace('+', ' ');
  const decodedItemName = decodeURIComponent((Item_Name || '').replace(/\+/g, '%2B'));
  // const encodedItemName = ((Item_Name || '').replace(/\+/g, '%2B'))
  const encodedItemName = (Item_Name || '').replace(/\&/g, '%26').replace(/\+/g, '%2B');


  console.log(Item_Id, part_number, Item_Name, Manufacturer_Part_Number);
  console.log(decodedItemName);
  console.log(encodedItemName);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/ergoninventorylogs`);
  };

  const [IDNumbers, setIDNumbers] = useState([]);

  const [workshopProjectDetails, setWorkshopProjectDetails] = useState([]);
  const [finalSalesOrderNo, setFinalSalesOrderNo] = useState("");
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  

  const [incomingDeliveryIds, setIncomingDeliveryIds] = useState([]);
  const [dispatchingParties, setDispatchingParties] = useState({});


  

  console.log("Items received:",itemsReceived);

  useEffect(() => {
    async function fetchIncomingDeliveryIds() {
      try {
        // Fetch data from the API
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get8?part_number=${part_number}&Item_Name=${encodedItemName}&Manufacturer_Part_Number=${Manufacturer_Part_Number}`
        );
        console.log("Items received2:", res.data.data);

        setItemsReceived2(res.data.data);
        // Store Incoming_Delivery_Id in state
      } catch (error) {
        console.error(error);
      }
    }
    fetchIncomingDeliveryIds();
  }, [Item_Name,part_number, Manufacturer_Part_Number]);

  const [expectedArrivalDates, setExpectedArrivalDates] = useState({});
  const [boe, setBoe] = useState({});
  useEffect(() => {
    async function fetchDispatchingParties() {
      try {
        // Fetch data from the API
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries`
        );
        console.log("Workshop project details:", res.data);
        const workshopProjectDetails = res.data.data;
        console.log("Workshop project details:", workshopProjectDetails);

        // Ensure that workshopProjectDetails is an array before using array methods
        if (Array.isArray(workshopProjectDetails)) {
          // Create an object to store Dispatching_Party and Expected_Arrival_Date for each S_No (Incoming_Delivery_Id)
          const dispatchingParties = {};
          const expectedArrivalDates = {};
          const boe = {};

          workshopProjectDetails.forEach((item) => {
            if (incomingDeliveryIds.includes(item.S_No)) {
              // If S_No already exists in dispatchingParties, append the Dispatching_Party
              // Otherwise, create a new entry with the Dispatching_Party
              dispatchingParties[item.S_No] = dispatchingParties[item.S_No]
                ? [...dispatchingParties[item.S_No], item.Dispatching_Party]
                : [item.Dispatching_Party];

              // Set the Expected_Arrival_Date for the S_No
              expectedArrivalDates[item.S_No] = item.Expected_Arrival_Date;
              boe[item.S_No] = item.Entry_Bill_No;
            }
          });

          console.log("Dispatching Parties:", dispatchingParties);
          console.log("Expected Arrival Dates:", expectedArrivalDates);

          // Set the dispatchingParties state with the object
          setDispatchingParties(dispatchingParties);

          // Set the expectedArrivalDates state with the object
          setExpectedArrivalDates(expectedArrivalDates);
          setBoe(boe);
        } else {
          console.error(
            "Data received from API is not an array:",
            workshopProjectDetails
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchDispatchingParties();
  }, [incomingDeliveryIds]);

  const [salesOrderNos, setSalesOrderNos] = useState({}); // Define salesOrderNos state


  useEffect(() => {
    if (!decodedItemName || decodedItemName === 'null') {
        console.error('Invalid item name:', decodedItemName);
        // Handle error or redirect
        navigate('/error'); // Redirect to a generic error page or dashboard
    } else {
        fetchData();
    }
}, [decodedItemName]);

const fetchData = async () => {
  try {
      const response = await axios.get( `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incoming_inspection2?part_number=${part_number}&Item_Name=${encodedItemName}&Manufacturer_Part_Number=${Manufacturer_Part_Number}`, {
          // params: { Item_Id, part_number, Item_Name: decodedItemName },
          headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
          }
      });
      console.log('API data:', response.data);
      // process your data
  } catch (error) {
      console.error('Failed to fetch data:', error);
  }
}; 

  useEffect(() => {
    async function fetchSalesOrderNo() {
      try {
        // Fetch data from the second API
        const res2 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_details2`
        );
        console.log("Workshop project details:", res2.data);
        // setWorkshopProjectDetails(res2.data.data); // Assuming you don't need this anymore

        // Ensure that res2.data.data is an array before using array methods
        if (Array.isArray(res2.data.data)) {
          // Create an object to store Sales_Order_No for each Purchase_ID
          const updatedSalesOrderNos = {};
          // Iterate over the itemsReceived state
          res2.data.data.forEach((item) => {
            // If Purchase_ID already exists in updatedSalesOrderNos, assign the PO_Number
            // Otherwise, create a new entry with the PO_Number
            if (updatedSalesOrderNos[item.Purchase_ID]) {
              updatedSalesOrderNos[item.Purchase_ID] = item.PO_Number;
            } else {
              updatedSalesOrderNos[item.Purchase_ID] = item.PO_Number;
            }
          });
          console.log("Sales Order Numbers:", updatedSalesOrderNos);

          // Set the salesOrderNos state with the object
          setSalesOrderNos(updatedSalesOrderNos);
        } else {
          console.error(
            "Data received from API is not an array:",
            res2.data.data
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchSalesOrderNo();
  }, []); // No dependencies here assuming you don't need to re-fetch based on any state changes

  const [tableRows, setTableRows] = useState([]);

  const [qtyDelta, setQtyDelta] = useState([]);
  const [qtyRejected, setQtyRejected] = useState([]);
  const [traceabilityNumber, setTraceabilityNumber] = useState([]);

  useEffect(() => {
    async function fetchIncomingDeliveryIds() {
      try {
        // Ensure part_number and Item_Name are not undefined
        if (!part_number || !Item_Name) {
          console.warn("part_number or Item_Name is not defined.");
          return;
        }

        // Fetch data from the API
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get5?part_number=${part_number}&Item_Name=${encodedItemName}&Manufacturer_Part_Number=${Manufacturer_Part_Number}`
        );
        console.log("Items received:", res.data.data);

        const data = res.data.data;

        // Fetch details to get qtyDelta and qtyRejected
        const detailsRes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incoming_inspection2?part_number=${part_number}&Item_Name=${encodedItemName}&Manufacturer_Part_Number=${Manufacturer_Part_Number}`
        );

        const detailsData = detailsRes.data.data;

        // Extract Qty_Delta and Qty_Rejected
        const qtyDeltaMap = {};
        const qtyRejectedMap = {};

        detailsData.forEach((item) => {
          if (item.Incoming_Delivery_ID) {
            qtyDeltaMap[item.Incoming_Delivery_ID] =
              item.Qty_Delta !== null && item.Qty_Delta !== "" ? parseInt(item.Qty_Delta) : 0;
            qtyRejectedMap[item.Incoming_Delivery_ID] =
              item.Qty_Rejected !== null && item.Qty_Rejected !== "" ? parseInt(item.Qty_Rejected) : 0;
          }
        });

        setQtyDelta(qtyDeltaMap);
        setQtyRejected(qtyRejectedMap);

        // Filter out entries where Scheduled_Quantity is 0
        const filteredData = data.filter((item) => {
          const delta = qtyDeltaMap[item.Incoming_Delivery_ID] || 0;
          const rejected = qtyRejectedMap[item.Incoming_Delivery_ID] || 0;
          return item.Scheduled_Quantity !== null && (item.Scheduled_Quantity + delta - rejected) !== 0;
        });

        // Extract Incoming_Delivery_Id from filtered data
        const incomingDeliveryIds = filteredData.map((item) => item.Incoming_Delivery_ID);

        // Store filtered data in state
        setItemsReceived(filteredData);
        // Store Incoming_Delivery_Id in state
        setIncomingDeliveryIds(incomingDeliveryIds);
      } catch (error) {
        console.error("Error fetching items received:", error);
      }
    }

    fetchIncomingDeliveryIds();
  }, [part_number, Item_Name,Manufacturer_Part_Number]); // Ensure all dependencies are included

  useEffect(() => {
    async function fetchDetails() {
      try {
        // Ensure part_number, Item_Name, and incomingDeliveryIds are defined
        if (!part_number || !Item_Name || !incomingDeliveryIds.length) {
          console.warn("Dependencies are not defined.");
          return;
        }

        // Fetch data from the first API
        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incoming_inspection2?part_number=${part_number}&Item_Name=${encodedItemName}&Manufacturer_Part_Number=${Manufacturer_Part_Number}`
        );

        const data = res1.data.data;

        // Check if the response data is an array before processing
        if (Array.isArray(data)) {
          // Filter out items with matching Incoming_Delivery_ID
          const filteredItems = data.filter((item) =>
            incomingDeliveryIds.includes(item.Incoming_Delivery_ID)
          );

          // Extract Qty_Delta and Traceability_Number from filteredItems
          const Qty_Delta = filteredItems.map((item) =>
            item.Qty_Delta !== null && item.Qty_Delta !== "" ? item.Qty_Delta : 0
          );
          const Qty_Rejected = filteredItems.map((item) =>
            item.Qty_Rejected !== null && item.Qty_Rejected !== "" ? item.Qty_Rejected : 0
          );
          const Traceability_Number = filteredItems.map(
            (item) => item.Traceability_Number
          );

          // Update state variables with extracted values
          setQtyDelta(Qty_Delta);
          setQtyRejected(Qty_Rejected);
          setTraceabilityNumber(Traceability_Number);
        } else {
          console.error("Data received from API is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    }
    fetchDetails();
  }, [part_number, Item_Name, incomingDeliveryIds, Manufacturer_Part_Number]); // Ensure all dependencies are included

  console.log("delta1",qtyDelta)
  console.log("delta2",qtyRejected)

  const totalUtilized = itemsReceived2.reduce((total, item) => {
    const utilized =
      item.Qty_Utilized !== null && item.Qty_Utilized !== ""
        ? parseInt(item.Qty_Utilized)
        : 0;
    return total + utilized;
  }, 0);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div style={{ marginTop: "115px", marginLeft: "1%", marginRight: "1%" }}>
      <div>
        <button className={styles.mybutton} onClick={handleClick}>
          &lt; Transaction History
        </button>
      </div>
      <div>
        <div className={styles.box}>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Item Name:{" "}
              {Item_Name}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Part Number:{" "}
              {part_number}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Manufacturer Part Number:{" "}
              {Manufacturer_Part_Number}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Available Quantity:{" "}
              {itemsReceived.reduce((total, item, index) => {
                const scheduledQuantity =
                  item.Scheduled_Quantity !== null &&
                  item.Scheduled_Quantity !== ""
                    ? parseInt(item.Scheduled_Quantity)
                    : 0;
                const delta =
                  qtyDelta[index] !== null && qtyDelta[index] !== ""
                    ? parseInt(qtyDelta[index])
                    : 0;
                const rejected =
                  qtyRejected[index] !== null && qtyRejected[index] !== ""
                    ? parseInt(qtyRejected[index])
                    : 0;

                return total + scheduledQuantity + delta - rejected;
              }, 0) - totalUtilized}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Units: {itemsReceived.length > 0 ? itemsReceived[0].UoM : ""}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Item ID: {Item_Id}
             
            </label>
          </div>
        </div>
      </div>
      {console.log(Item_Id)}
      {console.log(Item_Name)}

      <div style={{ marginTop: "15px" }}>
        <button
          className={`${styles["incomingButton"]} ${
            isIncoming ? styles["incoming"] : styles["not-incoming"]
          }`}
          onClick={handleIncomingClick}
        >
          Incoming
        </button>
        <button
          className={`${styles["outgoingButton"]} ${
            !isIncoming ? styles["outgoing"] : styles["not-outgoing"]
          }`}
          onClick={handleOutgoingClick}
        >
          Outgoing
        </button>
      </div>
      <div className={styles.sections}>
        {isIncoming ? (
          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>PO No.</th>
                  <th>Arrival Date</th>
                  <th>Dispatching Party</th>
                  <th>BOE</th>
                  <th>Incoming Delivery ID</th>
                  <th>Incoming Inspection ID</th>
                  <th>Qty Received</th>
                  <th>Shortfall/Surplus in Qty Received</th>
                  <th>Qty Rejected</th>
                  <th>UoM</th>
                  <th>Traceability Number</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {itemsReceived.map((item, index) => (
                  <tr key={item.S_No}>
                    <td>{index + 1}</td>
                    <td>
                      {/* Access the corresponding Sales_Order_No from finalSalesOrderNo object */}
                      {salesOrderNos[item.Purchase_ID]
                        ? salesOrderNos[item.Purchase_ID]
                        : ""}
                    </td>

                    <td>
                      {expectedArrivalDates[item.Incoming_Delivery_ID]
                        ? expectedArrivalDates[item.Incoming_Delivery_ID]
                        : ""}
                    </td>
                    <td>
                      {dispatchingParties[item.Incoming_Delivery_ID]
                        ? dispatchingParties[item.Incoming_Delivery_ID]
                        : ""}
                    </td>
                    <td>
                      {boe[item.Incoming_Delivery_ID]
                        ? boe[item.Incoming_Delivery_ID]
                        : ""}
                    </td>

                    <td>{item.Incoming_Delivery_ID}</td>
                    <td>{item.Incoming_Inspection_ID}</td>
                    <td>
                      {item.Scheduled_Quantity + parseInt(qtyDelta[index])}
                    </td>

                    <td>{qtyDelta[index]}</td>
                    <td>{qtyRejected[index]}</td>
                    <td>{item.UoM}</td>
                    <td>{traceabilityNumber[index]}</td>
                    <td>{item.Location}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>SO No.</th>
                  <th>Utilization Date</th>
                  <th>UoM</th>
                  <th>Qty Utilized</th>
                  <th>BOE</th>
                  <th>Traceability Number</th>
                </tr>
              </thead>
              <tbody>
                {itemsReceived2.length === 0 ? (
                  <tr>
                    <td colSpan="7">No result found</td>
                  </tr>
                ) : (
                  itemsReceived2.map((item, index) => (
                    <tr key={item.S_No}>
                      <td>{index + 1}</td>
                      <td>{item.Project_SO}</td>
                      <td>
                        {(() => {
                          // Check if Date_Of_Utilization is not null or undefined
                          if (item.Date_Of_Utilization) {
                            // Parse the date string
                            const date = new Date(item.Date_Of_Utilization);
                            // Extract day, month, and year components
                            const day = String(date.getDate()).padStart(2, "0");
                            const month = String(date.getMonth() + 1).padStart(
                              2,
                              "0"
                            ); // Months are zero-based
                            const year = date.getFullYear();
                            // Return formatted date string
                            return `${day}-${month}-${year}`;
                          }
                          // Return empty string if Date_Of_Utilization is null or undefined
                          return "";
                        })()}
                      </td>
                      <td>{item.UoM}</td>
                      <td>{item.Qty_Utilized}</td>
                      <td>
                      {boe[item.Incoming_Delivery_ID]
                        ? boe[item.Incoming_Delivery_ID]
                        : ""}
                    </td>
                    <td>{item.Traceability_Number}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
export default ErgonInventoryLogsDetails;
