// import React, { useState, useEffect } from 'react';
// import { useNavigate  } from "react-router-dom";

// export default function Signout() {
//   const [isLoggedOut, setIsLoggedOut] = useState(false);
//   const navigate = useNavigate();
//   useEffect(() => {
//     setIsLoggedOut(sessionStorage.removeItem("isLoggedIn"));
//     navigate("/");
//   }, [isLoggedOut]);

//   return (
//     <div>Signout</div>
//   )
// }
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


export default function Signout() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    navigate('/', { replace: true }); // Navigate to the home page
    window.location.reload(); // Refresh the page
  }, [navigate]);

  return <div>Signout</div>;
}
