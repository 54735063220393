import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/supplierdetailscreatenew.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import { Rating } from "@mui/material";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

function SupplierDetailsCreateNew() {
    const navigate = useNavigate();
    const [UploadFile,setUploadFile] = useState(null);
    const [formUpload, setFormUpload] = useState({evalutionForm: null, ISOForm: null})

    const [formdata1,setFormData1] = useState({
        SupplierName: "",
        RegDate: "",
        Scope: "",
        DeliveryTime: "",
        Rating: "",
        Category: "",
        TaxRegNo: "",
        Address: "",
        POC: "",
        Phone: "",
        Email: "",
        Website: "",
        Currency: "",
        PaymentTerms: "",
        AccountName: "",
        AccountNo: "",
        BankCode: "",
        BranchCode: "",
        SwiftCode: "",
        BankAddress: "",
        DateOfEval: "",
        DateOfReEval: "",
        ISOExpiry: "",
    })

    

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormData1({ ...formdata1, [name]: value });
    };

    const handleFileChange = (e) => {
        setUploadFile(e.target.files[0]); // Store selected file in state
    };

    const handleDiscard = () => {
        navigate("/supplierdetails")
    }

    const handleDates = (name, value) => {
        if (value !== null && value !== undefined) {
          const selectedDate = value.format("YYYY-MM-DD");
          setFormData1({ ...formdata1, [name]: selectedDate });
          console.log(name, selectedDate);
        } else {
          console.log("Invalid Date");
        }
      };

    const handleAdd = async (e) => {
        e.preventDefault();

        // Validation for SupplierName
  if (!formdata1.SupplierName.trim()) {
    alert("Supplier Name is required and cannot be empty.");
    return; // Stop form submission if SupplierName is empty
  }

        const formData = new FormData();
        formData.append("UploadFile", UploadFile); // Add file to FormData
    Object.keys(formdata1).forEach((key) => {
      formData.append(key, formdata1[key]); // Add all form data to FormData
    });

    formData.append("evalutionForm", formUpload.evalutionForm)
    formData.append("ISOForm", formUpload.ISOForm)

    console.log("formdata", formData);
    console.log("formdata1", formdata1)
        try {
            const res = await axios.post(
              "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supplier_details_post_with_file",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data", // Set the content type
                },
              }
            );
            console.log("res",res)
            if (res.status === 201) {
              alert("Supplier Added successfully");
              navigate(`/supplierdetails`);
            }
          } catch (error) {
            console.error(error);
          }
    };

// mui styling for date picker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "280px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));

  return (
    <div className={styles.createContainer}>
      <div className={styles.topContainer}>
        <div className={styles.top1}>
          <h3 className={styles.createHeading}>Create New Supplier</h3>
        </div>

        <div className={styles.top2}>
          <button className={styles.addButton} onClick={handleAdd}>Add Supplier</button>
          <button className={styles.discardButton} onClick={handleDiscard}>
            Discard
          </button>
        </div>
      </div>

      <div className={styles.supplierContainer}>
        

        <form>
            <div className={styles.heading1}>
                <h5>1. Supplier Registration and Contact</h5>
            </div>

          <div className={styles.container1}>
            <div className={styles.leftContainer}>
              <div className={styles.formElement}>
                <label>Supplier Name*</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                      value={formdata1.SupplierName}
                    name="SupplierName"
                      onChange={handleOnChange}
                      required
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Registration Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="RegDate"
                    value={
                        formdata1.RegDate
                        ? dayjs(formdata1.RegDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("RegDate", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.formElement}>
                <label>Approved Supplier Scope</label>
                <textarea
                  type="text"
                  value={formdata1.Scope}
                  name="Scope"
                  onChange={handleOnChange}
                  className={styles.textbox1b}
                />
              </div>

              <div className={styles.formElement}>
                <label>Standard Delivery Time</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                      value={formdata1.DeliveryTime}
                    name="DeliveryTime"
                      onChange={handleOnChange}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Rating</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                      value={formdata1.Rating.toUpperCase()}
                    name="Rating"
                    onChange={handleOnChange}
                  />
                </div>
              </div>

              <div className={styles.formElement}>
                <label>Category</label>
                <select
                  value={formdata1.Category}
                  name="Category"
                onChange={handleOnChange}
                  required
                >
                  <option value="">Select value</option>
                  <option value="Critical">Critical</option>
                  <option value="Non-Critical">Non-Critical</option>
                </select>
                <svg
                  style={{ position: "absolute", right: "15" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>

            <div className={styles.rightContainer}>
                <div className={styles.formElement}>
                    <label>Tax Registration No</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.TaxRegNo}
                        name="TaxRegNo"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Address</label>
                    <textarea
                        type="text"
                        value={formdata1.Address}
                        name="Address"
                        onChange={handleOnChange}
                        className={styles.textbox1b}
                    />
                </div>

                <div className={styles.formElement}>
                    <label>Supplier POC</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.POC}
                        name="POC"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Phone</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="number"
                          value={formdata1.Phone}
                        name="Phone"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Email</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.Email}
                        name="Email"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Website</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.Website}
                        name="Website"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

            </div>

          </div>

          <div className={styles.heading1}>
            <h5>2. Bank Account Details</h5>
          </div>

          <div className={styles.container1}>
            <div className={styles.leftContainer}>
                <div className={styles.formElement}>
                    <label>Payment Currency</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.Currency}
                        name="Currency"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Payment Terms</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.PaymentTerms}
                        name="PaymentTerms"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Bank Account Name</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.AccountName}
                        name="AccountName"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Account Number</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.AccountNo}
                        name="AccountNo"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>


            </div>

            <div className={styles.rightContainer}>

                <div className={styles.formElement}>
                <label>Bank Code</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                            type="text"
                              value={formdata1.BankCode}
                            name="BankCode"
                            onChange={handleOnChange}
                        />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Branch Code</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.BranchCode}
                        name="BranchCode"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Swift Code</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                          value={formdata1.SwiftCode}
                        name="SwiftCode"
                        onChange={handleOnChange}
                    />
                    </div>
                </div>

                <div className={styles.formElement}>
                    <label>Bank Address</label>
                    <textarea
                        type="text"
                        value={formdata1.BankAddress}
                        name="BankAddress"
                        onChange={handleOnChange}
                        className={styles.textbox1b}
                    />
                </div>


            </div>
          </div>

          <div className={styles.heading1}>
            <h5>3. Certification and Evaluation</h5>
          </div>

          <div className={styles.container1}>
            <div className={styles.leftContainer}>

                <div className={styles.formElement}>
                    <label>Date of Evaluation</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                        name="DateOfEval"
                        value={
                            formdata1.DateOfEval
                            ? dayjs(formdata1.DateOfEval, "YYYY-MM-DD")
                            : null
                        }
                        onChange={(value) => handleDates("DateOfEval", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        }}
                    />
                    </LocalizationProvider>
                </div>

                <div className={styles.formElement}>
                    <label>Due Date of Re-Evaluation</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                        name="DateOfReEval"
                        value={
                            formdata1.DateOfReEval
                            ? dayjs(formdata1.DateOfReEval, "YYYY-MM-DD")
                            : null
                        }
                        onChange={(value) => handleDates("DateOfReEval", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        }}
                    />
                    </LocalizationProvider>
                </div>
                <div className={styles.formElement}>
                <label>ISO certificate</label>
                <input
                  type="file"
                  className={styles.fileUpload}
                  onChange={(e)=>setFormUpload({...formUpload, ISOForm: e.target.files[0]})}
                  name="ISOForm"
                />
                <svg
                  width="28"
                  height="25"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: "absolute", // Use absolute positioning
                    left: "460px", // Adjust position based on your layout
                    top: "50%", // Vertically center the SVG
                    transform: "translateY(-50%)", // Correct vertical alignment
                    pointerEvents: "none",
                  }}
                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>

              </div>

            </div>

            <div className={styles.rightContainer}>

            <div className={styles.formElement}>
                    <label>ISO Certificate Expiration Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                        name="ISOExpiry"
                        value={
                            formdata1.ISOExpiry
                            ? dayjs(formdata1.ISOExpiry, "YYYY-MM-DD")
                            : null
                        }
                        onChange={(value) => handleDates("ISOExpiry", value)}
                        slotProps={{ textField: { size: "small" } }}
                        format="DD/MM/YYYY"
                        sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        }}
                    />
                    </LocalizationProvider>
            </div>

            <div className={styles.formElement}>
                <label>Registration form(optional)</label>
                <input
                  type="file"
                  className={styles.fileUpload}
                  onChange={handleFileChange}
                  name="UploadFile"
                />
                <svg
                            width="28"
                            height="25"
                            viewBox="0 0 28 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              position: "absolute", // Use absolute positioning
                              left: "460px", // Adjust position based on your layout
                              top: "50%", // Vertically center the SVG
                              transform: "translateY(-50%)", // Correct vertical alignment
                              pointerEvents: "none",
                            }}
                          >
                            <path
                              d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                              fill="black"
                            />
                            <path
                              d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                              fill="black"
                            />
                </svg>
              </div>
              <div className={styles.formElement}>
                <label>Evaluation form</label>
                <input
                  type="file"
                  className={styles.fileUpload}
                  onChange={(e)=>setFormUpload({...formUpload, evalutionForm: e.target.files[0]})}
                  name="evalutionForm"
                />
                <svg
                  width="28"
                  height="25"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: "absolute", // Use absolute positioning
                    left: "460px", // Adjust position based on your layout
                    top: "50%", // Vertically center the SVG
                    transform: "translateY(-50%)", // Correct vertical alignment
                    pointerEvents: "none",
                  }}
                >
                  <path
                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                    fill="black"
                  />
                  <path
                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                    fill="black"
                  />
                </svg>

              </div>

            </div>
          </div>


        </form>
      </div>
    </div>
  );
}

export default SupplierDetailsCreateNew