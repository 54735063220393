import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/dailytrackerpurchases.module.css";
import TabsBarDailyTracker from "./TabsBarDailyTracker.js";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

function DailyTrackerPurchases() {
  const [activeStatus, setActiveStatus] = useState("Ongoing");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);


  console.log("activeStatus", activeStatus);
  console.log("searchTerm", searchTerm);


  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_status_tracker");
        setPurchaseData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Filter data based on activeStatus and searchTerm
    const filtered = purchaseData.filter(item => {
      const mappedStatus =
        item.status === "Pending" ? "Ongoing" :
          item.status === "Completed" ? "Closed" :
            item.status; // Leave status as is if it doesn’t match the mappings

      return (
        mappedStatus === activeStatus &&
        (
          item.PO_Number?.toLowerCase().trim()?.includes(searchTerm.toLowerCase()) ||
          item.Linked_Project?.toLowerCase().trim()?.includes(searchTerm.toLowerCase())
        )
      );
    });
    setFilteredData(filtered);
  }, [activeStatus, searchTerm, purchaseData]);

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <div className={styles.listContainer}>
      <TabsBarDailyTracker
        activeTab="Purchases"
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        setSearchTerm={setSearchTerm}
      />
      <div className={styles.listingContainer}>
        <table className={styles.listTable}>
          <thead>
            <tr>
              <th style={{ minWidth: "60px", width: "60px" }}>S. No</th>
              <th>PO No.</th>
              <th>Quote No.</th>
              <th>Supplier Name</th>
              <th style={{ minWidth: "150px" }}>PO Date</th>
              <th style={{ minWidth: "150px" }}>Earliest Arrival Date</th>
              <th style={{ minWidth: "150px" }}>Last Arrival Date</th>
              {activeStatus !== "Closed" && (
                <th>Items pending receipt</th>
              )}
              <th>Linked to Spares SO</th>
              <th>Reason for Purchase</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item.ID_No}>
                <td>{index + 1}</td>
                <td>
                  <a href={`http://jupiter.ergontec.com/purchase_details/${item.Purchase_ID}`} target="_blank">
                    {item.PO_Number || "-"}
                  </a>
                </td>
                <td>{item.Quote_No || "-"}</td>
                <td>{item.Supplier_Name || "-"}</td>
                <td>{item.PO_Date ? formatDate(item.PO_Date) : "-"}</td>
                <td>{item.Earliest_Arrival_Date ? formatDate(item.Earliest_Arrival_Date) : "-"}</td>
                <td>{item.Expected_Arrival_Date ? formatDate(item.Expected_Arrival_Date) : "-"}</td>
                {activeStatus !== "Closed" && (
                  <td style={{ backgroundColor: "#e2e6ff" }}>{`${item.pendingItems}/${item.totalItems}`}</td>
                )}
                <td>{item.Linked_Project || "-"}</td>
                <td>{item.Reason_For_Purchase || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DailyTrackerPurchases;
