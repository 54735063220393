import React from "react";
import styles from "./css/Incoming_Inspections.module.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import * as XLSX from 'xlsx';
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Incoming_Inspections() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when tick button is clicked, also to disable the tick button to prevent multiple clicks
  const [incomingdetails, setincomingdetails] = useState([]);
  const [workshopdetails, setworkshopdetails] = useState([]);
  const [indetails, setindetails] = useState([]);
  const [openmodal, setopenmodal] = useState(false);
  const [tempPicture, setTempPicture] = useState("");
  const [documentToUpload, setDocumentToUpload] = useState(null);
  const [isselected, setisselected] = useState(false);
  const [itemdata, setitemdata] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [partNoSuggestions, setPartNoSuggestions] = useState([]);
  const [featureInspectionedit, setfeatureInspectionedit] = useState([]);
  const [featureInspectioneadd, setfeatureInspectionadd] = useState([]);
  const [featureInspectionremove, setfeatureInspectionremove] = useState([]);
  const [editrow, seteditrow] = useState(-1);
  const [editdetails, seteditdetails] = useState("");
  const [newrows, setnewrows] = useState("");
  const [workshopid, setworkshopid] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [removeindex, setremoveindex] = useState(-1);
  const navigate = useNavigate();
  const handlenewrow = () => {


    setisselected(false);
    const newrowdata = {
      description: "",
      part_no: "",
      trace_no: "",
      UOM: "",
      quantity: "",
      Picture: "",
      Condition: "",
      Remarks: "",
      Receipt_Date: "",
      manufaturer_no:""
    };
    setnewrows([...newrows, newrowdata]);
  };





  useEffect(() => {
    async function getDetails() {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incominginspections_details?project_id=${id}`
        );

        const response2 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_details2?project_id=${id}`
        );

        const res1 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshopprojectdetails2?id_No=${response2.data.data[0].ID_No}`
        );

        setincomingdetails(response2.data.data);//delivery details

        setworkshopdetails(res1.data.data);//workshop details
        setworkshopid(res1.data.data[0].ID_No);
        setindetails(response.data.data);//inspection details
      } catch (error) {
        console.log(error);
      }
    }
    getDetails();
  }, [refresh]);

  useEffect(() => {
    async function getDetails() {
      try {


        const resi = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster`
        );
        setitemdata(resi.data);
      } catch (error) {
        console.log(error);
      }
    }
    getDetails();
    //setrefresh(!refresh)
  }, [refresh]);

  const checkEntryCompleteness = (desired_delivery_id) => {

    return indetails.some(entry =>
      entry.incoming_Delivery_id === desired_delivery_id &&
      entry.s_no !== null &&
      entry.Project_Id !== null &&
      entry.Item_Name !== null &&
      // entry.Part_Number !== null &&
      // entry.Traceability_number !== null &&
      // entry.UOM !== null &&
      entry.Quantity !== null
      // entry.Picture !== null &&
      // entry.Condition_Upon_Reciept !== null &&
      // entry.Remarks !== null
    );
  };

  const handleremove = () => {
    document.getElementById("lnii").innerHTML = "";
    document.getElementById("lnii").style.visibility = "hidden";
    //document.getElementById("suggestionslist").style.border = '1px solid #e7ebff';
    document.getElementById("suggestionslist").style.visibility = 'hidden';
    document.getElementById("suggestionslist").style.border = 'none';
    document.getElementById("suggestionslist").style.height = '0px';

    setSuggestions([]);
    setPartNoSuggestions([]);
    setnewrows("");
  };

  //want to work here
  const handleSuggestionSelecte = (sValue, pValue) => {


    document.getElementById("eii").value = sValue;
    document.getElementById("eip").value = pValue;
    //setselectedvalue(sValue);
    seteditdetails({ ...editdetails, ["part_no"]: pValue, ["description"]: sValue });
    setSuggestions([]);
    document.getElementById("leii").innerHTML = " ";

    setisselected(false);
    document.getElementById("leii").style.visibility = "hidden";
    document.getElementById("suggestionslist").style.border = 'none';

    setPartNoSuggestions([]);
    document.getElementById("leip").innerHTML = " ";
    document.getElementById("leip").style.visibility = "hidden";
    document.getElementById("suggestionslistPartNo").style.border = 'none';
  };
  const handleSuggestionSelectn = (sValue, pValue) => {

    document.getElementById("nii").value = sValue;
    //setselectedvalue(sValue);

    document.getElementById("nip").value = pValue;
    //setselectedvalue(sValue);
    setnewrows({ ...newrows, ["part_no"]: pValue, ["description"]: sValue });
    setSuggestions([]);
    document.getElementById("lnii").innerHTML = " ";

    setisselected(false);
    document.getElementById("lnii").style.visibility = "hidden";
    document.getElementById("suggestionslist").style.border = 'none';

    setPartNoSuggestions([]);
    document.getElementById("lnip").innerHTML = " ";
    document.getElementById("lnip").style.visibility = "hidden";
    document.getElementById("suggestionslistPartNo").style.border = 'none';
  };
  const handlenewchangefile = (e) => {
    // const { name = e.target;
    const { name } = e.target;
    const value = e.target.files[0];
    setnewrows({ ...newrows, [name]: value });
  };
  const handlenewchange = (e) => {

    var { name, value } = e.target;



    if (name === "description") {
      document.getElementById("lnii").style.visibility = "visible";
      document.getElementById("suggestionslist").style.border = "1px solid #e7ebff";
      document.querySelectorAll("#suggestionslist li").forEach(li => li.style.border = "1px solid #e7ebff");
      document.getElementById("suggestionslist").style.visibility = 'visible';
      document.getElementById("suggestionslist").style.height = 'auto';
      document.getElementById("suggestionslist").style.width = 'auto';

      //document
      document.getElementById("suggestionslist").style.fontSize = '14x';
      document.getElementById("suggestionslist").style.borderRadius = "8px";
      document.getElementById("suggestionslist").style.padding = "0";
      const filteredSuggestions = itemdata.filter(item =>
        item.Item_Name?.toLowerCase().includes(value?.toLowerCase())
      );


      if (value.trim() === '') {
        // document.getElementById("lnii").innerHTML="";
        document.getElementById("lnii").style.visibility = "hidden";
        //document.getElementById("suggestionslist").style.border = '1px solid #e7ebff';
        document.getElementById("suggestionslist").style.visibility = 'hidden';
        document.getElementById("suggestionslist").style.border = 'none';
        document.getElementById("suggestionslist").style.height = '0px';
        setSuggestions([]);
      } else {

        //Disabled the "Add item to Items Master List" feature
        if ((!filteredSuggestions.length || (filteredSuggestions[0].Item_Name)?.toLowerCase() !== value?.toLowerCase()) && (value !== '') && false) {
          document.getElementById("lnii").innerHTML = `+ Add "${value}" to item master`;
          document.getElementById("lnii").addEventListener("click", function () {

            document.getElementById("lnii").innerHTML = " ";
            document.getElementById("lnii").style.visibility = "hidden";
            document.getElementById("suggestionslist").style.border = 'none';
            document.getElementById("suggestionslist").style.visibility = "hidden";
            document.getElementById("nip").value = "";
            setSuggestions([]);
            setisselected(true);//in post request set to false
          });
        }
        else if (((filteredSuggestions[0]?.Item_Name)?.toLowerCase() === value?.toLowerCase())) {
          document.getElementById("lnii").innerHTML = " ";
          document.getElementById("lnii").style.visibility = "hidden";
          document.getElementById("suggestionslist").style.border = "1px solid #e7ebff";
          document.querySelectorAll("#suggestionslist li").forEach(li => li.style.border = "1px solid #e7ebff");
        }
      }

      setSuggestions(filteredSuggestions);



      setnewrows({ ...newrows, [name]: value });
    } else if (name === "part_no") {
      // Part Number suggestions logic
      const listEl = document.getElementById("suggestionslistPartNo");
      const addItemEl = document.getElementById("lnip");
      if (listEl && addItemEl) {
        addItemEl.style.visibility = "visible";
        listEl.style.border = "1px solid #e7ebff";
        listEl.querySelectorAll("li").forEach(li => li.style.border = "1px solid #e7ebff");
        listEl.style.visibility = 'visible';
        listEl.style.height = 'auto';
        listEl.style.width = 'auto';
        listEl.style.fontSize = '14px';
        listEl.style.borderRadius = "8px";
        listEl.style.padding = "0";
      }

      // Filter suggestions by Part_Number
      const filteredSuggestions = itemdata.filter(item =>
        item?.Part_Number?.toLowerCase().includes(value?.toLowerCase())
      );

      if (value.trim() === '') {
        // Hide suggestions if input is empty
        if (addItemEl && listEl) {
          addItemEl.innerHTML = "";
          addItemEl.style.visibility = "hidden";
          listEl.style.visibility = 'hidden';
          listEl.style.border = 'none';
          listEl.style.height = '0px';
        }
        setPartNoSuggestions([]);
      } else {
        if ((!filteredSuggestions.length || (filteredSuggestions[0]?.Part_Number)?.toLowerCase() !== value?.toLowerCase()) && (value !== '') && false) {
          document.getElementById("lnip").innerHTML = `+ Add "${value}" to item master`;
          document.getElementById("lnip").addEventListener("click", function () {

            document.getElementById("lnip").innerHTML = " ";
            document.getElementById("lnip").style.visibility = "hidden";
            document.getElementById("suggestionslistPartNo").style.border = 'none';
            document.getElementById("suggestionslistPartNo").style.visibility = "hidden";
            document.getElementById("nip").value = "";
            setPartNoSuggestions([]);
            setisselected(true);//in post request set to false
          });
        }
        else if (((filteredSuggestions[0]?.Part_Number)?.toLowerCase() === value?.toLowerCase())) {
          document.getElementById("lnip").innerHTML = " ";
          document.getElementById("lnip").style.visibility = "hidden";
          document.getElementById("suggestionslistPartNo").style.border = "1px solid #e7ebff";
          document.querySelectorAll("#suggestionslistPartNo li").forEach(li => li.style.border = "1px solid #e7ebff");
        }
      }
      setPartNoSuggestions(filteredSuggestions);

      // Update local edit details
      seteditdetails({ ...editdetails, [name]: value });
    } else {
      setnewrows({ ...newrows, [name]: value });
    }

  };

  const handlenewchange2 = (e, index) => {

    var { name, value } = e.target;



    if (name === "description") {
      document.getElementById("leii").style.visibility = "visible";
      document.getElementById("suggestionslist").style.border = "1px solid #e7ebff";
      document.querySelectorAll("#suggestionslist li").forEach(li => li.style.border = "1px solid #e7ebff");
      document.getElementById("suggestionslist").style.visibility = 'visible';
      document.getElementById("suggestionslist").style.height = 'auto';
      document.getElementById("suggestionslist").style.width = 'auto';

      //document
      document.getElementById("suggestionslist").style.fontSize = '14x';
      document.getElementById("suggestionslist").style.borderRadius = "8px";
      document.getElementById("suggestionslist").style.padding = "0";
      // setisselected(false);
      const filteredSuggestions = itemdata.filter(item =>
        item.Item_Name?.toLowerCase().includes(value?.toLowerCase())
      );
      //setisselected(false);
      if (value.trim() === '') {
        document.getElementById("leii").innerHTML = "";
        document.getElementById("leii").style.visibility = "hidden";
        //document.getElementById("suggestionslist").style.border = '1px solid #e7ebff';
        document.getElementById("suggestionslist").style.visibility = 'hidden';
        document.getElementById("suggestionslist").style.border = 'none';
        document.getElementById("suggestionslist").style.height = '0px';
        setSuggestions([]);


      } else {

        //Disabled the "Add item to Items Master List" feature
        if ((!filteredSuggestions.length || (filteredSuggestions[0]?.Item_Name)?.toLowerCase() !== value?.toLowerCase() && (value !== '')) && false) {
          document.getElementById("leii").innerHTML = `+ Add "${value}" to Items Master list`;
          //document.querySelectorAll("#suggestionslist li").forEach(li => li.style.border = "1px solid #e7ebff");
          document.getElementById("leii").addEventListener("click", function () {
            //need to add logic for db entry
            document.getElementById("leii").innerHTML = " ";
            document.getElementById("leii").style.visibility = "hidden";
            document.getElementById("suggestionslist").style.border = 'none';
            document.getElementById("suggestionslist").style.visibility = "hidden";
            document.getElementById("eip").value = "";
            setSuggestions([]);
            setisselected(true);//in post request set to false
          });
        } else if (((filteredSuggestions[0]?.Item_Name)?.toLowerCase() === value?.toLowerCase())) {
          document.getElementById("leii").innerHTML = " ";
          document.getElementById("leii").style.visibility = "hidden";
          document.getElementById("suggestionslist").style.border = "1px solid #e7ebff";
          document.querySelectorAll("#suggestionslist li").forEach(li => li.style.border = "1px solid #e7ebff");

        }
      }

      setSuggestions(filteredSuggestions);


      // const updatedData = [...newrowdata];
      // updatedData[0] = {
      //  ...updatedData[0],
      //  [name]: value,
      // }
      seteditdetails({ ...editdetails, [name]: value });
    } else if (name === "part_no") {
      // Part Number suggestions logic
      const listEl = document.getElementById("suggestionslistPartNo");
      const addItemEl = document.getElementById("leip");
      if (listEl && addItemEl) {
        addItemEl.style.visibility = "visible";
        listEl.style.border = "1px solid #e7ebff";
        listEl.querySelectorAll("li").forEach(li => li.style.border = "1px solid #e7ebff");
        listEl.style.visibility = 'visible';
        listEl.style.height = 'auto';
        listEl.style.width = 'auto';
        listEl.style.fontSize = '14px';
        listEl.style.borderRadius = "8px";
        listEl.style.padding = "0";
      }

      // Filter suggestions by Part_Number
      const filteredSuggestions = itemdata.filter(item =>
        item?.Part_Number?.toLowerCase().includes(value?.toLowerCase())
      );

      if (value.trim() === '') {
        // Hide suggestions if input is empty
        if (addItemEl && listEl) {
          addItemEl.innerHTML = "";
          addItemEl.style.visibility = "hidden";
          listEl.style.visibility = 'hidden';
          listEl.style.border = 'none';
          listEl.style.height = '0px';
        }
        setPartNoSuggestions([]);
      } else {
        if ((!filteredSuggestions.length || (filteredSuggestions[0]?.Part_Number)?.toLowerCase() !== value?.toLowerCase()) && (value !== '') && false) {
          document.getElementById("leip").innerHTML = `+ Add "${value}" to item master`;
          document.getElementById("leip").addEventListener("click", function () {

            document.getElementById("leip").innerHTML = " ";
            document.getElementById("leip").style.visibility = "hidden";
            document.getElementById("suggestionslistPartNo").style.border = 'none';
            document.getElementById("suggestionslistPartNo").style.visibility = "hidden";
            document.getElementById("nip").value = "";
            setPartNoSuggestions([]);
            setisselected(true);//in post request set to false
          });
        }
        else if (((filteredSuggestions[0]?.Part_Number)?.toLowerCase() === value?.toLowerCase())) {
          document.getElementById("leip").innerHTML = " ";
          document.getElementById("leip").style.visibility = "hidden";
          document.getElementById("suggestionslistPartNo").style.border = "1px solid #e7ebff";
          document.querySelectorAll("#suggestionslistPartNo li").forEach(li => li.style.border = "1px solid #e7ebff");
        }
      }
      setPartNoSuggestions(filteredSuggestions);

      // Update local edit details
      seteditdetails({ ...editdetails, [name]: value });
    } else {
      //       const updatedData = [...newrowdata];
      //       updatedData[0] = {
      //       ...updatedData[0],
      //       [name]: value,
      //  }

      seteditdetails({ ...editdetails, [name]: value });
    }
    //  setNewRowData(updatedData);
    //  console.log(updatedData);
  };
  const handlenewchange2file = async (name, event) => {
    if (event) {
      event.preventDefault();
      const temp = event.target.files[0];

      // Extracting file name from temp and setting it to tempPicture
      const fileName = temp ? temp.name : "";
      setDocumentToUpload(temp);
      setTempPicture(fileName);
      seteditdetails({ ...editdetails, Picture: temp });
    }
  };


  const handleviewfile = () => {
    if (tempPicture) {
      return (
        <NavLink to={tempPicture} target="_blank" rel="noopener noreferrer">
          {tempPicture}
        </NavLink>
      );
    } else {
      return null; // or some fallback content
    }
  };

  const handledownloadfile = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = "Inspections-Picture";
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };

  const handlepost = async (e) => {
    setIsLoading(true);
    document.getElementById("lnii").innerHTML = "";
    document.getElementById("lnii").style.visibility = "hidden";
    //document.getElementById("suggestionslist").style.border = '1px solid #e7ebff';
    document.getElementById("suggestionslist").style.visibility = 'hidden';
    document.getElementById("suggestionslist").style.border = 'none';
    document.getElementById("suggestionslist").style.height = '0px';
    setSuggestions([]);
    setPartNoSuggestions([]);

    e.preventDefault();

    let { description, part_no, trace_no, UOM, quantity, Picture, Condition, Remarks, manufaturer_no } = newrows;
    if (!description) {
      alert("Please Enter A Valid Item Name");
      setIsLoading(false);
      return;
    }
    if (!part_no) {
      // alert("Please Enter A Valid Part Number");
      // return;
      part_no = null;
    }
    if (!description && !part_no && !trace_no && !UOM && !quantity && !Picture && !Condition && !Remarks) {
      alert("Invalid Entry");
      setIsLoading(false);
      return;
    }
    // console.log("hello");



    const payload = {
      Project_Id: workshopid,
      Item_Name: description,
      Part_Number: part_no,
      Traceability_Number: trace_no,
      UOM: UOM,
      Quantity: quantity,
      incoming_Delivery_id: id,
      // Picture: Picture,
      Incoming_Inspection_ID: incomingdetails[0].Incoming_Inspection_Id,
      Condition_Upon_Reciept: Condition,
      Remarks: Remarks,
      manufaturer_no: manufaturer_no
    };

    const res = await axios.post(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incominginspections",
      payload
    );

    const s_no = res.data.data;

    const payload2 = {
      Item_Name: description,
      Part_Number: part_no,
      Traceability_Number: trace_no,
      UOM: UOM,
      Quantity_Received: quantity,
      Arrival_Date: incomingdetails[0].Expected_Arrival_Date,
      Incoming_Inspection_ID: incomingdetails[0].Incoming_Inspection_Id,
      Incoming_Inspection_SNo: s_no, //added s_no to the payload as well for unique identification during put and delete operations
      Incoming_Delivery_Id: incomingdetails[0].S_No,
      Project_Id: incomingdetails[0].ID_No,
      // Picture: Picture,
      //Remarks: Remarks,
      manufaturer_no: manufaturer_no

    };

    const res2 = await axios.post(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_post",
      payload2
    );

    const isNotPresent = itemdata.every(item => item.Item_Name !== description);

    if (isNotPresent) {


      // Make a POST request to the backend
      const payload = {
        Item_Name: description,
        Part_Number: part_no,
      };

      // try {
      //   const res = await axios.post(
      //     "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemmaster",
      //     payload
      //   );
      // } catch (error) {
      //   console.error("Error occurred while making POST request:", error);
      // }
    } else {
      console.log(`Item with name ${description} is already present.`);
    }

    const formData = new FormData();
    formData.append("Picture", Picture);

    // const formData = {
    //   Picture: Picture
    // }

    const res1 = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incominginspectionsfiles?s_no=${res.data.data}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    setnewrows("");
    setIsLoading(false);
    setrefresh(!refresh)
  };

  const handleno = () => {
    setopenmodal(false);
    setrefresh(!refresh);
    setremoveindex();
  }
  const getBackgroundColor = (el) => {
    const isEntryComplete = checkEntryCompleteness(el.S_No);
    return isEntryComplete ? '#077D7D' : '#900909';
  };

  const getTextColor = (el) => {
    return '#FFFFFF';
  };

  const getButtonText = (el) => {
    const isEntryComplete = checkEntryCompleteness(el.S_No);
    return isEntryComplete ? 'Inspection Status: Completed' : 'Inspection Status: Pending';
  };

  const handleremove3 = async () => {

    const num = indetails[removeindex].s_no;
    const incoming_inspection_id = incomingdetails[0].Incoming_Inspection_Id;
    const res1 = await axios.delete(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_delete?s_no=${num}&incoming_inspection_id=${incoming_inspection_id}`);
    const res = await axios.delete(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incominginspections_delete?SNO=${num}`);
    indetails.length = 0;
    setrefresh(!refresh);
    setopenmodal(false);
    setremoveindex();
  }

  const handleedit = (index) => {


    setisselected(false);
    setSuggestions([]);
    setPartNoSuggestions([]);
    if (newrows !== "") {
      return;
    }
    seteditdetails({
      description: indetails[index].Item_Name,
      part_no: indetails[index].Part_Number,
      trace_no: indetails[index].Traceability_Number,
      UOM: indetails[index].UOM,
      quantity: indetails[index].Quantity,
      sno: indetails[index].s_no,
      Picture: indetails[index].tempPicture,
      Condition: indetails[index].Condition_Upon_Reciept,
      Remarks: indetails[index].Remarks,
      manufaturer_no: indetails[index].manufaturer_no

    })

    if (indetails[index].Picture) {
      setTempPicture(String(indetails[index].Picture).split("/")
        .pop()
        .split("-")
        .slice(1)
        .join("-"));
    } else {
      setTempPicture("No File Uploaded");
    }

    seteditrow(index);
  }

  const handleremove2 = () => {
    document.getElementById("leii").innerHTML = "";
    document.getElementById("leii").style.visibility = "hidden";
    //document.getElementById("suggestionslist").style.border = '1px solid #e7ebff';
    document.getElementById("suggestionslist").style.visibility = 'hidden';
    document.getElementById("suggestionslist").style.border = 'none';
    document.getElementById("suggestionslist").style.height = '0px';
    setSuggestions([]);
    setPartNoSuggestions([]);

    seteditrow(-1);
    seteditdetails("");
    indetails.length = 0;
    setrefresh(!refresh)
  }
  const handleBackClick = () => {
    navigate(`/universal_inspections`); // Navigate to the previous page
  };
  const handleDeliveryClick = () => { //need to define path of incomedelivery
    navigate(`/incomingdeliveriesupdate/${workshopdetails[0].ID_No}/${incomingdetails[0].S_No}`); // Navigate to the delivery page
  };

  const handleput = async (e) => {
    document.getElementById("leii").innerHTML = "";
    document.getElementById("leii").style.visibility = "hidden";
    //document.getElementById("suggestionslist").style.border = '1px solid #e7ebff';
    document.getElementById("suggestionslist").style.visibility = 'hidden';
    document.getElementById("suggestionslist").style.border = 'none';
    document.getElementById("suggestionslist").style.height = '0px';
    setSuggestions([]);
    setPartNoSuggestions([]);
    const { description, part_no, trace_no, UOM, quantity, sno, Picture, Condition, Remarks, manufaturer_no } = editdetails;
    if (!description) {
      alert("Invalid Item Name");
      return;
    }
    if (!description && !part_no && !trace_no && !UOM && !quantity && !Picture && !Condition && !Remarks) {
      alert("Invalid Entry");
      return;
    }
    const payload2 = {
      Item_Name: description,
      Part_Number: part_no,
      Traceability_Number: trace_no,
      UOM: UOM,
      Quantity_Received: quantity,
      // Picture: Picture,
      //Remarks: Remarks,
      manufaturer_no: manufaturer_no

    };

    const incoming_inspection_id = incomingdetails[0].Incoming_Inspection_Id;
    const res2 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_update_inspection?s_no=${sno}&incoming_inspection_id=${incoming_inspection_id}`, payload2);

    const payload = {
      Item_Name: description,
      Part_Number: part_no,
      Traceability_Number: trace_no,
      UOM: UOM,
      Quantity: quantity,
      // Picture: Picture,
      Condition_Upon_Reciept: Condition,
      Remarks: Remarks,
      manufaturer_no: manufaturer_no

    };

    const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incominginspections_update?SNO=${sno}`, payload);

    const isNotPresent = itemdata.every(item => item.Item_Name !== description);

    if (isNotPresent) {

      // Make a POST request to the backend
      const payload = {
        Item_Name: description,
        Part_Number: part_no,
      };

      // try {
      //   const res = await axios.post(
      //     "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemmaster",
      //     payload
      //   );
      // } catch (error) {
      //   console.error("Error occurred while making POST request:", error);
      // }
    } else {
      console.log(`Item with name ${description} is already present.`);
    }

    const formData = new FormData();
    formData.append("Picture", Picture);

    const res1 = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incominginspectionsfiles?s_no=${sno}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    seteditdetails("")
    indetails.length = 0;
    seteditrow(-1);
    setrefresh(!refresh);
  }

  const handledelete = async (index) => {
    if (newrows !== "") {
      return;
    }
    setopenmodal(true);
    setremoveindex(index);
    // const num = indetails[index].s_no;
    // const res = await axios.delete(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incominginspections_delete?SNO=${num}`);
    // setrefresh(!refresh)
  }

  const handleDownloadExcel = () => {
    // Define header row with bold font
    const header = [
      { v: 'S. No', s: { bold: true } },
      { v: 'Item Name', s: { bold: true } },
      { v: 'Part Number', s: { bold: true } },
      { v: 'Traceability Number', s: { bold: true } },
      { v: 'UOM', s: { bold: true } },
      { v: 'Quantity', s: { bold: true } },
      { v: 'Picture', s: { bold: true } },
      { v: 'Condition Upon Receipt', s: { bold: true } },
      { v: 'Remarks', s: { bold: true } },
      { v: 'Bill of Entry', s: { bold: true } },
      { v: 'Project ID', s: { bold: true } },
      { v: 'Incoming Inspection ID', s: { bold: true } },
      { v: 'Incoming Delivery ID', s: { bold: true } },
      { v: 'Project SO', s: { bold: true } },
      { v: 'Arrival', s: { bold: true } },
      { v: 'Dispatching Party', s: { bold: true } },
      { v: 'Manufacturer Part Number', s: { bold: true } },
    ];

    // Prepare data rows
    const data = indetails.map((item, index) => {
      return [
        index + 1,
        item.Item_Name,
        item.Part_Number,
        item.Traceability_Number,
        item.UOM,
        item.Quantity,
        {
          f: item.Picture ? `=HYPERLINK("${item.Picture}")` : null,
          t: 's'
        },
        item.Condition_Upon_Reciept,
        item.Remarks,
        incomingdetails[0].Entry_Bill_No,
        item.Project_Id,
        item.Incoming_Inspection_ID,
        item.incoming_Delivery_id,
        workshopdetails[0].Sales_Order_No,
        incomingdetails[0].Expected_Arrival_Date,
        incomingdetails[0].Dispatching_Party,
        item.manufaturer_no
      ];
    });

    // Create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);

    // Set column widths
    const columnWidths = [
      { wch: 5 }, // S. No
      { wch: 20 }, // Item Name
      { wch: 20 }, // Part Number
      { wch: 17 }, // Traceability Number
      { wch: 5 }, // UOM
      { wch: 10 }, // Quantity
      { wch: 80 }, // Picture
      { wch: 20 }, // Condition Upon Receipt
      { wch: 35 }, // Remarks
      { wch: 20 }, // Bill of Entry
      { wch: 10 }, // Project ID
      { wch: 20 }, // Incoming Inspection ID
      { wch: 20 }, // Incoming Delivery ID
      { wch: 20 }, // Project SO
      { wch: 20 }, // Arrival
      { wch: 20 }, // Dispatching Party
    ];
    worksheet['!cols'] = columnWidths;


    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Incoming Inspections');

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'Incoming_Inspections.xlsx');
  };

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        const group = localStorage.getItem("Group");
        const roles = JSON.parse(localStorage.getItem("Roles")) || []; // Retrieve roles from localStorage

        // Group feature filter for Inspection Edit
        const workshopInspectionsFeatureEditGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Workshop Projects" &&
            el.Feature_Name === "Workshop Incoming Inspection Edit" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        // Role feature filter for Inspection Edit
        const workshopInspectionsFeatureEditRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Workshop Projects" &&
            el.Feature === "Workshop Incoming Inspection Edit" &&
            el.Access === "Can View"
          );
        });

        // Combine group and role features for Inspection Edit
        const combinedWorkshopInspectionsFeatureEdit = [
          ...workshopInspectionsFeatureEditGroup,
          ...workshopInspectionsFeatureEditRole,
        ];

        // Group feature filter for Inspection Remove
        const workshopInspectionsFeatureRemoveGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Workshop Projects" &&
            el.Feature_Name === "Workshop Incoming Inspection Remove" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        // Role feature filter for Inspection Remove
        const workshopInspectionsFeatureRemoveRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Workshop Projects" &&
            el.Feature === "Workshop Incoming Inspection Remove" &&
            el.Access === "Can View"
          );
        });

        // Combine group and role features for Inspection Remove
        const combinedWorkshopInspectionsFeatureRemove = [
          ...workshopInspectionsFeatureRemoveGroup,
          ...workshopInspectionsFeatureRemoveRole,
        ];

        // Group feature filter for Inspection Add
        const workshopInspectionsFeatureAddGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Workshop Projects" &&
            el.Feature_Name === "Workshop Incoming Inspection Add" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });

        // Role feature filter for Inspection Add
        const workshopInspectionsFeatureAddRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Workshop Projects" &&
            el.Feature === "Workshop Incoming Inspection Add" &&
            el.Access === "Can View"
          );
        });

        // Combine group and role features for Inspection Add
        const combinedWorkshopInspectionsFeatureAdd = [
          ...workshopInspectionsFeatureAddGroup,
          ...workshopInspectionsFeatureAddRole,
        ];

        // Prioritize "Can View" over "Can Edit"
        const prioritizeAccess = (features) => {
          const featureMap = {};
          features.forEach((feature) => {
            const key = `${feature.Header_Name || feature.Header}-${feature.Feature_Name || feature.Feature}`;
            if (!featureMap[key] || feature.Access === "Can View") {
              featureMap[key] = feature;
            }
          });
          return Object.values(featureMap);
        };

        // Set features with prioritized access
        setfeatureInspectionedit(prioritizeAccess(combinedWorkshopInspectionsFeatureEdit));
        setfeatureInspectionremove(prioritizeAccess(combinedWorkshopInspectionsFeatureRemove));
        setfeatureInspectionadd(prioritizeAccess(combinedWorkshopInspectionsFeatureAdd));

      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);


  return (
    <div style={{ boxShadow: "4px" }}>
      <LoadingScreen isLoading={isLoading} />
      {/* <h1 style={{marginTop: "200px", marginLeft: "100px"}}>Hello</h1> */}
      <div className={styles.topButtonContainer}>
        <button
          className={styles.topBackButton}
          onClick={handleBackClick}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
                fill="#5B6BE1"
              />
            </svg>
            {' '}Incoming Inspections
          </div>
        </button>
      </div>
      <div className={styles.middlediv}>
        {openmodal && (
          // Your modal content goes here
          <div className={styles.modal}>
            <div className={styles.modalcontent}>
              <p className={styles.modalcontent1}>Warning

                <svg style={{ color: "red", marginLeft: "10px", marginTop: "-5px" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg></p>
              <p className={styles.modalcontent2}>Are you sure ?</p>
              <p className={styles.modalcontent2}>The entry will be deleted</p>
              <button className={styles.modalcontent3} onClick={handleno} >No</button>
              <button className={styles.modalcontent3} onClick={handleremove3} >Yes</button>
            </div>
          </div>
        )}

        {Array.isArray(incomingdetails) &&
          incomingdetails.map((el, index) => (
            <div key={index} >
              <div
                style={{
                  borderRadius: '8px',
                  border: '1px solid #D1D9FF',
                  backgroundColor: '#D1D9FF',
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
                  //width: '98%',
                  minHeight: '55px',
                  alignItems: 'center',
                  // display: 'flex',
                  // paddingLeft: '1%',
                  // paddingRight: '1%',
                  //margin: '1%',
                  marginTop: '10px',
                  whiteSpace: 'nowrap',
                  overflowX: 'scroll',
                  overflowY: 'auto'

                }}
              >
                <div style={{ display: "flex", width: "200px" }}>

                  {Array.isArray(workshopdetails) &&
                    workshopdetails.map((el, index) => (
                      <div
                        className={styles.inlinelabels}
                        style={{
                          marginTop: "20px",
                          marginLeft: "20px",
                          justifyContent: "center",
                          //width: "200px"
                        }}
                      >
                        <label
                          style={{ marginLeft: "5px" }}
                          className={styles.sublabel}
                        >
                          Project SO : {el.Sales_Order_No}{" "}
                        </label>
                      </div>
                    ))}
                  {Array.isArray(workshopdetails) &&
                    workshopdetails.map((el, index) => (
                      <div
                        className={styles.inlinelabels}
                        style={{
                          marginTop: "20px",
                          marginLeft: "20px",
                          justifyContent: "center",
                          // width: "200px"
                        }}
                      >
                        <label
                          style={{ marginLeft: "5px" }}
                          className={styles.sublabel}
                        >
                          Project Design : {el.Design}{" "}
                        </label>
                      </div>
                    ))}
                  {Array.isArray(workshopdetails) &&
                    workshopdetails.map((el, index) => (
                      <div
                        className={styles.inlinelabels}
                        style={{
                          marginTop: "20px",
                          marginLeft: "20px",
                          justifyContent: "center",
                          // width: "auto"
                        }}
                      >
                        <label
                          style={{ marginLeft: "5px" }}
                          className={styles.sublabel}
                        >
                          Project Name : {el.Project_Name}{" "}
                        </label>
                      </div>
                    ))}
                  {Array.isArray(workshopdetails) &&
                    workshopdetails.map((el, index) => (
                      <div
                        className={styles.inlinelabels}
                        style={{
                          marginTop: "20px",
                          marginLeft: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <label
                          style={{ marginLeft: "5px" }}
                          className={styles.sublabel}
                        >
                          Project Scope : {el.Scope}{" "}
                        </label>
                      </div>
                    ))}
                  <div
                    className={styles.deliveryStatusButton}
                    style={{
                      backgroundColor: getBackgroundColor(el), color: getTextColor(el),
                      marginLeft: "15%", marginTop: "20px", width: "auto", height: "42px"
                    }}>
                    {getButtonText(el)}
                  </div>

                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className={styles.inlinelabels}
                    style={{
                      marginTop: "20px",
                      justifyContent: "center",
                      whiteSpace: "nowrap",

                      marginLeft: "20px",
                      // width: "180px"
                    }}
                  >
                    <label
                      style={{ marginLeft: "5px" }}
                      className={styles.sublabel}
                    >
                      Arrival : {el.Expected_Arrival_Date}
                    </label>
                  </div>
                  <div
                    className={styles.inlinelabels}
                    style={{ marginTop: "20px", marginLeft: "20px" }}
                  >
                    <label
                      style={{ marginLeft: "5px", display: "flex", color: "#5B6BE1", fontWeight: "600" }}
                    //   className={styles.sublabel}
                    >
                      Dispatching Party : {el.Dispatching_Party}
                    </label>
                  </div>
                  <div
                    className={styles.inlinelabels}
                    style={{
                      marginTop: "20px",
                      marginLeft: "20px",
                      justifyContent: "center",
                      // width: "auto"
                    }}
                  >
                    <label
                      style={{ marginLeft: "5px" }}
                      className={styles.sublabel}
                    >
                      Bill Of Entry : {el.Entry_Bill_No}{" "}
                    </label>
                  </div>
                  <div
                    className={styles.inlinelabels}
                    style={{
                      marginTop: "20px",
                      marginLeft: "20px",
                      justifyContent: "center",
                      width: "auto",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      gap: "8px"
                    }}
                  >
                    <label
                      style={{ marginLeft: "5px" }}
                      className={styles.sublabel}
                    >
                      Incoming Delivery Id :{el.S_No}
                    </label>
                    <div style={{
                      cursor: 'pointer'
                    }}
                      onClick={handleDeliveryClick}>
                      <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" width="20" height="20" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="OpenInNewIcon" style={{ cursor: 'pointer' }}>
                        <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
                      </svg>
                    </div>
                  </div>
                  <div
                    className={styles.inlinelabels}
                    style={{
                      marginTop: "20px",
                      marginLeft: "20px",
                      justifyContent: "center",
                      width: "auto"
                    }}
                  >
                    <label
                      style={{ marginLeft: "5px" }}
                      className={styles.sublabel}
                    >
                      Incoming Inspection Id : {el.Incoming_Inspection_Id}{" "}
                    </label>
                  </div>
                  <div
                    className={styles.inlinelabels}
                    style={{
                      display: "flex",
                      flex: "1",
                      marginTop: "20px",
                      marginLeft: "20px",
                      marginRight: "20px",
                      justifyContent: "flex-end",
                      padding: "2px",
                      borderRadius: "5px",
                      backgroundColor: "rgb(209, 217, 255)",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "white",
                        // padding: "2px",
                      }}
                    >
                      <svg
                        width="42"
                        height="42"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: "pointer" }}
                        onClick={handleDownloadExcel}
                      >
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#5B6BE1"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div>
        <div style={{ overflowY: "scroll", maxWidth: "98%", marginLeft: "1%" }}>
          <table
            className={styles.dailtracwrkshp}
            style={{ marginTop: "20px" }}
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Item Name</th>
                <th>Part Number</th>
                <th>Traceability Number</th>
                <th>Manufacturer Part Number</th>
                <th>UOM</th>
                <th>Qty</th>
                <th>Picture</th>
                <th>Condition Upon Reciept</th>
                <th>Remarks</th>

              </tr>
            </thead>
            <tbody>
              {indetails &&
                indetails.map((el, index) => (
                  <tr>
                    <td>
                      <div style={{ marginBottom: "20px" }}>
                        {index + 1}
                      </div>
                      {editrow !== index ? (
                        <div style={{ display: "flex", gap: "20px", }}>
                          {featureInspectionedit.length !== 0 && (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => handleedit(index)}
                            >
                              <path
                                d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z"
                                fill="#5B6BE1"
                              />
                            </svg>
                          )}
                          {featureInspectionremove.length !== 0 && (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => handledelete(index)}
                            >
                              <path
                                d="M8 0C3.592 0 0 3.592 0 8C0 12.408 3.592 16 8 16C12.408 16 16 12.408 16 8C16 3.592 12.408 0 8 0ZM10.688 9.84C10.92 10.072 10.92 10.456 10.688 10.688C10.568 10.808 10.416 10.864 10.264 10.864C10.112 10.864 9.96 10.808 9.84 10.688L8 8.848L6.16 10.688C6.04 10.808 5.888 10.864 5.736 10.864C5.584 10.864 5.432 10.808 5.312 10.688C5.08 10.456 5.08 10.072 5.312 9.84L7.152 8L5.312 6.16C5.08 5.928 5.08 5.544 5.312 5.312C5.544 5.08 5.928 5.08 6.16 5.312L8 7.152L9.84 5.312C10.072 5.08 10.456 5.08 10.688 5.312C10.92 5.544 10.92 5.928 10.688 6.16L8.848 8L10.688 9.84Z"
                                fill="#5B6BE1"
                              />
                            </svg>
                          )}
                        </div>
                      ) : (
                        <div style={{ display: "flex", gap: "10px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="24"
                            viewBox="0 0 26 24"
                            fill="none"
                            onClick={handleput}
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                              fill="#66D171"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={handleremove2}
                          >
                            <path
                              d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                              fill="#F97575"
                            />
                            <path
                              d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                              fill="#F97575"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>

                      {editrow !== index ? (
                        <input
                          type="text"
                          name="description"

                          value={el.Item_Name}
                          disabled
                          onChange={handlenewchange2}
                          style={{ border: "none", backgroundColor: "white", textAlign: 'center' }}
                        />
                      ) : (
                        <div>
                          <input
                            type="text"
                            name="description"
                            id="eii"
                            className={styles.inputFieldeditd}
                            //value={el.Description}
                            placeholder={el.Item_Name}
                            onChange={handlenewchange2}

                          />

                          <ul id="suggestionslist" style={{ cursor: 'pointer' }}>
                            {suggestions.map((suggestion, index) => (
                              <li key={index} style={{ border: '1px solid #e7ebff' }} onClick={() => handleSuggestionSelecte(suggestion.Item_Name, suggestion.Part_Number)}>
                                {suggestion.Item_Name}
                              </li>

                            ))}

                            <li id="leii" >

                            </li>

                          </ul>


                        </div>
                      )}

                    </td>
                    <td>
                      {editrow !== index ? (
                        <input
                          type="text"
                          name="part_no"
                          disabled
                          onChange={handlenewchange2}
                          value={el.Part_Number}
                          style={{ border: "none", backgroundColor: "white", textAlign: 'center' }}


                        />) : (
                        <div>
                          <input
                            type="text"
                            name="part_no"
                            // disabled={isselected ? false : true}
                            onChange={handlenewchange2}
                            className={styles.inputFieldedit}
                            //Value={el.Part_Number}
                            style={{ backgroundColor: isselected ? 'inherit' : 'inherit' }}
                            id="eip"

                          />
                          <ul id="suggestionslistPartNo" style={{ cursor: 'pointer' }}>
                            {partNoSuggestions.map((suggestion, idx) => (
                              <li
                                key={idx}
                                style={{ border: '1px solid #e7ebff' }}
                                onClick={() => handleSuggestionSelecte(suggestion.Item_Name, suggestion.Part_Number)}
                              >
                                {suggestion.Part_Number}
                              </li>
                            ))}
                            <li id="leip"></li>
                          </ul>
                        </div>
                      )}
                    </td>
                    <td>
                      {editrow !== index ? (
                        <input
                          type="text"
                          name="trace_no"
                          onChange={handlenewchange2}
                          disabled
                          value={el.Traceability_Number}
                          style={{ border: "none", backgroundColor: "white", textAlign: 'center' }}
                        />) : (
                        <input
                          type="text"
                          name="trace_no"
                          onChange={handlenewchange2}
                          Value={el.Traceability_Number}
                          className={styles.inputFieldedit}
                        />
                      )}
                    </td>
                    <td>
                      {editrow !== index ? (
                        <input
                          type="text"
                          name="manufaturer_no"
                          onChange={handlenewchange2}
                          disabled
                          value={el.manufaturer_no}
                          style={{ border: "none", backgroundColor: "white", textAlign: 'center' }}
                        />) : (
                        <input
                          type="text"
                          name="manufaturer_no"
                          onChange={handlenewchange2}
                          Value={el.manufaturer_no}
                          className={styles.inputFieldedit}
                        />
                      )}
                    </td>
                    <td>
                      {editrow !== index ? (
                        <input
                          type="text"
                          name="UOM"
                          onChange={handlenewchange2}
                          value={el.UOM}
                          disabled
                          style={{ border: "none", backgroundColor: "white", textAlign: 'center' }}
                        />) : (
                        // <input
                        // type="text"
                        // name="UOM"
                        // onChange={handlenewchange2}
                        // Value={el.UOM}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <select name="UOM"
                            onChange={handlenewchange2}
                            value={editdetails.UOM}
                            className={styles.inputFieldeditsel}
                          >
                            <option value="">select the value</option>
                            <option value="Pcs">Pcs</option>
                            <option value="Nos">Nos</option>
                            <option value="Boxes">Boxes</option>
                            <option value="Sets">Sets</option>
                          </select>
                          <svg
                            style={{ marginLeft: "-15%", marginTop: "10px", pointerEvents: "none" }}
                            width="24"
                            height="24"
                            viewBox="0 0 30 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                        // />
                      )}
                    </td>
                    <td>
                      {editrow !== index ? (
                        <input
                          type="text"
                          name="quantity"
                          onChange={handlenewchange2}
                          value={el.Quantity}
                          disabled
                          style={{ border: "none", backgroundColor: "white", textAlign: 'center' }}
                        />) : (
                        <input
                          type="text"
                          name="quantity"
                          onChange={handlenewchange2}
                          Value={el.Quantity}
                          className={styles.inputFieldedit}

                        />
                      )}
                    </td>
                    <td>
                      {editrow !== index ? (
                        <div style={{ display: "inline-flex" }}>
                          <input
                            // type="text"
                            name="Picture"
                            value={el.Picture ? String(el.Picture).split("/")
                              .pop()
                              .split("-")
                              .slice(1)
                              .join("-") : "No File Chosen"}
                            style={{
                              border: "1px solid rgb(231, 235, 235)",
                              width: "200px",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              border: "none",
                              textAlign: 'center',
                              // marginLeft: "147px",
                              cursor: 'default',
                              pointerEvents: 'none'

                            }}
                            readOnly // Ensure the input is read-only if not in edit mode
                          />

                          {el.Picture && (
                            <>
                              <div >
                                <a href={el.Picture} target="_blank">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "3px",
                                      marginLeft: "30px",
                                    }}>
                                    <path
                                      d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                                      fill="black"></path>
                                    <path
                                      d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                                      fill="black"></path>
                                  </svg>
                                </a>
                              </div>
                              <div style={{ border: "none", width: "30px", height: "30px", borderRadius: "8px", cursor: "pointer", background: '#F0F0F0' }}
                              >
                                <svg
                                  style={{ marginTop: "5px", marginLeft: "5px" }}
                                  onClick={() => handledownloadfile(el.Picture)}
                                  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z" fill="#292D32" />
                                </svg>
                              </div>
                            </>
                          )}

                        </div>
                      ) : (
                        <div
                          style={{ display: "inline-flex" }}>
                          <input
                            type="file"
                            name="Picture"
                            id="Picture"
                            onChange={(e) => handlenewchange2file("Picture", e)}
                            style={{ display: "none", cursor: 'pointer', width: "auto" }} // Hide the file input

                          />
                          <div
                            style={{
                              border: "1px solid rgb(231, 235, 235)",
                              width: "auto",
                              borderRadius: "8px",
                              height: "35px",
                              alignItems: "center",
                              marginright: "auto",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              cursor: 'pointer',
                              marginTop: "10px"

                            }}
                            onClick={() =>
                              document.getElementById("Picture").click()
                            }>
                            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                              <div style={{ marginTop: "3px" }}> {tempPicture || "Upload File"}</div>
                              <div >
                                <svg
                                  width="25"
                                  height="25"
                                  viewBox="0 0 28 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"

                                >
                                  <path
                                    d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                    fill="black"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                        </div>
                      )}
                    </td>
                    <td>
                      {editrow !== index ? (
                        <input
                          type="text"
                          name="Condition"
                          onChange={handlenewchange2}
                          value={el.Condition_Upon_Reciept}
                          disabled
                          style={{ border: "none", backgroundColor: "white", textAlign: 'center' }}
                        />) : (
                        //   <input
                        //   type="text"
                        //   name="Condition"
                        //   onChange={handlenewchange2}
                        //   Value={el.Time_of_pf_receipt}

                        // />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <select name="Condition"
                            onChange={handlenewchange2}
                            className={styles.inputFieldeditsel}
                            value={editdetails.Condition}
                          >
                            <option value="">select the value</option>
                            <option value="New">New</option>
                            <option value="Old & Used">Old & Used</option>
                            <option value="Refurbished">Refurbished</option>

                          </select>
                          <svg
                            style={{ marginLeft: "-15%", marginTop: "10px", pointerEvents: "none" }}
                            width="24"
                            height="24"
                            viewBox="0 0 30 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editrow !== index ? (
                        <input
                          type="text"
                          name="Remarks"
                          onChange={handlenewchange2}
                          value={el.Remarks}
                          disabled
                          style={{ border: "none", backgroundColor: "white", textAlign: 'center' }}
                        />) : (
                        <input
                          type="text"
                          name="Remarks"
                          onChange={handlenewchange2}
                          className={styles.inputFieldedit}
                          Value={el.Remarks}

                        />
                      )}
                    </td>
                  </tr>
                ))}
              {newrows !== "" && (
                <tr>
                  <td>
                    <div styles={{ marginBottom: "2px" }}>
                      {indetails ? indetails.length + 1 : 1}
                    </div>
                    <br />
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="24"
                        viewBox="0 0 26 24"
                        fill="none"
                        onClick={handlepost}
                        disabled={isLoading}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                          fill="#66D171"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={handleremove}
                      >
                        <path
                          d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                          fill="#F97575"
                        />
                        <path
                          d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                          fill="#F97575"
                        />
                      </svg>
                    </div>
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        name="description"
                        onChange={handlenewchange}
                        id="nii"
                        className={styles.inputFieldeditd}
                        placeholder="Start typing to search..."
                      />

                      <ul id="suggestionslist" style={{ cursor: 'pointer' }}>
                        {suggestions.map((suggestion, index) => (
                          <li key={index} style={{ border: '1px solid #e7ebff' }} onClick={() => handleSuggestionSelectn(suggestion.Item_Name, suggestion.Part_Number)}>
                            {suggestion.Item_Name}
                          </li>
                        ))}

                        <li id="lnii" >

                        </li>

                      </ul>

                    </div>

                  </td>
                  <td>
                    <input
                      type="text"
                      name="part_no"
                      onChange={handlenewchange}
                      // disabled={isselected ? false : true}
                      style={{ backgroundColor: isselected ? 'inherit' : 'inherit' }}
                      className={styles.inputFieldedit}
                      id="nip"
                    />
                    <ul id="suggestionslistPartNo" style={{ cursor: 'pointer' }}>
                      {partNoSuggestions.map((suggestion, idx) => (
                        <li
                          key={idx}
                          style={{ border: '1px solid #e7ebff' }}
                          onClick={() => handleSuggestionSelectn(suggestion.Item_Name, suggestion.Part_Number)}
                        >
                          {suggestion.Part_Number}
                        </li>
                      ))}
                      <li id="lnip"></li>
                    </ul>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="trace_no"
                      onChange={handlenewchange}
                      className={styles.inputFieldedit}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="manufaturer_no"
                      onChange={handlenewchange}
                      className={styles.inputFieldedit}
                    />
                  </td>
                  <td>
                    {/* <input type="text" name="UOM" onChange={handlenewchange} /> */}
                    <select name="UOM"
                      onChange={handlenewchange}
                      className={styles.inputFieldeditsel}
                    >
                      <option value="">select the value</option>
                      <option value="Pcs">Pcs</option>
                      <option value="Nos">Nos</option>
                      <option value="Boxes">Boxes</option>
                      <option value="Sets">Sets</option>
                    </select>
                    <svg
                      style={{ marginLeft: "-15%", marginTop: "0px", pointerEvents: "none" }}
                      width="24"
                      height="24"
                      viewBox="0 0 30 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="quantity"
                      onChange={handlenewchange}
                      className={styles.inputFieldedit}
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                      <div style={{ marginLeft: "15px" }}>
                        <input
                          type="file"
                          name="Picture"
                          onChange={handlenewchangefile}
                          className={styles.inputFieldedit1}

                        />
                      </div>
                      <div style={{ marginLeft: "-35px", marginTop: "8px" }}>
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 24 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"


                        >
                          <path
                            d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                            fill="black"
                          />
                          <path
                            d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </td>
                  <td>
                    {/* <input
                      type="text"
                      name="Condition"
                      onChange={handlenewchange}
                    /> */}
                    <select name="Condition"
                      onChange={handlenewchange}
                      className={styles.inputFieldeditsel}
                    >
                      <option value="">select the value</option>
                      <option value="New">New</option>
                      <option value="Old & Used">Old & Used</option>
                      <option value="Refurbished">Refurbished</option>

                    </select>
                    <svg
                      style={{ marginLeft: "-15%", marginTop: "0px", pointerEvents: "none" }}
                      width="24"
                      height="24"
                      viewBox="0 0 30 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                        fill="black"
                      />
                    </svg>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="Remarks"
                      onChange={handlenewchange}
                      className={styles.inputFieldedit}
                    />
                  </td>

                </tr>
              )}
            </tbody>
          </table>


        </div>
        <div >
          {featureInspectioneadd.length !== 0 && (
            <button disabled={editrow !== -1 || newrows !== ""} className={styles.addnewbtn} onClick={handlenewrow}>
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: "5px" }}>
                <div style={{ fontWeight: "600", fontSize: "16" }}> Add new Item </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.4915 1.66602H6.50817C3.47484 1.66602 1.6665 3.47435 1.6665 6.50768V13.4827C1.6665 16.5243 3.47484 18.3327 6.50817 18.3327H13.4832C16.5165 18.3327 18.3248 16.5243 18.3248 13.491V6.50768C18.3332 3.47435 16.5248 1.66602 13.4915 1.66602ZM14.9998 10.6243H10.6248V14.9993C10.6248 15.341 10.3415 15.6243 9.99984 15.6243C9.65817 15.6243 9.37484 15.341 9.37484 14.9993V10.6243H4.99984C4.65817 10.6243 4.37484 10.341 4.37484 9.99935C4.37484 9.65768 4.65817 9.37435 4.99984 9.37435H9.37484V4.99935C9.37484 4.65768 9.65817 4.37435 9.99984 4.37435C10.3415 4.37435 10.6248 4.65768 10.6248 4.99935V9.37435H14.9998C15.3415 9.37435 15.6248 9.65768 15.6248 9.99935C15.6248 10.341 15.3415 10.6243 14.9998 10.6243Z" fill="white"></path>
                </svg>

              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Incoming_Inspections;
