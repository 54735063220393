import React from "react";
import { Link } from "react-router-dom";
import styles from "./css/ergonpropertylogs.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function ErgonPropertylogs() {
  const [searchQuery, setSearchQuery] = useState("");
  const { id } = useParams();
  const { part_number } = useParams();
  const navigate = useNavigate();
  const [itemsReceived, setItemsReceived] = useState([]);
  const [itemdata, setitemdata] = useState([]);

  const [partNumberToItemIdMap, setPartNumberToItemIdMap] = useState({});
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const groupedItems = itemsReceived.reduce((acc, item) => {
    const key = `${item.Item_Name}|${item.OEM_Part_Number}|${item.Manufacturer_Part_Number}`;
    const quantityReceived = parseFloat(item.Scheduled_Quantity); // Convert quantity to number

    if (!acc[key]) {
        acc[key] = { ...item, Quantity_Total: quantityReceived };
    } else {
        acc[key].Quantity_Total += quantityReceived;
    }

    return acc;
}, {});

// Convert the grouped object to an array of items
const result = Object.values(groupedItems);


  // Filtering logic
  const filteredItems = Object.values(groupedItems).filter((item) => {
    const queryParts = searchQuery.trim().toLowerCase().split(' ');
    // Get the Item_Id from the partNumberToItemIdMap
    const itemId = partNumberToItemIdMap[`${item.Item_Name}|${item.OEM_Part_Number ? item.OEM_Part_Number : ''}|${item.Manufacturer_Part_Number ? item.Manufacturer_Part_Number : 0}`];
    return queryParts.every(part =>
      item.Item_Name?.toLowerCase().includes(part) ||
      item.OEM_Part_Number?.toLowerCase().includes(part) || 
      item.Manufacturer_Part_Number?.toLowerCase().includes(part) ||
      (itemId && itemId.toString().toLowerCase().includes(part))
    );
  });
  // console.log("itemsReceived", itemsReceived);
  useEffect(() => {
    async function fetchDetails() {
      try {
        const [res1, resi] = await Promise.all([
          axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get4"),
          axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster2"),
        ]);

        const itemsReceivedData = res1.data.data;
        const itemData = resi.data;

        const newPartNumberToItemIdMap = {};
        itemData.forEach((item) => {
          const key = `${item.Item_Name}|${item.Part_Number ? item.Part_Number : ''}|${item.Manufacturer_Part_Number ? item.Manufacturer_Part_Number : 0}`;
          newPartNumberToItemIdMap[key] = item.Item_Id;
        });

        setItemsReceived(itemsReceivedData);
        setPartNumberToItemIdMap(newPartNumberToItemIdMap);

        // Example of how to safely log the item ID for "Grease" with a known part number.
        // You need to replace 'knownPartNumber' with the actual part number of "Grease".
        const greaseKey = `Grease|null`; // Make sure 'knownPartNumber' is defined correctly.
        // console.log("Item ID for Grease:", newPartNumberToItemIdMap[greaseKey]);

      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }

    fetchDetails();
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchDatain = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_summary"
        ); // Assuming this is the endpoint you've set up

        // Filter out items where Scheduled_Sum + Qty_Delta_Sum - Qty_Rejected_Sum == 0
        const filteredData = response.data.filter(item => {
          return (item.Scheduled_Sum + item.Qty_Delta_Sum) !== 0;
        });

        setData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDatain(); // Call the function to fetch data when the component mounts
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  // console.log("filteredItems", filteredItems);

  return (
    <div className={styles.topdiv}>
      <div style={{ justifyContent: "left" }}>
        <div className={styles.headingdiv}>
          <h1 className={styles.heading}>Ergon Inventory </h1>
        </div>
      </div>
      <div className={styles.sections}>
        <div>
          <div className={styles.searchContainer}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Search Item Name, Part Number, Item ID..."
              value={searchQuery}
              onChange={handleSearchChange}
            />

            <div className={styles.buttonContainer}>
              <button className={styles.searchButton} disabled>
                <svg
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  style={{ paddingBottom: "2px", display: "flex", justifyContent: "center" }}
                  viewBox="0 0 22 22"
                  id="search"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M15.5 14h-.79l-.28-.27c1.2-1.4 1.82-3.31 1.48-5.34-.47-2.78-2.79-5-5.59-5.34-4.23-.52-7.79 3.04-7.27 7.27.34 2.8 2.56 5.12 5.34 5.59 2.03.34 3.94-.28 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* ////table//// */}

        <div className={styles.itemstablecontainer}>
          <table className={styles.itemstable}>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Item Name</th>
                <th>Part Number</th>
                <th>Manufacturer Part Number</th>
                <th>Available Quantity</th>
                <th>UOM</th>
                <th>Item Id</th>
                
              </tr>
            </thead>
            <tbody>
              {filteredItems.length === 0 && (
                <tr>
                  <td colSpan="7">
                    <div className={styles.noResults}>No Results Found</div>
                  </td>
                </tr>
              )}
              {filteredItems
                // Filter items where the sum of Scheduled_Sum and Qty_Delta_Sum is not zero
                .filter(item => (item.Scheduled_Sum + item.Qty_Delta_Sum) !== 0)
                // Map through filtered items
                .map(item => {
                  // Find the corresponding item in the data array
                const totalSumItem = data.find(
                  dataItem =>
                    dataItem.Item_Name === item.Item_Name &&
                      dataItem.OEM_Part_Number === item.OEM_Part_Number && dataItem.Manufacturer_Part_Number === item.Manufacturer_Part_Number
                );
                  // Return the found item or undefined if not found
                  // Merge the UoM from the original item if totalSumItem is found
                  if (totalSumItem) {
                    return { ...totalSumItem, UoM: item.UoM };
                  } else {
                    return undefined;
                  }
                })
                // Filter out undefined items
                .filter(totalSumItem => totalSumItem !== undefined)
                // Return a table row for each item
                .map((totalSumItem, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {(() => {
                        // Encode the item name and OEM part number
                        const encodedItemName = encodeURIComponent((totalSumItem.Item_Name).replace(/\&/g, '%26').replace(/\+/g, '%2B'));
                        const encodedOEMPartNumber = encodeURIComponent(totalSumItem.OEM_Part_Number ? totalSumItem.OEM_Part_Number : null);
                        const encodedManufacturerPartNumber = encodeURIComponent(totalSumItem.Manufacturer_Part_Number ? totalSumItem.Manufacturer_Part_Number : null);
                        // Return a link to the item details
                        return (
                          <Link
                            to={`/ergoninventorylogsdetails/${partNumberToItemIdMap[`${totalSumItem.Item_Name}|${totalSumItem.OEM_Part_Number ? totalSumItem.OEM_Part_Number : ''}|${totalSumItem.Manufacturer_Part_Number ? totalSumItem.Manufacturer_Part_Number : 0}`]}/${encodedOEMPartNumber}/${encodedItemName}/${encodedManufacturerPartNumber}`}
                          >
                            {totalSumItem.Item_Name}
                          </Link>
                        );
                      })()}
                    </td>
                    <td>{totalSumItem.OEM_Part_Number}</td>
                    <td>{totalSumItem.Manufacturer_Part_Number}</td>
                    <td>{totalSumItem ? totalSumItem.Final_Total : "0"}</td>
                    <td>{totalSumItem.UoM}</td>
                    <td>{partNumberToItemIdMap[`${totalSumItem.Item_Name}|${totalSumItem.OEM_Part_Number ? totalSumItem.OEM_Part_Number : ''}|${totalSumItem.Manufacturer_Part_Number ? totalSumItem.Manufacturer_Part_Number : 0}`]}</td>
                    {/* <td>{partNumberToItemIdMap[`${totalSumItem.Item_Name}|${totalSumItem.OEM_Part_Number}|${totalSumItem.Manufacturer_Part_Number}`]}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default ErgonPropertylogs;
