import React from 'react';
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NavLink ,useNavigate} from "react-router-dom";
import styles from "../Components/css/availability.module.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import { Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import LoadingScreen from './LoadingScreen';
import { saveAs } from "file-saver";
// import 'jspdf-autotable';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Availability() {
  const [val, setval] = useState("");
  const [valdesignation, setvaldesignation] = useState("");
  const [projectdetails, setprojectdetails] = useState([]);
  const [daterange, setdaterange] = useState([]);
  const [resarray, setresarray] = useState([]);
  const [desarray, setdesarray] = useState([]);
  const [fill,setfill] = useState("false");
  const [dateselected,setdateselected] = useState("false");
  const [fromdate,setfromdate]=useState("");
  const [todate,settodate]=useState("");
  const [comdate,setcomdate]=useState("");
  const [podays,setpodays]=useState("");
  const [indetails, setindetails] = useState([]);
  const navigate = useNavigate();
  const presentdate = new Date();
  const [searchQuery, setSearchQuery] = useState("");

  const currentYear = new Date().getFullYear();
const yearOptions = [currentYear - 1, currentYear, currentYear + 1];
const [filterContainer, setFilterContainer] = useState(false);
const [selectedScopes, setSelectedScopes] = useState([]);
const [filteredFlattenedData, setFilteredFlattenedData] = useState([]);
const [isLoading, setIsLoading] = useState(false);

const [completedProjectsByTech, setCompletedProjectsByTech] = useState({}); 

  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiInputBase-input": {
      border: "transparent",
      padding: "0",
      paddingLeft: "5px",
    },
    "& .MuiFormLabel-root": {
      paddingTop: "3px",
    },
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      height: "45px",
      borderRadius: "8px",
      "&:hover": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
      },
    },
  }));

  const handlevalChange = (event) => {

    const selectedval = event.target.value;
    setvaldesignation(selectedval);
    setfill("false");
  };
  const handlevalChange2 = (event) => {
    setfromdate("");
settodate("");
setcomdate("");
setpodays("");

    const selectedval = event.target.value;
    if(selectedval ==="DateRange"){
      setdateselected(true);
    }else{
      setdateselected(false);
    }
    
    setval(selectedval);
    setfill("false");
  };


  const fetchProject = async () => {
    setIsLoading(true);
    const res = await axios.get(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getavailabilitydetails?searchTerm=${searchQuery}&param2=${val}&param3=${valdesignation}&param4=${comdate}&param5=${podays}`
    );

    const res1 =  await axios.get(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/techworkshop`
    );
    const res2 =  await axios.get(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details`
    );
  

    const resu = res.data.results;

    console.log("I am here");
    console.log(resu);
   
    const filteredData = resu;
    // .filter(item => item.Availability_Status !== '-');
    console.log(filteredData);
    if(comdate ==="" && podays===""){
    setprojectdetails(filteredData);
    }else if(comdate !=="" && podays!==""){
      // const filteredData1 = filteredData.filter(item => item.Person_Status !== 'Engaged');
      // setprojectdetails(filteredData1);
      const filteredData1 = filteredData.filter(item => item.Person_Status === 'Engaged');
 
      // Get the unique Customer_Names from filteredData1
      const engagedCustomerNames = filteredData1.map(item => item.Customer_Name);
     
      // Filter out entries where Customer_Name is not in engagedCustomerNames
      const filteredData2 = filteredData.filter(item => !engagedCustomerNames.includes(item.Customer_Name));
      setprojectdetails(filteredData2);
    }

    const resu1 = res1.data.data;
  
    console.log(resu1);
    const resultArray = [];

    resu1.forEach(entry => {
    for (const key in entry) {
        if (key !== 'S_No') {
            resultArray.push(...entry[key].split(','));
        }
    }
    
});

const filteredArray1 = resultArray.filter(name => name.toLowerCase().includes(searchQuery.toLowerCase()));
console.log("I am heredd");
console.log(filteredArray1);
const resu2 = res2.data;
const designationArrays = {};
console.log(resu2);
resultArray.forEach(name =>{
  const designations = resu2.filter(item => item.Customer_Name.trim() === name.trim()).map(item => item.Designation);
   designationArrays[name] = designations;
})
console.log(designationArrays);
setdesarray(designationArrays);
setresarray(filteredArray1);
setIsLoading(false);
  };

 

const handleSearchName = (event) => {
  const { value } = event.target;
  setSearchQuery(value);
}

useEffect(() => {
  fetchProject();
}, [searchQuery,valdesignation,val,comdate,podays]);

  const handledwn3 = () => {
    const form = document.getElementById('formm');
    const table = form.querySelector("table");
    const rows = Array.from(table.querySelectorAll("tr"));

    const data = rows.map((row) =>
      Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
    );

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.write to generate the Excel file as an ArrayBuffer
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Use FileSaver.js to save the file
    saveAs(blob, "table_data.xlsx");
  };


  // const handlefromdate=(event)=>{
  //   setfromdate(event.target.value);
  //       console.log("I am here1");
  //   console.log(fromdate);
  //   //2024-03-06

  // };

  const handlefromdate=(value)=>{
    const selectedDate = value.format("YYYY-MM-DD");
    setfromdate(selectedDate);
    // Additional logic if needed
    console.log("Selected date:", selectedDate);
  };
  const handletodate=(event)=>{
    settodate(event.target.value);
    console.log("I am here2");
    console.log(todate);
  };

  const handledatefil =()=>{
    setcomdate(fromdate);
    setpodays(todate);
    setfill("true");
  };


  const groupedData = {};

projectdetails.forEach(el => {
  if (!groupedData[el.Customer_Name]) {
    groupedData[el.Customer_Name] = {
      Customer_Name: el.Customer_Name,
      Designation: el.Designation,
      OngoingProjects: [],
      UpcomingProjects: []
    };
  }

  const rigNameNavLink = el.Rig_Name ? (
    <NavLink to={"/project_checklist/" + el.ID_No}>{el.Rig_Name}</NavLink>
  ) : (
    "-"
  );

  const dateRange = (
    <>
      {rigNameNavLink}-{el.Estimated_Date_Of_Commencement}-{el.Estimated_Project_Completion_Month}
    </>
  );

  if (el.Availability_Status === 'Ongoing Project') {
  
    groupedData[el.Customer_Name].OngoingProjects.push(dateRange);
  } else if (el.Availability_Status === 'Upcoming Project') {
    groupedData[el.Customer_Name].UpcomingProjects.push(dateRange);
  }

 });
 for (let i = 0; i < resarray.length; i++) {
  const customerName = resarray[i];
  
  // Check if the customerName exists in projectdetails array
  const customerExists = projectdetails.some((project) => project.Customer_Name === customerName);

  if (!customerExists && (desarray[customerName][0]?.toLowerCase() === valdesignation.toLowerCase() || valdesignation==="")) {
      groupedData[customerName] = {
          Customer_Name: customerName,
          Designation: desarray[customerName][0],
          OngoingProjects: ["Workshop"]
      };
  } else if(customerExists) {
      groupedData[customerName].OngoingProjects.push("Workshop");
  }
}

// console.log(resarray)
// resarray.forEach(customer_name => {
//   groupedData[customer_name].OngoingProjects.push("Workshop");
// });

// Filter Component for the year


const handleNavigation = (id) => {
  navigate(`/project_checklist/${id}`);
}

const fetchCompletedProjectsByTech = async () => {
  try {
      const response = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/completed-projects-by-tech');
      const projects = response.data.results;

      const groupedData = {};

      projects.forEach((project) => {
          const tech = project.Tech_Name;
          if (!groupedData[tech]) {
              groupedData[tech] = [];
          }
          groupedData[tech].push({
              Rig_Name: project.Rig_Name,
              Commencement_Date: project.Estimated_Date_Of_Commencement,
              Completion_Date: project.Estimated_Project_Completion_Month,
              ID_No: project.ID_No,
          });
      });

      setCompletedProjectsByTech(groupedData);
  } catch (error) {
      console.error('Error fetching completed projects by tech:', error);
  }
};

useEffect(() => {
  fetchCompletedProjectsByTech();
}, []);

const openFilterContainer = (e) => {
  e.preventDefault();
  setFilterContainer((prev) => !prev);
};

console.log("scopes",selectedScopes)
useEffect(() => {
  // Run the applyFilters function on initial load
  applyFilters();
  console.log("loading",isLoading)
}, [isLoading]); // Empty dependency array to ensure this runs only on mount

const applyFilters = () => {
  console.log("Selected Years:", selectedScopes);

  // Show all data if no filters are applied
  if (selectedScopes.length === 0) {
    console.log("No filters selected. Showing all data.");
    setFilteredFlattenedData(flattenedData);
    setFilterContainer(false);
    return;
  }

  // Helper function to check if any date in a project matches the selected year(s)
  const hasMatchingYear = (projects) => {
    return (projects || []).some((project) => {
      if (project?.props?.children) {
        const children = project.props.children;
        console.log("Project Children:", children);

        // Extract all date strings from children
        const dates = children.filter(
          (child) => typeof child === "string" && /\d{2}\/\d{2}\/\d{4}/.test(child)
        );
        console.log("Extracted Dates:", dates);

        // Check if any extracted date matches the selected year(s)
        return dates.some((date) => {
          const year = Number(date.split("/")[2]);
          console.log("Extracted Year from Date:", year);
          return selectedScopes.includes(year);
        });
      }
      return false;
    });
  };

  // Filter rows based on matching year
  const filteredData = flattenedData.filter((person) => {
    const matchesOngoing = hasMatchingYear(person.OngoingProjects);
    const matchesUpcoming = hasMatchingYear(person.UpcomingProjects);

    const matchesCompleted = (completedProjectsByTech[person.Customer_Name] || []).some((project) => {
      const datesToCheck = [project?.Commencement_Date, project?.Completion_Date];
      console.log("Dates to Check in Completed Projects:", datesToCheck);

      // Check both commencement and completion dates
      return datesToCheck.some((date) => {
        if (date) {
          const year = Number(date.split("/")[2]); // Assuming date format is YYYY-MM-DD
          console.log("Extracted Year from Completed Project Date:", year);
          return selectedScopes.includes(year);
        }
        return false;
      });
    });

    // Return true if any project category matches the year
    const matches = matchesOngoing || matchesUpcoming || matchesCompleted;
    if (matches) {
      console.log(`Matching row: ${person.Customer_Name}`);
    } else {
      console.log(`Excluded row: ${person.Customer_Name}`);
    }
    return matches;
  });

  console.log("Filtered Data:", filteredData);

  setFilteredFlattenedData(filteredData);
  setFilterContainer(false); // Close the filter dropdown
};



const flattenedData = Object.values(groupedData);


  const handlerefreshsvg = () => {
setfill("false");
setfromdate("");
settodate("");
setcomdate("");
setpodays("");

  }


  return (

    <div className={styles.availabilityOuterContainer}>
      <LoadingScreen isLoading={isLoading} />

      <div className={styles.vishdiv } >
        <div className={styles.drophead}>Filter:</div>
        <div style={{display:"flex", flexDirection:"row", gap:"0px"}}>
          <div>
            <select
              className={styles.dropdown}
              onChange={handlevalChange}
              value={valdesignation}
              id="designation"
            >
              <option value="" className={styles.dd}>
                Select Designation
              </option>
              <option value="SuperVisor" className={styles.dd}>
                Supervisor
              </option>
              <option value="Technician" className={styles.dd}>
              Technician
              </option>
              <option value="Trainee" className={styles.dd}>
              Trainee
              </option>
        
              
            </select>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginLeft: "160px",marginTop:"-95px", cursor: "pointer" }}>
            onClick={() => {
              document.getElementById("designation").click();
            }}
              <path d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z" fill="white"></path>
            </svg>
          </div>
          
    
          <div style={{marginLeft:"20px"}}>
            <select
              className={styles.dropdown}
              onChange={handlevalChange2}
              value={val}
              id="availability"
            >
              <option value="" className={styles.dd}>
                Select Availability
              </option>
              <option value="Available" className={styles.dd}>
                Currently Available
              </option>
              <option value="Engaged" className={styles.dd}>
              Currently Engaged
              </option>
              <option value="DateRange" className={styles.dd}>
                Select Date range
              </option>

            </select>
            <div style={{ position: "relative", display: "inline-block" }} onClick={() => { document.getElementById("availability").click(); }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginLeft: "160px", marginTop: "-95px", cursor: "pointer" }}>
                <path d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z" fill="white"></path>
              </svg>
            </div>
          </div>
          
        </div>
        { (dateselected===true) && (
          <div style={{ display:"flex", flexDirection:"row", height:"40px" ,marginTop:"20px", marginLeft:"5%"}}>
        
            {/* <label htmlFor="fromDate"style={{marginTop:"10px"}}></label> */}
            {/* <input 
                type="date" 
                id="fromDate" 
                value={fromdate} 
                onChange={handlefromdate} 
                placeholder="Commencement Date"
                style={{ marginLeft: "10px", border: "2px solid #5B6BE1", borderRadius: "4px" }} 
              />
            */}
             <LocalizationProvider dateAdapter={AdapterDayjs}>
             <CustomDatePicker
             name="fromDate"
             sx={{
              width: "170px",
              height:"40px" ,
              borderRadius: "4px",

            }}
            value={fromdate? dayjs(fromdate, "YYYY-MM-DD")
            : null}
            onChange={(value)=>handlefromdate(value)}
            slotProps={{ textField: { size: "small" } }}
             format="YYYY-MM-DD"
            label="From"
      />
    </LocalizationProvider>
              <input type="text" id="toDate" placeholder="PO Days" value={todate} onChange={handletodate} style={{ marginLeft: "10px", border: "2px solid #cfd5f3", borderRadius: "8px", width:'100px' }} />
              {(val !== "Open Final Invoice" && val !== "Open Final Report" ) && (           <svg
                      onClick ={handledatefil}
                      className={styles.btnf}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                        fill="#BCBCBC"
                      />
                      <path
                        d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
                        fill="#BCBCBC"
                      />
                    </svg>)}
            {(val !== "Open Final Invoice" && val !== "Open Final Report" ) && (<svg  style={{marginLeft:"15px", marginTop:"5px"}} onClick ={handlerefreshsvg} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z"/></svg>)}
          </div>
        )}
        <div style={{display:"flex", flexDirection:"row", gap:"50px", justifyContent: "flex-start", marginLeft:"auto"}}>
          <div className={styles.searchContainer} style={{  position: "relative"  }}>
            <input
              type="text"
              placeholder="Search by name"
              value={searchQuery}
              onChange={handleSearchName}
              className={styles.searchBox}
            />
            <svg
              className={styles.searchIconFilter}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                fill="#BCBCBC"
              />
              <path
                d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
                fill="#BCBCBC"
              />
            </svg>
          </div>

        </div>
        <div className={styles.dwn} style={{marginLeft:"auto"}}>
          
          {/* <button onClick={handledwn3} className={styles.excelbtn}>
            Download 
          </button> */}
            {<svg width="40" height="30"  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handledwn3}  className={styles.btnfilter3} >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 5.28595 -1.19209e-07 2.92893 1.46447 1.46447C2.92893 -1.19209e-07 5.28595 0 10 0C14.714 0 17.0711 -1.19209e-07 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C-1.19209e-07 17.0711 0 14.714 0 10ZM10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V10.1893L12.4697 8.4697C12.7626 8.1768 13.2374 8.1768 13.5303 8.4697C13.8232 8.7626 13.8232 9.2374 13.5303 9.5303L10.5303 12.5303C10.3897 12.671 10.1989 12.75 10 12.75C9.8011 12.75 9.6103 12.671 9.4697 12.5303L6.46967 9.5303C6.17678 9.2374 6.17678 8.7626 6.46967 8.4697C6.76256 8.1768 7.23744 8.1768 7.53033 8.4697L9.25 10.1893V5C9.25 4.58579 9.5858 4.25 10 4.25ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75H14C14.4142 15.75 14.75 15.4142 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H6Z" fill="#5B6BE1"/>
             </svg>}
        </div>
      </div>

      <div className={styles.availabilityTableContainer}>

        
       
      <form id="formm">
      <div style={{ maxWidth: "97%", marginLeft: "1.5%" }}>
        <table className={styles.rigtable}>
          <thead>
            <tr>
              <th>S. No</th>
              <th>Techs</th>
              <th>Designation</th>
              <th>Ongoing Projects</th>
              <th>Upcoming Projects</th>
              <th>Completed Projects</th>
            </tr>
          </thead>
          <tbody>
            {filteredFlattenedData.map((person, index) => {
              // Find completed projects for the current customer
              const completedProjects = completedProjectsByTech[person.Customer_Name]?.map(
                (project) => {
                  const rigName = project.Rig_Name;                                
                  const commencementDate = project.Commencement_Date || '-';
                  const completionDate = project.Completion_Date || '-';

                  return `${rigName} - ${commencementDate} - ${completionDate}`;
                }
              ); 

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{person.Customer_Name}</td>
                  <td>{person.Designation}</td>
                  <td>
                    {person.OngoingProjects?.length > 0 ? (
                      <React.Fragment>
                        {person.OngoingProjects.map((project, idx) => (
                          <React.Fragment key={idx}>
                            {idx > 0 && ', '}
                            {project}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {person.UpcomingProjects?.length > 0 ? (
                      <React.Fragment>
                        {person.UpcomingProjects.map((project, idx) => (
                          <React.Fragment key={idx}>
                            {idx > 0 && ', '}
                            {project}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {completedProjects?.length > 0 ? (
                      completedProjects.map((project, idx) => {
                        const [rigName, commencementDate, completionDate] = project.split(' - ');
                        return (
                          <React.Fragment key={idx}>
                            {idx > 0 && ', '}
                            <NavLink
                              to={`/project_checklist/${completedProjectsByTech[person.Customer_Name][idx]?.ID_No}`}
                              style={{ textDecoration: 'none', color: 'blue' }}
                            >
                              {rigName}
                            </NavLink>{' '}
                            - {commencementDate} - {completionDate}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>

        </table>
      </div>
    </form>
       
       
      </div>

    </div>
    
  );
}

export default Availability;
