import React, { useState, useEffect } from "react";
import styles from "../Components/css/customerfeedback.module.css";
import axios from "axios";
import { useParams, NavLink, useNavigate } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Customerfeedback() {
  const { id } = useParams();
  const [editclicked, seteditclicked] = useState(false);
  const [tempCustomerFeedback, setTempCustomerFeedback] = useState();
  const [tempServiceExecutionFeedback, setTempServiceExecutionFeedback] =
    useState("");
  const [customerfeedback, setcustomerfeedback] = useState("");
  const [serviceexecutionfeedback, setserviceexecutionfeedback] = useState("");
  const [indetails, setindetails] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [removeCustomerFeedback, setRemoveCustomerFeedback] = useState(false);
  const [removeServiceExecutionFeedback, setRemoveServiceExecutionFeedback] =
    useState(false);
  const [fetchFlag, setFetchFlag] = useState(true);
  const [refresh, setrefresh] = useState(false);

  const handleFileChange = (name, event) => {
    console.log("//////////////////////////////////////////////*******");
    const temp = event.target.files[0];
    console.log(temp);
    // setDocuments((prevDocuments) => {
    //   const updatedDocuments = [...prevDocuments];
    //   updatedDocuments[index].file = temp;
    //   console.log(updatedDocuments);
    //   return updatedDocuments;
    // });

    setindetails((prevFileInputs) => {
      return prevFileInputs.map((fileInput) => {
        if (fileInput.field === name) {
          fileInput.type = name;
          fileInput.file = temp;
          return { ...fileInput, temp };
        }
        return fileInput;
      });
    });
    const temp2 = [...indetails];
    // temp2[name].file = temp;
    temp2.forEach((doc) => {
      if (event.target.name === "Customer_Feedback") {
        doc.type = "Customer_Feedback";
        doc.file = temp;
        setTempCustomerFeedback(temp.name);
        setcustomerfeedback(temp);
      }
      if (event.target.name === "Service_Execution_Feedback") {
        doc.type = "Service_Execution_Feedback";
        doc.file = temp;
        setTempServiceExecutionFeedback(temp.name);
        setserviceexecutionfeedback(temp);
      }
    });

    setindetails(temp2);
    console.log(temp2);
  };

  const handleRemoveDocument = async (index) => {
    // const typeToRemove = indetails[index]?.type;

    // Update API with null for the corresponding file field
    // const nullValue = { [typeToRemove]: null };
    // const res = await axios.put(
    //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatemanualdocs/${id}`,
    //   nullValue,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );

    // // Handle API response if needed

    // // Remove the document from state
    // setDocuments((previndetails) => {
    //   const updatedDocuments = [...previndetails];
    //   updatedDocuments.splice(index, 1);
    //   return updatedDocuments;
    // });
    const updatedDocuments = [...documents];

    if (index === "CustomerFeedback") {
      setTempCustomerFeedback("");
      updatedDocuments[0].Customer_Feedback = "";
      setRemoveCustomerFeedback(true);
    } else if (index === "ServiceExecutionFeedback") {
      setTempServiceExecutionFeedback("");
      updatedDocuments[0].Service_Execution_Feedback = "";
      setRemoveServiceExecutionFeedback(true);



      setDocuments(updatedDocuments);
    };
  };

  console.log(handleRemoveDocument);

  const handleSave = async (e) => {
    e.preventDefault();
    let flag = false;

    const removeConditions = [
      {
        remove: removeCustomerFeedback,
        temp: tempCustomerFeedback,
        type: "Customer_Feedback",
      },
      {
        remove: removeServiceExecutionFeedback,
        temp: tempServiceExecutionFeedback,
        type: "Service_Execution_Feedback",
      },
    ];

    for (const condition of removeConditions) {
      if (condition.remove && condition.temp === "") {
        flag = true;
        const payload = { type: condition.type };
        await axios.put(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/removemanualdocs/${id}`,
          payload
        );
      }
    }

    const payloadFiles = {
      Customer_Feedback: customerfeedback,
      Service_Execution_Feedback: serviceexecutionfeedback,
    };

    if (!flag) {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/updatemanualdocs/${id}`,
        payloadFiles,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(res.data);
      setFetchFlag(true);
    }

    setRemoveCustomerFeedback(false);
    setRemoveServiceExecutionFeedback(false);

    seteditclicked(false);
  };

  useEffect(() => {
    const fetchDocuments = () => {
      axios
        .get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getmanualdocs/${id}`)
        .then((res) => {
          const {
            Team_Selection,
            SQP,
            Risk_Assessment,
            Task_Briefing,
            Customer_Feedback,
            Service_Execution_Feedback,
          } = res.data;

          console.log("******************", res.data);
          console.log("////////", res.data.data[0]);
          setindetails(res.data.data);
          setDocuments(res.data.data);

          console.log(indetails);
          console.log(documents);
          // setDocuments({
          //   Team_Selection: Team_Selection,
          //   SQP: SQP,
          //   Risk_Assessment: Risk_Assessment,
          //   Task_Briefing: Task_Briefing,
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (fetchFlag) {
      fetchDocuments();
      setFetchFlag(false);
    }
  }, [fetchFlag]);

  const handledownloadfile = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = "";
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  }

  const handleviewfile = (documents) => {

  };

  return (
    <div>
      <div>
        {editclicked === false && (
          <button
            onClick={() => seteditclicked(true)}
            className={styles.savebtncustomerfeedback}
            style={{ marginLeft: "65%" }}>
            Edit
          </button>
        )}
        {editclicked && (
          <div>
            <button
              className={styles.cancelbtn}
              style={{ marginLeft: "65%" }}
              onClick={() => seteditclicked(false)}>
              Cancel
            </button>
            <button
              className={styles.savebtncustomerfeedback}
              onClick={handleSave}>
              Save
            </button>
          </div>
        )}

        <div>
          {indetails.map((doc, index) => (
            <div key={doc.index}>
              <label
                style={{
                  marginLeft: "20px",
                  marginTop: "25px",
                  marginRight: "20px",
                }}>
                Customer Feedback
              </label>
              {editclicked ? (
                <div style={{ display: "inline-flex" }}>
                  <input
                    type="file"
                    name="Customer_Feedback"
                    id="Customer_Feedback"
                    onChange={(e) => handleFileChange("Customer_Feedback", e)}
                    // value={doc?.SQP ? getFileNameFromURL(doc?.SQP) : "Upload File"}
                    style={{
                      display: "none",
                      border: "1px solid rgb(231, 235, 235)",
                      width: "200px",
                      borderRadius: "8px",
                      height: "35px",
                      alignItems: "center",
                      marginLeft: "127px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid lightgray",
                      width: "200px",
                      borderRadius: "8px",
                      height: "35px",
                      alignItems: "center",
                      marginLeft: "147px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "300px",
                      fontSize: "14px"
                    }}
                    onClick={() =>
                      document.getElementById("Customer_Feedback").click()}>
                    {documents[0]?.Customer_Feedback
                      ? documents[0]?.Customer_Feedback?.toString()
                        .split("/")
                        .pop()
                        .split("-")
                        .slice(1)
                        .join("-")
                      : tempCustomerFeedback
                        ? tempCustomerFeedback
                        : "Upload File"}
                  </div>
                  <svg
                    width="20"
                    onClick={() => handleRemoveDocument("CustomerFeedback")}
                    style={{
                      marginTop: "7px",
                      marginLeft: "25px",
                      cursor: "pointer",
                    }}
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="black"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="black"
                    />
                  </svg>
                </div>
              ) : (
                <div style={{ display: "inline-flex" }}>
                  <input
                    type="text"
                    name="Customer_Feedback"
                    value={documents[0]?.Customer_Feedback?.toString()
                      .split("/")
                      .pop()
                      .split("-")
                      .slice(1)
                      .join("-")}
                    style={{
                      border: "1px solid lightgray",
                      width: "200px",
                      borderRadius: "8px",
                      height: "35px",
                      alignItems: "center",
                      marginLeft: "147px",
                      background: "whitesmoke",
                      color: "gray",
                      pointerEvents: "none",
                      width: "300px",
                      fontSize: "14px"
                    }}
                  />
                  <div
                    onClick={() => handleviewfile(doc?.Customer_Feedback)}>
                    {/* <object data="http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/uploads/1702321059617-dosplan_-_2023-12-06T140623.005.pdf" type="application/pdf" width="500px" height="500px">
   
   </object> */}
                    <a href={doc?.Customer_Feedback} target="_blank">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          cursor: "pointer",
                          marginTop: "3px",
                          marginLeft: "30px",
                        }}>
                        <path
                          d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                          fill="black"></path>
                        <path
                          d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                          fill="black"></path>
                      </svg>
                    </a>
                  </div>
                  <div
                    style={{
                      border: "none",
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginLeft: "8px",
                    }}
                    onClick={() => handledownloadfile(doc?.Customer_Feedback)}
                  >
                    <svg
                      style={{ marginTop: "5px", marginLeft: "5px" }}
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                        fill="#292D32"
                      />
                    </svg>
                  </div>
                </div>
              )}
              <br />
              <br />

              <label
                style={{
                  marginLeft: "20px",
                  marginTop: "12px",
                  marginRight: "20px",
                }}>
                Service Execution Feedback
              </label>
              {editclicked ? (
                <div style={{ display: "inline-flex" }}>
                  <input
                    type="file"
                    name="Service_Execution_Feedback"
                    id="Service_Execution_Feedback"
                    onChange={(e) => handleFileChange("Service_Execution_Feedback", e)}
                    // value={doc?.SQP ? getFileNameFromURL(doc?.SQP) : "Upload File"}
                    style={{
                      display: "none",
                      border: "1px solid rgb(231, 235, 235)",
                      width: "200px",
                      borderRadius: "8px",
                      height: "35px",
                      alignItems: "center",
                      marginLeft: "127px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid lightgray",
                      width: "200px",
                      borderRadius: "8px",
                      height: "35px",
                      alignItems: "center",
                      marginLeft: "87px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "300px",
                      fontSize: "14px"
                    }}
                    onClick={() =>
                      document.getElementById("Service_Execution_Feedback").click()
                    }>
                    {documents[0]?.Service_Execution_Feedback
                      ? documents[0]?.Service_Execution_Feedback?.toString()
                        .split("/")
                        .pop()
                        .split("-")
                        .slice(1)
                        .join("-")
                      : tempServiceExecutionFeedback
                        ? tempServiceExecutionFeedback
                        : "Upload File"}
                  </div>
                  <svg
                    width="20"
                    onClick={() => handleRemoveDocument("ServiceExecutionFeedback")}
                    style={{
                      marginTop: "7px",
                      marginLeft: "25px",
                      cursor: "pointer",
                    }}
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                      fill="black"
                    />
                    <path
                      d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                      fill="black"
                    />
                  </svg>
                </div>
              ) : (
                <div style={{ display: "inline-flex" }}>
                  <input
                    type="text"
                    name="Service_Execution_Feedback"
                    value={documents[0]?.Service_Execution_Feedback?.toString()
                      .split("/")
                      .pop()
                      .split("-")
                      .slice(1)
                      .join("-")}
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "8px",
                      height: "35px",
                      alignItems: "center",
                      marginLeft: "87px",
                      background: "whitesmoke",
                      color: "gray",
                      pointerEvents: "none",
                      width: "300px",
                      fontSize: "14px"
                    }}
                  />
                  <div
                    onClick={() => handleviewfile(doc?.Service_Execution_Feedback)}
                  >
                    {/* <object data="http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/uploads/1702321059617-dosplan_-_2023-12-06T140623.005.pdf" type="application/pdf" width="500px" height="500px">
   
   </object> */}
                    <a href={doc?.Service_Execution_Feedback} target="_blank">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          cursor: "pointer",
                          marginTop: "3px",
                          marginLeft: "30px",
                        }}>
                        <path
                          d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z"
                          fill="black"></path>
                        <path
                          d="M11.9984 9.14001C10.4284 9.14001 9.14844 10.42 9.14844 12C9.14844 13.57 10.4284 14.85 11.9984 14.85C13.5684 14.85 14.8584 13.57 14.8584 12C14.8584 10.43 13.5684 9.14001 11.9984 9.14001Z"
                          fill="black"></path>
                      </svg>
                    </a>
                  </div>
                  <div
                    style={{
                      border: "none",
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginLeft: "8px",
                    }}
                    onClick={() => handledownloadfile(doc?.Service_Execution_Feedback)}
                  >
                    <svg
                      style={{ marginTop: "5px", marginLeft: "5px" }}
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                        fill="#292D32"
                      />
                    </svg>
                  </div>
                </div>
              )}
              <br />
              <br />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Customerfeedback;
