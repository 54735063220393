import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Components/css/PurchaseCreateNew.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import axios from "axios";
import Select from "react-select";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function PurchaseCreateNew() {
  const navigate = useNavigate();

  const inputRef = useRef(null);


  const [formdata, setFormData] = useState({
    PONumber: "",
    PODate: "",
    QuoteNo: "",
    QuoteDate: "",
    SupplierName: "",
    LinkedInq: "",
    EarliestArrivalDate: "",
    ExpectedArrivalDate: "",
    PurchaseRequisitionID: "",
    ReasonForPurchase: "",

  });
  const handleCancel = () => {
    navigate(`/Purchases`);
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formdata, [name]: value });
  };

  // date changes
  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("YYYY-MM-DD");
      setFormData({ ...formdata, [name]: selectedDate });
    } else {
      console.log("Invalid Date");
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // const [showSuggestions, setShowSuggestions] = useState(false);
  // useEffect(() => {
  //   document.addEventListener("click", handleDocumentClick);

  //   return () => {
  //     document.removeEventListener("click", handleDocumentClick);
  //   };
  // }, []);

  // const handleDocumentClick = (event) => {
  //   if (inputRef.current && !inputRef.current.contains(event.target)) {
  //     setShowSuggestions(false);
  //   }
  // };

  //   const handleOnChange2 = (e) => {
  //     const inputValue = e.target.value;
  //     const { name, value } = e.target;
  //     setFormData({ ...formdata, [name]: value });

  //     // Filter salesOrderNos based on user input
  // const filteredOptions = salesOrderNos.filter(option =>
  //       option.toLowerCase().includes(inputValue.toLowerCase())
  //     );
  //     setFilteredSalesOrderNos(filteredOptions);
  //     setShowSuggestions(inputValue.trim() !== ''); // Show suggestions only if input is not empty
  //   };

  //   const handleSuggestionSelect = (option) => {
  //     setFormData({ ...formdata, LinkedProject: option });
  //     setShowSuggestions(false); // Hide suggestions when option is selected
  //   };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSave = async (e) => {
    e.preventDefault();
    const {
      PONumber,
      PODate,
      QuoteNo,
      QuoteDate,
      SupplierName,
      EarliestArrivalDate,
      ExpectedArrivalDate,
      PurchaseRequisitionID,
      ReasonForPurchase,
    } = formdata;

    if (!formdata.PONumber) {
      alert("PO Number is required.");
      return; // Exit the function without saving if the date is null
    }

    // Extracting selected SO numbers and joining them with commas
    const LinkedProj = filteredSalesOrderNos
      .map((option) => option.value)
      .join(", ");
      if (!inputName.value) {

        alert('"Request raised by" is required.');
  
        return; // Exit the function without saving if the date is null
  
      }
    const payload = {
      PO_Number: PONumber,
      PO_Date: PODate,
      Quote_No: QuoteNo,
      Quote_Date: QuoteDate,
      Supplier_Name: inputSupplier.value,
      Linked_Inquiry: inputInquiry.value,
      Earliest_Arrival_Date: EarliestArrivalDate,
      Expected_Arrival_Date: ExpectedArrivalDate,
      Linked_Project: LinkedProj,
      Purchase_Requisition_ID: PurchaseRequisitionID,
      Reason_For_Purchase: ReasonForPurchase,
      Request_Raised_By: inputName.value,
     // Add selected SO numbers to payload
    };

    try {
      const res = await axios.post(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchasenew_post`,
        payload
      );

      if (res.status === 200) {
        alert("Purchase done successfully");
        navigate(`/Purchases`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#b2b3b5",
    },
    "& .MuiOutlinedInput-root": {
      width: "350px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #b2b3b5",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #b2b3b5",
          borderRadius: "6px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #394759",
          borderRadius: "6px",
        },
      },
    },
  }));

  const [inputValue, setInputValue] = useState("");
  const [salesOrderNos, setSalesOrderNos] = useState([]);
  const [filteredSalesOrderNos, setFilteredSalesOrderNos] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [supplierNames, setSupplierNames] = useState([]);
  const [inquiryNos, setInquiryNos] = useState([]);
  const [inputInquiry, setInputInquiry] = useState([]);
  const [inputSupplier, setInputSupplier] = useState([]);
  const [inputName, setInputName] = useState("");
  const names = ["Ajhar", "Haneef", "Basil", "Irfan Sheikh", "Sandhya", "Ramadas", "Syed", "Nishad", "Arun"];
  const namesOptions = names.map((name) => ({ label: name, value: name }));

  useEffect(() => {
    const fetchSalesOrderNos = async () => {
      try {
        // Fetch data from the first API
        const res1 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details_Sales_Order_No"
        );
        const salesOrderNosArray1 = res1.data.data.map((row) => ({
          label: row.Sales_Order_No,
          value: row.Sales_Order_No,
        }));

        // Fetch data from the second API
        const res2 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_trading_Sales_Order_No"
        );
        const salesOrderNosArray2 = res2.data.data.map((row) => ({
          label: row.Sales_Order_No,
          value: row.Sales_Order_No,
        }));

        // Combine both arrays
        const combinedSalesOrderNos = [...salesOrderNosArray1, ...salesOrderNosArray2];
        
        setSalesOrderNos(combinedSalesOrderNos);

        const res3 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details_Inquiry_No"
        );
        const inquirynos = res3.data.data.map((row) => ({
          label: row.Inquiry_ID,
          value: row.Inquiry_ID,
        }));

        console.log("inquiry nos", inquirynos)
        setInquiryNos(inquirynos);

        const res4 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details_Supplier_Names"
        );
        const suppliernames = res4.data.data.map((row) => ({
          label: row.Supplier_Name,
          value: row.Supplier_Name,
        }));

        console.log("supplier names", suppliernames);
        setSupplierNames(suppliernames);

      } catch (error) {
        console.error("Error fetching Sales Order Numbers:", error);
      }
    };

    fetchSalesOrderNos();
  }, []);

  const handleSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      .map((option) => option.label)
      .join(", ");
    setInputValue(selectedValues);
    setFilteredSalesOrderNos(selectedOptions);
  };
  const handleSelectChange2 = (selectedOption) => {
    setInputName(selectedOption);
  };

  const handleSelectChange3 = (selectedOption) => {
    setInputInquiry(selectedOption);
  };

  const handleSelectChange4 = (selectedOption) => {
    setInputSupplier(selectedOption);
  };

  console.log("input name", inputName);
  return (
    <div className={styles.topdiv}>
      <div className={styles.sections}>
        <div style={{ marginLeft: "35px", marginTop: "15px" }}>
          <div className={styles.formElement}>
            <div style={{ width: "131.91px" }}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                PO Number*:
              </label>
           </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="text"
                value={formdata.PONumber}
                name="PONumber"
                onChange={handleOnChange}
                className={styles.textbox1}
              />
            </div>
          </div>
          {/* PO Date */}
          <div className={`${styles.formElement} ${styles.datepickerInput}`}>
          <div style={{width: "131.91px"}}>

            <label
              style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
            >
              PO Date:
            </label>
          </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
               
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="PODate"
                  value={
                    formdata.PODate
                      ? dayjs(formdata.PODate, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(value) => handleDates("PODate", value)}
                  slotProps={{ textField: { size: "small" } }}
                  format="DD-MM-YYYY"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "6px",
                    boxShadow: "none", // Corrected property name
                    marginLeft: "30px",
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          {/* PO Date Ends */}

          <div className={styles.formElement}>
          <div style={{width: "131.91px"}}>

            <label
              style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
            >
              Quote No:
            </label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
               
              }}
            >
              <input
                type="text"
                value={formdata.QuoteNo}
                name="QuoteNo"
                onChange={handleOnChange}
                className={styles.textbox1}
              />
            </div>
          </div>
          {/* Quote Date */}
          <div className={`${styles.formElement} ${styles.datepickerInput}`}>
            <div style={{width: "131.91px"}}>
            <label
              style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
            >

              Quote Date:

              
            </label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="QuoteDate"
                  value={
                    formdata.QuoteDate
                      ? dayjs(formdata.QuoteDate, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(value) => handleDates("QuoteDate", value)}
                  slotProps={{ textField: { size: "small" } }}
                  format="DD-MM-YYYY"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "6px",
                    boxShadow: "none", // Corrected property name
                    marginLeft: "30px",
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>

          {/* <div className={styles.formElement}>
            <label
              style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
            >
              Purchase
              <div>
              Requisition ID:
              </div>
            </label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                
              }}
            >
              <input
                type="text"
                value={formdata.PurchaseRequisitionID}
                name="PurchaseRequisitionID"
                onChange={handleOnChange}
                className={styles.textbox1}
              />
            </div>
          </div> */}

            <div className={styles.formElement}>
            <div style={{width: "131.91px"}}>

            
            <label
              style={{ color: "#394759", fontWeight: "600", fontSize: "20px" }}
            >
              Reason for 
              <div>
              Purchase:
              </div>
            </label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                
              }}
            >
             
              <textarea
                value={formdata.ReasonForPurchase}
                name="ReasonForPurchase"
                onChange={handleOnChange}
                className={styles.textbox1}
                style={{
                  minHeight: "70px",
                  // Ensure it takes full width available
                  resize: "none", // Optional: prevent resizing
                  padding: "5px", // Adjust padding as needed
                }}
             />
            </div>
          </div>
          {/* Quote Date Ends */}
        </div>




        <div style={{ marginLeft: "7vw", marginTop: "15px" }}>
        <div className={styles.formElement}>
              <div style={{ width: "205px" }}>
                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Supplier Name:
                </label>
              </div>
              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  // isMulti
                  value={inputSupplier}
                  options={supplierNames}
                  onChange={handleSelectChange4}
                  placeholder="Type to search..."
                  className={styles.textbox1z}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      overflowY: "hidden",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the clear indicator
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                  }}
                />
              </div>
            </div>

            <div className={styles.formElement}>
              <div style={{ width: "205px" }}>
                <label
                  style={{
                    color: "#394759",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Linked to Inquiry:
                </label>
              </div>
              <div
                className={styles.inputContainer} // Apply CSS class to the container
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  marginLeft: "1vw",
                }}
              >
                <Select
                  // isMulti
                  value={inputInquiry}
                  options={inquiryNos}
                  onChange={handleSelectChange3}
                  placeholder="Type to search..."
                  className={styles.textbox1z}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      overflowY: "hidden",
                      marginLeft: "-9px",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the clear indicator
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "scroll", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "#c5c5c5 #f5f5f5",
                    }),
                  }}
                />
              </div>
            </div>
          <div className={`${styles.formElement} ${styles.datepickerInput}`}>
            <label
              style={{ color: "#394759", fontWeight: "600", fontSize: "20px", marginLeft: "10px" }}
            >
              Earliest Arrival Date:
            </label>

            <div style={{ display: "flex", alignItems: "center", marginLeft: "6px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="EarliestArrivalDate"
                  value={
                    formdata.EarliestArrivalDate
                      ? dayjs(formdata.EarliestArrivalDate, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(value) =>
                    handleDates("EarliestArrivalDate", value)
                  }
                  slotProps={{ textField: { size: "small" } }}
                  format="DD-MM-YYYY"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "6px",
                    boxShadow: "none", // Corrected property name
                    marginLeft: "50px",
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className={`${styles.formElement} ${styles.datepickerInput}`}>
            <label
              style={{ color: "#394759", fontWeight: "600", fontSize: "20px", marginLeft: "38px" }}
            >
              Last Arrival Date:
            </label>

            <div style={{ display: "flex", alignItems: "center", marginLeft: "7px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="ExpectedArrivalDate"
                  value={
                    formdata.ExpectedArrivalDate
                      ? dayjs(formdata.ExpectedArrivalDate, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(value) =>
                    handleDates("ExpectedArrivalDate", value)
                  }
                  slotProps={{ textField: { size: "small" } }}
                  format="DD-MM-YYYY"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "6px",
                    boxShadow: "none", // Corrected property name
                    marginLeft: "50px",
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          <div className={styles.formElement}>
            <div style={{ width: "205px" }}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "15px",
                }}
              >
                Linked to Project SO:
                <div style={{ fontSize: "17px", fontWeight: "500" }}>
                  {" "}
                  (Can select multiple)
                </div>
              </label>
           </div>
            <div
              className={styles.inputContainer} // Apply CSS class to the container
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                marginLeft: "1vw",
              }}
            >
              <Select
                isMulti
                value={filteredSalesOrderNos}
                options={salesOrderNos}
                onChange={handleSelectChange}
                placeholder="Type to search..."
                className={styles.textbox1z}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    boxShadow: "none",
                    cursor: "pointer",
                    overflowY: "hidden",
                  }),
                  clearIndicator: (provided) => ({
                    ...provided,
                    display: "none", // Hide the clear indicator
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px",
                    overflowY: "scroll", // Enable vertical scrolling
                    scrollbarWidth: "thin",
                    scrollbarColor: "#c5c5c5 #f5f5f5",
                  }),
                }}
              />
            </div>
          </div>

          <div className={styles.formElement}>
            <div style={{ width: "205px" }}>
              <label
                style={{
                  color: "#394759",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "15px",
                }}
              >
                Request raised by*:
              </label>
            </div>
            <div
              className={styles.inputContainer} // Apply CSS class to the container
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                marginLeft: "1vw",
              }}
            >
              <Select
                // isMulti
                value={inputName}
                options={namesOptions}
                onChange={handleSelectChange2}
                placeholder="Type to search..."
                className={styles.textbox1z}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    boxShadow: "none",
                    cursor: "pointer",
                    overflowY: "hidden",
                 }),
                  clearIndicator: (provided) => ({
                    ...provided,
                    display: "none", // Hide the clear indicator
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px",
                    overflowY: "scroll", // Enable vertical scrolling
                    scrollbarWidth: "thin",
                    scrollbarColor: "#c5c5c5 #f5f5f5",
                  }),
                }}
              />
            </div>
          </div>
        </div>
          {/* <div className={styles.formElement}>
            <label
              style={{ color: "#394759", fontWeight: "600", fontSize: "20px" ,marginLeft:"15px"}}
            >
              Linked to
              <div >Project SO:</div>
            </label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                marginLeft:"2.4vw"
              }}
            >
              <input
               ref={inputRef}
                type="text"
                value={formdata.LinkedProject}
                name="LinkedProject"
                onChange={handleOnChange2}
                className={styles.textbox1}
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
                placeholder="Type to search..."
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "grey",
                }}
              >
                ▼
              </span>{" "}
              
            </div>
          
            {showSuggestions && filteredSalesOrderNos.length > 0 && (
            <ul
            style={{
              cursor: "pointer",
              border: "1px solid #e7ebff",
              padding: "0",
              margin: "0",
              position: "absolute ",
              top: "90%",
              left: "189px",
              width: "345px",
              zIndex: "999",
              maxHeight: "200px", // Set a fixed height
               overflowY: "scroll", // Enable vertical scrolling
               scrollbarWidth: "thin",
               scrollbarColor: "#c5c5c5 #f5f5f5",
            }}
          >
            {filteredSalesOrderNos.map((option, index) => ( // Show only first 5 suggestions
              <li
                key={index}
                style={{
                  border: "1px solid #e7ebff",
                  borderTop: "none",
                  padding: "10px",
                }}
                onClick={() => handleSuggestionSelect(option)}
              >
                {option}
              </li>
            ))}
          </ul>
            )}
          </div> */}

          {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          </div>

        <div
          style={{
            display: "flex",
            // position: "absolute",
            // bottom: "15px",
            alignItems: "self-end",
            justifyContent: "space-between",
            width: "95%",
            margin: "10px"
          }}
        >
          <div>
            <h4 className={styles.heading}>
              Note: You will be able to enter item details after purchase is
              created.
            </h4>
          </div>

          <div>
            <div className={styles.editAndSaveButtons}>
              <>
                <button
                  variant="contained"
                  onClick={handleSave}
                  className={styles.saveButton}
                >
                  Create Purchase
                </button>

                <button
                  variant="outlined"
                  onClick={handleCancel}
                  className={styles.cancelBtn}
                >
                  Cancel
                </button>
              </>
            </div>
          </div>
        </div>
      
    </div>
  );
}

export default PurchaseCreateNew;
