import { useEffect, useState, useRef } from "react";
import styles from "../Components/css/dailytracker.module.css";
import styles1 from "../Components/css/UniversalIncomingDeliveries.module.css";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { IoMdRefresh } from "react-icons/io";
import DailyTrackerWorkshop from "./DailyTrackerWorkshop";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  TimeScale,
  TimeSeriesScale,
  Tooltip as ChartJSTooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
// Registering the components used in the chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  TimeScale, // Make sure to register TimeScale if using time on any axis
  TimeSeriesScale,
  ChartJSTooltip
);
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default function DailyTracker() {

  const [stats, setstats] = useState("Ongoing");
  const [searchTerm, setSearchTerm] = useState("");
  const [onsearchTerm, setonSearchTerm] = useState("");
  const [researchTerm, setreSearchTerm] = useState("");
  const [clsearchTerm, setclSearchTerm] = useState("");
  const [projectdetails, setprojectdetails] = useState([]);
  const [checklistdata, setchecklistdata] = useState([]);
  const [upcomingdetails, setupcomingdetails] = useState([]);
  const [upcomingdetailsfil, setupcomingdetailsfil] = useState([]);
  const [ongoingdetails, setongoingdetails] = useState([]);
  const [ongoingdetailsfil, setongoingdetailsfil] = useState([]);
  const [combinedResults, setCombinedResults] = useState([]);
  
  const [recentlydetails, setrecentlydetails] = useState([]);
  const [recentlydetailsfil, setrecentlydetailsfil] = useState([]);
  const [closeddetails, setcloseddetails] = useState([]);
  const [closeddetailsfil, setcloseddetailsfil] = useState([]);
  const [smresults, setsmresults] = useState([]);
  const [filterItems, setFilterItems] = useState({
  });
  const [forwarddetails, setforwarddetails] = useState([]);
  const [profdetails, setprofdetails] = useState([]);
  const [fill, setfill] = useState(false);
  const [smresults2, setsmresults2] = useState([]);
  const [smresults3, setsmresults3] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [dailyTracker, setDailyTracker] = useState(1);
  const [hoverid, sethoverid] = useState(-1);
  const [hoverid1, sethoverid1] = useState(-1);
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const yearNames = ['2023', '2024', '2025', '2026', '2027']
  const currentYear = new Date().getFullYear();
  // const startYear = currentYear-3;
  // const yearsArray = Array.from({ length: currentYear - startYear + 1 }, (v, i) => startYear + i);

  const presentdate = new Date();
  const currentMonth = presentdate.getMonth();
  const [filmonval, setfilmonval] = useState([]);
  const [filyearval, setfilyearval] = useState([]);
  const [filterContainer, setFilterContainer] = useState(false);
  const [ongoingChartView, setOngoingChartView] = useState(false); //to control the chart view when ongoing project status is selected
  const tableRef = useRef(null);
  const tableHeaderRef = useRef(null);
  const [ongoingChartHeaderHeight, setOngoingChartHeaderHeight] = useState('auto');
  const [divHeight, setDivHeight] = useState('auto'); // to store the height of the table, to be used for setting the chart height equal to the table height in chart view
  const [divWidth, setDivWidth] = useState('0'); // to store the width of the table, to be used for setting the chart's left-margin
  const [previousMonthName, setPreviousMonthName] = useState('');
  const [currentMonthName, setCurrentMonthName] = useState('');
  const [nextMonthName, setNextMonthName] = useState('');
  const [nextMonthName2, setNextMonthName2] = useState('');
  const [nextMonthName3, setNextMonthName3] = useState('');
  const [nextMonthName4, setNextMonthName4] = useState('');

  // useEffect(() => {
  //   if (tableRef.current) {
  //     setDivHeight(`${tableRef.current.offsetHeight}px`);
  //   }
  //   console.log("tableRef", tableRef);
  //   const resizeObserver = new ResizeObserver(entries => {
  //     console.log(entries); // Log ResizeObserver entries for debugging
  //     for (let entry of entries) {
  //       if (entry.target === tableRef.current) {
  //         console.log('Table height changed:', entry.contentRect.height);
  //         setDivHeight(`${entry.contentRect.height}px`);
  //       }
  //     }
  //   });

  //   if (tableRef.current) {
  //     resizeObserver.observe(tableRef.current);
  //   }

  //   return () => {
  //     if (tableRef.current) {
  //       resizeObserver.unobserve(tableRef.current);
  //     }
  //   };
  // }, [ongoingdetailsfil, tableRef.current]);

  useEffect(() => {
    const checkRefAvailability = setInterval(() => {
      let updated = false;
      if (tableRef.current) {
        clearInterval(checkRefAvailability);
        setDivHeight(`${tableRef.current.offsetHeight - 13}px`);
        setDivWidth(`${tableRef.current.offsetWidth + 5}px`);
        updated = true;
      }
      if (tableHeaderRef.current) {
        setOngoingChartHeaderHeight(`${tableHeaderRef.current.offsetHeight - 11}px`);
        updated = true;
      }
      if (updated) clearInterval(checkRefAvailability);
    }, 100);

    console.log("tableRef", tableRef);
    console.log("tableHeaderRef", tableHeaderRef);

    const resizeObserver = new ResizeObserver(entries => {
      console.log(entries); // Log ResizeObserver entries for debugging
      for (let entry of entries) {
        if (entry.target === tableRef.current) {
          console.log('Table height changed:', entry.contentRect.height);
          setDivHeight(`${entry.contentRect.height - 13}px`);
          setDivWidth(`${entry.contentRect.width + 5}px`);
        }
        if (entry.target === tableHeaderRef.current) {
          console.log('Table header height changed:', entry.contentRect.height);
          setOngoingChartHeaderHeight(`${entry.contentRect.height - 11}px`);
        }
      }
    });

    if (tableRef.current) {
      resizeObserver.observe(tableRef.current);
    }
    if (tableHeaderRef.current) {
      resizeObserver.observe(tableHeaderRef.current);
    }

    return () => {
      if (tableRef.current) {
        resizeObserver.unobserve(tableRef.current);
      }
      if (tableHeaderRef.current) {
        resizeObserver.unobserve(tableHeaderRef.current);
      }
    };
  }, [ongoingdetailsfil, tableRef.current, tableHeaderRef.current]);

  const handleTableViewClick = () => {
    setOngoingChartView(false);
    setstats("Ongoing");
  }
  const handleChartViewClick = () => {
    setOngoingChartView(true);
    setstats("Ongoing&Upcoming");
  }

  const currentDate = new Date(); // Current date
  const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // First day of the current month
  const firstDayOfPreviousMonth = new Date(firstDayOfCurrentMonth.getFullYear(), firstDayOfCurrentMonth.getMonth() - 1, 1); // First day of the previous month
  const lastDayOfCurrentMonth = new Date(firstDayOfCurrentMonth.getFullYear(), firstDayOfCurrentMonth.getMonth() + 1, 0); // Last day of the current month
  const lastDayOfNextMonth = new Date(lastDayOfCurrentMonth.getFullYear(), lastDayOfCurrentMonth.getMonth() + 2, 0); // Last day of the next month
  const lastDayOfNextMonth2 = new Date(lastDayOfCurrentMonth.getFullYear(), lastDayOfCurrentMonth.getMonth() + 3, 0);
  const lastDayOfNextMonth3 = new Date(lastDayOfCurrentMonth.getFullYear(), lastDayOfCurrentMonth.getMonth() + 4, 0);
  const lastDayOfNextMonth4 = new Date(lastDayOfCurrentMonth.getFullYear(), lastDayOfCurrentMonth.getMonth() + 5, 0);

  // Helper function to format date to month name
  const getMonthName = (date) => {
    return new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
  }
  const getYear = (date) => {
    return new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);
  }

  useEffect(() => {
    // Setting month names using state setters
    setPreviousMonthName(getMonthName(firstDayOfPreviousMonth));
    setCurrentMonthName(getMonthName(firstDayOfCurrentMonth));
    setNextMonthName(getMonthName(lastDayOfNextMonth));
    setNextMonthName2(getMonthName(lastDayOfNextMonth2));
    setNextMonthName3(getMonthName(lastDayOfNextMonth3));
    setNextMonthName4(getMonthName(lastDayOfNextMonth4));
  }, []);

  const prepareOngoingChartData = () => {
    const currentDate = new Date(); // Ensures consistent current date

    // Create two separate datasets from the start
    const datasetBlue = {
      label: 'Planned Duration',
      data: [],
      backgroundColor: '#5B6BE1',
      barThickness: 50,  // Set the thickness of each bar
    };

    const datasetGreen = {
      label: 'Actual/Extended Duration',
      data: [],
      backgroundColor: '#FAA61A',
      barThickness: 50,  // Set the thickness of each bar
    };

    const datasetGreen2 = {
      label: 'Upcoming Duration',
      data: [],
      backgroundColor: '#4CAF50', // Green color for upcoming projects
      barThickness: 50,
    };
    
    // Separate ongoing and upcoming projects
  const ongoingProjects = combinedResults.filter((el) => el.Status === "Ongoing");
  const upcomingProjects = combinedResults.filter((el) => el.Status === "Upcoming");

  let yIndex = 0;
  
    console.log("COMBINE",combinedResults)
    ongoingProjects.forEach((el, index) => {
      const startDateParts = el.Estimated_Date_Of_Commencement.split("/");
      const startDate = new Date(startDateParts[2], startDateParts[1] - 1, startDateParts[0]).getTime();
      let endDateParts = el.Estimated_Project_Completion_Month.split("/");
      let endDate;

      if (el.Estimated_Project_Completion_Month && endDateParts.length === 3 && endDateParts[0] && endDateParts[1] && endDateParts[2]) {
        endDate = new Date(endDateParts[2], endDateParts[1] - 1, endDateParts[0]).getTime();
      } else {
        endDate = currentDate.getTime(); // Default to current date if endDate is not valid
      }

      // Use el.Po_Days to determine split in duration
      
      if (el.Po_Days) {
        const poDate = new Date(startDate);
        poDate.setDate(poDate.getDate() + parseInt(el.Po_Days));
        const poTime = poDate.getTime();

        if (endDate > poTime) {
          datasetBlue.data.push({ x: [startDate, poTime], y: yIndex });
          datasetGreen.data.push({ x: [poTime, endDate], y: yIndex });
          datasetGreen2.data.push({ x: [poTime, poTime], y: yIndex });
        } else {
          datasetBlue.data.push({ x: [startDate, poTime], y: yIndex });
          // Ensuring there is an entry for each dataset, even if not displayed
          datasetGreen.data.push({ x: [poTime, poTime], y: yIndex }); // Extremely short duration
          datasetGreen2.data.push({ x: [poTime, poTime], y: yIndex });
        }
      } else {
        if (endDate === currentDate.getTime()) {
          datasetGreen.data.push({ x: [startDate, endDate], y: yIndex });
          // Ensuring there is an entry for each dataset, even if not displayed
          datasetBlue.data.push({ x: [startDate, startDate], y: yIndex }); // Extremely short duration
          datasetGreen2.data.push({ x: [startDate, startDate], y: yIndex });
        } else {
          datasetBlue.data.push({ x: [startDate, endDate], y: yIndex });
          // Ensuring there is an entry for each dataset, even if not displayed
          datasetGreen.data.push({ x: [endDate, endDate], y: yIndex }); // Extremely short duration
          datasetGreen2.data.push({ x: [startDate, startDate], y: yIndex });
        }
      }
      yIndex++;
    });

    upcomingProjects.forEach((el, index) => {
      const startDateParts = el.Estimated_Date_Of_Commencement.split("/");
      const startDate = new Date(startDateParts[2], startDateParts[1] - 1, startDateParts[0]).getTime();
      let endDateParts = el.Estimated_Project_Completion_Month.split("/");
      let endDate;

      if (el.Estimated_Project_Completion_Month && endDateParts.length === 3 && endDateParts[0] && endDateParts[1] && endDateParts[2]) {
        endDate = new Date(endDateParts[2], endDateParts[1] - 1, endDateParts[0]).getTime();
      } else {
        endDate = currentDate.getTime(); // Default to current date if endDate is not valid
      }

      // Use el.Po_Days to determine split in duration
      
      if (el.Po_Days) {
        const poDate = new Date(startDate);
        poDate.setDate(poDate.getDate() + parseInt(el.Po_Days));
        const poTime = poDate.getTime();

        if (endDate > poTime) {
          datasetBlue.data.push({ x: [startDate, startDate], y: yIndex });
      datasetGreen.data.push({ x: [poTime, poTime], y: yIndex });

      // Use datasetGreen2 for upcoming projects
      datasetGreen2.data.push({ x: [startDate, endDate], y: yIndex });
        } else {
          datasetBlue.data.push({ x: [startDate, startDate], y: yIndex });
      datasetGreen.data.push({ x: [poTime, poTime], y: yIndex });

      // Use datasetGreen2 for upcoming projects
      datasetGreen2.data.push({ x: [startDate, endDate], y: yIndex });
        }
      } else {
        if (endDate === currentDate.getTime()) {
          datasetBlue.data.push({ x: [startDate, startDate], y: yIndex });
      datasetGreen.data.push({ x: [endDate, endDate], y: yIndex });

      datasetGreen2.data.push({ x: [startDate, endDate], y: yIndex });
        } else {
          datasetBlue.data.push({ x: [startDate, startDate], y: yIndex });
      datasetGreen.data.push({ x: [endDate, endDate], y: yIndex });

      datasetGreen2.data.push({ x: [startDate, endDate], y: yIndex });
        }
      }
      yIndex++;
    });
    console.log("datasetBlue, datasetGreen, datasetGreen2", datasetBlue, datasetGreen, datasetGreen2);

    return {
      labels: combinedResults.map((el, index) => `${index + 1}. ${el.Rig_Name || 'Unknown Rig'}`),
      datasets: [datasetBlue, datasetGreen, datasetGreen2]
    };
  };

  // custom positioner function
  ChartJSTooltip.positioners.followMousePosition = function (elements, eventPosition) {
    // `this` references the tooltip object
    const tooltip = this;

    // Adjusting the tooltip's x and y coordinates to follow the cursor
    return {
      x: eventPosition.x,
      y: eventPosition.y
    };
  };

  const openFilterContainer = (e) => {
    e.preventDefault();
    setFilterContainer(!filterContainer);
    setFilterItems({
      year: false,
      month: false,

    });
  };

  const handleFilterItemsClick = (e) => {
    const name = e.currentTarget.getAttribute("name");
    console.log("ans", name)

    const updatedFilterItems = {};
    for (const key in filterItems) {
      console.log('key', key)
      updatedFilterItems[key] = key === name;
    }

    setFilterItems(updatedFilterItems);
    console.log("updateFilterItems", updatedFilterItems);
  };

  console.log("updateFilterItems", filterItems);

  const ongoingChartOptions = {
    responsive: true, // Make sure the chart is responsive
    maintainAspectRatio: false, // Allow non-proportional scaling
    indexAxis: 'y',
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'dd MMM'
        },
        min: firstDayOfPreviousMonth.getTime(),
        max: lastDayOfNextMonth4.getTime(),
        stacked: false,
        title: {
          display: true,
          text: 'Date'
        },
        grid: {
          drawOnChartArea: true, // Allow custom grid lines
          color: (context) => {
            const date = new Date(context.tick.value);
            // Check if this is the first day of a month
            return date.getDate() === 1 ? '#000000' : '#e0e0e0';
          },
          lineWidth: (context) => {
            const date = new Date(context.tick.value);
            // Make the first day of each month bolder
            return date.getDate() === 1 ? 2 : 0.5;
          }
        },
        ticks: {
          autoSkip: false, // Prevent Chart.js from skipping labels
          maxRotation: 90, // Optional: Adjust based on space available
          minRotation: 20, // Optional: Adjust based on space available
          callback: function (value, index, values) {
            // Format the ticks label if needed
            return new Date(value).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            }); // 'en-GB' locale ensures 'dd MMM yyyy' format
          }
        }
      },
      // x2: {  // Duplicate x-axis configuration for top axis
      //   type: 'time',
      //   time: {
      //     unit: 'day',
      //     tooltipFormat: 'dd MMM'
      //   },
      //   min: firstDayOfPreviousMonth.getTime(),
      //   max: lastDayOfNextMonth.getTime(),
      //   title: {
      //     display: false,
      //     text: 'Date'
      //   },
      //   ticks: {
      //     autoSkip: false,
      //     maxRotation: 50,
      //     minRotation: 20,
      //     callback: function(value, index, values) {
      //       return new Date(value).toLocaleDateString('en-GB', {
      //         day: '2-digit',
      //         month: 'short',
      //         year: 'numeric'
      //       });
      //     }
      //   },
      //   position: 'top', // Position the x-axis at the top
      //   grid: { display: false } // Hide the grid for the top x-axis
      // },  
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          autoSkip: false // Ensure all y-axis labels are shown
        }
      }
    },
    plugins: {
      tooltip: {
        mode: 'y',
        // position: 'nearest',
        position: 'followMousePosition', // custom positioner
        callbacks: {
          title: function (tooltipItems) {
            return ''; // No title needed, return empty
          },
          label: function (tooltipItem) {
            let datasetIndex = tooltipItem.datasetIndex;
            let startDate = new Date(tooltipItem.raw.x[0]);
            let endDate = new Date(tooltipItem.raw.x[1]);
            let startFormatted = startDate.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            });
            let endFormatted = endDate.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            });

            const today = new Date();

            if (today > startDate) {
              // For ongoing projects: display datasetIndex 0 and 1 (blue and green)
              if (datasetIndex === 0) {
                return `Mob Date: ${startFormatted}\n Tentative Demob Date: ${endFormatted}`;
              } else if (datasetIndex === 1) {
                return `Tentative Demob: ${startFormatted}\n Actual Demob Date: ${endFormatted}`;
              }
            } else {
              // For upcoming projects: display datasetIndex 2 (green2)
              if (datasetIndex === 2) {
                return `Mob Date: ${startFormatted}\n Actual Demob Date: ${endFormatted}`;
              }
            }
            return null; // Hide tooltip if conditions are not met
          },
        },
        backgroundColor: '#FFFFFF',
        titleColor: '#000000',
        bodyColor: '#000000',
        borderColor: '#000000',
        borderWidth: 0.2,
        displayColors: true,
      },
      legend: {
        display: false
      }
    }
  };

  const ongoingChartData = prepareOngoingChartData();

  const navigate = useNavigate();
  const dailyTrackerTab = (tabNumber) => {
    setDailyTracker(tabNumber);
    if (tabNumber === 1) {
      navigate('/dailytracker');
    } else if (tabNumber === 2) {
      navigate('/dailytrackerworkshop');
    }
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const fetchProject = async () => {
    const res = await axios.get(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
    );

    const res1 = await axios.get(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project-checklistgetdetails"
    );

    const res2 = await axios.get(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details"
    );
    const res3 = await axios.get(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization_all"
    );
    const resu = res.data.data;
    const res1u = res1.data.data;
    const res2u = res2.data;
    const res3u = res3.data;
    setprojectdetails(resu);
    setchecklistdata(res1u);
    setprofdetails(res2u);
    setforwarddetails(res3u);

  };

  useEffect(() => {
    fetchProject();
  }, []);

  const handleMouseEnter = (i) => {
    if (hoverid === i) {
      setIsHovered(false);
      // setIsHovered1(false);
      sethoverid(-1);
    } else {
      setIsHovered(true);
      sethoverid(i);
    }

  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter1 = (i) => {
    if (hoverid1 === i) {
      setIsHovered1(false);
      // setIsHovered(false);
      sethoverid1(-1);
    } else {
      setIsHovered1(true);
      sethoverid1(i);
    }
  }
  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  }

  useEffect(() => {
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split('/');
      return new Date(year, month - 1, day);

    };
    if (fill === true) {
      const filteredRows = ongoingdetails.filter((ele) => {
        let parts = ele.Estimated_Date_Of_Commencement.split("/");
        let day = parseInt(parts[0]);
        let month = parseInt(parts[1]) - 1;
        let year = parseInt(parts[2]);
        const estimatedDate = new Date(year, month, day);
        let diff = presentdate.getTime() - estimatedDate.getTime();
        let days = Math.round(diff / (24 * 60 * 60 * 1000));

        let parts1 = ele.Estimated_Project_Completion_Month.split("/");
        let day1 = parseInt(parts1[0]);
        let month1 = parseInt(parts1[1]) - 1;
        let year1 = parseInt(parts1[2]);
        const estimatedcomp = new Date(year1, month1, day1);
        return (ele.Po_No === "" || estimatedcomp < presentdate || days > ele.Po_Days)
      });
      setongoingdetails(filteredRows);

      const filteredRows2 = upcomingdetails.filter((elem) => {
        const smresults2 = smresults.filter((ele) => ele.ID_No === elem.ID_No);
        const smresults3 = smresults2.filter((ele) => ele.Status === "Onboard");

        return (
          elem.Quote_No === "" ||
          elem.Po_No === "" ||
          (smresults2.length !== smresults3.length &&
            elem.Service_Component !== "Jacking System Inspection" &&
            elem.Service_Component !== "Crane System Inspection" &&
            elem.Service_Component !== "Skidding System Inspection" &&
            elem.Service_Component !== "Fixation System Inspection" &&
            elem.Service_Component !== "Drilling Equipment Inspection" &&
            elem.Service_Component !== "Supply Of Mechanics" &&
            elem.Service_Component !== "Braking System Inspection") ||
          (smresults2.length === smresults3.length &&
            smresults2.length === 0 &&
            elem.Service_Component !== "Jacking System Inspection" &&
            elem.Service_Component !== "Crane System Inspection" &&
            elem.Service_Component !== "Skidding System Inspection" &&
            elem.Service_Component !== "Fixation System Inspection" &&
            elem.Service_Component !== "Drilling Equipment Inspection" &&
            elem.Service_Component !== "Supply Of Mechanics" &&
            elem.Service_Component !== "Braking System Inspection")
        );

      });

      setupcomingdetails(filteredRows2);

      const filteredRows3 = recentlydetails.filter((elem) => {
        let check = checklistdata.filter((elm) => elm.ID_No === elem.ID_No);
        let parts1 = elem.Estimated_Project_Completion_Month.split("/");
        let day1 = parseInt(parts1[0]);
        let month1 = parseInt(parts1[1]) - 1;
        let year1 = parseInt(parts1[2]);
        const estimatedcomp = new Date(year1, month1, day1);
        let diff = presentdate.getTime() - estimatedcomp.getTime();
        let days = Math.round(diff / (24 * 60 * 60 * 1000));

        return (elem.Po_No === "" || check[0]?.Item39 !== "Completed" && days > 14 || check[0]?.Item37 !== "Completed" && days > 5)
      });
      setrecentlydetails(filteredRows3);




    };

    const filteredRows4 = closeddetails.filter((elem) => {
      let check = checklistdata.filter((elm) => elm.ID_No === elem.ID_No);
      let parts1 = elem.Estimated_Project_Completion_Month.split("/");
      let day1 = parseInt(parts1[0]);
      let month1 = parseInt(parts1[1]) - 1;
      let year1 = parseInt(parts1[2]);
      const estimatedcomp = new Date(year1, month1, day1);
      let diff = presentdate.getTime() - estimatedcomp.getTime();
      let days = Math.round(diff / (24 * 60 * 60 * 1000));

      return (elem.Po_No === "" || check[0]?.Item39 === "Completed" && days > 14 || check[0]?.Item37 === "Completed" && days > 5)
    });
    setcloseddetails(filteredRows4);






    if (fill === false) {


      if (projectdetails.length > 0) {
        const upfilteredProjects = projectdetails.filter((el) => {
          if (el.Estimated_Date_Of_Commencement) {
            const estimatedDate = parseDate(el.Estimated_Date_Of_Commencement);
            return presentdate < estimatedDate;
          }
          return false; // Exclude elements with no valid date
        }).sort((a, b) => {
          const dateA = parseDate(a.Estimated_Date_Of_Commencement);
          const dateB = parseDate(b.Estimated_Date_Of_Commencement);
          return dateA - dateB;
        });
        setupcomingdetails(upfilteredProjects);
        setupcomingdetailsfil(upfilteredProjects);

        const onfilteredprojects = projectdetails.filter((el) => {
          if (el.Estimated_Date_Of_Commencement) {
            const parts = el.Estimated_Date_Of_Commencement.split("/");
            const day = parseInt(parts[0]);
            const month = parseInt(parts[1]) - 1;
            const year = parseInt(parts[2]);
            const estimatedDate = new Date(year, month, day);
            const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
            return presentdate > estimatedDate && check[0]?.Item32 !== "Completed";

          }
          return false;
        });
        setongoingdetails(onfilteredprojects);
        setongoingdetailsfil(onfilteredprojects);

        // const recentfilteredprojects = projectdetails.filter((el) => {
        //   if (el.Estimated_Date_Of_Commencement) {
        //     const parts = el.Estimated_Date_Of_Commencement.split("/");
        //     const day = parseInt(parts[0]);
        //     const month = parseInt(parts[1]) - 1;
        //     const year = parseInt(parts[2]);
        //     const estimatedDate = new Date(year, month, day);
        //     const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
        //     let count = 0;
        //     for (let i = 1; i <= 40; i++) {
        //       const propertyName = "Item" + i;
        //       if (check[0]?.[propertyName] === "Completed") {
        //         count++;
        //       }
        //     }
        //     return presentdate > estimatedDate && check[0]?.Item32 === "Completed" && check[0]?.Item40 !== "Completed";

        //   }
        //   return false;
        // });
        // setrecentlydetails(recentfilteredprojects);
        // setrecentlydetailsfil(recentfilteredprojects);
        const recentfilteredprojects = projectdetails.filter((el) => {
          if (el.Estimated_Project_Completion_Month) {
            const parts = el.Estimated_Project_Completion_Month.split("/");
            const day = parseInt(parts[0]);
            const month = parseInt(parts[1]) - 1;
            const year = parseInt(parts[2]);
            const estimatedCompletionDate = new Date(year, month, day);
            const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
            let completedItemCount = 0;
            for (let i = 1; i <= 40; i++) {
              const propertyName = "Item" + i;
              if (check[0]?.[propertyName] === "Completed") {
                completedItemCount++;
              }
            }
            return presentdate >= estimatedCompletionDate && check[0]?.Item32 === "Completed" && completedItemCount !== 40;
          }
          return false;
        });
        setrecentlydetails(recentfilteredprojects);
        setrecentlydetailsfil(recentfilteredprojects);


        const closedfilteredprojects = projectdetails.filter((el) => {
          if (el.Estimated_Date_Of_Commencement) {
            const parts = el.Estimated_Date_Of_Commencement.split("/");
            const day = parseInt(parts[0]);
            const month = parseInt(parts[1]) - 1;
            const year = parseInt(parts[2]);
            const estimatedDate = new Date(year, month, day);
            const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
            let completedItemCount = 0;
            for (let i = 1; i <= 40; i++) {
              const propertyName = "Item" + i;
              if (check[0]?.[propertyName] === "Completed") {
                completedItemCount++;
              }
            }
            // return presentdate > estimatedDate && check[0]?.Item32 === "Completed" && check[0]?.Item37 === "Completed" && check[0]?.Item39 === "Completed" && check[0]?.Item40 === "Completed";
            return presentdate > estimatedDate && check[0]?.Item32 === "Completed" && completedItemCount === 40;

          }
          return false;
        });
        setcloseddetails(closedfilteredprojects);
        setcloseddetailsfil(closedfilteredprojects);
      }
    }

  }, [fill, stats]);

  useEffect(() => {
    async function fetchsmDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesmobilization_data"
        );
        setsmresults(response.data);
        console.log(smresults);
      } catch (error) {
        console.error(error);
      }
    }

    fetchsmDetails();
  }, []);

  useEffect(() => {
    if (projectdetails.length > 0) {
      const upfilteredProjects = projectdetails.filter((el) => {
        if (el.Estimated_Date_Of_Commencement) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          return presentdate < estimatedDate;
        }
        return false; // Exclude elements with no valid date
      });
      setupcomingdetails(upfilteredProjects);

      const onfilteredprojects = projectdetails.filter((el) => {
        if (el.Estimated_Date_Of_Commencement) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
          return presentdate > estimatedDate && check[0]?.Item32 !== "Completed";

        }
        return false;
      });
      setongoingdetails(onfilteredprojects);
      setongoingdetailsfil(onfilteredprojects);

      const upfilteredProjects1 = upfilteredProjects.map(project => ({
        ...project,
        Status: "Upcoming"
      }));
      const onfilteredprojects1 = onfilteredprojects.map(project => ({
        ...project,
        Status: "Ongoing"
      }));
      const combined = [...onfilteredprojects1,...upfilteredProjects1];
      setCombinedResults(combined);
      

      const recentfilteredprojects = projectdetails.filter((el) => {
        if (el.Estimated_Project_Completion_Month) {
          const parts = el.Estimated_Project_Completion_Month.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedCompletionDate = new Date(year, month, day);
          const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
          let completedItemCount = 0;
          for (let i = 1; i <= 40; i++) {
            const propertyName = "Item" + i;
            if (check[0]?.[propertyName] === "Completed") {
              completedItemCount++;
            }
          }
          return presentdate >= estimatedCompletionDate && check[0]?.Item32 === "Completed" && completedItemCount !== 40;
        }
        return false;
      });
      setrecentlydetails(recentfilteredprojects);
      setrecentlydetailsfil(recentfilteredprojects);

      const closedfilteredprojects = projectdetails.filter((el) => {
        if (el.Estimated_Date_Of_Commencement) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
          let completedItemCount = 0;
          for (let i = 1; i <= 40; i++) {
            const propertyName = "Item" + i;
            if (check[0]?.[propertyName] === "Completed") {
              completedItemCount++;
            }
          }
          // return presentdate > estimatedDate && check[0]?.Item32 === "Completed" && check[0]?.Item37 === "Completed" && check[0]?.Item39 === "Completed" && check[0]?.Item40 === "Completed";
          return presentdate > estimatedDate && check[0]?.Item32 === "Completed" && completedItemCount === 40;
        }
        return false;
      });
      setcloseddetails(closedfilteredprojects);
      setcloseddetailsfil(closedfilteredprojects);
    }
  }, [projectdetails]);

  // useEffect(() => {
  //   if (projectdetails.length > 0) {
  //     // Filter for projects with Estimated Date Of Commencement
  //     const filteredProjects = projectdetails.filter((el) => el.Estimated_Date_Of_Commencement);

  //     // Filter for Upcoming projects
  //     const upfilteredProjects = filteredProjects.filter((el) => {
  //       const estimatedDate = new Date(el.Estimated_Date_Of_Commencement);
  //       return presentdate < estimatedDate;
  //     });
  //     setupcomingdetails(upfilteredProjects);

  //     // Filter for Ongoing projects
  //     const onfilteredprojects = filteredProjects.filter((el) => {
  //       const estimatedDate = new Date(el.Estimated_Date_Of_Commencement);
  //       const check = checklistdata.find((elm) => elm.ID_No === el.ID_No);
  //       return presentdate > estimatedDate && check?.Item32 !== "Completed";
  //     });
  //     setongoingdetails(onfilteredprojects);
  //     setongoingdetailsfil(onfilteredprojects);

  //     // Filter for Recently Completed projects
  //     const recentfilteredprojects = filteredProjects.filter((el) => {
  //       const estimatedDate = new Date(el.Estimated_Date_Of_Commencement);
  //       const check = checklistdata.find((elm) => elm.ID_No === el.ID_No);
  //       let count = 0;
  //       for (let i = 1; i <= 40; i++) {
  //         const propertyName = "Item" + i;
  //         if (check?.[propertyName] === "Completed") {
  //           count++;
  //         }
  //       }
  //       return presentdate >= estimatedDate && check?.Item32 === "Completed" && check?.Item37 !== "Completed" && check?.Item39 !== "Completed" && check?.Item40 !== "Completed" && count !== 40;
  //     });
  //     setrecentlydetails(recentfilteredprojects);
  //     setrecentlydetailsfil(recentfilteredprojects);

  //     // Filter for Closed projects
  //     const closedfilteredprojects = filteredProjects.filter((el) => {
  //       const estimatedDate = new Date(el.Estimated_Date_Of_Commencement);
  //       const check = checklistdata.find((elm) => elm.ID_No === el.ID_No);
  //       let count = 0;
  //       for (let i = 1; i <= 40; i++) {
  //         const propertyName = "Item" + i;
  //         if (check?.[propertyName] === "Completed") {
  //           count++;
  //         }
  //       }
  //       return presentdate > estimatedDate && check?.Item32 === "Completed" && check?.Item37 === "Completed" && check?.Item39 === "Completed" && check?.Item40 === "Completed";
  //     });
  //     setcloseddetails(closedfilteredprojects);
  //     setcloseddetailsfil(closedfilteredprojects);
  //   }
  // }, [projectdetails, checklistdata]);



  const handlestatChange = (event) => {
    const selectedstat = event.target.value;
    setstats(selectedstat);
    if (selectedstat === "Ongoing&Upcoming") {
      setOngoingChartView(true); // Set the chart view to true if "Ongoing + Upcoming" is selected
    } else {
      setOngoingChartView(false);
    }
  };
  const handlefil = () => {
    setfill(!fill);
    console.log(fill);
  };

  const handleSearchName = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value.toLowerCase();
    setSearchTerm(lowerCaseValue);

    const filtered = upcomingdetails.filter((el, index) => {
      const name = el.Rig_Name ? el.Rig_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    });

    setupcomingdetailsfil(filtered);
  };

  const handleonSearchName = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value.toLowerCase();
    setonSearchTerm(lowerCaseValue);

    const filtered = ongoingdetails.filter((el, index) => {
      const name = el.Rig_Name ? el.Rig_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    }).map(project => ({ ...project, Status: "Ongoing" }));;

    const filtered2 = upcomingdetails.filter((el, index) => {
      const name = el.Rig_Name ? el.Rig_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    }).map(project => ({ ...project, Status: "Upcoming" }));;

    // Combine filtered results if ongoingChartView is true, otherwise update ongoing only
  if (ongoingChartView) {
    setCombinedResults([...filtered, ...filtered2]);
  } else {
    setongoingdetailsfil(filtered);
  }
  };

  const handlereSearchName = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value.toLowerCase();
    setreSearchTerm(lowerCaseValue);

    const filtered = recentlydetails.filter((el, index) => {
      const name = el.Rig_Name ? el.Rig_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    });

    setrecentlydetailsfil(filtered);
  };

  const handleclSearchName = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value.toLowerCase();
    setclSearchTerm(lowerCaseValue);

    const filtered = closeddetails.filter((el, index) => {
      const name = el.Rig_Name ? el.Rig_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    });

    setcloseddetailsfil(filtered);
  };

  const handleChange1 = (e) => {
    // setfilmonval(e.target.value);
    const { value, checked } = e.target;
    if (checked) {
      // If the checkbox is checked, add the value to the array
      setfilmonval([...filmonval, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setfilmonval(filmonval.filter((val) => val !== value));
    }
  };
  const handleChange2 = (e) => {
    // setfilmonval(e.target.value);
    const { value, checked } = e.target;
    if (checked) {
      // If the checkbox is checked, add the value to the array
      setfilyearval([...filyearval, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setfilyearval(filyearval.filter((val) => val !== value));
    }
  };




  const applyFilter = (e) => {
    console.log(filmonval);
    setFilterContainer(false);

    const filtered2 = upcomingdetails.filter((el, index) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const monthe = parseInt(parts[1]);
      const year = parseInt(parts[2]);

      if ((filmonval.length === 0 || filmonval.includes(monthe.toString())) &&
        (filyearval.length === 0 || filyearval.includes(year.toString()))) {
        return true;
      }
    });

    const filtered3 = ongoingdetails.filter((el, index) => {
      const parts = el.Estimated_Date_Of_Commencement.split("/");
      const monthe = parseInt(parts[1]);
      const year = parseInt(parts[2]);

      if ((filmonval.length === 0 || filmonval.includes(monthe.toString())) &&
        (filyearval.length === 0 || filyearval.includes(year.toString()))) {
        return true;
      }
    });

    const filtered4 = recentlydetails.filter((el, index) => {
      const parts = el.Estimated_Project_Completion_Month.split("/");
      const monthe = parseInt(parts[1]);
      const year = parseInt(parts[2]);

      if ((filmonval.length === 0 || filmonval.includes(monthe.toString())) &&
        (filyearval.length === 0 || filyearval.includes(year.toString()))) {
        return true;
      }
    });

    const filtered5 = closeddetails.filter((el, index) => {
      const parts = el.Estimated_Project_Completion_Month.split("/");
      const monthe = parseInt(parts[1]);
      const year = parseInt(parts[2]);

      if ((filmonval.length === 0 || filmonval.includes(monthe.toString())) &&
        (filyearval.length === 0 || filyearval.includes(year.toString()))) {
        return true;
      }
    });

    setupcomingdetailsfil(filtered2);
    setongoingdetailsfil(filtered3);
    setrecentlydetailsfil(filtered4);
    setcloseddetailsfil(filtered5);
  };

  const handlerefreshsvg = () => {
    setfilmonval([]);
    setupcomingdetailsfil(upcomingdetails);
    setongoingdetailsfil(ongoingdetails);
    setrecentlydetailsfil(recentlydetails);
    setcloseddetailsfil(closeddetails)
    setFilterContainer(false);
  }

  const handleexceldwn = () => {
    const form = document.getElementById(stats);
    const table = form.querySelector("table");
    const rows = Array.from(table.querySelectorAll("tr"));

    const data = rows.map((row) =>
      Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
    );

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.write to generate the Excel file as an ArrayBuffer
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Use FileSaver.js to save the file
    saveAs(blob, "table_data.xlsx");
  }


  return (
    <>
      <div className={styles.dropdiv}>
        {dailyTracker === 1 && (
          <>
            <div
              style={{
                borderBottomLeftRadius: "10px",
                borderTopLeftRadius: "10px",
                marginLeft: "20px",
              }}
              className={
                dailyTracker === 1 ? styles.aryaTabs1 : styles.aryaTabs2
              }
              onClick={() => dailyTrackerTab(1)}>
              Offshore
            </div>
            <div
              style={{
                borderBottomRightRadius: "10px",
                borderTopRightRadius: "10px",
                marginLeft: "-16px",
              }}
              className={
                dailyTracker === 2 ? styles.aryaTabs1 : styles.aryaTabs2
              }
              onClick={() => dailyTrackerTab(2)}>
              Workshop
            </div>
            {/* <label className={styles.dropdiv2} > Select Project Status:</label>
            <select className={styles.dropdiv3} value={stats} onChange={handlestatChange} id="svgdwn" style={{ cursor: "pointer" }}>
              <option value="Ongoing" className={styles.drop}>Ongoing</option>
              <option value="Recently_Completed" className={styles.drop}>Recently Completed</option>
              <option value="Upcoming" className={styles.drop}>Upcoming</option>
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{
                marginLeft: "-50px",
                cursor: "pointer"
              }}
              onClick={() => {
                document.getElementById("svgdwn").click();
              }}

            >
              <path
                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                fill="#394759"
              />
            </svg> */}
          </>
        )}
        <label className={styles.dropdiv2} > Select Project Status:</label>
        <select className={styles.dropdiv3} value={stats} onChange={handlestatChange} id="svgdwn" style={{ cursor: "pointer" }}>
          <option value="Ongoing" className={styles.drop}>Ongoing</option>
          <option value="Ongoing&Upcoming" className={styles.drop}>Ongoing + Upcoming</option>
          <option value="Recently_Completed" className={styles.drop}>Recently Completed</option>
          <option value="Upcoming" className={styles.drop}>Upcoming</option>
          <option value="Closed" className={styles.drop}>Closed</option>

        </select>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          style={{
            marginLeft: "-50px",
            cursor: "pointer"
          }}
          onClick={() => {
            document.getElementById("svgdwn").click();
          }}

        >
          <path
            d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
            fill="#394759"
          />
        </svg>

        {dailyTracker === 1 && (stats === "Ongoing" || "Ongoing&Upcoming") && (
          <>
            <div
              style={{
                borderBottomLeftRadius: "10px",
                borderTopLeftRadius: "10px",
                marginLeft: "20px",
              }}
              className={
                ongoingChartView === false ? styles.toggleTabPurple : styles.toggleTabWhite
              }
              onClick={() => handleTableViewClick()}
            >
              {ongoingChartView === false ? (
                <svg width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="1.4" stroke-linecap="square" stroke-linejoin="miter" fill="none"
                  style={{ marginTop: "-4px" }}
                >

                  <path d="M22 4V19H2L2 4L22 4Z" />
                  <line x1="22" y1="9" x2="2" y2="9" />
                  <line x1="22" y1="14" x2="2" y2="14" />
                  <path d="M12 19L12 4" />

                </svg>
              ) : (
                <svg width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#5B6BE1" stroke-width="1.4" stroke-linecap="square" stroke-linejoin="miter" fill="none"
                  style={{ marginTop: "-4px" }}
                >

                  <path d="M22 4V19H2L2 4L22 4Z" />
                  <line x1="22" y1="9" x2="2" y2="9" />
                  <line x1="22" y1="14" x2="2" y2="14" />
                  <path d="M12 19L12 4" />

                </svg>
              )
              }
            </div>
            <div
              style={{
                borderBottomRightRadius: "10px",
                borderTopRightRadius: "10px",
                marginLeft: "-16px",
              }}
              className={
                ongoingChartView === true ? styles.toggleTabPurple : styles.toggleTabWhite
              }
              onClick={() => handleChartViewClick()}
            >
              {ongoingChartView === true ? (
                <svg width="28" height="28" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "-2px" }}
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path fill="#ffffff" d="M12 3v4H3V3h9zm4 14v4H3v-4h13zm6-7v4H3v-4h19z" />
                  </g>
                </svg>
              ) : (
                <svg width="28" height="28" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "-2px" }}
                >
                  <g>
                    <path fill="#5b6be1" d="M12 3v4H3V3h9zm4 14v4H3v-4h13zm6-7v4H3v-4h19z" />
                  </g>
                </svg>
              )
              }

            </div>
          </>
        )}

        {(stats === "Upcoming") && <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearchName}
            className={styles.searchBox}
          />
          <svg
            className={styles.searchIconFilter}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#BCBCBC"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#BCBCBC"
            />
          </svg>
        </div>
        }
        {(stats === "Ongoing" || stats === "Ongoing&Upcoming") && <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search by name"
            value={onsearchTerm}
            onChange={handleonSearchName}
            className={styles.searchBox}
            style={{ marginLeft: "5%" }}
          />
          <svg
            className={styles.searchIconFilter}
            style={{ marginLeft: "5%" }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#BCBCBC"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#BCBCBC"
            />
          </svg>
        </div>
        }
        {(stats === "Recently_Completed") && <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search by name"
            value={researchTerm}
            onChange={handlereSearchName}
            className={styles.searchBox}
          />
          <svg
            className={styles.searchIconFilter}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#BCBCBC"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#BCBCBC"
            />
          </svg>
        </div>
        }
        {(stats === "Closed") && <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search by name"
            value={clsearchTerm}
            onChange={handleclSearchName}
            className={styles.searchBox}
          />
          <svg
            className={styles.searchIconFilter}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#BCBCBC"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#BCBCBC"
            />
          </svg>
        </div>
        }
        {/* <div className={styles.haridiv}>
          <div style={{ marginTop: "5px"}}>
              Quick Filter
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7934 1.33334H5.20671C2.78004 1.33334 1.33337 2.78001 1.33337 5.20668V10.7867C1.33337 13.22 2.78004 14.6667 5.20671 14.6667H10.7867C13.2134 14.6667 14.66 13.22 14.66 10.7933V5.20668C14.6667 2.78001 13.22 1.33334 10.7934 1.33334ZM8.88671 11.3333H7.10671C6.83337 11.3333 6.60671 11.1067 6.60671 10.8333C6.60671 10.56 6.83337 10.3333 7.10671 10.3333H8.88671C9.16004 10.3333 9.38671 10.56 9.38671 10.8333C9.38671 11.1067 9.16671 11.3333 8.88671 11.3333ZM10.6667 8.50001H5.33337C5.06004 8.50001 4.83337 8.27334 4.83337 8.00001C4.83337 7.72668 5.06004 7.50001 5.33337 7.50001H10.6667C10.94 7.50001 11.1667 7.72668 11.1667 8.00001C11.1667 8.27334 10.94 8.50001 10.6667 8.50001ZM12 5.66668H4.00004C3.72671 5.66668 3.50004 5.44001 3.50004 5.16668C3.50004 4.89334 3.72671 4.66668 4.00004 4.66668H12C12.2734 4.66668 12.5 4.89334 12.5 5.16668C12.5 5.44001 12.2734 5.66668 12 5.66668Z"
                  fill="#292D32"
                />
              </svg>
              </div>
            <div
              style={{
                cursor:"pointer",
                backgroundColor: fill === true ? "#FF6A6A" : "transparent",
                color: fill === true ? "white" : "#5B6BE1",
              }}
              className={styles.vishdiv}
              onClick={handlefil}
            >
              {" "}
              Past due date
            </div>
          </div> */}



      </div>
      {<button
        onClick={openFilterContainer}
        variant="contained"
        className={styles.btnfilter}
        style={{ marginLeft: "80%" }}
      >
        Filter
        <svg
          style={{ marginLeft: "10px" }}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none">
          <path
            d="M13.491 1.66699H6.50768C3.47435 1.66699 1.66602 3.47533 1.66602 6.50866V13.4837C1.66602 16.5253 3.47435 18.3337 6.50768 18.3337H13.4827C16.516 18.3337 18.3243 16.5253 18.3243 13.492V6.50866C18.3327 3.47533 16.5243 1.66699 13.491 1.66699ZM11.1077 14.167H8.88268C8.54102 14.167 8.25768 13.8837 8.25768 13.542C8.25768 13.2003 8.54102 12.917 8.88268 12.917H11.1077C11.4493 12.917 11.7327 13.2003 11.7327 13.542C11.7327 13.8837 11.4577 14.167 11.1077 14.167ZM13.3327 10.6253H6.66602C6.32435 10.6253 6.04102 10.342 6.04102 10.0003C6.04102 9.65866 6.32435 9.37533 6.66602 9.37533H13.3327C13.6743 9.37533 13.9577 9.65866 13.9577 10.0003C13.9577 10.342 13.6743 10.6253 13.3327 10.6253ZM14.9993 7.08366H4.99935C4.65768 7.08366 4.37435 6.80033 4.37435 6.45866C4.37435 6.11699 4.65768 5.83366 4.99935 5.83366H14.9993C15.341 5.83366 15.6243 6.11699 15.6243 6.45866C15.6243 6.80033 15.341 7.08366 14.9993 7.08366Z"
            fill="#292D32"
          />
        </svg>
      </button>}
      <div className={styles.filterContainer}>
        {filterContainer && (
          <div className={styles.itemsContainer}>
            {(stats === "Ongoing" || stats === "Upcoming") && <h10><b>Commencement Date</b></h10>}
            {(stats === "Recently_Completed" || stats === "Closed") && <h10><b> Completion Date</b></h10>}

            <div
              name="year"
              onClick={handleFilterItemsClick}
              className={
                filterItems.year
                  ? `${styles1.optionsList} ${styles1.activeOption}`
                  : styles1.optionsList
              }>
              <h5>Year</h5>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.75 13.5L11.25 9L6.75 4.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              {filterItems.year && (
                <div className={styles1.itemsContainer}>
                  <div className={styles.optionsList} style={{ cursor: "default" }}>
                    <h5>{yearNames[0]}</h5>
                    <input
                      type="checkbox"
                      name="Year"
                      value={yearNames[0]}
                      checked={filyearval.includes(yearNames[0])}
                      onChange={handleChange2}
                    />
                  </div>
                  {(stats === "Upcoming") && <div className={styles.optionsList}>
                    <h5>{yearNames[1]}</h5>
                    <input
                      type="checkbox"
                      name="Year"
                      value={yearNames[1]}
                      checked={filyearval.includes(yearNames[1])}
                      onChange={handleChange2}
                    />
                  </div>}
                  {(stats === "Recently_Completed" || stats === "Closed" || stats === "Ongoing") && (
                    <div className={styles.optionsList}>
                      <h5>{yearNames[1]}</h5>
                      <input
                        type="checkbox"
                        name="Year"
                        value={yearNames[1]}
                        checked={filyearval.includes(yearNames[1])}
                        onChange={handleChange2}
                      />
                    </div>
                  )}
                  {(stats === "Upcoming") && <div className={styles.optionsList}>
                    <h5>{yearNames[2]}</h5>
                    <input
                      type="checkbox"
                      name="Year"
                      value={yearNames[2]}
                      checked={filyearval.includes(yearNames[2])}
                      onChange={handleChange2}
                    />
                  </div>}
                  {(stats === "Recently_Completed" || stats === "Closed" || stats === "Ongoing") && <div className={styles.optionsList}>
                    <h5>{yearNames[2]}</h5>
                    <input
                      type="checkbox"
                      name="Year"
                      value={yearNames[2]}
                      checked={filyearval.includes(yearNames[2])}
                      onChange={handleChange2}
                    />
                  </div>}
                  {(stats === "Upcoming") && <div className={styles.optionsList}>
                    <h5>{yearNames[3]}</h5>
                    <input
                      type="checkbox"
                      name="Year"
                      value={yearNames[3]}
                      checked={filmonval.includes(yearNames[3])}
                      onChange={handleChange2}
                    />
                  </div>}
                  {(stats === "Recently_Completed" || stats === "Closed" || stats === "Ongoing") && <div className={styles.optionsList}>
                    <h5>{yearNames[3]}</h5>
                    <input
                      type="checkbox"
                      name="Year"
                      value={yearNames[3]}
                      checked={filmonval.includes(yearNames[3])}
                      onChange={handleChange2}
                    />
                  </div>}
                </div>

              )}

            </div>

            <div
              name="month"
              onClick={handleFilterItemsClick}
              className={
                filterItems.month
                  ? `${styles1.optionsList} ${styles1.activeOption}`
                  : styles1.optionsList
              }>
              <h5>Month</h5>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.75 13.5L11.25 9L6.75 4.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              {filterItems.month && (
                <div className={styles1.itemsContainer}>
                  <div className={styles.checkboxLabel}>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[0 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(0 % 12) + 1}
                        checked={filmonval.includes(((0 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[1 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(1 % 12) + 1}
                        checked={filmonval.includes(((1 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>

                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[2 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(2 % 12) + 1}
                        checked={filmonval.includes(((2 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[3 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(3 % 12) + 1}
                        checked={filmonval.includes(((3 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[4 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(4 % 12) + 1}
                        checked={filmonval.includes(((4 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[5 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(5 % 12) + 1}
                        checked={filmonval.includes(((5 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[6 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(6 % 12) + 1}
                        checked={filmonval.includes(((6 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[7 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(7 % 12) + 1}
                        checked={filmonval.includes(((7 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[8 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(8 % 12) + 1}
                        checked={filmonval.includes(((8 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[9 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(9 % 12) + 1}
                        checked={filmonval.includes(((9 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[10 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(10 % 12) + 1}
                        checked={filmonval.includes(((10 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    <div className={styles.optionsList} style={{ cursor: "default" }}>
                      <h5>{monthNames[11 % 12]}</h5>
                      <input
                        type="checkbox"
                        name="Month"
                        value={(11 % 12) + 1}
                        checked={filmonval.includes(((11 % 12) + 1).toString())}
                        onChange={handleChange1}
                      />

                    </div>
                    {/* {(stats === "Upcoming") && <div className={styles.optionsList}>
                    <h5>{monthNames[(0 + 1) % 12]}</h5>
                    <input
                      type="checkbox"
                      name="Month"
                      value={((0 + 1) % 12) + 1}
                      checked={filmonval.includes((((0 + 1) % 12) + 1).toString())}
                      onChange={handleChange1}
                    />
                  </div>} */}
                    {/* {(stats === "Recently_Completed" || stats === "Closed" || stats === "Ongoing") && (
                <div className={styles.optionsList}>
                  <h5>{monthNames[((currentMonth - 1) % 12 + 12) % 12]}</h5>
                  <input
                    type="checkbox"
                    name="Month"
                    value={(((currentMonth - 1) % 12 + 12) % 12) + 1}
                    checked={filmonval.includes(((((currentMonth - 1) % 12 + 12) % 12) + 1).toString())}
                    onChange={handleChange1}
                  />
                </div>
              )} */}
                    {/* {(stats === "Upcoming") && <div className={styles.optionsList}>
                <h5>{monthNames[(currentMonth + 2) % 12]}</h5>
                <input
                  type="checkbox"
                  name="Month"
                  value={((currentMonth + 2) % 12) + 1}
                  checked={filmonval.includes((((currentMonth + 2) % 12) + 1).toString())}
                  onChange={handleChange1}
                />
              </div>} */}
                    {/* {(stats === "Recently_Completed" || stats === "Closed" || stats === "Ongoing") && <div className={styles.optionsList}>
                <h5>{monthNames[((currentMonth - 2) % 12 + 12) % 12]}</h5>
                <input
                  type="checkbox"
                  name="Month"
                  value={(((currentMonth - 2) % 12 + 12) % 12) + 1}
                  checked={filmonval.includes(((((currentMonth - 2) % 12 + 12) % 12) + 1).toString())}
                  onChange={handleChange1}
                />
              </div>}
              {(stats === "Upcoming") && <div className={styles.optionsList}>
                <h5>{monthNames[(currentMonth + 3) % 12]}</h5>
                <input
                  type="checkbox"
                  name="Month"
                  value={((currentMonth + 3) % 12) + 1}
                  checked={filmonval.includes((((currentMonth + 3) % 12) + 1).toString())}
                  onChange={handleChange1}
                />
              </div>}
              {(stats === "Recently_Completed" || stats === "Closed" || stats === "Ongoing") && <div className={styles.optionsList}>
                <h5>{monthNames[((currentMonth - 3) % 12 + 12) % 12]}</h5>
                <input
                  type="checkbox"
                  name="Month"
                  value={(((currentMonth - 3) % 12 + 12) % 12) + 1}
                  checked={filmonval.includes(((((currentMonth - 3) % 12 + 12) % 12) + 1).toString())}
                  onChange={handleChange1}
                />
              </div>} */}

                  </div>
                </div>

              )}

            </div>


            <div className={styles.applyContainer}>
              <button
                onClick={applyFilter}
                variant="contained"
                className={styles.applyBtn}>
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
      {/* {<svg className={styles.btnfilter2} style={{ marginLeft: "90%" }} onClick={handlerefreshsvg} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z" /></svg>} */}
      {!(stats === "Ongoing" && ongoingChartView) && (
        <svg width="40" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleexceldwn} className={styles.btnfilter3} >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 5.28595 -1.19209e-07 2.92893 1.46447 1.46447C2.92893 -1.19209e-07 5.28595 0 10 0C14.714 0 17.0711 -1.19209e-07 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C-1.19209e-07 17.0711 0 14.714 0 10ZM10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V10.1893L12.4697 8.4697C12.7626 8.1768 13.2374 8.1768 13.5303 8.4697C13.8232 8.7626 13.8232 9.2374 13.5303 9.5303L10.5303 12.5303C10.3897 12.671 10.1989 12.75 10 12.75C9.8011 12.75 9.6103 12.671 9.4697 12.5303L6.46967 9.5303C6.17678 9.2374 6.17678 8.7626 6.46967 8.4697C6.76256 8.1768 7.23744 8.1768 7.53033 8.4697L9.25 10.1893V5C9.25 4.58579 9.5858 4.25 10 4.25ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75H14C14.4142 15.75 14.75 15.4142 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H6Z" fill="#5B6BE1" />
        </svg>
      )}

      <div>
        {(stats === "Ongoing") && (ongoingChartView === false) && (
          <form id="Ongoing">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th> Rig Name </th>
                  <th> SO No </th>
                  <th> Scope </th>
                  <th> PO Status </th>
                  <th> Techs/Trainees deployed</th>

                  <th>Ops Engineer</th>
                  <th>Onboard/PO days</th>
                  <th> Mobilization date </th>
                  <th>Estimated demob date</th>
                  <th>Techs Deployed</th>
                </tr>
              </thead>
              <tbody>
                {ongoingdetailsfil.map((el, index) => {
                  let check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
                  let parts = el.Estimated_Date_Of_Commencement.split("/");
                  let day = parseInt(parts[0]);
                  let month = parseInt(parts[1]) - 1;
                  let year = parseInt(parts[2]);
                  const estimatedDate = new Date(year, month, day);
                  let diff = presentdate.getTime() - estimatedDate.getTime();
                  let days = Math.round(diff / (24 * 60 * 60 * 1000));

                  let parts1 = el.Estimated_Project_Completion_Month.split("/");
                  let day1 = parseInt(parts1[0]);
                  let month1 = parseInt(parts1[1]) - 1;
                  let year1 = parseInt(parts1[2]);
                  const estimatedcomp = new Date(year1, month1, day1);
                  return (
                    <tr key={el.ID_No}>
                      <td>
                        {index + 1}
                      </td>
                      <td> <NavLink
                        to={"/project_checklist/" + el.ID_No}>{el.Rig_Name ? el.Rig_Name : "-"}</NavLink>
                      </td>
                      <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                      <td>{el.Service_Component ? el.Service_Component : "-"}</td>
                      <td style={{ backgroundColor: el.Po_No === '' ? '#FF6A6A' : 'white', color: el.Po_No === '' ? 'white' : 'black' }}>
                        {el.Po_No === "" ? "PO not received" : "PO received" + "(" + el.Po_No + ")"}{" "}{el.Po_No === "" ?
                          <>
                            <BootstrapTooltip title={check[0]?.Issue4 ? check[0]?.Issue4 : "--"}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                color="white"
                                fill="currentColor"
                                class="bi bi-info-circle"
                                viewBox="0 0 16 16"
                                style={{ cursor: "pointer" }}
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                              </svg>

                            </BootstrapTooltip>
                          </> : null
                        }
                      </td>
                      <td>{`${(el.Supervisor_Names ? el.Supervisor_Names.split(',').length : 0) +
                        (el.Technician_Names ? el.Technician_Names.split(',').length : 0)}/${el.Trainee_Names ? el.Trainee_Names.split(',').length : 0
                        }`}</td>

                      <td>{el.Ops_Engineer ? el.Ops_Engineer : "-"} </td>
                      <td>
                        {el.Po_Days ? (<>
                          <span style={{ color: days > el.Po_Days ? '#F00' : '#338700' }}>
                            {days}
                          </span>
                          <span>/{el.Po_Days}</span>
                        </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{el.Estimated_Date_Of_Commencement ? el.Estimated_Date_Of_Commencement : "-"}</td>
                      <td style={{ backgroundColor: el.Estimated_Project_Completion_Month && estimatedcomp < presentdate ? '#FF6A6A' : '', color: estimatedcomp < presentdate ? 'white' : '' }}>
                        {el.Estimated_Project_Completion_Month ? el.Estimated_Project_Completion_Month : "-"}
                      </td>
                      <td>{el.Supervisor_Names ? el.Supervisor_Names : ""}{el.Supervisor_Names && el.Technician_Names ? "," : ""}{el.Technician_Names ? el.Technician_Names : ""}{(el.Supervisor_Names || el.Technician_Names) && el.Trainee_Names ? "," : ""} {el.Trainee_Names ? el.Trainee_Names : ""}</td>

                    </tr>)
                })}
              </tbody>
            </table>
          </form>
        )}
        {((stats === "Ongoing") && (ongoingChartView === true) || (stats === "Ongoing&Upcoming")) && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <table className={styles.rigtable} style={{ maxWidth: "50vw", position: "relative" }} ref={tableRef}>
                <thead ref={tableHeaderRef}>
                  <tr>
                    <th>S. No</th>
                    <th> Rig Name </th>
                    <th> SO No </th>
                    <th> Scope </th>
                    <th> Status </th>
                    <th> Onboard/PO days </th>
                    <th> Mobilization date </th>
                    <th>Estimated demob date</th>
                  </tr>
                </thead>
                <tbody>
                  {combinedResults.map((el, index) => {
                    let check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
                    let parts = el.Estimated_Date_Of_Commencement.split("/");
                    let day = parseInt(parts[0]);
                    let month = parseInt(parts[1]) - 1;
                    let year = parseInt(parts[2]);
                    const estimatedDate = new Date(year, month, day);
                    let diff = presentdate.getTime() - estimatedDate.getTime();
                    let days = Math.round(diff / (24 * 60 * 60 * 1000));

                    let parts1 = el.Estimated_Project_Completion_Month.split("/");
                    let day1 = parseInt(parts1[0]);
                    let month1 = parseInt(parts1[1]) - 1;
                    let year1 = parseInt(parts1[2]);
                    const estimatedcomp = new Date(year1, month1, day1);
                    return (
                      <tr key={el.ID_No}>
                        <td>
                          {index + 1}
                        </td>
                        <td> <NavLink
                          to={"/project_checklist/" + el.ID_No}>{el.Rig_Name ? el.Rig_Name : "-"}</NavLink>
                        </td>
                        <td style={{padding: "8px"}}>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                        <td style={{padding: "0"}}>{el.Service_Component ? el.Service_Component : "-"}</td>
                        <td>{el.Status}</td>
                        <td>
                          {el.Po_Days ? (
                            <>
                              {el.Status === "Ongoing" ? (
                                <span style={{ color: days > el.Po_Days ? '#F00' : '#338700' }}>
                                  {days}
                                </span>
                              ) : (
                                <span>0</span>
                              )}
                              <span>/{el.Po_Days}</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{el.Estimated_Date_Of_Commencement ? el.Estimated_Date_Of_Commencement : "-"}</td>
                        <td style={{ backgroundColor: el.Estimated_Project_Completion_Month && estimatedcomp < presentdate ? '#FF6A6A' : '', color: estimatedcomp < presentdate ? 'white' : '' }}>
                          {el.Estimated_Project_Completion_Month ? el.Estimated_Project_Completion_Month : "-"}
                        </td>
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
            <div className={styles.ongoingChart} style={{ marginLeft: divWidth }}>
              <div className={styles.ongoingChartHeaderContainer} style={{ height: ongoingChartHeaderHeight }}>
                <div className={styles.ongoingChartHeadLabel}>
                  <h4 style={{ fontWeight: "bold" }}>
                    {previousMonthName}
                  </h4>
                </div>
                <div className={styles.ongoingChartHeadLabel}>
                  <h4 style={{ fontWeight: "bold" }}>
                    {currentMonthName}
                  </h4>
                </div>
                <div className={styles.ongoingChartHeadLabel}>
                  <h4 style={{ fontWeight: "bold" }}>
                    {nextMonthName}
                  </h4>
                </div>
                <div className={styles.ongoingChartHeadLabel}>
                  <h4 style={{ fontWeight: "bold" }}>
                    {nextMonthName2}
                  </h4>
                </div>
                <div className={styles.ongoingChartHeadLabel}>
                  <h4 style={{ fontWeight: "bold" }}>
                    {nextMonthName3}
                  </h4>
                </div>
                <div className={styles.ongoingChartHeadLabel}>
                  <h4 style={{ fontWeight: "bold" }}>
                    {nextMonthName4}
                  </h4>
                </div>
              </div>
              <div style={{ background: "white", height: divHeight }}>
                <Bar data={ongoingChartData} options={ongoingChartOptions} />
              </div>
            </div>
          </div>
        )}

        {(stats === "Recently_Completed") && (
          <form id="Recently_Completed">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th> Rig Name </th>
                  <th>SO No</th>
                  <th> Scope </th>
                  <th> PO Status </th>
                  <th>Final report status</th>
                  <th>Invoice status</th>
                  <th>Demobilization date</th>
                  <th>Ops Engineer</th>
                </tr>
              </thead>
              <tbody>
                {recentlydetailsfil.map((el, index) => {

                  let check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
                  let parts1 = el.Estimated_Project_Completion_Month.split("/");
                  let day1 = parseInt(parts1[0]);
                  let month1 = parseInt(parts1[1]) - 1;
                  let year1 = parseInt(parts1[2]);
                  const estimatedcomp = new Date(year1, month1, day1);
                  let diff = presentdate.getTime() - estimatedcomp.getTime();
                  let days = Math.round(diff / (24 * 60 * 60 * 1000));

                  return (<tr key={el.ID_No}>
                    <td>
                      {index + 1}
                    </td>
                    <td> <NavLink
                      to={"/project_checklist/" + el.ID_No}>{el.Rig_Name ? el.Rig_Name : "-"}</NavLink>
                    </td>
                    <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                    <td>{el.Service_Component ? el.Service_Component : "-"}</td>
                    <td style={{ backgroundColor: el.Po_No === '' ? '#FF6A6A' : 'white', color: el.Po_No === '' ? 'white' : 'black' }}>
                      {el.Po_No === "" ? "PO not received" : "PO received" + "(" + el.Po_No + ")"}{" "}{el.Po_No === "" ?
                        <>
                          <BootstrapTooltip title={check[0]?.Issue4 ? check[0]?.Issue4 : "--"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>

                          </BootstrapTooltip>
                        </> : null
                      }
                    </td>
                    <td
                      style={{
                        background:
                          check[0]?.Item39 !== "Completed" &&
                            days > 14

                            ? "#FF6A6A"
                            : "",
                        color:
                          check[0]?.Item39 !== "Completed" &&
                            days > 14

                            ? "white"
                            : "",
                      }}
                    >
                      {check[0]?.Item39 ? check[0]?.Item39 : "Not Completed"}{" "}{check[0]?.Item39 !== "Completed" ?
                        <>
                          <BootstrapTooltip title={check[0]?.Issue39 ? check[0]?.Issue39 : "--"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{
                                color:
                                  check[0]?.Item39 !== "Completed" &&
                                    days > 14

                                    ? "white"
                                    : "black",
                                cursor: "pointer"
                              }}
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>

                          </BootstrapTooltip>
                        </> : null}
                    </td>
                    <td
                      style={{
                        background:
                          check[0]?.Item37 !== "Completed" &&
                            days > 5

                            ? "#FF6A6A"
                            : "",
                        color:
                          check[0]?.Item37 !== "Completed" &&
                            days > 5

                            ? "white"
                            : "",
                      }}
                    >
                      {check[0]?.Item37 ? check[0]?.Item37 : "Not Completed"}{" "}{check[0]?.Item37 !== "Completed" ?
                        <>
                          <BootstrapTooltip title={check[0]?.Issue37 ? check[0]?.Issue37 : "--"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{
                                color:
                                  check[0]?.Item37 !== "Completed" &&
                                    days > 5

                                    ? "white"
                                    : "black",
                                cursor: "pointer"
                              }}
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>

                          </BootstrapTooltip>
                        </> : null}
                    </td>
                    <td>{el.Estimated_Project_Completion_Month ? el.Estimated_Project_Completion_Month : "-"}</td>
                    <td>{el.Ops_Engineer ? el.Ops_Engineer : "-"}</td>
                  </tr>)
                })}
              </tbody>
            </table>
          </form>
        )}
        {(stats === "Upcoming") && (
          <form id="Upcoming">
            <table className={styles.rigtable}>
              <thead>
                <tr>
                  <th>S. No</th>
                  <th> Rig Name </th>
                  <th>SO No</th>
                  <th>Quote status</th>
                  <th> PO Status </th>
                  <th> Scope </th>
                  <th>Rig Location</th>
                  <th>Visa Status</th>
                  <th>Spares Status</th>
                  <th>Estimated tech Mob. </th>
                  <th>Technicians planned</th>
                  <th>PO days</th>
                </tr>
              </thead>
              <tbody>
                {upcomingdetailsfil.map((el, index) => {
                  let check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
                  const smresults2 = smresults.filter((ele) => ele.ID_No === el.ID_No);
                  const smresults3 = smresults2.filter((ele) => ele.Status === "Onboard");
                  const smresults4 = smresults2.filter((ele) => (ele.Status === "In Transit to FS" || ele.Status === "In Transit to Rig"));
                  const smresults5 = smresults2.filter((ele) => ele.Status === "In FS (Dubai)");
                  const smresults6 = smresults2.filter((ele) => ele.Location === "#4 Primary Gearbox");
                  const smresults7 = smresults2.filter((ele) => ele.Location === "Secondary Drive");
                  const smresults8 = smresults2.filter((ele) => ele.Location === "Final Drive");
                  const smresults9 = smresults2.filter((ele) => ele.Location === "Helical Drive");
                  const smresults10 = smresults2.filter((ele) => ele.Location === "Planetary Drive");
                  const smresults6a = smresults6.filter((ele) => ele.Status === "Onboard");
                  const smresults7a = smresults7.filter((ele) => ele.Status === "Onboard");
                  const smresults8a = smresults8.filter((ele) => ele.Status === "Onboard");
                  const smresults9a = smresults9.filter((ele) => ele.Status === "Onboard");
                  const smresults10a = smresults10.filter((ele) => ele.Status === "Onboard");
                  const alpha = []
                  if ((smresults7.length === smresults7a.length) && smresults7.length !== 0) {
                    alpha.push("Secondary Drive");
                  }

                  if ((smresults8.length === smresults8a.length) && smresults8.length !== 0) {
                    alpha.push("Final Drive");
                  }

                  if ((smresults9.length === smresults9a.length) && smresults9.length !== 0) {
                    alpha.push("Helical Drive");
                  }

                  if ((smresults10.length === smresults10a.length) && smresults10.length !== 0) {
                    alpha.push("Planetary Drive");
                  }

                  if ((smresults6.length === smresults6a.length) && smresults6.length !== 0) {
                    alpha.push("#4 Primary Gearbox");
                  }
                  let count = 0;
                  const filteredforward = forwarddetails.filter(
                    (ele) => parseInt(ele.ID) === el.ID_No
                  );
                  console.log(filteredforward);
                  filteredforward.map((ele, ind) => {
                    const filteredprof = profdetails.filter(
                      (ij) => ij.Customer_Name === ele.Customer_Name
                    );
                    if (ele.Temp_Visa_Doc) {
                      count++;
                      console.log(ele.Temp_Visa_Doc);
                    } else if (
                      (filteredprof[0]?.Active_Visas_One || "")
                        .replace(/\s/g, "")
                        .toLowerCase() ===
                      (el.Rig_Location || "").replace(/\s/g, "").toLowerCase()
                    ) {
                      if (el.Rig_Location) {
                        count++;
                      }
                    } else if (
                      (filteredprof[0]?.Active_Visas_Two || "")
                        .replace(/\s/g, "")
                        .toLowerCase() ===
                      (el.Rig_Location || "").replace(/\s/g, "").toLowerCase()
                    ) {
                      if (el.Rig_Location) {
                        count++;
                      }
                    } else if (
                      (filteredprof[0]?.Active_Visas_Three || "")
                        .replace(/\s/g, "")
                        .toLowerCase() ===
                      (el.Rig_Location || "").replace(/\s/g, "").toLowerCase()
                    ) {
                      if (el.Rig_Location) {
                        count++;
                      }
                    } else if (
                      (filteredprof[0]?.Active_Visas_Four || "")
                        .replace(/\s/g, "")
                        .toLowerCase() ===
                      (el.Rig_Location || "").replace(/\s/g, "").toLowerCase()
                    ) {
                      if (el.Rig_Location) {
                        count++;
                      }
                    } else if (
                      (filteredprof[0]?.Active_Visas_Five || "")
                        .replace(/\s/g, "")
                        .toLowerCase() ===
                      (el.Rig_Location || "").replace(/\s/g, "").toLowerCase()
                    ) {
                      if (el.Rig_Location) {
                        count++;
                      }
                    } else if (
                      (filteredprof[0]?.Active_Visas_Six || "")
                        .replace(/\s/g, "")
                        .toLowerCase() ===
                      (el.Rig_Location || "").replace(/\s/g, "").toLowerCase()
                    ) {
                      if (el.Rig_Location) {
                        count++;
                      }
                    } else if (
                      (filteredprof[0]?.Nationality || "")
                        .replace(/\s/g, "")
                        .toLowerCase() ===
                      (el.Rig_Location || "").replace(/\s/g, "").toLowerCase()
                    ) {
                      if (el.Rig_Location) {
                        count++;
                      }
                    } else {
                    }
                    console.log(filteredprof[0]?.Active_Visas_Six);
                    console.log(el.Rig_Location);
                    console.log(`Count: ${count}`);
                  });
                  const percent = parseFloat(((smresults3.length / smresults2.length * 100)).toFixed(0));
                  const percent2 = parseFloat(((smresults4.length / smresults2.length * 100)).toFixed(0));
                  const percent3 = parseFloat(((smresults5.length / smresults2.length * 100)).toFixed(0));
                  var message = (
                    <div>
                      <div>{percent}% spares are onboard</div>
                      <div>{percent2}% spares in-transit</div>
                      <div>{percent3}% spares in FS</div>
                    </div>
                  );
                  return (<tr key={el.ID_No}>
                    <td>
                      {index + 1}
                    </td>
                    <td> <NavLink
                      to={"/project_checklist/" + el.ID_No}>{el.Rig_Name ? el.Rig_Name : "-"}</NavLink>
                    </td>
                    <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                    <td style={{ backgroundColor: el.Quote_No === '' ? '#FF6A6A' : 'white', color: el.Quote_No === '' ? 'white' : '' }}>
                      {el.Quote_No === "" ? "Quote not sent" : "Quote sent"}{" "}{el.Quote_No === "" ?
                        <>
                          <BootstrapTooltip title={check[0]?.Issue2 ? check[0]?.Issue2 : "--"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>

                          </BootstrapTooltip>
                        </> : null
                      }
                    </td>
                    <td style={{ backgroundColor: el.Po_No === '' ? '#FF6A6A' : 'white', color: el.Po_No === '' ? 'white' : '' }}>
                      {el.Po_No === "" ? "PO not received" : "PO received" + "(" + el.Po_No + ")"}{" "}{el.Po_No === "" ?
                        <>
                          <BootstrapTooltip title={check[0]?.Issue4 ? check[0]?.Issue4 : "--"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>

                          </BootstrapTooltip>
                        </> : null
                      }
                    </td>
                    <td>{el.Service_Component ? el.Service_Component : "-"}</td>
                    <td>{el.Rig_Location ? el.Rig_Location : "-"}</td>
                    <td>
                      {count} / {filteredforward.length} Visas are received
                    </td>
                    {/* <td style={{
                      backgroundColor: smresults3.length == 0 && smresults4.length == 0 && smresults5.length == 0 && (smresults2.length !== 0) && (el.Service_Component !== "Jacking System Inspection" && el.Service_Component !== "Crane System Inspection" && el.Service_Component !== "Skidding System Inspection" && el.Service_Component !== "Fixation System Inspection" && el.Service_Component !== "Drilling Equipment Inspection" && el.Service_Component !== "Supply Of Mechanics") || (smresults2.length === 0 && (el.Service_Component !== "Jacking System Inspection" && el.Service_Component !== "Crane System Inspection" && el.Service_Component !== "Skidding System Inspection" && el.Service_Component !== "Fixation System Inspection" && el.Service_Component !== "Drilling Equipment Inspection" && el.Service_Component !== "Supply Of Mechanics")) ? '#FF6A6A' : (alpha.length > 0) ? '#43B581' : (!(el.Service_Component !== "Jacking System Inspection" && el.Service_Component !== "Crane System Inspection" && el.Service_Component !== "Skidding System Inspection" && el.Service_Component !== "Fixation System Inspection" && el.Service_Component !== "Drilling Equipment Inspection" && el.Service_Component !== "Supply Of Mechanics")) ? 'white' : '#FAA61A',
                      color: (!(el.Service_Component !== "Jacking System Inspection" && el.Service_Component !== "Crane System Inspection" && el.Service_Component !== "Skidding System Inspection" && el.Service_Component !== "Fixation System Inspection" && el.Service_Component !== "Drilling Equipment Inspection" && el.Service_Component !== "Supply Of Mechanics")) ? '#567191' : 'white'
                    }}>
                      {smresults2.length === smresults3.length && smresults2.length === 0 && (el.Service_Component !== "Jacking System Inspection" && el.Service_Component !== "Crane System Inspection" && el.Service_Component !== "Skidding System Inspection" && el.Service_Component !== "Fixation System Inspection" && el.Service_Component !== "Drilling Equipment Inspection" && el.Service_Component !== "Supply Of Mechanics") ? "No spares are listed" : smresults2.length !== 0 && (el.Service_Component !== "Jacking System Inspection" && el.Service_Component !== "Crane System Inspection" && el.Service_Component !== "Skidding System Inspection" && el.Service_Component !== "Fixation System Inspection" && el.Service_Component !== "Drilling Equipment Inspection" && el.Service_Component !== "Supply Of Mechanics") ? message : "NA"}
                      {" "}{alpha.length !== 0 && ("(" + alpha.join(',')) + " - all spares onboard"}{" "}{((el.Service_Component !== "Jacking System Inspection" && el.Service_Component !== "Crane System Inspection" && el.Service_Component !== "Skidding System Inspection" && el.Service_Component !== "Fixation System Inspection" && el.Service_Component !== "Drilling Equipment Inspection" && el.Service_Component !== "Supply Of Mechanics")) && (<NavLink to={"/spares/" + el.ID_No}>View Details</NavLink>)}{" "}{smresults2.length !== smresults3.length && (el.Service_Component !== "Jacking System Inspection" && el.Service_Component !== "Crane System Inspection" && el.Service_Component !== "Skidding System Inspection" && el.Service_Component !== "Fixation System Inspection" && el.Service_Component !== "Drilling Equipment Inspection" && el.Service_Component !== "Supply Of Mechanics") || (smresults2.length === smresults3.length && smresults2.length === 0 && (el.Service_Component !== "Jacking System Inspection" && el.Service_Component !== "Crane System Inspection" && el.Service_Component !== "Skidding System Inspection" && el.Service_Component !== "Fixation System Inspection" && el.Service_Component !== "Drilling Equipment Inspection" && el.Service_Component !== "Supply Of Mechanics"))
                        ?
                        <>
                          <BootstrapTooltip title={check[0]?.Issue7 ? check[0]?.Issue7 : "--"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>

                          </BootstrapTooltip>
                        </> : null
                      }
                    </td> */}
                    <td style={{
                      backgroundColor: 'white',
                      color: '#567191',
                      minWidth: "300px",
                      maxWidth: "300px",
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      overflow: 'visible',
                    }}>
                      {check[0]?.Issue7 ? check[0]?.Issue7 : "--"}
                    </td>
                    <td>{el.Estimated_Date_Of_Commencement ? el.Estimated_Date_Of_Commencement : "-"}</td>
                    <td>{el.Supervisor_Names ? el.Supervisor_Names : ""}{el.Supervisor_Names && el.Technician_Names ? "," : ""}{el.Technician_Names ? el.Technician_Names : ""}{(el.Supervisor_Names || el.Technician_Names) && el.Trainee_Names ? "," : ""} {el.Trainee_Names ? el.Trainee_Names : ""}</td>
                    <td>{el.Po_Days ? el.Po_Days : "-"}</td>
                  </tr>)
                })}
              </tbody>
            </table>
          </form>
        )}
      </div>
      {(stats === "Closed") && (
        <form id="Closed">
          <table className={styles.rigtable}>
            <thead>
              <tr>
                <th>S. No</th>
                <th> Rig Name </th>
                <th>SO No</th>
                <th> Scope </th>
                <th> PO Status </th>
                <th>Final report status</th>
                <th>Invoice status</th>
                <th>Demobilization date</th>
                <th>Ops Engineer</th>
              </tr>
            </thead>
            <tbody>
              {closeddetailsfil.map((el, index) => {

                let check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
                let parts1 = el.Estimated_Project_Completion_Month.split("/");
                let day1 = parseInt(parts1[0]);
                let month1 = parseInt(parts1[1]) - 1;
                let year1 = parseInt(parts1[2]);
                const estimatedcomp = new Date(year1, month1, day1);
                let diff = presentdate.getTime() - estimatedcomp.getTime();
                let days = Math.round(diff / (24 * 60 * 60 * 1000));

                return (<tr key={el.ID_No}>
                  <td>
                    {index + 1}
                  </td>
                  <td> <NavLink
                    to={"/project_checklist/" + el.ID_No}>{el.Rig_Name ? el.Rig_Name : "-"}</NavLink>
                  </td>
                  <td>{el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                  <td>{el.Service_Component ? el.Service_Component : "-"}</td>
                  <td style={{ backgroundColor: el.Po_No === '' ? '#FF6A6A' : 'white', color: el.Po_No === '' ? 'white' : 'black' }}>
                    {el.Po_No === "" ? "PO not received" : "PO received" + "(" + el.Po_No + ")"}{" "}{el.Po_No === "" ?
                      <>
                        <BootstrapTooltip title={check[0]?.Issue4 ? check[0]?.Issue4 : "--"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            color="white"
                            fill="currentColor"
                            class="bi bi-info-circle"
                            viewBox="0 0 16 16"
                            style={{ cursor: "pointer" }}
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>

                        </BootstrapTooltip>
                      </> : null
                    }
                  </td>
                  <td
                    style={{
                      background:
                        check[0]?.Item39 !== "Completed" &&
                          days > 14

                          ? "#FF6A6A"
                          : "",
                      color:
                        check[0]?.Item39 !== "Completed" &&
                          days > 14

                          ? "white"
                          : "",
                    }}
                  >
                    {check[0]?.Item39 ? check[0]?.Item39 : "Not Completed"}{" "}{check[0]?.Item39 !== "Completed" ?
                      <>
                        <BootstrapTooltip title={check[0]?.Issue39 ? check[0]?.Issue39 : "--"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            class="bi bi-info-circle"
                            viewBox="0 0 16 16"
                            style={{
                              color:
                                check[0]?.Item39 !== "Completed" &&
                                  days > 14

                                  ? "white"
                                  : "black",
                              cursor: "pointer"
                            }}
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>

                        </BootstrapTooltip>
                      </> : null}
                  </td>
                  <td
                    style={{
                      background:
                        check[0]?.Item37 !== "Completed" &&
                          days > 5

                          ? "#FF6A6A"
                          : "",
                      color:
                        check[0]?.Item37 !== "Completed" &&
                          days > 5

                          ? "white"
                          : "",
                    }}
                  >
                    {check[0]?.Item37 ? check[0]?.Item37 : "Not Completed"}{" "}{check[0]?.Item37 !== "Completed" ?
                      <>
                        <BootstrapTooltip title={check[0]?.Issue37 ? check[0]?.Issue37 : "--"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            fill="currentColor"
                            class="bi bi-info-circle"
                            viewBox="0 0 16 16"
                            style={{
                              color:
                                check[0]?.Item37 !== "Completed" &&
                                  days > 5

                                  ? "white"
                                  : "black",
                              cursor: "pointer"
                            }}
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>

                        </BootstrapTooltip>
                      </> : null}
                  </td>
                  <td>{el.Estimated_Project_Completion_Month ? el.Estimated_Project_Completion_Month : "-"}</td>
                  <td>{el.Ops_Engineer ? el.Ops_Engineer : "-"}</td>
                </tr>)
              })}
            </tbody>
          </table>
        </form>
      )}
      <div>
        {dailyTracker === 2 && (
          <DailyTrackerWorkshop />
        )}
      </div>
    </>
  )
}