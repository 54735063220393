import React, { useState, useEffect } from 'react';
import styles from '../Components/css/Travelattendanceview.module.css';
import dayjs from 'dayjs';

import { useNavigate, useParams, useLocation } from "react-router-dom";

//import { styled } from '@mui/system';
import axios from "axios";

import { NavLink } from "react-router-dom";




const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const Travelattendanceview = () => {



  const navigate = useNavigate();
  const [travellogs,setTravellogs]=useState([]);
  const [tabno, setTabno] = useState(2);







    






    const handleDivClick = () => {
      navigate(`/travelattendance_createnew`);
    };
  
    const handleDivClick1 = () => {
      navigate(`/travelattendance_view`);
    };



      useEffect(() => {
        const fetchTravellogs = async () => {
          try {
            const response = await axios.get(
              "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshoptravellogs"
            );
            setTravellogs(response.data.data);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchTravellogs();
      }, []);

      travellogs.sort((a, b) => {
        const datePartsA = a.Date.split('/').map(Number);
        const datePartsB = b.Date.split('/').map(Number);
      
        // Compare years
        if (datePartsA[2] !== datePartsB[2]) {
          return datePartsB[2] - datePartsA[2];
        }
        // If years are equal, compare months
        if (datePartsA[1] !== datePartsB[1]) {
          return datePartsB[1] - datePartsA[1];
        }
        // If months are equal, compare days
        return datePartsB[0] - datePartsA[0];
      });

return(
    <div className={styles.maindiv}  >
  
        <div className={styles.backsvg}>
        <NavLink  to="/admintaskbar"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
<path d="M0.939342 10.9393C0.353556 11.5251 0.353556 12.4749 0.939342 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939342 10.9393ZM26 10.5L2 10.5V13.5L26 13.5V10.5Z" fill="white"/>
</svg></NavLink>
            <h5>Travel Attendance</h5>
        </div>
        <div className={styles.aryTabs}>
        <div
          className={tabno === 1 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick}>
          New Entry
        </div>
        <div
          className={tabno === 2 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick1}>
          Travel Logs
        </div>
 


      </div>
       

      <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead >
                <tr>
                  <th>S. No.</th>
                  <th>Techs</th>
                  <th>Date</th>
                  <th>Attendance</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {travellogs.map((item,index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.Tech}</td>
                    <td>{item.Date}</td>
                    <td>{item.Attendance}</td>
                    <td>{item.Remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>



        </div>






)
}

export default Travelattendanceview;
