import React, { useState, useEffect, useRef } from "react";
import styles from "../Components/css/PurchaseIncomingDeliveries.module.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';


function Purchase_Incoming_Deliveries() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { Purchase_id } = useParams();
  const [activeTab, setActiveTab] = useState("Incoming Deliveries");
  const [refresh, setrefresh] = useState(false);
  const [featureincomingdelivery,setfeatureincomingdelivery] = useState([]);
 
  const [featureincomingdeliverycreate,setfeatureincomingdeliverycreate] = useState([]);
  const handleDivClick = (tab, route) => {
    setActiveTab(tab);
    navigate(`${route}${Purchase_id}`);
  };
  
  const [indetails, setindetails] = useState([]);
  const [isIncoming, setIsIncoming] = useState(true);

  const handleIncomingClick = () => {
    setIsIncoming(true);
  };

  const handleOutgoingClick = () => {
    setIsIncoming(false);
  };

  const handleCreateNewClick = () => {
   navigate(`/purchase_create_new_incoming/${Purchase_id}`)
  }

    const handleDeliveryDivClick = (S_No) => {
    navigate(`/purchaseincomingdeliveriesupdate/${Purchase_id}/${S_No}`);
  }


    useEffect(() => {
    async function fetchsmDetails() {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseincomingdelivery_details?ID_No=${Purchase_id}`
        );
        setindetails(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchsmDetails();
  }, [refresh]);


  const getBackgroundColor = (el) => {
    return el.Received_Delivery_Status === "1" ? '#077D7D' : el.Expected_Arrival_Date < getCurrentDate() ? '#900909' : '#EFC221';
  };

  const getTextColor = (el) => {
    return el.Received_Delivery_Status === "1" ? '#FFFFFF' : el.Expected_Arrival_Date < getCurrentDate() ? '#FFFFFF' : '#000000';
  };

  const getButtonText = (el) => {
    return el.Received_Delivery_Status === "1" ? 'RECEIVED' : el.Expected_Arrival_Date < getCurrentDate() ? 'PENDING' : 'UPCOMING';
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }

    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(()=>{
    async function fetchRigDetails(){
    try{
      const response = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
      );
    
    
 
      const workshoppurchasesincomingdeliveries= response.data.filter((el)=>{
        return(
        el.Group_Name === localStorage.getItem("Group") &&
        el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Purchases Incoming Deliveries" && (el.Access === "Can View" || el.Access === "Can Edit")
        );
      })
 
       
      const workshoppurchasesincomingdeliveriescreate= response.data.filter((el)=>{
        return(
        el.Group_Name === localStorage.getItem("Group") &&
        el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Purchases Incoming Deliveries Create" && (el.Access === "Can View" || el.Access === "Can Edit")
        );
      })

      setfeatureincomingdelivery(workshoppurchasesincomingdeliveries);
      setfeatureincomingdeliverycreate(workshoppurchasesincomingdeliveriescreate);
    
    }catch(error){
      console.error(error);
    }
    }
    fetchRigDetails();
      },[]);

  return (
    
    <div className={styles.topdiv}>
    <div className={styles.sections1}>
        {" "}
        {/*Top_Bar_Div*/}
        <div
          className={
            activeTab === "Purchase Details" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick("Purchase Details", "/purchase_details/")
          }
        >
          Purchase Details
        </div>
        <div
          className={
            activeTab === "Item Details" ? styles.DivTabs1 : styles.DivTabs2
          }
          onClick={() => handleDivClick("Item Details", "/item_details/")}
        >
          Item Details
        </div>
        <div
          className={
            activeTab === "Incoming Deliveries"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Incoming Deliveries",
              "/purchase_incoming_deliveries/"
            )
          }
        >
          Incoming Deliveries
        </div>
        <div
          className={
            activeTab === "Incoming Inspections"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Incoming Inspections",
              "/purchase_incoming_inspections/"
            )
          }
        >
          Incoming Inspections
        </div>
        <div
          className={
            activeTab === "Items Received"
              ? styles.DivTabs1
              : styles.DivTabs2
          }
          onClick={() =>
            handleDivClick(
              "Items Received",
              "/purchase_items_received/"
            )
          }
        >
          Items Received
        </div>
      </div>
      {featureincomingdelivery.length >0 ?(
      <div className={styles.sections2}>
        
          <div style={{marginBottom:"55px"}}>
           {featureincomingdeliverycreate.length !==0 && (
            <button
              variant="contained"
              className={styles["btn-Createnew"]}
              onClick={handleCreateNewClick}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 20C10.5 20.3978 10.658 20.7794 10.9393 21.0607C11.2206 21.342 11.6022 21.5 12 21.5C12.3978 21.5 12.7794 21.342 13.0607 21.0607C13.342 20.7794 13.5 20.3978 13.5 20V13.5H20C20.3978 13.5 20.7794 13.342 21.0607 13.0607C21.342 12.7794 21.5 12.3978 21.5 12C21.5 11.6022 21.342 11.2206 21.0607 10.9393C20.7794 10.658 20.3978 10.5 20 10.5H13.5V4C13.5 3.60218 13.342 3.22064 13.0607 2.93934C12.7794 2.65804 12.3978 2.5 12 2.5C11.6022 2.5 11.2206 2.65804 10.9393 2.93934C10.658 3.22064 10.5 3.60218 10.5 4V10.5H4C3.60218 10.5 3.22064 10.658 2.93934 10.9393C2.65804 11.2206 2.5 11.6022 2.5 12C2.5 12.3978 2.65804 12.7794 2.93934 13.0607C3.22064 13.342 3.60218 13.5 4 13.5H10.5V20Z"
                  fill="white"
                />
                <defs>
                  <rect width="20" height="20" fill="white" />
                </defs>
              </svg>{" "}
              Create New Incoming Delivery
            </button>
            )}
          </div>
        

       
          <div>
               { indetails
            .slice() // Create a shallow copy of the array
            .sort((a, b) => b.S_No - a.S_No) // Sort in descending order based on the S_No         
            .map((el, index) => (
              <div key={index} className={styles.projectList}
                onClick={() => handleDeliveryDivClick(el.S_No)}>
                <div className={styles.textContainer}>
                  {`ID: ${el.S_No || '-'} \u00a0|\u00a0 
                    Expected Arrival: ${formatDate(el.Expected_Arrival_Date) || '-'} \u00a0|\u00a0 
                    Dispatching Party: ${el.Dispatching_Party || '-'} \u00a0|\u00a0 
                    Supplier CIPL: ${el.CIPL_Doc_No || '-'} \u00a0|\u00a0 
                    Ergon BOE: ${el.Entry_Bill_No || '-'}`}
                </div>

                <button
                  className={styles.deliveryStatusButton}
                  style={{ backgroundColor: getBackgroundColor(el), color: getTextColor(el) }}>
                  {getButtonText(el)}
                </button>
                </div>
            ))
}
          </div>
        
      </div>
  
    ):(
      <div style={{ marginLeft: "30%", marginTop: "15%" }}>
      <h3>
        You do not have permissions to view this page.
      </h3>
    </div>
    )
}
    </div>
  );
}

export default Purchase_Incoming_Deliveries;
