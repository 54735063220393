import React from "react";
import "../Components/css/preservationform.css";
import Erogonlogo from "../Components/Erogonlogo.png";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Preservationform() {
  return (
    <div>
      <div>
        <div>
          <img
            src={Erogonlogo}
            className="ergonlogo2"
            height="69"
            width="299"
            style={{
              textAlign: "center",
              marginLeft: "50px",
              marginTop: "85px",
            }}
          />
        </div>
        <h2 className="pagenumber"></h2>
        <div style={{ width: "1400px", marginLeft: "50px" }}>
          <table className="preservetable table-bordered">
            <tr>
              <td
                colspan="11"
                style={{ textAlign: "center", backgroundColor: "#dee2e6" }}>
                SRP PRESERVATION FORM
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "white" }} colSpan="1">
                Rig
              </td>
              <td style={{ backgroundColor: "white" }} colSpan="7">
                SDM
              </td>

              <td colspan="2" style={{ backgroundColor: "white" }}>
                Preservation Doc No:
              </td>
              <td style={{ backgroundColor: "white" }}>SRPC-622121</td>
            </tr>
            <tr>
              <td colSpan="1">P.O No.</td>
              <td colSpan="7">10392353</td>
              <td colspan="2">Date</td>
              <td>01-11-2022</td>
            </tr>
            <tr>
              <td>
                Equipment
                <br />
                ID/Traceability
              </td>
              <td colSpan="6">Equipment Details</td>

              <td>Preservation checklist</td>
              <td colSpan="2">Performed on</td>
              <td>
                Next preservation
                <br />
                evaluation
              </td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="3"></td>
              <td colSpan="3"></td>
              <td colspan="4"> General Storage Checklist</td>
            </tr>
            <tr>
              <td rowspan="6">ET-SRP-07</td>
              <td colSpan="3">Equipment Name :</td>
              <td colSpan="3">Torque Wrench</td>
              <td colspan="1">
                Checked and verified for
                <br />
                dust, oil drops or any other
                <br />
                debris before storage
              </td>
              <td colspan="2">01-11-2022</td>
              <td>31-10-2023</td>
            </tr>
            <tr>
              <td colspan="3">
                Manufacturer
                <br /> :
              </td>
              <td colSpan="3">
                Proto
                <br />
              </td>
              <td colspan="1" rowSpan="2">
                Verified the torque wrench
                <br />
                to be turned down to lowest
                <br />
                possible setting
              </td>
              <td colspan="2" rowSpan="2">
                01-11-2022
              </td>
              <td rowSpan="2">31-10-2023</td>
            </tr>
            <tr>
              <td colSpan="3">Model</td>
              <td colSpan="3">6016C</td>
            </tr>
            <tr>
              <td colSpan="3">Serial No.</td>
              <td colSpan="3">DRK87266</td>

              <td>
                Checked and verified for any <br />
                signs of wear or deterioration
              </td>
              <td colSpan="2">01-11-2022</td>
              <td>31-10-2023</td>
            </tr>

            <tr>
              <td colSpan="3">Calibration Date</td>
              <td colSpan="3">12 Jan 2022</td>
              <td colspan="1">
                Verified storage of wrench
                <br />
                in it's original case
              </td>
              <td colspan="2">01-11-2022</td>
              <td>31-10-2023</td>
            </tr>
            <tr>
              <td colSpan="3">Next Calibration Due Date</td>
              <td colSpan="3">1 Jan 2023</td>
              <td colspan="1">
                Verified the storage location
                <br />
                to be free of humidity to
                <br />
                prevent moisture
                <br />
                accumulation{" "}
              </td>
              <td colspan="2">1-11-2022</td>
              <td>31-10-2023</td>
            </tr>

            <tr>
              <td></td>
              <td colSpan="3"></td>
              <td colSpan="3"></td>
              <td colSpan="4">
                <u>Transportation and Handling Checklist</u>
              </td>
            </tr>
            <tr>
              <td>Supervisor</td>

              <td colspan="5">Roland Wayne Benn</td>

              <td colspan="2">
                Checked and verified that
                <br />
                the Sturdy case was in good
                <br />
                condition
              </td>
              <td colspan="2">01-11-2022</td>
              <td>31-10-2023</td>
            </tr>
            <tr>
              <td>Signature</td>
              <td colSpan="5"></td>

              <td colspan="2">
                Checked and verified the
                <br />
                smooth functioning of the
                <br />
                tool trolly
              </td>
              <td colspan="2">01-11-2022</td>
              <td>31-10-2023</td>
            </tr>
            <tr>
              <td>Approved by</td>

              <td colspan="5">Nishad Majeed</td>
              <td colspan="2">
                Checked and verified the tidy
                <br />
                and stable surface of the
                <br />
                trolley platform
              </td>
              <td colspan="2">01-11-2022</td>
              <td>31-10-2023</td>
            </tr>
            <tr>
              <td>Signature</td>
              <td colSpan="5"></td>

              <td colspan="2">
                Checked and verified friction-
                <br />
                free Packing of the tool
              </td>
              <td colspan="2">01-11-2022</td>
              <td>31-10-2023</td>
            </tr>
            <tr>
              <td colspan="11">
                Note: Preservation is valid till the next project or 1 year
                whichever is earlier. At the end of each project, preservation
                has to be carried out again using the same procedure.
              </td>
            </tr>
          </table>
        </div>
      </div>
      <footer>
        <div>
          <div style={{ textAlign: "left", marginTop: "280px" }}>
            TSD-OPS-FOR-022-21, Rev.1
          </div>
          <div style={{ textAlign: "center", marginTop: "-20px" }}>
            30 Apr 2022
          </div>
        </div>
        <div style={{ textAlign: "right", marginTop: "-20px" }}>1</div>
      </footer>
    </div>
  );
}

export default Preservationform;
