import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styles from "../Components/css/adminpermission.module.css"
// import imagepg from "../Components/image_pg.png";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import style from "./css/modal.module.css";
import { styled } from "@mui/system";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { blue } from '@mui/material/colors';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default function AdminPermission() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [editon, setediton] = useState(false);
  const [smResults, setsmResults] = useState([]);
  const [headerdetails, setheaderdetails] = useState([]);
  const [headerarray, setheaderarray] = useState([]);
  const initialArray = Array(1000).fill("");
  const initialArray1 = Array(1000).fill([]);
  const [selectedheaderarray, setselectedheaderarray] = useState(initialArray);
  const [featurearray, setfeaturearray] = useState(initialArray1);
  const [selectedfeaturearray, setselectedfeaturearray] = useState(initialArray1);
  const [editheader, seteditheader] = useState(initialArray);
  const [editfeature, seteditfeature] = useState(initialArray1);
  const [addhandfheader, setaddhandfheader] = useState(initialArray);
  const [addhandffeature, setaddhandffeature] = useState(initialArray1);
  const [searchviewdetails, setsearchviewdetails] = useState("");
  const [groupname, setgroupname] = useState();
  const [usersearchquery, setusersearchquery] = useState("");
  const [searchResultsadfil, setSearchResultsadfil] = useState([]);
  const [searchResultsadfileee, setSearchResultsadfileee] = useState([]);
  const [formDatasm, setFormDatasm] = useState({
    data: [],
  });
  const [postgroup, setpostgroup] = useState(
    {
      header: [
        {
          name: "",
          features: [
            {
              name: "",
              access: "",
            }
          ]
        }

      ]
    }
  );

  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_headerandfeatures");
        const responseData = response.data;
        setheaderdetails(response.data);
        console.log("API Response:", responseData);
        const headerNames = Array.from(new Set(responseData.map(item => item.Header)));
        console.log("Distinct Header Names:", headerNames);
        setheaderarray(headerNames);
      } catch (error) {
        console.error(error);
      }
    };

    fetchHeaders();
  }, []);

  const handleheader = (event, index) => {
    const head = event.target.value;

    if (selectedheaderarray.includes(head)) {
      // const updated = {...postgroup}
      // updated.header.splice(index, 1);
      // setpostgroup(updated);
      return;
    }

    const updatedPostGroup = { ...postgroup };
    updatedPostGroup.header[index].name = head;
    setpostgroup(updatedPostGroup);
    console.log(updatedPostGroup);
    const featurearray1 = [];
    headerdetails.forEach((item) => {
      if (item.Header === head) {
        featurearray1.push(item.Features);
      }
    });
    const new1 = [...featurearray];
    new1[index] = featurearray1;
    setfeaturearray(new1);
    const newa = [...selectedheaderarray];
    newa[index] = head;
    setselectedheaderarray(newa);
  }

  // useEffect(()=>{
  //   console.log(selectedheaderarray);
  // },[selectedheaderarray]);

  const handlefeature = (event, headerindex, featureindex) => {
    const feat = event.target.value;

    // if (selectedfeaturearray[headerindex].includes(feat)) {
    //   return;
    // }

    const updatedpostgroup = { ...postgroup };
    updatedpostgroup.header[headerindex].features[featureindex].name = feat;
    setpostgroup(updatedpostgroup);
    console.log(updatedpostgroup);

    const featurearr1 = [...selectedfeaturearray];
    featurearr1[headerindex].push(feat);
    setselectedfeaturearray(featurearr1);
  }

  const handleaccess = (event, headerindex, featureindex) => {
    const feat = event.target.value;

    const updatedpostgroup = { ...postgroup };
    updatedpostgroup.header[headerindex].features[featureindex].access = feat;
    setpostgroup(updatedpostgroup);
    console.log(updatedpostgroup);
  }

  const handlegroupname = (event) => {
    const grp = event.target.value;
    setgroupname(grp);
  }

  const handleSubmit3 = async () => {
    try {
      if (/^\s*$/.test(groupname)) {
        alert("Group name is not added");
        return;
      }

      const promises = [];
      postgroup.header.forEach((header) => {
        header.features.forEach((feature) => {
          const payload = {
            Group_Name: groupname,
            Header_Name: header.name,
            Feature_Name: feature.name,
            Access: feature.access,
          };

          const promise = axios.post(
            "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group_add",
            payload
          );
          promises.push(promise);
        });
      });
      const responses = await Promise.all(promises);
      responses.forEach((response) => {
        console.log("Response:", response.data);
      });
    } catch (error) {
      console.error("Error:", error);
    }
    setaddnew(false);
    setgroupname(" ");
    setpostgroup(
      {
        header: [
          {
            name: "",
            features: [
              {
                name: "",
                access: "",
              }
            ]
          }

        ]
      }
    );

    const initialArray2 = Array(1000).fill("");
    setselectedheaderarray(initialArray2);

    const initialArray1 = Array(1000).fill([]);
    setfeaturearray(initialArray1);
    setselectedfeaturearray(initialArray1);
  };

  const handleCancel3 = () => {
    setaddnew(false);
    setgroupname(" ");
    setpostgroup(
      {
        header: [
          {
            name: "",
            features: [
              {
                name: "",
                access: "",
              }
            ]
          }

        ]
      }
    );

    const initialArray2 = Array(1000).fill("");
    setselectedheaderarray(initialArray2);

    const initialArray1 = Array(1000).fill([]);
    setfeaturearray(initialArray1);
    setselectedfeaturearray(initialArray1);
  }


  const addHeader = () => {

    const updatedPostgroup = { ...postgroup };
    updatedPostgroup.header.push({
      name: "",
      features: [
        {
          name: "",
          access: "",
        },
      ],
    });
    setpostgroup(updatedPostgroup);
  };

  const addfeature = (headerIndex) => {

    const updatedPostgroup = { ...postgroup };


    if (headerIndex >= 0 && headerIndex < updatedPostgroup.header.length) {

      updatedPostgroup.header[headerIndex].features.push({
        name: "",
        access: "",
      });


      setpostgroup(updatedPostgroup);
    }
  };

  const navigate = useNavigate();
  // useEffect(() => {
  //   setIsLoggedIn(sessionStorage.getItem("isLoggedIn"));
  // }, [isLoggedIn]);

  const [headerlist, setHeaderlist] = useState([]);
  const [addnew, setaddnew] = useState(false);
  const [bug, setbug] = useState(false);
  const [indexdel, setindexdel] = useState();
  const [searchResultsad, setSearchResultsad] = useState([]);
  const [searchResultsadmem, setSearchResultsadmem] = useState([]);
  const [searchResultsadmemfil, setSearchResultsadmemfil] = useState([]);
  const [searchResultsedit, setSearchResultsedit] = useState([]);
  const [searchResultsgroup, setSearchResultsgroup] = useState([]);
  const [featurelist, setfeaturelist] = useState([])
  const [refresh, setRefresh] = useState(false);
  const [view, setview] = useState(false);
  const [admin, setadmin] = useState(2);

  const [user, setuser] = useState(true);
  const [addnewhandf, setaddnewhansf] = useState([]);
  const [group, setgroup] = useState(false);
  const [mainhead, setmainhead] = useState();

  const [editModes, setEditModes] = useState(Array(searchResultsad.length).fill(false));
  const [currentlyEditingRow, setCurrentlyEditingRow] = useState(-1);
  const [openmodal, setopenmodal] = useState(false);

  const filterAndExtractHeaders = (groupName) => {
    const uniqueHeaders = new Set(); // Create a Set to ensure uniqueness
    searchResultsgroup?.forEach((item) => {
      if (item.Group_Name === groupName) {
        uniqueHeaders.add(item.Header_Name);
      }
    });
    setHeaderlist(Array.from(uniqueHeaders)); // Convert the Set to an array


    uniqueHeaders.forEach((header) => {
      // Find elements in searchResultsgroup with Group_Name === groupName and Header_Name === header
      const matchingElements = searchResultsgroup.filter((item) => {
        return item.Group_Name === groupName && item.Header_Name === header;
      });

      // Extract the "Sno" values from the matching elements
      const snoValues = matchingElements.map((item) => item.Sno);

      // Add the "Sno" values to featurelist for this header
      setfeaturelist(prevFeaturelist => [...prevFeaturelist, snoValues]);
    });
    console.log(featurelist)
  };

  const CustomModal = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;


  const [formData, setFormData] = useState({
    Useremail: "",
    Password: "",
    Name: "",
    Group: "",
  });

  const [formDataed, setFormDataed] = useState({
    Useremail: "",
    Password: "",
    Name: "",
    Group: "",
  });

  const handleuser = () => {
    setuser(true);
    setgroup(false);
    setview(false);
    setfeaturelist([])
    setadmin(2);
    setaddnew(false);
    setediton(false);
    setRefresh(!refresh);
  }

  const handlegroup = () => {
    setuser(false);
    setgroup(true)
  }

  const handlerole = () => {
    navigate("/adminpermission-roles")
  }

  const [showForm, setShowForm] = useState(false);

  const handlenewuser = () => {
    setShowForm(true)
  }

  const handleStopClose = (e) => {
    e.stopPropagation();
  };

  const adminTab = (index) => {
    setadmin(index);
  };



  const buttonap1 = {
    backgroundColor: user ? 'blue' : 'white',
    color: user ? 'white' : 'black', // You can change the text color accordingly
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      Useremail,
      Password,
      Name,
      Group,
    } = formData;

    const payload = {
      Useremail: Useremail,
      Password: Password,
      Name: Name,
      Group: Group,
    };

    const res = await axios.post(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_add",
      payload
    );

    console.log("*************", payload);



    setShowForm(false);
    setFormData({
      Useremail: "",
      Password: "",
      Name: "",
      Group: "",
    });


    if (res.status === 200) {
      alert("User Details Added Successfully");
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        // Fetch the initial admin user data
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_user"
        );

        console.log("Initial response data:", response.data);

        const userEmails = response.data.map((user) => user.Useremail);

        // Fetch role data using the new API
        const rolesResponse = await axios.post(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_user_roles_email",
          { emails: userEmails }
        );

        console.log("Roles response data:", rolesResponse.data);

        // Combine both datasets
        const combinedData = response.data.map((user) => {
          const userRoles = rolesResponse.data.filter(
            (role) => role.Useremail === user.Useremail
          );
          return {
            ...user,
            roles: userRoles.map((role) => role.role_name), // Add roles array to each user
          };
        });

        console.log("Combined data:", combinedData);

        setSearchResultsad(combinedData);
        setSearchResultsadfil(combinedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchRigDetails();
  }, [refresh]);


  useEffect(() => {
    async function fetchRigDetails() {
      try {

        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );

        setSearchResultsgroup(response.data.groupFeatures);

        const filter = response.data.groupFeatures.filter((el) => el.Group_Name == mainhead)
        setsmResults(filter);
        setFormDatasm({
          data: filter,
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
    // setediton(false);
  }, [refresh, bug]);


  const handleeditbtn = () => {
    const newEditFeature = [];
    const newEditHeader = [];

    smResults.forEach((el, index) => {
      newEditHeader[index] = el.Header_Name;
      const featurearray1 = [];

      headerdetails.forEach((item) => {
        if (item.Header === el.Header_Name) {
          featurearray1.push(item.Features);
        }
      });

      newEditFeature[index] = featurearray1;
    });

    seteditfeature(newEditFeature);
    seteditheader(newEditHeader);

    console.log(newEditFeature);
    console.log(newEditHeader);
    setediton(true);
  };






  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleInputChangevish = (e, index) => {
    let { name, value } = e.target;

    const updatedData = [...formDatasm.data];
    updatedData[index][name] = value;
    setFormDatasm({
      ...formDatasm,
      data: updatedData,
    });

    if (name === "Header_Name") {
      const head = [...editheader];
      head[index] = value;
      seteditheader(head);
      const featurearray1 = [];
      headerdetails.forEach((item) => {
        if (item.Header === value) {
          featurearray1.push(item.Features);
        }
      });
      const newf = [...editfeature];
      newf[index] = featurearray1;
      seteditfeature(newf);
    }
  };

  const handlehariinput = (e, index) => {
    const { name, value } = e.target;
    const temp = [...addnewhandf];
    temp[index][name] = value; // Update the correct property using 'name'
    setaddnewhansf(temp);
    if (name === "Header_Name") {
      const head = [...addhandffeature];
      head[index] = value;
      setaddhandfheader(head);
      const featurearray1 = [];
      headerdetails.forEach((item) => {
        if (item.Header === value) {
          featurearray1.push(item.Features);
        }
      });
      const newf = [...addhandffeature];
      newf[index] = featurearray1;
      setaddhandffeature(newf);
    }
  }

  const handleRemovead = (e, index) => {
    e.preventDefault();


    axios.delete(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_delete?S_No=${searchResultsad[index].S_No}`)
      .then((response) => {
        console.log(`Deleted index ${searchResultsad[index].S_No} successfully`);
        // Handle the response or any other logic here
      })
      .catch((error) => {
        console.error(`Error deleting index : ${error.message}`);
        // Handle the error here
      });
    setopenmodal(false);
    setRefresh(!refresh);
  };

  const handleeditad = (e, index) => {
    e.preventDefault();

    const newEditModes = [...editModes];
    newEditModes[index] = true;
    setEditModes(newEditModes);
    setCurrentlyEditingRow(index);

    console.log(editModes)

    setFormDataed({
      Useremail: searchResultsad[index].Useremail,
      Password: searchResultsad[index].Password,
      Name: searchResultsad[index].Name,
      Group: searchResultsad[index].Group,
    })
    console.log(formDataed)

  }

  const handleInputChangemain = async (e, index) => {
    console.log(formDataed);
    const { name, value } = e.target;
    console.log(name, value);
    setFormDataed({
      ...formDataed,
      [name]: value,
    });
  };


  const handlecross = (index) => {
    setEditModes(Array(searchResultsad.length).fill(false));
    setFormDataed({
      Useremail: "",
      Password: "",
      Name: "",
      Group: "",
    })

  }




  const groupCounts = {};
  searchResultsgroup?.forEach((item) => {
    const group = item.Group_Name; // Assuming "Group" is the heading of the column

    groupCounts[group] = (groupCounts[group] || 0) + 1;
    console.log(groupCounts);
  });



  useEffect(() => {
    setSearchResultsadfileee(Object.entries(groupCounts).map(([groupName, count]) => [groupName, count]));
  }, [group]);




  const groupCountssecond = {};
  searchResultsad.forEach((item) => {
    const grouper = item.Group; // Assuming "Group" is the heading of the column
    groupCountssecond[grouper] = (groupCountssecond[grouper] || 0) + 1;
  });



  const handleSubmitedit = async (e, index) => {
    e.preventDefault();
    console.log(formDataed);
    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_update?S_No=${searchResultsad[index].S_No}`,
        formDataed
      );
      const data = res.data; // Access the response data using `data` property
      console.log(data);
      if (res.status === 200) {
        ;
      }
    } catch (error) {
      console.log("Error occurred during request.", error);
      // Handle any request errors
    }
    setEditModes(Array(searchResultsad.length).fill(false));
    setFormDataed({
      Useremail: "",
      Password: "",
      Name: "",
      Group: "",
    })
    setRefresh(!refresh);
  };

  const handleViewFilter = (e, group) => {
    e.preventDefault();
    setmainhead(group);
    filterAndExtractHeaders(group);
    setview(true); // Assuming setView is a function to update some state
    const filteredData = searchResultsad.filter(item => item.Group === group);
    setSearchResultsadmem(filteredData); // Assuming setSearchResultsadmem is a function to update some state
    setSearchResultsadmemfil(filteredData);
    const filteredData2 = searchResultsgroup.filter(item => item.Group_Name === group);
    setSearchResultsedit(filteredData2); // Assuming setSearchResultsadmem is a function to update some state
    console.log(filteredData);
    console.log(headerlist);
    setRefresh(!refresh);
  };

  const handleOpenm = (e, index) => {
    setopenmodal(true);
    console.log("open");
    setindexdel(index)
  }
  const handleClosem = () => {
    setopenmodal(false);
    console.log("close");
    setindexdel();


  }

  const handlenewgroup = () => {
    setaddnew(true);
  }

  const handlecanceleditbtn = () => {
    setaddhandffeature(initialArray1);
    setaddhandfheader(initialArray);
    setediton(false);
    setRefresh(!refresh);
    setaddnewhansf([]);
  }

  const handleaddeditbtn = () => {
    const temp = [...addnewhandf];
    temp.push({
      Group_Name: mainhead,
      Header_Name: "",
      Feature_Name: "",
      Access: ""
    })

    setaddnewhansf(temp);
  }

  const handlesaveeditbtn = async () => {
    try {
      // Make the PUT request
      const putRes = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group_update`,
        smResults
      );
      console.log(putRes);

      // Make POST requests for each item in addnewhandf
      if (addnewhandf.length > 0) {
        const postPromises = addnewhandf.map(async (item) => {
          try {
            const postRes = await axios.post(
              "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group_add",
              item
            );
            console.log(postRes);
          } catch (postError) {
            console.error("Error in POST request:", postError);
          }
        });

        // Wait for all POST requests to complete
        await Promise.all(postPromises);
      }




      console.log(searchResultsgroup);
      console.log(smResults);
      setRefresh(!refresh);
      setbug(!bug);
      filterAndExtractHeaders(mainhead);
      setaddnewhansf([]);
      setaddhandffeature(initialArray1);
      setaddhandfheader(initialArray);
      setediton(false);

    } catch (error) {
      console.error("Error in PUT request:", error);
    }
  };

  const handlesearchuser = (event) => {
    const { value } = event.target;
    setusersearchquery(value);

    const filtered = searchResultsad.filter((search) => {
      const username = search.Name || "";
      const email = search.Useremail || "";
      const group = search.Group || "";

      console.log(username);

      if (username.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }

      if (email.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }

      if (group.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }

      return false;
    });

    setSearchResultsadfil(filtered);

  }
  const handlegroupsearch = (event) => {
    const { value } = event.target;
    const filtered = Object.entries(groupCounts).filter(([group]) => {
      return group.toLowerCase().includes(value.toLowerCase());
    });

    setSearchResultsadfileee(filtered);
    console.log(searchResultsadfileee);
    console.log(groupCounts);

  }


  const handlesearchviewdetails = (event) => {
    const { value } = event.target;
    setsearchviewdetails(value);
    const filter = searchResultsadmem.filter((search) => {
      const username = search.Name || "";
      const email = search.Useremail || "";

      if (username.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }

      if (email.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
      return false;
    });
    setSearchResultsadmemfil(filter);
  }




  return (
    <>
      <div className={styles.upmost}>
        <div className={styles.dashboardheading}> Admin Permissions
          {/* <img src={imagepg} className={styles.imgap}></img> */}
        </div>
        <button className={styles.buttonap1} onClick={handleuser} style={{ backgroundColor: user === true ? '#5B6BE1' : 'white', color: user === true ? 'white' : '#5B6BE1' }} >Users</button>
        <button className={styles.buttonap2} onClick={handlegroup} style={{ backgroundColor: user === false ? '#5B6BE1' : 'white', color: user === false ? 'white' : '#5B6BE1' }}>Groups</button>
        <button className={styles.buttonap3} onClick={handlerole}>Roles</button>
      </div>
      <div className={styles.mainap}>
        {user && (
          <>
            <input type="text" placeholder="Search Username/Email/Group name " onChange={handlesearchuser} value={usersearchquery} className={styles.inputboxap}></input>
            <button className={styles.buttonapuser} onClick={handlenewuser}>Add New User</button>
            <label className={styles.labeluser}>Total user count : {searchResultsad.length} </label>
            <table className={styles.rigtableap}>
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Username</th>
                  <th>Email ID</th>
                  <th>Group Name</th>
                  <th>Roles</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {searchResultsadfil.map((ad, index) => (

                  <tr key={ad.S_No}>
                    <td>{index + 1}</td>
                    <td>

                      {editModes[index] ? (
                        <>
                          <input
                            type="text"
                            className={styles.inputFieldsm}
                            name="Name"
                            Value={searchResultsadfil[index].Name}
                            onChange={(e) => handleInputChangemain(e, index)}
                          />
                        </>
                      ) : ad.Name ? (
                        ad.Name
                      ) : (
                        "-"
                      )}

                    </td>
                    <td>

                      {editModes[index] ? (
                        <input
                          type="text"
                          className={styles.inputFieldsm}
                          name="Useremail"
                          Value={searchResultsadfil[index].Useremail}
                          onChange={(e) => handleInputChangemain(e, index)}
                        />
                      ) : ad.Useremail ? (
                        ad.Useremail
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {editModes[index] ? (
                        <>
                          <select
                            className={styles.inputFieldsm}
                            name="Group"
                            defaultValue={searchResultsadfil[index].Group}
                            onChange={(e) => handleInputChangemain(e, index)}
                          >
                            <option value="">Select a group</option>
                            {Object.keys(groupCounts).map((group, index) => (
                              <option key={index} value={group}>
                                {group}
                              </option>
                            ))}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            style={{
                              marginLeft: "-50px",
                              cursor: "pointer"
                            }}
                          >
                            <path
                              d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                              fill="#394759"
                            />
                          </svg>
                        </>
                      ) : ad.Group ? (
                        ad.Group
                      ) : (
                        "-"
                      )}</td>

                    <td>
                      {ad.roles && ad.roles.length > 0 ? ad.roles.join(", ") : "-"}
                    </td>

                    <td>
                      {!editModes[index] ? (
                        <>
                          <svg style={{ fill: "#5B6BE1" }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => handleeditad(e, index)} >
                            <path d="M17.5 18.333H2.5C2.15833 18.333 1.875 18.0497 1.875 17.708C1.875 17.3663 2.15833 17.083 2.5 17.083H17.5C17.8417 17.083 18.125 17.3663 18.125 17.708C18.125 18.0497 17.8417 18.333 17.5 18.333Z" fill="black" />
                            <path d="M15.8495 2.90005C14.2328 1.28338 12.6495 1.24172 10.9912 2.90005L9.98283 3.90838C9.89949 3.99172 9.86616 4.12505 9.89949 4.24172C10.5328 6.45005 12.2995 8.21672 14.5078 8.85005C14.5412 8.85838 14.5745 8.86672 14.6078 8.86672C14.6995 8.86672 14.7828 8.83338 14.8495 8.76672L15.8495 7.75838C16.6745 6.94172 17.0745 6.15005 17.0745 5.35005C17.0828 4.52505 16.6828 3.72505 15.8495 2.90005Z" fill="black" />
                            <path d="M13.0089 9.60866C12.7673 9.49199 12.5339 9.37533 12.3089 9.24199C12.1256 9.13366 11.9506 9.01699 11.7756 8.89199C11.6339 8.80033 11.4673 8.66699 11.3089 8.53366C11.2923 8.52533 11.2339 8.47533 11.1673 8.40866C10.8923 8.17533 10.5839 7.87533 10.3089 7.54199C10.2839 7.52533 10.2423 7.46699 10.1839 7.39199C10.1006 7.29199 9.95892 7.12532 9.83392 6.93366C9.73392 6.80866 9.61726 6.62533 9.50892 6.44199C9.37559 6.21699 9.25892 5.99199 9.14226 5.75866C8.98929 5.43088 8.55908 5.3335 8.30331 5.58928L3.61726 10.2753C3.50892 10.3837 3.40892 10.592 3.38392 10.7337L2.93392 13.9253C2.85059 14.492 3.00892 15.0253 3.35892 15.3837C3.65892 15.6753 4.07559 15.8337 4.52559 15.8337C4.62559 15.8337 4.72559 15.8253 4.82559 15.8087L8.02559 15.3587C8.17559 15.3337 8.38392 15.2337 8.48392 15.1253L13.1777 10.4315C13.4284 10.1809 13.3342 9.7496 13.0089 9.60866Z" fill="black" />
                          </svg>
                          <label className={styles.editlabel}>Edit</label>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => handleOpenm(e, index)} >
                            <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="black" />
                          </svg>
                          <label className={styles.editlabel2} >Remove</label>
                          {openmodal && (
                            // Your modal content goes here
                            <div className={styles.modal}>
                              <div className={styles.modalcontent}>
                                <p className={styles.modalcontent1}>Remove user</p>
                                <p className={styles.modalcontent2}>Are you sure you want to remove this user?</p>
                                <button onClick={handleClosem} className={styles.modalcontent3}>No</button>
                                <button className={styles.modalcontent3} onClick={(e) => handleRemovead(e, indexdel)} >Yes</button>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <svg
                            style={{ cursor: "pointer" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={(e) => handleSubmitedit(e, index)}>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                              fill="#66D171"
                            />
                          </svg>
                          <svg
                            style={{ cursor: "pointer", marginLeft: "100px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={(e) => handlecross(index)}>
                            <path
                              d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                              fill="#F97575"
                            />
                            <path
                              d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                              fill="#F97575"
                            />
                          </svg>
                        </>
                      )}



                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              {showForm && (
                <div
                  className={styles.popupContainer}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowForm(false);
                  }}>
                  <div className={styles.popUp} onClick={handleStopClose}>
                    <form>
                      <div className={styles.popupItemss}>
                        <b>Add User</b>
                        <div style={{ cursor: "pointer" }}>
                          <svg
                            onClick={() => setShowForm(false)}
                            width="29"
                            height="29"
                            viewBox="0 0 29 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M14.4998 2.41663C7.84192 2.41663 2.4165 7.84204 2.4165 14.5C2.4165 21.1579 7.84192 26.5833 14.4998 26.5833C21.1578 26.5833 26.5832 21.1579 26.5832 14.5C26.5832 7.84204 21.1578 2.41663 14.4998 2.41663ZM18.5598 17.2791C18.9103 17.6295 18.9103 18.2095 18.5598 18.56C18.3786 18.7412 18.149 18.8258 17.9194 18.8258C17.6898 18.8258 17.4603 18.7412 17.279 18.56L14.4998 15.7808L11.7207 18.56C11.5394 18.7412 11.3098 18.8258 11.0803 18.8258C10.8507 18.8258 10.6211 18.7412 10.4398 18.56C10.0894 18.2095 10.0894 17.6295 10.4398 17.2791L13.219 14.5L10.4398 11.7208C10.0894 11.3704 10.0894 10.7904 10.4398 10.44C10.7903 10.0895 11.3703 10.0895 11.7207 10.44L14.4998 13.2191L17.279 10.44C17.6294 10.0895 18.2094 10.0895 18.5598 10.44C18.9103 10.7904 18.9103 11.3704 18.5598 11.7208L15.7807 14.5L18.5598 17.2791Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className={styles.popupItemss}>
                        <label>Name:</label>
                        <input
                          type="text"
                          name="Name"
                          value={formData.Name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={styles.popupItemss}>
                        <label>Email ID:</label>
                        <input
                          type="text"
                          name="Useremail"
                          value={formData.Useremail}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={styles.popupItemss}>
                        <label>Assign Password:</label>
                        <input
                          type="text"
                          name="Password"
                          value={formData.Password}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={styles.popupItemss}>
                        <label> Assign to group:</label>
                        <select
                          name="Group"
                          value={formData.Group}
                          onChange={handleInputChange}
                        >
                          <option value="">Select a group</option>
                          {Object.keys(groupCounts).map((group, index) => (
                            <option key={index} value={group}>
                              {group}
                            </option>
                          ))}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          style={{
                            marginLeft: "-32%",
                            cursor: "pointer"
                          }}
                        >
                          <path
                            d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                            fill="#394759"
                          />
                        </svg>
                      </div>

                      <br />
                      <div className={styles.popupButtons}>
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          className={styles.SubmitButton}
                          size="large"
                          variant="contained">
                          Submit
                        </button>
                        <button
                          className={styles.CancelButton}
                          size="large"
                          onClick={() => setShowForm(false)}>
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </>

        )}

        {group && (
          <>
            {!view &&
              (
                <>
                  {!addnew &&
                    (
                      <>
                        <input type="text" placeholder="Search by Group name " onChange={handlegroupsearch} className={styles.inputboxapgp}></input>

                        <button className={styles.addnewuser} onClick={handlenewgroup}>Add New Group</button>
                        <label className={styles.labeluser2} >Total Group Count :{Object.keys(groupCounts).length}</label>
                        {searchResultsadfileee.map(([group, count]) => (
                          // {Object.keys(groupCounts).length}
                          <div key={group} className={styles.divmain}>
                            <div className={styles.divmain2}>{group}
                              <button className={styles.divmain4} onClick={(e) => handleViewFilter(e, group)}>
                                View details
                              </button></div>
                            <div className={styles.divmain3}>Members: {groupCountssecond[group] || 0}</div>

                          </div>

                        ))}
                      </>
                    )}
                  {addnew &&
                    (
                      <div className={styles.haricode}>
                        <div className={styles.dashboardheading2}>
                          <div className={styles.mainheader}>
                            <div style={{ marginTop: "18px" }}>
                              <span className={styles.mainheaderspan}>Add New Group</span>
                            </div>
                            <div>
                              <button className={styles.mainheaderbutton} style={{ marginRight: "35px" }} onClick={addHeader}>
                                + Add New Header
                              </button>
                              <button className={styles.mainheaderbutton2} onClick={handleCancel3}>
                                Cancel
                              </button>
                              <button className={styles.mainheaderbutton} onClick={handleSubmit3}>
                                Submit
                              </button>
                            </div>
                          </div>
                          <div style={{ marginTop: "25px" }}>
                            <label style={{ color: "black", fontWeight: "700", lineHeight: "normal", fontSize: "16px" }}>Group Name :</label>
                            <input type='text' value={groupname} onChange={handlegroupname} className={styles.groupnameinput} />
                          </div>

                          {postgroup.header.map((header, headerindex) => (
                            <div key={headerindex}>
                              <div className={styles.headerdivstyle}>
                                <div className={styles.headerstyle}>
                                  <span> Header :</span>
                                  <select value={header.name} onChange={(event) => handleheader(event, headerindex)}>
                                    <option value="" ></option>
                                    {headerarray.map((el, index) => (

                                      <option key={index}>{el}</option>

                                    ))}
                                  </select>
                                </div>

                              </div>
                              {header.features.map((feature, featureindex) => (
                                <div key={featureindex} className={styles.featurestyle}>
                                  <span>Feature :</span>
                                  <select value={feature.name} style={{ width: "35%", height: "40px" }} onChange={(event) => handlefeature(event, headerindex, featureindex)}>
                                    <option value=""></option>
                                    {featurearray[headerindex].map((ele, index) => (
                                      <option key={index}>{ele}</option>
                                    ))}
                                  </select>
                                  <select value={feature.access} style={{ width: "120px" }} onChange={(event) => handleaccess(event, headerindex, featureindex)}>
                                    <option value=""></option>
                                    <option>Can View</option>
                                    <option>Hidden</option>
                                  </select>
                                </div>
                              ))

                              }

                              <button className={styles.mainheaderbutton} style={{ marginTop: "30px" }} onClick={() => addfeature(headerindex)}>
                                Add New Feature
                              </button>
                            </div>
                          ))

                          }

                        </div>


                      </div>
                    )}
                </>
              )}
            {view && (
              <>
                <h1 className={styles.mainheadview}>{mainhead}</h1>

                <div style={{ display: "flex", paddingTop: "10px" }}>

                  {!editon && (<div
                    className={
                      admin === 2
                        ? styles.activePersonalMobilisationTab2
                        : styles.personalMobilisationTab2
                    }
                    onClick={() => adminTab(2)}>Members</div>)}
                  {!editon && (<div
                    className={
                      admin === 1
                        ? styles.activePersonalMobilisationTab
                        : styles.personalMobilisationTab
                    }
                    onClick={() => adminTab(1)}>
                    Features
                  </div>)}

                </div>

                {admin === 2 && (
                  <>
                    <label className={styles.labeluser3}>Total Member Count: {Object.keys(searchResultsadmem).length}</label>
                    <input type="text" placeholder="Search Username/Email " className={styles.inputboxap} onChange={handlesearchviewdetails}></input>


                    <table className={styles.rigtableap}>
                      <thead>
                        <tr>
                          <th>SI.No</th>
                          <th>Username</th>
                          <th>Email ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResultsadmemfil.map((ade, index) => (
                          <tr key={ade.S_No}>
                            <td>{index + 1}</td>
                            <td>
                              {ade.Name}

                            </td>
                            <td>
                              {ade.Useremail}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
                {admin === 1 && (
                  <>
                    {editon ? (
                      <div style={{ display: "flex", marginTop: "-60px", justifyContent: "space-between", width: "30%", marginLeft: "70%" }}>
                        <button className={styles.editbutton2} onClick={handleaddeditbtn}>Add new Feature</button>
                        <button className={styles.editbutton3} onClick={handlesaveeditbtn}>Save</button>
                        <button className={styles.editbutton1} onClick={handlecanceleditbtn}>Cancel</button>


                      </div>
                    ) : (
                      <button className={styles.editbutton} onClick={handleeditbtn}>Edit</button>
                    )}
                    {!editon && (
                      <div className={styles.pushdown} >
                        {headerlist.map((header, index) => (
                          header && (
                            <div >
                              <div className={styles.mainheadviewalpha}>
                                <div key={index} className={styles.mainheadviewbeta} >{header}</div>
                              </div>
                              <div>
                                {featurelist[index].map((number, subIndex) => (
                                  <div key={subIndex} className={styles.mainheadviewgamma}>
                                    {searchResultsgroup.find(item => item.Sno === number).Feature_Name}
                                    <h5 className={styles.mainheadviewdelta}>{searchResultsgroup.find(item => item.Sno === number).Access}</h5>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                        ))}
                      </div>
                    )}
                    {editon && (
                      <table className={styles.rigtableap}>
                        <thead>
                          <tr>
                            <th>SI.No</th>
                            <th>Header</th>
                            <th>Feature</th>
                            <th>Access</th>
                          </tr>
                        </thead>
                        <tbody>
                          {smResults.map((ele, index) => (
                            <tr key={ele.Sno}>
                              <td>{index + 1}</td>
                              <td>

                                <select
                                  className={styles.inputFieldsm}
                                  name="Header_Name"
                                  value={smResults[index].Header_Name}
                                  onChange={(e) => handleInputChangevish(e, index)}>
                                  <option value=" "> </option>
                                  {headerarray.map((el, i) => (
                                    <option key={i}>{el}</option>
                                  ))}



                                </select>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  style={{
                                    marginLeft: "-35px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <path
                                    d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                    fill="#394759"
                                  />
                                </svg>
                              </td>
                              <td>
                                <select
                                  className={styles.inputFieldsm}
                                  name="Feature_Name"
                                  value={smResults[index].Feature_Name}
                                  onChange={(e) => handleInputChangevish(e, index)}
                                >
                                  <option value=""></option>
                                  {editfeature[index].map((el, i) => (
                                    <option key={i}>{el}</option>
                                  ))}
                                </select>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  style={{
                                    marginLeft: "-35px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <path
                                    d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                    fill="#394759"
                                  />
                                </svg>
                              </td>
                              <td>
                                <select
                                  className={styles.inputFieldsm}
                                  name="Access"
                                  value={smResults[index].Access}
                                  onChange={(e) => handleInputChangevish(e, index)}
                                > <option value="">Select</option>
                                  <option value="Hidden">Hidden</option>
                                  <option value="Can View">Can View</option>
                                </select>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  style={{
                                    marginLeft: "-35px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <path
                                    d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                    fill="#394759"
                                  />
                                </svg>

                              </td>
                            </tr>
                          ))}

                          {addnewhandf.map((el, index) => (
                            <tr>
                              <td>
                                {smResults.length + index + 1}
                              </td>
                              <td>
                                <select
                                  className={styles.inputFieldsm}
                                  name="Header_Name"
                                  value={el.Header_Name}
                                  onChange={(e) => handlehariinput(e, index)}>
                                  <option value=" "> </option>
                                  {headerarray.map((el, i) => (
                                    <option key={i}>{el}</option>
                                  ))}



                                </select>
                                {/* <input 
                                type="text"
                                className={styles.inputFieldsm}
                                name="Header_Name"
                                value={el.Header_Name}
                                onChange={(e) => handlehariinput(e, index)}
                              /> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  style={{
                                    marginLeft: "-35px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <path
                                    d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                    fill="#394759"
                                  />
                                </svg>
                              </td>

                              <td>
                                <select

                                  className={styles.inputFieldsm}
                                  name="Feature_Name"
                                  value={el.Feature_Name}
                                  onChange={(e) => handlehariinput(e, index)}
                                >
                                  <option value=" "> </option>
                                  {addhandffeature[index].map((el, i) => (
                                    <option key={i}>{el}</option>
                                  ))}
                                </select>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  style={{
                                    marginLeft: "-35px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <path
                                    d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                    fill="#394759"
                                  />
                                </svg>
                              </td>
                              <td>

                                <select
                                  className={styles.inputFieldsm}
                                  name="Access"
                                  value={el.Access}
                                  onChange={(e) => handlehariinput(e, index)}
                                > <option value="">Select</option>
                                  <option value="Hidden">Hidden</option>
                                  <option value="Can View">Can View</option>
                                </select>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  style={{
                                    marginLeft: "-35px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <path
                                    d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                    fill="#394759"
                                  />
                                </svg>
                              </td>

                            </tr>
                          ))}
                        </tbody>
                      </table>


                    )}

                  </>
                )}
              </>
            )}
          </>

        )}



      </div>
    </>
  )
}
