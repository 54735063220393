import { React } from 'react';
import styles from "./css/itemdetailworkshop.module.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
        backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
        width: "230px",
        "&:hover": {
            "& fieldset": {
                borderColor: "1px solid #e2e6ff",
            },
        },
        "&:not(.Mui-focused)": {
            "& fieldset": {
                border: "1px solid #e2e6ff",
                borderRadius: "8px",
            },
        },
        "&.Mui-focused": {
            "& fieldset": {
                border: "1px solid #e2e6ff",
                borderRadius: "8px",
            },
        },
    },
    "& input.Mui-disabled": {
        opacity: 1,
        "-webkit-text-fill-color": "black", // For WebKit-based browsers
        color: "black", // Fallback for other browsers
    },
}));


const ItemDetailsWorkshop = () => {
    const { id } = useParams();
    const [showInputRow, setShowInputRow] = useState(false);
    const [smResults, setsmResults] = useState([]);
    const [editModesm, seteditModesm] = useState(false);
    const [workshop, setWorkshop] = useState(23);
    const [rows, setRows] = useState([]);   //to track the rows in the table
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showeditRow, setShoweditRow] = useState(false);
    const [containerStyle, setContainerStyle] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [formDatasm, setFormDatasm] = useState({    //used for storing the table data in the editable state, editModesm
        data: [],
    });
    const [openRemoveConfirmationModal, setOpenRemoveConfirmationModal] = useState(false);
    const [isOEMDisabled, setIsOEMDisabled] = useState([]);
    const [isOEMDisabledAddRow, setIsOEMDisabledAddRow] = useState(true);
    const [SNoToDelete, setSNoToDelete] = useState([]);

    const [isDropdownVisibleAddRow, setDropdownVisibleAddRow] = useState(false);
    const [isPartDropdownVisibleAddRow, setPartDropdownVisibleAddRow] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isPartDropdownVisible, setPartDropdownVisible] = useState(false);
    const [heightRefresh, setHeightRefresh] = useState(false);

    const [formdata1, setFormData1] = useState({
        salesOrderNo: "",

        PONO: "",
        PODays: "",
        PODate: "",
        QuoteNO: "",
        QuoteDate: "",
        CostingID: "",
        ProjectName: "",
        Scope: "",
        EstimatedDateCommencement: "",
        CustomerName: "",
        FirstArrival: "",
        TargetDaysForCompletion: "",
        TargetManhours: "",
        opsEngineer: "",
        quantity: "",
        projectDescription: "",
        currentStatus: "",
        Supervisors: "",
        Technicians: "",
        Trainees: "",
    });

    const [confirmationData, setConfirmationData] = useState({
        extractedSNo: null,
        ItemTraceability: "",
        CurrentStatus: "",
    });

    const [formDataAddRow, setFormDataAddRow] = useState({    //used for storing the data for the input row on clicking the "Add Items Manually" button
        data: [],
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleDateChange = (name, value, index) => {
        setFormDatasm((prevFormDatasm) => {
            const updatedData = [...prevFormDatasm.data];
            if (!updatedData[index]) {
                updatedData[index] = {};
            }
            updatedData[index] = {
                ...updatedData[index],
                [name]: value ? value.format("YYYY-MM-DD") : null,
            };
            return {
                ...prevFormDatasm,
                data: updatedData,
            };
        });
        console.log("formDatasm input change:", formDatasm);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        setFormDatasm((prevFormDatasm) => {
            const updatedData = [...prevFormDatasm.data];
            if (!updatedData[index]) {
                updatedData[index] = {};
            }
            updatedData[index] = {
                ...updatedData[index],
                [name]: value,
            };
            return {
                ...prevFormDatasm,
                data: updatedData,
            };
        });
        console.log("formDatasm input change:", formDatasm);
    };

    const handleInputChangevis = (e) => {
        const { name, value } = e.target;
        setFormDataAddRow((prev) => ({
            ...prev,
            [name]: value,
        }));
        console.log("formDataAddRow input change:", formDataAddRow);
    };

    const handleDateChangevis = (name, value) => {
        setFormDataAddRow((prev) => ({
            ...prev,
            [name]: value ? value.format("YYYY-MM-DD") : null,
        }));
        console.log("formDataAddRow input change:", formDataAddRow);
    };

    const handleDeleteRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        setShowInputRow(false);
        setIsButtonDisabled(false);
        setShoweditRow(false);
        seteditModesm(false);
        setDropdownVisibleAddRow(false);
        setPartDropdownVisibleAddRow(false);
        setDropdownVisible(false);
        setPartDropdownVisible(false);
        for (let i = 0; i < smResults.length; i++) {
            setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [i]: true }));
        }
        setIsOEMDisabledAddRow(true);
        setFormDataAddRow({
            Item_Traceability: "",
            Current_Status: "",
            Assigned_Supervisor: "",
            Requirements: "",
            Start_Date: "",
            Estimated_Completion_Date: "",
            Comments: "",
        });
        setRefresh(!refresh);
    };
    const [supervisors, setSupervisors] = useState([]);

    useEffect(() => {
        axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/get-supervisors')
            .then((response) => {
                setSupervisors(response.data);
            })
            .catch((error) => {
                console.error('Error fetching supervisors:', error);
            });
    }, []);

    useEffect(() => {
        const fetchProject = async () => {
            const res = await axios.get(
                "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
            );
            const filter = res.data.data.filter((el) => el.ID_No == id);

            setFormData1({
                ...formdata1,
                salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
                PONO: filter[0]?.PO_No?.toString(),
                PO_Date: filter[0]?.PODate,
                QuoteNo: filter[0]?.Quote_No,
                QuoteDate: filter[0]?.Quote_Date,
                CostingID: filter[0]?.Costing_ID,
                ProjectName: filter[0]?.Project_Name,
                Scope: filter[0]?.Scope,
                projectDescription: filter[0]?.Project_Description,
                quantity: filter[0]?.Quantity,
                CustomerName: filter[0]?.Customer_Name,
                FirstArrival: filter[0]?.First_Arrival,
                TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
                opsEngineer: filter[0]?.Ops_Engineer,
                TargetManhours: filter[0]?.Target_Manhours,
                currentStatus: filter[0]?.Current_Status,
                Supervisors: filter[0]?.Supervisors,
                Technicians: filter[0]?.Technicians,
                Trainees: filter[0]?.Trainees,

            });

        };
        fetchProject();
    }, [id, refresh]);

    const navigate = useNavigate();

    const handleDivClick = () => {
        navigate(`/project-checklist-workshop/${id}`);
    };

    const handleDivClick1 = () => {
        navigate(`/project-details-workshop/${id}`);
    };

    const handleDivClick2 = () => {
        navigate(`/timesheet-workshop/${id}`);
    };

    const handleDivClick3 = () => {
        navigate(`/incoming-deliveries-workshop/${id}`);
    };

    const handleDivClick4 = () => {
        navigate(`/outgoing-deliveries-workshop/${id}`);
    };

    const handleDivClick5 = () => {
        navigate(`/outsourcing-workshop/${id}`);
    };

    const handleDivClick6 = () => {
        navigate(`/reports-workshop/${id}`);
    };

    const handleDivClick7 = () => {
        navigate(`/inspections-workshop/${id}`);
    };
    const handleDivClick8 = () => {
        navigate(`/items-received-workshop/${id}`);
    };
    const handleDivClick9 = () => {
        navigate(`/finished-goods-workshop/${id}`);
    };

    const handleDivClick11 = () => {
        navigate(`/team-details-workshop/${id}`);
    };


    const handleDivClick14 = () => {
        navigate(`/project-timeline-workshop/${id}`);
    }
    const handleDivClick12 = () => {
        navigate(`/inventory-used-workshop/${id}`);
    };

    const handleDivClick13 = () => {
        navigate(`/purchases-workshop/${id}`);
    };
    const handleDivClick15 = () => {
        navigate(`/production-workshop/${id}`)
    }
    const handleDivClick16 = () => {
        navigate(`/Wip-workshop/${id}`);
    };
    const handleDivClick17 = () => {
        navigate(`/dispatch/${id}`);
    };
    const handleDivClick23 = () => {
        navigate(`/item-details-workshop/${id}`);
    };


    const handleSaveClick = async () => {
        setIsLoading(true);
        setDropdownVisibleAddRow(false);
        setPartDropdownVisibleAddRow(false);
        setDropdownVisible(false);
        setPartDropdownVisible(false);
        try {
            await Promise.all(SNoToDelete.map(async (extractedSNo) => {
                await axios.delete(
                    `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_itemdetails_delete?S_No=${extractedSNo}`
                );
            }));

            // Clear the SNoToDelete array after successful deletions
            setSNoToDelete([]);

            const updatePromises = formDatasm.data.map(async (el) => {
                const updatePayload = {
                    S_No: el.S_No,
                    Item_ID: id,
                    Item_Traceability: el.Item_Traceability,
                    Current_Status: el.Current_Status,
                    Assigned_Supervisor: el.Assigned_Supervisor,
                    Requirements: el.Requirements,
                    Start_Date: el.Start_Date,
                    Estimated_Completion_Date: el.Estimated_Completion_Date,
                    Comments: el.Comments,
                    // Delivery_time: el.Delivery_time,
                };
                console.log("updatePayload", updatePayload);

                // const updatePayload1 = {
                //   Item_Name: el.Item_Name,
                //   Part_Number: el.Part_Number,
                //   Manufacturer_Part_Number: el.Manufacturer_Part_Number,
                //   UoM: el.UoM,
                // }


                // // Check if there are differences in Part_Number or Manufacturer_Part_Number to be put in incoming deliveries purchase_incoming_items table and the purchase_incoming_inspections table
                // const smResult = smResults.find((result) => result.S_No === el.S_No);
                // if (smResult && (smResult.Part_Number !== el.Part_Number || smResult.Manufacturer_Part_Number !== el.Manufacturer_Part_Number || smResult.UoM !== el.UoM)) {
                //   // PUT request for item_details_incoming_items_put
                //   await axios.put(
                //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/item_details_incoming_items_put?Spares_Trading_Project_ID=${id}&Item_Name=${smResult.Item_Name}&Manufacturer_Part_Number=${smResult.Manufacturer_Part_Number}&Part_Number=${smResult.Part_Number}`,
                //     updatePayload1
                //   );

                //   // PUT request for item_details_incoming_inspections_put
                //   await axios.put(
                //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/item_details_incoming_inspections_put?Spares_Trading_Project_ID=${id}&Item_Name=${smResult.Item_Name}&Manufacturer_Part_Number=${smResult.Manufacturer_Part_Number}&Part_Number=${smResult.Part_Number}`,
                //     updatePayload1
                //   );
                // }


                return axios.put(
                    `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_itemdetails_put?S_No=${el.S_No}`,
                    updatePayload
                );
            });

            // Wait for all promises to resolve before proceeding
            await Promise.all(updatePromises);

            // Clear the formDatasm after successful updates
            setFormDatasm({
                data: [],
            });

        } catch (error) {
            console.error("Error submitting data:", error);
        }

        setShoweditRow(false);
        seteditModesm(false);
        setShowInputRow(false);
        setIsButtonDisabled(false);
        setRefresh(!refresh);
        setHeightRefresh(!heightRefresh);
        setIsLoading(false);

    }
    const handleeditsm = () => {
        setShoweditRow(true);
        seteditModesm(true);
    }


    const handleManualInputClick = () => {
        setIsButtonDisabled(true);
        setShowInputRow(true);
        setHeightRefresh(!refresh);
    };

    const handleOpenRemoveConfirmationModal = async (e, SNo, ItemTraceability, CurrentStatus) => {
        // console.log("Yes I am here")
        e.preventDefault();
        setConfirmationData({
            extractedSNo: SNo,
            ItemTraceability: ItemTraceability,
            CurrentStatus: CurrentStatus,
        });
        console.log("data", confirmationData)
        setOpenRemoveConfirmationModal(true);
    }

    async function fetchsmDetails() { //first loading of the page data and states, also used to refresh the page when save, cancel, cross etc. are clicked
        try {


            const response = await axios.get(
                "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_itemdetails_get"
            );

            // const resItemsMaster = await axios.get(
            //   "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
            // );

            const filter = response.data.data.filter((el) => el.Item_ID == id); //get all item details where Spares_Trading_Project_ID is equal to the useParams id

            // Fetch the inventory utilization data
            // const resInventoryUtil = await axios.get(
            //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/inventory_utilization_projectSO_get?Project_SO=${data?.Sales_Order_No}`
            // );

            // Loop through the filtered data and find matching items in inventory utilization data
            // const updatedFilter = filter.map((item) => {
            //   const matchingItems = resInventoryUtil.data.data.filter(
            //     (inventoryItem) =>
            //       inventoryItem.Item_Name === item.Item_Name &&
            //       inventoryItem.Part_Number === item.Part_Number
            //   );

            //   // Sum the Qty_Utilized for the matching items
            //   const totalQtyUtilized = matchingItems.reduce(
            //     (sum, inventoryItem) => sum + (parseFloat(inventoryItem.Qty_Utilized) || 0),
            //     0
            //   );

            //   // Assign the totalQtyUtilized to Stock_Qty
            //   return {
            //     ...item,
            //     Stock_Qty: totalQtyUtilized,
            //   };
            // });

            // setItemsMaster(resItemsMaster.data);
            setsmResults(filter);
            console.log("smResults:", filter);
            // console.log("Items Master:", resItemsMaster.data);
            setFormDatasm({
                data: filter,
            });
            setSNoToDelete([]);
            //Setting the Part_Number input to disabled 
            for (let i = 0; i < filter.length; i++) {
                setIsOEMDisabled((prevVisibility) => ({ ...prevVisibility, [i]: true }));
            }
            setIsOEMDisabledAddRow(true);

            //Adding items to Items Master List if they don't exist already based on Part Number
            const itemsToPost = [];

            // updatedFilter.forEach((smResult) => {
            //     // Normalize Manufacturer_Part_Number to null if it's an empty string
            //     const normalizedManufacturerPartNumber = smResult.Manufacturer_Part_Number ? (smResult.Manufacturer_Part_Number === "" ? null : smResult.Manufacturer_Part_Number) : null;

            //     const matchingItem = resItemsMaster.data.find(
            //         (item) =>
            //             item.Part_Number === smResult.Part_Number &&
            //             item.Item_Name === smResult.Item_Name &&
            //             item.Manufacturer_Part_Number === normalizedManufacturerPartNumber
            //     );

            //     if (!matchingItem) {
            //         // If matchingItem is not found, add it to the itemsToPost array
            //         itemsToPost.push({
            //             Item_Name: smResult.Item_Name,
            //             Part_Number: smResult.Part_Number,
            //             Manufacturer_Part_Number: normalizedManufacturerPartNumber,
            //         });
            //     }
            // });

            // Perform POST requests for itemsToPost
            // for (const itemToPost of itemsToPost) {
            //     try {
            //         const res = await axios.post(
            //             "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemmaster",
            //             itemToPost
            //         );
            //         console.log("Item added to itemsMaster:", itemToPost);
            //     } catch (error) {
            //         console.error("Error occurred while making POST request:", error);
            //     }
            // }

            // if (itemsToPost.length > 0) {
            //     const updatedItemsMaster = await axios.get(
            //         "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster"
            //     );
            //     setItemsMaster(updatedItemsMaster.data);
            //     console.log("Updated Items Master:", updatedItemsMaster.data)
            // }

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {


        fetchsmDetails();
    }, [refresh]);


    const handlecancelsm = () => {
        fetchsmDetails();
        setShoweditRow(false);
        seteditModesm(false);
    }

    const handleRemove = (e, extractedSNo) => {
        try {
            if (extractedSNo !== null) {
                console.log("idToDelete:" + extractedSNo);

                // Store the extractedSNo in the SNoToDelete array
                setSNoToDelete(prevState => [...prevState, extractedSNo]);

                // Update formDatasm and smResults on the frontend
                const updatedFormData = {
                    ...formDatasm,
                    data: formDatasm.data.filter((el) => el.S_No !== extractedSNo)
                };
                setFormDatasm(updatedFormData);

                const updatedsmResults = smResults.filter((sm) => sm.S_No !== extractedSNo);
                setsmResults(updatedsmResults);

                console.log("updatedFormDatasm, updatesmResults", updatedFormData, updatedsmResults);

                setOpenRemoveConfirmationModal(false);
                // setRefresh(!refresh);
            } else {
                console.log("No ID to delete");
            }
        } catch (error) {
            console.error("Error removing data:", error);
        }
    };

    const handleCloseRemoveConfirmationModal = async (e) => {
        e.preventDefault();
        setConfirmationData({
            extractedSNo: null,
            ItemTraceability: "",
            CurrentStatus: "",
        });
        setOpenRemoveConfirmationModal(false);
    }

    const handleSubmitsm = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const {
            Item_Traceability,
            Current_Status,
            Assigned_Supervisor,
            Requirements,
            Start_Date,
            Estimated_Completion_Date,
            Comments,
        } = formDataAddRow;

        const payloadsm = {
            Item_ID: id,
            Item_Traceability: Item_Traceability,
            Current_Status: Current_Status,
            Assigned_Supervisor: Assigned_Supervisor,
            Requirements: Requirements,
            Start_Date: Start_Date,
            Estimated_Completion_Date: Estimated_Completion_Date,
            Comments: Comments,

        };

        try {
            const res = await axios.post(
                "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_itemdetails_post", // create api in the backend
                payloadsm
            );
            console.log("payloadsm:", payloadsm);

            setFormDataAddRow({
                Item_Traceability: "",
                Current_Status: "",
                Assigned_Supervisor: "",
                Requirements: "",
                Start_Date: "",
                Estimated_Completion_Date: "",
                Comments: "",
            });

            if (res.status === 200) {
                alert("Item Added Successfully");
                setIsLoading(false);
                setRefresh(!refresh);
                setHeightRefresh(!heightRefresh);
            }
            console.log("post POST smResults", smResults);
        } catch (error) {
            console.error("Error submitting data:", error);
            setIsLoading(false);
        }
        setIsButtonDisabled(false);
        setShowInputRow(false);
        setIsLoading(false);
    };
    return (
        <>
            <div>
                <div className={styles.topdiv}>
                    <label className={styles.aryl1}>
                        Project Name: {formdata1.ProjectName}
                    </label>
                    <label className={styles.aryl}>Project Scope:{formdata1.Scope}</label>
                    <label className={styles.aryl}>
                        Project Description:{formdata1.projectDescription}
                    </label>
                    <label className={styles.aryl}>Quantity:{formdata1.quantity}</label>
                    <label className={styles.aryl}>
                        Target Days:{formdata1.TargetDaysForCompletion}
                    </label>
                    <label className={styles.aryl}>
                        Target Manhours:{formdata1.TargetManhours}
                    </label>
                </div>
            </div>
            <div className={styles.aryTabs}>
                <div
                    className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick}>
                    Project Checklist
                </div>
                <div
                    className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick1}>
                    Project Details
                </div>
                <div
                    className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick11}>
                    Team Details</div>
                <div
                    className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick12}>
                    Inventory Used
                </div>
                <div
                    className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick13}>
                    Purchases
                </div>
                <div
                    className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick2}>
                    Timesheet
                </div>
                <div
                    className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick3}>
                    Deliveries
                </div>
                <div
                    className={workshop === 23 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick23}>
                    Serial Number
                </div>
                <div
                    className={workshop === 15 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick15}>
                    Production
                </div>
                {/* <div
          className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick4}>
          Outgoing Deliveries
        </div> */}
                <div
                    className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick14}>
                    Timeline
                </div>
                {/* <div
                    className={workshop === 17 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick17}>
                    Dispatch
                </div> */}
                <div
                    className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick5}>
                    Outsourcing
                </div>
                <div
                    className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick6}>
                    Reports
                </div>
                <div
                    className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick7}>
                    Inspections
                </div>
                <div
                    className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick8}>
                    Items Received
                </div>
                <div
                    className={workshop === 11 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick9}
                >
                    Finished goods
                </div>
                <div
                    className={workshop === 16 ? styles.aryTabs1 : styles.aryTabs2}
                    onClick={handleDivClick16}
                >

                    WIP
                </div>
            </div>



            <div className={styles.sections2}>
                <div className={styles.smtablecontainer} style={containerStyle}>

                    <div className={styles.buttonsdiv}
                        style={{
                            border: "2px solid #E7EBFF",
                            borderTop: "none",
                            borderBottom: "none",
                            display: "flex",            // Flexbox for alignment
                            justifyContent: "space-between", // Space between label and buttons
                            alignItems: "center",       // Vertically center the elements

                        }}>
                        <label style={{
                            color: "#5B6BE1",
                            fontFamily: "sans-serif",
                            fontSize: "17px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            marginLeft: "25px",
                            marginTop: "15px"
                        }}>
                            Serial wise item details
                        </label>

                        <div style={{ display: "flex", gap: "0px", marginLeft: 'auto' }}>

                            {showeditRow ? (
                                <>

                                    <button
                                        variant="contained"
                                        onClick={handleSaveClick}
                                        disabled={isLoading}
                                    >
                                        Save Changes
                                    </button>
                                    <button
                                        variant="contained"
                                        className={styles.cancel}
                                        onClick={handlecancelsm}>
                                        Cancel
                                    </button>
                                </>
                            ) : (
                                <>

                                    <button
                                        variant="contained"
                                        onClick={handleeditsm}>
                                        Edit
                                    </button>

                                </>
                            )}
                        </div>
                    </div>

                    <div className={styles.smtablecontainer} >
                        <table className={styles.smtable}>
                            <thead>
                                <tr className={styles.headrowsm}>
                                    {showeditRow && <th></th>}
                                    {showInputRow && !editModesm && <th></th>}
                                    <th> S.No</th>
                                    <th >Item Traceability</th>
                                    <th >Current Status</th>
                                    <th> Assigned Supervisor</th>
                                    <th >Requirements</th>
                                    <th>Start Date</th>
                                    <th >Est. Completion Date</th>
                                    <th >Comments</th>


                                </tr>
                            </thead>
                            <tbody>
                                {smResults.map((sm, index) => (
                                    <tr key={sm.S_No}>
                                        {showeditRow && (
                                            <td>
                                                <div
                                                    className={styles.removeTxtBtn}
                                                    onClick={(e) => handleOpenRemoveConfirmationModal(e, sm.S_No, sm.Item_Traceability, sm.Current_Status)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none">
                                                        <path
                                                            d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
                                                            fill="#567191"
                                                        />
                                                    </svg>
                                                    Remove
                                                </div>
                                            </td>
                                        )}
                                        {showInputRow && !editModesm && <td></td>}
                                        <td>{index + 1}</td>
                                        <td>
                                            {editModesm ? (
                                                <>
                                                    <div style={{ position: "relative" }}>
                                                        <input
                                                            type="text"

                                                            name="Item_Traceability"
                                                            id="inputItemTraceability"
                                                            value={formDatasm.data[index].Item_Traceability}
                                                            onChange={(e) => {
                                                                handleInputChange(e, index);
                                                                setDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                                                            }}
                                                            className={styles.inputFieldsm}
                                                        // style={{ borderColor: "#e2e6ff", borderStyle: "solid" }}
                                                        />
                                                    </div>

                                                </>
                                            ) : sm.Item_Traceability ? (
                                                sm.Item_Traceability
                                            ) : (
                                                "-"
                                            )}
                                        </td>
                                        <td>
                                            {editModesm ? (
                                                <>
                                                    <div style={{ position: "relative" }}>
                                                        <input
                                                            type="text"
                                                            className={styles.inputFieldsm}
                                                            name="Current_Status"
                                                            value={formDatasm.data[index].Current_Status}
                                                            onChange={(e) => {
                                                                handleInputChange(e, index);
                                                                setPartDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                                                            }}
                                                            style={{ borderColor: "#2491eb", borderStyle: "solid" }} // Inline style to set border color
                                                        />

                                                    </div>
                                                </>
                                            ) : sm.Current_Status ? (
                                                sm.Current_Status
                                            ) : (
                                                "-"
                                            )}
                                        </td>
                                        <td>
                                            {editModesm ? (
                                                <>
                                                    <div style={{ position: "relative" }}>
                                                        <select
                                                            className={styles.inputFieldsm1}
                                                            style={{ paddingRight: "30px" }}
                                                            name="Assigned_Supervisor"
                                                            value={formDatasm.data[index].Assigned_Supervisor}
                                                            onChange={(e) => handleInputChange(e, index)}>
                                                            <option value="">Select Supervisor</option>
                                                            {supervisors.map((supervisor, index) => (
                                                                <option key={index} value={supervisor}>
                                                                    {supervisor}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <svg
                                                            style={{ marginLeft: "-25px", marginTop: "-1%", pointerEvents: "none" }}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 28 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                                                fill="black"
                                                            />
                                                        </svg>

                                                    </div>
                                                </>
                                            ) : sm.Assigned_Supervisor ? (
                                                sm.Assigned_Supervisor
                                            ) : (
                                                "-"
                                            )}
                                        </td>

                                        <td>
                                            {editModesm ? (
                                                <>
                                                    <div style={{ position: "relative" }}>
                                                        {/* <input
                                                            type="text"
                                                            className={styles.inputFieldsm}
                                                            name="Manufacturer Part_No."
                                                            value={formDatasm.data[index].Manufacturer_Part_No}
                                                            onChange={(e) => {
                                                                handleInputChange(e, index);
                                                                setPartDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                                                            }}
                                                        /> */}
                                                        <textarea
                                                            name="Requirements"
                                                            value={formDatasm.data[index].Requirements}
                                                            onChange={(e) => {
                                                                handleInputChange(e, index);
                                                                setPartDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                                                            }}
                                                            className={editModesm ? styles.textbox1b : styles.textbox1a}


                                                        />

                                                    </div>
                                                </>
                                            ) : sm.Requirements ? (
                                                sm.Requirements
                                            ) : (
                                                "-"
                                            )}

                                        </td>

                                        <td>
                                            {editModesm ? (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <CustomDatePicker
                                                        name="Start_Date"
                                                        value={formDatasm.data[index]?.Start_Date ? dayjs(formDatasm.data[index].Start_Date, "YYYY-MM-DD") : null}
                                                        onChange={(value) => handleDateChange("Start_Date", value, index)}
                                                        slotProps={{ textField: { size: "small" } }}
                                                        format="DD/MM/YYYY"
                                                        disabled={!editModesm}
                                                        className={editModesm ? null : styles.textbox2}
                                                    />
                                                </LocalizationProvider>
                                            ) : (
                                                <span>{formDatasm.data[index]?.Start_Date ? dayjs(formDatasm.data[index].Start_Date).format("DD/MM/YYYY") : "-"}</span>
                                            )}
                                        </td>

                                        <td>
                                            {editModesm ? (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <CustomDatePicker
                                                        name="Estimated_Completion_Date"
                                                        value={formDatasm.data[index]?.Estimated_Completion_Date ? dayjs(formDatasm.data[index].Estimated_Completion_Date, "YYYY-MM-DD") : null}
                                                        onChange={(value) => handleDateChange("Estimated_Completion_Date", value, index)}
                                                        slotProps={{ textField: { size: "small" } }}
                                                        format="DD/MM/YYYY"
                                                        disabled={!editModesm}
                                                        className={editModesm ? null : styles.textbox2}
                                                    />
                                                </LocalizationProvider>
                                            ) : (
                                                <span>{formDatasm.data[index]?.Estimated_Completion_Date ? dayjs(formDatasm.data[index].Estimated_Completion_Date).format("DD/MM/YYYY") : "-"}</span>
                                            )}
                                        </td>

                                        <td>
                                            {editModesm ? (
                                                <>
                                                    <div style={{ position: "relative" }}>
                                                        {/* <input
                                                            type="text"
                                                            className={styles.inputFieldsm}
                                                            name="Manufacturer Part_No."
                                                            value={formDatasm.data[index].Manufacturer_Part_No}
                                                            onChange={(e) => {
                                                                handleInputChange(e, index);
                                                                setPartDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                                                            }}
                                                        /> */}
                                                        <textarea
                                                            name="Comments"
                                                            value={formDatasm.data[index].Comments}
                                                            onChange={(e) => {
                                                                handleInputChange(e, index);
                                                                setPartDropdownVisible((prevVisibility) => ({ ...prevVisibility, [index]: true })); // Show the dropdown when typing
                                                            }}
                                                            className={editModesm ? styles.textbox1b : styles.textbox1a}


                                                        />

                                                    </div>
                                                </>
                                            ) : sm.Comments ? (
                                                sm.Comments
                                            ) : (
                                                "-"
                                            )}

                                        </td>

                                        {/* <td>
                                            {editModesm ? (
                                                <>
                                                    <select
                                                        className={styles.inputFieldsm}
                                                        style={{ paddingRight: "30px" }}
                                                        name="UoM"
                                                        value={formDatasm.data[index].UoM}
                                                        onChange={(e) => handleInputChange(e, index)}>
                                                        <option value="">Select Unit</option>
                                                        <option value="pcs">pcs</option>
                                                        <option value="kg">kg</option>
                                                    </select>
                                                    <svg
                                                        style={{ marginLeft: "-25px", marginTop: "-1%", pointerEvents: "none" }}
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 28 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </>
                                            ) : sm.UoM ? (
                                                sm.UoM
                                            ) : (
                                                "-"
                                            )}
                                        </td> */}

                                        {/* <td>
                                            {editModesm ? (
                                                <input
                                                    type="text"
                                                    className={styles.inputFieldsm}
                                                    style={{ width: "80px" }}
                                                    name="Quoted_cost"
                                                    value={formDatasm.data[index].Quoted_cost}
                                                    onChange={(e) => handleInputChange(e, index)}

                                                />
                                            ) : sm.Quoted_cost ? (
                                                sm.Quoted_cost
                                            ) : (
                                                "-"
                                            )}
                                        </td> */}

                                        {/* <td>
                                            {editModesm ? (
                                                <input
                                                    type="text"
                                                    className={styles.inputFieldsm}
                                                    style={{ width: "80px" }}
                                                    name="Quoted_on"
                                                    value={formDatasm.data[index].Quoted_on}
                                                    onChange={(e) => handleInputChange(e, index)}

                                                />
                                            ) : sm.Quoted_on ? (
                                                sm.Quoted_on
                                            ) : (
                                                "-"
                                            )}
                                        </td> */}
                                        {/* <td>
                                            {editModesm ? (
                                                <input
                                                    type="text"
                                                    className={styles.inputFieldsm}
                                                    style={{ width: "80px" }}
                                                    name="Delivery_time"
                                                    value={formDatasm.data[index].Delivery_time}
                                                    onChange={(e) => handleInputChange(e, index)}

                                                />
                                            ) : sm.Delivery_time ? (
                                                sm.Delivery_time
                                            ) : (
                                                "-"
                                            )}
                                        </td> */}


                                    </tr>
                                ))}

                                {showInputRow && !editModesm && (
                                    <tr key={rows.length} className={styles.addinputspares}>
                                        <td className={styles.tickcross}>
                                            <svg
                                                style={{ cursor: "pointer" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                onClick={handleSubmitsm} // This handles submission of the new row data
                                                disabled={isLoading}
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                                                    fill="#66D171"
                                                />
                                            </svg>
                                            <svg
                                                style={{ cursor: "pointer" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                onClick={handleDeleteRow} // This handles deletion of the new input row
                                            >
                                                <path
                                                    d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                                    fill="#F97575"
                                                />
                                                <path
                                                    d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                                    fill="#F97575"
                                                />
                                            </svg>
                                        </td>

                                        <td></td>

                                        <td>
                                            <div style={{ position: "relative" }}>
                                                <input
                                                    type="text"
                                                    className={styles.inputFieldsm}
                                                    name="Item_Traceability"
                                                    value={formDataAddRow.Item_Traceability}
                                                    onChange={(e) => {
                                                        handleInputChangevis(e);
                                                        setDropdownVisibleAddRow(true); // Show the dropdown when typing
                                                    }}
                                                    style={{ borderColor: "#2491eb", borderStyle: "solid" }} // Inline style to set border color
                                                />
                                                {/* {isDropdownVisibleAddRow && formDataAddRow.Item_Name && (
                                                    <div id="myDropdown" className={styles.dropdown}>
                                                        {itemsMaster
                                                            .filter((item) =>
                                                                item.Item_Name && item.Item_Name.toLowerCase().includes((formDataAddRow.Item_Name || '').toLowerCase())
                                                            )
                                                            .map((item) => (
                                                                <div
                                                                    key={item.Item_Id}
                                                                    onClick={() => handleDropdownSelectionAddRow(item)}
                                                                    className={styles.dropdownItem}>
                                                                    {item.Item_Name}
                                                                </div>
                                                            ))}
                                                        <div
                                                            className={styles.dropdownItem}
                                                            style={{ color: "#567191", fontWeight: "700" }}
                                                            onClick={handleAddToItemsMasterAddRow}>
                                                            {`+ Add "${formDataAddRow.Item_Name}" to Items Master List`}
                                                        </div>
                                                    </div>
                                                )} */}
                                            </div>
                                        </td>

                                        <td>
                                            <div style={{ position: "relative" }}>
                                                <input
                                                    type="text"
                                                    className={styles.inputFieldsm}
                                                    name="Current_Status"
                                                    value={formDataAddRow.Current_Status}
                                                    onChange={(e) => {
                                                        handleInputChangevis(e);
                                                        setPartDropdownVisibleAddRow(true); // Show the dropdown when typing
                                                    }}
                                                    style={{ borderColor: "#2491eb", borderStyle: "solid" }} // Inline style to set border color
                                                />
                                                {/* {isPartDropdownVisibleAddRow && formDataAddRow.Part_Number && (
                                                    <div id="myDropdown" className={styles.dropdown}>
                                                        {itemsMaster
                                                            .filter((item) =>
                                                                item.Part_Number && item.Part_Number.toLowerCase().includes((formDataAddRow.Part_Number || '').toLowerCase())
                                                            )
                                                            .map((item) => (
                                                                <div
                                                                    key={item.Item_Id}
                                                                    onClick={() => handleDropdownSelectionAddRow(item)}
                                                                    className={styles.dropdownItem}>
                                                                    {item.Part_Number}
                                                                </div>
                                                            ))}
                                                        <div
                                                            className={styles.dropdownItem}
                                                            style={{ color: "#567191", fontWeight: "700" }}
                                                            onClick={handleAddToItemsMasterAddRow}>
                                                            {`+ Add "${formDataAddRow.Part_Number}" to Items Master List`}
                                                        </div>
                                                    </div>
                                                )} */}
                                            </div>
                                        </td>
                                        <td>
                                            <select
                                                className={styles.inputFieldsm1}
                                                style={{ paddingRight: "30px" }}
                                                name="Assigned_Supervisor"
                                                value={formDataAddRow.Assigned_Supervisor}
                                                onChange={handleInputChangevis}>
                                                <option value="">Select Supervisor</option>
                                                {supervisors.map((supervisor, index) => (
                                                    <option key={index} value={supervisor}>
                                                        {supervisor}
                                                    </option>
                                                ))}
                                            </select>
                                            <svg
                                                style={{ marginLeft: "-25px", marginTop: "-1%", pointerEvents: "none" }}
                                                width="24"
                                                height="24"
                                                viewBox="0 0 28 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </td>
                                        <td>
                                            <div style={{ position: "relative" }}>
                                                <textarea
                                                    name="Requirements"
                                                    value={formDataAddRow.Requirements}
                                                    onChange={(e) => {
                                                        handleInputChangevis(e);
                                                        setPartDropdownVisibleAddRow(true);
                                                    }}
                                                    className={styles.textbox1b}


                                                />

                                                {/* {isDropdownVisibleAddRow && formDataAddRow.Item_Name && (
                                                    <div id="myDropdown" className={styles.dropdown}>
                                                        {itemsMaster
                                                            .filter((item) =>
                                                                item.Item_Name && item.Item_Name.toLowerCase().includes((formDataAddRow.Item_Name || '').toLowerCase())
                                                            )
                                                            .map((item) => (
                                                                <div
                                                                    key={item.Item_Id}
                                                                    onClick={() => handleDropdownSelectionAddRow(item)}
                                                                    className={styles.dropdownItem}>
                                                                    {item.Item_Name}
                                                                </div>
                                                            ))}
                                                        <div
                                                            className={styles.dropdownItem}
                                                            style={{ color: "#567191", fontWeight: "700" }}
                                                            onClick={handleAddToItemsMasterAddRow}>
                                                            {`+ Add "${formDataAddRow.Item_Name}" to Items Master List`}
                                                        </div>
                                                    </div>
                                                )} */}
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ position: "relative" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <CustomDatePicker
                                                        name="Start_Date"
                                                        value={formDataAddRow?.Start_Date ? dayjs(formDataAddRow?.Start_Date, "YYYY-MM-DD") : null}

                                                        onChange={(value) => handleDateChangevis("Start_Date", value)}
                                                        slotProps={{ textField: { size: "small" } }}
                                                        format="DD/MM/YYYY"
                                                        disabled={editModesm}
                                                        className={!editModesm ? null : styles.textbox2}

                                                    />
                                                </LocalizationProvider>
                                                {/* {isPartDropdownVisibleAddRow && formDataAddRow.Part_Number && (
                                                    <div id="myDropdown" className={styles.dropdown}>
                                                        {itemsMaster
                                                            .filter((item) =>
                                                                item.Part_Number && item.Part_Number.toLowerCase().includes((formDataAddRow.Part_Number || '').toLowerCase())
                                                            )
                                                            .map((item) => (
                                                                <div
                                                                    key={item.Item_Id}
                                                                    onClick={() => handleDropdownSelectionAddRow(item)}
                                                                    className={styles.dropdownItem}>
                                                                    {item.Part_Number}
                                                                </div>
                                                            ))}
                                                        <div
                                                            className={styles.dropdownItem}
                                                            style={{ color: "#567191", fontWeight: "700" }}
                                                            onClick={handleAddToItemsMasterAddRow}>
                                                            {`+ Add "${formDataAddRow.Part_Number}" to Items Master List`}
                                                        </div>
                                                    </div>
                                                )} */}
                                            </div>
                                        </td>

                                        <td>
                                            <div style={{ position: "relative" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <CustomDatePicker
                                                        name="Estimated_Completion_Date"
                                                        value={formDataAddRow?.Estimated_Completion_Date ? dayjs(formDataAddRow?.Estimated_Completion_Date, "YYYY-MM-DD") : null}

                                                        onChange={(value) => handleDateChangevis("Estimated_Completion_Date", value)}
                                                        slotProps={{ textField: { size: "small" } }}
                                                        format="DD/MM/YYYY"
                                                        disabled={editModesm}
                                                        className={!editModesm ? null : styles.textbox2}

                                                    />
                                                </LocalizationProvider>
                                                {/* {isPartDropdownVisibleAddRow && formDataAddRow.Part_Number && (
                                                    <div id="myDropdown" className={styles.dropdown}>
                                                        {itemsMaster
                                                            .filter((item) =>
                                                                item.Part_Number && item.Part_Number.toLowerCase().includes((formDataAddRow.Part_Number || '').toLowerCase())
                                                            )
                                                            .map((item) => (
                                                                <div
                                                                    key={item.Item_Id}
                                                                    onClick={() => handleDropdownSelectionAddRow(item)}
                                                                    className={styles.dropdownItem}>
                                                                    {item.Part_Number}
                                                                </div>
                                                            ))}
                                                        <div
                                                            className={styles.dropdownItem}
                                                            style={{ color: "#567191", fontWeight: "700" }}
                                                            onClick={handleAddToItemsMasterAddRow}>
                                                            {`+ Add "${formDataAddRow.Part_Number}" to Items Master List`}
                                                        </div>
                                                    </div>
                                                )} */}
                                            </div>
                                        </td>

                                        <td>
                                            <div style={{ position: "relative" }}>
                                                <textarea
                                                    name="Comments"
                                                    value={formDataAddRow.Comments}
                                                    onChange={(e) => {
                                                        handleInputChangevis(e);
                                                        setPartDropdownVisibleAddRow(true);
                                                    }}
                                                    className={styles.textbox1b}

                                                />

                                                {/* {isPartDropdownVisibleAddRow && formDataAddRow.Part_Number && (
                                                    <div id="myDropdown" className={styles.dropdown}>
                                                        {itemsMaster
                                                            .filter((item) =>
                                                                item.Part_Number && item.Part_Number.toLowerCase().includes((formDataAddRow.Part_Number || '').toLowerCase())
                                                            )
                                                            .map((item) => (
                                                                <div
                                                                    key={item.Item_Id}
                                                                    onClick={() => handleDropdownSelectionAddRow(item)}
                                                                    className={styles.dropdownItem}>
                                                                    {item.Part_Number}
                                                                </div>
                                                            ))}
                                                        <div
                                                            className={styles.dropdownItem}
                                                            style={{ color: "#567191", fontWeight: "700" }}
                                                            onClick={handleAddToItemsMasterAddRow}>
                                                            {`+ Add "${formDataAddRow.Part_Number}" to Items Master List`}
                                                        </div>
                                                    </div>
                                                )} */}
                                            </div>
                                        </td>


                                        {/* Other input fields such as Linked_PO, UoM, etc. */}
                                    </tr>
                                )}
                                {/* {showInputRow && !editModesm && (
                                    <tr key={rows.length} className={styles.addinputspares}>
                                        <td className={styles.tickcross}>
                                            <svg
                                                style={{ cursor: "pointer" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                onClick={handleSubmitsm} // This handles submission of the new row data
                                                disabled={isLoading}
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M7.92558 18.5697C8.21908 18.8631 8.59085 19 8.96261 19C9.33438 19 9.72572 18.8631 9.99965 18.5697L21.0744 7.49878C21.6419 6.93154 21.6419 5.99267 21.0744 5.42543C20.507 4.85819 19.5678 4.85819 19.0004 5.42543L9.02181 15.4005L4.56066 10.9393C3.97487 10.3536 3.02513 10.3536 2.43934 10.9393C1.85355 11.5251 1.85355 12.4749 2.43934 13.0607L7.43934 18.0607C7.51779 18.1391 7.60277 18.2071 7.69252 18.2645C7.755 18.374 7.83269 18.4768 7.92558 18.5697Z"
                                                    fill="#66D171"
                                                />
                                            </svg>
                                            <svg
                                                style={{ cursor: "pointer" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                onClick={handleDeleteRow} // This handles deletion of the new input row
                                            >
                                                <path
                                                    d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0025 4.85814 17.0636 5.42558 16.4964L16.5004 5.42544C17.0678 4.85821 18.007 4.85821 18.5744 5.42544C19.1419 5.99268 19.1419 6.93155 18.5744 7.49879L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                                    fill="#F97575"
                                                />
                                                <path
                                                    d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49879C4.85814 6.93155 4.85814 5.99268 5.42558 5.42544C5.99301 4.85821 6.93221 4.85821 7.49965 5.42544L18.5744 16.4964C19.1419 17.0636 19.1419 18.0025 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                                    fill="#F97575"
                                                />
                                            </svg>
                                        </td>

                                        <td></td>
                                    </tr>

                                )} */}

                            </tbody>
                        </table>

                    </div>
                    {!isButtonDisabled &&
                        <button
                            className={styles.manualbuttonsm}
                            onClick={handleManualInputClick}
                            disabled={isButtonDisabled}>
                            Add Items Manually
                            <svg
                                style={{ marginLeft: "10px" }}
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.493 1.66669H6.50964C3.4763 1.66669 1.66797 3.47502 1.66797 6.50835V13.4834C1.66797 16.525 3.4763 18.3334 6.50964 18.3334H13.4846C16.518 18.3334 18.3263 16.525 18.3263 13.4917V6.50835C18.3346 3.47502 16.5263 1.66669 13.493 1.66669ZM15.0013 10.625H10.6263V15C10.6263 15.3417 10.343 15.625 10.0013 15.625C9.65964 15.625 9.3763 15.3417 9.3763 15V10.625H5.0013C4.65964 10.625 4.3763 10.3417 4.3763 10C4.3763 9.65835 4.65964 9.37502 5.0013 9.37502H9.3763V5.00002C9.3763 4.65835 9.65964 4.37502 10.0013 4.37502C10.343 4.37502 10.6263 4.65835 10.6263 5.00002V9.37502H15.0013C15.343 9.37502 15.6263 9.65835 15.6263 10C15.6263 10.3417 15.343 10.625 15.0013 10.625Z"
                                    fill={isButtonDisabled ? '#5B6BE1' : 'white'}
                                />
                            </svg>
                        </button>
                    }
                    <div style={{ position: "", zIndex: "2" }}>
                        <Modal
                            open={openRemoveConfirmationModal}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                                backdrop: {
                                    timeout: 500,
                                },
                            }}
                            sx={{
                                backdropFilter: "blur(4px)",
                            }}
                        >
                            <Fade in={openRemoveConfirmationModal}>
                                <Box
                                    sx={{
                                        width: 440,
                                        height: "auto",
                                        p: 3,
                                        backgroundColor: "#fff",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            marginBottom: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginLeft: "50px"  // Change justifyContent to space-between
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <svg
                                                width="25"
                                                height="25"
                                                style={{ display: "flex" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                enable-background="new 0 0 47.5 47.5"
                                                viewBox="0 0 47.5 47.5"
                                                id="warning"
                                            >
                                                <defs>
                                                    <clipPath id="a">
                                                        <path d="M0 38h38V0H0v38Z"></path>
                                                    </clipPath>
                                                </defs>
                                                <g clip-path="url(#a)" transform="matrix(1.25 0 0 -1.25 0 47.5)">
                                                    <path
                                                        fill="#ffcc4d"
                                                        d="M0 0c-1.842 0-2.654 1.338-1.806 2.973l15.609 30.055c.848 1.635 2.238 1.635 3.087 0L32.499 2.973C33.349 1.338 32.536 0 30.693 0H0Z"
                                                        transform="translate(3.653 2)"
                                                    ></path>
                                                    <path
                                                        fill="#231f20"
                                                        d="M0 0c0 1.302.961 2.108 2.232 2.108 1.241 0 2.233-.837 2.233-2.108v-11.938c0-1.271-.992-2.108-2.233-2.108-1.271 0-2.232.807-2.232 2.108V0Zm-.187-18.293a2.422 2.422 0 0 0 2.419 2.418 2.422 2.422 0 0 0 2.419-2.418 2.422 2.422 0 0 0-2.419-2.419 2.422 2.422 0 0 0-2.419 2.419"
                                                        transform="translate(16.769 26.34)"
                                                    ></path>
                                                </g>
                                            </svg>
                                            <h3
                                                style={{
                                                    textAlign: "center",
                                                    font: "default",
                                                    fontWeight: "600",
                                                    fontSize: "25px",
                                                    marginLeft: "30px",
                                                }}
                                            >
                                                CONFIRMATION
                                            </h3>
                                        </div>
                                        {/* <button
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    marginLeft: "0px",
                    marginTop: "2px",
                    font: "default",
                    fontWeight: "600",
                    fontSize: "25px",
                  }}
                  onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                >
                  x
                </button> */}
                                        <svg
                                            width="20"
                                            onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                                            style={{
                                                marginLeft: "0px",
                                                cursor: "pointer",
                                            }}
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                                                fill="black"
                                            />
                                            <path
                                                d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </div>

                                    <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>Are you sure you want to remove this item?</h2>
                                    <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "10px" }}>
                                        Item Traceability: &nbsp;
                                        <label style={{ fontSize: "17px", fontWeight: "700" }}>
                                            {confirmationData.ItemTraceability}
                                        </label>
                                    </h2>
                                    <h2 style={{ fontSize: "17px", marginLeft: "10px", marginBottom: "15px" }}>
                                        Current Status:  &nbsp;
                                        <label style={{ fontSize: "17px", fontWeight: "700" }}>
                                            {confirmationData.CurrentStatus}
                                        </label>
                                    </h2>


                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <button
                                            style={{
                                                backgroundColor: "#5b6be1",
                                                border: "1px solid #5b6be1",
                                                borderRadius: "6px",
                                                justifyContent: "center",
                                                minWidth: "74px",
                                                fontWeight: 600,
                                                paddingLeft: "15px",
                                                paddingRight: "15px",
                                            }}
                                            onClick={(e) => handleRemove(e, confirmationData.extractedSNo)}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            style={{
                                                backgroundColor: "white",
                                                color: "#5b6be1",
                                                border: "1px solid #5b6be1",
                                                borderRadius: "6px",
                                                justifyContent: "center",
                                                minWidth: "74px",
                                                fontWeight: 600,
                                                paddingLeft: "15px",
                                                paddingRight: "15px",
                                            }}
                                            onClick={(e) => handleCloseRemoveConfirmationModal(e)}
                                        >
                                            No
                                        </button>
                                    </div>
                                </Box>
                            </Fade>
                        </Modal>
                    </div>
                </div>
            </div>


            {/* </div> */}




        </>
    )

}

export default ItemDetailsWorkshop;
