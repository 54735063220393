import React, { useState } from "react";
import axios from "axios";
import UploadDocument from "./Uploaddocument";
import styles from "../Components/css/rigdetails.module.css";
import { NavLink } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const EditLegalDetails = ({
  s_no,
  country,
  File_name_for_legal_requirements,
  Documents,
  Document_Names,
  refresh,
  setRefresh,
  enableEdit,
  setEnableEdit,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [checked, setChecked] = useState(false);

  const [formData, setFormData] = useState({
    country: country,
    File_name_for_legal_requirements: File_name_for_legal_requirements,
  });

  const [selectedFile, setSelectedFile] = useState({
    documents: Documents,
  });

  const [selectedFileName, setSelectedFileName] = useState({
    documents: Document_Names,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStopClose = (e) => {
    e.stopPropagation();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    closePopup();
    const { country, File_name_for_legal_requirements } = formData;
    const payload = {
      country: country,
      File_name_for_legal_requirements: File_name_for_legal_requirements,
      Documents: selectedFile.documents,
      Document_Names: selectedFileName.documents,
    };
    try {
      const res = await axios.put(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_legal_details/" + s_no,
        payload
      );

      if (res.status === 200) {
        setChecked(!checked);
      }
    } catch (err) {
      console.log(err);
    }
    setRefresh(!refresh);
  };

  // _______________________________to uncheck radio button
  const closePopup = () => {
    setChecked(!checked);
    handleUncheckRadio();
    setEnableEdit(!enableEdit);
  };
  const handleRadioOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleUncheckRadio = () => {
    setSelectedOption("");
  };
  // _
  // ______________________________________________________
  const handleFileUpload = async (file, fieldName) => {
    const formData = new FormData();

    formData.append("file", file);
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/uploadfile",
        formData
      );

      const pathOfFile = res.data.fileUrl;
      const fileNameApi = res.data.filename;

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: fileNameApi,
      });

      setSelectedFile({
        ...selectedFile,
        [fieldName]: pathOfFile,
      });
    } catch (error) {
      console.error("Error uploading details doc:", error);
    }
  };

  const removeFile = async (fieldName, e, index) => {
    e.preventDefault();

    setSelectedFile((prevSelectedFile) => ({
      ...prevSelectedFile,
      [fieldName]: null,
    }));
  };

  const deleteFile = async (fieldName) => {
    const fileApiName = selectedFileName[fieldName];
    console.log(fileApiName);
    try {
      const res = await axios.delete(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/deletefile/${fileApiName}`
      );

      await deleteURLName(fieldName);

      console.log("deleted File    ", res);

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: "",
      });
      setSelectedFile({
        ...selectedFile,
        [fieldName]: "",
      });
    } catch (err) {
      console.log(err);
    }
    setRefresh(!refresh);
  };

  const deleteURLName = async (fieldName) => {
    const { documents } = selectedFile;

    const payload = {
      Documents: fieldName === "documents" ? "" : documents,

      Document_Names:
        fieldName === "documents" ? "" : selectedFileName.documents,
    };

    const res = await axios.patch(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/rig_details/fileupdate/${s_no}`,
      payload
    );

    console.log(res);
  };
  return (
    <div>
      <div className="editRadioButton">
        <input
          type="radio"
          name="rig"
          value="select"
          checked={selectedOption === "select"}
          onChange={handleRadioOptionChange}
          onClick={() => setChecked(!checked)}
        />
      </div>
      {checked && (
        <>
          <div
            className={styles.popupContainer}
            onClick={(e) => {
              e.stopPropagation();
              setChecked(!checked);
              handleUncheckRadio();
            }}>
            <div className={styles.popUp} onClick={handleStopClose}>
              <form onSubmit={handleSubmit}>
                {/* <label>
              S.NO
              <input
                type="text"
                name="s_no"
                value={formData.rig_name}
                onChange={handleInputChange}
              />
            </label> */}
                <label className={styles.popupItemss}>
                  Country:
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={styles.popupItemss}>
                  File_name_for_<br />legal_requirements:
                  <input
                    type="text"
                    name="File_name_for_legal_requirements"
                    value={formData.File_name_for_legal_requirements}
                    onChange={handleInputChange}
                  />
                </label>

                <div className={styles.popupItemss}>
                  <label>
                    Documents:
                    <br />
                    (Upload Document)
                  </label>
                  <div>
                    {selectedFile.documents ? (
                      <div style={{display: "flex", alignItems: "center"}}>
                        <>
                          <UploadDocument
                            fieldName={"documents"}
                            selectedFile={selectedFile.documents}
                            selectedFileName={selectedFileName.documents}
                            setSelectedFile={setSelectedFile}
                            uploadFile={handleFileUpload}
                          />
                          {/* <svg
                            onClick={() => deleteFile("documents")}
                            style={{ cursor: "pointer" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black" />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg> */}
                        </>
                      </div>
                    ) : (
                      <UploadDocument
                        fieldName={"documents"}
                        selectedFile={selectedFile.documents}
                        selectedFileName={selectedFileName.documents}
                        setSelectedFile={setSelectedFile}
                        uploadFile={handleFileUpload}
                      />
                    )}
                  </div>
                </div>

                <br />
                <button type="submit" className={styles.SubmitButton}>
                  Submit
                </button>
                {/* <button onClick={() => setChecked(false)}>Cancel</button> */}
                <button onClick={closePopup} className={styles.CancelButton}>
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditLegalDetails;
