import { colors } from "@mui/material";
import React from "react";
import styles from "../Components/css/TSDstyle.module.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import Erogonlogo from "../../src/Components/Erogonlogo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const Dosplan = (props) => {
  const [formData, setFormData] = useState({
    Rig_Name: "",
    Sales_Order_No: "",
    Rig_Location: "",
  });

  const [projectList, setProjectList] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const res = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details"
        );
        const filter = res.data.data.filter((el) => el.ID_No == id);
        setProjectList(filter);

        setFormData({
          ...formData,
          Rig_Name: filter[0]?.Rig_Name,
          Sales_Order_No: filter[0]?.Sales_Order_No,
          Rig_Location: filter[0]?.Rig_Location,
        });
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    fetchProject();
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await generatePDF(formData);
  };

  const generatePDF = async (formData) => {
    try {
      const input = document.getElementById("dosplan");
      if (!input) {
        throw new Error('Element with ID "dosplan" not found');
      }

      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("dosplan.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div className={styles.dosplanContainer}>
      <div className={styles.dosplanHeading}>
        <h5>Dosplan</h5>
        <svg
          onClick={props.closeOnClick}
          style={{ cursor: "pointer" }}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.9499 22.3641L9.63615 11.0504C9.2496 10.6638 9.2496 10.0227 9.63615 9.63618C10.0227 9.24963 10.6638 9.24963 11.0504 9.63618L22.3641 20.9499C22.7506 21.3364 22.7506 21.9776 22.3641 22.3641C21.9775 22.7507 21.3364 22.7507 20.9499 22.3641Z"
            fill="black"
          />
          <path
            d="M9.63592 22.3641C9.24937 21.9776 9.24937 21.3364 9.63592 20.9499L20.9496 9.63618C21.3362 9.24963 21.9773 9.24963 22.3638 9.63618C22.7504 10.0227 22.7504 10.6638 22.3638 11.0504L11.0501 22.3641C10.6636 22.7507 10.0225 22.7507 9.63592 22.3641Z"
            fill="black"
          />
        </svg>
      </div>
      <table className={styles.tableContainer1}>
        <thead>
          <tr>
            <th colSpan={2} rowSpan={7}>
              <img
                src={Erogonlogo}
                style={{ height: "50px", width: "150px" }}
              />
            </th>
            <th
              colSpan={3}
              rowSpan={7}
              style={{ fontSize: 20, fontWeight: "bold" }}>
              Ergon Technologies Design and Development Plan
            </th>
            <td>Record No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Revision No.</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{projectList[0]?.Document_Date}</td>
          </tr>
          <tr>
            <td>Sales Order No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Customer Name</td>
            <td>{projectList[0]?.Customer_Name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{projectList[0]?.Service_Component}</td>
          </tr>
          <tr>
            <td>Specification</td>
            <td>{projectList[0]?.Rig_Type}</td>
          </tr>
          <tr>
            <th>S. No.</th>
            <th>Process to be carried out</th>
            <th>Document (Reference) Number</th>
            <th>Responsible for review</th>
            <th>Planned Completion Date</th>
            <th>Recommendation / Correction (if any)</th>
            <th>Status</th>
          </tr>
          <tr>
            <th colSpan={2}>I. Design Input</th>
            <th />
            <th />
            <th />
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Refer OEM Manual/ Criteria</td>
            <td>
              Refer OEM Manual/ Criteria for various services/ Scope of work
              from customer/ Email from customer - Scope of work to also include
              if it is an inspection or an overhaul
            </td>
            <td>
              The scope of work was reviewed by the Operations manager and
              approved by CEO
            </td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>
              If there is a change in scope of work, it has to be communicated
              to customer
            </td>
            <td>Completed</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Review of applicable legal requirements</td>
            <td>
              The job is to be carried out in Offshore in{" "}
              {projectList[0]?.Rig_Location}. For this applicable{" "}
              {projectList[0]?.Rig_Location} labor laws{" "}
              {projectList[0]?.File_name_for_legal_requirements} were reviewed.
              The visas were arranged by Ergon Technologies and passes to the
              rig were arranged by Customer.
            </td>
            <td>
              All applicable labor law was reviewed by the operations manager
              and approved by CEO in contract review process. Offshore passes
              were reviewed by the operations manager.
            </td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>NA</td>
            <td>Completed</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.textContainer}>
        <div>TSD-OPS-FOR-009-19,</div>
        <div>Rev.01 30 April 2022</div>
        <div>1</div>
      </div>
      {/* page2 */}
      <table className={styles.tableContainer1}>
        <thead>
          <tr>
            <th colSpan={2} rowSpan={7}>
              <img
                src={Erogonlogo}
                style={{ height: "50px", width: "150px" }}
              />
            </th>
            <th
              colSpan={3}
              rowSpan={7}
              style={{ fontSize: 20, fontWeight: "bold" }}>
              Ergon Technologies Design and Development Plan
            </th>
            <td>Record No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Revision No.</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{projectList[0]?.Document_Date}</td>
          </tr>
          <tr>
            <td>Sales Order No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Customer Name</td>
            <td>{projectList[0]?.Customer_Name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{projectList[0]?.Service_Component}</td>
          </tr>
          <tr>
            <td>Specification</td>
            <td>{projectList[0]?.Rig_Type}</td>
          </tr>
          <tr>
            <th>S. No.</th>
            <th>Process to be carried out</th>
            <th>Document (Reference) Number</th>
            <th>Responsible for review</th>
            <th>Planned Completion Date</th>
            <th>Recommendation / Correction (if any)</th>
            <th>Status</th>
          </tr>
          <tr>
            <th colSpan={2}>I. Design Input</th>
            <th />
            <th />
            <th />
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>3</td>
            <td>
              Review of requirement of any external source (e.g. OEM, MPI)
            </td>
            <td>
              For the {projectList[0]?.Service_Component}, OEM Manual,
              {projectList[0]?.new_group} Operating and Maintenance Manual;
              Parts catalogue and Accessory manuals
            </td>
            <td>
              The manual was obtained and reviewed by operations manager. Some
              roustabouts have been requested to assist with the opening of
              covers, draining of oil etc.
            </td>
            <td>
              Manual review completed at a date before mobilization and
              mechanics/ roustabout availability confirmed
            </td>
            <td>NA</td>
            <td>Completed</td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              Review of service-related products to be used in this project
              (e.g. dial gauge, compressor, etc.)
            </td>
            <td>
              Critical SRP: {projectList[0]?.Tool1} of
              {projectList[0]?.Make1} make and model –{projectList[0]?.Model1},
              serial no
              {projectList[0]?.Serial_Number1} and
              {projectList[0]?.Tool2} of {projectList[0]?.Make2} make and model
              – {projectList[0]?.Model2} and serial no -
              {projectList[0]?.Serial_Number2} were provided by Ergon
              Technologies.
            </td>
            <td>
              Calibration certificate of {projectList[0]?.Tool1}and
              {projectList[0]?.Tool2}
              were reviewed by the Operations manager.
            </td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>NA</td>
            <td>Completed</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.textContainer}>
        <div>TSD-OPS-FOR-009-19, Rev.01</div>
        <div>30 April 2022</div>
        <div>2</div>
      </div>
      {/* page3 */}
      <table className={styles.tableContainer1}>
        <thead>
          <tr>
            <th colSpan={2} rowSpan={7}>
              <img
                src={Erogonlogo}
                style={{ height: "50px", width: "150px" }}
              />
            </th>
            <th
              colSpan={3}
              rowSpan={7}
              style={{ fontSize: 20, fontWeight: "bold" }}>
              Ergon Technologies Design and Development Plan
            </th>
            <td>Record No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Revision No.</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{projectList[0]?.Document_Date}</td>
          </tr>
          <tr>
            <td>Sales Order No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Customer Name</td>
            <td>{projectList[0]?.Customer_Name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{projectList[0]?.Service_Component}</td>
          </tr>
          <tr>
            <td>Specification</td>
            <td>{projectList[0]?.Rig_Type}</td>
          </tr>
          <tr>
            <th>S. No.</th>
            <th>Process to be carried out</th>
            <th>Document (Reference) Number</th>
            <th>Responsible for review</th>
            <th>Planned Completion Date</th>
            <th>Recommendation / Correction (if any)</th>
            <th>Status</th>
          </tr>
          <tr>
            <th colSpan={2}>I. Design Input</th>
            <th />
            <th />
            <th />
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>5</td>
            <td>
              Review of requirement of any external source (e.g. OEM, MPI)
            </td>
            <td>
              HSE Site Personnel. The job is to be carried out in the months of{" "}
              {projectList[0]?.Estimated_Date_Of_Commencement} to{" "}
              {projectList[0]?.Estimated_Project_Completion_Month} .{" "}
            </td>
            <td>
              The operations manager assessed the condition of the location so
              that the quality of work due to this environmental and operational
              condition is not hampered. .
            </td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>NA</td>
            <td>Completed</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Review of Result of Risk Assessment </td>
            <td>TSD-OPS-REG-002-19- Risk Register </td>
            <td>
              Review of risk assessment was done, and results recorded by
              supervisor and approved by operations manager{" "}
            </td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>NA</td>
            <td>Completed</td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              Review of Historical performance and other information derived
              from previous similar service designs{" "}
            </td>
            <td />
            <td>Review of previous jobs of similar nature was done .</td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>NA</td>
            <td>Completed</td>
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan={2}>II. Design Input</th>
            <td colSpan={5} />
            {/* <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th> */}
          </tr>
          <tr>
            <td>1</td>
            <td>Preparation of Service Quality Plan</td>
            <td>
              {projectList[0]?.Sales_Order_No} - {projectList[0]?.Po_Date}
            </td>
            <td>Operations Manager</td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>MR</td>
            <td>Completed</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.textContainer}>
        <div>TSD-OPS-FOR-009-19, Rev.01</div>
        <div>30 April 2022</div>
        <div>3</div>
      </div>
      <table className={styles.tableContainer1}>
        <thead>
          <tr>
            <th colSpan={2} rowSpan={7}>
              <img
                src={Erogonlogo}
                style={{ height: "50px", width: "150px" }}
              />
            </th>
            <th
              colSpan={3}
              rowSpan={7}
              style={{ fontSize: 20, fontWeight: "bold" }}>
              Ergon Technologies Design and Development Plan
            </th>
            <td>Record No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Revision No.</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{projectList[0]?.Document_Date}</td>
          </tr>
          <tr>
            <td>Sales Order No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Customer Name</td>
            <td>{projectList[0]?.Customer_Name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{projectList[0]?.Service_Component}</td>
          </tr>
          <tr>
            <td>Specification</td>
            <td>{projectList[0]?.Rig_Type}</td>
          </tr>
          <tr>
            <th>S. No.</th>
            <th>Process to be carried out</th>
            <th>Document (Reference) Number</th>
            <th>Responsible for review</th>
            <th>Planned Completion Date</th>
            <th>Recommendation / Correction (if any)</th>
            <th>Status</th>
          </tr>
          {/* <tr>
          <th colspan="2">I. Design Input</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
      </tr> */}
        </thead>
        <tbody>
          <tr>
            <td>2</td>
            <td>Preparation of Mobilization Plan</td>
            <td>Task Briefing over call</td>
            <td>Operations Manager</td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>MR</td>
            <td>Completed</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Identification of Customer Allowance (+/- workdays)</td>
            <td>Email</td>
            <td> Operations manager</td>
            <td>30 Days</td>
            <td>MR</td>
            <td>Reviewed</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Selection of Team as per Competency requirement</td>
            <td>
              Competency matrix register TSD-ADM-FOR-009 was referred to for
              finalizing the team – {projectList[0]?.Supervisor_Names} ,
              {projectList[0]?.Technician_Names}
            </td>
            <td>Operations Manager</td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>MR</td>
            <td>Completed</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Selection of Measuring Equipment</td>
            <td>
              Critical SRP: {projectList[0]?.Tool1}
              of
              {projectList[0]?.Make1} make and model –{projectList[0]?.Model1},
              serial no {projectList[0]?.Serial_Number1} and
              {projectList[0]?.Tool2} of {projectList[0]?.Make2} make and model
              – {projectList[0]?.Model2} and serial no -{" "}
              {projectList[0]?.Serial_Number2} were provided by Ergon
              Technologies.
            </td>
            <td>Operations Manager</td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>MR</td>
            <td>Completed</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.textContainer}>
        <div>TSD-OPS-FOR-009-19, Rev.01</div>
        <div>30 April 2022</div>
        <div>4</div>
      </div>
      <table className={styles.tableContainer1}>
        <thead>
          <tr>
            <th colSpan={2} rowSpan={7}>
              <img
                src={Erogonlogo}
                style={{ height: "50px", width: "150px" }}
              />
            </th>
            <th
              colSpan={3}
              rowSpan={7}
              style={{ fontSize: 20, fontWeight: "bold" }}>
              Ergon Technologies Design and Development Plan
            </th>
            <td>Record No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Revision No.</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{projectList[0]?.Document_Date}</td>
          </tr>
          <tr>
            <td>Sales Order No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Customer Name</td>
            <td>{projectList[0]?.Customer_Name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{projectList[0]?.Service_Component}</td>
          </tr>
          <tr>
            <td>Specification</td>
            <td>{projectList[0]?.Rig_Type}</td>
          </tr>
          <tr>
            <th>S. No.</th>
            <th>Process to be carried out</th>
            <th>Document (Reference) Number</th>
            <th>Responsible for review</th>
            <th>Planned Completion Date</th>
            <th>Recommendation / Correction (if any)</th>
            <th>Status</th>
          </tr>
          {/* <tr>
          <th colspan="2">I. Design Input</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
      </tr> */}
        </thead>
        <tbody>
          <tr>
            <td>6</td>
            <td>Identification of Acceptance Criteria</td>
            <td>
              {projectList[0]?.Tool1} should have measurement with +/-
              {projectList[0]?.Acceptance_Criteria1} accuracy.
              {projectList[0]?.Tool2} with accuracy of +/-
              {projectList[0]?.Acceptance_Criteria2}
            </td>
            <td>Operations Manager</td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>MR</td>
            <td>Completed</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Execution of service design - Quality of Job</td>
            <td>
              Daily Reports &amp; Client Feedback, general condition of the
              internal components.
            </td>
            <td> Operations manager</td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>MR</td>
            <td>Completed</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Identification of critical service-related products </td>
            <td>
              Service quality plan TSD- OPS-SQP-{" "}
              {projectList[0]?.Sales_Order_No}-{projectList[0]?.Po_Date}{" "}
            </td>
            <td>Operations Manager</td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>MR</td>
            <td>Completed</td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              Implementation of Pre-Job briefing – briefing the Techs about the
              job
            </td>
            <td>As per client job scope. Informed to techs .</td>
            <td>Operations Manager</td>
            <td>{projectList[0]?.Document_Date}</td>
            <td>MR</td>
            <td>Completed</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.textContainer}>
        <div>TSD-OPS-FOR-009-19, Rev.01</div>
        <div>30 April 2022</div>
        <div>5</div>
      </div>
      <table className={styles.tableContainer1}>
        <tbody>
          <tr>
            <th colSpan={2} rowSpan={7}>
              <img
                src={Erogonlogo}
                style={{ height: "50px", width: "150px" }}
              />
            </th>
            <th
              colSpan={3}
              rowSpan={7}
              style={{ fontSize: 20, fontWeight: "bold" }}>
              Ergon Technologies Design and Development Plan
            </th>
            <td>Record No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Revision No.</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{projectList[0]?.Document_Date}</td>
          </tr>
          <tr>
            <td>Sales Order No.</td>
            <td>{projectList[0]?.Sales_Order_No}</td>
          </tr>
          <tr>
            <td>Customer Name</td>
            <td>{projectList[0]?.Customer_Name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{projectList[0]?.Service_Component}</td>
          </tr>
          <tr>
            <td>Specification</td>
            <td>{projectList[0]?.Rig_Type}</td>
          </tr>
          <tr>
            <th>S. No.</th>
            <th>Process to be carried out</th>
            <th>Document (Reference) Number</th>
            <th>Responsible for review</th>
            <th>Planned Completion Date</th>
            <th>Recommendation / Correction (if any)</th>
            <th>Status</th>
          </tr>
        </tbody>
      </table>

      <h3 className={styles.prepheading}>
        <b>Preparation</b>
      </h3>
      <h3 className={styles.reviewheading}>
        <b>Review</b>
      </h3>
      <h3 className={styles.approvalheading}>
        <b>Approval</b>
      </h3>
      <h4 className={styles.grpprep}>Prepared by</h4>
      <h4 className={styles.grpname}>Nishad Majeed</h4>
      <h4 className={styles.date}>Date</h4>
      <h4 className={styles.inputboxpr}>{projectList[0]?.Document_Date}</h4>
      <h3 className={styles.grpreview}>
        <b>Reviewed by</b>
      </h3>
      <h3 className={styles.grpreviewname}>
        {projectList[0]?.Supervisor_Names}
      </h3>
      <h4 className={styles.date1}>Date</h4>
      <h4 className={styles.inputboxre}>{projectList[0]?.Document_Date}</h4>
      <h3 className={styles.grpapprove}>
        <b>Approved by</b>
      </h3>
      <h3 className={styles.grpapprovenam}>
        Syed Ain Ahmad
        <b />
      </h3>
      <h4 className={styles.date2}>Date</h4>
      <h4 className={styles.inputboxap}>{projectList[0]?.Document_Date}</h4>
      <h3 className={styles.prepsign}>Signature</h3>
      <h3 className={styles.reviewsign}>Signature</h3>
      <h3 className={styles.appovalsign}>Signature</h3>
      <footer>
        <div className={styles.textContainer}>
          <div>TSD-OPS-FOR-009-19, Rev.01</div>
          <div>30 April 2022</div>
          <div>6</div>
        </div>
      </footer>

      <div className={styles.btnContainerFixed}>
        <NavLink
          className={styles.opennewtabbtn}
          to={"/dosplan/" + projectList[0]?.ID_No}
          target="_blank"
          rel="noopener noreferrer">
          Open in New Tab
          <OpenInNewIcon />
        </NavLink>

        <Button
          variant="contained"
          type="submit"
          className={styles.Downloadpdfbtn}
          onClick={handleSubmit}>
          Download PDF
        </Button>
      </div>
      <div id="dosplan">{/* Content to capture with html2canvas */}</div>
    </div>
  );
};

export default Dosplan;
