import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "../Components/css/responsibilitymatrix.module.css"; // Adjust the path to your CSS file

function ResponsibilityMatrix() {
  const [matrixData, setMatrixData] = useState([]);

  useEffect(() => {
    async function fetchMatrixData() {
      try {
        const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/responsibilitymatrix");
        setMatrixData(response.data.data);
      } catch (error) {
        console.error("Error fetching matrix data:", error);
      }
    }

    fetchMatrixData();
  }, []);

  return (
    <div style={{ marginTop: "120px" }}>
      <div className={styles.levelHeader}>
        <h5>Responsibility Matrix</h5>
      </div>
      <div className={styles.matrixtablecontainer}>
        <table className={styles.matrixtable}>
          <thead>
            <tr>
              <th>S. NO.</th>
              <th>Tasks</th>
              <th>Module</th>
              <th>Person Responsible</th>
              <th>Person Accountable</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(matrixData) && matrixData.map((row, index) => (
              <tr key={index}>
                <td>{row.s_no}</td>
                <td>{row.task}</td>
                <td>{row.module}</td>
                <td>{row.person_responsible}</td>
                <td>{row.person_accountable}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ResponsibilityMatrix;
