import React from 'react'
import { useEffect, useState } from "react";
import axios from "axios";
import styles from "../Components/css/PurchaseIncomingInspectionsUpdate.module.css";
import style from "./css/modal.module.css";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import { generatePDF, inspectionOrder } from './IncomingInspection.js';


const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function PurchaseIncomingInspectionsUpdate() {
  const navigate = useNavigate();
  const { Purchase_id, IncomingDeliveryID } = useParams();
  const [purchaseDetails, setPurchaseDetails] = useState([]);   //to store purchase details for display in the topdiv
  const [deliveryDetails, setDeliveryDetails] = useState([]);   //to store incoming delivery details for display in the topdiv
  const [inspectionItems, setInspectionItems] = useState([]);   //to store incoming inspection items from purchase_incoming_inspections table and to display it on the page in a table
  const [editIndex, setEditIndex] = useState();    //to set edit mode and track which row in the table is being edited
  const [refresh, setRefresh] = useState(false);    //refresh state to control the useEffect whenever it changes
  const [tempPicture, setTempPicture] = useState("");   //to store the picture file to be uploaded
  const [removePicture, setRemovePicture] = useState("");    //flag to check if we have to remove picture file on clicking cross next to the upload file input
  const [featureincominginspectionedit, setfeatureincominginspectionedit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks
  const [inspectionOrder, setInspectionOrder] = useState(0);
  const [inspectionStatus, setInspectionStatus] = useState('Pending');

  // const [formDataInspection, setFormDataInspection] = useState([{
  //   Actual_Received_Qty: "",
  //   Qty_Delta: "",
  //   Qty_Rejected: "",
  //   Traceability_Number: "",
  //   Picture: "",
  //   Condition_Upon_Receipt: "",
  //   Remarks: "",
  // }]);   //to store the editable state data

  const [formDataInspection, setFormDataInspection] = useState("");   //to store the editable state data

  useEffect(() => {
    //first loading of the page data and states, also used to refresh the page when save, cancel, cross etc. are clicked
    async function fetchDetails() {
      try {

        // Fetch all inspections for the current Purchase_ID, sorted by Inspection_Date
        const resAllInspections = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_inspections_get?Purchase_ID=${Purchase_id}`
        );

        // Filter to keep only unique Incoming_Inspection_IDs
        const uniqueInspections = Array.from(new Map(resAllInspections.data.data.map(item => [item.Incoming_Inspection_ID, item])).values());

        // Sort unique inspections by Incoming_Inspection_ID (ascending)
        const sortedInspections = uniqueInspections.sort(
          (a, b) => a.Incoming_Inspection_ID - b.Incoming_Inspection_ID
        );

        // Log sorted inspections to verify order
        console.log("Sorted Unique Inspections:", sortedInspections);

        // Determine the order of the current inspection
        const currentInspectionIndex = sortedInspections.findIndex(
          el => el.Incoming_Delivery_ID == IncomingDeliveryID
        );

        console.log("Current Inspection Index:", currentInspectionIndex);

        setInspectionOrder(currentInspectionIndex + 1); // Index + 1 gives the order number

        const resPurchaseDetails = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchases_get"
        );

        const resDeliveryDetails = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_details2?project_id=${IncomingDeliveryID}`
        );

        const resInspectionItems = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_inspections_get?Purchase_ID=${Purchase_id}`
        );

        const filterPurchaseDetails = resPurchaseDetails.data.data.filter(el => el.Purchase_ID == Purchase_id);
        const filterInspectionItems = resInspectionItems.data.data.filter(el =>
          el.Incoming_Delivery_ID == IncomingDeliveryID && el.Incoming_Qty !== undefined && el.Incoming_Qty !== null && el.Incoming_Qty !== 0 && el.Incoming_Qty !== "0"
        );


        console.log("PurchaseDetails", filterPurchaseDetails);
        console.log("Incoming Delivery Details", resDeliveryDetails.data.data);
        console.log("Filtered Inspection Items", filterInspectionItems)

        setPurchaseDetails(filterPurchaseDetails[0]);
        setDeliveryDetails(resDeliveryDetails.data.data[0]);
        setInspectionItems(filterInspectionItems);

        console.log("Refresh PurchaseDetails", purchaseDetails);
        console.log("Refresh Incoming Delivery Details", deliveryDetails);
        console.log("Refresh Inspection Items", inspectionItems);
        console.log("formDataInspection:", formDataInspection);

        // Fetch updated inspection date
        const updatedInspectionDate = filterInspectionItems[0]?.Inspection_Date || null;
        console.log("Fetched Inspection Date:", updatedInspectionDate);


        if (filterInspectionItems.length > 0) {
          setDeliveryDetails(prevState => ({
            ...prevState,
            ...resDeliveryDetails.data.data[0],
            Inspection_Date: updatedInspectionDate,
          }));
          console.log("Updated State:", {
            ...deliveryDetails,
            Inspection_Date: updatedInspectionDate,
          });
        }

      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, [refresh]);

  const handleBackButtonIncoming = () => {
    navigate(`/universal_inspections`);
  };

  const handleDeliveryNavigate = () => {
    navigate(`/purchaseincomingdeliveriesupdate/${Purchase_id}/${IncomingDeliveryID}`);
  }

  const getBackgroundColor = (el) => {
    // Filter inspectionDetails for entries with the same Incoming_Delivery_ID
    const filteredEntries = inspectionItems.filter(entry => entry.Incoming_Delivery_ID == IncomingDeliveryID);
    // Check if any Actual_Received_Qty is null
    const anyNullReceivedQty = filteredEntries.some(entry => entry.Actual_Received_Qty === null || entry.Actual_Received_Qty === "");

    // Check the conditions
    if (filteredEntries.length === 0 || anyNullReceivedQty) {
      return '#900909'; // Pending
    }
    else {
      return '#077D7D'; // Completed
    }
  };

  const getButtonText = (el) => {
    // Filter inspectionDetails for entries with the same Incoming_Delivery_ID
    const filteredEntries = inspectionItems.filter(entry => entry.Incoming_Delivery_ID == IncomingDeliveryID);

    // Check if any Actual_Received_Qty is null
    const anyNullReceivedQty = filteredEntries.some(entry => entry.Actual_Received_Qty === null || entry.Actual_Received_Qty === "");

    // Check the conditions
    if (filteredEntries.length === 0 || anyNullReceivedQty) {
      return 'PENDING';
    }
    else {
      return 'COMPLETED';
    }
  };

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   setFormDataInspection({ ...formDataInspection, [name]: value });
  //   console.log("formDataInspection:", formDataInspection);
  // };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let newValue = value;
    let qtyDelta = '';

    // If the changed field is Actual_Received_Qty, also calculate Qty_Delta
    if (name === 'Actual_Received_Qty') {
      const incomingQty = inspectionItems[index].Incoming_Qty;
      newValue = value;
      qtyDelta = parseFloat(value) - incomingQty;
      // Ensure Qty_Delta is always a number and not NaN
      qtyDelta = isNaN(qtyDelta) ? '' : qtyDelta;
    }

    if (name === 'Actual_Received_Qty') {
      setFormDataInspection({
        ...formDataInspection,
        [name]: newValue,
        Qty_Delta: qtyDelta
      });
    } else {
      setFormDataInspection({
        ...formDataInspection,
        [name]: newValue
      });
    }

    console.log("formDataInspection:", formDataInspection);
  };

  const handleEditPencilClick = (index) => {
    setEditIndex(index);
    setFormDataInspection({
      Actual_Received_Qty: inspectionItems[index].Actual_Received_Qty,
      Qty_Delta: inspectionItems[index].Qty_Delta,
      Qty_Rejected: inspectionItems[index].Qty_Rejected,
      Traceability_Number: inspectionItems[index].Traceability_Number,
      Picture: inspectionItems[index].Picture,
      Condition_Upon_Receipt: inspectionItems[index].Condition_Upon_Receipt,
      Remarks: inspectionItems[index].Remarks,
    })
    console.log("formDataInspection", formDataInspection)
  }

  //Inspection Date


  const handleSubmitTick = async (e, S_No) => {
    setIsLoading(true);
    setEditIndex();
    e.preventDefault();
    setEditIndex();
    const {
      Actual_Received_Qty,
      Qty_Delta,
      Qty_Rejected,
      Traceability_Number,
      Picture,
      Condition_Upon_Receipt,
      Remarks,
    } = formDataInspection

    const removeConditions = [
      { remove: removePicture, temp: tempPicture, type: "Picture" },
    ];

    for (const condition of removeConditions) {
      if (condition.remove && condition.temp === "") {
        const payloadRemoveFiles = { type: condition.type };
        await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_inspections_removefiles/${S_No}`, payloadRemoveFiles);
      }
    }

    const payload = {
      Actual_Received_Qty: Actual_Received_Qty,
      Qty_Delta: Qty_Delta,
      Qty_Rejected: Qty_Rejected,
      Traceability_Number: Traceability_Number,
      Condition_Upon_Receipt: Condition_Upon_Receipt,
      Remarks: Remarks,
    }

    const payloadfiles = {
      Picture: Picture,
    }

    console.log(payload);
    console.log(payloadfiles);

    const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_inspections_update?S_No=${S_No}`,
      payload);

    const res1 = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_inspections_files?S_No=${S_No}`,
      payloadfiles,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // Check if status changed to "Completed" and update inspection date
    // Update inspection date based on the current form data
    const currentDate = new Date().toLocaleDateString();
    if (Actual_Received_Qty !== null && Actual_Received_Qty !== "") {
      const resDate = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_inspection_date`, {
        Incoming_Delivery_ID: IncomingDeliveryID,
        Inspection_Date: currentDate,
      });
      console.log("Updated inspection date:", currentDate);
    }


    setFormDataInspection("");
    setTempPicture("");
    setRemovePicture(false);
    setRefresh(!refresh);
    setIsLoading(false);
    // window.location.reload();
    // Add logic to save changes

  }

  const handleCancelCross = () => {
    setEditIndex();
    console.log("formDataInspection", formDataInspection)
  }

  const handleforwarddoc = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = ""; // Set desired filename
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };

  const handlefileupload1 = (uploadname, e) => {
    const { name } = e.target
    let tempFile = e.target.files[0];
    console.log(tempFile);
    const inputElement = document.getElementsByName(uploadname)[0];
    const selectedFile = inputElement.files[0];
    // console.log(inputElement);
    // console.log(selectedFile);
    setTempPicture(selectedFile?.name || ""); // Set to blank if no file is selected
    setFormDataInspection({ ...formDataInspection, [name]: tempFile });
    inputElement.value = "";
    tempFile.value = "";
    console.log("formDataInspection", formDataInspection)
  }

  const handleRemoveFile = (name) => {
    setTempPicture(""); // Set blank
    setRemovePicture(true);
    setFormDataInspection({ ...formDataInspection, [name]: null }); // Remove the file from the editrow
    console.log("formDataInspection", formDataInspection);
  };

  const handleDownloadExcel = () => {
    // Define header row with bold font
    const header = [
      { v: 'S. No', s: { bold: true } },
      { v: 'Item Name', s: { bold: true } },
      { v: 'OEM Part Number', s: { bold: true } },
      { v: 'Manufacturer Part Number', s: { bold: true } },
      { v: 'UoM', s: { bold: true } },
      { v: 'Incoming Qty', s: { bold: true } },
      { v: 'Actual Received Qty', s: { bold: true } },
      { v: 'Shortfall/Surplus', s: { bold: true } },
      { v: 'Rejected Qty', s: { bold: true } },
      { v: 'Traceability Number', s: { bold: true } },
      { v: 'Picture', s: { bold: true } },
      { v: 'Condition Upon Receipt', s: { bold: true } },
      { v: 'Remarks', s: { bold: true } },
      { v: 'Bill of Entry', s: { bold: true } },
      { v: 'Purchase ID', s: { bold: true } },
      { v: 'Incoming Inspection ID', s: { bold: true } },
      { v: 'Incoming Delivery ID', s: { bold: true } },
      { v: 'PO No.', s: { bold: true } },
      { v: 'Supplier Name', s: { bold: true } },
      { v: 'Arrival', s: { bold: true } },
    ];

    // Prepare data rows
    const data = inspectionItems.map((item, index) => {
      return [
        index + 1,
        item.Item_Name,
        item.OEM_Part_Number,
        item.Manufacturer_Part_Number,
        item.UoM,
        item.Incoming_Qty,
        item.Actual_Received_Qty,
        item.Qty_Delta,
        item.Qty_Rejected,
        item.Traceability_Number,
        {
          f: item.Picture ? `=HYPERLINK("${item.Picture}")` : null,
          t: 's'
        },
        item.Condition_Upon_Receipt,
        item.Remarks,
        deliveryDetails.Entry_Bill_No ? deliveryDetails.Entry_Bill_No : "-",
        item.Purchase_ID,
        item.Incoming_Inspection_ID,
        item.Incoming_Delivery_ID,
        purchaseDetails.PO_Number ? purchaseDetails.PO_Number : "-",
        purchaseDetails.Supplier_Name ? purchaseDetails.Supplier_Name : "-",
        deliveryDetails.Expected_Arrival_Date ? formatDate(deliveryDetails.Expected_Arrival_Date) : "-",
      ];
    });

    // Create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);

    // Set column widths
    const columnWidths = [
      { wch: 5 }, // S. No
      { wch: 20 }, // Item Name
      { wch: 20 }, // OEM Part Number
      { wch: 22 }, // Manufacturer Part Number
      { wch: 5 }, // UoM
      { wch: 11 }, // Incoming Qty
      { wch: 17 }, // Actual Received Qty
      { wch: 14 }, // Shortfall/Surplus
      { wch: 14 }, // Rejected Qty
      { wch: 17 }, // Traceability Number
      { wch: 80 }, // Picture
      { wch: 20 }, // Condition Upon Receipt
      { wch: 35 }, // Remarks
      { wch: 20 }, // Bill of Entry
      { wch: 20 }, // Purchase ID
      { wch: 20 }, // Incoming Inspection ID
      { wch: 20 }, // Incoming Delivery ID
      { wch: 20 }, // PO No.
      { wch: 20 }, // Supplier Name
      { wch: 20 }, // Arrival
    ];
    worksheet['!cols'] = columnWidths;

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchase_Incoming_Inspection');

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'Purchase_Incoming_Inspection.xlsx');
  };

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );



        const workshoppurchasesincominginspectionedit = response.data.filter((el) => {
          return (
            el.Group_Name === localStorage.getItem("Group") &&
            el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Purchases Incoming Inspections Edit" && (el.Access === "Can View" || el.Access === "Can Edit")
          );
        })


        setfeatureincominginspectionedit(workshoppurchasesincominginspectionedit);

      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);

  //redirect
  const handleIncomingInspectionRedirect = () => {
    navigate(`/incominginspectionreport/${Purchase_id}/${IncomingDeliveryID}`)
  }

  const handleDownloadPDF = () => {
    // // Dynamically calculate the inspection order based on the incoming inspection data
    // const inspectionIndex = inspectionItems.findIndex(item => item.Incoming_Delivery_ID == IncomingDeliveryID);
    // const inspectionOrder = inspectionIndex + 1; // Use the index + 1 as the order
    // // const InspectionOrder = inspectionOrder;

    const formData = inspectionItems;

    // Call the generatePDF function with the correct inspectionOrder
    generatePDF(formData, purchaseDetails, deliveryDetails, inspectionOrder);
  };


  return (
    <div>
      <LoadingScreen isLoading={isLoading} />
      <div className={styles.topButtonContainer}>
        <button
          className={styles.topBackButton}
          onClick={handleBackButtonIncoming}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
              fill="#5B6BE1"
            />
          </svg>
          {' '}Incoming Inspections
        </button>
      </div>

      <div className={styles.topdiv}>
        <div className={styles.topsubdiv}>
          <label className={styles.projectDiv}>
            PO No. : {purchaseDetails ? purchaseDetails.PO_Number : "-"}
          </label>
          <label className={styles.projectDiv}>
            Supplier Name: {purchaseDetails ? purchaseDetails.Supplier_Name : "-"}
          </label>
          <label className={styles.projectDiv}>
            Linked SO: {purchaseDetails ? purchaseDetails.Linked_Project : "-"}
          </label>
          <label
            className={styles.deliveryStatusDiv}
            style={{ backgroundColor: getBackgroundColor(), color: "white" }}
          >
            Inspection Status: {getButtonText()}
          </label>
        </div>
        <div className={styles.topsubdiv}>
          <label className={styles.projectDiv} style={{ margin: "1%", marginLeft: "0.2%", marginTop: "0px", marginBottom: "0px" }}>
            Arrival: {deliveryDetails ? formatDate(deliveryDetails.Expected_Arrival_Date) : "-"}
          </label>
          <label className={styles.projectDiv} style={{ margin: "1%", marginLeft: "0.2%", marginTop: "0px", marginBottom: "0px" }}>
            Bill of Entry: {deliveryDetails ? deliveryDetails.Entry_Bill_No : "-"}
          </label>
          <label className={styles.projectDiv} style={{ margin: "1%", marginLeft: "0.2%", marginTop: "0px", marginBottom: "0px" }}>
            <span>Incoming Delivery ID: {IncomingDeliveryID}</span>
            <svg
              onClick={handleDeliveryNavigate}
              width="20" height="20" viewBox="0 0 24 24" style={{ marginLeft: "10px", pointerEvents: "all", cursor: "pointer" }}>
              <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
            </svg>

          </label>
          <label className={styles.projectDiv} style={{ margin: "1%", marginLeft: "0.2%", marginTop: "0px", marginBottom: "0px" }}>
            Incoming Inspection ID: {deliveryDetails ? deliveryDetails.Incoming_Inspection_Id : "-"}
          </label>
          <div
            className={styles.projectDiv}
            style={{
              display: "flex",
              flex: "1",
              marginTop: "0px",
              marginBottom: "2px",
              justifyContent: "flex-end",
              padding: "0",
              borderRadius: "5px",
              backgroundColor: "rgb(209, 217, 255)",
            }}
          >
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "white",
                // padding: "2px",
              }}
            >
              <svg
                width="42"
                height="42"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer", pointerEvents: "all", zIndex: "310" }}
                // onClick={handleDownloadExcel}
                onClick={handleDownloadPDF}
              >
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                  fill="#5B6BE1"
                />
              </svg>
            </div>
          </div>

        </div>
      </div>

      <div className={styles.maindiv}>
        <table className={styles.smtable}>
          <thead>
            <tr className={styles.headrowsm}>
              <th className={styles.fileUploadheading}>S.No</th>
              <th className={styles.fileUploadheading}>Item Name</th>
              <th className={styles.fileUploadheading}>OEM Part Number</th>
              <th className={styles.fileUploadheading}>Manufacturer Part Number</th>
              <th className={styles.fileUploadheading}>UoM</th>
              <th className={styles.fileUploadheading}>Incoming Qty</th>
              <th className={styles.fileUploadheading}>Actual Received Qty</th>
              <th className={styles.fileUploadheading}>Shortfall/Surplus</th>
              <th className={styles.fileUploadheading}>Rejected Qty</th>
              <th className={styles.fileUploadheading}>Traceability Number</th>
              <th className={styles.fileUploadheading}>Picture</th>
              <th className={styles.fileUploadheading}>Condition Upon Receipt</th>
              <th className={styles.fileUploadheading}>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {inspectionItems.map((el, index) => (
              <tr key={el.S_No}>
                <td>
                  {editIndex !== index ? (
                    <div className={styles.snoDiv}>
                      {index + 1}
                      {featureincominginspectionedit.length !== 0 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 18 16"
                          fill="none"
                          onClick={() => handleEditPencilClick(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            d="M10.9378 0.848271C11.4988 0.300259 12.2532 -0.00451602 13.0375 5.05871e-05C13.8218 0.0046172 14.5726 0.318157 15.1272 0.872665C15.6818 1.42717 15.9954 2.17793 15.9999 2.96211C16.0045 3.74629 15.6997 4.50065 15.1516 5.06158L14.4815 5.73159L10.2677 1.51827L10.9378 0.848271ZM9.55955 2.22734L1.54754 10.2383C1.21969 10.5665 0.992126 10.9813 0.89148 11.4341L0.0120526 15.3901C-0.00634122 15.4724 -0.00364512 15.558 0.0198909 15.639C0.043427 15.72 0.0870403 15.7937 0.146685 15.8533C0.206329 15.913 0.280072 15.9566 0.361071 15.9801C0.442071 16.0036 0.527703 16.0063 0.610023 15.9879L4.5424 15.1136C5.0106 15.0094 5.43937 14.7739 5.77841 14.4346L13.7734 6.44065L9.55955 2.22734Z"
                            fill="#5B6BE1"
                          />
                        </svg>
                      )}
                    </div>
                  ) : (
                    <div className={styles.snoDivEdit}>
                      {index + 1}
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="24"
                          viewBox="0 0 26 24"
                          fill="none"
                          onClick={(e) => handleSubmitTick(e, el.S_No)}
                          disabled={isLoading}
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.61002 18.6004C9.88256 18.8729 10.2278 19 10.573 19C10.9182 19 11.2816 18.8729 11.5359 18.6004L21.8197 8.32029C22.3466 7.79357 22.3466 6.92176 21.8197 6.39504C21.2928 5.86832 20.4206 5.86832 19.8937 6.39504L10.7034 15.5821L6.56066 11.4393C5.97487 10.8536 5.02513 10.8536 4.43934 11.4393C3.85355 12.0251 3.85355 12.9749 4.43934 13.5607L9.0822 18.2035C9.21524 18.3366 9.36706 18.4394 9.52912 18.512C9.55454 18.5424 9.58151 18.5719 9.61002 18.6004Z"
                            fill="#66D171"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          onClick={handleCancelCross}
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                            fill="#F97575"
                          />
                          <path
                            d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                            fill="#F97575"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  {el.Item_Name ? (
                    el.Item_Name
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {el.OEM_Part_Number ? (
                    el.OEM_Part_Number
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {el.Manufacturer_Part_Number ? (
                    el.Manufacturer_Part_Number
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {el.UoM ? (
                    el.UoM
                  ) : (
                    "-"
                  )}
                </td>
                <td style={{ width: "8%" }}>
                  {el.Incoming_Qty ? (
                    el.Incoming_Qty
                  ) : (
                    "-"
                  )}
                </td>

                <td style={{ width: "9%" }}>
                  {editIndex === index ? (
                    <input
                      type="text"
                      className={styles.inputFieldsm}
                      style={{ width: "75px" }}
                      name="Actual_Received_Qty"
                      value={formDataInspection.Actual_Received_Qty}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : el.Actual_Received_Qty ? (
                    el.Actual_Received_Qty
                  ) : (
                    "-"
                  )}
                </td>
                <td style={{ width: "9%" }}>
                  {editIndex === index ? (
                    <input
                      type="text"
                      className={styles.inputFieldsm}
                      style={{ width: "75px" }}
                      name="Qty_Delta"
                      value={formDataInspection.Qty_Delta}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled
                    />
                  ) : el.Qty_Delta ? (
                    el.Qty_Delta
                  ) : (
                    "-"
                  )}
                </td>
                <td style={{ width: "9%" }}>
                  {editIndex === index ? (
                    <input
                      type="text"
                      className={styles.inputFieldsm}
                      style={{ width: "75px" }}
                      name="Qty_Rejected"
                      value={formDataInspection.Qty_Rejected}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : el.Qty_Rejected ? (
                    el.Qty_Rejected
                  ) : (
                    "-"
                  )}
                </td>

                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      className={styles.inputFieldsm}
                      name="Traceability_Number"
                      value={formDataInspection.Traceability_Number}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : el.Traceability_Number ? (
                    el.Traceability_Number
                  ) : (
                    "-"
                  )}
                </td>
                <td style={{ minWidth: "min-content" }}>
                  {editIndex === index ? (
                    <div className={styles.snoDiv}>
                      <input
                        type="file"
                        className={styles.textbox1}
                        style={{ display: "none", width: "0" }}
                        name="Picture"
                        onInput={(e) => handlefileupload1("Picture", e)}
                        id="picture"
                      />
                      <div className={styles.uploadbox}
                        onClick={() => document.getElementById("picture").click()}
                      >
                        <span>
                          {tempPicture || (formDataInspection.Picture
                            ? formDataInspection.Picture?.toString().split("/").pop().split("-").slice(1).join("-")
                            : "Upload File")}
                        </span>
                      </div>
                      <svg
                        width="28"
                        height="25"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginTop: "0.5%",
                          marginLeft: "-35px",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                          fill="black"
                        />
                        <path
                          d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                          fill="black"
                        />
                      </svg>
                      {formDataInspection.Picture && (
                        <svg
                          width="20"
                          onClick={() => handleRemoveFile("Picture")}
                          style={{
                            marginLeft: "0px",
                            cursor: "pointer",
                          }}
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                            fill="black"
                          />
                          <path
                            d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                            fill="black"
                          />
                        </svg>
                      )}
                    </div>
                  ) : el.Picture ? (
                    <div style={{ marginLeft: "5px" }} className={styles.snoDiv}>
                      <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {el.Picture.toString().split("/")
                          .pop()
                          .split("-")
                          .slice(1)
                          .join("-")}
                      </div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.svgunshrinkable}
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        onClick={() => handleforwarddoc(el.Picture)}
                      >
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                          fill="#292D32"
                        />
                      </svg>
                      <a href={el.Picture} target=" _blank" >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        // style={{ marginLeft: "10px" }}
                        >
                          <path
                            d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                            fill="black"
                          />
                          <path
                            d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                            fill="black"
                          />
                        </svg>
                      </a>
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td style={{ width: "10%" }}>
                  {editIndex === index ? (
                    <>
                      <select
                        className={styles.inputFieldsm}
                        style={{ paddingRight: "30px" }}
                        name="Condition_Upon_Receipt"
                        value={formDataInspection.Condition_Upon_Receipt}
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        <option value="">Select a value</option>
                        <option value="New">New</option>
                        <option value="Refurbished">Refurbished</option>
                      </select>
                      <svg
                        style={{ marginLeft: "-25px", marginTop: "-1%", pointerEvents: "none" }}
                        width="24"
                        height="24"
                        viewBox="0 0 28 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </>
                  ) : el.Condition_Upon_Receipt ? (
                    el.Condition_Upon_Receipt
                  ) : (
                    "-"
                  )}
                </td>
                <td style={{ width: "15%" }}>
                  {editIndex === index ? (
                    <input
                      type="text"
                      className={styles.inputFieldsm}
                      name="Remarks"
                      value={formDataInspection.Remarks}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : el.Remarks ? (
                    el.Remarks
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PurchaseIncomingInspectionsUpdate;