import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../Components/css/purchaseincomingcreatenew.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import axios from "axios";
import dayjs from "dayjs";
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

const PurchaseIncomingCreateNew = () => {
  const { Purchase_id } = useParams();
  const navigate = useNavigate();
  const [receivedDeliveryStatus, setReceivedDeliveryStatus] = useState(false);
  const [documentToUpload1, setDocumentToUpload1] = useState("");
  const [documentToUpload2, setDocumentToUpload2] = useState("");
  const [documentToUpload3, setDocumentToUpload3] = useState("");
  const [documentToUpload4, setDocumentToUpload4] = useState("");
  const [isNext, setIsNext] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [incomingQuantities, setIncomingQuantities] = useState([]);
  const [mergedArray, setMergedArray] = useState([]);
  const [pendingQty, setPendingQty] = useState([]);
  const [isfillsame, setIsfillsame] = useState(false);
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_item_details?Purchase_ID=${Purchase_id}`
        );
        console.log("res", res);
        const data = res.data.data;
        console.log("data", data);
        setFilteredItems(data);
        // Initialize incomingQuantities state with empty values for each item
        setIncomingQuantities(new Array(data.length).fill(""));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchItems();
  }, [Purchase_id]);

  useEffect(() => {
    const fetchItems2 = async () => {
      try {
        const res9 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_items_get9?Purchase_ID=${Purchase_id}`
        );
        console.log("res", res9);
        const data9 = res9.data.data;
        console.log("data", data9);
        setPendingQty(data9);
        // Initialize incomingQuantities state with empty values for each item
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchItems2();
  }, [Purchase_id]);

  const differencesArray = filteredItems.map((item) => {
    const pendingItem = pendingQty.find(
      (pItem) => pItem.OEM_Part_Number === item.OEM_Part_Number && pItem.Item_Name === item.Item_Name && pItem.Manufacturer_Part_Number === item.Manufacturer_Part_Number
    );
    return pendingItem
      ? item.Qty_ordered - pendingItem.Scheduled_Quantity
      : item.Qty_ordered;
  });
  console.log("sdasdasd", differencesArray);

  const [errors, setErrors] = useState(Array(filteredItems.length).fill(false));

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [test, setTest] = useState(false);

  const handleQuantityChange = (event, index) => {
    const { value } = event.target;
    const updatedQuantities = [...incomingQuantities];
    updatedQuantities[index] = value;
    setIncomingQuantities(updatedQuantities);
    console.log("Dsfdf", incomingQuantities);

    // Check if the entered value is greater than the corresponding difference
    if (parseInt(value) > differencesArray[index]) {
      const newErrors = [...errors];
      newErrors[index] = true; // Set error state for this index
      setErrors(newErrors);
    } else {
      const newErrors = [...errors];
      newErrors[index] = false; // Clear error state for this index
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    // Check if incomingQuantities is not empty and contains any non-empty values
    const hasNonEmptyValues =
      incomingQuantities.length > 0 &&
      incomingQuantities.some(
        (val) => typeof val === "string" && val.trim() !== ""
      );

    if (hasNonEmptyValues) {
      // Check if any error exists, if yes, disable the save button
      const hasError = errors.some((error) => error);
      setSaveDisabled(hasError);
    } else if (isfillsame == true) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true); // Disable save button if incomingQuantities is empty or contains only empty strings
    }

    // Check if the sum of differencesArray is 0
    const sumOfDifferences = differencesArray.reduce(
      (acc, curr) => acc + curr,
      0
    );
    setInputDisabled(sumOfDifferences === 0); // Disable input if sum is 0
  }, [errors, differencesArray, incomingQuantities]);

  useEffect(() => {
    // Merge filteredItems and incomingQuantities arrays
    const mergedArray = filteredItems.map((item, index) => ({
      ...item,
      Scheduled_Quantity: incomingQuantities[index] || "", // Adding incomingQuantity to each item
    }));
    setMergedArray(mergedArray);
  }, [incomingQuantities]);

  const [formdata2, setFormData2] = useState({
    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    SupplierName: "",
    LinkedProject: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_details?Purchase_ID=${Purchase_id}`
      );
      const filter = res.data.data.filter(
        (el) => el.Purchase_ID == Purchase_id
      );

      setFormData2({
        ...formdata2,

        PONO: filter[0]?.PO_Number?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        LinkedProject: filter[0]?.Linked_Project,
        SupplierName: filter[0]?.Supplier_Name,
      });
    };
    fetchProject();
  }, [Purchase_id]);

  const [formdata1, setFormData1] = useState({
    CiplNumber: "",
    CiplDoc: "",
    CustomerExitNumber: "",
    CustomerExitDoc: "",
    ErgonBillDoc: "",
    DispatchingParty: "",
    ExpectedArrivalDate: "",
    ErgonBillNumber: "",
    ErgonBillDate: "",
    CustomerExitDate: "",
    CiplDate: "",
  });
  const [newRows, setNewRows] = useState("");

  const handleClick = () => {
    navigate(`/universal_deliveries`);
  };
  const handleNext = () => {
    if (!formdata1.ExpectedArrivalDate) {
      alert("Expected Arrival Date is required in Step 1.");
      return; // Exit the function without saving if the date is null
    } else setIsNext(true);
  };
  const handleBack = () => {
    setIsNext(false);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formdata1, [name]: value });
  };
  // date changes
  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("YYYY-MM-DD");
      setFormData1({ ...formdata1, [name]: selectedDate });
      // setFormData3Tool1({ ...formdata3Tool1, [name]: selectedDate });
      // setFormData3Tool2({ ...formdata3Tool2, [name]: selectedDate });
      console.log(name, selectedDate);
    } else {
      console.log("Invalid Date");
    }
  };
  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTest(true);  // Disable the save button immediately

    const {
        CiplNumber,
        CiplDoc,
        CustomerExitNumber,
        CustomerExitDoc,
        ErgonBillDoc,
        DispatchingParty,
        ExpectedArrivalDate,
        ErgonBillNumber,
        ErgonBillDate,
        CustomerExitDate,
        CiplDate,
    } = formdata1;

    const {
        PONO,
        PODays,
        PODate,
        QuoteNO,
        QuoteDate,
        SupplierName,
        LinkedProject,
    } = formdata2;

    const finalReceivedDeliveryStatus = ExpectedArrivalDate <= currentDate ? receivedDeliveryStatus : "0";

    const payload = {
        CIPL_Doc_No: CiplNumber,
        CIPL_Doc: CiplDoc,
        Customer_Exit_Bill_No: CustomerExitNumber,
        Customer_Exit_Bill_Doc: CustomerExitDoc,
        Entry_Bill_Doc: ErgonBillDoc,
        Dispatching_Party: SupplierName,
        Expected_Arrival_Date: ExpectedArrivalDate,
        ID_No: Purchase_id,
        Entry_Bill_No: ErgonBillNumber,
        Entry_Bill_Date: ErgonBillDate,
        Customer_Exit_Bill_Date: CustomerExitDate,
        CIPL_Date: CiplDate,
        Received_Delivery_Status: finalReceivedDeliveryStatus,
        Type: "Purchase",
    };

    const payloadfiles1 = new FormData();
    const payloadfiles2 = new FormData();
    const payloadfiles3 = new FormData();
    const payloadfiles4 = new FormData();
    payloadfiles3.append("CIPL_Doc", documentToUpload1);
    payloadfiles4.append("MOPA_Attestation", documentToUpload4);
    payloadfiles2.append("Customer_Exit_Bill_Doc", documentToUpload2);
    payloadfiles1.append("Entry_Bill_Doc", documentToUpload3);

    try {
        const res = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveriesnew_post', payload);
        const proId = res.data.ID_No;

        await Promise.all([
            axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseincomingdeliveries_put?proId=${proId}`, payloadfiles1, { headers: { "Content-Type": "multipart/form-data" } }),
            axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseincomingdeliveries_put2?proId=${proId}`, payloadfiles2, { headers: { "Content-Type": "multipart/form-data" } }),
            axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseincomingdeliveries_put3?proId=${proId}`, payloadfiles3, { headers: { "Content-Type": "multipart/form-data" } }),
            axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchaseincomingdeliveries_put4?proId=${proId}`, payloadfiles4, { headers: { "Content-Type": "multipart/form-data" } }),
            axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdeliveries_put_inspectionID?proId=${proId}`, { Incoming_Inspection_Id: proId + 20000 })
        ]);

        const modifiedMergedArray = mergedArray.map(item => ({
            ...item,
            Incoming_Delivery_ID: proId,
            Incoming_Inspection_ID: proId + 20000,
            Received_Delivery_Status: finalReceivedDeliveryStatus,
        }));

        const [res5, res6] = await Promise.all([
            axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_items_post', modifiedMergedArray),
            axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_items_post_inspection', modifiedMergedArray)
        ]);

        if (res.status === 200 && res5.status === 200 && res6.status === 200) {
            alert("Incoming delivery created successfully");
            setIsLoading(false);
            navigate(-1);
        }
    } catch (error) {
        console.error('Error during save operation:', error);
        alert("Failed to create incoming delivery");
        setIsLoading(false);
    }
    setIsLoading(false);
};

const handleCancel = () => {
  navigate(-1); // This will take the user back to the previous page they were on
};

  const [fileName1, setFileName1] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [fileName3, setFileName3] = useState("");
  const [fileName4, setFileName4] = useState("");
  const handlecross = (name) => {
    switch (name) {
     
      case "CIPL_Doc":
        setFileName1("");
        setDocumentToUpload1("");
        break;
      case "Customer_Exit_Bill_Doc":
        setFileName2("");
        setDocumentToUpload2("");
        break;
      case "Entry_Bill_Doc":
        setFileName3("");
        setDocumentToUpload3("");
        break;
      case "MOPA_Attestation":
        setFileName4("");
        setDocumentToUpload4("");
        break;
    }
  };
  
  const [documents, setDocuments] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDocuments([...documents, file]);
      setFileNames([...fileNames, ""]); // Add an empty string for the corresponding file
      event.target.value = ""; // Reset the file input
      console.log("docs",setDocuments)
      console.log("names",setFileNames)
    }
  };

  // const handleCross2 = (index) => {
  //   const updatedDocuments = [...documents];
  //   const updatedFileNames = [...fileNames];
  //   updatedDocuments.splice(index, 1);
  //   updatedFileNames.splice(index, 1);
  //   setDocuments(updatedDocuments);
  //   setFileNames(updatedFileNames);
  // };

  const handlefileupload1 = (name, event) => {
    event.preventDefault();
    const temp1 = event.target.files[0];
    setDocumentToUpload1(temp1);

    const inputElement1 = document.getElementsByName(name)[0];
    const selectedFile1 = inputElement1.files[0];

    if (selectedFile1) {
      const fileName1 = selectedFile1.name;
      setFileName1(fileName1 ? fileName1 : "");
    } else {
      setFileName1("");
    }
    inputElement1.value = "";
    temp1.value = "";
  };
  const handlefileupload2 = (name, event) => {
    event.preventDefault();
    const temp2 = event.target.files[0];
    setDocumentToUpload2(temp2);

    const inputElement2 = document.getElementsByName(name)[0];
    const selectedFile2 = inputElement2.files[0];

    if (selectedFile2) {
      const fileName2 = selectedFile2.name;
      setFileName2(fileName2 ? fileName2 : "");
    } else {
      setFileName2("");
    }
    inputElement2.value = "";
    temp2.value = "";
  };
  const handlefileupload3 = (name, event) => {
    event.preventDefault();
    const temp3 = event.target.files[0];
    setDocumentToUpload3(temp3);

    const inputElement3 = document.getElementsByName(name)[0];
    const selectedFile3 = inputElement3.files[0];

    if (selectedFile3) {
      const fileName3 = selectedFile3.name;
      setFileName3(fileName3 ? fileName3 : "");
    } else {
      setFileName3("");
    }
    inputElement3.value = "";
    temp3.value = "";
  };
  const handlefileupload4 = (name, event) => {
    event.preventDefault();
    const temp4 = event.target.files[0];
    setDocumentToUpload4(temp4);

    const inputElement4 = document.getElementsByName(name)[0];
    const selectedFile4 = inputElement4.files[0];

    if (selectedFile4) {
      const fileName4 = selectedFile4.name;
      setFileName4(fileName4 ? fileName4 : "");
    } else {
      setFileName4("");
    }
    inputElement4.value = "";
    temp4.value = "";
  };
  const [inputValues, setInputValues] = useState({});

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "230px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));
  const handleCheckboxChange = (isChecked) => {
    if (isChecked) {
      const updatedQuantities = differencesArray.map(
        (difference) => difference || ""
      );
      setIncomingQuantities(updatedQuantities);
    } else {
      setIncomingQuantities(new Array(filteredItems.length).fill(""));
    }
    setIsfillsame(isChecked);
    setErrors(Array(filteredItems.length).fill(false));
  };
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const currentDate = getCurrentDate();

  return (
    <div style={{ marginTop: "115px", marginLeft: "1%", marginRight: "1%" }}>
      <LoadingScreen isLoading={isLoading} />
      <div>
        <button className={styles.mybutton} onClick={handleClick}>
          &lt; Incoming Deliveries
        </button>
      </div>
      <div>
        <div className={styles.box}>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              PO No: {formdata2.PONO}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              PO Date: {formdata2.PODate}
            </label>
          </div>
          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Supplier Name: {formdata2.SupplierName}
            </label>
          </div>

          <div className={styles.smallcontainer}>
            <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
              Linked to SO: {formdata2.LinkedProject}
            </label>
          </div>
        </div>
      </div>

      {isNext ? (
        <div className={styles.midsection}>
          <h1
            style={{
              fontSize: " 18px",
              color: "#FFFFFF",
              fontWeight: "600",
              padding: "10px 5px",
            }}
          >
            Step 2 of 2 - Item details
          </h1>
        </div>
      ) : (
        <div className={styles.midsection}>
          <h1
            style={{
              fontSize: " 18px",
              color: "#FFFFFF",
              fontWeight: "600",
              padding: "10px 5px",
            }}
          >
            Step 1 of 2 - Document details
          </h1>
        </div>
      )}

      {/* form div below */}

      {isNext ? (
        <div className={styles.sections}>
          <div className={styles.editAndSaveButtons}>
            <>
              <button
                variant="contained"
                onClick={handleSave}
                className={styles.saveButton}
                disabled={saveDisabled || inputDisabled || test || isLoading}
                style={{ opacity: saveDisabled || inputDisabled || test? 0.8 : 1 }} // Reduce opacity when disabled
              >
                Save
              </button>

              <button
                variant="outlined"
                onClick={handleBack}
                className={styles.cancelBtn}
              >
                Back
              </button>
              <button
                variant="outlined"
                onClick={handleCancel}
                className={styles.cancelBtn}
              >
                Cancel
              </button>
            </>
          </div>
          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>OEM Part Number</th>
                  <th>Manufacturer Part Number</th>
                  <th>UOM</th>
                  <th>Pending Qty</th>
                  <th>
                    Incoming Qty
                    <input
                      type="checkbox"
                      checked={isfillsame}
                      style={{
                        width: "12px",
                        height: "12px",
                        marginLeft: "15px",
                      }}
                      onChange={(e) => handleCheckboxChange(e.target.checked)}
                    />
                    <label
                      style={{
                        marginLeft: "5px",
                        color: "#567191",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      Fill Same
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.Item_Name}</td>
                    <td>{item.OEM_Part_Number}</td>
                    <td>{item.Manufacturer_Part_Number}</td>
                    <td>{item.UoM}</td>
                    <td>{differencesArray[index]}</td>
                    <td style={{ position: "relative" }}>
                      <input
                        type="number"
                        style={{
                          border: "1px solid #5B6BE1",
                          maxWidth: "55px",
                          outline: "none",
                          paddingLeft: "5px",
                        }}
                        disabled={inputDisabled}
                        value={incomingQuantities[index] || ""}
                        onChange={(event) => handleQuantityChange(event, index)}
                      />
                      {errors[index] && (
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            fontSize: "13px",
                            position: "absolute",

                            left: 0,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          Incoming Qty can't exceed Pending Qty
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className={styles.sections}>
          <div className={styles.editAndSaveButtons}>
            <>
              <button
                variant="contained"
                onClick={handleNext}
                className={styles.saveButton}
              >
                Next
              </button>

              <button
                variant="outlined"
                onClick={handleCancel}
                className={styles.cancelBtn}
              >
                Cancel
              </button>
            </>
          </div>
          <form>
            <div style={{ marginLeft: "25px" }}>
              {/* custom cipl */}
              <div>
                <div>
                  <div
                    className={`${styles.formElement} ${styles.datepickerInput}`}
                  >
                    <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                      Expected Arrival Date*:
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="ExpectedArrivalDate"
                        value={
                          formdata1.ExpectedArrivalDate
                            ? dayjs(formdata1.ExpectedArrivalDate, "YYYY-MM-DD")
                            : null
                        }
                        onChange={(value) =>
                          handleDates("ExpectedArrivalDate", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        format="DD-MM-YYYY"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                          border: "1px solid #5B6BE1",
                          boxShadow: "none", // Corrected property name
                          marginLeft: "12px",
                        }}
                      />
                    </LocalizationProvider>
                    {formdata1.ExpectedArrivalDate &&
                      formdata1.ExpectedArrivalDate !== "" &&
                      formdata1.ExpectedArrivalDate <= currentDate && (
                        <>
                          <input
                            type="checkbox"
                            checked={receivedDeliveryStatus === "1"}
                            style={{
                              marginLeft: "30px",
                              width: "12px",
                              height: "12px",
                            }}
                            onChange={(e) =>
                              setReceivedDeliveryStatus(
                                e.target.checked ? "1" : "0"
                              )
                            }
                          />
                          <label
                            style={{
                              marginLeft: "10px",
                              color: "black",
                              fontWeight: 600,
                            }}
                          >
                            {" "}
                            Mark Received
                          </label>
                        </>
                      )}
                  </div>
                </div>
                <h2 className={styles.heading}>Supplier CIPL</h2>
                {/* Document No */}
                <div className={styles.formElement}>
                  <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                    Document Number:
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      value={formdata1.CiplNumber}
                      name="CiplNumber"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                    />
                  </div>
                </div>
                {/* Document No. Ends */}

                {/* Document Date */}
                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}
                >
                  <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                    Document Date:
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="CiplDate"
                      value={
                        formdata1.CiplDate
                          ? dayjs(formdata1.CiplDate, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(value) => handleDates("CiplDate", value)}
                      slotProps={{ textField: { size: "small" } }}
                      format="DD-MM-YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #5B6BE1",
                        boxShadow: "none", // Corrected property name
                        marginLeft: "52px",
                      }}
                    />
                  </LocalizationProvider>
                </div>
                {/* Document Date Ends */}
                {/* ----------------------------------------------------------------------------------------------- */}
                <div>
                <div
                  style={{
                    display: "inline-flex",
                    marginLeft: "0px",
                    padding: "10px 0px",
                    alignItems: "center",
                    border: "1px",
                  }}
                >
                  <label
                    htmlFor="upload1"
                    style={{ color: "#5B6BE1", fontWeight: "600" }}
                  >
                    Upload Document:
                  </label>
                  <input
                    type="file"
                    name="upload1"
                    id="upload1"
                    onInput={(e) => handlefileupload1("upload1", e)}
                    style={{
                      display: "none",
                    }}
                  />
                  <div
                    className={styles.textbox2}
                    style={{ position: "relative" }}
                    onClick={() => document.getElementById("upload1").click()}
                  >
                    <label
                      style={{
                        marginLeft: "15px",
                        marginTop: "5px",
                        color: "rgba(128, 128, 128, 0.8)",
                        overflow: "hidden", // Hide overflow text
                        textOverflow: "ellipsis", // Display ellipsis for overflow text
                        whiteSpace: "nowrap", // Prevent wrapping
                        maxWidth: "180px", // Set maximum width for the file name
                        alignItems: "center",
                      }}
                    >
                      {fileName1 ? fileName1 : "Upload File"}
                    </label>

                    <svg
                      width="25"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px", // Adjust as needed
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                        fill="grey"
                      />
                      <path
                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                        fill="grey"
                      />
                    </svg>
                  </div>
                  {fileName1 && (
                    <svg
                      width="25"
                      onClick={() => handlecross("CIPL_Doc")}
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="grey"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                        fill="grey"
                      />
                      <path
                        d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                        fill="grey"
                      />
                    </svg>
                  )}
                </div>
              </div>
                {/* ----------------------------------------------------------------------------------------------- */}
              </div>
              {/* Customer Exit Bill/Delivery Note */}
              <div
                style={{
                  marginTop: "30px",
                }}
              >
                <h2 className={styles.heading}>
                  Supplier Exit Bill/Delivery Note
                </h2>
                {/* Document No */}
                <div className={styles.formElement}>
                  <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                    Document Number:
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      value={formdata1.CustomerExitNumber}
                      name="CustomerExitNumber"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                    />
                  </div>
                </div>
                {/* Document No. Ends */}

                {/* Document Date */}
                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}
                >
                  <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                    Document Date:
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="CustomerExitDate"
                      value={
                        formdata1.CustomerExitDate
                          ? dayjs(formdata1.CustomerExitDate, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(value) =>
                        handleDates("CustomerExitDate", value)
                      }
                      slotProps={{ textField: { size: "small" } }}
                      format="DD-MM-YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #5B6BE1",
                        boxShadow: "none", // Corrected property name
                        marginLeft: "52px",
                      }}
                    />
                  </LocalizationProvider>
                </div>
                {/* Document Date Ends */}

                <div
                  style={{
                    display: "inline-flex",
                    marginLeft: "0px",
                    padding: "10px 0px",
                    alignItems: "center",
                    border: "1px",
                  }}
                >
                  <label
                    htmlFor="upload2"
                    style={{ color: "#5B6BE1", fontWeight: "600" }}
                  >
                    Upload Document:
                  </label>
                  <input
                    type="file"
                    name="upload2"
                    id="upload2"
                    onInput={(e) => handlefileupload2("upload2", e)}
                    style={{
                      display: "none",
                    }}
                  />
                  <div
                    className={styles.textbox2}
                    style={{ position: "relative" }}
                    onClick={() => document.getElementById("upload2").click()}
                  >
                    <label
                      style={{
                        marginLeft: "15px",
                        marginTop: "5px",
                        color: "rgba(128, 128, 128, 0.8)",
                        overflow: "hidden", // Hide overflow text
                        textOverflow: "ellipsis", // Display ellipsis for overflow text
                        whiteSpace: "nowrap", // Prevent wrapping
                        maxWidth: "180px", // Set maximum width for the file name
                        alignItems: "center",
                      }}
                    >
                      {fileName2 ? fileName2 : "Upload File"}
                    </label>

                    <svg
                      width="25"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px", // Adjust as needed
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                        fill="grey"
                      />
                      <path
                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                        fill="grey"
                      />
                    </svg>
                  </div>
                  {fileName2 && (
                    <svg
                      width="25"
                      onClick={() => handlecross("Customer_Exit_Bill_Doc")}
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="grey"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                        fill="grey"
                      />
                      <path
                        d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                        fill="grey"
                      />
                    </svg>
                  )}
                </div>
              </div>
              {/* Ergon Entry Bill */}
              <div
                style={{
                  marginTop: "30px",
                }}
              >
                <h2 className={styles.heading}>Ergon Entry Bill</h2>
                {/* Document No */}
                <div className={styles.formElement}>
                  <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                    Document Number:
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      value={formdata1.ErgonBillNumber}
                      name="ErgonBillNumber"
                      onChange={handleOnChange}
                      className={styles.textbox1}
                    />
                  </div>
                </div>
                {/* Document No. Ends */}

                {/* Document Date */}
                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}
                >
                  <label style={{ color: "#5B6BE1", fontWeight: "600" }}>
                    Document Date:
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="ErgonBillDate"
                      value={
                        formdata1.ErgonBillDate
                          ? dayjs(formdata1.ErgonBillDate, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(value) => handleDates("ErgonBillDate", value)}
                      slotProps={{ textField: { size: "small" } }}
                      format="DD-MM-YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #5B6BE1",
                        boxShadow: "none", // Corrected property name
                        marginLeft: "52px",
                      }}
                    />
                  </LocalizationProvider>
                </div>
                {/* Document Date Ends */}

                <div
                  style={{
                    display: "inline-flex",
                    marginLeft: "0px",
                    padding: "10px 0px",
                    alignItems: "center",
                    border: "1px",
                  }}
                >
                  <label
                    htmlFor="upload3"
                    style={{ color: "#5B6BE1", fontWeight: "600" }}
                  >
                    Upload Document:
                  </label>
                  <input
                    type="file"
                    name="upload3"
                    id="upload3"
                    onInput={(e) => handlefileupload3("upload3", e)}
                    style={{
                      display: "none",
                    }}
                  />
                  <div
                    className={styles.textbox2}
                    style={{ position: "relative" }}
                    onClick={() => document.getElementById("upload3").click()}
                  >
                    <label
                      style={{
                        marginLeft: "15px",
                        marginTop: "5px",
                        color: "rgba(128, 128, 128, 0.8)",
                        overflow: "hidden", // Hide overflow text
                        textOverflow: "ellipsis", // Display ellipsis for overflow text
                        whiteSpace: "nowrap", // Prevent wrapping
                        maxWidth: "180px", // Set maximum width for the file name
                        alignItems: "center",
                      }}
                    >
                      {fileName3 ? fileName3 : "Upload File"}
                    </label>

                    <svg
                      width="25"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px", // Adjust as needed
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                        fill="grey"
                      />
                      <path
                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                        fill="grey"
                      />
                    </svg>
                  </div>{" "}
                  {fileName3 && (
                    <svg
                      width="25"
                      onClick={() => handlecross("Entry_Bill_Doc")}
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="grey"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                        fill="grey"
                      />
                      <path
                        d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                        fill="grey"
                      />
                    </svg>
                  )}
                </div>
               
              </div>


              <div
                  style={{
                    display: "inline-flex",
                    marginLeft: "0px",
                    padding: "10px 0px",
                    alignItems: "center",
                    border: "1px",
                  }}
                >
                  <label
                    htmlFor="upload4"
                    style={{ color: "#5B6BE1", fontWeight: "600" }}
                  >
                    MOFA Attestation:
                  </label>
                  <input
                    type="file"
                    name="upload4"
                    id="upload4"
                    onInput={(e) => handlefileupload4("upload4", e)}
                    style={{
                      display: "none",
                    }}
                  />
                  <div
                    className={styles.textbox2}
                    style={{ position: "relative" }}
                    onClick={() => document.getElementById("upload4").click()}
                  >
                    <label
                      style={{
                        marginLeft: "15px",
                        marginTop: "5px",
                        color: "rgba(128, 128, 128, 0.8)",
                        overflow: "hidden", // Hide overflow text
                        textOverflow: "ellipsis", // Display ellipsis for overflow text
                        whiteSpace: "nowrap", // Prevent wrapping
                        maxWidth: "180px", // Set maximum width for the file name
                        alignItems: "center",
                      }}
                    >
                      {fileName4 ? fileName4 : "Upload File"}
                    </label>

                    <svg
                      width="25"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px", // Adjust as needed
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                        fill="grey"
                      />
                      <path
                        d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                        fill="grey"
                      />
                    </svg>
                  </div>{" "}
                  {fileName4 && (
                    <svg
                      width="25"
                      onClick={() => handlecross("MOPA_Attestation")}
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      height="25"
                      viewBox="0 0 20 20"
                      fill="grey"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                        fill="grey"
                      />
                      <path
                        d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                        fill="grey"
                      />
                    </svg>
                  )}
                </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PurchaseIncomingCreateNew;
