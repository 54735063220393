import React, { useState } from 'react';
import axios from 'axios';
import styles from "./css/emailform.module.css"; // Ensure the CSS module is correctly linked
import Etlogo from "../Components/Etlogo.png"; // Make sure the path matches where you've stored your image

function EmailForm() {
    const [emailData, setEmailData] = useState({
        recipientEmail: '',
        name: '',
        subject: '',
        htmlContent: '',
        textContent: ''
    });
    const [showForm, setShowForm] = useState(false);

    const handleChange = (e) => {
        setEmailData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('recipientEmail', emailData.recipientEmail);
        formData.append('name', emailData.name);
        formData.append('subject', emailData.subject);
        formData.append('htmlContent', emailData.htmlContent);
        formData.append('textContent', emailData.textContent);

        try {
            await axios.post('/send-email', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Email sent successfully');
            setEmailData({
                recipientEmail: '',
                name: '',
                subject: '',
                htmlContent: '',
                textContent: ''
            }); // Reset form after successful submission
            setShowForm(false); // Optionally close form or keep it open
        } catch (error) {
            alert('Failed to send email');
            console.error('Failed to send email:', error);
        }
    };

    return (
        <div>
            <button onClick={() => setShowForm(!showForm)} className={styles.toggleButton}>
                {showForm ? 'Close Form' : 'Send Email'} {/* Adjusted for clarity */}
            </button>

            {showForm && (
                <div className={styles.emailcomposer}>
                    <button className={styles.closeButton} onClick={() => setShowForm(false)}>×</button>
                    <h1>Send Email</h1>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Recipient's Email:
                            <input type="email" name="recipientEmail" value={emailData.recipientEmail} onChange={handleChange} required />
                        </label>
                        <br />
                        <label>
                            Your Name:
                            <input type="text" name="name" value={emailData.name} onChange={handleChange} required />
                        </label>
                        <br />
                        <label>
                            Subject:
                            <input type="text" name="subject" value={emailData.subject} onChange={handleChange} required />
                        </label>
                        <br />
                        <label>
                            HTML Content:
                            <textarea name="htmlContent" value={emailData.htmlContent} onChange={handleChange} required></textarea>
                        </label>
                        <br />
                        <label>
                            Text Content:
                            <textarea name="textContent" value={emailData.textContent} onChange={handleChange} required></textarea>
                        </label>
                        <br />
                        <button type="submit">Send Email</button>
                    </form>
                </div>
            )}
        </div>
    );
}

export default EmailForm;
