import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_trading_deliveries.module.css";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import TabsBarSparesTrading from './TabsBarSparesTrading';
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparestradingWorkshopDeliveries(){
    const { id } = useParams();
    // const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks
    const [deliveries, setDeliveries] = useState([]);
    const [deliveriesList, setDeliveriesList] = useState([]);
    const [deliveryDetails, setDeliveryDetails] = useState({});
    const navigate = useNavigate();


  useEffect(() => {
    const fetchDeliveries = async () => {
        try {
            
            const response = await axios.get(
                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
            );
            console.log("response", response)

            if (response.data.success) {
                setDeliveries(response.data.data);
                console.log("deliveries",response.data.data)
            }
            
        } catch (error) {
            console.error("Error fetching deliveries:", error);
            
        }
    };
    fetchDeliveries();
    const fetchDeliveriesList = () => {
        axios
            .get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/delivery/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    const updatedDeliveriesList = checkAndUpdateDeliveryStatus(response.data);
                    setDeliveriesList(updatedDeliveriesList);
                }
            })
            .catch((error) => {
                console.error("Error fetching deliveries:", error);
            });
    };

    const checkAndUpdateDeliveryStatus = (deliveriesList) => {
        const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
        const updatedDeliveries = deliveriesList.map((delivery) => {
            if (delivery.Delivery_Status === "Upcoming" && delivery.Delivery_Date <= today) {
                // Update the delivery status to 'Pending' if date has passed
                axios
                    .put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/delivery/updateStatus`, {
                        Delivery_ID: delivery.Delivery_ID,
                        Delivery_Status: "Pending",
                    })
                    .then(() => {
                        console.log(`Updated delivery ${delivery.Delivery_ID} to Pending`);
                    })
                    .catch((error) => {
                        console.error("Error updating delivery status:", error);
                    });
                // Return the delivery with the status updated locally
                return { ...delivery, Delivery_Status: "Pending" };
            }
            return delivery;
        });
        return updatedDeliveries;
    };
    fetchDeliveriesList();

    const fetchDeliveryDetails = async () => {
        try {    
          const response = await axios.get(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_delivery_update_getAll`
          );
  
          setDeliveryDetails(response.data.data); 
          
        } catch (error) {
          console.error("Error fetching delivery details:", error);
        }
      };
      fetchDeliveryDetails();
}, [id]);
console.log("delivery list",deliveriesList)

  const getStatusColor = (status) => {
    switch (status) {
        case 'Pending':
            return styles.pending;
        case 'Delivered':
            return styles.delivered;
        case 'Upcoming' :
            return styles.upcoming;
        case 'Dispatched' :
            return styles.dispatched;
        default:
            return '';
    }
};

const handleDeliveryDivClick = (Sales_Order_No,Delivery_ID) => {
    navigate(`/workshop-spares-trading-deliveries-update/${id}/${encodeURIComponent(deliveries[0]?.Sales_Order_No || '')}/${Delivery_ID}`);
  }



    return(
        <div>
            <div className={styles.topdiv}>
                <label className={styles.aryl1}>
                SO: {deliveries[0]?.Sales_Order_No ? deliveries[0]?.Sales_Order_No : "-"}
                </label>
                <label className={styles.aryl}>
                PO: {deliveries[0]?.PO_No  ? deliveries[0]?.PO_No : "-"}
                </label>
                <label className={styles.aryl}>
                Customer Name: {deliveries[0]?.Customer_Name  ? deliveries[0]?.Customer_Name : "-"}
                </label>
            </div>

            <TabsBarSparesTrading activeTab="Deliveries" />

            <div className={styles.listContainer}>
            <div className={styles.deliveriesList}>
                {deliveriesList.length > 0 ? (
                    deliveriesList.map((delivery, index) => (
                        <div 
                            key={index} 
                            className={styles.deliveryItem}
                            onClick={() => handleDeliveryDivClick(delivery.Sales_Order_No, delivery.Delivery_ID)}
                        >
                            <div className={styles.deliveryInfo}>
                                {`Outgoing Delivery ID: ${delivery.Delivery_ID || '-'}  |  Delivery date: ${delivery.Delivery_Date || '-'} | Customer name: ${deliveries[0]?.Customer_Name  ? deliveries[0]?.Customer_Name : "-"} | CIPL: ${delivery.CIPL_No || '-'} | BOE: ${delivery.BOE_No || '-'}`}
                            </div>
                            <div className={`${styles.statusButton} ${getStatusColor(delivery.Delivery_Status)}`}>
                                {delivery.Delivery_Status ? delivery.Delivery_Status.toUpperCase() : null}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={styles.nolist}>No deliveries found.</div>
                )}
            </div>
        </div>
        </div>
        
    )
        
    

}

export default SparestradingWorkshopDeliveries;