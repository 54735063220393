import React from "react";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import TabsBarSparesTrading from './TabsBarSparesTrading';
import LoadingScreen from './LoadingScreen';
import styles from "./css/projectchecklistworkshop.module.css";
import styles1 from "./css/workshop_spares_trading_item_details.module.css";
import styles2 from "./css/workshop_spares_trading_deliveries.module.css";
import styles3 from "./css/sparestradingworkshopdeliveriesupdate.module.css";
import { useEffect, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import UploadDocument from "./Uploaddocument";
import axios from "axios";
import { Switch } from "@mui/material";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import BasicModal from "./Modal";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ProjectDetailsWorkshop from "./ProjectDetailsWorkshop";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';




function SparesTradingChecklist() {
    const { id } = useParams();
    // const [showeditRow, setShoweditRow] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(true);
    const [checkListData, setCheckListData] = useState({});
    const [refresh, setRefresh] = useState(true);
    const [activeTab, setActiveTab] = useState("Project Checklist"); // Set default tab
    // const [featureprojectchecklistedit,setfeatureprojectchecklistedit] =  useState([]);
    const [formData, setFormData] = useState({
        Sales_Order_No: "",
        PO_No: "",
        PO_Date: null,
        Quote_No: "",
        Quote_Date: null,
        Costing_ID: "",
        Customer_Name: "",
        Scope: "",
        Scope_Description: "",
        Estimated_Date_Of_Dispatch: null,
        Ops_Engineer: "",
        Current_Status: "",
      });
      // const [selectValues1, setSelectValues1] = useState({
      //   item1: "",
      //   item2: "",
      //   item3: "",
      //   item4: "",
      // });
      const [selectValues2, setSelectValues2] = useState({
        item1: "",
        item2: "",
        item3: "",
        item4: "",
        item5: "",
        item6: "",
        item7: "",
        item8: "",
        item9: "",
        item10: "",
        item11: "",
        item12: "",
      });
      const [selectValues3, setSelectValues3] = useState({
        item13: "",
        item14: "",
        item15: "",
      });
    // const [inputValues1, setInputValues1] = useState({
    //     issue1: "",
    //     issue2: "",
    //     issue3: "",
    //     issue4: "",
    //   });
      const [inputValues2, setInputValues2] = useState({
        issue1: "",
        issue2: "",
        issue3: "",
        issue4: "",
        issue5: "",
        issue6: "",
        issue7: "",
        issue8: "",
        issue9: "",
        issue10: "",
        issue11: "",
        issue12: "",
      });
    
    const [inputValues3, setInputValues3] = useState({
        issue13: "",
        issue14: "",
        issue15: "",
      });
    
    // const { item1, item2, item3, item4} = selectValues1;
    // const {
    //     item5,
    //     item6,
    //     item7,
    //     item8,
    //     item9,
    //     item10,
    //     item11,
    //     item12,
    //     item13,
    //     item14,
    //     item15,
    //     item16,
    //   } = inputValues2;
    
    // const { item17, item18, item19} =
    //   selectValues3;
    const [activeStep, setActiveStep] = useState(0);

    

    // const handleSelectChange1 = (e) => {
    //     const { name, value } = e.target;
    //     step1IsActive();
    //     setSelectValues1({
    //       ...selectValues1,
    //       [name]: value,
    //     });
    //   };

    const handleSelectChange2 = (e) => {
        const { name, value } = e.target;
        step2IsActive();
        setSelectValues2({
          ...selectValues2,
          [name]: value,
        });
      };

    const handleSelectChange3 = (e) => {
        const { name, value } = e.target;
        step3IsActive();
        setSelectValues3({
          ...selectValues3,
          [name]: value,
        });
      };

    const step1IsActive = () => {
        setActiveStep(0);
      };

    const step2IsActive = () => {
        setActiveStep(1);
      };
    
    const step3IsActive = () => {
        setActiveStep(2);
      };

    const toggleDropdown1 = () => {
        setIsExpanded1(!isExpanded1);
      }; 
    
    const toggleDropdown2 = () => {
        setIsExpanded2(!isExpanded2);
      };
    
    const toggleDropdown3 = () => {
        setIsExpanded3(!isExpanded3);
      };
      
    // const handleInputValueChange1 = (e) => {
    //     const { name, value } = e.target;
    //     setInputValues1({
    //       ...inputValues1,
    //       [name]: value,
    //     });
    //   };

    const handleInputValueChange2 = (e) => {
        const { name, value } = e.target;
        setInputValues2({
          ...inputValues2,
          [name]: value,
        });
      };
    
      const handleInputValueChange3 = (e) => {
        const { name, value } = e.target;
        setInputValues3({
          ...inputValues3,
          [name]: value,
        });
      };

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
            );
    
            if (response.data.success) {
              const data = response.data.data[0];
              setFormData({
                Sales_Order_No: data.Sales_Order_No,
                PO_No: data.PO_No,
                PO_Date: data.PO_Date,
                Quote_No: data.Quote_No,
                Quote_Date: data.Quote_Date,
                Costing_ID: data.Costing_ID,
                Customer_Name: data.Customer_Name,
                Scope: data.Scope,
                Scope_Description: data.Scope_Description,
                Estimated_Date_Of_Dispatch: data.Estimated_Date_Of_Dispatch,
                Ops_Engineer: data.Ops_Engineer,
                Current_Status: data.Current_Status,
              });
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error here
          }
        };
        fetchData();
      }, [id]);

      const getItem17Details = async () => {
        try {
          // Make a PUT request to the new API to check POD and update Item17 status
          const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/item17/${id}`);
          
          // Log the response from the API
          console.log("resitem17", res.data.status);
          
          // Return the status received from the API
          return res.data.status; // The API returns { status: 'Received' or 'Not received' }
        } catch (error) {
          console.error('Error fetching Item 17 details:', error);
          return 'Not received'; // Default status in case of error
        }
      };
      

  
      const getChecklistData = async () => {
        try {
          const res = await axios.get(
            "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop-spares-checklistgetdetails"
          );

          const filter = res.data.data.filter((el) => el.ID_No == id);

          // Fetch Item 17 details and directly set Item17 status
          //  const item17Status = await getItem17Details(); // Use await here
          //  console.log("Item17status", item17Status)

          // setInputValues1({
          //   issue1: filter[0]?.Issue1,
          //   issue2: filter[0]?.Issue2,
          //   issue3: filter[0]?.Issue3,
          //   issue4: filter[0]?.Issue4,
          // });

          setInputValues2({
            issue1: filter[0]?.Issue1,
            issue2: filter[0]?.Issue2,
            issue3: filter[0]?.Issue3,
            issue4: filter[0]?.Issue4,
            issue5: filter[0]?.Issue5,
            issue6: filter[0]?.Issue6,
            issue7: filter[0]?.Issue7,
            issue8: filter[0]?.Issue8,
            issue9: filter[0]?.Issue9,
            issue10: filter[0]?.Issue10,
            issue11: filter[0]?.Issue11,
            issue12: filter[0]?.Issue12,
            
          });

          setInputValues3({
            issue13: filter[0]?.Issue13,
            issue14: filter[0]?.Issue14,
            issue15: filter[0]?.Issue15,
          });

          // setSelectValues1({
          //   item1: filter[0]?.Item1,
          //   item2: filter[0]?.Item2,
          //   item3: filter[0]?.Item3,
          //   item4: filter[0]?.Item4,
            
          // });

          setSelectValues2({
            item1: filter[0]?.Item1,
            item2: filter[0]?.Item2,
            item3: filter[0]?.Item3,
            item4: filter[0]?.Item4,
            item5: filter[0]?.Item5,
            item6: filter[0]?.Item6,
            item7: filter[0]?.Item7,
            item8: filter[0]?.Item8,
            item9: filter[0]?.Item9,
            item10: filter[0]?.Item10,
            item11: filter[0]?.Item11,
            item12: filter[0]?.Item12,
          });

          setSelectValues3({
            item13: filter[0]?.Item13, // Directly set the status for Item 17
            item14: filter[0]?.Item14,
            item15: filter[0]?.Item15,
            
          });
          setCheckListData(filter[0]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        };
        // const item17Status =  getItem17Details();
        // console.log("Item17status", item17Status)
         // PUT API to update the database on page refresh or load
  // const updateDataOnRefresh = async () => {
  //   try {
  //     const payload = {
  //       Sales_Order_No: formData.Sales_Order_No,
  //       Current_Status: "Updated Status", // Update the status as per your requirement
  //     };

  //     const response = await axios.put(
  //       `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_checklist/update/${id}`,
  //       payload
  //     );

  //     if (response.status === 200) {
  //       console.log("Data updated successfully on refresh");
  //     }
  //   } catch (error) {
  //     console.error("Error updating data on refresh:", error);
  //   }
  // };

 // Trigger getChecklistData immediately when the component is mounted
        useEffect(() => {
          getChecklistData();
        }, [id]); // Runs on component load
      //   if (activeTab === "Project Checklist") {
      //     getChecklistData();
      //   }
      // }, [activeTab, id]);
      
   
      // const renderStatusSelect1 = (selectFor) => {
      //   return (
      //     <div className={styles.selectContainer}>
      //       <select
      //         className={styles.notdisabledInput}
      //         value={selectValues1[selectFor] || ""}
      //         name={selectFor}
      //         onChange={handleSelectChange1}>
      //         <option value="">Select</option>
      //         {/* <option value="Yet to Start">Yet to Start</option> */}
      //         <option value="In Progress">In Progress</option>
      //         <option value="Facing Issues">Facing Issues</option>
      //         <option value="Completed">Completed</option>
      //       </select>
      //       <svg
      //         className={styles.svgSelect}
      //         style={{
      //           right: "20px",
      //         }}
      //         width="20"
      //         height="20"
      //         viewBox="0 0 20 20"
      //         fill="none"
      //         xmlns="http://www.w3.org/2000/svg">
      //         <path
      //           d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
      //           fill="#394759"
      //         />
      //       </svg>
      //     </div>
      //   );
      // }; 

      const renderStatusSelect2 = (selectFor) => {
        return (
          <div className={styles.selectContainer}>
            <select
              className={styles.notdisabledInput}
              value={selectValues2[selectFor] || ""}
              name={selectFor}
              onChange={handleSelectChange2}>
              <option value="">Select</option>
              {/* <option value="Yet to Start">Yet to Start</option> */}
              <option value="In Progress">In Progress</option>
              <option value="Facing Issues">Facing Issues</option>
              <option value="Completed">Completed</option>
            </select>
            <svg
              className={styles.svgSelect}
              style={{ right: "20px" }}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
                fill="#394759"
              />
            </svg>
          </div>
        );
      };
    
      const renderStatusSelect3 = (selectFor) => {
        return (
          <div className={styles.selectContainer}>
            <select
              className={styles.notdisabledInput}
              value={selectValues3[selectFor] || ""}
              name={selectFor}
              onChange={handleSelectChange3}>
              <option value="">Select</option>
              {/* <option value="Yet to Start">Yet to Start</option> */}
              <option value="In Progress">In Progress</option>
              <option value="Facing Issues">Facing Issues</option>
              <option value="Completed">Completed</option>
            </select>
            <svg
              className={styles.svgSelect}
              style={{ right: "20px" }}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.9336 6.81665H9.74195H5.06695C4.26695 6.81665 3.86695 7.78332 4.43361 8.34998L8.75028 12.6666C9.44195 13.3583 10.5669 13.3583 11.2586 12.6666L12.9003 11.025L15.5753 8.34998C16.1336 7.78332 15.7336 6.81665 14.9336 6.81665Z"
                fill="#394759"
              />
            </svg>
          </div>
        );
      };
    
     // Save the form data (just simulate saving)
    const saveForm = async () => {
        // console.log("Form saved:", formData);
        // setEditMode(false); // Exit edit mode

    // const { item1, item2, item3, item4} = selectValues1;
    const {
        item1,
        item2,
        item3,
        item4,
        item5,
        item6,
        item7,
        item8,
        item9,
        item10,
        item11,
        item12,
      } = selectValues2;
    
    const { item13, item14, item15} =
      selectValues3;
    
    // const { issue1, issue2, issue3, issue4 } = inputValues1;
    const {
      issue1,
      issue2,
      issue3,
      issue4,
      issue5,
      issue6,
      issue7,
      issue8,
      issue9,
      issue10,
      issue11,
      issue12,
    } = inputValues2;
    const {
      issue13,
      issue14,
      issue15,
    } = inputValues3;

    const payload = {
      Sales_Order_No: formData.Sales_Order_No,
      // Item1: item1,
      // Item2: item2,
      // Item3: item3,
      // Item4: item4,
      Item1: item1,
      Item2: item2,
      Item3: item3,
      Item4: item4,
      Item5: item5,
      Item6: item6,
      Item7: item7,
      Item8: item8,
      Item9: item9,
      Item10: item10,
      Item11: item11,
      Item12: item12,
      Item13: item13,
      Item14: item14,
      Item15: item15,
      
      Issue1: issue1,
      Issue2: issue2,
      Issue3: issue3,
      Issue4: issue4,
      Issue5: issue5,
      Issue6: issue6,
      Issue7: issue7,
      Issue8: issue8,
      Issue9: issue9,
      Issue10: issue10,
      Issue11: issue11,
      Issue12: issue12,
      Issue13: issue13,
      Issue14: issue14,
      Issue15: issue15,
    };
    // console.log("values",inputValues1, inputValues2, selectValues1, selectValues2);


    const res = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_checklist/update/${id}`,
       payload
    );
    console.log("res",res);
    console.log("Payload", payload);
    handleCancel();
   

    if (res.status === 200) {
      alert("Form data updated successfully!");
      setRefresh(!refresh);
      getChecklistData();
    }
    };

  const handleCancel = () => {
    setEditMode(false); // Exit edit mode
    setRefresh(!refresh);
  };
      
    console.log("inputValues2", inputValues2);

     return(
        <div>
        <div className={styles1.topdiv}>
        <label className={styles1.aryl1}>SO: {formData.Sales_Order_No}</label>
        <label className={styles1.aryl}>PO: {formData.PO_No}</label>
        <label className={styles1.aryl}>Customer Name: {formData.Customer_Name}</label>
        {/* <label className={styles.aryl}>Project Description: {formdata1.projectDescription}</label>
        <label className={styles.aryl}>Quantity: {formdata1.quantity}</label>
        <label className={styles.aryl}>Target Days: {formdata1.TargetDaysForCompletion}</label>
        <label className={styles.aryl}>Target Manhours: {formdata1.TargetManhours}</label> */}
      </div>

    <TabsBarSparesTrading activeTab="Project Checklist" />

    {/* <Box className={styles.pageContainer}> */}
    <div className={styles2.listContainer}>
  
    <div className={styles.editAndSaveButtons}>
  {editMode ? (
    <>
    {/* <button className={styles3.saveButton} onClick={saveForm}>
              Save
            </button>
            <button className={styles3.cancelButton} onClick={handleCancel}>
              Cancel
            </button> */}
      <Button
        variant="outlined"
        onClick={handleCancel}
        className={styles.cancelBtn}>
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={saveForm}
        className={styles.saveButton}>
        Save
      </Button>
    </>
  ) : (
    <div>
       {/* <button className={styles3.editButton} onClick={() => setEditMode(true)}>
            Edit
          </button> */}
      <Button
        className={styles.previewAndDownloadBtn}
        variant="contained"
        onClick={() => setEditMode(true)}>
        Edit
      </Button>
    </div>
  )}
</div>
    
{/* <div className={styles.containerDropDowns} onClick={toggleDropdown1}>
          <h4 className={styles.mainDropdowns}>1. Order Finalisation and procurement</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded1 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span> */}
        {/* </div>
        {isExpanded1 && (
        <div className={styles.contentInsidedropdown}>
          <table className={styles.projectChecklistTable}>
            <thead>
              <tr>
                <th>Task Number</th>
                <th>Task Description</th>
                <th>Personnel Responsible</th>
                <th>Status</th>
                <th>Issues/Remarks</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Create spares inquiry (RFQ)</td>
                <td>Sandhya</td>
                <td>
                  {editMode ? (
                    renderStatusSelect1("item1")
                  ) : (
                    <div className={styles.selectContainer}>
                      <input
                        className={styles.disabledInput}
                        type="text"
                        value={checkListData?.Item1 || ""}
                        disabled
                      />
                      <svg
                        style={{ right: "40px" }}
                        className={styles.svgSelect}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                          fill="rgba(57, 71, 89, 0.5)"
                        />
                      </svg>
                    </div>
                  )}
                </td>
                <td>
                    {editMode ? (
                      <input
                        className={styles.notdisabledInput2}
                        name="issue1"
                        value={inputValues1.issue1}
                        onChange={handleInputValueChange1}
                      />
                    ) : (
                      <input
                        className={styles.disabledInput2}
                        type="text"
                        value={
                          checkListData?.Issue1 ? checkListData?.Issue1 : ""
                        }
                        disabled
                      />
                    )}
                    </td>
              </tr>
              <tr>
                  <td>2</td>
                  <td>Send RFQ to vendors(Ref supplier catalogue)</td>
                  <td>Sandhya/Ramadas</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect1("item2")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item2}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        className={styles.notdisabledInput2}
                        name="issue2"
                        value={inputValues1.issue2}
                        onChange={handleInputValueChange1}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue2 ? checkListData?.Issue2 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Add quoted rates from vendors </td>
                  <td>Sandhya/Ramadas</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect1("item3")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item3}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue3"
                        value={inputValues1.issue3}
                        onChange={handleInputValueChange1}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue3 ? checkListData?.Issue3 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Create Quote and send to customer</td>
                  <td>Sandhya/Ramadas</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect1("item4")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item4}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue4"
                        value={inputValues1.issue4}
                        onChange={handleInputValueChange1}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue4 ? checkListData?.Issue4 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      )} */}
      <div className={styles.containerDropDowns} onClick={toggleDropdown2}>
          <h4 className={styles.mainDropdowns}>1. Order fulfilment</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded2 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>
        {isExpanded2 && (
          <div className={styles.contentInsidedropdown}>
            <table className={styles.projectChecklistTable}>
              <thead>
                <th>Task Number</th>
                <th>Task description</th>
                <th>Personnel Responsible</th>
                <th>Status</th>
                <th>Issues/Remarks</th>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>PO received</td>
                  <td>Sandhya</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item1")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item1}
                          disabled
                        />
                        <svg
                          style={{
                            right: "45px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue1"
                        value={inputValues2.issue1}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue1 ? checkListData?.Issue1 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Create Spares trading project</td>
                  <td>Sandhya/Ramadas</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item2")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item2}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue2"
                        value={inputValues2.issue2}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue2 ? checkListData?.Issue2: ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Add Item details</td>
                  <td>Sandhya/Ramadas</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item3")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item3}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue3"
                        value={inputValues2.issue3}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue3 ? checkListData?.Issue3 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Check in Ergon Inventory for items availability</td>
                  <td>Sandhya/Ramadas</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item4")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item4}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue4"
                        value={inputValues2.issue4}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        name="issue4"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue4 ? checkListData?.Issue4: ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Issue Purchase PO to vendors</td>
                  <td>Sandhya/Ramadas</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item5")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item5}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue5"
                        value={inputValues2.issue5}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue5? checkListData?.Issue5 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Receipt of items in inventory</td>
                  <td>Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item6")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item6}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue6"
                        value={inputValues2.issue6}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue6? checkListData?.Issue6 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Allocate items to project</td>
                  <td>Sandhya/Ramadas</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item7")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item7}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue7"
                        value={inputValues2.issue7}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue7? checkListData?.Issue7: ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Create dispatch</td>
                  <td>Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item8")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item8}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue8"
                        value={inputValues2.issue8}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue8? checkListData?.Issue8 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Perform outgoing inspection</td>
                  <td>Haneef</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item9")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item9}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue9"
                        value={inputValues2.issue9}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue9? checkListData?.Issue9 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Create and Add CIPL details to outgoing delivery</td>
                  <td>Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item10")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item10}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue10"
                        value={inputValues2.issue10}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue10? checkListData?.Issue10 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Create and Add Exit bill details to outgoing delivery</td>
                  <td>Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item11")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item11}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue11"
                        value={inputValues2.issue11}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue11? checkListData?.Issue11 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Mark delivery as dispatched</td>
                  <td>Irfan</td>
                  <td>
                    {editMode ? (
                      <>{renderStatusSelect2("item12")}</>
                    ) : (
                      <div className={styles.selectContainer}>
                        <input
                          type="text"
                          className={styles.disabledInput}
                          value={checkListData?.Item12}
                          disabled
                        />
                        <svg
                          style={{
                            right: "40px",
                          }}
                          className={styles.svgSelect}
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                            fill="rgba(57, 71, 89, 0.5)"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode ? (
                      <input
                        name="issue12"
                        value={inputValues2.issue12}
                        onChange={handleInputValueChange2}
                        className={styles.notdisabledInput2}
                      />
                    ) : (
                      <input
                        type="text"
                        className={styles.disabledInput2}
                        value={
                          checkListData?.Issue12? checkListData?.Issue12 : ""
                        }
                        disabled
                      />
                    )}
                  </td>
                </tr>
               </tbody>
          </table>
        </div>
        )}
        <div className={styles.containerDropDowns} onClick={toggleDropdown3}>
          <h4 className={styles.mainDropdowns}>2. Post fulfilment</h4>
          <span
            style={{
              fontSize: "16px",
            }}>
            {isExpanded3 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 15.8203H11.6888H6.07877C5.11877 15.8203 4.63877 14.6603 5.31877 13.9803L10.4988 8.80031C11.3288 7.97031 12.6788 7.97031 13.5088 8.80031L15.4788 10.7703L18.6888 13.9803C19.3588 14.6603 18.8788 15.8203 17.9188 15.8203Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>
        {isExpanded3 && (
  <>
    <div className={styles.contentInsidedropdown}>
      <table className={styles.projectChecklistTable}>
        <thead>
          <tr>
            <th>Task Number</th>
            <th>Task Description</th>
            <th>Personnel Responsible</th>
            <th>Status</th>
            <th>Issues/Remarks</th>
          </tr>
        </thead>
        <tbody>
        <tr>
                    <td>13</td>
                    <td>
                       Upload signed POD
                    </td>
                    <td>Irfan</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item13")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item13}
                            disabled
                          />

                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )} 
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue13"
                          value={inputValues3.issue13}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue13 ? checkListData?.Issue13 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>14</td>
                    <td>
                    Raise invoice to customer
                    </td>
                    <td>Sandhya/Ramadas</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item14")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item14}
                            disabled
                          />

                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue14"
                          value={inputValues3.issue14}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue14 ? checkListData?.Issue14 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>
                    Payment realisation
                    </td>
                    <td>Midhula</td>
                    <td>
                      {editMode ? (
                        <>{renderStatusSelect3("item15")}</>
                      ) : (
                        <div className={styles.selectContainer}>
                          <input
                            type="text"
                            className={styles.disabledInput}
                            value={checkListData?.Item15}
                            disabled
                          />

                          <svg
                            style={{
                              right: "40px",
                            }}
                            className={styles.svgSelect}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.9188 8.18005H11.6888H6.07877C5.11877 8.18005 4.63877 9.34005 5.31877 10.0201L10.4988 15.2001C11.3288 16.0301 12.6788 16.0301 13.5088 15.2001L15.4788 13.2301L18.6888 10.0201C19.3588 9.34005 18.8788 8.18005 17.9188 8.18005Z"
                              fill="rgba(57, 71, 89, 0.5)"
                            />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td>
                      {editMode ? (
                        <input
                          name="issue15"
                          value={inputValues3.issue15}
                          onChange={handleInputValueChange3}
                          className={styles.notdisabledInput2}
                        />
                      ) : (
                        <input
                          type="text"
                          className={styles.disabledInput2}
                          value={
                            checkListData?.Issue15 ? checkListData?.Issue15 : ""
                          }
                          disabled
                        />
                      )}
                    </td>
                  </tr>
          
        </tbody>
      </table>
    </div>
  </>
)}
    </div>   

    </div>
  );
}

export default SparesTradingChecklist;