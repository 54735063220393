import React, { useState, useEffect } from "react";
import styles from "../Components/css/professionaldetails.module.css";
import UploadDocument from "./Uploaddocument";
import { NavLink } from "react-router-dom";
import {
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const names = ["MLT", "F&G", "Keppel", "BMC", "Modec"];

const ProfessionalDetailsEdit = ({
  S_No,
  name,
  designation,
  retainership,
  employeeid,
  signature,
  activeVisasOfCountries,
  jackExperience,
  skidExperience,
  craneExperience,
  datesBookedForProject,
  fixationExperience,
  seamanBookExpiry,
  seamanIssuingCountry,
  seamanDoc,
  bosietExpiry,
  bosietDoc,
  h2sExpiry,
  h2sDoc,
  medicalExpiry,
  medicalDoc,
  insuranceExpiry,
  insuranceDoc,
  sntEyeTestExpiry,
  sntEyeTestDoc,
  yellowFeverExpiry,
  yellowFeverDoc,
  cvFile,
  passportExpiry,
  passportIssuingCountry,
  passportDoc,
  setEnableEdit,
  enableEdit,
  visaIssuingCountry1,
  visaIssuingCountry2,
  visaIssuingCountry3,
  visaIssuingCountry4,
  visaIssuingCountry5,
  visaIssuingCountry6,
  visaExpiry1,
  visaExpiry2,
  visaExpiry3,
  visaExpiry4,
  visaExpiry5,
  visaExpiry6,
  visaDoc1,
  visaDoc2,
  visaDoc3,
  visaDoc4,
  visaDoc5,
  visaDoc6,
  dropdownVisa,
  rigName,
  refresh,
  setRefresh,
  signatureDocName,
  seamanDocDocName,
  bosietDocDocName,
  h2sDocDocName,
  medicalDocDocName,
  insuranceDocDocName,
  sntEyeTestDocDocName,
  yellowFeverDocName,
  passportDocDocName,
  visaDoc1DocName,
  visaDoc2DocName,
  visaDoc3DocName,
  visaDoc4DocName,
  visaDoc5DocName,
  visaDoc6DocName,
  photoUpload,
  photoUploadDocName,
  vaccinationCertificate,
  vaccinationCertificateDocName,
  pcc,
  pccDocName,
  pccExpiry,
}) => {
  // const { id } = useParams();
  // const [refresh, setRefresh] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [checked, setChecked] = useState(false);

  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiInputBase-input": {
      border: "transparent",
      padding: "0",
      paddingLeft: "5px",
    },
    "& .MuiFormLabel-root": {
      paddingTop: "3px",
    },
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      height: "45px",
      borderRadius: "8px",
      "&:hover": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
      },
    },
  }));

  const saveArr = rigName?.split(",");

  const [rigNames, setRigNames] = useState(saveArr);

  // let splittedDate;
  // let fromDate;
  // let toDate;
  // let convertedFromDate;
  // let convertedToDate;

  // const setData = () => {
  //   if (datesBookedForProject) {
  //     splittedDate = datesBookedForProject?.split("-");

  //     fromDate = splittedDate[0]?.split("/");
  //     toDate = splittedDate[1]?.split("/");

  //     convertedFromDate = new Date(
  //       `${fromDate[1]}/${fromDate[0]}/${fromDate[2]}`
  //     );
  //     convertedToDate = new Date(`${toDate[1]}/${toDate[0]}/${toDate[2]}`);

  //     setFormData({
  //       ...formData,
  //       datesBookedForProjectFrom: convertedFromDate,
  //       datesBookedForProjectTo: convertedToDate,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   setData();
  // }, [formData]);

  const [formData, setFormData] = useState({
    name: name,
    designation: designation,
    retainership:retainership,
    employeeid:employeeid,
    jackExperience: jackExperience,
    skidExperience: skidExperience,
    craneExperience: craneExperience,
    datesBookedForProjectFrom: "",
    datesBookedForProjectTo: "",
    fixationExperience: fixationExperience,
    seamanBookExpiry: seamanBookExpiry,
    seamanIssuingCountry: seamanIssuingCountry,

    bosietExpiry: bosietExpiry,

    h2sExpiry: h2sExpiry,

    medicalExpiry: medicalExpiry,

    insuranceExpiry: insuranceExpiry,

    sntEyeTestExpiry: sntEyeTestExpiry,
  yellowFeverExpiry:yellowFeverExpiry,
    
    cvFile: cvFile,
    passportExpiry: passportExpiry,
    passportIssuingCountry: passportIssuingCountry,

    pccExpiry: pccExpiry,

    visaFields: [
      { visaIssuingCountry1: visaIssuingCountry1, visaExpiry1: visaExpiry1 },
      { visaIssuingCountry2: visaIssuingCountry2, visaExpiry2: visaExpiry2 },
      { visaIssuingCountry3: visaIssuingCountry3, visaExpiry3: visaExpiry3 },
      { visaIssuingCountry4: visaIssuingCountry4, visaExpiry4: visaExpiry4 },
      // { visaIssuingCountry5: visaIssuingCountry5, visaExpiry5: visaExpiry5 },
      // { visaIssuingCountry6: visaIssuingCountry6, visaExpiry6: visaExpiry6 },
    ],
  });

  const [selectedFile, setSelectedFile] = useState({
    signature: signature,
    seamanDoc: seamanDoc,
    bosietDoc: bosietDoc,
    h2sDoc: h2sDoc,
    medicalDoc: medicalDoc,
    insuranceDoc: insuranceDoc,
    sntEyeTestDoc: sntEyeTestDoc,
    yellowFeverDoc: yellowFeverDoc,
    passportDoc: passportDoc,
    visaDoc1: visaDoc1,
    visaDoc2: visaDoc2,
    visaDoc3: visaDoc3,
    visaDoc4: visaDoc4,
    visaDoc5: visaDoc5,
    visaDoc6: visaDoc6,
    photoUpload: photoUpload,
    vaccinationCertificate: vaccinationCertificate,
    pcc: pcc,
  });

  const [selectedFileName, setSelectedFileName] = useState({
    signature: signatureDocName,
    seamanDoc: seamanDocDocName,
    bosietDoc: bosietDocDocName,
    h2sDoc: h2sDocDocName,
    medicalDoc: medicalDocDocName,
    insuranceDoc: insuranceDocDocName,
    sntEyeTestDoc: sntEyeTestDocDocName,
    yellowFeverDoc: yellowFeverDocName,
    passportDoc: passportDocDocName,
    visaDoc1: visaDoc1DocName,
    visaDoc2: visaDoc2DocName,
    visaDoc3: visaDoc3DocName,
    visaDoc4: visaDoc4DocName,
    visaDoc5: visaDoc5DocName,
    visaDoc6: visaDoc6DocName,
    photoUpload: photoUploadDocName,
    vaccinationCertificate: vaccinationCertificateDocName,
    pcc: pccDocName,
  });

  const removeVisaField = (index) => {
    const updatedVisaFields = [...formData.visaFields];
    updatedVisaFields.splice(index, 1);

    updatedVisaFields.forEach((el, index) => {
      const newIndex = index + 1;
      const oldIndex = index + 2;

      for (const prop in el) {
        if (prop === `visaExpiry${oldIndex}`) {
          el[`visaExpiry${newIndex}`] = el[prop];
          delete el[prop];
        } else if (prop === `visaIssuingCountry${oldIndex}`) {
          el[`visaIssuingCountry${newIndex}`] = el[prop];
          delete el[prop];
        }
      }
    });

    setFormData({
      ...formData,
      visaFields: updatedVisaFields,
    });
  };

  const dateChanges = (name, value) => {
    const selectedDate = value?.format("DD/MM/YYYY");
    setFormData({
      ...formData,
      [name]: selectedDate,
    });
  };

  const handleVisaDate = (name, value, index) => {
    const selectedDate = value?.format("DD/MM/YYYY");
    const updatedVisaFields = [...formData.visaFields];

    const currentVisaField = updatedVisaFields[index];

    currentVisaField[name] = selectedDate;

    setFormData({
      ...formData,
      visaFields: updatedVisaFields,
    });
  };

  const handleVisaCountryChange = (index, e) => {
    const { name, value } = e.target;

    const updatedVisaFields = [...formData.visaFields];

    const currentVisaField = updatedVisaFields[index];

    currentVisaField[name] = value;
    setFormData({
      ...formData,
      visaFields: updatedVisaFields,
    });
  };

  const addVisas = () => {
    if (formData.visaFields.length < 6) {
      setFormData({
        ...formData,
        visaFields: [
          ...formData.visaFields,
          { visaIssuingCountry: "", visaExpiry: "" },
        ],
      });
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (checked) {
      body.classList.add(styles.noScroll);
    } else {
      body.classList.remove(styles.noScroll);
    }

    return () => {
      body.classList.remove(styles.noScroll);
    };
  }, [checked]);

  const handleMultiSelectChange = (e) => {
    const { value } = e.target;
    const selectedValue = typeof value === "string" ? value.split(",") : value;
    const nonEmptyValues = selectedValue.filter((val) => val.trim() !== "");
    setRigNames(nonEmptyValues);
    // setRigNames(typeof value === "string" ? value.split(",") : value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(":::::::::::::::", name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      setFormData({
        ...formData,
        [name]: file,
      });
    }
  };

  const handleStopClose = (e) => {
    e.stopPropagation();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!formData.retainership){
      alert("Please enter Retainership Status");
      return;
    }  
    closePopup();

    let fromDate = new Date();
    let toDate = new Date();
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    let period =
      fromDate.toLocaleDateString("en-GB", options) +
      "-" +
      toDate.toLocaleDateString("en-GB", options);

    // let seamanExpiryDate = new Date(seamanBookExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let BOSIETExpiryDate = new Date(bosietExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let H2SExpiryDate = new Date(h2sExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let medicalExpiryDate = new Date(medicalExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let insuranceExpriyDate = new Date(insuranceExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // );

    // let SNTEyeTestExpriyDate = new Date(sntEyeTestExpiry).toLocaleDateString(
    //   "en-GB",
    //   options
    // // );
    const {
      name,
      designation,
      retainership,
      employeeid,
      jackExperience,
      skidExperience,
      craneExperience,
      datesBookedForProjectFrom,
      datesBookedForProjectTo,
      fixationExperience,
      seamanBookExpiry,
      seamanIssuingCountry,

      bosietExpiry,

      h2sExpiry,

      medicalExpiry,

      insuranceExpiry,

      sntEyeTestExpiry,
      yellowFeverExpiry,
      cvFile,
      passportExpiry,
      passportIssuingCountry,
      visaFields,
      pccExpiry,
    } = formData;

    const {
      signature,
      seamanDoc,
      bosietDoc,
      h2sDoc,
      medicalDoc,
      insuranceDoc,
      sntEyeTestDoc,
      yellowFeverDoc,
      passportDoc,
      visaDoc1,
      visaDoc2,
      visaDoc3,
      visaDoc4,
      visaDoc5,
      visaDoc6,
      photoUpload,
      vaccinationCertificate,
      pcc,
    } = selectedFile;

    const nameOfRigs = rigNames.toString();
    const payload = {
      Customer_Name: name,
      Designation: designation,
      // Retainership:designation === 'Trainee' ? 'Salaried' : retainership,
      Retainership:retainership,
      Employee_ID:employeeid,
      Signature: signature,
      Rigs: nameOfRigs,
      jackingExperience: jackExperience,
      skiddingExperience: skidExperience,
      craneExperience: craneExperience,
      // upcomingProjectDuration: period,
      Fixation_Experience: fixationExperience,
      Seaman_Book_Expiry: seamanBookExpiry,
      Seaman_Issuing_Country: seamanIssuingCountry,
      Seaman_Doc: seamanDoc,
      Bosiet_Expiry: bosietExpiry,
      Bosiet_Doc: bosietDoc,
      H2s_Expiry: h2sExpiry,
      H2s_Doc: h2sDoc,
      Medical_Expiry: medicalExpiry,
      Medical_Doc: medicalDoc,
      Insurance_Expiry: insuranceExpiry,
      Insurance_Doc: insuranceDoc,
      SNT_Eye_Test_Expiry: sntEyeTestExpiry,
      SNT_Eye_Test_Doc: sntEyeTestDoc,
      Yellow_Fever_Expiry:yellowFeverExpiry,
      Yellow_Fever_Doc: yellowFeverDoc,
      CV_File: cvFile,
      Passport_Expiry: passportExpiry,
      Passport_Issuing_Country: passportIssuingCountry,
      Passport_Doc: passportDoc,

      Active_Visas_One: formData.visaFields[0]?.visaIssuingCountry1,
      Active_Visas_Two: formData.visaFields[1]?.visaIssuingCountry2,
      Active_Visas_Three: formData.visaFields[2]?.visaIssuingCountry3,
      Active_Visas_Four: formData.visaFields[3]?.visaIssuingCountry4,
      Active_Visas_Five: formData.visaFields[4]?.visaIssuingCountry5,
      Active_Visas_Six: formData.visaFields[5]?.visaIssuingCountry6,
      Active_Visas_One_Expiry: formData.visaFields[0]?.visaExpiry1,
      Active_Visas_Two_Expiry: formData.visaFields[1]?.visaExpiry2,
      Active_Visas_Three_Expiry: formData.visaFields[2]?.visaExpiry3,
      Active_Visas_Four_Expiry: formData.visaFields[3]?.visaExpiry4,
      Active_Visas_Five_Expiry: formData.visaFields[4]?.visaExpiry5,
      Active_Visas_Six_Expiry: formData.visaFields[5]?.visaExpiry6,
      Active_Visas_One_Doc: visaDoc1,
      Active_Visas_Two_Doc: visaDoc2,
      Active_Visas_Three_Doc: visaDoc3,
      Active_Visas_Four_Doc: visaDoc4,
      Active_Visas_Five_Doc: visaDoc5,
      Active_Visas_Six_Doc: visaDoc6,

      Signature_Doc_Name: selectedFileName.signature,
      Seaman_Doc_Name: selectedFileName.seamanDoc,
      Bosiet_Doc_Name: selectedFileName.bosietDoc,
      H2S_Doc_Name: selectedFileName.h2sDoc,
      Medical_Doc_Name: selectedFileName.medicalDoc,
      Insurance_Doc_Name: selectedFileName.insuranceDoc,
      Snt_Doc_Name: selectedFileName.sntEyeTestDoc,
      Yellow_Fever_Doc_Name: selectedFileName.yellowFeverDoc,
      Passport_Doc_Name: selectedFileName.passportDoc,
      Active_Visa1_Doc_Name: selectedFileName.visaDoc1,
      Active_Visa2_Doc_Name: selectedFileName.visaDoc2,
      Active_Visa3_Doc_Name: selectedFileName.visaDoc3,
      Active_Visa4_Doc_Name: selectedFileName.visaDoc4,
      Active_Visa5_Doc_Name: selectedFileName.visaDoc5,
      Active_Visa6_Doc_Name: selectedFileName.visaDoc6,
      Photo_Upload: photoUpload,
      Photo_Upload_Doc_Name: selectedFileName.photoUpload,
      Vaccination_Certificate: vaccinationCertificate,
      Vaccination_Certificate_Doc_Name: selectedFileName.vaccinationCertificate,
      PCC: pcc,
      PCC_Doc_Name: selectedFileName.pcc,
      PCC_Expiry: pccExpiry,
    };
    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professional_detailsupdation/${S_No}`,
        payload
      );

      console.log(res.data);
      if (res.status === 200) {
        setChecked(!checked);
      }
    } catch (err) {
      console.log(err);
    }
    setRefresh(!refresh);
  };

  const handleFileUpload = async (file, fieldName) => {
    const formData = new FormData();

    formData.append("file", file);
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/uploadfile",
        formData
      );

      // console.log(res.data);
      const pathOfFile = res.data.fileUrl;
      const fileNameApi = res.data.filename;

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: fileNameApi,
      });

      setSelectedFile({
        ...selectedFile,
        [fieldName]: pathOfFile,
      });
    } catch (error) {
      console.error("Error uploading signature:", error);
    }
  };

  const removeFile = async (fieldName, e, index) => {
    e.preventDefault();

    setSelectedFile((prevSelectedFile) => ({
      ...prevSelectedFile,
      [fieldName]: null,
    }));

    clearFileInput(fieldName, index);
    await deleteUploadedVisaFile(fieldName);
  };

  const clearFileInput = (fieldName, index) => {
    const fileInput = document.querySelector(`input[name="${fieldName}"]`);
    if (fileInput) {
      fileInput.value = "";
    }

    const nextFieldName = `visaDoc${index + 2}`;

    setSelectedFileName({
      ...selectedFileName,
      [fieldName]: selectedFileName[nextFieldName] || "",
    });
    setSelectedFile({
      ...selectedFile,
      [fieldName]: selectedFile[nextFieldName] || "",
    });
  };

  const deleteUploadedVisaFile = async (fieldName) => {
    const fileApiName = selectedFileName[fieldName];
    console.log(fileApiName);
    try {
      const res = await axios.delete(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/deletefile/${fileApiName}`
      );

      console.log("deleted File    ", res);
    } catch (err) {
      console.log(err);
    }
    console.log("After delete   ", selectedFile);
    setRefresh(!refresh);
  };

  const deleteFile = async (fieldName) => {
    const fileApiName = selectedFileName[fieldName];
    console.log(fileApiName);
    try {
      // const res = await axios.delete(
      //   `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/remove_visa/${fileApiName}`
      // );

      // await deleteURLName(fieldName);

      // console.log("deleted File    ", res);

      setSelectedFileName({
        ...selectedFileName,
        [fieldName]: "",
      });
      setSelectedFile({
        ...selectedFile,
        [fieldName]: "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteURLName = async (fieldName) => {
    const {
      signature,
      seamanDoc,
      bosietDoc,
      h2sDoc,
      medicalDoc,
      insuranceDoc,
      sntEyeTestDoc,
      yellowFeverDoc,
      passportDoc,
      visaDoc1,
      visaDoc2,
      visaDoc3,
      visaDoc4,
      visaDoc5,
      visaDoc6,
      photoUpload,
      vaccinationCertificate,
      pcc,
    } = selectedFile;

    const payload = {
      Signature: fieldName === "signature" ? "" : signature,
      Seaman_Doc: fieldName === "seamanDoc" ? "" : seamanDoc,
      Bosiet_Doc: fieldName === "bosietDoc" ? "" : bosietDoc,
      H2s_Doc: fieldName === "h2sDoc" ? "" : h2sDoc,
      Medical_Doc: fieldName === "medicalDoc" ? "" : medicalDoc,
      Insurance_Doc: fieldName === "insuranceDoc" ? "" : insuranceDoc,
      SNT_Eye_Test_Doc: fieldName === "sntEyeTestDoc" ? "" : sntEyeTestDoc,
      Yellow_Fever_Doc: fieldName === "yellowFeverDoc" ? "" : yellowFeverDoc,
      Passport_Doc: fieldName === "passportDoc" ? "" : passportDoc,
      Active_Visas_One_Doc: fieldName === "visaDoc1" ? "" : visaDoc1,
      Active_Visas_Two_Doc: fieldName === "visaDoc2" ? "" : visaDoc2,
      Active_Visas_Three_Doc: fieldName === "visaDoc3" ? "" : visaDoc3,
      Active_Visas_Four_Doc: fieldName === "visaDoc4" ? "" : visaDoc4,
      Active_Visas_Five_Doc: fieldName === "visaDoc5" ? "" : visaDoc5,
      Active_Visas_Six_Doc: fieldName === "visaDoc6" ? "" : visaDoc6,
      Photo_Upload: fieldName === "photoUpload" ? "" : photoUpload,
      vaccination_Certificate:
        fieldName === "vaccinationCertificate" ? "" : vaccinationCertificate,
      PCC: fieldName === "pcc" ? "" : pcc,
      Signature_Doc_Name:
        fieldName === "signature" ? "" : selectedFileName.signature,
      Seaman_Doc_Name:
        fieldName === "seamanDoc" ? "" : selectedFileName.seamanDoc,
      Bosiet_Doc_Name:
        fieldName === "bosietDoc" ? "" : selectedFileName.bosietDoc,
      H2S_Doc_Name: fieldName === "h2sDoc" ? "" : selectedFileName.h2sDoc,
      Medical_Doc_Name:
        fieldName === "medicalDoc" ? "" : selectedFileName.medicalDoc,
      Insurance_Doc_Name:
        fieldName === "insuranceDoc" ? "" : selectedFileName.insuranceDoc,
      Snt_Doc_Name:
        fieldName === "sntEyeTestDoc" ? "" : selectedFileName.sntEyeTestDoc,
        Yellow_Fever_Doc_Name:
        fieldName === "yellowFeverDoc" ? "" : selectedFileName.yellowFeverDoc,
      Passport_Doc_Name:
        fieldName === "passportDoc" ? "" : selectedFileName.passportDoc,
      Active_Visa1_Doc_Name:
        fieldName === "visaDoc1" ? "" : selectedFileName.visaDoc1,
      Active_Visa2_Doc_Name:
        fieldName === "visaDoc2" ? "" : selectedFileName.visaDoc2,
      Active_Visa3_Doc_Name:
        fieldName === "visaDoc3" ? "" : selectedFileName.visaDoc3,
      Active_Visa4_Doc_Name:
        fieldName === "visaDoc4" ? "" : selectedFileName.visaDoc4,
      Active_Visa5_Doc_Name:
        fieldName === "visaDoc5" ? "" : selectedFileName.visaDoc5,
      Active_Visa6_Doc_Name:
        fieldName === "visaDoc6" ? "" : selectedFileName.visaDoc6,
      Photo_Upload_Doc_Name:
        fieldName === "photoUpload" ? "" : selectedFileName.photoUpload,
      vaccination_Certificate_Doc_Name:
        fieldName === "vaccinationCertificate"
          ? ""
          : selectedFileName.vaccinationCertificate,
      PCC_Doc_Name: fieldName === "pcc" ? "" : selectedFileName.pcc,
    };

    const res = await axios.patch(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professional_file_updation/${S_No}`,
      payload
    );

    console.log(res);
  };

  // ___________to uncheck radio button
  const closePopup = () => {
    setChecked(!checked);
    handleUncheckRadio();
    setEnableEdit(!enableEdit);
  };
  const handleRadioOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleUncheckRadio = () => {
    setSelectedOption("");
  };
  // __________________
  const [visaData, setvisaData] = useState({});
  const getVisaData = () => {
    const data = formData?.visaFields?.filter(
      (el, index) =>
        (el[`visaExpiry${index + 1}`] !== null &&
          el[`visaExpiry${index + 1}`] !== "" &&
          el[`visaExpiry${index + 1}`] !== undefined) ||
        (el[`visaIssuingCountry${index + 1}`] !== null &&
          el[`visaIssuingCountry${index + 1}`] !== "" &&
          el[`visaIssuingCountry${index + 1}`] !== undefined) ||
        (selectedFile[`visaDoc${index + 1}`] !== null &&
          selectedFile[`visaDoc${index + 1}`] !== "" &&
          selectedFile[`visaDoc${index + 1}`] !== undefined)
    );
    setvisaData(data);
  };

  useEffect(() => {
    getVisaData();
  }, [checked]);


  return (
    <>
      <div className={styles.editRadioButtonProfessional}>
        <input
          type="radio"
          name="rig"
          value="select"
          checked={selectedOption === "select"}
          onChange={handleRadioOptionChange}
          onClick={() => setChecked(!checked)}
        />
      </div>
      {checked && (
        <>
          <div className={styles.popupContainer} onClick={closePopup}>
            <div className={styles.popup} onClick={handleStopClose}>
              <form onSubmit={handleSubmit}>
                <div className={styles.popupItems}>
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>Designation:</label>
                  {/* <input
                    type="text"
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                  /> */}
                  <select
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}>
                    <option value="">Select Value</option>
                    <option value="Supervisor">Supervisor</option>
                    <option value="Technician">Technician</option>
                    <option value="Trainee">Trainee</option>
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.popupItems}>
                  <label>RetainerShip:</label>
                  <select
                    name="retainership"
                    // value={formData.designation === 'Trainee'? 'Salaried' : formData.retainership}
                    value={formData.retainership}
                    onChange={handleInputChange}
                    // disabled={formData.designation === 'Trainee'}
                    >
                    <option value="">Select Value</option>
                    {/* {formData.designation === 'Supervisor' || formData.designation === 'Technician' ? (
                      <>
                        <option value="Retainer">Retainer</option>
                    <option value="Non-retainer">Non-retainer</option>
                  </>
                    ):(
                      
                      <option value="Salaried">Salaried</option>
                    )} */}
                   <option value="Monthly-Fixed">Monthly-Fixed</option>
                   <option value="Daily-Retainer">Daily-Retainer</option>
                  <option value="Non-retainer">Non-retainer</option>
                    
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.popupItems}>
                  <label>Employee ID:</label>
                  <input
                    type="text"
                    name="employeeid"
                    value={formData.employeeid}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>Signature:</label>

                  {selectedFile.signature ? (
                    <div className={styles.uploadedFileContainer}>
                      <NavLink
                        className={styles.uploadedFileName}
                        to={selectedFile.signature}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                      <svg
                        onClick={() => deleteFile("signature")}
                        style={{ cursor: "pointer" }}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                          fill="black"
                        />
                        <path
                          d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  ) : (
                    <UploadDocument
                      fieldName={"signature"}
                      selectedFile={selectedFile.signature}
                      selectedFileName={selectedFileName.signature}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  )}
                </div>

                <div className={styles.popupItems}>
                  <label>Experience of Rig Types:</label>
                  <Select
                    multiple
                    sx={{
                      position: "relative",
                      backgroundColor: "white",
                      width: 210,
                      backgroundColor: "white",
                      borderRadius: "8px",
                      border: "1px solid #dadce0;",
                      height: "45px",
                      fontSize: "16px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    value={rigNames}
                    onChange={handleMultiSelectChange}
                    renderValue={(selected) => selected?.join(",")}
                    MenuProps={MenuProps}
                    IconComponent={() => null}>
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={rigNames.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Jacking Experience <br /> (in months)
                  </label>
                  <input
                    type="text"
                    name="jackExperience"
                    value={formData.jackExperience}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Skidding Experience <br /> (in months)
                  </label>
                  <input
                    type="text"
                    name="skidExperience"
                    value={formData.skidExperience}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Crane Experience <br /> (in months)
                  </label>
                  <input
                    type="text"
                    name="craneExperience"
                    value={formData.craneExperience}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Fixation Experience <br /> (in months)
                  </label>
                  <input
                    type="text"
                    name="fixationExperience"
                    value={formData.fixationExperience}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  className={styles.popupItems}
                  style={{ paddingBottom: "0" }}>
                  <label>
                    <div>
                      Active Visas <br /> of countries:
                    </div>

                    {/* <div>
                      <Tooltip
                        title="Country name must be added to legal details page to reflect in drop-down"
                        placement="top">
                        <InfoOutlinedIcon sx={{ fontSize: "20px" }} />
                      </Tooltip>
                    </div> */}
                  </label>

                  <div>
                    {formData?.visaFields?.map((el, index) => (
                      <div className={styles.nameFieldsspacing} key={index + 1}>
                      
                       
                            <div className={styles.flexSpacing}>
                              {formData.visaFields.length > 1 && (
                                <IconButton
                                  onClick={(e) => {
                                    removeVisaField(index);
                                    if (
                                      selectedFile[`visaDoc${index + 1}`] !==
                                      null
                                    ) {
                                      removeFile(
                                        `visaDoc${index + 1}`,
                                        e,
                                        index
                                      );
                                    }
                                  }}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M5.38681 15.8327C5.07701 15.8327 4.7672 15.7186 4.52262 15.4741C4.04975 15.0014 4.04975 14.219 4.52262 13.7463L13.7516 4.52054C14.2245 4.04784 15.0071 4.04784 15.48 4.52054C15.9529 4.99324 15.9529 5.77563 15.48 6.24833L6.25101 15.4741C6.02273 15.7186 5.69662 15.8327 5.38681 15.8327Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M14.6158 15.8327C14.306 15.8327 13.9962 15.7186 13.7516 15.4741L4.52262 6.24833C4.04975 5.77563 4.04975 4.99324 4.52262 4.52054C4.99548 4.04784 5.77815 4.04784 6.25101 4.52054L15.48 13.7463C15.9529 14.219 15.9529 15.0014 15.48 15.4741C15.2354 15.7186 14.9256 15.8327 14.6158 15.8327Z"
                                      fill="black"
                                    />
                                  </svg>
                                </IconButton>
                              )}
                              <h6>Visa {index + 1}</h6>
                            </div>
                            <div className={styles.spaceContainers}>
                              <div className={styles.flexSpacing}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}>
                                  <CustomDatePicker
                                    name={"visaExpiry" + (index + 1)}
                                    value={
                                      formData.visaFields[index][
                                        "visaExpiry" + (index + 1)
                                      ]
                                        ? dayjs(
                                            formData.visaFields[index][
                                              "visaExpiry" + (index + 1)
                                            ],
                                            "DD/MM/YYYY"
                                          )
                                        : null
                                    }
                                    onChange={(value) =>
                                      handleVisaDate(
                                        "visaExpiry" + (index + 1),
                                        value,
                                        index
                                      )
                                    }
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                      },
                                    }}
                                    sx={{
                                      width: "102px",
                                      backgroundColor: "white",
                                      borderRadius: "8px",
                                    }}
                                    format="DD/MM/YYYY"
                                    label="Expiry"
                                  />
                                </LocalizationProvider>

                                <select
                                  style={{ width: "102px" }}
                                  name={"visaIssuingCountry" + (index + 1)}
                                  value={
                                    formData.visaFields[index][
                                      "visaIssuingCountry" + (index + 1)
                                    ]
                                  }
                                  onChange={(e) =>
                                    handleVisaCountryChange(index, e)
                                  }>
                                  <option value="">Country</option>
                                  {dropdownVisa.map((elm) => (
                                    <option value={elm} key={elm}>
                                      {elm}
                                    </option>
                                  ))}
                                </select>
                                <svg
                                  className={styles.svgIconStyle}
                                  style={{ right: "15px" }}
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                    fill="black"
                                  />
                                </svg>
                              </div>

                              <div>
                                {selectedFile[`visaDoc${index + 1}`] ? (
                                  <div className={styles.uploadedFileContainer}>
                                    <NavLink
                                      className={styles.uploadedFileName}
                                      to={selectedFile[`visaDoc${index + 1}`]}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      View File
                                    </NavLink>
                                    <svg
                                      onClick={() =>
                                        deleteFile(`visaDoc${index + 1}`)
                                      }
                                      style={{ cursor: "pointer" }}
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                ) : (
                                  <UploadDocument
                                    fieldName={`visaDoc${index + 1}`}
                                    selectedFile={
                                      selectedFile[`visaDoc${index + 1}`]
                                    }
                                    selectedFileName={
                                      selectedFileName[`visaDoc${index + 1}`]
                                    }
                                    setSelectedFile={setSelectedFile}
                                    uploadFile={handleFileUpload}
                                  />
                                )}
                              </div>
                            </div>
                       
                        
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.addMoreContainer}>
                  {/* <Button
                    onClick={addVisas}
                    className={styles.addMoreButton}
                    variant="contained">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.493 1.66602H6.50964C3.4763 1.66602 1.66797 3.47435 1.66797 6.50768V13.4827C1.66797 16.5243 3.4763 18.3327 6.50964 18.3327H13.4846C16.518 18.3327 18.3263 16.5243 18.3263 13.491V6.50768C18.3346 3.47435 16.5263 1.66602 13.493 1.66602ZM15.0013 10.6243H10.6263V14.9993C10.6263 15.341 10.343 15.6243 10.0013 15.6243C9.65964 15.6243 9.3763 15.341 9.3763 14.9993V10.6243H5.0013C4.65964 10.6243 4.3763 10.341 4.3763 9.99935C4.3763 9.65768 4.65964 9.37435 5.0013 9.37435H9.3763V4.99935C9.3763 4.65768 9.65964 4.37435 10.0013 4.37435C10.343 4.37435 10.6263 4.65768 10.6263 4.99935V9.37435H15.0013C15.343 9.37435 15.6263 9.65768 15.6263 9.99935C15.6263 10.341 15.343 10.6243 15.0013 10.6243Z"
                        fill="white"
                      />
                    </svg>
                    A
                  </Button> */}
                </div>

                {/* <label style={{ marginLeft: "-11.5rem" }}>
                  Dates Booked For Project:-
                </label>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <div className={styles.popupItems}>
                    <label>From:</label>
                    <input
                      type="date"
                      name="datesBookedForProjectFrom"
                      value={formData.datesBookedForProjectFrom}
                      onChange={handleInputChange}
                      style={{ width: "150px" }}
                    />
                  </div>

                  <div className={styles.popupItems}>
                    <label>To:</label>
                    <input
                      type="date"
                      name="datesBookedForProjectTo"
                      value={formData.datesBookedForProjectTo}
                      onChange={handleInputChange}
                      style={{ width: "150px" }}
                    />
                  </div>
                </div> */}

                <div className={styles.popupItems}>
                  <label>
                    Seaman Book <br /> Expiry | Issuing Country | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDatePicker
                          name="seamanBookExpiry"
                          value={
                            formData.seamanBookExpiry
                              ? dayjs(formData.seamanBookExpiry, "DD/MM/YYYY")
                              : null
                          }
                          onChange={(value) =>
                            dateChanges("seamanBookExpiry", value)
                          }
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                          sx={{
                            width: "102px",
                            backgroundColor: "white",
                            borderRadius: "8px",
                          }}
                          format="DD/MM/YYYY"
                          label="Expiry"
                        />
                      </LocalizationProvider>
                      <select
                        style={{ width: "102px" }}
                        name="seamanIssuingCountry"
                        value={formData.seamanIssuingCountry}
                        onChange={handleInputChange}>
                        <option value="">Country</option>
                        <option value="Belize">Belize</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Indian">Indian</option>
                        <option value="Curacao">Curacao</option>
                        <option value="Palau">Palau</option>
                        <option value="Bahamas">Bahamas </option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div>
                      {selectedFile.seamanDoc ? (
                        <div className={styles.uploadedFileContainer}>
                          <NavLink
                            className={styles.uploadedFileName}
                            to={selectedFile.seamanDoc}
                            target="_blank"
                            rel="noopener noreferrer">
                            View File
                          </NavLink>
                          <svg
                            onClick={() => deleteFile("seamanDoc")}
                            style={{ cursor: "pointer" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      ) : (
                        <UploadDocument
                          fieldName={"seamanDoc"}
                          selectedFile={selectedFile.seamanDoc}
                          selectedFileName={selectedFileName.seamanDoc}
                          setSelectedFile={setSelectedFile}
                          uploadFile={handleFileUpload}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    BOSIET <br /> Expiry | Doc:
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      {selectedFile.bosietDoc ? (
                        <div className={styles.uploadedFileLessWidth}>
                          <NavLink
                            className={styles.uploadedFileName}
                            to={selectedFile.bosietDoc}
                            target="_blank"
                            rel="noopener noreferrer">
                            View File
                          </NavLink>
                          <svg
                            onClick={() => deleteFile("bosietDoc")}
                            style={{ cursor: "pointer" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      ) : (
                        <UploadDocument
                          fieldName={"bosietDoc"}
                          selectedFile={selectedFile.bosietDoc}
                          selectedFileName={selectedFileName.bosietDoc}
                          setSelectedFile={setSelectedFile}
                          uploadFile={handleFileUpload}
                        />
                      )}
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="bosietExpiry"
                        value={
                          formData.bosietExpiry
                            ? dayjs(formData.bosietExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => dateChanges("bosietExpiry", value)}
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    H2S <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      {selectedFile.h2sDoc ? (
                        <div className={styles.uploadedFileLessWidth}>
                          <NavLink
                            className={styles.uploadedFileName}
                            to={selectedFile.h2sDoc}
                            target="_blank"
                            rel="noopener noreferrer">
                            View File
                          </NavLink>
                          <svg
                            onClick={() => deleteFile("h2sDoc")}
                            style={{ cursor: "pointer" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      ) : (
                        <UploadDocument
                          fieldName={"h2sDoc"}
                          selectedFile={selectedFile.h2sDoc}
                          selectedFileName={selectedFileName.h2sDoc}
                          setSelectedFile={setSelectedFile}
                          uploadFile={handleFileUpload}
                        />
                      )}
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="h2sExpiry"
                        value={
                          formData.h2sExpiry
                            ? dayjs(formData.h2sExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => dateChanges("h2sExpiry", value)}
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Medical <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      {selectedFile.medicalDoc ? (
                        <div className={styles.uploadedFileLessWidth}>
                          <NavLink
                            className={styles.uploadedFileName}
                            to={selectedFile.medicalDoc}
                            target="_blank"
                            rel="noopener noreferrer">
                            View File
                          </NavLink>
                          <svg
                            onClick={() => deleteFile("medicalDoc")}
                            style={{ cursor: "pointer" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      ) : (
                        <UploadDocument
                          fieldName={"medicalDoc"}
                          selectedFile={selectedFile.medicalDoc}
                          selectedFileName={selectedFileName.medicalDoc}
                          setSelectedFile={setSelectedFile}
                          uploadFile={handleFileUpload}
                        />
                      )}
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="medicalExpiry"
                        value={
                          formData.medicalExpiry
                            ? dayjs(formData.medicalExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          dateChanges("medicalExpiry", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Yellow Fever<br /> Expiry | Doc:
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      {selectedFile.yellowFeverDoc ? (
                        <div className={styles.uploadedFileLessWidth}>
                          <NavLink
                            className={styles.uploadedFileName}
                            to={selectedFile.yellowFeverDoc}
                            target="_blank"
                            rel="noopener noreferrer">
                            View File
                          </NavLink>
                          <svg
                            onClick={() => deleteFile("yellowFeverDoc")}
                            style={{ cursor: "pointer" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      ) : (
                        <UploadDocument
                          fieldName={"yellowFeverDoc"}
                          selectedFile={selectedFile.yellowFeverDoc}
                          selectedFileName={selectedFileName.yellowFeverDoc}
                          setSelectedFile={setSelectedFile}
                          uploadFile={handleFileUpload}
                        />
                      )}
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="yellowFeverExpiry"
                        value={
                          formData.yellowFeverExpiry
                            ? dayjs(formData.yellowFeverExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) => dateChanges("yellowFeverExpiry", value)}
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Insurance Insurance <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      {selectedFile.insuranceDoc ? (
                        <div className={styles.uploadedFileLessWidth}>
                          <NavLink
                            className={styles.uploadedFileName}
                            to={selectedFile.insuranceDoc}
                            target="_blank"
                            rel="noopener noreferrer">
                            View File
                          </NavLink>
                          <svg
                            onClick={() => deleteFile("insuranceDoc")}
                            style={{ cursor: "pointer" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      ) : (
                        <UploadDocument
                          fieldName={"insuranceDoc"}
                          selectedFile={selectedFile.insuranceDoc}
                          selectedFileName={selectedFileName.insuranceDoc}
                          setSelectedFile={setSelectedFile}
                          uploadFile={handleFileUpload}
                        />
                      )}
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="insuranceExpiry"
                        value={
                          formData.insuranceExpiry
                            ? dayjs(formData.insuranceExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          dateChanges("insuranceExpiry", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    SNT Eye Test <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                      {selectedFile.sntEyeTestDoc ? (
                        <div className={styles.uploadedFileLessWidth}>
                          <NavLink
                            className={styles.uploadedFileName}
                            to={selectedFile.sntEyeTestDoc}
                            target="_blank"
                            rel="noopener noreferrer">
                            View File
                          </NavLink>
                          <svg
                            onClick={() => deleteFile("sntEyeTestDoc")}
                            style={{ cursor: "pointer" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      ) : (
                        <UploadDocument
                          fieldName={"sntEyeTestDoc"}
                          selectedFile={selectedFile.sntEyeTestDoc}
                          selectedFileName={selectedFileName.sntEyeTestDoc}
                          setSelectedFile={setSelectedFile}
                          uploadFile={handleFileUpload}
                        />
                      )}
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="sntEyeTestExpiry"
                        value={
                          formData.sntEyeTestExpiry
                            ? dayjs(formData.sntEyeTestExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          dateChanges("sntEyeTestExpiry", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className={styles.popupItems}>
                  <label>
                    Passport <br /> Expiry | Issuing Country | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDatePicker
                          name="passportExpiry"
                          value={
                            formData.passportExpiry
                              ? dayjs(formData.passportExpiry, "DD/MM/YYYY")
                              : null
                          }
                          onChange={(value) =>
                            dateChanges("passportExpiry", value)
                          }
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                          sx={{
                            width: "102px",
                            backgroundColor: "white",
                            borderRadius: "8px",
                          }}
                          format="DD/MM/YYYY"
                          label="Expiry"
                        />
                      </LocalizationProvider>
                      <select
                        style={{ width: "102px" }}
                        name="passportIssuingCountry"
                        value={formData.passportIssuingCountry}
                        onChange={handleInputChange}>
                        <option value="">Country</option>
                        <option value="South Africa">South Africa</option>
                        <option value="India">India</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Singapore">Singapore</option>
                        <option value="UK">UK</option>
                        <option value="Croatia">Croatia</option>
                      </select>
                      <svg
                        style={{ position: "absolute", right: "15" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div>
                      {selectedFile.passportDoc ? (
                        <div className={styles.uploadedFileContainer}>
                          <NavLink
                            className={styles.uploadedFileName}
                            to={selectedFile.passportDoc}
                            target="_blank"
                            rel="noopener noreferrer">
                            View File
                          </NavLink>
                          <svg
                            onClick={() => deleteFile("passportDoc")}
                            style={{ cursor: "pointer" }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                              fill="black"
                            />
                            <path
                              d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      ) : (
                        <UploadDocument
                          fieldName={"passportDoc"}
                          selectedFile={selectedFile.passportDoc}
                          selectedFileName={selectedFileName.passportDoc}
                          setSelectedFile={setSelectedFile}
                          uploadFile={handleFileUpload}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.popupItems}>
                  <label>Photo Upload:</label>

                  {selectedFile.photoUpload ? (
                    <div className={styles.uploadedFileContainer}>
                      <NavLink
                        className={styles.uploadedFileName}
                        to={selectedFile.photoUpload}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                      <svg
                        onClick={() => deleteFile("photoUpload")}
                        style={{ cursor: "pointer" }}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                          fill="black"
                        />
                        <path
                          d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  ) : (
                    <UploadDocument
                      fieldName={"photoUpload"}
                      selectedFile={selectedFile.photoUpload}
                      selectedFileName={selectedFileName.photoUpload}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  )}
                </div>
                <div className={styles.popupItems}>
                  <label> Vaccination Certificate:</label>

                  {selectedFile.vaccinationCertificate ? (
                    <div className={styles.uploadedFileContainer}>
                      <NavLink
                        className={styles.uploadedFileName}
                        to={selectedFile.vaccinationCertificate}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                      <svg
                        onClick={() => deleteFile("vaccinationCertificate")}
                        style={{ cursor: "pointer" }}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                          fill="black"
                        />
                        <path
                          d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  ) : (
                    <UploadDocument
                      fieldName={"vaccinationCertificate"}
                      selectedFile={selectedFile.vaccinationCertificate}
                      selectedFileName={selectedFileName.vaccinationCertificate}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  )}
                </div>
                <div className={styles.popupItems}>
                  <label> 
                    PCC <br /> Expiry | Doc
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <div className={styles.lessWidth}>
                  {selectedFile.pcc ? (
                        <div className={styles.uploadedFileLessWidth}>
                      <NavLink
                        className={styles.uploadedFileName}
                        to={selectedFile.pcc}
                        target="_blank"
                        rel="noopener noreferrer">
                        View File
                      </NavLink>
                      <svg
                        onClick={() => deleteFile("pcc")}
                        style={{ cursor: "pointer" }}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.38565 15.8334C5.07585 15.8334 4.76604 15.7193 4.52146 15.4748C4.04859 15.0021 4.04859 14.2197 4.52146 13.747L13.7504 4.52121C14.2233 4.04851 15.006 4.04851 15.4788 4.52121C15.9517 4.99391 15.9517 5.7763 15.4788 6.249L6.24985 15.4748C6.02157 15.7193 5.69546 15.8334 5.38565 15.8334Z"
                          fill="black"
                        />
                        <path
                          d="M14.6146 15.8334C14.3048 15.8334 13.995 15.7193 13.7504 15.4748L4.52146 6.249C4.04859 5.7763 4.04859 4.99391 4.52146 4.52121C4.99432 4.04851 5.77699 4.04851 6.24985 4.52121L15.4788 13.747C15.9517 14.2197 15.9517 15.0021 15.4788 15.4748C15.2342 15.7193 14.9244 15.8334 14.6146 15.8334Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  ) : (
                    <UploadDocument
                      fieldName={"pcc"}
                      selectedFile={selectedFile.pcc}
                      selectedFileName={selectedFileName.pcc}
                      setSelectedFile={setSelectedFile}
                      uploadFile={handleFileUpload}
                    />
                  )}
                </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDatePicker
                        name="pccExpiry"
                        value={
                          formData.pccExpiry
                            ? dayjs(formData.pccExpiry, "DD/MM/YYYY")
                            : null
                        }
                        onChange={(value) =>
                          dateChanges("pccExpiry", value)
                        }
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          width: "102px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                        format="DD/MM/YYYY"
                        label="Expiry"
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                {/* <div className={styles.popupItems}>
                  <label>SNT Eye Test Doc:</label>
                  <input
                    type="file"
                    name="sntEyeTestDoc"
                    value={formData.sntEyeTestDoc}
                    onChange={handleInputChange}
                  />
                </div> */}
                {/* ------------------------------------------------------------change state variable used here ---------------------------------------------- */}

                {/* <div className={styles.popupItems}>
                  <label>CV:</label>
                  <input
                    type="file"
                    name="cvFile"
                    onChange={handleFileChange}
                  />
                </div> */}
                {/* ------------------------------------------------------------change state variable used here ---------------------------------------------- */}

                <br />
                <div className={styles.popUpButtons}>
                  <Button
                    className={styles.SubmitButton}
                    size="large"
                    variant="contained"
                    type="submit">
                    Submit
                  </Button>
                  <Button
                    size="large"
                    className={styles.CancelButton}
                    variant="outlined"
                    onClick={closePopup}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProfessionalDetailsEdit;
