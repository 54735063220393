import React from 'react'
import styles from './css/templates.module.css'
import Workplan from './Workplan'
// const token = localStorage.getItem("token");
// axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
// axios.defaults.headers.common['Content-Type'] = 'application/json';
function Templates() {
  return (
    <div>
      <div className={styles.templateheading}><b>Templates</b></div>
      <div className={styles.maintemp}>
        <div className={styles.file1}>
          <div className={styles.docname1}>1.Work plan</div>
          <button className={styles.workplanbox}>
            {/* <button onClick={() => handleDetailsClick(rig)}>
                  View Details
                </button> */}
            <a class="btn" href="Workplan" target="newtab" title="View details"> View<img src="https://seekicon.com/free-icon-download/box-arrow-up-right_1.svg" height="15" width="49" /> </a>
          </button>
        </div>
        <div className={styles.file2}>
       <div className={styles.docname1}>2.DOS Plan</div>
       <button className={styles.docplanbox}>
            {/* <button onClick={() => handleDetailsClick(rig)}>
                  View Details
                </button> */}
            <a class="btn" href="dosplan" target="newtab" title="View details"> View<img src="https://seekicon.com/free-icon-download/box-arrow-up-right_1.svg" height="15" width="49" /> </a>
          </button>
       </div>
       <div className={styles.file3}>
       <div className={styles.docname3}>3.Equipment Initial Validation Form 1 </div>
       <button className={styles.eqplanbox}>
            {/* <button onClick={() => handleDetailsClick(rig)}>
                  View Details
                </button> */}
            <a class="btn" href="Equipmentform1" target="newtab" title="View details"> View<img src="https://seekicon.com/free-icon-download/box-arrow-up-right_1.svg" height="15" width="49" /> </a>
          </button>
       </div>
       <div className={styles.file3}>
       <div className={styles.docname3}>4.Equipment Initial Validation Form 2</div>
       <button className={styles.eqplanbox}>
            {/* <button onClick={() => handleDetailsClick(rig)}>
                  View Details
                </button> */}
            <a class="btn" href="Equipmentform2" target="newtab" title="View details"> View<img src="https://seekicon.com/free-icon-download/box-arrow-up-right_1.svg" height="15" width="49" /> </a>
          </button>
       </div>
       <div className={styles.file3}>
       <div className={styles.docname3}>5.Preservation Form 2</div>
       <button className={styles.prplanbox}>
            {/* <button onClick={() => handleDetailsClick(rig)}>
                  View Details
                </button> */}
            <a class="btn" href="Preservationform" target="newtab" title="View details"> View<img src="https://seekicon.com/free-icon-download/box-arrow-up-right_1.svg" height="15" width="49" /> </a>
          </button>
       </div>
       <div className={styles.file3}>
       <div className={styles.docname3}>6.COC Form </div>
       <button className={styles.cocplanbox}>
            {/* <button onClick={() => handleDetailsClick(rig)}>
                  View Details
                </button> */}
            <a class="btn" href="Cocform" target="newtab" title="View details"> View<img src="https://seekicon.com/free-icon-download/box-arrow-up-right_1.svg" height="15" width="49" /> </a>
          </button>
       </div>

      </div>

    </div>
  )
}

export default Templates;