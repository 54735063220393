// import React, { useState, useEffect } from 'react';
// import { useNavigate } from "react-router-dom";
// import axios from 'axios';
// import './css/loginpage.css'
// import ergonlogo from '../Components/ergonlogo.png'
// const LoginPage = () => {
//   const [useremail, setUseremail] = useState('shm@ergontec.com');
//   const [password, setPassword] = useState('ergon@123');
//   const [errorMessage, setErrorMessage] = useState('');
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();
//   const handleEmailChange = (event) => {
//     setUseremail(event.target.value);
//   };

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//   };

//   useEffect(() => {
//     setIsLoggedIn(sessionStorage.getItem("isLoggedIn"));
//     isLoggedIn && navigate("/dashboard");

//   }, [isLoggedIn]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       if (useremail.length > 0 && password.length > 0) {
//         const response = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/loginpage', { Useremail: useremail, Password: password });
//         if (response.data) {
//           sessionStorage.setItem("isLoggedIn", true);
//           navigate("/dashboard");
//         }
//         setErrorMessage('');
//       }
//     } catch (error) {
//       console.log(error.response.data.message);
//       setErrorMessage(error.response.data.message);
//     }
//   };

//   return (
//     <div className='loginWrapper'>
//       <form onSubmit={handleSubmit}>
//         <div className="loginForms">
//           <div className='logoWrapper'><img src={ergonlogo} className="ergonlogo"/></div>
//           <div className='userEmailWrapper'>
//             <label>UserEmail:</label>
//               <input type="email" value={useremail} onChange={handleEmailChange} />
//           </div>
//           <div className='userEmailWrapper'>
//             <label>Password:</label>
//               <input type="password" value={password} onChange={handlePasswordChange}/>
//           </div>
//           <button className="loginButton" type="submit">Login</button>
//           {errorMessage && <p>{errorMessage}</p>}
//           <div className='loginDescription'>
//             <div>If you are having any issues with login, Contact:</div>
//             <div>Shubham Mehta at: shm@ergontec.com or +91-95413-77346</div>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default LoginPage;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/loginpage.module.css";
// import './css/loginpage.css';
import ergonlogo from "../Components/ergonlogo.png";
import { Button } from "@mui/material";

const LoginPage = () => {
  const [useremail, setUseremail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setUseremail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };


  useEffect(() => {
    const checkLoginStatus = async () => {
      const status = localStorage.getItem("isLoggedIn");
      const token = localStorage.getItem("token");
      if (status && token) {
        setIsLoggedIn(true);
        navigate("/projects");
      }
    };
    checkLoginStatus();
  }, [navigate]);

  function setCookie(name, value, hoursToExpire) {
    var expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + hoursToExpire * 60 * 60 * 1000); // Convert hours to milliseconds
  
    var cookieString =
      encodeURIComponent(name) +
      "=" +
      encodeURIComponent(value) +
      "; expires=" +
      expirationDate.toUTCString() +
      "; path=/";
  
    document.cookie = cookieString;
  }
  

  useEffect(() => {
    setCookie("isLoggedIn3", false, 2);
  },[])

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (useremail.length > 0 && password.length > 0) {
        const response = await axios.post(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/loginpage",
          {
            Useremail: useremail,
            Password: password,
          }
        );

        if (response.data) {
          const roles = response.data.results.map((item) => item.role_name);

          localStorage.setItem("isLoggedIn", true);
          setCookie("isLoggedIn3", true);
          localStorage.setItem("Group", response.data.results[0]?.Group);
          setCookie("Group2", response.data.results[0]?.Group);
          localStorage.setItem("Roles", JSON.stringify(roles));
          localStorage.setItem("useremail", useremail);
          localStorage.setItem("token", response.data.token);
          setCookie("useremail2", useremail);
          setIsLoggedIn(true);
          window.location.reload(); // Refresh the page
        }
        setErrorMessage("");
      }
    } catch (error) {
      console.log(error.response.data.message);
      setErrorMessage("Username/Password is incorrect");
      }
  };

  return (
    <>
      <div className={styles.backgroundImageContainer}></div>
      <div className={styles.loginWrapper}>
        <form onSubmit={handleSubmit}>
          <div className={styles.loginForms}>
            <div className={styles.logoWrapper}>
              <img
                src={ergonlogo}
                className={styles.ergonlogologinpage}
                alt="Ergon Logo"
              />
            </div>

            <div className={styles.inputWrapper}>
              <div className={styles.userEmailWrapper}>
                <label>User Email</label>
                <input
                  type="email"
                  value={useremail}
                  onChange={handleEmailChange}
                />
              </div>
              <div className={styles.userEmailWrapper}>
                <label>Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
            </div>
            {errorMessage && <p className={styles.red}>{errorMessage}</p>}
            <Button
              variant="contained"
              className={styles.loginButton}
              type="submit">
              Login
            </Button>
            <div className={styles.loginDescription}>
              <div>If you are having any issues with login, Contact:</div>
              <div>
                Shubham Mehta at:
                <span> shm@ergontec.com </span> or
                <span> +91-95413-77346</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginPage;
