import React from "react";
import { Link } from "react-router-dom";
import styles from "./css/CustomerPropertyLogs.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from 'xlsx';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function CustomerPropertyLogs() {
  const [searchQuery, setSearchQuery] = useState("");
  const { id } = useParams();
  const { part_number } = useParams();
  const navigate = useNavigate();
  const [itemsReceived, setItemsReceived] = useState([]);
  const [itemsProduced, setItemsProduced] = useState([]);
  const [itemdata, setitemdata] = useState([]); 
  const [consumedSum, setConsumedSum] = useState([]);
  const [producedSum, setProducedSum] = useState([]);

  const [partNumberToItemIdMap, setPartNumberToItemIdMap] = useState({});
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  useEffect(() => {
    async function fetchDetails() {
      try {
        const [res1, res2, resi] = await Promise.all([
          axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get2`),
          axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/csp_produced_items_get`),
          axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster`),
        ]);

        const itemsReceivedData = res1.data.data;
        const itemsProducedData = res2.data.data;
        const itemData = resi.data;

        // Create a mapping between (Item_Name + Part_Number) and item IDs
        const partNumberToItemIdMap = {};
        itemData.forEach((item) => {
          const key = `${item.Item_Name}_/${item.Part_Number}`;
          partNumberToItemIdMap[key] = item.Item_Id;
        });

        // Set the state for items received and the part number to item ID map
        setItemsReceived(itemsReceivedData);
        setItemsProduced(itemsProducedData);
        setPartNumberToItemIdMap(partNumberToItemIdMap);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, []);
  console.log("setPartNumberToItemIdMap", partNumberToItemIdMap);


  const groupedItems = itemsReceived.reduce((acc, item) => {
    // Convert quantity to number, consider it as 0 if it's null or an empty string
    const quantityReceived = item.Quantity_Received ? parseInt(item.Quantity_Received) : 0;
    const key = `${item.Item_Name}-${item.Part_Number}`; // Create composite key
  
    if (!acc[key]) {
      acc[key] = { ...item, Quantity_Total: quantityReceived };
    } else {
      acc[key].Quantity_Total += quantityReceived;
    }
    
    return acc;
  }, {});

  // Add items from itemsProduced that are not in groupedItems
  itemsProduced.forEach(item => {
    const key = `${item.Item_Name}-${item.Part_Number}`; // Create composite key

    if (!groupedItems[key]) {
      groupedItems[key] = { ...item, Quantity_Total: 0 };
    }
  });

  // Convert the grouped items object back to an array if needed
  const groupedItemsArray = Object.values(groupedItems);

  console.log("groupedItemsArray",groupedItemsArray);

  // Filtering logic
  const filteredItems = Object.values(groupedItems).filter((item) => {
    const queryParts = searchQuery.trim().toLowerCase().split(' ');
    const itemId = partNumberToItemIdMap[`${item.Item_Name}_/${item.Part_Number}`];
    return queryParts.every(part =>
      item.Item_Name?.toLowerCase().includes(part) ||
      item.Part_Number?.toLowerCase().includes(part) ||
      (itemId && itemId.toString().toLowerCase().includes(part))
    );
  });

  console.log("filtered_items", filteredItems);
  useEffect(() => {
    const finalsum = async () => {
      try {
        const res1 = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/Material_Consumed_Sum');

        // Transform the data to create the composite key using forEach
        const transformedData = {};
        res1.data.data.forEach(item => {
          const compositeKey = `${item.Item_Name}_/${item.Part_Number}`;
          transformedData[compositeKey] = item.Qty_Consumed;
        });

        setConsumedSum(transformedData);
      } catch (error) {
        console.error(error);
      }
    }

    finalsum();
  }, []);

  console.log("setConsumedSum", consumedSum);

  useEffect(() => {
    const finalsum = async () => {
      try {
        const res1 = await axios.get('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/Material_Produced_Sum');

        // Transform the data to create the composite key using forEach
        const transformedData = {};
        res1.data.data.forEach(item => {
          const compositeKey = `${item.Item_Name}_/${item.Part_Number}`;
          transformedData[compositeKey] = item.Qty_Produced;
        });

        setProducedSum(transformedData);
      } catch (error) {
        console.error(error);
      }
    }

    finalsum();
  }, []);

  const handleDownloadExcel = () => {
    // Define the header row (with bold styling)
    const header = [
      { v: 'S. No.', s: { bold: true } },
      { v: 'Item Name', s: { bold: true } },
      { v: 'Part Number', s: { bold: true } },
      { v: 'Available Quantity', s: { bold: true } },
      { v: 'UOM', s: { bold: true } },
      { v: 'Item ID', s: { bold: true } },
    ];
  
    // Build the data rows from filteredItems
    const data = filteredItems.map((item, index) => {
      const key = `${item.Item_Name}_/${item.Part_Number}`;
      const itemId = partNumberToItemIdMap[key];
      // Calculate Available Quantity
      const availableQuantity =
        (item.Quantity_Total || 0) -
        (consumedSum[key] || 0) +
        (producedSum[key] || 0);
  
      return [
        index + 1,                              // S. No.
        item.Item_Name,                         // Item Name
        item.Part_Number,                       // Part Number
        availableQuantity,                      // Available Quantity
        item.UOM ? item.UOM : item.UoM || '-',  // UOM fallback
        itemId,                                 // Item ID
      ];
    });
  
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    // Convert our data (header + rows) into a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);
  
    // Optionally, set column widths
    const columnWidths = [
      { wch: 6 },   // S. No.
      { wch: 40 },  // Item Name
      { wch: 20 },  // Part Number
      { wch: 20 },  // Available Quantity
      { wch: 10 },  // UOM
      { wch: 20 },  // Item ID
    ];
    worksheet['!cols'] = columnWidths;
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Items');
  
    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'Customer_Property_Log.xlsx');
  };
  
  return (
    <div className={styles.topdiv}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.headingdiv}>
          <h1 className={styles.heading}> Customer Supplied Property Logs </h1>
        </div>
        <div className={styles.downloadButton}>
          <svg
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={handleDownloadExcel}
          >
            <path
              d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
              fill="#5B6BE1"
            />
          </svg>
        </div>
      </div>
      <div className={styles.sections}>
        <div>
          <div className={styles.searchContainer}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Search Item Name, Part Number, Item ID..."
              value={searchQuery}
              onChange={handleSearchChange}
            />

            <div className={styles.buttonContainer}>
              <button className={styles.searchButton} disabled>
                <svg
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  style={{ paddingBottom: "2px" }}
                  viewBox="0 0 22 22"
                  id="search"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M15.5 14h-.79l-.28-.27c1.2-1.4 1.82-3.31 1.48-5.34-.47-2.78-2.79-5-5.59-5.34-4.23-.52-7.79 3.04-7.27 7.27.34 2.8 2.56 5.12 5.34 5.59 2.03.34 3.94-.28 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* ////table//// */}

        <div className={styles.itemstablecontainer}>
          <table className={styles.itemstable}>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Item Name</th>
                <th>Part Number</th>
                <th>Available Quantity</th>
                <th>UOM</th>
                <th>Item Id</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.length === 0 && (
                <tr>
                  <td colSpan="6">
                    <div className={styles.noResults}>No Results Found</div>
                  </td>
                </tr>
              )}
              {filteredItems.map((item, index) => {
                const key = `${item.Item_Name}_/${item.Part_Number}`;
                const itemId = partNumberToItemIdMap[key];
                console.log('Rendering:', item.Item_Name, item.Part_Number, key, itemId);
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{maxWidth: "44vw"}}>
                      <Link
                        to={{
                          pathname: `/customerlogsdetails/${encodeURIComponent(item.Item_Name)}/${encodeURIComponent(itemId)}/${item.Part_Number ? encodeURIComponent(item.Part_Number) : null
                            }`,
                        }}
                      >
                        {item.Item_Name}
                      </Link>
                    </td>
                    <td>{item.Part_Number}</td>
                    <td>{item.Quantity_Total - (consumedSum[key] ? consumedSum[key] : 0) + (producedSum[key] ? producedSum[key] : 0)}</td>
                    <td>{item.UOM ? item.UOM : item.UoM ? item.UoM : "-"}</td>
                    <td>{itemId}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}


export default CustomerPropertyLogs;
