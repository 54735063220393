import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/spares_inquiry.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/system";
import { Button, Select, MenuItem } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import dayjs from "dayjs";
import TabsBarSparesInquiry from './TabsBarSparesInquiry';

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
        backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
        width: "230px",
        "&:hover": {
            "& fieldset": {
                borderColor: "1px solid #DADCE0",
            },
        },
        "&:not(.Mui-focused)": {
            "& fieldset": {
                border: "1px solid #DADCE0",
                borderRadius: "8px",
            },
        },
        "&.Mui-focused": {
            "& fieldset": {
                border: "1px solid #DADCE0",
                borderRadius: "8px",
            },
        },
    },
    "& input.Mui-disabled": {
        opacity: 1,
        "-webkit-text-fill-color": "black", // For WebKit-based browsers
        color: "black", // Fallback for other browsers
    },
}));


const SparesTradingInquiryDetails = () => {

    const [editMode, setEditMode] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [formData, setFormData] = useState({
        Customer_Inquiry_No: "",
        MOC: "",
        Inquiry_Date: "",
        Expiry_Inquiry_Date: "",
        Buyer_POC: "",
        Rig_Name: "",
        Customer_Name: "",
        Category: "",
        Description: "",
        Upload_Inquiry_Received: "",
        Suppliers_Contacted: "",
        Current_Status: "",
        Quote_No: "",
        Quote_sent_on: "",
        PO_No: "",
        RFQ: ""
    });

    const [suppliersContacted, setSuppliersContacted] = useState("");
    console.log("suppliersContacted", suppliersContacted);
    const { id } = useParams();
    const [originalFormData, setOriginalFormData] = useState({});

    const fileNameFromLink = (link) => {
        if (typeof link === 'string' && link) {
            return link.split("/").pop().split("-").slice(1).join("-");
        }
        // Return an empty string if link is not valid
        return "";
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value ? value.format("YYYY-MM-DD") : null,
        });
    };

    const handleEdit = () => {
        setEditMode(true);
    };
    const fetchData = async () => {
        try {
            const response = await axios.get(
                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_InquiryDetails?ID_No=${id}`
            );

            const suppliersRes = await axios.get(
                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_distinct_suppliers/${id}`
            );

            const supplierNames = suppliersRes.data.suppliers.map((supplier) => supplier.Supplier_Name).join(", ");
            setSuppliersContacted(supplierNames);


            // const res = await axios.get(
            //     `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/distinct-suppliers/${id}`
            // );
 
            if (response.data.success) {
                // Fix the backslashes in the file path
                // console.log("res",res);
                const data = response.data.data[0];
                

                // const dat = res.
                // Fix the backslashes in the file path
                // const filePath = data.Inquiry_File
                // ? data.Inquiry_File.replace(/\\/g, '/') // Replacing backslashes with forward slashes
                // : "";

                const initialData = {
                    Customer_Inquiry_No: data.Inquiry_No,
                    MOC: data.MOC,
                    Inquiry_Date: data.Inquiry_Date,
                    Expiry_Inquiry_Date: data.Inquiry_Expiry_Date,
                    Buyer_POC: data.Buyer_POC,
                    Rig_Name: data.Rig_Name,
                    Customer_Name: data.Customer_Name,
                    Category: data.Category,
                    Description: data.Description,
                    Upload_Inquiry_Received: data.Inquiry_File,
                    Suppliers_Contacted: data.Suppliers_Contacted,
                    Current_Status: data.Current_Status,
                    Quote_No: data.Quote_No,
                    Quote_sent_on: data.Quote_sent_on,
                    PO_No: data.PO_No,
                    RFQ: data.RFQ
                };
                setFormData(initialData);
                // Store the original data
                setOriginalFormData(initialData);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error here
        }
    };
    
    useEffect(() => {

        fetchData();

    }, [id]);


    const handleSave = async (e) => {
        e.preventDefault(); // Prevent form submission default behavior


        const formDataObj = new FormData();
        // Ensure empty or null values are converted to empty strings
        formDataObj.append("Inquiry_No", formData.Customer_Inquiry_No || "");
        formDataObj.append("MOC", formData.MOC || "");
        formDataObj.append("Inquiry_Date", formData.Inquiry_Date || "");
        formDataObj.append("Inquiry_Expiry_Date", formData.Expiry_Inquiry_Date || "");
        formDataObj.append("Buyer_POC", formData.Buyer_POC || "");
        formDataObj.append("Customer_Name", formData.Customer_Name || "");
        formDataObj.append("Rig_Name", formData.Rig_Name || "");
        formDataObj.append("Category", formData.Category || "");
        formDataObj.append("Description", formData.Description || "");
        formDataObj.append("Current_Status", formData.Current_Status || "");
        formDataObj.append("Suppliers_Contacted", formData.Suppliers_Contacted || "");
        formDataObj.append("Quote_No", formData.Quote_No || "");
        formDataObj.append("Quote_sent_on", formData.Quote_sent_on || "");
        formDataObj.append("PO_No", formData.PO_No || "");
        formDataObj.append("RFQ", formData.RFQ || "");
        console.log(formData)
        console.log("File45", formData.Upload_Inquiry_Received)
        if (formData.Upload_Inquiry_Received) {
            formDataObj.append("Inquiry_File", formData.Upload_Inquiry_Received);
            // console.log("File",formData.Upload_Inquiry_Recieved)
        }

        try {
            const res = await axios.put(
                `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparsetrading_InquiryDetails/update/${id}`,
                formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            );

            if (res.status === 200) {
                alert("Form data updated successfully!");
                // fetchData()
                setRefresh(!refresh);
                fetchData();
                setEditMode(false); // Disable editing mode
            }
        } catch (error) {
            console.error("Error uploading file", error);
        }


    };

    console.log("File", formData.Upload_Inquiry_Received)

    const handleCancel = () => {
        setFormData(originalFormData);
        setEditMode(false);
    };

    // Preview the file in a new window
    const previewFile = (file) => {
        const fileURL = file;
        window.open(fileURL);
    };

    // Download the file
    const downloadFile = (fileUrl) => {
        console.log("file", fileUrl)

        const a = document.createElement('a');
        if (fileUrl !== null) {
            fetch(fileUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = fileNameFromLink(fileUrl); // You can set a filename here
                    link.click();
                    URL.revokeObjectURL(blobUrl);
                })
                .catch((error) => {
                    console.error("Error downloading file:", error);
                });
        } else {
            console.log("There is no file to download");
        }
    };

    const options = [
        { value: 'Ajhar', label: 'Ajhar' },
        { value: 'Haneef', label: 'Haneef' },
        { value: 'Basil', label: 'Basil' },
        { value: 'Irfan Sheikh', label: 'Irfan Sheikh' },
        { value: 'Firdose', label: 'Firdose' },

        // Add any other options here
    ];

    return (
        <div >

            <TabsBarSparesInquiry activeTab="Inquiry Details" />

            <div className={styles.sections}>
                <div className={styles.buttonsdiv}>
                    {editMode ? (
                        <div style={{ display: "flex" }}>
                            <button
                                variant="contained"
                                onClick={handleSave}
                            >
                                Save Changes
                            </button>
                            <button
                                variant="outlined"
                                onClick={handleCancel}
                                className={styles.cancel}
                            >
                                Cancel
                            </button>
                        </div>
                    ) : (
                        <button
                            variant="contained"
                            onClick={() => handleEdit()}
                        >
                            Edit
                        </button>
                    )}
                </div>
                <form style={{ display: "flex", justifyContent: "space-around" }}>
                    <div className={styles.splitHalfSection}>
                        <div className={styles.formElement}>
                            <label>Customer Inquiry number</label>
                            
                            <textarea
                                name="Customer_Inquiry_No"
                                value={formData.Customer_Inquiry_No}
                                onChange={handleInputChange}
                                className={editMode ? styles.textbox1b : styles.textbox1a}
                                disabled={!editMode}
                                readOnly={!editMode}
                            />
                        </div>

                        <div className={styles.formElement}>
                            <label>Mode of communication</label>
                            {editMode ? (
                                <>
                                    <select
                                        value={formData.MOC}
                                        name="MOC"
                                        onChange={handleInputChange}
                                        required>
                                        <option value="">Select value</option>
                                        <option value="Email">Email</option>
                                        <option value="Verbal">Verbal</option>
                                        <option value="Portal">Portal</option>
                                        {/* <option value="ADES">ADES</option>
                    <option value="COC">COC</option>
                    <option value="Northern Offshore">Northern Offshore</option>
                    <option value="Melius">Melius</option> */}
                                    </select>
                                    <svg
                                        style={{ position: "absolute", right: "15" }}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                            fill="black"
                                        />
                                    </svg>
                                </>
                            ) : (
                                <>
                                    <select
                                        value={formData.MOC}
                                        name="MOC"
                                        style={{
                                            pointerEvents: "none",
                                        }}
                                        onChange={handleInputChange}
                                        required
                                        className={styles.textbox1}
                                        disabled
                                        readOnly>
                                        <option value="">Select value</option>
                                        <option value="Email">Email</option>
                                        <option value="Verbal">Verbal</option>
                                        <option value="Portal">Portal</option>
                                        {/* <option value="ADES">ADES</option>
                    <option value="COC">COC</option>
                    <option value="Northern Offshore">Northern Offshore</option>
                    <option value="Melius">Melius</option> */}
                                    </select>
                                    <svg
                                        style={{ position: "absolute", right: "15" }}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                            fill="black"
                                        />
                                    </svg>
                                </>
                            )}
                        </div>


                        <div
                            className={`${styles.formElement} ${styles.datepickerInput}`}
                        >
                            <label>Inquiry Date</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CustomDatePicker
                                    name="Inquiry_Date"
                                    value={
                                        formData.Inquiry_Date ? dayjs(formData.Inquiry_Date, "YYYY-MM-DD") : null
                                    }
                                    onChange={(value) => handleDateChange("Inquiry_Date", value)}
                                    slotProps={{ textField: { size: "small" } }}
                                    format="DD/MM/YYYY"
                                    disabled={!editMode}
                                    className={editMode ? null : styles.textbox2}
                                />
                            </LocalizationProvider>
                        </div>
                        <div
                            className={`${styles.formElement} ${styles.datepickerInput}`}
                        >
                            <label>Expiry date of inquiry</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CustomDatePicker
                                    name="Expiry_Inquiry_Date"
                                    value={
                                        formData.Expiry_Inquiry_Date ? dayjs(formData.Expiry_Inquiry_Date, "YYYY-MM-DD") : null
                                    }
                                    onChange={(value) => handleDateChange("Expiry_Inquiry_Date", value)}
                                    slotProps={{ textField: { size: "small" } }}
                                    format="DD/MM/YYYY"
                                    disabled={!editMode}
                                    className={editMode ? null : styles.textbox2}
                                />
                            </LocalizationProvider>
                        </div>

                        <div className={styles.formElement}>
                            <label>Buyer POC</label>
                            <input
                                type="text"
                                name="Buyer_POC"
                                value={formData.Buyer_POC}
                                onChange={handleInputChange}
                                className={editMode ? null : styles.textbox1}
                                disabled={!editMode}
                                readOnly={!editMode}
                            />
                        </div>

                        <div className={styles.formElement}>
                            <label>Customer name</label>
                            <input
                                type="text"
                                name="Customer_Name"
                                value={formData.Customer_Name}
                                onChange={handleInputChange}
                                className={editMode ? null : styles.textbox1}
                                disabled={!editMode}
                                readOnly={!editMode}
                            />
                        </div>

                        <div className={styles.formElement}>
                            <label>Rig name</label>
                            <input
                                type="text"
                                name="Rig_Name"
                                value={formData.Rig_Name}
                                onChange={handleInputChange}
                                className={editMode ? null : styles.textbox1}
                                disabled={!editMode}
                                readOnly={!editMode}
                            />
                        </div>

                        <div className={styles.formElement}>
                            <label>Suppliers contacted</label>
                            <textarea
                                name="Suppliers_Contacted"
                                // value={formData.Suppliers_Contacted}
                                value={suppliersContacted ? suppliersContacted : ""}
                                onChange={handleInputChange}
                                className={styles.textbox1a}
                                disabled={!editMode || editMode}
                                readOnly={!editMode || editMode}
                            />
                        </div>

                        {/* <div className={styles.formElement}>
                            <label>RFQ prepared by</label>
                            {editMode ? (
                                <>
                                    <select
                                        value={formData.RFQ}
                                        name="RFQ"
                                        onChange={handleInputChange}
                                        required>
                                        <option value="">Select value</option>
                                        <option value="Ajhar">Ajhar</option>
                                        <option value="Haneef">Haneef</option>
                                        <option value="Basil">Basil</option>
                                        <option value="Irfan Sheikh">Irfan Sheikh</option>
                                        <option value="Firdose">Firdose</option>
                                        <option value="Ramadas">Ramadas</option>
                                        <option value="Sandhya">Sandhya</option>
                                    </select>
                                    <svg
                                        style={{ position: "absolute", left: "413px" }}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                            fill="black"
                                        />
                                    </svg>
                                </>
                            ) : (
                                <>
                                    <select
                                        value={formData.RFQ}
                                        name="RFQ"
                                        style={{
                                            pointerEvents: "none",
                                        }}
                                        onChange={handleInputChange}
                                        required
                                        className={styles.textbox1}
                                        disabled
                                        readOnly>
                                        <option value="">Select value</option>
                                        <option value="Ajhar">Ajhar</option>
                                        <option value="Haneef">Haneef</option>
                                        <option value="Basil">Basil</option>
                                        <option value="Irfan Sheikh">Irfan Sheikh</option>
                                        <option value="Firdose">Firdose</option>
                                        <option value="Ramadas">Ramadas</option>
                                        <option value="Sandhya">Sandhya</option>
                                    </select>
                                    <svg
                                        style={{
                                            position: "absolute", // Use absolute positioning
                                            left: "413", // Adjust position based on your layout
                                            top: "50%", // Vertically center the SVG
                                            transform: "translateY(-50%)", // Correct vertical alignment
                                            pointerEvents: "none",
                                        }}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                            fill="black"
                                        />
                                    </svg>
                                </>
                            )}
                        </div> */}




                    </div>



                    <div className={styles.splitHalfSection}>

                        <div className={styles.formElement}>
                            <label>Category</label>
                            {editMode ? (
                                <>
                                    <select
                                        value={formData.Category}
                                        name="Category"
                                        onChange={handleInputChange}
                                        required>
                                        <option value="">Select value</option>
                                        <option value="Spares">Spares</option>
                                        <option value="Service">Service</option>
                                        {/* <option value="Portal">Portal</option> */}
                                        {/* <option value="ADES">ADES</option>
                    <option value="COC">COC</option>
                    <option value="Northern Offshore">Northern Offshore</option>
                    <option value="Melius">Melius</option> */}
                                    </select>
                                    <svg
                                        style={{ position: "absolute", left: "413px" }}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                            fill="black"
                                        />
                                    </svg>
                                </>
                            ) : (
                                <>
                                    <select
                                        value={formData.Category}
                                        name="Category"
                                        style={{
                                            pointerEvents: "none",
                                        }}
                                        onChange={handleInputChange}
                                        required
                                        className={styles.textbox1}
                                        disabled
                                        readOnly>
                                        <option value="">Select value</option>
                                        <option value="Spares">Spares</option>
                                        <option value="Service">Service</option>
                                        {/* <option value="Portal">Portal</option> */}
                                        {/* <option value="ADES">ADES</option>
                    <option value="COC">COC</option>
                    <option value="Northern Offshore">Northern Offshore</option>
                    <option value="Melius">Melius</option> */}
                                    </select>
                                    <svg
                                        style={{
                                            position: "absolute", // Use absolute positioning
                                            left: "413", // Adjust position based on your layout
                                            top: "50%", // Vertically center the SVG
                                            transform: "translateY(-50%)", // Correct vertical alignment
                                            pointerEvents: "none",
                                        }}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                            fill="black"
                                        />
                                    </svg>
                                </>
                            )}
                        </div>

                        <div className={styles.formElement}>
                            <label>Description</label>
                            <textarea
                                name="Description"
                                value={formData.Description}
                                onChange={handleInputChange}
                                className={editMode ? styles.textbox1b : styles.textbox1a}
                                disabled={!editMode}
                                readOnly={!editMode}
                            />
                        </div>

                        <div className={styles.formElement}>
                            <label>Upload Inquiry Received</label>
                            <div className={styles.uploadFieldContainer}>
                                <div className={editMode ? null : styles.textbox1}>
                                    <div className={styles.customFileInput} style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* Display the selected file name or a placeholder if no file is selected */}
                                        {!editMode && (
                                            <input
                                                type="text"
                                                value={formData.Upload_Inquiry_Received ? fileNameFromLink(formData.Upload_Inquiry_Received) : 'No file chosen'}
                                                readOnly
                                                className={styles.fileNameInput}
                                                disabled={!editMode}
                                            />
                                        )}
                                        {editMode && (
                                            <>
                                                <input
                                                    type="file"
                                                    name="Upload_Inquiry_Received"
                                                    style={{ display: "none", width: "0" }}
                                                    onChange={(e) => {
                                                        if (e.target.files.length > 0) {
                                                            console.log("filelength", e.target.files.length)
                                                            setFormData({ ...formData, Upload_Inquiry_Received: e.target.files[0] });
                                                        }
                                                    }}
                                                    id="Inquiry_File"
                                                    disabled={!editMode}
                                                />

                                                <div
                                                    className=
                                                    {styles["inquiry-input-upload"]}

                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => document.getElementById("Inquiry_File").click()}
                                                >
                                                    <label
                                                        style={{
                                                            fontFamily: 'Arial, sans-serif',
                                                            marginLeft: "4px",
                                                            marginTop: "5px",
                                                            color: "rgba(128, 128, 128, 0.8)",
                                                            overflow: "hidden", // Hide overflow text
                                                            textOverflow: "ellipsis", // Display ellipsis for overflow text
                                                            whiteSpace: "nowrap", // Prevent wrapping
                                                            maxWidth: "180px", // Set maximum width for the file name
                                                            alignItems: "",
                                                        }}
                                                    >
                                                    <span>
                                                        {formData.Upload_Inquiry_Received?.name ||
                                                            fileNameFromLink(formData.Upload_Inquiry_Received) ||
                                                            "Upload File"}
                                                    </span>
                                                    </label>
                                                    
                                                      {}  <svg
                                                            width="28"
                                                            height="25"
                                                            viewBox="0 0 28 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            style={{
                                                                position: "absolute", // Use absolute positioning
                                                                left: "416px", // Adjust position based on your layout
                                                                top: "50%", // Vertically center the SVG
                                                                transform: "translateY(-50%)", // Correct vertical alignment
                                                                pointerEvents: "none",
                                                            }}
                                                        >
                                                            <path
                                                                d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                                                                fill="black"
                                                            />
                                                            <path
                                                                d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    
                                                </div>
                                                {formData.Upload_Inquiry_Received && (
                                                 <>
                                                <div>
                                                    <img
                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEJSURBVChTlZI9r0ZAEIXXbX1L0CoU/P9/IfGdUCgkSDSiolLMdeZlw3ur+yQbM2fmmLVLoRPxT36upyiKQkzTdGV/Qa0sy0+CSX3fYxrZtk1ZlkF6Ac2yLO4ZhoHYdBwHRVHEoq7rL2OapqyhFscx97IJzPNMQRBw0TAMyvOcF2JoYRhyD5AmMI6jNKqqygsxtNsAXiYAo+d53Izl+z5rT+Tp3ZxvFNu2XZkQ+76LZVmu7OIyM0mSkKZpPMF1XXIch2N8F2o30vRt6LqO2rblGBpqt5FN53h5rLirpmm4COq6Zu2euK7rx4STURSFt1NVFTc/gWaaJvdggNwe7uT7lJ6gdv5qZ0T0C/vJiM0XBD8WAAAAAElFTkSuQmCC"
                                                        alt="preview"
                                                        width="12.5"
                                                        height="12.5"
                                                        style={{ marginLeft: "9px", cursor: "pointer" }}
                                                        onClick={() => setFormData({ ...formData, Upload_Inquiry_Received: null })}

                                                    />

                                                </div>
                                                </>
                                                )}
                                            </>
                                        )}

                                    </div>
                                </div>
                                {!editMode && formData.Upload_Inquiry_Received && (
                                    <>



                                        <div className={'styles.uploadFieldContainer'}>

                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK6SURBVGhD7ZlbbuIwFIYPpuXSYQrSzEMf2QGwA3bCEtgCrAB2ADuBHQArAJ5aqa1QphdKgWbO7zgwQw0zdkgTJD7JKHZM+eIcn1BOwmXohPgk/L4kWrwTvS2I1h9q8Iu5uCC65Ja7IkoKNajYCOP16dUTvcoQZbntTv4qVmuiJS/c85wonSL6zuKJhHdOCkP20eGrShJd57Yno+aDvZ55EXHXf+Q9Lyn86wUHfCUsG0ecJ/bju339jUggThGzOe7EFSwkQhUrLbDkuWx8wkCHYDfsqwVLi9XK25FxJ5vmVeZIEEsWRhqJO0lOCAjfiBKXPWfhsDkLh03i9t51b36qXkD6/T6NRiOaTCayXywWqVQqUbValf2g3D3wC4SD0m633UKhgC9R2sbibrfbVbPtgWsg4fF47JbLZa2krkEc77ElkDA+GAI6sUMtiLS1sK2s32ylrYX3ySI8er2emuXK40NzTYGrcVrjzbPJAn/CYsSCMktUKhXZptMpDQYDYjk1a8twOKRms6l6Bpiu8L5NhtXsdDqfxpFBcG53HI3Tnfqr/4dVSOg+GA3oLsa/9bvjfjPBKiSixlh431MLT7laraZ6W+r1ujynQxfb/8Q0JBqNhvbW4tbPZjMZxzhGa7VacmxfpsBcE6xiGEBGJwCx3bR2aK4p1sIn9+AAttK2siCQMDCVRt61lQWBhX2weQ6J4xw2YFDgevQv8HjkOo4j+/l8XqauY36BP6pw2ED4/E9o2JyFw+b0hFHHiKr4YgLqHvhZWKTTRPM3NRpjUKTBz8IikyJ6mfPjCM+kmAI3v1IgUpdeiQuFmbiCchwWFj9qy02HAh5KB5CO00rDBRUkFGP8otGmsOjXxFD4wAUgwKMqJSAJYF+9cqiitgHZvwqL3qHHmncjKpBYcdQ/ogCZK8OiqIIiZLcQ/QaGz5TthWcj3QAAAABJRU5ErkJggg=="
                                                alt="preview"
                                                width="40"
                                                height="40"
                                                style={{ marginLeft: "8px", cursor: "pointer" }}
                                                onClick={() => previewFile(formData.Upload_Inquiry_Received)}
                                            />

                                        </div>

                                        <div className={'styles.uploadFieldContainer'}>

                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAxCAYAAAClOZt5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANlSURBVGhD7ZnLTxNRFId/5VXAFhe60LK31YUbhK0GNXFD8E8gLCAEFyYaYkhMSEzckWgIMbggunJhIhBZmJgQ123ZuFDqvsWFLmB4Pz2/O3dKS6Z2HqSdSfiSKfdOy+S7Z8698ziRYwEhpEH/DR22Ed/bB3b3gJ1d4PBI76wxTU1As2yxdqDRJrxl4mwZW6ZweyvQJpvdP9WCg0NgXwK4sQ1EW4C4DCAS0V8KRXF+/l2TUTYCHbHyH9WTI/HakGAyCy5dPPEqiq9vsiMjE+kgsmaIn5z9jgtmXyUC85g5HdM7gwgDyhRm5IkS56mItQUnPexoEDfOu12RV31+HByYMzjotEUl6pIZRInviziXn6DTKAuHtTwr8TByLl5rQiuuLkC//wBXLus9Lshks5hbWEQ6s4xCoaD3/p/ORALd3V0YHRmW9lW91zmWq2fx6Tczsr3VPW+MjgypAbjBcvWUKnmJrl9pwmOks8u65w5P4k6k4/E4EpIW1Zhf+Kxb7vAknpGcrsbU60mMjz3Rvco4OZYdnlPFCXHeH1fB6bFOE9rl8FycVJqQTieqG85U/OWLCbyfnSmTZPvTxw+OJqobPF2Abtzs0q1yeFV8N8ul8hiGsSGR5uTk08kxBgaHK07EH9+dryy+LkCVoNjA4JC0Ikglr6mBVJP2yplPTks+X1jV7bOXJr5ThROP2+mbLEZ73TAkZeTxvAS733tJFd/iU68mcbf3DlZyv7CyksPPXE6kVmGsm8KdnQnEJNdTySSup5IqhZaWvuHR45PJWjPx+w/6iqefke3tvY17Ik85RtMORj6dyaqbqnm5FbbORFIGMierjlN8iY8/n6h4c0Rx3mfHO8wB5PMSfZuUsXjY36eWUaf4EmfUzNXDP1+/LKqBOsVy9bSq9Nwyn2D88mzsqSvpUjxF3IJ5rh4GXD669cijW7+kCAPgFl+pUk98pUoQOBevNeEWZ52nXkUqN7AuZL0OV+LRKLC9o/qBhsUs63W4Em9tATa3zQJWUKGbVTkhSryl2SwNsoAVVFjGZID5cp8occJCKEsqlA9S5OnCihuLVqXFNXXl1O1iTZEFIg6EE6FeJRYuFpx3W5LCrP1Q2rZAW8qhzF5WdHkGWB+qB1zpWkWYVWWm8mlsxcNAMcfDRkjFgX/xjpSrcnSqIgAAAABJRU5ErkJggg=="
                                                alt="preview"
                                                width="40"
                                                height="40"
                                                style={{ marginLeft: "5px", cursor: "pointer" }}
                                                onClick={() => downloadFile(formData.Upload_Inquiry_Received)}
                                            />

                                        </div>

                                    </>
                                )}


                            </div>
                        </div>

                        <div className={styles.formElement}>
                            <label>Current Status</label>
                            <textarea
                                name="Current_Status"
                                value={formData.Current_Status}
                                onChange={handleInputChange}
                                className={editMode ? styles.textbox1b : styles.textbox1a}
                                disabled={!editMode}
                                readOnly={!editMode}
                            />
                        </div>



                        <div className={styles.formElement}>
                            <label>Quote No</label>
                            <input
                                type="text"
                                name="Quote_No"
                                value={formData.Quote_No}
                                onChange={handleInputChange}
                                className={editMode ? null : styles.textbox1}
                                disabled={!editMode}
                                readOnly={!editMode}
                            />
                        </div>

                        <div
                            className={`${styles.formElement} ${styles.datepickerInput}`}
                        >
                            <label>Quote sent on</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CustomDatePicker
                                    name="Quote_sent_on"
                                    value={
                                        formData.Quote_sent_on ? dayjs(formData.Quote_sent_on, "YYYY-MM-DD") : null
                                    }
                                    onChange={(value) => handleDateChange("Quote_sent_on", value)}
                                    slotProps={{ textField: { size: "small" } }}
                                    format="DD/MM/YYYY"
                                    disabled={!editMode}
                                    className={editMode ? null : styles.textbox2}
                                />
                            </LocalizationProvider>
                        </div>

                        <div className={styles.formElement}>
                            <label>PO No</label>
                            <input
                                type="text"
                                name="PO_No"
                                value={formData.PO_No}
                                onChange={handleInputChange}
                                className={editMode ? null : styles.textbox1}
                                disabled={!editMode}
                                readOnly={!editMode}
                            />
                        </div>



                    </div>

                </form>
            </div>
        </div>
    )
}
export default SparesTradingInquiryDetails;