import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_inquiry_customer_quote.module.css";
import TabsBarSparesInquiry from './TabsBarSparesInquiry';
import LoadingScreen from './LoadingScreen';
import Select from 'react-select';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparesTradingInquiryCustomerQuote() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks
  const [quotesData, setQuotesData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    // Fetch data from the API
    axios
      .get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_spares_inquiry_customer_quote_get?Spares_Inquiry_ID=${id}`)
      .then((response) => {
        const rawData = response.data.data;

        setQuotesData(rawData);
        console.log("quotesData", response);
      })
      .catch((error) => {
        console.error("Error fetching the data", error);
      });

    setIsLoading(false);
  }, []);

  console.log("quotesData", quotesData);

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <div>
      <LoadingScreen isLoading={isLoading} />

      <TabsBarSparesInquiry activeTab="Customer Quote" />

      <div className={styles.sections2}>
        <div className={styles.smtablecontainer}>
          <table className={styles.smtable}>
            <thead>
              <tr className={styles.headrowsm}>
                <th className={styles.fileUploadheading}>S. No.</th>
                <th className={styles.fileUploadheading}>Item Name</th>
                <th className={styles.fileUploadheading}>Part Number</th>
                <th className={styles.fileUploadheading}>UoM</th>
                <th className={styles.fileUploadheading}>Qty</th>
                <th className={styles.fileUploadheading}>Rate</th>
                <th className={styles.fileUploadheading}>Amount</th>
                <th className={styles.fileUploadheading}>Delivery Time</th>
                <th className={styles.fileUploadheading}>Ex-Works</th>
                <th className={styles.fileUploadheading}>Selected Supplier</th>
              </tr>
            </thead>

            <tbody>
              {quotesData.map((item, index) => {
                // Convert Inquired_Qty to a number
                const inquiredQty = parseInt(item.Inquired_Qty) || 0;

                // Extract numeric part of Rate and currency symbol
                const rateMatch = item.Rate ? item.Rate.match(/^([\d.,]+)\s*(\w+)$/) : null;
                const rateValue = rateMatch ? parseFloat(rateMatch[1].replace(/,/g, '')) : 0; // Remove commas if any and convert to float
                const currency = rateMatch ? rateMatch[2] : '';

                // Calculate Amount and format it with 2 decimal places if needed
                const amount = (inquiredQty * rateValue).toFixed(rateValue % 1 === 0 ? 0 : 2); // No decimals if it's an integer
                console.log("amount", amount);

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.Item_Name || "-"}</td>
                    <td>{item.Part_Number || "-"}</td>
                    <td>{item.UoM || "-"}</td>
                    <td>{item.Inquired_Qty || "-"}</td>
                    <td>{item.Rate || "-"}</td>
                    <td>{(amount && amount !== "0")? `${amount} ${currency}` : "-"}</td>
                    <td>{item.Delivery_Time || "-"}</td>
                    <td>{item.Ex_Works || "-"}</td>
                    <td>{item.Supplier_Name || "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SparesTradingInquiryCustomerQuote;