import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import styles from "../Components/css/newtimesheet.module.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Erogonlogo from "../../src/Components/Erogonlogo.png";

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const Newtimesheetedit = () => {
  const [employees, setEmployees] = useState([]);
  const [Fildata, setFildata] = useState([]);
  const [Techarray, setTecharray] = useState([]);
  const [Projectarray, setProjectarray] = useState([]);
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [editon, setediton] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [uploadopen, setUploadopen] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [uploadfile, setUploadfile] = useState("");
  const [filename, setFilename] = useState("");
  const [downloadstart, setdownloadstart] = useState(false);

  const dateParts = id.split("-");
  const [year, month, day] = dateParts;
  const formattedDate = `${day}/${month}/${year}`;
  const [maindate, setmaindate] = useState(formattedDate);

  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/toolbox_data"
        );
        const filteredData = response.data.data.filter(
          (el) => el.Date === maindate
        );
        setFildata(filteredData);

        const attendees = filteredData[0]?.Attendees || "";
        const supervisors = filteredData[0]?.Supervisors || "";

        const combinedString =
          (attendees !== "" ? attendees + "," : "") +
          (supervisors !== "" ? supervisors : "");

        console.log(combinedString);

        setTecharray(combinedString.split(","));

        const projectArray = response.data.data
          .filter((el) => el.Date === maindate)
          .map((item) => item.Project_so);
        // .filter(project => project !== null)
        // .filter((value, index, self) => self.indexOf(value) === index);

        // Using Set to remove duplicates and then spreading it back to array
        let uniqueArray = [...new Set(projectArray)];

        setProjectarray(uniqueArray);


     
      } catch (error) {
        console.error(error);
      }
    }

    if (maindate) {
      fetchRigDetails();
    }
  }, [maindate, refresh]);

  useEffect(() => {
    const fetchProfessionalDetails = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details"
        );

        setEmployees(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProfessionalDetails();
  }, [refresh]);

  useEffect(() => {
    async function fetchRigggDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheetwork_data"
        );

        const filteredData = response.data.data.filter(
          (el) => el.Date === maindate
        );
        setdata(filteredData);
        console.log();
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigggDetails();
  }, [refresh]);

  const handleedit = () => {
    setediton(true);
  };
  const handlecancel = () => {
    setediton(false);
    setrefresh(!refresh);
  };

  const handleClose = () => {
    setUploadopen(false);
  };

  const handlemanchange = (e, project, tech) => {
    const inputValue = e.target.value;
    const temp = [...data];
    const entryIndex = temp.findIndex(
      (el) => el.Project === project && el.Tech === tech
    );

    if (entryIndex !== -1) {
      temp[entryIndex] = {
        ...temp[entryIndex],
        Manhours: inputValue,
      };
      setdata(temp);
    }
  };

  const handlesave = async () => {
    if (data.length > 0) {
      await Promise.all(
        data.map(async (el, index) => {
          const payload = {
            Date: el.Date,
            Conducted_by: el.Conducted_by,
            Tech: el.Tech,
            Project: el.Project,
            Manhours: el.Manhours,
          };

          try {
            const res = await axios.put(
              `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshoptimesheet_update?S_No=${el.S_No}`,
              payload
            );
            console.log(res);
            setediton(false);
            setrefresh(!refresh);
            console.log(data);
          } catch (error) {
            console.error("Error:", error);
            console.log(data);
          }
        })
      );
    }
  };

  const handledwn = (e) => {
    e.preventDefault();
    // Call your API to post the form data
    // Once the API call is successful, proceed to generate the PDF
    generatePDF();
  };

  const uploadFile = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const fileInput = document.getElementById("fileInput");

    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      // if (!selectedFile) {
      //   return;
      // }
      setUploadfile(selectedFile);
      const fileURL = URL.createObjectURL(selectedFile);
      setFilePreview(fileURL);
      setFilename(selectedFile.name);
      setUploadopen(true);
      // e.preventDefault();
      const formData = new FormData();
      formData.append("Workshop_Time_sheet", selectedFile);
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshoptimesheetdata_update?date=${maindate}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(res.data.message);
      setrefresh(!refresh);
      setUploadopen(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Workshop_Time_sheet", uploadfile);
    const res = await axios.put(
      `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshoptimesheetdata_update?date=${maindate}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log(res.data.message);
    setrefresh(!refresh);
    setUploadopen(false);
  };

  const handledownloadfile = (e) => {
    if (e !== null) {
      fetch(e)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = ""; // Set desired filename
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      console.log("there is no file to download");
    }
  };

  const generatePDF = async () => {
    await setdownloadstart(true);
    const input = document.getElementById("table");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    html2canvas(input, { height: inputHeight, width: inputWidth }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4"); // Set PDF document size to A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust image position and size to fit the PDF
        // Save or preview the PDF
        pdf.save("Timesheet.pdf");
      }
    );
    setdownloadstart(false);
  };

  // const handledate = (e) => {
  //   const dateParts = e.target.value.split("-");
  //   const [year, month, day] = dateParts;
  //   const formattedDate = `${day}/${month}/${year}`;
  //   setmaindate(formattedDate);
  // }

       // Function to calculate total manhours for a tech
       function calculateTotalManhours(tech, data) {
        return data.reduce((total, el) => {
          // Handle missing or non-numeric Manhours
          const manhours = Number(el.Manhours) || 0;  // Convert to number or default to 0
          return total + (el.Tech === tech ? manhours : 0);
        }, 0);
      }
      
      // Function to find manhours for a specific project and tech
      function findManhours(data, project, tech) {
        const filteredData = data.find(
          (el) => el.Project === project && el.Tech === tech
        );
        return filteredData ? parseFloat(filteredData.Manhours) || 0 : 0;
      }

  return (
    <>
      <div className={styles.maindiv}>
        <h4 className={styles.mainheading1}>
          <NavLink to="/Timesheetlist" style={{ color: "white" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{ marginRight: "20px" }}>
              <path
                d="M22.4598 10.4922H5.25583L12.772 3.09556C13.3727 2.50443 13.3727 1.53438 12.772 0.943258C12.6295 0.802747 12.4602 0.691271 12.2739 0.61521C12.0876 0.53915 11.8879 0.5 11.6861 0.5C11.4844 0.5 11.2847 0.53915 11.0984 0.61521C10.912 0.691271 10.7428 0.802747 10.6003 0.943258L0.450421 10.9318C0.30764 11.072 0.194362 11.2385 0.117073 11.4219C0.0397835 11.6053 0 11.8018 0 12.0003C0 12.1988 0.0397835 12.3954 0.117073 12.5788C0.194362 12.7621 0.30764 12.9287 0.450421 13.0689L10.6003 23.0574C10.7429 23.1977 10.9122 23.309 11.0985 23.385C11.2848 23.4609 11.4845 23.5 11.6861 23.5C11.8878 23.5 12.0875 23.4609 12.2738 23.385C12.4601 23.309 12.6294 23.1977 12.772 23.0574C12.9146 22.9171 13.0277 22.7505 13.1049 22.5671C13.182 22.3838 13.2217 22.1873 13.2217 21.9888C13.2217 21.7904 13.182 21.5939 13.1049 21.4105C13.0277 21.2272 12.9146 21.0606 12.772 20.9202L5.25583 13.5236H22.4598C23.3069 13.5236 24 12.8415 24 12.0079C24 11.1743 23.3069 10.4922 22.4598 10.4922Z"
                fill="white"
              />
            </svg>
          </NavLink>
          <NavLink to="/admintaskbar" style={{ color: "white" }}>
            Admin dashboard
          </NavLink>
          {" > "}
          <NavLink to="/Timesheetlist" style={{ color: "white" }}>
            Timesheet
          </NavLink>
          {" > "}
          {maindate}
        </h4>
        <div className={styles.div2}>
          <h6 className={styles.div2button}>
            <div
              style={{
                marginTop: "20px",
                color: "#586BE1",
                fontWeight: "700",
                marginRight: "0px",
              }}>
              Actions:{" "}
            </div>
            {!editon && (
              <button onClick={handleedit} style={{ width: "100px" }}>
                Edit
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  style={{ marginLeft: "20px" }}>
                  <path
                    d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                    fill="white"
                  />
                </svg>
              </button>
            )}
            {editon && <button onClick={handlecancel}>Cancel</button>}
            {editon && <button onClick={handlesave}>Save</button>}
            {!editon && (
              <button onClick={handledwn}>
                Download TS
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginLeft: "20px" }}>
                  <path
                    d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM6.47 8.98C6.76 8.69 7.24 8.69 7.53 8.98L9.25 10.7V4.51C9.25 4.1 9.59 3.76 10 3.76C10.41 3.76 10.75 4.1 10.75 4.51V10.7L12.47 8.98C12.76 8.69 13.24 8.69 13.53 8.98C13.82 9.27 13.82 9.75 13.53 10.04L10.53 13.04C10.46 13.11 10.38 13.16 10.29 13.2C10.2 13.24 10.1 13.26 10 13.26C9.9 13.26 9.81 13.24 9.71 13.2C9.62 13.16 9.54 13.11 9.47 13.04L6.47 10.04C6.18 9.75 6.18 9.28 6.47 8.98ZM16.24 15.22C14.23 15.89 12.12 16.23 10 16.23C7.88 16.23 5.77 15.89 3.76 15.22C3.37 15.09 3.16 14.66 3.29 14.27C3.42 13.88 3.84 13.66 4.24 13.8C7.96 15.04 12.05 15.04 15.77 13.8C16.16 13.67 16.59 13.88 16.72 14.27C16.84 14.67 16.63 15.09 16.24 15.22Z"
                    fill="white"
                  />
                </svg>
              </button>
            )}
            {!editon && (
              <>
                {!data[0]?.Time_sheet_doc && (
                  <>
                    <button
                      // className={styles.button1}
                      style={{ width: "auto" }}
                      for="fileInput"
                      onClick={uploadFile}>
                      Upload Signed TS
                      <svg
                        width="20"
                        height="20"
                        style={{ marginLeft: "20px" }}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.80219 20H14.1822C17.8222 20 19.9922 17.83 19.9922 14.19V5.82C19.9922 2.17 17.8222 0 14.1822 0H5.81219C2.17219 0 0.00218773 2.17 0.00218773 5.81V14.19C-0.0078125 17.83 2.16219 20 5.80219 20ZM13.5222 11.02C13.2322 11.31 12.7522 11.31 12.4622 11.02L10.7422 9.3L10.7422 15.49C10.7422 15.9 10.4022 16.24 9.99219 16.24C9.58219 16.24 9.24219 15.9 9.24219 15.49L9.24219 9.3L7.52219 11.02C7.23219 11.31 6.75219 11.31 6.46219 11.02C6.17219 10.73 6.17219 10.25 6.46219 9.96L9.46219 6.96C9.53219 6.89 9.61219 6.84 9.70219 6.8C9.79219 6.76 9.89219 6.74 9.99219 6.74C10.0922 6.74 10.1822 6.76 10.2822 6.8C10.3722 6.84 10.4522 6.89 10.5222 6.96L13.5222 9.96C13.8122 10.25 13.8122 10.72 13.5222 11.02ZM3.75219 4.78C5.76219 4.11 7.87219 3.77 9.99219 3.77C12.1122 3.77 14.2222 4.11 16.2322 4.78C16.6222 4.91 16.8322 5.34 16.7022 5.73C16.5722 6.12 16.1522 6.34 15.7522 6.2C12.0322 4.96 7.94219 4.96 4.22219 6.2C3.83219 6.33 3.40219 6.12 3.27219 5.73C3.15219 5.33 3.36219 4.91 3.75219 4.78Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleFileChange}
                      id="fileInput"
                      style={{ display: "none" }}
                    />
                  </>
                )}

                {data[0]?.Time_sheet_doc && (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        marginTop: "20px",
                        color: "#586BE1",
                        fontWeight: "700",
                        marginLeft: "7%",
                        whiteSpace: "nowrap",
                      }}>
                      Signed Timesheet:{" "}
                    </div>
                    <a
                      href={data[0]?.Time_sheet_doc}
                      target="_blank"
                      rel="noreferrer">
                      <button style={{ width: "100px" }}>
                        View
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="13"
                          viewBox="0 0 21 15"
                          fill="none"
                          style={{ marginLeft: "10px " }}>
                          <path
                            d="M10.2273 10.2273C11.7335 10.2273 12.9545 9.00621 12.9545 7.49998C12.9545 5.99375 11.7335 4.77271 10.2273 4.77271C8.72104 4.77271 7.5 5.99375 7.5 7.49998C7.5 9.00621 8.72104 10.2273 10.2273 10.2273Z"
                            fill="white"
                          />
                          <path
                            d="M20.4133 7.26818C19.6114 5.19385 18.2192 3.39997 16.4089 2.10824C14.5985 0.816515 12.4494 0.0835725 10.227 0C8.00461 0.0835725 5.85543 0.816515 4.04507 2.10824C2.23472 3.39997 0.842547 5.19385 0.040619 7.26818C-0.0135397 7.41798 -0.0135397 7.58202 0.040619 7.73182C0.842547 9.80615 2.23472 11.6 4.04507 12.8918C5.85543 14.1835 8.00461 14.9164 10.227 15C12.4494 14.9164 14.5985 14.1835 16.4089 12.8918C18.2192 11.6 19.6114 9.80615 20.4133 7.73182C20.4675 7.58202 20.4675 7.41798 20.4133 7.26818ZM10.227 11.9318C9.35045 11.9318 8.49361 11.6719 7.7648 11.1849C7.03599 10.6979 6.46795 10.0058 6.13252 9.19598C5.79708 8.38617 5.70932 7.49508 5.88032 6.6354C6.05132 5.77571 6.47341 4.98603 7.09321 4.36623C7.71302 3.74643 8.50269 3.32434 9.36238 3.15334C10.2221 2.98234 11.1132 3.0701 11.923 3.40553C12.7328 3.74097 13.4249 4.30901 13.9119 5.03781C14.3989 5.76662 14.6588 6.62347 14.6588 7.5C14.657 8.67484 14.1895 9.80104 13.3588 10.6318C12.528 11.4625 11.4018 11.93 10.227 11.9318Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </a>
                    <button
                      onClick={() =>
                        handledownloadfile(data[0]?.Time_sheet_doc)
                      }>
                      Download
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 20 20"
                        fill="none"
                        style={{ marginLeft: "10px " }}>
                        <path
                          d="M14.1971 0L5.8129 0C2.17108 0 0 2.17 0 5.81L0 14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0ZM6.47323 8.98C6.76337 8.69 7.24361 8.69 7.53375 8.98L9.25461 10.7V4.51C9.25461 4.1 9.59478 3.76 10.005 3.76C10.4152 3.76 10.7554 4.1 10.7554 4.51V10.7L12.4762 8.98C12.7664 8.69 13.2466 8.69 13.5367 8.98C13.8269 9.27 13.8269 9.75 13.5367 10.04L10.5352 13.04C10.4652 13.11 10.3852 13.16 10.2951 13.2C10.2051 13.24 10.105 13.26 10.005 13.26C9.90494 13.26 9.81489 13.24 9.71484 13.2C9.6248 13.16 9.54476 13.11 9.47472 13.04L6.47323 10.04C6.18308 9.75 6.18308 9.28 6.47323 8.98ZM16.2481 15.22C14.2371 15.89 12.126 16.23 10.005 16.23C7.88393 16.23 5.77288 15.89 3.76187 15.22C3.37168 15.09 3.16158 14.66 3.29164 14.27C3.4217 13.88 3.84191 13.66 4.24211 13.8C7.96397 15.04 12.056 15.04 15.7779 13.8C16.1681 13.67 16.5983 13.88 16.7283 14.27C16.8484 14.67 16.6383 15.09 16.2481 15.22Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <>
                      <button onClick={uploadFile}>
                        Upload Again
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 20 20"
                          fill="none"
                          style={{ marginLeft: "10px " }}>
                          <path
                            d="M5.80293 20H14.1871C17.8289 20 20 17.83 20 14.19V5.82C20 2.17 17.8289 0 14.1871 0H5.81293C2.17112 0 3.43323e-05 2.17 3.43323e-05 5.81V14.19C-0.00996971 17.83 2.16111 20 5.80293 20ZM13.5268 11.02C13.2366 11.31 12.7564 11.31 12.4662 11.02L10.7454 9.3L10.7454 15.49C10.7454 15.9 10.4052 16.24 9.99501 16.24C9.58481 16.24 9.24464 15.9 9.24464 15.49L9.24464 9.3L7.52378 11.02C7.23364 11.31 6.7534 11.31 6.46325 11.02C6.17311 10.73 6.17311 10.25 6.46325 9.96L9.46475 6.96C9.53479 6.89 9.61483 6.84 9.70487 6.8C9.79492 6.76 9.89496 6.74 9.99501 6.74C10.0951 6.74 10.1851 6.76 10.2852 6.8C10.3752 6.84 10.4552 6.89 10.5253 6.96L13.5268 9.96C13.8169 10.25 13.8169 10.72 13.5268 11.02ZM3.7519 4.78C5.76291 4.11 7.87396 3.77 9.99501 3.77C12.1161 3.77 14.2271 4.11 16.2381 4.78C16.6283 4.91 16.8384 5.34 16.7084 5.73C16.5783 6.12 16.1581 6.34 15.7579 6.2C12.036 4.96 7.94399 4.96 4.22214 6.2C3.83194 6.33 3.40173 6.12 3.27167 5.73C3.15161 5.33 3.36171 4.91 3.7519 4.78Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept=".pdf"
                        id="fileInput"
                        style={{ display: "none" }}
                      />
                    </>
                  </div>
                )}
              </>
            )}
          </h6>
        </div>

        {!editon && (
          <form>
          <table className={styles.maintable}>
            <thead>
              <tr>
                <th>Techs/Projects</th>
                <th>Total Manhours</th>
                {Projectarray.map((project, index) => (
                  <th key={index}>{project}</th>
                ))}
               
              </tr>
            </thead>
            {Techarray.map((tech, index) => (
              <tr key={index}>
                <td>{tech}</td>
                <td>{calculateTotalManhours(tech, data).toFixed(2)}</td>
                {Projectarray.map((project, projectIndex) => (
                  <td key={projectIndex}>
                    {findManhours(data, project, tech).toFixed(2)}
                  </td>
                ))}
              </tr>
            ))}
          </table>
        </form>
        )}
   
        {editon && (
          <table className={styles.maintable}>
            <thead>
              <tr>
                <th>Techs/Projects</th>

                {Projectarray.map((project, index) => (
                  <th key={index}>{project}</th>
                ))}
              </tr>
            </thead>
            {Techarray.map((tech, index) => (
              <tr key={index}>
                <td>{tech}</td>
                {Projectarray.map((project, projectIndex) => {
                  const filteredData = data.find(
                    (el) => el.Project === project && el.Tech === tech
                  );
                  const manhours = filteredData ? filteredData.Manhours : 0;

                  return (
                    <td key={projectIndex}>
                      <input
                        type="number"
                        value={manhours}
                        onChange={(e) => handlemanchange(e, project, tech)}
                        style={{ textAlign: "center" }}
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
          </table>
        )}
      </div>
      {downloadstart && (
        <form id="table" style={{ paddingBottom: "5px", paddingTop: "5px" }}>
          <div
            style={{
              width: "95%",
              marginLeft: "2.5%",
              backgroundColor: "#DCF6FB",
              color: "black",
              height: "auto",
              paddingTop: "10px",
              paddingBottom: "10px",
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}>
            {" "}
            <img
              src={Erogonlogo}
              className="ergonlogo2"
              height="69"
              width="200"
              style={{ marginLeft: "1%" }}
            />
            <p
              style={{
                marginLeft: "15%",
                whiteSpace: "nowrap",
                fontSize: "25px",
              }}>
              Ergon Technologies FZE Timesheet- Workshop
            </p>
            <p style={{ marginLeft: "12%", fontSize: "25px" }}>
              Date:{maindate}
            </p>
          </div>
          <table
            style={{ marginTop: "10px", fontSize: "20px" }}
            className={styles.maintable2}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Techs</th>
                {/* <th>Designation</th> */}
                <th>Total Manhours</th>
                {Projectarray.map((project, index) => (
                  <th key={index}>{project}</th>
                ))}
              </tr>
            </thead>
            {Techarray.map((tech, index) => {
              const filteredEmployees = employees.filter(
                (employee) => employee.Customer_Name === tech
              );
              const des =
                filteredEmployees.length > 0
                  ? filteredEmployees[0].Designation
                  : "";

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{tech}</td>
                  {/* <td>{des}</td> */}
                  <td>
                    {/* Calculate total manhours for the current tech */}
                    {Projectarray.reduce((total, project) => {
                      const filteredData = data.find(
                        (el) => el.Project === project && el.Tech === tech
                      );
                      const manhours = filteredData
                        ? parseFloat(filteredData.Manhours)
                        : NaN;
                      return total + (Number.isNaN(manhours) ? 0 : manhours);
                    }, 0)}
                  </td>
                  {Projectarray.map((project, projectIndex) => {
                    const filteredData = data.find(
                      (el) => el.Project === project && el.Tech === tech
                    );
                    const manhours = filteredData ? filteredData.Manhours : 0;
                    console.log(manhours);
                    const totalmanhours = manhours + manhours;
                    return <td key={projectIndex}>{manhours}</td>;
                    console.log(manhours);
                  })}
                </tr>
              );
            })}
          </table>
          <table
            style={{ marginBottom: "20px", fontSize: "20px" }}
            className={styles.maintable3}>
            <thead style={{ height: "20px" }}>
              {" "}
              <tr>
                <th>Comments</th>
                <th>Supervisor's Signature</th>
                <th>Workshop in-charge's Signature</th>
              </tr>
            </thead>

            <tr>
              <td
                style={{
                  height: "70px",
                  backgroundColor: "white",
                  width: "25%",
                }}></td>
              <td
                style={{
                  height: "70px",
                  backgroundColor: "white",
                  width: "25%",
                }}></td>
              <td
                style={{
                  height: "70px",
                  backgroundColor: "white",
                  width: "25%",
                }}></td>
            </tr>
          </table>
          <div style={{ display: "flex", height: "30px" }}>
            <p style={{ marginLeft: "10%" }}>TSD-OPS-FOR-028-23,Rev1</p>{" "}
            <p style={{ marginLeft: "30%" }}>13 June 2023</p>{" "}
            <p style={{ marginLeft: "30%" }}>Page 1</p>
          </div>
        </form>
      )}
    </>
  );
};

export default Newtimesheetedit;
