import React from 'react'
import { useEffect, useState } from "react";
import axios from "axios";
import styles from "../Components/css/visualinspectionsupdate.module.css";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import { Select, MenuItem, TextField, Button, IconButton } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import LoadingScreen from './LoadingScreen';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';


function VisualInspectionsUpdate() {
  const navigate = useNavigate();
  const { project_id, visual_inspection_id } = useParams();
  const [refresh, setRefresh] = useState(false);    //refresh state to control the useEffect whenever it changes
  const [isEditing, setIsEditing] = useState(false);    //to control the editing mode, turns true when edit button is clicked, turns false when save/cancel button clicked
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks
  const [picturesToRemove, setPicturesToRemove] = useState([]);   //to track which pictures to remove when cross button is clicked

  const [inspectionDetails, setInspectionDetails] = useState([]);   // visual inspection details to be displayed in top div
  const [inspectionItems, setInspectionItems] = useState([]);   //visual inspection items, main data that is to be displayed in the table in non-editable mode
  const [formDataInspections, setFormDataInspections] = useState([]);   //form data to display and edit the data in the table in the editable mode

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
  });
  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == project_id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PO_Date: filter[0]?.PODate,
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
      });
      console.log(filter);
      console.log(project_id);
    };
    fetchProject();
  }, [project_id]);

  useEffect(() => {
    //first loading of the page data and states, also used to refresh the page when save, cancel, cross etc. are clicked
    async function fetchDetails() {
      try {
        const resInspectionDetails = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/visual_inspections_details_by_id?Visual_Inspection_ID=${visual_inspection_id}`
        );
        const resInspectionItems = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/visual_inspections_items_by_id?Visual_Inspection_ID=${visual_inspection_id}`
        );

        setInspectionDetails(resInspectionDetails.data.data);
        setInspectionItems(resInspectionItems.data.data);
        setFormDataInspections(resInspectionItems.data.data);

        console.log("resInspectionDetails.data.data", resInspectionDetails.data.data);
        console.log("resInspectionItems.data.data", resInspectionItems.data.data);

      } catch (error) {
        console.error(error);
      }
    }
    fetchDetails();
  }, [refresh]);

  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }

    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const getBackgroundColor = (el) => {
    // Get the Visual_Inspection_ID from the element
    const visualInspectionID = el.Visual_Inspection_ID;

    // Filter inspectionItems to get all items with the same Visual_Inspection_ID
    const itemsWithSameID = inspectionItems.filter(
      (item) => item.Visual_Inspection_ID === visualInspectionID
    );

    // Check the Condition for each item
    const allConditionsMet = itemsWithSameID.every(
      (item) => item.Condition !== null && item.Condition !== '' && !!item.Condition
    );

    // Return "Completed" if all conditions are met, otherwise "Pending"
    return (itemsWithSameID.length !== 0 && allConditionsMet) ? '#077D7D' : '#900909';
  };

  const getTextColor = (el) => {
    return '#FFFFFF';
  };

  const getButtonText = (el) => {
    // Get the Visual_Inspection_ID from the element
    const visualInspectionID = el.Visual_Inspection_ID;

    // Filter inspectionItems to get all items with the same Visual_Inspections_ID
    const itemsWithSameID = inspectionItems.filter(
      (item) => item.Visual_Inspection_ID === visualInspectionID
    );

    // Check the Condition for each item
    const allConditionsMet = itemsWithSameID.every(
      (item) => item.Condition !== null && item.Condition !== '' && !!item.Condition
    );

    // Return "Completed" if all conditions are met, otherwise "Pending"
    return (itemsWithSameID.length !== 0 && allConditionsMet) ? "Completed" : "Pending";
  };

  const handleBackButton = () => {
    navigate(`/reports-workshop/${project_id}`);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      // Remove pictures that need to be removed
      for (const { S_No } of picturesToRemove) {
        await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/visual_inspections_items_details_removefiles/${S_No}`, {
          type: 'Picture',
        });
      }

      // Update text data
      for (const item of formDataInspections) {
        const payload = {
          S_No: item.S_No,
          Visual_Inspection_ID: item.Visual_Inspection_ID,
          Project_ID: item.Project_ID,
          Item_Name: item.Item_Name,
          Part_Number: item.Part_Number,
          Part_Traceability_Number: item.Part_Traceability_Number,
          Equipment_Traceability_Number: item.Equipment_Traceability_Number,
          UoM: item.UoM,
          Qty: item.Qty,
          HS_Code: item.HS_Code,
          Condition: item.Condition,
          Damage: item.Damage,
          Corrosion: item.Corrosion,
          Pitting: item.Pitting,
          Sharp_Edges: item.Sharp_Edges,
          Wear_Marks: item.Wear_Marks,
          Plastic_Deformation: item.Plastic_Deformation,
          Remarks: item.Remarks,
        };

        await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/visual_inspections_items_details_put?S_No=${item.S_No}`, payload);
      }

      // Update pictures
      for (const item of formDataInspections) {
        if (item.Picture && item.Picture instanceof File) {
          const formData = new FormData();
          formData.append('Picture', item.Picture);

          await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/visual_inspections_items_picture_put?S_No=${item.S_No}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
      }

      setRefresh(!refresh); // Trigger refresh
      setIsEditing(false);
      setPicturesToRemove([]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error saving data:', error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setFormDataInspections(JSON.parse(JSON.stringify(inspectionItems))); // Deep copy
    setPicturesToRemove([]);
    setRefresh(!refresh);
  };

  const handleInputChange = (S_No, name, value) => {
    // Deep copy to prevent any irregular duplication of data in inspectionItems
    const updatedFormData = formDataInspections.map((item) =>
      item.S_No === S_No ? { ...item, [name]: value } : item
    );
    setFormDataInspections(updatedFormData);
  };

  const handleFileUpload = (S_No, name, e) => {
    const file = e.target.files[0];
    // Deep copy to prevent any irregular duplication of data in inspectionItems
    const updatedFormData = formDataInspections.map((item) =>
      item.S_No === S_No ? { ...item, [name]: file } : item
    );
    setFormDataInspections(updatedFormData);
  };

  console.log("inspectionItems", inspectionItems);
  console.log("formDataInspections", formDataInspections);

  const handleRemoveFile = (S_No) => {
    const updatedFormData = formDataInspections.map((item) =>
      item.S_No === S_No ? { ...item, Picture: '' } : item
    );
    setFormDataInspections(updatedFormData);
    setPicturesToRemove([...picturesToRemove, { S_No, Picture: formDataInspections.find(item => item.S_No === S_No).Picture }]);
  };

  console.log("picturesToRemove", picturesToRemove);

  const handleDownload = (url) => {
    if (url) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = url.split('/').pop(); // Use the file name from the URL
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error('Error downloading file:', error);
        });
    } else {
      console.log('There is no file to download');
    }
  };

  const getFileName = (picture) => {
    if (typeof picture === 'string') {
      return picture.split('/').pop();
    } else if (picture instanceof File) {
      return picture.name;
    }
    return '-';
  };


  return (
    <div>
      <LoadingScreen isLoading={isLoading} />
      <div className={styles.topButtonContainer}>
        <button
          className={styles.topBackButton}
          onClick={handleBackButton}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
              fill="#5B6BE1"
            />
          </svg>
          {' '}Visual Inspections
        </button>
      </div>

      <div className={styles.topdiv}>
        <div className={styles.topsubdiv}>
          <label className={styles.projectDiv}>
            SO No. : {formdata1 ? formdata1.salesOrderNo : "-"}
          </label>
          <label className={styles.projectDiv}>
            Project Name: {formdata1 ? formdata1.ProjectName : "-"}
          </label>
          <label className={styles.projectDiv}>
            Customer Name: {formdata1 ? formdata1.CustomerName : "-"}
          </label>
          <label className={styles.projectDiv}>
            Created on: {inspectionDetails[0] ? formatDate(inspectionDetails[0].Date) : "-"}
          </label>
          <label className={styles.projectDiv}>
            Created by: {inspectionDetails[0] ? inspectionDetails[0].Created_By : "-"}
          </label>


          <label
            className={styles.deliveryStatusDiv}
            style={{ backgroundColor: inspectionDetails[0] ? getBackgroundColor(inspectionDetails[0]) : "gray", color: "white" }}
          >
            Inspection Status: {inspectionDetails[0] ? getButtonText(inspectionDetails[0]) : "-"}
          </label>
        </div>
      </div>

      <div className={styles.maindiv}>
        {!isEditing ? (
          // Non-editable display of data
          <div className={styles.buttonsdiv}>
            <button onClick={handleEditClick}>
              Edit{" "}
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginLeft: "5px" }}
              >
                <path
                  d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div className={styles.buttonsdiv} >
            <button onClick={handleSaveClick} style={{ marginRight: "10px" }} disabled={isLoading}>
              Save{" "}
            </button>

            <button onClick={handleCancelClick} style={{ background: "white", ":hover": { background: "#F5F5F5", }, border: "1px solid #5B6BE1", color: "#5B6BE1", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
              Cancel{" "}
            </button>
          </div>
        )}

        <div className={styles.smtablecontainer}>
          <table className={styles.smtable}>
            <thead>
              <tr className={styles.headrowsm}>
                <th className={styles.fileUploadheading}>S.No</th>
                <th className={styles.fileUploadheading}>Item Name</th>
                <th className={styles.fileUploadheading}>Part Number</th>
                <th className={styles.fileUploadheading}>Part Traceability Number</th>
                <th className={styles.fileUploadheading}>Equipment Traceability Number</th>
                <th className={styles.fileUploadheading}>UoM</th>
                <th className={styles.fileUploadheading}>Qty</th>
                <th className={styles.fileUploadheading}>HS Code</th>
                <th className={styles.fileUploadheading}>Upload Picture</th>
                <th className={styles.fileUploadheading}>Condition</th>
                <th className={styles.fileUploadheading}>Damage</th>
                <th className={styles.fileUploadheading}>Corrosion</th>
                <th className={styles.fileUploadheading}>Pitting</th>
                <th className={styles.fileUploadheading}>Sharp Edges</th>
                <th className={styles.fileUploadheading}>Wear Marks</th>
                <th className={styles.fileUploadheading}>Plastic Deformation</th>
                <th className={styles.fileUploadheading}>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {formDataInspections.map((item, index) => (
                <tr key={item.S_no}>
                  <td>{index + 1}</td>
                  <td>{item.Item_Name}</td>
                  <td>{item.Part_Number}</td>
                  <td>{item.Part_Traceability_Number}</td>
                  <td>{item.Equipment_Traceability_Number}</td>
                  <td>{item.UoM}</td>
                  <td>{item.Qty}</td>
                  <td>{item.HS_Code}</td>
                  <td>
                    {!isEditing ? (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', minWidth: '100px' }}>
                        <div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {getFileName(item.Picture)}
                        </div>
                        {item.Picture && (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={styles.svgunshrinkable}
                              style={{ marginLeft: "10px", marginRight: "8px", cursor: "pointer" }}
                              onClick={() => handleDownload(item.Picture)}
                            >
                              <path
                                d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                                fill="#292D32"
                              />
                            </svg>
                            <a href={item.Picture} target="_blank" rel="noopener noreferrer">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                                  fill="black"
                                />
                                <path
                                  d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                                  fill="black"
                                />
                              </svg>
                            </a>
                          </>
                        )}
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', minWidth: '200px' }}>
                        <TextField
                          sx={{ input: { cursor: 'pointer', paddingRight: "30px" } }}
                          value={item.Picture ? getFileName(item.Picture) : '-'}
                          onClick={(e) => {
                            e.target.value = null;
                            document.getElementById(`file-input-${item.S_No}`).click();
                          }}
                          InputProps={{ readOnly: true }}
                        />
                        <input
                          type="file"
                          id={`file-input-${item.S_No}`}
                          style={{ display: 'none', cursor: "pointer" }}
                          onChange={(e) => handleFileUpload(item.S_No, 'Picture', e)}
                        />
                        <svg
                          width="40"
                          height="28"
                          viewBox="0 0 28 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginTop: "0.5%",
                            marginLeft: "-25px",
                            pointerEvents: "none",
                          }}
                        >
                          <path
                            d="M17.083 8.49175H14.6747C12.6997 8.49175 11.0913 6.88341 11.0913 4.90841V2.50008C11.0913 2.04175 10.7163 1.66675 10.258 1.66675H6.72467C4.15801 1.66675 2.08301 3.33341 2.08301 6.30841V13.6917C2.08301 16.6667 4.15801 18.3334 6.72467 18.3334H13.2747C15.8413 18.3334 17.9163 16.6667 17.9163 13.6917V9.32508C17.9163 8.86675 17.5413 8.49175 17.083 8.49175ZM9.60801 11.2751C9.48301 11.4001 9.32467 11.4584 9.16634 11.4584C9.00801 11.4584 8.84968 11.4001 8.72468 11.2751L8.12467 10.6751V14.1667C8.12467 14.5084 7.84134 14.7917 7.49967 14.7917C7.15801 14.7917 6.87467 14.5084 6.87467 14.1667V10.6751L6.27467 11.2751C6.03301 11.5167 5.63301 11.5167 5.39134 11.2751C5.14967 11.0334 5.14967 10.6334 5.39134 10.3917L7.05801 8.72508C7.11634 8.67508 7.17467 8.63341 7.24134 8.60008C7.25801 8.59175 7.28301 8.58341 7.29967 8.57508C7.34967 8.55841 7.39967 8.55008 7.45801 8.54175C7.48301 8.54175 7.49967 8.54175 7.52467 8.54175C7.59134 8.54175 7.65801 8.55841 7.72468 8.58341C7.73301 8.58341 7.73301 8.58341 7.74134 8.58341C7.80801 8.60841 7.87467 8.65841 7.92467 8.70841C7.93301 8.71675 7.94134 8.71675 7.94134 8.72508L9.60801 10.3917C9.84967 10.6334 9.84967 11.0334 9.60801 11.2751Z"
                            fill="gray"
                          />
                          <path
                            d="M14.5251 7.34158C15.3167 7.34991 16.4167 7.34991 17.3584 7.34991C17.8334 7.34991 18.0834 6.79158 17.7501 6.45825C16.5501 5.24991 14.4001 3.07491 13.1667 1.84158C12.8251 1.49991 12.2334 1.73325 12.2334 2.20825V5.11658C12.2334 6.33325 13.2667 7.34158 14.5251 7.34158Z"
                            fill="gray"
                          />
                        </svg>
                        <IconButton
                          onClick={() => handleRemoveFile(item.S_No)}
                          aria-label="remove file"
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Select
                        value={item.Condition || ''}
                        onChange={(e) => handleInputChange(item.S_No, 'Condition', e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return <label>Select Option</label>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value=""><em>Select Option</em></MenuItem>
                        <MenuItem value="Very Poor">Very Poor</MenuItem>
                        <MenuItem value="Poor">Poor</MenuItem>
                        <MenuItem value="Fair">Fair</MenuItem>
                        <MenuItem value="Good">Good</MenuItem>
                        <MenuItem value="New">New</MenuItem>
                      </Select>
                    ) : (
                      item.Condition || '-'
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Select
                        value={item.Damage || ''}
                        onChange={(e) => handleInputChange(item.S_No, 'Damage', e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return <label>Select Option</label>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value=""><em>Select Option</em></MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Slight">Slight</MenuItem>
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Severe">Severe</MenuItem>
                      </Select>
                    ) : (
                      item.Damage || '-'
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Select
                        value={item.Corrosion || ''}
                        onChange={(e) => handleInputChange(item.S_No, 'Corrosion', e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return <label>Select Option</label>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value=""><em>Select Option</em></MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Slight">Slight</MenuItem>
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Severe">Severe</MenuItem>
                      </Select>
                    ) : (
                      item.Corrosion || '-'
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Select
                        value={item.Pitting || ''}
                        onChange={(e) => handleInputChange(item.S_No, 'Pitting', e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return <label>Select Option</label>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value=""><em>Select Option</em></MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Slight">Slight</MenuItem>
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Severe">Severe</MenuItem>
                      </Select>
                    ) : (
                      item.Pitting || '-'
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Select
                        value={item.Sharp_Edges || ''}
                        onChange={(e) => handleInputChange(item.S_No, 'Sharp_Edges', e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return <label>Select Option</label>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value=""><em>Select Option</em></MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Slight">Slight</MenuItem>
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Severe">Severe</MenuItem>
                      </Select>
                    ) : (
                      item.Sharp_Edges || '-'
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Select
                        value={item.Wear_Marks || ''}
                        onChange={(e) => handleInputChange(item.S_No, 'Wear_Marks', e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return <label>Select Option</label>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value=""><em>Select Option</em></MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Slight">Slight</MenuItem>
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Severe">Severe</MenuItem>
                      </Select>
                    ) : (
                      item.Wear_Marks || '-'
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Select
                        value={item.Plastic_Deformation || ''}
                        onChange={(e) => handleInputChange(item.S_No, 'Plastic_Deformation', e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return <label>Select Option</label>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value=""><em>Select Option</em></MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Slight">Slight</MenuItem>
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Severe">Severe</MenuItem>
                      </Select>
                    ) : (
                      item.Plastic_Deformation || '-'
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <TextField
                        style={{ minWidth: "200px" }}
                        value={item.Remarks || ''}
                        onChange={(e) => handleInputChange(item.S_No, 'Remarks', e.target.value)}
                      />
                    ) : (
                      item.Remarks || '-'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  )
}

export default VisualInspectionsUpdate
