import React from "react";
import { Link } from "react-router-dom";
import styles from "./css/ergonpropertylogs.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from 'xlsx';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function ErgonPropertylogs() {
  const [searchQuery, setSearchQuery] = useState("");
  const { id } = useParams();
  const { part_number } = useParams();
  const navigate = useNavigate();
  const [itemsReceived, setItemsReceived] = useState([]);
  const [itemdata, setitemdata] = useState([]);

  const [partNumberToItemIdMap, setPartNumberToItemIdMap] = useState({});
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const groupedItems = itemsReceived.reduce((acc, item) => {
    const key = `${item.Item_Name}|${item.OEM_Part_Number}|${item.Manufacturer_Part_Number}`;
    const quantityReceived = parseFloat(item.Scheduled_Quantity); // Convert quantity to number

    if (!acc[key]) {
      acc[key] = { ...item, Quantity_Total: quantityReceived };
    } else {
      acc[key].Quantity_Total += quantityReceived;
    }

    return acc;
  }, {});

  // Convert the grouped object to an array of items
  const result = Object.values(groupedItems);

  // Filtering logic
  const filteredItems = Object.values(groupedItems).filter((item) => {
    const queryParts = searchQuery.trim().toLowerCase().split(' ');
    // Get the Item_Id from the partNumberToItemIdMap
    const itemId = partNumberToItemIdMap[`${item.Item_Name}|${item.OEM_Part_Number ? item.OEM_Part_Number : ''}|${item.Manufacturer_Part_Number ? item.Manufacturer_Part_Number : 0}`];
    return queryParts.every(part =>
      item.Item_Name?.toLowerCase().includes(part) ||
      item.OEM_Part_Number?.toLowerCase().includes(part) ||
      item.Manufacturer_Part_Number?.toLowerCase().includes(part) ||
      (itemId && itemId.toString().toLowerCase().includes(part))
    );
  });

  useEffect(() => {
    async function fetchDetails() {
      try {
        const [res1, resi] = await Promise.all([
          axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get4"),
          axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster2"),
        ]);

        const itemsReceivedData = res1.data.data;
        const itemData = resi.data;

        const newPartNumberToItemIdMap = {};
        itemData.forEach((item) => {
          const key = `${item.Item_Name}|${item.Part_Number ? item.Part_Number : ''}|${item.Manufacturer_Part_Number ? item.Manufacturer_Part_Number : 0}`;
          newPartNumberToItemIdMap[key] = item.Item_Id;
        });

        setItemsReceived(itemsReceivedData);
        setPartNumberToItemIdMap(newPartNumberToItemIdMap);

        // Example of how to safely log the item ID for "Grease" with a known part number.
        // You need to replace 'knownPartNumber' with the actual part number of "Grease".
        const greaseKey = `Grease|null`; // Make sure 'knownPartNumber' is defined correctly.

      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }

    fetchDetails();
  }, []);

  const [data, setData] = useState([]);

  const handleDownloadExcel = () => {
    // Building the array of items exactly as they appear in the jsx table
    const displayedItems = filteredItems
      .filter(item => (item.Scheduled_Sum + item.Qty_Delta_Sum) !== 0)
      .map(item => {
        const totalSumItem = data.find(
          dataItem =>
            dataItem.Item_Name === item.Item_Name &&
            dataItem.OEM_Part_Number === item.OEM_Part_Number &&
            dataItem.Manufacturer_Part_Number === item.Manufacturer_Part_Number
        );
        if (totalSumItem) {
          return { ...totalSumItem, UoM: item.UoM };
        }
        return undefined;
      })
      .filter(totalSumItem => totalSumItem !== undefined);

    const headerRow = [
      { v: 'S. No', s: { bold: true } },
      { v: 'Item Name', s: { bold: true } },
      { v: 'Part Number', s: { bold: true } },
      { v: 'Manufacturer Part Number', s: { bold: true } },
      { v: 'Available Quantity', s: { bold: true } },
      { v: 'UOM', s: { bold: true } },
      { v: 'Item Id', s: { bold: true } },
    ];

    const dataRows = displayedItems.map((item, index) => {
      const itemId = partNumberToItemIdMap[
        `${item.Item_Name}|${item.OEM_Part_Number ? item.OEM_Part_Number : ''}|${item.Manufacturer_Part_Number ? item.Manufacturer_Part_Number : 0}`
      ];
      return [
        index + 1,
        item.Item_Name,
        item.OEM_Part_Number,
        item.Manufacturer_Part_Number,
        item.Final_Total,
        item.UoM,
        itemId,
      ];
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);

    worksheet['!cols'] = [
      { wch: 6 },  // S. No
      { wch: 45 }, // Item Name
      { wch: 25 }, // Part Number
      { wch: 35 }, // Manufacturer Part Number
      { wch: 15 }, // Available Quantity
      { wch: 10 }, // UOM
      { wch: 10 }, // Item Id
    ];

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Items');

    XLSX.writeFile(workbook, 'Ergon_Inventory.xlsx');
  };


  useEffect(() => {
    const fetchDatain = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_summary"
        ); // Assuming this is the endpoint you've set up

        // Filter out items where Scheduled_Sum + Qty_Delta_Sum - Qty_Rejected_Sum == 0
        const filteredData = response.data.filter(item => {
          return (item.Scheduled_Sum + item.Qty_Delta_Sum) !== 0;
        });

        setData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDatain(); // Call the function to fetch data when the component mounts
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  return (
    <div className={styles.topdiv}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.headingdiv}>
          <h1 className={styles.heading}>Ergon Inventory </h1>
        </div>
        <div className={styles.downloadButton}>
          <svg
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={handleDownloadExcel}
          >
            <path
              d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
              fill="#5B6BE1"
            />
          </svg>
        </div>
      </div>
      <div className={styles.sections}>
        <div>
          <div className={styles.searchContainer}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Search Item Name, Part Number, Item ID..."
              value={searchQuery}
              onChange={handleSearchChange}
            />

            <div className={styles.buttonContainer}>
              <button className={styles.searchButton} disabled>
                <svg
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  style={{ paddingBottom: "2px", display: "flex", justifyContent: "center" }}
                  viewBox="0 0 22 22"
                  id="search"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M15.5 14h-.79l-.28-.27c1.2-1.4 1.82-3.31 1.48-5.34-.47-2.78-2.79-5-5.59-5.34-4.23-.52-7.79 3.04-7.27 7.27.34 2.8 2.56 5.12 5.34 5.59 2.03.34 3.94-.28 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* ////table//// */}

        <div className={styles.itemstablecontainer}>
          <table className={styles.itemstable}>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Item Name</th>
                <th>Part Number</th>
                <th>Manufacturer Part Number</th>
                <th>Available Quantity</th>
                <th>UOM</th>
                <th>Item Id</th>

              </tr>
            </thead>
            <tbody>
              {filteredItems.length === 0 && (
                <tr>
                  <td colSpan="7">
                    <div className={styles.noResults}>No Results Found</div>
                  </td>
                </tr>
              )}
              {filteredItems
                // Filter items where the sum of Scheduled_Sum and Qty_Delta_Sum is not zero
                .filter(item => (item.Scheduled_Sum + item.Qty_Delta_Sum) !== 0)
                // Map through filtered items
                .map(item => {
                  // Find the corresponding item in the data array
                  const totalSumItem = data.find(
                    dataItem =>
                      dataItem.Item_Name === item.Item_Name &&
                      dataItem.OEM_Part_Number === item.OEM_Part_Number && dataItem.Manufacturer_Part_Number === item.Manufacturer_Part_Number
                  );
                  // Return the found item or undefined if not found
                  // Merge the UoM from the original item if totalSumItem is found
                  if (totalSumItem) {
                    return { ...totalSumItem, UoM: item.UoM };
                  } else {
                    return undefined;
                  }
                })
                // Filter out undefined items
                .filter(totalSumItem => totalSumItem !== undefined)
                // Return a table row for each item
                .map((totalSumItem, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ maxWidth: "44vw" }}>
                      {(() => {
                        // Encode the item name and OEM part number
                        const encodedItemName = encodeURIComponent((totalSumItem.Item_Name).replace(/\&/g, '%26').replace(/\+/g, '%2B'));
                        const encodedOEMPartNumber = encodeURIComponent(totalSumItem.OEM_Part_Number ? totalSumItem.OEM_Part_Number : null);
                        const encodedManufacturerPartNumber = encodeURIComponent(totalSumItem.Manufacturer_Part_Number ? totalSumItem.Manufacturer_Part_Number : null);
                        // Return a link to the item details
                        return (
                          <Link
                            to={`/ergoninventorylogsdetails/${partNumberToItemIdMap[`${totalSumItem.Item_Name}|${totalSumItem.OEM_Part_Number ? totalSumItem.OEM_Part_Number : ''}|${totalSumItem.Manufacturer_Part_Number ? totalSumItem.Manufacturer_Part_Number : 0}`]}/${encodedOEMPartNumber}/${encodedItemName}/${encodedManufacturerPartNumber}`}
                          >
                            {totalSumItem.Item_Name}
                          </Link>
                        );
                      })()}
                    </td>
                    <td>{totalSumItem.OEM_Part_Number}</td>
                    <td>{totalSumItem.Manufacturer_Part_Number}</td>
                    <td>{totalSumItem ? totalSumItem.Final_Total : "0"}</td>
                    <td>{totalSumItem.UoM}</td>
                    <td>{partNumberToItemIdMap[`${totalSumItem.Item_Name}|${totalSumItem.OEM_Part_Number ? totalSumItem.OEM_Part_Number : ''}|${totalSumItem.Manufacturer_Part_Number ? totalSumItem.Manufacturer_Part_Number : 0}`]}</td>
                    {/* <td>{partNumberToItemIdMap[`${totalSumItem.Item_Name}|${totalSumItem.OEM_Part_Number}|${totalSumItem.Manufacturer_Part_Number}`]}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default ErgonPropertylogs;
