import React from "react";
import styles from "./css/inspections.module.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, NavLink } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
function Inspections() {
  const { id } = useParams();
  const [workshop, setWorkshop] = useState(8);
  const [projectList, setProjectList] = useState({});
  const [isIncoming, setIsIncoming] = useState(true);
  const [indetails, setindetails] = useState([]);
  const [indetails1, setindetails1] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(false);
  const [projectidd, setprojectidd] = useState("");
  const [featurewsInspections, setfeaturewsInspections] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    async function fetchsmDetails() {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_details?ID_No=${id}`
        );
        setindetails(response.data.data);
        setprojectidd(response.data.data[0].ID_No);
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchsmDetails();
  }, [refresh]);

  console.log("setprojectidd", projectidd)
  useEffect(() => {
    async function fetchsm1Details() {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incominginspection_detail?project_id=${projectidd}`
        );
        setindetails1(response.data.data);
        console.log("Hello");
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchsm1Details();
  }, [projectidd]);

  const checkEntryCompleteness = (desired_delivery_id) => {
    console.log(indetails1);
    let v = indetails1?.some(entry =>
      entry.incoming_Delivery_id === desired_delivery_id &&
      entry.s_no !== null &&
      entry.Project_Id !== null &&
      entry.Item_Name !== null &&
      // entry.Part_Number !== null &&
      // entry.Traceability_number !==null &&
      // entry.UOM !== null &&
      entry.Quantity !== null
      // entry.Picture !== null &&
      // entry.Condition_Upon_Reciept !== null &&
      // entry.Remarks !== null
    );
    console.log("I am here");
    console.log(desired_delivery_id, v);

    return v;
  };

  const handleDivClick = () => {
    navigate(`/project-checklist-workshop/${id}`);
  };

  const handleDivClick1 = () => {
    navigate(`/project-details-workshop/${id}`);
  };
  const handleDivClick2 = () => {
    navigate(`/timesheet-workshop/${id}`);
  };

  const handleDivClick3 = () => {
    navigate(`/incoming-deliveries-workshop/${id}`);
  };

  const handleDivClick4 = () => {
    navigate(`/outgoing-deliveries-workshop/${id}`);
  };

  const handleDivClick5 = () => {
    navigate(`/outsourcing-workshop/${id}`);
  };

  const handleDivClick6 = () => {
    navigate(`/reports-workshop/${id}`);
  };

  const handleDivClick7 = () => {
    navigate(`/inspections-workshop/${id}`);
  };
  const handleDivClick8 = () => {
    navigate(`/items-received-workshop/${id}`);
  };
  const handleDivClick9 = () => {
    navigate(`/finished-goods-workshop/${id}`);
  };
  const handleDivClick10 = () => {
    navigate(`/team-details-workshop/${id}`);
  };
  const handleDivClick12 = () => {
    navigate(`/inventory-used-workshop/${id}`);
  };
  const handleDivClick13 = () => {
    navigate(`/purchases-workshop/${id}`);
  };
  const handleDivClick14 = () => {
    navigate(`/project-timeline-workshop/${id}`);
  }
  const handleDivClick15 = () => {
    navigate(`/production-workshop/${id}`)
  }
  const handleDivClick16 = () => {
    navigate(`/Wip-workshop/${id}`);
  };
  const handleDivClick23 = () => {
    navigate(`/item-details-workshop/${id}`);
  };
  const getBackgroundColor = (sno) => {
    const isEntryComplete = checkEntryCompleteness(sno);
    return isEntryComplete ? '#077D7D' : '#900909';
  };

  // const getTextColor = (el) => {
  //     return '#FFFFFF' ;
  // };

  const getButtonText = (sno) => {
    const isEntryComplete = checkEntryCompleteness(sno);
    return isEntryComplete ? 'Completed' : 'Pending';
  };

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
  });
  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PO_Date: filter[0]?.PODate,
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
      });
      //console.log(filter);
      //console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  const handleIncomingClick = () => {
    setIsIncoming(true);
  };

  const handleOutgoingClick = () => {
    setIsIncoming(false);
  };

  const handleinspectionDivClick = (S_No) => {
    navigate(`/incoming-inspections-workshop/${S_No}`);
  }


  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  // useEffect(() => {
  //   async function fetchsmDetails() {
  //     try {
  //       const response = await axios.get(
  //         `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_details?project_id=${id}`
  //       );
  //       setindetails(response.data.data);
  //       console.log(response.data.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   fetchsmDetails();
  // }, [refresh]);

  // // useEffect(() => {
  // //   async function fetchsm1Details() {
  // //     try {
  // //       const response = await axios.get(
  // //         `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incominginspection_details?project_id=${id}`
  // //       );
  // //       setindetails1(response.data.data);
  // //       console.log(response.data.data);
  // //     } catch (error) {
  // //       console.error(error);
  // //     }
  // //   }

  //   fetchsm1Details();
  // }, [refresh]);


  useEffect(() => {
    async function fetchRigDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
        );
  
        const group = localStorage.getItem("Group");
        const roles = JSON.parse(localStorage.getItem("Roles")) || []; // Retrieve roles from localStorage
  
        // Group feature filter for Inspections
        const workshopInspectionsGroup = response.data.groupFeatures.filter((el) => {
          return (
            el.Group_Name === group &&
            el.Header_Name === "Workshop Projects" &&
            el.Feature_Name === "Workshop Inspections" &&
            (el.Access === "Can View" || el.Access === "Can Edit")
          );
        });
  
        // Role feature filter for Inspections
        const workshopInspectionsRole = response.data.roleFeatures.filter((el) => {
          return (
            roles.includes(el.Role_Name) &&
            el.Header === "Workshop Projects" &&
            el.Feature === "Workshop Inspections" &&
            el.Access === "Can View"
          );
        });
  
        // Combine group and role features for Inspections
        const combinedWorkshopInspections = [
          ...workshopInspectionsGroup,
          ...workshopInspectionsRole,
        ];
  
        // Prioritize "Can View" over "Can Edit"
        const prioritizeAccess = (features) => {
          const featureMap = {};
          features.forEach((feature) => {
            const key = `${feature.Header_Name || feature.Header}-${feature.Feature_Name || feature.Feature}`;
            if (!featureMap[key] || feature.Access === "Can View") {
              featureMap[key] = feature;
            }
          });
          return Object.values(featureMap);
        };
  
        // Set features with prioritized access
        setfeaturewsInspections(prioritizeAccess(combinedWorkshopInspections));
  
      } catch (error) {
        console.error(error);
      }
    }
    fetchRigDetails();
  }, []);


  return (
    featurewsInspections.length > 0 ? (
      <div>
        <div className={styles.topdiv}>
          <label className={styles.firstlabel}>
            Project Name: {formdata1.ProjectName}
          </label>
          <label className={styles.firstlabel}>Project Scope:{formdata1.Scope}</label>
          <label className={styles.firstlabel}>
            Project Description:{formdata1.projectDescription}
          </label>
          <label className={styles.firstlabel}>Quantity:{formdata1.quantity}</label>
          <label className={styles.firstlabel}>
            Target Days:{formdata1.TargetDaysForCompletion}
          </label>
          <label className={styles.firstlabel}>
            Target Manhours:{formdata1.TargetManhours}
          </label>
        </div>
        <div className={styles.tabarray}>
          <div
            className={workshop === 1 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick}>
            Project Checklist
          </div>
          <div
            className={workshop === 2 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick1}>
            Project Details
          </div>
          <div
            className={workshop === 10 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick10}
          >
            Team Details
          </div>
          <div
            className={workshop === 12 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick12}>
            Inventory Used
          </div>
          <div
            className={workshop === 13 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick13}>
            Purchases
          </div>
          <div
            className={workshop === 3 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick2}>
            Timesheet
          </div>
          <div
            className={workshop === 4 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick3}>
            Deliveries
          </div>
          <div
            className={workshop === 23 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick23}>
             Serial Number
          </div>
          <div
            className={workshop === 15 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick15}>
            Production
          </div>
          <div
            className={workshop === 14 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick14}>
            Timeline
          </div>

          <div
            className={workshop === 6 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick5}>
            Outsourcing
          </div>
          <div
            className={workshop === 7 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick6}>
            Reports
          </div>
          <div
            className={workshop === 8 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick7}>
            Inspections
          </div>
          <div
            className={workshop === 9 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick8}>
            Items Received
          </div>
          <div
            className={workshop === 10 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick9}>
            Finished goods
          </div>
          <div
            className={workshop === 16 ? styles.tabs1 : styles.tabs2}
            onClick={handleDivClick16}
          >
            WIP
          </div>
        </div>
        {/* <div> */}

        {/* <button className={styles.createnewbutton} style={{marginLeft: "80%", marginTop: "30px", width: "280px"}}>Create New Incoming Inspection</button>
        <div>
          <table className={styles.dailtracwrkshp} style={{ marginTop: "20px", marginLeft: "40px"}}>
            <thead>
              <tr>
                <th>S.No</th>
              </tr>
            </thead>
          </table>
        </div> */}
        {/* <div className={styles.maindiv}>
          {Array.isArray(indetails) && indetails.map((el, index) => (
          <div key= {index} className={styles.projectlists}>
            <label className={styles.label}>Arrival:  {el.Expected_Arrival_Date}  |  </label>
            <label className={styles.label}>Dispatching Party:  {el.Dispatching_Party}  |  </label>
            <label className={styles.label}>Bill of Entry:  {el.Entry_Bill_No}</label>
            <NavLink to={`/incoming_inspections/${el.S_No}`}>View Details</NavLink>
          </div>
          ))}
        </div>
      </div> */}
        {isIncoming ? (
          <div className={styles.maindiv}>
            <div className={styles.buttonContainer}>
              <button
                className={`${styles['incomingButton']} ${isIncoming ? styles['incoming'] : styles['not-incoming']}`}
                onClick={handleIncomingClick}
              >
                Incoming
              </button>
              <button
                className={`${styles['outgoingButton']} ${!isIncoming ? styles['outgoing'] : styles['not-outgoing']}`}
                onClick={handleOutgoingClick}
              >
                Outgoing
              </button>

              {/* <button 
                variant="contained" 
                className={styles["btn-Createnew"]}
                onClick={handleCreateNewClick}
              >
                <svg 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 28" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 20C10.5 20.3978 10.658 20.7794 10.9393 21.0607C11.2206 21.342 11.6022 21.5 12 21.5C12.3978 21.5 12.7794 21.342 13.0607 21.0607C13.342 20.7794 13.5 20.3978 13.5 20V13.5H20C20.3978 13.5 20.7794 13.342 21.0607 13.0607C21.342 12.7794 21.5 12.3978 21.5 12C21.5 11.6022 21.342 11.2206 21.0607 10.9393C20.7794 10.658 20.3978 10.5 20 10.5H13.5V4C13.5 3.60218 13.342 3.22064 13.0607 2.93934C12.7794 2.65804 12.3978 2.5 12 2.5C11.6022 2.5 11.2206 2.65804 10.9393 2.93934C10.658 3.22064 10.5 3.60218 10.5 4V10.5H4C3.60218 10.5 3.22064 10.658 2.93934 10.9393C2.65804 11.2206 2.5 11.6022 2.5 12C2.5 12.3978 2.65804 12.7794 2.93934 13.0607C3.22064 13.342 3.60218 13.5 4 13.5H10.5V20Z" fill="white"/>
                  <defs>
                    <rect
                    width="20" 
                    height="20" 
                    fill="white"/>
                  </defs>
                </svg>
                {' '}Create New Incoming Delivery
              </button> */}
            </div>

            {indetails.sort((a, b) => b.S_No - a.S_No).map((el, index) => (
              <div key={index} className={styles.projectList}
                onClick={() => handleinspectionDivClick(el.S_No)}>
                <div className={styles.textContainer}>
                  {`Arrival: ${el.Expected_Arrival_Date || '-'} \u00a0|\u00a0
                    Dispatching Party: ${el.Dispatching_Party || '-'} \u00a0|\u00a0
                    Incoming Delivery Id: ${el.S_No || '-'}\u00a0|\u00a0
                    Incoming Inspection Id: ${el.Incoming_Inspection_Id || '-'}`}
                </div>

                <button
                  className={styles.deliveryStatusButton}
                  style={{ backgroundColor: getBackgroundColor(el.S_No), color: '#FFFFFF' }}>
                  {getButtonText(el.S_No)}
                </button>

              </div>

            ))}

          </div>
        ) : (
          <div className={styles.maindiv}>
            <div className={styles.buttonContainer}>
              <button
                className={`${styles['incomingButton']} ${isIncoming ? styles['incoming'] : styles['not-incoming']}`}
                onClick={handleIncomingClick}
              >
                Incoming
              </button>
              <button
                className={`${styles['outgoingButton']} ${!isIncoming ? styles['outgoing'] : styles['not-outgoing']}`}
                onClick={handleOutgoingClick}
              >
                Outgoing
              </button>

              {/* <button 
                variant="contained" 
                className={styles["btn-Createnew"]}
                // onClick={handleCreateNewClick}
              >
                <svg 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 28" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 20C10.5 20.3978 10.658 20.7794 10.9393 21.0607C11.2206 21.342 11.6022 21.5 12 21.5C12.3978 21.5 12.7794 21.342 13.0607 21.0607C13.342 20.7794 13.5 20.3978 13.5 20V13.5H20C20.3978 13.5 20.7794 13.342 21.0607 13.0607C21.342 12.7794 21.5 12.3978 21.5 12C21.5 11.6022 21.342 11.2206 21.0607 10.9393C20.7794 10.658 20.3978 10.5 20 10.5H13.5V4C13.5 3.60218 13.342 3.22064 13.0607 2.93934C12.7794 2.65804 12.3978 2.5 12 2.5C11.6022 2.5 11.2206 2.65804 10.9393 2.93934C10.658 3.22064 10.5 3.60218 10.5 4V10.5H4C3.60218 10.5 3.22064 10.658 2.93934 10.9393C2.65804 11.2206 2.5 11.6022 2.5 12C2.5 12.3978 2.65804 12.7794 2.93934 13.0607C3.22064 13.342 3.60218 13.5 4 13.5H10.5V20Z" fill="white"/>
                  <defs>
                    <rect
                    width="20" 
                    height="20" 
                    fill="white"/>
                  </defs>
                </svg>
                {' '}Create New Outgoing Delivery
              </button> */}
            </div>
          </div>
        )}

      </div>
    ) : (
      <div style={{ marginLeft: "30%", marginTop: "15%" }}>
        <h3>
          You do not have permissions to view this page.
        </h3>
      </div>
    )
  );
}
export default Inspections;