import React from "react";
import styles from "./css/sparestradingcreatenew.module.css";
import { useEffect, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import UploadDocument from "./Uploaddocument";
import axios from "axios";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import BasicModal from "./Modal";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function SparestradingWorkshopCreatenew() {
  const [formdata1Display1, setFormData1Display1] = useState(true);
  const [isRigNameValid, setIsRigNameValid] = useState(true);
  const [open, setOpen] = React.useState(false);

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",
    PONO: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    Scope: "Spares trading",
    EstimatedDateDispatch: "",
    CustomerName: "",
    opsEngineer: "",
    currentStatus: "",
    scopeDescription:"",
  });

    //Start of Hooks and functions for Estimated Date of Dispatch edit

    const [dispatchDateFormData, setDispatchDateFormData] = useState({
      Previous_Dispatch_Date: "",
      Done_By: "",
      Reason_For_Change: "Order Details Added",
    });
    console.log("dispatchDateFormData", dispatchDateFormData);
  
    const userEmail = localStorage.getItem('useremail');
    if (userEmail) {
      console.log('Found value:', userEmail);
    } else {
      console.log('No value found');
    }
      
    useEffect(() => {
      const fetchLoginDetails = async() => {
        const userRes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_user_details_project_details?Email=${userEmail}`
        );
        setDispatchDateFormData(prevState => ({
          ...prevState,
          Done_By: userRes.data.data[0].Name,
        }));
      };
  
      fetchLoginDetails();
    }, []);
    //End of Hooks and functions for Estimated Date of Commencement edit flow and modals  

  const [selectedFile, setSelectedFile] = useState({
    workPlan: null,
  });
  const [selectedFileName, setSelectedFileName] = useState({
    workplan: "",
  });

  const handleNumeriChange = (e) => {
    let { name, value } = e.target;

    // value = value.replace(/[^0-9]/g, "");
    // if (value.length <= 3 && !isNaN(value)) {
    setFormData1({
      ...formdata1,
      [name]: value,
    });
    // }
  };
  let projectId;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formdata1, [name]: value });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDivClick = () => {
    navigate("/workshop-spares-trading");
  }

  const navigate = useNavigate();

  const handleCreateNewProject = async (e) => {
    e.preventDefault();
    const {
      salesOrderNo,
      PONO,
      PODate,
      QuoteNO,
      QuoteDate,
      CostingID,
      Scope,
      CustomerName,
      EstimatedDateDispatch,
      opsEngineer,
      currentStatus,
      scopeDescription,
   } = formdata1;

    // Check if EstimatedDateDispatch and currentStatus are missing or empty
    if (!EstimatedDateDispatch) {
      alert('Please enter "Estimated Date of Dispatch"');
      return; // Stop execution if the field is empty
    }

    if (!currentStatus) {
      alert('Please enter "Current Status"');
      return; // Stop execution if the field is empty
    }

    const payload = {
       Sales_Order_No: salesOrderNo,
      PO_No: PONO,
      PO_Date: PODate,
      Quote_No: QuoteNO,
      Quote_Date: QuoteDate,
      Costing_ID: CostingID,
      Scope: Scope,
      Customer_Name: CustomerName,
      Estimated_Date_Of_Dispatch: EstimatedDateDispatch,
      Ops_Engineer: opsEngineer,
      Current_Status: currentStatus,
     Scope_Description: scopeDescription,
    

    };
    try {
      const res = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_post",
        payload
      );
      
      const projId = res.data.data.insertId;
      // console.log("res",res);
      // console.log("projId",res.data.data);
      // console.log("ID", res.data.data.insertId);
      const checklistPayload = {
        Sales_Order_No: salesOrderNo,
        ID_No: projId,
      };
      
      const forChecklist = await axios.post(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_checklist",
        checklistPayload
      );

      // ************Start of Dispatch Date log post******************
      let { Previous_Dispatch_Date, Done_By, Reason_For_Change } = dispatchDateFormData;
      let New_Dispatch_Date = EstimatedDateDispatch;
      const Timestamp = new Date();  // Current date and time in ISO format
      const Order_ID = projId;
      // Convert empty string to null, to prevent empty string post into the database
      if (!Previous_Dispatch_Date) {
        Previous_Dispatch_Date = null;
      }
      if (!New_Dispatch_Date) {
        New_Dispatch_Date = null;
      }

      // Construct the payload
      const payloadDispatchDate = {
        Order_ID,
        Previous_Dispatch_Date,
        New_Dispatch_Date,
        Done_By,
        Reason_For_Change,
        Timestamp
      };
      console.log("payloadDispatchDate", payloadDispatchDate);

      let dispatchRes = null;

      if (New_Dispatch_Date !== Previous_Dispatch_Date) {
        dispatchRes = await axios.post('http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/spares_trading_dispatch_date_change_log', payloadDispatchDate);
        console.log(dispatchRes.data);
      }
      // ************End of Commencement Date log post******************

      if (res.status === 200 && forChecklist.status === 200 && (dispatchRes === null || dispatchRes.status === 200)) {
        alert("project created successfully");
        navigate(`/workshop-spares-trading?id=${projectId}`);
      }
      else {
        // Handle failure in case any of the requests failed
        console.log("Error while creating project. Server Responses:", res, forChecklist, dispatchRes);
        navigate(`/workshop-spares-trading?id=${projectId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };



  // date changes
  const handleDates = (name, value) => {
    if (value !== null && value !== undefined) {
      const selectedDate = value.format("YYYY-MM-DD");
      setFormData1({ ...formdata1, [name]: selectedDate });
      // setFormData3Tool1({ ...formdata3Tool1, [name]: selectedDate });
      // setFormData3Tool2({ ...formdata3Tool2, [name]: selectedDate });
      console.log(name, selectedDate);
    } else {
      console.log("Invalid Date");
    }
  };

  // mui styling for date picker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "230px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));

  return (
    <>
      {formdata1Display1 && (
        <div className={styles.sectionscreate}>
          <form>
            <div className={styles.splitHalfSection}>
              <div>
                <div className={styles.formElement}>
                  <label>Sales Order No</label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <span className={styles.salesOrderSpan}></span> */}
                    <input
                      type="text"
                      value={formdata1.salesOrderNo}
                      name="salesOrderNo"
                      onChange={handleNumeriChange}
                    />
                  </div>
                </div>

                <div className={styles.formElement}>
                  <label>PO Number</label>
                  <input
                    type="text"
                    min={0}
                    value={formdata1.PONO}
                    name="PONO"
                    onChange={handleOnChange}
                  />
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>PO Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="PODate"
                      value={
                        formdata1.PODate
                          ? dayjs(formdata1.PODate, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(value) => handleDates("PODate", value)}
                      slotProps={{ textField: { size: "small" } }}
                      format="DD/MM/YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className={styles.formElement}>
                  <label>Quote no</label>
                  <input
                    type="text"
                    min={0}
                    value={formdata1.QuoteNO}
                    name="QuoteNO"
                    onChange={handleOnChange}
                  />
                </div>

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>Quote Date</label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="QuoteDate"
                      value={
                        formdata1.QuoteDate
                          ? dayjs(formdata1.QuoteDate, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(value) => handleDates("QuoteDate", value)}
                      slotProps={{ textField: { size: "small" } }}
                      format="DD/MM/YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className={styles.formElement}>
                  <label>Costing ID</label>
                  <input
                    type="text"
                    min={0}
                    value={formdata1.CostingID}
                    name="CostingID"
                    onChange={handleOnChange}
                  />
                </div>

                <div className={styles.formElement}>
                  <label>Customer Name</label>
                  <select
                    value={formdata1.CustomerName}
                    name="CustomerName"
                    onChange={handleOnChange}
                    required>
                    <option value="">Select value</option>
                    <option value="Shelf Drilling">Shelf Drilling</option>
                    <option value="Valaris">Valaris</option>
              <option value="ARO Drilling">ARO Drilling</option>
              <option value="ADES">ADES</option>
              <option value="COC">COC</option>
              <option value="Northern Offshore">Northern Offshore</option>
              <option value="Melius">Melius</option>
              <option value="Goltens Co Ltd. Dubai Branch">Goltens Co Ltd. Dubai Branch</option>
              <option value="Foresight Offshore Drilling Limited S.A.">Foresight Offshore Drilling Limited S.A.</option> 
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

              
              
              </div>

             

              <div>

         
            

                <div className={styles.formElement} >
                  <label>Scope</label>
                  <input type="text" value={formdata1.Scope}
                  style={{backgroundColor:"#DADCE0"}}
                  disabled
                   readOnly />
                
                </div>

                <div className={styles.formElement}>
                  <label>Scope Description</label>
                  <textarea
                    type="text"
                    min={0}
                    value={formdata1.scopeDescription}
                    name="scopeDescription"
                    onChange={handleOnChange}
                    className={styles.textbox1b}
                  />
                </div>

                

         

                <div
                  className={`${styles.formElement} ${styles.datepickerInput}`}>
                  <label>Estimated Date Of Dispatch *</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      name="EstimatedDateDispatch"
                      value={
                        formdata1.EstimatedDateDispatch
                          ? dayjs(
                              formdata1.EstimatedDateDispatch,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                      onChange={(value) =>
                        handleDates("EstimatedDateDispatch", value)
                      }
                      slotProps={{ textField: { size: "small" } }}
                      format="DD/MM/YYYY"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                      required
                    />
                  </LocalizationProvider>
                </div>

                
                
              </div>

              <div>
               

              
                <div className={styles.formElement}>
                  <label>Ops Engineer</label>
                  <select
                    value={formdata1.opsEngineer}
                    name="opsEngineer"
                    onChange={handleOnChange}>
                    <option value=""> Select the Member</option>
                    <option value="Jancy">Jancy</option>
                    <option value="Charulatha">Charulatha</option>
                  </select>
                  <svg
                    style={{ position: "absolute", right: "15" }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                      fill="black"
                    />
                  </svg>
                </div>

               
                <div className={styles.formElement}>
                  <label className={styles.currentStatus}>Current Status *</label>

                  <textarea
                    type="text"
                    min={0}
                    value={formdata1.currentStatus}
                    name="currentStatus"
                    onChange={handleOnChange}
                    className={styles.textbox1b}
                  />
                </div>
              </div>
            </div>
            <div className={styles.btnContainer}>
              {/* <BasicModal  /> */}
              <Button
                onClick={handleCreateNewProject}
                variant="contained"
                className={styles.proceedBtn}>
                Create Project
              </Button>
              <Button
                onClick={handleDivClick}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  border: "1px solid #5B6BE1",
                  color: "#5B6BE1",
                  borderRadius: "8px",
                  height: "50px",
                  width: "170px",
                  "&:hover": {
                    borderColor: "#5B6BE1",
                  },
                }}>
                Discard
              </Button>
             
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default SparestradingWorkshopCreatenew;
