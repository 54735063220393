import React from "react";
import styles from "./css/itemsmaster.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Itemmaster() {
  const [itemdetails, setItemdetails] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");


  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsmaster?searchTerm=${encodeURIComponent(searchQuery.trim())}`);
     
      setItemdetails(res.data);
      setFilteredItems(res.data);
    };
    fetchProject();
  }, [searchQuery]);

  

  const handleSearchItem = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };


 
 


  return (
    <div>
        <div className={styles.topContainer}>
        <div className={styles.searchContainer}>
        <h1 className={styles.itemMasterHeading}>Items Master List</h1>
        <div>
          <input
            type="text"
            placeholder="Search by Item Id, Item Name and Part Number"
            value={searchQuery}
            onChange={handleSearchItem}
            className={styles.searchBox}
          />
          <svg
            className={styles.searchIconFilter}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#BCBCBC"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#BCBCBC"
            />
          </svg>
          </div>
        </div>
        </div>
      



          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead >
                <tr>
                  <th>S. No.</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>Manufacturer Part Number</th>
                </tr>
              </thead>
              <tbody>
                {itemdetails.map((item,index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.Item_Id}</td>
                    <td>{item.Item_Name}</td>
                    <td>{item.Part_Number}</td>
                    <td>{item.Manufacturer_Part_Number}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) 
}
export default Itemmaster;