import React from "react";
import styles from "./css/workshoptimesheet.module.css";
import { useEffect, useRef, useState } from "react";

import axios from "axios";
import WorkshopTimeline from "./WorkshopTimeline";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function ProjectTimelineWorkshop() {
  const [projectList, setProjectList] = useState({});
  const [isDescending, setIsDescending] = useState(false);
  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
  });

  const [workshop, setWorkshop] = useState(14);
  const { id } = useParams();

  const [TimelineData, setTimelineData] = useState([]);

  const navigate = useNavigate();


  const handleDivClick = () => {
    navigate(`/project-checklist-workshop/${id}`);
  };

  const handleDivClick1 = () => {
    navigate(`/project-details-workshop/${id}`);
  };
  const handleDivClick2 = () => {
    navigate(`/timesheet-workshop/${id}`);
  };

  const handleDivClick3 = () => {
    navigate(`/incoming-deliveries-workshop/${id}`);
  };

  const handleDivClick4 = () => {
    navigate(`/outgoing-deliveries-workshop/${id}`);
  };

  const handleDivClick5 = () => {
    navigate(`/outsourcing-workshop/${id}`);
  };

  const handleDivClick6 = () => {
    navigate(`/reports-workshop/${id}`);
  };

  const handleDivClick7 = () => {
    navigate(`/inspections-workshop/${id}`);
  };
  const handleDivClick8 = () => {
    navigate(`/items-received-workshop/${id}`);
  };
  const handleDivClick9 = () => {
    navigate(`/finished-goods-workshop/${id}`);
  };
  const handleDivClick10 = () => {
    navigate(`/team-details-workshop/${id}`);
  };
  const handleDivClick12 = () => {
    navigate(`/inventory-used-workshop/${id}`);
  }

  const handleDivClick13 = () => {
    navigate(`/purchases-workshop/${id}`);
  }
  const handleDivClick14 = () => {
    navigate(`/project-timeline-workshop/${id}`);
  }
  const handleDivClick15 = () => {
    navigate(`/production-workshop/${id}`);
  }
  const handleDivClick16 = () => {
    navigate(`/Wip-workshop/${id}`);
  };
  const handleDivClick23 = () => {
    navigate(`/item-details-workshop/${id}`);
  };
  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PO_Date: filter[0]?.PODate,
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);
  var filter;
  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_timeline_details"
      );
      filter = res.data.data.filter((el) => el.ID_No == id);
      console.log(filter);
      filter.sort((a, b) => new Date(a.Date) - new Date(b.Date));
      setTimelineData(filter);
    };
    fetchProject();
  }, [id]);


  const handleClick = () => {
    if (isDescending) {
      // Copy the TimelineData array to a new array to avoid mutating the original state directly
      const sortedTimelineData = [...TimelineData];

      // Sort the new array in descending order
      sortedTimelineData.sort((a, b) => new Date(a.Date) - new Date(b.Date));

      // Set the TimelineData state with the newly sorted array
      // This will ensure the state is updated with a new reference and the component re-renders
      setTimelineData(sortedTimelineData);

      console.log("Sorted Data:", sortedTimelineData); // Optional: for debugging to see the sorted data
    } else {
      const sortedTimelineData = [...TimelineData];

      // Sort the new array in descending order
      sortedTimelineData.sort((a, b) => new Date(b.Date) - new Date(a.Date));

      // Set the TimelineData state with the newly sorted array
      // This will ensure the state is updated with a new reference and the component re-renders
      setTimelineData(sortedTimelineData);

      console.log("Sorted Data:", sortedTimelineData); // Optional: for debugging to see the sorted data
    }
  };

  const toggleSortOrder = () => {

    setIsDescending(!isDescending);
    handleClick();
  };

  return (
    <div>
      <div className={styles.topdiv}>
        <label className={styles.aryl1}>
          Project Name: {formdata1.ProjectName}
        </label>
        <label className={styles.aryl}>Project Scope:{formdata1.Scope}</label>
        <label className={styles.aryl}>
          Project Description:{formdata1.projectDescription}
        </label>
        <label className={styles.aryl}>Quantity:{formdata1.quantity}</label>
        <label className={styles.aryl}>
          Target Days:{formdata1.TargetDaysForCompletion}
        </label>
        <label className={styles.aryl}>
          Target Manhours:{formdata1.TargetManhours}
        </label>
      </div>
      <div className={styles.aryTabs}>
        <div
          className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick}>
          Project Checklist
        </div>
        <div
          className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick1}>
          Project Details
        </div>
        <div
          className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick10}
        >
          Team Details
        </div>
        <div
          className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick12}>
          Inventory Used
        </div>
        <div
          className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick13}>
          Purchases
        </div>
        <div
          className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick2}>
          Timesheet
        </div>
        <div
          className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick3}>
          Deliveries
        </div>
        <div
          className={workshop === 23 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick23}>
          Serial Number
        </div>
        <div
          className={workshop === 15 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick15}>
          Production
        </div>
        <div
          className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick14}>
          Timeline
        </div>
        {/* <div
          className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick4}>
          Outgoing Deliveries
        </div> */}
        <div
          className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick5}>
          Outsourcing
        </div>
        <div
          className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick6}>
          Reports
        </div>
        <div
          className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick7}>
          Inspections
        </div>
        <div
          className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick8}>
          Items Received
        </div>
        <div
          className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick9}>
          Finished goods
        </div>
        <div
          className={workshop === 16 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick16}
        >
          WIP
        </div>

      </div>

      <div className={styles.sections}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', background: 'white' }}>
          <h1 style={{
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '19.07px',
            color: '#5B6BE1',
            margin: '0', // Removes default margin
          }}>
            Tasks performed
          </h1>
          <button
            style={{
              padding: '8px 8px',
              fontSize: '16px',
              cursor: 'pointer',
              backgroundColor: isDescending ? '#5B6BE1' : 'transparent', // Changes background based on isDescending
              border: isDescending ? '#5B6BE1' : '1px solid #5B6BE1',
              borderRadius: '5px',
              color: isDescending ? 'White' : 'black',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', // Drop shadow
            }}
            onClick={toggleSortOrder} // Toggles sorting order on click
          >
            Sort Descending
            {isDescending ? (<svg
              style={{ marginLeft: "5px", marginTop: "-4px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M13.491 1.66699H6.50768C3.47435 1.66699 1.66602 3.47533 1.66602 6.50866V13.4837C1.66602 16.5253 3.47435 18.3337 6.50768 18.3337H13.4827C16.516 18.3337 18.3243 16.5253 18.3243 13.492V6.50866C18.3327 3.47533 16.5243 1.66699 13.491 1.66699ZM11.1077 14.167H8.88268C8.54102 14.167 8.25768 13.8837 8.25768 13.542C8.25768 13.2003 8.54102 12.917 8.88268 12.917H11.1077C11.4493 12.917 11.7327 13.2003 11.7327 13.542C11.7327 13.8837 11.4577 14.167 11.1077 14.167ZM13.3327 10.6253H6.66602C6.32435 10.6253 6.04102 10.342 6.04102 10.0003C6.04102 9.65866 6.32435 9.37533 6.66602 9.37533H13.3327C13.6743 9.37533 13.9577 9.65866 13.9577 10.0003C13.9577 10.342 13.6743 10.6253 13.3327 10.6253ZM14.9993 7.08366H4.99935C4.65768 7.08366 4.37435 6.80033 4.37435 6.45866C4.37435 6.11699 4.65768 5.83366 4.99935 5.83366H14.9993C15.341 5.83366 15.6243 6.11699 15.6243 6.45866C15.6243 6.80033 15.341 7.08366 14.9993 7.08366Z"
                fill="white"
              />
            </svg>) : (<svg
              style={{ marginLeft: "5px", marginTop: "-4px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M13.491 1.66699H6.50768C3.47435 1.66699 1.66602 3.47533 1.66602 6.50866V13.4837C1.66602 16.5253 3.47435 18.3337 6.50768 18.3337H13.4827C16.516 18.3337 18.3243 16.5253 18.3243 13.492V6.50866C18.3327 3.47533 16.5243 1.66699 13.491 1.66699ZM11.1077 14.167H8.88268C8.54102 14.167 8.25768 13.8837 8.25768 13.542C8.25768 13.2003 8.54102 12.917 8.88268 12.917H11.1077C11.4493 12.917 11.7327 13.2003 11.7327 13.542C11.7327 13.8837 11.4577 14.167 11.1077 14.167ZM13.3327 10.6253H6.66602C6.32435 10.6253 6.04102 10.342 6.04102 10.0003C6.04102 9.65866 6.32435 9.37533 6.66602 9.37533H13.3327C13.6743 9.37533 13.9577 9.65866 13.9577 10.0003C13.9577 10.342 13.6743 10.6253 13.3327 10.6253ZM14.9993 7.08366H4.99935C4.65768 7.08366 4.37435 6.80033 4.37435 6.45866C4.37435 6.11699 4.65768 5.83366 4.99935 5.83366H14.9993C15.341 5.83366 15.6243 6.11699 15.6243 6.45866C15.6243 6.80033 15.341 7.08366 14.9993 7.08366Z"
                fill="black"
              />
            </svg>)}

          </button>
        </div>
        <div className={styles.timelinesection} style={{ marginLeft: '-68%', width: '100%' }}>
          <WorkshopTimeline data={TimelineData} />
        </div>
      </div>

    </div>
  );
}

export default ProjectTimelineWorkshop;
