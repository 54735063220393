import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/supplierdetailslisting.module.css";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

function SupplierDetailsListing() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [supplierLists, setSupplierLists] = useState([]);

  const handleNav = () => {
    navigate("/supplierdetails-createnew");
  };

  const handleNav2 = (Supplier_ID, Supplier_Name) => {
    navigate(`/supplierdetails-viewdetails/${Supplier_ID}/${Supplier_Name}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supplier_details"
        );
        setSupplierLists(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSuppliers();
  }, []);

  console.log("Suppliers List", supplierLists)

  const filteredSuppliers = supplierLists
  .sort((a, b) => b.Supplier_No - a.Supplier_No)
  .filter((supply) => {
    const supplierId = supply?.Supplier_ID ? supply.Supplier_ID.toLowerCase() : "";
    const supplierName = supply?.Supplier_Name
      ? supply.Supplier_Name.toLowerCase()
      : "";
    return (
      searchTerm === "" ||
      supplierId.includes(searchTerm.toLowerCase()) ||
      supplierName.includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div className={styles.listContainer}>
      <div className={styles.topContainer}>
        <div className={styles.top1}>
          <h3 className={styles.listHeading}>Suppliers</h3>

          <div className={styles.Searchbar}>
            <div className={styles.searchContainer}>
              <input
                type="text"
                placeholder="Search by Supplier Name, Supplier ID "
                onChange={handleSearchChange}
              />
              <svg
                className={styles.searchIconFilter}
                style={{ marginLeft: "5%" }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                  fill="#0000008F"
                />
                <path
                  d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
                  fill="#0000008F"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className={styles.top2}>
          <div>
            <button onClick={handleNav} className={styles.createNew}>
              Create New
              <svg
                style={{ marginLeft: "10px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-plus-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={styles.listingContainer}>
        <table className={styles.listTable}>
          <thead>
            <tr>
            <th style={{ minWidth: "60px", width: "60px" }}>S.No</th>
              <th style={{ width: "150px", minWidth: "150px" }}>Supplier ID</th>
              <th style={{ width: "160px", minWidth: "170px", maxWidth: "170px" }}>
                Supplier Name
              </th>
              <th style={{ width: "160px", minWidth: "170px", wordBreak: "break-all"}}>Address</th>
              <th style={{ width: "160px", minWidth: "175px" }}>
                Approved Supplier Scope
              </th>
              <th style={{ width: "130px", minWidth: "130px" }}>Category</th>
              <th style={{ width: "180px", minWidth: "180px" }}>
                Registration Date
              </th>
              <th style={{ width: "180px", minWidth: "180px" }}>
                Date of Evaluation
              </th>
              <th style={{ width: "100px", minWidth: "100px" }}>Rating</th>
              <th style={{ width: "150px", minWidth: "150px" }}>
                Std. Delivery Time
              </th>
              <th style={{ width: "150px", minWidth: "150px" }}>SPOC</th>
              <th style={{ width: "150px", minWidth: "150px" }}>Phone</th>
            </tr>
          </thead>
          <tbody>
            {filteredSuppliers.length > 0 ? (
            filteredSuppliers.map((supply, index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{supply.Supplier_ID}</td>
                <td><div className={styles.nav2} onClick={() => handleNav2(supply.Supplier_ID, supply.Supplier_Name)}>{supply.Supplier_Name}</div></td>
                <td>{supply.Address}</td>
                <td>{supply.Scope}</td>
                <td>{supply.Category}</td>
                <td>{supply.Reg_Date}</td>
                <td>{supply.Date_Of_Eval}</td>
                <td>{supply.Rating}</td>
                <td>{supply.Delivery_Time}</td>
                <td>{supply.POC}</td>
                <td>{supply.Phone}</td>
              </tr>
            ))):(<div className={styles.nolist}></div>)}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SupplierDetailsListing;
