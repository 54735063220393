import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import styles from "../Components/css/adminpermission_roles_features.module.css";
import styles2 from "../Components/css/adminpermission_roles.module.css";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import style from "./css/modal.module.css";
import { styled } from "@mui/system";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { blue } from '@mui/material/colors';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function AdminPermissionRolesFeatures() {

    const navigate = useNavigate();
    const { name } = useParams();
    const [selectedTab, setSelectedTab] = useState("members");
    const [editMode, setEditMode] = useState(false);
    const [features, setFeatures] = useState([{ header: "", feature: "", access: "Hidden" }]);
    const [featureDetails, setFeatureDetails] = useState([]);
    const [headerOptions, setHeaderOptions] = useState([]);
    const [featureOptions, setFeatureOptions] = useState({});
    const [searchResultsadmemfil, setSearchResultsadmemfil] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState("");
    const [selectedName, setSelectedName] = useState("");


    useEffect(() => {

        fetchsmDetails();
    }, []);

    useEffect(() => {
        fetchAllUsers();
    }, []);

    const fetchAllUsers = async () => {
        try {
            const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/users");
            setAllUsers(response.data); // Assuming the response is an array of user objects
        } catch (error) {
            console.error("Error fetching all users:", error);
        }
    };

    async function fetchsmDetails() { //first loading of the page data and states, also used to refresh the page when save, cancel, cross etc. are clicked
        try {


            const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/role-members/${name}`);


            const fetchedData = response.data.map(item => ({
                Username: item.user_name,
                EmailID: item.Useremail,
                user_id: item.user_id
            }));

            setSearchResultsadmemfil(fetchedData);

            console.log("response", response);
        } catch (error) {
            console.error(error);
        }
    }
    console.log("Data3", searchResultsadmemfil)


    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        console.log("hP", name);
        console.log("hP1", value);

        // Update the specific entry in the array
        setSearchResultsadmemfil((prevData) =>
            prevData.map((item, i) =>
                i === index ? { ...item, [name]: value } : item
            )
        );
    };

    const handleEmailChange = (event) => {
        const email = event.target.value;
        setSelectedEmail(email);
        const user = allUsers.find(user => user.Useremail === email);
        if (user) {
            setSelectedName(user.Name); // Assuming the user object has a 'name' property
        } else {
            setSelectedName("");
        }
    };

    const handleClose = () => {
        setOpenModal(false);
        setSelectedEmail("");
        setSelectedName("");
    };


    const handleNewUser = () => {
        setOpenModal(true);
    }

    const handleSave1 = async () => {
        if (!selectedEmail) {
            alert("Please select a user email.");
            return;
        }
        const payload = {
            EmailID: selectedEmail,
            Username: selectedName,
            Role: name,
        };

        try {
            const response = await axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/role-members", payload);
            if (response.status === 200) {
                alert("User added successfully!");
                setOpenModal(false);
                fetchsmDetails();
            } else {
                alert("Failed to add user.");
            }
        } catch (error) {
            console.error("Error adding user:", error);
        }
        finally{
            setSelectedEmail("");
            setSelectedName("");
        }
    };


    const handleSaveUpdate = async () => {
        try {
            const payload = searchResultsadmemfil.map((item, index) => ({
                Username: allUsers.find(user => user.Useremail === searchResultsadmemfil[index]?.EmailID)?.Name || "",
                EmailID: item.EmailID,
                user_id: item.user_id
            }));


            const response = await axios.put("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/role-members/update", payload);

            if (response.status === 200) {
                alert("Updates saved successfully!");
                setEditMode(false); // Exit edit mode
                fetchsmDetails(); // Refresh the table data
            } else {
                alert("Failed to save updates.");
            }
        } catch (error) {
            console.error("Error saving updates:", error);
            alert("An error occurred while saving updates.");
        }
    };


    useEffect(() => {
        // Fetch header and feature options using the same API as group features
        const fetchOptions = async () => {
            try {
                const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_headerandfeatures");
                const data = response.data;

                // Extract unique headers
                const headers = Array.from(new Set(data.map(item => item.Header)));
                setHeaderOptions(headers);

                // Map features to headers
                const featureMap = {};
                data.forEach(item => {
                    if (!featureMap[item.Header]) {
                        featureMap[item.Header] = [];
                    }
                    featureMap[item.Header].push(item.Features);
                });
                setFeatureOptions(featureMap);
            } catch (error) {
                console.error("Error fetching headers and features:", error);
            }
        };

        fetchOptions();
    }, []);

    useEffect(() => {
        const fetchFeatures = async () => {
            try {
                const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_roles_features/${name}`);
                setFeatureDetails(response.data); // Set the grouped data in the state
            } catch (error) {
                console.error("Error fetching features:", error);
            }
        };

        fetchFeatures();
    }, [name]);

    const handleTabSwitch = (tab) => {
        setSelectedTab(tab);
    };

    const handleEditToggle = async () => {
        try {
            if (!editMode) {
                // Fetch existing features when entering edit mode
                const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_roles_features/${name}`);
                const groupedData = response.data;

                // Flatten grouped data into an array for editable fields
                const featuresList = Object.entries(groupedData).flatMap(([header, featureList]) =>
                    featureList.map((feature) => ({
                        header: header,
                        feature: feature.Feature,
                        access: feature.Access,
                    }))
                );

                setFeatures(featuresList.length > 0 ? featuresList : [{ header: "", feature: "", access: "Hidden" }]);
            }

            setEditMode(!editMode); // Toggle edit mode
        } catch (error) {
            console.error("Error fetching features for edit:", error);
        }
    };


    const handleAddFeature = () => {
        setFeatures([{ header: "", feature: "", access: "Hidden" }, ...features]);
    };

    const handleFeatureChange = (index, field, value) => {
        const updatedFeatures = [...features];
        updatedFeatures[index][field] = value;
        setFeatures(updatedFeatures);
    };

    const handleSave = async () => {
        try {
            // Prepare the payload to match the backend table structure
            const payload = features.map((feature) => ({
                Role_Name: name, // Assuming `name` is the role identifier
                Header: feature.header, // Standardized key
                Feature: feature.feature, // Standardized key
                Access: feature.access, // Standardized key
            }));

            // Send payload to the backend
            await axios.put("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_roles_features", payload);

            console.log("Features saved successfully:", payload);

            // Refresh features after save
            const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_roles_features/${name}`);
            setFeatureDetails(response.data);

            // Exit edit mode
            setEditMode(false);
        } catch (error) {
            console.error("Error saving features:", error);
        }
    };

    const handleCancel = async () => {
        try {
            // Re-fetch the data from the backend to reset the `features` state
            const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_roles_features/${name}`);
            setFeatures(
                Object.entries(response.data).flatMap(([header, featureList]) =>
                    featureList.map((feature) => ({
                        header,
                        feature: feature.Feature,
                        access: feature.Access,
                    }))
                )
            );
            setEditMode(false); // Exit edit mode
        } catch (error) {
            console.error("Error fetching features for cancel operation:", error);
        }
    };




    const handlerole = () => {
        navigate("/adminpermission-roles");
    }

    const handleuser = () => {
        navigate("/adminpermission");
    }





    return (
        <>
            <div className={styles2.upmost}>
                <div className={styles2.dashboardheading}> Admin Permissions</div>
                <button
                    className={styles2.buttonap1}
                    onClick={handleuser}
                >
                    Users
                </button>
                <button
                    className={styles2.buttonap2}
                    onClick={handleuser}
                >
                    Groups
                </button>
                <button className={styles2.buttonap3} onClick={handlerole}>
                    Roles
                </button>
            </div>

            <div className={styles.mainap}>
                <div className={styles.headerRow} >
                    <div>
                        <h2 className={styles.mainheadview}>{name}</h2>
                    </div>

                    <div className={styles.tabContainer}>
                        <div
                            className={
                                selectedTab === "members"
                                    ? styles.activePersonalMobilisationTab
                                    : styles.personalMobilisationTab
                            }
                            onClick={() => handleTabSwitch("members")}
                        >
                            Members
                        </div>
                        <div
                            className={
                                selectedTab === "features"
                                    ? styles.activePersonalMobilisationTab
                                    : styles.personalMobilisationTab
                            }
                            onClick={() => handleTabSwitch("features")}
                        >
                            Features
                        </div>
                    </div>

                    <div>
                        {selectedTab === "members" && (
                            <div>
                                <button className={styles.editbutton222} onClick={handleEditToggle}>
                                    {editMode ? "Cancel" : "Edit"}
                                </button>
                                {!editMode && (
                                    <button className={styles.buttonapuser}
                                        onClick={handleNewUser}
                                    >
                                        <svg
                                            style={{ marginRight: "10px" }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-plus-square-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                        </svg>
                                        Add New User
                                    </button>
                                )}
                                {editMode && (
                                    <button className={styles.buttonapuser1}
                                        onClick={handleSaveUpdate}
                                    >
                                        Save
                                    </button>
                                )}
                            </div>
                        )}
                          {openModal && (
                    <div>
                        <Modal
                            open={openModal}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                                backdrop: {
                                    timeout: 500,
                                },
                            }}
                            sx={{
                                backdropFilter: "blur(4px)",
                            }}>
                            <Fade in={openModal}>
                                <Box
                                    sx={{
                                        width: 650,
                                        height: 400,
                                        p: 3,
                                        backgroundColor: "#fff",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <div style={{
                                        display: "flex", flexDirection: "column", gap: "70px", margin: "10px", fontSize: "21px",
                                        fontWeight: "bold"
                                    }}>
                                        <label style={{ color: "#5B6BE1", fontSize: "20px" }}>Add New User</label>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>

                                                <label style={{ marginTop: "-40px" }}>User email: </label>
                                                <select value={selectedEmail} onChange={handleEmailChange} className={styles.groupnameinput}>
                                                    <option value="">Select a user email</option>
                                                    {allUsers.map(user => (
                                                        <option key={user.Useremail} value={user.Useremail}>{user.Useremail}</option>
                                                    ))}
                                                </select>
                                                <svg
                                                    style={{ position: "absolute", right: "90", top: "140px" }}
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                            </div>

                                            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                                <label>User Name: </label>
                                                <input type="text" value={selectedName} readOnly className={styles.groupnameinput} />
                                            </div>
                                        </div>


                                        <div style={{ marginTop: "-20px", display: "flex", justifyContent: "flex-end" }}>
                                            <button className={styles.submitBtn} onClick={handleSave1}>Save</button>
                                            <button className={styles.submitBtn} onClick={handleClose}>Cancel</button>
                                        </div>

                                    </div>
                                </Box>
                            </Fade>
                        </Modal>
                    </div>
                )}
                        {selectedTab === "features" && (
                            <div>
                                {editMode ? (
                                    <div className={styles.featureActions}>

                                        <button className={styles.mainheaderbutton} onClick={handleSave}>
                                            Save
                                        </button>
                                        <button className={styles.mainheaderbutton2} onClick={handleCancel}>
                                            Cancel
                                        </button>
                                        <button className={styles.mainheaderbutton2} onClick={handleAddFeature}>
                                            + Add New Feature
                                        </button>
                                    </div>
                                ) : (
                                    <div className={styles.featureActions}>
                                        <button className={styles.editbutton222} onClick={handleEditToggle}>
                                            Edit
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                </div>
                <div className={styles.contentArea}>
                    {selectedTab === "members" ? (
                        <div>
                            {/* Members content goes here */}
                            <>
                                <table className={styles.rigtableap1}>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Username</th>
                                            <th>Email ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchResultsadmemfil.length > 0 ? ( // Only render table rows if formDatasm has data
                                            searchResultsadmemfil.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {editMode ? (
                                                            <>
                                                                <div style={{ position: "relative" }}>
                                                                    <input
                                                                        type="text"
                                                                        name="Username"
                                                                        id="inputItemTraceability"
                                                                        value={
                                                                            allUsers.find(user => user.Useremail === searchResultsadmemfil[index]?.EmailID)?.Name || ""
                                                                        } // Dynamically finds the name corresponding to the email
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                        className={styles.textbox1}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : data.Username ? (
                                                            data.Username
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                    <td>
                                                        {editMode ? (
                                                            <>
                                                                <div>
                                                                    <select name="EmailID" value={searchResultsadmemfil[index]?.EmailID} onChange={(e) => handleInputChange(e, index)} className={styles.inputFieldsm1}>
                                                                        <option value="">Select a user email</option>
                                                                        {allUsers.map(user => (
                                                                            <option key={user.Useremail} value={user.Useremail}>{user.Useremail}</option>
                                                                        ))}
                                                                    </select>
                                                                    <svg
                                                                        style={{marginLeft: "-7%"}}
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                                                                            fill="black"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </>
                                                        ) : data.EmailID ? (
                                                            data.EmailID
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                </tr>
                                            ))

                                        ) : (
                                            <tr>
                                                <td colSpan="3">No data available</td> {/* Render this if no data exists */}
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </>
                        </div>
                    ) : (
                        <div>
                            {editMode ? (
                                <div>
                                    <table className={styles.rigtableap}>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Header</th>
                                                <th>Feature</th>
                                                <th>Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {features.map((feature, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <select
                                                            value={feature.header}
                                                            onChange={(e) => handleFeatureChange(index, "header", e.target.value)}
                                                            className={styles.inputFieldsm}
                                                        >
                                                            <option value="">Select Header</option>
                                                            {headerOptions.map((header, i) => (
                                                                <option key={i} value={header}>
                                                                    {header}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            style={{
                                                                marginLeft: "-35px",
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            <path
                                                                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                                                fill="#394759"
                                                            />
                                                        </svg>
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={feature.feature}
                                                            onChange={(e) => handleFeatureChange(index, "feature", e.target.value)}
                                                            className={styles.inputFieldsm}
                                                        >
                                                            <option value="">Select Feature</option>
                                                            {(featureOptions[feature.header] || []).map((feat, i) => (
                                                                <option key={i} value={feat}>
                                                                    {feat}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            style={{
                                                                marginLeft: "-35px",
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            <path
                                                                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                                                fill="#394759"
                                                            />
                                                        </svg>
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={feature.access}
                                                            onChange={(e) => handleFeatureChange(index, "access", e.target.value)}
                                                            className={styles.inputFieldsm}
                                                        >
                                                            <option value="Hidden">Hidden</option>
                                                            <option value="Can View">Can View</option>
                                                        </select>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            style={{
                                                                marginLeft: "-35px",
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            <path
                                                                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                                                                fill="#394759"
                                                            />
                                                        </svg>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                            ) : (
                                <div className={styles.pushdown}>
                                    {Object.entries(featureDetails).map(([header, featureList]) => (
                                        <div key={header}>
                                            <div className={styles.mainheadviewalpha}>
                                                <div className={styles.mainheadviewbeta}>{header}</div>
                                            </div>

                                            <div>
                                                {featureList.map((feature, index) => (
                                                    <div key={index} className={styles.mainheadviewepsilon}>
                                                        <div className={styles.mainheadviewgamma}>{feature.Feature || "No Feature Selected"}</div>
                                                        <h5 className={styles.mainheadviewdelta}>{feature.Access || "Hidden"}</h5>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default AdminPermissionRolesFeatures