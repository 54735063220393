import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import { useParams, NavLink } from "react-router-dom";
import styles from "../Components/css/inspectionreport.module.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
import logo from "../../src/Components/Erogonlogo.png";

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const formatDate = (dateString) => {
  if (!dateString) return "-";
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-GB', options);
};


export const generatePDF = (formData, purchaseDetails, deliveryDetails, inspectionOrder, preparedBy, approvedBy) => {
  // Create a new jsPDF instance in landscape mode
  const pdf = new jsPDF("l", "mm", "a4");
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  const inspectionDate = deliveryDetails?.Inspection_Date ? formatDate(deliveryDetails.Inspection_Date) : 'NA';
  console.log("Inspection Date:", inspectionDate);



  // Add border around the logo and heading
  pdf.setLineWidth(0.25);
  pdf.rect(14, 10, pageWidth - 30, 30);  // Rect around the logo and heading

  // Add the logo and title
  const logoImg = new Image();
  logoImg.src = logo;  // Assuming 'logo' is a base64 or URL string
  pdf.addImage(logoImg, "PNG", 30, 15, 55, 20);

  pdf.setFontSize(21);
  pdf.text("INCOMING INSPECTION REPORT", pageWidth / 2, 33, null, null, "left");

  // Metadata Table (same width as the form data table)
  pdf.autoTable({
    startY: 45,
    tableWidth: pageWidth - 20,
    head: [["Report No.", `IIR-${purchaseDetails?.PO_Number}-${inspectionOrder}-${new Date().getFullYear()}`, "Inspection Date", inspectionDate]],
    body: [
      ["Purchase Order", `${purchaseDetails?.PO_Number}`, "Supplier Name", `${purchaseDetails?.Supplier_Name}`],
      ["BOE", `${deliveryDetails?.Entry_Bill_No ? deliveryDetails?.Entry_Bill_No : 'NA'}`, "BOE Date", `${deliveryDetails?.Entry_Bill_Date ? formatDate(deliveryDetails.Entry_Bill_Date) : 'NA'}`],
      ["Location", { content: "Ergon Technologies, Hamriyah, Sharjah, UAE", colSpan: 3 }],
    ],
    styles: { fontSize: 10, cellPadding: 3 },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontStyle: 'normal', lineColor: [0, 0, 0], lineWidth: 0.1 },
    bodyStyles: {
      fillColor: [255, 255, 255], textColor: [0, 0, 0], lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    theme: 'grid',
    columnStyles: {
      0: { cellWidth: 45 },
      1: { cellWidth: 88 },
      2: { cellWidth: 46 },
      3: { cellWidth: 88 },
    },
  });

  // Adjust startY for the inspection items table based on the last table's position
  const startY = pdf.lastAutoTable.finalY + 3;

  // Inspection Items Table with Increased Cell Height

  pdf.autoTable({
    startY: startY,
    tableWidth: pageWidth - 20,
    head: [["S.No", "Item Description", "OEM Part Number", "Manufacturer Part Number", "Traceability No.", "UOM", "Qty.", "Picture", "Condition at Receipt", "Inspection Remarks"]],
    body: formData.map((item, index) => {
      const images = item.Picture
        ? item.Picture.split(',')
          .map(img => img.trim())
          .filter(img => img && img !== "null" && img !== "undefined")
        : [];

      return [
        index + 1,
        item.Item_Name,
        item.OEM_Part_Number,
        item.Manufacturer_Part_Number,
        item.Traceability_Number,
        item.UoM,
        item.Incoming_Qty,
        '',  // Set this to empty string to avoid text in the Picture column
        item.Condition_Upon_Receipt,
        item.Remarks
      ];
    }),
    headStyles: { fillColor: [226, 230, 255], textColor: [0, 0, 0], fontStyle: 'bold', lineColor: [0, 0, 0], lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineColor: [0, 0, 0], lineWidth: 0.1 },
    columnStyles: {
      0: { cellWidth: 12 },
      1: { cellWidth: 40 },
      2: { cellWidth: 25 },
      3: { cellWidth: 26 },
      4: { cellWidth: 30 },
      5: { cellWidth: 20 },
      6: { cellWidth: 15 },
      7: { cellWidth: 45, halign: 'center', valign: 'middle', minCellHeight: 52 },
      8: { cellWidth: 30 },
      9: { cellWidth: 24 }
    },

    didDrawCell: (data) => {
      if (data.column.dataKey === 7 && data.cell.raw === '') {
        const img1 = formData[data.row.index].Picture?.split(',')[0]?.trim();  // Directly use the image URL
        if (img1 && img1 !== "null" && img1 !== "undefined") {
          const imageFormat = img1.endsWith('png') ? 'PNG' : 'JPEG';

          const imgWidth1 = 40;
          const imgHeight1 = 23;
          const xPos1 = data.cell.x + (data.cell.width - imgWidth1) / 2;
          const yPos1 = data.cell.y + (data.cell.height - imgHeight1) / 18;

          pdf.addImage(img1, imageFormat, xPos1, yPos1, imgWidth1, imgHeight1);

          // Check for the second image if it exists
          const img2 = formData[data.row.index].Picture?.split(',')[1]?.trim();  // Second image URL if available
          if (img2 && img2 !== "null" && img2 !== "undefined") {
            const imgWidth2 = 40;
            const imgHeight2 = 23;
            const xPos2 = data.cell.x + (data.cell.width - imgWidth2) / 2;
            const yPos2 = yPos1 + imgHeight1 + 2;

            pdf.addImage(img2, imageFormat, xPos2, yPos2, imgWidth2, imgHeight2);
          }
        }
      }
    },

    theme: 'grid',
    rowPageBreak: 'avoid',
    didDrawPage: (data) => {
      const footerY = pageHeight - 20;
      const contentHeight = data.cursor.y;


      if (contentHeight + 20 > footerY) {

        pdf.addPage();
      }
      pdf.setLineWidth(0.35);
      pdf.line(14, footerY + 5, pageWidth - 15, footerY + 5);
      pdf.setFontSize(8);
      pdf.text("TSD-OPS-FOR-019-23, Rev.03", 14, footerY + 10);
      pdf.text("08 Aug 2024", pageWidth / 2, footerY + 10, null, null, "center");
      pdf.text("This document is digitally generated and does not require a signature.", pdf.internal.pageSize.getWidth() / 2, footerY + 15, null, null, "center");
      pdf.text("Page No " + data.pageNumber, pageWidth - 15, footerY + 10, null, null, "right");
    }
  });


  // Footer (Ensuring it stays at the bottom of the page)
  const footerY = pdf.internal.pageSize.getHeight() - 20;

  pdf.setLineWidth(0.25);
  pdf.rect(14, footerY - 18, pageWidth - 30, 20);
  pdf.line(pageWidth / 2, footerY - 18, pageWidth / 2, footerY + 2);

  pdf.setFontSize(12);
  pdf.text(`Inspected by: `, 25, footerY - 10);
  pdf.text(`Reviewed & Approved by: `, pageWidth - 140, footerY - 10);
  pdf.text(`${preparedBy}`, 25, footerY - 4);
  pdf.text(`${approvedBy}`, pageWidth - 140, footerY - 4);

  pdf.save("incoming_inspection_report.pdf");
};

function IncomingInspectionReport(props) {
  const [formData, setFormData] = useState([]);
  const [purchaseDetails, setPurchaseDetails] = useState({});
  const [deliveryDetails, setDeliveryDetails] = useState({});
  const { id, deliveryId } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [inspectionOrder, setInspectionOrder] = useState(0);
  const [inspectionStatus, setInspectionStatus] = useState('Pending');



  useEffect(() => {
    const fetchDetails = async () => {
      try {
        // Fetch all inspections for the current Purchase_ID, sorted by Inspection_Date
        const resAllInspections = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_inspections_get?Purchase_ID=${id}`
        );

        // Filter to keep only unique Incoming_Inspection_IDs
        const uniqueInspections = Array.from(new Map(resAllInspections.data.data.map(item => [item.Incoming_Inspection_ID, item])).values());

        // Sort unique inspections by Incoming_Inspection_ID (ascending)
        const sortedInspections = uniqueInspections.sort(
          (a, b) => a.Incoming_Inspection_ID - b.Incoming_Inspection_ID
        );

        // Log sorted inspections to verify order
        console.log("Sorted Unique Inspections:", sortedInspections);

        // Determine the order of the current inspection
        const currentInspectionIndex = sortedInspections.findIndex(
          el => el.Incoming_Delivery_ID == deliveryId
        );

        console.log("Current Inspection Index:", currentInspectionIndex);

        setInspectionOrder(currentInspectionIndex + 1); // Index + 1 gives the order number


        const resPurchaseDetails = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchases_get"
        );


        const resDeliveryDetails = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_details2?project_id=${deliveryId}`
        );

        const resInspectionItems = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/purchase_incoming_inspections_get?Purchase_ID=${id}`
        );

        const filterPurchaseDetails = resPurchaseDetails.data.data.filter(el => el.Purchase_ID == id);
        const filterInspectionItems = resInspectionItems.data.data.filter(el =>
          el.Incoming_Delivery_ID == deliveryId && el.Incoming_Qty !== undefined && el.Incoming_Qty !== null && el.Incoming_Qty !== 0 && el.Incoming_Qty !== "0"
        );

        setPurchaseDetails(filterPurchaseDetails[0]);
        setDeliveryDetails(resDeliveryDetails.data.data[0]);
        setFormData(filterInspectionItems);

        console.log("filter", filterInspectionItems);

        // Fetch updated inspection date
        const updatedInspectionDate = filterInspectionItems[0]?.Inspection_Date || null;
        console.log("Fetched Inspection Date:", updatedInspectionDate);
        console.log("resdelivery", resInspectionItems);

        if (filterInspectionItems.length > 0) {
          setDeliveryDetails(prevState => ({
            ...prevState,
            ...resDeliveryDetails.data.data[0],
            Inspection_Date: updatedInspectionDate,
          }));
          console.log("Updated State:", {
            ...deliveryDetails,
            Inspection_Date: updatedInspectionDate,
          });
        }

      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };

    fetchDetails();
  }, [deliveryId, refresh]);

  const handleDateUpdate = async () => {
    if (inspectionStatus === 'Completed') {
      try {
        const payload = {
          Incoming_Delivery_ID: deliveryId,
          Inspection_Date: getFormattedDate(),
        };

        console.log('Sending payload to update inspection date:', payload);

        await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/update_inspection_date`, payload);

        // Trigger a refresh or any additional actions here
        setRefresh(prev => !prev);

      } catch (error) {
        console.error('Error updating inspection date:', error);
      }
    }
  };

  useEffect(() => {
    if (inspectionStatus === 'Completed') {
      handleDateUpdate();
    }
  }, [inspectionStatus]);



  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };



  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generatePDF(formData, purchaseDetails, deliveryDetails, inspectionOrder);
  };




  return (
    <div className={styles.inspectionReportContainer}>
      <div className={styles.formContainer}>
        <form id="incomingInspection">
          <table>
            <thead className="header" style={{ width: "80vw" }}>
              <tr>
                <th colSpan="4">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      paddingTop: "30px",
                    }}
                  >
                    <div>
                      <img
                        src={logo}
                        alt="ergon-logo"
                        style={{ width: "200px", height: "150" }}
                      />
                    </div>
                    <div>
                      <h2>INCOMING INSPECTION REPORT</h2>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
          <br />
          <table>
            <tbody className="report-header">
              <tr>
                <th scope="row">Report No.</th>
                {console.log("PO ", purchaseDetails)}
                <td>{`IIR-${purchaseDetails?.PO_Number}-${inspectionOrder}-${new Date().getFullYear()}`}</td>
                <th scope="row">Inspection Date</th>
                <td>{deliveryDetails?.Inspection_Date ? formatDate(deliveryDetails.Inspection_Date) : 'NA'}</td>
              </tr>
              <tr>
                <th scope="row">Purchase Order</th>
                <td>{purchaseDetails?.PO_Number}</td>
                <th scope="row">Supplier Name</th>
                <td>{purchaseDetails?.Supplier_Name}</td>
              </tr>
              <tr>
                <th scope="row">BOE</th>
                <td>{deliveryDetails?.Entry_Bill_No ? deliveryDetails?.Entry_Bill_No : 'NA'}</td>
                <th scope="row">BOE Date</th>
                <td>{deliveryDetails?.Entry_Bill_Date ? formatDate(deliveryDetails.Entry_Bill_Date) : 'NA'}</td>

              </tr>
              <tr>
                <th scope="row">Location</th>
                <td colSpan="3">
                  Ergon Technologies, Hamriyah, Sharjah, UAE
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table>
            <tbody>
              <tr>
                <th scope="row" className={styles.darkHeading}>
                  S.No
                </th>
                <th className={styles.darkHeading}>Item Description</th>
                <th className={styles.darkHeading}>OEM Part Number</th>
                <th className={styles.darkHeading}>Manufacturer Part Number</th>
                <th className={styles.darkHeading}>Traceability No.</th>
                <th className={styles.darkHeading}>UOM</th>
                <th className={styles.darkHeading}>Qty.</th>
                <th className={styles.darkHeading}>Picture</th>
                <th className={styles.darkHeading}>Condition at Receipt</th>
                <th className={styles.darkHeading}>Inspection Remarks</th>
              </tr>

              {formData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.Item_Name}</td>
                  <td>{item.OEM_Part_Number}</td>
                  <td>{item.Manufacturer_Part_Number}</td>
                  <td>{item.Traceability_Number}</td>
                  <td>{item.UoM}</td>
                  <td>{item.Incoming_Qty}</td>
                  <td>
                    {/* Split the Picture field by commas and render each image */}
                    {item.Picture && item.Picture.split(',').map((pictureUrl, picIndex) => (
                      <img
                        key={picIndex}
                        src={pictureUrl.trim()}  // Trimming in case of any spaces around the URL
                        alt={`Item picture ${picIndex + 1}`}
                        style={{ width: "100px", marginRight: "5px" }} // Adjust size and margin as necessary
                      />
                    ))}
                  </td>
                  <td>{item.Condition_Upon_Receipt}</td>
                  <td>{item.Remarks}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />

          <table>
            <tbody>
              <tr>
                <td style={{ width: "45vw" }}>
                  <b>Inspected by: </b>
                  <br />
                  <b>Name: </b> M. Ajhar
                </td>
                <td>
                  <b>Reviewed & Approved by: </b>
                  <br />
                  <b>Name: </b> M. Haneef
                </td>
              </tr>
            </tbody>
          </table>

          <div className={styles.footer}>
            <div className={styles.footerInfo}>
              <div>TSD-OPS-FOR-019-23, Rev.03</div>
              <div>08 Aug 2024</div>
              <div>Page No. 1</div>
            </div>
            <div className={styles.footerSign}>
              <div>
                This document is digitally generated and does not require a
                signature.
              </div>
            </div>
          </div>

          <div className={styles.btnContainerFixed}>

            <Button
              variant="contained"
              type="submit"
              className={styles.Downloadpdfbtn}
              onClick={handleSubmit}
            >
              Download Pdf
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default IncomingInspectionReport;
