import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../Components/css/visualinspectionscreatenew.module.css";
import axios from "axios";
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Visual_Inspections_Create_New() {
  const [itemsReceived, setItemsReceived] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { Project_ID } = useParams();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks

  const navigate=useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_wip_items_get?Project_ID=${Project_ID}`
        );
        setItemsReceived(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [Project_ID]);

  const handleSelectAllChange = (checked) => {
    setSelectAll(checked);
    if (checked) {
      setSelectedItems(itemsReceived.map(item => item.S_No));
    } else {
      setSelectedItems([]);
    }
  };

  const handleItemChange = (itemSNo, checked) => {
    setSelectedItems(prevSelectedItems =>
      checked ? [...prevSelectedItems, itemSNo] : prevSelectedItems.filter(sNo => sNo !== itemSNo)
    );
  };
  const handleClick = () => {
    navigate(`/reports-workshop/${Project_ID}`);
  };

  useEffect(() => {
    setSelectAll(selectedItems.length === itemsReceived.length && itemsReceived.length > 0);
  }, [selectedItems, itemsReceived]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const userEmail = localStorage.getItem('useremail');

      const userRes = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_user_details_project_details?Email=${userEmail}`);

    const selectedData = itemsReceived.filter(item => selectedItems.includes(item.S_No));
    const datePayload = {
      Created_By: userRes.data.data[0].Name,
      Project_ID,
      Date: new Date().toISOString().split('T')[0], // Sending the current date without timestamp
    };
  
    try {
      // First API call to post Project_ID and Date
      const dateRes = await axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/post_project_date", datePayload);
  
      if (dateRes.status === 200) {
        const Visual_Inspection_ID = dateRes.data.id; // Assuming the ID is returned in the response data
  
        // Proceed with the second API call if the first one is successful
        const itemsPayload = {
          Visual_Inspection_ID,
          Project_ID,
          selectedItems: selectedData,
        };
  
        const itemsRes = await axios.post("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/post_selected_items", itemsPayload);
        
        // Handle success (e.g., show a success message, navigate to another page, etc.)
        if (itemsRes.status === 200) {
          setIsLoading(false);
          navigate(`/reports-workshop/${Project_ID}`);
        }
      } else {
        console.error("Error posting project date:", dateRes.statusText);
        // Handle error (e.g., show an error message)
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error in API calls:", error);
      // Handle error (e.g., show an error message)
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <LoadingScreen isLoading={isLoading} />
      <div className={styles.topButtonContainer}>
        <button className={styles.topBackButton} onClick={handleClick}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292895 7.29289C-0.0976302 7.68341 -0.0976318 8.31658 0.292891 8.7071L6.65684 15.0711C7.04736 15.4616 7.68052 15.4616 8.07105 15.0711C8.46158 14.6806 8.46158 
                  14.0474 8.07105 13.6569L2.41421 8L8.07108 2.34316C8.46161 1.95264 8.46161 1.31947 8.07109 0.928948C7.68056 0.538422 7.0474 0.538421 6.65687 0.928944L0.292895 7.29289ZM2 7L1 7L0.999997 9L2 9L2 7Z"
              fill="#5B6BE1"
            />
          </svg>
          {' '}Visual Inspections
        </button>
      </div>
      <div className={styles.editAndSaveButtons}>
        <>
          <button
            variant="contained"
            onClick={handleSubmit}
            className={styles.saveButton}
            disabled={isLoading}
          >
            Create
          </button>

          <button
            variant="outlined"
            onClick={handleClick}
            className={styles.cancelBtn}
          >
            Cancel
          </button>
        </>
      </div>

      <div className={styles.itemstablecontainer}>
        <table className={styles.itemstable}>
          <thead>
            <tr>
              {itemsReceived.length === 0 ? (
                <th></th>
              ) : (
                <>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      style={{ width: "14px", height: "14px", marginLeft: "15px" }}
                      onChange={(e) => handleSelectAllChange(e.target.checked)}
                    />
                   
                    Select All
                  </th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>Part Traceability No</th>
                  <th>Equipment Traceability No</th>
                  <th>UoM</th>
                  <th>Qty</th>
                  <th>HS Code</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {itemsReceived.length === 0 ? (
              <tr>
                <td colSpan="8">No results found</td>
              </tr>
            ) : (
              itemsReceived.map((item) => (
                <tr key={item.S_No}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item.S_No)}
                      style={{ width: "14px", height: "14px", marginLeft: "15px" }}
                      onChange={(e) => handleItemChange(item.S_No, e.target.checked)}
                    />
                  </td>
                  <td>{item.Item_Name}</td>
                  <td>{item.Part_Number}</td>
                  <td>{item.Part_Traceability_Number}</td>
                  <td>{item.Equipment_Traceability_Number}</td>
                  <td>{item.UoM}</td>
                  <td>{item.Qty_Produced}</td>
                  <td>{item.HS_Code}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Visual_Inspections_Create_New;
