import React, { useState, useRef, useEffect } from "react";
import styles from "../Components/css/mobilizations.module.css";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Modal from '@mui/material/Modal';
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { styled } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const Mobilizations = () => {
  const [mobilizations, setMobilizations] = useState(3);

  const [profdetails, setprofdetails] = useState([]);
  const [filteredprofdetails, setfilteredprofdetails] = useState([]);
  const [selectedDateDisabled, setSelectedDateDisabled] = useState(true);
  const [updatedateksa, setupdatedateksa] = useState("");
  const [updatedateuae, setupdatedateuae] = useState("");
  const [personaldetails, setpersonaldetails] = useState([]);
  const [filteredmobdetails, setfilteredmobdetails] = useState([]);
  const [filtereddemobdetails, setfiltereddemobdetails] = useState([]);
  const [visacountry, setvisacountry] = useState("UAE");
  const [filteredvisa, setfilteredvisa] = useState([]);
  const [projectdetails, setprojectdetails] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [removemodal, setremovemodal] = useState(false);
  const [renewmodal, setrenewmodal] = useState(false);
  const [editon, setediton] = useState(false);
  const [updateexit, setupdateexit] = useState("");
  const [modalopen, setmodalopen] = useState(false);
  const [profremovedoc, setprofremovedoc] = useState("");
  const [profrenewdoc, setprofrenewdoc] = useState("");
  const [profrenewdocname, setprofrenewdocname] = useState("");
  const [profremoveexpiry, setprofremoveexpiry] = useState("");
  const [profrenewexpiry, setprofrenewexpiry] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [profremoveid, setprofremoveid] = useState(0);
  const [profrenewid, setprofrenewid] = useState(0);
  const [profrenewname, setprofrenewname] = useState("");
  const [renewexpiry, setrenewexpiry] = useState("");
  const [renewdoc, setrenewdoc] = useState("");
  const [renewdocURL, setrenewdocURL] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const presentdate = new Date();
  presentdate.setHours(0, 0, 0, 0);
  console.log(presentdate);
  let prevsno = 1;
  const mobilizationsTab = (tabNumber) => {
    setMobilizations(tabNumber);
  };
  const visafunction = (e) => {
    const { value } = e.target;
    setvisacountry(value);
  };
  const handleupdateexit = (e) => {
    setupdateexit(e.target.value);
    console.log(updateexit);
    if (visacountry == "KSA" && updateexit == "enter") {
      setupdatedateksa("");
    }
    if (visacountry == "UAE" && updateexit == "enter") {
      setupdatedateuae("");
    };

    if (e.target.value === "enter") {
      setSelectedDateDisabled(true); // Disable the date input if "Entering Country" is selected
    } else {
      setSelectedDateDisabled(false); // Enable the date input if "Exiting Country" is selected
    }
  }



  const handleMouseEnter = () => {
    setIsHovered(!isHovered);
  };

  const handlebutton = () => {
    setediton(true);
  };

  const handleexceldwn = () => {
    const form = document.getElementById('mobt');
    const table = form.querySelector("table");
    const rows = Array.from(table.querySelectorAll("tr"));

    const data = rows.map((row) =>
      Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
    );

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.write to generate the Excel file as an ArrayBuffer
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Use FileSaver.js to save the file
    saveAs(blob, "table_data.xlsx");
  }



  const renewdate = (event) => {
    const inputDate = event.target.value;
    const parts = inputDate.split('-');
    let formatted = "";
    if (parts.length === 3) {
      const [year, month, day] = parts;
      formatted = `${day}/${month}/${year}`;
    }
    setrenewexpiry(formatted);
    console.log(formatted);
  }

  const removeprofdoc = (el, i, j) => {
    setprofremoveexpiry(j);
    setprofremovedoc(i);
    setprofremoveid(el);
    setremovemodal(true);
  }

  const renewprofdocs = (el, i, j, k, l) => {
    setrenewmodal(true);
    setprofrenewid(el);
    setprofrenewname(i);
    setprofrenewdocname(j);
    setprofrenewdoc(k);
    setprofrenewexpiry(l);
  }

  const handlerenewdoc = (e) => {
    const selectedFile = e.target.files[0];
    setrenewdoc(selectedFile);
    setrenewdocURL(URL.createObjectURL(selectedFile));
  }

  const renewcancel = () => {
    setrenewmodal(false);
    setprofrenewid(0);
    setprofrenewname("");
    setprofrenewdocname("");
    setprofrenewdoc("");
    setprofrenewexpiry("");
    setrenewdocURL("");
    setrenewdoc("");
    setrenewexpiry("");
  }

  const renewsave = async () => {

    const formData = new FormData();
    formData.append('Renew_Doc', renewdoc); // Append the file only


    formData.append('Doc_name', profrenewdoc);
    formData.append('Expiry', profrenewexpiry);
    formData.append('Date', renewexpiry);

    try {
      const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professionaldoc_renew?S_No=${profrenewid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(res);
      setrenewmodal(false);
      setprofrenewid(0);
      setprofrenewname("");
      setprofrenewdocname("");
      setprofrenewdoc("");
      setprofrenewexpiry("");
      setrenewdocURL("");
      setrenewdoc("");
      setrenewexpiry("");
      setrefresh(!refresh);


    } catch (error) {
      console.error(error);

    }
  };



  const removeprofdocno = () => {
    setprofremoveexpiry("");
    setprofremovedoc("");
    setprofremoveid(0);
    setremovemodal(false);
  }

  const removeprofdocyes = async () => {
    const payload = {
      Doc_name: profremovedoc,
      Expiry: profremoveexpiry
    }
    const res = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professionaldoc_remove?S_No=${profremoveid}`, payload);
    console.log(res.data);
    setprofremoveexpiry("");
    setprofremovedoc("");
    setprofremoveid(0);
    setremovemodal(false);
    setrefresh(!refresh);
  }

  const handleupdate = async () => {
    console.log(updatedateksa);
    console.log(updatedateuae);

    const payload = {
      KSA_Exit_Date: updatedateksa,
      UAE_Exit_Date: updatedateuae,
    }

    try {
      const res = await axios.put(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/professional_detailsupdation2/${filteredvisa[selectedRow]?.Customer_Name}`,
        payload
      );

      console.log(res.data);
      if (res.status === 200) {
        setSelectedDateDisabled(true);
        setmodalopen(false);
        setediton(false);
        setSelectedRow(null);
        setupdateexit("");
        setrefresh(!refresh);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openmodal = (index) => {
    setSelectedRow(index);
    setmodalopen(true);
    if (visacountry == "KSA") {
      setupdatedateuae(filteredvisa[index].UAE_Exit_Date);
      setupdatedateksa("");
    }
    if (visacountry == "UAE") {
      setupdatedateksa(filteredvisa[index].KSA_Exit_Date);
      setupdatedateuae("");
    }
  };

  const handlecancelmodal = () => {
    setmodalopen(false);
    setediton(false);
    setSelectedRow(null);
    setSelectedDateDisabled(true);
    setupdateexit("");
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    const fetchprof = async () => {
      const res = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getprofessional_details");
      const res1 = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/forward_mobilization_all");
      const res2 = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getproject_details");
      setprofdetails(res.data);
      setpersonaldetails(res1.data);
      setprojectdetails(res2.data.data);

    }
    fetchprof();
  }, [refresh])

  useEffect(() => {
    if (personaldetails.length > 0) {
      const INT_MAX = Number.MAX_SAFE_INTEGER;
      const mob = personaldetails.filter((el) => {
        let mobdays = INT_MAX;
        if (el?.Boarding_Date) {
          const parts = el.Boarding_Date.split("-");
          const day = parseInt(parts[2]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[0]);
          let mobdate = new Date(year, month, day);
          let diff = mobdate.getTime() - presentdate.getTime();
          mobdays = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        return mobdays <= 7 && mobdays >= 0;
      })
      setfilteredmobdetails(mob);

      const demob = personaldetails.filter((el) => {
        let demobdays = INT_MAX;
        if (el?.return_Boarding_Date) {
          const parts = el.return_Boarding_Date.split("-");
          const day = parseInt(parts[2]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[0]);
          let demobdate = new Date(year, month, day);
          let diff = demobdate.getTime() - presentdate.getTime();
          demobdays = Math.round(diff / (24 * 60 * 60 * 1000));
        }

        return demobdays <= 7 && demobdays >= 0;
      })
      setfiltereddemobdetails(demob);

    }
  }, [personaldetails]);

  // useEffect(() => {
  //   console.log(filtereddemobdetails);
  //   console.log(filteredmobdetails);
  // },[filtereddemobdetails, filteredmobdetails]);



  useEffect(() => {
    if (profdetails.length > 0) {
      const INT_MAX = Number.MAX_SAFE_INTEGER;
      const filteredprof = profdetails.filter((el) => {
        let seamandays = INT_MAX;
        if (el?.Seaman_Book_Expiry) {
          const parts = el.Seaman_Book_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const seamandate = new Date(year, month, day);
          let diff = seamandate.getTime() - presentdate.getTime();
          seamandays = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let boisetdays = INT_MAX;
        if (el?.Bosiet_Expiry) {
          const parts = el.Bosiet_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const boisetdate = new Date(year, month, day);
          let diff = boisetdate.getTime() - presentdate.getTime();
          boisetdays = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let passportdays = INT_MAX;
        if (el?.Passport_Expiry) {
          const parts = el.Passport_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const passportdate = new Date(year, month, day);
          let diff = passportdate.getTime() - presentdate.getTime();
          passportdays = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let insurancedays = INT_MAX;
        if (el?.Insurance_Expiry) {
          const parts = el.Insurance_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const insurancedate = new Date(year, month, day);
          let diff = insurancedate.getTime() - presentdate.getTime();
          insurancedays = Math.round(diff / (24 * 60 * 60 * 1000));
        }

        let medicaldays = INT_MAX;
        if (el?.Medical_Expiry) {
          const parts = el.Medical_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const medicaldate = new Date(year, month, day);
          let diff = medicaldate.getTime() - presentdate.getTime();
          medicaldays = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let h2sdays = INT_MAX;
        if (el?.H2s_Expiry) {
          const parts = el.H2s_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const h2sdate = new Date(year, month, day);
          let diff = h2sdate.getTime() - presentdate.getTime();
          h2sdays = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let sntdays = INT_MAX;
        if (el?.SNT_Eye_Test_Expiry) {
          const parts = el.SNT_Eye_Test_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const sntdate = new Date(year, month, day);
          let diff = sntdate.getTime() - presentdate.getTime();
          sntdays = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let visa1days = INT_MAX;
        if (el?.Active_Visas_One_Expiry) {
          const parts = el.Active_Visas_One_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const visa1date = new Date(year, month, day);
          let diff = visa1date.getTime() - presentdate.getTime();
          visa1days = Math.round(diff / (24 * 60 * 60 * 1000));
        }

        let visa2days = INT_MAX;
        if (el?.Active_Visas_Two_Expiry) {
          const parts = el.Active_Visas_Two_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const visa2date = new Date(year, month, day);
          let diff = visa2date.getTime() - presentdate.getTime();
          visa2days = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let visa3days = INT_MAX;
        if (el?.Active_Visas_Three_Expiry) {
          const parts = el.Active_Visas_Three_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const visa3date = new Date(year, month, day);
          let diff = visa3date.getTime() - presentdate.getTime();
          visa3days = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let visa4days = INT_MAX;
        if (el?.Active_Visas_Four_Expiry) {
          const parts = el.Active_Visas_Four_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const visa4date = new Date(year, month, day);
          let diff = visa4date.getTime() - presentdate.getTime();
          visa4days = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let visa5days = INT_MAX;
        if (el?.Active_Visas_Five_Expiry) {
          const parts = el.Active_Visas_Five_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const visa5date = new Date(year, month, day);
          let diff = visa5date.getTime() - presentdate.getTime();
          visa5days = Math.round(diff / (24 * 60 * 60 * 1000));
        }
        let visa6days = INT_MAX;
        if (el?.Active_Visas_Six_Expiry) {
          const parts = el.Active_Visas_Six_Expiry.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const visa6date = new Date(year, month, day);
          let diff = visa6date.getTime() - presentdate.getTime();
          visa6days = Math.round(diff / (24 * 60 * 60 * 1000));
        }

        return seamandays < 60 || boisetdays < 60 || passportdays < 60 || insurancedays < 60 || medicaldays < 60 || h2sdays < 60 || sntdays < 60 || visa1days < 60 || visa2days < 60 || visa3days < 60 || visa4days < 60 || visa5days < 60 || visa6days < 60;

      })

      setfilteredprofdetails(filteredprof);
    }
    console.log(profdetails);
  }, [profdetails]);

  useEffect(() => {
    if (profdetails.length > 0) {
      const filtered = profdetails.filter((el) => {
        return (
          el?.Active_Visas_One === visacountry ||
          el?.Active_Visas_Two === visacountry ||
          el?.Active_Visas_Three === visacountry ||
          el?.Active_Visas_Four === visacountry ||
          el?.Active_Visas_Five === visacountry ||
          el?.Active_Visas_Six === visacountry
        );
      });
      setfilteredvisa(filtered);
    }
  }, [profdetails, visacountry]);
  const handleupdatedate = (e) => {
    if (visacountry == "UAE" && updateexit == "exit") {
      setupdatedateuae(e.target.value);
    }
    if (visacountry == "KSA" && updateexit == "exit") {
      setupdatedateksa(e.target.value);
    }
  };

  return (
    <div >
      <div
        style={{
          marginTop: "135px",
        }}
      >
        {/* <div className={styles.allTabs}>
          <div
            style={{
              marginLeft: "20px",
            }}
            className={
              mobilizations === 3
                ? styles.activeMobilizationsTab
                : styles.mobilizationsTab
            }
            onClick={() => mobilizationsTab(3)}
          >
          Resident Visas
          </div>
          <div
            className={
              mobilizations === 2
                ? styles.activeMobilizationsTab
                : styles.mobilizationsTab
            }
            onClick={() => mobilizationsTab(2)}
          >
            Documents
          </div>
          <div
            className={
              mobilizations === 1
                ? styles.activeMobilizationsTab
                : styles.mobilizationsTab
            }
            onClick={() => mobilizationsTab(1)}
          >
            Flights
          </div>
        </div> */}
      </div>
      {/* {mobilizations === 1 && ( */}
      <div style={{ marginTop: "20px" }}>
        <div>
          <label htmlFor="message" className={styles.timeperiodlabel}>
            Time Period:
          </label>
          <input
            type="text"
            value="Next 7 Days"
            className={styles.noneditabletextbox}
            disabled
          />
        </div>
        {/* <input
            type="text"
            value="Date"
            className={styles.noneditabletextbox1}
            disabled
          /> */}
        {[...Array(7)].map((_, index) => {
          const date = new Date();
          let sno = 1;
          date.setDate(presentdate.getDate() + index + 1);
          function formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
          }


          const mobEntriesExist = filteredmobdetails.some((el) => {
            const INT_MAX = Number.MAX_SAFE_INTEGER;
            let mobdays = INT_MAX;
            const proj = projectdetails.filter((elm) => elm.ID_No === parseInt(el.ID));

            if (el?.Boarding_Date) {
              const parts = el.Boarding_Date.split("-");
              const day = parseInt(parts[2]);
              const month = parseInt(parts[1]) - 1;
              const year = parseInt(parts[0]);
              let mobdate = new Date(year, month, day);
              let diff = mobdate.getTime() - presentdate.getTime();
              mobdays = Math.round(diff / (24 * 60 * 60 * 1000));
            }

            return mobdays === index + 1;
          });

          const demobEntriesExist = filtereddemobdetails.some((el) => {
            const INT_MAX = Number.MAX_SAFE_INTEGER;
            let demobdays = INT_MAX;
            const proj = projectdetails.filter((elm) => elm.ID_No === el.ID);

            if (el?.return_Boarding_Date) {
              const parts = el.return_Boarding_Date.split("-");
              const day = parseInt(parts[2]);
              const month = parseInt(parts[1]) - 1;
              const year = parseInt(parts[0]);
              let demobdate = new Date(year, month, day);
              let diff = demobdate.getTime() - presentdate.getTime();
              demobdays = Math.round(diff / (24 * 60 * 60 * 1000));
            }

            return demobdays === index + 1;
          });


          if (mobEntriesExist || demobEntriesExist) {
            return (
              <div key={index}>
                <div className={styles.noneditabletextbox1}>
                  <span style={{ marginLeft: "20px", fontSize: "16px", fontWeight: "700" }}>Date : {formatDate(date)}</span>
                </div>
                <div style={{ /* backgroundColor: "white", */ }}>
                  <table className={styles.rigtable}>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Professional Name</th>
                        <th>Nature</th>
                        <th>Project</th>
                        <th>Departure Airport</th>
                        <th>Arrival Airport</th>
                        <th>Ticket</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredmobdetails.map((el, ind) => {
                        const INT_MAX = Number.MAX_SAFE_INTEGER;
                        let mobdays = INT_MAX;
                        const proj = projectdetails.filter((elm) => elm.ID_No === parseInt(el.ID));
                        console.log(proj[0]);
                        if (el?.Boarding_Date) {
                          const parts = el.Boarding_Date.split("-");
                          const day = parseInt(parts[2]);
                          const month = parseInt(parts[1]) - 1;
                          const year = parseInt(parts[0]);
                          let mobdate = new Date(year, month, day);
                          let diff = mobdate.getTime() - presentdate.getTime();
                          mobdays = Math.round(diff / (24 * 60 * 60 * 1000));
                        }
                        if (mobdays === index + 1) {
                          return (
                            <tr key={ind}>
                              <td>{sno++}</td>
                              <td>{el.Customer_Name}</td>
                              <td>Mobilization</td>
                              <td>{proj[0]?.Rig_Name ? proj[0].Rig_Name : "-"}</td>
                              <td>{el.Boarding_Airport ? el.Boarding_Airport : "-"}</td>
                              <td>{el.Arrival_Airport ? el.Arrival_Airport : "-"}</td>
                              <td>{el.Upload_Tickets ? <a href={el.Upload_Tickets} target="_blank">{el.Customer_Name} <svg width="13" height="13" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.77778 0.000318156V1.66699H12.9689L4.23111 9.85833L5.48444 11.0333L14.2222 2.84198V5.83365H16V0.000318156M14.2222 13.3333H1.77778V1.66667H7.77778V0.000318156L1.77778 0C1.30628 0 0.854097 0.175595 0.520699 0.488155C0.187301 0.800716 0 1.22464 0 1.66667V13.3333C0 13.7754 0.187301 14.1993 0.520699 14.5118C0.854097 14.8244 1.30628 15 1.77778 15H14.2222C14.6937 15 15.1459 14.8244 15.4793 14.5118C15.8127 14.1993 16 13.7754 16 13.3333V7.91666H14.2222V13.3333Z" fill="black" />
                              </svg></a> : "-"}</td>
                            </tr>
                          )
                        }
                      })}
                      {filtereddemobdetails.map((el, ind) => {
                        const INT_MAX = Number.MAX_SAFE_INTEGER;
                        let demobdays = INT_MAX;
                        const proj = projectdetails.filter((elm) => elm.ID_No === el.ID);
                        if (el?.return_Boarding_Date) {
                          const parts = el.return_Boarding_Date.split("-");
                          const day = parseInt(parts[2]);
                          const month = parseInt(parts[1]) - 1;
                          const year = parseInt(parts[0]);
                          let demobdate = new Date(year, month, day);
                          let diff = demobdate.getTime() - presentdate.getTime();
                          demobdays = Math.round(diff / (24 * 60 * 60 * 1000));
                        }
                        if (demobdays === index + 1) {
                          return (
                            <tr key={ind}>
                              <td>{sno++}</td>
                              <td>{el.Customer_Name}</td>
                              <td>Demobilization</td>
                              <td>{proj[0]?.Rig_Name ? proj[0].Rig_Name : "-"}</td>
                              <td>{el.return_Boarding_Airport ? el.return_Boarding_Airport : "-"}</td>
                              <td>{el.return_Arrival_Airport ? el.return_Arrival_Airport : "-"}</td>
                              <td>{el.return_Upload_Ticket ? <a href={el.return_Upload_Ticket} target="_blank">{el.Customer_Name} <svg width="13" height="13" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.77778 0.000318156V1.66699H12.9689L4.23111 9.85833L5.48444 11.0333L14.2222 2.84198V5.83365H16V0.000318156M14.2222 13.3333H1.77778V1.66667H7.77778V0.000318156L1.77778 0C1.30628 0 0.854097 0.175595 0.520699 0.488155C0.187301 0.800716 0 1.22464 0 1.66667V13.3333C0 13.7754 0.187301 14.1993 0.520699 14.5118C0.854097 14.8244 1.30628 15 1.77778 15H14.2222C14.6937 15 15.1459 14.8244 15.4793 14.5118C15.8127 14.1993 16 13.7754 16 13.3333V7.91666H14.2222V13.3333Z" fill="black" />
                              </svg></a> : "-"}</td>
                            </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          } else {
            // If no entries, return null or an empty fragment
            return (<>
              <div className={styles.noneditabletextbox1}>
                <span style={{ marginLeft: "20px", fontSize: "16px", fontWeight: "700" }}>Date : {formatDate(date)}</span>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "15px", marginBottom: "30px" }}>
                <h4>No Flights for the day</h4>
              </div>
            </>
            )

          }
        })}


      </div>
      {/* )} */}
      {mobilizations === 2 && (
        <div style={{ marginTop: "20px" }}>




        </div>
      )}
      {mobilizations === 3 && (
        <div style={{ marginTop: "20px", overflowY: "hidden" }}>




        </div>
      )}
    </div>
  );
};

export default Mobilizations;