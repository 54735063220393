import React from 'react'
import Erogonlogo from "../Components/Erogonlogo.png"
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function Equipmentform2() {
  return (
  
    <div>
    {/* <div className='equipmentformheading' style={{marginTop:"60px",fontSize:"20px"}}>Equipmentform1</div> */}
    <div>
    <img src={Erogonlogo} className="ergonlogo2" height="69" width="299" style={{textAlign:"center",marginLeft:"590px", marginTop:"85px"}}  />
    <hr style={{marginTop:"20px",borderTop: "2px solid black", height:"2px",backgroundColor:" black",marginLeft:"190px",marginRight:"190px"}}></hr>
    </div>

    <div style={{marginLeft:"600px"}}><b>Measuring Equipment Initial Validation Form</b></div>
    <table style={{ height: "99px", width: "750px", borderCollapse: "collapse",marginLeft:"390px" ,border: "2px solid black",marginTop:"40px"}}>
  <tbody>
    <tr>
      <td style={{ border: "2px solid black" }}>Equipment</td>
      <td style={{ border: "2px solid black" }}>Torque Wrench</td>
      <td style={{ border: "2px solid black" }}>Tag No</td>
      <td style={{ border: "2px solid black" }}>NA</td>
    </tr>
    <tr>
      <td style={{ border: "2px solid black" }}>Model</td>
      <td style={{ border: "2px solid black" }}>CAT <br/>1628718</td>
      <td style={{ border: "2px solid black" }}> Sr.No</td>
      <td style={{ border: "2px solid black" }}>17365</td>
    </tr>
    <tr>
      <td style={{ border: "2px solid black" }}>Date</td>
      <td style={{border: "2px solid black" }}>18-05-2022</td>
      <td style={{ border: "2px solid black" }}> Time</td>
      <td style={{ border: "2px solid black" }}>11:00 AM</td>
    </tr>
  </tbody>
</table>

<table style={{ height: "99px", width: "750px", marginLeft:"390px" ,marginTop:"60px"}}>
    <tbody>
        <tr>
        <th style={{ backgroundColor: "#2C4667",border: "2px solid black"}}>
            Description/Criteria
        </th>
        <th style={{ backgroundColor: "#2C4667",border: "2px solid black" }}>
            Parameters
        </th>
        <th style={{ backgroundColor: "#2C4667",border: "2px solid black" }}>
           Test Criteria
        </th>
        <th style={{ backgroundColor: "#2C4667",border: "2px solid black" }}>
            Results (Pass/Fail)
        </th>
        </tr>
        <tr>
            <td style={{ border: "2px solid black" }}>Inspect the ratchet assembly function  </td>
            <td style={{ border: "2px solid black" }}>Slip </td>
            <td style={{ border: "2px solid black" }}>No slip</td>
            <td style={{ border: "2px solid black" }}>Pass</td>
        </tr>
        <tr>
            <td style={{ border: "2px solid black" }}>Visibility and readability of torque markings</td>
            <td style={{ border: "2px solid black" }}> Visibility</td>
            <td style={{ border: "2px solid black" }}>Visible and accurate </td>
            <td style={{ border: "2px solid black" }}>Pass</td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}>Condition of Grip</td>
            <td style={{height:"24px",border: "2px solid black"}}>Firmness</td>
            <td style={{height:"24px",border: "2px solid black"}}>Firm and strong</td>
            <td style={{height:"24px",border: "2px solid black"}}> Pass</td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
        <tr>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
            <td style={{height:"24px",border: "2px solid black"}}></td>
        </tr>
      
    </tbody>
</table>

<h6 style={{marginTop:"100px",marginLeft:"400px"}}>Tested By</h6>
<h6 style={{marginLeft:"400px",marginTop:"50px"}}>Signature</h6>

<h6  style={{marginLeft:"610px",marginTop:"-93px"}}>Vo Doan Thang</h6>

<h6 style={{marginLeft:"810px",marginTop:"-27px"}}>Reviewed by/<br/>Approved by</h6>

<h6 style={{marginLeft:"1020px",marginTop:"-43px"}}>Nishad Majeed</h6>


<hr style={{marginTop:"90px",borderTop: "2px solid black", height:"2px",color:"black" ,backgroundColor:" black",marginLeft:"190px",marginRight:"190px"}}></hr>

<footer>
<h6 style={{textAlign:"left"}}>TSD-OPS-FOR-007-19, Rev.01</h6>
<h6 style={{textAlign:"center",marginTop:"-30px"}}>30-Apr-22 </h6>
<h6 style={{textAlign:"right",marginTop:"-30px"}}>Page 1</h6>

</footer>
    </div>
  )
}

export default Equipmentform2;