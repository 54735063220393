import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import styles from "./css/modal.module.css";
import { useNavigate, useParams,useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const CustomModal = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const BasicModal = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const routeBackToProjects = () => {
  //   navigate("/projects");
  // };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const handleExitWithoutSaving = async () => {
    try {
      await axios.delete(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details/delete/${id}`);
      await axios.delete(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/project_details_commencement_date_delete?Project_ID=${id}`);
      navigate("/projects");
    } catch (error) {
      console.error("Failed to delete project:", error);
      // Handle error as needed (e.g., show error message to the user)
    }
  };

  const saveAsDraft = (e) => {
    props.postOrPut(e);
    setTimeout(() => {
      navigate("/projects");
    }, 0);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        sx={{
          textTransform: "capitalize",
          fontWeight: "700",
          border: "1px solid #5B6BE1",
          color: "#5B6BE1",
          borderRadius: "8px",
          height: "50px",
          width: "170px",
          "&:hover": {
            borderColor: "#5B6BE1",
          },
        }}>
        Exit
      </Button>
      <CustomModal
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
        }}>
        <Fade in={open}>
          <Box className={styles.ModalBox}>
            <div>
              <svg
                onClick={handleClose}
                className={styles.crossButton}
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.9499 22.3641L9.63615 11.0504C9.2496 10.6638 9.2496 10.0227 9.63615 9.63618C10.0227 9.24963 10.6638 9.24963 11.0504 9.63618L22.3641 20.9499C22.7506 21.3364 22.7506 21.9776 22.3641 22.3641C21.9775 22.7507 21.3364 22.7507 20.9499 22.3641Z"
                  fill="black"
                />
                <path
                  d="M9.63592 22.3641C9.24937 21.9776 9.24937 21.3364 9.63592 20.9499L20.9496 9.63618C21.3362 9.24963 21.9773 9.24963 22.3638 9.63618C22.7504 10.0227 22.7504 10.6638 22.3638 11.0504L11.0501 22.3641C10.6636 22.7507 10.0225 22.7507 9.63592 22.3641Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className={styles.iconAndText}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.0002 3.33337C10.8168 3.33337 3.3335 10.8167 3.3335 20C3.3335 29.1834 10.8168 36.6667 20.0002 36.6667C29.1835 36.6667 36.6668 29.1834 36.6668 20C36.6668 10.8167 29.1835 3.33337 20.0002 3.33337ZM18.7502 13.3334C18.7502 12.65 19.3168 12.0834 20.0002 12.0834C20.6835 12.0834 21.2502 12.65 21.2502 13.3334V21.6667C21.2502 22.35 20.6835 22.9167 20.0002 22.9167C19.3168 22.9167 18.7502 22.35 18.7502 21.6667V13.3334ZM21.5335 27.3C21.4502 27.5167 21.3335 27.6834 21.1835 27.85C21.0168 28 20.8335 28.1167 20.6335 28.2C20.4335 28.2834 20.2168 28.3334 20.0002 28.3334C19.7835 28.3334 19.5668 28.2834 19.3668 28.2C19.1668 28.1167 18.9835 28 18.8168 27.85C18.6668 27.6834 18.5502 27.5167 18.4668 27.3C18.3835 27.1 18.3335 26.8834 18.3335 26.6667C18.3335 26.45 18.3835 26.2334 18.4668 26.0334C18.5502 25.8334 18.6668 25.65 18.8168 25.4834C18.9835 25.3334 19.1668 25.2167 19.3668 25.1334C19.7668 24.9667 20.2335 24.9667 20.6335 25.1334C20.8335 25.2167 21.0168 25.3334 21.1835 25.4834C21.3335 25.65 21.4502 25.8334 21.5335 26.0334C21.6168 26.2334 21.6668 26.45 21.6668 26.6667C21.6668 26.8834 21.6168 27.1 21.5335 27.3Z"
                  fill="#5B6BE1"
                />
              </svg>

              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ color: "#5B6BE1", marginTop: "10px", fontWeight: "600" }}>
                Want to save your progress?
              </Typography>
            </div>
            <div className={styles.buttonPosition}>
              <Button
                onClick={saveAsDraft}
                className={styles.buttonStyles}
                variant="outlined">
                Save Details
              </Button>
              <Button
                onClick={handleExitWithoutSaving}
                className={`${styles.buttonStyles} ${styles.blackColour}`}
                variant="outlined">
                Exit without saving details
              </Button>
            </div>
          </Box>
        </Fade>
      </CustomModal>
    </>
  );
};

export default BasicModal;
