import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './css/tabs_bar_spares_trading.module.css';

const TabsBarSparesTrading = ({ activeTab }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDivClick = (tab, path) => {
    navigate(`${path}${id}`);
  };

  return (
    <div className={styles.sections1}>
      <div
        className={activeTab === 'Project Checklist' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Project Checklist', '/workshop-spares-trading-project-checklist/')}
      >
        Order Checklist
      </div>
      <div
        className={activeTab === 'Project Details' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Project Details', '/workshop-spares-trading-details-update/')}
      >
        Order Details
      </div>
      <div
        className={activeTab === 'Item Details' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Item Details', '/workshop-spares-trading-item-details/')}
      >
        Item Details
      </div>
      <div
        className={activeTab === 'Inventory Used' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Inventory Used', '/workshop-spares-trading-inventory-used/')}
      >
        Inventory Used
      </div>
      <div
        className={activeTab === 'Dispatch' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Dispatch', '/workshop-spares-trading-dispatch/')}
      >
        Dispatch
      </div>
      <div
        className={activeTab === 'Inspection' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Inspection', '/workshop-spares-trading-outgoing-inspections-listing/')}
      >
        Outgoing Inspections
      </div>
      <div
        className={activeTab === 'Deliveries' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Deliveries', '/workshop-spares-trading-deliveries/')}
      >
        Outgoing Deliveries
      </div>
    </div>
  );
};

export default TabsBarSparesTrading;
